import { combineReducers } from "redux";
import authenticationSlice from "./authenticationSlice";
import navbarSlice from "./navbarSlice";
import themeSlice from "./themeSlice";
import createAccountSlice from "./createAccountSlice";
import accountDashboardSlice from "./accountDashboardSlice";
import templateSlice from "./templateSlice";
import articleSlice from "./articleSlice";
import orderSlice from "./orderSlice";
import articleTypeSlice from "./articleTypeSlice";
import compositionSlice from "./compositionSlice";
import genderSlice from "./genderSlice";
import agentSlice from "./agentSlice";
import retailerSlice from "./retailerSlice";
import supplierSlice from "./supplierSlice";
import subSupplierSlice from "./subSupplierSlice";
import userSlice from "./userSlice";
import settingsWorkspaceSlice from "./settingsWorkspaceSlice";
import certificateSlice from "./certificateSlice";
import subscriptionSlice from "./subscriptionSlice";
import invitationSlice from "./invitationSlice";
import companySlice from "./companySlice";
import billingSlice from "./billingSlice";
import globalActionsSlice from "./globalActionsSlice"
import complacencySlice from "./complacencySlice";
import auditSlice from "./auditSlice"

const appReducer = combineReducers({
  authenticationSlice,
  themeSlice,
  navbarSlice,
  createAccountSlice,
  templateSlice,
  accountDashboardSlice,
  articleSlice,
  orderSlice,
  articleTypeSlice,
  compositionSlice,
  genderSlice,
  agentSlice,
  retailerSlice,
  supplierSlice,
  subSupplierSlice,
  userSlice,
  settingsWorkspaceSlice,
  certificateSlice,
  subscriptionSlice,
  invitationSlice,
  companySlice,
  billingSlice,
  globalActionsSlice,
  complacencySlice,
  auditSlice
});


const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT') {
    // Preserve the auth slice while resetting the rest
    state = { authenticationSlice: state.authenticationSlice, themeSlice: state.themeSlice };
  }
  return appReducer(state, action);
};

export default rootReducer;
