import useAcceptInvitation from "hooks/useAcceptInvitation";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import SetPasswordPage from "./Settings/UserManagement/SetPasswordPage";
import { object, string } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import toast from "react-hot-toast";
import { useMutation } from "@tanstack/react-query";
import { storePassword } from "services/user.services";
import { passwordStrength } from "check-password-strength";
import { useDispatch } from "react-redux";
import { setAuthentication } from "store/authenticationSlice";
import useCustomToaster from "hooks/useCustomToaster";
import LoadingComponent from "components/UI/LoadingComponent";

const SetPasswordSchema = object({
  password: string().min(8),
  hash: string(),
});

const HandleInvitation = () => {
  const dispatch = useDispatch();
  const { showSetPasswordComponent, hash, email, navigate, hasQueryIsLoading } =
    useAcceptInvitation();
  const [showPassCriteria, setShowPassCriteria] = useState(false);
  const [passwordStrengthData, setPasswordStrengthData] = useState(null);
  const { showCustomToast } = useCustomToaster();
  const {
    register,
    control,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      password: "",
      hash,
    },
    mode: "onBlur",
    reValidateMode: "onChange",
    resolver: zodResolver(SetPasswordSchema),
  });

  /// Before Set password mutationpasswordStrengthData
  const setPasswordMutation = useMutation({
    mutationKey: ["user", "setpassword"],
    mutationFn: (data) => storePassword(data),
    onMutate: () => {
      showCustomToast({
        title: "Password setting..."
      }, "set-password");
    },
    onSuccess: (res) => {
      toast.dismiss("set-password");
      if (res) {
        const { token, refresh_token } = res.data;
        localStorage.setItem("auth", token);
        localStorage.setItem("refresh_token", refresh_token);
        dispatch(
          setAuthentication({
            refresh_token: refresh_token,
            token: token,
          }),
        );
        showCustomToast({
          title: "Password set successfully"
        });
        navigate("/");
      }
    },
    onError: (error) => {
      showCustomToast({
        title: error.response?.data?.message || error.message || "Something went wrong"
      });
    },
  });

  const onFormSubmit = (data) => {
    setPasswordMutation.mutate(data);
  };

  const watchedPassword = watch("password");

  useEffect(() => {
    const newPasswordStrengthData = passwordStrength(watchedPassword);
    setPasswordStrengthData(newPasswordStrengthData);
    if (watchedPassword.length === 0) {
      setShowPassCriteria(false);
    } else {
      setShowPassCriteria(true);
    }
  }, [watchedPassword]);

  if (!showSetPasswordComponent && hasQueryIsLoading) return null;

  return (
    <>
      {showSetPasswordComponent && !hasQueryIsLoading ? (
        <div className="flex items-center justify-center h-screen">
          <div className="flex items-center justify-center">
            <SetPasswordPage
              {...{
                register,
                control,
                errors,
                email,
                showPassCriteria,
                passwordStrengthData,
                isLoading: setPasswordMutation.isLoading,
                isPending: setPasswordMutation.isPending,
                handleSubmit,
                onFormSubmit,
              }}

            />
          </div>
        </div>
      ) : (
        <LoadingComponent />
      )}
    </>
  );
};

export default HandleInvitation;
