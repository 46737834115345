import React, { useEffect, useState } from "react";
import {
  BgImg,
  Halflogoicon,
  LeftArrowSlider,
  Mail01Icon,
  PhoneBGImg,
  RightArrowSlide,
  StarsIcon,
} from "assests";
import { Button } from "components/UI/Button";
import { Label } from "components/UI/Label";
import { Input } from "components/UI/Input";
import ErrorMessage from "components/UI/ErrorMessage";
import FormHeader from "components/UI/FormHeader";
import { Link } from "react-router-dom";
import PhoneInput from "react-phone-number-input";
import flags from "react-phone-number-input/flags";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Pagination } from "swiper/modules";
import useGeoLocation from "react-ipgeolocation";
import SigninLogo from "components/UI/SigninLogo";
import { handleRestrictNumbersOnKeyPress, handleRestrictNumbersOnPaste } from "utils/helper";
const PersonalDetails = ({
  register,
  nextStep,
  errors,
  hash,
  getValues,
  setDefaultCountryCode,
  phoneNumberErrorMessage,
  handlePhoneNumberChange,
  defaultCountryCode,
  watch,
}) => {
  const location = useGeoLocation();
  useEffect(() => {
    if (location) {
      setDefaultCountryCode(location.country);
    }
  }, [location]);

  const firstName = watch("firstName", "");

  return (
    <div className="grid grid-cols-12   ">
      <div className="col-span-6 hidden lg:inline-flex bg-global-white relative  justify-center items-center min-h-screen h-full w-full">
        <div className="max-w-[560px] px-8 xl:px-0 signup-swiper relative">
          <Swiper
            slidesPerView={1}
            cssMode={true}
            pagination={{
              clickable: true,
            }}
            navigation={{
              nextEl: ".image-swiper-button-next",
              prevEl: ".image-swiper-button-prev",
              disabledClass: "swiper-button-disabled",
            }}
            modules={[Navigation, Pagination]}
            className="mySwiper"
          >
            <SwiperSlide>
              <div className="flex-col flex justify-center items-center pb-16 gap-8">
                <StarsIcon />
                <div className=" font-medium text-center text-global-title text-2xl md:text-[30px] leading-[32px] md:leading-[38px] md:mb-3">
                  “We’ve been using FiberVue™ to kick start our compliance and can’t imagine
                  working without it.”
                </div>
                <div>
                  <div className="text-global-title text-base font-semibold pb-1">Elise Vonk</div>
                  <div className="text-sm font-medium text-global-paragraph">
                    CSRD Consultant, terStal
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="flex-col flex justify-center items-center pb-16 gap-8">
                <StarsIcon />
                <div className=" font-medium text-center text-global-title text-2xl md:text-[30px] leading-[32px] md:leading-[38px] md:mb-3">
                  “Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem
                  Ipsum has.”
                </div>
                <div>
                  <div className="text-global-title text-base font-semibold pb-1">Elise Vonk</div>
                  <div className="text-sm font-medium text-global-paragraph">
                    CSRD Consultant, terStal
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="flex-col flex justify-center items-center pb-16 gap-8">
                <StarsIcon />
                <div className=" font-medium text-center text-global-title text-2xl md:text-[30px] leading-[32px] md:leading-[38px] md:mb-3">
                  “There are many variations of passages of Lorem Ipsum available, the majority
                  suffered.”
                </div>
                <div>
                  <div className="text-global-title text-base font-semibold pb-1">Elise Vonk</div>
                  <div className="text-sm font-medium text-global-paragraph">
                    CSRD Consultant, terStal
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="flex-col flex justify-center items-center pb-16 gap-8">
                <StarsIcon />
                <div className=" font-medium text-center text-global-title text-2xl md:text-[30px] leading-[32px] md:leading-[38px] md:mb-3">
                  “Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem
                  Ipsum has.”
                </div>
                <div>
                  <div className="text-global-title text-base font-semibold pb-1">Elise Vonk</div>
                  <div className="text-sm font-medium text-global-paragraph">
                    CSRD Consultant, terStal
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <div className="swiper-button image-swiper-button-prev text-global-gray-brand ">
              <LeftArrowSlider width={36} height={36} />
            </div>
            <div className="swiper-button image-swiper-button-next text-global-gray-brand  ">
              <RightArrowSlide width={36} height={36} />
            </div>
          </Swiper>
        </div>
        <div className="absolute bottom-4 right-8">
          <a href="mailto:hello@fibervue.com"><div className="flex items-center gap-2">
            <Mail01Icon width={16} height={16} /> <p className="text-sm font-normal text-btn-text-tertiary">hello@fibervue.com</p>
          </div></a>
        </div>
      </div>
      <div className="col-span-12 lg:col-span-6 inline-flex justify-center items-start pt-[48px] pb-2 lg:pt-[160px] relative">
        <div className="relative z-[2]  ">
          <div
            className=" flex items-center justify-center w-full h-full "
            style={{ pointerEvents: "none" }}
          >
            <img src={BgImg} alt="" className="fixed top-0 hidden md:block z-[-1]" />
            <img src={PhoneBGImg} alt="" className="fixed top-0 block md:hidden z-[-1]" />
          </div>
          <div className="flex flex-col px-4 md:px-0 gap-5 md:gap-8 w-full  md:w-[360px] ">
            <div className="flex flex-col gap-6 w-full">
              <SigninLogo>
                <Halflogoicon width={18.31} height={28} />
              </SigninLogo>
              <FormHeader
                title="Create Account"
                subtitle={`${firstName ? `Hi ${firstName}!` : "Hi!"} Welcome to FiberVue™. Let’s create an account, together.`}
              />
            </div>
            <div className="grid grid-cols-12 gap-4 md:gap-5">
              <div className="col-span-12 ">
                <div className="grid gap-1.5">
                  <Label htmlFor="firstName" className="">
                    First name
                  </Label>
                  <Input
                    id="firstName"
                    type="text"
                    placeholder="Enter your first name"
                    onKeyPress={handleRestrictNumbersOnKeyPress}
                    onPaste={handleRestrictNumbersOnPaste}
                    {...register("firstName", {
                      setValueAs: (value) => value.trim(),
                    })}
                  />
                  <ErrorMessage errors={errors?.firstName?.message} />
                </div>
              </div>
              <div className="col-span-12 ">
                <div className="grid gap-1.5">
                  <Label htmlFor="lastName">Last name</Label>
                  <Input
                    id="lastName"
                    type="text"
                    placeholder="Enter your last name"
                    onKeyPress={handleRestrictNumbersOnKeyPress}
                    onPaste={handleRestrictNumbersOnPaste}
                    {...register("lastName", {
                      setValueAs: (value) => value.trim(),
                    })}
                  />
                  <ErrorMessage errors={errors?.lastName?.message} />
                </div>
              </div>
              <div className="col-span-12 ">
                <div className="grid gap-1.5">
                  <Label htmlFor="email">Company email</Label>
                  <Input
                    id="email"
                    type="email"
                    placeholder="Enter your company email"
                    {...register("email")}
                  />
                  <ErrorMessage errors={errors?.email?.message} />
                </div>
              </div>
              <div className="col-span-12 ">
                <div className="grid gap-1.5">
                  <Label htmlFor="phone">Telephone</Label>

                  <div className="flex w-full h-[44px] gap-1.5 flex-start phone-input-field border border-input-stroke-default hover:border-gray-800   hover:transition hover:duration-300 hover:ease-in-out ">
                    <PhoneInput
                      autoComplete="off"
                      name="telephone"
                      international
                      defaultCountry={defaultCountryCode || ""}
                      withCountryCallingCode
                      flags={flags}
                      value={getValues("telephone") || ""}
                      countryCallingCodeEditable={false}
                      onChange={handlePhoneNumberChange}
                      countrySelectProps={{
                        unicodeFlags: true,
                      }}
                    />
                  </div>
                  {(phoneNumberErrorMessage || errors?.telephone?.message) && (
                    <ErrorMessage errors={phoneNumberErrorMessage || errors?.telephone?.message} />
                  )}
                </div>
              </div>
              <div className="col-span-12 gap-1.5 pt-1">
                <div className="flex justify-center ">
                  <Button
                    type="button"
                    size="lg"
                    className="flex items-center w-full  text-base font-semibold"
                    onClick={nextStep}
                  >
                    Continue
                  </Button>
                </div>
              </div>
            </div>
            <div className="flex items-center justify-center">
              <p className="relative pl-2 pr-1 text-sm font-normal  text-login-text-paragraph whitespace-nowrap">
                Already have an account?
              </p>
              <Link
                to="/signin"
                className="pr-2 bg-transparent hover:bg-transparent hover:text-global-primary text-sm font-semibold  text-btn-text-link whitespace-nowrap "
              >
                Log in
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PersonalDetails;
