import * as z from "zod";

export const createTemplateSchema = z.object({
  name: z
    .string({
      required_error: "Name is required",
      invalid_type_error: "Name is required",
    })
    .min(3, {
      message: "Enter at least 3 characters"
    })
    .max(100, { message: "Name must not exceed 100 characters" }),
  workspace_id: z.number({
    required_error: "Workspace id is required",
    invalid_type_error: "Workspace id is invalid",
  }),
  tier_1: z
    .array(z.any({ required_error: "Tier 0 is required", invalid_type_error: "Tier 0 is invalid" }))
    .nonempty({ message: "Tier 0 is required" }),
  tier_2: z
    .array(z.any({ required_error: "Tier 1 is required", invalid_type_error: "Tier 1 is invalid" }))
    .nonempty({ message: "Tier 1 is required" }),
  tier_3: z
    .array(z.any({ required_error: "Tier 2 is required", invalid_type_error: "Tier 2 is invalid" }))
    .nonempty({ message: "Tier 2 is required" }),
  tier_4: z
    .array(z.any({ required_error: "Tier 3 is required", invalid_type_error: "Tier 3 is invalid" }))
    .nonempty({ message: "Tier 3 is required" }),
  tier_5: z
    .array(z.any({ required_error: "Tier 4 is required", invalid_type_error: "Tier 4 is invalid" }))
    .nonempty({ message: "Tier 4 is required" }),
  // subtiers: z
  //   .array(
  //     z.number({
  //       required_error: "Subtier is required",
  //       invalid_type_error: "Subtier is invalid",
  //     }),
  //   )
  //   .optional(),
});
