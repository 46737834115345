import React from "react";

const LeftArrowSlider = React.forwardRef(
  (
    { width = "36", height = "36", viewBox = "0 0 36 36", fill = "none", className, ...props },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.1084 23.4419C19.8644 23.686 19.4686 23.686 19.2246 23.4419L14.2246 18.4419C13.9805 18.1979 13.9805 17.8021 14.2246 17.5581L19.2246 12.5581C19.4686 12.314 19.8644 12.314 20.1084 12.5581C20.3525 12.8021 20.3525 13.1979 20.1084 13.4419L15.5504 18L20.1084 22.5581C20.3525 22.8021 20.3525 23.1979 20.1084 23.4419Z"
          fill="#475467"
          stroke="#475467"
          strokeWidth="0.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  },
);

export default LeftArrowSlider;
