import { FilterOption, TickIcon, XCloseIcon } from "assests";
import { Button } from "components/UI/Button";
import CustomDropdownCard from "components/UI/CustomDropdownCard";
import TransparencySlider from "components/UI/TransparencySlider";
import { useEffect, useRef, useState } from "react";
import { RxCross2 } from "react-icons/rx";
import { useSelector } from "react-redux";
import { filterNonEmptyKeys } from "utils/helper";
const RetailerFilters = ({ activeFilters, setActiveFilters, setFilteredData, retailerOptions }) => {
  const [isHovered, setIshovered] = useState(false);
  const { user, currentWorkspace } = useSelector((state) => state.authenticationSlice);
  const filterOptions = [
    "partner_name",
    "type",
    "status",
    "active_orders",
    "transparency",
    "verification",
    "street",
    "city",
    "country",
  ];
  const statusOptions = [
    {
      label: "Active",
      value: "accepted",
    },
    {
      label: "Pending",
      value: "pending",
    },
  ];
  const typeOptions = [
    {
      label: "Retailer",
      value: "retailer",
    },
    {
      label: "Importer",
      value: "importer",
    },
    {
      label: "Brand",
      value: "brand",
    },
  ];
  const filterRef = useRef(null);
  const childfilterRef = useRef(null);
  const [transparencyRange, setTransparencyRange] = useState([0, 100]);
  const [transparencyScoreRange, setTransparencyScoreRange] = useState([0, 100]);
  const [varificationRange, setVarificationRange] = useState([0, 100]);
  const [hoveredId, setHoveredId] = useState({});
  const [mainDropdownVisible, setMainDropdownVisible] = useState(false);
  const [currentFilterDropdown, setCurrentFilterDropdown] = useState(null);
  const [uniqueCountries, setUniqueCountries] = useState([]);
  const [uniqueCities, setUniqueCities] = useState([]);
  const [uniqueStreets, setUniqueStreets] = useState([]);
  // Toggle Main Dropdown
  const toggleMainDropdown = () => {
    setMainDropdownVisible(!mainDropdownVisible);
    setCurrentFilterDropdown(null);
  };

  // Add Filter
  const addFilter = (filterName) => {
    if (!activeFilters[filterName]) {
      setActiveFilters((prev) => ({
        ...prev,
        [filterName]: filterName === "Active Orders" ? transparencyRange : [],
      }));
    }
  };

  // Remove Filter
  const removeFilter = (filterName) => {
    setActiveFilters((prev) => {
      const updatedFilters = { ...prev };
      delete updatedFilters[filterName];
      return updatedFilters;
    });
    setCurrentFilterDropdown(null);
    if (filterName === "active_orders") {
      setTransparencyRange([0, 100]);
    }
    if (filterName === "transparency") {
      setTransparencyScoreRange([0, 100]);
    }
    if (filterName === "verification") {
      setVarificationRange([0, 100]);
    }
  };

  const addFilterValue = (filterName, value, label) => {
    setActiveFilters((prev) => {
      const updatedValues = prev[filterName] || [];
      if (!updatedValues.some((item) => item.value === value)) {
        updatedValues.push({ value, label });
      }
      // Return the updated filters
      return { ...prev, [filterName]: updatedValues };
    });
  };

  const removeFilterValue = (filterName, value) => {
    setActiveFilters((prev) => {
      const updatedValues = prev[filterName]?.filter((item) => item.value !== value);
      if (!updatedValues || updatedValues.length === 0) {
        const { [filterName]: _, ...rest } = prev; // Destructure to exclude the filterName
        return rest;
      }
      return { ...prev, [filterName]: updatedValues };
    });
  };

  // Handle Transparency Slider Change
  const handleSliderChange = (range) => {
    setTransparencyRange(range);
    setActiveFilters((prev) => ({
      ...prev,
      active_orders: range,
    }));
  };
  const handleTranspercencyScoreSliderChange = (range) => {
    setTransparencyScoreRange(range);
    setActiveFilters((prev) => ({
      ...prev,
      transparency: range,
    }));
  };

  const handleVarificationScoreSliderChange = (range) => {
    setVarificationRange(range);
    setActiveFilters((prev) => ({
      ...prev,
      verification: range,
    }));
  };

  useEffect(() => {
    if (activeFilters) {
      const transformedData = {
        filter_by_active_orders: activeFilters.active_orders,
        transparency_score: activeFilters.transparency,
        verification_score: activeFilters.verification,
        filter_by_status: activeFilters.status,
        filter_by_company_type: activeFilters.type,
        filter_by_company_name: activeFilters.partner_name,
        filter_by_street: activeFilters.street,
        filter_by_city: activeFilters.city,
        filter_by_country: activeFilters.country,
      };
      const updatedFilteredData = filterNonEmptyKeys(transformedData);
      setFilteredData(updatedFilteredData);
    }
  }, [activeFilters]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (filterRef.current && !filterRef.current.contains(event.target)) {
        setMainDropdownVisible(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (childfilterRef.current && !childfilterRef.current.contains(event.target)) {
        setCurrentFilterDropdown(null);
      }
    };
    document.addEventListener("click", handleClickOutside);
    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [currentFilterDropdown]);

  const processUniqueData = () => {
    const acceptedOptions = retailerOptions.filter(
      item => item.status === "accepted"
    );
    // Extract unique countries
    const countries = [...new Set(
      acceptedOptions
        .map(item => item.country)
        .filter(country => country && country.trim() !== "")
    )];
    setUniqueCountries(countries);
    // Extract unique cities
    const cities = [...new Set(
      acceptedOptions
        .map(item => item.region)
        .filter(region => region && region.trim() !== "")
    )];
    setUniqueCities(cities);
    // Extract unique streets
    const streets = [...new Set(
      acceptedOptions
        .map(item => item.street)
        .filter(street => street && street.trim() !== "")
    )];
    setUniqueStreets(streets);
  };

  useEffect(() => {
    if (retailerOptions && retailerOptions.length > 0) {
      processUniqueData();
    }
  }, [retailerOptions]);

  useEffect(() => {
    if (user && currentWorkspace) {
      const handleKeyDown = (event) => {
        // Check if 'Ctrl + R' is pressed

        if ((event.ctrlKey || event.metaKey) && event.shiftKey && (event.key === "Y" || event.key === "y")) {
          event.preventDefault();
          setMainDropdownVisible(true);
        }
      };
      window.addEventListener("keydown", handleKeyDown);
      return () => {
        window.removeEventListener("keydown", handleKeyDown);
      };
    }
  }, [user, currentWorkspace]);

  return (
    <div className={`flex flex-col md:flex-row md:flex-wrap  w-full ${activeFilters && Object.keys(activeFilters).length > 0 && 'gap-2'}`}>
      <div ref={childfilterRef} className="flex flex-col md:flex-row md:flex-wrap gap-2 order-2 md:order-1">
        {activeFilters &&
          Object.keys(activeFilters).map((filter) => (
            <div key={filter} className="relative">
              <Button
                variant="secondary"
                className="text-txt-secondary px-3.5 py-2.5 justify-between h-10 lg:h-auto  gap-1 truncate w-full md:w-auto text-sm tracking-wide font-normal  flex"
                onClick={() => {
                  setCurrentFilterDropdown(currentFilterDropdown === filter ? null : filter);
                  setMainDropdownVisible(false);
                }}
              >
                <div>
                  <span className="capitalize">
                    {filter && (filter === "transparency" || filter === "verification")
                      ? `${filter} %`
                      : filter?.replace(/_/g, " ")}
                  </span>
                  :
                  {(filter === "date_added" || filter === "last_updated") && activeFilters[filter] ? (
                    <span className="font-semibold">
                      {" "}
                      {activeFilters[filter]?.startDate && activeFilters[filter]?.endDate
                        ? `${activeFilters[filter].startDate} ~ ${activeFilters[filter].endDate}`
                        : null}
                    </span>
                  ) : activeFilters[filter]?.length > 0 ? (
                    <span className="font-semibold">
                      {" "}
                      {activeFilters[filter].length > 2
                        ? "Multiple"
                        : activeFilters[filter]
                          .map((status) =>
                            typeof status === "object" && status.label ? status.label : status,
                          )
                          .join(", ")}
                    </span>
                  ) : null}
                </div>
                <div>
                  <RxCross2
                    size={20}
                    onClick={(e) => {
                      e.stopPropagation();
                      removeFilter(filter);
                    }}
                    className="cursor-pointer"
                  />
                </div>
              </Button>

              {/* Sub-dropdown for Status and partner_name*/}
              {currentFilterDropdown === filter && filter === "partner_name" && (
                <CustomDropdownCard>
                  {retailerOptions && retailerOptions.length > 0 ? (
                    retailerOptions.map((article, index) => {
                      const isSelected = activeFilters[filter]?.some(
                        (item) => item.value === article.name,
                      );
                      return (
                        <div
                          key={index}
                          onClick={(e) => {
                            e.stopPropagation();
                            addFilterValue(filter, article.name, article.name);
                          }}
                          onMouseEnter={() => setHoveredId(article.name)}
                          onMouseLeave={() => setHoveredId(null)}
                          className={`cursor-pointer flex items-center justify-start w-full pl-2 pr-2.5 py-2.5 rounded-md hover:bg-global-white ${isSelected ? "bg-global-white" : ""
                            }`}
                        >
                          <span className="text-base font-medium text-global-title truncate">
                            {article.name}
                          </span>
                          {isSelected && (
                            <span className="ml-auto">
                              {hoveredId === article.name ? (
                                <XCloseIcon
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    removeFilterValue(filter, article.name);
                                  }}
                                  strokeColor={"var(--color-login-text-title)"}
                                  width={20}
                                  height={20}
                                />
                              ) : (
                                <TickIcon width={20} height={20} />
                              )}
                            </span>
                          )}
                        </div>
                      );
                    })
                  ) : (
                    <p className="text-base px-6 py-5 font-normal text-center text-global-gray-brand">
                      No data found
                    </p>
                  )}
                </CustomDropdownCard>
              )}
              {currentFilterDropdown === filter && filter === "status" && (
                <CustomDropdownCard>
                  {statusOptions.map((status, index) => {
                    const isSelected = activeFilters[filter]?.some(
                      (item) => item.value === status.value,
                    );
                    return (
                      <div
                        key={index}
                        onClick={(e) => {
                          e.stopPropagation();

                          addFilterValue(filter, status.value, status.label);
                        }}
                        onMouseEnter={() => setHoveredId(status.value)}
                        onMouseLeave={() => setHoveredId(null)}
                        className={`cursor-pointer flex items-center justify-start w-full pl-2 pr-2.5 py-2.5 rounded-md hover:bg-global-white ${isSelected ? "bg-global-white" : ""
                          }`}
                      >
                        <span className="text-base font-medium text-global-title truncate">
                          {status.label}
                        </span>
                        {isSelected && (
                          <span className="ml-auto">
                            {hoveredId === status.value ? (
                              <XCloseIcon
                                onClick={(e) => {
                                  e.stopPropagation();
                                  removeFilterValue(filter, status.value);
                                }}
                                strokeColor={"var(--color-login-text-title)"}
                                width={20}
                                height={20}
                              />
                            ) : (
                              <TickIcon width={20} height={20} />
                            )}
                          </span>
                        )}
                      </div>
                    );
                  })}
                </CustomDropdownCard>
              )}
              {/* Sub-dropdown for Transparency Score */}
              {currentFilterDropdown === filter && filter === "active_orders" && (
                <CustomDropdownCard className="w-[292px] md:w-[320px]">
                  <TransparencySlider
                    setTransparencyRange={setTransparencyRange}
                    transparencyRange={transparencyRange}
                    onChange={handleSliderChange}
                    maxValue={100}
                    minValue={0}
                  />
                </CustomDropdownCard>
              )}
              {currentFilterDropdown === filter && filter === "transparency" && (
                <CustomDropdownCard className="w-[292px] md:w-[320px]">
                  <TransparencySlider
                    setTransparencyRange={setTransparencyScoreRange}
                    transparencyRange={transparencyScoreRange}
                    onChange={handleTranspercencyScoreSliderChange}
                    maxValue={100}
                    minValue={0}
                  />
                </CustomDropdownCard>
              )}
              {currentFilterDropdown === filter && filter === "verification" && (
                <CustomDropdownCard className="w-[292px] md:w-[320px]">
                  <TransparencySlider
                    setTransparencyRange={setVarificationRange}
                    transparencyRange={varificationRange}
                    onChange={handleVarificationScoreSliderChange}
                    maxValue={100}
                    minValue={0}
                  />
                </CustomDropdownCard>
              )}
              {currentFilterDropdown === filter && filter === "type" && (
                <CustomDropdownCard>
                  {typeOptions && typeOptions.length > 0 ? (
                    typeOptions.map((status, index) => {
                      const isSelected = activeFilters[filter]?.some(
                        (item) => item.value === status.value,
                      );
                      return (
                        <div
                          key={index}
                          onClick={(e) => {
                            e.stopPropagation();
                            addFilterValue(filter, status.value, status.label);
                          }}
                          onMouseEnter={() => setHoveredId(status.value)}
                          onMouseLeave={() => setHoveredId(null)}
                          className={`cursor-pointer flex items-center justify-start w-full pl-2 pr-2.5 py-2.5 rounded-md hover:bg-global-white ${isSelected ? "bg-global-white" : ""
                            }`}
                        >
                          <span className="text-base font-medium text-global-title truncate">
                            {status.label}
                          </span>
                          {isSelected && (
                            <span className="ml-auto">
                              {hoveredId === status.value ? (
                                <XCloseIcon
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    removeFilterValue(filter, status.value);
                                  }}
                                  strokeColor={"var(--color-login-text-title)"}
                                  width={20}
                                  height={20}
                                />
                              ) : (
                                <TickIcon width={20} height={20} />
                              )}
                            </span>
                          )}
                        </div>
                      );
                    })
                  ) : (
                    <p className="text-base px-6 py-5 font-normal text-center text-global-gray-brand">
                      No data found
                    </p>
                  )}
                </CustomDropdownCard>
              )}
              {currentFilterDropdown === filter && filter === "street" && (
                <CustomDropdownCard>
                  {uniqueStreets && uniqueStreets.length > 0 ? (
                    uniqueStreets.map((article, index) => {
                      const isSelected = activeFilters[filter]?.some(
                        (item) => item.value === article.street,
                      );
                      return (
                        <div
                          key={index}
                          onClick={(e) => {
                            e.stopPropagation();
                            addFilterValue(filter, article.street, article.street);
                          }}
                          onMouseEnter={() => setHoveredId(article.street)}
                          onMouseLeave={() => setHoveredId(null)}
                          className={`cursor-pointer flex items-center justify-start w-full pl-2 pr-2.5 py-2.5 rounded-md hover:bg-global-white ${isSelected ? "bg-global-white" : ""
                            }`}
                        >
                          <span className="text-base font-medium text-global-title truncate">
                            {article.street}
                          </span>
                          {isSelected && (
                            <span className="ml-auto">
                              {hoveredId === article.street ? (
                                <XCloseIcon
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    removeFilterValue(filter, article.street);
                                  }}
                                  strokeColor={"var(--color-login-text-title)"}
                                  width={20}
                                  height={20}
                                />
                              ) : (
                                <TickIcon width={20} height={20} />
                              )}
                            </span>
                          )}
                        </div>
                      );
                    })
                  ) : (
                    <p className="text-base px-6 py-5 font-normal text-center text-global-gray-brand">
                      No data found
                    </p>
                  )}
                </CustomDropdownCard>
              )}
              {currentFilterDropdown === filter && filter === "city" && (
                <CustomDropdownCard>
                  {uniqueCities && uniqueCities.length > 0 ? (
                    uniqueCities.map((article, index) => {
                      const isSelected = activeFilters[filter]?.some(
                        (item) => item.value === article,
                      );
                      return (
                        <div
                          key={index}
                          onClick={(e) => {
                            e.stopPropagation();
                            addFilterValue(filter, article, article);
                          }}
                          onMouseEnter={() => setHoveredId(article)}
                          onMouseLeave={() => setHoveredId(null)}
                          className={`cursor-pointer flex items-center justify-start w-full pl-2 pr-2.5 py-2.5 rounded-md hover:bg-global-white ${isSelected ? "bg-global-white" : ""
                            }`}
                        >
                          <span className="text-base font-medium text-global-title truncate">
                            {article}
                          </span>
                          {isSelected && (
                            <span className="ml-auto">
                              {hoveredId === article ? (
                                <XCloseIcon
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    removeFilterValue(filter, article);
                                  }}
                                  strokeColor={"var(--color-login-text-title)"}
                                  width={20}
                                  height={20}
                                />
                              ) : (
                                <TickIcon width={20} height={20} />
                              )}
                            </span>
                          )}
                        </div>
                      );
                    })
                  ) : (
                    <p className="text-base px-6 py-5 font-normal text-center text-global-gray-brand">
                      No data found
                    </p>
                  )}
                </CustomDropdownCard>
              )}
              {currentFilterDropdown === filter && filter === "country" && (
                <CustomDropdownCard>
                  {uniqueCountries && uniqueCountries.length > 0 ? (
                    uniqueCountries.map((article, index) => {
                      const isSelected = activeFilters[filter]?.some(
                        (item) => item.value === article,
                      );
                      return (
                        <div
                          key={index}
                          onClick={(e) => {
                            e.stopPropagation();
                            addFilterValue(filter, article, article);
                          }}
                          onMouseEnter={() => setHoveredId(article)}
                          onMouseLeave={() => setHoveredId(null)}
                          className={`cursor-pointer flex items-center justify-start w-full pl-2 pr-2.5 py-2.5 rounded-md hover:bg-global-white ${isSelected ? "bg-global-white" : ""
                            }`}
                        >
                          <span className="text-base font-medium text-global-title truncate">
                            {article}
                          </span>
                          {isSelected && (
                            <span className="ml-auto">
                              {hoveredId === article ? (
                                <XCloseIcon
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    removeFilterValue(filter, article);
                                  }}
                                  strokeColor={"var(--color-login-text-title)"}
                                  width={20}
                                  height={20}
                                />
                              ) : (
                                <TickIcon width={20} height={20} />
                              )}
                            </span>
                          )}
                        </div>
                      );
                    })
                  ) : (
                    <p className="text-base px-6 py-5 font-normal text-center text-global-gray-brand">
                      No data found
                    </p>
                  )}
                </CustomDropdownCard>
              )}
            </div>
          ))}
      </div>
      <div className="order-1 md:order-2">
        <div ref={filterRef} className="w-full md:w-auto">
          <Button
            variant="secondary"
            className="text-txt-secondary px-[15.08px] py-2.5 w-full md:w-auto gap-1.5 text-sm  tracking-normal font-semibold flex h-10 lg:h-auto"
            onMouseEnter={() => setIshovered(true)}
            onMouseLeave={() => setIshovered(false)}
            onClick={toggleMainDropdown}
          >
            <FilterOption
              width={20}
              height={20}
              strokeColor={
                isHovered ? "var(--color-icon-fill-secondary-button)" : "var(--color-txt-secondary)"
              }
            />
            {Object.keys(activeFilters).length > 0 ? (
              "More filters"
            ) : (
              <span className="tracking-[0.01em]">Filters</span>
            )}
          </Button>

          {mainDropdownVisible && (
            <CustomDropdownCard>
              {filterOptions.map((filter, index) => {
                const isSelected = activeFilters[filter];
                return (
                  <div
                    key={index}
                    onClick={() => addFilter(filter)}
                    onMouseEnter={() => setHoveredId(filter)}
                    onMouseLeave={() => setHoveredId(null)}
                    className={`cursor-pointer flex gap-2 flex-wrap items-center justify-start w-full pl-2 pr-2.5 py-2.5 rounded-md hover:bg-global-white ${isSelected ? "bg-global-white" : ""
                      }`}
                  >
                    <span className="text-base font-medium text-global-title truncate capitalize">
                      <span className="capitalize">
                        {filter && (filter === "transparency" || filter === "verification")
                          ? `${filter} %`
                          : filter?.replace(/_/g, " ")}
                      </span>
                    </span>
                    {isSelected && (
                      <span className="ml-auto">
                        {hoveredId === filter ? (
                          <XCloseIcon
                            onClick={(e) => {
                              e.stopPropagation();
                              removeFilter(filter);
                            }}
                            strokeColor={"var(--color-login-text-title)"}
                            width={20}
                            height={20}
                          />
                        ) : (
                          <TickIcon width={20} height={20} />
                        )}
                      </span>
                    )}
                  </div>
                );
              })}
            </CustomDropdownCard>
          )}
        </div>
      </div>
    </div>
  );
};

export default RetailerFilters;
