import React from "react";

const ResetPassword = React.forwardRef(
  (
    { width = "60", height = "60", viewBox = "0 0 60 60", fill = "none", className, ...props },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_dii_17_2539)">
          <path
            d="M2 13C2 6.37258 7.37258 1 14 1H46C52.6274 1 58 6.37258 58 13V45C58 51.6274 52.6274 57 46 57H14C7.37258 57 2 51.6274 2 45V13Z"
            fill="white"
          />
          <path
            d="M2.5 13C2.5 6.64873 7.64873 1.5 14 1.5H46C52.3513 1.5 57.5 6.64873 57.5 13V45C57.5 51.3513 52.3513 56.5 46 56.5H14C7.64873 56.5 2.5 51.3513 2.5 45V13Z"
            stroke="#E4E7EC"
          />
          <path
            d="M35.8334 26.6667V24.3333C35.8334 21.1117 33.2217 18.5 30 18.5C26.7784 18.5 24.1667 21.1117 24.1667 24.3333V26.6667M30 31.9167V34.25M26.2667 39.5H33.7334C35.6935 39.5 36.6736 39.5 37.4223 39.1185C38.0809 38.783 38.6163 38.2475 38.9519 37.589C39.3334 36.8403 39.3334 35.8602 39.3334 33.9V32.2667C39.3334 30.3065 39.3334 29.3264 38.9519 28.5777C38.6163 27.9191 38.0809 27.3837 37.4223 27.0481C36.6736 26.6667 35.6935 26.6667 33.7334 26.6667H26.2667C24.3065 26.6667 23.3264 26.6667 22.5777 27.0481C21.9192 27.3837 21.3837 27.9191 21.0482 28.5777C20.6667 29.3264 20.6667 30.3065 20.6667 32.2667V33.9C20.6667 35.8602 20.6667 36.8403 21.0482 37.589C21.3837 38.2475 21.9192 38.783 22.5777 39.1185C23.3264 39.5 24.3065 39.5 26.2667 39.5Z"
            stroke="#344054"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <filter
            id="filter0_dii_17_2539"
            x="0"
            y="0"
            width="60"
            height="60"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="1" />
            <feGaussianBlur stdDeviation="1" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.05 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_17_2539" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_17_2539"
              result="shape"
            />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="-2" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.05 0"
            />
            <feBlend mode="normal" in2="shape" result="effect2_innerShadow_17_2539" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feMorphology
              radius="1"
              operator="erode"
              in="SourceAlpha"
              result="effect3_innerShadow_17_2539"
            />
            <feOffset />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.18 0"
            />
            <feBlend
              mode="normal"
              in2="effect2_innerShadow_17_2539"
              result="effect3_innerShadow_17_2539"
            />
          </filter>
        </defs>
      </svg>
    );
  },
);

export default ResetPassword;
