import React from 'react'

const BoxCardIcon = React.forwardRef(
  (
    {
      width = "29",
      height = "30",
      viewBox = "0 0 29 30",
      fill = "none",
     
      className,
      ...props
    },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect y="0.389404" width="28.8053" height="28.8053" rx="12" fill="white" />
        <path
          d="M14.4071 7.38501C10.3178 7.38501 7 10.7028 7 14.7921C7 18.8814 10.3178 22.1992 14.4071 22.1992C18.4964 22.1992 21.8142 18.8814 21.8142 14.7921C21.8142 10.7028 18.4964 7.38501 14.4071 7.38501ZM14.1756 19.1129C14.023 19.1129 13.8738 19.0676 13.7469 18.9828C13.6201 18.8981 13.5212 18.7776 13.4628 18.6366C13.4044 18.4956 13.3891 18.3405 13.4189 18.1908C13.4486 18.0411 13.5221 17.9036 13.63 17.7957C13.7379 17.6878 13.8754 17.6143 14.0251 17.5846C14.1748 17.5548 14.3299 17.5701 14.4709 17.6285C14.6119 17.6869 14.7324 17.7858 14.8171 17.9127C14.9019 18.0395 14.9472 18.1887 14.9472 18.3413C14.9472 18.5459 14.8659 18.7422 14.7212 18.8869C14.5765 19.0316 14.3802 19.1129 14.1756 19.1129ZM15.4657 15.1779C14.8403 15.5976 14.7543 15.9822 14.7543 16.3352C14.7543 16.4785 14.6974 16.6158 14.5961 16.7171C14.4948 16.8184 14.3574 16.8753 14.2142 16.8753C14.0709 16.8753 13.9336 16.8184 13.8323 16.7171C13.731 16.6158 13.6741 16.4785 13.6741 16.3352C13.6741 15.49 14.063 14.8179 14.8631 14.2805C15.6069 13.7813 16.0274 13.465 16.0274 12.7694C16.0274 12.2964 15.7573 11.9373 15.1983 11.6715C15.0668 11.609 14.774 11.548 14.4136 11.5523C13.9615 11.558 13.6104 11.6661 13.34 11.8837C12.83 12.2941 12.7868 12.7409 12.7868 12.7474C12.7834 12.8184 12.766 12.8879 12.7357 12.9521C12.7054 13.0163 12.6627 13.074 12.6102 13.1217C12.5576 13.1694 12.4961 13.2064 12.4293 13.2303C12.3625 13.2543 12.2916 13.2649 12.2206 13.2615C12.1497 13.2581 12.0802 13.2407 12.0159 13.2104C11.9517 13.1801 11.8941 13.1375 11.8464 13.0849C11.7986 13.0323 11.7617 12.9708 11.7377 12.904C11.7137 12.8372 11.7032 12.7663 11.7066 12.6953C11.7108 12.6016 11.776 11.7571 12.6622 11.0442C13.1216 10.6746 13.7061 10.4825 14.3982 10.474C14.8882 10.4682 15.3484 10.5511 15.6605 10.6985C16.5945 11.1402 17.1076 11.8767 17.1076 12.7694C17.1076 14.0745 16.2353 14.6605 15.4657 15.1779Z"
          fill="#11C182"
        />
      </svg>
    );
  },
);

BoxCardIcon.displayName = "BoxCardIcon";

export default BoxCardIcon
