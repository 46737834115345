import { CheckCircleFill, InfoIcon } from "assests";
import DynamicSVG from "components/UI/DynamicSVG";
import { LineProgressBar } from "components/UI/LineProgressBar";
import Tooltip from "components/UI/Tooltip";
import React from "react";

const SubscriptionRow = ({ label, value, limit, progressVariant = "green" }) => {
  /**
   * Calculates the percentage based on the given part and whole numbers.
   *
   * @param {number} part - The part of the whole value.
   * @param {number} whole - The total whole value.
   * @return {string} The calculated percentage rounded to two decimal places.
   */
  const calculatePercentage = (part, whole) => {
    // Check for invalid inputs (non-numeric or zero whole)
    if (isNaN(part) || isNaN(whole) || whole === 0) {
      return "Invalid input: Please provide valid numbers and ensure the whole is not zero.";
    }

    // Calculate percentage
    const percentage = (part / whole) * 100;

    // Round to two decimal places (optional, adjust as needed)
    return percentage.toFixed(2);
  };

  // Calculated percentage
  const progress = calculatePercentage(value, limit);

  return (
    <div
      className={` w-full grid grid-cols-12 gap-[11px] items-center text-global-gray-brand`}
    >
      <div className="col-span-12">
        <p className="text-sm font-medium text-global-title">{value} of {limit} orderlines used</p>
      </div>
      <div className="col-span-12">
        <LineProgressBar className="h-[8px]" variant={progressVariant} progress={progress} />
      </div>
    </div>
  );
};
export default SubscriptionRow;
