import { IconButton, Menu } from "@mui/material";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { CertificateIcon, MoreOptions, PDFIcon, PlanIcon, Shock, TrashUpdateIcon } from "assests";
import EmptyTable from "components/Card/EmptyTable";
import DeleteButton from "components/MoreAction/DeleteButton";
import EditButton from "components/MoreAction/EditButton";
import MoreActionOptionUpdated from "components/MoreAction/MoreActionOptionUpdated";
import ViewButton from "components/MoreAction/ViewButton";
import SearchCreatePanelUpdate from "components/SearchCreatePanelUpdate";
import BreadCrumb from "components/TopHeader/BreadCrumb";
import TopNavbar from "components/TopHeader/TopNavbar";
import { Button } from "components/UI/Button";
import CommonDiscardButtonUpdated from "components/UI/CommonDiscardButtonUpdated";
import LoadingComponent from "components/UI/LoadingComponent";
import Modal from "components/UI/Modal";
import SortIcons from "components/UI/SortIcons";
import TertiaryPagination from "components/UI/TertiaryPagination";
import useCustomToaster from "hooks/useCustomToaster";
import usePermission from "hooks/usePermission";
import SubNavbar from "layout/SubNavbar";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  bulkDeleteWorkspaceCertificate,
  deleteWorkspaceCertificate,
  downloadCertificates,
  getCertificates,
} from "services/certificate.services";
import { setRefetchBillingData } from "store/billingSlice";
import {
  setworkspaceAllCertificateData,
  setWorkspaceCertificateList,
  toggleActionableWrokspaceCertificateId,
  toggleDeleteWorkspacecertificateModal,
  toggleisCertificateEditable,
  toggleisWrokspaceCertificateCreateModalOpen,
} from "store/certificateSlice";
import { formatDateToDayMonthYear, getCertificateProgress, truncateString } from "utils/helper";
import CertificateFilters from "./CertificateFilters";
import CreateCertificate from "./CreateCertificate";
const CertificatesMain = () => {
  const [selectedOrders, setSelectedOrders] = useState([]);
  const { showCustomToast } = useCustomToaster();
  const { hasPermission } = usePermission();
  const {
    isCertificateEditable,
    workspaceCertificateList,
    workspaceAllCertificateData,
    deleteWorkspaceModal,
    actionableWrokspaceCertificateId,
    isworkspaceCertificateCreateModalOpen,
  } = useSelector((state) => state.certificateSlice);
  const { user, currentWorkspace, subscription, subscription_status } = useSelector(
    (state) => state.authenticationSlice,
  );
  const supplierId = currentWorkspace?.workspace_id && parseInt(currentWorkspace?.workspace_id);
  const [anchorEl, setAnchorEl] = useState(null);
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const open = Boolean(anchorEl);
  const { refetchBillingData, workspacePlan } = useSelector((state) => state.billingSlice);
  const handleClose = () => {
    setAnchorEl(null);
    if (actionableWrokspaceCertificateId) {
      dispatch(toggleActionableWrokspaceCertificateId());
    }
  };
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [orderBy, setOrderBy] = useState("");
  const [enteredSearchKey, setEnteredSearchKey] = useState("");
  const [activeFilters, setActiveFilters] = useState({});
  const [filteredData, setFilteredData] = useState({});
  const [certificateOptions, setCertificateOptions] = useState([]);
  const handleSizeChange = (newSize) => {
    setSize(newSize);
  };

  const {
    data: certificatesQueryData,
    isLoading: iscertificatesQuryLoading,
    refetch,
  } = useQuery({
    queryKey: [
      "certificates",
      "getCertificates",
      supplierId,
      page,
      size,
      orderBy,
      enteredSearchKey,
      filteredData,
    ],
    queryFn: () =>
      getCertificates({
        page,
        per_page: size,
        sort: orderBy,
        search: enteredSearchKey,
        workspace_id: parseInt(supplierId),
        type: "partner",
        ...filteredData,
      }),
    enabled: !!user && !!currentWorkspace && (!!page || !!size) && !!supplierId,
  });

  const {
    data: articlesNopaginatedData,
    isLoading: isArticlesNopaginatedData,
    refetch: refetchNopaginatedData,
  } = useQuery({
    queryKey: ["articlesnopaginate", "getData", supplierId],
    queryFn: () =>
      getCertificates({
        workspace_id: parseInt(supplierId),
        pagination: "no",
        type: "partner",
      }),
    enabled: !!supplierId,
  });

  useEffect(() => {
    if (!isArticlesNopaginatedData && articlesNopaginatedData) {
      if (articlesNopaginatedData?.data?.certificates) {
        setCertificateOptions(articlesNopaginatedData?.data?.certificates);
      }
    }
  }, [articlesNopaginatedData, isArticlesNopaginatedData]);

  const refetchDatas = () => {
    refetch();
    refetchNopaginatedData();
  };

  useEffect(() => {
    if (
      certificatesQueryData &&
      certificatesQueryData.status === 200 &&
      !iscertificatesQuryLoading
    ) {
      const details = certificatesQueryData.data.certificates.data;
      dispatch(setWorkspaceCertificateList({ append: false, workspaceCertificateList: details }));
      dispatch(setworkspaceAllCertificateData(certificatesQueryData?.data?.certificates));
    }
  }, [certificatesQueryData, iscertificatesQuryLoading]);

  const onClickCheckbox = (inviatation) => {
    const findedItem = selectedOrders.some((item) => item.id === inviatation.id);
    if (!findedItem) {
      setSelectedOrders((prevSelectedOrders) => [...prevSelectedOrders, inviatation]);
    } else {
      setSelectedOrders((prevSelectedOrders) =>
        prevSelectedOrders.filter((item) => item.id !== inviatation.id),
      );
    }
  };

  const closeModal = () => {
    if (isworkspaceCertificateCreateModalOpen) {
      dispatch(toggleisWrokspaceCertificateCreateModalOpen());
    }
    if (actionableWrokspaceCertificateId) {
      dispatch(toggleActionableWrokspaceCertificateId());
    }
    if (deleteWorkspaceModal) {
      dispatch(toggleDeleteWorkspacecertificateModal());
    }
    if (isCertificateEditable) {
      dispatch(toggleisCertificateEditable());
    }
    if (showUpgradeModal) {
      setShowUpgradeModal(false);
    }
  };

  const handleSelectAll = (e) => {
    if (!e.target.checked || !workspaceCertificateList.length > 0) {
      setSelectedOrders([]);
      return;
    }
    const invitationIds =
      workspaceCertificateList.length > 0 && workspaceCertificateList.map((inviation) => inviation);
    setSelectedOrders(invitationIds);
  };

  const handleDelete = (ids) => {
    if (actionableWrokspaceCertificateId) {
      deleteUserMutation.mutate(actionableWrokspaceCertificateId);
    } else {
      const allIds = ids.map((item) => item.id);
      if (allIds) {
        deleteAllMutation.mutate({ ids: allIds });
      }
    }
  };
  const openDeleteModal = (e) => {
    e.stopPropagation();
    dispatch(toggleDeleteWorkspacecertificateModal());
  };

  const openSingleModal = (e, item) => {
    e.stopPropagation();
    setAnchorEl(null);
    setSelectedOrders([]);
    dispatch(toggleActionableWrokspaceCertificateId(item.id));
    dispatch(toggleDeleteWorkspacecertificateModal());
  };

  const deleteUserMutation = useMutation({
    mutationKey: ["partnerDelete", "delete"],
    mutationFn: (data) => deleteWorkspaceCertificate(data),
    onMutate: () => {
      showCustomToast(
        {
          title: "Removing...",
        },
        "partnerDelete",
      );
    },
    onSuccess: (res) => {
      toast.dismiss("partnerDelete");
      showCustomToast({
        title: res.data.message || "Removed successfully",
      });
      if (!refetchBillingData) {
        dispatch(setRefetchBillingData(true));
      }
      refetchDatas();
      closeModal();
      queryClient.invalidateQueries(["partnerDelete", "get", "delete"]);
    },
    onError: (error) => {
      toast.dismiss("partnerDelete");
      showCustomToast({
        title: error.response.data.message || error.message,
      });
    },
    onSettled: () => {
      toast.dismiss("partnerDelete");
    },
  });

  const deleteAllMutation = useMutation({
    mutationKey: ["allInvitationsDelete", "deleteAllInviation"],
    mutationFn: (data) => bulkDeleteWorkspaceCertificate(data),
    onMutate: () => {
      showCustomToast(
        {
          title: "Removing...",
        },
        "deleteAllInviation",
      );
    },
    onSuccess: (res) => {
      toast.dismiss("deleteAllInviation");
      showCustomToast({
        title: res.data.message || "Removed successfully",
      });
      refetchDatas();
      if (!refetchBillingData) {
        dispatch(setRefetchBillingData(true));
      }
      setSelectedOrders([]);
      closeModal();
      queryClient.invalidateQueries(["allInvitationsDelete", "get", "delete"]);
    },
    onError: (error) => {
      toast.dismiss("deleteAllInviation");
      showCustomToast({
        title: error.response.data.message || error.message,
      });
    },
    onSettled: () => {
      toast.dismiss("deleteAllInviation");
    },
  });

  const downloadInvitationsAsCSV = useMutation({
    mutationKey: ["certificates", "download"],
    mutationFn: (data) => downloadCertificates(data),
    onMutate: () => {
      showCustomToast(
        {
          title: "Downloading...",
        },
        "downloadCertificates",
      );
    },
    onSuccess: (res) => {
      toast.dismiss("downloadCertificates");
      showCustomToast({
        title: "Downloaded successfully",
      });
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "certificates.csv");
      document.body.appendChild(link);
      link.click();
      link.remove();
      queryClient.invalidateQueries(["certificates", "download"]);
    },
    onError: (error) => {
      toast.dismiss("downloadCertificates");
      showCustomToast({
        title: error.response.data.message || error.message,
      });
    },
  });

  const handleDownloadCSV = () => {
    downloadInvitationsAsCSV.mutate({
      workspace_id: parseInt(currentWorkspace?.workspace_id),
      type: "partner",
    });
  };

  const handleCreateAction = () => {
    if (
      (currentWorkspace &&
        currentWorkspace.is_owner &&
        (!subscription ||
          (subscription && (!subscription_status || subscription_status === "canceled")))) ||
      !workspacePlan
    ) {
      setShowUpgradeModal(true);
    } else {
      dispatch(toggleisWrokspaceCertificateCreateModalOpen());
      dispatch(toggleisCertificateEditable());
      if (actionableWrokspaceCertificateId) {
        dispatch(toggleActionableWrokspaceCertificateId());
      }
      setShowUpgradeModal(false);
    }
  };

  const handleClickOptionButton = (certificateId, actionType) => {
    switch (actionType) {
      case "create":
        handleCreateAction();
        setAnchorEl(null);
        break;
      case "edit":
        dispatch(toggleActionableWrokspaceCertificateId(certificateId));
        dispatch(toggleisCertificateEditable());
        dispatch(toggleisWrokspaceCertificateCreateModalOpen());
        setAnchorEl(null);
        break;
      case "view":
        dispatch(toggleActionableWrokspaceCertificateId(certificateId));
        dispatch(toggleisWrokspaceCertificateCreateModalOpen());
        if (isCertificateEditable) {
          dispatch(toggleisCertificateEditable());
        }
        setAnchorEl(null);
        break;
      default:
        break;
    }
  };

  const handleActionClick = (id) => {
    dispatch(toggleActionableWrokspaceCertificateId(id));
  };

  useEffect(() => {
    if (user && currentWorkspace) {
      const handleKeyDown = (e) => {
        if (
          (e.ctrlKey || e.metaKey) &&
          (e.key === "E" || e.key === "e") &&
          hasPermission("uploadCertificate")
        ) {
          e.preventDefault();
          setAnchorEl(null);
          if (actionableWrokspaceCertificateId) {
            handleClickOptionButton(actionableWrokspaceCertificateId, "edit");
          }
        }
        if ((e.ctrlKey || e.metaKey) && e.shiftKey && e.key.toLowerCase() === "v") {
          if (actionableWrokspaceCertificateId) {
            e.preventDefault();
            setAnchorEl(null);
            handleClickOptionButton(actionableWrokspaceCertificateId, "view");
          }
        }
        if (e.key === "Delete" && hasPermission("uploadCertificate")) {
          e.preventDefault();
          if (actionableWrokspaceCertificateId) {
            setAnchorEl(null);
            dispatch(toggleActionableWrokspaceCertificateId(actionableWrokspaceCertificateId));
            dispatch(toggleDeleteWorkspacecertificateModal());
          } else if (selectedOrders && selectedOrders.length > 0) {
            e.preventDefault();
            dispatch(toggleDeleteWorkspacecertificateModal());
          }
        }
        if (
          (e.ctrlKey || e.metaKey) &&
          (e.key === "B" || e.key === "b") &&
          hasPermission("canAddCertificate")
        ) {
          e.preventDefault();
          setAnchorEl(null);
          handleClickOptionButton(actionableWrokspaceCertificateId, "create");
        }
        if (
          (e.ctrlKey || e.metaKey) &&
          (e.key === "A" || e.key === "a") &&
          hasPermission("uploadCertificate")
        ) {
          e.preventDefault();
          const allChecked =
            workspaceCertificateList?.length > 0 &&
            selectedOrders.length === workspaceCertificateList.length;
          const newSelectAllState = !allChecked;
          const checkboxEvent = { target: { checked: newSelectAllState } };
          handleSelectAll(checkboxEvent);
        }
      };
      window.addEventListener("keydown", handleKeyDown);
      return () => {
        window.removeEventListener("keydown", handleKeyDown);
      };
    }
  }, [
    user,
    currentWorkspace,
    dispatch,
    actionableWrokspaceCertificateId,
    selectedOrders,
    handleSelectAll,
  ]);

  const handleSearch = (e) => {
    const searchText = e.target.value?.trim();
    if (searchText.length >= 2) {
      setEnteredSearchKey(searchText);
    } else {
      setEnteredSearchKey("");
    }
  };

  return (
    <div>
      <TopNavbar isFromDashboard={false}>
        <BreadCrumb text="My Certificates" />
      </TopNavbar>
      <div className="px-4 md:px-8  pt-[64px] md:pt-[108px]">
        <div className="block md:hidden mt-4">
          {" "}
          <BreadCrumb text="My Certificates" />
        </div>
        <div className="pt-6">
          <SubNavbar
            title="My Certificates"
            subtitle="View and manage your supplier certificates here."
          />
        </div>
        <div className="bg-global-divider-medium h-[1px] my-5  hidden md:block" />

        <div className="bg-btn-surface-secondary border border-border-divider-stroke  rounded-xl custom-card w-full mb-6  mt-4 lg:mt-0">
          <SearchCreatePanelUpdate
            createTxt="Add Certificate"
            title="Certificates"
            permissionText="canAddCertificate"
            permissionText2="uploadCertificate"
            handleDownloadCSV={handleDownloadCSV}
            selectedItems={selectedOrders}
            openDeleteModal={openDeleteModal}
            btnText={workspaceAllCertificateData && workspaceAllCertificateData.total}
            onBtnClick={() => handleClickOptionButton(null, "create")}
            onChange={handleSearch}
            classWidth="w-full md:w-[156px] whitespace-nowrap"
          >
            <CertificateFilters
              workspaceId={supplierId}
              {...{
                activeFilters,
                setActiveFilters,
                setFilteredData,
                certificateOptions,
                setCertificateOptions,
              }}
            />
          </SearchCreatePanelUpdate>

          <div className="w-full max-w-screen overflow-x-auto ">
            <table className="w-full border-spacing-0 table-auto rounded-table-custom overflow-x-auto">
              <thead className="text-sm text-left whitespace-nowrap text-global-paragraph">
                <tr className="border-b  border-t border-b-global-divider-soft border-t-global-divider-soft">
                  <th className="pl-6 pr-4 py-[11.5px]">
                    <div className="flex items-center gap-3">
                      <input
                        type="checkbox"
                        className={`rounded-md  w-5 h-5 ${workspaceCertificateList && workspaceCertificateList.length > 0 ? "cursor-pointer" : "cursor-default"}`}
                        onChange={handleSelectAll}
                        checked={
                          workspaceCertificateList &&
                          workspaceCertificateList.length > 0 &&
                          selectedOrders.length === workspaceCertificateList.length
                        }
                      />
                      <div className="flex items-center">
                        <span className="mr-1 text-xs font-medium text-global-paragraph leading-[18px]">
                          Certificate Name
                        </span>
                        <SortIcons
                          hasData={workspaceCertificateList && workspaceCertificateList.length > 0}
                          sortName="name"
                          {...{ orderBy, setOrderBy }}
                        />
                      </div>
                    </div>
                  </th>

                  <th className="relative px-4  py-[11.5px]  ">
                    <div className="flex items-center">
                      <span className="mr-1 text-xs font-medium text-global-paragraph leading-[18px]">
                        Days until Expiry
                      </span>
                      <SortIcons
                        hasData={workspaceCertificateList && workspaceCertificateList.length > 0}
                        sortName="expiry"
                        {...{ orderBy, setOrderBy }}
                      />
                    </div>
                  </th>

                  <th className="relative px-4 py-[11.5px]  ">
                    <div className="flex items-center">
                      <span className="mr-1 text-xs font-medium text-global-paragraph leading-[18px]">
                        Certificate No.
                      </span>
                      <SortIcons
                        hasData={workspaceCertificateList && workspaceCertificateList.length > 0}
                        sortName="certificate_id"
                        {...{ orderBy, setOrderBy }}
                      />
                    </div>
                  </th>

                  <th className="relative px-4 py-[11.5px]  ">
                    <div className="flex items-center">
                      <span className="mr-1 text-xs font-medium text-global-paragraph leading-[18px]">
                        Certificate Type
                      </span>
                      <SortIcons
                        hasData={workspaceCertificateList && workspaceCertificateList.length > 0}
                        sortName="certificate_type"
                        {...{ orderBy, setOrderBy }}
                      />
                    </div>
                  </th>
                  <th className="relative px-4 py-[11.5px]  ">
                    <div className="flex items-center">
                      <span className="mr-1 text-xs font-medium text-global-paragraph leading-[18px]">
                        Partner Name
                      </span>
                      <SortIcons
                        hasData={workspaceCertificateList && workspaceCertificateList.length > 0}
                        sortName="partner_name"
                        {...{ orderBy, setOrderBy }}
                      />
                    </div>
                  </th>
                  <th className="relative px-4 py-[11.5px]  ">
                    <div className="flex items-center">
                      <span className="mr-1 text-xs font-medium text-global-paragraph leading-[18px]">
                        Issue Date
                      </span>
                      <SortIcons
                        hasData={workspaceCertificateList && workspaceCertificateList.length > 0}
                        sortName="issued_at"
                        {...{ orderBy, setOrderBy }}
                      />
                    </div>
                  </th>
                  <th className="relative px-4 py-[11.5px]  ">
                    <div className="flex items-center">
                      <span className="mr-1 text-xs font-medium text-global-paragraph leading-[18px]">
                        Expiry Date
                      </span>
                      <SortIcons
                        hasData={workspaceCertificateList && workspaceCertificateList.length > 0}
                        sortName="expired_at"
                        {...{ orderBy, setOrderBy }}
                      />
                    </div>
                  </th>

                  <th className="relative px-4 py-[11.5px]  ">
                    <div className="flex items-center">
                      <span className="mr-1 text-xs font-medium text-global-paragraph leading-[18px]">
                        Certified By
                      </span>
                      <SortIcons
                        hasData={workspaceCertificateList && workspaceCertificateList.length > 0}
                        sortName="certified_by"
                        {...{ orderBy, setOrderBy }}
                      />
                    </div>
                  </th>
                  <th className="relative px-4 py-[11.5px]  ">
                    <div className="flex items-center">
                      <span className="mr-1"> </span>
                    </div>
                  </th>
                </tr>
              </thead>

              <tbody>
                {!iscertificatesQuryLoading &&
                workspaceCertificateList &&
                workspaceCertificateList?.length > 0 ? (
                  workspaceCertificateList?.map((item, index) => (
                    <tr
                      key={index}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleClickOptionButton(item.id, "view");
                      }}
                      className={`whitespace-nowrap border-b last:border-0 border-b-global-divider-soft  ${index % 2 === 0 ? "bg-global-white" : "bg-white"} rounded-lg text-global-paragraph text-sm font-normal cursor-pointer`}
                    >
                      <td className="pl-6 pr-4 py-[15.5px]  ">
                        <div className="flex items-center gap-3">
                          <input
                            onChange={() => onClickCheckbox(item)}
                            onClick={(e) => e.stopPropagation()}
                            checked={selectedOrders.some((list) => list.id === item?.id)}
                            type="checkbox"
                            className="rounded-md border cursor-pointer w-5 h-5  "
                          />
                          <div className="flex justify-start items-center gap-3">
                            <div className="flex justify-start items-center text-table-text-data-dark font-medium text-sm gap-3">
                              <PDFIcon fillColor={"var(--color-global-primary)"} />
                            </div>

                            <div className="flex flex-col">
                              <p className="text-sm leading-5 font-medium text-global-title">
                                {item?.certificate_type?.name}
                              </p>
                              <p className="text-sm text-global-paragraph leading-5">
                                {item?.workspace?.name}
                              </p>
                            </div>
                          </div>
                        </div>
                      </td>

                      <td className=" px-4 py-[15.5px]  ">
                        {item.issued_at && item.expired_at ? (
                          <Button
                            variant={"status"}
                            className={
                              " flex justify-center items-center gap-1 px-1.5 py-[2px]  h-[22px] text-global-gray-brand font-medium text-xs leading-[18px]  border border-border-divider-stroke custom-card cursor-default "
                            }
                          >
                            <div
                              className={`w-1.5 h-1.5 rounded-full ${item && getCertificateProgress(item.issued_at, item.expired_at)?.progressThemeColor}`}
                            />
                            {item &&
                              getCertificateProgress(item.issued_at, item.expired_at)
                                .remainingDays}{" "}
                            days
                          </Button>
                        ) : null}
                      </td>
                      <td className="relative px-4 py-[15.5px] text-sm text-global-paragraph leading-5  ">
                        {item?.certificate_id ? truncateString(item?.certificate_id) : null}
                      </td>
                      <td className="relative px-4 py-[15.5px] ">
                        {item?.certificate_type ? (
                          <Button
                            variant={"status"}
                            className={`rounded-[6px] w-auto h-[22px] px-2 py-[2px]  border border-border-divider-stroke custom-card cursor-default capitalize   text-status-text-default  text-xs leading-[18px] font-medium `}
                          >
                            {item?.certificate_type?.name}
                          </Button>
                        ) : null}
                      </td>

                      <td className="relative px-4 py-[15.5px] text-sm text-global-paragraph leading-5  ">
                        {item?.workspace?.name ? truncateString(item?.workspace?.name) : null}
                      </td>

                      <td className="relative px-4 py-[15.5px] text-sm text-global-paragraph leading-5  ">
                        {item?.issued_at ? formatDateToDayMonthYear(item?.issued_at) : null}
                      </td>
                      <td className="relative px-4 py-[15.5px] text-sm text-global-paragraph leading-5  ">
                        {item?.expired_at ? formatDateToDayMonthYear(item?.expired_at) : null}
                      </td>
                      <td className="relative px-4 py-[15.5px] text-sm text-global-paragraph leading-5  ">
                        {item?.certified_by ? truncateString(`${item?.certified_by?.name}`) : null}
                      </td>
                      <td
                        onClick={(e) => e.stopPropagation()}
                        className="relative cursor-pointer px-4 py-[15.5px] text-sm text-global-paragraph leading-5  "
                      >
                        <IconButton
                          aria-label="more"
                          id={`${index}`}
                          aria-controls={open ? "long-menu" : undefined}
                          aria-expanded={open ? "true" : undefined}
                          aria-haspopup="true"
                          onClick={(e) => {
                            handleActionClick(item.id);
                            setAnchorEl(e.currentTarget);
                            e.stopPropagation();
                          }}
                        >
                          <MoreOptions width={20} height={20} />
                        </IconButton>
                        {!isworkspaceCertificateCreateModalOpen &&
                          actionableWrokspaceCertificateId &&
                          actionableWrokspaceCertificateId === item?.id && (
                            <Menu
                              id="long-menu"
                              MenuListProps={{
                                "aria-labelledby": "long-button",
                              }}
                              anchorEl={anchorEl}
                              open={open}
                              onClose={handleClose}
                              anchorOrigin={{
                                vertical: "top",
                                horizontal: "left",
                              }}
                              elevation={0}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                              }}
                              PaperProps={{
                                style: {
                                  maxHeight: 45 * 4.5,
                                  borderRadius: "8px",
                                  border: "1px solid var(--color-border-secondary)",
                                  padding: 0,
                                  backgroundColor: "transparent",
                                  boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.05)",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  alignContent: "center",
                                },
                              }}
                            >
                              <MoreActionOptionUpdated
                                viewButton={
                                  <ViewButton
                                    title="View"
                                    isNotPartner={true}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleClickOptionButton(item.id, "view");
                                    }}
                                    shortcut="⌘+Shift+V"
                                    className={"w-[236px]"}
                                  />
                                }
                                editButton={
                                  hasPermission("uploadCertificate") && (
                                    <EditButton
                                      title="Edit"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleClickOptionButton(item.id, "edit");
                                      }}
                                      shortcut="⌘+E"
                                      className={"w-[236px]"}
                                    />
                                  )
                                }
                                deleteButton={
                                  hasPermission("uploadCertificate") && (
                                    <DeleteButton
                                      title="Delete"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        openSingleModal(e, item);
                                      }}
                                      shortcut="⌘+Delete"
                                      className={"w-[236px]"}
                                    />
                                  )
                                }
                              />
                            </Menu>
                          )}
                      </td>
                    </tr>
                  ))
                ) : iscertificatesQuryLoading ? (
                  <tr>
                    <td colSpan={11} className="text-center pb-3">
                      <LoadingComponent />
                    </td>
                  </tr>
                ) : enteredSearchKey &&
                  workspaceCertificateList &&
                  workspaceCertificateList?.length === 0 ? (
                  <EmptyTable colSpan={11} text="Result not found" />
                ) : (
                  <EmptyTable colSpan={11} />
                )}
              </tbody>
            </table>
          </div>
          {workspaceAllCertificateData &&
            workspaceAllCertificateData.data &&
            workspaceAllCertificateData.total > 0 && (
              <TertiaryPagination
                page={page}
                totalPages={Math.ceil(workspaceAllCertificateData.total / size)}
                setPage={setPage}
                size={size}
                handleSizeChange={handleSizeChange}
              />
            )}
        </div>
      </div>
      <Modal open={isworkspaceCertificateCreateModalOpen} close={closeModal} title={""}>
        <CreateCertificate supplierId={supplierId} workspaceRefetch={refetchDatas} />
      </Modal>
      <Modal open={deleteWorkspaceModal} close={closeModal}>
        <CommonDiscardButtonUpdated
          closeModal={closeModal}
          title={selectedOrders.length > 1 ? "Remove certificates" : "Remove certificate"}
          subText1={`Are you sure you want to remove selected ${selectedOrders.length > 1 ? "certificates" : "certificate"}? This action cannot be undone.`}
          className="w-full lg:max-w-[600px]"
          IconComponent={CertificateIcon}
          confirmButtonText="Remove"
          confirmButtonVariant="warning"
          confirmButtonClassName={"w-full h-11"}
          onConfirmClick={() => handleDelete(selectedOrders)}
          icon={
            <TrashUpdateIcon
              width={20}
              height={20}
              strokeColor={"var(--color-icon-fill-secondary-button)"}
            />
          }
        />
      </Modal>
      <Modal open={showUpgradeModal} close={closeModal}>
        <CommonDiscardButtonUpdated
          closeModal={closeModal}
          title="Upgrade plan"
          subText1={
            currentWorkspace && !currentWorkspace?.is_owner && !workspacePlan
              ? `Your partner workspace is currently on a free plan.`
              : `You are currently on a free plan. Upgrade to a paid plan to start creating your own ${user && user?.workspace_type} workspaces.`
          }
          className="w-full lg:max-w-[544px]"
          confirmButtonClassName={"w-full h-11"}
          IconComponent={PlanIcon}
          confirmButtonText={
            currentWorkspace && !currentWorkspace?.is_owner && !workspacePlan ? null : "Ugrade Plan"
          }
          confirmButtonVariant="primary"
          onConfirmClick={() => {
            navigate(`/workspace/${currentWorkspace?.user_workspace_id}/setting?selectedTab=plan`);
            if (showUpgradeModal) {
              setShowUpgradeModal(false);
            }
          }}
          icon={
            <Shock width={20} height={20} strokeColor={"var(--color-icon-fill-secondary-button)"} />
          }
        />
      </Modal>
    </div>
  );
};

export default CertificatesMain;
