import { PiCheck, PiCross } from "react-icons/pi";
import { HiOutlineX } from "react-icons/hi";
import { LineProgressBar } from "./LineProgressBar";
import { CheckCircleFill } from "assests";

const PassWordCriteria = ({ passwordStrengthData }) => {
  const passCriteriaArray = ["symbol"];
  const isLengthValid = passwordStrengthData?.length >= 8;
  const showCriteria =
    passCriteriaArray.every((element) => passwordStrengthData?.contains?.includes(element)) &&
    passwordStrengthData?.length >= 8;

  return (
    <>
      {!showCriteria && (
        <div className="grid gap-4 w-full pt-3.5">
          <div className="flex-col justify-start items-start gap-4 flex">
            <div className="flex-col justify-start items-start gap-3 flex">
              <div className={`justify-start items-center flex text-global-paragraph`}>
                {passwordStrengthData?.contains?.includes("symbol") ? (
                  <CheckCircleFill width={20} height={20} />
                ) : (
                  <CheckCircleFill
                    width={20}
                    height={20}
                    fillColor={"var(--color-icon-fill-disable)"}
                  />
                )}
                <div className="text-sm pl-2  font-normal leading-none">
                  Must contain one special character
                </div>
              </div>
              <div className="justify-start items-start">
                <div className={`justify-start items-center flex text-global-paragraph`}>
                  {isLengthValid ? (
                    <CheckCircleFill width={20} height={20} />
                  ) : (
                    <CheckCircleFill width={20} height={20} fillColor={"var(--color-icon-fill-disable)"} />
                  )}
                  <div className="text-sm pl-2   font-normal leading-none">
                    Must be at least 8 characters
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      )}
    </>
  );
};

export default PassWordCriteria;