import React from "react";

const Mastercard = React.forwardRef(
  (
    { width = "40", height = "27", viewBox = "0 0 40 27", fill = "none", className, ...props },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6 0.5H34C37.0376 0.5 39.5 2.96243 39.5 6V21C39.5 24.0376 37.0376 26.5 34 26.5H6C2.96243 26.5 0.5 24.0376 0.5 21V6C0.5 2.96243 2.96243 0.5 6 0.5Z"
          fill="white"
        />
        <path
          d="M6 0.5H34C37.0376 0.5 39.5 2.96243 39.5 6V21C39.5 24.0376 37.0376 26.5 34 26.5H6C2.96243 26.5 0.5 24.0376 0.5 21V6C0.5 2.96243 2.96243 0.5 6 0.5Z"
          stroke="#E4E7EC"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M19.7459 18.9331C18.3849 20.0559 16.6195 20.7337 14.6904 20.7337C10.3859 20.7337 6.89648 17.359 6.89648 13.1962C6.89648 9.03334 10.3859 5.65869 14.6904 5.65869C16.6195 5.65869 18.3849 6.3365 19.7459 7.4593C21.1069 6.3365 22.8723 5.65869 24.8014 5.65869C29.1058 5.65869 32.5953 9.03334 32.5953 13.1962C32.5953 17.359 29.1058 20.7337 24.8014 20.7337C22.8723 20.7337 21.1069 20.0559 19.7459 18.9331Z"
          fill="#ED0006"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M19.7461 18.9331C21.4219 17.5506 22.4845 15.4934 22.4845 13.1962C22.4845 10.899 21.4219 8.84181 19.7461 7.4593C21.1071 6.3365 22.8725 5.65869 24.8016 5.65869C29.106 5.65869 32.5955 9.03334 32.5955 13.1962C32.5955 17.359 29.106 20.7337 24.8016 20.7337C22.8725 20.7337 21.1071 20.0559 19.7461 18.9331Z"
          fill="#F9A000"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M19.7457 18.933C21.4215 17.5504 22.4841 15.4933 22.4841 13.1961C22.4841 10.8989 21.4215 8.84174 19.7457 7.45923C18.0699 8.84174 17.0073 10.8989 17.0073 13.1961C17.0073 15.4933 18.0699 17.5504 19.7457 18.933Z"
          fill="#FF5E00"
        />
      </svg>
    );
  },
);

export default Mastercard;
