import { Cross } from "assests";
import { equalTo, get, orderByChild, query, ref, remove, update } from "firebase/database";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { database } from "../../../firebase";
import Lists from "./Lists";

const AppHeaderNotification = ({
  handleScroll,
  isOpen,
  setIsOpen,
  notificationData,
  partner_id,
}) => {
  const navigate = useNavigate();
  const { currentWorkspace } = useSelector((state) => state.authenticationSlice);
  const workspaceId = parseInt(currentWorkspace?.workspace_id);
  const userWorkpsaceId = parseInt(currentWorkspace?.user_workspace_id)
  const handleRedirectDynamicPage = async (item) => {
    const { type, workspace_id, doc_id, company_type, status, partner_id } = item;
    if (
      (type === "supplier" || type === "agent" || type === "retailer") &&
      workspace_id && userWorkpsaceId &&
      status === "active"
    ) {
      const workspace_id = parseInt(workspaceId);
      if (currentWorkspace && currentWorkspace.role_name.includes("supplier")) {
        navigate(`/workspace/${userWorkpsaceId}/setting?selectedTab=company`, { replace: true });
      } else {
        navigate(`/workspace/${workspace_id}/supplier-details/${doc_id}/accepted`, {
          replace: true,
        });
      }
    }

    // if (title === "Expiry Notice") {
    //   navigate(`/workspace/${workspace_id}/supplier-details/${doc_id}/supplier`, {
    //     replace: true,
    //   });
    // }

    // if (status === "active" && workspace_id && partner_id) {
    //   navigate(`/workspace/${workspace_id}/supplier-details/${partner_id}/accepted`, {
    //     replace: true,
    //   });
    // }
    if (type === "invitation" && status === "pending" && workspace_id && userWorkpsaceId) {
      if (workspace_id) {
        navigate(`/workspace/${userWorkpsaceId}/setting?selectedTab=partner_invites`, {
          replace: true,
        });
      }
    }
    if (type === "new_invitation" && workspace_id) {
      if (workspace_id) {
        navigate(`/`, {
          replace: true,
        });
      }
    }

    if (type === "order" && workspace_id && (status === "active" || status === "pending")) {
      navigate(`/workspace/${workspace_id}/orders/${doc_id}`, { replace: true });
    }
    if (type === "system_plan" && workspace_id && userWorkpsaceId) {
      navigate(`/workspace/${userWorkpsaceId}/setting?selectedTab=plan`, { replace: true });
    }
    if (type === "supplyChainPartner") {
      const workspace_id = workspaceId;
      if (workspace_id) {
        console.log(workspace_id, "check workspace id");
        if (company_type === "retailer") {
          navigate(`/workspace/${workspace_id}/retailer-details/${doc_id}?status=accepted`, {
            replace: true,
          });
        }
        if (company_type === "supplier") {
          navigate(`/workspace/${workspace_id}/supplier-details/${doc_id}/supplier/accepted`, {
            replace: true,
          });
        }
        if (company_type === "agent") {
          navigate(`/workspace/${workspace_id}/agent-details/${doc_id}?status=accepted`, {
            replace: true,
          });
        }
      }
    } else {
      return;
    }
  };

  const handleNotificationClick = async (id, item) => {
    const dbRef = ref(database, "/notification");
    const queryRef = query(dbRef, orderByChild(`reference_key`), equalTo(id.toString()));
    try {
      const snapshot = await get(queryRef);
      if (snapshot.exists()) {
        const notificationRef = ref(database, `/notification/${item.reference_key}`);
        update(notificationRef, { isRead: true });
        remove(notificationRef);
      } else {
        console.log("No notifications found for the user.");
      }
    } catch (error) {
      console.error("Error updating status:", error);
    }
    handleRedirectDynamicPage(item);
  };

  return (
    <>
      {isOpen && (
        <>
          <div className="px-6 py-6 flex justify-between gap-2">
            <div className="text-[20px] leading-[30px] text-global-title font-semibold">
              Notification
            </div>
            <Cross
              width={20}
              height={20}
              onClick={() => setIsOpen(false)}
              fillColor={"var(--color-gray-extra-3"}
              className="cursor-pointer"
            />
            {/* <Divider className="mt-4" /> */}
          </div>
          <Lists
            handleScroll={handleScroll}
            datas={notificationData}
            handleNotificationClick={handleNotificationClick}
          />
        </>
      )}
    </>
  );
};

export default AppHeaderNotification;
