import {
  ArrowLeft,
  BgImg,
  EmailVerification,
  EmailVerificationIcon,
  Mail01Icon,
  MailSuccessIcon,
  PhoneBGImg,
  ResetPasswordEmail,
} from "assests";
import { Button } from "components/UI/Button";
import Divider from "components/UI/Divider";
import FormHeader from "components/UI/FormHeader";
import { Input } from "components/UI/Input";
import { Label } from "components/UI/Label";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import OTPInput, { ResendOTP } from "otp-input-react";
import { useDispatch } from "react-redux";
import { setActiveStep } from "store/createAccountSlice";
import BackButton from "components/UI/BackButton";
import SigninLogo from "components/UI/SigninLogo";
const ConfirmEmail = ({ validateTheOtp, getValues, resendOtp, isEmailValidating }) => {
  const dispatch = useDispatch();
  const [OTP, setOTP] = useState("");
  const [timeLeft, setTimeLeft] = useState(60);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [isHovered, setIshovered] = useState(false);
  const handleResend = (e) => {
    e.preventDefault();
    // setShowContact(true);
    resendOtp(e);
    setIsButtonDisabled(true);
    setTimeLeft(60);
    setOTP("");
  };

  useEffect(() => {
    if (timeLeft > 0) {
      const timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
      return () => clearInterval(timer);
    } else {
      setIsButtonDisabled(false);
      // setShowContact(false);
    }
  }, [timeLeft]);
  const handleValidatOtp = () => {
    if (!OTP || OTP.length < 4) return;
    validateTheOtp(OTP);
  };

  const handleLogoClick = () => {
    dispatch(setActiveStep(3));
  };

  return (
    <div className="flex items-center justify-center w-full ">
      <div className="z-[2] w-full flex flex-col items-center justify-center">
        <div
          className=" flex items-center justify-center w-full h-full "
          style={{ pointerEvents: "none" }}
        >
          <img src={BgImg} alt="" className="fixed top-0 hidden md:block z-[-1]" />
          <img src={PhoneBGImg} alt="" className="fixed top-0 block md:hidden z-[-1]" />
        </div>
        <div className="relative w-full px-4 md:p-0 md:w-[360px]">
          <div className="flex flex-col gap-8">
            <div className="flex flex-col gap-6">
              <SigninLogo>
                <Mail01Icon strokeColor="#344054" width={28} height={28} />
              </SigninLogo>
              <FormHeader
                title="Check your email"
                subtitle={`We sent a verification link to ${getValues(["email"])}`}
              />
            </div>
            <div className="grid gap-8">
              <div className="grid w-full gap-3">
                <div className="flex flex-row  gap-2 lg:gap-3 justify-center items-center custom-otp-input">
                  <OTPInput
                    id="verificationCode"
                    value={OTP}
                    onChange={setOTP}
                    autoFocus
                    OTPLength={4}
                    otpType="number"
                    disabled={false}
                    data-testid="input"
                  />
                </div>
              </div>
              <div className="flex justify-center">
                <Button
                  size="lg"
                  className="flex items-center w-full gap-2  text-base font-semibold"
                  onClick={handleValidatOtp}
                  isDisabled={!OTP || OTP.length < 4 || OTP.length > 4 || isEmailValidating}
                >
                  Verify email
                </Button>
              </div>
            </div>
            <div className="flex items-center justify-center">
              <p className="relative pl-2 pr-1 text-sm font-normal  text-login-text-paragraph whitespace-nowrap">
                Didn’t receive the email?
              </p>
              <Button
                onClick={(e) => handleResend(e)}
                isDisabled={isButtonDisabled}
                className="p-0 m-0 pr-2  bg-transparent hover:bg-transparent disabled:bg-transparent text-sm font-semibold  text-btn-text-link whitespace-nowrap "
              >
                {isButtonDisabled ? `Click to resend (${timeLeft}s)` : "Click to resend"}
              </Button>
            </div>
            <div className="flex items-center justify-center gap-1.5">
              <Button
                disabled
                className={`text-sm font-semibold border-none p-0 m-0 bg-transparent hover:bg-transparent disabled:bg-transparent whitespace-nowrap text-btn-text-tertiary`}
              >
                <ArrowLeft
                  fillColor={"var(--color-login-icon-outlined-disabled)"}
                  height={20}
                  width={20}
                />
                Go back
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmEmail;
