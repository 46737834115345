import { showToast } from "components/CustomeToaster";

const useCustomToaster = () => {
    const showCustomToast = (data, id) => {
        showToast(data, id);
    };
    return { showCustomToast };
};

export default useCustomToaster;
