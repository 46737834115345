const { createSlice } = require("@reduxjs/toolkit");

const orderSlice = createSlice({
  name: "order",
  initialState: {
    currentStep: 1,
    validatedSteps: [],
    allOrderData: null,
    orderList: [],
    order: null,
    actionableOrderId: null,
    compositionFormLength: 1,
    isUploadBulkOrderModalOpen: false,
    orderDetailsData: null,
    isSubSupplierCreateModalOpen: false,
    selectedTier: "tier_4",
    selectedTierType: null,
    selectedTierLists: null,
    selectedSubTierId: null,
    deleteSubSupplierLocationModal: false,
    isOrderCreateModalOpen: false,
    deleteModal: false,
    bulkDeleteModal: false,
    showDiscardModal: false,
    isOrderEditable: false,
    cancleModal: false,
    cancleOrderModal: false,
    cancleInvitationModal: false,
    isDuplicateAction: false,
    isOrderDocumentsEditable: false,
    selectedOrderDocumentsId: null,
    orderDocumentsShowDiscardModal: false,
    orderDocumentsdeleteModal: false,
    orderDocumnetsDetails: null,
    showArticleForm: false,
    formatedArticleList: [],
    formatedTemplateList: [],
    showTemplateForm: false,
    showCreateTemplateForm: false,
    refetchRetailerData: false,
    refetchSupplierData: false,
    refetchAgentData: false,
    refetchTemplateData: false,
    reachedOrderModal: false,
    artcileReachedModal: false,
    inviteReachedModal: false,
    isSubSupplierCreateFromOrderModalOpen: false,
    isForwarderCreateModalOpen: false,
    selectedOrderItemData: null,
    isTiersUpdateModalOpen: false,
  },
  reducers: {
    toggleIsOrderCreateModalOpen: (state) => ({
      ...state,
      isOrderCreateModalOpen: !state.isOrderCreateModalOpen,
    }),
    toggleIsTiersUpdateModalOpen: (state) => ({
      ...state,
      isTiersUpdateModalOpen: !state.isTiersUpdateModalOpen,
    }),
    toggleReachedOrderModal: (state) => ({
      ...state,
      reachedOrderModal: !state.reachedOrderModal,
    }),
    toggleArtcileReachedModal: (state) => ({
      ...state,
      artcileReachedModal: !state.artcileReachedModal,
    }),
    toggleInviteReachedModal: (state) => ({
      ...state,
      inviteReachedModal: !state.inviteReachedModal,
    }),
    toggleIsForwarderCreateModalOpen: (state) => ({
      ...state,
      isForwarderCreateModalOpen: !state.isForwarderCreateModalOpen,
    }),
    toggleIsSubSupplierCreateModalOpen: (state) => ({
      ...state,
      isSubSupplierCreateModalOpen: !state.isSubSupplierCreateModalOpen,
    }),
    toggleIsSubSupplierCreateFromOrderModalOpen: (state) => ({
      ...state,
      isSubSupplierCreateFromOrderModalOpen: !state.isSubSupplierCreateFromOrderModalOpen,
    }),
    toggleIsOrderDocumentsEditable: (state) => ({
      ...state,
      isOrderDocumentsEditable: !state.isOrderDocumentsEditable,
    }),
    toggleActionableOrderId: (state, action) => ({
      ...state,
      actionableOrderId: action.payload ?? null,
    }),
    setSelectedOrderItemData: (state, action) => ({
      ...state,
      selectedOrderItemData: action.payload ?? null,
    }),
    toggleSelectedOrderDocuments: (state, action) => ({
      ...state,
      selectedOrderDocumentsId: action.payload ?? null,
    }),
    toggleIsOrderEditable: (state) => ({ ...state, isOrderEditable: !state.isOrderEditable }),
    toggleShowDiscardModal: (state) => ({ ...state, showDiscardModal: !state.showDiscardModal }),
    toggleDuplicateAction: (state) => ({ ...state, isDuplicateAction: !state.isDuplicateAction }),
    toggleOrderCancleInvitationModal: (state) => ({
      ...state,
      cancleInvitationModal: !state.cancleInvitationModal,
    }),
    toggleOrderDocumentsShowDiscardModal: (state) => ({
      ...state,
      orderDocumentsShowDiscardModal: !state.orderDocumentsShowDiscardModal,
    }),
    toggleOrderDocumentsDeleteModal: (state) => {
      state.orderDocumentsdeleteModal = !state.orderDocumentsdeleteModal;
    },

    setCurrentStep: (state, action) => ({
      ...state,
      currentStep: action.payload,
    }),
    toggleDeleteModal: (state) => {
      state.deleteModal = !state.deleteModal;
    },
    toggleBulkDeleteModal: (state) => {
      state.bulkDeleteModal = !state.bulkDeleteModal;
    },
    toggleOrderCancleModal: (state) => ({
      ...state,
      cancleOrderModal: !state.cancleOrderModal,
    }),
    setOrderList: (state, action) => {
      if (action.payload.append) {
        return {
          ...state,
          orderList: [action.payload.order, ...state.orderList],
        };
      } else {
        return {
          ...state,
          orderList: action.payload.orderList,
        };
      }
    },
    setAllOrderData: (state, action) => ({
      ...state,
      allOrderData: action.payload.allOrderData ?? null,
    }),
    setOrderDocumnetsDetails: (state, action) => ({
      ...state,
      orderDocumnetsDetails: action.payload.orderDocumnetsDetails ?? null,
    }),
    setSelectedTierLists: (state, action) => ({
      ...state,
      selectedTierLists: action.payload ?? null,
    }),
    setOrderDetailsData: (state, action) => ({
      ...state,
      orderDetailsData: action.payload ?? null,
    }),
    setSelectedSubTierId: (state, action) => ({
      ...state,
      selectedSubTierId: action.payload ?? null,
    }),
    toggleDeleteSubSupplierLocationModal: (state) => {
      state.deleteSubSupplierLocationModal = !state.deleteSubSupplierLocationModal;
    },
    setOrder: (state, action) => ({
      ...state,
      order: action.payload ?? null,
    }),
    setSelectedTier: (state, action) => ({
      ...state,
      selectedTier: action.payload ?? null,
    }),
    setSelectedTierType: (state, action) => ({
      ...state,
      selectedTierType: action.payload ?? null,
    }),
    setValidatedSteps: (state, action) => {
      if (action.payload) {
        const newValidatedSteps = new Set([...state.validatedSteps, action.payload]);
        return {
          ...state,
          validatedSteps: Array.from(newValidatedSteps),
        };
      } else {
        return { ...state, validatedSteps: [] };
      }
    },
    setCompositionFormLength: (state, action) => ({
      ...state,
      compositionFormLength: action.payload,
    }),

    toggleIsUploadBulkOrderModalOpen: (state) => ({
      ...state,
      isUploadBulkOrderModalOpen: !state.isUploadBulkOrderModalOpen,
    }),
    setShowArticleForm: (state, action) => ({
      ...state,
      showArticleForm: action.payload,
    }),
    setShowTemplateForm: (state, action) => ({
      ...state,
      showTemplateForm: action.payload,
    }),
    setFormatedArticleList: (state, action) => ({
      ...state,
      formatedArticleList: action.payload,
    }),
    setFormatedTemplateList: (state, action) => ({
      ...state,
      formatedTemplateList: action.payload,
    }),
    updateOrderSliceState: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    setRefetchRetailerData: (state) => ({
      ...state,
      refetchRetailerData: !state.refetchRetailerData,
    }),
    setRefetchAgentData: (state) => ({
      ...state,
      refetchAgentData: !state.refetchAgentData,
    }),
    setRefetchSupplierData: (state) => ({
      ...state,
      refetchSupplierData: !state.refetchSupplierData,
    }),
    setRefetchTemplateData: (state) => ({
      ...state,
      refetchTemplateData: !state.refetchTemplateData,
    }),
  },
});

export const {
  setAllOrderData,
  setCurrentStep,
  setOrderList,
  setOrder,
  setActionableOrderId,
  setValidatedSteps,
  setCompositionFormLength,
  toggleIsUploadBulkOrderModalOpen,
  toggleIsOrderCreateModalOpen,
  toggleActionableOrderId,
  setOrderDetailsData,
  toggleIsSubSupplierCreateModalOpen,
  toggleIsSubSupplierCreateFromOrderModalOpen,
  setSelectedTier,
  setSelectedTierLists,
  setSelectedSubTierId,
  toggleDeleteSubSupplierLocationModal,
  toggleShowDiscardModal,
  toggleDeleteModal,
  toggleOrderCancleModal,
  toggleIsOrderEditable,
  toggleDuplicateAction,
  toggleIsOrderDocumentsEditable,
  toggleSelectedOrderDocuments,
  toggleOrderDocumentsShowDiscardModal,
  toggleOrderDocumentsDeleteModal,
  setOrderDocumnetsDetails,
  toggleOrderCancleInvitationModal,
  setFormatedArticleList,
  setShowArticleForm,
  setFormatedTemplateList,
  setShowTemplateForm,
  updateOrderSliceState,
  setRefetchRetailerData,
  setRefetchSupplierData,
  setRefetchAgentData,
  setRefetchTemplateData,
  toggleBulkDeleteModal,
  toggleReachedOrderModal,
  toggleArtcileReachedModal,
  toggleInviteReachedModal,
  toggleIsForwarderCreateModalOpen,
  setSelectedOrderItemData,
  toggleIsTiersUpdateModalOpen,
  setSelectedTierType
} = orderSlice.actions;

export default orderSlice.reducer;
