import React from "react";

const ToasterIcon = ({ children, className }) => {
  return (
    <div className="flex items-center justify-center ">
      <div
        className={`bg-white flex items-center justify-center rounded-xl w-10 h-10 ${className} `}
        style={{
          boxShadow:
            "rgba(16, 24, 40, 0.05) 0px 1px 2px 0px, rgba(16, 24, 40, 0.05) 0px -2px 0px 0px inset, rgba(16, 24, 40, 0.15) 0px 0px 0px 1px inset",
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default ToasterIcon;
