import React from "react";

const UTurnIcon = React.forwardRef(
  (
    {
      width = "16",
      height = "16",
      viewBox = "0 0 16 16",
      fill = "none",
      strokeColor = "#717680",
      className,
      onClick = () => {},
      ...props
    },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className, onClick }}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2 6.00016H11C12.6569 6.00016 14 7.34331 14 9.00016C14 10.657 12.6569 12.0002 11 12.0002H8M2 6.00016L4.66667 3.3335M2 6.00016L4.66667 8.66683"
          stroke={strokeColor}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  },
);

export default UTurnIcon;
