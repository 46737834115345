import React from "react";

const BorderDesign = ({ children, className, boxShadowShow }) => {
  return (
    <div className="flex items-center justify-center ">
      <div
        className={`flex items-center justify-center rounded-xl  ${className} `}
        style={{
          boxShadow: !boxShadowShow ? "rgba(16, 24, 40, 0.05) 0px 1px 2px 0px, rgba(16, 24, 40, 0.05) 0px -2px 0px 0px inset, rgba(228, 231, 236, 1) 0px 0px 0px 1px" : "rgba(16, 24, 40, 0.05) 0px 1px 2px 0px, rgba(16, 24, 40, 0.05) 0px -2px 0px 0px inset, rgba(16, 24, 40, 0.15) 0px 0px 0px 1px inset",
        }}
      >
        {children}
      </div>
    </div >

  );
};

export default BorderDesign;
