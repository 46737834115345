import React from "react";

const View = React.forwardRef(
  (
    {
      width = "20",
      height = "25",
      viewBox = "0 0 24 25",
      fill = "none",
      stroke = "none",
      fillColor = "#8E8F98",
      strokeColor = "#8E8F98",
      className,
      onClick = () => {},
      ...props
    },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, stroke, className, onClick }}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 12.5C6.63257 24.5 17.3674 24.5 24 12.5C17.3674 0.5 6.63257 0.5 0 12.5ZM12 16.1857C11.271 16.1857 10.5584 15.9696 9.95233 15.5646C9.34621 15.1596 8.87381 14.5839 8.59484 13.9105C8.31588 13.237 8.24289 12.4959 8.38511 11.781C8.52732 11.066 8.87835 10.4093 9.39381 9.89381C9.90926 9.37835 10.566 9.02732 11.281 8.88511C11.9959 8.74289 12.737 8.81588 13.4105 9.09484C14.0839 9.37381 14.6596 9.84621 15.0646 10.4523C15.4696 11.0584 15.6857 11.771 15.6857 12.5C15.6853 13.4774 15.2968 14.4146 14.6057 15.1057C13.9146 15.7968 12.9774 16.1853 12 16.1857Z"
          fill={fillColor}
        />
        <path d="M1.64648 22.7012L22.2013 2.14637" stroke={strokeColor} strokeWidth="2" />
      </svg>
    );
  },
);

export default View;
