import React from "react";

const ResetPasswordSucccessEmail = React.forwardRef(
  (
    { width = "72", height = "72", viewBox = "0 0 72 72", fill = "none", className, ...props },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="fi_5089889" clipPath="url(#clip0_1670_26659)">
          <g id="Group">
            <path
              id="Vector"
              d="M60.8531 72H11.1469C5.00048 72 0 66.9995 0 60.853V29.2207C0 28.0558 0.944437 27.1113 2.10938 27.1113C3.27431 27.1113 4.21875 28.0558 4.21875 29.2207C4.21875 31.2046 5.32055 32.9871 7.09411 33.8737L36 48.3276L64.9056 33.8738C66.6795 32.9871 67.7812 31.2046 67.7812 29.2218V29.2207C67.7812 28.0558 68.7257 27.1113 69.8906 27.1113C71.0487 27.1113 71.9889 28.0447 71.9999 29.2002C72 29.2069 72 29.2138 72 29.2207V60.853C72 66.9995 66.9995 72 60.8531 72Z"
              fill="#5CF1BB"
            />
            <path
              id="Vector_2"
              d="M72 60.853V29.2207C72 29.214 72 29.2071 71.9999 29.2002C71.9889 28.0447 71.0487 27.1113 69.8906 27.1113C68.7257 27.1113 67.7812 28.0558 67.7812 29.2207V29.2218C67.7812 31.2046 66.6795 32.9871 64.9056 33.8738L36 48.3276V72H60.8531C66.9995 72 72 66.9995 72 60.853Z"
              fill="#11C182"
            />
            <path
              id="Vector_3"
              d="M59.7443 40.0537C59.1223 39.6693 58.7439 38.9904 58.7439 38.2593V20.1842C58.7439 19.4531 59.1223 18.7742 59.7443 18.3898C60.3659 18.0055 61.1424 17.9703 61.7966 18.2974L66.7923 20.7951C70.0046 22.4009 72.0001 25.6293 72.0001 29.2206V29.2218C72.0001 32.813 70.0046 36.0415 66.7923 37.6472L61.7966 40.1461C61.1562 40.4661 60.3789 40.4459 59.7443 40.0537Z"
              fill="#0D9766"
            />
            <path
              id="Vector_4"
              d="M10.2036 40.1463L5.2072 37.6471C1.99547 36.0417 0 32.8134 0 29.2221V29.221C0 25.6297 1.99547 22.4012 5.20777 20.7956L10.2035 18.2979C10.8572 17.9708 11.6339 18.006 12.2557 18.3903C12.8777 18.7746 13.2562 19.4536 13.2562 20.1847V38.2598C13.2562 38.9909 12.8777 39.6698 12.2557 40.0542C11.6447 40.4317 10.8681 40.4787 10.2036 40.1463Z"
              fill="#11C182"
            />
            <path
              id="Vector_5"
              d="M36.0001 52.7953C35.6768 52.7953 35.3536 52.721 35.0567 52.5727L10.2037 40.146C9.489 39.7887 9.0376 39.0583 9.0376 38.2593V8.8875C9.0376 3.97828 13.0086 0 17.9251 0H54.075C58.9834 0 62.9625 3.97041 62.9625 8.8875V38.2594C62.9625 39.0585 62.5111 39.7887 61.7964 40.1462L36.9434 52.5727C36.6465 52.721 36.3233 52.7953 36.0001 52.7953Z"
              fill="#CCFBEA"
            />
            <path
              id="Vector_6"
              d="M61.7964 40.146C62.511 39.7887 62.9625 39.0583 62.9625 38.2593V8.8875C62.9625 3.97041 58.9832 0 54.075 0H36V52.7953C36.3233 52.7953 36.6465 52.721 36.9433 52.5727L61.7964 40.146Z"
              fill="#94F6D3"
            />
            <path
              id="Vector_7"
              d="M31.1431 40.5158L18.6927 28.0655C17.869 27.2418 17.8697 25.9053 18.6929 25.0822L25.0836 18.6927C25.9074 17.8692 27.2429 17.869 28.0665 18.6928L32.6346 23.2609L43.9808 11.9147C44.8044 11.091 46.1399 11.0909 46.9637 11.9145L53.3543 18.3041C54.1783 19.128 54.1775 20.4644 53.3544 21.2873L34.1262 40.5158C33.3023 41.3398 31.9662 41.3388 31.1431 40.5158Z"
              fill="#11C182"
            />
            <path
              id="Vector_8"
              d="M36 38.642L53.3545 21.2873C54.1776 20.4644 54.1785 19.128 53.3544 18.3041L46.9638 11.9145C46.1402 11.0909 44.8047 11.091 43.9809 11.9147L36 19.8957V38.642Z"
              fill="#0D9766"
            />
          </g>
        </g>
        <defs>
          <clipPath id="clip0_1670_26659">
            <rect width="72" height="72" fill="white" />
          </clipPath>
        </defs>
      </svg>
    );
  },
);

export default ResetPasswordSucccessEmail;
