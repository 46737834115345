import { useMutation, useQueryClient } from "@tanstack/react-query";
import { CheckCircleOutlined, Cross, Mail01Icon } from "assests";
import { Button } from "components/UI/Button";
import SigninLogo from "components/UI/SigninLogo";
import useCustomToaster from "hooks/useCustomToaster";
import useUser from "hooks/useUser";
import OTPInput from "otp-input-react";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { resendEmailValidationOTP } from "services/auth.services";
import { validateUpdatedEmailOTP, removeTempEmail } from "services/user.services";
const VerifyUpdatedEmail = ({
  timeLeft,
  setTimeLeft,
  OTP,
  setOTP,
  isButtonDisabled,
  setIsButtonDisabled,
  setShowSuccessEmailUpdated,
  setSuccessMsgtimeLeft,
}) => {
  const { token, user } = useSelector((state) => state.authenticationSlice);
  const { showCustomToast } = useCustomToaster();
  const queryClient = useQueryClient();
  const { userMutation } = useUser();

  const handleResend = (e) => {
    e.preventDefault();
    resendOtp(e);
    setIsButtonDisabled(true);
    setTimeLeft(60);
    setOTP("");
  };

  const validateTheOtp = async (otp) => {
    const payloadData = { otp: parseInt(otp) };
    confirmEmailMutation.mutate(payloadData, {
      onSuccess: (response) => {
        const data = response.data;
        userMutation.mutate();
        setShowSuccessEmailUpdated(true);
        setSuccessMsgtimeLeft(60);
        setTimeLeft(0);
        setOTP("");
        if (data.status) {
          showCustomToast({
            title: "Email verified successfully",
            subtitle:
              "Your email address has been successfully verified. You can now use your new email address to login.",
          });
        }
      },
    });
  };

  const handleError = (error) => {
    toast.dismiss("creatingAccount");
    if (error?.response?.data?.errors) {
      const errorMessages = Object.values(error?.response?.data?.errors).flat();
      errorMessages.forEach((errorMessage) => {
        showCustomToast({
          title: errorMessage,
        });
      });
    } else {
      const errorMessage = error?.response?.data?.title || error.title;
      const subErrorMessage = error?.response?.data?.message || error.message;
      if (errorMessage && subErrorMessage) {
        showCustomToast({
          title: errorMessage,
          subtitle: subErrorMessage,
        });
      } else {
        showCustomToast({
          title: subErrorMessage,
        });
      }
    }
  };

  const confirmEmailMutation = useMutation({
    mutationKey: ["authEmail", "confirmNewEmail"],
    mutationFn: (data) => validateUpdatedEmailOTP(data),
    onError: handleError,
  });

  const resendOtp = async (e) => {
    try {
      e.preventDefault();
      e.stopPropagation();
      const res = await resendEmailValidationOTP({ token: token });
      if (res.status !== 200 || !res.data.status) {
        handleError(res);
        return;
      }
      showCustomToast({
        title: `New code sent!`,
        subtitle: `If you still haven’t received the email, please`,
        url: "contact us.",
      });
    } catch (error) {
      handleError(error);
    }
  };

  const handleValidatOtp = () => {
    if (!OTP || OTP.length < 4) return;
    validateTheOtp(OTP);
  };

  const handleDeleteTempEmail = useMutation({
    mutationKey: ["tempEmailRemove", "deleteTempEmail"],
    mutationFn: () => removeTempEmail(),
    onMutate: () => {
      showCustomToast(
        {
          title: "Removing...",
        }, "deleteTempEmail"
      );
    },
    onSuccess: (res) => {
      toast.dismiss("deleteTempEmail");
      showCustomToast(
        {
          title: "Removed successfully",
        }
      );
      userMutation.mutate();
      setShowSuccessEmailUpdated(false);
      setSuccessMsgtimeLeft(0);
      setTimeLeft(0);
      setOTP("");
      queryClient.invalidateQueries(["tempEmailRemove", "get", "delete"]);
    },
    onError: (error) => {
      toast.dismiss("deleteTempEmail");
      showCustomToast(
        {
          title: error.response.data.message || error.message,
        }
      );
    },
    onSettled: () => {
      toast.dismiss("deleteTempEmail");
    },
  });


  return (
    <div className="w-full relative rounded-xl bg-global-white-default border border-border-secondary shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)] ">
      <div className="absolute top-[18px] right-[18px]">
        <Cross
          fillColor="var(--color-icon-fill-input)"
          width={24}
          height={24}
          onClick={() => handleDeleteTempEmail.mutate()}
          className="cursor-pointer"
        />
      </div>
      <div className=" w-full p-6">
        <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-4">
            <SigninLogo>
              <Mail01Icon strokeColor="#344054" width={28} height={28} />
            </SigninLogo>
            <div className="grid gap-[2px]">
              <h6
                className={`font-semibold text-center text-login-text-title text-base md:text-lg`}
              >
                Check your email
              </h6>
              <p className="font-normal text-center text-login-text-paragraph  text-sm">
                Verification code sent to <strong>{user && user?.temp_email}</strong> to update
                your email
              </p>
            </div>
          </div>
          <div className="w-full flex items-center justify-center">
            <div className="flex flex-col gap-4 w-[216px]">
              <div className="grid w-full gap-3">
                <div className="flex flex-row  gap-2 lg:gap-3 justify-center items-center custom-otp-input-profile">
                  <OTPInput
                    id="verificationCode"
                    value={OTP}
                    onChange={setOTP}
                    autoFocus
                    OTPLength={4}
                    otpType="number"
                    disabled={false}
                    data-testid="input"
                  />
                </div>
              </div>
              <div className="flex justify-center flex-grow">
                <Button
                  className="flex items-center h-9 w-full gap-2 rounded-lg  text-sm font-semibold"
                  onClick={handleValidatOtp}
                  isDisabled={
                    !OTP || OTP.length < 4 || OTP.length > 4 || confirmEmailMutation.isPending
                  }
                >
                  Submit OTP
                </Button>
              </div>
            </div>
          </div>

          <div className="flex items-center justify-center">
            <p className="relative pl-2 pr-1 text-sm font-normal  text-login-text-paragraph whitespace-nowrap">
              Didn't receive the email?
            </p>
            <Button
              onClick={(e) => handleResend(e)}
              isDisabled={isButtonDisabled}
              className="p-0 m-0 pr-2  bg-transparent hover:bg-transparent disabled:bg-transparent text-sm font-semibold  text-btn-text-link whitespace-nowrap "
            >
              {isButtonDisabled ? `Click to resend (${timeLeft}s)` : "Click to resend"}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyUpdatedEmail;
