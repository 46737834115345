import { Button } from "components/UI/Button";
import ErrorMessage from "components/UI/ErrorMessage";
import { Input } from "components/UI/Input";
import { Label } from "components/UI/Label";
import ToggleSwitch from "components/UI/ToggleSwitch";
import React from "react";

const FirstStepEdit = ({ control,
  register,
  editNextStep,
  setValue,
  errors,
  closeModal,
  getValues,
  activeRole,
  setActiveRole,
  actionableUserId,
  userDetails
}) => {
  console.log(errors, "errors");

  const handleSwitchChange = (role) => {
    const newActiveRole = activeRole === role ? null : role;
    setActiveRole(newActiveRole);
    const newState = { Admin: false, Editor: false, Viewer: false, ActiveRole: newActiveRole };
    if (newActiveRole) {
      newState[newActiveRole] = true;
    }
    setValue("type", newActiveRole);
  };

  return (
    <div className="w-full">
      <div className="flex flex-col gap-1 mt-4 mb-3 lg:mb-5">
        <h3 className="text-lg font-semibold text-global-title">{userDetails?.person_name}</h3>
        <p className="text-sm text-global-paragraph ">Update member account permission.</p>
      </div>
      <div className="grid grid-cols-1 gap-8 mb-[78.5px]">
        <div className="grid gap-1.5">
          <Label>
            Email address <span className="text-global-primary">*</span>
          </Label>

          <Input className="h-10" placeholder="Enter email" {...register("email")} disabled={actionableUserId} />

          <ErrorMessage errors={errors?.email?.message} />
        </div>
        <div className="grid grid-cols-1 gap-3 px-2 lg:px-4">
          {["admin", "editor", "viewer"].map((role, index) => (
            <div key={index} className="flex flex-row gap-2">
              <div>
                <ToggleSwitch
                  autoRenew={activeRole === role}
                  handleSwitchChange={() => handleSwitchChange(role)}
                />
              </div>
              <div className="flex flex-col">
                <p className="font-semibold text-sm leading-5 text-global-title-2 capitalize">
                  {role}
                </p>
                <p className="text-sm text-global-paragraph font-normal ">
                  {role === "admin"
                    ? "Full control over account."
                    : role === "editor"
                      ? "Can add and edit items and invite supply chain partners."
                      : "Can view workspace content."}
                </p>
              </div>
            </div>
          ))}
        </div>
        <ErrorMessage errors={errors?.type?.message} />
      </div>
      <div className="pt-5 lg:pt-8 flex justify-center lg:justify-end flex-col lg:flex-row items-center gap-3">
        <Button
          variant={"secondary"}
          className={"w-full lg:w-[90px] h-11 py-2.5 px-4 font-semibold text-base"}
          onClick={closeModal}
        >
          Cancel
        </Button>
        <Button
          variant={"primary"}
          className={`w-full  h-11 py-2.5 px-4 font-semibold text-base ${getValues("type") === "admin" ? actionableUserId ? "lg:w-[161px]" : "lg:w-[147px]" : "lg:w-[107px]"}`}
          onClick={editNextStep}
        >
          {
            getValues("type") === "admin"
              ? actionableUserId ? "Update Member" : "Invite Member"
              : "Continue"
          }
        </Button>

      </div>
    </div>
  );
};

export default FirstStepEdit;
