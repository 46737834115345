import { AfternoonIcon, EveningIcon, MorningIcon } from "assests";
import { useSelector } from "react-redux";

const Greeting = () => {
  const { user } = useSelector((state) => state.authenticationSlice);
  const names = user?.name;

  const getGreetingMessage = () => {
    const time = new Date().getHours();
    const namePart = names; // Get the first name

    if (time >= 0 && time < 12) {
      return {
        icon: <MorningIcon className="h-5 md:h-6 md:w-6" />,
        message: `Good morning, `,
        name: namePart,
      };
    } else if (time >= 12 && time < 18) {
      return {
        icon: <AfternoonIcon className="h-5 md:h-6 md:w-6" />,
        message: `Good afternoon, `,
        name: namePart,
      };
    } else {
      return {
        icon: <EveningIcon className="h-5 md:h-6 md:w-6" />,
        message: `Good evening, `,
        name: namePart,
      };
    }
  };

  const greeting = getGreetingMessage();

  return (
    <div className=" place-content-center">
      <div className="text-white whitespace-nowrap truncate text-sm  font-semibold tracking-[0.3px]">
        {greeting.message}
      </div>
      <div className="flex flex-col gap-2">
        <div className="flex items-center text-white whitespace-nowrap truncate text-sm  font-normal tracking-[0.3px]">
          <span>{greeting.name}</span>

        </div>
      </div>
    </div>
  );
};

export default Greeting;
