import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation, useParams, useSearchParams } from "react-router-dom";
import { CreateUserStepList, EditUserStepList } from "utils/constant";
import { setActiveStep } from "store/createAccountSlice";
import {
  createUser,
  storePassword,
  updateRole,
  acceptInvitation,
  acceptStatus,
  getAcceptInvitationDetails,
  getAcceptInvitationDetailsByid,
  getUsers,
  searchWorkspace,
} from "services/user.services";
import { userSchema, passwordSchema } from "validation/user.validation";
import {
  toggleIsUserCreateModalOpen,
  toggleActionableUserId,
  toggleIsUserEditable,
  toggleShowDiscardModal,
  setUserList,
  setUserDetails,
  setUserSliceState,
  setWorkspaceListData,
  setSelectedWorkspaces,
  toggleUserDeleteModal,
  toggleSelectedUserId,
} from "store/userSlice";
import { setRefetchBillingData } from "store/billingSlice";
import useCustomToaster from "./useCustomToaster";
import { removeQueryParam } from "utils/helper";

const useCreateUserManagement = ({ refetchUserData }) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const { pathname } = useLocation();
  const hash = searchParams.get("hash");
  const user_hash = searchParams.get("user_hash");
  const { currentWorkspace } = useSelector((state) => state.authenticationSlice);
  const { refetchBillingData } = useSelector((state) => state.billingSlice);
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [userPermissionType, setUserPermissionType] = useState(null);
  const { showCustomToast } = useCustomToaster();
  const [passwordInputType, setPasswordInputType] = useState({
    password: "password",
  });
  const { workspaceList, deleteModal,
    allUserData, } = useSelector((state) => state.userSlice);
  const { activeStep, validatedSteps } = useSelector(
    (state) => state.createAccountSlice,
  );

  const stepOneFileds = ["first_name", "last_name", "email", "job_title"];
  const stepTwoFileds = ["type"];
  const stepThreeFileds = ["workspaces"];

  //Edit fields
  const stepOneEditFileds = ["email", "type"];
  const stepSecondEditFileds = ["workspaces"];

  const {
    control,
    trigger,
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    setError,
    setFocus,
    clearErrors,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      type: "",
      first_name: "",
      last_name: "",
      email: "",
      job_title: "",
      workspaces: [],
    },
    resolver: !hash ? zodResolver(userSchema) : zodResolver(passwordSchema),
  });

  const {
    actionableUserId,
    isUserEditable,
    isUserCreateModalOpen,
    userDetails,
    userList,
    showDiscardModal,
  } = useSelector((state) => state.userSlice);

  const {
    data: workspaceListData,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ["invitations"],
    queryFn: () => searchWorkspace(),
    enabled: !!currentWorkspace,
  });

  const userIds = userList?.length > 0 && userList?.map((user) => user?.user_id);

  useEffect(() => {
    if (!isLoading && workspaceListData) {
      dispatch(
        setWorkspaceListData({ workspaceList: workspaceListData?.data?.workspaces, append: false }),
      );
    }
  }, [workspaceListData, isLoading]);

  const closeModal = () => {
    setValue("type", null);
    reset();
    dispatch(setSelectedWorkspaces([]));
    dispatch(setActiveStep(1));
    setSelectedOrders([]);
    setUserPermissionType(null)
    if (isUserCreateModalOpen) {
      dispatch(toggleIsUserCreateModalOpen());
    }
    if (actionableUserId) {
      dispatch(toggleActionableUserId());
    }
    if (userDetails) {
      dispatch(setUserDetails());
    }
    if (isUserEditable) {
      dispatch(toggleIsUserEditable());
    }
    if (deleteModal) {
      dispatch(toggleUserDeleteModal());
    }
    if (userIds) {
      dispatch(toggleSelectedUserId());
    }
    if (user_hash) {
      removeQueryParam(["user_hash"], location, navigate)
    }
    removeQueryParam(["selectedTab"], location, navigate);
  };

  const createMutation = useMutation({
    mutationKey: ["user", "createUser"],
    mutationFn: createUser,
    onMutate: () => {
      showCustomToast(
        {
          title: "Validating the request...",
        },
        "createLoader",
      );
    },
    onSuccess: (res) => {
      toast.dismiss("createLoader");
      const data = res.data;
      closeModal()
      refetchUserData()
      queryClient.invalidateQueries({
        queryKey: ["user", "createUser"],
      });
      if (!refetchBillingData) {
        dispatch(setRefetchBillingData(true));
      }
      showCustomToast(
        {
          title: "Invitation sent successfully",
        }
      );
      dispatch(setUserList({ user: data.Invitation, append: true }));
    },
    onError: (error) => {
      toast.dismiss("createLoader");
      const errorMessage = error?.response?.data?.message || error.message;
      const errorMessages = error?.response?.data?.errors;
      const toastMessages = errorMessages ? Object.values(errorMessages).flat() : [errorMessage];
      toastMessages.forEach((errorMessage) => {
        showCustomToast({
          title: errorMessage,
        });
      });
    },
  });

  const updateUserMutation = useMutation({
    mutationKey: ["user", "updateUser"],
    mutationFn: (data) => updateRole(data),
    onMutate: () => {
      showCustomToast(
        {
          title: "Upating the User...",
        }, "updateUserLoadingToast"
      );
    },
    onSuccess: (res) => {
      toast.dismiss("updateUserLoadingToast");
      dispatch(setUserDetails(res.data.User));
      refetchUserData()
      const updatedUser = res.data.User;
      const updatedUsers = userList.map((user) =>
        user.id === updatedUser.id ? updatedUser : user,
      );
      dispatch(setUserList({ append: false, userList: updatedUsers }));
      setDefaultFormValue(res.data.User);
      closeModal()
      showCustomToast(
        {
          title: "User updated successfully",
        }
      );
      queryClient.invalidateQueries({ queryKey: ["user", "updateUser"] });
    },
    onError: (error) => {
      toast.dismiss("updateUserLoadingToast");
      showCustomToast(
        {
          title: error.response?.data?.message || error.message || "Something went wrong",
        }
      );
      if (error.response?.data?.errors) {
        Object.entries(error.response.data.errors).forEach(([field, messages]) => {
          setError(field, { type: "server", message: messages[0] });
          setFocus(field);
        });
      }
    },
  });

  /// Before Set password mutation
  const mutation = useMutation({
    mutationKey: ["user", "setpassword"],
    mutationFn: (data) => {
      const formData = {
        password: data.password,
        hash: hash,
      };
      storePassword(formData);
    },
    onSuccess: (res) => {
      showCustomToast(
        {
          title: "Password set successfully",
        }
      );
      reset();
      queryClient.invalidateQueries("protectedResource");
      navigate("/signin");
    },
    onError: (error) => {
      showCustomToast(
        {
          title: error.response?.data?.message || error.message || "Something went wrong",
        }
      );
    },
  });

  const acceptInvitationMutation = useMutation({
    mutationKey: ["useraccept", "acceptinvitationlogin"],
    mutationFn: ({ hash }) => {
      return acceptStatus({ hash });
    },
    onSuccess: (res) => {
      showCustomToast(
        {
          title: "Invitation has been accepted",
        }
      );
      queryClient.invalidateQueries("protectedResource");
    },
    onError: (error) => {
      showCustomToast(
        {
          title: error.response?.data?.message || error.message || "Something went wrong"
        }
      );
    },
  });

  const handleInvitationCallFnc = () => {
    try {
      acceptInvitationMutation.mutate({ hash: hash });
    } catch (error) {
      console.error("Mutation error:", error);
    }
  };

  const handleSubmitFn = async (data) => {
    console.log(data, "processed   Data");
    const formData = {
      ...data
    }
    if (data.type !== "admin" && data.workspaces.length > 0) {
      const ids = data.workspaces.map(item => item.id);
      formData.workspaces = ids
    }
    if (actionableUserId) {
      const processedData = {
        email: userDetails.email,
        type: data.type,
      };
      if (data.type !== "admin" && data.workspaces.length > 0) {
        const ids = data.workspaces.map(item => item.id);
        processedData.workspaces = ids
      }
      updateUserMutation.mutate(processedData);
    } else {
      const isValid = await trigger(["first_name", "last_name", "email", "password"], {
        focus: true,
      });
      if (!isValid) return;
      createMutation.mutate(formData);
    }
  };

  const handleInvitationSubmitFn = (data) => {
    try {
      mutation.mutate(data); // Ensure mutation is invoked with the correct data
    } catch (error) {
      console.error("Mutation error:", error);
    }
  };

  const setDefaultFormValue = (details) => {
    if (!details && !actionableUserId) return;
    if (details) {

      const { first_name, last_name, email, role, workspaces } = details;
      console.log("hello", email);
      setValue("first_name", first_name);
      setValue("last_name", last_name);
      setValue("email", email);
      setValue("type", role.split(".")[1]);
      if (workspaces && workspaces.length > 0) {
        const filteredWorkspaces = workspaceList.filter(workspace => workspaces.includes(workspace.id));
        setValue("workspaces", filteredWorkspaces);
        dispatch(setSelectedWorkspaces(filteredWorkspaces))
      }
    }
  };

  const handleClickEdiable = () => {
    if (!isUserEditable) {
      dispatch(toggleIsUserEditable());
    }
  };

  const discardChanges = () => {
    if (actionableUserId && userDetails) {
      const previousRole = getPreviousRole();
      const currentRoles = getValues("type");
      if (previousRole !== currentRoles) {
        if (!showDiscardModal) dispatch(toggleShowDiscardModal());
      } else {
        if (isUserEditable) dispatch(toggleIsUserEditable());
      }
    }
  };

  const getPreviousRole = () => {
    if (actionableUserId && userDetails) {
      return userDetails.role.split(".")[1];
    }
    return null;
  };

  const onDiscard = (action) => {
    if (action) {
      const previousRole = getPreviousRole();
      setValue("type", previousRole);
      if (showDiscardModal) dispatch(toggleShowDiscardModal());
      if (isUserEditable) dispatch(toggleIsUserEditable());
    } else {
      if (showDiscardModal) dispatch(toggleShowDiscardModal());
    }
  };

  const togglePasswordType = (data) => {
    if (passwordInputType[data] === "text") {
      setPasswordInputType({ ...passwordInputType, [data]: "password" });
    } else {
      setPasswordInputType({ ...passwordInputType, [data]: "text" });
    }
  };

  const handleClickStepper = async (step) => {
    // if no token and step is equal to 5 then return
    // because are at the end of steps
    if (step === 3) return;

    // Trigger the validation
    if (!validatedSteps.includes(step)) {
      const isValid = await triggerFormValidation();
      if (!isValid) return;
    }
    // Identify the next step from the active steps
    const identicalNextStep = activeStep + 1;
    if (step > identicalNextStep) return;

    //ste the current step as active step
    dispatch(setActiveStep(step));
  };

  const nextStep = async () => {
    const isValid = await triggerFormValidation();
    // Check if the form is valid
    if (isValid && activeStep < CreateUserStepList.length) {
      if (getValues("type") === "admin") {
        handleSubmit(handleSubmitFn)()
      } else {
        dispatch(setActiveStep(activeStep + 1));
      }
    }
    if (!isValid) return;
  };


  const onError = (error) => {
    console.log(error);
  }

  const triggerFormValidation = async () => {
    let fields;
    if (activeStep === 1) {
      fields = stepOneFileds;
    } else if (activeStep === 2) {
      fields = stepTwoFileds;
    } else if (activeStep === 3) {
      fields = stepThreeFileds;
    }
    const isValid = await trigger(fields, { shouldFocus: true });
    return isValid;
  };

  const editNextStep = async () => {
    const isValid = await triggerEditFormValidation();
    // Check if the form is valid
    if (isValid && activeStep < EditUserStepList.length) {
      if (getValues("type") === "admin") {
        handleSubmit(handleSubmitFn)()
      } else {
        dispatch(setActiveStep(activeStep + 1));
      }
    }
    if (!isValid) return;
  };

  const triggerEditFormValidation = async () => {
    let fields;
    if (activeStep === 1) {
      fields = stepOneEditFileds;
    } else if (activeStep === 2) {
      fields = stepSecondEditFileds;
    }
    const isValid = await trigger(fields, { shouldFocus: true });
    return isValid;
  };

  useEffect(() => {
    dispatch(setActiveStep(1));
  }, [pathname]);

  useEffect(() => {
    if (actionableUserId && userList?.length > 0) {
      const user = userList?.find((user) => parseInt(user?.id) === parseInt(actionableUserId));
      dispatch(setUserDetails(user));
      setDefaultFormValue(user);
    }
  }, [actionableUserId, userList]);

  return {
    control,
    errors,
    isPending: createMutation.isPending || updateUserMutation.isPending || mutation.isPending,
    actionableUserId,
    isUserEditable,
    showDiscardModal,
    userDetails,
    register,
    getValues,
    setValue,
    handleSubmit: handleSubmit(handleSubmitFn, onError),
    handleInvitationSubmitFn: handleSubmit(handleInvitationSubmitFn),
    handleClickEdiable,
    discardChanges,
    onDiscard,
    togglePasswordType,
    passwordInputType,
    hash,
    handleInvitationCallFnc,
    handleClickStepper,
    nextStep,
    setActiveStep,
    reset,
    clearErrors,
    watch,
    editNextStep,
    user_hash,
    selectedOrders, setSelectedOrders,
    userIds,
    closeModal,
    userPermissionType,
    setUserPermissionType
  };
};

export default useCreateUserManagement;
