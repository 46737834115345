import React from "react";

const arrow_down_updated = React.forwardRef(
  (
    {
      width = "16",
      height = "16",
      viewBox = "0 0 16 16",
      fill = "none",
      strokeColor = "#475467",
      className,
      ...props
    },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.00001 3.3335V12.6668M8.00001 12.6668L12.6667 8.00016M8.00001 12.6668L3.33334 8.00016"
          stroke={strokeColor}
          strokeWidth="1.33333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  },
);

arrow_down_updated.displayName = "arrow_down_updated";

export default arrow_down_updated;
