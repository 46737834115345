import { emailValidation, passwordPattern } from "utils/constant";
import * as z from "zod";

// schema for the password
export const passwordSchema = z.object({
  password: z.string().refine((value) => passwordPattern.test(value), {
    message: "Required",
  }),
});

// user schema
export const userSchema = z
  .object({
    first_name: z
      .string({
        required_error: "Required",
      })
      .min(3, { message: "Enter at least 3 characters" }).max(100, { message: "First name must not exceed 100 characters" }),
    last_name: z
      .string({
        required_error: "Required",
      })
      .min(1, { message: "Enter at least 1 character" }).max(100, { message: "Last name must not exceed 100 characters" }),
    job_title: z.string().nullable(true).optional(true),
    email: emailValidation,
    type: z
      .union([
        z.enum(["admin", "editor", "viewer"], {
          invalid_type_error: "Required",
        }),
        z.string().min(1, { message: "Required" }),
      ])
      .refine((val) => ["admin", "editor", "viewer"].includes(val), {
        message: "Required",
      }),

    workspaces: z.array(
      z.object({
        id: z.number(),
        name: z.string(),
        purchased_package: z.string(),
        status: z.string(),
      }),
    ),
  })
  .superRefine((values, ctx) => {
    if (values.type === "viewer" || values.type === "editor") {
      if (values.workspaces.length === 0) {
        ctx.addIssue({
          message: "Please select at least one workspace",
          code: z.ZodIssueCode.custom,
          path: ["workspaces"],
        });
      }
    }
  });

export const transferSchema = z.object({
  user_id: z.number(),
  workspace_id: z.number({
    required_error: "Workspace id required",
    invalid_type_error: "Workspace id required",
  }),
});

export const companyProfileSchema = z.object({
  name: z
    .string({
      required_error: "Required",
      invalid_type_error: "Required",
    })
    .min(1, { message: "Required" }).max(100, { message: "Company name must not exceed 100 characters" }),
  address: z
    .string({
      required_error: "Required",
      invalid_type_error: "Required",
    })
    .min(1, {
      message: "Required",
    }),
  street: z.string({
    required_error: "Required",
    invalid_type_error: "Required",
  }).min(1, {
    message: "Required",
  }),
  zip: z.string({
    required_error: "Required",
    invalid_type_error: "Required",
  }).min(1, {
    message: "Required",
  }),
  region: z.string({
    required_error: "Required",
    invalid_type_error: "Required",
  }).min(1, {
    message: "Required",
  }),
  country: z.string({
    required_error: "Required",
    invalid_type_error: "Required",
  }).min(1, {
    message: "Required",
  }),
  country_code: z.string({
    required_error: "Required",
    invalid_type_error: "Required",
  }).min(1, {
    message: "Required",
  }),
  registration_number: z
    .string({
      required_error: "Required",
      invalid_type_error: "Required",
    })
    .min(1, { message: "Required" }),
});
