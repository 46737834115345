import { Button } from "components/UI/Button";
import SearchWorkspaces from "components/UI/SearchWorkspaces";
import React, { useEffect, useState } from "react";
import { SmallCrossIcon } from "assests";
import ErrorMessage from "components/UI/ErrorMessage";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedWorkspaces } from "store/userSlice";

const ThirdStep = ({
  closeModal,
  setValue,
  handleSubmit,
  errors,
  clearErrors,
  actionableUserId
}) => {
  const dispatch = useDispatch();
  const {
    selectedWorkspaces,
    userDetails
  } = useSelector((state) => state.userSlice);
  const handleSelectChange = (newSelected) => {
    dispatch(setSelectedWorkspaces(newSelected))
    setValue("workspaces", newSelected);
    clearErrors("workspaces");
  };
  const handleDeselect = (person) => {
    const updatedSelected = selectedWorkspaces.filter((p) => p.id !== person.id);
    handleSelectChange(updatedSelected);
    setValue("workspaces", updatedSelected);
  };
  return (
    <div className="w-full">
      <div className="flex flex-col gap-1 mt-4 mb-3 lg:mb-5">
        <h3 className="text-lg font-semibold text-global-title">{actionableUserId ? userDetails?.person_name : "Workspace Access"}</h3>
        <p className="text-sm text-global-paragraph ">{actionableUserId ? "Update member account permission." : "Set member workspace permission."} </p>
      </div>
      <div className="z-10">
        <SearchWorkspaces selected={selectedWorkspaces} onSelectChange={handleSelectChange} />
      </div>
      <div className="pt-1.5">
        <ErrorMessage errors={errors?.workspaces?.message} />
      </div>
      <div className={`h-[282px] overflow-y-auto`}>
        {selectedWorkspaces && selectedWorkspaces?.length > 0 && (
          <div className="pt-3 lg:pt-[20px]">
            <div className="flex flex-row flex-wrap gap-x-2 gap-y-5">
              {selectedWorkspaces?.map((person) => (
                <div
                  key={person?.id}
                  className="flex gap-1 justify-between items-center bg-white  h-6 py-[2px] pl-2 pr-1 rounded-[6px] border border-status-stroke-default custom-card"
                >
                  <span className="text-status-text-default text-sm font-medium  whitespace-nowrap truncate">
                    {person?.name}
                  </span>
                  <SmallCrossIcon
                    fillColor={"var(--color-icon-fill-input)"}
                    onClick={() => handleDeselect(person)}
                    className="cursor-pointer"
                  />
                </div>
              ))}
            </div>
          </div>
        )}
      </div>

      <div className="pt-5 lg:pt-[41.5px] flex justify-center lg:justify-end flex-col lg:flex-row items-center gap-3">
        <Button
          variant={"secondary"}
          className={"w-full lg:w-[90px] h-11 py-2.5 px-4 font-semibold text-base"}
          onClick={closeModal}
        >
          Cancel
        </Button>
        <Button
          variant={"primary"}
          className={`w-full  h-11 py-2.5 px-4 font-semibold text-base ${actionableUserId ? "lg:w-[161px]" : "lg:w-[147px]"} `}
          onClick={handleSubmit}
        >
          {actionableUserId ? "Update Member" : "Invite Member"}
        </Button>
      </div>
    </div>
  );
};

export default ThirdStep;
