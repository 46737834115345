import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";

import { useDispatch, useSelector } from "react-redux";
import {
  createAudit,
  deleteAudit,
  getAuditDetailsById,
  getAuditTypes,
  updateAudit,
} from "services/audit.services";
import { getAllSuppliers } from "services/supplier.services";
import {
  setAuditTypeList,
  setWorkspaceauditDetails,
  setauditDetails,
  toggleActionableAuditId,
  toggleActionableWrokspaceAuditId,
  toggleDeleteModal,
  toggleisAuditEditable,
  toggleisWrokspaceAuditCreateModalOpen,
} from "store/auditSlice";
import { setRefetchBillingData } from "store/billingSlice";
import { formattedDateIntoObject } from "utils/helper";
import { workspaceAuditSchema } from "validation/audit.validation";
import useCustomToaster from "./useCustomToaster";
const useWorkspaceAudit = ({ workspaceRefetch, supplierId }) => {
  const queryClient = useQueryClient();
  const { showCustomToast } = useCustomToaster();
  const {
    actionableWrokspaceAuditId,
    isAuditEditable,
    workspaceAuditList,
    certificateList,
    deleteModal,
    isworkspaceAuditCreateModalOpen,
    actionableAuditId,
    workspaceAuditDetails,
  } = useSelector((state) => state.auditSlice);
  //file upload
  const [resultType, setResultType] = useState([]);
  const { refetchBillingData } = useSelector((state) => state.billingSlice);
  const [supplierList, setSupplierList] = useState([]);
  const [isDragOver, setIsDragOver] = useState(false);
  const [fileData, setFileData] = useState([]);
  const [showFileData, setShowFileData] = useState([]);
  const [imagePreviews, setImagePreviews] = useState([]);
  const [hasImage, setHasImage] = useState(false);

  const dispatch = useDispatch();
  const {
    control,
    register,
    reset,
    trigger,
    setValue,
    getValues,
    setError,
    watch,
    clearErrors,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      audit_id: "",
      // partner_workspace_id: "",
      workspace_id: parseInt(supplierId),
      result_type_id: "",
      supplierList: "",
      issue_date: { startDate: "", endDate: "" },
      expired_at: { startDate: "", endDate: "" },
    },
    resolver: zodResolver(workspaceAuditSchema),
  });

  const auditQuery = useQuery({
    queryKey: ["audit", "get", actionableWrokspaceAuditId],
    queryFn: () => getAuditDetailsById(actionableWrokspaceAuditId),
    enabled: !!actionableWrokspaceAuditId,
  });

  const certificateTypeQuery = useQuery({
    queryKey: ["resultType"],
    queryFn: (supplierId) =>
      getAuditTypes({
        workspace_id: parseInt(supplierId),
      }),
    enabled: !!supplierId,
  });

  const allSupplierListQuery = useQuery({
    queryKey: ["supplierList"],
    queryFn: () => getAllSuppliers({ workspace_id: parseInt(supplierId) }),
    enabled: !!supplierId,
  });

  const closeModal = () => {
    if (isworkspaceAuditCreateModalOpen) {
      dispatch(toggleisWrokspaceAuditCreateModalOpen());
    }
    if (actionableWrokspaceAuditId) {
      dispatch(toggleActionableWrokspaceAuditId());
    }
    if (workspaceAuditDetails) {
      dispatch(setWorkspaceauditDetails());
    }
    if (isAuditEditable) {
      dispatch(toggleisAuditEditable());
    }
  };

  const createMutation = useMutation({
    mutationKey: ["audit", "create"],
    mutationFn: (data) => createAudit(data),
    onMutate: () => {
      showCustomToast(
        {
          title: "Creating audit...",
        },
        "loadingCreateAudit",
      );
    },
    onSuccess: (res) => {
      toast.dismiss("loadingCreateAudit");
      dispatch(setWorkspaceauditDetails(res.data.audit));
      reset();
      clearErrors();
      if (!refetchBillingData) {
        dispatch(setRefetchBillingData(true));
      }
      workspaceRefetch();
      setFileData([]);
      setImagePreviews([]);
      setShowFileData([]);
      setHasImage(false);
      closeModal();
      showCustomToast({ title: "Audit created successfully" });
    },
    onError: (error) => {
      toast.dismiss("loadingCreateAudit");
      if (error.response?.data?.errors) {
        Object.entries(error.response.data.errors).forEach(([field, messages]) => {
          setError(field, { type: "server", message: messages[0] });
        });
      } else {
        showCustomToast({
          title: error.response?.data?.message || error.message || "Something went wrong",
        });
      }
    },
    onSettled: () => {
      toast.dismiss("loadingCreateAudit");
      queryClient.invalidateQueries({ queryKey: ["audit"] });
    },
  });

  const updateMutation = useMutation({
    mutationKey: ["audit", "updateAudit"],
    mutationFn: (data) => updateAudit(data),
    onMutate: () => {
      showCustomToast({ title: "Upating the Audit..." }, "updateAuditLoadingToast");
    },
    onSuccess: (res) => {
      toast.dismiss("updateAuditLoadingToast");
      reset();
      clearErrors();
      workspaceRefetch();
      setImagePreviews([]);
      setFileData([]);
      setShowFileData([]);
      setHasImage(false);
      closeModal();
      showCustomToast({ title: "Audit updated successfully" });
      queryClient.invalidateQueries({ queryKey: ["audit", "updateAudit"] });
    },
    onError: (error) => {
      toast.dismiss("updateAuditLoadingToast");
      if (error.response?.data?.errors) {
        Object.entries(error.response.data.errors).forEach(([field, messages]) => {
          setError(field, { type: "server", message: messages[0] });
        });
      } else {
        showCustomToast({
          title: error.response?.data?.message || error.message || "Something went wrong",
        });
      }
    },
    onSettled: () => {
      toast.dismiss("updateAuditLoadingToast");
      queryClient.invalidateQueries({ queryKey: ["audit"] });
    },
  });

  const handleClickEdiable = () => {
    if (!isAuditEditable) {
      dispatch(toggleisAuditEditable());
    }
  };

  const deleteCertificateMutation = useMutation({
    mutationKey: ["audit", "delete"],
    mutationFn: (certificateId) => deleteAudit(certificateId),
    onMutate: () => {
      showCustomToast({ title: "Deleting..." }, "deleteAudit");
    },
    onSuccess: () => {
      toast.dismiss("deleteAudit");
      showCustomToast({ title: "Audit deleted successfully" });
      dispatch(toggleDeleteModal());
      workspaceRefetch();
      if (actionableWrokspaceAuditId) {
        dispatch(toggleActionableAuditId());
      }
      queryClient.invalidateQueries(["audits", "get", "delete"]);
    },
    onError: (error) => {
      toast.dismiss("deleteAudit");
      showCustomToast({ title: error.response.data.message || error.message });
    },
    onSettled: () => {
      toast.dismiss("deleteAudit");
    },
  });

  const handleOnDelete = () => {
    if (!actionableWrokspaceAuditId) return;
    if (!deleteModal) {
      dispatch(toggleDeleteModal());
    }
  };

  const setDefaultFormValue = async (details) => {
    if (!details && !actionableWrokspaceAuditId) return;
    if (details) {
      const {
        result_type_id,
        name,
        partner_workspace_id,
        audit_id,
        issue_date,
        expired_at,
        workspace_id,
        files,
        file,
      } = details;

      setValue("issue_date", formattedDateIntoObject(issue_date));
      setValue("expired_at", formattedDateIntoObject(expired_at));
      setValue("audit_id", audit_id);
      if (result_type_id) {
        const filteredResultType =
          resultType.length > 0 &&
          resultType.find((item) => parseInt(item.value) === parseInt(result_type_id));
        if (filteredResultType) {
          setValue("result_type_id", filteredResultType);
        }
      }
      if (name || partner_workspace_id) {
        const filteredPartnerName =
          supplierList.length > 0 &&
          supplierList.find((item) => parseInt(item.value) === parseInt(partner_workspace_id));
        if (filteredPartnerName) {
          setValue("name", filteredPartnerName);
        }
      }

      if (workspace_id) {
        setValue("workspace_id", workspace_id);
      }
      if (files && files.length > 0) {
        setImagePreviews(files);
        setValue("audit_files", file);
        setFileData(file);
        if (files && files.length > 0) {
          const transformedFiles = files?.map((file) => ({
            url: file.url,
            name: file.file_name,
            size: file.file_size * 1024,
          }));
          setShowFileData(transformedFiles);
          setHasImage(true);
        }
      } else {
        setImagePreviews([]);
        setFileData([]);
        setShowFileData([]);
        setHasImage(false);
      }
    }
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragOver(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragOver(false);
    onFileDropOrChange(e);
  };

  const onFormSubmit = (data) => {
    if (new Date(getValues("expired_at").startDate) < new Date(getValues("issue_date").startDate)) {
      setError("expired_at.startDate", {
        type: "custom",
        message: "Check for the issue date",
      });
      return;
    } else {
      const updatedData = {
        ...data,
        partner_workspace_id: parseInt(data?.name?.value),
      };
      const processedData = processData(updatedData);
      if (actionableWrokspaceAuditId) {
        updateMutation.mutate({ body: processedData, id: actionableWrokspaceAuditId });
      } else {
        createMutation.mutate(processedData);
      }
    }
  };

  const processData = (data) => {
    const processedData = Object.fromEntries(
      Object.entries(data).map(([key, value]) => {
        if (typeof value === "object" && value !== null && "label" in value && "value" in value) {
          return [key, value.value];
        }
        if (
          typeof value === "object" &&
          value !== null &&
          "startDate" in value &&
          "endDate" in value
        ) {
          return [key, value.startDate];
        }
        return [key, value];
      }),
    );
    const formData = new FormData();
    Object.entries(processedData).forEach(([key, value]) => formData.append(key, value));
    if (fileData && fileData.length > 0) {
      fileData.forEach((file, index) => {
        formData.append(`audit_files[${index}]`, file);
      });
    }
    if (actionableWrokspaceAuditId) {
      if (hasImage && fileData && fileData.length === 0) {
        const formData = new FormData();
        formData.append(`audit_files[]`, []);
      } else if (fileData && fileData.length > 0) {
        console.log(fileData, "fileData");
        const formData = new FormData();
        fileData.forEach((file, index) => {
          formData.append(`audit_files[${index}]`, file);
        });
      }
    }

    return formData;
  };

  const onError = (error) => {
    console.log(error);
  };

  const onFileDropOrChange = (e) => {
    const MAX_FILE_SIZE = 10;
    let files = null;
    if (isDragEvent(e)) {
      files = e.dataTransfer.files;
    } else {
      files = e.target.files;
    }

    if (files && files.length > 0) {
      const newFiles = Array.from(files); // Convert FileList to array
      const validFiles = []; // To store valid files only
      const previewPromises = newFiles.map((file, index) => {
        return new Promise((resolve, reject) => {
          // Check file type
          if (file.type !== "application/pdf" && !file.type.startsWith("image/")) {
            showCustomToast({ title: "Please drop a PDF or image file." });
            reject("Invalid file type");
            return;
          }
          // Check file size
          if (file.size > MAX_FILE_SIZE * 1024 * 1024) {
            showCustomToast({ title: "File size exceeds 10MB limit." });
            reject("File size exceeds limit");
            return;
          }
          // Add valid files to the list
          validFiles.push(file);

          // Generate image preview if it's an image file
          if (file.type.startsWith("image/")) {
            const reader = new FileReader();
            reader.onloadend = () => {
              resolve(reader.result);
            };
            reader.onerror = () => {
              reject("Error reading file");
            };
            reader.readAsDataURL(file);
          } else {
            // Resolve with null for non-image files
            resolve(null);
          }
        });
      });

      Promise.all(previewPromises)
        .then((previews) => {
          setValue("audit_files", [...previews]);
          setImagePreviews((prevPreviews) => [...prevPreviews, ...previews]);
          setFileData((prevFileData) => [...prevFileData, ...validFiles]);
          setShowFileData((prevShowFileData) => [...prevShowFileData, ...validFiles]);
          // Clear the input value to allow re-selection of the same files
          if (e.target && e.target.value) {
            e.target.value = null;
          }
        })
        .catch((error) => {
          console.error("Error processing files:", error);
        });
    }
  };

  const handleCertFileDelete = (index) => {
    // Remove the image preview and corresponding file data
    const updatedPreviews = [...imagePreviews];
    const updatedFileData = [...fileData];
    const updatedShowFileData = [...showFileData];
    updatedPreviews.splice(index, 1);
    updatedFileData.splice(index, 1);
    updatedShowFileData.splice(index, 1);
    setImagePreviews(updatedPreviews);
    setFileData(updatedFileData);
    setValue("audit_files", updatedFileData);
    setShowFileData(updatedShowFileData);
  };

  const isDragEvent = (event) => {
    return "dataTransfer" in event;
  };

  useEffect(() => {
    if (certificateTypeQuery) {
      const result = certificateTypeQuery?.data?.data.result_type;
      if (result && result?.length > 0) {
        let modifiedList = result?.map((item) => ({
          label: item?.name && item?.name === "Failed" ? "Fail" : item?.name,
          value: item?.id,
        }));
        dispatch(setAuditTypeList(modifiedList));
        setResultType(modifiedList);
      }
    }
  }, [certificateTypeQuery?.data]);

  useEffect(() => {
    if (allSupplierListQuery) {
      const result = allSupplierListQuery?.data?.data?.data;
      if (result && result?.length > 0) {
        let modifiedList = result
          ?.filter((item) => !item?.pending)
          .map((item) => ({
            value: item.workspace_id,
            label: item?.company_name,
          }));
        setSupplierList(modifiedList);
      }
    }
  }, [allSupplierListQuery?.data]);

  useEffect(() => {
    if (actionableWrokspaceAuditId && auditQuery && auditQuery.isSuccess && !auditQuery.isError) {
      const result = auditQuery?.data?.data?.audit;
      if (result) {
        dispatch(setWorkspaceauditDetails(result));
        setDefaultFormValue(result);
      }
    }
  }, [auditQuery?.data, actionableWrokspaceAuditId, supplierList]);

  return {
    control,
    watch,
    register,
    reset,
    trigger,
    setValue,
    getValues,
    setError,
    clearErrors,
    handleSubmit,
    onFormSubmit,
    fileData,
    resultType,
    setFileData,
    onError,
    errors,
    isDragOver,
    handleDragEnter,
    handleDragLeave,
    handleDrop,
    onFileDropOrChange,
    imagePreviews,
    handleCertFileDelete,
    workspaceAuditDetails,
    setauditDetails,
    setWorkspaceauditDetails,
    isAuditEditable,
    actionableWrokspaceAuditId,
    isAuditDetailsLoading: auditQuery.isLoading,
    isPending: createMutation.isPending || updateMutation.isPending,
    workspaceAuditList,
    handleClickEdiable,
    handleOnDelete,
    deleteCertificateMutation,
    showFileData,
    supplierList,
    isLoading: allSupplierListQuery.isLoading || certificateTypeQuery.isLoading,
  };
};

export default useWorkspaceAudit;
