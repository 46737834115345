const { createSlice } = require("@reduxjs/toolkit");
const templateSlice = createSlice({
  name: "template",
  initialState: {
    isCreateTemplateOpen: false,
    isTemplateEditable: false,
    discardModal: false,
    delAccessDeniedModal: false,
    actionableTemplateId: null,
    actionableWorkspaceId: null,
    templateDetails: null,
    templateList: [],
    templateAllData: null,
    deleteModal: false,
    thresholdModal: false,
    isDuplicateAction: false,
  },
  reducers: {
    toggleCreateTemplateOpen: (state) => {
      state.isCreateTemplateOpen = !state.isCreateTemplateOpen;
    },
    toggleDiscardModal: (state) => {
      state.discardModal = !state.discardModal;
    },
    toggleDeleteModal: (state) => {
      state.deleteModal = !state.deleteModal;
    },
    toggleThresholdModal: (state) => {
      state.thresholdModal = !state.thresholdModal;
    },
    toggleDelAccessDeniedModal: (state) => {
      state.delAccessDeniedModal = !state.delAccessDeniedModal;
    },
    toggleActionableTemplateId: (state, action) => ({
      ...state,
      actionableTemplateId: action.payload ?? null,
    }),
    toggleActionableWorkspaceId: (state, action) => ({
      ...state,
      actionableWorkspaceId: action.payload ?? null,
    }),

    toggleIsTemplateEditable: (state) => ({
      ...state,
      isTemplateEditable: !state.isTemplateEditable,
    }),
    toggleTemplateDuplicateAction: (state) => ({
      ...state,
      isDuplicateAction: !state.isDuplicateAction,
    }),
    setTemplateList: (state, action) => {
      if (action.payload.append) {
        // append the Template to the list
        return {
          ...state,
          templateList: [action.payload.template, ...state.templateList],
        };
      } else {
        // set the Template list
        return {
          ...state,
          templateList: action.payload.templateList,
        };
      }
    },
    setTemplateAllData: (state, action) => ({
      ...state,
      templateAllData: action.payload.templateAllData ?? null,
    }),
    setTemplateDetails: (state, action) => ({
      ...state,
      templateDetails: action.payload ?? null,
    }),
    setTemplateSliceState: (state, action) => ({
      ...state,
      ...action.payload,
    }),
  },
});

export const {
  toggleCreateTemplateOpen,
  toggleIsTemplateEditable,
  toggleDiscardModal,
  toggleActionableTemplateId,
  setTemplateAllData,
  setTemplateList,
  setTemplateDetails,
  setTemplateSliceState,
  toggleDeleteModal,
  toggleThresholdModal,
  toggleDelAccessDeniedModal,
  toggleTemplateDuplicateAction,
  toggleActionableWorkspaceId
} = templateSlice.actions;

export default templateSlice.reducer;
