import React from "react";

const UploadIcon = React.forwardRef(
  (
    {
      width = "16",
      height = "16",
      viewBox = "0 0 16 16",
      fill = "none",
      strokeColor = "#717680",
      className,
      ...props
    },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_13279_2872)">
          <path
            d="M10.6676 10.6667L8.00095 8.00007M8.00095 8.00007L5.33428 10.6667M8.00095 8.00007V14.0001M13.5943 12.2601C14.2445 11.9056 14.7582 11.3447 15.0542 10.6658C15.3502 9.98698 15.4118 9.22889 15.2291 8.5112C15.0464 7.7935 14.6299 7.15708 14.0454 6.70237C13.4609 6.24766 12.7415 6.00056 12.001 6.00007H11.161C10.9592 5.21956 10.5831 4.49496 10.0609 3.88073C9.53877 3.2665 8.88418 2.77864 8.14635 2.45381C7.40852 2.12898 6.60665 1.97564 5.80103 2.00533C4.99541 2.03501 4.20699 2.24694 3.49507 2.62518C2.78314 3.00342 2.16622 3.53814 1.6907 4.18913C1.21517 4.84011 0.89341 5.59043 0.749603 6.38367C0.605797 7.17691 0.643687 7.99242 0.860426 8.76891C1.07716 9.5454 1.46711 10.2626 2.00095 10.8667"
            stroke={strokeColor}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_13279_2872">
            <rect width="16" height="16" fill="white" />
          </clipPath>
        </defs>
      </svg>
    );
  },
);

export default UploadIcon;
