import { CardCvcElement, CardExpiryElement, CardNumberElement } from "@stripe/react-stripe-js";
import { CreditCard, PlusCircle, SubscribeLock } from "assests";
import BorderDesign from "components/UI/BorderDesign";
import { Button } from "components/UI/Button";
import ErrorMessage from "components/UI/ErrorMessage";
import { Input } from "components/UI/Input";
import InstanceCard from "components/UI/InstanceCard";
import { Label } from "components/UI/Label";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { setIsEditingBillingDetails } from "store/billingSlice";
import { findIcon } from "utils/fakeData";
import { handleRestrictNumbersOnKeyPress, handleRestrictNumbersOnPaste } from "utils/helper";

const StripeUpgradeForm = ({
  register,
  errors,
  handleChangeCardItem,
  onShowCompanyDetails,
  handleSubmit,
  isLoading,
  selectedPlanData,
  downgradeOrUpgrade,
  errorMessage,
  isPending
}) => {
  const dispatch = useDispatch();
  const { billingDetails } = useSelector((state) => state.billingSlice);
  const { isEditingBillingDetails, paymentMethodDetails: currentPaymentMethodDetails } =
    useSelector((state) => state.billingSlice);
  const handleEditBillingDetails = () => {
    dispatch(setIsEditingBillingDetails(true));
    onShowCompanyDetails();
  };
  return (
    <div className="w-full max-w-[480px] p-6 relative  z-[2] bg-global-theme border border-global-divider-soft rounded-xl ">
      <div className="relative w-12 ">
        <svg
          width="216"
          height="216"
          viewBox="0 0 216 216"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{
            zIndex: -1,
            position: "absolute",
            top: "14%",
            left: "14%",
            transform: "translate(-14%, -14%)",
          }}
        >
          <g clipPath="url(#clip0_12110_7255)">
            <mask
              id="mask0_12110_7255"
              style={{ maskType: "luminance" }}
              maskUnits="userSpaceOnUse"
              x="0"
              y="0"
              width="216"
              height="216"
            >
              <path d="M216 0H0V216H216V0Z" fill="white" />
            </mask>
            <g mask="url(#mask0_12110_7255)">
              <mask
                id="mask1_12110_7255"
                style={{ maskType: "alpha" }}
                maskUnits="userSpaceOnUse"
                x="-120"
                y="-120"
                width="336"
                height="336"
              >
                <path d="M216 -120H-120V216H216V-120Z" fill="url(#paint0_radial_12110_7255)" />
              </mask>
              <g mask="url(#mask1_12110_7255)">
                <path
                  d="M48 95.5C74.2335 95.5 95.5 74.2335 95.5 48C95.5 21.7665 74.2335 0.5 48 0.5C21.7665 0.5 0.5 21.7665 0.5 48C0.5 74.2335 21.7665 95.5 48 95.5Z"
                  stroke="#E4E7EC"
                />
                <path
                  d="M48 95.5C74.2335 95.5 95.5 74.2335 95.5 48C95.5 21.7665 74.2335 0.5 48 0.5C21.7665 0.5 0.5 21.7665 0.5 48C0.5 74.2335 21.7665 95.5 48 95.5Z"
                  stroke="#E4E7EC"
                />
                <path
                  d="M48 119.5C87.4884 119.5 119.5 87.4884 119.5 48C119.5 8.51164 87.4884 -23.5 48 -23.5C8.51164 -23.5 -23.5 8.51164 -23.5 48C-23.5 87.4884 8.51164 119.5 48 119.5Z"
                  stroke="#E4E7EC"
                />
                <path
                  d="M48 143.5C100.743 143.5 143.5 100.743 143.5 48C143.5 -4.74319 100.743 -47.5 48 -47.5C-4.74319 -47.5 -47.5 -4.74319 -47.5 48C-47.5 100.743 -4.74319 143.5 48 143.5Z"
                  stroke="#E4E7EC"
                />
                <path
                  d="M48 167.5C113.998 167.5 167.5 113.998 167.5 48C167.5 -17.998 113.998 -71.5 48 -71.5C-17.998 -71.5 -71.5 -17.998 -71.5 48C-71.5 113.998 -17.998 167.5 48 167.5Z"
                  stroke="#E4E7EC"
                />
                <path
                  d="M48 191.5C127.253 191.5 191.5 127.253 191.5 48C191.5 -31.2529 127.253 -95.5 48 -95.5C-31.2529 -95.5 -95.5 -31.2529 -95.5 48C-95.5 127.253 -31.2529 191.5 48 191.5Z"
                  stroke="#E4E7EC"
                />
                <path
                  d="M48 215.5C140.508 215.5 215.5 140.508 215.5 48C215.5 -44.5077 140.508 -119.5 48 -119.5C-44.5077 -119.5 -119.5 -44.5077 -119.5 48C-119.5 140.508 -44.5077 215.5 48 215.5Z"
                  stroke="#E4E7EC"
                />
              </g>
            </g>
          </g>
          <defs>
            <radialGradient
              id="paint0_radial_12110_7255"
              cx="0"
              cy="0"
              r="1"
              gradientUnits="userSpaceOnUse"
              gradientTransform="translate(48 48) rotate(90) scale(168)"
            >
              <stop />
              <stop offset="1" stop-opacity="0" />
            </radialGradient>
            <clipPath id="clip0_12110_7255">
              <rect width="216" height="216" fill="white" />
            </clipPath>
          </defs>
        </svg>
        <BorderDesign className={"w-12 h-12 bg-white"}>
          {" "}
          <CreditCard />
        </BorderDesign>
      </div>
      <div className="grid grid-cols-1 gap-4 lg:gap-5 z-[2] ">
        <div className="grid gap-1 ">
          <p className="text-global-title font-semibold text-base lg:text-lg pt-4">Pay with card</p>
          <p className="text-global-paragraph text-sm">Update your card details.</p>
        </div>
        {downgradeOrUpgrade && downgradeOrUpgrade === "UPGRADE" ? (
          <>
            <div className="grid grid-cols-12 gap-4">
              <div className="col-span-12 lg:col-span-8">
                <div className="grid gap-1.5">
                  <Label className="text-sm font-medium text-input-text-label">Name on card</Label>
                  <Input
                    id="cardHolderName"
                    type="text"
                    size="lg"
                    placeholder="Enter name"
                    value={currentPaymentMethodDetails?.billing_details?.name}
                    readOnly={true}
                    className="!bg-transparent"
                  />
                  <ErrorMessage errors={errors?.cardHolderName?.message} />
                </div>
              </div>
              <div className="col-span-12 lg:col-span-4">
                <div className="grid gap-1.5">
                  <Label className="text-sm font-medium text-input-text-label">Expiry</Label>
                  <Input
                    id="cardHolderName"
                    type="text"
                    size="lg"
                    placeholder="Enter card number"
                    className="!bg-transparent"
                    value={`${currentPaymentMethodDetails?.card?.exp_month < 10 ? `0${currentPaymentMethodDetails?.card?.exp_month}` : currentPaymentMethodDetails?.card?.exp_month}/${currentPaymentMethodDetails?.card?.exp_year}`}
                    readOnly={true}
                  />
                  <ErrorMessage errors={errors?.cardExpiry?.message} />
                </div>
              </div>
              <div className="col-span-12 lg:col-span-8">
                <div className="grid gap-1.5 ">
                  <Label className="text-sm font-medium text-input-text-label">Card number</Label>
                  <Input
                    id="cardHolderName"
                    type="text"
                    size="lg"
                    placeholder="Enter card number"
                    className="!bg-transparent !pl-14"
                    defaultValue={
                      currentPaymentMethodDetails &&
                      `*****${currentPaymentMethodDetails?.card?.last4}`
                    }
                    readOnly={true}
                    iconLeft={
                      currentPaymentMethodDetails?.card?.brand &&
                      findIcon(currentPaymentMethodDetails?.card?.brand, 34, 24)
                    }
                  />
                  <ErrorMessage errors={errors?.cardNumber?.message} />
                </div>
              </div>

              <div className="col-span-12 lg:col-span-4">
                <div className="grid gap-1.5">
                  <Label className="text-sm font-medium text-input-text-label">CVV</Label>
                  <Input
                    id="cardHolderName"
                    type="text"
                    size="lg"
                    placeholder="Enter card number"
                    className="!bg-transparent"
                    value={`***`}
                    readOnly={true}
                  />
                  <ErrorMessage errors={errors?.cardCvc?.message} />
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="grid grid-cols-12 gap-4">
            <div className="col-span-12 lg:col-span-8">
              <div className="grid gap-1.5">
                <Label className="text-sm font-medium text-input-text-label">Name on card</Label>
                <Input
                  id="cardHolderName"
                  type="text"
                  size="lg"
                  placeholder="Enter name"
                  onKeyPress={handleRestrictNumbersOnKeyPress}
                  onPaste={handleRestrictNumbersOnPaste}
                  {...register("cardHolderName", {
                    setValueAs: (value) => value.trim(),
                  })}
                />
                <ErrorMessage errors={errors?.cardHolderName?.message} />
              </div>
            </div>
            <div className="col-span-12 lg:col-span-4">
              <div className="grid gap-1.5">
                <Label className="text-sm font-medium text-input-text-label">Expiry</Label>

                <CardExpiryElement
                  as={Input}
                  options={{
                    style: {
                      base: {
                        fontSize: "16px",
                        padding: "12px",
                        backgroundColor: "var(--color-input-surface-background)",
                        border: "1px solid var(--color-input-stroke-default)",
                        borderRadius: "0.5rem",
                        color: "#344054",
                        fontFamily: "inherit",
                        "::placeholder": {
                          color: "#667085",
                          fontWeight: "400",
                        },
                        ":focus": {
                          border: "1px solid #11c182",
                          outline: "1px solid #11c182",
                          transition: "all 300ms ease-in-out",
                        },
                      },
                      placeholder: {
                        color: "var(--color-input-text-placeholder)",
                      },
                    },
                  }}
                  onChange={handleChangeCardItem}
                  className="grid w-full gap-3 h-11 items-center px-3.5 text-base bg-input-surface-background shadow-[0px,1px,2px,0px,rgba(16,24,40,0.05)] focus:shadow-[none]  py-[2.5px] text-input-text-data placeholder:text-input-text-placeholder placeholder:font-normal placeholder:text-base  self-stretch border border-input-stroke-default  hover:border-gray-800 transition duration-300 ease-in-out rounded-lg"
                  required
                />

                <ErrorMessage errors={errors?.cardExpiry?.message} />
              </div>
            </div>
            <div className="col-span-12 lg:col-span-8">
              <div className="grid gap-1.5 ">
                <Label className="text-sm font-medium text-input-text-label">Card number</Label>

                <CardNumberElement
                  options={{
                    showIcon: true,
                    style: {
                      base: {
                        fontSize: "16px",
                        padding: "12px",
                        backgroundColor: "var(--color-input-surface-background)",
                        border: "1px solid var(--color-input-stroke-default)",
                        borderRadius: "0.5rem",
                        color: "#344054",
                        fontFamily: "inherit",
                        "::placeholder": {
                          color: "#667085",
                          fontWeight: "400",
                        },
                        ":focus": {
                          border: "1px solid #11c182",
                          outline: "1px solid #11c182",
                          transition: "all 300ms ease-in-out",
                        },
                      },
                      placeholder: {
                        color: "var(--color-input-text-placeholder)",
                      },
                    },
                  }}
                  onChange={handleChangeCardItem}
                  className="grid w-full gap-3 h-11 items-center px-3.5 text-base bg-input-surface-background shadow-[0px,1px,2px,0px,rgba(16,24,40,0.05)] focus:shadow-[none]  py-[2.5px] text-input-text-data placeholder:text-input-text-placeholder placeholder:font-normal placeholder:text-base  self-stretch border border-input-stroke-default  hover:border-gray-800 transition duration-300 ease-in-out rounded-lg"
                  required
                />
                <ErrorMessage errors={errors?.cardNumber?.message} />
              </div>
            </div>

            <div className="col-span-12 lg:col-span-4">
              <div className="grid gap-1.5">
                <Label className="text-sm font-medium text-input-text-label">CVV</Label>

                <CardCvcElement
                  placeholder="123"
                  options={{
                    style: {
                      base: {
                        fontSize: "16px",
                        padding: "12px",
                        backgroundColor: "var(--color-input-surface-background)",
                        border: "1px solid var(--color-input-stroke-default)",
                        borderRadius: "0.5rem",
                        color: "#344054",
                        fontFamily: "inherit",
                        "::placeholder": {
                          color: "#667085",
                          fontWeight: "400",
                        },
                        ":focus": {
                          border: "1px solid #11c182",
                          outline: "1px solid #11c182",
                          transition: "all 300ms ease-in-out",
                        },
                      },
                      placeholder: {
                        color: "var(--color-input-text-placeholder)",
                      },
                    },
                  }}
                  onChange={handleChangeCardItem}
                  className="grid w-full gap-3 h-11 items-center px-3.5 text-base bg-input-surface-background shadow-[0px,1px,2px,0px,rgba(16,24,40,0.05)] focus:shadow-[none]  py-[2.5px] text-input-text-data placeholder:text-input-text-placeholder placeholder:font-normal placeholder:text-base  self-stretch border border-input-stroke-default  hover:border-gray-800 transition duration-300 ease-in-out rounded-lg"
                  required
                />
                <ErrorMessage errors={errors?.cardCvc?.message} />
              </div>
            </div>
          </div>
        )}

        {billingDetails ? (
          <div className="pt-4 lg:pt-6 pb-3.5">
            <div className="flex flex-row gap-2 items-center">
              <h3 className="text-global-title text-base lg:text-lg font-semibold">
                {billingDetails.company_name}
              </h3>
              <div className="px-2 h-6 bg-global-white rounded-md shadow border border-border-primary justify-start items-center flex">
                <div className="text-center text-status-text-default uppercase text-sm font-medium">
                  {selectedPlanData.name}
                </div>
              </div>
            </div>
            <p className="text-global-paragraph text-xs lg:text-sm font-normal mt-1">
              {billingDetails.street}
            </p>
            <p className="text-global-paragraph text-xs lg:text-sm font-normal">
              {`${billingDetails.region}, ${billingDetails.zip} ${billingDetails.country_code}`}
            </p>
            <p className="text-global-paragraph text-xs lg:text-sm font-normal">
              {`${billingDetails.country}`}
            </p>
            <Button
              type="button"
              variant="tertiary"
              className={
                "w-[27px] h-5 text-btn-text-tertiary font-semibold text-sm bg-transparent mt-2.5"
              }
              onClick={handleEditBillingDetails}
            >
              Edit
            </Button>
          </div>
        ) : (
          <button
            onClick={onShowCompanyDetails}
            className="flex flex-row gap-1 items-center mt-2.5"
          >
            <PlusCircle />
            <p className="text-btn-text-tertiary font-semibold text-sm">Company Details</p>
          </button>
        )}

        <Button
          variant="primary"
          className={
            "h-11 flex gap-1.5 w-full md:min-w-[432px] items-center text-white font-semibold text-base mt-5 mb-6 lg:mt-[22px]"
          }
          isDisabled={isPending || isLoading}
          onClick={handleSubmit}
        >
          {!downgradeOrUpgrade ? "Subscribe" : "Upgrade"}
          <SubscribeLock fillColor="white" width={20} height={20} />
        </Button>
      </div>
      <div>
        {errorMessage && (
          <div className="mt-[-8px]">
            <InstanceCard errorMessage={errorMessage} />
          </div>
        )}
        {!downgradeOrUpgrade && (
          <div className="w-full mt-6">
            <div className="flex items-center justify-start py-1">
              <div className="flex items-center cursor-pointer">
                <label htmlFor="terms" className="flex items-center">
                  <input
                    type="checkbox"
                    id="terms"
                    name="terms"
                    className="mr-2 border-2"
                    {...register("acceptTerms")}
                  />
                </label>
                <div
                  role="button"
                  className="text-global-paragraph font-normal text-base text-start"
                >
                  You agree to our friendly{" "}
                  <Link
                    to={`${process.env.REACT_APP_WEB_URL}/terms-and-conditions`}
                    target="_blank"
                    className="underline underline-offset-2"
                  >
                    terms & conditions.
                  </Link>
                </div>
              </div>
            </div>
            {errors.acceptTerms && <ErrorMessage errors={errors?.acceptTerms?.message} />}
          </div>
        )}
      </div>
    </div>
  );
};

export default StripeUpgradeForm;
