import React from "react";

const fi_search = React.forwardRef(
  (
    {
      width = "20",
      height = "20",
      viewBox = "0 0 20 20",
      fill = "none",
      strokeColor = "#8E8F98",
      className,
      ...props
    },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="fi:search">
          <path
            id="Vector"
            d="M9.17637 15.8333C12.8583 15.8333 15.843 12.8486 15.843 9.16667C15.843 5.48477 12.8583 2.5 9.17637 2.5C5.49447 2.5 2.5097 5.48477 2.5097 9.16667C2.5097 12.8486 5.49447 15.8333 9.17637 15.8333Z"
            stroke={strokeColor}
            strokeWidth="1.6"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Vector_2"
            d="M17.5097 17.5L13.8847 13.875"
            stroke={strokeColor}
            strokeWidth="1.6"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </svg>
    );
  },
);

fi_search.displayName = "fi_search";

export default fi_search;
