import { ForgetPassWordIcon, ResetPasswordEmail, Mail, ArrowLeft, SentEmailSuccess, Mail01Icon } from "assests";
import BackButton from "components/UI/BackButton";
import { Button } from "components/UI/Button";
import Divider from "components/UI/Divider";
import FormHeader from "components/UI/FormHeader";
import SigninLogo from "components/UI/SigninLogo";
import useForgetPassword from "hooks/useForgetPassword";
import React, { useEffect, useState } from "react";
import { Navigate, useParams } from "react-router-dom";

const ResetPasswordEmailSentSuccess = () => {
  let { email } = useParams();
  const { resendInstruction } = useForgetPassword();
  const [timeLeft, setTimeLeft] = useState(60);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const decryptedEmail = atob(email)
  const handleResend = (e) => {
    e.preventDefault();
    resendInstruction(decryptedEmail);
    setIsButtonDisabled(true);
    setTimeLeft(60);
  };


  useEffect(() => {
    if (timeLeft > 0) {
      const timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
      return () => clearInterval(timer);
    } else {
      setIsButtonDisabled(false);
    }
  }, [timeLeft]);

  if (!email || email === "undefined") {
    return <Navigate to="/signin" replace={true} />;
  }

  return (
    <div className="flex items-center justify-center">
      <div className="relative md:w-[360px] rounded-3xl px-4 md:px-0 ">
        <div className="flex flex-col gap-8">
          <div className="flex flex-col gap-6">
            <SigninLogo>
              <Mail01Icon strokeColor="#344054" width={28} height={28} />
            </SigninLogo>

            <div className="flex-col justify-center items-center inline-flex gap-3">
              <FormHeader
                icon={false}
                title="Check your email"
                subtitle={`Check your ${decryptedEmail}`}
                subtitle2={`inbox for instructions from us on how to reset your `}
                subtitle3="password."
              />
            </div>
          </div>
          <a href="mailto:">
            <div className="flex justify-center">
              <Button
                className="w-full text-base h-[44px] font-semibold"
              >
                Open email app
              </Button>
            </div>
          </a>
          <div className="flex items-center justify-center">
            <p className="relative pl-2 pr-1 text-sm font-normal  text-login-text-paragraph whitespace-nowrap">
              Didn’t receive the email?
            </p>
            <Button
              onClick={(e) => handleResend(e)}
              isDisabled={isButtonDisabled}
              className="p-0 m-0 pr-2  bg-transparent hover:bg-transparent disabled:bg-transparent text-sm font-semibold  text-btn-text-link hover:text-global-primary whitespace-nowrap "
            >
              {isButtonDisabled ? `Click to resend (${timeLeft}s)` : 'Click to resend'}
            </Button>
          </div>
          <BackButton text="Back to log in" url="/signin" />
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordEmailSentSuccess;
