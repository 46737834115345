import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import {
  registration,
  resendEmailValidationOTP,
  validateEmailOTP,
  validateRegistrationFields,
} from "services/auth.services";
import { getAcceptInvitationDetails } from "services/user.services";
import { clearAuthData } from "store/authenticationSlice";
import { setActiveStep, setValidatedSteps } from "store/createAccountSlice";
import { CreateAccountStepList } from "utils/constant";
import { businessPartnerList } from "utils/fakeData";
import { permissions } from "utils/permissions";
import { registerSchema } from "validation/auth.validation";
import useCustomToaster from "./useCustomToaster";
import { isValidPhoneNumber } from "react-phone-number-input";
const useRegistration = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { pathname } = useLocation();
  const { activeStep, validatedSteps, selectedCountryId } = useSelector(
    (state) => state.createAccountSlice,
  );
  const [defaultCountryCode, setDefaultCountryCode] = useState();
  const [phoneNumberErrorMessage, setPhoneNumberErrorMessage] = useState(null);
  const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(true);
  const [filterdInviteViewList, setFilterdInviteViewList] = useState([]);
  const { token } = useSelector((state) => state.authenticationSlice);
  const [searchParams] = useSearchParams();
  const hash = searchParams.get("hash");
  const email = searchParams.get("email");
  const navigate = useNavigate();
  const { showCustomToast } = useCustomToaster();
  // Local state

  const [passwordInputType, setPasswordInputType] = useState("password");
  const [confirmPasswordInputType, setConfirmPasswordInputType] = useState("password");
  const togglePasswordType = () => {
    setPasswordInputType((prevType) => (prevType === "password" ? "text" : "password"));
  };

  const toggleConfirmPasswordType = () => {
    setConfirmPasswordInputType((prevType) => (prevType === "password" ? "text" : "password"));
  };

  const [tempAuthToken, setTempAuthToken] = useState(token || null);

  const stepOneFileds = ["firstName", "lastName", "email", "telephone"];
  const stepTwoFileds = ["companyName", "company_type", "address", "registration_number"];
  const stepThreeFileds = ["password", "password_confirmation"];

  const {
    register,
    control,
    reset,
    setValue,
    trigger,
    getValues,
    clearErrors,
    setError,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      telephone: "",
      password: "",
      password_confirmation: "",
      companyName: "",
      address: "",
      registration_number: "",
      company_type: null,
      street: "",
      zip: "",
      region: "",
      country: "",
      country_code: "",
    },
    resolver: zodResolver(registerSchema),
  });
  const { data: userData } = useQuery({
    queryKey: ["user", "getUserData", email, hash],
    queryFn: () => getAcceptInvitationDetails({ hash, email }),
    enabled: !!hash && !!email,
  });

  useEffect(() => {
    if (userData) {
      if (!userData.data.user_exist && userData.data.workspace_required) {
        const {
          person_name,
          email,
          first_name,
          last_name,
          company_name,
          company_type,
          source,
          workspace,
        } = userData.data.invitation;
        if (!first_name || !last_name) {
          const [first, ...last] = person_name.split(" ");
          setValue("firstName", first);
          setValue("lastName", last.join(" "));
        }
        setValue("firstName", first_name);
        setValue("lastName", last_name);
        setValue("email", email);
        setValue("companyName", company_name);
        if (company_type) {
          const findcompanyType = businessPartnerList.find((item) => item.value === company_type);
          setValue("company_type", findcompanyType);
        }
        if (source === "member") {
          const type = workspace?.purchased_package;
          if (type) {
            const { invitedPersonCanView } = permissions[`${type}.admin`];
            const filteredList = businessPartnerList.filter((item) =>
              invitedPersonCanView.includes(item.value),
            );
            setFilterdInviteViewList(filteredList);
          }
        } else {
          setFilterdInviteViewList(businessPartnerList);
        }
      }
      if (userData.data.user_exist && !userData.data.workspace_required) {
        navigate(`/signin?hash=${hash}`);
      }
    }
  }, [userData, hash, navigate, setValue]);

  const handleSuccess = (response) => {
    const data = response.data;
    if (data.status) {
      toast.dismiss("creatingAccount");
      showCustomToast({
        title: data.message,
      });
      setTempAuthToken(data.token);
      dispatch(setActiveStep(activeStep + 1));
    }
    queryClient.invalidateQueries({
      queryKey: ["auth", "register"],
    });
  };
  const handleError = (error) => {
    toast.dismiss("creatingAccount");
    if (error?.response?.data?.errors) {
      const errorMessages = Object.values(error?.response?.data?.errors).flat();
      errorMessages.forEach((errorMessage) => {
        showCustomToast({
          title: errorMessage,
        });
      });
    } else {
      const errorMessage = error?.response?.data?.title || error.title;
      const subErrorMessage = error?.response?.data?.message || error.message;
      if (errorMessage && subErrorMessage) {
        showCustomToast({
          title: errorMessage,
          subtitle: subErrorMessage,
        });
      } else {
        showCustomToast({
          title: subErrorMessage,
        });
      }
    }
  };

  const handleChangeCompanyType = (selectedOption) => {
    if (selectedOption) {
      setValue("company_type", selectedOption);
    } else {
      setValue("company_type", null);
    }
  };

  const mutation = useMutation({
    mutationKey: ["auth", "register"],
    mutationFn: registration,
    onMutate: () => {
      showCustomToast(
        {
          title: "Creating Account...",
        },
        "creatingAccount",
      );
    },
    onSuccess: handleSuccess,
    onError: handleError,
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ["auth", "register"],
      });
      toast.dismiss("creatingAccount");
    },
  });

  const confirmEmailMutation = useMutation({
    mutationKey: ["auth", "confirmEmail"],
    mutationFn: (data) => validateEmailOTP(data),
    onError: handleError,
  });

  const validateFieldsMutation = useMutation({
    mutationKey: ["auth", "validateFields", activeStep],
    mutationFn: (data) => validateRegistrationFields(data),
    onMutate: () => {
      showCustomToast(
        {
          title: "Validating...",
        },
        "validatingFields",
      );
    },
    onSuccess: (response) => {
      const data = response.data;
      dispatch(setActiveStep(activeStep + 1));
    },
    onError: (error) => {
      toast.dismiss("validatingFields");
      if (error?.response?.data?.errors) {
        const errors = error?.response?.data?.errors;
        Object.keys(errors).map((keys) => {
          const value = errors[keys];
          switch (keys) {
            case "email":
              showCustomToast({
                title: "Email Taken",
                subtitle: "This email has already been taken.",
              });
              break;
            case "companyName":
              showCustomToast({
                title: "Company Name Taken",
                subtitle: "This company name has already been taken.",
              });
              break;
            case "registration_number":
              showCustomToast({
                title: "Registration Number Taken",
                subtitle: "This company registration number has already been taken.",
              });
              break;
            default:
              const message = Object.values(value).flat();
              showCustomToast({
                title: message,
              });
              break;
          }
        });
      } else {
        const errorMessage = error?.response?.data?.message || error.message;
        showCustomToast({
          title: errorMessage,
        });
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ["auth", "validateFields"],
      });
      toast.dismiss("validatingFields");
    },
  });

  /**
   * Handles the click event on the stepper.
   *
   * @param {number} step - The step to navigate to.
   * @return {Promise<void>} A promise that resolves when the step is set.
   */
  const handleClickStepper = async (step) => {
    // if no token and step is equal to 5 then return
    // because are at the end of steps
    if (token || step === 5) return;

    // Trigger the validation
    if (!token && !validatedSteps.includes(step)) {
      const isValid = await triggerFormValidation();
      if (!isValid) return;
    }
    // Identify the next step from the active steps
    const identicalNextStep = activeStep + 1;
    if (step > identicalNextStep) return;

    //ste the current step as active step
    dispatch(setActiveStep(step));
  };

  const nextStep = async () => {
    const isValid = await triggerFormValidation();
    if (!isValid || isNextButtonDisabled) return;
    if (activeStep === 1 && isValid) {
      const processedValues = processCreateValues(getValues());
      validateFieldsMutation.mutate({ ...processedValues, step: 1 });
    }
    if (activeStep === 2 && isValid) {
      const processedValues = processCreateValues(getValues());
      validateFieldsMutation.mutate({ ...processedValues, step: 2 });
    }
    if (isValid && activeStep <= CreateAccountStepList.length - 1) {
      if ((activeStep === 1 || activeStep === 2) && !validateFieldsMutation.isSuccess) {
        return;
      } else {
        dispatch(setValidatedSteps(activeStep));
        if (activeStep === 3) {
          const processedValues = processCreateValues(getValues());
          mutation.mutate(processedValues);
        } else {
          dispatch(setActiveStep(activeStep + 1));
        }
      }
    }
  };

  const triggerFormValidation = async () => {
    let fields;
    if (activeStep === 1) {
      fields = stepOneFileds;
    } else if (activeStep === 2) {
      fields = stepTwoFileds;
    } else if (activeStep === 3) {
      fields = stepThreeFileds;
    }

    const isValid = await trigger(fields, { shouldFocus: true });
    return isValid;
  };

  const handlePhoneNumberChange = (value) => {
    if (value === undefined || typeof value !== "string" || !isValidPhoneNumber(value)) {
      setValue("telephone", value);
      setPhoneNumberErrorMessage("Invalid phone number");
      setIsNextButtonDisabled(true);
    } else {
      setPhoneNumberErrorMessage(null);
      clearErrors("telephone");
      setValue("telephone", value);
      setIsNextButtonDisabled(false);
    }
  };

  const resendOtp = async (e) => {
    try {
      e.preventDefault();
      e.stopPropagation();
      const res = await resendEmailValidationOTP({ token: tempAuthToken });
      if (res.status !== 200 || !res.data.status) {
        handleError(res);
        return;
      }
      showCustomToast({
        title: `New code sent!`,
        subtitle: `If you still haven’t received the email, please`,
        url: "contact us.",
      });
    } catch (error) {
      handleError(error);
    }
  };

  const validateTheOtp = async (data) => {
    const payloadData = { token: tempAuthToken, body: data };
    confirmEmailMutation.mutate(payloadData, {
      onSuccess: (response) => {
        const data = response.data;
        if (data.status) {
          dispatch(clearAuthData());
          showCustomToast({
            title: "Email verified successfully",
            subtitle:
              "Your email address has been successfully verified. You can now log in and access all features of your account.",
          });
          dispatch(setActiveStep(activeStep + 1));
        }
      },
    });
  };

  const processCreateValues = (values) => {
    if (hash) {
      return {
        ...values,
        company_type: values.company_type.value,
        telephone: values.telephone.replace("+", ""),
        hash: hash,
      };
    } else {
      return {
        ...values,
        company_type: values.company_type.value,
        telephone: values.telephone.replace("+", ""),
      };
    }
  };

  useEffect(() => {
    dispatch(setActiveStep(1));
  }, [pathname]);

  return {
    control,
    passwordInputType,
    confirmPasswordInputType,
    errors,
    isRegistering: mutation.isPending,
    isEmailValidating: confirmEmailMutation.isPending,
    register,
    nextStep,
    setValue,
    getValues,
    resendOtp,
    validateTheOtp,
    togglePasswordType,
    toggleConfirmPasswordType,
    handleClickStepper,
    handleError,
    hash,
    dispatch,
    confirmEmailMutation,
    setDefaultCountryCode,
    defaultCountryCode,
    setPhoneNumberErrorMessage,
    phoneNumberErrorMessage,
    isValidPhoneNumber,
    handlePhoneNumberChange,
    watch,
    handleChangeCompanyType,
    filterdInviteViewList,
  };
};

export default useRegistration;
