import { UpgradeBg } from "assests";
import React from "react";
import BorderDesign from "./BorderDesign";
import zIndex from "@mui/material/styles/zIndex";

const UpgradeComponent = ({ children }) => {
  return (
    <div className="relative w-12 ">
      <UpgradeBg
        style={{
          zIndex: -1,
          position: "absolute",
          top: "14%",
          left: "14%",
          transform: "translate(-14%, -14%)",
        }}
      />
      <BorderDesign className={"w-12 h-12 bg-white"}>{children}</BorderDesign>
    </div>
  );
};

export default UpgradeComponent;
