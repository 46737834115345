import React from "react";

const AccountMemberIcon = React.forwardRef(
  (
    {
      width = "26",
      height = "26",
      viewBox = "0 0 26 26",
      fill = "none",
      fillColor = "white",
      className,
      ...props
    },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M23.8334 22.75V20.5833C23.8334 18.5642 22.4524 16.8676 20.5834 16.3865M16.7917 3.56499C18.3798 4.20782 19.5 5.76475 19.5 7.58333C19.5 9.40191 18.3798 10.9588 16.7917 11.6017M18.4167 22.75C18.4167 20.7309 18.4167 19.7214 18.0868 18.925C17.647 17.8633 16.8034 17.0197 15.7416 16.5799C14.9453 16.25 13.9358 16.25 11.9167 16.25H8.66669C6.64761 16.25 5.63807 16.25 4.84173 16.5799C3.77994 17.0197 2.93635 17.8633 2.49654 18.925C2.16669 19.7214 2.16669 20.7309 2.16669 22.75M14.625 7.58333C14.625 9.97657 12.6849 11.9167 10.2917 11.9167C7.89845 11.9167 5.95835 9.97657 5.95835 7.58333C5.95835 5.1901 7.89845 3.25 10.2917 3.25C12.6849 3.25 14.625 5.1901 14.625 7.58333Z"
          stroke={fillColor}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  },
);

AccountMemberIcon.displayName = "AccountMemberIcon";
export default AccountMemberIcon;
