import { LogoCollpased, MoreOptions } from "assests";

import { IconButton, Menu } from "@mui/material";
import DeleteWorkspace from "components/MoreAction/DeleteWorkspace";
import MoreActionOptionUpdated from "components/MoreAction/MoreActionOptionUpdated";
import BorderDesign from "components/UI/BorderDesign";
import { Button } from "components/UI/Button";
import ChipButton from "components/UI/ChipButton";
import Divider from "components/UI/Divider";
import { LineProgressBar } from "components/UI/LineProgressBar";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  setActionableInvitationId,
  setActionableItemData,
  toggleDeleteWorkspaceModal,
  toggleLeaveWorkspaceModal,
  toggleShowAcceptInviteModal,
} from "store/accountDashboardSlice";
import { transpercencyPercentageColors } from "utils/helper";

const DashboardOwnerCard = ({ item, index }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { currentWorkspace } = useSelector((state) => state.authenticationSlice);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClickOptionButton = (actionType, id, item) => {
    switch (actionType) {
      case "delete":
        dispatch(toggleDeleteWorkspaceModal());
        dispatch(setActionableInvitationId(id));
        setAnchorEl(null);
        break;
      case "leave":
        dispatch(toggleLeaveWorkspaceModal());
        dispatch(setActionableInvitationId(id));
        setAnchorEl(null);
        break;
      case "accept_invite":
        dispatch(toggleShowAcceptInviteModal());
        dispatch(setActionableItemData(item));
        setAnchorEl(null);
        break;
      default:
        break;
    }
  };

  return (
    <>
      <div
        className={
          "relative w-full sm:w-[344px]   max-h-[218px] rounded-xl bg-global-theme  custom-card border border-global-gray-soft"
        }
      >
        <div className="flex flex-col gap-3 p-5 relative">
          <div className="py-2 ">
            <div className="flex flex-row justify-start items-center gap-3 w-[90%]">
              <div
                className="rounded-xl"
                style={{
                  boxShadow:
                    "0px 1.25px 2.5px 0px rgba(16, 24, 40, 0.06), 0px 1.25px 3.75px 0px rgba(16, 24, 40, 0.1)",
                }}
              >
                <BorderDesign
                  className={`w-[45px] h-[45px] rounded-xl ${item?.status === "accepted" ? "bg-global-primary" : "bg-global-paragraph"}`}
                  boxShadowShow={false}
                >
                  <LogoCollpased fillColor="var(--color-white)" />
                </BorderDesign>
              </div>
              <div className="text-global-title font-semibold text-sm lg:text-base truncate ">
                {item?.status === "accepted" ? `${item?.name}` : item?.workspace?.name}
              </div>
              <ChipButton>
                {item?.status === "accepted"
                  ? `${item?.purchased_package}`
                  : item?.workspace?.purchased_package}
              </ChipButton>
            </div>

            {currentWorkspace && currentWorkspace.role_name.includes("admin") && (
              <div
                className={`absolute  top-4 right-[7.5px] ${item?.status === "accepted" ? "block" : "hidden"}`}
              >
                <IconButton
                  aria-label="more"
                  id={`${index}`}
                  aria-controls={open ? "long-menu" : undefined}
                  aria-expanded={open ? "true" : undefined}
                  aria-haspopup="true"
                  onClick={(e) => {
                    setAnchorEl(e.currentTarget);
                    e.stopPropagation();
                  }}
                >
                  <MoreOptions width={24} height={24} />
                </IconButton>
                <Menu
                  id="long-menu"
                  MenuListProps={{
                    "aria-labelledby": "long-button",
                  }}
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  elevation={0}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  PaperProps={{
                    style: {
                      maxHeight: 45 * 4.5,
                      borderRadius: "8px",
                      border: "1px solid var(--color-border-secondary)",
                      padding: 0,
                      backgroundColor: "transparent",
                      boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.05)",
                    },
                  }}
                >
                  <MoreActionOptionUpdated
                    deleteWorkspace={
                      item?.invited ? (
                        <DeleteWorkspace
                          title="Delete Workspace"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleClickOptionButton("delete", item.invitation_id, null);
                          }}
                        />
                      ) : (
                        <DeleteWorkspace
                          title="Leave Workspace"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleClickOptionButton("leave", item.id, null);
                          }}
                        />
                      )
                    }
                  />
                </Menu>
              </div>
            )}
          </div>

          <div className="text-global-paragraph font-normal capitalize text-sm">
            {item?.status === "accepted" ? `Transparency %` : "Accept Invite"}
          </div>
          <div className="flex justify-between items-center gap-3">
            <LineProgressBar
              className="h-2"
              variant={
                item?.status === "accepted"
                  ? transpercencyPercentageColors(item.transparency)
                  : transpercencyPercentageColors(0)
              }
              progress={item?.status === "accepted" ? item.transparency : 0}
            />
            <div className="text-global-title-2 font-medium text-sm">
              {item?.status === "accepted" ? item.transparency : 0}%
            </div>
          </div>
        </div>
        <Divider />
        <div className="w-full flex  justify-end items-center">
          {item?.status === "accepted" ? (
            <Link to={`/workspace/${item.id}/orders`}>
              <Button variant={"link"} className="py-4 px-6 flex-1 justify-end text-end">
                View workspace
              </Button>
            </Link>
          ) : (
            <Button
              onClick={() => {
                handleClickOptionButton("accept_invite", null, item);
              }}
              variant={"link"}
              className="py-4 px-6 flex-1 justify-end text-end"
            >
              Accept Invite
            </Button>
          )}
        </div>
      </div>
    </>
  );
};

export default DashboardOwnerCard;
