import { useEffect, useState } from "react";
import { CircularProgressbarWithChildren, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { getCssVariable } from "utils/helper";
import CircleProgressReduceBar from "./CircleProgressReduceBar";

export const calculatePadding = (size = 96) => {
  const basePaddingRatio = 0.21;
  const padding = size * basePaddingRatio;
  return Math.floor(padding);
};

const CircleProgressBarMultiple = ({
  currentProgress,
  size,
  strokeColorVariable,
  childrenProgress,
  childrenSize,
  childrenStrokeColorVariable,
  subText,
  text,
}) => {
  const [strokeColor, setStrokeColor] = useState("");

  useEffect(() => {
    if (strokeColorVariable) {
      const color = getCssVariable(strokeColorVariable);
      setStrokeColor(color);
    }
  }, [strokeColorVariable]);

  const calculatedSize = size ? size * 4 : 96;
  const paddingSize = calculatePadding(calculatedSize);

  return (
    <div
      className="relative"
      style={{ height: `${calculatedSize}px`, width: `${calculatedSize}px` }}
    >
      <div className="absolute z-10">
        <CircularProgressbarWithChildren
          value={currentProgress}
          styles={buildStyles({
            pathColor: strokeColor,
            transition: "stroke-dashoffset 0.5s ease 0s",
            trailColor: "#F2F4F7",
          })}
          strokeWidth={7}
        >
          <div>
            <CircleProgressReduceBar
              currentProgress={childrenProgress}
              size={childrenSize}
              strokeColorVariable={childrenStrokeColorVariable}
              title={text}
              subText={subText}
            />
          </div>
        </CircularProgressbarWithChildren>
      </div>
      <div
        className="w-full h-full rounded-full bg-progress-circle-surface-element"
        style={{ padding: `${paddingSize}px` }}
      >
        <div className="w-full h-full rounded-full bg-progress-circle-surface-element"></div>
      </div>
    </div>
  );
};

export default CircleProgressBarMultiple;
