import { useQuery } from "@tanstack/react-query";
import { FilterOption, TickIcon, XCloseIcon } from "assests";
import { Button } from "components/UI/Button";
import CustomDropdownCard from "components/UI/CustomDropdownCard";
import TransparencySlider from "components/UI/TransparencySlider";
import { useEffect, useRef, useState } from "react";
import { RxCross2 } from "react-icons/rx";
import { useSelector } from "react-redux";
import Datepicker from "react-tailwindcss-datepicker";
import { getCertificateTypeList } from "services/certificate-type.services";
import { certifiedByList } from "services/certified-by.services";
import { getAllSuppliers } from "services/supplier.services";
import { convertDate, filterNonEmptyKeys, setTimeInDateObject } from "utils/helper";
const CertificateFilters = ({
  workspaceId,
  activeFilters,
  setActiveFilters,
  setFilteredData,
  certificateOptions,
  setCertificateOptions,
}) => {
  const [isHovered, setIshovered] = useState(false);
  const [certificateType, setCertificateType] = useState([]);
  const { user, currentWorkspace } = useSelector((state) => state.authenticationSlice);
  const filterOptions = [
    "status",
    "days_until_expiry",
    "certificate_no",
    "certificate_type",
    "supplier_name",
    "issue_date",
    "expiry_date",
    "certified_by",
  ];
  const [supplierList, setSupplierList] = useState([]);
  const statusOptions = [
    {
      label: "Active",
      value: "active",
    },
    {
      label: "Expired",
      value: "expired",
    },
  ];

  const filterRef = useRef(null);
  const childfilterRef = useRef(null);
  const [transparencyRange, setTransparencyRange] = useState([0, 365]);
  const [hoveredId, setHoveredId] = useState({});
  const [mainDropdownVisible, setMainDropdownVisible] = useState(false);
  const [currentFilterDropdown, setCurrentFilterDropdown] = useState(null);
  const [certifiedBy, setCertifiedBy] = useState([]);

  const { data: queryData, isLoading: isArticleTypeListLoading } = useQuery({
    queryKey: ["certificateType", "suppliers", "getList"],
    queryFn: () =>
      Promise.all([
        getCertificateTypeList(),
        getAllSuppliers({ workspace_id: parseInt(workspaceId) }),
        certifiedByList(),
      ]),
    enabled: !!workspaceId,
  });

  // Toggle Main Dropdown
  const toggleMainDropdown = () => {
    setMainDropdownVisible(!mainDropdownVisible);
    setCurrentFilterDropdown(null);
  };

  useEffect(() => {
    const certificateTypeListData = queryData?.[0];
    const supplierData = queryData?.[1];
    const certifiedByData = queryData?.[2];
    if (certificateTypeListData?.data?.certificate_types) {
      let modifiedList = certificateTypeListData?.data?.certificate_types?.map((item) => ({
        label: item?.name,
        value: item?.name,
      }));
      setCertificateType(modifiedList);
    }
    if (supplierData?.data?.data) {
      const result = supplierData?.data?.data;
      if (result && result?.length > 0) {
        let modifiedList = result
          ?.filter((item) => !item?.pending)
          .map((item) => ({
            value: item?.company_name,
            label: item?.company_name,
          }));
        setSupplierList(modifiedList);
      }
    }
    if (certifiedByData?.data?.certified_by) {
      let modifiedList = certifiedByData?.data?.certified_by?.map((item) => ({
        label: item?.name,
        value: item?.name,
      }));
      setCertifiedBy(modifiedList);
    }
  }, [queryData]);
  // Add Filter
  const addFilter = (filterName) => {
    if (!activeFilters[filterName]) {
      setActiveFilters((prev) => ({
        ...prev,
        [filterName]: filterName === "Transparency Score" ? transparencyRange : [],
      }));
    }
    // setCurrentFilterDropdown(filterName);
  };

  // Remove Filter
  const removeFilter = (filterName) => {
    setActiveFilters((prev) => {
      const updatedFilters = { ...prev };
      delete updatedFilters[filterName];
      return updatedFilters;
    });
    setCurrentFilterDropdown(null);
    if (filterName === "days_until_expiry") {
      setTransparencyRange([0, 365]);
    }
  };

  const addFilterValue = (filterName, value, label) => {
    setActiveFilters((prev) => {
      const updatedValues = prev[filterName] || [];
      if (!updatedValues.some((item) => item.value === value)) {
        updatedValues.push({ value, label });
      }
      // Return the updated filters
      return { ...prev, [filterName]: updatedValues };
    });
  };

  const removeFilterValue = (filterName, value) => {
    setActiveFilters((prev) => {
      const updatedValues = prev[filterName]?.filter((item) => item.value !== value);
      if (!updatedValues || updatedValues.length === 0) {
        const { [filterName]: _, ...rest } = prev; // Destructure to exclude the filterName
        return rest;
      }
      return { ...prev, [filterName]: updatedValues };
    });
  };

  // Handle Transparency Slider Change
  const handleSliderChange = (range) => {
    setTransparencyRange(range);
    setActiveFilters((prev) => ({
      ...prev,
      days_until_expiry: range,
    }));
  };

  useEffect(() => {
    if (activeFilters) {
      const transformedData = {
        status: activeFilters.status,
        ranges: activeFilters.days_until_expiry,
        issue_date: activeFilters.issue_date,
        expire_date: activeFilters.expiry_date,
        supplier: activeFilters.supplier_name,
        certified_by: activeFilters.certified_by,
        certificate_type: activeFilters.certificate_type,
        certificate_no: activeFilters.certificate_no,
      };
      const updatedFilteredData = filterNonEmptyKeys(transformedData);
      setFilteredData(updatedFilteredData);
    }
  }, [activeFilters]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (filterRef.current && !filterRef.current.contains(event.target)) {
        setMainDropdownVisible(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (childfilterRef.current && !childfilterRef.current.contains(event.target)) {
        setCurrentFilterDropdown(null);
      }
    };
    document.addEventListener("click", handleClickOutside);
    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [currentFilterDropdown]);


  useEffect(() => {
    if (user && currentWorkspace) {
      const handleKeyDown = (event) => {
        // Check if 'Ctrl + R' is pressed

        if ((event.ctrlKey || event.metaKey) && event.shiftKey && (event.key === "Y" || event.key === "y")) {
          event.preventDefault();
          setMainDropdownVisible(true);
        }
      };
      window.addEventListener("keydown", handleKeyDown);
      return () => {
        window.removeEventListener("keydown", handleKeyDown);
      };
    }
  }, [user, currentWorkspace]);

  return (
    <div className={`flex flex-col md:flex-row md:flex-wrap  w-full ${activeFilters && Object.keys(activeFilters).length > 0 && 'gap-2'}`}>
      <div ref={childfilterRef} className="flex flex-col md:flex-row md:flex-wrap gap-2 order-2 md:order-1">
        {activeFilters &&
          Object.keys(activeFilters).map((filter) => (
            <div key={filter} className="relative">
              <Button
                variant="secondary"
                className="text-txt-secondary px-3.5 py-2.5 justify-between h-10 lg:h-auto  gap-1 truncate w-full md:w-auto text-sm tracking-wide font-normal  flex"
                onClick={() => {
                  setCurrentFilterDropdown(currentFilterDropdown === filter ? null : filter);
                  setMainDropdownVisible(false);
                }}
              >
                <div>
                  <span className="capitalize">
                    {filter === "certificate_no" ? "Certificate No." : filter?.replace(/_/g, " ")}
                  </span>
                  :
                  {(filter === "issue_date" || filter === "expiry_date") && activeFilters[filter] ? (
                    <span className="font-semibold">
                      {" "}
                      {activeFilters[filter]?.startDate && activeFilters[filter]?.endDate
                        ? `${convertDate(activeFilters[filter].startDate)} ~ ${convertDate(activeFilters[filter].endDate)}`
                        : null}
                    </span>
                  ) : activeFilters[filter]?.length > 0 ? (
                    <span className="font-semibold">
                      {" "}
                      {activeFilters[filter].length > 2
                        ? "Multiple"
                        : activeFilters[filter]
                          .map((status) =>
                            typeof status === "object" && status.label ? status.label : status,
                          )
                          .join(", ")}
                    </span>
                  ) : null}
                </div>
                <div>
                  <RxCross2
                    size={20}
                    onClick={(e) => {
                      e.stopPropagation();
                      removeFilter(filter);
                    }}
                    className="cursor-pointer"
                  />
                </div>
              </Button>
              {/* Sub-dropdown for Status and Status */}
              {currentFilterDropdown === filter && filter === "status" && (
                <CustomDropdownCard>
                  {statusOptions.map((status, index) => {
                    const isSelected = activeFilters[filter]?.some(
                      (item) => item.value === status.value,
                    );
                    return (
                      <div
                        key={index}
                        onClick={(e) => {
                          e.stopPropagation();

                          addFilterValue(filter, status.value, status.label);
                        }}
                        onMouseEnter={() => setHoveredId(status.value)}
                        onMouseLeave={() => setHoveredId(null)}
                        className={`cursor-pointer flex items-center justify-start w-full pl-2 pr-2.5 py-2.5 rounded-md hover:bg-global-white ${isSelected ? "bg-global-white" : ""
                          }`}
                      >
                        <span className="text-base font-medium text-global-title truncate">
                          {status.label}
                        </span>
                        {isSelected && (
                          <span className="ml-auto">
                            {hoveredId === status.value ? (
                              <XCloseIcon
                                onClick={(e) => {
                                  e.stopPropagation();
                                  removeFilterValue(filter, status.value);
                                }}
                                strokeColor={"var(--color-login-text-title)"}
                                width={20}
                                height={20}
                              />
                            ) : (
                              <TickIcon width={20} height={20} />
                            )}
                          </span>
                        )}
                      </div>
                    );
                  })}
                </CustomDropdownCard>
              )}
              {/* Sub-dropdown for Status and certificate_no.*/}
              {currentFilterDropdown === filter && filter === "certificate_no" && (
                <CustomDropdownCard>
                  {certificateOptions && certificateOptions.length > 0 ? (
                    certificateOptions.map((article, index) => {
                      const isSelected = activeFilters[filter]?.some(
                        (item) => item.value === article.certificate_id,
                      );
                      return (
                        <div
                          key={index}
                          onClick={(e) => {
                            e.stopPropagation();
                            addFilterValue(filter, article.certificate_id, article.certificate_id);
                          }}
                          onMouseEnter={() => setHoveredId(article.certificate_id)}
                          onMouseLeave={() => setHoveredId(null)}
                          className={`cursor-pointer flex items-center justify-start w-full pl-2 pr-2.5 py-2.5 rounded-md hover:bg-global-white ${isSelected ? "bg-global-white" : ""
                            }`}
                        >
                          <span className="text-base font-medium text-global-title truncate">
                            {article.certificate_id}
                          </span>
                          {isSelected && (
                            <span className="ml-auto">
                              {hoveredId === article.certificate_id ? (
                                <XCloseIcon
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    removeFilterValue(filter, article.certificate_id);
                                  }}
                                  strokeColor={"var(--color-login-text-title)"}
                                  width={20}
                                  height={20}
                                />
                              ) : (
                                <TickIcon width={20} height={20} />
                              )}
                            </span>
                          )}
                        </div>
                      );
                    })
                  ) : (
                    <p className="text-base px-6 py-5 font-normal text-center text-global-gray-brand">
                      No data found
                    </p>
                  )}
                </CustomDropdownCard>
              )}
              {/* Sub-dropdown for Transparency Score */}
              {currentFilterDropdown === filter && filter === "days_until_expiry" && (
                <CustomDropdownCard className="w-[292px] md:w-[320px]">
                  <TransparencySlider
                    setTransparencyRange={setTransparencyRange}
                    transparencyRange={transparencyRange}
                    onChange={handleSliderChange}
                    maxValue={365}
                    minValue={0}
                  />
                </CustomDropdownCard>
              )}
              {currentFilterDropdown === filter && filter === "certificate_type" && (
                <CustomDropdownCard>
                  {certificateType && certificateType.length > 0 ? (
                    certificateType.map((status, index) => {
                      const isSelected = activeFilters[filter]?.some(
                        (item) => item.value === status.value,
                      );
                      return (
                        <div
                          key={index}
                          onClick={(e) => {
                            e.stopPropagation();
                            addFilterValue(filter, status.value, status.label);
                          }}
                          onMouseEnter={() => setHoveredId(status.value)}
                          onMouseLeave={() => setHoveredId(null)}
                          className={`cursor-pointer flex items-center justify-start w-full pl-2 pr-2.5 py-2.5 rounded-md hover:bg-global-white ${isSelected ? "bg-global-white" : ""
                            }`}
                        >
                          <span className="text-base font-medium text-global-title truncate">
                            {status.label}
                          </span>
                          {isSelected && (
                            <span className="ml-auto">
                              {hoveredId === status.value ? (
                                <XCloseIcon
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    removeFilterValue(filter, status.value);
                                  }}
                                  strokeColor={"var(--color-login-text-title)"}
                                  width={20}
                                  height={20}
                                />
                              ) : (
                                <TickIcon width={20} height={20} />
                              )}
                            </span>
                          )}
                        </div>
                      );
                    })
                  ) : (
                    <p className="text-base px-6 py-5 font-normal text-center text-global-gray-brand">
                      No data found
                    </p>
                  )}
                </CustomDropdownCard>
              )}
              {currentFilterDropdown === filter && filter === "supplier_name" && (
                <CustomDropdownCard>
                  {supplierList && supplierList.length > 0 ? (
                    supplierList.map((status, index) => {
                      const isSelected = activeFilters[filter]?.some(
                        (item) => item.value === status.value,
                      );
                      return (
                        <div
                          key={index}
                          onClick={(e) => {
                            e.stopPropagation();
                            addFilterValue(filter, status.value, status.label);
                          }}
                          onMouseEnter={() => setHoveredId(status.value)}
                          onMouseLeave={() => setHoveredId(null)}
                          className={`cursor-pointer flex items-center justify-start w-full pl-2 pr-2.5 py-2.5 rounded-md hover:bg-global-white ${isSelected ? "bg-global-white" : ""
                            }`}
                        >
                          <span className="text-base font-medium text-global-title truncate">
                            {status.label}
                          </span>
                          {isSelected && (
                            <span className="ml-auto">
                              {hoveredId === status.value ? (
                                <XCloseIcon
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    removeFilterValue(filter, status.value);
                                  }}
                                  strokeColor={"var(--color-login-text-title)"}
                                  width={20}
                                  height={20}
                                />
                              ) : (
                                <TickIcon width={20} height={20} />
                              )}
                            </span>
                          )}
                        </div>
                      );
                    })
                  ) : (
                    <p className="text-base px-6 py-5 font-normal text-center text-global-gray-brand">
                      No data found
                    </p>
                  )}
                </CustomDropdownCard>
              )}
              {(currentFilterDropdown === filter && (filter === "issue_date" || filter === "expiry_date")) && (
                <div>
                  {activeFilters[filter] && (
                    <div className="absolute z-50 py-2 rounded-lg focus:outline-none bg-global-theme ">
                      <div
                        className="rounded-lg p-6 bg-btn-surface-secondary max-h-[320px] w-[320px] border border-global-divider-soft "
                        style={{
                          boxShadow:
                            "0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08)",
                        }}
                      >
                        <div className="grid grid-cols-1 gap-[2px] py-1">
                          <div className="relative">
                            <Datepicker
                              useRange={window.innerWidth > 768 ? true : false}
                              asSingle={false}
                              value={activeFilters[filter]}
                              placeholder={"Select issue date range"}
                              displayFormat="DD-MM-YYYY"
                              inputClassName={`flex justify-between w-full gap-3 px-4 h-[44px]  py-2 items-center text-dropdown-text-data text-base placeholder:text-input-text-placeholder placeholder:text-base placeholder:font-normal self-stretch border border-dropdown-stroke-default bg-dropdown-surface-background focus:shadow-[none] focus:ring-offset-input-surface-background focus:border-input-stroke-active focus:ring-input-stroke-active rounded-lg z-[2]`}
                              containerClassName="certificate-date-picker"
                              toggleClassName="absolute text-icon-fill-input custom-toggle-icon right-0 h-full z-[4]"
                              onChange={(range) => {
                                setActiveFilters((prev) => ({
                                  ...prev,
                                  [filter]: setTimeInDateObject(range),
                                }));
                              }}
                              primaryColor={"emerald"}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}

              {currentFilterDropdown === filter && filter === "certified_by" && (
                <CustomDropdownCard>
                  {certifiedBy && certifiedBy.length > 0 ? (
                    certifiedBy.map((status, index) => {
                      const isSelected = activeFilters[filter]?.some(
                        (item) => item.value === status.value,
                      );
                      return (
                        <div
                          key={index}
                          onClick={(e) => {
                            e.stopPropagation();
                            addFilterValue(filter, status.value, status.label);
                          }}
                          onMouseEnter={() => setHoveredId(status.value)}
                          onMouseLeave={() => setHoveredId(null)}
                          className={`cursor-pointer flex items-center justify-start w-full pl-2 pr-2.5 py-2.5 rounded-md hover:bg-global-white ${isSelected ? "bg-global-white" : ""
                            }`}
                        >
                          <span className="text-base font-medium text-global-title truncate">
                            {status.label}
                          </span>
                          {isSelected && (
                            <span className="ml-auto">
                              {hoveredId === status.value ? (
                                <XCloseIcon
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    removeFilterValue(filter, status.value);
                                  }}
                                  strokeColor={"var(--color-login-text-title)"}
                                  width={20}
                                  height={20}
                                />
                              ) : (
                                <TickIcon width={20} height={20} />
                              )}
                            </span>
                          )}
                        </div>
                      );
                    })
                  ) : (
                    <p className="text-base px-6 py-5 font-normal text-center text-global-gray-brand">
                      No data found
                    </p>
                  )}
                </CustomDropdownCard>
              )}
            </div>
          ))}
      </div>
      <div className="order-1 md:order-2">
        <div ref={filterRef} className="w-full md:w-auto">
          <Button
            variant="secondary"
            className="text-txt-secondary px-[15.08px] py-2.5 w-full md:w-auto  gap-1.5 text-sm   font-semibold flex h-10 lg:h-auto"
            onMouseEnter={() => setIshovered(true)}
            onMouseLeave={() => setIshovered(false)}
            onClick={toggleMainDropdown}
          >
            <FilterOption
              width={20}
              height={20}
              strokeColor={
                isHovered ? "var(--color-icon-fill-secondary-button)" : "var(--color-txt-secondary)"
              }
            />
            {Object.keys(activeFilters).length > 0 ? (
              "More filters"
            ) : (
              <span className="tracking-[0.01em]">Filters</span>
            )}
          </Button>
          {mainDropdownVisible && (
            <CustomDropdownCard>
              {filterOptions.map((filter, index) => {
                const isSelected = activeFilters[filter];
                return (
                  <div
                    key={index}
                    onClick={() => addFilter(filter)}
                    onMouseEnter={() => setHoveredId(filter)}
                    onMouseLeave={() => setHoveredId(null)}
                    className={`cursor-pointer flex gap-2 flex-wrap items-center justify-start w-full pl-2 pr-2.5 py-2.5 rounded-md hover:bg-global-white ${isSelected ? "bg-global-white" : ""
                      }`}
                  >
                    <span className="text-base font-medium text-global-title truncate capitalize">
                      {filter === "certificate_no" ? "Certificate No." : filter?.replace(/_/g, " ")}
                    </span>
                    {isSelected && (
                      <span className="ml-auto">
                        {hoveredId === filter ? (
                          <XCloseIcon
                            onClick={(e) => {
                              e.stopPropagation();
                              removeFilter(filter);
                            }}
                            strokeColor={"var(--color-login-text-title)"}
                            width={20}
                            height={20}
                          />
                        ) : (
                          <TickIcon width={20} height={20} />
                        )}
                      </span>
                    )}
                  </div>
                );
              })}
            </CustomDropdownCard>
          )}
        </div>
      </div>
    </div>
  );
};

export default CertificateFilters;
