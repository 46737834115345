import api from "config/axios";
export const retailer = (data) => api.post("/invitations", data);
export const getRetailers = async (query) => {
  const params = new URLSearchParams();
  try {
    Object.keys(query).forEach((key) => {
      const value = query[key];
      if (Array.isArray(value)) {
        value.forEach((item) => {
          params.append(`${key}[]`, item);
        });
      } else if (value) {
        params.append(key, value);
      }
    });
    const response = await api.get(`/retailers?${params}`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const downloadRetailerAsCSV = async (params) => {
  try {
    const response = await api.get(`/retailers`, {
      params: { ...params, download: "yes" },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const getAllRetailers = async (query) => {
  const params = new URLSearchParams();
  try {
    Object.keys(query).forEach((key) => {
      if (query[key]) {
        params.append(key, query[key]);
      }
    });
    const response = await api.get(`/all-retailers?${params}`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const getOrderRetailers = async (query) => {
  const params = new URLSearchParams();
  try {
    Object.keys(query).forEach((key) => {
      if (query[key]) {
        params.append(key, query[key]);
      }
    });
    const response = await api.get(`/order-retailers?${params}`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const getRetailerCompany = async (query) => {
  const params = new URLSearchParams();
  try {
    Object.keys(query).forEach((key) => {
      if (query[key]) {
        params.append(key, query[key]);
      }
    });
    const response = await api.get(`/retailers-filter/company?${params}`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const bulkDeleteRetailers = async (data) => {
  try {
    const response = await api.post(`/retailers/bulk-delete`, { retailers: data });
    return response;
  } catch (error) {
    throw error;
  }
};
