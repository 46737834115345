import { Mail01Icon } from "assests";
import React from "react";
import CompanyDetailsUpgrade from "./CompanyDetailsUpgrade";
const CompanyDetailsForm = ({ handleShowCompanyDetails, className }) => {
  return (
    <div className={`col-span-12 lg:col-span-6 inline-flex relative  justify-center items-center min-h-screen h-full w-full py-6 md:py-16 lg:py-[109px] bg-global-white ${className}`}>
      <CompanyDetailsUpgrade
        {...{
          handleShowCompanyDetails,
        }}
      />
      <div className="absolute bottom-4 right-8">
        <a href="mailto:hello@fibervue.com">
          <div className="flex items-center gap-2">
            <Mail01Icon width={16} height={16} />{" "}
            <p className="text-sm font-normal text-btn-text-tertiary">hello@fibervue.com</p>
          </div>
        </a>
      </div>
    </div>
  );
};

export default CompanyDetailsForm;
