import { Autocomplete } from "@react-google-maps/api";
import { useQuery } from "@tanstack/react-query";
import { AttachFileicon, Cross, SupplierIcon } from "assests";
import { Button } from "components/UI/Button";
import CountrySelect from "components/UI/CountrySelect";
import CustomSelect from "components/UI/CustomSelect";
import Divider from "components/UI/Divider";
import ErrorMessage from "components/UI/ErrorMessage";
import { Input } from "components/UI/Input";
import { Label } from "components/UI/Label";
import UpgradeComponent from "components/UI/UpgradeComponent";
import { useGoogleMaps } from "contexts/GoogleMapsContext";
import { Country } from "country-state-city";
import useCreateUpdateSubSupplier from "hooks/useCreateUpdateSubSupplier";
import usePermission from "hooks/usePermission";
import { useEffect, useState } from "react";
import { getSubTiersList } from "services/subtiers.services";
import { truncateString } from "utils/helper";

const AddSubSupplier = ({ closeModal, refetchDatas, fromOrderDetailsPage }) => {
  const { hasPermission } = usePermission();
  const {
    control,
    isLoading,
    isDuplicateAction,
    subSupplierDetails,
    errors,
    isPending,
    isSubSupplierEditable,
    actionableSubSupplierId,
    showDiscardModal,
    register,
    reset,
    handleSubmit,
    onSubmit,
    onValidationError,
    getValues,
    toggleEditOrDiscard,
    handleConfirmDiscard,
    isSubSupplierDetailsLoading,
    setValue,
    selectedCountry,
    setSelectedCountry,
    clearErrors,
  } = useCreateUpdateSubSupplier({ refetchDatas });
  const { isLoaded } = useGoogleMaps();
  const handleCancel = () => {
    reset();
    closeModal();
  };
  const [autocomplete, setAutocomplete] = useState(null);
  const { data: SubTiers, isLoading: subTiersLoading } = useQuery({
    queryKey: ["template", "get-sub-tiers"],
    queryFn: getSubTiersList,
  });
  const onLoad = (autocomplete) => {
    setAutocomplete(autocomplete);
  };

  const onPlaceChanged = () => {
    if (autocomplete) {
      const place = autocomplete.getPlace();
      if (place.formatted_address) {
        const formattedAddress = place.formatted_address;
        setValue("address", formattedAddress);
      } else {
        const suggestedAddress = place.name;
        setValue("address", suggestedAddress);
      }
      if (place.address_components) {
        let street = "";

        let region = "";
        let country = "";
        let countryCode = "";

        place.address_components.forEach((component) => {
          const types = component.types;
          if (types.includes("street_number")) {
            street = component.long_name + " ";
          }
          if (types.includes("route")) {
            street += component.long_name;
          }
          // if (types.includes("postal_code")) {
          //   zip = component.long_name;
          // }
          if (types.includes("locality")) {
            region = component.long_name;
          }
          if (types.includes("country")) {
            country = component.long_name;
            countryCode = component.short_name;
          }
        });
        let countryData = Country.getCountryByCode(country);
        if (!countryData) {
          countryData = Country.getAllCountries().find(
            (c) => c.name.toLowerCase() === country?.toLowerCase(),
          );
        }
        if (countryData) {
          setValue("country", countryData.name);
          setValue("country_code", countryData.isoCode);
          setSelectedCountry({
            value: countryData.name,
            label: countryData.name,
            flag: countryData.flag,
            isoCode: countryData.isoCode,
          });
        }
        setValue("street", street);
        // setValue("zip", zip);
        setValue("region", region);
        setValue("country", country);
        setValue("country_code", countryCode);
      }
    }
  };

  useEffect(() => {
    if (selectedCountry) {
      clearErrors("country");
      clearErrors("country_code");
      setValue("country", selectedCountry.value);
      setValue("country_code", selectedCountry.isoCode);
    }
  }, [selectedCountry]);

  const subTierOptions = SubTiers?.data?.sub_tiers.map((tier) => ({
    label: tier.name,
    value: tier.id,
  }));

  return (
    <>
      <div className="relative  z-[2] border border-global-divider-soft rounded-xl  w-full lg:w-[600px] max-h-[600px] py-6 bg-white">
        <div className="flex justify-between gap-x-4 px-6 pb-5">
          {subTiersLoading || (actionableSubSupplierId && isSubSupplierDetailsLoading) ? <div></div> :
            <div className="flex justify-start items-center gap-4">
              <UpgradeComponent>
                {fromOrderDetailsPage ? (
                  <AttachFileicon
                    strokeColor={"var(--color-txt-secondary-secondary)"}
                    width={24}
                    height={24}
                  />
                ) : (
                  <SupplierIcon
                    width={24}
                    height={24}
                    strokeColor="var(--color-login-icon-outlined-default)"
                  />
                )}
              </UpgradeComponent>
              <div className="flex justify-between z-[2]">
                <div className="flex flex-col gap-1">
                  <h2 className="text-sm lg:text-lg  font-semibold text-start text-login-text-title">
                    {fromOrderDetailsPage ? (
                      "New Sub Supplier"
                    ) : (
                      <>
                        {actionableSubSupplierId &&
                          isDuplicateAction &&
                          `Duplicate Sub-Supplier-${subSupplierDetails?.sku}`}
                        {actionableSubSupplierId &&
                          !isDuplicateAction &&
                          truncateString(`Sub-Supplier-${subSupplierDetails?.name}`)}
                        {!actionableSubSupplierId && "Add Sub-Supplier"}
                      </>
                    )}
                  </h2>
                  <p className="text-xs lg:text-sm leading-5 text-global-paragraph font-normal truncate">
                    {fromOrderDetailsPage
                      ? "Add supply chain partner details here."
                      : "Add a new sub-supplier to your supplier list."}
                  </p>
                </div>
              </div>
            </div>
          }
          <div>
            <Cross
              fillColor="var(--color-icon-fill-input)"
              width={24}
              height={24}
              onClick={handleCancel}
              className="cursor-pointer"
            />
          </div>
        </div>

        {subTiersLoading || (actionableSubSupplierId && isSubSupplierDetailsLoading) ? (
          <div className="grid gap-2">
            <div className="flex items-center justify-center w-full h-32 mt-10">
              <div className="border-4 rounded-full w-7 h-7 animate-spin border-global-primary border-t-transparent"></div>
            </div>
            <p className="mt-5 text-center">Preparing form data, please wait...</p>
          </div>
        ) : (
          <form onSubmit={handleSubmit(onSubmit, onValidationError)}>


            <div className="grid gap-4 lg:gap-6 px-6 pt-6 lg:pt-11  max-h-[378px] overflow-auto z-[3]">
              <div className="col-span-2">
                <div className="grid gap-1.5">
                  <Label htmlFor="name" className="text-sm font-medium leading-5">
                    Company Name
                  </Label>
                  <Input
                    id="name"
                    type="text"
                    {...register("name")}
                    placeholder="Enter company name"
                  />
                  <ErrorMessage errors={errors?.name?.message} />
                </div>
              </div>
              <div className="col-span-2">
                <div className="grid gap-1.5">
                  <Label htmlFor="sub_tier_id" className="text-sm font-medium leading-5">
                    Supplier Type
                  </Label>
                  <CustomSelect
                    control={control}
                    name="sub_tier_id"
                    placeholder="Select type"
                    options={subTierOptions}
                    isClearable={false}
                    isDisabled={actionableSubSupplierId ? true : false}
                    style={{
                      padding: "4px 10px 4.5px 6px",
                      fontSize: "16px",
                      width: "100%",
                      iconSize: 16,
                    }}
                    {...register("sub_tier_id")}
                  />
                  <ErrorMessage errors={errors?.sub_tier_id?.message} />
                </div>
              </div>
              <div className="col-span-2 lg:col-span-1">
                <div className="grid gap-1.5">
                  <Label className="text-sm font-medium leading-5">Street</Label>
                  <div className="relative">
                    {isLoaded ? (
                      <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
                        <Input
                          id="street"
                          type="text"
                          placeholder="Enter street"
                          {...register("street")}
                          onChange={(e) => {
                            console.log(e);
                          }}
                        />
                      </Autocomplete>
                    ) : (
                      <Input
                        id="street"
                        type="text"
                        placeholder="Enter street"
                        {...register("street")}
                      />
                    )}
                  </div>
                  <ErrorMessage errors={errors?.street?.message} />
                </div>
              </div>
              <div className="col-span-2 lg:col-span-1">
                <div className="grid gap-1.5">
                  <Label className="text-sm font-medium leading-5">City</Label>
                  <Input
                    id="region"
                    type="text"
                    placeholder="Enter city"
                    {...register("region")}
                  />
                  <ErrorMessage errors={errors?.region?.message} />
                </div>
              </div>
              <div className="col-span-2">
                <div className="grid gap-1.5">
                  <Label className="text-sm font-medium leading-5">Country</Label>
                  <CountrySelect
                    control={control}
                    {...{ selectedCountry, setSelectedCountry }}
                  />
                  <ErrorMessage errors={errors?.country?.message} />
                </div>
              </div>
            </div>
            <Divider className={"mb-4 mt-8 lg:mb-6"} />
            <div className="flex  justify-center items-center lg:justify-between gap-4 px-6">
              {actionableSubSupplierId ? (
                <div className="flex flex-col lg:flex-row  w-full gap-3">
                  <Button
                    type="button"
                    variant={"secondary"}
                    className="h-11 px-4 py-2.5 text-base w-full"
                    onClick={handleCancel}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    className="h-11 px-4 py-2.5 text-base w-full"
                    isDisabled={isPending}
                  >
                    Update sub-supplier
                  </Button>
                </div>
              ) : (
                <div className="flex flex-col lg:flex-row  w-full gap-3">
                  <Button
                    type="button"
                    variant={"secondary"}
                    className={"text-btn-text-default h-11 px-4 py-2.5 text-base w-full"}
                    onClick={handleCancel}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    size={"md"}
                    className="h-11 px-4 py-2.5 text-base w-full"
                    isDisabled={isPending}
                  >
                    Save
                  </Button>
                </div>
              )}
            </div>


          </form>

        )}
      </div>



      {/* <div className="relative">
        <div className=" flex items-center justify-center ">
          <div className="w-full lg:w-[600px] max-h-[600px] py-6   rounded-xl bg-white z-[2] ">
            <div className="absolute right-5 top-5 z-[2] cursor-pointer " onClick={handleCancel}>
              <Cross fillColor={"#A4A7AE"} height={24} width={24} />
            </div>

            <form onSubmit={handleSubmit(onSubmit, onValidationError)}>
              <div className="grid grid-cols-1 gap-6">
                <div>
                  <div className="flex justify-start gap-x-4 px-6 z-[1] ">
                    <UpgradeComponent>
                      {fromOrderDetailsPage ? (
                        <AttachFileicon
                          strokeColor={"var(--color-txt-secondary-secondary)"}
                          width={24}
                          height={24}
                        />
                      ) : (
                        <SupplierIcon
                          width={24}
                          height={24}
                          strokeColor="var(--color-login-icon-outlined-default)"
                        />
                      )}
                    </UpgradeComponent>

                    <div className="flex justify-between z-[2]">
                      <div className="flex flex-col gap-1">
                        <h2 className="text-sm lg:text-lg  font-semibold text-start text-login-text-title">
                          {fromOrderDetailsPage ? (
                            "New Sub Supplier"
                          ) : (
                            <>
                              {actionableSubSupplierId &&
                                isDuplicateAction &&
                                `Duplicate Sub-Supplier-${subSupplierDetails?.sku}`}
                              {actionableSubSupplierId &&
                                !isDuplicateAction &&
                                truncateString(`Sub-Supplier-${subSupplierDetails?.name}`)}
                              {!actionableSubSupplierId && "Add Sub-Supplier"}
                            </>
                          )}
                        </h2>
                        <p className="text-xs lg:text-sm leading-5 text-global-paragraph font-normal truncate">
                          {fromOrderDetailsPage
                            ? "Add supply chain partner details here."
                            : "Add a new sub-supplier to your supplier list."}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="grid gap-4 lg:gap-6 px-6 pt-6 lg:pt-11  max-h-[378px] overflow-auto z-[3]">
                    <div className="col-span-2">
                      <div className="grid gap-1.5">
                        <Label htmlFor="name" className="text-sm font-medium leading-5">
                          Company Name
                        </Label>
                        <Input
                          id="name"
                          type="text"
                          {...register("name")}
                          placeholder="Enter company name"
                        />
                        <ErrorMessage errors={errors?.name?.message} />
                      </div>
                    </div>
                    <div className="col-span-2">
                      <div className="grid gap-1.5">
                        <Label htmlFor="sub_tier_id" className="text-sm font-medium leading-5">
                          Supplier Type
                        </Label>
                        <CustomSelect
                          control={control}
                          name="sub_tier_id"
                          placeholder="Select type"
                          options={subTierOptions}
                          isClearable={false}
                          isDisabled={actionableSubSupplierId ? true : false}
                          style={{
                            padding: "4px 10px 4.5px 6px",
                            fontSize: "16px",
                            width: "100%",
                            iconSize: 16,
                          }}
                          {...register("sub_tier_id")}
                        />
                        <ErrorMessage errors={errors?.sub_tier_id?.message} />
                      </div>
                    </div>
                    <div className="col-span-2 lg:col-span-1">
                      <div className="grid gap-1.5">
                        <Label className="text-sm font-medium leading-5">Street</Label>
                        <div className="relative">
                          {isLoaded ? (
                            <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
                              <Input
                                id="street"
                                type="text"
                                placeholder="Enter street"
                                {...register("street")}
                                onChange={(e) => {
                                  console.log(e);
                                }}
                              />
                            </Autocomplete>
                          ) : (
                            <Input
                              id="street"
                              type="text"
                              placeholder="Enter street"
                              {...register("street")}
                            />
                          )}
                        </div>
                        <ErrorMessage errors={errors?.street?.message} />
                      </div>
                    </div>
                    <div className="col-span-2 lg:col-span-1">
                      <div className="grid gap-1.5">
                        <Label className="text-sm font-medium leading-5">City</Label>
                        <Input
                          id="region"
                          type="text"
                          placeholder="Enter city"
                          {...register("region")}
                        />
                        <ErrorMessage errors={errors?.region?.message} />
                      </div>
                    </div>
                    <div className="col-span-2">
                      <div className="grid gap-1.5">
                        <Label className="text-sm font-medium leading-5">Country</Label>
                        <CountrySelect
                          control={control}
                          {...{ selectedCountry, setSelectedCountry }}
                        />
                        <ErrorMessage errors={errors?.country?.message} />
                      </div>
                    </div>
                  </div>
                  <Divider className={"mb-4 mt-8 lg:mb-6"} />
                  <div className="flex  justify-center items-center lg:justify-between gap-4 px-6">
                    {actionableSubSupplierId ? (
                      <div className="flex flex-col lg:flex-row  w-full gap-3">
                        <Button
                          type="button"
                          variant={"secondary"}
                          className="h-11 px-4 py-2.5 text-base w-full"
                          onClick={handleCancel}
                        >
                          Cancel
                        </Button>
                        <Button
                          type="submit"
                          className="h-11 px-4 py-2.5 text-base w-full"
                          isDisabled={isPending}
                        >
                          Update sub-supplier
                        </Button>
                      </div>
                    ) : (
                      <div className="flex flex-col lg:flex-row  w-full gap-3">
                        <Button
                          type="button"
                          variant={"secondary"}
                          className={"text-btn-text-default h-11 px-4 py-2.5 text-base w-full"}
                          onClick={handleCancel}
                        >
                          Cancel
                        </Button>
                        <Button
                          type="submit"
                          size={"md"}
                          className="h-11 px-4 py-2.5 text-base w-full"
                          isDisabled={isPending}
                        >
                          Save
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default AddSubSupplier;
