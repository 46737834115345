import { Action, Cross, EditLable, TicCircle, Uploadnew, XCircle } from "assests";
import { Button } from "components/UI/Button";
import CustomSelectEdition from "components/UI/CustomSelectEdition";
import Divider from "components/UI/Divider";
import { Input } from "components/UI/Input";
import Tooltip from "components/UI/Tooltip";
import UpgradeComponent from "components/UI/UpgradeComponent";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { preventSpaceAndPasteNegative, preventSpaceMinus } from "utils/helper";

const Preview = ({
  data,
  genders,
  compositions,
  articleTypes,
  templates,
  onChangeHandler,
  onSubmit,
  closeModal,
  handleCancel,
  uploadedData,
  preview,
  setPreview,
  onSubmitBulkUpload,
  hasAnyError,
  showLimitText,
}) => {
  const { control } = useForm();
  const { workspacePlan } = useSelector((state) => state.billingSlice);
  return (
    <>
      <div className="grid grid-cols-1 h-full z-[2] ">
        <div className="absolute right-5 top-5 cursor-pointer  z-[2]">
          <Cross fillColor={"#A4A7AE"} height={24} width={24} onClick={closeModal} />
        </div>
        <div className="flex justify-start ml-6 z-[1]">
          <UpgradeComponent>
            <Uploadnew />
          </UpgradeComponent>
          <div className="flex justify-between lg:gap-4 px-3 pb-5 lg:px-4 z-[2] ">
            <div className="flex flex-col gap-1">
              <h2 className="font-semibold text-sm lg:text-lg leading-7 text-start text-primary-900 w-full">
                {data.some((item) => Object.values(item.error).includes(true))
                  ? "Missing Fields"
                  : "Article Upload"}
              </h2>
              <div className="flex justify-start items-center">
                <p className="text-sm text-switch-active-gray font-normal text-wrap leading-5">
                  {data.some((item) => Object.values(item.error).includes(true))
                    ? `We found ${data?.length} articles in your file . Please correct any errors before proceeding.`
                    : `We found ${data?.length} articles in your file with no errors. You may proceed with the next steps.`}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="w-auto z-[3] ">
          {showLimitText && (
            <div className="px-6 py-4">
              <div className="h-11 px-4 py-3 bg-white rounded-xl border border-[#e4e7ec] justify-start items-center gap-3 inline-flex overflow-hidden">
                <Action width={20} height={20} />
                <div className="grow shrink basis-0 text-[#535862] bg-transparent text-sm font-normal leading-tight">{`You cannot add a new article as you have reached the maximum limit of ${workspacePlan && workspacePlan?.package?.articles} articles in your ${workspacePlan && workspacePlan?.package?.name} plan. Please reduce your usage or upgrade your plan.`}</div>
              </div>
            </div>
          )}
          <div className="overflow-x-auto ">
            <table className="table-auto border-spacing-y-3  px-6  overflow-x-auto">
              <thead className="text-left whitespace-nowrap ">
                <tr className="text-xs leading-3 font-medium text-table-text-header ">
                  <th className="px-6 pb-3">
                    <div className="flex items-center gap-1">Article ID</div>
                  </th>
                  <th className="px-6 pb-3">
                    {" "}
                    <div className="flex items-center gap-1 ">Template</div>
                  </th>
                  <th className="px-6 pb-3">
                    <div className="flex items-center gap-1 ">Compositions </div>
                  </th>
                  <th className="px-6 pb-3">
                    <div className="flex items-center gap-1 ">
                      <div> Percentage</div>
                      <div>
                        <Tooltip text="The overall percentage should be 100%" />
                      </div>
                    </div>
                  </th>
                  <th className="px-6 pb-3">
                    {" "}
                    <div className="flex items-center gap-1 min-w-[108px]">Sex</div>
                  </th>
                  <th className="px-6 pb-3">
                    {" "}
                    <div className="flex items-center gap-1 min-w-[105px]">Article Type</div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {data?.map((item, index) => (
                  <tr
                    key={index}
                    className={`whitespace-nowrap border-b last:border-0 border-b-global-divider-soft  ${index % 2 === 0 ? "bg-global-white" : "bg-white"} rounded-lg text-global-paragraph text-sm font-normal cursor-pointer`}
                  >
                    <td className="px-6 py-3 ">
                      <div>
                        <div className="flex flex-row items-center gap-3">
                          <div className="bg-[#F2F4F7] h-10 w-10 rounded-full flex items-center justify-center p-2">
                            <EditLable className="w-6 h-6" />
                          </div>
                          <Input
                            type="text"
                            defaultValue={item.articleId}
                            maxLength={100}
                            onChange={(e) => onChangeHandler(index, "articleId", e.target.value)}
                            isError={item.error.articleIdError}
                            onPaste={preventSpaceAndPasteNegative}
                            onKeyPress={preventSpaceMinus}
                            className={`text-base text-global-primary-900 min-w-[129px] font-normal leading-normal rounded-lg px-3 custom-card`}
                          />
                        </div>
                      </div>
                    </td>
                    <td className="px-6 py-3 ">
                      <div className="w-auto">
                        <CustomSelectEdition
                          control={control}
                          options={templates}
                          name={`templateType${index}`}
                          value={item.templateType}
                          handleOnChange={(value) => onChangeHandler(index, "templateType", value)}
                          isError={item.error.templateTypeError}
                          style={{
                            padding: "4.5px 10px 5.5px 4px",
                            fontSize: "12px",
                            minWidth: "320px",
                          }}
                        />
                      </div>
                    </td>
                    <td className="px-6 py-3">
                      <div className="min-w-[318px]">
                        <CustomSelectEdition
                          isMulti
                          control={control}
                          options={compositions}
                          name={`composition${index}`}
                          value={item.composition}
                          handleOnChange={(value) => onChangeHandler(index, "composition", value)}
                          isError={item.error.compositionError}
                          style={{
                            padding: "4.5px 10px 5.5px 4px",
                            fontSize: "12px",
                            width: "auto",
                          }}
                          isClearable={false}
                        />
                      </div>
                    </td>
                    <td className="px-6 py-3">
                      <Input
                        type="text"
                        defaultValue={item.percentage}
                        onChange={(e) => onChangeHandler(index, "percentage", e.target.value)}
                        isError={item.error.percentageError}
                        className={`text-base text-global-primary-900 font-normal leading-normal custom-card px-3 rounded-lg`}
                      />
                    </td>

                    <td className="px-6 py-3">
                      <div className="w-auto">
                        <CustomSelectEdition
                          control={control}
                          options={genders}
                          name={`gender${index}`}
                          value={item.gender}
                          handleOnChange={(value) => onChangeHandler(index, "gender", value)}
                          isError={item.error.genderError}
                          style={{
                            padding: "4.5px 10px 5.5px 4px",
                            fontSize: "12px",
                            minWidth: "320px",
                          }}
                        />
                      </div>
                    </td>
                    <td className="px-6 py-3">
                      <div className="w-auto">
                        <CustomSelectEdition
                          control={control}
                          options={articleTypes}
                          name={`articleType${index}`}
                          value={item.articleType}
                          handleOnChange={(value) => onChangeHandler(index, "articleType", value)}
                          isError={item.error.articleTypeError}
                          style={{
                            padding: "4.5px 10px 5.5px 4px",
                            fontSize: "12px",
                            minWidth: "320px",
                          }}
                        />
                      </div>
                    </td>

                    <td className="px-6 py-3">
                      {Object.values(item.error).some((value) => value === true) ? (
                        <>
                          <XCircle
                            fillColor={"var(--color-icon-fill-danger)"}
                            width={24}
                            height={24}
                          />
                        </>
                      ) : (
                        <TicCircle
                          fillColor={"var(--color-icon-fill-active)"}
                          width={24}
                          height={24}
                        />
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        <div className="mt-8">
          <Divider />
        </div>
        <div className="flex justify-between gap-4 pt-6 px-6">
          <div className="w-full flex justify-end items-center gap-3 ">
            <Button
              type="button"
              size={"md"}
              variant={"secondary"}
              className={`w-auto h-11 px-4 py-2.5 text-base leading-6 font-semibold `}
              onClick={handleCancel}
            >
              Cancel
            </Button>
            {!preview && (
              <Button
                type="button"
                size={"md"}
                className={`w-auto h-11 px-4 py-2.5 text-base leading-6 font-semibold `}
                isDisabled={!uploadedData}
                onClick={() => setPreview(true)}
              >
                Upload
              </Button>
            )}
            {preview && (
              <Button
                type="button"
                size={"md"}
                variant={"primary"}
                className={`w-auto h-11 px-4 py-2.5 text-base leading-6 font-semibold `}
                isDisabled={hasAnyError()}
                onClick={onSubmitBulkUpload}
              >
                Continue
              </Button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Preview;
