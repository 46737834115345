import React from "react";

const Amex = React.forwardRef(
  (
    { width = "40", height = "27", viewBox = "0 0 40 27", fill = "none", className, ...props },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_12487_7267)">
          <mask
            id="mask0_12487_7267"
            style={{ maskType: "luminance" }}
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="40"
            height="27"
          >
            <path d="M40 0H0V26.6667H40V0Z" fill="white" />
          </mask>
          <g mask="url(#mask0_12487_7267)">
            <path
              d="M36.6667 0H3.33333C1.49238 0 0 1.49238 0 3.33333V23.3333C0 25.1743 1.49238 26.6667 3.33333 26.6667H36.6667C38.5077 26.6667 40 25.1743 40 23.3333V3.33333C40 1.49238 38.5077 0 36.6667 0Z"
              fill="#016FD0"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M22.9403 22.3232V12.8208L39.8528 12.836V15.4608L37.898 17.5498L39.8528 19.658V22.3385H36.7318L35.0731 20.5082L33.4263 22.3453L22.9403 22.3232Z"
              fill="#FFFFFE"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M24.0698 21.281V13.8662H30.357V15.5744H26.1055V16.7338H30.2557V18.4135H26.1055V19.5523H30.357V21.281H24.0698Z"
              fill="#016FD0"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M30.3257 21.2808L33.8045 17.5692L30.3255 13.8662H33.0183L35.1442 16.2163L37.276 13.8662H39.8528V13.9246L36.4482 17.5692L39.8528 21.1757V21.2808H37.25L35.0865 18.9072L32.9452 21.2808H30.3257Z"
              fill="#016FD0"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M23.729 4.38623H27.8056L29.2376 7.63765V4.38623H34.2706L35.1386 6.82218L36.0095 4.38623H39.8526V13.8885H19.5418L23.729 4.38623Z"
              fill="#FFFFFE"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M24.501 5.41846L21.211 12.8271H23.4675L24.0882 11.3435H27.4513L28.0717 12.8271H30.3843L27.108 5.41846H24.501ZM24.7837 9.68084L25.7703 7.32239L26.7563 9.68084H24.7837Z"
              fill="#016FD0"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M30.3531 12.8259V5.41748L33.525 5.42838L35.1571 9.98293L36.7998 5.41748H39.8525V12.8259L37.8881 12.8433V7.75441L36.034 12.8259H34.241L32.3481 7.73703V12.8259H30.3531Z"
              fill="#016FD0"
            />
          </g>
        </g>
        <defs>
          <clipPath id="clip0_12487_7267">
            <rect width="40" height="26.6667" fill="white" />
          </clipPath>
        </defs>
      </svg>
    );
  },
);

Amex.displayName = "Amex";

export default Amex;
