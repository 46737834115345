import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import BackToDashboard from "components/UI/BackToDashboard";
import ChipButton from "components/UI/ChipButton";
import Divider from "components/UI/Divider";
import LoadingComponent from "components/UI/LoadingComponent";
import SubNavbar from "layout/SubNavbar";
import queryString from "query-string";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { setSelectedSettingTab } from "store/settingsWorkspaceSlice";
import { removeQueryParam } from "utils/helper";
import BillingList from "../BillingList";
import CompanyProfileList from "../CompanyProfileList";
import InvitationList from "../InvitationList/InvitationList";
import PlansLists from "../Plans";
import UserManagement from "../UserManagement/UserManagement";
import Settings from "./Settings";
const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}`);
const SettingsMainPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const parsed = queryString.parse(location.search);
  const { workspacePlan, sidebarCountData } = useSelector((state) => state.billingSlice);
  const { currentWorkspace } = useSelector((state) => state.authenticationSlice);
  const { selectedSettingTab } = useSelector((state) => state.settingsWorkspaceSlice);
  const handleStatusChange = (status) => {
    dispatch(setSelectedSettingTab(status));
    removeQueryParam(["selectedTab"], location, navigate);
  };

  useEffect(() => {
    if (parsed.selectedTab) {
      dispatch(setSelectedSettingTab(parsed.selectedTab));
    } else if (selectedSettingTab !== "profile") {
      dispatch(setSelectedSettingTab(selectedSettingTab));
    } else {
      dispatch(setSelectedSettingTab("profile"));
    }
  }, [parsed.selectedTab, selectedSettingTab]);

  const renderComponent = () => {
    switch (selectedSettingTab) {
      case "profile":
        return <Settings />;
      case "company":
        return <CompanyProfileList />;
      case "plan":
        return <PlansLists />;
      case "billing":
        return (
          <Elements stripe={stripePromise}>
            <BillingList />
          </Elements>
        );
      case "members":
        return <UserManagement />;
      case "partner_invites":
        return <InvitationList />;
      default:
        return null;
    }
  };
  return (
    <>
      {currentWorkspace && currentWorkspace.is_owner ? (
        <div className="px-4 md:px-8 pt-2">
          <BackToDashboard className="block md:hidden" />
          <div className="md:pt-6">
            <SubNavbar title="Settings" />
          </div>
          <div className="overflow-x-auto">
            <div className="mb-1 md:mb-0 mt-4 md:mt-5  bg-transparent border font-semibold   whitespace-nowrap ">
              <div className="flex flex-wrap items-center justify-start gap-1 ">
                {["profile", "company", "members", "plan", "billing", "partner_invites"].map(
                  (result, index) => (
                    <button
                      key={index}
                      className={`flex tracking-[-0.278px] justify-center items-center text-center font-semibold text-sm py-2 px-3 h-9 rounded-md ${selectedSettingTab === result
                        ? "bg-white border border-border-primary text-gray-900 custom-card-buttom rounded-lg "
                        : "bg-transparent  text-gray-700 "
                        } `}
                      onClick={() => handleStatusChange(result)}
                    >
                      <div className="flex items-center gap-2">
                        <span className="capitalize">{result?.replace("_", " ")}</span>
                        {result === "members" && (
                          <ChipButton
                            className={
                              "text-[10px] lg:text-xs text-global-paragraph !h-[22px] !px-2 whitespace-nowrap !rounded-full"
                            }
                          >
                            {sidebarCountData ? sidebarCountData.members + 1 : 0}
                          </ChipButton>
                        )}
                        {result === "plan" && (
                          <ChipButton
                            className={
                              "text-[10px] lg:text-xs text-global-paragraph !h-[22px] !px-2 whitespace-nowrap !rounded-full"
                            }
                          >
                            {workspacePlan ? workspacePlan?.package?.name : "Free"}
                          </ChipButton>
                        )}
                      </div>
                    </button>
                  ),
                )}
              </div>
            </div>
          </div>
          <Divider className="my-4 lg:my-[35px]" />
          <div>{renderComponent()}</div>
        </div>
      ) : (
        <>
          <LoadingComponent />
        </>
      )}
    </>
  );
};

export default SettingsMainPage;
