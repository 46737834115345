import { CertificateFill } from "assests";
import { Button } from "components/UI/Button";
import Divider from "components/UI/Divider";
import { LineProgressBar } from "components/UI/LineProgressBar";
import usePermission from "hooks/usePermission";
import { useSelector } from "react-redux";
import { getCertificateProgress } from "utils/helper";

const CertificateCard = ({ item, handleClickOptionButton }) => {
  const { currentWorkspace, user } = useSelector((state) => state.authenticationSlice);
  const { remainingDays, progressPercent, progressColor } =
    item && getCertificateProgress(item.issued_at, item.expired_at);
  const { hasPermission } = usePermission();
  return (
    <div
      className={
        "relative w-full sm:w-[344px] h-[213px] rounded-xl bg-global-theme  custom-card border border-global-gray-soft"
      }
    >
      <div className="flex flex-col gap-3 p-5 relative">
        <div className="py-2 ">
          <div className="flex flex-row justify-start items-center gap-2">
            <CertificateFill
              style={{
                boxShadow:
                  "0px 1.25px 2.5px 0px rgba(16, 24, 40, 0.06), 0px 1.25px 3.75px 0px rgba(16, 24, 40, 0.1)",
                width: "40px",
                height: "40px",
                borderRadius: "100%",
              }}
            />
            <div className="text-global-title font-semibold text-sm lg:text-base truncate">
              {`${item?.certificate_type?.name}${" "}${item?.certificate_id}`}
            </div>
          </div>
        </div>

        <div className="text-global-paragraph font-normal text-sm">
          {remainingDays === 0 ? "Certificate Expired" : `${remainingDays} days until expiry`}
        </div>
        <div className="flex justify-between items-center gap-3">
          <LineProgressBar
            className="h-2"
            variant={progressColor && progressColor}
            progress={progressPercent && progressPercent}
          />
          <div className="text-global-title-2 font-medium text-sm whitespace-nowrap">
            {item.expired_at ? remainingDays && remainingDays : 0} days
          </div>
        </div>
      </div>
      <Divider />
      <div className="w-full flex  justify-end items-center">
        <Button
          onClick={(e) => {
            e.stopPropagation();
            if (
              hasPermission("uploadCertificate") &&
              item?.created_by_workspace &&
              user &&
              parseInt(item?.created_by_workspace) === parseInt(user?.workspace_id)
            ) {
              handleClickOptionButton(item.id, "edit");
            } else {
              handleClickOptionButton(item.id, "view");
            }
          }}
          variant={"link"}
          className="py-4 px-6 flex-1 justify-end text-end font-semibold"
        >
          {currentWorkspace && (
            <>
              {hasPermission("uploadCertificate") &&
              item?.created_by_workspace &&
              user &&
              parseInt(item?.created_by_workspace) === parseInt(user?.workspace_id)
                ? "Edit certificate"
                : "View certificate"}
            </>
          )}
        </Button>
      </div>
    </div>
  );
};

export default CertificateCard;
