import BillingWrapper from "components/HOC/BillingWrapper";
import LeftSidebar from "components/Sidebar/LeftSidebar";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import { clearAuthData } from "store/authenticationSlice";
import { collapseSidebar } from "store/navbarSlice";
import { setSelectedSettingTab } from "store/settingsWorkspaceSlice";
const MainLayout = () => {
  const { token, user, currentWorkspace } = useSelector((state) => state.authenticationSlice);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleLogOut = () => {
    dispatch(clearAuthData());
    dispatch({ type: "LOGOUT" });
    return <Navigate to="/signin" />;
  };

  useEffect(() => {
    if (user && token && currentWorkspace) {
      const handleKeyDown = (event) => {
        const isMac = event.metaKey; // `metaKey` for Cmd on Mac
        const isWindows = event.ctrlKey; // `ctrlKey` for Ctrl on Windows

        // Check for Ctrl + H (Windows) or Cmd + H (Mac)
        if ((isMac || isWindows) && (event.key === "h" || event.key === "H")) {
          event.preventDefault(); // Prevent default browser behavior
          navigate("/");
        }
        if ((isMac || isWindows) && (event.key === "q" || event.key === "Q")) {
          event.preventDefault(); // Prevent default browser behavior
          handleLogOut();
        }
        if ((isMac || isWindows) && event.key === "s") {
          event.preventDefault();
          if (currentWorkspace && currentWorkspace.user_workspace_id) {
            navigate(`/workspace/${currentWorkspace.user_workspace_id}/setting`);
            dispatch(setSelectedSettingTab("profile"));
          }
        }
        if ((isMac || isWindows) && (event.key === "m" || event.key === "M")) {
          event.preventDefault(); // Prevent default browser behavior
          dispatch(collapseSidebar());
        }
      };

      window.addEventListener("keydown", handleKeyDown);

      return () => {
        window.removeEventListener("keydown", handleKeyDown);
      };
    }
  }, [currentWorkspace, user, token, navigate]);

  if (!token || !localStorage.getItem("auth")) {
    const currentPath = window.location.href;
    const appPath = currentPath.replace(window.location.origin, ""); // Remove origin
    if (currentPath && currentPath.includes("setting/active-users?user_hash")) {
      // Store the full URL in localStorage
      localStorage.setItem("grant_access_url", appPath);
    }
    return <Navigate to="/signin" replace />;
  }

  if (token && user && !user.email_verified_at) {
    return <Navigate to="/verify-email" replace />;
  }
  // Initialize history for navigation

  return (
    <BillingWrapper>
      <div className="flex w-full h-screen overflow-hidden gap-x-0 gap-y-6">
        {/* Left Sidebar */}
        <LeftSidebar />

        {/* Main Content */}
        <aside className="flex flex-col flex-1 h-full overflow-x-hidden md:gap-12 ">
          {/* <TopNavbar isFromDashboard={false} /> */}
          <div className="flex flex-col flex-grow pb-12  ">
            <Outlet />
          </div>
        </aside>
      </div>
    </BillingWrapper>
  );
};

export default MainLayout;
