import React from "react";

const smallCross = React.forwardRef(
  (
    {
      width = "12",
      height = "12",
      viewBox = "0 0 12 12",
      fill = "none",
      strokeColor = "#98A2B3",
      className,
      ...props
    },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, strokeColor, className }}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9 3L3 9M3 3L9 9"
          stroke={strokeColor}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  },
);




export default smallCross;
