import React from "react";

const MaterialExtractionIcon = React.forwardRef(
  (
    {
      width = "126",
      height = "126",
      viewBox = "0 0 126 126",
      fill = "none",

      className,
      ...props
    },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="63" cy="63" r="63" fill="#37C290" fill-opacity="0.1" />
        <circle cx="63" cy="63" r="53" fill="#37C290" fill-opacity="0.1" />
        <circle cx="63" cy="63" r="44" fill="#11C182" />
        <path
          d="M47.2681 64.3225L47.362 64.7056C49.0319 71.492 54.7098 76.3238 61.4443 76.9937V86.8395H64.5945V77.0325C76.1632 76.0562 78.571 64.6293 78.7707 64.3225C75.7128 64.3225 72.7208 65.2071 70.1214 66.8816L67.4909 68.5761L67.7062 65.4184C68.0606 60.4624 65.7915 54.8765 63.0194 51.5183C60.3146 54.7906 57.9724 60.3824 58.3326 65.4184L58.5479 68.5761L55.9174 66.8816C53.318 65.2071 50.326 64.3225 47.2681 64.3225Z"
          fill="url(#paint0_linear_851_3921)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M54.2492 74.6802C52.9052 75.1523 51.4599 75.4091 49.9545 75.4091C42.7999 75.4091 37 69.6091 37 62.4545C37 55.2999 42.7999 49.5 49.9545 49.5L50.0353 49.5002C50.7581 43.0302 56.2461 38 62.9091 38C69.572 38 75.0601 43.0302 75.7829 49.5002L75.8636 49.5C83.0182 49.5 88.8182 55.2999 88.8182 62.4545C88.8182 69.6091 83.0182 75.4091 75.8636 75.4091C74.3583 75.4091 72.913 75.1523 71.569 74.6802C69.2743 76.7441 66.2384 78 62.9091 78C59.5798 78 56.5439 76.7441 54.2492 74.6802Z"
          fill="url(#paint1_linear_851_3921)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_851_3921"
            x1="63.0194"
            y1="51.5183"
            x2="63.0194"
            y2="93.4349"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#FCFCFC" />
            <stop offset="1" stop-color="#FCFCFC" stopOpacity="0" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_851_3921"
            x1="62.9091"
            y1="40"
            x2="62.9091"
            y2="78"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="white" />
            <stop offset="1" stop-color="white" stopOpacity="0" />
          </linearGradient>
        </defs>
      </svg>
    );
  },
);

export default MaterialExtractionIcon;
