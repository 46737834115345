// import React, { useState, useEffect } from "react";

// const fetchSvgData = async (path) => {
//   const response = await fetch(`${path}`);
//   const svgData = await response.text();
//   return svgData;
// };

// const DynamicSVG = ({ strokeColor, fillColor, className, path }) => {
//   const [svgProperties, setSvgProperties] = useState(null);

//   useEffect(() => {
//     fetchSvgData(path).then((data) => {
//       if (data) {
//         const parser = new DOMParser();
//         const doc = parser.parseFromString(data, "image/svg+xml");
//         const svgElement = doc.documentElement;

//         // Access SVG attributes
//         const height = svgElement.getAttribute("height");
//         const width = svgElement.getAttribute("width");
//         const viewBox = svgElement.getAttribute("viewBox");
//         const svgFill = svgElement.getAttribute("fill");

//         const extractAttributes = (element) => {
//           if (!element) return null;

//           const attributes = {};
//           for (let i = 0; i < element.attributes.length; i++) {
//             const { nodeName, nodeValue } = element.attributes[i];
//             attributes[nodeName] = nodeValue;
//           }

//           const extractedElement = {
//             tagName: element.tagName,
//             attributes,
//           };

//           if (element.tagName === "g") {
//             extractedElement.children = Array.from(element.children).map((child) =>
//               extractAttributes(child),
//             );
//           }

//           return extractedElement;
//         };

//         const extractSvgChildren = Array.from(svgElement.children).map((child) =>
//           extractAttributes(child),
//         );

//         const updateAttributes = (attributes) => {
//           if (attributes.attributes.fill && fillColor) {
//             return {
//               ...attributes,
//               attributes: {
//                 ...attributes.attributes,
//                 fill: fillColor,
//               },
//             };
//           }
//           return attributes;
//         };

//         const updatedSvgChildren = extractSvgChildren.map((child) => {
//           if (child !== null) {
//             if (child.tagName === "g") {
//               return {
//                 ...child,
//                 children: child.children.map((child) => updateAttributes(child)),
//               };
//             }

//             return updateAttributes(child);
//           }

//           return child;
//         });

//         const generateSvgInnerContent = (svgChildren) => {
//           return svgChildren.reduce((acc, child) => {
//             if (child !== null) {
//               if (child.tagName === "g") {
//                 console.log(path, child);
//                 const newChild = document.createElementNS(
//                   "http://www.w3.org/2000/svg",
//                   child.tagName,
//                 );
//                 if (child.attributes.id) {
//                   newChild.setAttribute("id", child.attributes.id);
//                 }
//                 const children = generateSvgInnerContent(child.children);
//                 children.forEach((nestedChild) => newChild.appendChild(nestedChild));
//                 acc.push(newChild);
//                 return acc;
//               }

//               if (child.tagName === "path") {
//                 const element = document.createElementNS(
//                   "http://www.w3.org/2000/svg",
//                   child.tagName,
//                 );
//                 Object.keys(child.attributes).forEach((key) => {
//                   if (key === "stroke") {
//                     element.setAttribute("stroke", strokeColor);
//                   } else if (key === "fill") {
//                     element.setAttribute("fill", fillColor);
//                   } else {
//                     element.setAttribute(key, child.attributes[key]);
//                   }
//                 });
//                 if (child.textContent) {
//                   element.textContent = child.textContent;
//                 }
//                 acc.push(element);
//               }
//             }

//             return acc;
//           }, []);
//         };

//         const updatedDoc = document.createElementNS("http://www.w3.org/2000/svg", "svg");
//         updatedDoc.setAttribute("height", height);
//         updatedDoc.setAttribute("width", width);
//         updatedDoc.setAttribute("viewBox", viewBox);
//         if (svgFill) {
//           updatedDoc.setAttribute("fill", svgFill);
//         }
//         updatedDoc.append(...generateSvgInnerContent(updatedSvgChildren));

//         const finalSvgInnerContent = updatedDoc.outerHTML;
//         setSvgProperties({ height, width, viewBox, svgFill, innerContent: finalSvgInnerContent });
//       }
//     });
//   }, [path, strokeColor, fillColor]);

//   if (!svgProperties) {
//     return null; // or a placeholder while loading
//   }

//   return <div dangerouslySetInnerHTML={{ __html: svgProperties.innerContent }} />;
// };

// export default DynamicSVG;

import { createSvgIcon } from "@mui/material";
import React, { useState, useEffect } from "react";

const cache = new Map();
const cacheTime = 1000 * 60 * 60 * 24 * 7; // 7 days in milliseconds
const fetchSvgData = async (path) => {
  if (cache.has(path)) {
    const cachedData = cache.get(path);
    const now = Date.now();
    if (now - cachedData.timestamp < cacheTime) {
      return cachedData.data;
    }
  }

  const response = await fetch(`${path}`);
  const svgData = await response.text();

  const dataToCache = {
    data: svgData,
    timestamp: Date.now(),
  };

  cache.set(path, dataToCache);

  return svgData;
};

const DynamicSVG = ({ strokeColor, fillColor, height, width, className, path, onClick }) => {
  const [svgContent, setSvgContent] = useState(null);

  useEffect(() => {
    const loadSvg = async () => {
      try {
        const data = await fetchSvgData(path);
        if (data) {
          const parser = new DOMParser();
          const doc = parser?.parseFromString(data, "image/svg+xml");
          const svgElement = doc?.documentElement;
          setSvgContent(svgElement);
        }
      } catch (error) {
        console.error("Error loading SVG:", error);
      }
    };

    loadSvg();
  }, [path, height, width]);

  if (!svgContent) {
    return null; // or a placeholder while loading
  }

  if (height) {
    svgContent.setAttribute("height", height);
  }
  if (width) {
    svgContent.setAttribute("width", width);
  }

  // Modify SVG attributes
  svgContent.querySelectorAll("path").forEach((pathElement) => {
    if (pathElement.getAttribute("stroke")) {
      pathElement.setAttribute("stroke", strokeColor);
    }
    if (pathElement.getAttribute("fill")) {
      pathElement.setAttribute("fill", fillColor);
    }
  });

  svgContent.querySelectorAll("rect").forEach((pathElement) => {
    if (pathElement.getAttribute("stroke")) {
      pathElement.setAttribute("stroke", strokeColor);
    }
    if (pathElement.getAttribute("fill")) {
      pathElement.setAttribute("fill", fillColor);
    }
  });

  const handleIconClick = (e) => {
    if (onClick) {
      e.stopPropagation();
      onClick();
    }
  };

  return (
    <div
      className={className}
      onClick={handleIconClick}
      dangerouslySetInnerHTML={{ __html: svgContent.outerHTML }}
    />
  );
};

export const FiDownload = ({
  width = 19,
  height = 19,
  fillColor = "none",
  viewBox = "0 0 19 19",
  strokeColor,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill={fillColor}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.625 11.875V15.0417C16.625 15.4616 16.4582 15.8643 16.1613 16.1613C15.8643 16.4582 15.4616 16.625 15.0417 16.625H3.95833C3.53841 16.625 3.13568 16.4582 2.83875 16.1613C2.54181 15.8643 2.375 15.4616 2.375 15.0417V11.875"
        stroke={strokeColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.54199 7.91675L9.50033 11.8751L13.4587 7.91675"
        stroke={strokeColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.5 11.875V2.375"
        stroke={strokeColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DynamicSVG;
