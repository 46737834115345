import axios from "axios";
import api from "config/axios";
import { API_URL } from "config/environment";

export const getCompanyDetailsForUser = () => api.get("/company-settings");
export const updateCompanyDetailsById = (data) => {
  return api.put(`/company/${data.companyId}`, data);
};

export const deactivateCompanyAccount = async (data) => {
  try {
    const response = await api.post(`/deactive-company`, data);
    return response;
  } catch (error) {
    throw error;
  }
}

export const confirmDeactivation = async (data) => {
  const response = await axios.post(`${API_URL}/deactive-confirmation`, { hash: data.hash }, {
    headers: { Authorization: `Bearer ${data.token}` }
  });
  return response;
}