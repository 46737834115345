import React from "react";

const Logout_icon = React.forwardRef(
  (
    {
      width = "21",
      height = "20",
      viewBox = "0 0 21 20",
      fill = "none",
      strokeColor = "white",
      onClick = () => {},
      className,
    },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className, onClick }}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.8333 14.1667L18 10M18 10L13.8333 5.83334M18 10H8M10.5 14.1667C10.5 14.413 10.5 14.5361 10.4908 14.6428C10.3957 15.7516 9.57905 16.664 8.48753 16.8811C8.38252 16.9019 8.26001 16.9156 8.01529 16.9427L7.16412 17.0373C5.8854 17.1794 5.24601 17.2504 4.73805 17.0879C4.06078 16.8712 3.50785 16.3763 3.21765 15.7271C3 15.2402 3 14.5969 3 13.3103V6.68975C3 5.40314 3 4.75983 3.21765 4.27293C3.50785 3.62373 4.06078 3.12884 4.73805 2.91211C5.24601 2.74956 5.88538 2.8206 7.16412 2.96268L8.01529 3.05726C8.2601 3.08446 8.3825 3.09806 8.48753 3.11894C9.57905 3.33596 10.3957 4.24837 10.4908 5.35717C10.5 5.46386 10.5 5.58702 10.5 5.83334"
          stroke={strokeColor}
          strokeWidth="1.66667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  },
);

export default Logout_icon;
