import { Cross, OrderIcon } from "assests";
import { Button } from "components/UI/Button";
import CustomMultiSelect from "components/UI/CustomMultiSelect";
import CustomSelect from "components/UI/CustomSelect";
import Divider from "components/UI/Divider";
import ErrorMessage from "components/UI/ErrorMessage";
import { Label } from "components/UI/Label";
import LoadingComponent from "components/UI/LoadingComponent";
import UpgradeComponent from "components/UI/UpgradeComponent";
import useUpdateTiersOrders from "hooks/useUpdateTiersOrders";
import { useMemo } from "react";

const UpdateOrderTiers = ({ closeModal, orderDetailsData, refetchOrderDetails }) => {
  const memoisedOrderDetails = useMemo(() => orderDetailsData, [orderDetailsData]);

  const {
    control,
    tierList,
    tiersItem,
    errors,
    isPending,
    register,
    handleSubmit,
    onFormError,
    handleFormSubmit,
    handleTierOptionSelect,
    resetTheCreateForm,
    canUpdateTiers,
    subTiersLoading
  } = useUpdateTiersOrders({ refetchOrderDetails, orderDetailsData: memoisedOrderDetails });

  const onClickCloseModal = () => {
    closeModal();
    resetTheCreateForm();
  };

  return (
    <>
      <div className="relative  z-[2] border border-global-divider-soft rounded-xl  w-full lg:w-[600px] max-h-[600px] py-6 bg-white">
        <div className="flex justify-between gap-x-4 px-6 pb-5">
          {subTiersLoading ? <div></div> :
            <div className="flex justify-start items-center gap-4">
              <UpgradeComponent>
                <OrderIcon
                  strokeColor={"var(--color-txt-secondary-secondary)"}
                  width={24}
                  height={24}
                />
              </UpgradeComponent>
              <div className="flex justify-between gap-6 lg:gap-8 px-3 lg:px-4 z-[2]">
                <div className="flex flex-col gap-1">
                  <h2 className="font-semibold text-sm lg:text-lg leading-7 text-start  text-primary-900 w-full">
                    Supply Chain Settings
                  </h2>
                  <div className="text-sm text-login-text-paragraph font-normal text-wrap leading-5">
                    Adjust supply chain settings for this order here.
                  </div>
                </div>
              </div>
            </div>
          }
          <div>
            <Cross
              fillColor="var(--color-icon-fill-input)"
              width={24}
              height={24}
              onClick={onClickCloseModal}
              className="cursor-pointer"
            />
          </div>
        </div >
        {subTiersLoading ? (
          <div className="grid gap-2">
            <div className="flex items-center justify-center w-full h-32 mt-10">
              <div className="border-4 rounded-full w-7 h-7 animate-spin border-global-primary border-t-transparent"></div>
            </div>
            <p className="mt-5 text-center">Preparing form data, please wait...</p>
          </div>
        ) :
          <form onSubmit={handleSubmit(handleFormSubmit, onFormError)} method="post">
            <div className="grid grid-cols-1 w-full px-6 h-[327px] lg:h-[387px] overflow-auto mt-3 lg:mt-5 ">
              {tierList && (
                <>
                  <div className="flex flex-col md:flex-row gap-1.5 md:gap-8 w-full py-2 md:py-4">
                    <Label
                      htmlFor="templateName"
                      className="text-sm leading-5 pr-1 tracking-wide font-medium whitespace-nowrap"
                    >
                      Tier 4
                    </Label>
                    <div className="w-full grid grid-cols-1 gap-1.5">
                      <CustomSelect
                        control={control}
                        name="subtiers"
                        placeholder="Select Template"
                        options={tierList["tier_5"]}
                        handleOnChange={(selectedOption) =>
                          handleTierOptionSelect("tier_5", selectedOption)
                        }
                        {...register("tier_5")}
                        value={tiersItem["tier_5"]}
                        isClearable={false}
                        isMulti={true}
                        isDisabled={!canUpdateTiers}
                        style={{
                          padding: "3.34px 10px 3.34px 6px",
                          fontSize: "12px",
                          width: "100%",
                          noHeight: true,
                        }}
                      />
                      <ErrorMessage errors={errors?.tier_5?.message} />
                    </div>
                  </div>
                  <div className="flex flex-col md:flex-row gap-1.5 md:gap-8 py-2 md:py-4">
                    <Label
                      htmlFor="templateName"
                      className="text-sm leading-5 pr-1 tracking-wide font-medium whitespace-nowrap"
                    >
                      Tier 3
                    </Label>
                    <div className="w-full grid grid-cols-1 gap-1.5">
                      <CustomSelect
                        control={control}
                        name="tier 3"
                        placeholder="Select Template"
                        options={tierList["tier_4"]}
                        handleOnChange={(selectedOption) =>
                          handleTierOptionSelect("tier_4", selectedOption)
                        }
                        {...register("tier_4")}
                        value={tiersItem["tier_4"] || []}
                        isClearable={false}
                        isMulti={true}
                        isDisabled={!canUpdateTiers}
                        style={{
                          padding: "3.34px 10px 3.34px 6px",
                          fontSize: "12px",
                          width: "100%",
                          noHeight: true,
                        }}
                      />
                      <ErrorMessage errors={errors?.tier_4?.message} />
                    </div>
                  </div>
                  <div className="flex flex-col md:flex-row gap-1.5 md:gap-8 py-2 md:py-4">
                    <Label
                      htmlFor="templateName"
                      className="text-sm leading-5 pr-1 tracking-wide font-medium whitespace-nowrap"
                    >
                      Tier 2
                    </Label>
                    <div className="w-full grid grid-cols-1 gap-1.5 ">
                      <CustomSelect
                        control={control}
                        name="tier 2"
                        placeholder="Select Template"
                        options={tierList["tier_3"]}
                        handleOnChange={(selectedOption) =>
                          handleTierOptionSelect("tier_3", selectedOption)
                        }
                        {...register("tier_3")}
                        value={tiersItem["tier_3"]}
                        isClearable={false}
                        isMulti={true}
                        isDisabled={!canUpdateTiers}
                        style={{
                          padding: "3.34px 10px 3.34px 6px",
                          fontSize: "12px",
                          width: "100%",
                          noHeight: true,
                        }}
                      />
                      <ErrorMessage errors={errors?.tier_3?.message} />
                    </div>
                  </div>
                  <div className="flex flex-col md:flex-row gap-1.5 md:gap-8 py-2 md:py-4">
                    <Label
                      htmlFor="templateName"
                      className="text-sm leading-5 pr-1 tracking-wide font-medium whitespace-nowrap"
                    >
                      Tier 1
                    </Label>
                    <div className="w-full grid grid-cols-1 gap-1.5">
                      <CustomSelect
                        control={control}
                        name="tier 1"
                        placeholder="Select Template"
                        options={tierList["tier_2"]}
                        handleOnChange={(selectedOption) =>
                          handleTierOptionSelect("tier_2", selectedOption)
                        }
                        {...register("tier_2")}
                        value={tiersItem["tier_2"]}
                        isClearable={false}
                        isMulti={true}
                        isDisabled={!canUpdateTiers}
                        style={{
                          padding: "3.34px 10px 3.34px 6px",
                          fontSize: "12px",
                          width: "100%",
                          noHeight: true,
                        }}
                      />
                      <ErrorMessage errors={errors?.tier_2?.message} />
                    </div>
                  </div>
                  <div className="flex flex-col md:flex-row gap-1.5 md:gap-8 w-full py-2 md:py-4">
                    <Label
                      htmlFor="templateName"
                      className="text-sm leading-5 pr-1 tracking-wide font-medium whitespace-nowrap"
                    >
                      Tier 0
                    </Label>
                    <div className="w-full grid grid-cols-1 gap-1.5">
                      <CustomSelect
                        control={control}
                        name="tier 0"
                        placeholder="Select Template"
                        options={tierList["tier_1"]}
                        handleOnChange={(selectedOption) =>
                          handleTierOptionSelect("tier_1", selectedOption)
                        }
                        {...register("tier_1")}
                        value={tiersItem["tier_1"]}
                        isClearable={false}
                        isMulti={true}
                        isDisabled={!canUpdateTiers}
                        style={{
                          padding: "3.34px 10px 3.34px 6px",
                          fontSize: "12px",
                          width: "100%",
                          noHeight: true,
                        }}
                      />
                      <ErrorMessage errors={errors?.tier_1?.message} />
                    </div>
                  </div>
                </>
              )}
            </div>
            {canUpdateTiers && <Divider className={"mb-6"} />}
            {canUpdateTiers && (
              <div className="grid gap-6  px-6 sticky  mt-4 lg:mt-0">
                <div className="flex  justify-center items-center lg:justify-between gap-4">
                  <div className="flex flex-col lg:flex-row  w-full gap-3">
                    <Button
                      type="button"
                      size={"md"}
                      className={
                        "w-full text-txt-secondary-secondary bg-white h-11 px-4 py-2.5 text-base leading-6 font-semibold custom-outline-shadow hover:text-white"
                      }
                      variant={"secondary"}
                      onClick={onClickCloseModal}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      size={"md"}
                      className="h-11 px-4 py-2.5 text-base w-full "
                      isDisabled={isPending}
                    >
                      Save
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </form>
        }
      </div >


    </>
  );
};

export default UpdateOrderTiers;
