import { Autocomplete, LoadScript } from "@react-google-maps/api";
import { CompanyIconRotate, Cross } from "assests";
import { Button } from "components/UI/Button";
import CountrySelect from "components/UI/CountrySelect";
import ErrorMessage from "components/UI/ErrorMessage";
import { Input } from "components/UI/Input";
import { Label } from "components/UI/Label";
import UpgradeComponent from "components/UI/UpgradeComponent";
import { useGoogleMaps } from "contexts/GoogleMapsContext";
import { Country } from "country-state-city";
import { useEffect, useState } from "react";
import { handleAcceptNumbersOnKeyPress, handleAcceptNumbersOnPaste } from "utils/helper";
const EditCompanyForm = ({
  closeModal,
  control,
  errors,
  register,
  setValue,
  onError,
  clearErrors,
  handleSubmit,
  selectedCountry,
  setSelectedCountry,
}) => {
  const [autocomplete, setAutocomplete] = useState(null);

  const { isLoaded } = useGoogleMaps();

  const onLoad = (autocomplete) => {
    setAutocomplete(autocomplete);
  };

  const onPlaceChanged = () => {
    if (autocomplete) {
      const place = autocomplete.getPlace();
      if (place.formatted_address) {
        const formattedAddress = place.formatted_address;
        setValue("address", formattedAddress);
      } else {
        const suggestedAddress = place.name;
        setValue("address", suggestedAddress);
      }
      if (place.address_components) {
        let street = "";
        let zip = "";
        let region = "";
        let country = "";
        let countryCode = "";
        place.address_components.forEach((component) => {
          const types = component.types;
          if (types.includes("street_number")) {
            street = component.long_name + " ";
          }
          if (types.includes("route")) {
            street += component.long_name;
          }
          if (types.includes("postal_code")) {
            zip = component.long_name;
          }
          if (types.includes("locality")) {
            region = component.long_name;
          }
          if (types.includes("country")) {
            country = component.long_name;
            countryCode = component.short_name;
          }
        });
        let countryData = Country.getCountryByCode(country);
        if (!countryData) {
          countryData = Country.getAllCountries().find(
            (c) => c.name.toLowerCase() === country?.toLowerCase(),
          );
        }
        if (countryData) {
          setValue("country", countryData.name);
          setValue("country_code", countryData.isoCode);
          setSelectedCountry({
            value: countryData.name,
            label: countryData.name,
            flag: countryData.flag,
            isoCode: countryData.isoCode,
          });
        }
        setValue("street", street);
        setValue("zip", zip);
        setValue("region", region);
        setValue("country", country);
        setValue("country_code", countryCode);
      }
    }
  };

  useEffect(() => {
    if (selectedCountry) {
      clearErrors("country");
      clearErrors("country_code");
      setValue("country", selectedCountry.value);
      setValue("country_code", selectedCountry.isoCode);
    }
  }, [selectedCountry]);

  return (
    <>
      <div className="w-full lg:w-[640px] p-6 relative z-[2] bg-white border border-global-divider-soft rounded-xl ">
        <div className="flex justify-between mb-3.5">
          <div>
            <UpgradeComponent>
              <CompanyIconRotate fillColor="var(--color-icon-default-2)" />
            </UpgradeComponent>
          </div>
          <div>
            <Cross
              fillColor="var(--color-icon-fill-input)"
              width={24}
              height={24}
              onClick={closeModal}
              className="cursor-pointer"
            />
          </div>
        </div>
        <div className="grid grid-cols-1 gap-1 mb-3 lg:mb-5">
          <h3 className="text-base lg:text-lg leading-normal font-semibold text-global-title">
            Account details
          </h3>
          <p className="text-xs lg:text-sm text-global-paragraph font-normal">
            Update your account details here.
          </p>
        </div>
        <form onSubmit={handleSubmit} onError={onError}>
          <div className="grid grid-cols-12 gap-4">
            <div className="col-span-12 ">
              <div className="grid gap-1.5">
                <Label className="text-sm font-medium text-input-text-label">Company Name</Label>
                <Input
                  id="name"
                  type="text"
                  size="lg"
                  placeholder="Enter company name"
                  {...register("name", {
                    setValueAs: (value) => value.trim(),
                  })}
                />
                <ErrorMessage errors={errors?.name?.message} />
              </div>
            </div>
            <div className="col-span-12 ">
              <div className="grid gap-1.5">
                <div>
                  <Label className="text-sm leading-[26px] font-medium text-input-text-label">
                    Street
                  </Label>
                  <div className="relative">
                    {isLoaded && (
                      <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
                        <Input
                          id="street"
                          type="text"
                          placeholder="Enter street"
                          {...register("street")}
                          onChange={(e) => {
                            console.log(e);
                          }}
                        />
                      </Autocomplete>
                    )}
                  </div>
                </div>

                <ErrorMessage errors={errors?.street?.message} />
              </div>
            </div>

            <div className="col-span-12 lg:col-span-6">
              <div className="grid gap-1.5">
                <Label className="text-sm font-medium text-input-text-label">ZIP</Label>
                <Input
                  id="zip"
                  type="text"
                  size="lg"
                  placeholder="Enter ZIP"
                  {...register("zip")}
                />
                <ErrorMessage errors={errors?.zip?.message} />
              </div>
            </div>
            <div className="col-span-12 lg:col-span-6">
              <div className="grid gap-1.5 ">
                <Label className="text-sm font-medium text-input-text-label">City</Label>
                <Input
                  id="region"
                  type="text"
                  size="lg"
                  placeholder="Enter city"
                  {...register("region")}
                />
                <ErrorMessage errors={errors?.region?.message} />
              </div>
            </div>

            <div className="col-span-12  w-full">
              <div className="grid gap-1.5 w-full">
                <Label className="text-sm font-medium text-input-text-label">Country</Label>
                <CountrySelect control={control} {...{ selectedCountry, setSelectedCountry }} />
                <ErrorMessage errors={errors?.country?.message} />
              </div>
            </div>

            <div className="col-span-12 ">
              <div className="grid gap-1.5">
                <Label className="text-sm font-medium text-input-text-label">
                  Registration Number
                </Label>
                <div className="flex w-full h-[44px] gap-1.5 flex-start phone-input-field border border-input-stroke-default hover:border-gray-800   hover:transition hover:duration-300 hover:ease-in-out ">
                  <span className="inline-flex items-center pl-3.5 pr-3 border-r border-r-input-stroke-default rounded-e-0">
                    {selectedCountry ? selectedCountry?.isoCode : "N/A"}
                  </span>
                  <Input
                    type="text"
                    size="lg"
                    className="rounded-none rounded-e-lg w-full h-full placeholder:text-input-text-placeholder placeholder:font-normal placeholder:text-base  self-stretch"
                    placeholder="865112084B01"
                    {...register("registration_number", {
                      setValueAs: (value) => value.trim(),
                    })}
                  />
                </div>
                <ErrorMessage errors={errors?.registration_number?.message} />
              </div>
            </div>
          </div>

          <div className="flex flex-col lg:flex-row gap-3 pt-3 lg:pt-8">
            <Button
              variant={"secondary"}
              className="px-4 py-2.5 w-full  h-11 text-[10px] lg:text-base font-semibold rounded-lg"
              onClick={closeModal}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant={"primary"}
              className="px-4 py-2.5 w-full  h-11 text-[10px] lg:text-base font-semibold rounded-lg"
            >
              Update details
            </Button>
          </div>
        </form>
      </div>
    </>
  );
};

export default EditCompanyForm;
