import { Cross, Search, SmallCrossIcon, TickIcon, XCircle, XCloseIcon } from "assests";
import ResultNotFound from "components/Card/ResultNotFound";

import React, { useRef, useState } from "react";
import { Input } from "./Input";
import { useSelector } from "react-redux";

const SearchWorkspaces = ({ selected, onSelectChange }) => {
  const [isMenuVisible, setMenuVisible] = useState(false);
  const menuRef = useRef(null);
  const searchInputRef = useRef(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [hoveredId, setHoveredId] = useState(null);
  const { workspaceList } = useSelector((state) => state.userSlice);
  const filteredPeople = workspaceList && workspaceList?.filter((person) =>
    person.name.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  const handleFocus = () => {
    setMenuVisible(true);
  };

  const handleBlur = (event) => {
    if (menuRef.current && menuRef.current.contains(event.relatedTarget)) {
      return;
    }
    setMenuVisible(false);
  };

  const handleMouseDown = (event) => {
    if (menuRef.current && menuRef.current.contains(event.target)) {
      event.preventDefault();
    }
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value?.trim() || "");
  };
  const handleClearSearch = () => {
    setSearchTerm("");
    searchInputRef.current.value = "";
  }

  // Toggle select/deselect for individual workspaces
  const handleSelect = (person) => {
    const isSelected = selected.some((p) => p.id === person.id);
    const newSelected = isSelected
      ? selected.filter((p) => p.id !== person.id)
      : [...selected, person];
    onSelectChange(newSelected);
  };

  // Toggle select/deselect all workspaces
  const handleSelectAll = () => {
    const newSelected = selected.length === filteredPeople.length ? [] : filteredPeople;
    onSelectChange(newSelected);
  };

  return (
    <div className="relative" onMouseDown={handleMouseDown}>
      <Input
        iconLeft={
          <Search
            className="w-4 h-4 lg:w-5 lg:h-5"
            strokeColor={"var(--color-icon-default-4)"}
            height={20}
            width={20}
          />
        }
        autoComplete="off"
        id="searchInputId"
        type="text"
        onFocus={handleFocus}
        onBlur={handleBlur}
        onChange={handleSearch}
        ref={searchInputRef}
        placeholder="Select workspace..."
        className="w-full !ps-10"
        iconRight={
          searchTerm ? (
            <XCircle
              width={20}
              height={20}
              onClick={handleClearSearch}
              fillColor={"var(--color-icon-fill-input)"}
              className="cursor-pointer"
            />
          ) : (
            ""
          )
        }

      />

      {isMenuVisible && (
        <div
          ref={menuRef}
          className="absolute z-50 top-[46px] py-1 w-full  rounded-lg focus:outline-none bg-global-theme "
        >
          <div
            className="rounded-lg px-1.5 bg-btn-surface-secondary max-h-[320px] border border-global-divider-soft overflow-y-auto "
            style={{
              boxShadow:
                "0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08)",
            }}
          >

            {filteredPeople && filteredPeople?.length > 0 ? (
              <>
                <div
                  className="w-full text-end pl-2 pr-2.5 py-2.5 my-1 text-base text-global-title font-medium leading-6 hover:text-global-primary cursor-pointer"
                  onClick={handleSelectAll}
                >
                  {selected && selected?.length === filteredPeople?.length ? "Deselect all" : "Select all"}
                </div>
                <div className="grid grid-cols-1 gap-[2px] pb-9 overflow-y-auto">
                  {filteredPeople.map((person) => {
                    const isSelected = selected.some((p) => p.id === person.id);
                    return (
                      <div
                        key={person.id}
                        onClick={() => handleSelect(person)}
                        onMouseEnter={() => setHoveredId(person.id)}
                        onMouseLeave={() => setHoveredId(null)}
                        className={`cursor-pointer flex items-center justify-start w-full pl-2 pr-2.5 py-2.5 rounded-md hover:bg-global-white ${isSelected ? "bg-global-white" : ""
                          }`}
                      >
                        <span className="text-base font-medium text-global-title truncate">{person.name}</span>
                        {isSelected && (
                          <span className="ml-auto">
                            {hoveredId === person.id ? (
                              <XCloseIcon strokeColor={"var(--color-global-danger)"} width={20} height={20} />
                            ) : (
                              <TickIcon width={20} height={20} />
                            )}
                          </span>
                        )}
                      </div>
                    );
                  })}
                </div>
              </>
            ) : (
              <ResultNotFound text="Result not found" />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default SearchWorkspaces;
