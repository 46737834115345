import React from "react";

const MaterialProcessingIcon = React.forwardRef(
  (
    {
      width = "126",
      height = "126",
      viewBox = "0 0 126 126",
      fill = "none",

      className,
      ...props
    },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="63" cy="63" r="63" fill="#37C290" fill-opacity="0.1" />
        <circle cx="63" cy="63" r="53" fill="#37C290" fill-opacity="0.1" />
        <circle cx="63" cy="63" r="44" fill="#11C182" />
        <rect
          x="39.9111"
          y="56.0886"
          width="4.44279"
          height="41.7622"
          transform="rotate(-45 39.9111 56.0886)"
          fill="url(#paint0_linear_851_3922)"
        />
        <rect
          x="46.1943"
          y="49.8057"
          width="4.44279"
          height="41.7622"
          transform="rotate(-45 46.1943 49.8057)"
          fill="url(#paint1_linear_851_3922)"
        />
        <rect
          x="52.4775"
          y="43.5225"
          width="4.44279"
          height="41.7622"
          transform="rotate(-45 52.4775 43.5225)"
          fill="url(#paint2_linear_851_3922)"
        />
        <rect
          x="69.4414"
          y="40.3811"
          width="4.44279"
          height="41.7622"
          transform="rotate(45 69.4414 40.3811)"
          fill="url(#paint3_linear_851_3922)"
        />
        <rect
          x="75.7246"
          y="46.6641"
          width="4.44279"
          height="41.7622"
          transform="rotate(45 75.7246 46.6641)"
          fill="url(#paint4_linear_851_3922)"
        />
        <rect
          x="82.0078"
          y="52.9473"
          width="4.44279"
          height="41.7622"
          transform="rotate(45 82.0078 52.9473)"
          fill="url(#paint5_linear_851_3922)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_851_3922"
            x1="42.1325"
            y1="47.203"
            x2="42.1325"
            y2="112.068"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="white" />
            <stop offset="1" stop-color="white" stopOpacity="0" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_851_3922"
            x1="48.4157"
            y1="40.9201"
            x2="48.4157"
            y2="105.785"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="white" />
            <stop offset="1" stop-color="white" stopOpacity="0" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_851_3922"
            x1="54.6989"
            y1="34.6369"
            x2="54.6989"
            y2="99.5016"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="white" />
            <stop offset="1" stop-color="white" stopOpacity="0" />
          </linearGradient>
          <linearGradient
            id="paint3_linear_851_3922"
            x1="71.6628"
            y1="31.4955"
            x2="71.6628"
            y2="96.3602"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="white" />
            <stop offset="1" stop-color="white" stopOpacity="0" />
          </linearGradient>
          <linearGradient
            id="paint4_linear_851_3922"
            x1="77.946"
            y1="37.7785"
            x2="77.946"
            y2="102.643"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="white" />
            <stop offset="1" stop-color="white" stopOpacity="0" />
          </linearGradient>
          <linearGradient
            id="paint5_linear_851_3922"
            x1="84.2292"
            y1="44.0617"
            x2="84.2292"
            y2="108.926"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="white" />
            <stop offset="1" stop-color="white" stopOpacity="0" />
          </linearGradient>
        </defs>
      </svg>
    );
  },
);

export default MaterialProcessingIcon;
