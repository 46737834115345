import React, { useEffect, useRef, useState } from "react";
import { CheckIcon, ChevronDownIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";
import { Listbox } from "@headlessui/react";
import { Search } from "assests"; // Ensure correct import path
import ResultNotFound from "components/Card/ResultNotFound";
import { Input } from "./Input";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { searchPartners } from "services/invitation.services";
import { setSearchedPartnersData } from "store/invitationSlice";
import LoadingComponent from "./LoadingComponent";

const SearchBoxComponent = ({ handleViewDetailsClick, setEnteredSearchKey }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isMenuVisible, setMenuVisible] = useState(false);
    const menuRef = useRef(null);
    const searchInputRef = useRef(null);
    const [searchKey, setSearchKey] = useState("");
    const { currentWorkspace } = useSelector((state) => state.authenticationSlice);
    const { searchedPartnersData } = useSelector((state) => state.invitationSlice);
    const handleMouseDown = (event) => {
        if (menuRef.current && menuRef.current.contains(event.target)) {
            event.preventDefault();
        }
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setMenuVisible(false);
            }
        };

        document.addEventListener("click", handleClickOutside);

        // Clean up the event listener when the component unmounts
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, []);

    const {
        data: searchPartnersDataQuery,
        isLoading,
        refetch,
    } = useQuery({
        queryKey: ["globalSearch", "getGlobalSearchData", searchKey],
        queryFn: () =>
            searchPartners({
                search: searchKey,
                workspace_id: parseInt(currentWorkspace?.workspace_id),
            }),
        enabled: !!searchKey && !!currentWorkspace,
    });

    useEffect(() => {
        if (!isLoading && searchPartnersDataQuery) {
            dispatch(
                setSearchedPartnersData({
                    searchedPartnersData: searchPartnersDataQuery?.data?.data?.data,
                    append: false,
                }),
            );

        }
    }, [searchPartnersDataQuery, isLoading]);

    const handleSearch = (e) => {
        const searchText = e.target.value?.trim();
        if (searchText.length >= 2) {
            setMenuVisible(true);
            setSearchKey(searchText);
        } else {
            setSearchKey("");
            setEnteredSearchKey("");
            setMenuVisible(false);
            dispatch(
                setSearchedPartnersData({
                    searchedPartnersData: null,
                    append: false,
                }),
            );
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            e.stopPropagation();
            const searchText = e.target.value?.trim();
            if (searchText.length >= 2) {
                setEnteredSearchKey(searchText);
            } else {
                setEnteredSearchKey("");
            }
        }
    };

    return (
        <div className="relative"  >
            <Input
                iconLeft={
                    <Search
                        className="w-4 h-4 lg:w-5 lg:h-5"
                        strokeColor={"var(--color-icon-default-4)"}
                        height={20}
                        width={20}
                    />
                }
                autoComplete="off"
                id="searchInputId"
                type="text"
                onMouseDown={handleMouseDown}
                onKeyDown={handleKeyDown}
                onChange={handleSearch}
                ref={searchInputRef}
                placeholder="Search for company"
                className="md:w-[400px]"
            />


            {isMenuVisible && (
                <div
                    ref={menuRef}

                    className="absolute z-50 w-full lg:w-[400px] my-2 rounded-lg focus:outline-none"
                >
                    <div
                        className="rounded-lg px-1.5 py-1 bg-global-theme max-h-[320px] overflow-y-auto border border-global-divider-soft"
                        style={{
                            boxShadow:
                                "0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)",
                        }}
                    >
                        {searchedPartnersData ? (
                            <div>
                                {searchedPartnersData?.length > 0 ? (
                                    <div className="grid grid-cols-1 gap-0 py-[1px]  overflow-y-auto">
                                        {searchedPartnersData?.map((item, index) => (
                                            <div
                                                key={index}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    e.preventDefault();
                                                    if (item.status === "accepted") {
                                                        handleViewDetailsClick(
                                                            item.company_type,
                                                            item.invited_workspace_id,
                                                            item.status,
                                                            item.workspace_id,
                                                        );
                                                    }
                                                }}
                                                className={` flex items-center justify-start gap-2 w-full pl-2 pr-2.5 py-2.5 rounded-md hover:bg-[#F9FAFB] ${item.status === "accepted" ? "cursor-pointer" : "cursor-not-allowed"}`}
                                            >
                                                <div className="text-sm lg:text-base font-medium text-global-title">
                                                    {item.company_name}
                                                </div>
                                                <div className="text-sm lg:text-base font-normal  text-btn-text-tertiary">
                                                    {item.company_type}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                ) : (
                                    <ResultNotFound text="Result not found" />
                                )}
                            </div>
                        ) : (
                            <LoadingComponent />
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default SearchBoxComponent;
