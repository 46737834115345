import React from "react";

const mailSuccessIcon = React.forwardRef(
  (
    {
      width = "15",
      height = "15",
      viewBox = "0 0 15 15",
      fill = "none",
      fillColor = "#444656",
      className,
      ...props
    },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_10558_32006)">
          <path
            d="M12.3325 14.5H2.66746C1.47232 14.5 0.5 13.5277 0.5 12.3326V6.18182C0.5 5.95531 0.683641 5.77167 0.910156 5.77167C1.13667 5.77167 1.32031 5.95531 1.32031 6.18182C1.32031 6.56759 1.53455 6.91417 1.87941 7.08657L7.5 9.89705L13.1205 7.0866C13.4654 6.91417 13.6797 6.56759 13.6797 6.18204V6.18182C13.6797 5.95531 13.8633 5.77167 14.0898 5.77167C14.315 5.77167 14.4978 5.95315 14.5 6.17783C14.5 6.17914 14.5 6.18048 14.5 6.18182V12.3326C14.5 13.5277 13.5277 14.5 12.3325 14.5Z"
            fill="#5CF1BB"
          />
          <path
            d="M14.5 12.3326V6.18182C14.5 6.18051 14.5 6.17917 14.5 6.17783C14.4978 5.95315 14.315 5.77167 14.0898 5.77167C13.8633 5.77167 13.6797 5.95531 13.6797 6.18182V6.18204C13.6797 6.56759 13.4654 6.91417 13.1205 7.0866L7.5 9.89705V14.5H12.3325C13.5277 14.5 14.5 13.5277 14.5 12.3326Z"
            fill="#11C182"
          />
          <path
            d="M12.1169 8.28824C11.9959 8.21351 11.9224 8.0815 11.9224 7.93934V4.42474C11.9224 4.28258 11.9959 4.15056 12.1169 4.07583C12.2377 4.0011 12.3887 3.99426 12.5159 4.05787L13.4873 4.54352C14.1119 4.85576 14.4999 5.48351 14.4999 6.18182V6.18204C14.4999 6.88034 14.1119 7.5081 13.4873 7.82031L12.5159 8.30621C12.3914 8.36844 12.2403 8.36451 12.1169 8.28824Z"
            fill="#0D9766"
          />
          <path
            d="M2.48404 8.30618L1.51251 7.82023C0.888008 7.50808 0.5 6.88035 0.5 6.18204V6.18182C0.5 5.48352 0.888008 4.85576 1.51262 4.54355L2.48401 4.0579C2.61113 3.99429 2.76215 4.00113 2.88306 4.07586C3.004 4.15059 3.07759 4.28261 3.07759 4.42477V7.93937C3.07759 8.08153 3.004 8.21354 2.88306 8.28827C2.76425 8.36169 2.61323 8.37083 2.48404 8.30618Z"
            fill="#11C182"
          />
          <path
            d="M7.50002 10.7657C7.43716 10.7657 7.37432 10.7513 7.3166 10.7225L2.48406 8.30618C2.3451 8.2367 2.25732 8.09467 2.25732 7.93931V2.22813C2.25732 1.27355 3.02946 0.5 3.98545 0.5H11.0146C11.969 0.5 12.7427 1.27202 12.7427 2.22813V7.93933C12.7427 8.0947 12.6549 8.2367 12.516 8.3062L7.68345 10.7225C7.62572 10.7513 7.56289 10.7657 7.50002 10.7657Z"
            fill="#CCFBEA"
          />
          <path
            d="M12.516 8.30618C12.6549 8.2367 12.7427 8.09467 12.7427 7.93931V2.22813C12.7427 1.27202 11.969 0.5 11.0146 0.5H7.5V10.7657C7.56286 10.7657 7.6257 10.7513 7.68342 10.7225L12.516 8.30618Z"
            fill="#94F6D3"
          />
          <path
            d="M6.55563 8.37806L4.13473 5.95715C3.97455 5.797 3.97469 5.53712 4.13476 5.37708L5.37739 4.13466C5.53757 3.97454 5.79726 3.97451 5.95741 4.13469L6.84564 5.02293L9.05185 2.81672C9.212 2.65657 9.47168 2.65654 9.63186 2.81669L10.8745 4.05911C11.0347 4.21932 11.0345 4.47917 10.8745 4.63918L7.13568 8.37806C6.97547 8.53827 6.71568 8.53807 6.55563 8.37806Z"
            fill="#11C182"
          />
          <path
            d="M7.5 8.0137L10.8745 4.63918C11.0345 4.47917 11.0347 4.21932 10.8745 4.05911L9.63186 2.81669C9.4717 2.65654 9.21202 2.65657 9.05184 2.81672L7.5 4.36859V8.0137Z"
            fill="#0D9766"
          />
        </g>
        <defs>
          <clipPath id="clip0_10558_32006">
            <rect width="14" height="14" fill="white" transform="translate(0.5 0.5)" />
          </clipPath>
        </defs>
      </svg>
    );
  },
);

export default mailSuccessIcon;
