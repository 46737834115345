import React from "react";

const EmptyDashboard = React.forwardRef(
  (
    {
      width = "72",
      height = "72",
      viewBox = "0 0 72 72",
      fill = "none",

      className,
      ...props
    },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="fi_4076441" clipPath="url(#clip0_1116_12428)">
          <g id="Group">
            <g id="Group_2">
              <g id="Group_3">
                <g id="Group_4">
                  <path
                    id="Vector"
                    d="M52.1406 36.0586C53.2278 34.3327 54.0462 32.3965 54.5217 30.2936C56.7683 20.3576 50.5348 10.4815 40.5987 8.23485C30.6626 5.98822 20.7866 12.2217 18.54 22.1578C17.9647 24.7025 17.9467 27.2427 18.4034 29.6523L52.1406 36.0586Z"
                    fill="#A8EFD6"
                  />
                  <path
                    id="Vector_2"
                    d="M54.521 30.294C54.047 32.3961 53.2285 34.3325 52.1401 36.0588L47.1122 35.1041C48.1992 33.3775 49.0177 31.4411 49.4931 29.3393C51.5984 20.0348 46.2655 10.7823 37.4181 7.79962C38.4713 7.85137 39.5345 7.9934 40.5997 8.23457C50.5344 10.4818 56.7686 20.3577 54.521 30.294Z"
                    fill="#70E6BD"
                  />
                  <path
                    id="Vector_3"
                    d="M71.9006 35.8862C73.2673 28.7092 58.3033 19.8305 38.4776 16.0551C18.6519 12.2798 1.47208 15.0374 0.105378 22.2144C-1.26133 29.3915 13.7026 38.2701 33.5283 42.0455C53.354 45.8209 70.5339 43.0633 71.9006 35.8862Z"
                    fill="#11C182"
                  />
                  <path
                    id="Vector_4"
                    d="M12.4965 21.2975C14.2243 21.2975 15.625 19.8968 15.625 18.169C15.625 16.4412 14.2243 15.0405 12.4965 15.0405C10.7687 15.0405 9.36801 16.4412 9.36801 18.169C9.36801 19.8968 10.7687 21.2975 12.4965 21.2975Z"
                    fill="#11C182"
                  />
                  <path
                    id="Vector_5"
                    d="M71.9132 35.8411C70.5499 43.021 53.3663 45.7892 33.5306 42.0227C22.5195 39.9317 13.0065 36.2672 6.95572 32.1909C12.8476 35.2981 20.7228 37.9914 29.5647 39.6704C49.399 43.4368 66.5837 40.6703 67.9473 33.4888C68.5544 30.292 65.9233 26.7603 61.0651 23.4929C68.4005 27.3565 72.6694 31.8594 71.9132 35.8411Z"
                    fill="#11C182"
                  />
                  <path
                    id="Vector_6"
                    d="M55.9989 39.3555C56.7067 35.6385 47.7937 30.8188 36.0911 28.5903C24.3885 26.3618 14.3279 27.5684 13.6201 31.2854C12.9123 35.0023 21.8253 39.8221 33.5279 42.0505C45.2305 44.279 55.2911 43.0724 55.9989 39.3555Z"
                    fill="#D4F2F6"
                  />
                  <path
                    id="Vector_7"
                    d="M49.174 68.3221L9.33678 60.7573C8.46209 60.5912 7.94291 59.6829 8.24342 58.8449L19.3482 27.8858C23.5183 27.1444 29.5457 27.3141 36.0883 28.5566C42.6309 29.799 48.301 31.8504 51.909 34.0688L50.8922 66.9435C50.8648 67.8334 50.0486 68.4881 49.174 68.3221Z"
                    fill="#A2F0D5"
                  />
                  <g id="Group_5">
                    <path
                      id="Vector_8"
                      d="M51.9086 34.0682C48.3006 31.8499 42.6303 29.7983 36.0879 28.556C29.5453 27.3136 23.5179 27.1439 19.3478 27.8852L17.9456 31.7921C20.6009 31.2269 24.1284 31.0679 28.1198 31.3632L23.8764 48.3559C23.731 48.9381 24.0851 49.5278 24.6673 49.6732C24.7558 49.6953 24.8444 49.7059 24.9314 49.7059C25.4184 49.7059 25.8615 49.3761 25.9847 48.8822L30.3089 31.5655C31.9438 31.7469 33.6396 32.0006 35.3713 32.3294C37.1034 32.6583 38.7744 33.0441 40.3623 33.4747L39.2975 41.582C39.2195 42.1769 39.6384 42.7226 40.2334 42.8008C40.2813 42.8071 40.329 42.8102 40.3763 42.8102C40.9133 42.8102 41.3803 42.4119 41.4522 41.865L42.4735 34.0889C46.2953 35.2773 49.519 36.7182 51.7812 38.2171L51.9086 34.0682Z"
                      fill="#73EAC0"
                    />
                    <path
                      id="Vector_9"
                      d="M49.1736 68.3215L45.4444 67.6133L46.5622 31.4868C48.6318 32.29 50.4421 33.1653 51.9086 34.0682L50.8918 66.943C50.8644 67.833 50.0482 68.4876 49.1736 68.3215Z"
                      fill="#73EAC0"
                    />
                  </g>
                </g>
                <g id="Group_6">
                  <g id="Group_7">
                    <path
                      id="Vector_10"
                      d="M11.0079 25.6867C11.2316 24.512 10.3158 23.3508 8.96249 23.0931C7.60913 22.8354 6.33068 23.5787 6.10699 24.7534C5.8833 25.9281 6.79908 27.0892 8.15244 27.3469C9.5058 27.6047 10.7842 26.8613 11.0079 25.6867Z"
                      fill="#D4F2F6"
                    />
                  </g>
                  <g id="Group_8">
                    <path
                      id="Vector_11"
                      d="M24.1833 21.5034C24.407 20.3287 23.4912 19.1675 22.1378 18.9098C20.7845 18.6521 19.506 19.3954 19.2823 20.5701C19.0586 21.7448 19.9744 22.9059 21.3278 23.1637C22.6811 23.4214 23.9596 22.678 24.1833 21.5034Z"
                      fill="#D4F2F6"
                    />
                  </g>
                  <g id="Group_9">
                    <path
                      id="Vector_12"
                      d="M39.6872 23.0357C39.9109 21.861 38.9951 20.6998 37.6418 20.4421C36.2884 20.1844 35.01 20.9277 34.7863 22.1024C34.5626 23.2771 35.4784 24.4382 36.8317 24.6959C38.1851 24.9537 39.4635 24.2103 39.6872 23.0357Z"
                      fill="#D4F2F6"
                    />
                  </g>
                  <g id="Group_10">
                    <path
                      id="Vector_13"
                      d="M65.3872 36.0379C65.6109 34.8632 64.6951 33.702 63.3418 33.4443C61.9884 33.1866 60.71 33.9299 60.4863 35.1046C60.2626 36.2793 61.1784 37.4404 62.5317 37.6981C63.8851 37.9559 65.1635 37.2125 65.3872 36.0379Z"
                      fill="#D4F2F6"
                    />
                  </g>
                  <g id="Group_11">
                    <path
                      id="Vector_14"
                      d="M54.67 27.3066C54.8936 26.1319 53.9779 24.9708 52.6245 24.713C51.2711 24.4553 49.9927 25.1987 49.769 26.3733C49.5453 27.548 50.4611 28.7092 51.8145 28.9669C53.1678 29.2246 54.4463 28.4813 54.67 27.3066Z"
                      fill="#D4F2F6"
                    />
                  </g>
                </g>
              </g>
            </g>
            <g id="Group_12">
              <path
                id="Vector_15"
                d="M19.0872 3.97041C18.6629 3.54615 17.9751 3.54601 17.5504 3.97041L16.9716 4.54922L16.3928 3.97041C15.9684 3.54615 15.2806 3.54615 14.856 3.97041C14.4318 4.39482 14.4318 5.08262 14.856 5.50702L15.4349 6.08583L14.856 6.66465C14.4318 7.08891 14.4318 7.77685 14.856 8.20126C15.0682 8.41346 15.3463 8.51949 15.6244 8.51949C15.9024 8.51949 16.1806 8.41332 16.3928 8.20126L16.9716 7.62244L17.5504 8.20126C17.7626 8.41346 18.0408 8.51949 18.3188 8.51949C18.5968 8.51949 18.875 8.41332 19.0872 8.20126C19.5114 7.77685 19.5114 7.08905 19.0872 6.66465L18.5084 6.08583L19.0872 5.50702C19.5114 5.08262 19.5114 4.39468 19.0872 3.97041Z"
                fill="#64FCC7"
              />
              <path
                id="Vector_16"
                d="M55.5581 8.45407C55.7703 8.66628 56.0485 8.77231 56.3265 8.77231C56.6045 8.77231 56.8827 8.66613 57.0949 8.45407C57.5191 8.02981 57.5191 7.34187 57.0949 6.91746L56.5161 6.33865L57.0949 5.75984C57.5191 5.33557 57.5191 4.64763 57.0949 4.22323C56.6705 3.79896 55.9827 3.79896 55.5581 4.22323L54.9795 4.8019L54.4008 4.22323C53.9764 3.79896 53.2886 3.79896 52.864 4.22323C52.4398 4.64749 52.4398 5.33543 52.864 5.75984L53.4428 6.33865L52.864 6.91746C52.4398 7.34173 52.4398 8.02967 52.864 8.45407C53.0762 8.66628 53.3544 8.77231 53.6324 8.77231C53.9104 8.77231 54.1886 8.66613 54.4008 8.45407L54.9795 7.8754L55.5581 8.45407Z"
                fill="#64FCC7"
              />
              <path
                id="Vector_17"
                d="M59.2102 52.8358L59.789 52.257C60.2132 51.8326 60.2132 51.1448 59.789 50.7204C59.3646 50.2961 58.6768 50.2961 58.2522 50.7204L57.6736 51.299L57.0949 50.7204C56.6706 50.2961 55.9828 50.2959 55.5581 50.7204C55.1339 51.1446 55.1339 51.8326 55.5581 52.257L56.1369 52.8358L55.5581 53.4146C55.1339 53.839 55.1339 54.5268 55.5581 54.9512C55.7703 55.1634 56.0485 55.2694 56.3265 55.2694C56.6045 55.2694 56.8827 55.1633 57.0949 54.9512L57.6736 54.3725L58.2522 54.9512C58.4644 55.1634 58.7424 55.2694 59.0206 55.2694C59.2986 55.2694 59.5768 55.1633 59.789 54.9512C60.2132 54.5269 60.2132 53.839 59.789 53.4146L59.2102 52.8358Z"
                fill="#A8EFD6"
              />
            </g>
            <g id="Group_13">
              <path
                id="Vector_18"
                d="M61.7376 18.7611C63.4654 18.7611 64.8661 17.3604 64.8661 15.6326C64.8661 13.9048 63.4654 12.5041 61.7376 12.5041C60.0098 12.5041 58.6091 13.9048 58.6091 15.6326C58.6091 17.3604 60.0098 18.7611 61.7376 18.7611Z"
                fill="#A8EFD6"
              />
              <path
                id="Vector_19"
                d="M64.8658 15.6323C64.8658 17.3607 63.4648 18.7617 61.7377 18.7617C61.1588 18.7617 60.6154 18.6045 60.1508 18.3286C61.3789 18.0455 62.2939 16.9461 62.2939 15.6323C62.2939 14.3198 61.3804 13.219 60.1523 12.9359C60.617 12.6614 61.1588 12.5042 61.7379 12.5042C63.4648 12.5041 64.8658 13.9051 64.8658 15.6323Z"
                fill="#70E6BD"
              />
            </g>
            <g id="Group_14">
              <path
                id="Vector_20"
                d="M12.4965 18.7611C14.2243 18.7611 15.625 17.3604 15.625 15.6326C15.625 13.9048 14.2243 12.5041 12.4965 12.5041C10.7687 12.5041 9.36801 13.9048 9.36801 15.6326C9.36801 17.3604 10.7687 18.7611 12.4965 18.7611Z"
                fill="#73EAC0"
              />
              <path
                id="Vector_21"
                d="M15.6247 15.6323C15.6247 17.3607 14.2237 18.7617 12.4967 18.7617C11.9177 18.7617 11.3743 18.6045 10.9097 18.3286C12.1378 18.0455 13.0528 16.9461 13.0528 15.6323C13.0528 14.3198 12.1393 13.219 10.9112 12.9359C11.3759 12.6614 11.9177 12.5042 12.4968 12.5042C14.2237 12.5041 15.6247 13.9051 15.6247 15.6323Z"
                fill="#3BD59F"
              />
            </g>
          </g>
        </g>
        <defs>
          <clipPath id="clip0_1116_12428">
            <rect width="72" height="72" fill="white" />
          </clipPath>
        </defs>
      </svg>
    );
  },
);

export default EmptyDashboard;
