import * as z from "zod";
export const workspaceAuditSchema = z.object({
  // name: z.string().min(3, { message: "Required" }),
  issue_date: z.object({
    startDate: z
      .string({
        required_error: "Required",
        invalid_type_error: "Required",
      })
      .min(1, "Required"),
    endDate: z.string(),
  }),

  expired_at: z.object({
    startDate: z
      .string({
        required_error: "Required",
        invalid_type_error: "Required",
      })
      .min(1, "Required"),
    endDate: z.string(),
  }),
  result_type_id: z.object(
    {
      label: z.string({ message: "Required" }),
      value: z.number({ message: "Required" }),
    },
    {
      required_error: "Required",
      invalid_type_error: "Required",
    },
  ),
      name: z.object(
    {
      label: z.string({ message: "Required" }),
      value: z.number({ message: "Required" }),
    },
    {
      required_error: "Required",
      invalid_type_error: "Required",
    },
  ),
  audit_id: z.string().min(1, { message: "Required" }).max(100, { message: "Audit number must not exceed 100 characters" }),
  workspace_id: z.number({
    required_error: "Required",
    invalid_type_error: "Required",
  }),
  // partner_workspace_id: z.string().min(1, { message: "Required" }),
  audit_files: z
    .array(
      z.any({
        required_error: "Required",
        invalid_type_error: "Required",
      }),
    )
    .nonempty({ message: "Required" }),
});
