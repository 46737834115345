import { Divider } from "@mui/material";
import { Cross, EditLable, Plus, SmallCrossIcon } from "assests";
import { Button } from "components/UI/Button";
import CustomSelect from "components/UI/CustomSelect";
import ErrorMessage from "components/UI/ErrorMessage";
import { Input } from "components/UI/Input";
import { Label } from "components/UI/Label";
import UpgradeComponent from "components/UI/UpgradeComponent";
import useCreateArticle from "hooks/useCreateArticle";
import { useDispatch, useSelector } from "react-redux";
import {
  preventMinus,
  preventPasteNegative,
  preventSpaceAndPasteNegative,
  preventSpaceMinus,
} from "utils/helper";

const ArticleForm = ({ genderList, onClose, refetchArticleData, handleArticleSelect }) => {
  const dispatch = useDispatch();
  const { articleTypeList } = useSelector((state) => state.articleTypeSlice);
  const { compositionList } = useSelector((state) => state.compositionSlice);

  const {
    control,
    setValue,
    isDuplicateAction,
    articleDetails,
    errors,
    isLoading,
    isArticleDetailsLoading,
    isPending,
    isArticleEditable,
    actionableArticelId,
    imagePreviews,
    handleDragEnter,
    handleDragLeave,
    handleDrop,
    onFileDropOrChange,
    handleCertFileDelete,
    onValidationError,
    register,
    reset,
    handleSubmit,
    onSubmit,
    handleAddComposition,
    handleRemoveComposition,
    backImagePreviews,
    templateList,
    compositionFormLength,
  } = useCreateArticle({ refetchArticleData, handleArticleSelect, isCreatingArticleFromArticle: false });

  return (
    <>
      <div className="relative">
        <div className=" flex items-center justify-center ">
          <div className="w-full lg:w-[600px] max-h-[600px] py-6  rounded-xl bg-white z-[2] ">
            <div className="absolute right-5 top-5 z-[2] cursor-pointer " onClick={onClose}>
              <Cross fillColor={"#A4A7AE"} height={24} width={24} />
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="grid grid-cols-1 gap-6">
                <div>
                  <div className="flex justify-start gap-x-4 px-6 z-[1] ">
                    <UpgradeComponent>
                      <EditLable />
                    </UpgradeComponent>

                    <div className="flex justify-between z-[2]">
                      <div className="flex flex-col gap-1">
                        <h2 className="text-sm lg:text-lg  font-semibold text-start text-login-text-title">
                          New Article
                        </h2>
                        <p className="text-xs lg:text-sm leading-5 text-global-paragraph font-normal truncate">
                          Add article details here
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="grid gap-4 lg:gap-6 px-6 pt-6 lg:pt-11  max-h-[378px] overflow-auto z-[3]">
                    <div className="grid gap-4 lg:gap-6">
                      <div className="grid gap-1.5">
                        <Label htmlFor="sku" className="text-sm leading-5 font-medium">
                          Article No.
                        </Label>
                        <Input
                          id="sku"
                          type="text"
                          placeholder="PW120-100"
                          className={`px-[14px] py-[12.5px] h-11 custom-card text-base font-normal placeholder:text-base leading-[19.36px] text-gray-950 placeholder:leading-[19.36px] placeholder:text-gray-700 hover:outline-none hover:border hover:border-input-stroke-default`}
                          {...register("sku")}
                          onPaste={preventSpaceAndPasteNegative}
                          onKeyPress={preventSpaceMinus}
                        />
                        <ErrorMessage errors={errors?.sku?.message} />
                      </div>
                      <div className="grid gap-1.5">
                        <Label htmlFor="articleType" className="text-sm leading-5 font-medium">
                          Supply Chain Template
                        </Label>

                        <CustomSelect
                          control={control}
                          name="template_id"
                          placeholder="Select type"
                          options={templateList}
                          isClearable={false}
                          style={{
                            padding: "3.5px 10px 7px 1.5px",
                            fontSize: "16px",
                          }}
                          {...register("template_id")}
                        />
                        <ErrorMessage errors={errors?.template_id?.message} />
                      </div>
                    </div>
                    <div className="grid grid-cols-2 gap-3 lg:gap-5">
                      <div className="col-span-2 md:col-span-1">
                        <div className="grid gap-1.5">
                          <Label htmlFor="articleType" className="text-sm leading-5 font-medium">
                            Article Type
                          </Label>

                          <CustomSelect
                            control={control}
                            name="article_type"
                            placeholder="Select type"
                            options={articleTypeList}
                            isClearable={false}
                            style={{
                              padding: "3.5px 10px 7px 1.5px",
                              fontSize: "16px",
                            }}
                            {...register("article_type")}
                          />
                          <ErrorMessage errors={errors?.article_type?.message} />
                        </div>
                      </div>

                      <div className="col-span-2 md:col-span-1">
                        <div className="grid gap-1.5">
                          <Label htmlFor="gender" className="text-sm leading-5 font-medium">
                            Sex
                          </Label>
                          <CustomSelect
                            control={control}
                            name="gender_id"
                            placeholder="Select Gender"
                            options={genderList}
                            {...register("gender_id")}
                            isClearable={false}
                            style={{
                              padding: "3.5px 10px 7px 1.5px",
                              fontSize: "16px",
                            }}
                          />
                          <ErrorMessage errors={errors?.gender_id?.message} />
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col gap-1.5">
                      <div className="flex flex-row gap-2">
                        <Label className="text-sm leading-5 font-medium">Composition</Label>
                        <Button
                          type="button"
                          size="sm"
                          className="p-[5px] rounded-full"
                          onClick={handleAddComposition}
                        >
                          <Plus width={10} height={10} fillColor={"var(--color-icon-fill-light)"} />
                        </Button>
                      </div>

                      <div className="flex flex-col gap-6">
                        {[...Array(compositionFormLength)].map((_, index) => (
                          <div className="w-full flex flex-col gap-1.5">
                            <div
                              key={index}
                              className="flex flex-row  justify-start   gap-x-2 w-full rounded-lg"
                            >
                              <div className="flex justify-start  w-full rounded-lg">
                                <div className="flex-1 w-full">
                                  <div className="flex flex-col gap-1.5">
                                    <CustomSelect
                                      control={control}
                                      name={`compositions-${index}`}
                                      placeholder="Select composition"
                                      options={compositionList}
                                      {...register(`compositions.${index}`)}
                                      style={{
                                        padding: "3.5px 10px 7px 1.5px",
                                        fontSize: "16px",
                                        borderRadius: "6px 0px 0px 6px",
                                      }}
                                    />
                                    <div>
                                      {errors?.compositions?.length > 0 &&
                                        errors.compositions[index] && (
                                          <ErrorMessage
                                            errors={errors?.compositions[index]?.message}
                                          />
                                        )}
                                    </div>
                                  </div>
                                </div>

                                <div className="flex flex-col gap-1.5 text-wrap">
                                  <input
                                    className="text-center h-11 w-[69px] border border-l-0 border-input-stroke-default focus:outline-none focus:border-input-stroke-default focus:ring-0 custom-card  placeholder:text-base placeholder:text-gray-700 rounded-r-md"
                                    type="number"
                                    min="0"
                                    max="100"
                                    step="0.1"
                                    placeholder="0%"
                                    name={`percentage.${index}`}
                                    onPaste={preventPasteNegative}
                                    onKeyPress={preventMinus}
                                    {...register(`percentage.${index}`, { valueAsNumber: true })}
                                  />
                                  <div>
                                    {errors?.percentage?.length > 0 && errors.percentage[index] && (
                                      <ErrorMessage errors={errors?.percentage[index]?.message} />
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div
                                className={`cursor-pointer h-11 flex items-center justify-center ${compositionFormLength === 1 && index === 0 ? "hidden" : compositionFormLength > 1 && index !== 0 ? "visible" : "invisible"}`}
                              >
                                <SmallCrossIcon
                                  strokeColor={"var(--color-icon-default-5)"}
                                  width={16}
                                  height={16}
                                  onClick={() => handleRemoveComposition(index)}
                                />
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                      {errors?.sum && (
                        <p className="text-sm leading-5 text-global-paragraph font-normal whitespace-nowrap">
                          The total percentage must equal 100%.
                        </p>
                      )}
                    </div>

                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 p-6">
                      <div className="grid gap-1.5">
                        <div className="flex flex-col gap-3">
                          <div
                            className={`w-full lg:max-w-[240px] h-[160px] relative bg-[#F5F5F5] rounded-xl `}
                            onDragOver={handleDragEnter}
                            onDragEnter={handleDragEnter}
                            onDragLeave={handleDragLeave}
                            onDrop={(e) => handleDrop(e, "front")}
                          >
                            {imagePreviews && imagePreviews?.length > 0 ? (
                              <div className="w-full h-full flex justify-center items-center">
                                <img
                                  src={imagePreviews && imagePreviews[0]}
                                  alt={`front${0}`}
                                  key={new Date().getTime()}
                                  className="w-full h-full object-cover rounded-xl"
                                  loading="lazy"
                                />
                              </div>
                            ) : (
                              <label htmlFor="dropzone-file" className="cursor-pointer">
                                <div className="w-full h-full flex justify-center items-center">
                                  <div className="w-full flex justify-center items-center">
                                    <div className="h-10 w-10 bg-white rounded-full border-dotted border-[3px] border-[#D0D5DD] flex justify-center items-center">
                                      <Plus
                                        className="w-5 h-5"
                                        fillColor={"var(--color-gray-extra-3"}
                                      />
                                    </div>
                                  </div>

                                  <input
                                    id="dropzone-file"
                                    type="file"
                                    multiple
                                    className="hidden"
                                    accept=".png,.jpg,.jpeg"
                                    onChange={(e) => onFileDropOrChange(e, "front", false)}
                                  />
                                </div>
                              </label>
                            )}
                          </div>

                          <div className="flex flex-col items-start">
                            <p className="text-sm leading-5 font-semibold text-global-title">
                              Front
                            </p>
                            {imagePreviews && imagePreviews.length > 0 ? (
                              <button
                                onClick={() => handleCertFileDelete(0, "front")}
                                className="text-xs leading-[18px] text-global-paragraph"
                              >
                                Remove
                              </button>
                            ) : (
                              <p className="text-xs leading-[18px] text-global-paragraph">
                                image (max. 5 mb)
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="grid gap-1.5">
                        <div className="flex flex-col gap-3">
                          <div
                            className={`w-full lg:max-w-[240px] h-[160px] relative bg-[#F5F5F5] rounded-xl cursor-pointer`}
                            onDragOver={(e) => handleDragEnter(e, "back")}
                            onDragEnter={(e) => handleDragEnter(e, "back")}
                            onDragLeave={(e) => handleDragLeave(e, "back")}
                            onDrop={(e) => handleDrop(e, "back")}
                          >
                            {backImagePreviews && backImagePreviews.length > 0 ? (
                              <div className="w-full h-full flex justify-center items-center">
                                <img
                                  src={backImagePreviews && backImagePreviews[0]}
                                  alt={`back${0}`}
                                  key={new Date().getTime()}
                                  className="w-full h-full object-cover rounded-xl"
                                  loading="lazy"
                                />
                              </div>
                            ) : (
                              <label htmlFor="back-dropzone-file" className="cursor-pointer">
                                <div className="h-full w-full flex justify-center items-center">
                                  <div className="w-full flex justify-center items-center">
                                    <div className="h-10 w-10 bg-white rounded-full border-dotted border-[3px] border-[#D0D5DD] flex justify-center items-center">
                                      <Plus
                                        className="w-5 h-5"
                                        fillColor={"var(--color-gray-extra-3"}
                                      />
                                    </div>
                                  </div>

                                  <input
                                    id="back-dropzone-file"
                                    type="file"
                                    multiple
                                    className="hidden"
                                    accept=".png,.jpg,.jpeg"
                                    onChange={(e) => onFileDropOrChange(e, "back", false)}
                                  />
                                </div>
                              </label>
                            )}
                          </div>

                          <div className="flex flex-col items-start">
                            <p className="text-sm leading-5 font-semibold text-global-title">
                              Back
                            </p>
                            {backImagePreviews && backImagePreviews.length > 0 ? (
                              <button
                                onClick={() => handleCertFileDelete(0, "back")}
                                className="text-xs leading-[18px] text-global-paragraph"
                              >
                                Remove
                              </button>
                            ) : (
                              <p className="text-xs leading-[18px] text-global-paragraph">
                                image (max. 5 mb)
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {!(actionableArticelId && !isArticleEditable) && (
                    <div className="mt-4 lg:mt-8">
                      <Divider />
                    </div>
                  )}
                </div>
              </div>
              <div className="flex  justify-center items-center lg:justify-between gap-4 pt-4 lg:pt-6 px-6 ">
                {/* {!actionableArticelId &&
                  (!isArticleEditable || isArticleEditable) &&
                  !isDuplicateAction && (
                    <div className="flex flex-col lg:flex-row justify-center  w-full  gap-3 ">
                      <Button
                        type="button"
                        size={"md"}
                        variant={"secondary"}
                        className={
                          "w-full text-txt-secondary-secondary  h-11 px-4 py-2.5 text-base leading-6 font-semibold custom-outline-shadow "
                        }
                        onClick={handleCancel}
                      >
                        Cancel
                      </Button>
                      <Button
                        type="submit"
                        size={"md"}
                        className="h-11 px-4 py-2.5 text-base  leading-6 w-full"
                        isDisabled={isPending}
                      >
                        Save
                      </Button>
                    </div>
                  )} */}

                {/* {actionableArticelId && isArticleEditable && isDuplicateAction && (
                  <div className="flex flex-col lg:flex-row justify-center w-full  gap-3">
                    <Button
                      type="button"
                      size={"md"}
                      variant={"secondary"}
                      className={
                        "w-full text-txt-secondary-secondary bg-white h-11 px-4 py-2.5 text-base leading-6 font-semibold custom-outline-shadow hover:text-white"
                      }
                      onClick={handleCancel}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      size={"md"}
                      className="h-11 px-4 py-2.5 text-base  leading-6 w-full"
                      isDisabled={isPending}
                    >
                      Save
                    </Button>
                  </div>
                )} */}

                {/* {actionableArticelId && !isArticleEditable && !isDuplicateAction && (
                  <div className="flex flex-col lg:flex-row justify-center w-full gap-3">
                    <Button
                      type="button"
                      size={"md"}
                      variant={"secondary"}
                      className={
                        "w-full text-txt-secondary-secondary bg-white h-11 px-4 py-2.5 text-base leading-6 font-semibold custom-outline-shadow hover:text-white"
                      }
                      onClick={handleCancel}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="button"
                      size={"md"}
                      variant={"primary"}
                      className="h-11 px-4 py-2.5 text-base  leading-6 w-full"
                      isDisabled={!hasPermission("updateArticle")}
                      onClick={handleCancel}
                    >
                      <Edit fillColor={"var(--color-icon-fill-default)"} width={14} height={14} />{" "}
                      Edit
                      Save
                    </Button>
                  </div>
                )} */}

                {/* {actionableArticelId && isArticleEditable && !isDuplicateAction && ( */}
                <div className="flex flex-col lg:flex-row justify-center w-full  gap-3 ">
                  <Button
                    type="button"
                    size={"md"}
                    variant={"secondary"}
                    className={
                      "w-full text-txt-secondary-secondary bg-white h-11 px-4 py-2.5 text-base leading-6 font-semibold custom-outline-shadow hover:text-white"
                    }
                    onClick={onClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    size={"md"}
                    className="h-11 px-4 py-2.5 text-base  leading-6 w-full"
                    isDisabled={isPending}
                  >
                    Save
                  </Button>
                </div>
                {/* )} */}
              </div>
            </form>
          </div>
          {/* <Modal open={showDiscardModal} close={() => handleConfirmDiscard(false)}>
          <DiscardModal onDiscard={handleConfirmDiscard} />
        </Modal> */}
        </div>
      </div>
    </>
  );
};

export default ArticleForm;
