import React from 'react';

const ErrorMessage = ({ errors }) => {
    return (
        <>
            {errors && <p className="text-sm font-normal text-btn-text-tertiary m-0 p-0">{errors && errors}</p>}
        </>
    );
};

export default ErrorMessage;


