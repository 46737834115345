import { useMutation, useQueryClient } from "@tanstack/react-query";
import useCustomToaster from "hooks/useCustomToaster";
import Papa from "papaparse";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getArticleTypeList } from "services/article-types.services";
import { createBulkArticle } from "services/article.services";
import { getCompositionList } from "services/composition.services";
import { getGenders } from "services/gender.services";
import { getTemplates } from "services/template.services";
import { toggleIsUploadBulkArticleModalOpen } from "store/articleSlice";
import { setRefetchBillingData } from "store/billingSlice";
import Preview from "./Preview";
import UploadViewArticle from "./UploadViewArticle";

const UploadBulkArticle = ({ closeModal, refetch }) => {
  const queryClient = useQueryClient();
  const { workspaceId } = useParams();
  const dispatch = useDispatch();
  const [isDragOver, setIsDragOver] = useState(false);
  const [preview, setPreview] = useState(false);
  const [genders, setGenders] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [compositions, setCompositions] = useState([]);
  const [articleTypes, setArticleTypes] = useState([]);
  const [showLimitText, setShowLimitText] = useState(false);
  const [uploadedData, setUploadedData] = useState(null);
  const [fileData, setFileData] = useState(null);
  const { showCustomToast } = useCustomToaster();
  const { refetchBillingData, workspacePlan } = useSelector((state) => state.billingSlice);

  const handleCancel = () => {
    // reset();
    closeModal();
  };
  const handleDragEnter = (e) => {
    e.preventDefault();
    setIsDragOver(true);
  };
  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragOver(false);
  };
  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragOver(false);
    onFileDropOrChange(e);
  };
  const isDragEvent = (event) => {
    return "dataTransfer" in event;
  };
  const onFileDropOrChange = (e) => {
    let csv = null;
    if (isDragEvent(e)) {
      csv = e.dataTransfer.files[0];
    } else {
      csv = e.target.files[0];
    }
    if (csv && csv.type !== "text/csv") {
      // to do your code
      console.log("error");
    } else if (csv) {
      Papa.parse(csv, {
        complete: function (results) {
          const { data } = results;
          const headers = [
            "Article ID",
            "Compostions",
            "Percentage",
            "Gender",
            "Article Type",
            "template",
          ];
          const indices = {};
          headers.forEach((header) => {
            const index = data[0].indexOf(header);
            if (index !== -1) {
              indices[header] = index;
            }
          });
          data.shift();
          const filtered = data.filter((element) => !(element.length === 1 && element[0] === ""));
          const newData = filtered.map((item) => {
            let articleId = item[indices["Article ID"]]?.replace(/ /g, "-") || "";
            let composition = item[indices["Compostions"]];
            let percentage = item[indices["Percentage"]];
            let gender = item[indices["Gender"]];
            let articleType = item[indices["Article Type"]];
            let templateType = item[indices["template"]];
            let articleIdError = true;
            let compositionError = true;
            let percentageError = true;
            let genderError = true;
            let articleTypeError = true;
            let templateTypeError = true;
            if (articleId) {
              articleIdError = false;
            } else {
              articleId = "";
              articleIdError = true;
            }
            if (gender) {
              gender = genders.find((item) => item.label.toLowerCase() === gender.toLowerCase());
              if (!gender) {
                gender = null;
                genderError = true;
              } else {
                genderError = false;
              }
            } else {
              gender = null;
              genderError = true;
            }
            if (articleType) {
              articleType = articleTypes.find(
                (item) => item.label.toLowerCase() === articleType.toLowerCase(),
              );
              if (!articleType) {
                articleType = null;
                articleTypeError = true;
              } else {
                articleTypeError = false;
              }
            } else {
              articleType = null;
              articleTypeError = true;
            }
            if (composition) {
              composition = composition
                .split(",")
                .map((item) => item.trim())
                .filter((item) => item !== "");
              composition = compositions.filter((comp) => composition.includes(comp.label));
              if (composition.length <= 0) {
                composition = [];
                compositionError = true;
                percentageError = true;
              } else {
                const tempPercentage = percentage
                  .split(",")
                  .map((item) => item.trim())
                  .filter((item) => item !== "");
                if (composition.length !== tempPercentage.length) {
                  percentageError = true;
                }
                compositionError = false;
              }
            } else {
              composition = [];
              percentageError = true;
              compositionError = true;
            }
            if (percentage) {
              if (typeof percentage === "string") {
                percentage = percentage
                  .split(",")
                  .map((item) => item.trim())
                  .filter((item) => item !== "");
                const total = percentage.map(Number).reduce((acc, current) => acc + current, 0);
                if (percentage.length !== composition.length) {
                  percentageError = true;
                } else {
                  if (total === 100) {
                    percentageError = false;
                  } else {
                    percentageError = true;
                  }
                }
              }
            } else {
              percentage = [];
              percentageError = true;
            }
            if (templateType) {
              templateType = templates.find(
                (item) => item.label.toLowerCase() === templateType.toLowerCase(),
              );
              if (!templateType) {
                templateType = null;
                templateTypeError = true;
              } else {
                templateTypeError = false;
              }
            } else {
              templateType = null;
              templateTypeError = true;
            }
            return {
              articleId,
              composition,
              percentage,
              gender,
              articleType,
              templateType,
              error: {
                articleIdError,
                compositionError,
                percentageError,
                genderError,
                articleTypeError,
                templateTypeError,
              },
            };
          });
          console.log(newData, headers, data);
          setUploadedData(newData);
        },
      });
    }
  };
  const handleFileDropOrChange = (e) => {
    let file = null;
    if (e.dataTransfer) {
      file = e.dataTransfer.files[0];
    } else {
      file = e.target.files[0];
    }
    if (file && file.type === "text/csv") {
      const fileSize = (file.size / (1024 * 1024)).toFixed(2) + " MB"; // Calculate file size in MB
      setFileData({
        name: file.name,
        size: fileSize,
      });
      onFileDropOrChange(e); // Continue with your existing file parsing logic
    } else {
      console.error("Please upload a valid CSV file.");
    }
  };

  const genderListFetch = async () => {
    const res = await getGenders();
    if (res?.status === 200) {
      const genders = res?.data?.gender?.map((item) => {
        const { id, name } = item;
        return { value: id, label: name };
      });
      setGenders(genders);
    }
  };
  const templateListFetch = async () => {
    const res = await getTemplates({ pagination: "no", workspace_id: parseInt(workspaceId) });
    if (res?.status === 200) {
      const templatesData = res?.data?.templates?.map((item) => {
        const { id, name } = item;
        return { value: id, label: name };
      });
      setTemplates(templatesData);
    }
  };

  const compositionListFetch = async () => {
    const res = await getCompositionList();
    if (res?.status === 200) {
      const compositions = res?.data?.compositions?.map((item) => {
        const { id, name } = item;
        return { value: id, label: name };
      });
      setCompositions(compositions);
    }
  };
  const articleTypeListFetch = async () => {
    const res = await getArticleTypeList();
    if (res?.status === 200) {
      const types = res?.data?.article_type?.map((item) => {
        const { id, name } = item;
        return { value: id, label: name };
      });
      setArticleTypes(types);
    }
  };
  const handleErrors = (index, name, hasError, errors) => ({
    ...errors,
    [`${name}Error`]: hasError,
  });
  const onChangeHandler = (index, name, value) => {
    console.log(name);
    const updatedData = [...uploadedData];
    if (name === "articleId") {
      if (value) {
        updatedData[index] = {
          ...updatedData[index],
          [name]: value,
          error: handleErrors(index, name, false, updatedData[index].error),
        };
      } else {
        updatedData[index] = {
          ...updatedData[index],
          [name]: value,
          error: handleErrors(index, name, true, updatedData[index].error),
        };
      }
    }
    if (name === "gender") {
      if (value) {
        updatedData[index] = {
          ...updatedData[index],
          [name]: value,
          error: handleErrors(index, name, false, updatedData[index].error),
        };
      } else {
        updatedData[index] = {
          ...updatedData[index],
          [name]: value,
          error: handleErrors(index, name, true, updatedData[index].error),
        };
      }
    }
    if (name === "articleType") {
      if (value) {
        updatedData[index] = {
          ...updatedData[index],
          [name]: value,
          error: handleErrors(index, name, false, updatedData[index].error),
        };
      } else {
        updatedData[index] = {
          ...updatedData[index],
          [name]: value,
          error: handleErrors(index, name, true, updatedData[index].error),
        };
      }
    }
    if (name === "composition") {
      if (value.length > 0) {
        updatedData[index] = {
          ...updatedData[index],
          [name]: value,
          error: handleErrors(index, name, false, updatedData[index].error),
        };
        if (value.length !== updatedData[index].percentage.length) {
          updatedData[index] = {
            ...updatedData[index],
            error: handleErrors(index, "percentage", true, updatedData[index].error),
          };
        } else {
          const total = updatedData[index].percentage
            .map(Number)
            .reduce((acc, current) => acc + current, 0);
          if (total === 100) {
            updatedData[index] = {
              ...updatedData[index],
              error: handleErrors(index, "percentage", false, updatedData[index].error),
            };
          } else {
            updatedData[index] = {
              ...updatedData[index],
              error: handleErrors(index, "percentage", true, updatedData[index].error),
            };
          }
        }
      } else {
        updatedData[index] = {
          ...updatedData[index],
          [name]: value,
          error: handleErrors(index, name, true, updatedData[index].error),
        };
        updatedData[index] = {
          ...updatedData[index],
          error: handleErrors(index, "percentage", true, updatedData[index].error),
        };
      }
    }
    if (name === "percentage") {
      if (value) {
        let cleanValue = value.replace(/[A-Za-z]/g, "");
        cleanValue = cleanValue
          .split(",")
          .map((item) => item.trim())
          .filter((item) => item !== "");
        if (cleanValue.length === updatedData[index].composition.length) {
          const total = cleanValue.map(Number).reduce((acc, current) => acc + current, 0);
          if (total === 100) {
            updatedData[index] = {
              ...updatedData[index],
              [name]: cleanValue,
              error: handleErrors(index, name, false, updatedData[index].error),
            };
          } else {
            updatedData[index] = {
              ...updatedData[index],
              [name]: cleanValue,
              error: handleErrors(index, name, true, updatedData[index].error),
            };
          }
        } else {
          updatedData[index] = {
            ...updatedData[index],
            [name]: cleanValue,
            error: handleErrors(index, name, true, updatedData[index].error),
          };
        }
      } else {
        updatedData[index] = {
          ...updatedData[index],
          [name]: [],
          error: handleErrors(index, name, true, updatedData[index].error),
        };
      }
    }
    if (name === "templateType") {
      if (value) {
        updatedData[index] = {
          ...updatedData[index],
          [name]: value,
          error: handleErrors(index, name, false, updatedData[index].error),
        };
      } else {
        updatedData[index] = {
          ...updatedData[index],
          [name]: value,
          error: handleErrors(index, name, true, updatedData[index].error),
        };
      }
    }
    console.log(updatedData, "updatedData");
    setUploadedData(updatedData);
  };

  const createBulkUploadArticleMutation = useMutation({
    mutationKey: ["bulkArticle", "createBulkArticle"],
    mutationFn: (data) => createBulkArticle(data),
    onMutate: () => {
      showCustomToast(
        {
          title: "Uploading bulk article....",
        },
        "createBulkArticleUploadLoadingToast",
      );
    },
    onSuccess: (res) => {
      if (res) {
        toast.dismiss("createBulkArticleUploadLoadingToast");
        dispatch(toggleIsUploadBulkArticleModalOpen());
        refetch();
        if (!refetchBillingData) {
          dispatch(setRefetchBillingData(true));
        }
        showCustomToast({
          title: "Bulk article uploaded successfully",
        });
        queryClient.invalidateQueries({ queryKey: ["bulkArticle", "createBulkArticle"] });
      }
    },
    onError: (error) => {
      if (error) {
        toast.dismiss("createBulkArticleUploadLoadingToast");
        const errorResponse = error.response?.data;

        if (errorResponse?.duplicate_article_numbers) {
          const duplicateOrders = errorResponse.duplicate_article_numbers.join('", "');
          showCustomToast({
            title: `Article upload failed\nError: article id "${duplicateOrders}" already exists`,
          });
        } else if (errorResponse?.errors) {
          Object.entries(errorResponse.errors).forEach(([field, messages]) => {
            messages.forEach((message) => {
              showCustomToast({
                title: message,
              });
            });
          });
        } else {
          showCustomToast({
            title: errorResponse?.message || error.message,
          });
        }
      }
    },
    onSettled: () => {
      toast.dismiss("createBulkArticleUploadLoadingToast");
    },
  });

  const onSubmitBulkUpload = async () => {
    const csvData = uploadedData.map((item) => {
      const { articleId, articleType, composition, gender, percentage, templateType } = item;
      const tempComposition = composition.map((item) => item.label);
      const tempGender = item.gender.label;
      const tempArticleType = item.articleType.label;
      const tempTemplate = item.templateType.label;
      return {
        "Article ID": articleId,
        Compostions: tempComposition.toString(),
        Percentage: percentage.toString(),
        Gender: tempGender,
        "Article Type": tempArticleType,
        template: tempTemplate,
      };
    });
    const totalCurrentCount =
      parseInt(uploadedData?.length) + parseInt(workspacePlan?.articles_count);
    const limitCount = parseInt(workspacePlan?.package?.articles);
    if (workspacePlan && totalCurrentCount > limitCount) {
      setShowLimitText(true);
    } else {
      setShowLimitText(false);
      const csv = Papa.unparse(csvData);
      const csvBlob = new Blob([csv], { type: "text/csv" });
      const formData = new FormData();
      formData.append("import_csv", csvBlob, "data.csv");
      formData.append("workspace_id", parseInt(workspaceId));
      if (formData) {
        createBulkUploadArticleMutation.mutate(formData);
      }
    }
  };

  useEffect(() => {
    genderListFetch();
    compositionListFetch();
    articleTypeListFetch();
    templateListFetch();
  }, []);

  const hasAnyError = () => {
    if (uploadedData) {
      return uploadedData?.some((data) => Object?.values(data?.error)?.includes(true));
    } else {
      return true;
    }
  };
  const handleDownloadFunction = () => {
    const url = `/articles.csv`;
    const link = document.createElement("a");
    link.href = url;
    link.download = "articles.csv";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="">
      <div className="rounded-xl bg-white">
        {/* min-w-[749px] */}
        <div className="grid grid-cols-1 gap-4">
          <div className="w-full py-6 overflow-x-hidden">
            {!preview && (
              <>
                <UploadViewArticle
                  isDragOver={isDragOver}
                  handleDragEnter={handleDragEnter}
                  handleDragLeave={handleDragLeave}
                  handleDrop={handleDrop}
                  onFileDropOrChange={onFileDropOrChange}
                  uploadedData={uploadedData}
                  setPreview={setPreview}
                  handleFileDropOrChange={handleFileDropOrChange}
                  setFileData={setFileData}
                  fileData={fileData}
                  handleDownloadFunction={handleDownloadFunction}
                  closeModal={closeModal}
                  handleCancel={handleCancel}
                  preview={preview}
                  onSubmitBulkUpload={onSubmitBulkUpload}
                  hasAnyError={hasAnyError}
                />
              </>
            )}
            {preview && (
              <Preview
                data={uploadedData}
                genders={genders}
                compositions={compositions}
                articleTypes={articleTypes}
                templates={templates}
                onChangeHandler={onChangeHandler}
                onSubmit={onSubmitBulkUpload}
                closeModal={closeModal}
                handleCancel={handleCancel}
                uploadedData={uploadedData}
                preview={preview}
                setPreview={setPreview}
                onSubmitBulkUpload={onSubmitBulkUpload}
                hasAnyError={hasAnyError}
                showLimitText={showLimitText}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadBulkArticle;
