import React from "react";

const shieldTick = React.forwardRef(
  (
    {
      width = "28",
      height = "28",
      viewBox = "0 0 28 28",
      fill = "none",
      strokeColor = "#344054",
      className,
      ...props
    },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, strokeColor, className }}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.5001 13.4167L12.8334 15.7501L18.0834 10.5001M23.3334 14.0001C23.3334 19.7266 17.0871 23.8915 14.8144 25.2174C14.5561 25.3681 14.427 25.4434 14.2447 25.4825C14.1033 25.5129 13.8969 25.5129 13.7555 25.4825C13.5732 25.4434 13.444 25.3681 13.1858 25.2174C10.913 23.8915 4.66675 19.7266 4.66675 14.0001V8.42059C4.66675 7.48782 4.66675 7.02144 4.8193 6.62054C4.95407 6.26638 5.17306 5.95038 5.45735 5.69984C5.77916 5.41623 6.21585 5.25247 7.08922 4.92496L13.3447 2.57917C13.5872 2.48822 13.7085 2.44274 13.8332 2.42471C13.9439 2.40872 14.0563 2.40872 14.1669 2.42471C14.2917 2.44274 14.413 2.48822 14.6555 2.57917L20.9109 4.92496C21.7843 5.25247 22.221 5.41623 22.5428 5.69984C22.8271 5.95038 23.0461 6.26638 23.1809 6.62054C23.3334 7.02144 23.3334 7.48782 23.3334 8.42059V14.0001Z"
          stroke={strokeColor}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  },
);

export default shieldTick;
