import React from "react";

const ForgetPassWordIcon = React.forwardRef(
  (
    {
      width = "60",
      height = "60",
      viewBox = "0 0 60 60",
      fill = "none",

      className,
    },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_dii_17_2050)">
          <path
            d="M2 13C2 6.37258 7.37258 1 14 1H46C52.6274 1 58 6.37258 58 13V45C58 51.6274 52.6274 57 46 57H14C7.37258 57 2 51.6274 2 45V13Z"
            fill="white"
          />
          <path
            d="M2.5 13C2.5 6.64873 7.64873 1.5 14 1.5H46C52.3513 1.5 57.5 6.64873 57.5 13V45C57.5 51.3513 52.3513 56.5 46 56.5H14C7.64873 56.5 2.5 51.3513 2.5 45V13Z"
            stroke="#E4E7EC"
          />
          <path
            d="M33.5 25.5H33.5117M33.5 32.5C37.366 32.5 40.5 29.366 40.5 25.5C40.5 21.634 37.366 18.5 33.5 18.5C29.634 18.5 26.5 21.634 26.5 25.5C26.5 25.8193 26.5214 26.1336 26.5628 26.4415C26.6309 26.948 26.6649 27.2013 26.642 27.3615C26.6181 27.5284 26.5877 27.6184 26.5055 27.7655C26.4265 27.9068 26.2873 28.046 26.009 28.3243L20.0467 34.2866C19.845 34.4884 19.7441 34.5893 19.6719 34.707C19.608 34.8114 19.5608 34.9252 19.5322 35.0442C19.5 35.1785 19.5 35.3212 19.5 35.6065V37.6333C19.5 38.2867 19.5 38.6134 19.6272 38.863C19.739 39.0825 19.9175 39.261 20.137 39.3728C20.3866 39.5 20.7133 39.5 21.3667 39.5H23.3935C23.6788 39.5 23.8215 39.5 23.9558 39.4678C24.0748 39.4392 24.1886 39.392 24.293 39.3281C24.4107 39.2559 24.5116 39.155 24.7134 38.9533L30.6757 32.991C30.954 32.7127 31.0932 32.5735 31.2345 32.4945C31.3816 32.4123 31.4716 32.3819 31.6385 32.358C31.7987 32.3351 32.052 32.3691 32.5585 32.4372C32.8664 32.4786 33.1807 32.5 33.5 32.5Z"
            stroke="#344054"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <filter
            id="filter0_dii_17_2050"
            x="0"
            y="0"
            width="60"
            height="60"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="1" />
            <feGaussianBlur stdDeviation="1" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.05 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_17_2050" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_17_2050"
              result="shape"
            />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="-2" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.05 0"
            />
            <feBlend mode="normal" in2="shape" result="effect2_innerShadow_17_2050" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feMorphology
              radius="1"
              operator="erode"
              in="SourceAlpha"
              result="effect3_innerShadow_17_2050"
            />
            <feOffset />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.18 0"
            />
            <feBlend
              mode="normal"
              in2="effect2_innerShadow_17_2050"
              result="effect3_innerShadow_17_2050"
            />
          </filter>
        </defs>
      </svg>
    );
  },
  <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_dii_17_2050)">
      <path
        d="M2 13C2 6.37258 7.37258 1 14 1H46C52.6274 1 58 6.37258 58 13V45C58 51.6274 52.6274 57 46 57H14C7.37258 57 2 51.6274 2 45V13Z"
        fill="white"
      />
      <path
        d="M2.5 13C2.5 6.64873 7.64873 1.5 14 1.5H46C52.3513 1.5 57.5 6.64873 57.5 13V45C57.5 51.3513 52.3513 56.5 46 56.5H14C7.64873 56.5 2.5 51.3513 2.5 45V13Z"
        stroke="#E4E7EC"
      />
      <path
        d="M33.5 25.5H33.5117M33.5 32.5C37.366 32.5 40.5 29.366 40.5 25.5C40.5 21.634 37.366 18.5 33.5 18.5C29.634 18.5 26.5 21.634 26.5 25.5C26.5 25.8193 26.5214 26.1336 26.5628 26.4415C26.6309 26.948 26.6649 27.2013 26.642 27.3615C26.6181 27.5284 26.5877 27.6184 26.5055 27.7655C26.4265 27.9068 26.2873 28.046 26.009 28.3243L20.0467 34.2866C19.845 34.4884 19.7441 34.5893 19.6719 34.707C19.608 34.8114 19.5608 34.9252 19.5322 35.0442C19.5 35.1785 19.5 35.3212 19.5 35.6065V37.6333C19.5 38.2867 19.5 38.6134 19.6272 38.863C19.739 39.0825 19.9175 39.261 20.137 39.3728C20.3866 39.5 20.7133 39.5 21.3667 39.5H23.3935C23.6788 39.5 23.8215 39.5 23.9558 39.4678C24.0748 39.4392 24.1886 39.392 24.293 39.3281C24.4107 39.2559 24.5116 39.155 24.7134 38.9533L30.6757 32.991C30.954 32.7127 31.0932 32.5735 31.2345 32.4945C31.3816 32.4123 31.4716 32.3819 31.6385 32.358C31.7987 32.3351 32.052 32.3691 32.5585 32.4372C32.8664 32.4786 33.1807 32.5 33.5 32.5Z"
        stroke="#344054"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <filter
        id="filter0_dii_17_2050"
        x="0"
        y="0"
        width="60"
        height="60"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="1" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.05 0"
        />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_17_2050" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_17_2050" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="-2" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.05 0"
        />
        <feBlend mode="normal" in2="shape" result="effect2_innerShadow_17_2050" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feMorphology
          radius="1"
          operator="erode"
          in="SourceAlpha"
          result="effect3_innerShadow_17_2050"
        />
        <feOffset />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.18 0"
        />
        <feBlend
          mode="normal"
          in2="effect2_innerShadow_17_2050"
          result="effect3_innerShadow_17_2050"
        />
      </filter>
    </defs>
  </svg>,
);

export default ForgetPassWordIcon;
