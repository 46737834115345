import api from "config/axios";

export const getAgentDetails = (id, query) => {
  const params = new URLSearchParams();
  Object.keys(query).forEach((key) => {
    if (query[key]) {
      params.append(key, query[key]);
    }
  });
  return api.get(`/company/${id}?${params}`);
};

