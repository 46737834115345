import { useSelector } from "react-redux";

import { permissions } from "utils/permissions";

const usePermission = () => {
  // Global state or redux state
  const { currentWorkspace } = useSelector((state) => state.authenticationSlice);
  const { workspacePlan, checkPlanLimitation } = useSelector((state) => state.billingSlice);

  /**
   * Checks if the user has permission to perform a certain action.
   *
   * @return {boolean} Returns true if the user has permission, false otherwise.
   */
  // const hasPermission = useCallback((),[workspacePlan, currentWorkspace])
  const hasPermission = (action) => {
    /**
     * Indentifying user permission on bellow condition
     * 1. First check if the user has current workspace and has a role if not then then return false
     * 2. If has the current workspace and has a role then check the current plan.
     *    when user has not current plan or have the plan but subscription status is 'cancelled' then return false
     * 3. When user has current workspace and user has role also has the current plan with subscription status 'active'
     *    according to the role and if user has limit to take action then return true otherwise false
     */

    // First condition checked
    if (
      !currentWorkspace ||
      typeof currentWorkspace !== "object" ||
      Object.keys(currentWorkspace).length === 0
    ) {
      return false;
    }
    // Second condition checked
    const UserRole = currentWorkspace.role_name;

    if (!UserRole) return false;

    if (!workspacePlan) {
      if (currentWorkspace.is_owner && action.startsWith("canAdd")) {
        if (permissions[UserRole].ownerActions.includes(action)) return true;
      } else if (!currentWorkspace.is_owner && action.startsWith("canAdd")) {
        if (permissions[UserRole].invitedActions.includes(action)) return true;
      }
      return false;
    } else {
      if (
        !workspacePlan ||
        typeof workspacePlan !== "object" ||
        Object.keys(workspacePlan).length === 0
      ) {
        return false;
      }
      const SubscriptionStatus = workspacePlan.package.is_active;
      if (!SubscriptionStatus) return false;
      /**
       * From the current plan extract the
       * user current count for
       * -total orders,
       * -total users account who is responsible to maintain the worksapce (admin, editior, viewer),
       * -total article
       * -total supply-chain members
       */
      const UserPlanLimitation = {
        canCreateOrder:
          parseInt(workspacePlan["orders_count"]) < parseInt(workspacePlan.package["order_lines"]),
        canCreateUser:
          parseInt(workspacePlan["user_count"]) < parseInt(workspacePlan.package["users"]),
        canCreateArticle:
          parseInt(workspacePlan["articles_count"]) < parseInt(workspacePlan.package["articles"]),
        canCreatePartner:
          parseInt(workspacePlan["partner_links_count"]) <
          parseInt(workspacePlan.package["partner_links"]),
      };

      if (currentWorkspace) {
        if (currentWorkspace.is_owner) {
          if (!permissions[UserRole].ownerActions.includes(action)) return false;
        } else {
          if (!permissions[UserRole].invitedActions.includes(action)) return false;
        }
      }

      if (action.startsWith("create")) {
        const actionDomain = action.slice(6).toLowerCase();
        if (actionDomain === "user") {
          if (!UserPlanLimitation.canCreateUser) return false;
        } else if (actionDomain === "order") {
          if (!UserPlanLimitation.canCreateOrder) return false;
        } else if (actionDomain === "article") {
          if (!UserPlanLimitation.canCreateArticle) return false;
        } else if (
          actionDomain === "agent" ||
          actionDomain === "supplier" ||
          actionDomain === "retailer" ||
          actionDomain === "importer" ||
          actionDomain === "brand"
        ) {
          if (!UserPlanLimitation.canCreatePartner) return false;
        }
      } else if (action === "canGlobalCreate") {
        if (!UserPlanLimitation.canCreateOrder && !UserPlanLimitation.canCreateArticle)
          return false;
      }
    }

    return true;
  };

  return { hasPermission, checkPlanLimitation };
};

export default usePermission;
