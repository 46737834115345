import React from "react";

const Logo_Light = React.forwardRef(
  ({ width = "123", height = "24", viewBox = "0 0 123 24", fill = "none", className }, ref) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.2949 7.69334H0V5.82161C0 2.60601 2.60601 0 5.82161 0H15.1165V1.87172C15.1165 5.08733 12.5105 7.69334 9.2949 7.69334Z"
          fill="#11C182"
        />
        <path
          d="M5.82161 16.4048H15.1165V14.533C15.1165 11.3174 12.5105 8.71143 9.2949 8.71143H0V10.5832C0 13.7988 2.6074 16.4048 5.82161 16.4048Z"
          fill="#11C182"
        />
        <path
          d="M7.6948 22.6519V24H5.82308C2.60747 24 0.00146484 21.394 0.00146484 18.1784V16.8303H1.87319C5.08741 16.8289 7.6948 19.4363 7.6948 22.6519Z"
          fill="#11C182"
        />
        <path
          d="M23.1167 21.7117V7.76542C23.1167 6.22711 23.5731 5.00153 24.486 4.08868C25.3988 3.15894 26.7005 2.69406 28.3909 2.69406C28.9657 2.69406 29.5151 2.75323 30.0391 2.87156C30.58 2.98989 31.0365 3.17584 31.4084 3.42941L30.3687 6.29472C30.149 6.14258 29.9039 6.02425 29.6334 5.93973C29.3629 5.85521 29.0755 5.81295 28.7713 5.81295C28.1965 5.81295 27.7485 5.98199 27.4274 6.32008C27.1231 6.64127 26.9709 7.1315 26.9709 7.79078V9.05862L27.0724 10.7575V21.7117H23.1167ZM24.5657 11.4168L26.6703 8.37398H30.4702V11.4168H24.5657ZM32.7016 21.7117V8.0697H36.6572V21.7117H32.7016ZM34.6794 6.16794C33.9525 6.16794 33.3608 5.95663 32.9044 5.53402C32.448 5.11141 32.2198 4.58737 32.2198 3.9619C32.2198 3.33643 32.448 2.81239 32.9044 2.38978C33.3608 1.96717 33.9525 1.75586 34.6794 1.75586C35.4063 1.75586 35.9979 1.95871 36.4544 2.36442C36.9108 2.75323 37.139 3.26036 37.139 3.88583C37.139 4.54511 36.9108 5.0945 36.4544 5.53402C36.0149 5.95663 35.4232 6.16794 34.6794 6.16794Z"
          fill="#101828"
        />
        <path
          d="M47.8717 21.9145C46.6714 21.9145 45.6403 21.6609 44.7781 21.1538C43.916 20.6467 43.2567 19.8775 42.8003 18.8463C42.3439 17.7983 42.1157 16.4797 42.1157 14.8907C42.1157 13.2847 42.3523 11.9662 42.8257 10.935C43.3159 9.90384 43.9921 9.13469 44.8542 8.62755C45.7163 8.12041 46.7222 7.86685 47.8717 7.86685C49.1564 7.86685 50.3059 8.15422 51.3202 8.72898C52.3514 9.30373 53.1628 10.1151 53.7544 11.1632C54.363 12.2113 54.6673 13.4538 54.6673 14.8907C54.6673 16.3107 54.363 17.5447 53.7544 18.5928C53.1628 19.6408 52.3514 20.4607 51.3202 21.0524C50.3059 21.6271 49.1564 21.9145 47.8717 21.9145ZM39.8082 21.7117V2.89692H43.7639V10.8336L43.5103 14.8653L43.5864 18.9224V21.7117H39.8082ZM47.187 18.6688C47.8463 18.6688 48.4295 18.5167 48.9366 18.2124C49.4607 17.9081 49.8748 17.4771 50.1791 16.9192C50.5003 16.3445 50.6609 15.6683 50.6609 14.8907C50.6609 14.0962 50.5003 13.42 50.1791 12.8621C49.8748 12.3043 49.4607 11.8732 48.9366 11.5689C48.4295 11.2647 47.8463 11.1125 47.187 11.1125C46.5278 11.1125 45.9361 11.2647 45.4121 11.5689C44.888 11.8732 44.4739 12.3043 44.1696 12.8621C43.8653 13.42 43.7131 14.0962 43.7131 14.8907C43.7131 15.6683 43.8653 16.3445 44.1696 16.9192C44.4739 17.4771 44.888 17.9081 45.4121 18.2124C45.9361 18.5167 46.5278 18.6688 47.187 18.6688Z"
          fill="#101828"
        />
        <path
          d="M63.5784 21.9145C62.0232 21.9145 60.6539 21.6102 59.4706 21.0017C58.3042 20.3931 57.3998 19.5648 56.7574 18.5167C56.1151 17.4517 55.7939 16.243 55.7939 14.8907C55.7939 13.5214 56.1066 12.3127 56.7321 11.2647C57.3744 10.1997 58.245 9.37135 59.3438 8.77969C60.4426 8.17113 61.6851 7.86685 63.0713 7.86685C64.4067 7.86685 65.6069 8.15422 66.6719 8.72898C67.7538 9.28683 68.6075 10.0982 69.233 11.1632C69.8584 12.2113 70.1712 13.4707 70.1712 14.9414C70.1712 15.0935 70.1627 15.271 70.1458 15.4739C70.1289 15.6598 70.112 15.8373 70.0951 16.0064H59.0142V13.6989H68.0158L66.4944 14.3835C66.4944 13.6736 66.3507 13.0565 66.0634 12.5325C65.776 12.0085 65.3787 11.6027 64.8716 11.3154C64.3645 11.0111 63.7728 10.8589 63.0966 10.8589C62.4204 10.8589 61.8203 11.0111 61.2963 11.3154C60.7892 11.6027 60.3919 12.0169 60.1045 12.5579C59.8172 13.0819 59.6735 13.7074 59.6735 14.4343V15.0428C59.6735 15.7866 59.8341 16.4459 60.1552 17.0206C60.4933 17.5785 60.9582 18.0096 61.5499 18.3138C62.1584 18.6012 62.8684 18.7449 63.6798 18.7449C64.4067 18.7449 65.0406 18.635 65.5816 18.4153C66.1394 18.1955 66.6466 17.8659 67.103 17.4264L69.2076 19.7085C68.5821 20.4185 67.7961 20.9679 66.8494 21.3567C65.9028 21.7286 64.8124 21.9145 63.5784 21.9145Z"
          fill="#101828"
        />
        <path
          d="M72.2976 21.7117V8.0697H76.0757V11.9239L75.5432 10.8082C75.949 9.84468 76.5998 9.11778 77.4957 8.62755C78.3917 8.12041 79.482 7.86685 80.7667 7.86685V11.5182C80.5977 11.5013 80.4456 11.4929 80.3103 11.4929C80.1751 11.476 80.0314 11.4675 79.8793 11.4675C78.7974 11.4675 77.9183 11.7802 77.2421 12.4057C76.5829 13.0143 76.2532 13.9694 76.2532 15.271V21.7117H72.2976Z"
          fill="#101828"
        />
        <path
          d="M115.938 22.2442C114.383 22.2442 113.013 21.9399 111.83 21.3313C110.664 20.7228 109.759 19.8945 109.117 18.8464C108.475 17.7814 108.153 16.5727 108.153 15.2204C108.153 13.8511 108.466 12.6424 109.092 11.5943C109.734 10.5294 110.604 9.70104 111.703 9.10938C112.802 8.50081 114.045 8.19653 115.431 8.19653C116.766 8.19653 117.966 8.48391 119.031 9.05866C120.113 9.61651 120.967 10.4279 121.592 11.4929C122.218 12.541 122.531 13.8004 122.531 15.2711C122.531 15.4232 122.522 15.6007 122.505 15.8036C122.488 15.9895 122.471 16.167 122.455 16.3361H111.374V14.0286H120.375L118.854 14.7132C118.854 14.0032 118.71 13.3862 118.423 12.8622C118.135 12.3381 117.738 11.9324 117.231 11.6451C116.724 11.3408 116.132 11.1886 115.456 11.1886C114.78 11.1886 114.18 11.3408 113.656 11.6451C113.149 11.9324 112.751 12.3466 112.464 12.8875C112.177 13.4116 112.033 14.037 112.033 14.7639V15.3725C112.033 16.1163 112.194 16.7756 112.515 17.3503C112.853 17.9082 113.318 18.3392 113.909 18.6435C114.518 18.9309 115.228 19.0746 116.039 19.0746C116.766 19.0746 117.4 18.9647 117.941 18.745C118.499 18.5252 119.006 18.1956 119.462 17.756L121.567 20.0382C120.942 20.7481 120.156 21.2975 119.209 21.6863C118.262 22.0582 117.172 22.2442 115.938 22.2442Z"
          fill="#11C182"
        />
        <path
          d="M101.32 22.244C100.187 22.244 99.173 22.0242 98.277 21.5847C97.398 21.1452 96.7134 20.4775 96.2231 19.5815C95.7329 18.6687 95.4878 17.5107 95.4878 16.1076V8.39917H99.4435V15.5244C99.4435 16.657 99.6801 17.4938 100.153 18.0347C100.644 18.5588 101.328 18.8208 102.207 18.8208C102.816 18.8208 103.357 18.694 103.83 18.4405C104.304 18.17 104.675 17.7643 104.946 17.2233C105.216 16.6655 105.352 15.9724 105.352 15.1441V8.39917H109.307V22.0411H105.554V18.2883L106.239 19.3787C105.783 20.3253 105.106 21.0438 104.211 21.534C103.331 22.0073 102.368 22.244 101.32 22.244Z"
          fill="#11C182"
        />
        <path
          d="M87.9147 22.0411L82.1841 8.39917H86.2665L91.0336 20.1394H89.0051L93.9496 8.39917H97.7532L91.9972 22.0411H87.9147Z"
          fill="#11C182"
        />
      </svg>
    );
  },
);

Logo_Light.displayName = "Logo_Light";

export default Logo_Light;
