import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import useCustomToaster from "hooks/useCustomToaster";
import Papa from "papaparse";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { createBulkOrder } from "services/order.services";
import { getTemplates } from "services/template.services";
import { setRefetchBillingData } from "store/billingSlice";
import { toggleIsUploadBulkOrderModalOpen } from "store/orderSlice";
import { formattedOrderDateIntoObject } from "utils/helper";
import Preview from "./Preview";
import UploadViewOrder from "./UploadVIewOrder";

const UploadBulkOrder = ({
  closeModal,
  refetch,
  seasonList,
  articleListQuery,
  artcileLoading,
  artcileStatus,
  agentList,
  retailerList,
  supplierList,
}) => {
  const [fileData, setFileData] = useState(null);
  const { user, currentWorkspace } = useSelector((state) => state.authenticationSlice);
  const { workspaceId } = useParams();
  const { refetchBillingData, workspacePlan } = useSelector((state) => state.billingSlice);
  const queryClient = useQueryClient();
  const [workspaceRolePrefix, setWorkspaceRolePrefix] = useState(null);

  const handleCancel = () => {
    // reset();
    closeModal();
  };
  const dispatch = useDispatch();
  const { control, setValue } = useForm();
  const { showCustomToast } = useCustomToaster();
  const [articleList, setArticleList] = useState([]);
  const [templateList, setTemplateList] = useState([]);
  const [isDragOver, setIsDragOver] = useState(false);
  const [preview, setPreview] = useState(false);
  const [uploadedData, setUploadedData] = useState(null);
  const [showLimitText, setShowLimitText] = useState(false);

  const {
    data: templatesQuery,
    isLoading: isTemplateLoading,
    status: templatesQueryStatus,
  } = useQuery({
    queryKey: ["templates", "getTemplateList", workspaceId],
    queryFn: () => getTemplates({ pagination: "no", workspace_id: parseInt(workspaceId) }),
    enabled: !!workspaceId,
  });

  useEffect(() => {
    if (
      articleListQuery &&
      artcileStatus === "success" &&
      !artcileLoading &&
      articleListQuery.status === 200 &&
      articleListQuery?.data?.articles &&
      articleListQuery?.data?.articles?.length > 0
    ) {
      const formatedArticleList = articleListQuery?.data?.articles?.map((article) => ({
        label: `${article?.sku} ${article?.gender?.name}'s ${article?.article_types?.name}`,
        value: article?.id,
      }));
      setArticleList(formatedArticleList);
    }
  }, [articleListQuery, artcileLoading, artcileStatus]);

  useEffect(() => {
    if (templatesQuery && !isTemplateLoading && templatesQueryStatus === "success") {
      if (templatesQuery?.data && templatesQuery?.data?.templates) {
        const result = templatesQuery?.data?.templates;
        if (result && Array.isArray(result) && result.length > 0) {
          const formatedTemplateList = result.map((item) => ({
            label: item.name,
            value: item.id,
          }));
          setTemplateList(formatedTemplateList);
        }
      }
    }
  }, [templatesQuery, isTemplateLoading, templatesQueryStatus]);

  const handleDragEnter = (e) => {
    e.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragOver(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragOver(false);
    onFileDropOrChange(e);
  };

  const isDragEvent = (event) => {
    return "dataTransfer" in event;
  };

  const onFileDropOrChange = (e) => {
    let csv = null;
    if (isDragEvent(e)) {
      csv = e.dataTransfer.files[0];
    } else {
      csv = e.target.files[0];
    }
    if (csv && csv.type !== "text/csv") {
      toast.error("Please upload a CSV file");
    } else if (csv) {
      handleFileParse(csv);
    }
  };

  useEffect(() => {
    if (currentWorkspace) {
      const role = currentWorkspace && currentWorkspace?.role_name?.split(".")[0];
      setWorkspaceRolePrefix(role);
    }
  }, [currentWorkspace, workspaceId]);

  const handleFileParse = (csv) => {
    Papa.parse(csv, {
      complete: function (results) {
        const { data } = results;
        const headers = ["Order Number", "Order Date", "Season", "Quantity", "Article", "Template"];
        if (workspaceRolePrefix && workspaceRolePrefix === "agent") {
          headers.push("Retailer");
          headers.push("Supply Chain Partner");
        }
        if (
          workspaceRolePrefix &&
          ["retailer", "importer", "brand"].includes(workspaceRolePrefix)
        ) {
          headers.push("Agent");
          headers.push("Supply Chain Partner");
        }
        if (workspaceRolePrefix && workspaceRolePrefix === "supplier") {
          headers.push("Retailer");
        }
        const indices = {};
        headers.forEach((header) => {
          const index = data[0].indexOf(header);
          if (index !== -1) {
            indices[header] = index;
          }
        });

        data.shift();
        const filteredData = data.filter((row) => row.length !== 1 || row[0] !== "");
        const newData = filteredData.map((row) => {
          let item = {};
          headers.forEach((header) => {
            item[header] = row[indices[header]];
          });
          return processRow(item, indices);
        });
        setUploadedData(newData);
      },
    });
  };

  const processRow = (item, indices) => {
    const processedItem = {};
    let errors = {};

    processedItem.orderNumber = item["Order Number"]?.replace(/ /g, "-") || "";
    errors.orderNumberError = !processedItem.orderNumber;

    processedItem.orderDate = processOrderDate(item["Order Date"]);
    errors.orderDateError = !processedItem.orderDate;

    processedItem.season = processSeason(item["Season"]);
    errors.seasonError = !processedItem.season;

    processedItem.quantity = processQuantity(item["Quantity"]);
    errors.quantityError = !processedItem.quantity;

    processedItem.article = processArticle(item["Article"]);
    errors.articleError = !processedItem.article;

    processedItem.template = processTemplate(item["Template"]);
    errors.templateError = !processedItem.template;

    // if (getRoleName(currentWorkspace) === "agent.admin") {
    //   processedItem.retailer = processRetailer(item["Retailer"]);
    //   errors.retailerError = !processedItem.retailer;
    // } else if (getRoleName(currentWorkspace) === "retailer.admin") {
    //   processedItem.agent = processAgent(item["Agent"]);
    //   errors.agentError = !processedItem.agent;
    // }


    if (workspaceRolePrefix && workspaceRolePrefix === "agent") {
      if (["retailer", "importer", "brand"].includes(currentWorkspace.invited_workspace_type)) {
        processedItem.retailer = processRetailer(currentWorkspace.invited_workspace_name);
        errors.retailerError = !processedItem.retailer;
      } else {
        processedItem.retailer = processRetailer(item["Retailer"]);
        errors.retailerError = !processedItem.retailer;
      }
      if (currentWorkspace.invited_workspace_type === "supplier") {
        processedItem.supplier = processSupplier(currentWorkspace.invited_workspace_name);
        errors.supplierError = !processedItem.supplier;
      } else {
        processedItem.supplier = processSupplier(item["Supply Chain Partner"]);
        errors.supplierError = !processedItem.supplier;
      }
    } else if (
      workspaceRolePrefix &&
      ["retailer", "importer", "brand"].includes(workspaceRolePrefix)
    ) {
      if (currentWorkspace.invited_workspace_type === "agent") {
        processedItem.agent = processAgent(currentWorkspace.invited_workspace_name);
        errors.agentError = !processedItem.agent;
      }
      else if (currentWorkspace.invited_workspace_type === "supplier") {
        processedItem.supplier = processSupplier(currentWorkspace.invited_workspace_name);
        errors.supplierError = !processedItem.supplier;
      } else {
        processedItem.agent = processAgent(item["Agent"]);
        errors.agentError = !processedItem.agent;
        processedItem.supplier = processSupplier(item["Supply Chain Partner"]);
        errors.supplierError = !processedItem.supplier;
      }
    } else if (workspaceRolePrefix && workspaceRolePrefix === "supplier") {
      if (["retailer", "importer", "brand"].includes(currentWorkspace.invited_workspace_type)) {
        console.log("hello");
        processedItem.retailer = processRetailer(currentWorkspace.invited_workspace_name);
        errors.retailerError = !processedItem.retailer;
      } else {
        console.log("hello tata");
        processedItem.retailer = processRetailer(item["Retailer"]);
        errors.retailerError = !processedItem.retailer;
      }
    }
    processedItem.error = errors;
    return processedItem;
  };

  const processOrderDate = (orderDate) => {
    if (!orderDate) return null;
    if (orderDate) {
      const matchedDate = formattedOrderDateIntoObject(orderDate);
      return matchedDate || null;
    }
  };

  const processSeason = (season) => {
    if (!season) return null;
    if (seasonList && seasonList.length > 0) {
      const matchedSeason = seasonList.find((s) => s.label.toLowerCase() === season.toLowerCase());
      return matchedSeason || null;
    } else {
      return null;
    }
  };

  const processQuantity = (quantity) => {
    if (!quantity) return 0;
    const numQuantity = Number(quantity);
    return numQuantity > 0 ? numQuantity : 0;
  };
  const normalizeString = (str) =>
    str
      .trim()
      .replace(/[\u2018\u2019\u201A\u201B\u2032\u2035]/g, "'")
      .toLowerCase();

  const processArticle = (article) => {
    if (!article) return null;
    if (articleList && articleList.length > 0) {
      const matchedArticle = articleList.find(
        (item) => normalizeString(item.label) === normalizeString(article),
      );
      return matchedArticle || null;
    } else {
      return null;
    }
  };

  const processTemplate = (template) => {
    if (!template) return null;
    if (templateList && templateList.length > 0) {
      const matchedTemplate = templateList.find(
        (item) => normalizeString(item.label) === normalizeString(template),
      );
      return matchedTemplate || null;
    } else {
      return null;
    }
  };

  const processSupplier = (supplier) => {
    if (!supplier) return null;
    if (supplierList && supplierList.length > 0) {
      const matchedSupplier = supplierList.find(
        (item) => item.label.toLowerCase() === supplier.toLowerCase(),
      );
      return matchedSupplier || null;
    } else {
      return null;
    }
  };

  const processAgent = (agent) => {
    if (!agent) return null;
    if (agentList && agentList.length > 0) {
      const matchedAgent = agentList.find(
        (item) => item.label.toLowerCase() === agent.toLowerCase(),
      );
      return matchedAgent || null;
    } else {
      return null;
    }
  };

  const processRetailer = (retailer) => {
    if (!retailer) return null;
    if (retailerList && retailerList.length > 0) {
      const matchedRetailer = retailerList.find(
        (item) => item.label.toLowerCase() === retailer.toLowerCase(),
      );
      return matchedRetailer || null;
    } else {
      return null;
    }
  };
  const handleErrors = (index, name, hasError, errors) => ({
    ...errors,
    [`${name}Error`]: hasError,
  });

  const onChangeHandler = (index, name, value) => {
    if (workspaceRolePrefix && workspaceRolePrefix === "agent") {
      const updatedData = [...uploadedData];
      if (value) {
        updatedData[index] = {
          ...updatedData[index],
          [name]: value,
          error: handleErrors(index, name, false, updatedData[index].error),
        };
      } else {
        updatedData[index] = {
          ...updatedData[index],
          [name]: value,
          error: handleErrors(index, name, true, updatedData[index].error),
        };
      }
      setUploadedData(updatedData);
    } else if (workspaceRolePrefix && workspaceRolePrefix === "supplier") {
      const updatedData = [...uploadedData];
      if (value) {
        if (name === "retailer") {
          updatedData[index] = {
            ...updatedData[index],
            [name]: value,
            error: handleErrors(index, name, false, updatedData[index].error),
          };
          updatedData[index] = {
            ...updatedData[index],
            supplier: null,
            error: handleErrors(index, "supplier", false, updatedData[index].error),
          };
          updatedData[index] = {
            ...updatedData[index],
            agent: null,
            error: handleErrors(index, "agent", false, updatedData[index].error),
          };
        } else {
          updatedData[index] = {
            ...updatedData[index],
            [name]: value,
            error: handleErrors(index, name, false, updatedData[index].error),
          };
        }
      } else {
        updatedData[index] = {
          ...updatedData[index],
          [name]: value,
          error: handleErrors(index, name, true, updatedData[index].error),
        };
      }
      setUploadedData(updatedData);
    } else {
      const updatedData = [...uploadedData];
      if (value) {
        if (name === "agent") {
          updatedData[index] = {
            ...updatedData[index],
            [name]: value,
            error: handleErrors(index, name, false, updatedData[index].error),
          };
          updatedData[index] = {
            ...updatedData[index],
            supplier: null,
            error: handleErrors(index, "supplier", false, updatedData[index].error),
          };
        } else if (name === "supplier") {
          updatedData[index] = {
            ...updatedData[index],
            [name]: value,
            error: handleErrors(index, name, false, updatedData[index].error),
          };
          updatedData[index] = {
            ...updatedData[index],
            agent: null,
            error: handleErrors(index, "agent", false, updatedData[index].error),
          };
        } else {
          updatedData[index] = {
            ...updatedData[index],
            [name]: value,
            error: handleErrors(index, name, false, updatedData[index].error),
          };
        }
      } else {
        if (name === "agent") {
          if (updatedData[index].supplier) {
            updatedData[index] = {
              ...updatedData[index],
              [name]: value,
              error: handleErrors(index, name, false, updatedData[index].error),
            };
          } else {
            updatedData[index] = {
              ...updatedData[index],
              [name]: value,
              error: handleErrors(index, name, true, updatedData[index].error),
            };
          }
        } else if (name === "supplier") {
          if (updatedData[index].agent) {
            updatedData[index] = {
              ...updatedData[index],
              [name]: value,
              error: handleErrors(index, name, false, updatedData[index].error),
            };
          } else {
            updatedData[index] = {
              ...updatedData[index],
              [name]: value,
              error: handleErrors(index, name, true, updatedData[index].error),
            };
          }
        } else {
          updatedData[index] = {
            ...updatedData[index],
            [name]: value,
            error: handleErrors(index, name, true, updatedData[index].error),
          };
        }
      }
      setUploadedData(updatedData);
    }
  };

  const createBulkUploadOrderMutation = useMutation({
    mutationKey: ["bulkOrder", "createBulkOrder"],
    mutationFn: (data) => createBulkOrder(data),
    onMutate: () => {
      showCustomToast(
        {
          title: "Uploading bulk order....",
        },
        "createBulkOrderUploadLoadingToast",
      );
    },
    onSuccess: (res) => {
      if (res) {
        toast.dismiss("createBulkOrderUploadLoadingToast");
        dispatch(toggleIsUploadBulkOrderModalOpen());
        refetch();
        if (!refetchBillingData) {
          dispatch(setRefetchBillingData(true));
        }
        showCustomToast({
          title: "Bulk order uploaded successfully",
        });
        // toast.success("Bulk order uploaded successfully");
        queryClient.invalidateQueries({ queryKey: ["bulkOrder", "createBulkOrder"] });
      }
    },
    onError: (error) => {
      showCustomToast({
        title: error
          ? error.response?.data?.order_numbers_duplicate?.length > 0
            ? `Order upload failed\nError: order "${error.response.data.order_numbers_duplicate.join('", "')} already exists`
            : error.response?.data?.errors
              ? Object.entries(error.response.data.errors)
                .map(([field, messages]) => messages.map((message) => `${message} `))
                .flat()
                .join(", ")
              : error.response?.data?.message || error.message
          : "",
      });
    },
    onSettled: () => {
      toast.dismiss("createBulkOrderUploadLoadingToast");
    },
  });



  const onSubmitBulkUpload = async () => {
    const csvData = uploadedData.map((item) => {
      const {
        orderNumber,
        orderDate,
        article,
        quantity,
        season,
        template,
        agent,
        retailer,
        supplier,
      } = item;
      const returnData = {
        "Order Number": orderNumber,
        "Order Date": orderDate?.startDate,
        Article: article?.label,
        Quantity: quantity,
        Season: season?.label,
        Template: template?.label,
      };

      // if (workspaceRolePrefix && workspaceRolePrefix === "agent") {
      //   if (["retailer", "importer", "brand"].includes(currentWorkspace.invited_workspace_type)) {
      //     returnData.Client = retailer?.label || null;
      //     returnData["Supply Chain Partner"] = supplier?.label || null;
      //     returnData.Agent = user?.own_workspace?.name || null;
      //   } else {
      //     returnData.Client = retailer?.label || null;
      //     returnData["Supply Chain Partner"] = supplier?.label || null;
      //   }
      // }
      // if (workspaceRolePrefix && ["retailer", "importer", "brand"].includes(workspaceRolePrefix)) {
      //   if (["agent"].includes(currentWorkspace.invited_workspace_type)) {
      //     returnData.Agent = agent?.label || null;
      //     returnData["Supply Chain Partner"] = supplier?.label || null;
      //     returnData.Client = user?.own_workspace?.name || null;
      //   } else if (["supplier"].includes(currentWorkspace.invited_workspace_type)) {
      //     returnData.Agent = agent?.label || null;
      //     returnData["Supply Chain Partner"] = supplier?.label || null;
      //     returnData.Client = user?.own_workspace?.name || null;
      //   } else {
      //     returnData.Agent = agent?.label || null;
      //     returnData["Supply Chain Partner"] = supplier?.label || null;
      //   }

      // }
      // if (workspaceRolePrefix && workspaceRolePrefix === "supplier") {
      //   if (["retailer", "importer", "brand"].includes(currentWorkspace.invited_workspace_type)) {
      //     returnData.Client = retailer?.label || null;
      //     returnData["Supply Chain Partner"] = user?.own_workspace?.name || null;
      //   } else {
      //     returnData.Client = retailer?.label || null;
      //   }
      // }

      if (workspaceRolePrefix && workspaceRolePrefix === "agent") {
        returnData.Client = retailer?.label || null;
        returnData["Supply Chain Partner"] = supplier?.label || null;
      }
      if (workspaceRolePrefix && ["retailer", "importer", "brand"].includes(workspaceRolePrefix)) {
        returnData.Agent = agent?.label || null;
        returnData["Supply Chain Partner"] = supplier?.label || null;
      }
      if (workspaceRolePrefix && workspaceRolePrefix === "supplier") {
        returnData.Client = retailer?.label || null;
      }
      return returnData;
    });
    const totalCurrentCount = parseInt(uploadedData.length) + parseInt(workspacePlan?.orders_count);
    const limitCount = parseInt(workspacePlan?.package?.order_lines);
    if (workspacePlan && totalCurrentCount > limitCount) {
      setShowLimitText(true);
    } else {
      setShowLimitText(false);
      const csv = Papa.unparse(csvData);
      const csvBlob = new Blob([csv], { type: "text/csv" });
      const formData = new FormData();
      formData.append("import_csv", csvBlob, "data.csv");
      formData.append("workspace_id", parseInt(workspaceId));
      formData.append("created_by", user?.workspace_id);
      formData.append("created_by_user_type", user?.workspace_type);
      formData.append("created_by_company_name", user?.own_workspace?.name);
      console.log(csvData, "csvData");
      if (workspaceRolePrefix && workspaceRolePrefix === "supplier") {
        formData.append("supplier_id", parseInt(workspaceId));
      }
      if (formData) {
        createBulkUploadOrderMutation.mutate(formData);
      }
    }
  };

  const hasAnyError = () => {
    if (uploadedData) {
      return uploadedData?.some((data) => Object?.values(data?.error)?.includes(true));
    } else {
      return true;
    }
  };
  const handleFileDropOrChange = (e) => {
    let file = null;
    if (e.dataTransfer) {
      file = e.dataTransfer.files[0];
    } else {
      file = e.target.files[0];
    }
    if (file && file.type === "text/csv") {
      const fileSize = (file.size / (1024 * 1024)).toFixed(2) + " MB"; // Calculate file size in MB
      setFileData({
        name: file.name,
        size: fileSize,
      });
      onFileDropOrChange(e); // Continue with your existing file parsing logic
    } else {
      console.error("Please upload a valid CSV file.");
    }
  };

  const handleDownload = () => {
    const url = currentWorkspace?.role_name.includes("retailer")
      ? `/client_order.csv`
      : currentWorkspace?.role_name.includes("agent")
        ? `/agent_order_file.csv`
        : `/supplier_order.csv`;
    const link = document.createElement("a");
    link.href = url;
    link.download = "orders.csv";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="">
      <div className="rounded-xl bg-white ">
        {/* min-w-[749px] */}
        <div className="grid grid-cols-1 gap-4">
          <div className="w-full py-6">
            {!preview && (
              <>
                <UploadViewOrder
                  isDragOver={isDragOver}
                  handleDragEnter={handleDragEnter}
                  handleDragLeave={handleDragLeave}
                  handleDrop={handleDrop}
                  onFileDropOrChange={onFileDropOrChange}
                  setPreview={setPreview}
                  uploadedData={uploadedData}
                  hasAnyError={hasAnyError}
                  handleDownload={handleDownload}
                  closeModal={closeModal}
                  handleCancel={handleCancel}
                  setFileData={setFileData}
                  fileData={fileData}
                  onSubmit={onSubmitBulkUpload}
                  handleFileDropOrChange={handleFileDropOrChange}
                />
              </>
            )}
            {preview && (
              <Preview
                data={uploadedData}
                seasons={seasonList}
                articles={articleList}
                templates={templateList}
                suppliers={supplierList}
                agents={agentList}
                retailers={retailerList}
                onChangeHandler={onChangeHandler}
                onSubmit={onSubmitBulkUpload}
                onSubmitBulkUpload={onSubmitBulkUpload}
                control={control}
                setValue={setValue}
                hasAnyError={hasAnyError}
                showLimitText={showLimitText}
                closeModal={closeModal}
                handleCancel={handleCancel}
                preview={preview}
                setPreview={setPreview}
                uploadedData={uploadedData}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadBulkOrder;
