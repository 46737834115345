import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useParams } from "react-router-dom";
import {
  downloadReports,
  getDashboardWorkspaceDetails,
  getReports,
} from "services/dashboard.services";
import { setWorkspaceDashboardData } from "store/accountDashboardSlice";

import { DownloadCloudIcon } from "assests";
import TopNavbar from "components/TopHeader/TopNavbar";
import BackToDashboard from "components/UI/BackToDashboard";
import { Button } from "components/UI/Button";
import Card from "components/UI/Card";
import CircleProgressBar from "components/UI/CircleProgressBar";
import useCustomToaster from "hooks/useCustomToaster";
import SubNavbar from "layout/SubNavbar";
import Packages from "pages/Dashboard/Packages";
import toast from "react-hot-toast";
import { getAgentDetails } from "services/company-details.services";
import { transpercencyLineProgressPercentageColors } from "utils/helper";
import LoadingComponent from "components/UI/LoadingComponent";

const ReportMain = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const [isHovered, setIshovered] = useState(false);
  const { workspaceId } = useParams();
  const { token, currentWorkspace } = useSelector((state) => state.authenticationSlice);
  const supplierId = currentWorkspace?.own_workspace_id;
  const { workspaceDashboardData } = useSelector((state) => state.accountDashboardSlice);
  const [reportDetails, setReportDetails] = useState(null);
  const [supplierDetails, setSupplierDetails] = useState(null);
  const { showCustomToast } = useCustomToaster();
  const {
    data: workspaceDashboardDataquery,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ["orders", "getOrders", workspaceId],
    queryFn: () => getDashboardWorkspaceDetails({ workspace_id: workspaceId }),
    enabled: !!workspaceId,
  });

  useEffect(() => {
    if (!isLoading && workspaceDashboardDataquery) {
      dispatch(
        setWorkspaceDashboardData({
          workspaceDashboardData: workspaceDashboardDataquery?.data?.data,
          append: false,
        }),
      );
    }
  }, [workspaceDashboardDataquery, isLoading]);

  const {
    data: ReportQueryData,
    isLoading: isReportLoading,
    refetch: refetchReport,
  } = useQuery({
    queryKey: ["report", "getReport", workspaceId],
    queryFn: () => getReports({ workspace_id: workspaceId }),
    enabled: !!workspaceId,
  });

  useEffect(() => {
    if (ReportQueryData && ReportQueryData.status === 200 && !isReportLoading) {
      const details = ReportQueryData.data;
      setReportDetails(details);
    }
  }, [ReportQueryData, isReportLoading]);

  const {
    data: supplierQueryData,
    isLoading: isSupplierQuryLoading,
    refetch: refetchSupplierQury,
  } = useQuery({
    queryKey: ["suppliers", "getAgentDetails", supplierId, workspaceId],
    queryFn: () =>
      getAgentDetails(supplierId, {
        type: "supplier",
        status: "active",
        workspace_id: workspaceId,
      }),
    enabled: !!supplierId,
  });

  useEffect(() => {
    if (supplierQueryData && supplierQueryData.status === 200 && !isLoading) {
      const details = supplierQueryData.data.workspace;
      const allDetails = supplierQueryData.data;
      const total_order = supplierQueryData.data.total_order;
      setSupplierDetails(details);
    }
  }, [supplierQueryData, isLoading]);

  const downloadReportAsZIP = useMutation({
    mutationKey: ["retailers", "download"],
    mutationFn: (params) => downloadReports(params), // Ensure the server provides a ZIP file
    onMutate: () => {
      showCustomToast(
        {
          title: "Downloading...",
        },
        "downloadRetailers",
      );
    },
    onSuccess: (res) => {
      toast.dismiss("downloadRetailers");
      showCustomToast({
        title: "Downloaded successfully",
      });
      const blob = new Blob([res.data], { type: "application/zip" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "reports.zip";
      document.body.appendChild(link); // Append the link to the body
      link.click();
      document.body.removeChild(link); // Remove the link after clicking
      URL.revokeObjectURL(link.href);
      URL.revokeObjectURL(link.href);
      queryClient.invalidateQueries(["retailers", "download"]);
    },
    onError: (error) => {
      toast.dismiss("downloadRetailers");
      showCustomToast({
        title: error.response.data.message || error.message || "Something went wrong!",
      });
    },
  });

  const handleDownloadCSV = () => {
    downloadReportAsZIP.mutate({
      workspace_id: parseInt(currentWorkspace?.workspace_id),
    });
  };

  if (!token || !localStorage.getItem("auth")) {
    return <Navigate to="/signin" replace />;
  }

  return (
    <>
      <TopNavbar isFromDashboard={false}>
        <SubNavbar
          title="Reporting"
          subtitle="Gain insight into your overall account performance here."
        />
      </TopNavbar>
      <div className="px-4 md:px-8 pt-[64px] md:pt-[116px]">
        <BackToDashboard className="block md:hidden" />
        <div className="block md:hidden">
          <SubNavbar
            title="Reporting"
            subtitle="Gain insight into your overall account performance here."
          />
        </div>
        <div className="flex flex-col gap-4 md:flex-row md:items-center justify-start md:justify-between py-4 md:py-6">
          <div className="text-base md:text-lg font-semibold">Supply Chain Metrics</div>
          <Button
            variant="secondary"
            className="text-txt-secondary px-3.5 py-2.5 gap-[5px] text-sm  font-semibold"
            onMouseEnter={() => setIshovered(true)}
            onMouseLeave={() => setIshovered(false)}
            onClick={handleDownloadCSV}
          >
            <DownloadCloudIcon
              width={20}
              height={20}
              strokeColor={
                isHovered ? "var(--color-icon-fill-secondary-button)" : "var(--color-txt-secondary)"
              }
            />{" "}
            Download report
          </Button>
        </div>
        <div className="bg-global-divider-medium h-[1px] mb-4 md:mb-6 " />

        <div className="grid grid-cols-12 gap-4 md:gap-6">
          <div className="col-span-12 lg:col-span-6 min-[1440px]:col-span-3 w-full">
            <Card
              size={"sm"}
              borderRadius={"sm"}
              className="py-3 px-4 h-[106px] md:px-5 bg-white sticky top-0 rounded-xl border border-border-secondary custom-card-buttom  gap-2 flex justify-start items-center text-card-small-title "
            >
              <div className="w-full whitespace-nowrap">
                <div className="text-sm text-gray-800 leading-5 font-medium">
                  Transparency Score
                </div>
                <div className="text-3xl font-semibold text-global-title leading-[38px]">
                  {reportDetails?.metrics?.transparency_score
                    ? reportDetails?.metrics?.transparency_score
                    : 0}
                  %
                </div>
              </div>
              <div>
                <CircleProgressBar
                  currentProgress={
                    reportDetails?.metrics?.transparency_score
                      ? reportDetails?.metrics?.transparency_score
                      : 0
                  }
                  size={17.25}
                  strokeColorVariable={transpercencyLineProgressPercentageColors(reportDetails?.metrics?.transparency_score
                    ? reportDetails?.metrics?.transparency_score
                    : 0)}
                />
              </div>
            </Card>
          </div>
          <div className="col-span-12 lg:col-span-6 min-[1440px]:col-span-3 w-full">
            <Card
              size={"sm"}
              borderRadius={"sm"}
              className="py-3 px-4 h-[106px] md:px-5 bg-white sticky top-0 rounded-xl border border-border-secondary custom-card-buttom  gap-2 flex justify-start items-center text-card-small-title "
            >
              <div className="w-full whitespace-nowrap">
                <div className="text-sm text-gray-800 leading-5 font-medium">
                  Verification Score
                </div>
                <div className="text-3xl font-semibold text-global-title leading-[38px]">
                  {" "}
                  {reportDetails?.metrics?.verification_score
                    ? reportDetails?.metrics?.verification_score
                    : 0}
                  %
                </div>
              </div>
              <div>
                <CircleProgressBar
                  currentProgress={
                    reportDetails?.metrics?.verification_score
                      ? reportDetails?.metrics?.verification_score
                      : 0
                  }
                  size={17.25}
                  strokeColorVariable={"--color-progress-surface-blue"}
                />
              </div>
            </Card>
          </div>
          <div className="col-span-12 lg:col-span-6 min-[1440px]:col-span-3 w-full">
            <Card
              size={"sm"}
              borderRadius={"sm"}
              className="py-3 px-4 h-[106px] md:px-5 bg-white sticky top-0 rounded-xl border border-border-secondary custom-card-buttom  gap-2 flex justify-start items-center text-card-small-title "
            >
              <div className="w-full whitespace-nowrap">
                <div className="text-sm text-gray-800 leading-5 font-medium">Active Orders</div>
                <div className="text-3xl font-semibold text-global-title leading-[38px]">
                  {reportDetails?.metrics?.active_orders_count
                    ? reportDetails?.metrics?.active_orders_count
                    : 0}
                </div>
              </div>
            </Card>
          </div>

          <div className="col-span-12 lg:col-span-6 min-[1440px]:col-span-3 w-full">
            <Card
              size={"sm"}
              borderRadius={"sm"}
              className="py-3  px-4 h-[106px] md:px-5 bg-white sticky top-0 rounded-xl border border-border-secondary custom-card-buttom  gap-2 flex justify-start items-center text-card-small-title "
            >
              <div className="w-full whitespace-nowrap">
                <div className="text-sm text-gray-800 leading-5 font-medium">Linked Tiers</div>
                <div className="text-3xl font-semibold text-global-title leading-[38px]">
                  {reportDetails?.metrics?.total_linked_tiers
                    ? reportDetails?.metrics?.total_linked_tiers
                    : 0}
                  <span className="text-2xl">
                    /{reportDetails?.metrics?.total_tiers ? reportDetails?.metrics?.total_tiers : 0}
                  </span>
                </div>
              </div>
            </Card>
          </div>
        </div>

      
        <div className="text-base md:text-lg font-semibold my-4 md:mt-6 md:mb-5">Tier Performance</div>
        <div className="bg-global-divider-medium h-[1px] " />
        <div className="flex flex-col flex-grow w-full pt-4 md:pt-5 ">
          <div className="grid gap-12">
            <div className="overflow-x-auto">
              {(isLoading || isReportLoading) ? (
                <LoadingComponent />
              ) : (
                workspaceDashboardData && (
                  <Packages tiersData={workspaceDashboardData} reportDetails={reportDetails} />
                )
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReportMain;
