import api from "config/axios";
export const getWorkspaces = (query) => {
  const params = new URLSearchParams();
  Object.keys(query).forEach((key) => {
    if (query[key]) {
      if (key === "transparency_score") {
        for (let i = 0; i < query[key].length; i++) {
          params.append(`${key}[]`, query[key][i]);
        }
      } else {
        params.append(key, query[key]);
      }
    }
  });
  return api.get(`/workspaces?${params}`);
};



export const getPendingInvitationList = async () => {
  try {
    const response = await api.get(`/pending-invitations`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const destroyWorkspace = (id) => {
  return api.delete(`/workspace/destroy/${id}`);
};

export const getAllWorkspaces = async () => {
  try {
    const response = await api.get(`/workspaces-filter/company`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const downloadWorkspaceAsCSV = async (params) => {
  try {
    const response = await api.get(`/workspaces`, {
      params: { ...params, download: 'yes' },

    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const bulkDeleteWorkspaces = async (data) => {
  try {
    const response = await api.post(`/workspaces/bulk-delete`, { workspaces: data });
    return response;
  } catch (error) {
    throw error;
  }
}

export const getPendingInvitedList = async () => {
  try {
    const response = await api.get(`/pending-invited`);
    return response;
  } catch (error) {
    throw error;
  }
}

export const cancelInvitation = async (data) => {
  try {
    const response = await api.post(`/invitations/cancel`, data);
    return response;
  } catch (error) {
    throw error;
  }
}

export const leaveWorkspace = async (data) => {
  try {
    const response = await api.post(`/leave-partner`, data);
    return response;
  } catch (error) {
    throw error;
  }
}
export const deleteWorkspace = async (data) => {
  try {
    const response = await api.post(`/remove-partner`, data);
    return response;
  } catch (error) {
    throw error;
  }
}