import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { BgImg, CheckCircleOutlined, Halflogoicon, PhoneBGImg } from "assests";
import FormHeader from "components/UI/FormHeader";
import SigninLogo from "components/UI/SigninLogo";
import useCustomToaster from "hooks/useCustomToaster";
import Navbar from "layout/Navbar";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import { confirmDeactivation } from "services/company-settings.services";

const DeactivateSuccess = () => {
  const navigate = useNavigate();
  const { showCustomToast } = useCustomToaster();
  const [searchParams] = useSearchParams();
  const hash = searchParams.get("hash");
  const { isDarkMode } = useSelector((state) => state.themeSlice);
  const queryClient = useQueryClient();

  const acceptInvitationMutation = useMutation({
    mutationKey: ["useraccept", "acceptinvitationlogin"],
    mutationFn: (data) => confirmDeactivation(data),
    onSuccess: (res) => {
      showCustomToast(
        {
          title: res.data.message || "Account has been deactivated successfully",
        },
      );
      queryClient.invalidateQueries("protectedResource");
    },
    onError: (error) => {
      showCustomToast(
        {
          title: "There is a problem deactivating your account. Please conatct administrator",
        },
      );
    },
  });

  useEffect(() => {
    if (hash) {
      acceptInvitationMutation.mutate({ hash: hash });
    }
  }, [hash])

  if (!hash) navigate("/", { replace: true });

  return (
    <div
      className={`w-full h-full min-h-screen   ${isDarkMode ? "bg-global-surface" : "bg-global-theme"
        } overflow-y-auto `}
    >
      <div
        className=" flex items-center justify-center w-full h-full "
        style={{ pointerEvents: "none" }}
      >
        <img src={BgImg} alt="" className="fixed top-0 hidden md:block" />
        <img src={PhoneBGImg} alt="" className="fixed top-[50px] block md:hidden" />
      </div>
      <Navbar redirectToSignin={true} />
      <div className="flex flex-col justify-between w-full h-full py-[120px]  lg:py-[176px] gap-20">
        <div className="flex items-center justify-center w-full">

          <div className="relative w-full px-4 md:p-0 md:w-[360px]">
            <div className="flex flex-col gap-8">
              <div className="flex flex-col gap-6">
                <SigninLogo>
                  <Halflogoicon width={18.31} height={28} />
                </SigninLogo>
                <FormHeader
                  icon={false}
                  title={`We are sorry to`}
                  titleTwo={`see you go!`}
                  subtitle={`Your account has been deactivated. We’re sorry to see you go and hope to have the opportunity to serve you again in the future. `}
                  subtitle4={`If you wish to recover your account, you can request `}
                  boldText4={"reactivation within 30 days."}
                />
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>

  );
};

export default DeactivateSuccess;
