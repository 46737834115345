import { weCareIcon } from "assests";
import React from "react";
import { Link } from "react-router-dom";

const WeCare = ({ className }) => {
  return (
    <div
      className={`w-full px-8 py-[19px] md:px-12 md:py-4 bg-white rounded-xl  border border-global-divider-soft flex-col justify-center items-center inline-flex ${className}`}
      style={{
        boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
      }}
    >
      <div className="w-full justify-start items-center gap-x-4 gap-y-0 inline-flex">
        <img alt="" className="w-6 h-6" src={weCareIcon} />
        <div className="w-full flex-col justify-start items-start gap-0  inline-flex">
          <div className="text-global-title text-lg leading-[26px] font-semibold">Because we care</div>
          <div>
            <span className="text-global-paragraph text-sm font-normal leading-tight">
              FiberVue™ contributes 1% of your subscription to remove CO₂ from the atmosphere
              through{" "}
            </span>
            <Link to="https://stripe.com/climate" target="_blank">
              <span className="text-global-primary text-sm font-normal  leading-tight">
                Stripe Climate
              </span>
            </Link>
            <span className="text-global-paragraph text-sm font-normal  leading-tight">.</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WeCare;
