import { cva } from "class-variance-authority";
import { cn } from "utils/helper";

const buttonVariants = cva(
  [
    "flex gap-2 place-items-center justify-center disabled:bg-btn-surface-disabled disabled:border-0 disabled:text-btn-text-primary-disabled items-center text-center place-content-center w-auto rounded-lg font-semibold text-sm disabled:cursor-not-allowed",
  ],
  {
    variants: {
      variant: {
        primary:
          "border-none bg-global-primary text-white hover:bg-gray-950  text-white custom-primary-shadow",
        secondary:
          " border-none bg-btn-surface-secondary text-global-gray-brand outline-none hover:bg-gray-950 hover:text-white  ",
        status:
          "border-none bg-btn-surface-secondary text-global-gray-brand outline-none custom-outline-shadow ",
        tertiary:
          "border-none text-btn-text-tertiary bg-transparent hover:text-btn-text-tertiary-hover  ",
        disabled: "disabled:bg-btn-surface-disabled text-btn-text-primary-disabled",
        link: "border-none bg-transparent outline-none ring-0 focus:outline-none focus:ring-0 focus:border-none text-btn-text-link hover:text-btn-text-link-hover disabled:bg-transparent",
        action: "border-none text-global-title-2 bg-transparent",
        warning:
          "border-none bg-btn-surface-warning text-white hover:bg-gray-950 text-white custom-primary-shadow",
      },
      size: {
        xs: "py-1.5 px-2",
        sm: "py-[2px] px-3 ",
        md: "py-[10px] px-[14px]",
        lg: "py-[10px] px-4 ",
      },
    },
    defaultVariants: {
      variant: "primary",
      size: "md",
    },
  },
);

const Button = ({ children, type, className, variant, size, isDisabled = false, ...props }) => {
  const computedClassName = cn(
    buttonVariants({
      variant,
      size
    }),
    className,
    variant === "secondary" && !isDisabled ? "custom-outline-shadow" : ""
  );
  return (
    <button
      type={type || "button"}
      className={computedClassName}
      disabled={isDisabled}
      {...props}
    >
      {children}
    </button>
  );
};
export { Button, buttonVariants };
