import { useQuery } from "@tanstack/react-query";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getSubscriptionPlans } from "services/subscription.services";
import { setSubscriptionList } from "store/subscriptionSlice";

const useSubscriptionPlan = () => {
  const dispatch = useDispatch();
  const {
    data: subscriptionPlansData,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ["subscriptionPlans", "getSubscriptionPlans"],
    queryFn: getSubscriptionPlans,
    enabled: true,
  });
  useEffect(() => {
    if (!isLoading && subscriptionPlansData) {
      dispatch(
        setSubscriptionList({
          subscriptionList: subscriptionPlansData?.data?.data?.data,
          append: false,
        }),
      );
    }
  }, [subscriptionPlansData, isLoading, dispatch]);
};

export default useSubscriptionPlan;
