import React from "react";

const SentEmailSuccess = React.forwardRef(
  (
    { width = "60", height = "60", viewBox = "0 0 60 60", fill = "none", className, ...props },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_dii_17_2592)">
          <path
            d="M2 13C2 6.37258 7.37258 1 14 1H46C52.6274 1 58 6.37258 58 13V45C58 51.6274 52.6274 57 46 57H14C7.37258 57 2 51.6274 2 45V13Z"
            fill="white"
          />
          <path
            d="M2.5 13C2.5 6.64873 7.64873 1.5 14 1.5H46C52.3513 1.5 57.5 6.64873 57.5 13V45C57.5 51.3513 52.3513 56.5 46 56.5H14C7.64873 56.5 2.5 51.3513 2.5 45V13Z"
            stroke="#E4E7EC"
          />
          <path
            d="M18.3333 23.1667L27.8591 29.8347C28.6304 30.3747 29.0161 30.6446 29.4356 30.7492C29.8062 30.8416 30.1938 30.8416 30.5643 30.7492C30.9839 30.6446 31.3695 30.3747 32.1409 29.8347L41.6666 23.1667M23.9333 38.3334H36.0666C38.0268 38.3334 39.0069 38.3334 39.7556 37.9519C40.4142 37.6163 40.9496 37.0809 41.2852 36.4223C41.6666 35.6736 41.6666 34.6935 41.6666 32.7334V25.2667C41.6666 23.3065 41.6666 22.3264 41.2852 21.5777C40.9496 20.9192 40.4142 20.3837 39.7556 20.0482C39.0069 19.6667 38.0268 19.6667 36.0666 19.6667H23.9333C21.9731 19.6667 20.993 19.6667 20.2443 20.0482C19.5858 20.3837 19.0503 20.9192 18.7148 21.5777C18.3333 22.3264 18.3333 23.3065 18.3333 25.2667V32.7334C18.3333 34.6935 18.3333 35.6736 18.7148 36.4223C19.0503 37.0809 19.5858 37.6163 20.2443 37.9519C20.993 38.3334 21.9731 38.3334 23.9333 38.3334Z"
            stroke="#344054"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <filter
            id="filter0_dii_17_2592"
            x="0"
            y="0"
            width="60"
            height="60"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="1" />
            <feGaussianBlur stdDeviation="1" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.05 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_17_2592" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_17_2592"
              result="shape"
            />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="-2" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.05 0"
            />
            <feBlend mode="normal" in2="shape" result="effect2_innerShadow_17_2592" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feMorphology
              radius="1"
              operator="erode"
              in="SourceAlpha"
              result="effect3_innerShadow_17_2592"
            />
            <feOffset />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.18 0"
            />
            <feBlend
              mode="normal"
              in2="effect2_innerShadow_17_2592"
              result="effect3_innerShadow_17_2592"
            />
          </filter>
        </defs>
      </svg>
    );
  },
);

export default SentEmailSuccess;
