import React from "react";

const ArrowdownCircle = React.forwardRef(
  (
    {
      width = "20",
      height = "20",
      viewBox = "0 0 20 20",
      fill = "none",
      strokeColor = "#414651",
      filColor = "white",
      className,
      ...props
    },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_13177_401)">
          <path
            d="M6.66602 10.0001L9.99935 13.3334M9.99935 13.3334L13.3327 10.0001M9.99935 13.3334V6.66675M18.3327 10.0001C18.3327 14.6025 14.6017 18.3334 9.99935 18.3334C5.39698 18.3334 1.66602 14.6025 1.66602 10.0001C1.66602 5.39771 5.39698 1.66675 9.99935 1.66675C14.6017 1.66675 18.3327 5.39771 18.3327 10.0001Z"
            stroke={strokeColor}
            strokeWidth="1.67"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_13177_401">
            <rect width="20" height="20" fill={filColor} />
          </clipPath>
        </defs>
      </svg>
    );
  },
);

ArrowdownCircle.displayName = "ArrowdownCircle";

export default ArrowdownCircle;
