import { zodResolver } from "@hookform/resolvers/zod";
import { CardNumberElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { useMutation, useQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  createWorkspaceAndConfirmPayment,
  migrateSubscriptionPlan,
} from "services/subscription.services";
import { setRefetchBillingData } from "store/billingSlice";
import { paymentSchema } from "validation/payment.validation";
import useCustomToaster from "./useCustomToaster";
import Cookies from "js-cookie";
import { checkDowngradeOrUpgrade } from "utils/helper";
import useUser from "./useUser";

const usePayment = () => {
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const { selectedPlanData, selectedPlanType, autoRenew } = useSelector(
    (state) => state.subscriptionSlice,
  );
  const { showCustomToast } = useCustomToaster();
  const { billingDetails, currentPlan } = useSelector((state) => state.billingSlice);
  const { user } = useSelector((state) => state.authenticationSlice);
  const [downgradeOrUpgrade, setDowngradeOrUpgrade] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const { userMutation } = useUser();
  //? New Subscription mutation
  const confirmPaymentMutation = useMutation({
    mutationKey: ["confirmPayment"],
    mutationFn: async (data) => {
      const { cardElement, processedData, billingDetails } = data;
      try {
        const stripPaymentMethod = await stripe.createPaymentMethod({
          type: "card",
          card: cardElement,
          billing_details: {
            name: processedData.cardHolderName,
            email: billingDetails?.email,
            address: {
              line1: billingDetails?.street,
              country: billingDetails?.country_code,
              state: billingDetails?.region,
              postal_code: billingDetails?.zip,
            },
          },
        });
        if (stripPaymentMethod.paymentMethod && selectedPlanData && selectedPlanType) {
          const payLoad = {
            workspace_id: processedData.workspace_id,
            plan_id: selectedPlanData.id,
            auto_renew: autoRenew,
            payment_method: stripPaymentMethod.paymentMethod,
          };
          const successData = await createWorkspaceAndConfirmPayment(payLoad);
          return successData;
        } else {
          return stripPaymentMethod;
        }
      } catch (error) {
        setErrorMessage(error.message);
        throw error; // Re-throw the error for `useMutation` to catch
      }
    },
    onMutate: () => {
      setErrorMessage(null)
      showCustomToast(
        {
          title: "Processing payment...",
        },
        "payment-processing",
      );
    },
    onSuccess: (res) => {
      toast.dismiss("payment-processing");
      if (!res) return;
      if (res.error) {
        const message = res.error.message.substring(0, 50);
        dispatch(setRefetchBillingData(true));
        showCustomToast({
          title: message || "Something went wrong",
        });
        return;
      }
      const isFirstPayment = Cookies.get("showTutorial") || false;
      const parseIsFirstPayment = JSON.parse(isFirstPayment);
      if (!parseIsFirstPayment) {
        Cookies.set("showTutorial", "true", { expires: 365 });
      }
      userMutation.mutate();
      reset();
      navigate("/", { replace: true });
      showCustomToast({
        title: "Plan upgrade succesful",
        subtitle: "Your plan has been successfully upgraded. Enjoy your new features and benefits.",
        icon: true,
      });
      setErrorMessage(null)
    },
    onError: (error) => {
      toast.dismiss("payment-processing");
      setErrorMessage(error.message);
      if (error.status === false && error.message === "validation error") {
        const errorMessages = error.errors;
        Object.entries(errorMessages || {}).forEach(([key, value]) => {
          if (Array.isArray(value)) {
            value.forEach((message) => {
              showCustomToast({
                title: message,
              });
            });
          }
        });
      } else {
        showCustomToast({
          title: error.message || "Something went wrong",
        });
      }
    },
  });

  const upgradePlanMutation = useMutation({
    mutationKey: ["upgrade-plan"],
    mutationFn: (data) => migrateSubscriptionPlan(data),
    onMutate: () => {
      showCustomToast(
        {
          title: "Migrating your plan...",
        },
        "upgrade-plan",
      );
    },
    onSuccess: (res) => {
      toast.dismiss("upgrade-plan");
      if (res.data && res.status === 200 && res.data.status) {
        userMutation.mutate();
        dispatch(setRefetchBillingData(true));
        showCustomToast({
          title: "Plan upgrade succesful",
          subtitle: "Your plan has been successfully upgraded. Enjoy your new features and benefits.",
          icon: true,
        });
        if (downgradeOrUpgrade === "UPGRADE") {
          Cookies.set("showTutorial", "true", { expires: 365 });
        }
        navigate("/", { replace: true });
      }
    },
    onError: (error) => {
      toast.dismiss("upgrade-plan");
      showCustomToast({
        title: error.message || "Something went wrong",
      });
    },
  });

  const {
    control,
    register,
    handleSubmit,
    setValue,
    setError,
    formState: { errors, isLoading },
    reset,
  } = useForm({
    defaultValues: {
      workspace_id: user?.workspace_id?.toString(),
      cardHolderName: "",
      planId: "",
      acceptTerms: false,
    },
    mode: "all",
    reValidateMode: "onChange",
    shouldUnregister: true,
    criteriaMode: "all",
    resolver: zodResolver(paymentSchema),
  });



  const onSubmitForm = async (data) => {
    if (!stripe || !elements || !selectedPlanData) return;

    const processedData = Object.entries(data).reduce((acc, [key, value]) => {
      if (typeof value === "object" && value !== null) {
        acc[key] = value.value;
      } else {
        acc[key] = value;
      }
      return acc;
    }, {});

    const cardElement = elements.getElement(CardNumberElement);

    if (!billingDetails) {
      showCustomToast({
        title: "Company details is missing",
      });
      return;
    }

    const mutationData = {
      cardElement,
      processedData,
      billingDetails,
    };
    confirmPaymentMutation.mutate(mutationData);
  };

  const onCreateOrUpgrade = () => {
    if (downgradeOrUpgrade === "UPGRADE") {
      if (!currentPlan && !selectedPlanData) return;
      const mutationData = {
        plan_id: selectedPlanData.id,
        subscription_id: currentPlan.subscription.subscription_id,
      };
      upgradePlanMutation.mutate(mutationData);
    } else {
      handleSubmit(onSubmitForm, onFormError)();
    }
  };

  const handleChangeCardItem = (e) => {
    const { brand, complete, elementType, error, empty } = e;

    if (empty && !complete && !error) {
      setError(elementType, { type: "custom", message: "Field is required" });
    }

    if (empty && !complete && error) {
      setError(elementType, { type: "custom", message: error.message });
    }

    if (!empty && !complete && error) {
      setError(elementType, { type: "custom", message: error.message });
    }

    if (complete && !empty && !error) {
      setError(elementType, null);
    }
  };

  const onFormError = (error) => {
    console.error(error);
  };

  useEffect(() => {
    if (user) {
      setValue("workspace_id", user.workspace_id.toString());
    }
  }, [user]);

  useEffect(() => {
    if ((currentPlan, selectedPlanData, selectedPlanType)) {
      setDowngradeOrUpgrade(
        checkDowngradeOrUpgrade(currentPlan, selectedPlanData, selectedPlanType),
      );
    }
  }, [currentPlan, selectedPlanData, selectedPlanType]);

  return {
    control,
    register,
    setValue,
    setError,
    handleSubmit: onCreateOrUpgrade,
    isPending: upgradePlanMutation.isPending || confirmPaymentMutation.isPending,
    handleChangeCardItem,
    errors,
    isLoading,
    confirmPaymentMutation,
    downgradeOrUpgrade,
    upgradePlanMutation,
    errorMessage,
    setErrorMessage
  };
};

export default usePayment;
