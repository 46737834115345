import { createContext, useContext, useState } from "react";
import { LoadScript } from "@react-google-maps/api";

const GoogleMapsContext = createContext();

export const GoogleMapsProvider = ({ children }) => {
  const [isLoaded, setIsLoaded] = useState(false);

  return (
    <GoogleMapsContext.Provider value={{ isLoaded }}>
      <LoadScript
        googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAP_KEY}
        libraries={["places"]}
        onLoad={() => setIsLoaded(true)}
        onError={(error) => console.error("Error loading Google Maps:", error)}
      >
        {children}
      </LoadScript>
    </GoogleMapsContext.Provider>
  );
};

export const useGoogleMaps = () => useContext(GoogleMapsContext);
