import React from "react";

const PlanIcon = React.forwardRef(
  (
    {
      width = "24",
      height = "24",
      viewBox = "0 0 24 24",
      fill = "none",
      fillColor = "white",
      className,
      ...props
    },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2 14.4999L11.6422 19.3211C11.7734 19.3867 11.839 19.4194 11.9078 19.4324C11.9687 19.4438 12.0313 19.4438 12.0922 19.4324C12.161 19.4194 12.2266 19.3867 12.3578 19.3211L22 14.4999M2 9.49994L11.6422 4.67883C11.7734 4.61324 11.839 4.58044 11.9078 4.56753C11.9687 4.5561 12.0313 4.5561 12.0922 4.56753C12.161 4.58044 12.2266 4.61324 12.3578 4.67883L22 9.49994L12.3578 14.3211C12.2266 14.3866 12.161 14.4194 12.0922 14.4324C12.0313 14.4438 11.9687 14.4438 11.9078 14.4324C11.839 14.4194 11.7734 14.3866 11.6422 14.3211L2 9.49994Z"
          stroke={fillColor}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  },
);

PlanIcon.displayName = "PlanIcon";

export default PlanIcon;
