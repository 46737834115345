import React from "react";

const PlusCircle = React.forwardRef(
  (
    {
      width = "20",
      height = "20",
      viewBox = "0 0 20 20",
      fill = "none",
      className,
      onClick,
      ...props
    },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        width={width}
        height={height}
        viewBox={viewBox}
        fill={fill}
        className={className}
        onClick={onClick} // Ensure onClick is passed directly here
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.2778 7.49992V13.0555M7.50004 10.2777H13.0556M17.2223 10.2777C17.2223 14.113 14.1131 17.2221 10.2778 17.2221C6.44251 17.2221 3.33337 14.113 3.33337 10.2777C3.33337 6.44239 6.44251 3.33325 10.2778 3.33325C14.1131 3.33325 17.2223 6.44239 17.2223 10.2777Z"
          stroke="#475467"
          strokeWidth="1.66667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  },
);
PlusCircle.displayName = "PlusCircle";

export default PlusCircle;
