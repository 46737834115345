import React from "react";

const MailResendIcon = React.forwardRef(
  (
    {
      width = "16",
      height = "16",
      viewBox = "0 0 16 16",
      fill = "none",
      fillColor = "#8E8F98",
      className,
    },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.3332 12L9.90468 7.99996M6.09516 7.99996L1.66661 12M1.33325 4.66663L6.77653 8.47692C7.21731 8.78547 7.4377 8.93974 7.67743 8.9995C7.88918 9.05228 8.11066 9.05228 8.32241 8.9995C8.56213 8.93974 8.78252 8.78547 9.2233 8.47692L14.6666 4.66663M4.53325 13.3333H11.4666C12.5867 13.3333 13.1467 13.3333 13.5746 13.1153C13.9509 12.9236 14.2569 12.6176 14.4486 12.2413C14.6666 11.8134 14.6666 11.2534 14.6666 10.1333V5.86663C14.6666 4.74652 14.6666 4.18647 14.4486 3.75864C14.2569 3.38232 13.9509 3.07636 13.5746 2.88461C13.1467 2.66663 12.5867 2.66663 11.4666 2.66663H4.53325C3.41315 2.66663 2.85309 2.66663 2.42527 2.88461C2.04895 3.07636 1.74299 3.38232 1.55124 3.75864C1.33325 4.18647 1.33325 4.74652 1.33325 5.86663V10.1333C1.33325 11.2534 1.33325 11.8134 1.55124 12.2413C1.74299 12.6176 2.04895 12.9236 2.42527 13.1153C2.85309 13.3333 3.41315 13.3333 4.53325 13.3333Z"
          stroke="#717680"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  },
);

export default MailResendIcon;
