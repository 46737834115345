import { emailValidation } from "utils/constant";
import * as z from "zod";
export const invitationSchema = z.object({
  company_name: z
    .string({
      required_error: "Company name is required",
    })
    .min(3, {
      message: "Enter at least 3 characters",
    })
    .max(100, { message: "Company name must not exceed 100 characters" }),

  person_name: z
    .string({
      required_error: "Invited person name is required",
    })
    .min(3, "Enter at least 3 characters")
    .max(100, { message: "Invited person name must not exceed 100 characters" }),
  email: emailValidation,
  workspace_id: z.number({
    required_error: "Workspace id required",
    invalid_type_error: "Workspace id required",
  }),
  company_type: z.string(),
});

export const invitationFromWorkspaceSchema = z.object({
  company_name: z
    .string({
      required_error: "Company name is required",
    })
    .min(3, { message: "Enter at least 3 characters" })
    .max(100, { message: "Company name must not exceed 100 characters" }),
  company_type: z.object(
    {
      label: z.string({ message: "Business Type is required" }),
      value: z.string({ message: "Business Type is required" }),
    },
    {
      required_error: "Business Type is required",
      invalid_type_error: "Please select business type",
    },
  ),
  first_name: z
    .string({
      required_error: "Required",
    })
    .min(3, { message: "Enter at least 3 characters" })
    .max(100, { message: "First name must not exceed 100 characters" }),
  last_name: z
    .string({
      required_error: "Required",
    })
    .min(1, { message: "Enter at least 1 characters" })
    .max(100, { message: "Last name must not exceed 100 characters" }),
  details: z.string().optional().nullable(true),
  email: emailValidation,
  workspace_id: z.number({
    required_error: "Workspace id required",
    invalid_type_error: "Workspace id required",
  }),
});
