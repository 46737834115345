const { createSlice } = require("@reduxjs/toolkit");

const articleSlice = createSlice({
  name: "article",
  initialState: {
    isArticleCreateModalOpen: false,
    actionableArticelId: null,
    isArticleEditable: false,
    isDuplicateAction: false,
    articleAllData: null,
    articleList: [],
    articleDetails: null,
    deleteModal: false,
    actionModal: false,
    reachedModal: false,
    showDiscardModal: false,
    isUploadBulkArticleModalOpen: false,
    resetFormData: false,
    actionableArticleItem: null,
  },
  reducers: {
    toggleIsArticleCreateModalOpen: (state) => ({
      ...state,
      isArticleCreateModalOpen: !state.isArticleCreateModalOpen,
    }),
    toggleActionableArticleId: (state, action) => ({
      ...state,
      actionableArticelId: action.payload ?? null,
    }),
    toggleIsArticleEditable: (state) => ({ ...state, isArticleEditable: !state.isArticleEditable }),
    toggleShowDiscardModal: (state) => ({ ...state, showDiscardModal: !state.showDiscardModal }),
    toggleDuplicateAction: (state) => ({ ...state, isDuplicateAction: !state.isDuplicateAction }),
    toggleActionModal: (state) => ({ ...state, actionModal: !state.actionModal }),
    toggleDeleteModal: (state) => {
      state.deleteModal = !state.deleteModal;
    },
    toggleResetFormData: (state) => {
      state.resetFormData = !state.resetFormData;
    },
    toggleReachedModal: (state) => {
      state.reachedModal = !state.reachedModal;
    },
    setArticleList: (state, action) => {
      if (action.payload.append) {
        // append the article to the list
        return {
          ...state,
          articleList: [action.payload.article, ...state.articleList],
        };
      } else {
        // set the article list
        return {
          ...state,
          articleList: action.payload.articleList,
        };
      }
    },
    setArticleAllData: (state, action) => ({
      ...state,
      articleAllData: action.payload.articleAllData ?? null,
    }),
    setArticleDetails: (state, action) => ({
      ...state,
      articleDetails: action.payload ?? null,
    }),

    setActionableArticleItem: (state, action) => ({
      ...state,
      actionableArticleItem: action.payload ?? null,
    }),
    setArticleSliceState: (state, action) => ({
      ...state,
      ...action.payload,
    }),
    toggleIsUploadBulkArticleModalOpen: (state) => ({
      ...state,
      isUploadBulkArticleModalOpen: !state.isUploadBulkArticleModalOpen,
    }),
  },
});

export const {
  toggleIsArticleCreateModalOpen,
  toggleActionableArticleId,
  toggleIsArticleEditable,
  toggleShowDiscardModal,
  toggleDuplicateAction,
  setArticleList,
  setArticleDetails,
  setArticleSliceState,
  toggleDeleteModal,
  setArticleAllData,
  toggleIsUploadBulkArticleModalOpen,
  toggleReachedModal,
  toggleActionModal,
  toggleResetFormData,
  setActionableArticleItem,
} = articleSlice.actions;
export default articleSlice.reducer;
