import { BgImg, CheckCircleOutlined, PhoneBGImg } from 'assests';
import { Button } from 'components/UI/Button';
import FormHeader from 'components/UI/FormHeader';
import SigninLogo from 'components/UI/SigninLogo';
import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const VerifyEmailSuccess = () => {
    const navigate = useNavigate();
    const firstName = localStorage.getItem("userName").split(" ")[0]
    const { isDarkMode } = useSelector((state) => state.themeSlice);

    return (
        <div className={`flex items-start justify-center w-full min-h-screen h-full ${isDarkMode ? " bg-global-surface" : "bg-global-theme"}`}>
            <div className={`flex items-center justify-center w-full pt-[48px] lg:pt-[96px] `}>
                <div className="z-[2] w-full flex flex-col items-center justify-center">
                    <div
                        className=" flex items-center justify-center w-full h-full "
                        style={{ pointerEvents: "none" }}
                    >
                        <img src={BgImg} alt="" className="fixed top-0 hidden md:block z-[-1]" />
                        <img src={PhoneBGImg} alt="" className="fixed top-[0px] block md:hidden z-[-1]" />
                    </div>
                    <div className="relative w-full px-4 md:p-0 md:w-[360px] ">
                        <div className="flex flex-col gap-8">
                            <div className="flex flex-col gap-6">
                                <SigninLogo>
                                    <CheckCircleOutlined width={28} height={28} />
                                </SigninLogo>
                                <FormHeader
                                    icon={false}
                                    title={`Welcome aboard, `}
                                    titleTwo={`${firstName}!`}
                                    subtitle={`Your journey towards enhanced garment supply chain management, starts now.`}
                                />
                            </div>
                            <Button
                                size="lg"
                                className="flex items-center w-full gap-2 text-base font-semibold"
                                onClick={() => navigate("/signin", { replace: true })}
                            >
                                Continue
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default VerifyEmailSuccess;