import React from "react";

const AllSet = React.forwardRef(
  (
    {
      width = "48",
      height = "48",
      viewBox = "0 0 48 48",
      fill = "none",

      className,
      ...props
    },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_ddddii_10304_34195)">
          <rect x="4" y="3" width="40" height="40" rx="8" fill="white" />
          <rect x="4.5" y="3.5" width="39" height="39" rx="7.5" stroke="#E4E7EC" />
          <g clipPath="url(#clip0_10304_34195)">
            <path
              d="M17.7501 31.3334V27.1667M17.7501 18.8334V14.6667M15.6667 16.75H19.8334M15.6667 29.25H19.8334M24.8334 15.5L23.3883 19.2574C23.1533 19.8684 23.0358 20.1739 22.853 20.4309C22.6911 20.6587 22.4921 20.8577 22.2643 21.0196C22.0073 21.2024 21.7018 21.3199 21.0908 21.5549L17.3334 23L21.0908 24.4452C21.7018 24.6802 22.0073 24.7977 22.2643 24.9804C22.4921 25.1424 22.6911 25.3414 22.853 25.5691C23.0358 25.8261 23.1533 26.1316 23.3883 26.7426L24.8334 30.5L26.2786 26.7426C26.5136 26.1316 26.6311 25.8261 26.8138 25.5691C26.9758 25.3414 27.1748 25.1424 27.4025 24.9804C27.6595 24.7977 27.965 24.6802 28.576 24.4452L32.3334 23L28.576 21.5549C27.965 21.3199 27.6595 21.2024 27.4025 21.0196C27.1748 20.8577 26.9758 20.6587 26.8138 20.4309C26.6311 20.1739 26.5136 19.8684 26.2786 19.2574L24.8334 15.5Z"
              stroke="#344054"
              strokeWidth="1.66667"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
        </g>
        <defs>
          <filter
            id="filter0_ddddii_10304_34195"
            x="0.25"
            y="0.5"
            width="47.5"
            height="47.5"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="1" />
            <feGaussianBlur stdDeviation="1" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.05 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_10304_34195"
            />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="1.25" />
            <feGaussianBlur stdDeviation="1.25" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.06 0"
            />
            <feBlend
              mode="normal"
              in2="effect1_dropShadow_10304_34195"
              result="effect2_dropShadow_10304_34195"
            />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="1.25" />
            <feGaussianBlur stdDeviation="1.875" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.1 0"
            />
            <feBlend
              mode="normal"
              in2="effect2_dropShadow_10304_34195"
              result="effect3_dropShadow_10304_34195"
            />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="1" />
            <feGaussianBlur stdDeviation="1" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.05 0"
            />
            <feBlend
              mode="normal"
              in2="effect3_dropShadow_10304_34195"
              result="effect4_dropShadow_10304_34195"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect4_dropShadow_10304_34195"
              result="shape"
            />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="-2" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.05 0"
            />
            <feBlend mode="normal" in2="shape" result="effect5_innerShadow_10304_34195" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feMorphology
              radius="1"
              operator="erode"
              in="SourceAlpha"
              result="effect6_innerShadow_10304_34195"
            />
            <feOffset />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.18 0"
            />
            <feBlend
              mode="normal"
              in2="effect5_innerShadow_10304_34195"
              result="effect6_innerShadow_10304_34195"
            />
          </filter>
          <clipPath id="clip0_10304_34195">
            <rect width="20" height="20" fill="white" transform="translate(14 13)" />
          </clipPath>
        </defs>
      </svg>
    );
  },
);

export default AllSet;
