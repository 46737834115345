import React from "react";

const Brand = React.forwardRef(
  (
    {
      width = "16",
      height = "16",
      viewBox = "0 0 17 16",
      fill = "none",
      strokeColor = "#667085",
      className,
      ...props
    },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.6665 14V10.4C10.6665 10.0266 10.6665 9.83995 10.5938 9.69734C10.5299 9.5719 10.4279 9.46991 10.3025 9.406C10.1599 9.33333 9.9732 9.33333 9.59984 9.33333H7.73317C7.3598 9.33333 7.17312 9.33333 7.03051 9.406C6.90507 9.46991 6.80308 9.5719 6.73917 9.69734C6.6665 9.83995 6.6665 10.0266 6.6665 10.4V14M13.3332 14V4.13333C13.3332 3.3866 13.3332 3.01323 13.1878 2.72801C13.06 2.47713 12.856 2.27316 12.6052 2.14532C12.3199 2 11.9466 2 11.1998 2H6.13317C5.38643 2 5.01307 2 4.72785 2.14532C4.47697 2.27316 4.27299 2.47713 4.14516 2.72801C3.99984 3.01323 3.99984 3.3866 3.99984 4.13333V14M14.6665 14H2.6665M6.99984 5.33333H7.0065M10.3332 5.33333H10.3398M7.33317 5.33333C7.33317 5.51743 7.18393 5.66667 6.99984 5.66667C6.81574 5.66667 6.6665 5.51743 6.6665 5.33333C6.6665 5.14924 6.81574 5 6.99984 5C7.18393 5 7.33317 5.14924 7.33317 5.33333ZM10.6665 5.33333C10.6665 5.51743 10.5173 5.66667 10.3332 5.66667C10.1491 5.66667 9.99984 5.51743 9.99984 5.33333C9.99984 5.14924 10.1491 5 10.3332 5C10.5173 5 10.6665 5.14924 10.6665 5.33333Z"
          stroke={strokeColor}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  },
);

Brand.displayName = "Brand";

export default Brand;
