import { AddMember, Cross } from "assests";
import DiscardModal from "components/Modal/DiscardModal";
import Modal from "components/UI/Modal";
import UpgradeComponent from "components/UI/UpgradeComponent";
import { useSelector } from "react-redux";
import ThirdStep from "./Steps/ThirdStep";
import SecondStep from "./Steps/SecondStep";
import FirstStep from "./Steps/FirstStep";
import { CreateUserStepList } from "utils/constant";

const AddNewUserForm = ({
  closeModal,
  control,
  showDiscardModal,
  setActiveStep,
  errors,
  getValues,
  register,
  handleSubmit,
  onDiscard,
  setValue,
  nextStep,
  clearErrors,
  actionableUserId,
  watch
}) => {
  const { activeStep } = useSelector((state) => state.createAccountSlice);
  return (
    <div className="relative flex items-center justify-center">
      <div className="w-full lg:w-[400px] relative  z-[2]  p-6 rounded-xl bg-white">
        <div className="flex  justify-between">
          <div>
            <UpgradeComponent>
              <AddMember />
            </UpgradeComponent>
          </div>
          <div >
            <Cross
              fillColor={"var(--color-global-paragraph-2)"}
              height={24}
              width={24}
              className={"w-6 h-6 cursor-pointer"}
              onClick={closeModal}
            />
          </div>
        </div>
        <div className="flex flex-col justify-between w-full h-full gap-12 ">
          <div className="grid gap-10">
            {activeStep === 1 && (
              <FirstStep
                {...{
                  register,
                  nextStep,
                  errors,
                  closeModal,
                  setActiveStep,
                }}
              />
            )}
            {activeStep === 2 && (
              <SecondStep
                {...{
                  control,
                  register,
                  nextStep,
                  setValue,
                  errors,
                  closeModal,
                  getValues,
                }}
              />
            )}
            {activeStep === 3 && (
              <ThirdStep
                {...{
                  register,
                  nextStep,
                  errors,
                  closeModal,
                  setValue,
                  getValues,
                  clearErrors,
                  handleSubmit,
                  actionableUserId
                }}
              />
            )}
          </div>
        </div>
        <div className="relative flex items-center h-[10.5px] justify-center">
          <div className="absolute bottom-[134px] lg:bottom-[90px]">
            <div className="flex space-x-4">
              {CreateUserStepList?.slice(0, watch("type") === "admin" ? 2 : 3)?.map((_, index) => (
                <div
                  key={index}
                  className={`w-[10px] h-[10px] flex rounded-full  ${activeStep === index + 1
                    ? "bg-global-primary"
                    : activeStep > index + 1
                      ? "bg-global-gray-soft"
                      : "bg-global-gray-soft"
                    }`}
                >
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <Modal open={showDiscardModal} close={() => null} title={""}>
        <DiscardModal onDiscard={onDiscard} />
      </Modal>
    </div>
  );
};

export default AddNewUserForm;
