import api from "config/axios";
export const getUsers = (query) => {
  const params = new URLSearchParams();
  Object.keys(query).forEach((key) => {
    if (query[key]) {
      if (key === "invitation_date" || key === "activation_date") {
        for (let i = 0; i < query[key].length; i++) {
          params.append(`${key}[]`, query[key][i]);
        }
      } else {
        params.append(key, query[key]);
      }
    }
  });
  // params.append("status", "accepted");
  return api.get(`/users?${params}`);
};

export const downloadUsersAsCSV = async (params) => {
  try {
    const response = await api.get(`/users`, {
      params: { ...params, download: "yes" },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const getUserDetailsByid = (id) => api.get(`/users/${id}`);
export const createUser = (data) => api.post("/users/invite", data);
export const updateRole = (data) => api.post(`/users/roleUpdate`, data);
export const deleteUser = (id) => {
  return api.delete(`/users/${id}`);
};
// export const updateUserDetailsById = (data) => {
//   console.log(data, "data");
//   return api.post(`/profile/update`, data);
// };

export const updateUserDetailsById = (data) =>
  api.post(`/profile/update`, data.body, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

export const updatePassword = (data) => api.post(`/profile/changePass`, data);
export const transferOwnership = (data) => api.post(`/users/transferOwnerShip`, data);

export const storePassword = (data) => {
  const requestData = {
    password: data.password,
    hash: data.hash,
  };
  return api.post("/users", requestData);
};

export const getAcceptInvitationDetails = (query) => {
  const params = new URLSearchParams();
  Object.keys(query).forEach((key) => {
    if (query[key]) {
      params.append(key, query[key]);
    }
  });
  return api.get(`/fetch-invitation?${params}`);
};

export const acceptStatus = (data) => {
  return api.post("/invitations/accept", data);
};

export const bulkDeleteUsers = async (data) => {
  try {
    const response = await api.post(`/users/bulk-delete`, data);
    return response;
  } catch (error) {
    throw error;
  }
};

export const deleteUserProfile = async () => {
  try {
    const response = await api.delete(`/profile/delete`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const validateUpdatedEmailOTP = async (data) => {
  try {
    const response = api.post("/email/verifyOTP-temp", data);
    return response;
  } catch (error) {
    throw error;
  }
};

export const removeTempEmail = async () => {
  try {
    const response = api.get("/profile/remove-temp");
    return response;
  } catch (error) {
    throw error;
  }
};

export const searchWorkspace = async () => {
  try {
    const response = api.get("/users/workspaces");
    return response;
  } catch (error) {
    throw error;
  }
};
