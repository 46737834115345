import React from "react";

const ResetPasswordSuccess = React.forwardRef(
  (
    {
      width = "28",
      height = "28",
      viewBox = "0 0 28 28",

      stroke = "none",
      strokeColor = "#344054",
      className,
      ...props
    },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, stroke, className }}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="check-circle">
          <path
            id="Icon"
            d="M8.74998 14L12.25 17.5L19.25 10.5M25.6666 14C25.6666 20.4434 20.4433 25.6667 14 25.6667C7.55666 25.6667 2.33331 20.4434 2.33331 14C2.33331 7.55672 7.55666 2.33337 14 2.33337C20.4433 2.33337 25.6666 7.55672 25.6666 14Z"
            stroke={strokeColor}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </svg>
    );
  },
);

export default ResetPasswordSuccess;
