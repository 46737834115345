import React from "react";

const upgradeIcon = React.forwardRef(
    (
        {
            width = "20",
            height = "20",
            viewBox = "0 0 20 20",
            fill = "none",
            strokeColor = "#535862",
            className,
            ...props
        },
        ref,
    ) => {
        return (
            <svg
                ref={ref}
                {...{ width, height, viewBox, fill, className }}
                {...props}
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M10.8333 1.66669L3.41121 10.5732C3.12054 10.922 2.9752 11.0964 2.97298 11.2437C2.97105 11.3718 3.02811 11.4936 3.12771 11.5741C3.24229 11.6667 3.46931 11.6667 3.92336 11.6667H10L9.16666 18.3334L16.5888 9.42681C16.8795 9.078 17.0248 8.9036 17.027 8.7563C17.0289 8.62826 16.9719 8.50643 16.8723 8.42594C16.7577 8.33335 16.5307 8.33335 16.0766 8.33335H10L10.8333 1.66669Z"
                    stroke={strokeColor}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        );
    },
);

upgradeIcon.displayName = "upgradeIcon";

export default upgradeIcon;
