import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { login } from "services/auth.services";
import { acceptStatus } from "services/user.services";
import { setAuthentication, setLoginError } from "store/authenticationSlice";
import { loginSchema } from "validation/auth.validation";
import useCustomToaster from "./useCustomToaster";
import Cookies from "js-cookie";
import { current } from "@reduxjs/toolkit";
/**
 * Custom hook for handling user login functionality.
 *
 * @return {Object} Returns an object containing form state, errors, loading state, and login error message.
 */
const useLogin = () => {
  // Redux dispatch function
  const dispatch = useDispatch();
  // React Query client for cache invalidation
  const queryClient = useQueryClient();

  // React Router navigate function
  const navigate = useNavigate();

  //define local state
  const [loginPasswordInputType, setLoginPasswordInputType] = useState("password");

  // React Hook Form for handling form state and validation
  const { showCustomToast } = useCustomToaster();

  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
      password: "",
      remember_me: false,
    },
    resolver: zodResolver(loginSchema), // Zod form validation resolver
  });

  // React Query mutation for handling API request
  const mutation = useMutation({
    mutationKey: ["auth", "login"],
    mutationFn: login, // Auth service login function

    onMutate: () => {
      showCustomToast(
        {
          title: "Validating credential...",
        },
        "loginLoading",
      );
    },

    onSuccess: handleSuccess,
    onError: handleError,
    onSettled: () => {
      toast.dismiss("loginLoading");
    },
  });

  //if hash exist

  /**
   * Submit handler for the login form.
   *
   * @param {Object} data - Form data object.
   */
  const handleSubmitFn = (data) => mutation.mutate(data);
  const acceptMutation = useMutation({
    mutationKey: ["user", "acceptinvitation"],
    mutationFn: ({ hash }) => {
      return acceptStatus({ hash });
    },
    onSuccess: (res) => {
      const data = res.data;

      showCustomToast({
        title: "Invitation has been accepted",
      });

      queryClient.invalidateQueries("protectedResource");
    },
    onError: (error) => {
      const errormessage = error.response?.data?.message || error.message || "Something went wrong";
      showCustomToast({
        title: errormessage,
      });
    },
  });

  /**
   * Success handler for login API request.
   *
   * @param {Object} response - API response object.
   */
  function handleSuccess(response) {
    const urlParams = new URLSearchParams(window.location.search);
    const hash = urlParams.get("hash");
    const storedRedirectUrl = localStorage.getItem("grant_access_url");
    if (hash) {
      acceptMutation.mutate({ hash });
    } else {
      if (storedRedirectUrl) {
        window.location.href = storedRedirectUrl;
        localStorage.removeItem("grant_access_url"); // Clean up after redirection
      } else {
        navigate("/");
      }
    }
    const data = response.data;
    toast.dismiss("loginLoading");
    showCustomToast({
      title: data.message,
    });
    Cookies.set("showSidebarMsg", "true", { expires: 7 });
    localStorage.setItem("auth", data.token);
    localStorage.setItem("expiration", data.expiration);
    localStorage.setItem("refresh_token", data.refresh_token);
    localStorage.setItem("workspaces", JSON.stringify(data.workspaces));
    localStorage.setItem("user", JSON.stringify(data.user));
    localStorage.setItem("subscription", JSON.stringify(data.subscription));
    localStorage.setItem("subscriptionBtn", JSON.stringify(data.subscription));
    dispatch(setAuthentication(data));
    reset();
    queryClient.invalidateQueries("protectedResource");

    if (storedRedirectUrl) {
      window.location.href = storedRedirectUrl;
      localStorage.removeItem("grant_access_url"); // Clean up after redirection
    } else {
      navigate("/");
    }
  }

  /**
   * Error handler for login API request.
   *
   * @param {Object} error - Error object.
   */
  function handleError(error) {
    toast.dismiss("loginLoading");
    if (error?.response?.data?.errors) {
      const errorMessages = Object.values(error?.response?.data?.errors).flat();
      errorMessages.forEach((errorMessage) => {
        showCustomToast({
          title: errorMessage,
        });
      });
    } else {
      const errorMessage = error?.response?.data?.message || error.message;
      showCustomToast({
        title: errorMessage,
      });
    }
  }

  const togglePasswordType = () => {
    if (loginPasswordInputType === "password") {
      setLoginPasswordInputType("text");
    } else {
      setLoginPasswordInputType("password");
    }
  };

  // Return form state, errors, loading state, and login error message
  return {
    errors,
    isLoading: mutation.isPending,
    loginPasswordInputType,
    loginErrorMessage: useSelector((state) => state.authenticationSlice.loginErrorMessage),
    togglePasswordType,
    register,
    handleSubmit: handleSubmit(handleSubmitFn),
    watch,
  };
};

export default useLogin;
