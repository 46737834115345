import { useQuery } from "@tanstack/react-query";
import useUserCurrentSubscription from "hooks/useUserCurrentSubscription";
import React, { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentWorkspacePlan } from "services/subscription.services";
import { setCheckPlanLimitation, setRefetchBillingData, setWorkspacePlan } from "store/billingSlice";

const BillingWrapper = ({ children }) => {
  // Custom hooks
  const dispatch = useDispatch();
  // Global state or redux state
  const { currentWorkspace } = useSelector((state) => state.authenticationSlice);

  const { refetchBillingData } = useSelector((state) => state.billingSlice);
  useUserCurrentSubscription()
  // Queries
  const {
    data: subscriptionQueryData,
    isLoading: isLoadingSubsQuery,
    refetch,
    isRefetching,
    error: subscriptionFetchError,
  } = useQuery({
    queryKey: ["workspaceSubscription", currentWorkspace],
    queryFn: async () => {
      const workspaceId = parseInt(currentWorkspace?.workspace_id);
      return getCurrentWorkspacePlan(workspaceId);
    },
    enabled: !!currentWorkspace,
    select: (res) => res.data,
  });

  useEffect(() => {
    if (refetchBillingData && !isRefetching) {
      refetch();
    }
    return () => {
      dispatch(setRefetchBillingData(false));
    };
  }, [refetchBillingData, isRefetching, refetch]);

  useEffect(() => {
    if (subscriptionQueryData && !isLoadingSubsQuery && !subscriptionFetchError) {
      const userSubscriptionQueryData = subscriptionQueryData.data;
      const UserPlanLimitation = {
        canCreateOrder:
          parseInt(userSubscriptionQueryData["orders_count"]) <
          parseInt(userSubscriptionQueryData.package["order_lines"]),
        canCreateUser:
          parseInt(userSubscriptionQueryData["user_count"]) < parseInt(userSubscriptionQueryData.package["users"]),
        canCreateArticle:
          parseInt(userSubscriptionQueryData["articles_count"]) <
          parseInt(userSubscriptionQueryData.package["articles"]),
        canCreatePartner:
          parseInt(userSubscriptionQueryData["partner_links_count"]) <
          parseInt(userSubscriptionQueryData.package["partner_links"]),
      };
      dispatch(setCheckPlanLimitation(UserPlanLimitation));
      dispatch(setWorkspacePlan(userSubscriptionQueryData));
    } else {
      dispatch(setWorkspacePlan(null));
      dispatch(setCheckPlanLimitation({
        canCreateOrder: false,
        canCreateUser: false,
        canCreateArticle: false,
        canCreatePartner: false,
      }));
    }
  }, [subscriptionQueryData, isLoadingSubsQuery, subscriptionFetchError]);

  return <Fragment>{children}</Fragment>;
};

export default BillingWrapper;
