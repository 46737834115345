import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import UpgradePlan from "components/UpgradePlan/UpgradePlan";
import useUser from "hooks/useUser";
import LoginLayout from "layout/LoginLayout";
import MainLayout from "layout/MainLayout";
import Agent from "pages/Agent/Agent";
import DashboardMain from "pages/Dashboard";
import AccountDashboard from "pages/Dashboard/AccountDashboard";
import AccountOrders from "pages/Dashboard/EmptyAccountOrders";
import RetailerDetail from "pages/Dashboard/RetailerDetail";
import ForgetPassword from "pages/ForgetPassword/ForgetPassword";
import ResetPassword from "pages/ForgetPassword/ResetPassword";
import ResetPasswordEmailSentSuccess from "pages/ForgetPassword/ResetPasswordEmailSentSuccess";
import ResetPasswordSuccess from "pages/ForgetPassword/ResetPasswordSuccess";
import HandleInvitation from "pages/HandleInvitation";
import NotFound from "pages/NotFound";
import Retailer from "pages/Retailer/Retailer";
import DeactivateSuccess from "pages/Settings/CompanyProfileList/DeactivateSuccess";
import SettingsMainPage from "pages/Settings/ProfileSettings/SettingsMainPage";
import AgentDetailPage from "pages/Settings/Workspace/AccountDetailsPage/AgentDetailPage";
import Signin from "pages/Signin/Signin";
import Signup from "pages/Signup/Signup";
import Supplier from "pages/Supplier/Supplier";
import VerifyOtp from "pages/VerifyOtp";
import VerifyEmailSuccess from "pages/VerifyOtp/VerifyEmailSuccess";
import Articles from "pages/Workspace/Article/Articles";
import AuditsMain from "pages/Workspace/Compliancy/Audits";
import CertificatesMain from "pages/Workspace/Compliancy/Certificates";
import Order from "pages/Workspace/Order/Order";
import OrderDetailsPage from "pages/Workspace/Order/OrderDetails/OrderDetailsPage";
import ReportMain from "pages/Workspace/Report";
import RetailerDetailPage from "pages/Workspace/Retailer/RetailerDetailPage";
import SupplierDetailPage from "pages/Workspace/Supplier/SupplierDetailPage";
import Template from "pages/Workspace/Template/Template";
import LoginAs from "pages/loginas/LoginAs";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import "rc-slider/assets/index.css";
const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}`);

function App() {
  const { user, token } = useSelector((state) => state.authenticationSlice);
  const { userMutation } = useUser();
  const { isDarkMode } = useSelector((state) => state.themeSlice);

  const location = useLocation();
  useEffect(() => {
    document.documentElement.classList.toggle("dark", isDarkMode);
  }, [isDarkMode]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    if (token && !user) {
      userMutation.mutate();
    }
  }, [user, token]);


  return (
    <>
      <Routes>
        <Route path="/" element={<MainLayout />}>
          <Route index element={<DashboardMain />} />
        </Route>
        <Route path="/workspace/:workspaceId" element={<MainLayout />}>
          <Route index element={<Navigate to={"dashboard"} />} />
          <Route path="workspaces" element={<DashboardMain />} />
          <Route path="report" element={<ReportMain />} />
          <Route path="certificates" element={<CertificatesMain />} />
          <Route path="audits" element={<AuditsMain />} />
          <Route path="accountorders" element={<AccountOrders />} />
          <Route path="retailers" element={<Retailer />} />
          <Route path="orders" element={<Order />} />
          <Route path="orders/:orderId" element={<OrderDetailsPage />} />
          <Route path="agent-details/:agentId" element={<AgentDetailPage />} />
          <Route path="retailer-details/:retailerId" element={<RetailerDetailPage />} />
          <Route path="supplier-details/:supplierId/:supplier" element={<SupplierDetailPage />} />
          <Route path="articles" element={<Articles />} />
          <Route path="agents" element={<Agent />} />
          <Route path="suppliers" element={<Supplier />} />
          <Route path="setting" element={<SettingsMainPage />} />
        </Route>
        <Route
          path="/plans/upgrade"
          element={
            <Elements stripe={stripePromise}>
              <UpgradePlan />
            </Elements>
          }
        />
        <Route
          exact
          path="/signin"
          element={
            <LoginLayout>
              <Signin />
            </LoginLayout>
          }
        />
        <Route
          path="/forget-password"
          element={
            <LoginLayout>
              <ForgetPassword />
            </LoginLayout>
          }
        />
        <Route
          path="/sent-email-success/:email"
          element={
            <LoginLayout>
              <ResetPasswordEmailSentSuccess />
            </LoginLayout>
          }
        />
        <Route
          path="/reset-password"
          element={
            <LoginLayout>
              <ResetPassword />
            </LoginLayout>
          }
        />
        <Route
          path="/reset-password-success"
          element={
            <LoginLayout>
              <ResetPasswordSuccess />
            </LoginLayout>
          }
        />
        <Route path="/invitation" element={<HandleInvitation />} />
        <Route path="/confirm-deactive" element={<DeactivateSuccess />} />
        <Route path="/accountdashboard" element={<AccountDashboard />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/verify-email" element={<VerifyOtp />} />
        <Route path="/verify-email-success" element={<VerifyEmailSuccess />} />
        <Route path="/login-as" element={<LoginAs />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
}

export default App;
