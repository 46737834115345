import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { getCertificateTypeList } from "services/certificate-type.services";
import {
  createCertificate,
  deleteCertificate,
  getCertificateDetailsById,
  updateCertificates,
} from "services/certificate.services";
import { certifiedByList } from "services/certified-by.services";
import {
  setCertificateDetails,
  toggleActionableCertificateId,
  toggleDeleteModal,
  toggleisCertificateCreateModalOpen,
  toggleisCertificateEditable,
} from "store/certificateSlice";
import { formattedDateIntoObject } from "utils/helper";
import { certificateSchema } from "validation/certificate.validation";
import useCustomToaster from "./useCustomToaster";
const useCertificate = ({ workspaceRefetch, supplierId }) => {
  const queryClient = useQueryClient();
  const { showCustomToast } = useCustomToaster();
  const {
    actionableCertificateId,
    isCertificateEditable,
    certificateDetails,
    certificateList,
    deleteModal,
    isCertificateCreateModalOpen
  } = useSelector((state) => state.certificateSlice);
  //file upload
  const [certificateType, setCertificateType] = useState([]);
  const [certifiedBy, setCertifiedBy] = useState([]);
  const [isDragOver, setIsDragOver] = useState(false);
  const [fileData, setFileData] = useState([]);
  const [showFileData, setShowFileData] = useState([]);
  const [imagePreviews, setImagePreviews] = useState([]);
  const [hasImage, setHasImage] = useState(false);

  const dispatch = useDispatch();
  const {
    control,
    register,
    reset,
    trigger,
    setValue,
    getValues,
    setError,
    watch,
    clearErrors,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      certificate_type_id: "",
      certified_by_id: "",
      certificate_id: "",
      issued_at: { startDate: "", endDate: "" },
      expired_at: { startDate: "", endDate: "" },
      workspace_id: parseInt(supplierId),
    },
    resolver: zodResolver(certificateSchema),
  });

  const certificateQuery = useQuery({
    queryKey: ["certificate", "get", actionableCertificateId],
    queryFn: () => getCertificateDetailsById(actionableCertificateId),
    enabled: !!actionableCertificateId,
  });

  const certificateTypeQuery = useQuery({
    queryKey: ["certificateType"],
    queryFn: getCertificateTypeList,
    enabled: !!supplierId,
  });
  const certifiedByQuery = useQuery({
    queryKey: ["certifiedBy"],
    queryFn: certifiedByList,
    enabled: !!supplierId,
  });

  const closeModal = () => {
    if (isCertificateEditable) {
      dispatch(toggleisCertificateEditable());
    }
    if (actionableCertificateId) {
      dispatch(toggleActionableCertificateId());
    }
    if (isCertificateCreateModalOpen) {
      dispatch(toggleisCertificateCreateModalOpen());
    }
  }

  const createMutation = useMutation({
    mutationKey: ["certificate", "create"],
    mutationFn: (data) => createCertificate(data),
    onMutate: () => {
      showCustomToast(
        {
          title: "Creating certificate...",
        },
        "loadingCreateCertificate",
      );
    },
    onSuccess: (res) => {
      toast.dismiss("loadingCreateCertificate");
      dispatch(setCertificateDetails(res.data.certificate));
      reset();
      clearErrors();
      workspaceRefetch();
      setFileData([]);
      setImagePreviews([]);
      setShowFileData([]);
      setHasImage(false);
      closeModal();
      showCustomToast({ title: "Certificate created successfully" });
    },
    onError: (error) => {
      toast.dismiss("loadingCreateCertificate");
      if (error.response?.data?.errors) {
        Object.entries(error.response.data.errors).forEach(([field, messages]) => {
          setError(field, { type: "server", message: messages[0] });
        });
      } else {
        showCustomToast({
          title: error.response?.data?.message || error.message || "Something went wrong",
        });
      }
    },
    onSettled: () => {
      toast.dismiss("loadingCreateCertificate");
      queryClient.invalidateQueries({ queryKey: ["certificate"] });
    },
  });

  const updateMutation = useMutation({
    mutationKey: ["certificate", "updateCertificate"],
    mutationFn: (data) => updateCertificates(data),
    onMutate: () => {
      showCustomToast({ title: "Upating the Certificate..." }, "updateCertificateLoadingToast");
    },
    onSuccess: (res) => {
      toast.dismiss("updateCertificateLoadingToast");
      reset();
      clearErrors();
      workspaceRefetch();
      setImagePreviews([]);
      setFileData([]);
      setShowFileData([]);
      setHasImage(false);
      closeModal()
      showCustomToast({ title: "Certificate updated successfully" });
      queryClient.invalidateQueries({ queryKey: ["certificate", "updateCertificate"] });
    },
    onError: (error) => {
      toast.dismiss("updateCertificateLoadingToast");
      if (error.response?.data?.errors) {
        Object.entries(error.response.data.errors).forEach(([field, messages]) => {
          setError(field, { type: "server", message: messages[0] });
        });
      } else {
        showCustomToast({
          title: error.response?.data?.message || error.message || "Something went wrong",
        });
      }
    },
    onSettled: () => {
      toast.dismiss("updateCertificateLoadingToast");
      queryClient.invalidateQueries({ queryKey: ["certificate"] });
    },
  });

  const handleClickEdiable = () => {
    if (!isCertificateEditable) {
      dispatch(toggleisCertificateEditable());
    }
  };

  const deleteCertificateMutation = useMutation({
    mutationKey: ["certificate", "delete"],
    mutationFn: (certificateId) => deleteCertificate(certificateId),
    onMutate: () => {
      showCustomToast({ title: "Deleting..." }, "deleteCertificate");
    },
    onSuccess: () => {
      toast.dismiss("deleteCertificate");
      showCustomToast({ title: "certificate deleted successfully" });
      dispatch(toggleDeleteModal());
      workspaceRefetch();
      if (actionableCertificateId) {
        dispatch(toggleActionableCertificateId());
      }
      queryClient.invalidateQueries(["certificates", "get", "delete"]);
    },
    onError: (error) => {
      toast.dismiss("deleteCertificate");
      showCustomToast({ title: error.response.data.message || error.message });
    },
    onSettled: () => {
      toast.dismiss("deleteCertificate");
    },
  });

  const handleOnDelete = () => {
    if (!actionableCertificateId) return;
    if (!deleteModal) {
      dispatch(toggleDeleteModal());
    }
  };

  const setDefaultFormValue = async (details) => {
    if (!details && !actionableCertificateId) return;
    if (details) {
      const {
        certificate_type_id,
        certificate_type,
        certified_by_id,
        certified_by,
        certificate_id,
        issued_at,
        expired_at,
        cert_files,
        workspace_id,
        files,
      } = details;

      setValue("issued_at", formattedDateIntoObject(issued_at));
      setValue("expired_at", formattedDateIntoObject(expired_at));
      setValue("certificate_id", certificate_id);
      if (certificate_type || certificate_type_id) {
        setValue("certificate_type_id", {
          label: certificate_type?.name,
          value: certificate_type?.id,
        });
      }

      if (certified_by || certified_by_id) {
        setValue("certified_by_id", {
          label: certified_by?.name,
          value: certified_by?.id,
        });
      }

      setValue("workspace_id", parseInt(workspace_id));
      if (cert_files && cert_files.length > 0) {
        setImagePreviews(cert_files);
        setValue("cert_files", cert_files);
        setFileData(cert_files);
        if (files && files.length > 0) {
          const transformedFiles = files?.map((file) => ({
            url: file.url,
            name: file.file_name,
            size: file.file_size * 1024,
          }));
          setShowFileData(transformedFiles);
          setHasImage(true);
        }
      } else {
        setImagePreviews([]);
        setFileData([]);
        setShowFileData([]);
        setHasImage(false);
      }
    }
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragOver(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragOver(false);
    onFileDropOrChange(e);
  };

  const onFormSubmit = (data) => {
    if (getValues("issued_at") && getValues("expired_at")) {
      if (
        new Date(getValues("expired_at").startDate) < new Date(getValues("issued_at").startDate)
      ) {
        setError("expired_at.startDate", {
          type: "custom",
          message: "Check for the issue date",
        });
        return;
      } else {
        const processedData = processData(data);
        if (actionableCertificateId) {
          updateMutation.mutate({ body: processedData, id: actionableCertificateId });
        } else {
          createMutation.mutate(processedData);
        }
      }
    }
  };

  const processData = (data) => {
    const processedData = Object.fromEntries(
      Object.entries(data).map(([key, value]) => {
        if (typeof value === "object" && value !== null && "label" in value && "value" in value) {
          return [key, value.value];
        }
        if (
          typeof value === "object" &&
          value !== null &&
          "startDate" in value &&
          "endDate" in value
        ) {
          return [key, value.startDate];
        }
        return [key, value];
      }),
    );
    const formData = new FormData();
    Object.entries(processedData).forEach(([key, value]) => formData.append(key, value));
    if (fileData && fileData.length > 0) {
      fileData.forEach((file, index) => {
        formData.append(`cert_files[${index}]`, file); // Append each file individually with a dynamic key
      });
    }
    if (actionableCertificateId) {
      if (hasImage && fileData && fileData.length === 0) {
        const formData = new FormData();
        formData.append(`cert_files[]`, []);
      } else if (fileData && fileData.length > 0) {
        const formData = new FormData();
        fileData.forEach((file, index) => {
          formData.append(`cert_files[${index}]`, file); // Append each file individually with a dynamic key
        });
      }
    }

    return formData;
  };

  const onError = (error) => {
    console.log(error);
  };

  const onFileDropOrChange = (e) => {
    const MAX_FILE_SIZE = 10;
    let files = null;
    if (isDragEvent(e)) {
      files = e.dataTransfer.files;
    } else {
      files = e.target.files;
    }

    if (files && files.length > 0) {
      const newFiles = Array.from(files); // Convert FileList to array
      const validFiles = []; // To store valid files only
      const previewPromises = newFiles.map((file, index) => {
        return new Promise((resolve, reject) => {
          // Check file type
          if (file.type !== "application/pdf" && !file.type.startsWith("image/")) {
            showCustomToast({ title: "Please drop a PDF or image file." });
            reject("Invalid file type");
            return;
          }
          // Check file size
          if (file.size > MAX_FILE_SIZE * 1024 * 1024) {
            showCustomToast({ title: "File size exceeds 10MB limit." });
            reject("File size exceeds limit");
            return;
          }
          // Add valid files to the list
          validFiles.push(file);

          // Generate image preview if it's an image file
          if (file.type.startsWith("image/")) {
            const reader = new FileReader();
            reader.onloadend = () => {
              resolve(reader.result);
            };
            reader.onerror = () => {
              reject("Error reading file");
            };
            reader.readAsDataURL(file);
          } else {
            // Resolve with null for non-image files
            resolve(null);
          }
        });
      });

      Promise.all(previewPromises)
        .then((previews) => {
          setValue("cert_files", [...previews]);
          setImagePreviews((prevPreviews) => [...prevPreviews, ...previews]);
          setFileData((prevFileData) => [...prevFileData, ...validFiles]);
          setShowFileData((prevShowFileData) => [...prevShowFileData, ...validFiles]);
          // Clear the input value to allow re-selection of the same files
          if (e.target && e.target.value) {
            e.target.value = null;
          }
        })
        .catch((error) => {
          console.error("Error processing files:", error);
        });
    }
  };

  const handleCertFileDelete = (index) => {
    // Remove the image preview and corresponding file data
    const updatedPreviews = [...imagePreviews];
    const updatedFileData = [...fileData];
    const updatedShowFileData = [...showFileData];
    updatedPreviews.splice(index, 1);
    updatedFileData.splice(index, 1);
    updatedShowFileData.splice(index, 1);
    setImagePreviews(updatedPreviews);
    setFileData(updatedFileData);
    setValue("cert_files", updatedFileData);
    setShowFileData(updatedShowFileData);
  };

  const isDragEvent = (event) => {
    return "dataTransfer" in event;
  };
  useEffect(() => {
    if (certificateTypeQuery) {
      const result = certificateTypeQuery?.data?.data.certificate_types;
      if (result && result?.length > 0) {
        let modifiedList = result?.map((item) => ({
          label: item?.name,
          value: item?.id,
        }));

        setCertificateType(modifiedList);
      }
    }
  }, [certificateTypeQuery?.data]);

  useEffect(() => {
    if (certifiedByQuery) {
      const result = certifiedByQuery?.data?.data?.certified_by;
      if (result && result?.length > 0) {
        let modifiedList = result?.map((item) => ({
          label: item?.name,
          value: item?.id,
        }));
        setCertifiedBy(modifiedList);
      }
    }
  }, [certifiedByQuery?.data]);

  useEffect(() => {
    if (
      actionableCertificateId &&
      certificateQuery &&
      certificateQuery.isSuccess &&
      !certificateQuery.isError
    ) {
      const result = certificateQuery?.data?.data?.certificate;
      if (result) {
        dispatch(setCertificateDetails(result));
        setDefaultFormValue(result);
      }
    }
  }, [certificateQuery?.data, actionableCertificateId]);

  return {
    control,
    watch,
    register,
    reset,
    trigger,
    setValue,
    getValues,
    setError,
    clearErrors,
    handleSubmit,
    onFormSubmit,
    fileData,
    certifiedBy,
    setFileData,
    onError,
    errors,
    isDragOver,
    handleDragEnter,
    handleDragLeave,
    handleDrop,
    onFileDropOrChange,
    imagePreviews,
    handleCertFileDelete,
    certificateType,
    isCertificateEditable,
    actionableCertificateId,
    isPending: createMutation.isPending || updateMutation.isPending,
    certificateDetails,
    handleClickEdiable,
    handleOnDelete,
    deleteCertificateMutation,
    showFileData,
    isCertificateDetailsLoading: certificateQuery.isLoading,
    isLoading: certificateTypeQuery.isLoading || certifiedByQuery.isLoading,
  };
};

export default useCertificate;
