import { ArrowsDown, Cross } from "assests";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getCircleTransparencyScoreReduceVariant } from "utils/helper";
import { Button } from "./Button";
import CircleProgressReduceBar from "./CircleProgressReduceBar";
import UpgradeComponent from "./UpgradeComponent";

const ReduceComponent = ({ closeModal }) => {
  const { currentPlan } = useSelector((state) => state.billingSlice);
  const { selectedPlanData } = useSelector((state) => state.subscriptionSlice);
  const { currentWorkspace } = useSelector((state) => state.authenticationSlice);
  const [currentPlanLimitCount, setCurrentPlanLimitCount] = useState(null);
  const [totalPlanLimitCount, setTotalPlanLimitCount] = useState(null);
  const calculatePercentage = (part, whole) => {
    // Check for invalid inputs (non-numeric or zero whole)
    if (isNaN(part) || isNaN(whole) || whole === 0) {
      return "Invalid input: Please provide valid numbers and ensure the whole is not zero.";
    }

    // Calculate percentage
    const percentage = (part / whole) * 100;

    // Round to two decimal places (optional, adjust as needed)
    return percentage.toFixed(2);
  };

  useEffect(() => {
    if (currentPlan && selectedPlanData) {
      const { orders_count, articles_count, partner_links_count, user_count } = currentPlan;
      const { order_lines, articles, users, partner_links } = selectedPlanData;
      setTotalPlanLimitCount({
        order_lines,
        articles,
        users,
        partner_links,
      });
      setCurrentPlanLimitCount({
        orders_count,
        articles_count,
        partner_links_count,
        user_count,
      });
    }
  }, [currentPlan, selectedPlanData]);

  return (
    <div className="w-full lg:max-w-[600px] relative z-[2] bg-white p-6 rounded-xl border border-border-divider-stroke">
      <div className="flex flex-row">
        <div className="mr-4">
          <UpgradeComponent>
            <ArrowsDown />
          </UpgradeComponent>
        </div>
        <div className="flex relative">
          <div className="flex flex-col gap-2">
            <h4 className="text-base lg:text-lg font-semibold text-text-primary leading-7">
              Resource Limit Exceeded
            </h4>
            <p className="text-sm lg:text-base  text-global-paragraph leading-5">
              You are currently exceeding the resource limits for the selected plan. Please reduce
              your usage before downgrading to the PRO plan.
            </p>
          </div>
          <div className="absolute top-0 right-0">
            <Cross width={24} height={24} className="cursor-pointer w-6 h-6" onClick={closeModal} />
          </div>
        </div>
      </div>
      {currentPlan && (
        <div className="max-w-[360px] h-[360px] mx-0 lg:mx-auto m-6 lg:m-10">
          <div className="grid gap-6 lg:gap-10">
            <div className="grid grid-cols-2 gap-6 lg:gap-10">
              <div className="col-span-1">
                <CircleProgressReduceBar
                  title={"Orderlines"}
                  subText={`${currentPlanLimitCount?.orders_count}/${totalPlanLimitCount?.order_lines}`}
                  currentProgress={calculatePercentage(
                    currentPlanLimitCount?.orders_count,
                    totalPlanLimitCount?.order_lines,
                  )}
                  strokeColorVariable={getCircleTransparencyScoreReduceVariant(
                    calculatePercentage(
                      currentPlanLimitCount?.orders_count,
                      totalPlanLimitCount?.order_lines,
                    ),
                  )}
                />
              </div>
              <div className="col-span-1">
                <CircleProgressReduceBar
                  title={"Articles"}
                  subText={`${currentPlanLimitCount?.articles_count}/${totalPlanLimitCount?.articles}`}
                  currentProgress={calculatePercentage(
                    currentPlanLimitCount?.articles_count,
                    totalPlanLimitCount?.articles,
                  )}
                  strokeColorVariable={getCircleTransparencyScoreReduceVariant(
                    calculatePercentage(
                      currentPlanLimitCount?.articles_count,
                      totalPlanLimitCount?.articles,
                    ),
                  )}
                />
              </div>
            </div>
            <div className="grid grid-cols-2 gap-6 lg:gap-10">
              <div className="col-span-1">
                <CircleProgressReduceBar
                  title={"Partner links"}
                  subText={`${currentPlanLimitCount?.partner_links_count}/${totalPlanLimitCount?.partner_links}`}
                  currentProgress={calculatePercentage(
                    currentPlanLimitCount?.partner_links_count,
                    totalPlanLimitCount?.partner_links,
                  )}
                  strokeColorVariable={getCircleTransparencyScoreReduceVariant(
                    calculatePercentage(
                      currentPlanLimitCount?.partner_links_count,
                      totalPlanLimitCount?.partner_links,
                    ),
                  )}
                />
              </div>
              <div className="col-span-1">
                <CircleProgressReduceBar
                  title={"Members"}
                  subText={`${currentPlanLimitCount?.user_count}/${totalPlanLimitCount?.users}`}
                  currentProgress={calculatePercentage(
                    currentPlanLimitCount?.user_count,
                    totalPlanLimitCount?.users,
                  )}
                  strokeColorVariable={getCircleTransparencyScoreReduceVariant(
                    calculatePercentage(
                      currentPlanLimitCount?.user_count,
                      totalPlanLimitCount?.users,
                    ),
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="flex justify-center lg:justify-end flex-col lg:flex-row gap-3 pt-4 lg:pt-8">
        <Button
          variant="secondary"
          className="px-4 py-2.5 w-full lg:w-[112px] h-11 text-base font-semibold"
          type="button"
          onClick={closeModal}
        >
          Cancel
        </Button>
        <Link to={`/workspace/${currentWorkspace?.workspace_id}/orders`}>
          <Button
            variant="primary"
            className="px-4 py-2.5 w-full lg:w-[144px] h-11 text-base font-semibold whitespace-nowrap"
          >
            Reduce Usage
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default ReduceComponent;
