// GreetingText.js
import { useSelector } from "react-redux";

const GreetingText = () => {
  const { user } = useSelector((state) => state.authenticationSlice);
  const names = user?.name?.split(" ");

  const getGreetingMessage = () => {
    const time = new Date().getHours();
    const namePart = names && names[0]; // Get the first name

    if (time >= 0 && time < 12) {
      return `Good Morning, ${namePart}`;
    } else if (time >= 12 && time < 18) {
      return `Good Afternoon, ${namePart}`;
    } else {
      return `Good Evening, ${namePart}`;
    }
  };

  return getGreetingMessage();
};

export default GreetingText;
