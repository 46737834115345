import api from "config/axios";
export const supplier = (data) => api.post("/invitations", data);

export const getSuppliers = async (query) => {
  const params = new URLSearchParams();
  try {
    Object.keys(query).forEach((key) => {
      const value = query[key];
      if (Array.isArray(value)) {
        value.forEach((item) => {
          params.append(`${key}[]`, item);
        });
      } else if (value) {
        params.append(key, value);
      }
    });
    const response = await api.get(`/suppliers?${params}`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const downloadSupplierAsCSV = async (params) => {
  try {
    const response = await api.get(`/suppliers`, {
      params: { ...params, download: "yes" },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const getAllSuppliers = async (query) => {
  const params = new URLSearchParams();
  try {
    Object.keys(query).forEach((key) => {
      if (query[key]) {
        params.append(key, query[key]);
      }
    });
    const response = await api.get(`/all-suppliers?${params}`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const getOrderSuppliers = async (query) => {
  const params = new URLSearchParams();
  try {
    Object.keys(query).forEach((key) => {
      if (query[key]) {
        params.append(key, query[key]);
      }
    });
    const response = await api.get(`/order-suppliers?${params}`);
    return response;
  } catch (error) {
    throw error;
  }
};


export const getSupplierCompany = async (query) => {
  const params = new URLSearchParams();
  try {
    Object.keys(query).forEach((key) => {
      if (query[key]) {
        params.append(key, query[key]);
      }
    });
    const response = await api.get(`/suppliers-filter/company?${params}`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const bulkDeleteSuppliers = async (data) => {
  try {
    const response = await api.post(`/suppliers/bulk-delete`, { suppliers: data });
    return response;
  } catch (error) {
    throw error;
  }
};

export const inviteSupplierForOder = (data) => api.post(`/orders/add-supplier`, data);
