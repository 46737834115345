import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import configureAppStore from "./store/configureStore";
import ErrorFallback from "components/Error/ErrorFallback";
import { ErrorBoundary } from "react-error-boundary";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import "./styles/globals.css";
import "./styles/theme.css";
import "react-circular-progressbar/dist/styles.css";
import "react-phone-number-input/style.css";
import { Portal } from "@headlessui/react";
import { Toaster } from "react-hot-toast";
import { GoogleMapsProvider } from "contexts/GoogleMapsContext";

// ==============================|| REACT DOM RENDER  ||============================== //
const container = document.getElementById("root");
const root = createRoot(container);
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
    },
    mutations: {
      retry: 1,
    },
  },
});
const { store, persistor } = configureAppStore();

root.render(
  <ErrorBoundary FallbackComponent={ErrorFallback}>
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <PersistGate loading={null} persistor={persistor}>
          <BrowserRouter basename="/">
            <Portal>
              <Toaster
                position="bottom-right"
                toastOptions={{
                  duration: 3000,
                }}
              />
              <GoogleMapsProvider>
                <App />
              </GoogleMapsProvider>
            </Portal>
          </BrowserRouter>
        </PersistGate>
      </QueryClientProvider>
    </Provider>
  </ErrorBoundary>,
);

// serviceWorker.unregister();

reportWebVitals();
