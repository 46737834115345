const SigninLogo = ({ children, fromSignup }) => {
  return (
    <div className="flex items-center justify-center ">
      <div
        className={`bg-white flex items-center justify-center   border border-global-gray-soft ${fromSignup ? "w-10 h-10 rounded-lg" : "w-14 h-14 rounded-xl"}`}
        style={{
          boxShadow:
            "rgba(16, 24, 40, 0.05) 0px 1px 2px 0px, rgba(16, 24, 40, 0.1) 0px 1.25px 3.75px 0px, rgba(16, 24, 40, 0.06) 0px 1.25px 2.5px 0px, rgba(16, 24, 40, 0.05) 0px 1px 2px 0px, rgba(1, 24, 50, 0.06) 0px 0px 0px .5px inset, rgba(16, 24, 40, 0.05) 0px -2px 0px 0px inset",
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default SigninLogo;
