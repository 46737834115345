import React from "react";

const SupportIcon = React.forwardRef(
  (
    {
      width = "20",
      height = "20",
      viewBox = "0 0 20 20",
      fill = "none",
      fillColor = "white",
      className,
      ...props
    },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_1001_265)">
          <path
            d="M5.07864 9.35732C5.02684 9.02351 4.99997 8.68154 4.99997 8.33332C4.99997 4.65142 8.00438 1.66666 11.7105 1.66666C15.4166 1.66666 18.421 4.65142 18.421 8.33332C18.421 9.16505 18.2677 9.9612 17.9876 10.6954C17.9295 10.8479 17.9004 10.9241 17.8872 10.9837C17.8741 11.0426 17.869 11.0841 17.8676 11.1445C17.8662 11.2055 17.8744 11.2726 17.891 11.4069L18.2265 14.1321C18.2628 14.4271 18.2809 14.5746 18.2319 14.6818C18.1889 14.7758 18.1125 14.8504 18.0176 14.8912C17.9092 14.9378 17.7622 14.9163 17.4681 14.8732L14.8137 14.4841C14.6751 14.4638 14.6058 14.4536 14.5427 14.454C14.4803 14.4543 14.4371 14.4589 14.376 14.4718C14.3142 14.4848 14.2353 14.5143 14.0775 14.5734C13.3414 14.8491 12.5437 15 11.7105 15C11.362 15 11.0197 14.9736 10.6856 14.9227M6.35965 18.3333C8.8304 18.3333 10.8333 16.2813 10.8333 13.75C10.8333 11.2187 8.8304 9.16666 6.35965 9.16666C3.88891 9.16666 1.88597 11.2187 1.88597 13.75C1.88597 14.2588 1.9669 14.7483 2.1163 15.2056C2.17945 15.3989 2.21102 15.4956 2.22139 15.5616C2.2322 15.6305 2.2341 15.6693 2.23007 15.7389C2.22621 15.8057 2.20952 15.8811 2.17613 16.0319L1.66667 18.3333L4.16234 17.9925C4.29856 17.9739 4.36667 17.9646 4.42615 17.965C4.48877 17.9654 4.52201 17.9688 4.58343 17.981C4.64176 17.9927 4.72847 18.0233 4.9019 18.0845C5.35883 18.2458 5.84926 18.3333 6.35965 18.3333Z"
            stroke="white"
            strokeWidth="1.39167"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_1001_265">
            <rect width="20" height="20" fill="white" />
          </clipPath>
        </defs>
      </svg>
    );
  },
);

SupportIcon.displayName = "SupportIcon";

export default SupportIcon;
