import { cva } from "class-variance-authority";
import { cn } from "utils/helper";
const cardVariants = cva(["px-8 py-4 bg-global-white "], {
  variants: {
    borderRadius: {
      sm: "rounded-[10px]",
      md: "rounded-[20px]",
      lg: "rounded-[25px]",
    },
    size: {
      xs: "py-0 px-0 ",
      sm: "py-[15px] px-[25px] ",
      md: "py-[16px] px-[32px] ",
      lg: "py-[38px] px-[41px] ",
    },
  },
  defaultVariants: {
    variant: "primary",
    size: "md",
  },
});

const Card = ({ children, size, borderRadius, className, ...props }) => {
  return (
    <div className={cn(cardVariants({ borderRadius, size, className }))} {...props}>
      {children}
    </div>
  );
};

export default Card;
