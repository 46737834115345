import { Menu } from "@mui/material";
import { useState } from "react";
import { Button } from "./Button";

const MemberTooltip = ({ children, length }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      {length &&
        <Button
          aria-label="more"
          id={`long-button`}
          aria-controls={open ? "long-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          variant={"secondary"}
          onClick={(e) => {
            setAnchorEl(e.currentTarget);
            e.stopPropagation();
          }}
          className="rounded-full w-[32px] h-[22px] px-2 py-[2px]  text-status-text-default text-xs leading-[18px] font-medium  border border-border-divider-stroke custom-card cursor-pointer "
        >
          +{length}
        </Button >
      }
      <Menu
        id="account-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={(e) => e.stopPropagation()}
        elevation={0}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              overflow: "visible",
              mt: 1.5,
              maxHeight: 45 * 4.5,
              overflowY: "auto",
              borderRadius: "8px",
              border: "1px solid var(--color-border-secondary)",
              padding: "8px 8px 0px 8px",
              backgroundColor: "bg-tooltip-surface-light",
              boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.05)",
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&::before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                borderLeft: "1px solid var(--color-border-secondary)",
                borderTop: "1px solid var(--color-border-secondary)",
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <div className="relative ">{children}</div>
      </Menu>
    </>
  );
};

export default MemberTooltip;
