import { ActiveEye, ArrowLeft, Lock, LockOutLinedIcon, ResetPasswordIcon, SentEmailSuccess, SetPasswordIcon, View } from "assests";
import { passwordStrength } from "check-password-strength";
import BackButton from "components/UI/BackButton";
import { Button } from "components/UI/Button";
import Divider from "components/UI/Divider";
import DynamicSVG from "components/UI/DynamicSVG";
import ErrorMessage from "components/UI/ErrorMessage";
import FormHeader from "components/UI/FormHeader";
import { Input } from "components/UI/Input";
import { Label } from "components/UI/Label";
import PassWordCriteria from "components/UI/PassWordCriteria";
import SigninLogo from "components/UI/SigninLogo";
import useResetPassword from "hooks/useResetPassword";
import React, { useEffect, useState } from "react";
import { Link, Navigate } from "react-router-dom";


const ResetPassword = () => {
  const {
    isLoading,
    newPasswordInputType,
    confirmPasswordInputType,
    resetToken,
    email,
    errors,
    encodedString,
    register,
    handleSubmit,
    setValue,
    toggleNewPasswordType,
    toggleConfirmPasswordType,
  } = useResetPassword();

  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [showPassCriteria, setShowPassCriteria] = useState(false);
  const [passwordStrengthData, setPasswordStrengthData] = useState(null);


  const handleNewPasswordChange = (e) => {
    setNewPassword(e.target.value);
    setValue("password", e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    setValue("password_confirmation", e.target.value);
  };

  useEffect(() => {
    const newPasswordStrengthData = passwordStrength(newPassword);
    setPasswordStrengthData(newPasswordStrengthData);
    setShowPassCriteria(newPassword.length > 0);
  }, [newPassword]);


  if (!encodedString) {
    return <Navigate to="/signin" replace={true} />;
  }

  return (
    <div className="flex items-center justify-center">
      <div className="relative w-full px-4 md:px-0 md:w-[360px]">
        <div className="flex flex-col gap-8">
          <div className="flex flex-col gap-6">
            <SigninLogo>
              <LockOutLinedIcon width={28} height={28} />
            </SigninLogo>
            <div className="flex-col justify-center items-center inline-flex">
              <FormHeader
                icon={false}
                title="Set new password"
                subtitle="Your new FiberVue™ password must be different to previously used passwords."
              />
            </div>
          </div>

          <form onSubmit={handleSubmit} method="post">
            <div className="grid gap-5">
              <div className="grid gap-1.5">
                <Label htmlFor="newPassword" >
                  Password
                </Label>

                <Input
                  id="newPassword"
                  type={newPasswordInputType}
                  placeholder="••••••••"
                  {...register("password")}
                  onChange={handleNewPasswordChange}
                  className="text-btn-text-default border border-input-stroke-default placeholder:text-xs placeholder:text-text-input-text-placeholder"

                />
                {showPassCriteria && (
                  <PassWordCriteria passwordStrengthData={passwordStrengthData} />
                )}

                <ErrorMessage errors={errors?.password?.message} />
              </div>
              <div className="grid gap-3">
                <Label htmlFor="newConfirmPassword" >
                  Confirm Password
                </Label>

                <div className="relative">
                  <Input
                    id="newConfirmPassword"
                    {...register("password_confirmation")}
                    type={confirmPasswordInputType}
                    placeholder="••••••••"
                    className="text-btn-text-default border-input-stroke-default placeholder:text-xs placeholder:text-text-input-text-placeholder"
                    onChange={handleConfirmPasswordChange}

                  />
                </div>
                <ErrorMessage errors={errors?.password_confirmation?.message} />
              </div>
              <div className="flex justify-center">
                <Button
                  type="submit"
                  className="w-full text-base h-[44px] font-semibold"
                  isDisabled={isLoading}
                >
                  Reset Password
                </Button>
              </div>
            </div>
          </form>
          <BackButton text="Back to log in" url="/signin" />
          
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
