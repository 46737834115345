import { zodResolver } from "@hookform/resolvers/zod";
import { useQuery } from "@tanstack/react-query";
import { ArrowRight } from "assests";
import { Button } from "components/UI/Button";
import CustomSelect from "components/UI/CustomSelect";
import DynamicSVG from "components/UI/DynamicSVG";
import ErrorMessage from "components/UI/ErrorMessage";
import { Input } from "components/UI/Input";
import { Label } from "components/UI/Label";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { getCompanyDetailsForUser } from "services/company-settings.services";
import { setWorkSpaceCreateData } from "store/subscriptionSlice";
import { number, object, string } from "zod";

const CreateWorkSpaceSchema = object({
  workspace_id: number({ message: "Workspacename required" }),
  role: object(
    {
      value: string().min(1, { message: "Role required" }),
      label: string().min(1, { message: "Role required" }),
    },
    {
      message: "Role is required",
      invalid_type_error: "Role is required",
      required_error: "Role is required",
    },
  ),
});

const CreateWorkSpace = ({ handleStepChange }) => {
  const dispatch = useDispatch();
  const { workspaceCreateFormData } = useSelector((state) => state.subscriptionSlice);
  const [companyData, setCompanyData] = useState("");

  const {
    data: CompanySettingQueryData,
    isLoading,
    status,
  } = useQuery({
    queryKey: ["CompanySettingQueryData"],
    queryFn: getCompanyDetailsForUser,
    enabled: true,
  });

  const {
    control,
    register,
    setValue,
    handleSubmit,
    trigger,
    reset,
    formState: { errors },
  } = useForm({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: {
      workpace_id: parseInt(workspaceCreateFormData?.workspace_id) || "",
      role: workspaceCreateFormData?.role || "",
    },
    resolver: zodResolver(CreateWorkSpaceSchema),
  });
  const handleClick = async () => {
    const result = await trigger(["name", "role"]);
    if (result) {
      handleSubmit(onSubmit, onError)();
    }
  };

  const onSubmit = (data) => {
    if (data && typeof data === "object") {
      dispatch(setWorkSpaceCreateData(data));
      handleStepChange(2);
    }
  };
  const onError = (error) => {
    console.log(error);
    toast.error("Invalid form data");
  };
  const RoleOptions = [
    {
      label: "Agent",
      value: "agent",
    },
    {
      label: "Retailer",
      value: "retailer",
    },
  ];

  useEffect(() => {
    if (
      !isLoading &&
      status === "success" &&
      CompanySettingQueryData &&
      CompanySettingQueryData.data.status
    ) {
      const workspaceData = CompanySettingQueryData.data.workspace;
      setValue("workspace_id", parseInt(workspaceData.id));
      setCompanyData(workspaceData);
    }
  }, [CompanySettingQueryData, isLoading, status]);

  return (
    <div className="w-full">
      <div className="grid grid-cols-1">
        <p className="font-bold text-base md:text-3xl text-global-title mb-6 md:mb-12">
          Add new workspace
        </p>
        <div className="grid grid-cols-2 gap-6 md:gap-10">
          <div className="col-span-2 md:col-span-1 ">
            <div className="grid gap-3">
              <Label className="text-xs font-semibold text-input-text-label">
                Name your Workspace
              </Label>
              <Input
                id="name"
                type="text"
                size="xl"
                className="px-3 text-xs h-[49px] border-input-stroke-default text-dropdown-text-data placeholder:text-xs placeholder:text-text-input-text-placeholder hover:border-input-stroke-default"
                placeholder="Enter"
                value={companyData?.name}
                readOnly={true}
              />
              <p className="text-[10px] font-normal text-input-text-placeholder">
                You can also use the name of your company or organization
              </p>
              <ErrorMessage errors={errors?.name?.message} />
            </div>
          </div>
          <div className="col-span-2 md:col-span-1 ">
            <div className="grid gap-3">
              <Label className="text-xs font-semibold text-input-text-label">
                Select the role of this Workspace
              </Label>
              <CustomSelect
                control={control}
                id="season_id"
                name="season_id"
                placeholder="Select"
                options={RoleOptions}
                style={{
                  padding: "7px 10px 7px 3px",
                  fontSize: "12px",
                  height: "49px",
                  color: "var(--color-dropdown-text-data)",
                  borderRadius: "8px",
                  borderColor: "var(--color-input-stroke-default)",
                }}
                className="hover:border-input-stroke-default"
                isClearable={true}
                {...register("role")}
              />
              <ErrorMessage errors={errors?.role?.message} />
            </div>
          </div>
        </div>
        <div className="pt-6 md:pt-10">
          <Button
            className={
              "flex justify-between gap-2 px-8 py-4 h-[48px] text-sm text-white font-semibold"
            }
            onClick={handleClick}
          >
            Next
            <ArrowRight fillColor={"white"} height={14} width={14} />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CreateWorkSpace;
