import { Action, Cross, OrderIcon, Shock } from "assests";
import { Button } from "components/UI/Button";
import CustomSelect, { customMenuList } from "components/UI/CustomSelect";
import ErrorMessage from "components/UI/ErrorMessage";
import { Input } from "components/UI/Input";
import { Label } from "components/UI/Label";
import LimitReached from "components/UI/LimitReached";
import Modal from "components/UI/Modal";
import UpgradeComponent from "components/UI/UpgradeComponent";
import useAddOrder from "hooks/useAddOrder";
import AddAgent from "pages/Agent/AddAgent";
import InvitationCreateForm from "pages/Dashboard/DashboardWorkspaces/InvitationCreateForm";
import AddRetailer from "pages/Retailer/AddRetailer";
import AddSupplier from "pages/Supplier/AddSupplier";
import { Controller } from "react-hook-form";
import { PiCaretRightBold } from "react-icons/pi";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { components } from "react-select";
import Datepicker from "react-tailwindcss-datepicker";
import {
  setActionableCompanyType,
  toggleIsInvitationCreateModalOpen,
} from "store/accountDashboardSlice";
import { toggleIsAgentCreateModalOpen } from "store/agentSlice";
import {
  setRefetchAgentData,
  setRefetchRetailerData,
  setRefetchSupplierData,
  setShowArticleForm,
  setShowTemplateForm,
  toggleArtcileReachedModal,
  toggleInviteReachedModal,
} from "store/orderSlice";
import { toggleIsRetailerCreateModalOpen } from "store/retailerSlice";
import { toggleIsSupplierCreateModalOpen } from "store/supplierSlice";
import {
  preventMinus,
  preventPasteNegative,
  preventSpaceAndPasteNegative,
  preventSpaceMinus,
  setTimeInDateObject,
  truncateString,
} from "utils/helper";
import ArticleForm from "./ArticleForm";
import SelectTemplate from "./SelectTemplate";
import SupplyChainRetailerUser from "./SupplyChainRetailerUser";
const AddOrder = ({ closeModal, refetchData, articleListQueryRefetch }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isRetailerCreateModalOpen } = useSelector((state) => state.retailerSlice);
  const { isSupplierCreateModalOpen } = useSelector((state) => state.supplierSlice);
  const { isAgentCreateModalOpen } = useSelector((state) => state.agentSlice);
  const { actionableCompanyType, isInvitationCreateModalOpen } = useSelector(
    (state) => state.accountDashboardSlice,
  );

  const {
    control,
    seasonList,
    errors,
    formatedArticleList,
    showArticleForm,
    formatedTemplateList,
    tierList,
    showTemplateForm,
    tiersItem,
    workspace,
    agentList,
    retailerList,
    supplierList,
    genderList,
    actionableOrderId,
    orderDetailsData,
    isPending,
    isOrderDetailsLoading,
    isValid,
    clearErrors,
    register,
    setValue,
    getValues,
    processOrderSubmitOrderForm,
    handleClickAddNewTempalte,
    handleTierOptionSelect,
    handleClickAddNewArticle,
    handleSelectTemplate,
    onClickInvite,
    handleChangeType,
    watch,
    workspacePlan,
    currentWorkspace,
    artcileReachedModal,
    inviteReachedModal,
    onCloseTempalteForm,
    handleArticleSelect,
  } = useAddOrder({ refetchData });

  const onClickNewArticle = () => {
    handleClickAddNewArticle();
  };

  const onClickTemplateButton = () => {
    dispatch(setShowTemplateForm(true));
  };

  const closeInvitePartnerModal = () => {
    if (isRetailerCreateModalOpen) {
      dispatch(toggleIsRetailerCreateModalOpen());
    }
    if (isSupplierCreateModalOpen) {
      dispatch(toggleIsSupplierCreateModalOpen());
    }
    if (isAgentCreateModalOpen) {
      dispatch(toggleIsAgentCreateModalOpen());
    }
    if (actionableCompanyType) {
      dispatch(setActionableCompanyType(null));
    }
    if (artcileReachedModal) {
      dispatch(toggleArtcileReachedModal());
    }
    if (inviteReachedModal) {
      dispatch(toggleInviteReachedModal());
    }
    if (isInvitationCreateModalOpen) {
      dispatch(toggleIsInvitationCreateModalOpen());
    }
  };

  const handleCancel = () => {
    closeModal();
  };

  // check the form status
  const watchStatus = watch("status");

  return (
    <>
      <div className="relative">
        <div className=" flex items-center justify-center ">
          <div className="w-full lg:w-[600px] max-h-[600px] py-6   rounded-xl bg-white z-[2] ">
            <div className="absolute right-5 top-5 z-[2] cursor-pointer " onClick={handleCancel}>
              <Cross fillColor={"#A4A7AE"} height={24} width={24} />
            </div>
            {actionableOrderId && isPending && isOrderDetailsLoading ? (
              <div className="grid gap-2">
                <div className="flex items-center justify-center w-full h-32 mt-10">
                  <div className="border-4 rounded-full w-7 h-7 animate-spin border-global-primary border-t-transparent"></div>
                </div>
                <p className="mt-5 text-center">Preparing form data, please wait...</p>
              </div>
            ) : (
              <form>
                <div className="grid grid-cols-1 gap-6">
                  <div>
                    <div className="flex justify-start gap-x-4 px-6 z-[1] ">
                      <UpgradeComponent>
                        <OrderIcon
                          strokeColor={"var(--color-txt-secondary-secondary)"}
                          width={24}
                          height={24}
                        />
                      </UpgradeComponent>

                      <div className="flex justify-between z-[2]">
                        <div className="flex flex-col gap-1">
                          <h2 className="text-sm lg:text-lg  font-semibold text-start text-login-text-title">
                            {actionableOrderId &&
                              truncateString(`Order-${orderDetailsData?.order_number}`)}
                            {!actionableOrderId && "New Order"}
                          </h2>
                          <p className="text-xs lg:text-sm leading-5 text-global-paragraph font-normal truncate">
                            Add order details here.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="grid gap-4 lg:gap-6 px-6 pt-6 lg:pt-11 max-h-[378px] overflow-auto  z-[3]">
                      <div className="grid grid-cols-2 gap-4 lg:gap-6">
                        <div className="col-span-2">
                          <div className="grid gap-1.5">
                            <Label htmlFor="orderNumber" className="text-sm leading-5 font-medium">
                              Order Number
                            </Label>
                            <Input
                              id="orderNumber"
                              type="text"
                              placeholder="Order Number"
                              className={`px-[14px] py-[12.5px] h-11 custom-card text-base font-normal placeholder:text-base leading-[19.36px] text-gray-950 placeholder:leading-[19.36px] placeholder:text-gray-700 hover:outline-none hover:border hover:border-input-stroke-default`}
                              {...register("orderNumber", {
                                required: true,
                              })}
                              onPaste={preventSpaceAndPasteNegative}
                              onKeyPress={preventSpaceMinus}
                            />
                            <ErrorMessage errors={errors?.orderNumber?.message} />
                          </div>
                        </div>
                        <div className="col-span-2">
                          <div className="grid gap-1.5">
                            <Label htmlFor="articleType" className="text-sm leading-5 font-medium">
                              Order Date
                            </Label>

                            <div className="relative">
                              <Controller
                                control={control}
                                name="order_date"
                                render={({ field }) => (
                                  <>
                                    <Datepicker
                                      useRange={false}
                                      asSingle={true}
                                      value={field.value}
                                      placeholder={"Select date"}
                                      displayFormat="DD/MM/YYYY"
                                      containerClassName="certificate-date-picker "
                                      inputClassName={`flex justify-between w-full gap-3 px-4 h-[44px] relative py-2 items-center text-global-title text-base placeholder:text-input-text-placeholder placeholder:text-base placeholder:font-normal self-stretch border border-dropdown-stroke-default focus:shadow-[none] focus:ring-offset-input-surface-background focus:border-input-stroke-active focus:ring-input-stroke-active bg-dropdown-surface-background rounded-lg z-[2]`}
                                      primaryColor={"emerald"}
                                      toggleClassName="absolute text-icon-fill-input custom-toggle-icon right-0 h-full z-[4]"
                                      onChange={(value) => {
                                        field.onChange(setTimeInDateObject(value));
                                      }}
                                      popperPlacement="auto"
                                      popperModifiers={{
                                        preventOverflow: {
                                          enabled: true,
                                        },
                                      }}
                                    />
                                  </>
                                )}
                              />
                            </div>
                            <ErrorMessage errors={errors?.order_date?.startDate?.message} />
                          </div>
                        </div>
                        <div className="col-span-2 lg:col-span-1">
                          <div className="grid gap-1.5">
                            <Label htmlFor="articleType" className="text-sm leading-5 font-medium">
                              Select article
                            </Label>
                            <CustomSelect
                              control={control}
                              name="article_id"
                              placeholder="Select"
                              options={formatedArticleList}
                              {...register("article_id")}
                              handleOnChange={handleArticleSelect}
                              components={{
                                ...components,
                                MenuList: (props) =>
                                  customMenuList({
                                    ...props,
                                    label: "New Article",
                                    onClick: onClickNewArticle,
                                  }),
                              }}
                              style={{
                                padding: "3.5px 10px 7px 1.5px",
                                fontSize: "16px",
                              }}
                              isDisabled={formatedTemplateList.length === 0}
                            />
                            <ErrorMessage errors={errors?.article_id?.message} />
                          </div>
                        </div>
                        <div className="col-span-2 lg:col-span-1">
                          <div className="grid gap-1.5">
                            <Label htmlFor="articleType" className="text-sm leading-5 font-medium">
                              Quantity
                            </Label>
                            <Input
                              id="quantity"
                              type="number"
                              min={1}
                              className={`px-[14px] py-[12.5px] h-11 custom-card text-base font-normal placeholder:text-base leading-[19.36px] text-gray-950 placeholder:leading-[19.36px] placeholder:text-gray-700 hover:outline-none hover:border hover:border-input-stroke-default`}
                              placeholder="2000 pcs"
                              {...register("quantity", {
                                required: true,
                                valueAsNumber: true,
                                setValueAs: (v) => parseInt(v),
                              })}
                              onPaste={preventPasteNegative}
                              onKeyPress={preventMinus}
                            />
                            <ErrorMessage errors={errors?.quantity?.message} />
                          </div>
                        </div>
                        <div className="col-span-2">
                          <div className="grid gap-1.5">
                            <Label htmlFor="orderNumber" className="text-sm leading-5 font-medium">
                              Season
                            </Label>
                            <CustomSelect
                              control={control}
                              id="season_id"
                              name="season_id"
                              placeholder="Select"
                              options={seasonList}
                              handleOnChange={(selectedOption) =>
                                setValue("season_id", selectedOption)
                              }
                              isClearable={false}
                              style={{
                                padding: "3.5px 10px 7px 1.5px",
                                fontSize: "16px",
                              }}
                            />
                            <ErrorMessage errors={errors?.season_id?.message} />
                          </div>
                        </div>
                        <div className="col-span-2">
                          <div className="grid gap-1.5">
                            <Label htmlFor="orderNumber" className="text-sm leading-5 font-medium">
                              Supply Chain Template
                            </Label>
                            <div
                              onClick={onClickTemplateButton}
                              className="h-11 px-3.5 py-2.5 bg-white cursor-pointer rounded-lg shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)] border border-[#d5d7da] justify-start items-center gap-2 inline-flex"
                            >
                              <div className="grow shrink basis-0 h-[22px] justify-start items-center gap-2 flex">
                                {getValues("template_id")?.label || (
                                  <span className="text-gray-700">Select Template</span>
                                )}
                              </div>
                              <div>
                                <PiCaretRightBold size={16} color="var(--color-icon-fill-input)" />
                              </div>
                            </div>
                            <ErrorMessage errors={errors?.template_id?.message} />
                          </div>
                        </div>
                        <div className="col-span-2">
                          <SupplyChainRetailerUser
                            {...{
                              control,
                              register,
                              agentList,
                              workspace,
                              retailerList,
                              supplierList,
                              errors,
                              onClickInvite,
                              handleChangeType,
                              isValid,
                              currentWorkspace,
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex  justify-center items-center lg:justify-between gap-4 pt-4 lg:pt-6 px-6 ">
                  <div className="flex flex-col lg:flex-row justify-center w-full  gap-3">
                    <Button
                      type="button"
                      size={"md"}
                      variant={"secondary"}
                      className={
                        "w-full text-txt-secondary-secondary bg-white h-11 px-4 py-2.5 text-base leading-6 font-semibold custom-outline-shadow hover:text-white"
                      }
                      onClick={handleCancel}
                    >
                      Cancel
                    </Button>
                    <Button
                      onClick={processOrderSubmitOrderForm}
                      type="button"
                      size={"md"}
                      className="h-11 px-4 py-2.5 text-base  leading-6 w-full"
                      isDisabled={isPending}
                    >
                      {watchStatus === "draft" ? "Save Draft" : "Add Order"}
                    </Button>
                  </div>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>

      <Modal open={showArticleForm} close={() => null}>
        <ArticleForm
          refetchArticleData={articleListQueryRefetch}
          handleArticleSelect={handleArticleSelect}
          {...{
            genderList,
            onClose: () => dispatch(setShowArticleForm(false)),
          }}
        />
      </Modal>
      <Modal open={artcileReachedModal} close={closeInvitePartnerModal}>
        <LimitReached
          closeModal={closeInvitePartnerModal}
          IconComponent={Action}
          title="Article limit reached"
          subText1={`You cannot add a new article as you have reached the maximum limit of ${workspacePlan && workspacePlan?.package?.articles} articles in your ${workspacePlan && workspacePlan?.package?.name} plan. Please reduce your usage or upgrade your plan.`}
          className={"w-full lg:w-[600px]"}
          linked={workspacePlan && workspacePlan?.articles_count}
          totalLink={workspacePlan && workspacePlan?.package?.articles}
          linkedtitle={"Articles"}
          confirmButtonText={currentWorkspace && !currentWorkspace?.is_owner ? null : "Ugrade Plan"}
          confirmButtonVariant={"primary"}
          confirmButtonClassName={"w-full h-11"}
          onConfirmClick={() => {
            navigate(`/workspace/${currentWorkspace?.user_workspace_id}/setting?selectedTab=plan`);
            if (artcileReachedModal) {
              dispatch(toggleArtcileReachedModal());
            }
          }}
          icon={
            <Shock width={20} height={20} strokeColor={"var(--color-icon-fill-secondary-button)"} />
          }
        />
      </Modal>
      <Modal open={inviteReachedModal} close={closeInvitePartnerModal}>
        <LimitReached
          closeModal={closeInvitePartnerModal}
          IconComponent={Action}
          title="Partner limit reached"
          subText1={`You cannot add a new business partner  as you have reached the maximum limit of ${workspacePlan && workspacePlan?.package?.partner_links} partners in your ${workspacePlan && workspacePlan?.package?.name} plan. Please reduce your usage or upgrade your plan.`}
          className={"w-full  lg:w-[600px]"}
          linked={workspacePlan && workspacePlan?.partner_links_count}
          totalLink={workspacePlan && workspacePlan?.package?.partner_links}
          linkedtitle={"Business Partners"}
          confirmButtonText={currentWorkspace && !currentWorkspace?.is_owner ? null : "Ugrade Plan"}
          confirmButtonVariant={"primary"}
          confirmButtonClassName={"w-full h-11"}
          onConfirmClick={() => {
            navigate(`/workspace/${currentWorkspace?.user_workspace_id}/setting?selectedTab=plan`);
            if (inviteReachedModal) {
              dispatch(toggleInviteReachedModal());
            }
          }}
          icon={
            <Shock width={20} height={20} strokeColor={"var(--color-icon-fill-secondary-button)"} />
          }
        />
      </Modal>
      <Modal open={showTemplateForm} close={() => null}>
        <SelectTemplate
          {...{
            control,
            register,
            getValues,
            errors,
            formatedTemplateList,
            tierList,
            showTemplateForm,
            handleClickAddNewTempalte,
            handleTierOptionSelect,
            tiersItem,
            handleSelectTemplate,
            onClose: onCloseTempalteForm,
          }}
        />
      </Modal>
      <Modal open={isRetailerCreateModalOpen} close={() => null}>
        <AddRetailer
          closeModal={closeInvitePartnerModal}
          invitationListRefetch={() => {
            dispatch(setRefetchRetailerData(true));
          }}
        />
      </Modal>
      <Modal open={isAgentCreateModalOpen} close={() => null}>
        <AddAgent
          closeModal={closeInvitePartnerModal}
          invitationListRefetch={() => {
            dispatch(setRefetchAgentData(true));
          }}
        />
      </Modal>

      <Modal open={isSupplierCreateModalOpen} close={() => null}>
        <AddSupplier
          closeModal={closeInvitePartnerModal}
          invitationListRefetch={() => {
            dispatch(setRefetchSupplierData(true));
          }}
        />
      </Modal>
      <Modal open={isInvitationCreateModalOpen} close={() => null}>
        <InvitationCreateForm
          closeModal={closeInvitePartnerModal}
          invitationListRefetch={() => {
            dispatch(setRefetchAgentData(true));
            dispatch(setRefetchSupplierData(true));
          }}
        />
      </Modal>
    </>
  );
};

export default AddOrder;
