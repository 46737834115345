import React from "react";

const BrandRetailerIcon = React.forwardRef(
  (
    {
      width = "126",
      height = "126",
      viewBox = "0 0 126 126",
      fill = "none",

      className,
      ...props
    },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="63" cy="63" r="63" fill="#37C290" fill-opacity="0.1" />
        <circle cx="63" cy="63" r="53" fill="#37C290" fill-opacity="0.1" />
        <circle cx="63" cy="63" r="44" fill="#11C182" />
        <path
          d="M41.804 54.4315H47.2158V78.7843C47.2158 79.2822 47.6199 79.6863 48.1177 79.6863H71.5685C72.0664 79.6863 72.4705 79.2822 72.4705 78.7843V54.4315H77.8822C78.3422 54.4315 78.7274 54.086 78.7788 53.6287L79.6807 45.5111C79.7276 45.0881 79.4733 44.6903 79.0701 44.5559L65.5408 40.0461C65.1935 39.9307 64.8093 40.0362 64.5703 40.3149C62.2252 43.1876 57.462 43.1858 55.1169 40.3149C54.8779 40.0371 54.4945 39.9307 54.1464 40.0461L40.6171 44.5559C40.213 44.6903 39.9586 45.089 40.0056 45.5111L40.9075 53.6287C40.9589 54.086 41.3441 54.4315 41.804 54.4315Z"
          fill="url(#paint0_linear_503_9637)"
          fill-opacity="0.5"
        />
        <path
          d="M48.1175 59.8433H53.5293V84.1962C53.5293 84.6941 53.9333 85.0981 54.4312 85.0981H77.882C78.3799 85.0981 78.784 84.6941 78.784 84.1962V59.8433H84.1957C84.6557 59.8433 85.0408 59.4979 85.0922 59.0406L85.9942 50.923C86.0411 50.4999 85.7868 50.1022 85.3836 49.9678L71.8543 45.458C71.507 45.3425 71.1228 45.4481 70.8838 45.7268C68.5387 48.5995 63.7755 48.5977 61.4304 45.7268C61.1914 45.449 60.808 45.3425 60.4599 45.458L46.9306 49.9678C46.5265 50.1022 46.2721 50.5008 46.319 50.923L47.221 59.0406C47.2724 59.4979 47.6575 59.8433 48.1175 59.8433Z"
          fill="url(#paint1_linear_503_9637)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_503_9637"
            x1="59.8431"
            y1="40"
            x2="59.8431"
            y2="79.6863"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="white" />
            <stop offset="1" stop-color="white" stopOpacity="0" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_503_9637"
            x1="66.1566"
            y1="45.4119"
            x2="66.1566"
            y2="85.0981"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="white" />
            <stop offset="1" stop-color="white" stopOpacity="0" />
          </linearGradient>
        </defs>
      </svg>
    );
  },
);

export default BrandRetailerIcon;
