import React from "react";

const ArrowLeftIcon = React.forwardRef(
  (
    { width = "20", height = "20", viewBox = "0 0 20 20", fill = "none", className, ...props },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.6667 9.83333H4M4 9.83333L9.83333 15.6667M4 9.83333L9.83333 4"
          stroke="#11C182"
          strokeWidth="1.66667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  },
);

ArrowLeftIcon.displayName = "ArrowLeftIcon";

export default ArrowLeftIcon;
