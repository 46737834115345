import React, { useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { useNavigate, useSearchParams } from "react-router-dom";
import { acceptStatus, getAcceptInvitationDetails } from "services/user.services";
import { useSelector } from "react-redux";
import { FaLessThanEqual } from "react-icons/fa";

const useAcceptInvitation = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const hash = searchParams.get("hash");

  if (!hash) navigate("/", { replace: true });

  //global redux state
  const { token } = useSelector((state) => state.authenticationSlice);

  // const local state declaration
  const [showSetPasswordComponent, setShowPasswordComponent] = useState(FaLessThanEqual);
  const [email, setEmail] = useState("");

  const queryClient = useQueryClient();

  const { data: userInvitationQueryData, isLoading } = useQuery({
    queryKey: ["user", "getUserData"],
    queryFn: () => getAcceptInvitationDetails({ hash: hash }),
    enabled: !!hash,
  });

  const acceptInvitationMutation = useMutation({
    mutationKey: ["useraccept", "acceptinvitationlogin"],
    mutationFn: (data) => acceptStatus(data),
    onSuccess: (res) => {
      toast.success("Invitation has been accepted");
      queryClient.invalidateQueries("protectedResource");
    },
    onError: (error) => {
      toast.error(error.response?.data?.message || error.message || "Something went wrong");
    },
  });

  useEffect(() => {
    if (userInvitationQueryData && !isLoading) {
      toast.dismiss();
      const invitationData = userInvitationQueryData.data;

      const invitationStatus = invitationData.invitation.status;

      if (invitationStatus === "accepted") {
        /**
         * If it accepted then directly redirect the user to login page
         *  Or if logged in then redirect to dashboard page
         */
        if (token) {
          navigate("/");
        } else {
          navigate("/signin");
        }
      } else {
        const { workspace_required, user_exist, workspace_exist } = invitationData;

        if (!user_exist && !workspace_exist && workspace_required) {
          /**
           * User don't have user fibervue account
           * User also don't have any workspace and its required
           * Then redirect the user to signup page with the hash and email
           */
          navigate(`/signup?hash=${hash}&email=${btoa(`${invitationData.invitation.email}`)}`);
        }

        if (workspace_required && user_exist && workspace_exist) {
          /**
           * When its a registered user, have workspace then
           * if logged in then accept the invitation and redirect to the dashboard page
           * else redirect to login page with hash
           * after successfull login accept the invitation with hash
           */
          if (token) {
            // call the accept invitation function
            acceptInvitationMutation.mutateAsync({ hash });
            navigate("/");
          } else {
            navigate("/signin?hash=" + hash);
          }
        }

        if (!workspace_required && !user_exist && !workspace_exist) {
          /**
           * If workspace is not required means a user is worksapce admin, editor or viewer
           * Then set the user password and on set password inviation will be accepted and
           * then redirect to login page.
           */
          setEmail(invitationData.invitation.email);
          setShowPasswordComponent(true);
        }
      }
    }
  }, [userInvitationQueryData, isLoading]);

  return { showSetPasswordComponent, hash, email, navigate, hasQueryIsLoading: isLoading };
};

export default useAcceptInvitation;
