import { ActiveEye, ArrowLeft, BgImg, PhoneBGImg, SetPasswordIcon, View } from "assests";
import { Button } from "components/UI/Button";
import { Label } from "components/UI/Label";
import { Input } from "components/UI/Input";
import FormHeader from "components/UI/FormHeader";
import ErrorMessage from "components/UI/ErrorMessage";
import { useEffect, useState } from "react";
import { passwordStrength } from "check-password-strength";
import { Link } from "react-router-dom";
import PassWordCriteria from "components/UI/PassWordCriteria";
import { useDispatch } from "react-redux";
import { setActiveStep } from "store/createAccountSlice";
import BackButton from "components/UI/BackButton";
import SigninLogo from "components/UI/SigninLogo";


const SetPassword = ({
  register,
  errors,
  nextStep,
  getValues,
  passwordInputType,
  confirmPasswordInputType,
  togglePasswordType,
  toggleConfirmPasswordType,
  isRegistering,
  setValue,
}) => {
  const dispatch = useDispatch();
  const [passwordStrengthData, setPasswordStrengthData] = useState(null);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassCriteria, setShowPassCriteria] = useState(false);

  const handleNewPasswordChange = (e) => {
    setNewPassword(e.target.value);
    setValue("password", e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    setValue("password_confirmation", e.target.value);
  };

  useEffect(() => {
    const newPasswordStrengthData = passwordStrength(newPassword);
    setPasswordStrengthData(newPasswordStrengthData);
    setShowPassCriteria(newPassword.length > 0);
  }, [newPassword]);


  const handleLogoClick = () => {
    dispatch(setActiveStep(2));
  };

  return (
    <div className="flex items-center justify-center w-full ">
      <div className="z-[2] w-full flex flex-col items-center justify-center">
        <div
          className=" flex items-center justify-center w-full h-full "
          style={{ pointerEvents: "none" }}
        >
          <img src={BgImg} alt="" className="fixed top-0 hidden md:block z-[-1]" />
          <img src={PhoneBGImg} alt="" className="fixed top-0 block md:hidden z-[-1]" />
        </div>
        <div className="relative w-full px-4 md:p-0 md:w-[360px]">
          <div className="flex flex-col gap-8">
            <div className="flex flex-col gap-6">
              <SigninLogo>
                <SetPasswordIcon width={28} height={28} />
              </SigninLogo>
              <FormHeader title="Choose a password" subtitle="Set your FiberVue™ account password." />
            </div>

            <div className="grid gap-5">
              <div className="grid w-full gap-1.5">
                <Label htmlFor="password" >
                  Password
                </Label>

                <Input
                  id="password"
                  type={passwordInputType}
                  placeholder="••••••••"
                  value={getValues("password")}
                  onChange={(value) => {
                    handleNewPasswordChange(value);
                  }}
                />
                {showPassCriteria ? (
                  <PassWordCriteria passwordStrengthData={passwordStrengthData} />
                ) : (
                  ""
                )}

                <ErrorMessage errors={errors?.password?.message} />
              </div>
              <div className="grid w-full gap-1.5">
                <Label htmlFor="password_confirmation" >
                  Confirm password
                </Label>

                <Input
                  id="password_confirmation"
                  type={confirmPasswordInputType}
                  placeholder="••••••••"
                  value={getValues("password_confirmation")}
                  onChange={(value) => {
                    handleConfirmPasswordChange(value);
                  }}
                />
               
                <ErrorMessage errors={errors?.password_confirmation?.message} />
              </div>
              <div className="flex justify-center pt-1">
                <Button
                  size="lg"
                  className="flex items-center w-full gap-2 text-base font-semibold"
                  onClick={nextStep}
                  isDisabled={isRegistering}
                >
                  Set password
                </Button>
              </div>
            </div>
            <BackButton isButton={true} text="Go back" handleBtnClick={handleLogoClick} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SetPassword;
