import {
  AgentIcon,
  AmexIcon,
  BrandIcon,
  GeneraleIcon,
  ImporterIcon,
  MastercardIcon,
  RetailerUpdatedIcon,
  SupplierIcon,
  VisaLogoIcon,
} from "assests";

export const articleSeasonsList = [{ value: "SPRING_OR_SUMMER", label: "Spring/Summer (SS)" }];

export const articleCompositionsList = [
  { value: "COTTON", label: "Cotton" },
  { value: "POLYESTER", label: "Polyester" },
];

export const getStatusIconColor = (status) => {
  switch (status) {
    case "payment_failed":
      return "var(--color-status-icon-red)";
    case "draft":
      return "var(--color-status-icon-default)";
    case "overdue":
      return "var(--color-status-icon-red)";
    case "upcoming":
      return "var(--color-status-icon-default)";
    case "paid":
      return "var(--color-status-icon-green)";
    default:
      return "var(--color-status-icon-default)";
  }
};

export const getStatusButtonVariant = (status) => {
  switch (status) {
    case "draft":
      return "bg-status-surface-default border-status-stroke-default text-status-text-default";
    case "overdue":
      return "bg-status-surface-red border-status-stroke-default text-status-text-default";
    case "payment_failed":
      return "bg-status-surface-red border-status-stroke-default text-status-text-default";
    case "upcoming":
      return "bg-status-surface-default border-status-stroke-red text-status-text-red";
    case "paid":
      return "bg-status-surface-green border-status-stroke-green text-status-text-green";
    default:
      return "bg-status-surface-gray text-status-text-gray";
  }
};

export const getRoleList = (role) => {
  switch (role) {
    case "admin":
      return "bg-status-surface-gray text-status-text-gray";
    case "editor":
      return "bg-status-surface-gray text-status-text-gray";
    case "viewer":
      return "bg-status-surface-gray text-status-text-gray";
    default:
      return "bg-status-surface-gray text-status-text-gray";
  }
};

export const genderList = [
  {
    value: "MALE",
    label: "Male",
  },
  {
    value: "FEMALE",
    label: "Female",
  },
];

export const userData = [
  {
    name: "Myron Zimmerman",
    role: "Admin",
    email: "myron@workspaceit.com",
  },
  {
    name: "Wilma van Elk",
    role: "Viewer",
    email: "william@workspaceit.com",
  },
  {
    name: "Sadia moitry",
    role: "Editor",
    email: "sadia@workspaceit.com",
  },
  {
    name: "Sumon Biswas",
    role: "Admin",
    email: "sumon@workspaceit.com",
  },
  {
    name: "Wilma van Elk",
    role: "Editor",
    email: "william@workspaceit.com",
  },
];

export const registrationSamples = {
  Albania: "L91415031L",
  Andorra: "12345678",
  Armenia: "12345678",
  Austria: "FN 123456a",
  Azerbaijan: "123456789",
  Belarus: "123456789",
  Belgium: "0123.456.789",
  "Bosnia and Herzegovina": "1234567890123",
  Bulgaria: "123456789 or 1234567890123",
  Croatia: "12345678901",
  Cyprus: "HE 123456",
  "Czech Republic": "12345678",
  Denmark: "12345678",
  Estonia: "12345678",
  Finland: "1234567-8",
  France: "123 456 789",
  Georgia: "123456789",
  Germany: "HRB 123456",
  Greece: "123456789",
  Hungary: "01-09-123456",
  Iceland: "123456-7890",
  Ireland: "123456",
  Italy: "12345678901",
  Kazakhstan: "123456789012",
  Kosovo: "123456789",
  Latvia: "40003521600",
  Liechtenstein: "FL-0002.000.123-4",
  Lithuania: "123456789",
  Luxembourg: "B12345678",
  Malta: "C 12345",
  Moldova: "1234567890123",
  Monaco: "123456789",
  Montenegro: "12345678",
  Netherlands: "12345678",
  "North Macedonia": "1234567890123",
  Norway: "123456789",
  Poland: "1234567890",
  Portugal: "123456789",
  Romania: "J40/12345/2021",
  Russia: "1027700132195",
  "San Marino": "SM12345",
  Serbia: "12345678",
  Slovakia: "12345678",
  Slovenia: "12345678",
  Spain: "A12345678",
  Sweden: "5561234567",
  Switzerland: "CHE-123.456.789",
  Turkey: "1234567890",
  Ukraine: "12345678",
  "United Kingdom": "12345678 or SC123456",
};

export const inviteCompanyTypeList = [
  {
    name: "Retailer",
    icon: RetailerUpdatedIcon,
    shortcut: "Ctrl+R",
    type: "retailer",
  },
  {
    name: "Supplier",
    icon: SupplierIcon,
    shortcut: "Ctrl+Shift+X",
    type: "supplier",
  },
  {
    name: "Agent",
    icon: AgentIcon,
    shortcut: "⌘A",
    type: "agent",
  },
  {
    name: "Brand",
    icon: BrandIcon,
    shortcut: "⌘B",
    type: "brand",
  },
  {
    name: "Importer",
    icon: ImporterIcon,
    shortcut: "⌘I",
    type: "importer",
  },
];

export const businessPartnerList = [
  { value: "retailer", label: "Retailer" },
  { value: "supplier", label: "Supplier" },
  { value: "agent", label: "Agent" },
  { value: "brand", label: "Brand" },
  { value: "importer", label: "Importer" },
];

export const findIcon = (brand, width, height) => {
  if (brand === "amex") {
    return <AmexIcon width={width} height={height} />;
  } else if (brand === "visa") {
    return <VisaLogoIcon width={width} height={height} />;
  } else if (brand === "mastercard") {
    return <MastercardIcon width={width} height={height} />;
  } else {
    return <GeneraleIcon />;
  }
};

export const invitedPartnerListData = [
  {
    companyname: "Shark",
    status: "accepted",
    type: "supplier",
    firstname: "John",
    lastname: "Doe",
    activeOrders: 3,

    invited: "2/5/2024",
    activated: "10/9/2024",
    role: "retailer",
    email: "Dq0uA@example.com",
    workspaces: ["workspace"],
  },
  {
    companyname: "Sharkroo",
    status: "invite_pending",
    type: "agent",
    firstname: "Jonna",
    lastname: "Dora",
    activeOrders: 5,

    invited: "2/5/2024",
    activated: "10/9/2024",
    role: "retailer",
    email: "Dq0uA@example.com",
    workspaces: ["workspace", "workspace"],
  },
  {
    companyname: "Wsit",
    status: "accepted",
    type: "supplier",
    firstname: "John",
    lastname: "Doe",
    activeOrders: 3,

    invited: "2/5/2024",
    activated: "10/9/2024",
    role: "supplier",
    email: "Dq0uA@example.com",
    workspaces: ["workspace", "workspace", "workspace", "workspace", "workspace"],
  },
  {
    companyname: "Shark",
    status: "accepted",
    type: "supplier",
    firstname: "John",
    lastname: "Doe",
    activeOrders: 0,

    invited: "2/5/2024",
    activated: "10/9/2024",
    role: "retailer",
    email: "Dq0uA@example.com",
    workspaces: ["workspace", "workspace"],
  },
  {
    companyname: "Shark Tank",
    status: "accepted",
    type: "supplier",
    firstname: "John",
    lastname: "Doe",
    activeOrders: 3,

    invited: "2/5/2024",
    activated: "10/9/2024",
    role: "retailer",
    email: "Dq0uA@example.com",
    workspaces: ["workspace", "workspaceewrwetetyryyur", "workspace", "workspace", "workspace"],
  },
];
