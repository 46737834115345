import React from "react";

const Shock = React.forwardRef(
  (
    {
      width = "17",
      height = "20",
      viewBox = "0 0 17 20",
      fill = "none",
      strokeColor = "white",
      className,
      ...props
    },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.33306 1.66675L1.91094 10.5733C1.62026 10.9221 1.47492 11.0965 1.4727 11.2438C1.47077 11.3718 1.52783 11.4937 1.62744 11.5742C1.74201 11.6667 1.96904 11.6667 2.42308 11.6667H8.49972L7.66639 18.3334L15.0885 9.42687C15.3792 9.07806 15.5245 8.90366 15.5267 8.75636C15.5287 8.62832 15.4716 8.50649 15.372 8.426C15.2574 8.33342 15.0304 8.33342 14.5764 8.33342H8.49972L9.33306 1.66675Z"
          stroke={strokeColor}
          strokeWidth="1.66667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  },
);

export default Shock;
