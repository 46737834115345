import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Cross, OrderIcon } from "assests";
import { Button } from "components/UI/Button";
import CustomSelect, { customMenuList } from "components/UI/CustomSelect";
import Divider from "components/UI/Divider";
import ErrorMessage from "components/UI/ErrorMessage";
import { Label } from "components/UI/Label";
import Modal from "components/UI/Modal";
import UpgradeComponent from "components/UI/UpgradeComponent";
import useCustomToaster from "hooks/useCustomToaster";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getOrderSuppliers, inviteSupplierForOder } from "services/supplier.services";
import { setActionableCompanyType } from "store/accountDashboardSlice";
import { toggleIsSupplierCreateModalOpen } from "store/supplierSlice";
import { formateLable, truncateString } from "utils/helper";
import { z } from "zod";
import AddSupplier from "./AddSupplier";
const AddSupplierSchemaForOrder = z
  .object({
    workspace_id: z.number({
      required_error: "Workspace is required",
    }),
    order_id: z.number({
      required_error: "Order id is required",
    }),
    company_id: z
      .object({
        label: z.any().optional(),
        value: z.number().optional(),
        pending: z.any().optional(),
      })
      .nullable(),
    new_data: z
      .object({
        person_name: z
          .string({
            required_error: "Person name is required",
            invalid_type_error: "Person name is required",
          })
          .optional(),
        company_name: z
          .string({
            required_error: "Company name is required",
            invalid_type_error: "Company name is required",
          })
          .optional(),
        email: z
          .string({
            required_error: "Email is required",
            invalid_type_error: "Email is required",
          })
          .optional(),
      })
      .nullable(),
  })
  .superRefine((data, ctx) => {
    if (!data.showNewForm && (!data.company_id || data.company_id === null)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Please select supplier",
        path: ["company_id"],
      });
    }
    if (data.showNewForm) {
      if (!data.new_data?.company_name) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Company name required",
          path: ["new_data.company_name"],
        });
      }
      if (!data.new_data?.person_name) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Person name required",
          path: ["new_data.person_name"],
        });
      }

      if (!data.new_data?.email) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Email is required",
          path: ["new_data.email"],
        });
      } else {
        const re =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!re.test(data.new_data?.email)) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "Email is not valid",
            path: ["new_data.email"],
          });
        }
      }
    }
  });

const AddSupplierModal = ({ closeModal, refetchData, orderId, orderNo }) => {
  const { workspaceId } = useParams();
  const dispatch = useDispatch();
  const { showCustomToast } = useCustomToaster();
  const [supplierList, setSupplierList] = useState([]);
  const { isSupplierCreateModalOpen } = useSelector((state) => state.supplierSlice);
  const { currentWorkspace } = useSelector(
    (state) => state.authenticationSlice,
  );
  const {
    data: supplierListQuery,
    isLoading: isSupplierLoading,
    status: supplierQueryStatus,
    refetch,
  } = useQuery({
    queryKey: ["createUpdateOrderQueries", "getSupplierList"],
    queryFn: () => getOrderSuppliers({ pagination: "no", workspace_id: parseInt(workspaceId) }),
    enabled: !!workspaceId,
  });

  const AddSupplierMutation = useMutation({
    mutationKey: ["addSupplier"],
    mutationFn: (data) => inviteSupplierForOder(data),
    onMutate: () => {
      showCustomToast(
        {
          title: "Inviting supplier...",
        },
        "inviteSupplier",
      );
    },
    onSuccess: (res) => {
      toast.dismiss("inviteSupplier");
      showCustomToast({
        title: "Supplier invited successfully",
      });
      closeModal();
      reset();
      refetchData();
    },
    onError: (error) => {
      toast.dismiss("inviteSupplier");
      if (error.response?.data?.errors) {
        Object.entries(error.response.data.errors).forEach(([field, messages]) => {
          messages.forEach((message) => {
            showCustomToast({
              title: message,
            });
          });
        });
      } else {
        showCustomToast({
          title: error.response?.data?.message || error.message,
        });
      }
    },
  });

  const {
    control,
    register,
    handleSubmit,
    reset,
    getValues,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm({
    defaultValues: {
      workspace_id: parseInt(workspaceId),
      order_id: parseInt(orderId),
      company_id: null,
      new_data: null,
    },
    mode: "onBlur",
    reValidateMode: "onChange",
    resolver: zodResolver(AddSupplierSchemaForOrder),
  });

  const onError = (error) => {
    console.log(getValues());
    console.log(error);
  };

  const onFormSubmit = handleSubmit(async (data) => {
    const processedData = processFormData(data);
    AddSupplierMutation.mutate(processedData);
    // Submit the data to your API or perform other actions
  }, onError);

  const processFormData = (data) => {
    let processedData = {};

    if (data.workspace_id) {
      processedData.workspace_id = parseInt(data.workspace_id);
    }
    if (data.order_id) {
      processedData.order_id = data.order_id;
    }
    if (data.company_id.pending) {
      processedData = {
        ...processedData,
        invite: {
          new_data: {
            ...data.new_data,
            company_type: "supplier",
            invitation_id: data.company_id.value,
          },
        },
      };
    } else {
      processedData = {
        ...processedData,
        invite: {
          company_id: data.company_id.value,
          company_type: "supplier",
        },
      };
    }

    return processedData;
  };

  const handleClose = () => {
    reset();
    closeModal();
  };

  const handleChangeSupplier = (selectedOption) => {
    if (selectedOption) {
      setValue("company_id", selectedOption);
    } else {
      setValue("company_id", null);
    }
  };
  const handleClickShowNewForm = () => {
    dispatch(toggleIsSupplierCreateModalOpen());
    dispatch(setActionableCompanyType("supplier"));
  };

  useEffect(() => {
    if (supplierListQuery && !isSupplierLoading && supplierQueryStatus === "success") {
      if (supplierListQuery.data) {
        const result = supplierListQuery.data.data;
        if (result && Array.isArray(result) && result.length > 0) {
          const supplierList = result?.map((item) => ({
            ...item,
            label: formateLable(item?.company_name, item?.company_type),
            customLabel: `${item?.company_name}${" "}${item?.company_type}`,
            value: item?.pending ? item.invitation_id : item.workspace_id,
            purchased_package: item?.company_type,
          }));
          setSupplierList(supplierList);
        }
      }
    }
  }, [supplierListQuery, isSupplierLoading, supplierQueryStatus]);

  return (
    <>
      <Modal open={isSupplierCreateModalOpen} close={() => null}>
        <AddSupplier
          closeModal={() => {
            dispatch(toggleIsSupplierCreateModalOpen()
            )
            dispatch(setActionableCompanyType(null));
          }}
          invitationListRefetch={refetch}
        />
      </Modal>
      <div className="relative  z-[2] border border-global-divider-soft rounded-xl  w-full lg:w-[600px] max-h-[600px] py-6 bg-white">
        <div className="flex justify-between gap-x-4 px-6 pb-5">
          <div className="flex justify-start items-center gap-4">
            <UpgradeComponent>
              <OrderIcon
                width={24}
                height={24}
                strokeColor="var(--color-login-icon-outlined-default)"
              />
            </UpgradeComponent>
            <div className="flex flex-col gap-0">
              <h3 className="text-sm lg:text-lg  font-semibold text-start text-login-text-title">
                {orderNo && truncateString(orderNo)}
              </h3>
              <p className="text-sm text-global-paragraph font-normal text-wrap">
                Link a supplier here.
              </p>
            </div>
          </div>
          <div>
            <Cross
              fillColor="var(--color-icon-fill-input)"
              width={24}
              height={24}
              onClick={closeModal}
              className="cursor-pointer"
            />
          </div>
        </div>

        <form onSubmit={onFormSubmit} onError={onError}>
          <div className="grid grid-cols-2 w-full gap-y-3 lg:gap-y-6 gap-x-5 px-6  pt-5 ">
            <div className="col-span-2">
              <div className="grid gap-1.5">
                <Label htmlFor="company_name">Supplier Name</Label>
                <CustomSelect
                  control={control}
                  name="company_id"
                  placeholder="Select partner"
                  options={supplierList}
                  value={null}
                  isClearable={false}
                  handleOnChange={handleChangeSupplier}
                  {...(currentWorkspace && currentWorkspace?.role_name.includes("admin")
                    ? {
                      components: {
                        MenuList: (props) =>
                          customMenuList({
                            ...props,
                            label: "Add supplier",
                            onClick: handleClickShowNewForm,
                          }),
                      },
                    }
                    : {})}
                  style={{
                    padding: "3.5px 10px 7px 1.5px",
                    fontSize: "16px",
                  }}
                />
                <ErrorMessage errors={errors?.company_id?.message} />
              </div>
            </div>
          </div>
          <Divider className={"mb-4 mt-4 lg:mt-8 lg:mb-6"} />
          <div className="flex  justify-center items-center lg:justify-between gap-4 px-6">
            <div className="flex flex-col lg:flex-row  w-full gap-2 lg:gap-3">
              <Button
                variant={"secondary"}
                className={
                  "text-btn-text-default px-2.5 py-4 w-full  h-11 text-base font-semibold rounded-lg "
                }
                onClick={closeModal}
              >
                Cancel
              </Button>

              <Button
                variant={"primary"}
                type="submit"
                isDisabled={AddSupplierMutation.isPending}
                className={
                  "text-btn-text-default px-2.5 py-4 w-full gap-1.5  h-11 text-base font-semibold rounded-lg text-white "
                }
              >
                Save
              </Button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default AddSupplierModal;
