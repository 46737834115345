import React from "react";

const Passcode = React.forwardRef(
  (
    { width = "48", height = "48", viewBox = "0 0 48 48", fill = "none", className, ...props },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_ddddii_10304_34183)">
          <rect x="4" y="3" width="40" height="40" rx="8" fill="white" />
          <rect x="4.5" y="3.5" width="39" height="39" rx="7.5" stroke="#E4E7EC" />
          <path
            d="M24.0001 23H24.0042M28.1667 23H28.1709M19.8334 23H19.8376M18.3334 18.8333H29.6667C30.6002 18.8333 31.0669 18.8333 31.4234 19.015C31.737 19.1748 31.992 19.4297 32.1518 19.7433C32.3334 20.0998 32.3334 20.5666 32.3334 21.5V24.5C32.3334 25.4334 32.3334 25.9001 32.1518 26.2566C31.992 26.5702 31.737 26.8252 31.4234 26.985C31.0669 27.1666 30.6002 27.1666 29.6667 27.1666H18.3334C17.4 27.1666 16.9333 27.1666 16.5768 26.985C16.2632 26.8252 16.0082 26.5702 15.8484 26.2566C15.6667 25.9001 15.6667 25.4334 15.6667 24.5V21.5C15.6667 20.5666 15.6667 20.0998 15.8484 19.7433C16.0082 19.4297 16.2632 19.1748 16.5768 19.015C16.9333 18.8333 17.4 18.8333 18.3334 18.8333ZM24.2084 23C24.2084 23.115 24.1151 23.2083 24.0001 23.2083C23.885 23.2083 23.7917 23.115 23.7917 23C23.7917 22.8849 23.885 22.7916 24.0001 22.7916C24.1151 22.7916 24.2084 22.8849 24.2084 23ZM28.3751 23C28.3751 23.115 28.2818 23.2083 28.1667 23.2083C28.0517 23.2083 27.9584 23.115 27.9584 23C27.9584 22.8849 28.0517 22.7916 28.1667 22.7916C28.2818 22.7916 28.3751 22.8849 28.3751 23ZM20.0417 23C20.0417 23.115 19.9485 23.2083 19.8334 23.2083C19.7184 23.2083 19.6251 23.115 19.6251 23C19.6251 22.8849 19.7184 22.7916 19.8334 22.7916C19.9485 22.7916 20.0417 22.8849 20.0417 23Z"
            stroke="#344054"
            strokeWidth="1.66667"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <filter
            id="filter0_ddddii_10304_34183"
            x="0.25"
            y="0.5"
            width="47.5"
            height="47.5"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="1" />
            <feGaussianBlur stdDeviation="1" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.05 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_10304_34183"
            />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="1.25" />
            <feGaussianBlur stdDeviation="1.25" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.06 0"
            />
            <feBlend
              mode="normal"
              in2="effect1_dropShadow_10304_34183"
              result="effect2_dropShadow_10304_34183"
            />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="1.25" />
            <feGaussianBlur stdDeviation="1.875" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.1 0"
            />
            <feBlend
              mode="normal"
              in2="effect2_dropShadow_10304_34183"
              result="effect3_dropShadow_10304_34183"
            />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="1" />
            <feGaussianBlur stdDeviation="1" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.05 0"
            />
            <feBlend
              mode="normal"
              in2="effect3_dropShadow_10304_34183"
              result="effect4_dropShadow_10304_34183"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect4_dropShadow_10304_34183"
              result="shape"
            />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="-2" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.05 0"
            />
            <feBlend mode="normal" in2="shape" result="effect5_innerShadow_10304_34183" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feMorphology
              radius="1"
              operator="erode"
              in="SourceAlpha"
              result="effect6_innerShadow_10304_34183"
            />
            <feOffset />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.18 0"
            />
            <feBlend
              mode="normal"
              in2="effect5_innerShadow_10304_34183"
              result="effect6_innerShadow_10304_34183"
            />
          </filter>
        </defs>
      </svg>
    );
  },
);

export default Passcode;
