import { Menu } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { LableDashboard, Plus, ReportIcons, Search, ShieldDashbaord, Vector } from "assests";

import ResultNotFound from "components/Card/ResultNotFound";
import { Button } from "components/UI/Button";
import ChipButton from "components/UI/ChipButton";
import DynamicSVG from "components/UI/DynamicSVG";
import usePermission from "hooks/usePermission";
import { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getGlobalSearchData } from "services/global.services";
import { setSearchedAllData } from "store/globalActionsSlice";
const GlobalActions = ({ setIsInputVisible, setIsSearchActive, isInputVisible, searchInputRef }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { workspaceId } = useParams();
  const { pathname } = useLocation();
  const location = useLocation();
  const [searchKey, setSearchKey] = useState("");
  const [searchedData, setSearchedData] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isMenuVisible, setMenuVisible] = useState(false);
  const menuRef = useRef(null);

  const open = Boolean(anchorEl);
  const { searchedAllData } = useSelector((state) => state.globalActionsSlice);
  const handleFocus = () => {
    setMenuVisible(true);
  };

  const handleBlur = (event) => {
    if (menuRef.current && menuRef.current.contains(event.relatedTarget)) {
      return;
    }
    setMenuVisible(false);
    setIsInputVisible(false);
    setIsSearchActive(false);
  };

  const handleMouseDown = (event) => {
    if (menuRef.current && menuRef.current.contains(event.target)) {
      event.preventDefault();
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const {
    data: globalSearchDataQuery,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ["globalSearch", "getGlobalSearchData", searchKey],
    queryFn: () =>
      getGlobalSearchData({
        search: searchKey,
      }),
    enabled: !!searchKey,
  });
  useEffect(() => {
    if (!isLoading && globalSearchDataQuery) {
      dispatch(setSearchedAllData({ searchedAllData: globalSearchDataQuery?.data, append: false }));
    }
  }, [globalSearchDataQuery, isLoading]);

  const handleSearch = (e) => {
    const searchText = e.target.value?.trim();
    if (searchText.length >= 2) {
      setSearchKey(searchText);
    } else {
      setSearchKey("");
    }
  };

  const handleClickPlus = (e, type) => {
    e.stopPropagation();
    setAnchorEl(null);
    switch (type) {
      case "add-order":
        navigate(`/workspace/${workspaceId}/create-order`, { replace: true });
        break;
      case "add-articles":
        navigate(`/workspace/${workspaceId}/articles?fromGlobalCreate=true`, { replace: true });
        break;
      case "add-templates":
        navigate(`/workspace/${workspaceId}/templates?fromGlobalCreate=true`, { replace: true });
        break;
      default:
        break;
    }
  };

  const handleGlobalsearchItemClick = (item, type) => {
    switch (type) {
      case "order":
        navigate(`/workspace/${item?.workspace_id}/orders/${item.id}`, { replace: true });
        break;
      case "retailer":
        navigate(`/workspace/${item?.invited_by}/retailer-details/${item?.workspace_id}`, {
          replace: true,
        });
        break;
      case "supplier":
        navigate(`/workspace/${item?.invited_by}/supplier-details/${item?.workspace_id}/supplier`, {
          replace: true,
        });
        break;
      case "article":
        navigate(
          `/workspace/${item?.workspace_id}/articles?article_id=${item?.id}&fromGlobalSearch=true`,
          { replace: true },
        );
        break;
      case "agent":
        navigate(
          `/workspace/${item?.invited_by}/agent-details/${item?.workspace_id}?status=accepted`,
          { replace: true },
        );
        break;
      case "sub_suppliers":
        navigate(
          `/workspace/${item?.workspace_id}/suppliers?subsuppliers_id=${item?.id}&fromGlobalSearch=true&selectedTab=subsupplier`,
          { replace: true },
        );
        break;
      case "template":
        if (searchInputRef.current) {
          searchInputRef.current.blur();
        }
        setMenuVisible(false);
        navigate(
          `/workspace/${item?.workspace_id}/articles?template_id=${item?.id}&workspace_id=${item?.workspace_id}&fromGlobalSearch=true&selectedTab=Templates`,
          { replace: true },
        );
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if ((event.ctrlKey || event.metaKey) && event.key === "f") {
        event.preventDefault();
        searchInputRef.current.focus();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);



  return (
    <div className="flex items-center gap-2 md:gap-4 ">

      <div className="relative" onMouseDown={handleMouseDown}>
        <div className="flex justify-center items-center cursor-pointer ">
          <div className="w-10 h-9 flex items-center justify-center">
            <Search
              className="w-5 h-5"
              strokeColor="#717680"
              onClick={() => {
                setIsInputVisible(true);
                setIsSearchActive(true); // Change the div to rounded-lg when input is shown
              }}
            />
          </div>

          {/* Input field that appears when isInputVisible is true */}
          {isInputVisible && (
            <input
              ref={searchInputRef}
              onFocus={handleFocus}
              onBlur={handleBlur}
              onChange={handleSearch}
              type="text"
              className="pl-0 pr-2 py-1 rounded-md max-w-[150px] md:w-[220px] outline-none border-0 ring-0 focus:ring-0 focus:outline-none  focus:border-0 placeholder:text-base placeholder:font-medium leading-6 placeholder:text-[#181D27]"
              placeholder="Search Query"

            />
          )}
        </div>

        {isMenuVisible && (

          <div
            ref={menuRef}
            className="absolute z-50 w-[290px] md:w-[372px]  top-[52px]  global-search-shadow   ring-1 ring-[#E9EAEB] focus:outline-none  flex flex-col items-center justify-start py-1 px-1.5  rounded-lg bg-global-theme"

          >
            {searchedAllData ? (
              <>
                {searchedAllData?.orders?.data?.length > 0 ||
                  searchedAllData?.articles?.data?.length > 0 ||
                  searchedAllData?.templates?.data?.length > 0 ||
                  searchedAllData?.agents?.data?.length > 0 ||
                  searchedAllData?.suppliers?.data?.length > 0 ||
                  searchedAllData?.retailers?.data?.length > 0 ||
                  searchedAllData?.sub_suppliers?.data?.length > 0 ? (
                  <div className="grid grid-cols-1 gap-y-[1px] w-full max-h-[320px] overflow-y-auto">
                    {searchedAllData?.orders &&
                      searchedAllData?.orders?.data &&
                      searchedAllData?.orders?.data?.length > 0 && (
                        <div className="flex flex-col gap-[1px]">
                          {searchedAllData?.orders?.data?.map((item, index) => (
                            <Button
                              key={index}
                              type="button"
                              variant={"action"}
                              className={`flex items-center justify-start text-base w-full font-medium text-global-title gap-2 px-2.5 h-[40px] rounded-sm  hover:bg-[#FAFAFA] `}
                              onClick={(e) => handleGlobalsearchItemClick(item, "order")}
                            >
                              <div className="flex items-center justify-start  gap-2">
                                {item?.order_number}
                              </div>
                              <ChipButton className="text-xs h-[22px] !px-1.5" childrenClassName="text-global-paragraph-3">Order</ChipButton>
                            </Button>
                          ))}
                        </div>
                      )}
                    {searchedAllData?.articles &&
                      searchedAllData?.articles?.data &&
                      searchedAllData?.articles?.data?.length > 0 && (
                        <div className="flex flex-col gap-[1px]">
                          {searchedAllData?.articles?.data?.map((item, index) => (
                            <Button
                              key={index}
                              type="button"
                              variant={"action"}
                              className={`flex items-center justify-start text-base w-full font-medium text-global-title gap-2 px-2.5 h-[40px] rounded-sm  hover:bg-[#FAFAFA] `}
                              onClick={(e) => handleGlobalsearchItemClick(item, "article")}
                            >
                              <div className="flex items-center justify-start  gap-2">
                                {item?.sku}
                              </div>
                              <ChipButton className="text-xs h-[22px] !px-1.5" childrenClassName="text-global-paragraph-3">Article</ChipButton>
                            </Button>
                          ))}
                        </div>
                      )}
                    {searchedAllData?.templates &&
                      searchedAllData?.templates?.data &&
                      searchedAllData?.templates?.data?.length > 0 && (
                        <div className="flex flex-col gap-[1px]">
                          {searchedAllData?.templates?.data?.map((item, index) => (
                            <Button
                              key={index}
                              type="button"
                              variant={"action"}
                              className={`flex items-center justify-start text-base w-full font-medium text-global-title gap-2 px-2.5 h-[40px] rounded-sm  hover:bg-[#FAFAFA] `}
                              onClick={(e) => handleGlobalsearchItemClick(item, "template")}
                            >
                              <div className="flex items-center justify-start  gap-2">
                                {item?.name}
                              </div>
                              <ChipButton className="text-xs h-[22px] !px-1.5" childrenClassName="text-global-paragraph-3">Template</ChipButton>
                            </Button>
                          ))}
                        </div>
                      )}

                    {searchedAllData?.agents &&
                      searchedAllData?.agents?.data &&
                      searchedAllData?.agents?.data?.length > 0 && (
                        <div className="flex flex-col gap-[1px]">
                          {searchedAllData?.agents?.data?.map((item, index) => (
                            <Button
                              key={index}
                              type="button"
                              variant={"action"}
                              className={`flex items-center justify-start text-base w-full font-medium text-global-title gap-2 px-2.5 h-[40px] rounded-sm  hover:bg-[#FAFAFA] `}
                              onClick={(e) => handleGlobalsearchItemClick(item, "agent")}
                            >
                              <div className="flex items-center justify-start  gap-2">
                                {item?.name}
                              </div>
                              <ChipButton className="text-xs h-[22px] !px-1.5" childrenClassName="text-global-paragraph-3">Agent</ChipButton>
                            </Button>
                          ))}
                        </div>
                      )}

                    {searchedAllData?.suppliers &&
                      searchedAllData?.suppliers?.data &&
                      searchedAllData?.suppliers?.data?.length > 0 && (
                        <div className="flex flex-col gap-[1px]">
                          {searchedAllData?.suppliers?.data?.map((item, index) => (
                            <Button
                              key={index}
                              type="button"
                              variant={"action"}
                              className={`flex items-center justify-start text-base w-full font-medium text-global-title gap-2 px-2.5 h-[40px] rounded-sm  hover:bg-[#FAFAFA] `}
                              onClick={(e) => handleGlobalsearchItemClick(item, "supplier")}
                            >
                              <div className="flex items-center justify-start  gap-2">
                                {item?.name}
                              </div>
                              <ChipButton className="text-xs h-[22px] !px-1.5" childrenClassName="text-global-paragraph-3">Supplier</ChipButton>
                            </Button>
                          ))}
                        </div>
                      )}
                    {searchedAllData?.retailers &&
                      searchedAllData?.retailers?.data &&
                      searchedAllData?.retailers?.data?.length > 0 && (
                        <div className="flex flex-col gap-[1px]">
                          {searchedAllData?.retailers?.data?.map((item, index) => (
                            <Button
                              key={index}
                              type="button"
                              variant={"action"}
                              className={`flex items-center justify-start text-base w-full font-medium text-global-title gap-2 px-2.5 h-[40px] rounded-sm  hover:bg-[#FAFAFA] `}
                              onClick={(e) => handleGlobalsearchItemClick(item, "retailer")}
                            >
                              <div className="flex items-center justify-start  gap-2">
                                {item?.name}
                              </div>
                              <ChipButton className="text-xs h-[22px] !px-1.5" childrenClassName="text-global-paragraph-3">Retailer</ChipButton>
                            </Button>
                          ))}
                        </div>
                      )}
                    {searchedAllData?.sub_suppliers &&
                      searchedAllData?.sub_suppliers?.data &&
                      searchedAllData?.sub_suppliers?.data?.length > 0 && (
                        <div className="flex flex-col gap-[1px]">

                          {searchedAllData?.sub_suppliers?.data?.map((item, index) => (
                            <Button
                              key={index}
                              type="button"
                              variant={"action"}
                              className={`flex items-center justify-start text-base w-full font-medium text-global-title gap-2 px-2.5 h-[40px] rounded-sm  hover:bg-[#FAFAFA] `}
                              onClick={(e) => handleGlobalsearchItemClick(item, "sub_suppliers")}
                            >
                              <div className="flex items-center justify-start  gap-2">
                                {item?.name}
                              </div>
                              <ChipButton className="text-xs h-[22px] !px-1.5" childrenClassName="text-global-paragraph-3">Sub-Supplier</ChipButton>
                            </Button>
                          ))}
                        </div>
                      )}
                  </div>
                ) : (
                  <ResultNotFound text="Result not found" />
                )}
              </>
            ) : (
              <ResultNotFound text="Please type something" />
            )}
          </div>

        )}
      </div>


      {/* {pathname !== "/" && hasPermission("canGlobalCreate") && (
        <div className="flex items-center justify-center w-auto">
          <Button
            size="lg"
            className="p-2 md:p-[14px] rounded-full w-7 h-7 md:w-full md:h-full"
            aria-label="more"
            id="long-button"
            aria-controls={open ? "long-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-haspopup="true"
            onClick={(e) => {
              e.stopPropagation();
              setAnchorEl(e.currentTarget);
            }}
          >
            <Plus fillColor={"#fff"} />
          </Button>
          <div
            onClick={(e) => {
              e.stopPropagation();
              setAnchorEl(null);
            }}
          >
            <Menu
              id="long-menu"
              MenuListProps={{
                "aria-labelledby": "long-button",
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              PaperProps={{
                style: {
                  padding: 0,
                  borderRadius: "8px",
                  marginTop: "3px",
                  boxShadow: "0px 4px 32px 0px rgba(40, 54, 36, 0.24)",
                  backgroundColor: "transparent",
                },
              }}
            >
              <div className="flex flex-col items-center justify-start p-3 bg-[#FAFAFA]">
                <div className="grid gap-2">
                  <Button
                    className={`flex items-center justify-start w-full gap-2 px-3  py-2 rounded bg-transparent hover:bg-transparent ${!hasPermission("createOrder") ? "cursor-not-allowed text-btn-text-primary-disabled " : "cursor-pointer text-option-text-default"}`}
                    onClick={(e) =>
                      !hasPermission("createOrder")
                        ? handleShowToaster("order")
                        : handleClickPlus(e, "add-order")
                    }
                  >
                    <div>
                      <ReportIcons
                        fillColor={
                          !hasPermission("createOrder")
                            ? "var(--color-icon-fill-disable)"
                            : "var(--color-icon-fill-default)"
                        }
                        width={16}
                        height={16}
                      />
                    </div>
                    <div className="text-sm font-normal">Add Order</div>
                  </Button>
                  <Button
                    className={`flex items-center justify-start w-full gap-2 px-3  py-2 rounded bg-transparent hover:bg-transparent ${!hasPermission("createArticle") ? "cursor-not-allowed text-btn-text-primary-disabled " : "cursor-pointer text-option-text-default"}`}
                    onClick={(e) =>
                      !hasPermission("createArticle")
                        ? handleShowToaster("article")
                        : handleClickPlus(e, "add-articles")
                    }
                  >
                    <div>
                      <LableDashboard
                        fillColor={
                          !hasPermission("createArticle")
                            ? "var(--color-icon-fill-disable)"
                            : "var(--color-icon-fill-default)"
                        }
                        width={16}
                        height={16}
                      />
                    </div>
                    <div className="text-sm font-normal">Add Articles</div>
                  </Button>

                  <div
                    className="cursor-pointer flex items-center justify-start w-full gap-2 px-3 py-2 rounded hover:bg-transparent"
                    onClick={(e) => handleClickPlus(e, "add-templates")}
                  >
                    <div>
                      <ShieldDashbaord
                        fillColor={`var(--color-icon-fill-default)`}
                        width={16}
                        height={16}
                      />
                    </div>
                    <div className="text-sm font-normal text-option-text-default">
                      Add Templates
                    </div>
                  </div>
                </div>
              </div>
            </Menu>
          </div>
        </div>
      )} */}
    </div>
  );
};

export default GlobalActions;
