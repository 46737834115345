import React from "react";

const AttachFiles = React.forwardRef(
  (
    {
      width = "20",
      height = "20",
      viewBox = "0 0 20 20",
      fill = "none",
      strokeColor = "white",
      className,
      ...props
    },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Content">
          <path
            id="Vector"
            d="M7.5013 14.1668H5.83464C3.53345 14.1668 1.66797 12.3013 1.66797 10.0002C1.66797 7.69898 3.53345 5.8335 5.83464 5.8335H7.5013M12.5013 14.1668H14.168C16.4692 14.1668 18.3346 12.3013 18.3346 10.0002C18.3346 7.69898 16.4692 5.8335 14.168 5.8335H12.5013M5.83464 10.0002L14.168 10.0002"
            stroke={strokeColor}
            strokeWidth="1.66667"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </svg>
    );
  },
);

AttachFiles.displayName = "AttachFiles";
export default AttachFiles;
