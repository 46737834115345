import React from "react";

const MaterialProductionIcon = React.forwardRef(
  (
    {
      width = "126",
      height = "126",
      viewBox = "0 0 126 126",
      fill = "none",

      className,
      ...props
    },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="63" cy="63" r="63" fill="#37C290" fill-opacity="0.1" />
        <circle cx="63" cy="63" r="53" fill="#37C290" fill-opacity="0.1" />
        <circle cx="63" cy="63" r="44" fill="#11C182" />
        <path
          d="M62.4998 44L41 56L62.4998 68L84 56L62.4998 44Z"
          fill="url(#paint0_linear_503_9621)"
        />
        <path
          d="M62.4998 51L41 63L62.4998 75L84 63L62.4998 51Z"
          fill="url(#paint1_linear_503_9621)"
        />
        <path
          d="M62.4998 58L41 70L62.4998 82L84 70L62.4998 58Z"
          fill="url(#paint2_linear_503_9621)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_503_9621"
            x1="69.6667"
            y1="74.4286"
            x2="80.9464"
            y2="45.4097"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="white" />
            <stop offset="1" stop-color="white" stopOpacity="0" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_503_9621"
            x1="69.6667"
            y1="81.4286"
            x2="80.9464"
            y2="52.4097"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="white" />
            <stop offset="1" stop-color="white" stopOpacity="0" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_503_9621"
            x1="69.6667"
            y1="88.4286"
            x2="80.9464"
            y2="59.4097"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="white" />
            <stop offset="1" stop-color="white" stopOpacity="0" />
          </linearGradient>
        </defs>
      </svg>
    );
  },
);
export default MaterialProductionIcon;
