import { LogoCollpased, NotificationIcon, PDFIcon, Random } from "assests";
import moment from "moment";
import { useEffect, useRef, useState } from "react";

const Lists = ({ handleScroll, datas, handleNotificationClick }) => {
  const divRef = useRef(null);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [fileSizes, setFileSizes] = useState({});
  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };
  const notificationArray = [
    {
      image: Random,
      icon: LogoCollpased,
      title: "New order",
      message: "You have a new order",
      reference_key: "new-order",
      isRead: false,
      time: "2h ago",
      file: "delux.pdf",
    },
    {
      image: Random,
      title: "New order",
      message: "You have a new order",
      reference_key: "new-order",
      isRead: false,
      time: "just now",
      file: "",
    },
    {
      image: "",
      title: "New order",
      message: "You have a new order",
      reference_key: "new-order",
      isRead: false,
      time: "just now",
      file: "delux.pdf",
    },
  ];
  // Function to convert bytes to human-readable format
  const formatFileSize = (bytes, decimalPoint = 2) => {
    if (bytes === 0) return "0 Bytes";
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    return parseFloat((bytes / Math.pow(1024, i)).toFixed(decimalPoint)) + " " + sizes[i];
  };

  // Function to fetch file size
  const fetchFileSize = async (url) => {
    try {
      const response = await fetch(url, { method: "HEAD" });
      if (response.ok) {
        const contentLength = response.headers.get("Content-Length");
        return parseInt(contentLength, 10); // Return size in bytes
      }
    } catch (error) {
      console.error("Error fetching file size:", error);
    }
    return null; // Return null if there's an error
  };

  // Effect to fetch sizes for all cert_files when component mounts or datas change
  useEffect(() => {
    const getFileSizes = async () => {
      const sizes = {};
      for (const item of datas) {
        if (item.cert_files) {
          const size = await fetchFileSize(item.cert_files);
          if (size !== null) {
            sizes[item.reference_key] = size; // Store by reference_key for easy access
          }
        }
      }
      setFileSizes(sizes); // Update state with fetched sizes
    };

    getFileSizes();
  }, [datas]);

  return (
    <>
      <div className="w-full overflow-y-auto h-[calc(100vh_-_90px)] lg:h-[calc(100vh_-_90px)]">
        <div
          ref={divRef}
          id="notificationContainer"
          onScroll={handleScroll}
          className="pb-6  z-[2]"
        >
          {datas.length > 0 ? (
            <div className="relative z-0">
              {datas?.map((item, index) => {
                return (
                  <div
                    onMouseEnter={() => handleMouseEnter(index)}
                    onMouseLeave={handleMouseLeave}
                    key={index}
                    onClick={() => handleNotificationClick(item.reference_key, item)}
                    className={`w-full px-6 grid grid-cols-12 items-start justify-start rounded-lg ${hoveredIndex === index ? "bg-notification-stroke-hover" : ""}`}
                  >
                    <div
                      className={`col-span-2 gap-1 flex flex-col h-full  items-center ${index === datas.length - 1 ? "justify-start" : "justify-center"}`}
                    >
                      <div className="grid grid-cols-1 gap-0 relative">
                        {item?.image ? (
                          <div className="flex flex-col items-center">
                            <img
                              src={item.image}
                              alt="notification"
                              className="h-12 w-12 rounded-full z-20 border border-white border-opacity-10"
                            />
                            <div className="flex items-center">
                              <div className="flex-1 h-auto w-[2px] bg-white z-20"></div>
                            </div>
                          </div>
                        ) : (
                          <div className="flex flex-col items-center">
                            <div className="h-12 w-12 bg-global-primary rounded-full flex justify-center items-center z-20 border border-white border-opacity-10">
                              <LogoCollpased className="w-[11.97px] h-[19px]" fillColor={"white"} />
                            </div>
                            {/* Wrapper for image and vertical line */}
                            <div className="flex items-center">
                              <div className="flex-1 h-full w-[2px] bg-white z-20"></div>
                            </div>
                          </div>
                        )}
                      </div>

                      <div
                        className={`bg-[#E4E7EC] w-[2px] h-full rounded-[2px] mb-1 ${index === datas.length - 1 ? "hidden" : "block"}`}
                      ></div>
                    </div>

                    <div className="col-span-10 cursor-pointer flex flex-col ml-3 pb-8">
                      <div className="flex justify-between items-center gap-3">
                        <div className="flex justify-start items-center gap-2">
                          <div
                            className={`text-sm leading-5 font-medium truncate ${item?.isRead
                              ? "text-notification-text-read"
                              : "text-notification-text-unread"
                              }`}
                          >
                            {item?.title}
                          </div>
                          <div className="text-xs font-normal text-global-paragraph leading-[18px] whitespace-nowrap">
                            {item?.created_at ? moment(item.created_at).fromNow() : ""}
                          </div>
                        </div>
                        <div
                          className={`w-2 h-2 rounded-full ${item.isRead ? "bg-icon-fill-notification-read" : "bg-icon-fill-notification-unread"}`}
                        ></div>
                      </div>
                      <div className="flex flex-col gap-3">
                        <div
                          className={`text-sm font-normal leading-5 ${item?.isRead ? "text-notification-text-read" : "text-global-paragraph"
                            }`}
                        >
                          <div dangerouslySetInnerHTML={{ __html: item?.message }} />
                        </div>
                        {item?.cert_files &&
                          item?.cert_files.length > 0 &&
                          item?.cert_files.map((certItem, index) => (
                            <a
                              onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                              }}
                              href={certItem?.cert_files}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <div className="flex justify-start items-center gap-3">
                                <PDFIcon
                                  width={40}
                                  height={40}
                                  fillColor={"var(--color-global-primary)"}
                                />

                                <div className="flex flex-col">
                                  <div className="text-sm font-medium text-global-title-2 leading-5">
                                    {certItem?.cert_files?.split("/")?.pop()}
                                  </div>
                                  {fileSizes[certItem.reference_key] !== undefined ? (
                                    <p className="text-global-paragraph text-sm leading-5">
                                      {certItem?.reference_key &&
                                        formatFileSize(fileSizes[certItem?.reference_key])}
                                    </p>
                                  ) : (
                                    <p className="text-global-paragraph text-sm leading-5">
                                      0 Bytes
                                    </p>
                                  )}
                                </div>
                              </div>
                            </a>
                          ))}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="w-full h-full flex-col justify-center items-center inline-flex">
              <NotificationIcon width={124} height={124} alt="" />
              <div className="text-sm leading-5 font-medium text-global-gray-brand mt-6">
                No notification found
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Lists;
