import { Action, Cross, TicCircle, Uploadnew, XCircle } from "assests";
import { Button } from "components/UI/Button";
import CustomSelectEdition from "components/UI/CustomSelectEdition";
import Divider from "components/UI/Divider";
import { Input } from "components/UI/Input";
import Tooltip from "components/UI/Tooltip";
import UpgradeComponent from "components/UI/UpgradeComponent";
import { useSelector } from "react-redux";
import Datepicker from "react-tailwindcss-datepicker";
import { preventSpaceAndPasteNegative, preventSpaceMinus, setTimeInDateObject } from "utils/helper";

const Preview = ({
  data,
  seasons,
  articles,
  templates,
  suppliers,
  agents,
  retailers,
  control,
  onChangeHandler,
  onSubmit,
  closeModal,
  handleCancel,
  preview,
  setPreview,
  onSubmitBulkUpload,
  hasAnyError,
  uploadedData,
  showLimitText,
}) => {
  const { currentWorkspace } = useSelector((state) => state.authenticationSlice);
  const { workspacePlan } = useSelector((state) => state.billingSlice);
  const workspaceRolePrefix = currentWorkspace && currentWorkspace?.role_name?.split(".")[0];
  return (
    <>
      <div className="grid grid-cols-1 h-full z-[2]">
        <div className="absolute right-5 top-5 cursor-pointer  z-[2]">
          <Cross fillColor={"#A4A7AE"} height={24} width={24} onClick={closeModal} />
        </div>
        <div className="flex justify-start ml-6 z-[1]">
          <UpgradeComponent>
            <Uploadnew />
          </UpgradeComponent>
          <div className="flex justify-between lg:gap-4 px-3 pb-9 lg:px-4 z-[2] ">
            <div className="flex flex-col gap-1">
              <h2 className="font-semibold text-sm lg:text-lg leading-7 text-start text-primary-900 w-full">
                {data.some((item) => Object.values(item.error).includes(true))
                  ? "Missing Fields"
                  : "Order Upload"}
              </h2>
              <div className="flex justify-start items-center">
                <p className="text-sm text-switch-active-gray font-normal text-wrap leading-5">
                  {data.some((item) => Object.values(item.error).includes(true))
                    ? `We found ${data?.length} orders in your file . Please correct any errors before proceeding.`
                    : ` ${data?.length} orders found. You may proceed with the next steps.`}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="w-auto z-[3] px-6">
          {showLimitText && (
            <div className="px-6 py-4">
              <div className="h-11 px-4 py-3 bg-white rounded-xl border border-[#e4e7ec] justify-start items-center gap-3 inline-flex overflow-hidden">
                <Action width={20} height={20} />
                <div className="grow shrink basis-0 text-[#535862] bg-transparent text-sm font-normal leading-tight">{`You cannot add a new order as you have reached the maximum limit of ${workspacePlan && workspacePlan?.package?.order_lines} orders in your ${workspacePlan && workspacePlan?.package?.name} plan. Please reduce your usage or upgrade your plan.`}</div>
              </div>
            </div>
          )}
          <div className="overflow-x-auto relative">
            <table className="table-auto border-spacing-y-3 px-6">
              <thead>
                <tr className="text-xs leading-3 font-medium text-table-text-header ">
                  <th className="px-6 pb-3 whitespace-nowrap">Order Number</th>
                  <th className="px-6 pb-3 whitespace-nowrap">Order Date</th>
                  <th className="px-6 pb-3 whitespace-nowrap">Article Number</th>
                  <th className="px-6 pb-3 whitespace-nowrap">Quantity (pcs)</th>
                  <th className="px-6 pb-3 whitespace-nowrap">Season</th>
                  <th className="px-6 pb-3 whitespace-nowrap">Supply Chain Template</th>
                  {workspaceRolePrefix && workspaceRolePrefix === "agent" && (
                    <>
                      <th className="px-6 pb-3 whitespace-nowrap">Client Name</th>
                      <th className="px-6 pb-3">
                        <div className="flex items-center gap-1">
                          <span className="whitespace-nowrap">Supplier Name</span>
                          <Tooltip text="As a agent, you are able to send invitations to a supplier" />
                        </div>
                      </th>
                    </>
                  )}
                  {workspaceRolePrefix &&
                    ["retailer", "importer", "brand"].includes(workspaceRolePrefix) && (
                      <>
                        <th className="px-6 pb-3">
                          <div className="flex items-center gap-1">
                            <span className="whitespace-nowrap">Agent Name</span>
                            <Tooltip text="As a client, you are able to send invitations to a agent" />
                          </div>
                        </th>
                        <th className="px-6 pb-3">
                          <div className="flex items-center gap-1">
                            <span className="whitespace-nowrap">Supplier Name</span>
                            <Tooltip text="As a client, you are able to send invitations to a supplier" />
                          </div>
                        </th>
                      </>
                    )}
                  {workspaceRolePrefix && workspaceRolePrefix === "supplier" && (
                    <>
                      <th className="px-6 pb-3 whitespace-nowrap">Client Name</th>
                    </>
                  )}
                </tr>
              </thead>
              <tbody>
                {data.map((item, index) => (
                  <tr
                    key={index}
                    className={`whitespace-nowrap border-b last:border-0 border-b-global-divider-soft  ${index % 2 === 0 ? "bg-global-white" : "bg-white"} rounded-lg text-global-paragraph text-sm font-normal cursor-pointer`}
                  >
                    <td className="px-6 py-3">
                      <div>
                        <Input
                          type="text"
                          defaultValue={item.orderNumber}
                          maxLength={100}
                          onChange={(e) => onChangeHandler(index, "orderNumber", e.target.value)}
                          isError={item?.error?.orderNumberError}
                          onPaste={preventSpaceAndPasteNegative}
                          onKeyPress={preventSpaceMinus}
                          className={`text-base text-global-primary-900 min-w-[88px] max-w-[135px]  font-normal leading-normal rounded-lg px-3.5 custom-card`}
                        />
                      </div>
                    </td>
                    <td className="px-6 py-3">
                      <div className="relative w-[144px]">
                        <Datepicker
                          name={`orderDate${index}`}
                          useRange={false}
                          asSingle={true}
                          value={item.orderDate}
                          placeholder={"Select date"}
                          displayFormat="DD/MM/YYYY"
                          containerClassName="certificate-date-picker "
                          inputClassName={`flex justify-between w-full gap-3 px-4 h-[44px] relative py-2 items-center text-global-title text-base placeholder:text-input-text-placeholder placeholder:text-base placeholder:font-normal self-stretch border border-dropdown-stroke-default focus:shadow-[none] focus:ring-offset-input-surface-background focus:border-input-stroke-active focus:ring-input-stroke-active bg-dropdown-surface-background rounded-lg z-[2]`}
                          primaryColor={"emerald"}
                          toggleClassName="absolute text-icon-fill-input custom-toggle-icon right-0 h-full z-[4]"
                          onChange={(value) => {
                            onChangeHandler(index, "orderDate", setTimeInDateObject(value));
                          }}
                          popperPlacement="auto"
                          popperModifiers={{
                            preventOverflow: {
                              enabled: true,
                            },
                          }}
                        />
                      </div>
                    </td>
                    <td className="px-6 py-3 ">
                      <div className="min-w-[135px]">
                        <CustomSelectEdition
                          control={control}
                          options={articles}
                          name={`article${index}`}
                          value={item.article}
                          handleOnChange={(value) => onChangeHandler(index, "article", value)}
                          isError={item?.error?.articleError}
                          style={{
                            padding: "4.5px 10px 5.5px 4px",
                            fontSize: "12px",
                            minWidth: "320px",
                          }}
                        />
                      </div>
                    </td>
                    <td className="px-6 py-3 ">
                      <Input
                        type="text"
                        defaultValue={item.quantity}
                        onChange={(e) => onChangeHandler(index, "quantity", e.target.value)}
                        isError={item?.error?.quantityError}
                        className={`text-base text-global-primary-900 min-w-[68px] max-w-[135px]  font-normal leading-normal rounded-lg px-3.5 custom-card`}
                      />
                    </td>
                    <td className="px-6 py-3 ">
                      <div className="min-w-[126px]">
                        <CustomSelectEdition
                          control={control}
                          options={seasons}
                          name={`season${index}`}
                          value={item.season}
                          handleOnChange={(value) => onChangeHandler(index, "season", value)}
                          isError={item.error.seasonError}
                          style={{
                            padding: "4.5px 10px 5.5px 4px",
                            fontSize: "12px",
                            minWidth: "320px",
                          }}
                        />
                      </div>
                    </td>
                    <td className="px-6 py-3 ">
                      <div className="min-w-[120px]">
                        <CustomSelectEdition
                          control={control}
                          options={templates}
                          name={`template${index}`}
                          value={item.template}
                          handleOnChange={(value) => onChangeHandler(index, "template", value)}
                          isError={item.error.templateError}
                          style={{
                            padding: "4.5px 10px 5.5px 4px",
                            fontSize: "12px",
                            minWidth: "320px",
                          }}
                        />
                      </div>
                    </td>

                    {workspaceRolePrefix &&
                      workspaceRolePrefix !== "retailer" &&
                      workspaceRolePrefix !== "importer" &&
                      workspaceRolePrefix !== "brand" && (
                        <>
                          <td className="px-6 py-3 ">
                            <div className="min-w-[120px]">
                              <CustomSelectEdition
                                control={control}
                                options={retailers}
                                name={`retailer${index}`}
                                value={item?.retailer ? item?.retailer : null}
                                handleOnChange={(value) => {
                                  onChangeHandler(index, "retailer", value);
                                }}
                                isError={item.error.retailerError}
                                style={{
                                  padding: "4.5px 10px 5.5px 4px",
                                  fontSize: "12px",
                                  minWidth: "320px",
                                }}
                                isDisabled={
                                  !currentWorkspace.is_owner &&
                                  ["retailer", "importer", "brand"].includes(
                                    currentWorkspace.invited_workspace_type,
                                  )
                                }
                              />
                            </div>
                          </td>
                        </>
                      )}
                    {workspaceRolePrefix &&
                      ["retailer", "importer", "brand"].includes(workspaceRolePrefix) && (
                        <>
                          <td className="px-6 py-3 ">
                            <div className="min-w-[120px]">
                              <CustomSelectEdition
                                control={control}
                                options={agents}
                                name={`agent${index}`}
                                value={item?.agent ? item?.agent : null}
                                handleOnChange={(value) => onChangeHandler(index, "agent", value)}
                                isError={item.error.agentError}
                                style={{
                                  padding: "4.5px 10px 5.5px 4px",
                                  fontSize: "12px",
                                  minWidth: "320px",
                                }}
                                isWatchedValueNeed={false}
                                isDisabled={
                                  !currentWorkspace.is_owner &&
                                  (currentWorkspace.invited_workspace_type === "agent" || currentWorkspace.invited_workspace_type === "supplier")
                                }
                              />
                            </div>
                          </td>
                        </>
                      )}

                    {workspaceRolePrefix !== "supplier" && (
                      <td className="px-6 py-3 ">
                        <div className="min-w-[120px]">
                          <CustomSelectEdition
                            control={control}
                            options={suppliers}
                            name={`supplier${index}`}
                            value={item?.supplier ? item?.supplier : null}
                            handleOnChange={(value) => {
                              onChangeHandler(index, "supplier", value);
                            }}
                            isError={item.error.supplierError}
                            style={{
                              padding: "4.5px 10px 5.5px 4px",
                              fontSize: "12px",
                              minWidth: "320px",
                            }}
                            isWatchedValueNeed={false}
                            isDisabled={
                              !currentWorkspace.is_owner &&
                              (currentWorkspace.invited_workspace_type === "agent" || currentWorkspace.invited_workspace_type === "supplier")
                            }
                          />
                        </div>
                      </td>
                    )}
                    <td className="px-4 pb-2">
                      {Object.values(item.error).some((value) => value === true) ? (
                        <>
                          <XCircle
                            fillColor={"var(--color-icon-fill-danger)"}
                            width={24}
                            height={24}
                          />
                        </>
                      ) : (
                        <TicCircle
                          fillColor={"var(--color-icon-fill-active)"}
                          width={26}
                          height={26}
                        />
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        <div className="mt-8">
          <Divider />
        </div>
        <div className="flex justify-between gap-4 pt-6 px-6">
          <div className="w-full flex justify-end items-center gap-3 ">
            <Button
              type="button"
              size={"md"}
              variant={"secondary"}
              className={`w-auto h-11 px-4 py-2.5 text-base leading-6 font-semibold `}
              onClick={handleCancel}
            >
              Cancel
            </Button>
            {!preview && (
              <Button
                type="button"
                size={"md"}
                className={`w-auto h-11 px-4 py-2.5 text-base leading-6 font-semibold `}
                isDisabled={!uploadedData}
                onClick={() => setPreview(true)}
              >
                Upload
              </Button>
            )}
            {preview && (
              <Button
                type="button"
                size={"md"}
                variant={"primary"}
                className={`w-auto h-11 px-4 py-2.5 text-base leading-6 font-semibold `}
                isDisabled={hasAnyError()}
                onClick={onSubmitBulkUpload}
              >
                Upload
              </Button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default Preview;
