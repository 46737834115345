import { CheckIcon, ClockIcon, DownloadCloudIcon, Lock, Mail01Icon, PDFIcon } from "assests";
import { Button } from "components/UI/Button";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useMutation, useQuery } from "@tanstack/react-query";
import EmptyTable from "components/Card/EmptyTable";
import BackToDashboard from "components/UI/BackToDashboard";
import ChipButton from "components/UI/ChipButton";
import CommonCard from "components/UI/CommonCard";
import Divider from "components/UI/Divider";
import LoadingComponent from "components/UI/LoadingComponent";
import Modal from "components/UI/Modal";
import SettingsPermissionModal from "components/UI/SettingsPermissionModal";
import SortIcons from "components/UI/SortIcons";
import UpdatedPagination from "components/UI/UpdatedPagination";
import WeCare from "components/UI/WeCare";
import CompanyDetailsUpgrade from "components/UpgradePlan/CompanyDetailsUpgrade";
import useCustomToaster from "hooks/useCustomToaster";
import useSubscriptionModel from "hooks/useSubscriptionModel";
import useSubscriptionPlan from "hooks/useSubscriptionPlan";
import SubNavbar from "layout/SubNavbar";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getBillingDetails, getPaymentMethods } from "services/billing.services";
import {
  cancelCurrentSubscriptionPlan,
  downloadSubscriptionAllInvoice,
  downloadSubscriptionInvoice,
  getInvoiceList,
  getUserSubscriptionDetails,
  recreateSubscription,
} from "services/subscription.services";
import { setAuthentication } from "store/authenticationSlice";
import {
  setBillingDetails,
  setCurrentPlan,
  setInvoiceData,
  setIsEditingBillingDetails,
  setPaymentMethodDetails,
} from "store/billingSlice";
import { setShowCompanyDetails } from "store/subscriptionSlice";
import { findIcon, getStatusButtonVariant, getStatusIconColor } from "utils/fakeData";
import { formateDateToMonthYear } from "utils/helper";
import CancelSubsciptionConfirmation from "./CancelSubsciptionConfirmation";
import RecreateSubsConfirmationModal from "./RecreateSubsConfirmationModal";
import SubscriptionRow from "./SubscriptionRow";
import UpdatePaymetMethod from "./UpdatePaymetMethod";
import UpgradePlanModal from "./UpgradePlanModal";
import TopNavbar from "components/TopHeader/TopNavbar";
import BreadCrumb from "components/TopHeader/BreadCrumb";
import EmptyComponent from "components/Card/EmptyComponent";
const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}`);

// Subscription statick variable data
const SubscriptionDataValue = [
  {
    label: "Orderline",
    value: "orders_count",
    limit: "order_lines",
    progressVariant: "green",
  },
];

const BillingList = () => {
  const dispatch = useDispatch();
  const { showCustomToast } = useCustomToaster();

  //Custom hook initialization
  const {
    isSubscriptionCreateModalOpen,
    openCreateModal,
    closeModal: closeCreateSubscriptionOpenModal,
  } = useSubscriptionModel();

  useSubscriptionPlan();

  // Global state or redux state
  const { currentWorkspace, workspaces, subscription, subscription_status, user } = useSelector(
    (state) => state.authenticationSlice,
  );
  const {
    currentPlan,
    invoiceData,
    billingDetails: currentBillingDetails,
    isEditingBillingDetails,
    paymentMethodDetails: currentPaymentMethodDetails,
  } = useSelector((state) => state.billingSlice);
  const { showCompanyDetails } = useSelector((state) => state.subscriptionSlice);
  const [orderBy, setOrderBy] = useState("");
  const [isHovered, setIshovered] = useState(false);
  // local state
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [isOpenPlanCancelModal, setIsOpenPlanCancelModal] = useState(false);
  const [isOpenUpdatePaymentMethodModal, setIsOpenUpdatePaymentMethodModal] = useState(false);
  const [isSubscriptionUpgradeModalOpen, setIsSubscriptionUpgradeModalOpen] = useState(false);
  const [showRecreateSubsConfirmationModal, setShowRecreateSubsConfirmationModal] = useState(false);
  const { isSidebarCollapsed } = useSelector((state) => state.navbarSlice);
  const navigate = useNavigate();
  // Queries
  const {
    data: userSubscriptionQueryData,
    isLoading: isLoadingUserSubsQuery,
    refetch,
    error: userSubscriptionFetchError,
  } = useQuery({
    queryKey: ["get", "subscription", currentWorkspace],
    queryFn: async () => {
      const workspaceId = parseInt(currentWorkspace?.workspace_id);
      return getUserSubscriptionDetails(workspaceId);
    },
    enabled: !!currentWorkspace && subscription,
    select: (data) => data.data,
  });

  const {
    data: billingDetails,
    isLoading: billingDetailsLoading,
    status,
  } = useQuery({
    queryKey: ["billingDetails", currentWorkspace?.workspace_id],
    queryFn: () => getBillingDetails(currentWorkspace?.workspace_id),
    enabled: !!currentWorkspace,
  });

  const {
    data: paymentMethodDetails,
    isLoading: paymentMethodDetailsLoading,
    status: paymentMethodDetailsStatus,
  } = useQuery({
    queryKey: ["paymentMethodDetails", currentPlan],
    queryFn: () => getPaymentMethods(currentPlan?.subscription?.payment_method),
    enabled: !!currentPlan,
  });

  const { data: invoiceQueryData, isLoading: isLoadingInvoiceQuery } = useQuery({
    queryKey: ["get", "invoice", currentWorkspace, size, page, orderBy],
    queryFn: () => {
      const data = {
        workspace_id: parseInt(currentWorkspace?.workspace_id),
        page,
        size,
        sort: orderBy,
      };
      return getInvoiceList(data);
    },
    enabled: !!currentWorkspace,
    select: (data) => data.data,
  });

  // Cancel current active subscription mutation
  const cancelCurrentSubscribePlan = useMutation({
    mutationKey: ["cancel", "subscription"],
    mutationFn: cancelCurrentSubscriptionPlan,
    onMutate: () => {
      setIsOpenPlanCancelModal(false);
      showCustomToast(
        {
          title: "Canceling current subscription....",
        },
        "canceling_subscription",
      );
    },
    onSuccess: (res) => {
      toast.dismiss("canceling_subscription");
      if (res.data.status) {
        refetch();
        dispatch(setAuthentication({ subscription: false, subscription_status: "canceled" }));
        showCustomToast({
          title: "Subscription plan has been cancelled!",
        });
      }
    },
    onError: () => {
      toast.dismiss("canceling_subscription");
      showCustomToast({
        title: "Something went wrong",
      });
    },
  });

  // Recreate/Resume the subscription
  const recreateSubscriptionMutation = useMutation({
    mutationKey: ["recreate", "subscription"],
    mutationFn: (data) => recreateSubscription(data),
    onMutate: () => {
      showCustomToast(
        {
          title: "Recreating subscription....",
        },
        "recreating_subscription",
      );
    },
    onSuccess: (res) => {
      toast.dismiss("recreating_subscription");
      if (res.data.status) {
        refetch();
        dispatch(setAuthentication({ subscription: true, subscription_status: "active" }));
        setShowRecreateSubsConfirmationModal(false);
        showCustomToast({
          title: "Subscription has been recreated successfully",
        });
      }
    },
    onError: () => {
      toast.dismiss("recreating_subscription");
      showCustomToast({
        title: "Something went wrong",
      });
    },
  });

  const downloadInvoiceMutation = useMutation({
    mutationKey: ["get", "invoice"],
    mutationFn: (workspaceId) => downloadSubscriptionInvoice(workspaceId),

    onMutate: () => {
      showCustomToast(
        {
          title: "Downloading invoice",
        },
        "downloading_invoice",
      );
    },
    onSuccess: (res) => {
      toast.dismiss("downloading_invoice");
      const blob = new Blob([res.data], { type: "application/pdf" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "invoice.pdf";
      document.body.appendChild(link); // Append the link to the body
      link.click();
      document.body.removeChild(link); // Remove the link after clicking
      URL.revokeObjectURL(link.href);
      URL.revokeObjectURL(link.href);
    },
    onError: (error) => {
      toast.dismiss("downloading_invoice");
      showCustomToast({
        title: error.response.data.message || error.message || "Soemthing went wrong",
      });
    },
  });

  const downloadAllInvoiceMutation = useMutation({
    mutationKey: ["get-all", "invoice-all"],
    mutationFn: () => downloadSubscriptionAllInvoice(parseInt(currentWorkspace?.workspace_id)),
    onMutate: () => {
      showCustomToast(
        {
          title: "Downloading invoices",
        },
        "downloading_all_invoices",
      );
    },
    onSuccess: (res) => {
      toast.dismiss("downloading_all_invoices");
      const blob = new Blob([res.data], { type: "application/zip" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "invoices.zip";
      document.body.appendChild(link); // Append the link to the body
      link.click();
      document.body.removeChild(link); // Remove the link after clicking
      URL.revokeObjectURL(link.href);
      URL.revokeObjectURL(link.href);
    },
    onError: (error) => {
      toast.dismiss("downloading_all_invoices");
      showCustomToast({
        title: error.response.data.message || error.message || "Soemthing went wrong",
      });
    },
  });

  /**
   * Updates the payment method modal state to open or close.
   *
   * @return {void} This function does not return anything.
   */
  const handleClickUpdatePaymentMethod = () => {
    setIsOpenUpdatePaymentMethodModal((prev) => !prev);
  };

  /**
   * Executes when the upgrade button is clicked. Sets the active modal step to 2 and opens the create modal.
   *
   * @return {void}
   */
  const onClickUpgrade = () => {
    setIsSubscriptionUpgradeModalOpen(true);
  };

  const handClickRecreateSubscription = () => {
    if (showRecreateSubsConfirmationModal) {
      const mutationData = {
        workspace_id: parseInt(currentWorkspace?.workspace_id),
      };
      recreateSubscriptionMutation.mutate(mutationData);
    } else {
      setShowRecreateSubsConfirmationModal(true);
    }
  };

  /**
   * Updates the size state with the provided data.
   *
   * @param {any} data - The new size data.
   * @return {void} This function does not return anything.
   */
  const handleSizeChange = (data) => {
    setSize(data);
  };

  const handleShowCompanyDetails = () => {
    dispatch(setShowCompanyDetails());
    dispatch(setIsEditingBillingDetails(!isEditingBillingDetails));
  };

  /**
   * Handles the cancellation of the current subscription plan. If the current plan has a customer ID and the plan cancel modal is open,
   * logs a confirmation message and executes the mutation to cancel the current subscription plan. Otherwise, sets the plan cancel modal to open.
   *
   * @return {void}
   */
  const handleCancelPlan = () => {
    if (currentPlan && currentPlan.customer_id && isOpenPlanCancelModal) {
      cancelCurrentSubscribePlan.mutate();
    } else {
      setIsOpenPlanCancelModal(true);
    }
  };

  /**
   * Closes the subscription upgrade modal by setting the state variable
   * `isSubscriptionUpgradeModalOpen` to `false`.
   *
   * @return {void}
   */
  const closeSubscriptionUpgradeModal = () => {
    setIsSubscriptionUpgradeModalOpen(false);
  };

  useEffect(() => {
    if (userSubscriptionQueryData && !isLoadingUserSubsQuery && !userSubscriptionFetchError) {
      const useruserSubscriptionQueryData = userSubscriptionQueryData.data;
      dispatch(setCurrentPlan(useruserSubscriptionQueryData));
    } else {
      console.log(userSubscriptionFetchError);
    }
  }, [userSubscriptionQueryData, isLoadingUserSubsQuery, userSubscriptionFetchError, dispatch]);

  useEffect(() => {
    if (!billingDetailsLoading && billingDetails && status === "success") {
      const billingDetailsData = billingDetails.data.billing;
      dispatch(setBillingDetails(billingDetailsData));
    }
  }, [billingDetails, billingDetailsLoading, status, dispatch]);

  useEffect(() => {
    if (invoiceQueryData && !isLoadingInvoiceQuery) {
      const payload = {
        data: invoiceQueryData.data.data,
        total: invoiceQueryData.data.total,
      };
      dispatch(setInvoiceData(payload));
    }
  }, [invoiceQueryData, isLoadingInvoiceQuery, dispatch]);

  useEffect(() => {
    if (
      !paymentMethodDetailsLoading &&
      paymentMethodDetails &&
      paymentMethodDetailsStatus === "success"
    ) {
      const paymentMethodDetailsData = paymentMethodDetails.data.methods;
      dispatch(setPaymentMethodDetails(paymentMethodDetailsData));
    }
  }, [paymentMethodDetails, paymentMethodDetailsLoading, paymentMethodDetailsStatus, dispatch]);

  return (
    <>
      <SettingsPermissionModal actionText="billing" />
      {/* Cancel Plan Confirmation Modal */}
      <Modal open={isOpenPlanCancelModal} close={() => setIsOpenPlanCancelModal(false)}>
        <CancelSubsciptionConfirmation
          {...{
            closeModal: () => setIsOpenPlanCancelModal(false),
            onClick: () => handleCancelPlan(),
            currentWorkspace,
            currentPlan,
          }}
        />
      </Modal>

      {/* Update payment method modal */}
      <Modal
        open={isOpenUpdatePaymentMethodModal}
        close={() => setIsOpenUpdatePaymentMethodModal(false)}
      >
        <Elements stripe={stripePromise}>
          <UpdatePaymetMethod closeModal={() => setIsOpenUpdatePaymentMethodModal(false)} />
        </Elements>
      </Modal>

      {/* Upgrade subscription modal */}
      <Modal open={isSubscriptionUpgradeModalOpen} close={closeSubscriptionUpgradeModal} title={""}>
        <UpgradePlanModal
          handleCancel={closeSubscriptionUpgradeModal}
          refetchSubscription={refetch}
        />
      </Modal>

      {/* Open recreate subscription modal */}

      <Modal
        open={showRecreateSubsConfirmationModal}
        close={() => setShowRecreateSubsConfirmationModal(false)}
      >
        <RecreateSubsConfirmationModal
          {...{
            currentPlan,
            onClose: () => setShowRecreateSubsConfirmationModal(false),
            handClickRecreateSubscription,
          }}
        />
      </Modal>

      <Modal open={showCompanyDetails} close={handleShowCompanyDetails}>
        <CompanyDetailsUpgrade {...{ handleShowCompanyDetails }} fromBilling={true} />
      </Modal>

      <div className=" pb-16">
        {!isLoadingUserSubsQuery &&
          currentWorkspace &&
          currentPlan &&
          currentWorkspace.is_owner && (!currentWorkspace.role_name.includes("editor") || !currentWorkspace.role_name.includes("viewer")) ? (
          <div className="flex flex-col flex-grow w-full h-full gap-y-4 md:gap-y-6 pt-0 ">
            {subscription_status && subscription_status === "canceled" && (
              <div className="flex gap-2 text-info-text-danger w-full bg-global-white rounded-xl px-8 py-2 text-sm items-center">
                <Lock fillColor={`var(--color-icon-outline-danger)`} height={14} width={14} />
                <p>You have canceled your subscription.</p>
              </div>
            )}

            <div>
              <div className="grid grid-cols-12 justify-start items-center gap-3 md:gap-6  h-full">
                <div className="col-span-12 lg:col-span-6 h-full">
                  <CommonCard
                    link={`/workspace/${currentWorkspace?.user_workspace_id}/setting?selectedTab=plan`}
                    btnText={"Edit plan"}
                    minHeight={171}
                    className="flex-col justify-start items-center"
                  >
                    <div className="justify-between items-start flex w-full h-full">
                      <div className="py-1 flex-col justify-start items-start gap-1 inline-flex">
                        <div className="justify-start items-center gap-2 inline-flex">
                          <div className="text-global-title uppercase text-base md:text-lg font-semibold leading-7">
                            {currentPlan?.plan?.name}
                          </div>
                          <ChipButton childrenClassName="text-sm lg:text-base">
                            {currentPlan?.plan?.interval === "month" ? "Monthly" : "Yearly"}
                          </ChipButton>
                        </div>
                        <div className=" text-global-paragraph text-sm font-normal  leading-tight">
                          Renews on {formateDateToMonthYear(currentPlan?.auto_renewal)}
                        </div>
                      </div>
                      <div className="justify-start items-start md:items-end gap-0.5 flex flex-col md:flex-row">
                        <div className="justify-start items-center gap-0.5 flex">
                          <div className="flex-col justify-start items-start gap-2.5 inline-flex">
                            <div className="text-global-title text-2xl leading-tight md:text-4xl font-semibold  md:leading-[44px]">
                              €
                            </div>
                          </div>
                          <div className="text-global-title text-2xl leading-tight md:text-5xl font-semibold  md:leading-[60px] tracking-[-2%]">
                            {parseInt(currentPlan?.plan?.price)}
                          </div>
                        </div>
                        <div className="justify-start items-start flex">
                          <div className="text-global-paragraph text-base font-medium  leading-normal">
                            per {currentPlan?.plan?.interval}
                          </div>
                        </div>
                      </div>
                    </div>

                    {SubscriptionDataValue.map((item, index) => (
                      <SubscriptionRow
                        key={index}
                        label={item.label}
                        value={currentPlan[item.value]}
                        limit={currentPlan.plan.package[item.limit]}
                        progressVariant={item.progressVariant}
                      />
                    ))}
                  </CommonCard>
                </div>
                <div className="col-span-12 lg:col-span-6 h-full">
                  <div className="rounded-xl bg-global-white-default min-h-[225px] w-full border border-border-secondary shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)]">
                    <div className="p-4 md:p-6 flex flex-col justify-start items-center gap-5">
                      <div className="flex-col justify-start items-start gap-1 inline-flex w-full">
                        <div className="text-global-title  text-base md:text-lg  font-semibold leading-7">
                          Payment method
                        </div>
                        <div className=" text-global-paragraph text-sm font-normal  leading-tight">
                          Change how you pay for your plan.
                        </div>
                      </div>

                      <div className="w-full p-4 border border-global-divider-soft rounded-lg">
                        <div className="flex justify-start items-start gap-4">
                          <div className="h-10 w-[58px] px-2 border border-global-divider-soft rounded-md flex justify-center items-center">
                            {currentPaymentMethodDetails?.card?.brand &&
                              findIcon(currentPaymentMethodDetails?.card?.brand, 40, 27)}
                          </div>
                          <div className="w-full">
                            <div className="flex justify-between items-center gap-2 w-full">
                              <div>
                                <h6 className="text-sm font-medium text-global-title-2">
                                  <span className="capitalize">
                                    {currentPaymentMethodDetails?.card?.brand}
                                  </span>{" "}
                                  ending in {currentPaymentMethodDetails?.card?.last4}
                                </h6>
                                <p className="text-sm font-normal text-global-paragraph">
                                  Expiry{" "}
                                  {currentPaymentMethodDetails?.card?.exp_month < 10
                                    ? `0${currentPaymentMethodDetails?.card?.exp_month}`
                                    : currentPaymentMethodDetails?.card?.exp_month}
                                  /{currentPaymentMethodDetails?.card?.exp_year}
                                </p>
                              </div>
                              <div>
                                <Button
                                  onClick={handleClickUpdatePaymentMethod}
                                  variant="secondary"
                                  className="px-3.5 py-2.5 w-[55px]"
                                >
                                  Edit
                                </Button>
                              </div>
                            </div>
                            <div className="pt-2">
                              <a href="mailto:jvanelk@gfg.asia">
                                <div className="flex items-center gap-1.5">
                                  <Mail01Icon width={16} height={16} />{" "}
                                  <p className="text-sm font-normal text-global-paragraph">
                                    {currentPaymentMethodDetails?.billing_details?.email}
                                  </p>
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <WeCare />
            <Divider className="bg-global-divider-medium h-[1px]  md:my-[11px]" />
            <div className="flex flex-col md:flex-row md:justify-between md:items-start gap-4">
              <div>
                <h6 className="text-lg text-global-title font-semibold">Billing history</h6>
                <p className="pt-1 font-normal text-sm text-global-paragraph">
                  View and download your invoices.
                </p>
              </div>
              <Button
                variant="secondary"
                onClick={() => downloadAllInvoiceMutation.mutate()}
                onMouseEnter={() => setIshovered(true)}
                onMouseLeave={() => setIshovered(false)}
              >
                <DownloadCloudIcon
                  width={20}
                  height={20}
                  strokeColor={
                    isHovered
                      ? "var(--color-icon-fill-secondary-button)"
                      : "var(--color-txt-secondary)"
                  }
                />{" "}
                Download all
              </Button>
            </div>

            <div className="w-full h-auto rounded-xl bg-table-surface-row-background border border-border-secondary shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)]">
              <div className="w-full h-auto overflow-x-auto overflow-y-visible">
                <table className="w-full table-auto  border-spacing-y-3 ">
                  <thead className="text-xs leading-[18px] text-left whitespace-nowrap text-global-gray-brand  border-b border-table-stroke-row-divider">
                    <tr>
                      <th className="relative flex items-center px-4 md:px-6 py-[13px] gap-1">
                        <span className="text-table-text-header font-semibold">Invoice</span>
                      </th>
                      <th className="relative px-4 md:px-6 py-[13px] gap-1">
                        <div className="flex justify-start items-center gap-1">
                          <span className="text-table-text-header font-semibold">
                            Billing date
                          </span>
                          <SortIcons
                            hasData={invoiceData && invoiceData.data.length > 0}
                            sortName="issue_date"
                            {...{ orderBy, setOrderBy }}
                          />
                        </div>
                      </th>

                      <th className="relative px-4 md:px-6 py-[13px] gap-1">
                        <div className="flex justify-start items-center gap-1">
                          <span className="text-table-text-header font-semibold">Status</span>
                        </div>
                      </th>
                      <th className="relative px-4 md:px-6 py-[13px] gap-1">
                        <div className="flex justify-start items-center gap-1">
                          <span className="text-table-text-header font-semibold">Amount</span>
                        </div>
                      </th>
                      <th className="relative px-4 md:px-6 py-[13px] gap-1">
                        <div className="flex justify-start items-center gap-1">
                          <span className="text-table-text-header font-semibold">Plan</span>
                        </div>
                      </th>

                      <th className="relative px-4 md:px-6 py-[13px] gap-1">
                        <div className="flex justify-start items-center gap-1 ">
                          <span className="text-table-text-header font-semibold ">Action</span>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {!isLoadingInvoiceQuery && invoiceData && invoiceData.data.length > 0 ? (
                      invoiceData.data.map((invoice, index) => (
                        <tr
                          key={index}
                          className={`whitespace-nowrap text-global-gray-brand text-sm border-b border-table-stroke-row-divider last:border-b-0 font-normal gap-2`}
                        >
                          <td className="px-4 md:px-6 py-4">
                            <div className="flex justify-start items-center text-table-text-data-dark font-medium text-sm gap-3">
                              <PDFIcon />
                              {invoice?.invoice_number
                                ? `INV-${invoice.invoice_number}`
                                : null}
                            </div>
                          </td>
                          <td className="px-4 md:px-6   py-4">
                            <div className="flex justify-start items-center text-table-text-data text-sm">
                              {invoice?.issue_date
                                ? formateDateToMonthYear(invoice?.issue_date)
                                : null}
                            </div>
                          </td>

                          <td className="px-4 md:px-6   py-4">
                            <div className="flex justify-start items-center">
                              <div
                                className={`h-[22px] pl-1.5 pr-2 py-0.5   rounded-full border justify-center items-center gap-0.5 inline-flex ${getStatusButtonVariant(invoice.status)} ${invoice.status === "open" && "cursor-pointer"}`}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  if (invoice.status === "open" && invoice.payment_url) {
                                    window.open(invoice.payment_url, "_blank", "noreferrer");
                                  }
                                }}
                              >
                                {invoice.status === "paid" ? (
                                  <CheckIcon
                                    width={12}
                                    height={12}
                                    fillColor={`${getStatusIconColor(invoice.status)}`}
                                  />
                                ) : (
                                  <ClockIcon
                                    width={12}
                                    height={12}
                                    strokeColor={`${getStatusIconColor(invoice.status)}`}
                                  />
                                )}

                                <div className="text-center  text-xs font-medium capitalize leading-[18px]">
                                  {invoice.status === "draft" ? "Upcoming" : invoice.status}
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className="px-4 md:px-6   py-4">
                            <div className="flex justify-start items-center text-table-text-data text-sm">
                              EUR €{invoice?.total ? invoice?.total : 0.0}
                            </div>
                          </td>
                          <td className="relative px-4 md:px-6   py-4">
                            <div className="flex justify-start items-center text-table-text-data text-sm uppercase">
                              {invoice?.plan_name ? invoice?.plan_name : null}
                            </div>
                          </td>

                          <td className="relative px-4 md:px-6   py-4">
                            <Button
                              variant={"link"}
                              onClick={() => downloadInvoiceMutation.mutate(invoice.id)}
                              className="p-0"
                            >
                              Download
                            </Button>
                          </td>
                        </tr>
                      ))
                    ) : isLoadingInvoiceQuery ? (
                      <tr>
                        <td colSpan={6} className="text-center pb-3">
                          <LoadingComponent />
                        </td>
                      </tr>
                    ) : (
                      <EmptyTable colSpan={6} />
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            {invoiceData && invoiceData.data && invoiceData.total > 0 && (
              <UpdatedPagination
                page={page}
                totalPages={Math.ceil(invoiceData.total / size)}
                setPage={setPage}
                size={size}
                handleSizeChange={handleSizeChange}
              />
            )}
            <Divider className="bg-global-divider-medium h-[1px] md:my-[11px]" />
            <div>
              <h6 className="text-lg text-global-title font-semibold">Billing details</h6>
              <p className="pt-1 font-normal text-sm text-global-paragraph">
                This information will be displayed on your invoice and used to calculate taxes.
              </p>
            </div>
            <div className="grid grid-cols-12 justify-start items-center gap-4 md:gap-6">
              <div className="col-span-12">
                <div className="w-full lg:w-[520px]">
                  <CommonCard
                    onClick={handleShowCompanyDetails}
                    btnText={"Edit details"}
                    minHeight={143}
                    className="flex-col justify-start items-center"
                  >
                    <div className="justify-between items-start flex w-full h-full">
                      <div className="py-1 flex-col justify-start items-start gap-1 inline-flex">
                        <div className="justify-start items-center gap-2 inline-flex">
                          <div className="text-global-title text-lg font-semibold leading-7">
                            {currentBillingDetails?.company_name}
                          </div>
                          <ChipButton className="!uppercase" childrenClassName="text-sm lg:text-base">
                            {currentPlan?.plan?.name ? currentPlan?.plan?.name : "FREE"}
                          </ChipButton>
                        </div>
                        <div>
                          <p className="text-global-paragraph text-xs lg:text-sm font-normal leading-tight">
                            {currentBillingDetails?.street}
                          </p>
                          <p className="text-global-paragraph text-xs lg:text-sm font-normal leading-tight">
                            {`${currentBillingDetails?.region}, ${currentBillingDetails?.zip} ${currentBillingDetails?.country_code}`}
                          </p>
                          <p className="text-global-paragraph text-xs lg:text-sm font-normal leading-tight">
                            {`${currentBillingDetails?.country}`}
                          </p>
                        </div>
                      </div>
                    </div>
                  </CommonCard>
                </div>
              </div>
            </div>
          </div>
        ) : isLoadingUserSubsQuery ? (
          <LoadingComponent />
        ) : (
          <EmptyComponent text="No data found!" />
        )}
      </div>

    </>
  );
};

export default BillingList;
