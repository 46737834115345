import { Autocomplete, LoadScript } from "@react-google-maps/api";
import { BgImg, PhoneBGImg, ShieldTickIcon } from "assests";
import BackButton from "components/UI/BackButton";
import { Button } from "components/UI/Button";
import CustomSelect from "components/UI/CustomSelect";
import ErrorMessage from "components/UI/ErrorMessage";
import FormHeader from "components/UI/FormHeader";
import { Input } from "components/UI/Input";
import { Label } from "components/UI/Label";
import SigninLogo from "components/UI/SigninLogo";
import { useGoogleMaps } from "contexts/GoogleMapsContext";
import { Country } from "country-state-city";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { setActiveStep } from "store/createAccountSlice";
import { handleAcceptNumbersOnKeyPress, handleAcceptNumbersOnPaste } from "utils/helper";

const CompanyDetails = ({
  control,
  register,
  getValues,
  setValue,
  nextStep,
  errors,
  handleChangeCompanyType,
  filterdInviteViewList,
}) => {
  const dispatch = useDispatch();
  const [address, setAddress] = useState(getValues("address") || "");
  const [autocomplete, setAutocomplete] = useState(null);

  const handleLogoClick = () => {
    dispatch(setActiveStep(1));
  };

  const { isLoaded } = useGoogleMaps();

  const onLoad = (autocomplete) => {
    setAutocomplete(autocomplete);
  };

  const onPlaceChanged = () => {
    if (autocomplete) {
      const place = autocomplete.getPlace();
      if (place.formatted_address) {
        const formattedAddress = place.formatted_address;
        setValue("address", formattedAddress);
        setAddress(formattedAddress);
      } else {
        const suggestedAddress = place.name;
        setValue("address", suggestedAddress);
        setAddress(suggestedAddress);
      }

      if (place.address_components) {
        let street = "";
        let zip = "";
        let region = "";
        let country = "";
        let countryCode = "";
        place.address_components.forEach((component) => {
          const types = component.types;
          if (types.includes("street_number")) {
            street = component.long_name + " ";
          }
          if (types.includes("route")) {
            street += component.long_name;
          }
          if (types.includes("postal_code")) {
            zip = component.long_name;
          }
          if (types.includes("locality")) {
            region = component.long_name;
          }
          if (types.includes("country")) {
            country = component.long_name;
            countryCode = component.short_name;
          }
        });
        let countryData = Country.getCountryByCode(country);
        if (!countryData) {
          countryData = Country.getAllCountries().find(
            (c) => c.name.toLowerCase() === country?.toLowerCase(),
          );
        }
        if (countryData) {
          setValue("country", countryData.name);
          setValue("country_code", countryData.isoCode);
        }
        setValue("street", street);
        setValue("zip", zip);
        setValue("region", region);
        setValue("country", country);
        setValue("country_code", countryCode);
      }
    }
  };

  return (
    <div className="flex items-center justify-center w-full ">
      <div className="z-[2] w-full flex flex-col items-center justify-center">
        <div
          className=" flex items-center justify-center w-full h-full "
          style={{ pointerEvents: "none" }}
        >
          <img src={BgImg} alt="" className="fixed top-0 hidden md:block z-[-1]" />
          <img src={PhoneBGImg} alt="" className="fixed top-0 block md:hidden z-[-1]" />
        </div>
        <div className="w-full  px-4 md:p-0 md:w-[360px]">
          <div className="flex flex-col gap-8 w-full">
            <div className="flex flex-col gap-6">
              <SigninLogo>
                <ShieldTickIcon width={28} height={28} />
              </SigninLogo>
              <FormHeader title="Company Details" subtitle="Please enter your company details." />
            </div>

            <div className="grid grid-cols-12 gap-5">
              <div className="col-span-12 ">
                <div className="grid gap-1.5">
                  <Label htmlFor="companyName">Company name</Label>
                  <Input
                    id="firstName"
                    type="text"
                    placeholder="Enter your company name"
                    {...register("companyName", {
                      setValueAs: (value) => value.trim(),
                    })}
                  />
                  <ErrorMessage errors={errors?.companyName?.message} />
                </div>
              </div>
              <div className="col-span-12 ">
                <div className="grid gap-1.5">
                  <Label htmlFor="company_type">Business type</Label>
                  <CustomSelect
                    control={control}
                    name="company_type"
                    placeholder="Select"
                    options={filterdInviteViewList}
                    isClearable={false}
                    style={{
                      padding: "3.337px 10px 3.337px 4px",
                      fontSize: "16px",
                      width: "100%",
                    }}
                    handleOnChange={handleChangeCompanyType}
                    {...register("company_type")}
                  />

                  <ErrorMessage errors={errors?.company_type?.message} />
                </div>
              </div>

              <div className="col-span-12  relative">
                <div className="grid  gap-1.5 ">
                  <Label htmlFor="address">Address</Label>
                  <div>
                    {isLoaded && (
                      <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
                        <Input
                          id="address"
                          type="text"
                          placeholder="Enter your company address"
                          value={address}
                          onChange={(e) => {
                            console.log(e);
                            setAddress(() => e.target.value);
                          }}
                        />
                      </Autocomplete>
                    )}
                  </div>
                  <ErrorMessage errors={errors?.address?.message} />
                </div>
              </div>
              <div className="col-span-12 ">
                <div className="grid gap-1.5">
                  <Label htmlFor="regNum">Company registration</Label>
                  <Input
                    id="regNum"
                    type="text"
                    placeholder="Enter your registration number"
                    {...register("registration_number", {
                      setValueAs: (value) => value.trim(),
                    })}
                  />
                  <ErrorMessage errors={errors?.registration_number?.message} />
                </div>
              </div>

              <div className="col-span-12 pt-1">
                <div className="flex justify-center">
                  <Button
                    size="lg"
                    className="flex items-center w-full gap-2 text-base font-semibold"
                    onClick={nextStep}
                  >
                    Continue
                  </Button>
                </div>
              </div>
            </div>
            <BackButton isButton={true} text="Go back" handleBtnClick={handleLogoClick} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyDetails;
