import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getArticleTypeList } from "services/article-types.services";
import {
  createArticle,
  getArticleDetailsById,
  updateArticleDetailsById,
} from "services/article.services";
import { getCompositionList } from "services/composition.services";
import { getGenders } from "services/gender.services";
import { getTemplates } from "services/template.services";
import {
  setArticleDetails,
  setArticleList,
  toggleActionableArticleId,
  toggleDuplicateAction,
  toggleIsArticleCreateModalOpen,
  toggleIsArticleEditable,
  toggleShowDiscardModal,
} from "store/articleSlice";
import { setRefetchBillingData } from "store/billingSlice";
import { setGenderList } from "store/genderSlice";
import { articleCompositionsList } from "utils/fakeData";
import { formateLable, removeQueryParam } from "utils/helper";
import { createArticleSchema } from "validation/article.validation";
import useCustomToaster from "./useCustomToaster";
import { Button } from "@mui/material";
import { setFormatedArticleList, setShowArticleForm } from "store/orderSlice";

const useCreateArticle = ({ refetchArticleData = null, handleArticleSelect = () => null, isCreatingArticleFromArticle = false }) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const location = useLocation();
  const navigate = useNavigate();
  const { workspaceId } = useParams();
  const {
    actionableArticelId,
    isArticleEditable,
    isDuplicateAction,
    articleDetails,
    articleList,
    showDiscardModal,
    resetFormData,
    isArticleCreateModalOpen
  } = useSelector((state) => state.articleSlice);

  const { formatedArticleList } = useSelector((state) => state.orderSlice);
  const { refetchBillingData } = useSelector((state) => state.billingSlice);
  const [articleTypeList, setArticleTypeList] = useState([]);
  const [templateList, setTemplateList] = useState([]);
  const [compositionList, setCompositionList] = useState([]);
  const [compositionFormLength, setCompositionFormLength] = useState(1);
  const [modifiedGenderList, setModifiedGenderList] = useState([]);
  const [isDragOver, setIsDragOver] = useState(false);
  const [fileData, setFileData] = useState([]);
  const [imagePreviews, setImagePreviews] = useState([]);
  const [hasImage, setHasImage] = useState(false);
  const [backFileData, setBackFileData] = useState([]);
  const [backImagePreviews, setBackImagePreviews] = useState([]);
  const [hasBackImage, setHasBackImage] = useState(false);
  const { showCustomToast } = useCustomToaster();
  const {
    control,
    register,
    reset,
    setValue,
    getValues,
    handleSubmit,
    clearErrors,
    setError,
    setFocus,
    formState: { errors },
  } = useForm({
    defaultValues: {
      sku: "",
      workspace_id: parseInt(workspaceId),
      compositions: [],
      gender_id: null,
      percentage: [],
      article_type: null,
      template_id: null,
    },
    resolver: zodResolver(createArticleSchema),
  });

  const { data: queryData, isLoading: isArticleTypeListLoading } = useQuery({
    queryKey: ["articleTypes", "compositions", "genders", "template", "getList", workspaceId],
    queryFn: () =>
      Promise.all([
        getArticleTypeList(),
        getCompositionList(),
        getGenders(),
        getTemplates({ pagination: "no", workspace_id: parseInt(workspaceId) }),
      ]),
    enabled: !!workspaceId,
  });

  const { data: articleDetailsById, isPending: isArticleDetailsLoading } = useQuery({
    queryKey: ["article", "getDetails"],
    queryFn: () => getArticleDetailsById(actionableArticelId),
    enabled: !!actionableArticelId,
  });

  const closeModal = () => {
    if (isDuplicateAction) {
      dispatch(toggleDuplicateAction());
    }
    if (isArticleEditable) {
      dispatch(toggleIsArticleEditable());
    }
    if (actionableArticelId) {
      dispatch(toggleActionableArticleId());
    }
    if (isArticleCreateModalOpen) {
      dispatch(toggleIsArticleCreateModalOpen());
    }
  }

  const createArticleMutation = useMutation({
    mutationKey: ["article", "createArticle"],
    mutationFn: (data) => createArticle(data),
    onMutate: () => {
      showCustomToast(
        {
          title: `${isDuplicateAction ? "Duplicating the article...." : "Creating article ..."}`,
        },
        "createArticleLoadingToast",
      );
    },
    onSuccess: (res) => {
      toast.dismiss("createArticleLoadingToast");
      if (!isCreatingArticleFromArticle && res.data.article) {
        const formatedArticleData = res.data.article;
        const newList = [
          ...formatedArticleList,
          {
            ...formatedArticleData,
            label: formateLable(formatedArticleData.sku, formatedArticleData.article_types.name),
            customLabel: `${formatedArticleData?.sku}${" "}${formatedArticleData?.article_types.name}`,
            value: formatedArticleData.id,
            template_id: formatedArticleData.template_id || formatedArticleData?.template?.id,
          },
        ]
        dispatch(setFormatedArticleList(newList));
        dispatch(setShowArticleForm(false))
        handleArticleSelect({
          label: formateLable(formatedArticleData?.sku, formatedArticleData?.article_types?.name),
          customLabel: `${formatedArticleData?.sku}${" "}${formatedArticleData?.article_types.name}`,
          value: formatedArticleData?.id,
          template_id: formatedArticleData.template_id || formatedArticleData?.template?.id,
        });
      }

      dispatch(setArticleList({ ...res.data, append: true }));

      if (!refetchBillingData) {
        dispatch(setRefetchBillingData(true));
      }
      if (refetchArticleData && typeof refetchArticleData === "function") {
        refetchArticleData();
      }
      closeModal()
      reset();
      clearErrors();
      setCompositionFormLength(1);
      setFileData([]);
      setImagePreviews([]);
      setHasImage(false);
      setBackFileData([]);
      setBackImagePreviews([]);
      setHasBackImage(false);
      showCustomToast({
        title: `Article created successfully`,
      });
      queryClient.invalidateQueries({ queryKey: ["articles", "createArticle"] });
    },
    onError: (error) => {
      toast.dismiss("createArticleLoadingToast");
      if (error.response?.data?.errors) {
        Object.entries(error.response.data.errors).forEach(([field, messages]) => {
          setError(field, { type: "server", message: messages[0] });
          setFocus(field);
          showCustomToast({
            title: messages[0],
          });
        });
      } else {
        showCustomToast({
          title: error.response?.data?.message || error.message || "Something went wrong",
        });
      }
    },
    onSettled: () => {
      toast.dismiss("createArticleLoadingToast");
      queryClient.invalidateQueries({
        queryKey: ["article", "createArticle"],
      });
      removeQueryParam(["fromGlobalCreate", "fromGlobalSearch", "article_id", "selectedTab"], location, navigate);
    },
  });

  const updateArticleMutation = useMutation({
    mutationKey: ["article", "updateArticle"],
    mutationFn: (data) => updateArticleDetailsById(data),
    onMutate: () => {
      showCustomToast(
        {
          title: "Upating the Article...",
        },
        "updateArticleLoadingToast",
      );
    },
    onSuccess: (res) => {
      toast.dismiss("updateArticleLoadingToast");
      dispatch(setArticleDetails(res.data.article));
      const updatedArticle = res.data.article;
      const updatedArticles = articleList.map((article) =>
        article.id === updatedArticle.id ? updatedArticle : article,
      );
      if (refetchArticleData && typeof refetchArticleData === "function") {
        refetchArticleData();
      }
      dispatch(setArticleList({ append: false, articleList: updatedArticles }));
      reset();
      closeModal()
      setImagePreviews([]);
      setFileData([]);
      setBackFileData([]);
      setBackImagePreviews([]);
      setHasImage(false);
      setHasBackImage(false);
      showCustomToast({
        title: "Article updated successfully",
      });
      queryClient.invalidateQueries({ queryKey: ["articles", "updateArticle"] });
    },
    onError: (error) => {
      toast.dismiss();
      showCustomToast({
        title: error.response?.data?.message || error.message || "Something went wrong",
      });
      if (error.response?.data?.errors) {
        Object.entries(error.response.data.errors).forEach(([field, messages]) => {
          setError(field, { type: "server", message: messages[0] });
          setFocus(field);
        });
      }
    },
    onSettled: () => {
      toast.dismiss("updateArticleLoadingToast");
      queryClient.invalidateQueries({
        queryKey: ["article", "updateArticle"],
      });
      removeQueryParam(["fromGlobalCreate", "fromGlobalSearch", "article_id", "selectedTab"], location, navigate);
    },
  });

  const onSubmit = (data) => {
    const processedData = {
      ...data,
      compositions: data.compositions.map((composition) => composition.value),
      gender_id: data.gender_id?.value,
      article_type: data.article_type?.value,
      template_id: data.template_id?.value,
      main_workspace_id: parseInt(data?.workspace_id),
    };
    const formData = new FormData();
    Object.keys(processedData).forEach((key) => {
      if (Array.isArray(processedData[key])) {
        processedData[key].forEach((item, index) => {
          formData.append(`${key}[${index}]`, item);
        });
      } else {
        formData.append(key, processedData[key]);
      }
    });
    if (fileData && fileData.length > 0) {
      fileData.forEach((file, index) => {
        formData.append(`front_file`, file); // Append each file individually with a dynamic key
      });
    }
    if (backFileData && backFileData.length > 0) {
      backFileData.forEach((file, index) => {
        formData.append(`back_file`, file); // Append each file individually with a dynamic key
      });
    }

    if (actionableArticelId) {
      if (hasImage && fileData && fileData.length === 0) {
        const formData = new FormData();
        formData.append(`front_file`, null);
      } else if (fileData && fileData.length > 0) {
        const formData = new FormData();
        fileData.forEach((file, index) => {
          formData.append(`front_file`, file); // Append each file individually with a dynamic key
        });
      }
      if (hasBackImage && backFileData && backFileData.length === 0) {
        const formData = new FormData();
        formData.append(`back_file`, null);
      } else if (backFileData && backFileData.length > 0) {
        const formData = new FormData();
        backFileData.forEach((file, index) => {
          formData.append(`back_file`, file); // Append each file individually with a dynamic key
        });
      }
    }

    if (actionableArticelId && !isDuplicateAction) {
      updateArticleMutation.mutate({ body: formData, id: actionableArticelId });
    } else {
      createArticleMutation.mutate(formData);
    }
  };

  const onValidationError = (error) => {
    console.log(error);
  };

  const handleAddComposition = () => {
    setCompositionFormLength((prev) => prev + 1);
  };



  const handleRemoveComposition = (index) => {
    const compositions = [...getValues().compositions];
    const percentages = [...getValues().percentage];

    if (compositions?.length > 1) {
      compositions.splice(index, 1);
      percentages.splice(index, 1);

      setValue("compositions", compositions);
      setValue("percentage", percentages);
    }
    setCompositionFormLength((prev) => prev - 1);
  };

  const toggleEditOrDiscard = () => {
    dispatch(toggleIsArticleEditable());
    if (isArticleEditable) {
      dispatch(toggleShowDiscardModal());
    }
  };

  const handleConfirmDiscard = (confirm) => {
    dispatch(toggleIsArticleEditable());
    if (!confirm) {
      dispatch(toggleShowDiscardModal());
      return;
    }
    dispatch(toggleIsArticleEditable());
    // setDefaultFormValue(articleDetails);
    dispatch(toggleShowDiscardModal());
    if (isArticleCreateModalOpen) {
      dispatch(toggleIsArticleCreateModalOpen());
    }
    removeQueryParam(["fromGlobalCreate", "fromGlobalSearch", "article_id", "selectedTab"], location, navigate);
  };

  const setDefaultFormValue = async (details) => {
    if (!details && !actionableArticelId) return;
    if (details) {
      const {
        sku,
        gender,
        workspace_id,
        article_types,
        compositions,
        percentage,
        template,
        files,
        front_file_url,
        back_file_url,
      } = details;

      if (article_types) {
        setValue("article_type", {
          label: article_types.name,
          value: article_types.id,
        });
      }
      setValue("sku", sku);
      if (template) {
        setValue("template_id", {
          label: template.name,
          value: parseInt(template.id),
        });
      }
      if (gender) {
        setValue("gender_id", {
          label: gender.name,
          value: parseInt(gender.id),
        });
      }
      setCompositionFormLength(compositions.length);
      setValue(
        "compositions",
        compositions.map(({ name, id }) => ({
          label: name,
          value: id,
        })),
      );
      setValue("workspace_id", parseInt(workspace_id));
      setValue("percentage", percentage);

      if (front_file_url && front_file_url !== null) {
        setImagePreviews([front_file_url]);
        setValue("front_file", front_file_url);
        setFileData([front_file_url]);
        setHasImage(true);
      } else {
        setImagePreviews([]);
        setFileData([]);
        setHasImage(false);
      }
      if (back_file_url && back_file_url !== null) {
        setBackImagePreviews([back_file_url]);
        setValue("back_file", back_file_url);
        setBackFileData([back_file_url]);
        setHasBackImage(true);
      } else {
        setBackImagePreviews([]);
        setBackFileData([]);
        setHasBackImage(false);
      }
    }
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragOver(false);
  };

  const handleDrop = (e, name) => {
    e.preventDefault();
    setIsDragOver(false);
    onFileDropOrChange(e, name);
  };

  const isDragEvent = (event, name) => {
    return "dataTransfer" in event;
  };

  const onFileDropOrChange = (e, name) => {
    const MAX_FILE_SIZE = 10;
    let files = null;
    if (isDragEvent(e)) {
      files = e.dataTransfer.files;
    } else {
      files = e.target.files;
    }

    if (files && files.length > 0) {
      const newFiles = Array.from(files); // Convert FileList to array
      const validFiles = []; // To store valid files only
      const previewPromises = newFiles.map((file, index) => {
        return new Promise((resolve, reject) => {
          // Check file type
          if (file.type !== "application/pdf" && !file.type.startsWith("image/")) {
            showCustomToast({
              title: "Please drop a PDF or image file.",
            });
            reject("Invalid file type");
            return;
          }
          // Check file size
          if (file.size > MAX_FILE_SIZE * 1024 * 1024) {
            showCustomToast({
              title: "File size exceeds 10MB limit.",
            });
            reject("File size exceeds limit");
            return;
          }
          // Add valid files to the list
          validFiles.push(file);

          // Generate image preview if it's an image file
          if (file.type.startsWith("image/")) {
            const reader = new FileReader();
            reader.onloadend = () => {
              resolve(reader.result);
            };
            reader.onerror = () => {
              reject("Error reading file");
            };
            reader.readAsDataURL(file);
          } else {
            // Resolve with null for non-image files
            resolve(null);
          }
        });
      });

      Promise.all(previewPromises)
        .then((previews) => {
          if (name === "front") {
            setImagePreviews((prevPreviews) => [...prevPreviews, ...previews]);
            setFileData((prevFileData) => [...prevFileData, ...validFiles]);
            if (e.target && e.target.value) {
              e.target.value = null;
            }
          } else {
            setBackImagePreviews((prevPreviews) => [...prevPreviews, ...previews]);
            setBackFileData((prevFileData) => [...prevFileData, ...validFiles]);
            if (e.target && e.target.value) {
              e.target.value = null;
            }
          }
        })
        .catch((error) => {
          console.error("Error processing files:", error);
        });
    }
  };

  const handleCertFileDelete = (index, name) => {
    if (name === "front") {
      const updatedPreviews = [...imagePreviews];
      const updatedFileData = [...fileData];
      updatedPreviews.splice(index, 1);
      updatedFileData.splice(index, 1);
      setImagePreviews(updatedPreviews);
      setFileData(updatedFileData);
      setValue("front_file", updatedFileData);
    } else if (name === "back") {
      const updatedPreviews = [...backImagePreviews];
      const updatedFileData = [...backFileData];
      updatedPreviews.splice(index, 1);
      updatedFileData.splice(index, 1);
      setBackImagePreviews(updatedPreviews);
      setBackFileData(updatedFileData);
      setValue("back_file", updatedFileData);
    }
    // Remove the image preview and corresponding file data
  };

  useEffect(() => {
    if (
      actionableArticelId &&
      articleTypeList &&
      articleDetailsById &&
      articleCompositionsList &&
      articleDetailsById.status === 200
    ) {
      if (articleDetailsById.data) {
        dispatch(setArticleDetails(articleDetailsById.data.article));
        setDefaultFormValue(articleDetailsById.data.article);
      }
    }
  }, [articleDetailsById, actionableArticelId]);

  useEffect(() => {
    const articleTypes = queryData?.[0];
    const compositionData = queryData?.[1];
    const genderData = queryData?.[2];
    const templateData = queryData?.[3];
    if (articleTypes?.data?.article_type) {
      const updatedArticleTypeList = articleTypes.data.article_type.map((item) => ({
        value: item.id,
        label: item.name,
      }));
      setArticleTypeList(updatedArticleTypeList);
    }

    if (compositionData?.data?.compositions) {
      const updatedCompositionList = compositionData.data.compositions.map((item) => ({
        value: item.id,
        label: item.name,
      }));
      setCompositionList(updatedCompositionList);
    }

    if (genderData?.data?.status && genderData?.data?.gender) {
      const updatedGenderList = genderData.data.gender.map((item) => ({
        value: item.id,
        label: item.name,
      }));
      setModifiedGenderList(updatedGenderList);
      dispatch(setGenderList(genderData.data.gender));
    }
    if (templateData?.data?.templates) {
      const updatedtemplatesList = templateData.data.templates.map((item) => ({
        value: item.id,
        label: item.name,
      }));
      setTemplateList(updatedtemplatesList);
    }
  }, [queryData]);

  return {
    control,
    isDuplicateAction,
    articleDetails,
    articleTypeList,
    compositionList,
    genderList: modifiedGenderList,
    compositionFormLength,
    errors,
    isLoading: isArticleTypeListLoading,
    isArticleDetailsLoading,
    isPending: createArticleMutation.isPending || updateArticleMutation.isPending,
    isArticleEditable,
    actionableArticelId,
    showDiscardModal,
    register,
    reset,
    handleSubmit,
    onSubmit,
    onValidationError,
    handleAddComposition,
    handleRemoveComposition,
    getValues,
    toggleEditOrDiscard,
    handleConfirmDiscard,
    templateList,
    imagePreviews,
    backImagePreviews,
    isDragOver,
    handleDragEnter,
    handleDragLeave,
    handleDrop,
    onFileDropOrChange,
    handleCertFileDelete,
    fileData,
    backFileData,
  };
};

export default useCreateArticle;
