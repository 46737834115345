import React from "react";

const EditDropDown = React.forwardRef(
  (
    {
      width = "20",
      height = "20",
      viewBox = "0 0 20 20",
      fill = "none",
      fillColor = "#FCFCFC",
      className,
      ...props
    },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Editorial / fill-and-stroke" clipPath="url(#clip0_508_35827)">
          <path
            id="Fill and stroke"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.4512 15H14.9497V17.5C14.9499 17.8282 14.8854 18.1533 14.76 18.4566C14.6345 18.7599 14.4505 19.0355 14.2184 19.2677C13.9864 19.4998 13.7109 19.684 13.4077 19.8097C13.1044 19.9353 12.7794 20 12.4512 20H2.45117C1.78813 20 1.15225 19.7366 0.683405 19.2678C0.214564 18.7989 -0.0488277 18.163 -0.0488277 17.5V7.5C-0.0490155 7.17164 0.0155207 6.84647 0.141091 6.54307C0.266661 6.23967 0.450803 5.964 0.682987 5.73181C0.91517 5.49963 1.19084 5.31549 1.49424 5.18992C1.79764 5.06435 2.12281 4.99981 2.45117 5H4.94974V2.5C4.94974 2.17158 5.01446 1.84637 5.14018 1.54296C5.26591 1.23955 5.45018 0.963893 5.68248 0.731728C5.91478 0.499563 6.19055 0.315444 6.49402 0.189891C6.7975 0.0643387 7.12275 -0.000187263 7.45117 4.08201e-07H17.4512C18.1142 4.08201e-07 18.7501 0.263393 19.2189 0.732233C19.6878 1.20107 19.9512 1.83696 19.9512 2.5V12.5C19.9512 12.8283 19.8865 13.1534 19.7609 13.4567C19.6352 13.76 19.4511 14.0356 19.2189 14.2678C18.9868 14.4999 18.7112 14.6841 18.4079 14.8097C18.1046 14.9353 17.7795 15 17.4512 15ZM17.4512 2.5H7.45117V5H12.4512C12.7794 5 13.1044 5.06467 13.4077 5.19033C13.7109 5.31598 13.9864 5.50016 14.2184 5.73232C14.4505 5.96449 14.6345 6.24009 14.76 6.5434C14.8854 6.84671 14.9499 7.17176 14.9497 7.5V12.5H17.4512V2.5Z"
            fill={fillColor}
          />
        </g>
        <defs>
          <clipPath id="clip0_508_35827">
            <rect width="20" height="20" fill="white" />
          </clipPath>
        </defs>
      </svg>
    );
  },
);

export default EditDropDown;
