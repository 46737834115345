import React from "react";

const DropDownArrow = React.forwardRef(
  (
    {
      width = "14",
      height = "15",
      viewBox = "0 0 13 15",
      fill = "none",
      fillColor = "#444656",
      className,
      ...props
    },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          id="Vector 37 (Stroke)"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.26516 0.734835C7.41161 0.881282 7.41161 1.11872 7.26516 1.26517L4.26516 4.26517C4.11872 4.41161 3.88128 4.41161 3.73483 4.26516L0.734835 1.26516C0.588388 1.11872 0.588388 0.881281 0.734835 0.734835C0.881281 0.588388 1.11872 0.588388 1.26516 0.734835L4 3.46967L6.73483 0.734835C6.88128 0.588388 7.11872 0.588388 7.26516 0.734835Z"
          fill={fillColor}
        />
      </svg>
    );
  },
);

export default DropDownArrow;
