import { Button } from "components/UI/Button";
import React, { useState } from "react";
import FormHeader from "components/UI/FormHeader";
import TogglerButton from "components/UI/TogglerButton";
import SignInForm from "./SignInForm";

import { useDispatch, useSelector } from "react-redux";
import { toggleRequestDemo } from "store/navbarSlice";
import SigninLogo from "components/UI/SigninLogo";
import { Halflogoicon } from "assests";
import { Link } from "react-router-dom";

const Signin = () => {
  const { showRequestDemo } = useSelector((state) => state.navbarSlice);
  return (
    <div className="flex items-center justify-center px-4">
      <div className="relative w-full  md:w-[360px]">
        <div className="flex flex-col gap-6">
          <SigninLogo>
            <Halflogoicon width={18.31} height={28} />
          </SigninLogo>
          <FormHeader
            title="Log in to your account"
            subtitle="Welcome back! Please enter your details."
          />
          <div className="flex flex-col gap-8">
            <TogglerButton btnTextOne="Request demo" btnTextTwo="Log in" />
            <>{!showRequestDemo && <SignInForm />}</>
            <div className="flex items-center justify-center">
              <p className="relative pl-2 pr-1 text-sm font-normal  text-login-text-paragraph whitespace-nowrap">
                {!showRequestDemo ? "Don’t have an account?" : "Already have an account?"}
              </p>
              <Link
                to={`${process.env.REACT_APP_WEB_URL}/request-demo`}
                target="_blank"
                rel="noreferrer">
                <Button
                  // onClick={() => dispatch(toggleRequestDemo())}
                  className="p-0 m-0 pr-2 bg-transparent hover:bg-transparent hover:text-global-primary text-sm font-semibold  text-btn-text-link whitespace-nowrap "
                >
                  {/* {!showRequestDemo ? "Request demo" : "Login"} */}
                  Request demo
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signin;
