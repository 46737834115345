import React from "react";

const LinkIcon = React.forwardRef(
  (
    {
      width = "20",
      height = "20",
      viewBox = "0 0 20 20",
      fill = "none",
      strokeColor = "white",
      className,
      ...props
    },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.0903 15.3032L9.91177 16.4817C8.28459 18.1089 5.6464 18.1089 4.01922 16.4817C2.39203 14.8546 2.39203 12.2164 4.01922 10.5892L5.19773 9.41068M15.8043 10.5892L16.9828 9.41067C18.61 7.78349 18.61 5.1453 16.9828 3.51812C15.3557 1.89093 12.7175 1.89093 11.0903 3.51812L9.91177 4.69663M7.58436 12.9166L13.4177 7.08325"
          stroke={strokeColor}
          strokeWidth="1.66667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  },
);

LinkIcon.displayName = "LinkIcon";

export default LinkIcon;
