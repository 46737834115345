import { ArrowdownCircleIcon, PlusIcon, Search, TrashUpdateIcon } from "assests";
import { useEffect, useRef, useState } from "react";
import { Button } from "./UI/Button";
import ChipButton from "./UI/ChipButton";
import Divider from "./UI/Divider";
import { Input } from "./UI/Input";
import { useSelector } from "react-redux";
import usePermission from "hooks/usePermission";

const SearchCreatePanelUpdate = ({
  showHeaders = true,
  title,
  btnText,
  subText,
  selectedItems,
  openDeleteModal,
  handleDownloadCSV,
  createTxt,
  onChange,
  children,
  onBtnClick,
  inputRefExternal,
  classWidth,
  permissionText,
  isDisabled,
  permissionText2
}) => {
  const { hasPermission } = usePermission();
  const [isHovered, setIshovered] = useState(false);
  const [isTrashbtnHovered, setIsTrashBtnhovered] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const searchInputRef = useRef(null);
  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    if (inputRefExternal) {
      inputRefExternal.current = searchInputRef.current;
    }
  }, [inputRefExternal]);
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (
        (event.ctrlKey || event.metaKey) &&
        event.shiftKey &&
        (event.key.toLowerCase() === "f" || event.key.toLowerCase() === "F")
      ) {
        event.preventDefault();
        searchInputRef.current?.focus();
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, []);

  return (
    <div>
      {/* {showHeaders && <HeadersName />} */}
      <div className="flex flex-col md:flex-row flex-wrap justify-center md:justify-between py-[11.5px] lg:py-5 px-4 lg:px-6 gap-3 lg:gap-5">
        <div className="flex gap-2 justify-start items-center">
          <h3 className="text-base md:text-lg font-semibold text-global-title leading-5 md:leading-7">
            {title}
          </h3>
          <ChipButton
            className={
              "text-[10px] lg:text-xs text-global-paragraph !h-[22px] !px-1.5 whitespace-nowrap"
            }
          >
            {btnText ? btnText : 0}
          </ChipButton>
        </div>

        {subText && (
          <p className="text-xs lg:text-sm text-global-paragraph leading-5 pt-1">{subText}</p>
        )}
        <div className="flex flex-col md:flex-row gap-3">
          {createTxt && selectedItems.length > 0 && permissionText2 && hasPermission(permissionText2) && ( // Check if any orders are selected
            <div className="hidden md:block">
              <Button
                variant="secondary"
                onClick={openDeleteModal}
                className="text-txt-secondary px-3.5 py-2.5 gap-[4.55px] text-sm tracking-wide font-semibold flex"
                onMouseEnter={() => setIsTrashBtnhovered(true)}
                onMouseLeave={() => setIsTrashBtnhovered(false)}
                isDisabled={isDisabled || false}
              >
                <TrashUpdateIcon
                  width={20}
                  height={20}
                  strokeColor={
                    isTrashbtnHovered
                      ? "var(--color-icon-fill-secondary-button)"
                      : "var(--color-txt-secondary)"
                  }
                />{" "}
                Remove
              </Button>
            </div>
          )}
          <div className="flex flex-row gap-3">
            <div className="order-2 md:order-1">
              <Button
                variant="secondary"
                onClick={handleDownloadCSV}
                className="text-txt-secondary px-3.5 py-2.5 gap-[4.55px] text-sm  font-semibold flex"
                onMouseEnter={() => setIshovered(true)}
                onMouseLeave={() => setIshovered(false)}
              >
                <ArrowdownCircleIcon
                  width={20}
                  height={20}
                  strokeColor={
                    isHovered ? "var(--color-icon-fill-secondary-button)" : "var(--color-txt-secondary)"
                  }
                />{" "}
                <span className="hidden md:block">Download CSV</span>
              </Button>
            </div>
            {createTxt && permissionText && hasPermission(permissionText) && (
              <div className="order-1 md:order-2 flex-1">
                <Button
                  variant="primary"
                  onClick={onBtnClick}
                  className={`px-3.5 py-2.5 gap-1 text-sm leading-5 font-semibold flex ${classWidth}`}
                >
                  <PlusIcon
                    width={20}
                    height={20}
                    fillColor={"var(--color-icon-fill-secondary-button)"}
                  />{" "}
                  {createTxt}
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
      <Divider />
      <div className="px-4 lg:px-6 py-3  ">
        <div className="flex flex-col gap-3 md:flex-row  justify-between items-start">
          <div className="flex-1 w-full md:w-auto order-2 md:order-1">{children}</div>
          <div className="w-full md:w-auto order-1 md:order-2">
            <div className="flex items-center w-full md:!w-[320px] h-10  md:h-auto  shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)] pl-3 pr-5 border border-border-primary rounded-lg md:px-3.5 md:py-[9px] bg-global-theme">
              <div className="flex items-center w-full ">
                <div>
                  <Search className="w-5 h-5" strokeColor={"var(--color-fg-quantary)"} />
                </div>
                <Input
                  ref={searchInputRef}
                  size="sm"
                  type="text"
                  onChange={onChange}
                  placeholder={`Search`}
                  className="px-2 text-[8px] h-6 w-full flex-1 md:!w-[271px]  text-base bg-transparent border-none focus:ring-0 focus:border-none focus:bg-transparent focus:outline-none text-global-paragraph placeholder:text-input-text-placeholder-extra placeholder:font-normal placeholder:text-base hover:bg-transparent "
                />
              </div>
            </div>
          </div>
        </div>
        {createTxt && selectedItems.length > 0 && permissionText2 && hasPermission(permissionText2) && ( // Check if any orders are selected
          <div className="block md:hidden w-full order-3 mt-3">
            <Button
              variant="secondary"
              onClick={openDeleteModal}
              className="text-txt-secondary px-3.5 py-2.5 gap-[4.55px] text-sm w-full  tracking-wide font-semibold flex"
              onMouseEnter={() => setIsTrashBtnhovered(true)}
              onMouseLeave={() => setIsTrashBtnhovered(false)}
              isDisabled={isDisabled || false}
            >
              <TrashUpdateIcon
                width={20}
                height={20}
                strokeColor={
                  isTrashbtnHovered
                    ? "var(--color-icon-fill-secondary-button)"
                    : "var(--color-txt-secondary)"
                }
              />{" "}
              Remove
            </Button>
          </div>
        )}
      </div>

    </div>
  );
};

export default SearchCreatePanelUpdate;
