import { useMutation } from "@tanstack/react-query";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserLoggedInData } from "services/auth.services";
import { setAuthentication } from "store/authenticationSlice";

const useUser = () => {
  const dispatch = useDispatch();
  const userMutation = useMutation({
    mutationKey: ["auth", "login"],
    mutationFn: getUserLoggedInData, // Auth service login function
    onSuccess: (res) => {
      if (res) {
        const data = res.data;
        localStorage.setItem("workspaces", JSON.stringify(data.workspaces));
        localStorage.setItem("user", JSON.stringify(data.user));
        dispatch(setAuthentication(data));
      }
    },
    onError: (err) => {
      console.log(err);
    },
  });
  return { userMutation };
};

export default useUser;
