import { ArrowLeft, ArrowNext, ArrowPrev, ArrowRightFill } from "assests";

const UpdatedPagination = (props) => {
    const { page, setPage, size, handleSizeChange, totalPages } = props;
    const pages = [];
    for (let i = 0; i < totalPages; i++) {
        pages.push(i);
    }

    const handlePageChange = (page) => {
        setPage(page);
    };
    const handleNumberInput = (value) => {
        if (value) {
            const number = Number(value);
            if (!isNaN(number) && number > 1 && number <= totalPages) {
                handlePageChange(number - 1);
            } else {
                alert("Invalid Input");
            }
        }
    };

    const renderPageNumbers = () => {
        const displayedPages = [];
        const maxDisplayedPages = 5;

        if (totalPages <= maxDisplayedPages) {
            return pages.map((item, index) => (
                <button
                    key={index}
                    onClick={() => handlePageChange(item + 1)}
                    className={`relative inline-flex items-center min-w-10 justify-center text-center first:rounded-l-lg last:rounded-r-lg px-2.5 py-2.5 text-sm font-semibold ring-1 ring-inset ring-global-gray-medium hover:bg-global-white text-global-title focus:z-20 focus:outline-offset-0  ${page === item ? "bg-global-white text-global-title " : "text-global-title "
                        }`}
                >
                    {item + 1}
                </button>
            ));
        }

        const midPoint = Math.floor(maxDisplayedPages / 2);

        if (page <= midPoint) {
            displayedPages.push(...pages.slice(0, maxDisplayedPages - 1), "ellipsis", totalPages - 1);
        } else if (page >= totalPages - midPoint) {
            displayedPages.push(0, "ellipsis", ...pages.slice(totalPages - maxDisplayedPages + 1));
        } else {
            displayedPages.push(
                0,
                "ellipsis",
                ...pages.slice(page - midPoint, page + midPoint),
                "ellipsis",
                totalPages - 1,
            );
        }

        return displayedPages.map((item, index) => {
            if (item === "ellipsis") {
                return (
                    <span
                        key={index}
                        className={`relative inline-flex items-center justify-center text-center min-w-10 first:rounded-l-lg last:rounded-r-lg px-2.5 py-2.5 text-sm font-semibold ring-1 ring-inset ring-global-gray-medium hover:bg-global-white text-global-title focus:z-20 focus:outline-offset-0  ${page === item ? "bg-global-white text-global-title " : "text-global-title "
                            }`}
                    >
                        ...
                    </span>
                );
            }

            return (
                <button
                    key={index}
                    onClick={() => handlePageChange(item + 1)}
                    className={`relative inline-flex items-center justify-center text-center min-w-10 first:rounded-l-lg last:rounded-r-lg px-2.5 py-2.5 text-sm font-semibold ring-1 ring-inset ring-global-gray-medium hover:bg-global-white text-global-title focus:z-20 focus:outline-offset-0  ${page === item ? "bg-global-white text-global-title " : "text-global-title "
                        }`}
                >
                    {item + 1}
                </button>
            );
        });
    };
    const handlePrev = () => {
        if (page > 1) {
            setPage(page - 1);
        }
    };
    const handleNext = () => {
        if (page < totalPages) {
            setPage(page + 1);
        }
    };

    return (
        <div className="bg-global-surface  w-full">
            <div className="flex items-center  justify-end w-full md:px-4 py-3">
                <div className="flex items-center gap-6 ">
                    <div className="flex items-center gap-2.5 text-global-title">
                        <nav
                            className={`inline-flex -space-x-px  rounded-lg shadow-sm isolate  bg-global-theme`}
                            aria-label="Pagination"
                        >
                            {" "}
                            <button
                                type="button"
                                onClick={handlePrev}
                                className={`relative inline-flex items-center justify-center text-center  first:rounded-l-lg last:rounded-r-lg px-2.5 py-2.5 text-sm font-semibold ring-1 ring-inset ring-global-gray-medium hover:bg-global-white text-global-title focus:z-20 focus:outline-offset-0`}
                            >
                                <ArrowPrev
                                    className="w-5 h-5"
                                    
                                />
                            </button>
                            {renderPageNumbers()}
                            <button
                                type="button"
                                onClick={handleNext}
                                className={`relative inline-flex items-center justify-center text-center first:rounded-l-lg last:rounded-r-lg px-2.5 py-2.5 text-sm font-semibold ring-1 ring-inset ring-global-gray-medium hover:bg-global-white text-global-title focus:z-20 focus:outline-offset-0`}
                            >
                                <ArrowNext
                                    className="w-5 h-5"
                                    
                                />
                            </button>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UpdatedPagination;
