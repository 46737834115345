import { ActiveEye, ArrowRight, BgImg, LockOutLinedIcon, PhoneBGImg, View } from "assests";
import { Button } from "components/UI/Button";
import DynamicSVG from "components/UI/DynamicSVG";
import ErrorMessage from "components/UI/ErrorMessage";
import FormHeader from "components/UI/FormHeader";
import { Input } from "components/UI/Input";
import { Label } from "components/UI/Label";
import PassWordCriteria from "components/UI/PassWordCriteria";
import SigninLogo from "components/UI/SigninLogo";
import Navbar from "layout/Navbar";
import { useState } from "react";

const SetPasswordPage = ({
  control,
  register,
  errors,
  email,
  showPassCriteria,
  passwordStrengthData,
  isLoading,
  isPending,
  handleSubmit,
  onFormSubmit,
}) => {
  const [passwordInputType, setPasswordInputType] = useState("password");
  return (
    <>
      <div
        className={`w-screen h-full min-h-screen bg-global-theme overflow-y-auto `}
      >
        <div
          className=" flex items-center justify-center w-full h-full "
          style={{ pointerEvents: "none" }}
        >
          <img src={BgImg} alt="" className="fixed top-0 hidden md:block" />
          <img src={PhoneBGImg} alt="" className="fixed top-[50px] block md:hidden" />
        </div>
        <Navbar />
        <div className="flex flex-col justify-between w-full h-full py-[120px]  lg:py-[176px] gap-20">
          <div className="flex items-center justify-center w-full">
            <div className="relative w-full px-4 md:px-0 md:w-[360px]">
              <div className="flex flex-col gap-8">
                <div className="flex flex-col gap-6">
                  <SigninLogo>
                    <LockOutLinedIcon width={28} height={28} />
                  </SigninLogo>
                  <div className="flex-col justify-center items-center inline-flex">
                    <FormHeader
                      icon={false}
                      title="Set Password"
                      subtitle="Enter a strong password for your account:"
                      boldText={email}
                    />
                  </div>
                </div>

                <form onSubmit={handleSubmit(onFormSubmit)}>
                  <div className="grid gap-5">
                    <div className="grid gap-1.5">
                      <Label htmlFor="newPassword" >
                        Password
                      </Label>

                      <Input
                        control={control}
                        id="newPassword"
                        type={passwordInputType}
                        placeholder="••••••••"
                        {...register("password")}
                        className="text-btn-text-default border border-input-stroke-default placeholder:text-xs placeholder:text-text-input-text-placeholder"
                      />
                      {showPassCriteria && (
                        <PassWordCriteria passwordStrengthData={passwordStrengthData} />
                      )}

                      <ErrorMessage errors={errors?.password?.message} />
                    </div>

                    <div className="flex justify-center">
                      <Button
                        type="submit"
                        className="w-full text-base h-[44px] font-semibold"
                        isDisabled={isLoading || isPending}
                      >
                        Login to dashboard  <ArrowRight fillColor={"white"} />
                      </Button>
                    </div>
                  </div>
                </form>
                {/* <div className="flex items-center justify-center gap-2">
            <ArrowLeft fillColor="var(--color-icon-outline-default)" height={20} width={20} />
            <Link
              to="/signin"
              className=" text-sm font-semibold  text-btn-text-link  whitespace-nowrap"
            >
              Back to log in
            </Link>
          </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SetPasswordPage;
