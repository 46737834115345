import React from "react";

const passcode = React.forwardRef(
  (
    {
      width = "20",
      height = "20",
      viewBox = "0 0 20 20",
      fill = "none",
      strokeColor = "#344054",
      className,
      onClick = () => {},
      ...props
    },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.0001 9.99998H10.0042M14.1667 9.99998H14.1709M5.83341 9.99998H5.83758M4.33341 5.83331H15.6667C16.6002 5.83331 17.0669 5.83331 17.4234 6.01497C17.737 6.17476 17.992 6.42973 18.1518 6.74333C18.3334 7.09985 18.3334 7.56656 18.3334 8.49998V11.5C18.3334 12.4334 18.3334 12.9001 18.1518 13.2566C17.992 13.5702 17.737 13.8252 17.4234 13.985C17.0669 14.1666 16.6002 14.1666 15.6667 14.1666H4.33342C3.39999 14.1666 2.93328 14.1666 2.57676 13.985C2.26316 13.8252 2.00819 13.5702 1.8484 13.2566C1.66675 12.9001 1.66675 12.4334 1.66675 11.5V8.49998C1.66675 7.56656 1.66675 7.09985 1.8484 6.74333C2.00819 6.42973 2.26316 6.17476 2.57676 6.01497C2.93328 5.83331 3.39999 5.83331 4.33341 5.83331ZM10.2084 9.99998C10.2084 10.115 10.1151 10.2083 10.0001 10.2083C9.88502 10.2083 9.79175 10.115 9.79175 9.99998C9.79175 9.88492 9.88502 9.79165 10.0001 9.79165C10.1151 9.79165 10.2084 9.88492 10.2084 9.99998ZM14.3751 9.99998C14.3751 10.115 14.2818 10.2083 14.1667 10.2083C14.0517 10.2083 13.9584 10.115 13.9584 9.99998C13.9584 9.88492 14.0517 9.79165 14.1667 9.79165C14.2818 9.79165 14.3751 9.88492 14.3751 9.99998ZM6.04175 9.99998C6.04175 10.115 5.94847 10.2083 5.83341 10.2083C5.71836 10.2083 5.62508 10.115 5.62508 9.99998C5.62508 9.88492 5.71836 9.79165 5.83341 9.79165C5.94847 9.79165 6.04175 9.88492 6.04175 9.99998Z"
          stroke={strokeColor}
          strokeWidth="1.66667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  },
);

export default passcode;
