import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { DeleteProfile, DownloadCloudIcon, Trash } from "assests";
import { Button } from "components/UI/Button";
import Modal from "components/UI/Modal";
import SortIcons from "components/UI/SortIcons";
import { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  bulkDeleteInvitations,
  deleteInvitation,
  downloadInvitationAsCSV,
  searchPartners,
} from "services/invitation.services";
import {
  setAllInviattionData,
  setInvitationList,
  toggleActionableInvitationId,
  toggleDeletePertnerModal,
  toggleInvitationCancleModal,
  toggleInvitationDeleteModal,
  toggleSelectedCompanyId,
  toggleSelectedCompanyType,
} from "store/invitationSlice";
import {
  calculatePercentage,
  formatDateToDayMonthYear,
  getInitials,
  removeQueryParam,
  truncateString,
} from "utils/helper";
import useSubscriptionModel from "hooks/useSubscriptionModel";
import { setRefetchBillingData } from "store/billingSlice";
import BackToDashboard from "components/UI/BackToDashboard";
import SubNavbar from "layout/SubNavbar";
import CommonCard from "components/UI/CommonCard";
import ChipButton from "components/UI/ChipButton";
import { LineProgressBar } from "components/UI/LineProgressBar";
import Divider from "components/UI/Divider";
import { BsCircleFill } from "react-icons/bs";
import SearchBoxComponent from "components/UI/SearchBoxComponent";
import CommonDiscardButton from "components/UI/CommonDiscardButton";
import SettingsPermissionModal from "components/UI/SettingsPermissionModal";
import SubScriptionModal from "pages/Dashboard/DashboardWorkspaces/SubScriptionModal";
import LoadingComponent from "components/UI/LoadingComponent";
import EmptyTable from "components/Card/EmptyTable";
import TertiaryPagination from "components/UI/TertiaryPagination";
import useCustomToaster from "hooks/useCustomToaster";
import TopNavbar from "components/TopHeader/TopNavbar";
import BreadCrumb from "components/TopHeader/BreadCrumb";
const InvitationList = () => {
  const [isHovered, setIshovered] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [orderBy, setOrderBy] = useState("");
  const [enteredSearchKey, setEnteredSearchKey] = useState("");
  const [selectedOrders, setSelectedOrders] = useState([]);
  const { currentWorkspace } = useSelector((state) => state.authenticationSlice);
  const { refetchBillingData, currentPlan } = useSelector((state) => state.billingSlice);
  const { showCustomToast } = useCustomToaster();
  const {
    allInviattionData,
    invitationList,
    actionableInvitationId,
    isInvitationDeleteModalOpen,
    isInvitationCancleModalOpen,
    selectedCompanyId,
    selectedCompanyType,
    isDeletePertnerModalOpen,
  } = useSelector((state) => state.invitationSlice);

  const { isSubscriptionCreateModalOpen, closeModal: closeSubscriptionModal } =
    useSubscriptionModel();

  const handleSizeChange = (newSize) => {
    setSize(newSize);
  };

  const {
    data: invitationsData,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ["invitations", page, size, orderBy, enteredSearchKey],
    queryFn: () =>
      searchPartners({
        page,
        per_page: size,
        sort: orderBy,
        search: enteredSearchKey,
        workspace_id: parseInt(currentWorkspace?.workspace_id),
      }),
    enabled: !!currentWorkspace && (!!page || !!size),
  });

  useEffect(() => {
    if (!isLoading && invitationsData) {
      dispatch(
        setInvitationList({ invitationList: invitationsData?.data?.data?.data, append: false }),
      );
      dispatch(setAllInviattionData(invitationsData?.data?.data));
    }
  }, [invitationsData, isLoading]);

  const handleViewDetailsClick = (type, invitationId, status, workspaceId) => {
    switch (type) {
      case "supplier":
        navigate(
          `/workspace/${workspaceId}/supplier-details/${invitationId}/${type}?status=${status}`,
          {
            replace: true,
          },
        );
        break;
      case "agent":
        navigate(`/workspace/${workspaceId}/agent-details/${invitationId}?status=${status}`, {
          replace: true,
        });
        break;
      case "retailer":
        navigate(`/workspace/${workspaceId}/retailer-details/${invitationId}?status=${status}`, {
          replace: true,
        });
        break;
      case "importer":
        navigate(`/workspace/${workspaceId}/retailer-details/${invitationId}?status=${status}`, {
          replace: true,
        });
        break;
      case "brand":
        navigate(`/workspace/${workspaceId}/retailer-details/${invitationId}?status=${status}`, {
          replace: true,
        });
        break;
      default:
        break;
    }
  };

  const closeModal = () => {
    if (actionableInvitationId) {
      dispatch(toggleActionableInvitationId());
    }
    if (isInvitationDeleteModalOpen) {
      dispatch(toggleInvitationDeleteModal());
    }

    if (isInvitationCancleModalOpen) {
      dispatch(toggleInvitationCancleModal());
    }
    if (selectedCompanyId) {
      dispatch(toggleSelectedCompanyId());
    }
    if (selectedCompanyType) {
      dispatch(toggleSelectedCompanyType());
    }
    if (isDeletePertnerModalOpen) {
      dispatch(toggleDeletePertnerModal());
    }
    removeQueryParam(["selectedTab"], location, navigate);
  };

  const deleteUserMutation = useMutation({
    mutationKey: ["partnerDelete", "delete"],
    mutationFn: (data) => deleteInvitation(data),
    onMutate: () => {
      showCustomToast(
        {
          title: "Removing...",
        },
        "partnerDelete",
      );
    },
    onSuccess: (res) => {
      toast.dismiss("partnerDelete");
      showCustomToast({
        title: res.data.message || "Partner removed successfully",
      });
      refetch();
      closeModal();
      if (!refetchBillingData) {
        dispatch(setRefetchBillingData(true));
      }
      queryClient.invalidateQueries(["partnerDelete", "get", "delete"]);
    },
    onError: (error) => {
      toast.dismiss("partnerDelete");
      showCustomToast({
        title: error.response.data.message || error.message,
      });
    },
    onSettled: () => {
      toast.dismiss("partnerDelete");
    },
  });

  const downloadInvitationsAsCSV = useMutation({
    mutationKey: ["invitations", "download"],
    mutationFn: (params) => downloadInvitationAsCSV(params),
    onMutate: () => {
      showCustomToast(
        {
          title: "Downloading...",
        },
        "downloadInvitations",
      );
    },
    onSuccess: (res) => {
      toast.dismiss("downloadInvitations");
      showCustomToast({
        title: "Downloaded successfully",
      });
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "partners.csv");
      document.body.appendChild(link);
      link.click();
      link.remove();
      queryClient.invalidateQueries(["invitations", "download"]);
    },
    onError: (error) => {
      toast.dismiss("downloadInvitations");
      showCustomToast({
        title: error.response.data.message || error.message,
      });
    },
  });

  const handleDownloadCSV = () => {
    downloadInvitationsAsCSV.mutate({
      workspace_id: parseInt(currentWorkspace?.workspace_id),
    });
  };

  const onClickCheckbox = (inviatation) => {
    const findedItem = selectedOrders.some((item) => item.id === inviatation.id);
    if (!findedItem) {
      setSelectedOrders((prevSelectedOrders) => [...prevSelectedOrders, inviatation]);
    } else {
      setSelectedOrders((prevSelectedOrders) =>
        prevSelectedOrders.filter((item) => item.id !== inviatation.id),
      );
    }
  };

  const handleSelectAll = (e) => {
    if (!e.target.checked || !invitationList.length > 0) {
      setSelectedOrders([]);
      return;
    }
    const invitationIds = invitationList.length > 0 && invitationList.map((inviation) => inviation);
    setSelectedOrders(invitationIds);
  };

  const deleteAllMutation = useMutation({
    mutationKey: ["allInvitationsDelete", "deleteAllInviation"],
    mutationFn: (data) => bulkDeleteInvitations(data),
    onMutate: () => {
      showCustomToast(
        {
          title: "Removing...",
        },
        "deleteAllInviation",
      );
    },
    onSuccess: (res) => {
      toast.dismiss("deleteAllInviation");
      showCustomToast({
        title: res.data.message || "Partner's removed successfully",
      });
      refetch();
      setSelectedOrders([]);
      closeModal();
      if (!refetchBillingData) {
        dispatch(setRefetchBillingData(true));
      }
      queryClient.invalidateQueries(["allInvitationsDelete", "get", "delete"]);
    },
    onError: (error) => {
      toast.dismiss("deleteAllInviation");
      showCustomToast({
        title: error.response.data.message || error.message,
      });
    },
    onSettled: () => {
      toast.dismiss("deleteAllInviation");
    },
  });

  const handleDelete = (ids) => {
    if (actionableInvitationId) {
      deleteUserMutation.mutate({ invitation_id: actionableInvitationId });
    } else {
      const allIds = ids.map((item) => item.id);
      if (allIds) {
        deleteAllMutation.mutate({ invitation_id: allIds });
      }
    }
  };

  /**
   * Calculates the percentage based on the given part and whole numbers.
   *
   * @param {number} part - The part of the whole value.
   * @param {number} whole - The total whole value.
   * @return {string} The calculated percentage rounded to two decimal places.
   */

  const openDeleteModal = (e) => {
    e.stopPropagation();
    const hasActiveOrdr = selectedOrders.some((order) => order.active_orders > 0);
    if (hasActiveOrdr) {
      dispatch(toggleInvitationDeleteModal());
    } else {
      dispatch(toggleDeletePertnerModal());
    }
  };

  const openSingleModal = (e, item) => {
    e.stopPropagation();
    setSelectedOrders([]);
    dispatch(toggleActionableInvitationId(item.id));
    const hasActiveOrdr = item.active_orders > 0;
    if (hasActiveOrdr) {
      dispatch(toggleInvitationDeleteModal());
    } else {
      dispatch(toggleDeletePertnerModal());
    }
  };

  return (
    <>
      <SettingsPermissionModal actionText="partners" />
      <Modal open={isDeletePertnerModalOpen} close={closeModal}>
        <CommonDiscardButton
          closeModal={closeModal}
          title="Remove Partners(s)"
          subText1="Are you sure you want to delete partner(s)? This action cannot be undone."
          className="max-w-[544px]"
          IconComponent={DeleteProfile}
          confirmButtonText="Remove"
          confirmButtonVariant="warning"
          confirmButtonClassName={"w-full h-11"}
          onConfirmClick={() => handleDelete(selectedOrders)}
        />
      </Modal>
      <Modal open={isInvitationDeleteModalOpen} close={closeModal}>
        <CommonDiscardButton
          closeModal={closeModal}
          title="Confirm Removal of Supply Chain Partner"
          subText1="Are you sure you want to remove this supply chain partner from your account? All active orders linked to this partner will be set to draft status. This action cannot be undone."
          className="max-w-[544px]"
          IconComponent={DeleteProfile}
          confirmButtonText="Confirm"
          confirmButtonVariant="warning"
          confirmButtonClassName={"w-full h-11"}
          onConfirmClick={() => handleDelete(selectedOrders)}
        />
      </Modal>
      <Modal open={isSubscriptionCreateModalOpen} close={closeModal} title={""}>
        <SubScriptionModal closeModal={closeSubscriptionModal} />
      </Modal>

      <div>
        <div className="w-full">
          <div className="grid grid-cols-12 justify-start items-center gap-4 md:gap-6 ">
            <div className="col-span-12 lg:col-span-6 w-full lg:max-w-[520px] mt-0">
              <CommonCard
                btnText={"Upgrade plan"}
                minHeight={188}
                link={`/workspace/${currentWorkspace?.user_workspace_id}/setting?selectedTab=plan`}
              >
                <div className="flex flex-col justify-start gap-4  md:gap-6">
                  <div className="justify-between items-start flex w-full">
                    <div className="pt-1 flex-col justify-start items-start gap-1 inline-flex">
                      <div className="justify-start items-center gap-2 inline-flex">
                        <div className="text-global-title text-base md:text-lg font-semibold leading-7">
                          Plan Usage
                        </div>
                        <ChipButton className="!uppercase" childrenClassName="text-sm lg:text-base">
                          {currentPlan?.plan?.name ? currentPlan?.plan?.name : "FREE"}
                        </ChipButton>
                      </div>
                      <p className="text-xs lg:text-sm text-global-paragraph font-normal">
                        You have used{" "}
                        {currentPlan?.partner_links_count ? currentPlan?.partner_links_count : 0}{" "}
                        out of{" "}
                        {currentPlan?.plan?.package?.partner_links
                          ? currentPlan?.plan?.package?.partner_links
                          : 0}{" "}
                        supply chain partner credits from your current plan.
                      </p>
                    </div>
                  </div>
                  <div className="grid grid-cols-12 gap-3">
                    <div className="col-span-12">
                      <p className="text-sm font-medium text-global-title">
                        {currentPlan?.partner_links_count ? currentPlan?.partner_links_count : 0}{" "}
                        of{" "}
                        {currentPlan?.plan?.package?.partner_links
                          ? currentPlan?.plan?.package?.partner_links
                          : 0}{" "}
                        partners
                      </p>
                    </div>
                    <div className="col-span-12">
                      <LineProgressBar
                        className="h-[8px]"
                        variant="green"
                        progress={
                          currentPlan?.partner_links_count &&
                            currentPlan?.plan?.package?.partner_links
                            ? calculatePercentage(
                              currentPlan?.partner_links_count,
                              currentPlan?.plan?.package?.partner_links,
                            )
                            : 0
                        }
                      />
                    </div>
                  </div>
                </div>
              </CommonCard>
            </div>
          </div>
        </div>
        <Divider className={"my-4 lg:my-[35px]"} />

        <div className="bg-btn-surface-secondary border border-border-divider-stroke  rounded-xl custom-card w-full  mb-6">
          <div className="flex flex-col lg:flex-row justify-center lg:justify-between py-[11.5px] lg:py-5 px-4 lg:px-6 gap-3 lg:gap-5">
            <div className="flex flex-col gap-1">
              <div className="flex gap-2 justify-start items-center">
                <h3 className="text-base lg:text-lg font-semibold text-global-title leading-5 lg:leading-7">
                  Supply Chain Partners
                </h3>
                <ChipButton
                  className={
                    "text-[10px] lg:text-xs text-global-paragraph !h-[22px] !px-1.5 whitespace-nowrap"
                  }
                >
                  {currentPlan?.partner_links_count} partners
                </ChipButton>
              </div>
              <p className="text-xs lg:text-sm text-global-paragraph leading-5">
                Manage your supply chain partners and their account permissions here.
              </p>
            </div>
            <div className="flex flex-col lg:flex-row gap-3">
              <Button
                variant="secondary"
                className={"w-full lg:w-[157px] h-10 py-2.5 px-[14px] "}
                onClick={handleDownloadCSV}
                onMouseEnter={() => setIshovered(true)}
                onMouseLeave={() => setIshovered(false)}
              >
                <DownloadCloudIcon
                  width={20}
                  height={20}
                  strokeColor={
                    isHovered
                      ? "var(--color-icon-fill-secondary-button)"
                      : "var(--color-txt-secondary)"
                  }
                />{" "}
                Download CSV
              </Button>
              <div className="hidden md:block">
                {selectedOrders.length > 0 && ( // Check if any orders are selected
                  <Button
                    variant={"primary"}
                    className={
                      "flex gap-1.5 justify-center items-center whitespace-nowrap w-full lg:w-[111px] h-10 py-2.5 px-[14px]"
                    }
                    onClick={openDeleteModal}
                  >
                    Remove
                    <Trash
                      className={"w-5 h-5"}
                      strokeColor="var(--color-icon-fill-secondary-button)"
                    />
                  </Button>
                )}
              </div>
            </div>
          </div>
          <Divider />
          <div className="mx-4 lg:mx-6 my-3">
            <SearchBoxComponent
              handleViewDetailsClick={handleViewDetailsClick}
              setEnteredSearchKey={setEnteredSearchKey}
            />
            <div className="mt-3 md:mt-0 block md:hidden">
              {selectedOrders.length > 0 && ( // Check if any orders are selected
                <Button
                  variant={"primary"}
                  className={
                    "flex gap-1.5 justify-center items-center whitespace-nowrap w-full lg:w-[111px] h-10 py-2.5 px-[14px]"
                  }
                  onClick={openDeleteModal}
                >
                  Remove
                  <Trash
                    className={"w-5 h-5"}
                    strokeColor="var(--color-icon-fill-secondary-button)"
                  />
                </Button>
              )}
            </div>
          </div>
          <div className="w-full max-w-screen overflow-x-auto ">
            <table className="w-full border-spacing-0 table-auto rounded-table-custom overflow-x-auto">
              <thead className="text-sm text-left whitespace-nowrap text-global-gray-brand">
                <tr className="border-b border-t border-b-global-divider-soft border-t-global-divider-soft">
                  <th className="pl-6 pr-4 py-[11.5px]">
                    <div className="flex items-center gap-3">
                      <input
                        type="checkbox"
                        className={`rounded-md  w-5 h-5 ${invitationList && invitationList.length > 0 ? "cursor-pointer" : "cursor-default"}`}
                        onChange={handleSelectAll}
                        checked={
                          invitationList &&
                          invitationList.length > 0 &&
                          selectedOrders.length === invitationList.length
                        }
                      />
                      <div className="flex items-center">
                        <span className="mr-1 text-xs font-medium text-global-gray-brand leading-[18px]">
                          Company name
                        </span>
                        <SortIcons
                          hasData={invitationList && invitationList.length > 0}
                          sortName="company_name"
                          {...{ orderBy, setOrderBy }}
                        />
                      </div>
                    </div>
                  </th>

                  <th className="relative px-4  py-[11.5px]  ">
                    <div className="flex items-center">
                      <span className="mr-1 text-xs font-medium text-global-gray-brand leading-[18px]">
                        Status
                      </span>
                      <SortIcons
                        hasData={invitationList && invitationList.length > 0}
                        sortName="status"
                        {...{ orderBy, setOrderBy }}
                      />
                    </div>
                  </th>

                  <th className="relative px-4 py-[11.5px]  ">
                    <div className="flex items-center">
                      <span className="mr-1 text-xs font-medium text-global-gray-brand leading-[18px]">
                        Type
                      </span>
                      <SortIcons
                        hasData={invitationList && invitationList.length > 0}
                        sortName="company_type"
                        {...{ orderBy, setOrderBy }}
                      />
                    </div>
                  </th>

                  <th className="relative px-4 py-[11.5px]  ">
                    <div className="flex items-center">
                      <span className="mr-1 text-xs font-medium text-global-gray-brand leading-[18px]">
                        First Name
                      </span>
                      <SortIcons
                        hasData={invitationList && invitationList.length > 0}
                        sortName="first_name"
                        {...{ orderBy, setOrderBy }}
                      />
                    </div>
                  </th>
                  <th className="relative px-4 py-[11.5px]  ">
                    <div className="flex items-center">
                      <span className="mr-1 text-xs font-medium text-global-gray-brand leading-[18px]">
                        Last Name
                      </span>
                      <SortIcons
                        hasData={invitationList && invitationList.length > 0}
                        sortName="last_name"
                        {...{ orderBy, setOrderBy }}
                      />
                    </div>
                  </th>
                  <th className="relative px-4 py-[11.5px]  ">
                    <div className="flex items-center">
                      <span className="mr-1 text-xs font-medium text-global-gray-brand leading-[18px]">
                        Active Orders
                      </span>
                      <SortIcons
                        hasData={invitationList && invitationList.length > 0}
                        sortName="active_orders"
                        {...{ orderBy, setOrderBy }}
                      />
                    </div>
                  </th>
                  <th className="relative px-4 py-[11.5px]  ">
                    <div className="flex items-center">
                      <span className="mr-1 text-xs font-medium text-global-gray-brand leading-[18px]">
                        Email
                      </span>
                      <SortIcons
                        hasData={invitationList && invitationList.length > 0}
                        sortName="email"
                        {...{ orderBy, setOrderBy }}
                      />
                    </div>
                  </th>
                  <th className="relative px-4 py-[11.5px]  ">
                    <div className="flex items-center">
                      <span className="mr-1 text-xs font-medium text-global-gray-brand leading-[18px]">
                        Invited by
                      </span>
                      <SortIcons
                        hasData={invitationList && invitationList.length > 0}
                        sortName="invited_by"
                        {...{ orderBy, setOrderBy }}
                      />
                    </div>
                  </th>
                  <th className="relative px-4 py-[11.5px]  ">
                    <div className="flex items-center">
                      <span className="mr-1 text-xs font-medium text-global-gray-brand leading-[18px]">
                        Invited
                      </span>
                      <SortIcons
                        hasData={invitationList && invitationList.length > 0}
                        sortName="created_at"
                        {...{ orderBy, setOrderBy }}
                      />
                    </div>
                  </th>
                  <th className="relative px-4 py-[11.5px]  ">
                    <div className="flex items-center">
                      <span className="mr-1 text-xs font-medium text-global-gray-brand leading-[18px]">
                        Activated
                      </span>
                      <SortIcons
                        hasData={invitationList && invitationList.length > 0}
                        sortName="accepted_at"
                        {...{ orderBy, setOrderBy }}
                      />
                    </div>
                  </th>
                  <th className="relative px-4 py-[11.5px]  ">
                    <div className="flex items-center">
                      <span className="mr-1"> </span>
                    </div>
                  </th>
                </tr>
              </thead>

              <tbody>
                {!isLoading && invitationList && invitationList?.length > 0 ? (
                  invitationList?.map((invitation, index) => (
                    <tr
                      key={index}
                      onClick={(e) => {
                        e.stopPropagation();
                        invitation.status === "accepted" &&
                          handleViewDetailsClick(
                            invitation.company_type,
                            invitation.invited_workspace_id,
                            invitation.status,
                            invitation.workspace_id,
                          );
                      }}
                      className={`whitespace-nowrap border-b border-b-global-divider-soft  ${index % 2 === 0 ? "bg-global-white" : "bg-white"} rounded-lg text-global-gray-brand text-sm font-normal ${invitation.status === "accepted" ? "cursor-pointer" : ""}`}
                    >
                      <td className="pl-6 pr-4 py-[15.5px]  ">
                        <div className="flex items-center gap-3">
                          <input
                            onChange={() => onClickCheckbox(invitation)}
                            onClick={(e) => e.stopPropagation()}
                            checked={selectedOrders.some((item) => item.id === invitation?.id)}
                            type="checkbox"
                            className="rounded-md border cursor-pointer w-5 h-5  "
                          />
                          <div className="flex justify-start items-center gap-3">
                            {invitation && invitation.company_name &&
                              <div className="relative">
                                <>
                                  <div className="flex bg-global-surface uppercase justify-center items-center text-xs md:text-base font-semibold text-global-paragraph leading-6 w-8 h-8 md:h-10 md:w-10 p-2 ring-1 ring-global-divider-medium ring-opacity-75 ring-inset rounded-full border-none">
                                    {invitation && invitation.company_name
                                      ? getInitials(invitation?.company_name)
                                      : ""}
                                  </div>
                                  <BsCircleFill
                                    className={`absolute bottom-0 right-0 w-2.5 h-2.5 ${invitation?.status === "accepted" ? "text-global-primary" : "text-global-paragraph"}`}
                                  />
                                </>
                              </div>
                            }
                            <div className="flex flex-col">
                              <p className="text-sm leading-5 font-medium text-global-title">
                                {invitation?.company_name}
                              </p>
                              <p className="text-sm text-global-gray-brand leading-5">
                                {invitation?.role}
                              </p>
                            </div>
                          </div>
                        </div>
                      </td>

                      <td className=" px-4 py-[15.5px]  ">
                        {invitation?.status ? (
                          <Button
                            variant="status"
                            className={
                              " flex justify-center items-center gap-1 px-1.5 py-[2px]  h-[22px] text-global-gray-brand font-medium text-xs leading-[18px]  border border-border-divider-stroke custom-card cursor-default "
                            }
                          >
                            <BsCircleFill
                              className={`w-1.5 h-1.5 ${invitation?.status === "accepted" ? "text-global-primary" : "text-global-paragraph"}`}
                            />
                            {invitation.status.toLowerCase() === "accepted"
                              ? "Active"
                              : invitation.status.toLowerCase() === "pending"
                                ? "Invite Pending"
                                : invitation.status}
                          </Button>
                        ) : (
                          null
                        )}
                      </td>
                      <td className="relative px-4 py-[15.5px] ">
                        {invitation?.company_type ? (
                          <Button
                            variant="status"
                            className={`rounded-[6px] w-16 h-[22px] px-2 py-[2px]  border border-border-divider-stroke custom-card cursor-default capitalize   text-status-text-default  text-xs leading-[18px] font-medium `}
                          >
                            {invitation?.company_type}
                          </Button>
                        ) : (
                          null
                        )}
                      </td>
                      <td className="relative px-4 py-[15.5px] text-sm text-global-gray-brand leading-5  ">
                        {invitation?.first_name
                          ? truncateString(invitation.first_name)
                          : null}
                      </td>
                      <td className="relative px-4 py-[15.5px] text-sm text-global-gray-brand leading-5  ">
                        {invitation?.last_name
                          ? truncateString(invitation.last_name)
                          : null}
                      </td>
                      <td className="relative px-4 py-[15.5px] text-sm text-global-gray-brand leading-5  ">
                        {invitation?.active_orders}
                      </td>
                      <td className="relative px-4 py-[15.5px] text-sm text-global-gray-brand leading-5  ">
                        {invitation?.email ? truncateString(invitation.email) : null}
                      </td>
                      <td className="relative px-4 py-[15.5px] text-sm text-global-gray-brand leading-5  ">
                        {invitation?.inviter
                          ? truncateString(`${invitation?.inviter?.name}`)
                          : null}
                      </td>

                      <td className="relative px-4 py-[15.5px] text-sm text-global-gray-brand leading-5  ">
                        {invitation?.created_at
                          ? formatDateToDayMonthYear(invitation?.created_at)
                          : null}
                      </td>
                      <td className="relative px-4 py-[15.5px] text-sm text-global-gray-brand leading-5  ">
                        {invitation?.accepted_at
                          ? formatDateToDayMonthYear(invitation?.accepted_at)
                          : null}
                      </td>
                      <td className="relative cursor-pointer px-4 py-[15.5px] text-sm text-global-gray-brand leading-5  ">
                        <Trash
                          strokeColor={"var(--color-icon-outline-default-hover)"}
                          onClick={(e) => openSingleModal(e, invitation)}
                        />
                      </td>
                    </tr>
                  ))
                ) : isLoading ? (
                  <tr>
                    <td colSpan={11} className="text-center pb-3">
                      <LoadingComponent />
                    </td>
                  </tr>
                ) : enteredSearchKey && invitationList && invitationList?.length === 0 ? (
                  <EmptyTable colSpan={11} text="Result not found" />
                ) : (
                  <EmptyTable colSpan={11} />
                )}
              </tbody>
            </table>
          </div>
          {allInviattionData && allInviattionData.data && allInviattionData.total > 0 && (
            <TertiaryPagination
              page={page}
              totalPages={Math.ceil(allInviattionData.total / size)}
              setPage={setPage}
              size={size}
              handleSizeChange={handleSizeChange}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default InvitationList;
