import React from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";

const FormHeader = ({ title, titleTwo, subtitle, subtitle2, subtitle3, subtitle4, boldText, icon, boldText4 }) => {
  return (
    <>

      {title && (
        <div className="grid gap-x-1">
          <h2 className={`font-semibold text-center text-login-text-title text-2xl md:text-[30px] ${titleTwo ? "leading-tight" : "mb-2 md:mb-3 leading-[32px] md:leading-[38px] "}`}>
            {title}
          </h2>
          {
            titleTwo && <h2 className="mb-2 font-semibold text-center text-login-text-title text-2xl md:text-[30px] leading-[32px] md:leading-[38px] md:mb-3">
              {titleTwo}
            </h2>
          }
          <p className="font-normal text-center text-login-text-paragraph  text-base leading-6">
            {subtitle}
            {boldText && <strong>&nbsp;{boldText}</strong>}&nbsp;{subtitle2}
          </p>
          <p className="px-5 font-normal text-center text-login-text-paragraph leading-6 text-xs md:text-base">
            {subtitle3}
          </p>
          {subtitle4 && (
            <h2 className="mt-3 font-normal text-center text-login-text-paragraph  text-base leading-6">
              {subtitle4}
              {boldText4 && <strong>&nbsp;{boldText4}</strong>}
            </h2>
          )}

        </div>
      )}
    </>
  );
};

export default FormHeader;
