import * as z from "zod";
export const certificateSchema = z.object({
  issued_at: z.object({
    startDate: z
      .string({
        required_error: 'Required',
        invalid_type_error: 'Required',
      })
      .min(1, 'Required'),
    endDate: z.string(),
  }),

  expired_at: z.object({
    startDate: z
      .string({
        required_error: 'Required',
        invalid_type_error: 'Required',
      })
      .min(1, 'Required'),
    endDate: z.string(),
  }),
  certificate_type_id: z.object(
    {
      label: z.string({ message: "Required" }),
      value: z.number({ message: "Required" }),
    },
    {
      required_error: "Required",
      invalid_type_error: "Required",
    },
  ),

  certified_by_id: z.object(
    {
      label: z.string({ message: "Required" }),
      value: z.number({ message: "Required" }),
    },
    {
      required_error: "Required",
      invalid_type_error: "Required",
    },
  ),
  certificate_id: z.string().min(1, { message: "Required" }).max(100, { message: "Certificate number must not exceed 100 characters" }),
  workspace_id: z.number({
    required_error: "Workspace id required",
    invalid_type_error: "Workspace id required",
  }),
  cert_files: z.array(z.any({
    required_error: 'Required',
    invalid_type_error: 'Required',
  }))
    .nonempty({ message: "Required" }),

});

export const workspaceCertificateSchema = z.object({
  issued_at: z.object({
    startDate: z
      .string({
        required_error: 'Required',
        invalid_type_error: 'Required',
      })
      .min(1, 'Required'),
    endDate: z.string(),
  }),

  expired_at: z.object({
    startDate: z
      .string({
        required_error: 'Required',
        invalid_type_error: 'Required',
      })
      .min(1, 'Required'),
    endDate: z.string(),
  }),
  certificate_type_id: z.object(
    {
      label: z.string({ message: "Required" }),
      value: z.number({ message: "Required" }),
    },
    {
      required_error: "Required",
      invalid_type_error: "Required",
    },
  ),

  certified_by_id: z.object(
    {
      label: z.string({ message: "Required" }),
      value: z.number({ message: "Required" }),
    },
    {
      required_error: "Required",
      invalid_type_error: "Required",
    },
  ),
  certificate_id: z.string().min(1, { message: "Required" }).max(100, { message: "Certificate number must not exceed 100 characters" }),
  workspace_id: z.object(
    {
      label: z.string({ message: "Required" }),
      value: z.number({ message: "Required" }),
    },
    {
      required_error: "Required",
      invalid_type_error: "Required",
    },
  ),
  cert_files: z.array(z.any({
    required_error: 'Required',
    invalid_type_error: 'Required',
  }))
    .nonempty({ message: "Required" }),

});