import React, { useCallback, useState } from "react";
// import required modules
import { VideoContenticon } from "assests";
import { Button } from "components/UI/Button";
import { WelcomeCardStepList } from "utils/constant";
import { useDispatch, useSelector } from "react-redux";
import { render } from "@testing-library/react";

const StepsData = ({ activeStep, step, handleStepChange, discardModal }) => {
  const { selectedPlanData } = useSelector((state) => state.subscriptionSlice);
  const renderIcon = (step) => {
    switch (step) {
      case 1:
        return VideoContenticon;
      case 2:
        return VideoContenticon;
      case 3:
        return VideoContenticon;
      case 4:
        return VideoContenticon;
      default:
        return null;
    }
  };

  // Function to get the step label text
  const getStepDescription = (step) => {
    switch (step) {
      case 1:
        return `We're glad to have you onboard. Here are some quick tips to get you up and running.`;
      case 2:
        return `We're glad to have you onboard. Here are some quick tips to get you up and running.`;
      case 3:
        return `We're glad to have you onboard. Here are some quick tips to get you up and running.`;
      case 4:
        return `We're glad to have you onboard. Here are some quick tips to get you up and running.`;
      default:
        return "";
    }
  };
  return (
    <div className=" flex-col justify-start items-center gap-6 inline-flex ">
      <img src={renderIcon(step)} alt="" className="rounded-lg" />
      <div className=" flex-col justify-start items-center gap-1 inline-flex">
        <div className="self-stretch text-center text-global-title text-lg font-semibold leading-7">
          Welcome to {selectedPlanData && selectedPlanData?.name?.toUpperCase()}
        </div>
        <div className="self-stretch text-center text-global-paragraph text-sm font-normalleading-tight">
          {getStepDescription(step)}
        </div>
      </div>
      <div className="flex justify-center items-center gap-4">
        {WelcomeCardStepList.map((item, index) => (
          <div
            key={index}
            className={`w-[10px] h-[10px]  rounded-full cursor-pointer ${
              activeStep === item.step
                ? "bg-global-primary"
                : activeStep > item.step
                  ? "bg-[#E4E7EC]"
                  : "bg-[#E4E7EC]"
            }`}
          >
            {console.log("hello")}
          </div>
        ))}
      </div>
      <div className="pt-2 flex justify-center items-center gap-3">
        <Button
          onClick={discardModal}
          variant="secondary"
          className="h-11 font-semibold text-base w-[146px]"
        >
          Skip
        </Button>
        <Button
          onClick={handleStepChange ? handleStepChange : discardModal}
          variant="primary"
          className="h-11 font-semibold text-base w-[146px]"
        >
          {step !== 4 ? "Continue" : "Done"}
        </Button>
      </div>
    </div>
  );
};

const WelcomeCard = ({ discardModal }) => {
  const [activeStep, setActiveStep] = useState(1); // Define activeStep state
  const { isSidebarCollapsed } = useSelector((state) => state.navbarSlice);
  const handleStepChange = () => {
    setActiveStep((activeStep) => activeStep + 1);
  };

  return (
    <div
      className={`fixed inset-0 z-[51] px-4 md:px-0 transition-opacity bg-[#444656] flex justify-center items-center bg-opacity-50 ${isSidebarCollapsed ? "left-0 md:left-16" : "md:left-[292px]"}`}
    >
      <div className="w-full max-w-[400px] md:w-[400px] z-10  bg-global-white welcomeCard-swiper rounded-xl p-4 md:p-6">
        {activeStep === 1 && (
          <StepsData
            activeStep={activeStep}
            step={1}
            discardModal={discardModal}
            handleStepChange={handleStepChange}
          />
        )}
        {activeStep === 2 && (
          <StepsData
            activeStep={activeStep}
            step={2}
            discardModal={discardModal}
            handleStepChange={handleStepChange}
          />
        )}
        {activeStep === 3 && (
          <StepsData
            activeStep={activeStep}
            step={3}
            discardModal={discardModal}
            handleStepChange={handleStepChange}
          />
        )}
        {activeStep === 4 && (
          <StepsData activeStep={activeStep} step={4} discardModal={discardModal} />
        )}
      </div>
    </div>
  );
};

export default WelcomeCard;
