import { ArrowLeft, CheckCircleOutlined, LockSuccess, ResetPasswordSuccessIcon } from "assests";
import BackButton from "components/UI/BackButton";
import { Button } from "components/UI/Button";
import DynamicSVG from "components/UI/DynamicSVG";
import FormHeader from "components/UI/FormHeader";
import SigninLogo from "components/UI/SigninLogo";
import React from "react";
import { Link, useNavigate } from "react-router-dom";

const ResetPasswordSuccess = () => {
  const navigate = useNavigate();
  return (
    <div className="flex items-center justify-center">
      <div className="relative w-full px-4 md:px-0 md:w-[360px]">
        <div className="flex flex-col gap-8">
          <div className="flex-col justify-center items-center inline-flex gap-6">
            <SigninLogo>
              <CheckCircleOutlined width={28} height={28} />
            </SigninLogo>
            <FormHeader icon={false} title="Password reset" subtitle="Your FiberVue™ password has been successfully reset. Click below to log in magically." />
          </div>
          <div className="flex justify-center">
            <Button
              className="w-full text-base h-[44px] font-semibold"
              onClick={() => navigate("/signin")}
            >
              Continue
            </Button>
          </div>
          <BackButton text="Back to log in" url="/signin" />
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordSuccess;
