import { Cross, TemplateIcon } from "assests";
import { Button } from "components/UI/Button";
import CustomSelect from "components/UI/CustomSelect";
import Divider from "components/UI/Divider";
import ErrorMessage from "components/UI/ErrorMessage";
import { Input } from "components/UI/Input";
import { Label } from "components/UI/Label";
import UpgradeComponent from "components/UI/UpgradeComponent";
import useCreateTemplate from "hooks/useCreateTemplate";
import { truncateString } from "utils/helper";

const OrderTemplateForm = ({ closeModal, refetch }) => {
    const {
        control,
        tierList,
        tiersItem,
        errors,
        createMutation,
        discardModal,
        isTemplateEditable,
        actionableTemplateId,
        isPending,
        templateDetails,
        handleDiscardModal,
        register,
        handleSubmit,
        onFormError,
        handleFormSubmit,
        handleTierOptionSelect,
        reset,
        toggleEditOrDiscard,
        handleConfirmDiscard,
        isDuplicateAction,
        actionableWorkspaceId,
    } = useCreateTemplate({ refetch });

    const handleCancel = () => {
        reset();
        closeModal();
    };

    return (
        <>
            <div className="w-full lg:w-[600px] py-6  z-[2]  rounded-xl bg-white ">
                <div className="grid grid-cols-1 gap-4 lg:gap-6 w-full ">
                    <div className="absolute right-5 top-5 cursor-pointer z-[2] ">
                        <Cross fillColor={"#A4A7AE"} height={24} width={24} onClick={closeModal} />
                    </div>
                    <div className="flex justify-start  px-6 z-[1] ">
                        <UpgradeComponent>
                            <TemplateIcon />
                        </UpgradeComponent>

                        <div className="flex justify-between gap-6 lg:gap-8 px-3 lg:px-4 z-[2]">
                            <div className="flex flex-col gap-1">
                                <h2 className="font-semibold text-sm lg:text-lg leading-7 text-start text-primary-900 w-full">
                                    {actionableTemplateId &&
                                        isDuplicateAction &&
                                        truncateString(`Duplicate Template-${templateDetails?.name}`)}
                                    {actionableTemplateId &&
                                        !isDuplicateAction &&
                                        truncateString(`Template-${templateDetails?.name}`)}
                                    {!actionableTemplateId && "New Supply Chain Template"}
                                </h2>
                                <p className="text-sm text-login-text-paragraph font-normal truncate leading-5">
                                    {actionableTemplateId &&
                                        templateDetails &&
                                        truncateString(`Edit template details here.`)}
                                    {!actionableTemplateId && "Add template details here."}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="z-[2]">
                        <form onSubmit={handleSubmit(handleFormSubmit, onFormError)} method="post">
                            <div className="grid grid-cols-1 w-full px-6  max-h-[392px] overflow-auto mt-3 lg:mt-5 ">
                                <div className="grid gap-1.5 w-full pb-4 lg:pb-6 ">
                                    <Label
                                        htmlFor="templateName"
                                        className="text-sm leading-5 font-medium whitespace-nowrap"
                                    >
                                        Template Name
                                    </Label>
                                    <Input
                                        id="templateName"
                                        type="text"
                                        size="xl"
                                        placeholder="Men’s t-shirt with print"
                                        className={`px-[14px] h-11 py-2.5 text-table-text-data-dark w-full text-base placeholder:text-base placeholder:text-global-paragraph-2  focus:border-[#D5D7DA]  hover:border-[#D5D7DA]  `}
                                        {...register("name")}
                                    />
                                    <ErrorMessage errors={errors?.name?.message} />
                                </div>

                                {tierList && (
                                    <>
                                        <div className="flex flex-row gap-6 lg:gap-8 w-full py-4">
                                            <Label
                                                htmlFor="templateName"
                                                className="text-sm leading-5 pr-1 tracking-wide font-medium whitespace-nowrap"
                                            >
                                                Tier 4
                                            </Label>
                                            <div className="w-full grid grid-cols-1 gap-1.5">
                                                <CustomSelect
                                                    control={control}
                                                    name="subtiers"
                                                    placeholder="Select Template"
                                                    options={tierList["tier_5"]}
                                                    handleOnChange={(selectedOption) =>
                                                        handleTierOptionSelect("tier_5", selectedOption)
                                                    }
                                                    {...register("tier_5")}
                                                    value={tiersItem["tier_5"]}
                                                    isClearable={false}
                                                    isMulti={true}
                                                    style={{
                                                        padding: "3.34px 10px 3.34px 6px",
                                                        fontSize: "12px",
                                                        width: "100%",
                                                        noHeight: true,
                                                    }}
                                                />
                                                <ErrorMessage errors={errors?.tier_5?.message} />
                                            </div>
                                        </div>
                                        <div className="flex flex-row gap-6 lg:gap-8 py-4">
                                            <Label
                                                htmlFor="templateName"
                                                className="text-sm leading-5 pr-1 tracking-wide font-medium whitespace-nowrap"
                                            >
                                                Tier 3
                                            </Label>
                                            <div className="w-full grid grid-cols-1 gap-1.5">
                                                <CustomSelect
                                                    control={control}
                                                    name="tier 3"
                                                    placeholder="Select Template"
                                                    options={tierList["tier_4"]}
                                                    handleOnChange={(selectedOption) =>
                                                        handleTierOptionSelect("tier_4", selectedOption)
                                                    }
                                                    {...register("tier_4")}
                                                    value={tiersItem["tier_4"] || []}
                                                    isClearable={false}
                                                    isMulti={true}
                                                    style={{
                                                        padding: "3.34px 10px 3.34px 6px",
                                                        fontSize: "12px",
                                                        width: "100%",
                                                        noHeight: true,
                                                    }}
                                                />
                                                <ErrorMessage errors={errors?.tier_4?.message} />
                                            </div>
                                        </div>
                                        <div className="flex flex-row gap-6 lg:gap-8 py-4">
                                            <Label
                                                htmlFor="templateName"
                                                className="text-sm leading-5 pr-1 tracking-wide font-medium whitespace-nowrap"
                                            >
                                                Tier 2
                                            </Label>
                                            <div className="w-full grid grid-cols-1 gap-1.5 ">
                                                <CustomSelect
                                                    control={control}
                                                    name="tier 2"
                                                    placeholder="Select Template"
                                                    options={tierList["tier_3"]}
                                                    handleOnChange={(selectedOption) =>
                                                        handleTierOptionSelect("tier_3", selectedOption)
                                                    }
                                                    {...register("tier_3")}
                                                    value={tiersItem["tier_3"]}
                                                    isClearable={false}
                                                    isMulti={true}
                                                    style={{
                                                        padding: "3.34px 10px 3.34px 6px",
                                                        fontSize: "12px",
                                                        width: "100%",
                                                        noHeight: true,
                                                    }}
                                                />
                                                <ErrorMessage errors={errors?.tier_3?.message} />
                                            </div>
                                        </div>
                                        <div className="flex flex-row gap-6 lg:gap-8 py-4">
                                            <Label
                                                htmlFor="templateName"
                                                className="text-sm leading-5 pr-1 tracking-wide font-medium whitespace-nowrap"
                                            >
                                                Tier 1
                                            </Label>
                                            <div className="w-full grid grid-cols-1 gap-1.5">
                                                <CustomSelect
                                                    control={control}
                                                    name="tier 1"
                                                    placeholder="Select Template"
                                                    options={tierList["tier_2"]}
                                                    handleOnChange={(selectedOption) =>
                                                        handleTierOptionSelect("tier_2", selectedOption)
                                                    }
                                                    {...register("tier_2")}
                                                    value={tiersItem["tier_2"]}
                                                    isClearable={false}
                                                    isMulti={true}
                                                    style={{
                                                        padding: "3.34px 10px 3.34px 6px",
                                                        fontSize: "12px",
                                                        width: "100%",
                                                        noHeight: true,
                                                    }}
                                                />
                                                <ErrorMessage errors={errors?.tier_2?.message} />
                                            </div>
                                        </div>
                                        <div className="flex flex-row gap-6 lg:gap-8 w-full py-4">
                                            <Label
                                                htmlFor="templateName"
                                                className="text-sm leading-5 pr-1 tracking-wide font-medium whitespace-nowrap"
                                            >
                                                Tier 0
                                            </Label>
                                            <div className="w-full grid grid-cols-1 gap-1.5">
                                                <CustomSelect
                                                    control={control}
                                                    name="tier 0"
                                                    placeholder="Select Template"
                                                    options={tierList["tier_1"]}
                                                    handleOnChange={(selectedOption) =>
                                                        handleTierOptionSelect("tier_1", selectedOption)
                                                    }
                                                    {...register("tier_1")}
                                                    value={tiersItem["tier_1"]}
                                                    isClearable={false}
                                                    isMulti={true}
                                                    // isDisabled={!isTemplateEditable}
                                                    style={{
                                                        padding: "3.34px 10px 3.34px 6px",
                                                        fontSize: "12px",
                                                        width: "100%",
                                                        noHeight: true,
                                                    }}
                                                // isViewMode1={!isTemplateEditable}
                                                />
                                                <ErrorMessage errors={errors?.tier_1?.message} />
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                            {isTemplateEditable && <Divider className={"mb-6"} />}
                            <div className="grid gap-6  px-6 sticky  mt-4 lg:mt-0">
                                <div className="flex  justify-center items-center lg:justify-between gap-4">
                                    {!actionableTemplateId &&
                                        (!isTemplateEditable || isTemplateEditable) &&
                                        !isDuplicateAction && (
                                            <div className="flex flex-col lg:flex-row  w-full gap-3">
                                                <Button
                                                    type="button"
                                                    size={"md"}
                                                    variant={"secondary"}
                                                    className={
                                                        "w-full text-txt-secondary-secondary bg-white h-11 px-4 py-2.5 text-base leading-6 font-semibold custom-outline-shadow hover:text-white"
                                                    }
                                                    onClick={handleCancel}
                                                >
                                                    Cancel
                                                </Button>
                                                <Button
                                                    size={"md"}
                                                    type="submit"
                                                    className={"w-full text-base h-11 px-4 py-2.5"}
                                                    isDisabled={createMutation.isPending}
                                                >
                                                    {/* Create template */}
                                                    Save
                                                </Button>
                                            </div>
                                        )}

                                    {actionableTemplateId && isTemplateEditable && isDuplicateAction && (
                                        <div className="flex flex-col lg:flex-row  w-full gap-3">
                                            <Button
                                                type="button"
                                                size={"md"}
                                                variant={"secondary"}
                                                className={
                                                    "w-full text-txt-secondary-secondary bg-white h-11 px-4 py-2.5 text-base leading-6 font-semibold custom-outline-shadow hover:text-white"
                                                }
                                                onClick={handleCancel}
                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                type="submit"
                                                size={"md"}
                                                className="h-11 px-4 py-2.5 text-base w-full "
                                                isDisabled={isPending}
                                            >
                                                Duplicate template
                                            </Button>
                                        </div>
                                    )}

                                    {actionableTemplateId && isTemplateEditable && !isDuplicateAction && (
                                        <div className="flex flex-col lg:flex-row  w-full gap-3">
                                            <Button
                                                type="button"
                                                size={"md"}
                                                className={
                                                    "w-full text-txt-secondary-secondary bg-white h-11 px-4 py-2.5 text-base leading-6 font-semibold custom-outline-shadow hover:text-white"
                                                }
                                                variant={"secondary"}
                                                onClick={toggleEditOrDiscard}
                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                type="submit"
                                                size={"md"}
                                                className="h-11 px-4 py-2.5 text-base w-full "
                                                isDisabled={isPending}
                                            >
                                                Save
                                            </Button>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
};

export default OrderTemplateForm;
