import { Button } from "components/UI/Button";
import ErrorMessage from "components/UI/ErrorMessage";
import ToggleSwitch from "components/UI/ToggleSwitch";
import React, { useState } from "react";
import { useDispatch } from "react-redux";

const SecondStep = ({ setValue, closeModal, nextStep, errors, getValues }) => {
  const [activeRole, setActiveRole] = useState(null);

  const handleSwitchChange = (role) => {
    const newActiveRole = activeRole === role ? null : role;
    setActiveRole(newActiveRole);
    const newState = { Admin: false, Editor: false, Viewer: false, ActiveRole: newActiveRole };
    if (newActiveRole) {
      newState[newActiveRole] = true;
    }
    setValue("type", newActiveRole);
  };

  return (
    <div className="w-full">
      <div className="flex flex-col gap-1 mt-4 mb-3 lg:mb-5">
        <h3 className="text-lg font-semibold text-global-title">Invite Member</h3>
        <p className="text-sm text-global-paragraph ">Set member account permission.</p>
      </div>
      <div className="grid grid-cols-1 gap-3 px-2 lg:px-4 mb-[168px]">
        {["admin", "editor", "viewer"].map((role, index) => (
          <div key={index} className="flex flex-row gap-2">
            <div>
              <ToggleSwitch
                autoRenew={activeRole === role}
                handleSwitchChange={() => handleSwitchChange(role)}
              />
            </div>
            <div className="flex flex-col">
              <p className="font-semibold text-sm leading-5 text-global-title-2 capitalize">
                {role}
              </p>
              <p className="text-sm text-global-paragraph font-normal ">
                {role === "admin"
                  ? "Full control over account."
                  : role === "editor"
                    ? "Can add and edit items and invite supply chain partners."
                    : "Can view workspace content."}
              </p>
            </div>
          </div>
        ))}
        <ErrorMessage errors={errors?.type?.message} />
      </div>
      <div className="pt-5 lg:pt-[41.5px] flex justify-center lg:justify-end flex-col lg:flex-row items-center gap-3">
        <Button
          variant={"secondary"}
          className={"w-full lg:w-[90px] h-11 py-2.5 px-4 font-semibold text-base"}
          onClick={closeModal}
        >
          Cancel
        </Button>
        <Button
          variant={"primary"}
          className={`w-full  h-11 py-2.5 px-4 font-semibold text-base ${getValues("type") === "admin" ? "lg:w-[147px]" : "lg:w-[107px]"}`}
          onClick={nextStep}
        >
          {
            getValues("type") === "admin"
              ? "Invite member"
              : "Continue"
          }
        </Button>
      </div>
    </div>
  );
};

export default SecondStep;
