import React from "react";

const RightArrowSlide = React.forwardRef(
  (
    { width = "36", height = "36", viewBox = "0 0 36 36", fill = "none", className, ...props },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.8916 12.5581C16.1356 12.314 16.5314 12.314 16.7754 12.5581L21.7754 17.5581C22.0195 17.8021 22.0195 18.1979 21.7754 18.4419L16.7754 23.4419C16.5314 23.686 16.1356 23.686 15.8916 23.4419C15.6475 23.1979 15.6475 22.8021 15.8916 22.5581L20.4496 18L15.8916 13.4419C15.6475 13.1979 15.6475 12.8021 15.8916 12.5581Z"
          fill="#475467"
          stroke="#475467"
          strokeWidth="0.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  },
);

export default RightArrowSlide;
