import React from 'react';

const ChipButton = ({ children, className, childrenClassName }) => {
    return (
        <div className={`px-2 h-6 capitalize bg-global-theme rounded-md shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)] ring-1 ring-inset ring-border-primary justify-start items-center flex ${className}`}>
            <div className={`text-center text-status-text-default font-medium tracking-[0.002em] ${childrenClassName}`}>
                {children}
            </div>
        </div>
    );
};

export default ChipButton;