import axios from "axios";
import { API_URL } from "./environment";
import dayjs from "dayjs";

const api = axios.create({
  baseURL: API_URL,
  withCredentials: false,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

// Attach auth token to every request
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("auth");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error),
);

// Prevent multiple refresh token requests at the same time
let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });
  failedQueue = [];
};

// 🔴 **Force Logout Function**
const forceLogout = () => {
  console.warn("🔴 FORCE LOGOUT TRIGGERED: Clearing all auth data...");
  localStorage.clear();
  sessionStorage.clear();

  // 🔴 **Force remove all cookies**
  document.cookie.split(";").forEach((c) => {
    document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
  });

  // 🔄 **Force hard redirect**
  window.location.href = "/signin";
};

const saveApiUrl = () => {
  localStorage.setItem("currentApiUrl", API_URL);
};

// Compare stored API URL with the current API URL
const isApiUrlChanged = () => {
  const storedApiUrl = localStorage.getItem("currentApiUrl");
  return storedApiUrl !== API_URL;
};

// **API Interceptor for Handling 401 Errors**
api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    const expiration = localStorage.getItem("expiration");
    const refreshToken = localStorage.getItem("refresh_token");

    if (isApiUrlChanged()) {
      console.error("🔴 API URL has changed. Logging out...");
      forceLogout();
      return Promise.reject(error);
    }
    // 🔴 **Check if refresh token is expired/missing → LOGOUT**
    if (
      error.response?.status === 401 &&
      !originalRequest._retry &&
      (dayjs().isAfter(dayjs(expiration, "DD-MM-YYYY HH:mm")) || !refreshToken)
    ) {
      console.warn("🔴 Access token expired and refresh token missing/expired. Logging out...");
      forceLogout();
      return Promise.reject(error);
    }

    // 🔄 **Handle token refresh when access token expires**
    if (error.response?.status === 401 && !originalRequest._retry) {
      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject });
        })
          .then((token) => {
            originalRequest.headers["Authorization"] = `Bearer ${token}`;
            return api(originalRequest);
          })
          .catch((err) => Promise.reject(err));
      }

      originalRequest._retry = true;
      isRefreshing = true;

      if (refreshToken) {
        try {
          console.log("🔄 Attempting to refresh token...");
          const response = await api.post("/auth/refresh-token", null, {
            headers: { Authorization: `Bearer ${refreshToken}` },
          });

          if (response.status === 200) {
            const newToken = response.data.token;
            localStorage.setItem("auth", newToken);
            localStorage.setItem("expiration", dayjs().add(1, "hour").format("DD-MM-YYYY HH:mm"));

            api.defaults.headers.common["Authorization"] = `Bearer ${newToken}`;
            processQueue(null, newToken);

            originalRequest.headers["Authorization"] = `Bearer ${newToken}`;
            return api(originalRequest);
          } else {
            console.error("🔴 Refresh token invalid. Logging out...");
            forceLogout();
            return Promise.reject(error);
          }
        } catch (err) {
          console.error("🔴 Refresh token request failed. Logging out...");
          processQueue(err, null);
          forceLogout();
          return Promise.reject(err);
        } finally {
          isRefreshing = false;
        }
      }
    }

    // 🔴 **If still getting 401, force logout**
    if (error.response?.status === 401) {
      console.error("🔴 Unauthorized access detected. Logging out...");
      forceLogout();
    }

    return Promise.reject(error);
  }
);

// Ensure the API URL is saved after login
saveApiUrl();


export default api;