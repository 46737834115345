import { clsx } from "clsx";
import { Button } from "components/UI/Button";
import LoadingComponent from "components/UI/LoadingComponent";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import toast from "react-hot-toast";
import { twMerge } from "tailwind-merge";
dayjs.extend(customParseFormat);
export function cn(...args) {
  return twMerge(clsx(args));
}
export const MAX_FILE_SIZE_MB = 10;
export const fileSizeLimitBytes = MAX_FILE_SIZE_MB * 1024 * 1024;
export const defaultImgLimitMsg = `File Size Limit Exceeded, Ensure your file is under ${MAX_FILE_SIZE_MB}MB to proceed`;
export const percentageColors = (percentage) => {
  if (percentage === 100) {
    return "green";
  } else if (percentage >= 50) {
    return "blue";
  } else if (percentage >= 20) {
    return "yellow";
  } else {
    return "red";
  }
};

export const transpercencyPercentageColors = (percentage) => {
  if (percentage >= 80) {
    return "green";
  } else if (percentage >= 40) {
    return "yellow";
  } else {
    return "red";
  }
};

export const transpercencyLineProgressPercentageColors = (percentage) => {
  if (percentage >= 80) {
    return "--color-progress-surface-green";
  } else if (percentage >= 40) {
    return "--color-input-text-warning";
  } else {
    return "--color-global-danger";
  }
};

export const percentageIconColors = (percentage) => {
  if (percentage === 100) {
    return "text-[#11c182]";
  } else if (percentage >= 50) {
    return "text-[#FF922D]";
  } else if (percentage > 1) {
    return "text-[#FF614B]";
  } else {
    return "";
  }
};

export const passCriteriaArray = ["symbol"];

export const formatedDate = (dateTimeString) => {
  const [datePart, timePart] = dateTimeString.split(" ");
  const [day, month, year] = datePart.split("-");
  const date = new Date(`${year}-${month}-${day}T${timePart}`);

  const dayPart = date.getDate();
  const monthPart = date.toLocaleString("default", { month: "short" });
  const yearPart = date.getFullYear();

  // Formatting the date as "24 July, 2024"
  return `${dayPart} ${monthPart}, ${yearPart}`;
};

export const getPathType = (pathname, workspaceName = null) => {
  const pathSegments = pathname.split("/") || [];
  const typeMap = {
    retailers: "Retailers",
    articles: "Articles",
    templates: "Templates",
    suppliers: "Suppliers",
    agents: "Agents",
    subsuppliers: "Sub-Suppliers",
    orders: "Orders",
    invitations: "Invitations",
    "active-users": "Active Users",
    "profile-settings": "Profile Settings",
    workspaces: "Workspaces",
    "company-profile": "Company Profile",
    billing: "Billing",
    dashboard: workspaceName ? `${workspaceName} Dashboard` : `Dashboard`,
    "create-order": "Orders /Create order",
  };
  const key = Object.keys(typeMap).find((key) => pathSegments.includes(key)) || "";
  return typeMap[key] || "";
};

export const processImageData = async (fileData, fileSize, fileRef, callback) => {
  const image = new Image();
  image.src = fileData;
  image.onload = () => {
    if (fileSize > fileSizeLimitBytes) {
      toast.error(defaultImgLimitMsg);
      fileRef.current.value = "";
      return;
    } else {
      // Image is within the size limit
      if (callback) {
        callback(); // Invoke the callback function
      }
    }
  };
};

export const isDateExpired = (expiredAt) => {
  const [datePart, timePart] = expiredAt.split(" ");

  // Split the date into day, month, and year
  const [day, month, year] = datePart.split("-").map((part) => parseInt(part, 10));

  // Split the time into hours and minutes
  const [hours, minutes] = timePart.split(":").map((part) => parseInt(part, 10));

  // Create a Date object with the given date and time
  const givenDateTime = new Date(year, month - 1, day, hours, minutes);

  // Get the current date and time
  const currentDate = new Date();

  // Get the current date only (without time)
  const currentDateOnly = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate(),
  );

  // Get the given date only (without time)
  const givenDateOnly = new Date(year, month - 1, day);

  // Check if the given date is today
  if (givenDateOnly.getTime() === currentDateOnly.getTime()) {
    return false; // Date is today, so it is not expired
  }

  // Compare given date with the current date and time
  if (givenDateTime.getTime() < currentDate.getTime()) {
    return true; // Date has passed
  } else {
    return false; // Date has not passed
  }
};

export const formattedTodaysDateIntoObject = () => {
  const date = new Date();
  // Get day, month, and year from the Date object
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();

  // Format the date into YYYY-MM-DD
  const formattedDate = `${year}-${month}-${day}`;

  // Create the formatted date object using the formatted date
  const result = {
    startDate: formattedDate,
    endDate: formattedDate,
  };

  return result;
};

export const formattedDateIntoObject = (inputDate) => {

  if (inputDate) {
    // Check the format of the input date and split accordingly
    const datePart = inputDate.includes(" ") ? inputDate.split(" ")[0] : inputDate;
    let day, month, year;
    // Determine the format of the date part and split it into day, month, and year
    if (datePart.includes("-")) {
      const dateParts = datePart.split("-");

      if (dateParts[0].length === 4) {
        // Format is YYYY-MM-DD
        [year, month, day] = dateParts;
      } else {
        // Format is DD-MM-YYYY
        [day, month, year] = dateParts;
      }
    } else {
      throw new Error("Invalid date format");
    }

    // Format the date into YYYY-MM-DD
    const formattedDate = `${year}-${month}-${day}`;

    // Create the formatted date object using the formatted date
    const result = {
      startDate: formattedDate,
      endDate: formattedDate,
    };
    return result;
  }
};

export const formattedOrderDateIntoObject = (inputDate) => {
  if (!inputDate) return null;

  // Remove time if included
  const datePart = inputDate.includes(" ") ? inputDate.split(" ")[0] : inputDate;

  let day, month, year;

  if (datePart.includes("/")) {
    // Handle dates in DD/MM/YYYY or MM/DD/YYYY formats
    const dateParts = datePart.split("/");
    if (dateParts.length !== 3) {
      console.error("Invalid date format:", inputDate);
      return null;
    }

    // Assume DD/MM/YYYY if the first part is greater than 12
    if (parseInt(dateParts[0]) > 12) {
      [day, month, year] = dateParts;
    } else {
      [month, day, year] = dateParts;
    }
  } else if (datePart.includes("-")) {
    // Handle dates in YYYY-MM-DD or DD-MM-YYYY formats
    const dateParts = datePart.split("-");
    if (dateParts.length !== 3) {
      console.error("Invalid date format:", inputDate);
      return null;
    }

    if (dateParts[0].length === 4) {
      // Format is YYYY-MM-DD
      [year, month, day] = dateParts;
    } else {
      // Format is DD-MM-YYYY
      [day, month, year] = dateParts;
    }
  } else {
    console.error("Unsupported date format:", inputDate);
    return null;
  }

  // Validate and normalize day, month, and year
  day = parseInt(day, 10);
  month = parseInt(month, 10);
  year = parseInt(year, 10);

  if (!isValidDate(day, month, year)) {
    console.error("Invalid date components:", { day, month, year });
    return null;
  }

  // Format the date as YYYY-MM-DD
  const formattedDate = `${year.toString().padStart(4, "0")}-${month
    .toString()
    .padStart(2, "0")}-${day.toString().padStart(2, "0")}`;

  return {
    startDate: formattedDate,
    endDate: formattedDate,
  };
};

// Helper function to validate the date
const isValidDate = (day, month, year) => {
  if (year < 1000 || year > 9999) return false;
  if (month < 1 || month > 12) return false;

  // Days in each month
  const daysInMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

  // Check for leap year in February
  if (month === 2 && isLeapYear(year)) {
    return day >= 1 && day <= 29;
  }

  return day >= 1 && day <= daysInMonth[month - 1];
};

// Helper function to check for leap year
const isLeapYear = (year) => {
  return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
};

const formateDate = (date) => {
  const newDate = new Date(date);
  return dayjs(newDate).format("YYYY-MM-DD");
};

export const convertDate = (date) => {
  const newDate = new Date(date);
  return dayjs(newDate).format("DD-MM-YYYY");
};

// export const formattedDate = (date) => {
//   console.log(date);
//   const newDate = new Date(date);
//   return dayjs(newDate).format("DD/MM/YYYY");
// };
export const formattedDate = (date) => {
  // Define possible formats for the input date
  const formats = [
    "MM-DD-YYYY",
    "YYYY-MM-DD",
    "DD-MM-YYYY",
    "DD-MM-YYYY hh:mm a",
    "MM-DD-YYYY hh:mm a",
    "YYYY-MM-DD hh:mm a",
  ];
  const parsedDate = dayjs(date, formats, true);
  return parsedDate.isValid() ? parsedDate.format("DD/MM/YYYY") : "Invalid Date";
};

export const setTimeInDateObject = (dateObject) => {
  if (!dateObject || dateObject.startDate === null) {
    return {
      startDate: "",
      endDate: "",
    };
  }
  return {
    startDate: `${formateDate(dateObject.startDate)}`,
    endDate: `${formateDate(dateObject.endDate)}`,
  };
};

export const convertedDate = (inputDate) => {
  const [datePart, timePart] = inputDate.split(" ");
  const [day, month, year] = datePart.split("-");
  const [time, period] = timePart.split(" ");
  let [hours, minutes] = time.split(":");

  if (period?.toLowerCase() === "pm" && hours !== "12") {
    hours = parseInt(hours, 10) + 12;
  } else if (period?.toLowerCase() === "am" && hours === "12") {
    hours = "00";
  }
  const date = new Date(year, month - 1, day, hours, minutes);
  const options = { day: "numeric", month: "short", year: "numeric" };

  // Format the date
  const formattedDate = date.toLocaleDateString("en-US", options);

  return formattedDate;
};

export const formatOrderDate = (inputDate) => {
  const date = new Date(inputDate);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

export const convertToDateOnly = (dateTimeStr) => {
  const [date, time] = dateTimeStr.split(" ");
  return date;
};

export const urlToFile = async (url) => {
  const response = await fetch(url);
  const blob = await response?.blob();
  // Extract filename from URL
  const filename = url?.split("/")?.pop();
  const mimeType = response?.headers?.get("content-type") || "image/jpeg";

  return new File([blob], filename, { type: mimeType });
};

export const mapRender = (status) => {
  if (status === "FAILURE") return <h1>Error Occurred</h1>;
  return <LoadingComponent />;
};

export const getCssVariable = (variableName) => {
  const rootStyles = getComputedStyle(document.documentElement);
  return rootStyles.getPropertyValue(variableName).trim();
};

export const preventPasteNegative = (e) => {
  const clipboardData = e.clipboardData || window.clipboardData;
  const pastedData = parseFloat(clipboardData.getData("text"));

  if (pastedData < 0) {
    e.preventDefault();
  }
};

export const preventMinus = (e) => {
  if (e.code === "Minus") {
    e.preventDefault();
  }
};

export const preventSpaceAndPasteNegative = (e) => {
  const clipboardData = e.clipboardData || window.clipboardData;
  const pastedData = clipboardData.getData("text");

  // Prevent spaces and negative numbers (standalone - at the beginning)
  if (pastedData.startsWith("-") || /\s/.test(pastedData)) {
    e.preventDefault();
  }
};

export const preventSpaceMinus = (e) => {
  // Prevent entering minus at the beginning
  if ((e.target.value === "" && e.key === "-") || e.key === " ") {
    e.preventDefault();
  }
};

export const getCircleTransparencyScoreReduceVariant = (score) => {
  if (score >= 80) {
    return "--color-global-danger";
  } else if (score >= 40 && score < 80) {
    return "--color-global-warning";
  } else {
    return "--color-global-primary";
  }
};

export const parseDate = (dateString) => {
  // Extract day, month, year, time, and period (am/pm)
  const [datePart, timePart, period] = dateString.split(" ");
  const [day, month, year] = datePart.split("-").map(Number);
  let [hours, minutes] = timePart.split(":").map(Number);

  // Adjust hours for am/pm
  if (period.toLowerCase() === "pm" && hours < 12) hours += 12;
  if (period.toLowerCase() === "am" && hours === 12) hours = 0;

  // Return a Date object in the local timezone
  return new Date(year, month - 1, day, hours, minutes);
};

export const getCertificateProgress = (issued_at, expired_at) => {
  const issuedDate = parseDate(issued_at);
  const expiryDate = parseDate(expired_at);
  const today = new Date();

  const totalDays = Math.floor((expiryDate - issuedDate) / (1000 * 60 * 60 * 24));
  let remainingDays = Math.floor((expiryDate - today) / (1000 * 60 * 60 * 24));

  // Handle expired certificate case
  if (remainingDays <= 0) {
    remainingDays = 0;
    return {
      remainingDays,
      progressPercent: 100,
      progressColor: "red",
      progressThemeColor: "bg-progress-surface-red",
    };
  }

  const minWidth = 20; // minimum visible width as a percentage
  const scaledProgress = ((totalDays - remainingDays) / totalDays) * (100 - minWidth) + minWidth;
  const progressPercent = Math.min(scaledProgress, 100);
  // Determine color based on remaining days
  let progressColor = "green";
  let progressThemeColor = "bg-progress-surface-green";
  if (remainingDays >= 340) {
    progressColor = "green";
    progressThemeColor = "bg-progress-surface-green";
  } else if (remainingDays >= 120) {
    progressColor = "yellow";
    progressThemeColor = "bg-progress-surface-yellow";
  } else {
    progressColor = "red";
    progressThemeColor = "bg-progress-surface-red";
  }

  return { remainingDays, progressPercent, progressColor, progressThemeColor };
};
export const getAuditProgress = (issue_date, expired_at) => {
  const issuedDate = parseDate(issue_date);
  const expiryDate = parseDate(expired_at);
  const today = new Date();

  const totalDays = Math.floor((expiryDate - issuedDate) / (1000 * 60 * 60 * 24));
  let remainingDays = Math.floor((expiryDate - today) / (1000 * 60 * 60 * 24));

  // Handle expired certificate case
  if (remainingDays <= 0) {
    remainingDays = 0;
    return {
      remainingDays,
      progressPercent: 100,
      progressColor: "red",
      progressThemeColor: "bg-progress-surface-red",
    };
  }

  const minWidth = 20; // minimum visible width as a percentage
  const scaledProgress = ((totalDays - remainingDays) / totalDays) * (100 - minWidth) + minWidth;
  const progressPercent = Math.min(scaledProgress, 100);
  // Determine color based on remaining days
  let progressColor = "green";
  let progressThemeColor = "bg-progress-surface-green";
  if (remainingDays >= 340) {
    progressColor = "green";
    progressThemeColor = "bg-progress-surface-green";
  } else if (remainingDays >= 120) {
    progressColor = "yellow";
    progressThemeColor = "bg-progress-surface-yellow";
  } else {
    progressColor = "red";
    progressThemeColor = "bg-progress-surface-red";
  }

  return { remainingDays, progressPercent, progressColor, progressThemeColor };
};

export const dateRangeFormat = (dateRange) => {
  if (dateRange.startDate && dateRange.endDate) {
    const startDate = new Date(dateRange.startDate);
    const endDate = new Date(dateRange.endDate);

    const format = (date) => {
      const day = `0${date.getDate()}`.slice(-2);
      const month = `0${date.getMonth() + 1}`.slice(-2);
      const year = date.getFullYear();
      return `${day}-${month}-${year}`;
    };

    return [format(startDate), format(endDate)];
  }
  return [];
};

export const OrderStausList = [
  {
    label: "View All",
    value: "",
  },
  {
    label: "Active",
    value: "active",
  },
  {
    label: "Pending",
    value: "pending",
  },
  {
    label: "Draft",
    value: "draft",
  },
  {
    label: "Completed",
    value: "completed",
  },
  {
    label: "Archived",
    value: "archived",
  },
  {
    label: "Cancelled",
    value: "cancelled",
  },
];

export const accountTypeList = [
  {
    label: "All",
    value: "all",
  },
  {
    label: "Retailer",
    value: "retailer",
  },
  {
    label: "Agent",
    value: "agent",
  },
  {
    label: "Supplier",
    value: "supplier",
  },
];

export const truncateString = (input, maxChar = 30) => {
  const str = input.toString(); // Convert input to string
  if (window.innerWidth < 768) {
    return str.length > 20 ? str.substring(0, 20) + "..." : str;
  } else {
    return str.length > maxChar ? str.substring(0, maxChar) + "..." : str;
  }
};

// export const removeQueryParam = (param, location, navigate) => {
//   const params = new URLSearchParams(location.search);

//   if (params.has(param)) {
//     params.delete(param);

//     const newSearch = params.toString();
//     const newUrl = `${location.pathname}${newSearch ? `?${newSearch}` : ''}`;

//     navigate(newUrl, { replace: true });
//   }
// };

export const removeQueryParam = (paramsToRemove, location, navigate) => {
  const params = new URLSearchParams(location.search);

  if (!Array.isArray(paramsToRemove)) {
    paramsToRemove = [paramsToRemove];
  }

  let paramsChanged = false;

  paramsToRemove.forEach((param) => {
    if (params.has(param)) {
      params.delete(param);
      paramsChanged = true;
    }
  });

  if (paramsChanged) {
    const newSearch = params.toString();
    const newUrl = `${location.pathname}${newSearch ? `?${newSearch}` : ""}`;

    navigate(newUrl, { replace: true });
  }
};

export const featureOptions = [
  { value: "feature1", label: "Feature 1" },
  { value: "feature2", label: "Feature 2" },
];

export const solutionOptions = [
  { value: "solution1", label: "Solution 1" },
  { value: "solution2", label: "Solution 2" },
];

export const formateDateToMonthYear = (dateString) => {
  const dateObject = new Date(dateString);
  const formattedDate = dateObject.toLocaleDateString("en-US", {
    year: "numeric",
    month: "short",
    day: "numeric",
  });
  return formattedDate;
};

export const formateDateToLongMonthYear = (dateString) => {
  const dateObject = new Date(dateString);
  const formattedDate = dateObject.toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
  return formattedDate;
};

// export const checkDowngradeOrUpgrade = (activePlan, selectedPlan) => {
//   if (!activePlan || !selectedPlan) return null;

//   const activePlanPrice = parseFloat(activePlan.plan.price);
//   const selectedPlanPrice = parseFloat(selectedPlan.price);
//   if (activePlanPrice === selectedPlanPrice) {
//     return null;
//   } else if (selectedPlanPrice > activePlanPrice) {
//     return "UPGRADE";
//   } else {
//     return "DOWNGRADE";
//   }
// };
export const checkDowngradeOrUpgrade = (activePlan, selectedPlan) => {
  if (!activePlan || !selectedPlan) return null;

  const activePlanPrice = parseFloat(activePlan.plan.price);
  const selectedPlanPrice = parseFloat(selectedPlan.price);

  if (activePlanPrice === selectedPlanPrice) return null;

  const isIntervalChanged = activePlan.plan.interval !== selectedPlan.interval;
  const isDowngrade = isIntervalChanged
    ? checkIntervalDowngrade(activePlan, selectedPlan)
    : activePlanPrice > selectedPlanPrice;

  return isDowngrade ? "DOWNGRADE" : "UPGRADE";
};

const checkIntervalDowngrade = (activePlan, selectedPlan) => {
  if (activePlan.plan.interval === "year" && selectedPlan.interval === "month") {
    return true; // always return true when changing from yearly to monthly
  }

  return checkLimitDowngrade(activePlan, selectedPlan);
};

const checkLimitDowngrade = (activePlan, selectedPlan) => {
  const { orders_count, articles_count, partner_links_count, user_count } = activePlan;

  const { order_lines, articles, users, partner_links } = selectedPlan;

  return (
    parseInt(orders_count) > parseInt(order_lines) ||
    parseInt(articles_count) > parseInt(articles) ||
    parseInt(partner_links_count) > parseInt(partner_links) ||
    parseInt(user_count) > parseInt(users)
  );
};

export const calculateFutureDate = (selectedPlanType) => {
  const currentDate = new Date();
  let newDate;

  if (selectedPlanType === "month") {
    newDate = new Date(currentDate.setMonth(currentDate.getMonth() + 1));
  } else if (selectedPlanType === "year") {
    newDate = new Date(currentDate.setFullYear(currentDate.getFullYear() + 1));
  }

  // Formatting the date as "Jan 28, 2025"
  const formattedDate = newDate.toLocaleDateString("en-US", {
    year: "numeric",
    month: "short", // "Jan", "Feb", etc.
    day: "numeric",
  });

  return formattedDate;
};

export const formatDateToDayMonthYear = (dateString) => {
  const [datePart, timePart, period] = dateString.split(" ");

  // Split the date part into day, month, and year
  const [day, month, year] = datePart.split("-");

  // Format to dd/mm/yyyy
  return `${day}/${month}/${year}`;
};

export const handleRestrictNumbersOnKeyPress = (e) => {
  if (/\d/.test(e.key)) {
    e.preventDefault();
  }
};

export const handleRestrictNumbersOnPaste = (event) => {
  const pastedData = event.clipboardData.getData("Text");
  // If the pasted data is a number, prevent the paste action
  if (/\d/.test(pastedData)) {
    event.preventDefault();
  }
};

export const handleAcceptNumbersOnKeyPress = (event) => {
  if (!/[0-9]/.test(event.key)) {
    event.preventDefault();
  }
};

export const handleAcceptNumbersOnPaste = (event) => {
  const pastedData = event.clipboardData.getData("Text");
  // If the pasted data is not a number, prevent the paste action
  if (!/^\d+$/.test(pastedData)) {
    event.preventDefault();
  }
};

export const getInitials = (name) => {
  if (!name) return "";
  const nameParts = name.trim().split(" ");
  if (nameParts.length === 1) {
    // If there's only one name part, take the first two letters
    return nameParts[0].substring(0, 2).toUpperCase();
  } else {
    // If there are multiple name parts, take the first letter of the first two words
    return nameParts[0][0].toUpperCase() + nameParts[1][0].toUpperCase();
  }
};

export const formatAddress = ({ region, zip, countryCode }) => {
  return [region, zip, countryCode].filter((value) => value != null && value !== "").join(", ");
};

export const formatLocation = ({ region, country }) => {
  return [region, country].filter((value) => value != null && value !== "").join(", ");
};

export const orders = [
  {
    companyname: "Shark",
    status: "accepted",
    type: "supplier",
    firstname: "John",
    lastname: "Doe",
    activeOrders: 3,

    invited: "2/5/2024",
    activated: "10/9/2024",
    role: "retailer",
    email: "Dq0uA@example.com",
    workspaces: ["workspace"],
  },
  {
    companyname: "Sharkroo",
    status: "invite_pending",
    type: "agent",
    firstname: "Jonna",
    lastname: "Dora",
    activeOrders: 5,

    invited: "2/5/2024",
    activated: "10/9/2024",
    role: "retailer",
    email: "Dq0uA@example.com",
    workspaces: ["workspace", "workspace"],
  },
  {
    companyname: "Wsit",
    status: "accepted",
    type: "supplier",
    firstname: "John",
    lastname: "Doe",
    activeOrders: 3,

    invited: "2/5/2024",
    activated: "10/9/2024",
    role: "supplier",
    email: "Dq0uA@example.com",
    workspaces: ["workspace", "workspace", "workspace", "workspace", "workspace"],
  },
  {
    companyname: "Shark",
    status: "accepted",
    type: "supplier",
    firstname: "John",
    lastname: "Doe",
    activeOrders: 0,

    invited: "2/5/2024",
    activated: "10/9/2024",
    role: "retailer",
    email: "Dq0uA@example.com",
    workspaces: ["workspace", "workspace"],
  },
  {
    companyname: "Shark Tank",
    status: "accepted",
    type: "supplier",
    firstname: "John",
    lastname: "Doe",
    activeOrders: 3,

    invited: "2/5/2024",
    activated: "10/9/2024",
    role: "retailer",
    email: "Dq0uA@example.com",
    workspaces: ["workspace", "workspaceewrwetetyryyur", "workspace", "workspace", "workspace"],
  },
];

export const calculatePercentage = (part, whole) => {
  // Check for invalid inputs (non-numeric or zero whole)
  if (isNaN(part) || isNaN(whole) || whole === 0) {
    return "Invalid input: Please provide valid numbers and ensure the whole is not zero.";
  }

  // Calculate percentage
  const percentage = (part / whole) * 100;

  // Round to two decimal places (optional, adjust as needed)
  return percentage.toFixed(2);
};

export const filterNonEmptyKeys = (data) => {
  // Create a new object to hold the filtered data
  const filteredData = {};
  // Iterate through the keys in the input data
  Object.keys(data).forEach((key) => {
    const value = data[key];

    // Check if the value is non-empty
    if (
      (Array.isArray(value) && value.length > 0) || // Non-empty array
      (typeof value === "object" && value !== null && Object.keys(value).length > 0) // Non-empty object
    ) {
      filteredData[key] = value; // Add to filtered data if it meets the criteria
    }
  });
  const datas = prepareQueryData(filteredData);
  return datas;
};

export const prepareQueryData = (data) => {
  const preparedData = {};

  Object.keys(data).forEach((key) => {
    const value = data[key];

    if (key === "status" && Array.isArray(value)) {
      // Special case for `status`: Extract only the values as an array
      preparedData[key] = value.map((item) => item.value);
    } else if (Array.isArray(value)) {
      // Handle other arrays of objects: Extract their `value` properties
      preparedData[key] = value.map((item) => (item.value !== undefined ? item.value : item));
    } else if (typeof value === "object" && value !== null) {
      // If it's an object with `startDate` and `endDate`, set an array of dates
      if (value.startDate && value.endDate) {
        preparedData[key] = [value.startDate, value.endDate];
      }
    } else {
      // Keep any other values as they are
      preparedData[key] = value;
    }
  });

  return preparedData;
};

export const formateLable = (name, type) => {
  return (
    <div className="flex items-center justify-start gap-2">
      <span className="text-base font-normal text-global-title truncate">{name}</span>
      <Button
        variant="status"
        className="rounded-[6px] w-auto h-[22px] tracking-tight px-1.5 py-[2px] border whitespace-nowrap border-border-primary custom-card cursor-default capitalize text-global-title-2 text-xs leading-[18px] font-medium"
      >
        {type}
      </Button>
    </div>
  );
};

/**
 * This function is used to check if two objects are different
 * @param {*} obj1
 * @param {*} obj2
 * @returns true if objects are different, false if they are the same
 */

export const objectDeepCheck = (obj1, obj2) => {
  // Handle null/undefined cases
  if (obj1 === null || obj2 === null) {
    return obj1 !== obj2;
  }

  // Check if both inputs are objects
  if (typeof obj1 !== "object" || typeof obj2 !== "object") {
    return obj1 !== obj2;
  }

  // Handle arrays specially
  if (Array.isArray(obj1) && Array.isArray(obj2)) {
    if (obj1.length !== obj2.length) {
      return true;
    }

    // For arrays of objects with value/label pairs, sort by value before comparing
    if (obj1[0]?.value !== undefined) {
      const sorted1 = [...obj1].sort((a, b) => a.value - b.value);
      const sorted2 = [...obj2].sort((a, b) => a.value - b.value);

      return sorted1.some(
        (item, index) => item.value !== sorted2[index].value || item.label !== sorted2[index].label,
      );
    }

    // For other arrays, compare each element
    return obj1.some((item, index) => objectDeepCheck(item, obj2[index]));
  }

  // Get keys of both objects
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  // If number of keys is different, objects are different
  if (keys1.length !== keys2.length) {
    return true;
  }

  // Compare each key-value pair
  return keys1.some((key) => {
    if (!keys2.includes(key)) {
      return true;
    }
    return objectDeepCheck(obj1[key], obj2[key]);
  });
};

function getGreeting() {
  const currentTime = new Date().getHours(); // Get the current hour (0-23)

  if (currentTime >= 0 && currentTime < 12) {
    return "Good morning,";
  } else if (currentTime >= 12 && currentTime < 18) {
    return "Good afternoon,";
  } else {
    return "Good evening,";
  }
}

export const greeting = getGreeting();
