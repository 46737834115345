import React from "react";

const InfoNewIcon = React.forwardRef(
  (
    {
      width = "12",
      height = "12",
      viewBox = "0 0 12 12",
      fill = "none",
      strokeColor = "535862",
      className,
      ...props
    },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="info" clipPath="url(#clip0_13534_13993)">
          <path
            id="Icon"
            d="M6 8V6M6 4H6.005M11 6C11 8.76142 8.76142 11 6 11C3.23858 11 1 8.76142 1 6C1 3.23858 3.23858 1 6 1C8.76142 1 11 3.23858 11 6Z"
            stroke={strokeColor}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_13534_13993">
            <rect width="12" height="12" fill="white" />
          </clipPath>
        </defs>
      </svg>
    );
  },
);

export default InfoNewIcon;
