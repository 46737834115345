import { ArrowLeft, ArrowNext, ArrowPrev, ArrowRightFill } from "assests";

const TertiaryPagination = (props) => {
  const { page, setPage, totalPages } = props;
  const pages = [];
  for (let i = 0; i < totalPages; i++) {
    pages.push(i);
  }

  const handlePageChange = (page) => {
    setPage(page);
  };

  const renderPageNumbers = () => {
    const displayedPages = [];
    const maxDisplayedPages = 5;

    if (totalPages <= maxDisplayedPages) {
      return pages.map((item, index) => (
        <button
          key={index}
          onClick={() => handlePageChange(item + 1)}
          className={`relative inline-flex items-center min-w-10 justify-center text-center rounded-lg px-2.5 py-2.5 text-sm font-semibold  hover:bg-global-white focus:z-20 focus:outline-offset-0  ${
            page === item + 1
              ? "bg-global-white text-txt-secondary-hover "
              : "bg-transparent text-btn-text-tertiary "
          }`}
        >
          {item + 1}
        </button>
      ));
    }

    const midPoint = Math.floor(maxDisplayedPages / 2);

    if (page <= midPoint) {
      displayedPages.push(...pages.slice(0, maxDisplayedPages - 1), "ellipsis", totalPages - 1);
    } else if (page >= totalPages - midPoint) {
      displayedPages.push(0, "ellipsis", ...pages.slice(totalPages - maxDisplayedPages + 1));
    } else {
      displayedPages.push(
        0,
        "ellipsis",
        ...pages.slice(page - midPoint, page + midPoint),
        "ellipsis",
        totalPages - 1,
      );
    }

    return displayedPages.map((item, index) => {
      if (item === "ellipsis") {
        return (
          <span
            key={index}
            className={`relative inline-flex items-center justify-center text-center min-w-10 first:rounded-lg last:rounded-lg px-2.5 py-2.5 text-sm font-semibold  hover:bg-global-white  focus:z-20 focus:outline-offset-0  ${
              page === item + 1
                ? "bg-global-white text-txt-secondary-hover "
                : "bg-transparent text-btn-text-tertiary "
            }`}
          >
            ...
          </span>
        );
      }

      return (
        <button
          key={index}
          onClick={() => handlePageChange(item + 1)}
          className={`relative inline-flex items-center justify-center text-center min-w-10 first:rounded-lg last:rounded-lg px-2.5 py-2.5 text-sm font-semibold  hover:bg-global-white  focus:z-20 focus:outline-offset-0  ${
            page === item + 1
              ? "bg-global-white text-txt-secondary-hover "
              : "bg-transparent text-btn-text-tertiary "
          }`}
        >
          {item + 1}
        </button>
      );
    });
  };
  const handlePrev = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };
  const handleNext = () => {
    if (page < totalPages) {
      setPage(page + 1);
    }
  };

  return (
    <div className="bg-global-theme  rounded-b-xl  w-full">
      <div
        className={`flex items-center  w-full px-4 pt-3 pb-4 ${
          props.borderTop ? "border-t border-global-divider-soft" : ""
        }`}
      >
        <div className="flex items-center justify-between w-full gap-2.5 text-global-title">
          <button
            type="button"
            onClick={handlePrev}
            className={`relative ring-1 ring-inset ring-border-primary inline-flex items-center justify-center text-center w-9 md:w-auto h-9  gap-1 first:rounded-lg last:rounded-lg p-2 md:px-3 md:py-2 text-sm font-semibold leading-5  custom-outline-shadow  hover:bg-global-white text-global-title focus:z-20 focus:outline-offset-0 ${page > 1 ? "cursor-pointer" : "cursor-not-allowed"}`}
          >
            <ArrowPrev
              className="w-5 h-5"
              strokeColor="var(--color-gray-800)"
              fillColor="var(--color-gray-800)"
            />
            <div className="text-sm font-semibold text-global-title-2 hidden  md:block leading-5">
              Previous
            </div>
          </button>
          <nav
            className={`inline-flex -space-x-px gap-[2px] rounded-lg  isolate  `}
            aria-label="Pagination"
          >
            <div className="block md:hidden text-sm  text-global-title-2">
              {" "}
              Page <span className="font-semibold">{page}</span> of{" "}
              <span className="font-semibold">{totalPages}</span>
            </div>
            <div className="hidden md:block">{renderPageNumbers()}</div>
          </nav>
          <button
            type="button"
            onClick={handleNext}
            className={`relative ring-1 ring-inset ring-border-primary inline-flex items-center justify-center text-center w-9 md:w-auto h-9  gap-1 first:rounded-lg last:rounded-lg p-2 md:px-3 md:py-2 text-sm font-semibold leading-5 custom-outline-shadow  hover:bg-global-white text-global-title focus:z-20 focus:outline-offset-0 ${page < totalPages ? "cursor-pointer" : "cursor-not-allowed"}`}
          >
            <div className="text-sm font-semibold text-global-title-2 hidden  md:block leading-5">
              Next
            </div>
            <ArrowNext
              className="w-5 h-5"
              strokeColor="var(--color-gray-800)"
              fillColor="var(--color-gray-800)"
            />
          </button>
        </div>
      </div>
    </div>
  );
};

export default TertiaryPagination;
