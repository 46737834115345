import React from "react";

const success_icon = React.forwardRef(
  (
    {
      width = "40",
      height = "40",
      viewBox = "0 0 40 40",
      fill = "none",
      strokeColor = "#11C182",
      fillColor = "#11C182",
      className,
    },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 20C0 8.95431 8.95431 0 20 0C31.0457 0 40 8.95431 40 20C40 31.0457 31.0457 40 20 40C8.95431 40 0 31.0457 0 20Z"
          fill={fillColor}
          fill-opacity="0.1"
        />
        <g clipPath="url(#clip0_473_5750)">
          <path
            d="M16.2503 19.9998L18.7503 22.4998L23.7503 17.4998M28.3337 19.9998C28.3337 24.6022 24.6027 28.3332 20.0003 28.3332C15.398 28.3332 11.667 24.6022 11.667 19.9998C11.667 15.3975 15.398 11.6665 20.0003 11.6665C24.6027 11.6665 28.3337 15.3975 28.3337 19.9998Z"
            stroke={strokeColor}
            strokeWidth="1.66667"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_473_5750">
            <rect width="20" height="20" fill="white" transform="translate(10 10)" />
          </clipPath>
        </defs>
      </svg>
    );
  },
);

export default success_icon;
