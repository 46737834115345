import React from "react";

const Arrow_Down = React.forwardRef(
  (
    {
      width = "21",
      height = "20",
      viewBox = "0 0 21 20",
      fill = "none",
      fillColor = "#444656",
      className,
      ...props
    },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.9727 7.89131C16.2168 8.13539 16.2168 8.53112 15.9727 8.77519L10.9727 13.7752C10.7286 14.0193 10.3329 14.0193 10.0888 13.7752L5.08882 8.77519C4.84474 8.53112 4.84474 8.13539 5.08882 7.89131C5.3329 7.64723 5.72863 7.64723 5.9727 7.89131L10.5308 12.4494L15.0888 7.89131C15.3329 7.64723 15.7286 7.64723 15.9727 7.89131Z"
          fill="#101828"
          stroke="#101828"
          strokeWidth="0.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  },
);
Arrow_Down.displayName = "Arrow_Down";

export default Arrow_Down;
