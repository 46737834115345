import { CreditCard, Cross } from "assests";
import { Button } from "components/UI/Button";
import Divider from "components/UI/Divider";
import React, { useState } from "react";
import { useForm } from "react-hook-form";

import {
  CardExpiryElement,
  CardNumberElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import StripePaymentCardDetails from "components/Card/StripePaymentCardDetails";
import { useMutation } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { City } from "country-state-city";
import { zodResolver } from "@hookform/resolvers/zod";
import { basePaymentSchema } from "validation/payment.validation";
import { useDispatch, useSelector } from "react-redux";
import { updatePaymentMethod } from "services/subscription.services";
import UpgradeComponent from "components/UI/UpgradeComponent";
import useCustomToaster from "hooks/useCustomToaster";
import { setRefetchBillingData } from "store/billingSlice";

const UpdatePaymetMethod = ({ closeModal }) => {
  //hooks
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();
  const { showCustomToast } = useCustomToaster();
  //Global State
  const { user } = useSelector((state) => state.authenticationSlice);
  const { billingDetails } = useSelector((state) => state.billingSlice);
  const [errorMessage, setErrorMessage] = useState(null);
  const {
    control,
    register,
    setValue,
    handleSubmit,
    reset,
    formState: { errors, isLoading },
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      cardHolderName: "",
    },
    resolver: zodResolver(basePaymentSchema),
  });

  const confirmMutation = useMutation({
    mutationKey: ["updatePaymentMethod"],
    mutationFn: (data) => updatePaymentMethod(data),
    onMutate: () => {
      setErrorMessage(null);
      showCustomToast(
        {
          title: "Udating payment information",
        },
        "updating-Billing-Details",
      );
    },
    onSuccess: (res) => {
      setErrorMessage(null);
      toast.dismiss("updating-Billing-Details");
      if (res.data && res.status === 200 && res.data.status) {
        dispatch(setRefetchBillingData(true));
        handleCancel();
        showCustomToast({
          title: res.data.message || "Payment method has been updated successfully",
        });
      }
    },
    onError: (error) => {
      toast.dismiss("updating-Billing-Details");
      setErrorMessage(error.response.data.message || error.message);
      showCustomToast({
        title: error.response.data.message || error.message,
      });
    },
  });

  const onFormSubmit = async (data) => {
    try {
      if (!stripe || !elements) return;

      const processedData = Object.entries(data).reduce((acc, [key, value]) => {
        if (typeof value === "object" && value !== null) {
          acc[key] = value.value;
        } else {
          acc[key] = value;
        }
        return acc;
      }, {});

      const cardElement = elements.getElement(CardNumberElement);

      const stripPaymentMethod = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
        billing_details: {
          name: processedData.cardHolderName,
          email: billingDetails ? billingDetails?.email : user.email,
          address: {
            city: processedData.city,
            country: processedData.country,
          },
        },
      });
      if (stripPaymentMethod.error) {
        setErrorMessage(stripPaymentMethod.error.message);
        showCustomToast({
          title: stripPaymentMethod.error.message,
        });
        return;
      }
      confirmMutation.mutate({ payment_method: stripPaymentMethod.paymentMethod });
    } catch (error) {
      setErrorMessage(null);
      showCustomToast({
        title: error.message,
      });
    }
  };
  const onFormError = (error) => {
    console.log(error);
    setErrorMessage(null);
  };

  const handleCancel = () => {
    reset();
    elements.getElement(CardNumberElement).clear();
    closeModal();
    setErrorMessage(null);
  };
  return (
    <>
      <div className="w-full lg:max-w-[480px] relative  z-[2] bg-white p-6 rounded-xl border border-border-divider-stroke">
        <div className="flex justify-between mb-4">
          <div>
            <UpgradeComponent>
              <CreditCard />
            </UpgradeComponent>
          </div>
          <div>
            <Cross
              fillColor="var(--color-icon-fill-input)"
              width={24}
              height={24}
              onClick={handleCancel}
              className="cursor-pointer"
            />
          </div>
        </div>
        <div className="grid grid-cols-1 gap-4">
          <div>
            <h3 className="text-base lg:text-lg font-semibold text-global-title leading-7">
              Update payment method
            </h3>
            <p className="text-xs lg:text-sm text-global-paragraph leading-5">
              Enter your credit card details.
            </p>
          </div>
          <form onSubmit={handleSubmit(onFormSubmit, onFormError)}>
            <StripePaymentCardDetails
              {...{
                control,
                register,
                errors,
                errorMessage,
                setErrorMessage,
              }}
            />
            <div className="flex flex-col lg:flex-row gap-3 pt-4 lg:pt-8">
              <Button
                variant={"secondary"}
                className={
                  "px-6 w-full lg:w-[210px] h-11 text-[10px] lg:text-base whitespace-nowrap font-semibold"
                }
                onClick={handleCancel}
                isDisabled={isLoading || confirmMutation.isLoading}
              >
                Cancel
              </Button>

              <Button
                type="submit"
                variant={"primary"}
                className={
                  "px-6 w-full lg:w-[210px] h-11 text-[10px] lg:text-base whitespace-nowrap font-semibold"
                }
                isDisabled={isLoading || confirmMutation.isLoading}
              >
                Update
              </Button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default UpdatePaymetMethod;
