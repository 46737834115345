import BorderDesign from "components/UI/BorderDesign";
import { Button } from "components/UI/Button";
import Divider from "components/UI/Divider";
import { LineProgressBar } from "components/UI/LineProgressBar";
import UpgradeComponent from "components/UI/UpgradeComponent";
import { useState } from "react";

const { Upload, DownloadDoc, PDFIcon, Trash, UploadIcon, Cross, Uploadnew } = require("assests");
const { default: DynamicSVG } = require("components/UI/DynamicSVG");
const { Label } = require("components/UI/Label");

const UploadViewOrder = ({
  isDragOver,
  handleDragEnter,
  handleDragLeave,
  handleDrop,
  onFileDropOrChange,
  handleDownload,
  closeModal,
  handleCancel,
  uploadedData,
  preview,
  setPreview,
  onSubmitBulkUpload,
  hasAnyError,
}) => {
  const [fileData, setFileData] = useState(null); // State to store file details

  // Function to handle file selection or drag and drop
  const handleFileDropOrChange = (e) => {
    let file = null;
    if (e.dataTransfer) {
      file = e.dataTransfer.files[0];
    } else {
      file = e.target.files[0];
    }
    if (file && file.type === "text/csv") {
      const fileLength = file.length;
      const fileSize = (file.size / (1024 * 1024)).toFixed(4) + " MB";
      console.log(fileLength);
      const fileSizeInMB = fileSize.match(/\d+(\.\d+)?/)[0]; // Extract only the numeric part
      const maxSize = 100;

      // Calculate percentage
      let percentage = (fileSizeInMB / maxSize) * 100;

      // Cap percentage at 100%
      percentage = percentage > 100 ? 100 : percentage;
      setFileData({
        name: file.name,
        size: fileSizeInMB,
        percentage,
      });
      onFileDropOrChange(e);
    } else {
      console.error("Please upload a valid CSV file.");
    }
  };

  const removeFile = () => {
    setFileData(null);
  };

  return (
    <>
      <div className="grid grid-cols-1 w-full lg:w-[600px]">
        <div className="absolute right-5 top-5 cursor-pointer z-10 ">
          <Cross fillColor={"#A4A7AE"} height={24} width={24} onClick={closeModal} />
        </div>
        <div className="flex justify-start pl-6 z-[2]">
          <UpgradeComponent>
            <Uploadnew />
          </UpgradeComponent>

          <div className="flex justify-between lg:gap-4 px-3 lg:px-4 ">
            <div className="flex flex-col gap-1 pr-[6px] lg:pr-0">
              <h2 className="font-semibold text-sm lg:text-lg leading-7 text-start text-primary-900 w-full">
                Order Upload
              </h2>

              <div className="flex justify-start items-center ">
                <div className="text-sm text-switch-active-gray font-normal leading-5 text-wrap">
                  Upload a CSV file containing the orders you would like to add.
                  <span
                    className="text-login-text-title text-sm font-medium leading-5 cursor-pointer p-0 bg-transparent hover:bg-transparent"
                    onClick={handleDownload}
                  >
                    {" "}
                    Download the template here.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="grid gap-3 px-6 z-[5] group">
          <div
            className={`flex flex-col gap-3 items-center justify-center w-full h-[126px] border border-border-divider-stroke group-hover:border-2  group-hover:border-global-primary px-12 py-4 bg-white rounded-xl mt-5 
              `}
            onDragOver={handleDragEnter}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
          >
            <label
              htmlFor="dropzone-file"
              className="flex flex-col items-center justify-center w-full h-full text-status-text-gray gap-2 lg:gap-3   cursor-pointer"
            >
              {" "}
              <BorderDesign className={" rounded-lg h-10 w-10 p-2.5 "}>
                <UploadIcon />
              </BorderDesign>
              <div className="grid gap-1">
                {" "}
                <p className="text-xs lg:text-sm leading-3 lg:leading-5 font-semibold text-global-title text-start lg:text-center ">
                  Click to upload or{" "}
                  <span className="text-switch-active-gray font-normal">drag and drop</span>
                </p>
                <p className="text-center text-switch-active-gray font-normal text-xs lg:text-sm leading-5 ">
                  XLS or XLSX
                </p>
              </div>
              <input
                id="dropzone-file"
                type="file"
                className="hidden"
                accept=".csv"
                onChange={handleFileDropOrChange}
              />
            </label>
          </div>
        </div>
        {fileData && (
          <div className="p-4 flex   gap-3 bg-white rounded-xl border border-gray-300 mt-2 lg:mt-4 mx-6">
            <div className="flex justify-start items-start">
              <PDFIcon className="w-10 h-10" fillColor={"#11C182"} />
            </div>

            <div className="flex flex-col w-full ">
              <div className="flex justify-between w-full ">
                <div className="flex flex-col">
                  <p className="text-xs lg:text-sm leading-5 font-medium text-global-gray-brand">
                    {fileData?.name}
                  </p>
                  <p className="text-xs lg:text-sm leading-5 font-medium text-global-paragraph">
                    {fileData.size} MB
                  </p>
                </div>
                <Trash
                  strokeColor={"var(--color-gray-800)"}
                  className="h-5 w-5 cursor-pointer"
                  onClick={removeFile}
                />
              </div>
              <div className="flex justify-between items-center gap-3 w-full">
                <div className="w-full py-2.5">
                  <LineProgressBar
                    progress={fileData?.size ? Math.min(100, (fileData.size / 100) * 100) : 0}
                  />
                </div>
                <p className="text-xs lg:text-sm font-medium leading-5 text-[#344054]">100%</p>
              </div>
            </div>
          </div>
        )}
        <div className="mt-8">
          <Divider />
        </div>
        <div className="flex justify-between gap-4 pt-6 px-6">
          <div className="w-full flex justify-center items-center gap-3 ">
            <Button
              type="button"
              size={"md"}
              variant={"secondary"}
              className={`w-full h-11 px-4 py-2.5 text-base leading-6 font-semibold `}
              onClick={handleCancel}
            >
              Cancel
            </Button>
            {!preview && (
              <Button
                type="button"
                size={"md"}
                className={`w-full h-11 px-4 py-2.5 text-base leading-6 font-semibold `}
                isDisabled={!uploadedData}
                onClick={() => setPreview(true)}
              >
                Continue
              </Button>
            )}
            {preview && (
              <Button
                type="button"
                size={"md"}
                variant={"primary"}
                className={`w-full h-11 px-4 py-2.5 text-base leading-6 font-semibold `}
                isDisabled={hasAnyError()}
                onClick={onSubmitBulkUpload}
              >
                Continue
              </Button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default UploadViewOrder;
