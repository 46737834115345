import { CheckCircleOutlined, LockOutLinedIcon, MessageChatCircleIcon, PlanIcon } from "assests";
import BackToDashboard from "components/UI/BackToDashboard";
import { Button } from "components/UI/Button";
import CommonDiscardButton from "components/UI/CommonDiscardButton";
import Divider from "components/UI/Divider";
import Modal from "components/UI/Modal";
import ReduceComponent from "components/UI/ReduceComponent";
import ToasterIcon from "components/UI/ToasterIcon";
import WeCare from "components/UI/WeCare";
import useSubscriptionPlan from "hooks/useSubscriptionPlan";
import SubNavbar from "layout/SubNavbar";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { setSelectedPlanData, setSelectedPlanType } from "store/subscriptionSlice";
import { checkDowngradeOrUpgrade } from "utils/helper";
import UpgradePlanModal from "../BillingList/UpgradePlanModal";
import TopNavbar from "components/TopHeader/TopNavbar";
import BreadCrumb from "components/TopHeader/BreadCrumb";
import LoadingComponent from "components/UI/LoadingComponent";
const PlansLists = ({ actionType = "upgrade", handleUpgrade = null }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useSubscriptionPlan();
  const { subscriptionList, selectedPlanData, selectedPlanType } = useSelector(
    (state) => state.subscriptionSlice,
  );
  const { currentWorkspace } = useSelector((state) => state.authenticationSlice);
  const { currentPlan } = useSelector((state) => state.billingSlice);
  const [percentageSavings, setPercentageSavings] = useState(0);
  const [upgradeOrDowngrade, setUpgradeOrDowngrade] = useState(null);
  const [showUpgradeOrDowngradeModal, setShowUpgradeOrDowngradeModal] = useState(false);
  const [showReduceModal, setShowReduceModal] = useState(false);
  const [filteredIntervalPackageList, setFilteredIntervalPackageList] = useState([]);
  const { isSidebarCollapsed } = useSelector((state) => state.navbarSlice);

  const handleClick = (plan) => {
    dispatch(setSelectedPlanData(plan));
    const percentageSavings = calculatePercentageSavings(plan);
    setPercentageSavings(percentageSavings);
  };

  const calculatePercentageSavings = (plan) => {
    const totalMonthlyPrice = plan.monthly_purchase_price * 12;
    const totalYearlyPrice = plan.yearly_purchase_price;
    const savings = totalMonthlyPrice - totalYearlyPrice;
    const percentageSavings = Math.floor((savings / totalMonthlyPrice) * 100);
    return percentageSavings;
  };

  const onClickChangePlan = () => {
    if (!selectedPlanData) {
      toast.error("Please select a plan to change to");
      return;
    }
    navigate("/plans/upgrade");
  };

  const onClickUpradeOrDowngrade = (e, plan = null, action) => {
    e.stopPropagation();
    e.preventDefault();
    if (plan) {
      dispatch(setSelectedPlanData(plan));
    }
    if (currentPlan && action === "DOWNGRADE") {
      const { orders_count, articles_count, partner_links_count, user_count } = currentPlan;
      const { order_lines, articles, users, partner_links } = plan;
      let showWarningModal = false;
      if (orders_count > order_lines) {
        showWarningModal = true;
      } else if (articles_count > articles) {
        showWarningModal = true;
      } else if (partner_links_count > partner_links) {
        showWarningModal = true;
      } else if (user_count > users) {
        showWarningModal = true;
      }
      if (showWarningModal) {
        setShowReduceModal(showWarningModal);
      } else {
        setShowUpgradeOrDowngradeModal(true);
      }
    } else if (action === "UPGRADE") {
      navigate("/plans/upgrade");
    } else return;
  };

  useEffect(() => {
    const selectedPlan = selectedPlanType || "monthly";
    if (
      selectedPlan &&
      subscriptionList &&
      Array.isArray(subscriptionList) &&
      subscriptionList.length > 0
    ) {
      const filteredIntervalPackageList = subscriptionList.map((item) => {
        const filteredPackage = item.plans.find((plan) => plan.interval === selectedPlan);

        return {
          ...filteredPackage,
          order_lines: item.order_lines,
          articles: item.articles,
          partner_links: item.partner_links,
          users: item.users,
          monthly_purchase_price: item.monthly_purchase_price,
          yearly_purchase_price: item.yearly_purchase_price,
        };
      });

      setFilteredIntervalPackageList(filteredIntervalPackageList);
    }
  }, [selectedPlanType, subscriptionList]);

  useEffect(() => {
    if (
      currentPlan &&
      subscriptionList &&
      Array.isArray(subscriptionList) &&
      subscriptionList.length > 0
    ) {
      const findPlan = subscriptionList.find((item) => item.id === currentPlan.plan.package.id);

      dispatch(setSelectedPlanData(currentPlan.plan));
      const percentageSavings = calculatePercentageSavings(findPlan);
      setPercentageSavings(percentageSavings);
      checkDowngradeOrUpgrade(currentPlan, findPlan);
    }
  }, [currentPlan, subscriptionList]);

  useEffect(() => {
    if (currentPlan && selectedPlanData && selectedPlanType) {
      setUpgradeOrDowngrade(checkDowngradeOrUpgrade(currentPlan, selectedPlanData));
    }
  }, [currentPlan, selectedPlanData, selectedPlanType]);

  return (
    <>
      <div>
        <div className="pt-0 pb-16">
          {showReduceModal && (
            <Modal open={showReduceModal} close={() => setShowReduceModal(false)}>
              <ReduceComponent closeModal={() => setShowReduceModal(false)} />
            </Modal>
          )}
          {showUpgradeOrDowngradeModal && (
            <Modal
              open={showUpgradeOrDowngradeModal}
              close={() => setShowUpgradeOrDowngradeModal(false)}
            >
              <UpgradePlanModal handleCancel={() => setShowUpgradeOrDowngradeModal(false)} />
            </Modal>
          )}

          {
            filteredIntervalPackageList && filteredIntervalPackageList.length > 0 ? (
              <div
                className="w-full md:w-auto h-auto bg-white rounded-xl border border-global-divider-soft flex-col justify-start items-center inline-flex"
                style={{
                  boxShadow: `0px 1px 2px 0px rgba(16, 24, 40, 0.05)`,
                }}
              >
                <div>
                  <div
                    className={`w-full flex-col pl-4 pt-4 pb-4 md:pl-6  md:pt-6 md:pb-5 md:flex-row gap-4  md:justify-between md:items-center flex  border-b border-b-global-divider-soft ${percentageSavings > 0 ? "pr-[54px]" : "pr-6"}`}
                  >
                    <div className="w-full  justify-start items-start gap-4 inline-flex">
                      <ToasterIcon className="w-12 h-12">
                        <PlanIcon fillColor="var(--color-icon-default-2)" width={24} height={24} />
                      </ToasterIcon>
                      <div className=" flex-col justify-start items-start gap-1 inline-flex">
                        <div className=" text-global-title text-lg font-semibold  leading-7">
                          Select plan
                        </div>
                        <div className="  text-global-paragraph text-sm font-normal leading-tight">
                          Simple, transparent pricing
                        </div>
                      </div>
                    </div>
                    <div>
                      <div
                        className="h-10 relative inline-flex rounded-lg border border-border-primary"
                        role="group"
                        style={{
                          boxShadow:
                            "rgba(16, 24, 40, 0.05) 0px 1px 2px 0px, rgba(16, 24, 40, 0.05) 0px -2px 0px 0px inset, rgba(16, 24, 40, 0.15) 0px 0px 0px 1px inset",
                        }}
                      >
                        <button
                          onClick={() => dispatch(setSelectedPlanType("month"))}
                          type="button"
                          className={`w-[88px] py-2.5 text-sm font-semibold  border-r border-border-primary rounded-s-lg  ${selectedPlanType === "month" ? "text-txt-secondary-hover bg-bg-primary-hover" : "text-txt-secondary  bg-bg-primary"}`}
                        >
                          Monthly
                        </button>

                        <button
                          type="button"
                          onClick={() => dispatch(setSelectedPlanType("year"))}
                          className={`w-[92px] py-2.5 text-sm font-semibold rounded-e-lg ${selectedPlanType === "year" ? "text-txt-secondary-hover bg-bg-primary-hover" : "text-txt-secondary  bg-bg-primary"}`}
                        >
                          Yearly
                        </button>
                        {percentageSavings > 0 && (
                          <div className="h-7 w-[53px] pl-2 pr-2.5 py-1 bg-white rounded-lg shadow border absolute top-[-18px] right-[-26.5px] border-[#d0d5dd] justify-start items-center gap-1 inline-flex">
                            <div className="text-center text-[#344054] text-sm font-medium font-['Inter'] leading-tight">
                              -{percentageSavings}%
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="w-full border-b border-b-global-divider-soft">
                    <div className="p-4 md:px-6 md:pt-5 md:pb-8 flex-col justify-center items-center md:justify-start md:items-start gap-4 md:gap-5 flex">
                      <div className="justify-center items-center md:justify-start md:items-start gap-5 flex flex-col md:flex-wrap md:flex-row w-full">
                        {filteredIntervalPackageList &&
                          filteredIntervalPackageList.length > 0 &&
                          [...filteredIntervalPackageList]
                            .sort((a, b) => parseFloat(a.price) - parseFloat(b.price))
                            .map((item, index) => (
                              <div
                                onClick={() => handleClick(item)}
                                key={index}
                                className="group cursor-pointer self-stretch "
                              >
                                <div
                                  className={`min-w-[286px] w-full  rounded-xl  h-full bg-transparent relative flex-col justify-start items-start inline-flex shadow-[0px_1px_2px_0px_rgba(16, 24, 40, 0.05)] group-hover:shadow-[0px_1px_2px_0px_rgba(16, 24, 40, 0.05)] ${(selectedPlanData && selectedPlanData.id) === item.id
                                    ? "ring-2 ring-inset ring-global-primary"
                                    : "ring-1 ring-inset ring-global-divider-medium group-hover:ring-2 group-hover:ring-global-primary"
                                    }`}
                                >
                                  <div className={`w-5 h-5 absolute top-4 right-4`}>
                                    <div
                                      className={`w-full h-full rounded-full flex justify-center items-center  ${(selectedPlanData && selectedPlanData.id) === item.id
                                        ? "bg-global-primary"
                                        : "bg-global-gray-medium"
                                        }`}
                                    >
                                      <div
                                        className={`bg-white rounded-full ${(selectedPlanData && selectedPlanData.id) === item.id
                                          ? "w-2 h-2"
                                          : "w-4 h-4"
                                          }`}
                                      />
                                    </div>
                                  </div>
                                  <div className=" px-5 pt-5 flex-col justify-start items-start gap-2 flex">
                                    <div className="text-global-title text-2xl font-semibold">
                                      {selectedPlanType === "month"
                                        ? `€${parseInt(item.price)}/mth`
                                        : `€${parseInt(item.price)}/yr`}
                                    </div>
                                    <div className=" flex-col justify-start items-start gap-0.5 flex">
                                      <div className="text-global-title text-base font-semibold">
                                        <span className="uppercase">{item?.name}</span> plan
                                      </div>
                                      <div className="text-global-paragraph text-sm font-normal">
                                        {currentPlan ? (
                                          currentPlan.plan.id === item.id ? (
                                            "Current Plan"
                                          ) : checkDowngradeOrUpgrade(currentPlan, item) ===
                                            "UPGRADE" ? (
                                            "Upgrade"
                                          ) : (
                                            <Button
                                              variant="secondary"
                                              className="h-9 px-3 mt-[2px] font-semibold text-sm w-[103px]"
                                              onClick={(e) =>
                                                onClickUpradeOrDowngrade(e, item, "DOWNGRADE")
                                              }
                                            >
                                              Downgrade
                                            </Button>
                                          )
                                        ) : (
                                          "Select Plan"
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  <div className=" p-5 ">
                                    <div className="justify-start items-start gap-3 flex flex-col">
                                      <div className="justify-start items-start gap-3 inline-flex">
                                        <CheckCircleOutlined
                                          strokeColor="var(--color-global-primary)"
                                          className="w-5 h-5 md:w-6 md:h-6"
                                        />
                                        <div className="text-global-paragraph text-sm md:text-base font-normal">
                                          {item?.order_lines} Orderlines
                                        </div>
                                      </div>
                                      <div className="justify-start items-start gap-3 inline-flex">
                                        <CheckCircleOutlined
                                          strokeColor="var(--color-global-primary)"
                                          className="w-5 h-5 md:w-6 md:h-6"
                                        />
                                        <div className="text-global-paragraph text-sm md:text-base font-normal">
                                          {item?.articles} Articles
                                        </div>
                                      </div>
                                      <div className="justify-start items-start gap-3 inline-flex">
                                        <CheckCircleOutlined
                                          strokeColor="var(--color-global-primary)"
                                          className="w-5 h-5 md:w-6 md:h-6"
                                        />
                                        <div className="text-global-paragraph text-sm md:text-base font-normal">
                                          {item?.partner_links} Partner links
                                        </div>
                                      </div>
                                      <div className="justify-start items-start gap-3 inline-flex">
                                        <CheckCircleOutlined
                                          strokeColor="var(--color-global-primary)"
                                          className="w-5 h-5 md:w-6 md:h-6"
                                        />
                                        <div className="text-global-paragraph text-sm md:text-base font-normal">
                                          {item?.users} Members
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                      </div>

                      <div className="justify-center items-center gap-2 inline-flex ">
                        <div>
                          <span className="text-global-paragraph text-base font-normal  leading-normal">
                            Visit our{" "}
                          </span>
                          <Link to={`${process.env.REACT_APP_WEB_URL}/pricing`} target="_blank">
                            <span className="text-global-primary text-base font-semibold  leading-normal">
                              pricing
                            </span>
                          </Link>
                          <span className="text-global-paragraph text-base font-normal  leading-normal">
                            {" "}
                            page for a more detailed overview.
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className=" p-4 md:p-6  md:pb-6 justify-between items-center gap-3 flex flex-col md:flex-row">
                    <div
                      className="px-4 py-2.5 md:w-[333px] bg-white rounded-lg  justify-center items-center gap-1.5 flex"
                      style={{
                        boxShadow:
                          "rgba(16, 24, 40, 0.05) 0px 1px 2px 0px, rgba(16, 24, 40, 0.05) 0px -2px 0px 0px inset, rgba(16, 24, 40, 0.15) 0px 0px 0px 1px inset",
                      }}
                    >
                      <MessageChatCircleIcon width={20} height={20} />
                      <div className="px-0.5 justify-center items-center flex">
                        <div>
                          <span className="text-global-gray-brand text-sm md:text-base font-normal  leading-normal">
                            Need a custom quote?{" "}
                          </span>
                          <a href="mailto:sales@fibervue.com">
                            <span className="text-global-primary text-sm md:text-base font-semibold  leading-normal">
                              Talk to sales
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>

                    {selectedPlanData && !currentPlan && (
                      <Button
                        variant="primary"
                        className="h-11 w-full md:w-[118px] text-sm md:text-base"
                        onClick={onClickChangePlan}
                      >
                        Select plan
                      </Button>
                    )}
                    {upgradeOrDowngrade && upgradeOrDowngrade === "UPGRADE" && (
                      <Button
                        variant="primary"
                        className="h-11 px-4 w-full md:w-[198px] whitespace-nowrap text-sm md:text-base"
                        onClick={(e) => onClickUpradeOrDowngrade(e, null, "UPGRADE")}
                      >
                        <div>
                          Upgrade to <span className="uppercase">{selectedPlanData.name}</span>
                        </div>
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            ) : <LoadingComponent />
          }
          <div className="my-4 md:my-[35px]">
            <Divider className="border-global-divider-medium" />
          </div>
          <WeCare />
        </div>
        {currentWorkspace && (currentWorkspace.role_name.includes("editor") || currentWorkspace.role_name.includes("viewer")) && (
          <div
            className={`fixed inset-0 z-[51] px-4 md:px-0 transition-opacity bg-[#FFFFFFCC] backdrop-blur-sm flex justify-center items-center bg-opacity-50 ${isSidebarCollapsed ? "left-0 md:left-[68px]" : "md:left-[296px]"}`}
          >
            <div className="md:m-4">
              <CommonDiscardButton
                title="Permission required"
                subText1="This section is restricted to admins only. Please request access from your administrator if you need to view this page."
                className="max-w-[544px] border border-border-secondary !shadow-none"
                IconComponent={LockOutLinedIcon}
                confirmButtonText="Request Permission"
                confirmButtonVariant="primary"
                backToDashboard={() => {
                  navigate("/");
                }}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default PlansLists;
