import { ChevronRight } from "@mui/icons-material";
import Card from "components/UI/Card";
import { LineProgressBar } from "components/UI/LineProgressBar";
import { BsArrowRight } from "react-icons/bs";
import { FaGreaterThan } from "react-icons/fa6";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { AccountDashboardPackageLists } from "utils/constant";
import { percentageColors, transpercencyPercentageColors } from "utils/helper";
const Packages = ({ tiersData, reportDetails }) => {
  const { currentWorkspace } = useSelector((state) => state.authenticationSlice);

  return (
    <Card size="md" borderRadius="md" className=" bg-transparent p-0 lg:bg-global-white ">
      <div className="flex overflow-x-auto lg:grid lg:grid-cols-5 gap-3  divide-x divide-global-divider  items-center  pb-6 ">
        {reportDetails && reportDetails?.tiers &&
          AccountDashboardPackageLists.map((packageData, index) => {
            const tierData = reportDetails.tiers[packageData.id];
            return (
              <div key={index} className="lg:col-span-1">
                <div
                  className={`bg-white group rounded-xl w-[196px] lg:w-full py-6 lg:pt-5 pb-4 transition duration-300 ease-in-out border border-global-divider-soft`}
                >
                  <div className="">
                    <div className="flex-col items-center justify-center flex gap-2 lg:gap-3 px-6 mb-6 lg:px-[26.4px]">
                      <div
                        className={`w-[64px] lg:w-[60px] h-7 px-2.5 py-1 border rounded-lg border-[#D5D7DA] text-global-gray-brand font-bold whitespace-nowrap text-center text-[10px] lg:text-sm leading-5 custom-card bg-white`}
                      >
                        {packageData?.tier && packageData?.tier}
                      </div>
                      <div className="max-w-[105px] lg:max-w-[122.59px] h-10 text-xs lg:text-sm leading-5 font-medium text-center whitespace-normal break-words text-gray-800">
                        {packageData?.title && packageData?.title}
                      </div>
                    </div>

                    <div className="px-6 lg:px-[26.4px] flex-col items-center mt-[25px] mb-5 gap-6 justify-center flex border-b border-b-[#E4E7EC]">
                      <div className="px-[10.5px]">
                        {/* Assuming item.icon is a valid React component */}
                        {packageData.icon && (
                          <packageData.icon className="w-[72px] h-[72px] lg:w-[123px] lg:h-[126px]" />
                        )}
                      </div>
                      <div className="w-full">
                        <div className="text-center pb-6">
                          <div className="flex items-center justify-center  whitespace-nowrap">
                            <span className="text-gray-800 text-sm font-bold leading-5">
                              {tierData?.linked ? tierData.linked : 0}
                            </span>
                            <p className="text-gray-800 text-sm font-medium leading-5 mx-1">
                              out of {tierData?.total ? tierData.total : 0} items
                            </p>
                          </div>
                          <p className="text-gray-800 text-sm font-medium leading-5">linked</p>
                        </div>

                        <div className="flex justify-center items-center gap-3 w-full mb-4">
                          <LineProgressBar
                            variant={transpercencyPercentageColors(`${tierData?.score ? tierData.score : 0}`)}
                            progress={tierData?.score ? tierData.score : 0}
                            className="h-2 my-1.5"
                          />
                          <div className="text-gray-800 text-sm leading-5 text-center font-medium ">
                            {`${tierData?.score ? tierData.score : 0}%`}
                          </div>
                        </div>
                      </div>
                    </div>
                    <Link
                      className="flex justify-end gap-3 items-center w-full px-[26.4px]"
                      to={`/workspace/${currentWorkspace?.workspace_id}/orders`}
                    >
                      <p className="whitespace-nowrap text-sm font-semibold leading-5 text-global-title">
                        View Orders
                      </p>
                      <ChevronRight style={{ fill: "#535862", width: "20px", height: "20px" }} />
                    </Link>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </Card>
  );
};

export default Packages;
