import { ActiveEye, EyeIcon, EyeoffIcon, View } from "assests";
import { Button } from "components/UI/Button";
import ErrorMessage from "components/UI/ErrorMessage";
import { Input } from "components/UI/Input";
import { Label } from "components/UI/Label";
import useLogin from "hooks/useLogin";
import React, { useRef } from "react";
import { Link } from "react-router-dom";

const SignInForm = () => {
  const {
    register,
    isLoading,
    loginPasswordInputType,
    handleSubmit,
    togglePasswordType,
    errors,
    onError,
    watch,
  } = useLogin();
  const checkboxRef = useRef(null);
  const watchedPassword = watch("password")
  const handleDivClick = () => {
    if (checkboxRef.current) {
      checkboxRef.current.checked = !checkboxRef.current.checked;
    }
  };
  return (
    <form onSubmit={handleSubmit} onError={onError} method="post">
      <div className="grid gap-5">
        <div className="grid gap-1.5">
          <Label htmlFor="email">Email</Label>
          <Input
            id="email"
            type="email"
            placeholder="Enter your email"
            className="ps-3.5"
            {...register("email")}
          />
          <ErrorMessage errors={errors?.email?.message} />
        </div>
        <div className="grid gap-3">
          <div className="grid gap-1.5">
            <Label htmlFor="loginPassword">Password</Label>
            <Input
              name="password"
              id="loginPassword"
              type={loginPasswordInputType}
              placeholder="••••••••"
              className="ps-3.5"
              {...register("password")}
              iconRight={
                loginPasswordInputType === "password" ? (
                  <EyeoffIcon
                    className="cursor-pointer"
                    strokeColor={watchedPassword === "" ? "var(--color-login-icon-outlined-disabled)" : "var(--color-login-icon-outlined-default)"}
                    // strokeColor={`var(--color-login-icon-outlined-default)`}
                    width={20}
                    height={20}
                    onClick={togglePasswordType}
                  />
                ) : (
                  <EyeIcon
                    strokeColor={watchedPassword === "" ? "var(--color-login-icon-outlined-disabled)" : "var(--color-login-icon-outlined-default)"}
                    className="cursor-pointer"
                    width={20}
                    height={20}
                    onClick={togglePasswordType}
                  />
                )
              }
            />
            <ErrorMessage errors={errors?.password?.message} />
          </div>
          <div className="flex items-center justify-between py-1">
            <div className="flex items-center cursor-pointer">
              <label htmlFor="terms" className="flex items-center">
                <input
                  type="checkbox"
                  id="terms"
                  name="terms"
                  className="mr-2 border-2"
                  ref={checkboxRef}
                  {...register("remember_me")}
                />
                <div
                  role="button"
                  className="text-sm font-medium  text-global-gray-brand cursor-pointer"
                  onClick={handleDivClick}
                >
                  Remember for 30 days
                </div>
              </label>
            </div>
            <Link to="/forget-password" className="text-sm font-semibold text-btn-text-link hover:text-global-primary">
              Forgot password
            </Link>
          </div>
        </div>
        <div className="flex justify-center">
          <Button
            type="submit"
            size="lg"
            className="w-full text-base font-semibold hover:bg-login-text-title rounded-lg"
            isDisabled={isLoading}
          >
            Sign in
          </Button>
        </div>
      </div>
    </form>
  );
};

export default SignInForm;
