import { Cross, TransferIcon } from "assests";
import { Button } from "components/UI/Button";
import { useEffect, useState } from "react";

import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import EmptyComponent from "components/Card/EmptyComponent";
import ChipButton from "components/UI/ChipButton";
import UpgradeComponent from "components/UI/UpgradeComponent";
import useCustomToaster from "hooks/useCustomToaster";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import { getUsers, transferOwnership } from "services/user.services";
import { clearAuthData } from "store/authenticationSlice";
import { setUserList, toggleUserResendModal } from "store/userSlice";
import { transferSchema } from "validation/user.validation";
const TransferOwnershipForm = ({ closeModal }) => {
  const navigate = useNavigate();
  const { userList } = useSelector((state) => state.userSlice);
  const { currentWorkspace } = useSelector((state) => state.authenticationSlice);
  const [selectedUser, setSelectedUser] = useState(null);
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { showCustomToast } = useCustomToaster();
  const handleLogOut = () => {
    dispatch(clearAuthData());
    dispatch({ type: "LOGOUT" });
    return <Navigate to="/signin" />;
  };
  const {
    data: userListQueryData,
    isLoading,
    status,
    refetch,
  } = useQuery({
    queryKey: ["userList", "getUserList"],
    queryFn: getUsers,
  });
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      workspace_id: "",
      user_id: "",
    },
    resolver: zodResolver(transferSchema),
  });
  const mutation = useMutation({
    mutationKey: ["transferownership", "transferownership"],
    mutationFn: transferOwnership,
    onSuccess: handleSuccess,
    onError: handleError,
  });
  const handleSubmitFn = (data) => {
    mutation.mutate(data);
  };

  function handleSuccess(response) {
    const data = response.data;
    dispatch(toggleUserResendModal());
    reset();
    queryClient.invalidateQueries("protectedResource");
    showCustomToast({
      title: data.message,
    });
    refetch();
    closeModal();
    handleLogOut();
  }
  function handleError(error) {
    const errorMessage = error?.response?.data?.message || error.message;
    showCustomToast({
      title: errorMessage,
    });
  }
  const handleItemCheck = (data) => {
    if (data) {
      setSelectedUser(data);
      setValue("user_id", parseInt(data?.user_id));
      setValue("workspace_id", parseInt(currentWorkspace?.workspace_id));
    }
  };

  useEffect(() => {
    if (
      userListQueryData &&
      status === `success` &&
      userListQueryData.status === 200 &&
      !isLoading
    ) {
      dispatch(setUserList({ append: false, userList: userListQueryData?.data?.users?.data }));

      // dispatch(setAllUserData({ append: false, allUserData: userListQueryData.data.users }));
    }
  }, [userListQueryData, isLoading, status]);

  // const handleFormSubmit = () => {
  //   if (selectedUser) {
  //     handleSubmit(handleSubmitFn)();
  //   }
  // };

  return (
    <>
      <div className="w-full md:w-[480px] relative  z-[2]  p-6 bg-white border border-global-divider-soft rounded-xl ">
        <div className="flex justify-between mb-4">
          <div>
            <UpgradeComponent>
              <TransferIcon fillColor="var(--color-icon-default-2)" />
            </UpgradeComponent>
          </div>
          <div>
            <Cross
              fillColor="var(--color-icon-fill-input)"
              width={24}
              height={24}
              onClick={closeModal}
              className="cursor-pointer"
            />
          </div>
        </div>
        <form onSubmit={handleSubmit(handleSubmitFn)} className="w-full ">
          <div className="grid grid-cols-1 gap-8 w-full">
            <div>
              <h3 className="text-base lg:text-lg font-semibold text-global-title leading-7">
                Transfer Ownership
              </h3>
              <p className="text-xs lg:text-sm text-global-paragraph leading-5">
                Select your desired user to transfer the account ownership
              </p>
            </div>
            <div className="grid gap-8 w-full">
              {userList?.filter(
                (user) =>
                  user?.status === "accepted" &&
                  !user.is_owner &&
                  currentWorkspace.role_name.includes("admin"),
              )?.length > 0 ? (
                <>
                  <div className="rounded-lg flex flex-col gap-6">
                    {userList
                      ?.filter(
                        (user) =>
                          user?.status === "accepted" &&
                          !user.is_owner &&
                          currentWorkspace.role_name.includes("admin"),
                      )
                      ?.map((user, index) => (
                        <div
                          key={index}
                          onClick={() => handleItemCheck(user)}
                          className="group cursor-pointer"
                        >
                          <div
                            className={`w-full rounded-xl bg-transparent relative justify-between items-start flex gap-3`}
                          >
                            <div className="flex justify-start items-start gap-3">
                              <div className="w-5 h-5 cursor-pointer mt-1">
                                <div
                                  className={`w-full h-full rounded-full flex justify-center items-center  ${
                                    selectedUser && selectedUser.id === user.id
                                      ? "bg-global-primary"
                                      : "bg-global-gray-medium"
                                  }`}
                                >
                                  <div
                                    className={`bg-white rounded-full ${
                                      selectedUser && selectedUser.id === user.id
                                        ? "w-2 h-2"
                                        : "w-4 h-4"
                                    }`}
                                  />
                                </div>
                              </div>
                              <div>
                                <h3 className="text-global-title font-medium text-base">
                                  {user.person_name}
                                </h3>
                                <p className="text-global-paragraph text-sm font-normal pt-1">
                                  {user.email}
                                </p>
                              </div>
                            </div>
                            <ChipButton>{user?.role?.split(".")[1]}</ChipButton>
                          </div>
                        </div>
                      ))}
                  </div>

                  <div className="flex justify-center items-center flex-col md:flex-row gap-3">
                    <Button
                      variant={"secondary"}
                      onClick={() => {
                        setSelectedUser(null);
                        reset();
                        closeModal();
                      }}
                      className={"w-full h-11 text-sm lg:text-base  py-2.5 px-4"}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant={"primary"}
                      className={"w-full h-11 text-sm lg:text-base py-2.5 px-4 whitespace-nowrap"}
                      type={"submit"}
                      isDisabled={!selectedUser}
                    >
                      Transfer Ownership
                    </Button>
                  </div>
                </>
              ) : (
                <EmptyComponent
                  text="You don't have any members to transfer ownership."
                  buttonText="Add members"
                  onClick={() => {
                    closeModal();
                    navigate(
                      `/workspace/${currentWorkspace?.user_workspace_id}/setting?selectedTab=members`,
                      { replace: true },
                    );
                  }}
                  icon={true}
                  isFromSettings={true}
                />
              )}
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default TransferOwnershipForm;
