import { ArrowLeft, ArrowRight, ForgetPassWordIcon, Message, SetPasswordIcon } from "assests";
import BackButton from "components/UI/BackButton";
import { Button } from "components/UI/Button";
import Divider from "components/UI/Divider";
import DynamicSVG from "components/UI/DynamicSVG";
import ErrorMessage from "components/UI/ErrorMessage";
import FormHeader from "components/UI/FormHeader";
import { Input } from "components/UI/Input";
import { Label } from "components/UI/Label";
import SigninLogo from "components/UI/SigninLogo";
import useForgetPassword from "hooks/useForgetPassword";
import React from "react";
import { Link, useNavigate } from "react-router-dom";

const ForgetPassword = () => {
  const { isLoading, register, handleSubmit, errors } = useForgetPassword();
  return (
    <div className="flex items-center justify-center">
      <div className="relative w-full px-4 md:px-0 md:w-[360px]">
        <div className="flex flex-col gap-5 md:gap-8">
          <div className="flex flex-col gap-6">
            <SigninLogo>
              <SetPasswordIcon width={28} height={28} />
            </SigninLogo>
            <FormHeader
              title="Forgot password?"
              subtitle="No worries, we’ll send you instructions to reset your FiberVue™ account password."
            />
          </div>
          <form onSubmit={handleSubmit} method="post">
            <div className="grid gap-6">
              <div className="grid gap-1.5">
                <Label htmlFor="loginEmail">
                  Email
                </Label>
                <Input
                  id="loginEmail"
                  type="email"
                  placeholder="Enter your email"
                  {...register("email")}
                />
                <ErrorMessage errors={errors?.email?.message} />
              </div>

              <div className="flex justify-center">
                <Button
                  type="submit"
                  className="w-full h-[44px] text-base"
                  isDisabled={isLoading}
                >
                  Reset password
                </Button>
              </div>
            </div>
          </form>
          <BackButton text="Back to log in" url="/signin" />
         
        </div>
      </div>
    </div>
  );
};

export default ForgetPassword;
