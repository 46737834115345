import React from "react";

const WarningIcon = React.forwardRef(
  (
    {
      width = "38",
      height = "38",
      viewBox = "0 0 38 38",
      fill = "none",

      className,
      ...props
    },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <g opacity="0.3">
          <path
            d="M19 32C11.8203 32 6 26.1797 6 19C6 11.8203 11.8203 6 19 6C26.1797 6 32 11.8203 32 19C32 26.1797 26.1797 32 19 32Z"
            stroke="#D92D20"
            strokeWidth="2"
          />
        </g>
        <g opacity="0.1">
          <path
            d="M19 37C9.05888 37 1 28.9411 1 19C1 9.05888 9.05888 1 19 1C28.9411 1 37 9.05888 37 19C37 28.9411 28.9411 37 19 37Z"
            stroke="#D92D20"
            strokeWidth="2"
          />
        </g>
        <g clipPath="url(#clip0_11518_42644)">
          <path
            d="M19.0001 15.6665V18.9998M19.0001 22.3332H19.0084M27.3334 18.9998C27.3334 23.6022 23.6025 27.3332 19.0001 27.3332C14.3977 27.3332 10.6667 23.6022 10.6667 18.9998C10.6667 14.3975 14.3977 10.6665 19.0001 10.6665C23.6025 10.6665 27.3334 14.3975 27.3334 18.9998Z"
            stroke="#D92D20"
            strokeWidth="1.66667"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_11518_42644">
            <rect width="20" height="20" fill="white" transform="translate(9 9)" />
          </clipPath>
        </defs>
      </svg>
    );
  },
);

export default WarningIcon;
