import React from "react";

const Invitation = React.forwardRef(
  (
    {
      width = "26",
      height = "26",
      viewBox = "0 0 26 26",
      fillColor = "white",
      fill = "none",

      className,
    },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.3755 13H5.41713M5.32542 13.3158L2.79592 20.8717C2.5972 21.4653 2.49784 21.7621 2.56915 21.9449C2.63107 22.1036 2.76406 22.224 2.92817 22.2698C3.11714 22.3225 3.40256 22.1941 3.97341 21.9372L22.0775 13.7904C22.6347 13.5396 22.9133 13.4143 22.9994 13.2401C23.0742 13.0888 23.0742 12.9113 22.9994 12.7599C22.9133 12.5858 22.6347 12.4604 22.0775 12.2097L3.9671 4.06005C3.39797 3.80395 3.11341 3.67589 2.92463 3.72842C2.76068 3.77404 2.6277 3.89406 2.56556 4.05249C2.49401 4.23492 2.59231 4.53108 2.78891 5.12339L5.32612 12.7677C5.35989 12.8694 5.37677 12.9203 5.38344 12.9723C5.38935 13.0185 5.38929 13.0652 5.38326 13.1113C5.37646 13.1633 5.35945 13.2142 5.32542 13.3158Z"
          stroke={fillColor}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  },
);

Invitation.displayName = "Invitation";

export default Invitation;
