import React from "react";

const dashboard = React.forwardRef(
  (
    {
      width = "20",
      height = "20",
      viewBox = "0 0 20 20",
      fill = "none",
      fillColor = "white",
      className,
      ...props
    },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.99999 4.99999C10.4602 4.99999 10.8333 4.6269 10.8333 4.16666C10.8333 3.70642 10.4602 3.33333 9.99999 3.33333C9.53976 3.33333 9.16666 3.70642 9.16666 4.16666C9.16666 4.6269 9.53976 4.99999 9.99999 4.99999Z"
          stroke="white"
          strokeWidth="1.67"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.99999 10.8333C10.4602 10.8333 10.8333 10.4602 10.8333 9.99999C10.8333 9.53976 10.4602 9.16666 9.99999 9.16666C9.53976 9.16666 9.16666 9.53976 9.16666 9.99999C9.16666 10.4602 9.53976 10.8333 9.99999 10.8333Z"
          stroke="white"
          strokeWidth="1.67"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.99999 16.6667C10.4602 16.6667 10.8333 16.2936 10.8333 15.8333C10.8333 15.3731 10.4602 15 9.99999 15C9.53976 15 9.16666 15.3731 9.16666 15.8333C9.16666 16.2936 9.53976 16.6667 9.99999 16.6667Z"
          stroke="white"
          strokeWidth="1.67"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.8333 4.99999C16.2936 4.99999 16.6667 4.6269 16.6667 4.16666C16.6667 3.70642 16.2936 3.33333 15.8333 3.33333C15.3731 3.33333 15 3.70642 15 4.16666C15 4.6269 15.3731 4.99999 15.8333 4.99999Z"
          stroke="white"
          strokeWidth="1.67"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.8333 10.8333C16.2936 10.8333 16.6667 10.4602 16.6667 9.99999C16.6667 9.53976 16.2936 9.16666 15.8333 9.16666C15.3731 9.16666 15 9.53976 15 9.99999C15 10.4602 15.3731 10.8333 15.8333 10.8333Z"
          stroke="white"
          strokeWidth="1.67"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.8333 16.6667C16.2936 16.6667 16.6667 16.2936 16.6667 15.8333C16.6667 15.3731 16.2936 15 15.8333 15C15.3731 15 15 15.3731 15 15.8333C15 16.2936 15.3731 16.6667 15.8333 16.6667Z"
          stroke="white"
          strokeWidth="1.67"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.16666 4.99999C4.6269 4.99999 4.99999 4.6269 4.99999 4.16666C4.99999 3.70642 4.6269 3.33333 4.16666 3.33333C3.70642 3.33333 3.33333 3.70642 3.33333 4.16666C3.33333 4.6269 3.70642 4.99999 4.16666 4.99999Z"
          stroke="white"
          strokeWidth="1.67"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.16666 10.8333C4.6269 10.8333 4.99999 10.4602 4.99999 9.99999C4.99999 9.53976 4.6269 9.16666 4.16666 9.16666C3.70642 9.16666 3.33333 9.53976 3.33333 9.99999C3.33333 10.4602 3.70642 10.8333 4.16666 10.8333Z"
          stroke="white"
          strokeWidth="1.67"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.16666 16.6667C4.6269 16.6667 4.99999 16.2936 4.99999 15.8333C4.99999 15.3731 4.6269 15 4.16666 15C3.70642 15 3.33333 15.3731 3.33333 15.8333C3.33333 16.2936 3.70642 16.6667 4.16666 16.6667Z"
          stroke="white"
          strokeWidth="1.67"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  },
);

dashboard.displayName = "dashboard";

export default dashboard;
