import React from "react";

const CreditCard = React.forwardRef(
  (
    { width = "24", height = "25", viewBox = "0 0 24 25", fill = "none", className, ...props },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M22 10.5H2M22 11.5V8.7C22 7.5799 22 7.01984 21.782 6.59202C21.5903 6.2157 21.2843 5.90974 20.908 5.71799C20.4802 5.5 19.9201 5.5 18.8 5.5H5.2C4.0799 5.5 3.51984 5.5 3.09202 5.71799C2.7157 5.90973 2.40973 6.21569 2.21799 6.59202C2 7.01984 2 7.5799 2 8.7V16.3C2 17.4201 2 17.9802 2.21799 18.408C2.40973 18.7843 2.71569 19.0903 3.09202 19.282C3.51984 19.5 4.07989 19.5 5.2 19.5H11.5M18 21.5C18 21.5 21 20.0701 21 17.9252V15.4229L18.8124 14.6412C18.2868 14.4529 17.712 14.4529 17.1864 14.6412L15 15.4229V17.9252C15 20.0701 18 21.5 18 21.5Z"
          stroke="#344054"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  },
);
CreditCard.displayName = "CreditCard";

export default CreditCard;
