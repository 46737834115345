import {
  AddPeople,
  InvitePartnerIcon,
  Logout,
  SettingIcon,
  SettingsIcon,
  SignoutIcon,
  SingleUserIcon,
  SupportIcon,
  SupportIconnn,
} from "assests";
import { Button } from "components/UI/Button";
import Card from "components/UI/Card";
import Divider from "components/UI/Divider";
import Greeting from "components/UI/Greeting";
import GreetingText from "components/UI/GreetingText";
import { useEffect } from "react";
import { FaCircle, FaMoon } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import { clearAuthData } from "store/authenticationSlice";
import { toggleProfileDropdown, toggleTheme } from "store/themeSlice";
import { greeting } from "utils/helper";

const ProfileDropdown = ({ message }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userData = JSON.parse(localStorage.getItem("user"));
  const { isDarkMode, showProfileDropdown } = useSelector((state) => state.themeSlice);
  const { currentWorkspace, user } = useSelector((state) => state.authenticationSlice);
  const { greetingMessage } = GreetingText();
  const handleThemeToggle = () => {
    dispatch(toggleTheme());
  };

  const handleLogOut = () => {
    if (showProfileDropdown) {
      dispatch(toggleProfileDropdown());
    }
    dispatch({ type: "LOGOUT" });
    dispatch(clearAuthData());
    return <Navigate to="/signin" />;
  };
  useEffect(() => {
    if (user) {
      const handleKeyDown = (event) => {
        const isMac = event.metaKey; // `metaKey` for Cmd on Mac
        const isWindows = event.ctrlKey; // `ctrlKey` for Ctrl on Windows
        if ((isMac || isWindows) && (event.key === "p" || event.key === "P")) {
          event.preventDefault(); // Prevent default browser behavior
          navigate(`/workspace/${currentWorkspace?.user_workspace_id}/setting?selectedTab=profile`);
        }
        if ((isMac || isWindows) && (event.key === "z" || event.key === "Z")) {
          event.preventDefault(); // Prevent default browser behavior
          navigate(
            `/workspace/${currentWorkspace?.user_workspace_id}/setting?selectedTab=partner_invites`,
          );
        }
      };

      window.addEventListener("keydown", handleKeyDown);
      return () => {
        window.removeEventListener("keydown", handleKeyDown);
      };
    }
  }, [user, navigate]);


  return (
    <Card className={"bg-white border border-[#E9EAEB] rounded-lg custom-modal-dropdown py-3 px-0"}>
      <div className="grid grid-cols-2 gap-1">
        <div className="col-span-6">
          <div className="flex items-center gap-3 px-4">
            <div className="w-10 h-10 relative rounded-full">
              <div className="flex justify-center items-center p-0 ring-1 ring-global-divider-soft rounded-full border-none text-btn-text-default bg-transparent">
                {user && user?.image ? (
                  <div className="!w-10 h-10">
                    <img
                      src={`${user?.image}?t=${new Date().getTime()}`}
                      alt={`Profile`}
                      className="rounded-full !w-10 h-10"
                    />
                  </div>
                ) : (
                  <div className="flex bg-global-surface justify-center items-center uppercase text-base font-semibold text-text-quaternary w-10 h-10  p-0 ring-1 ring-global-divider-soft  rounded-full border-none">
                    {user &&
                      `${user?.first_name?.substring(0, 1)}${user?.last_name?.substring(0, 1)}`}
                  </div>
                )}
              </div>
              <div className="w-2.5 h-2.5 left-[30px] top-[30px] absolute bg-[#17b169] rounded-full border border-white" />
            </div>
            <div className="grid ">
              <p className="text-sm leading-5 font-semibold text-gray-900 truncate">{greeting}</p>
              <p className="text-sm font-normal leading-5 text-global-paragraph truncate">
                {user?.name}
              </p>
            </div>
          </div>

          <Divider className="mt-3 mb-2 bg-[#E9EAEB]" />
          <Link
            className="flex justify-between items-center gap-3 px-4"
            to={`/workspace/${currentWorkspace?.user_workspace_id}/setting?selectedTab=profile`}
          >
            <div className="flex items-center gap-3 py-2  ">
              <div className=" ">
                <SingleUserIcon
                  fillColor={"#717680"}
                  strokeColor={"#717680"}
                  width={20}
                  height={20}
                  alt="Partner icon"
                />
              </div>
              <div>
                <Button
                  type="button"
                  variant="tertiary"
                  className="p-0 text-sm font-semibold text-global-gray-brand hover:bg-transparent"
                >
                  View profile
                </Button>
              </div>
            </div>
            <Button
              className={
                "w-auto border border-[#E9EAEB] h-5 bg-white rounded-[4px] px-1 py-[1px] text-gray-700 text-xs leading-[18px] font-medium text-center  hover:bg-transparent"
              }
              style={{
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.05)",
              }}
            >
              ⌘P
            </Button>
          </Link>
          <Link
            to={`/workspace/${currentWorkspace?.user_workspace_id}/setting`}
            className="flex justify-between items-center gap-3 px-4"
          >
            <div className="flex items-center gap-3 py-2  ">
              <div className=" ">
                <SettingsIcon
                  fillColor={"#717680"}
                  strokeColor={"#717680"}
                  width={20}
                  height={20}
                  alt="Image 2"
                />
              </div>
              <div>
                <Button
                  type="button"
                  variant="tertiary"
                  className="p-0 text-sm font-semibold text-global-gray-brand hover:bg-transparent"
                >
                  Settings
                </Button>
              </div>
            </div>
            <Button
              className={
                "w-auto border border-[#E9EAEB] h-5 bg-white rounded-[4px] px-1 py-[1px] text-gray-700 text-xs leading-[18px] font-medium text-center hover:bg-transparent "
              }
              style={{
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.05)",
              }}
            >
              ⌘S
            </Button>
          </Link>
          <Divider className="my-1 bg-[#E9EAEB]" />
          <Link
            className="flex justify-between items-center gap-3 px-4"
            to={`/workspace/${currentWorkspace?.user_workspace_id}/setting?selectedTab=partner_invites`}
          >
            <div className="flex items-center gap-3 py-2  ">
              <div className=" ">
                <AddPeople
                  fillColor={"#717680"}
                  strokeColor={"#717680"}
                  width={20}
                  height={20}
                  alt="Image 2"
                />
              </div>
              <div>
                <Button
                  type="button"
                  variant="tertiary"
                  className="p-0 text-sm font-semibold text-global-gray-brand hover:bg-transparent"
                >
                  Invite Partner
                </Button>
              </div>
            </div>
            <Button
              className={
                "w-auto border border-[#E9EAEB] h-5 bg-white rounded-[4px] px-1 py-[1px] text-gray-700 text-xs leading-[18px] font-medium text-center  hover:bg-transparent"
              }
              style={{
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.05)",
              }}
            >
              ⌘Z
            </Button>
          </Link>
          <Divider className="my-1 bg-[#E9EAEB]" />
          <div
            className="flex justify-between items-center gap-3 px-4 cursor-pointer"
            onClick={() => (window.location.href = "mailto:support@fibervue.com")}
          >
            <div className="flex items-center gap-3 py-2">
              <div>
                <SupportIconnn
                  fillColor={"#717680"}
                  strokeColor={"#717680"}
                  width={20}
                  height={20}
                  alt="Image 2"
                />
              </div>
              <div>
                <Button
                  type="button"
                  variant="tertiary"
                  className="p-0 text-sm font-semibold text-global-gray-brand hover:bg-transparent"
                >
                  Support
                </Button>
              </div>
            </div>
          </div>

          {/* <div className="flex items-center justify-between gap-3 py-2 px-3 ">
            <div className="flex gap-3 items-center ">
              <div className="">
                <FaMoon className="w-4 h-4 transform scale-x-[-1] text-icon-fill-default" />
              </div>
              <div className="ml-1">
                <p className="text-sm font-normal text-global-gray-brand">Dark Mode</p>
              </div>
            </div>
            <div className="flex items-center justify-center  mr-2">
              <div className="flex items-center justify-center w-full gap-4  mt-auto">
                <button
                  className={`relative flex items-center h-4 p-3 rounded-full cursor-pointer w-12 bg-toggle-inactive-switch place-items-center focus:outline-none`}
                  onClick={handleThemeToggle}
                >
                  <div className="relative flex w-full h-auto place-items-center">
                    <FaCircle
                      className={`absolute text-global-gray p-1 text-2xl z-10 rounded-full transition-all duration-300`}
                      style={{
                        color: "white",
                        left: isDarkMode ? "100%" : "0",
                        transform: "translateX(-50%)",
                      }}
                    />
                  </div>
                </button>
              </div>
            </div>
          </div> */}
          <Divider className="my-1 bg-[#E9EAEB]" />
          <div className="flex justify-between items-center gap-3 px-4">
            <div className="flex items-center gap-3 py-2">
              <div className="">
                <SignoutIcon
                  fillColor={"#717680"}
                  strokeColor={"#717680"}
                  width={20}
                  height={20}
                  alt="Image 2"
                />
              </div>

              <Button
                type="button"
                variant="tertiary"
                className="p-0 text-sm font-semibol text-global-gray-brand hover:bg-transparent"
                onClick={handleLogOut}
              >
                Sign out
              </Button>
            </div>
            <Button
              className={
                "w-auto border border-[#E9EAEB] h-5 bg-white rounded-[4px] px-1 py-[1px] text-gray-700 text-xs leading-[18px] font-medium text-center hover:bg-transparent "
              }
              style={{
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.05)",
              }}
            >
              ⌘+Shift+Q
            </Button>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default ProfileDropdown;
