import { Action, Cross } from "assests";
import { Button } from "components/UI/Button";
import UpgradeComponent from "components/UI/UpgradeComponent";
import Divider from "./Divider";
import { LineProgressBar } from "./LineProgressBar";

const LimitReached = ({
  title,
  subText1,
  subText2,
  IconComponent,
  confirmButtonText,
  onConfirmClick,
  closeModal,
  confirmButtonClassName,
  className,
  confirmButtonVariant,
  boldText,
  inviteBtnTrue,
  inviterInfo,
  linked,
  totalLink,
  linkedtitle,
  icon,
}) => {
  const renderSubtitle = () => {
    if (!boldText) {
      return (
        <span className="text-xs lg:text-sm font-normal leading-5 text-gray-800 tracking-wide">
          {subText1}
        </span>
      );
    }
    const parts = subText1.split(boldText);
    return (
      <>
        {parts[0]}
        <span className="font-semibold">{boldText}</span>
        {parts[1]}
      </>
    );
  };
  return (
    <div
      className={` bg-white  max-h-[600px]  rounded-xl relative z-[2]  ${className}`}
    >
      {closeModal && (
        <div className="absolute right-[22px] top-[22px] cursor-pointer" onClick={closeModal}>
          <Cross fillColor={"var(--color-icon-default-5)"} height={24} width={24} />
        </div>
      )}
      <div className={`flex px-6 pt-6  ${inviteBtnTrue ? "flex-col " : "flex-row"}`}>
        {IconComponent && (
          <div className="mr-4">
            <UpgradeComponent>
              <Action fillColor="var(--color-icon-default-2)" width={24} height={24} />
            </UpgradeComponent>
          </div>
        )}
        <div className={`flex flex-col  gap-1 ${inviteBtnTrue && "pt-4"}`}>
          <h4 className="text-base lg:text-lg font-semibold text-text-primary leading-7 ">
            {title}
          </h4>
          <p className="text-xs lg:text-sm  text-global-paragraph ">
            {renderSubtitle()}
            {subText2 && (
              <>
                <br />
                {subText2}
              </>
            )}
          </p>
        </div>
      </div>
      <div className="flex flex-col gap-2 mt-11 px-6">
        <LineProgressBar className={"w-full bg-transparent h-2"} variant={"transparent"} />
        <p className="text-end text-xs lg:text-sm font-medium leading-5 text-gray-900">
          {linked}/{totalLink} {linkedtitle}
        </p>
      </div>
      <Divider className="mt-6 md:mt-8" />
      <div className="flex justify-center p-6 lg:justify-end flex-col lg:flex-row gap-3 ">
        {closeModal && (
          <Button
            variant="secondary"
            className="px-4 py-2.5 w-full  h-11 text-sm md:text-base font-semibold"
            onClick={closeModal}
          >
            Cancel
          </Button>
        )}
        {
          confirmButtonText &&
          <Button
            variant={confirmButtonVariant}
            className={`px-4 py-2.5 w-full gap-1.5  h-11 custom-primary-shadow text-white text-sm md:text-base font-semibold whitespace-nowrap ${confirmButtonClassName}`}
            onClick={onConfirmClick}
          >
            {confirmButtonText} {icon && icon}
          </Button>
        }
      </div>
    </div>
  );
};

export default LimitReached;
