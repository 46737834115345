import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { updateOrder } from "services/order.services";
import { getSubTiersList } from "services/subtiers.services";
import { toggleIsTiersUpdateModalOpen } from "store/orderSlice";
import { createTemplateSchema } from "validation/template.validation";
import useCustomToaster from "./useCustomToaster";

const useUpdateTiersOrders = ({ refetchOrderDetails, orderDetailsData }) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { showCustomToast } = useCustomToaster();
  const { user, currentWorkspace, subscription, subscription_status } = useSelector(
    (state) => state.authenticationSlice,
  );
  const { isTiersUpdateModalOpen } = useSelector((state) => state.orderSlice);
  const { workspacePlan } = useSelector((state) => state.billingSlice);
  const { workspaceId } = useParams();
  const [tiersItem, setTiersItem] = useState({
    tier_1: [],
    tier_2: [],
    tier_3: [],
    tier_4: [],
    tier_5: [],
  });
  const [tierList, setTierList] = useState(null);

  const { data: SubTiers, isLoading: subTiersLoading } = useQuery({
    queryKey: ["template", "get-sub-tiers"],
    queryFn: getSubTiersList,
  });

  const canUpdateTiers = useMemo(() => {
    if ((currentWorkspace && currentWorkspace.is_owner && (!subscription || (subscription && (!subscription_status || subscription_status === "canceled")))) || !workspacePlan) {
      return false;
    } else {
      const userId = orderDetailsData?.created_by_user?.id;
      if (
        (currentWorkspace && currentWorkspace.role_name.includes("supplier") && user.id !== userId) ||
        (currentWorkspace && currentWorkspace?.role_name?.includes("viewer"))
      ) {
        return false;
      } else {
        return true;
      }
    }
  }, [orderDetailsData, user]);

  const {
    control,
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      workspace_id: parseInt(workspaceId),
      subtiers: [],
      tier_1: [],
      tier_2: [],
      tier_3: [],
      tier_4: [],
      tier_5: [],
    },
    resolver: zodResolver(createTemplateSchema),
  });

  const updateOrderTemplateMutation = useMutation({
    mutationKey: ["order", "update-order-template"],
    mutationFn: (body) => updateOrder(body),
    onMutate: () => {
      showCustomToast({
        title: "Updating order template...",
      });
    },
    onSuccess: (res) => {
      toast.dismiss();
      queryClient.invalidateQueries({ queryKey: ["order", "update-order-template"] });
      if (res.status === 200 && res.data.status) {
        showCustomToast({
          title: "Order template updated successfully",
        });
        refetchOrderDetails();
        resetTheCreateForm();
        if (isTiersUpdateModalOpen) {
          dispatch(toggleIsTiersUpdateModalOpen());
        }
      }
    },
    onError: (error) => {
      toast.dismiss();
      showCustomToast({
        title: "Failed to update order template",
      });
    },
  });

  const handleFormSubmit = (data) => {
    const { tier_1, tier_2, tier_3, tier_4, tier_5, ...rest } = data;
    const subtiers = [tier_1, tier_2, tier_3, tier_4, tier_5]
      .filter(Boolean)
      .flatMap((tiers) => tiers.map((tier) => tier.value));
    const processedData = {
      actionableOrderId: orderDetailsData.id,
      orderNumber: orderDetailsData.order_number,
      quantity: orderDetailsData.quantity,
      workspace_id: orderDetailsData.workspace_id,
      season_id: orderDetailsData.season_id,
      template_id: orderDetailsData.template_id,
      article_id: orderDetailsData.article_id,
      status: orderDetailsData.status?.name,
      templates: {
        ...rest,
        subtiers,
      },
    };

    updateOrderTemplateMutation.mutate(processedData);
  };

  const onFormError = (error) => {
    console.log(error);
  };

  const handleTierOptionSelect = (tier, selectedOption) => {
    if (selectedOption && tier) {
      setTiersItem((prev) => ({
        ...prev,
        [tier]: selectedOption,
      }));
      setValue(tier, selectedOption);
    }
  };

  const resetTheCreateForm = () => {
    reset();
    setTiersItem({
      tier_1: [],
      tier_2: [],
      tier_3: [],
      tier_4: [],
      tier_5: [],
    });
  };

  const onValidationError = (error) => {
    console.log(error);
  };

  useEffect(() => {
    if (orderDetailsData) {
      const tierData = {};
      const templateData = orderDetailsData.order_template;
      if (templateData) {
        const itemTiers = templateData.tier;
        setValue("name", templateData.name);

        if (itemTiers && Array.isArray(itemTiers) && itemTiers.length > 0) {
          for (const tier of itemTiers) {
            const subTiers = tier.sub_tiers;
            const tierId = tier.parent_tier_id;
            if (subTiers && Array.isArray(subTiers) && subTiers.length > 0) {
              for (const subTier of subTiers) {
                if (!tierData[`tier_${tierId}`]) {
                  tierData[`tier_${tierId}`] = [];
                }
                if (subTier) {
                  tierData[`tier_${tierId}`].push({ label: subTier.name, value: subTier.id });
                }
              }
            }
          }
        }
        Object.entries(tierData).forEach(([key]) => {
          setValue(key, tierData[key]);
        });
        setTiersItem(tierData);
      }
    }
  }, [orderDetailsData]);

  useEffect(() => {
    if (SubTiers && SubTiers.data.status) {
      let tierData = {};
      for (const subTier of SubTiers.data.sub_tiers) {
        const tierId = subTier.tier.id;
        if (!tierData[`tier_${tierId}`]) {
          tierData[`tier_${tierId}`] = [];
        }
        if (subTier) tierData[`tier_${tierId}`].push({ label: subTier.name, value: subTier.id });
      }
      setTierList(tierData);
    }
  }, [SubTiers]);

  return {
    control,
    tierList,
    tiersItem,
    errors,
    register,
    reset,
    handleSubmit,
    onFormError,
    handleFormSubmit,
    handleTierOptionSelect,
    onValidationError,
    resetTheCreateForm,
    canUpdateTiers,
    subTiersLoading,
  };
};

export default useUpdateTiersOrders;
