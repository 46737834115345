import React from "react";

import PropTypes from "prop-types";

const SubNavbar = ({ title, subtitle, linkText, linkHref }) => {
  const renderSubtitle = () => {
    if (!linkText || !linkHref) {
      return <span>{subtitle}</span>;
    }

    const parts = subtitle.split(linkText);
    return (
      <>
        {parts[0]}
        <a href={linkHref} target="_blank" rel="noreferrer" className="text-global-primary font-semibold">
          {linkText}
        </a>
        {parts[1]}
      </>
    );
  };

  return (
    <div className="w-full ">
      <div className="text-lg md:text-2xl font-semibold text-global-title md:pb-1">{title}</div>
      <div className="w-full lg:max-w-[765px] text-sm md:text-base font-normal text-global-paragraph">
        {renderSubtitle()}
      </div>
    </div>
  );
};

SubNavbar.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  linkText: PropTypes.string,
  linkHref: PropTypes.string,
};

export default SubNavbar;
