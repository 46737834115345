import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Country } from "country-state-city";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";

import {
  getCompanyDetailsForUser,
  updateCompanyDetailsById,
} from "services/company-settings.services";

import {
  setAllCompanyData,
  setCompanyDetails,
  setCompanySliceState,
  toggleActionableCompanyId,
  toggleIsCompanyCreateModalOpen,
  toggleIsCompanyEditable,
  toggleShowDiscardModal,
} from "store/companySlice";
import { companyProfileSchema } from "validation/user.validation";
import useCustomToaster from "./useCustomToaster";

const useCompanyProfile = () => {
  const { showCustomToast } = useCustomToaster();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { companyDetails, isCompanyCreateModalOpen } = useSelector((state) => state.companySlice);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const { actionableCompanyId } = useSelector((state) => state.companySlice);
  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    getValues,
    setError,
    setFocus,
    clearErrors,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      street: "",
      zip: "",
      region: "",
      country: "",
      registration_number: "",
      country_code: "",
      address: "",
    },
    resolver: zodResolver(companyProfileSchema),
  });

  const {
    data: companyData,
    isLoading,
    refetch: refetchCompanyProfile,
  } = useQuery({
    queryKey: ["company", "getDetails"],
    queryFn: getCompanyDetailsForUser,
    enabled: true,
  });

  const updateUserMutation = useMutation({
    mutationKey: ["company", "updateUser"],
    mutationFn: (data) => updateCompanyDetailsById(data),
    onMutate: () => {
      showCustomToast(
        {
          title: "Updating...",
        },
        "updateUserLoadingToast",
      );
    },
    onSuccess: (res) => {
      toast.dismiss("updateUserLoadingToast");
      const data = res.data;
      if (data.User) {
        console.log(data.User);
      }
      if (isCompanyCreateModalOpen) {
        dispatch(toggleIsCompanyCreateModalOpen());
      }
      queryClient.invalidateQueries("protectedResource");
      showCustomToast({
        title: "Company details has been updated",
      });
      // setDefaultFormValue(res.data.User);
    },
    onError: (error) => {
      toast.dismiss("updateUserLoadingToast");
      showCustomToast({
        title: error.response?.data?.message || error.message || "Something went wrong",
      });
      if (error.response?.data?.errors) {
        Object.entries(error.response.data.errors).forEach(([field, messages]) => {
          setError(field, { type: "server", message: messages[0] });
          setFocus(field);
        });
      }
    },
  });

  const handleSubmitFn = (data) => {
    updateUserMutation.mutate({ ...data, companyId: companyDetails.id });
  };

  const onSubmitError = (error) => {
    console.log(error);
  };

  const setDefaultFormValue = (details) => {
    if (!details) return;
    const { name, registration_number, street, zip, region, address } = details;
    setValue("registration_number", registration_number);
    setValue("name", name);
    setValue("street", street);
    setValue("zip", zip);
    setValue("region", region);
    setValue("address", address);
    let country = Country.getCountryByCode(details.country);
    if (!country) {
      country = Country.getAllCountries().find(
        (c) => c.name.toLowerCase() === details?.country?.toLowerCase(),
      );
    }
    if (country) {
      setSelectedCountry({
        value: country.name,
        label: country.name,
        flag: country.flag,
        isoCode: country.isoCode,
      });
      setValue("country", country.name);
      setValue("country_code", country.isoCode);
    }
  };

  useEffect(() => {
    if (companyData && companyData.status === 200) {
      const fetchedData = companyData.data.workspace;
      dispatch(setCompanyDetails({ append: false, companyDetails: fetchedData }));
      dispatch(setAllCompanyData({ append: false, allCompanyData: fetchedData }));
      setDefaultFormValue(fetchedData);
    }
  }, [companyData]);

  return {
    handleSubmit: handleSubmit(handleSubmitFn, onSubmitError),
    register,
    reset,
    getValues,
    errors,
    toggleActionableCompanyId,
    toggleIsCompanyEditable,
    toggleShowDiscardModal,
    setCompanyDetails,
    setCompanySliceState,
    actionableCompanyId,
    isPending: updateUserMutation.isPending,
    setValue,
    control,
    selectedCountry,
    setSelectedCountry,
    clearErrors,
    refetchCompanyProfile,
  };
};

export default useCompanyProfile;
