import React from "react";

const PDFDragDropIcon = React.forwardRef(
  (
    {
      width = "48",
      height = "48",
      viewBox = "0 0 48 48",
      fill = "none",
      fillColor = "#D92D20",
      className,
      ...props
    },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M35 39.25H11C9.20507 39.25 7.75 37.7949 7.75 36V4C7.75 2.20508 9.20508 0.75 11 0.75H27C27.1212 0.75 27.2375 0.798159 27.3232 0.883883L38.1161 11.6768C38.2018 11.7625 38.25 11.8788 38.25 12V36C38.25 37.7949 36.7949 39.25 35 39.25Z"
          fill="white"
          stroke="#D5D7DA"
          strokeWidth="1.5"
        />
        <path d="M27 0.5V8C27 10.2091 28.7909 12 31 12H38.5" stroke="#D5D7DA" strokeWidth="1.5" />
        <path
          d="M1 20C1 18.8954 1.89543 18 3 18H25C26.1046 18 27 18.8954 27 20V32C27 33.1046 26.1046 34 25 34H3C1.89543 34 1 33.1046 1 32V20Z"
          fill="#11C182"
        />
        <path
          d="M4.8323 30V22.7273H7.70162C8.25323 22.7273 8.72316 22.8326 9.11142 23.0433C9.49967 23.2517 9.7956 23.5417 9.9992 23.9134C10.2052 24.2827 10.3082 24.7088 10.3082 25.1918C10.3082 25.6747 10.204 26.1009 9.99565 26.4702C9.78732 26.8395 9.48547 27.1271 9.09011 27.3331C8.69712 27.5391 8.22127 27.642 7.66255 27.642H5.83372V26.4098H7.41397C7.7099 26.4098 7.95375 26.3589 8.14551 26.2571C8.33964 26.1529 8.48405 26.0097 8.57875 25.8274C8.67581 25.6428 8.72434 25.4309 8.72434 25.1918C8.72434 24.9503 8.67581 24.7396 8.57875 24.5597C8.48405 24.3774 8.33964 24.2365 8.14551 24.1371C7.95138 24.0353 7.70517 23.9844 7.40687 23.9844H6.36994V30H4.8323ZM13.885 30H11.3069V22.7273H13.9063C14.6379 22.7273 15.2676 22.8729 15.7955 23.1641C16.3235 23.4529 16.7295 23.8684 17.0136 24.4105C17.3 24.9527 17.4433 25.6013 17.4433 26.3565C17.4433 27.1141 17.3 27.7652 17.0136 28.3097C16.7295 28.8542 16.3211 29.272 15.7884 29.5632C15.2581 29.8544 14.6237 30 13.885 30ZM12.8445 28.6825H13.8211C14.2757 28.6825 14.658 28.602 14.9681 28.4411C15.2806 28.2777 15.515 28.0256 15.6713 27.6847C15.8299 27.3414 15.9092 26.8987 15.9092 26.3565C15.9092 25.8191 15.8299 25.38 15.6713 25.0391C15.515 24.6982 15.2818 24.4472 14.9717 24.2862C14.6615 24.1252 14.2792 24.0447 13.8247 24.0447H12.8445V28.6825ZM18.5823 30V22.7273H23.3976V23.995H20.1199V25.728H23.078V26.9957H20.1199V30H18.5823Z"
          fill="white"
        />
        <g filter="url(#filter0_d_13236_10455)">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M34.6727 32.8478C35.16 32.6624 36.1213 32.7759 36.3751 33.3404C36.5913 33.8215 36.777 34.6327 36.7872 34.4598C36.8116 34.0756 36.7628 33.2446 36.9263 32.8103C37.045 32.4937 37.2785 32.1959 37.6226 32.0907C37.9119 32.0012 38.252 31.9699 38.5525 32.0335C38.8702 32.1001 39.2042 32.3323 39.329 32.5531C39.6965 33.2019 39.7026 34.5306 39.7198 34.4598C39.7848 34.1766 39.7909 33.18 40.0071 32.8103C40.1502 32.5656 40.5116 32.3469 40.7045 32.3115C41.0029 32.2574 41.3704 32.2407 41.683 32.3032C41.9358 32.3542 42.2779 32.6614 42.3703 32.8103C42.5926 33.1685 42.7174 34.1807 42.755 34.5369C42.7712 34.6837 42.8301 34.1276 43.0524 33.7704C43.4646 33.105 44.9243 32.9759 44.9791 34.4359C45.0055 35.1169 44.9994 35.0857 44.9994 35.5439C44.9994 36.0812 44.9872 36.4061 44.9588 36.7956C44.9283 37.2111 44.841 38.1535 44.7141 38.6096C44.6269 38.9231 44.3375 39.6281 44.0513 40.0509C44.0513 40.0509 42.9611 41.3526 42.8423 41.9378C42.7235 42.5241 42.7631 42.5283 42.7388 42.9427C42.7154 43.3582 42.8616 43.9039 42.8616 43.9039C42.8616 43.9039 42.0485 44.0122 41.6089 43.9393C41.212 43.8747 40.7207 43.0646 40.5938 42.8167C40.4192 42.4751 40.0467 42.5408 39.9015 42.7928C39.6742 43.1916 39.1818 43.907 38.8357 43.9518C38.1566 44.0393 36.7496 43.983 35.6482 43.9726C35.6482 43.9726 35.836 42.9198 35.4178 42.5585C35.1082 42.2877 34.5753 41.742 34.2565 41.4546L33.412 40.4955C33.1247 40.1206 32.3948 39.5281 32.1502 38.4284C31.934 37.4537 31.9553 36.9757 32.1878 36.5852C32.4233 36.1885 32.8679 35.9719 33.0547 35.9344C33.2658 35.8906 33.7571 35.8938 33.9429 35.9989C34.1692 36.127 34.2606 36.1645 34.4382 36.4061C34.6717 36.7258 34.755 36.881 34.6545 36.5321C34.5773 36.2593 34.3276 35.9125 34.2139 35.522C34.1033 35.1461 33.8068 34.54 33.8282 33.9329C33.8363 33.7028 33.9327 33.13 34.6727 32.8478Z"
            fill="white"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M34.6727 32.8478C35.16 32.6624 36.1213 32.7759 36.3751 33.3404C36.5913 33.8215 36.777 34.6327 36.7872 34.4598C36.8116 34.0756 36.7628 33.2446 36.9263 32.8103C37.045 32.4937 37.2785 32.1959 37.6226 32.0907C37.9119 32.0012 38.252 31.9699 38.5525 32.0335C38.8702 32.1001 39.2042 32.3323 39.329 32.5531C39.6965 33.2019 39.7026 34.5306 39.7198 34.4598C39.7848 34.1766 39.7909 33.18 40.0071 32.8103C40.1502 32.5656 40.5116 32.3469 40.7045 32.3115C41.0029 32.2574 41.3704 32.2407 41.683 32.3032C41.9358 32.3542 42.2779 32.6614 42.3703 32.8103C42.5926 33.1685 42.7174 34.1807 42.755 34.5369C42.7712 34.6837 42.8301 34.1276 43.0524 33.7704C43.4646 33.105 44.9243 32.9759 44.9791 34.4359C45.0055 35.1169 44.9994 35.0857 44.9994 35.5439C44.9994 36.0812 44.9872 36.4061 44.9588 36.7956C44.9283 37.2111 44.841 38.1535 44.7141 38.6096C44.6269 38.9231 44.3375 39.6281 44.0513 40.0509C44.0513 40.0509 42.9611 41.3526 42.8423 41.9378C42.7235 42.5241 42.7631 42.5283 42.7388 42.9427C42.7154 43.3582 42.8616 43.9039 42.8616 43.9039C42.8616 43.9039 42.0485 44.0122 41.6089 43.9393C41.212 43.8747 40.7207 43.0646 40.5938 42.8167C40.4192 42.4751 40.0467 42.5408 39.9015 42.7928C39.6742 43.1916 39.1818 43.907 38.8357 43.9518C38.1566 44.0393 36.7496 43.983 35.6482 43.9726C35.6482 43.9726 35.836 42.9198 35.4178 42.5585C35.1082 42.2877 34.5753 41.742 34.2565 41.4546L33.412 40.4955C33.1247 40.1206 32.3948 39.5281 32.1502 38.4284C31.934 37.4537 31.9553 36.9757 32.1878 36.5852C32.4233 36.1885 32.8679 35.9719 33.0547 35.9344C33.2658 35.8906 33.7571 35.8938 33.9429 35.9989C34.1692 36.127 34.2606 36.1645 34.4382 36.4061C34.6717 36.7258 34.755 36.881 34.6545 36.5321C34.5773 36.2593 34.3276 35.9125 34.2139 35.522C34.1033 35.1461 33.8068 34.54 33.8282 33.9329C33.8363 33.7028 33.9327 33.13 34.6727 32.8478Z"
            stroke="#101828"
            strokeWidth="0.75"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M38 37.5C38 37.2239 37.7761 37 37.5 37C37.2239 37 37 37.2239 37 37.5V40.5C37 40.7761 37.2239 41 37.5 41C37.7761 41 38 40.7761 38 40.5V37.5ZM39.5 37C39.7761 37 40 37.2239 40 37.5V40.5C40 40.7761 39.7761 41 39.5 41C39.2239 41 39 40.7761 39 40.5V37.5C39 37.2239 39.2239 37 39.5 37ZM41.5 37C41.7761 37 42 37.2239 42 37.5V40.5C42 40.7761 41.7761 41 41.5 41C41.2239 41 41 40.7761 41 40.5V37.5C41 37.2239 41.2239 37 41.5 37Z"
          fill="#101828"
        />
        <defs>
          <filter
            id="filter0_d_13236_10455"
            x="29.625"
            y="30.625"
            width="17.75"
            height="16.75"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="1" />
            <feGaussianBlur stdDeviation="1" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.027451 0 0 0 0 0.12549 0 0 0 0 0.239216 0 0 0 0.24 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_13236_10455"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_13236_10455"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    );
  },
);

export default PDFDragDropIcon;
