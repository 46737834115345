import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Country } from "country-state-city";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  createSubSupplier,
  getSubSupplierDetailsById,
  updateSubSupplierDetailsById,
} from "services/sub_suppliers.services";
import { setRefetchBillingData } from "store/billingSlice";
import { toggleIsSubSupplierCreateFromOrderModalOpen } from "store/orderSlice";
import {
  setSubSupplierDetails,
  toggleActionableSubSupplierId,
  toggleDuplicateAction,
  toggleIsSubSupplierCreateModalOpen,
  toggleIsSubSupplierEditable,
  toggleShowDiscardModal,
} from "store/subSupplierSlice";
import { removeQueryParam } from "utils/helper";
import { createSubSupplierSchema } from "validation/subSupplier.validation";
import useCustomToaster from "./useCustomToaster";

const useCreateUpdateSubSupplier = ({ refetchDatas }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [selectedCountry, setSelectedCountry] = useState(null);
  const { workspaceId } = useParams();
  const { showCustomToast } = useCustomToaster();
  const { refetchBillingData } = useSelector((state) => state.billingSlice);
  const {
    actionableSubSupplierId,
    isSubSupplierEditable,
    isDuplicateAction,
    subSupplierDetails,
    subSupplierList,
    showDiscardModal,
    isSubSupplierCreateModalOpen,
  } = useSelector((state) => state.subSupplierSlice);
  const { isSubSupplierCreateFromOrderModalOpen } = useSelector((state) => state.orderSlice);

  const {
    control,
    register,
    reset,
    setValue,
    getValues,
    handleSubmit,
    clearErrors,
    setError,
    setFocus,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      street: "",
      sub_tier_id: "",
      country: "",
      region: "",
      country_code: "",
      address: "",
      workspace_id: parseInt(workspaceId),
    },
    resolver: zodResolver(createSubSupplierSchema),
  });

  const { data: subSupplierDetailsById, isPending: isSubSupplierDetailsLoading } = useQuery({
    queryKey: ["article", "getDetails"],
    queryFn: () =>
      getSubSupplierDetailsById({
        id: actionableSubSupplierId,
        workspace_id: parseInt(workspaceId),
      }),
    enabled: !!actionableSubSupplierId,
  });

  const createSubSupplierMutation = useMutation({
    mutationKey: ["subSupplier", "createSubSupplier"],
    mutationFn: (data) => createSubSupplier(data),
    onMutate: () => {
      showCustomToast(
        {
          title: `${isDuplicateAction ? "Duplicating the sub-supplier...." : "Creating sub-supplier ..."}`,
        },
        "createSubSupplierLoadingToast",
      );
    },
    onSuccess: (response) => {
      reset();
      toast.dismiss("createSubSupplierLoadingToast");
      const data = response.data;
      if (data) {
        clearErrors();
        refetchDatas();
        if (!refetchBillingData) {
          dispatch(setRefetchBillingData(true));
        }
        if (isDuplicateAction) {
          dispatch(toggleDuplicateAction());
        }
        if (isSubSupplierCreateFromOrderModalOpen) {
          dispatch(toggleIsSubSupplierCreateFromOrderModalOpen());
        }
        if (isSubSupplierCreateModalOpen) {
          dispatch(toggleIsSubSupplierCreateModalOpen());
        }
        if (actionableSubSupplierId) {
          dispatch(toggleActionableSubSupplierId());
        }
        if (isSubSupplierEditable) {
          dispatch(toggleIsSubSupplierEditable());
        }
        showCustomToast({
          title: `Sub-Supplier created successfully`,
        });
        queryClient.invalidateQueries({ queryKey: ["subSuppliers", "createSubSupplier"] });
      }
    },
    onError: (error) => {
      toast.dismiss("createSubSupplierLoadingToast");
      const errorMessage = error?.response?.data?.message || error.message;
      const errorMessages = error?.response?.data?.errors;
      const toastMessages = errorMessages ? Object.values(errorMessages).flat() : [errorMessage];
      toastMessages.forEach((item) =>
        showCustomToast({
          title: item,
        }),
      );
    },
    onSettled: () => {
      toast.dismiss("createSubSupplierLoadingToast");
      removeQueryParam(["fromGlobalSearch", "subsuppliers_id", "selectedTab"], location, navigate);
      queryClient.invalidateQueries({
        queryKey: ["subSupplier", "get-sub-supplier"],
      });
    },
  });

  const updateArticleMutation = useMutation({
    mutationKey: ["subSupplier", "updateSubSupplier"],
    mutationFn: (data) => updateSubSupplierDetailsById(data),
    onMutate: () => {
      toast.loading("Upating the Sub-Supplier...", { id: "updateSubSupplierLoadingToast" });
    },
    onSuccess: (res) => {
      reset();
      toast.dismiss("updateSubSupplierLoadingToast");
      if (isSubSupplierCreateModalOpen) {
        dispatch(toggleIsSubSupplierCreateModalOpen());
      }
      refetchDatas();
      if (isSubSupplierEditable) {
        dispatch(toggleIsSubSupplierEditable());
      }
      showCustomToast({
        title: `Sub-Supplier updated successfully`,
      });
      queryClient.invalidateQueries({ queryKey: ["subSuppliers", "updateSubSupplier"] });
    },
    onError: (error) => {
      toast.dismiss("updateSubSupplierLoadingToast");
      const errorMessage = error?.response?.data?.message || error.message;
      const errorMessages = error?.response?.data?.errors;
      const toastMessages = errorMessages ? Object.values(errorMessages).flat() : [errorMessage];
      toastMessages.forEach((item) =>
        showCustomToast({
          title: item,
        }),
      );
    },
    onSettled: () => {
      toast.dismiss("updateSubSupplierLoadingToast");
      removeQueryParam(["fromGlobalSearch", "subsuppliers_id", "selectedTab"], location, navigate);
      queryClient.invalidateQueries({
        queryKey: ["subSupplier", "updateSubSupplier"],
      });
    },
  });

  const onSubmit = (data) => {
    const processedData = {
      ...data,
      sub_tier_id: data.sub_tier_id?.value,
    };
    if (actionableSubSupplierId && !isDuplicateAction) {
      updateArticleMutation.mutate({ body: processedData, id: actionableSubSupplierId });
    } else {
      createSubSupplierMutation.mutate(processedData);
    }
  };

  const onValidationError = (error) => {
    console.log(error);
  };

  const toggleEditOrDiscard = () => {
    dispatch(toggleIsSubSupplierEditable());
    if (isSubSupplierEditable) {
      dispatch(toggleShowDiscardModal());
      dispatch(toggleIsSubSupplierCreateModalOpen());
    }
  };

  const handleConfirmDiscard = (confirm) => {
    dispatch(toggleIsSubSupplierEditable());

    if (!confirm) {
      dispatch(toggleShowDiscardModal());
      return;
    }

    dispatch(toggleIsSubSupplierEditable());
    setDefaultFormValue(subSupplierDetails);
    dispatch(toggleShowDiscardModal());
  };

  const setDefaultFormValue = (details) => {
    if (!details && !actionableSubSupplierId) return;
    if (details) {
      const { name, street, sub_tier, region, address } = details;
      setValue("name", name);
      setValue("workspace_id", parseInt(workspaceId));
      setValue("region", region);
      setValue("street", street);
      if (sub_tier) {
        setValue("sub_tier_id", {
          value: sub_tier.id,
          label: sub_tier.name,
        });
      }
      setValue("address", address);
      let country = Country.getCountryByCode(details.country);
      if (!country) {
        country = Country.getAllCountries().find(
          (c) => c.name.toLowerCase() === details?.country?.toLowerCase(),
        );
      }
      if (country) {
        setSelectedCountry({
          value: country.name,
          label: country.name,
          flag: country.flag,
          isoCode: country.isoCode,
        });
        setValue("country", country.name);
        setValue("country_code", country.isoCode);
      }
    }
  };

  useEffect(() => {
    if (
      actionableSubSupplierId &&
      subSupplierDetailsById &&
      subSupplierDetailsById.status === 200
    ) {
      if (subSupplierDetailsById.data) {
        dispatch(setSubSupplierDetails(subSupplierDetailsById.data.sub_supplier));
        setDefaultFormValue(subSupplierDetailsById.data.sub_supplier);
      }
    }
  }, [subSupplierDetailsById, actionableSubSupplierId]);

  return {
    control,
    isDuplicateAction,
    subSupplierDetails,
    errors,
    isPending: createSubSupplierMutation.isPending || updateArticleMutation.isPending,
    isSubSupplierEditable,
    actionableSubSupplierId,
    showDiscardModal,
    register,
    reset,
    handleSubmit,
    onSubmit,
    onValidationError,
    getValues,
    setValue,
    toggleEditOrDiscard,
    handleConfirmDiscard,
    selectedCountry,
    setSelectedCountry,
    clearErrors,
    isSubSupplierDetailsLoading,
  };
};

export default useCreateUpdateSubSupplier;
