import { Button } from "components/UI/Button";
import Divider from "components/UI/Divider";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import useCompanyProfile from "hooks/useCompanyProfile";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CertificateIcon, CompanyIconRotate } from "assests";
import BackToDashboard from "components/UI/BackToDashboard";
import ChipButton from "components/UI/ChipButton";
import CommonCard from "components/UI/CommonCard";
import CommonDiscardButton from "components/UI/CommonDiscardButton";
import Modal from "components/UI/Modal";
import SubNavbar from "layout/SubNavbar";
import { Navigate, useLocation, useNavigate, useParams } from "react-router";
import {
  setCertificateList,
  toggleActionableCertificateId,
  toggleisCertificateCreateModalOpen,
  toggleisCertificateEditable,
} from "store/certificateSlice";
import {
  toggleIsCompanyCreateModalOpen,
  toggleIsCompanyEditable,
  toggleShowDiscardModal,
} from "store/companySlice";
import CreateCertificate from "../Workspace/AccountDetailsPage/CreateCertificate";
import CertificateCard from "./CertificateCard";
import EditCompanyForm from "./EditCompanyForm";
import SettingsPermissionModal from "components/UI/SettingsPermissionModal";
import { getCertificates } from "services/certificate.services";
import { formatAddress, formateDateToLongMonthYear, removeQueryParam } from "utils/helper";
import toast from "react-hot-toast";
import { deactivateCompanyAccount } from "services/company-settings.services";
import useCustomToaster from "hooks/useCustomToaster";
import { clearAuthData } from "store/authenticationSlice";
import LoadingComponent from "components/UI/LoadingComponent";
import EmptyComponent from "components/Card/EmptyComponent";
const CompanyProfileList = () => {
  const { isCertificateCreateModalOpen, certificateList, actionableCertificateId, isCertificateEditable } = useSelector(
    (state) => state.certificateSlice,
  );
  const location = useLocation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { showCustomToast } = useCustomToaster();
  const { currentWorkspace, user } = useSelector((state) => state.authenticationSlice);
  const supplierId = currentWorkspace?.workspace_id && parseInt(currentWorkspace?.workspace_id);
  const { currentPlan } = useSelector((state) => state.billingSlice);
  const {
    control,
    errors,
    register,
    setValue,
    onError,
    clearErrors,
    handleSubmit,
    selectedCountry,
    setSelectedCountry,
    reset,
    refetchCompanyProfile,
  } = useCompanyProfile();

  const handleClickOpen = (actionType) => {
    switch (actionType) {
      case "createCompany":
        dispatch(toggleIsCompanyCreateModalOpen());
        refetchCompanyProfile();
        break;
      case "editCompany":
        dispatch(toggleIsCompanyCreateModalOpen());

        break;
      case "discard":
        dispatch(toggleShowDiscardModal());
        break;
      default:
        break;
    }
  };

  const handleClickOptionButton = (certificateId, actionType) => {
    switch (actionType) {
      case "create":
        dispatch(toggleisCertificateCreateModalOpen());
        if (actionableCertificateId) {
          dispatch(toggleActionableCertificateId());
        }
        if (!isCertificateEditable) {
          dispatch(toggleisCertificateEditable());
        }
        break;
      case "edit":
        dispatch(toggleActionableCertificateId(certificateId));
        dispatch(toggleisCertificateCreateModalOpen());
        if (!isCertificateEditable) {
          dispatch(toggleisCertificateEditable());
        }
        break;
      case "view":
        dispatch(toggleActionableCertificateId(certificateId));
        dispatch(toggleisCertificateCreateModalOpen());
        if (isCertificateEditable) {
          dispatch(toggleisCertificateEditable());
        }
        break;
      default:
        break;
    }
  };

  const {
    data: certificatesQueryData,
    isLoading: iscertificatesQuryLoading,
    refetch: refetchCertificatesQury,
  } = useQuery({
    queryKey: ["certificates", "getCertificates", supplierId],
    queryFn: () => getCertificates({ workspace_id: supplierId }),
    enabled: !!user && !!user?.workspace_type?.includes("supplier") && !!supplierId,
  });

  useEffect(() => {
    if (
      certificatesQueryData &&
      certificatesQueryData.status === 200 &&
      !iscertificatesQuryLoading
    ) {
      const details = certificatesQueryData.data.certificates.data;
      dispatch(setCertificateList({ append: false, certificateList: details }));
    }
  }, [certificatesQueryData, iscertificatesQuryLoading]);

  const { isCompanyCreateModalOpen, showDiscardModal, isCompanyEditable, companyDetails } =
    useSelector((state) => state.companySlice);

  const closeModal = () => {
    if (isCertificateCreateModalOpen) {
      dispatch(toggleisCertificateCreateModalOpen());
    }
    if (isCompanyCreateModalOpen) {
      dispatch(toggleIsCompanyCreateModalOpen());
      reset();
    }
    if (showDiscardModal) {
      dispatch(toggleShowDiscardModal());
    }
    if (isCertificateEditable) {
      dispatch(toggleisCertificateEditable());
    }
    if (actionableCertificateId) {
      dispatch(toggleActionableCertificateId());
    }
    removeQueryParam(["selectedTab"], location, navigate);

  };

  const handleLogOut = () => {
    dispatch(clearAuthData());
    dispatch({ type: "LOGOUT" });
    return <Navigate to="/signin" />;
  };

  const deactivateCompanyAccountMutation = useMutation({
    mutationKey: ["deactivateAccount", "delete"],
    mutationFn: (data) => deactivateCompanyAccount(data),
    onMutate: () => {
      showCustomToast(
        {
          title: "Deactivating...",
        },
        "deactivatingAccount",
      );
    },
    onSuccess: (res) => {
      toast.dismiss("deactivatingAccount");
      showCustomToast({
        title: res.data.message,
      });
      if (showDiscardModal) {
        dispatch(toggleShowDiscardModal());
      }
      handleLogOut();
      queryClient.invalidateQueries(["deactivatingAccount", "get", "delete"]);
    },
    onError: (error) => {
      toast.dismiss("deactivatingAccount");
      showCustomToast({
        title: error.response.data.message || error.message,
      });
    },
    onSettled: () => {
      toast.dismiss("deactivatingAccount");
    },
  });

  const handleOnDeactivateAccount = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!showDiscardModal) return;
    deactivateCompanyAccountMutation.mutate({
      workspace_id: parseInt(currentWorkspace?.workspace_id),
    });
  };

  return (
    <div>
      <SettingsPermissionModal />
      <div className="w-full overflow-hidden pt-0  pb-16">
        <div className="grid grid-cols-12 justify-start items-center gap-3 md:gap-6">
          <div className="col-span-12">
            <div className="w-full lg:w-[520px]">
              {companyDetails && (
                <CommonCard
                  onClick={() => handleClickOpen("createCompany")}
                  btnText={"Edit details"}
                  minHeight={143}
                  className="flex-col justify-start items-center"
                >
                  <div className="justify-between items-start flex w-full h-full">
                    <div className="py-1 flex-col justify-start items-start gap-1 inline-flex">
                      <div className="justify-start items-center gap-2 inline-flex">
                        <div className="text-global-title text-base md:text-lg font-semibold leading-7">
                          {companyDetails.name}
                        </div>
                        <ChipButton className="!uppercase" childrenClassName="text-sm lg:text-base">
                          {currentPlan?.plan?.name ? currentPlan?.plan?.name : "FREE"}
                        </ChipButton>
                      </div>
                      <div>
                        <p className="text-global-paragraph text-xs lg:text-sm font-normal leading-tight">
                          {companyDetails.street && companyDetails.street}
                        </p>
                        <p className="text-global-paragraph text-xs lg:text-sm font-normal leading-tight">
                          {companyDetails &&
                            formatAddress({
                              region: companyDetails.region,
                              zip: companyDetails.zip,
                              countryCode: companyDetails.country_code,
                            })}
                        </p>
                        <p className="text-global-paragraph text-xs lg:text-sm font-normal leading-tight">
                          {companyDetails.country}
                        </p>
                      </div>
                    </div>
                  </div>
                </CommonCard>
              )}
            </div>
          </div>
        </div>
        {user && user?.workspace_type && user?.workspace_type?.includes("supplier") && (
          <>
            <Divider className={"bg-global-divider-medium my-4 md:my-[35px]"} />
            <div className="flex flex-col md:flex-row justify-center md:justify-between">
              <div className="flex flex-col gap-1">
                <h3 className="text-base md:text-lg font-semibold text-global-title">
                  Company Certificates
                </h3>
                <p className="text-xs lg:text-sm font-normal text-global-paragraph">
                  Pick an account plan that fits your workflow.
                </p>
              </div>
              <div className="mt-4 md:mt-0">
                <Button
                  variant={"primary"}
                  className="text-sm font-semibold h-10 w-full md:w-[160px] px-[14px] gap-1 whitespace-nowrap"
                  onClick={() => handleClickOptionButton(null, "create")}
                >
                  Add Certificate <CertificateIcon fillColor={"white"} />
                </Button>
              </div>
            </div>

            <div className="flex flex-col sm:flex-row flex-wrap items-center gap-4 md:gap-6  mt-4 md:mt-6">
              {!iscertificatesQuryLoading && certificateList && certificateList?.length > 0 ? (
                certificateList?.map((item, index) => (
                  <div className="flex-shrink-0 w-full sm:w-auto">
                    <CertificateCard
                      key={index}
                      item={item}
                      index={index}
                      handleClickOptionButton={handleClickOptionButton}
                    />
                  </div>
                ))
              ) : iscertificatesQuryLoading ? (
                <LoadingComponent />
              ) : (
                <EmptyComponent text="No certificate found!" />
              )}
            </div>
          </>
        )}
        {currentWorkspace && currentWorkspace?.is_owner && (
          <>
            <Divider className={"my-4 lg:my-[35px]"} />
            <h4 className="text-base lg:text-lg text-global-title font-semibold">
              Deactivate Account
            </h4>
            <p className="text-xs lg:text-sm text-global-paragraph font-normal leading-5">
              Deactivate your FiberVue™ company account is irreversible. All items you’ve created
              will be permanently erased.
            </p>
            <Button
              variant={"primary"}
              className={
                "mt-4 lg:mt-6 h-11 w-full lg:w-56 py-2.5 px-4 bg-global-danger text-base font-semibold text-white whitespace-nowrap"
              }
              onClick={() => handleClickOpen("discard")}
            >
              Deactivate Account
            </Button>
          </>
        )}

        <Modal open={isCompanyCreateModalOpen} close={closeModal}>
          <EditCompanyForm
            {...{
              closeModal,
              control,
              errors,
              register,
              setValue,
              onError,
              clearErrors,
              handleSubmit,
              selectedCountry,
              setSelectedCountry,
            }}
          />
        </Modal>
        <Modal open={showDiscardModal} close={closeModal}>
          <CommonDiscardButton
            title={"Deactivate Account"}
            subText1={
              currentPlan && currentPlan?.auto_renewal
                ? `Are you sure you want to deactivate your company account? This action is irreversible. Your billing will stop on ${formateDateToLongMonthYear(currentPlan?.auto_renewal)}.`
                : "Are you sure you want to deactivate your company account? This action is irreversible."
            }
            boldText={
              currentPlan &&
              currentPlan?.auto_renewal &&
              formateDateToLongMonthYear(currentPlan?.auto_renewal)
            }
            IconComponent={CompanyIconRotate}
            confirmButtonText={"Deactive account"}
            closeModal={closeModal}
            confirmButtonClassName={"w-full"}
            confirmButtonVariant={"warning"}
            className={"w-full lg:w-[544px]"}
            onConfirmClick={handleOnDeactivateAccount}
          />
        </Modal>
        <Modal open={isCertificateCreateModalOpen} close={closeModal} title={""}>
          <CreateCertificate supplierId={supplierId} workspaceRefetch={refetchCertificatesQury} />
        </Modal>
      </div>
    </div>
  );
};

export default CompanyProfileList;
