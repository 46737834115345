import { cva } from "class-variance-authority";
import React, { forwardRef } from "react";
import { cn } from "utils/helper";

const inputVariants = cva(
  `flex w-full gap-3  px-[14px] text-base shadow-[0px,1px,2px,0px,rgba(16,24,40,0.05)] focus:shadow-[none] focus:ring-offset-input-surface-background focus:border-none focus:ring-input-stroke-default py-[2.5px] items-center text-input-text-data placeholder:text-input-text-placeholder placeholder:font-normal placeholder:text-base  self-stretch border border-input-stroke-default bg-input-surface-background hover:border-gray-800 transition duration-300 ease-in-out rounded-lg   disabled:bg-input-surface-disabled disabled:border-dropdown-stroke-default-2 read-only:bg-input-surface-hover hover:bg-input-surface-hover hover:outline-none`,
  {
    variants: {
      size: {
        xl: "h-[49px]",
        lg: "h-[44px]",
        md: "h-11",
        sm: "h-8",
      },
    },
    defaultVariants: {
      size: "lg",
    },
  },
);

const InputWithRef = forwardRef(
  (
    {
      className,
      size,
      type,
      disabled,
      iconLeft,
      iconRight,
      defaultValue,
      onChange,
      onKeyPress,
      onPaste,
      isError,
      ...props
    },
    ref,
  ) => {
    const iconPaddingClass = iconLeft ? "ps-12" : iconRight ? "pe-12" : "";
    const errorClass = isError ? "border-input-text-error" : "";
    if (type === "radio") {
      return (
        <div className="rounded-full">
          <input
            type="radio"
            className={cn(
              "h-5 w-5 rounded-full border-2 focus:ring-2 focus:ring-global-primary ",
              "bg-white",
              "checked:bg-[var(--color-global-primary)]",
              disabled && "cursor-not-allowed",
            )}
            ref={ref}
            onChange={onChange}
            disabled={disabled}
            {...props}
          />
          <div className="ml-2">{props.children}</div>
        </div>
      );
    }

    return (
      <div className="relative w-full">
        {iconLeft && (
          <div className="absolute inset-y-0 left-0 flex items-center pl-3">{iconLeft}</div>
        )}
        <input
          type={type}
          className={cn(inputVariants({ size, className }), iconPaddingClass, errorClass)}
          ref={ref}
          onChange={onChange}
          disabled={disabled}
          defaultValue={defaultValue}
          autoComplete="off"
          onKeyPress={onKeyPress}
          onPaste={onPaste}
          {...props}
        />
        {iconRight && (
          <div className="absolute inset-y-0 right-0 flex items-center pl-2 pr-3.5">
            {iconRight}
          </div>
        )}
      </div>
    );
  },
);

export const Input = React.memo(InputWithRef);
