import React from "react";

const play = React.forwardRef(
  (
    {
      width = "20",
      height = "20",
      viewBox = "0 0 20 20",
      fill = "none",
      strokeColor = "white",
      className,
    },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.99999 17.716C14.2614 17.716 17.716 14.2614 17.716 9.99999C17.716 5.73853 14.2614 2.28394 9.99999 2.28394C5.73853 2.28394 2.28394 5.73853 2.28394 9.99999C2.28394 14.2614 5.73853 17.716 9.99999 17.716Z"
          stroke={strokeColor}
          strokeWidth="1.67"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.07097 7.65842C8.07097 7.29015 8.07097 7.10601 8.14793 7.00321C8.215 6.91362 8.31767 6.85757 8.42929 6.8496C8.55738 6.84045 8.71227 6.94003 9.02206 7.13917L12.6645 9.48074C12.9333 9.65355 13.0677 9.73996 13.1141 9.84982C13.1547 9.94582 13.1547 10.0541 13.1141 10.1501C13.0677 10.26 12.9333 10.3464 12.6645 10.5192L9.02206 12.8608C8.71227 13.0599 8.55738 13.1595 8.42929 13.1504C8.31767 13.1424 8.215 13.0863 8.14793 12.9968C8.07097 12.894 8.07097 12.7098 8.07097 12.3416V7.65842Z"
          stroke={strokeColor}
          strokeWidth="1.67"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  },
);

play.displayName = "play";

export default play;
