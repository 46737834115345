import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { resetpassword } from "services/auth.services";
import { resetpasswordSchema } from "validation/auth.validation";
import useCustomToaster from "./useCustomToaster";

const useResetPassword = () => {
  const url = window.location.href;
  const encodedString = url.split('?')[1];
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [newPasswordInputType, setNewPasswordInputType] = useState("password");
  const [confirmPasswordInputType, setConfirmPasswordInputType] = useState("password");
  const [resetToken, setResetToken] = useState('');
  const [email, setEmail] = useState('');
  const { showCustomToast } = useCustomToaster();


  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      password: "",
      email: "",
      token: "",
      password_confirmation: "",
    },
    resolver: zodResolver(resetpasswordSchema),
  });
  const mutation = useMutation({
    mutationKey: ["auth", "resetpassword"],
    mutationFn: resetpassword,
    onSuccess: handleSuccess,
    onError: handleError,
  });
  const handleSubmitFn = (data) => mutation.mutate(data);
  function handleSuccess(response) {
    const data = response.data;
    reset();
    queryClient.invalidateQueries("protectedResource");
    showCustomToast({
      title: "Password reset successful",
      subtitle:"Your password has been successfully reset. You can now log in with your new password."
    });
    navigate("/reset-password-success");
  }

  const toggleNewPasswordType = () => {
    setNewPasswordInputType((prevType) => (prevType === "password" ? "text" : "password"));
  };

  const toggleConfirmPasswordType = () => {
    setConfirmPasswordInputType((prevType) => (prevType === "password" ? "text" : "password"));
  };

  function handleError(error) {
    const errorMessage = error?.response?.data?.message || error.message;

    showCustomToast({
      title: errorMessage,
    });
  }

  useEffect(() => {
    if (encodedString) {
      try {
        const decodedString = atob(encodedString);
        const params = new URLSearchParams(decodedString);
        setResetToken(params.get('token'));
        setEmail(params.get('email'));
        setValue("token", params.get('token'));
        setValue("email", params.get('email'));
      } catch (error) {
        console.error("Error decoding the string:", error);
      }
    }
  }, [encodedString, setValue])

  return {
    errors,
    isLoading: mutation.isLoading,
    newPasswordInputType,
    confirmPasswordInputType,
    resetToken,
    email,
    encodedString,
    register,
    handleSubmit: handleSubmit(handleSubmitFn),
    toggleNewPasswordType,
    toggleConfirmPasswordType,
    setValue,
    setNewPasswordInputType,
    setConfirmPasswordInputType,
  };
};

export default useResetPassword;
