import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
const TogglerButton = ({ btnTextOne, btnTextTwo }) => {
    // const dispatch = useDispatch();
    // const handleToggle = () => {
    //     dispatch(toggleRequestDemo());
    // };
    const { showRequestDemo } = useSelector((state) => state.navbarSlice);

    return (
        <div className="flex justify-center items-center text-center ">
            <div className="bg-toggle-surface-background w-[360px] font-semibold  h-[36px] border border-toggle-stroke-default rounded-lg transition duration-300 ease-in-out  flex ">
                <Link
                    to={`${process.env.REACT_APP_WEB_URL}/request-demo`}
                    target="_blank"
                    rel="noreferrer"
                    className={`icon-option w-1/2 cursor-pointer text-sm text-center flex justify-center items-center px-4   ${showRequestDemo
                        ? "bg-toggle-active-switch rounded-lg border border-transparent ring-1 ring-toggle-stroke-active shadow-toggle-shadow  text-toggle-text-active "
                        : "text-toggle-text-default"
                        }`}
                >
                    {btnTextOne}
                </Link>
                <div
                    className={`icon-option w-1/2 cursor-pointer text-sm  text-center flex justify-center  items-center px-4   ${!showRequestDemo
                        ? "bg-toggle-active-switch rounded-lg border border-transparent ring-1 ring-toggle-stroke-active shadow-toggle-shadow  text-toggle-text-active"
                        : "text-toggle-text-default"
                        }`}
                >
                    {btnTextTwo}
                </div>
            </div>
        </div>
    );
};

export default TogglerButton;
