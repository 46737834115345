import { Autocomplete } from "@react-google-maps/api";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  AttachFileicon,
  Cross,
  LinkIcon,
  PDFDragDropIcon,
  PDFIcon,
  Trash,
  UploadImgIcon,
} from "assests";
import { Button } from "components/UI/Button";
import CountrySelect from "components/UI/CountrySelect";
import CustomSelect, { customMenuList } from "components/UI/CustomSelect";
import Divider from "components/UI/Divider";
import ErrorMessage from "components/UI/ErrorMessage";
import { Input } from "components/UI/Input";
import { Label } from "components/UI/Label";
import Modal from "components/UI/Modal";
import ToasterIcon from "components/UI/ToasterIcon";
import UpgradeComponent from "components/UI/UpgradeComponent";
import { useGoogleMaps } from "contexts/GoogleMapsContext";
import { Country } from "country-state-city";
import useCustomToaster from "hooks/useCustomToaster";
import useOrderDetails from "hooks/useOrderDetails";
import usePermission from "hooks/usePermission";
import AddSubSupplier from "pages/SubSupplier/AddSubSupplier";
import { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { components } from "react-select";
import Datepicker from "react-tailwindcss-datepicker";
import { unLinkForwarderData, unLinkSubsupplierData } from "services/order.services";
import { toggleIsSubSupplierCreateFromOrderModalOpen } from "store/orderSlice";
import { setTimeInDateObject, truncateString } from "utils/helper";

const AddLocationForm = ({ closeModal, selectedSubTierId, refetchOrderDetails, canUpdateSubSupplier }) => {
  const [selectedSubTierTitle, setSelectedSubTierTitle] = useState(null);
  const { hasPermission } = usePermission();
  const [autocomplete, setAutocomplete] = useState(null);
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { showCustomToast } = useCustomToaster();
  const {
    control,
    imagePreviews,
    isDragOver,
    handleDragEnter,
    handleDragLeave,
    handleDrop,
    onFileDropOrChange,
    onValidationError,
    register,
    reset,
    handleSubmit,
    onSubmit,
    subSupplierList,
    errors,
    setValue,
    isPending,
    toggleEditOrDiscard,
    handleConfirmDiscard,
    fileData,
    getValues,
    setError,
    clearErrors,
    showFileData,
    handleCertFileDelete,
    selectedCountry,
    setSelectedCountry,
    refetchSubSupplierData,
    isInitialValuesSet,
    isManuallyValuesSet,
    setIsManuallyValuesSet,
    setIsInitialValuesSet,
  } = useOrderDetails({ refetchOrderDetails });

  const {
    isOrderDocumentsEditable,
    selectedOrderDocumentsId,
    orderDocumentsShowDiscardModal,
    selectedTierLists,
    selectedTierType,
    isSubSupplierCreateFromOrderModalOpen,

  } = useSelector((state) => state.orderSlice);

  const { isLoaded } = useGoogleMaps();

  const handleCancel = () => {
    reset();
    closeModal();
    setIsManuallyValuesSet(false);
    setIsInitialValuesSet(false);
  };

  const onLoad = (autocomplete) => {
    setAutocomplete(autocomplete);
  };

  const onPlaceChanged = () => {
    if (autocomplete) {
      const place = autocomplete.getPlace();
      if (place.formatted_address) {
        const formattedAddress = place.formatted_address;
        setValue("address", formattedAddress);
      } else {
        const suggestedAddress = place.name;
        setValue("address", suggestedAddress);
      }
      if (place.address_components) {
        let street = "";

        let region = "";
        let country = "";
        let countryCode = "";

        place.address_components.forEach((component) => {
          const types = component.types;
          if (types.includes("street_number")) {
            street = component.long_name + " ";
          }
          if (types.includes("route")) {
            street += component.long_name;
          }
          // if (types.includes("postal_code")) {
          //   zip = component.long_name;
          // }
          if (types.includes("locality")) {
            region = component.long_name;
          }
          if (types.includes("country")) {
            country = component.long_name;
            countryCode = component.short_name;
          }
        });
        let countryData = Country.getCountryByCode(country);
        if (!countryData) {
          countryData = Country.getAllCountries().find(
            (c) => c.name.toLowerCase() === country?.toLowerCase(),
          );
        }
        if (countryData) {
          setValue("sub_supplier_country", countryData.name);
          setValue("country_code", countryData.isoCode);
          setSelectedCountry({
            value: countryData.name,
            label: countryData.name,
            flag: countryData.flag,
            isoCode: countryData.isoCode,
          });
        }
        setValue("sub_supplier_street", street);
        // setValue("zip", zip);
        setValue("sub_supplier_region", region);
        setValue("sub_supplier_country", country);
        setValue("country_code", countryCode);
      }
      if (selectedOrderDocumentsId) {
        setIsManuallyValuesSet(true);
        setIsInitialValuesSet(false);
      }
    }
  };

  useEffect(() => {
    if (selectedCountry) {
      clearErrors("sub_supplier_country");
      clearErrors("country_code");
      setValue("sub_supplier_country", selectedCountry.value);
      setValue("country_code", selectedCountry.isoCode);
    }
  }, [selectedCountry]);

  useEffect(() => {
    setValue("sub_tier_id", selectedSubTierId);
    if (
      typeof selectedTierLists === "object" &&
      Object.keys(selectedTierLists).length > 0 &&
      selectedSubTierId
    ) {
      const tierList = Object.values(selectedTierLists).flat();
      const tierItem = tierList.find((item) => item.value === selectedSubTierId);
      const selectedTierLabel = tierItem ? tierItem.label : "";
      setSelectedSubTierTitle(selectedTierLabel);
    }
  }, [selectedSubTierId]);

  const onClickNew = () => {
    dispatch(toggleIsSubSupplierCreateFromOrderModalOpen());
  };

  const unlinkSubsupplierMutation = useMutation({
    mutationKey: ["deleteForwarder", "delete"],
    mutationFn: (id) => unLinkSubsupplierData(id),
    onMutate: () => {
      showCustomToast({ title: "Deleting..." }, "unlinkForwarder");
    },
    onSuccess: () => {
      toast.dismiss("unlinkForwarder");
      showCustomToast({ title: "Unlinked successfully" });
      refetchOrderDetails();
      handleCancel();
      queryClient.invalidateQueries(["deleteForwarder", "get", "delete"]);
    },
    onError: (error) => {
      toast.dismiss("unlinkForwarder");
      showCustomToast({ title: error.response.data.message || error.message });
    },
    onSettled: () => {
      toast.dismiss("unlinkForwarder");
    },
  });

  const handleSubSupplierSelect = (selectedOption) => {
    if (selectedOption) {
      clearErrors("sub_supplier_id");
      setValue("sub_supplier_region", selectedOption.region);
      setValue("sub_supplier_street", selectedOption.street);
      setValue("address", selectedOption.address);
      let country = Country.getCountryByCode(selectedOption.country);
      if (!country) {
        country = Country.getAllCountries().find(
          (c) => c.name.toLowerCase() === selectedOption?.country?.toLowerCase(),
        );
      }
      if (country) {
        setSelectedCountry({
          value: country.name,
          label: country.name,
          flag: country.flag,
          isoCode: country.isoCode,
        });
        setValue("sub_supplier_country", country.name);
        setValue("country_code", country.isoCode);
      }
      if (selectedOrderDocumentsId) {
        setIsManuallyValuesSet(true);
        setIsInitialValuesSet(false);
      }
    }
  };

  const filteredSubSupplierList = (subSupplierList && subSupplierList.length > 0)
    ? subSupplierList.filter((item) => item.type === selectedTierType)
    : [];

  const handleOnUnLink = (id) => {
    if (!id) return;
    unlinkSubsupplierMutation.mutate(id);
  };

  return (
    <>
      <Modal open={isSubSupplierCreateFromOrderModalOpen} close={() => null} title={""}>
        <AddSubSupplier
          closeModal={() => {
            dispatch(toggleIsSubSupplierCreateFromOrderModalOpen());
          }}
          refetchDatas={refetchSubSupplierData}
          fromOrderDetailsPage={true}
        />
      </Modal>
      <div className="relative">
        <div className=" flex items-center justify-center ">
          <div className="w-full lg:w-[600px]  py-6   rounded-xl bg-white z-[2] ">
            <div className="absolute right-5 top-5 z-[2] cursor-pointer " onClick={handleCancel}>
              <Cross fillColor={"#A4A7AE"} height={24} width={24} />
            </div>
            <form onSubmit={handleSubmit(onSubmit, onValidationError)}>
              <div className="grid grid-cols-1 gap-6">
                <div>
                  <div className="flex justify-start gap-x-4 px-6 z-[1] ">
                    <UpgradeComponent>
                      <AttachFileicon
                        strokeColor={"var(--color-txt-secondary-secondary)"}
                        width={24}
                        height={24}
                      />
                    </UpgradeComponent>

                    <div className="flex justify-between z-[2]">
                      <div className="flex flex-col gap-1">
                        <h2 className="text-sm lg:text-lg  font-semibold text-start text-login-text-title">
                          {selectedSubTierTitle ? `${selectedSubTierTitle}` : "Add New Location"}
                        </h2>
                        <p className="text-xs lg:text-sm leading-5 text-global-paragraph font-normal truncate">
                          Add supply chain partner details here.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="grid gap-4 lg:gap-6 px-6 pt-6 lg:pt-11  h-[378px] lg:h-[399px] overflow-y-auto z-[3]">
                    <div className="col-span-2 z-[6]">
                      <div className="grid gap-1.5">
                        <Label htmlFor="certificate_type_id">Company Name</Label>
                        <CustomSelect
                          control={control}
                          name="sub_supplier_id"
                          placeholder="Select company"
                          options={filteredSubSupplierList}
                          isClearable={false}
                          handleOnChange={handleSubSupplierSelect}

                          {...register("sub_supplier_id")}
                          {...(canUpdateSubSupplier
                            ? {
                              components: {
                                ...components,
                                MenuList: (props) =>
                                  customMenuList({
                                    ...props,
                                    label: "Sub Supplier",
                                    onClick: onClickNew,
                                  }),
                              }
                            } : {})}
                          style={{
                            padding: "4px 10px 4.5px 6px",
                            fontSize: "16px",
                            width: "100%",
                            iconSize: 16,
                          }}
                          isDisabled={!canUpdateSubSupplier}
                        />
                        <ErrorMessage errors={errors?.sub_supplier_id?.message} />
                      </div>
                    </div>
                    <div className="col-span-2">
                      <div className="grid gap-1.5 relative">
                        <Label className="text-sm font-medium leading-5">Street</Label>
                        {isLoaded && (
                          <div className="relative">
                            <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
                              <Input
                                id="street"
                                type="text"
                                placeholder="Enter street"
                                {...register("sub_supplier_street")}
                                onChange={(e) => {
                                  if (selectedOrderDocumentsId) {
                                    setIsManuallyValuesSet(true);
                                    setIsInitialValuesSet(false);
                                  }
                                }}
                                disabled={!canUpdateSubSupplier}
                              />
                            </Autocomplete>
                          </div>
                        )}
                        <ErrorMessage errors={errors?.sub_supplier_street?.message} />
                      </div>
                    </div>
                    <div className="col-span-2 lg:col-span-1">
                      <div className="grid gap-1.5">
                        <Label className="text-sm font-medium leading-5">City</Label>
                        <Input
                          id="region"
                          type="text"
                          placeholder="Enter city"
                          {...register("sub_supplier_region")}
                          onChange={(e) => {
                            if (selectedOrderDocumentsId) {
                              setIsManuallyValuesSet(true);
                              setIsInitialValuesSet(false);
                            }
                          }}
                          disabled={!canUpdateSubSupplier}
                        />
                        <ErrorMessage errors={errors?.sub_supplier_region?.message} />
                      </div>
                    </div>
                    <div className="col-span-2 lg:col-span-1">
                      <div className="grid gap-1.5">
                        <Label className="text-sm font-medium leading-5">Country</Label>
                        <CountrySelect
                          control={control}
                          {...{ selectedCountry, setSelectedCountry }}
                          onChange={() => {
                            if (selectedOrderDocumentsId) {
                              setIsManuallyValuesSet(true);
                              setIsInitialValuesSet(false);
                            }
                          }}
                          isDisabled={!canUpdateSubSupplier}
                        />
                        <ErrorMessage errors={errors?.sub_supplier_country?.message} />
                      </div>
                    </div>
                    <div className="col-span-2 lg:col-span-1">
                      <div className="grid gap-1.5">
                        <Label>Arrived</Label>
                        <div className="relative">
                          <Controller
                            control={control}
                            name="arrive_date"
                            render={({ field }) => (
                              <>
                                <Datepicker
                                  useRange={false}
                                  asSingle={true}
                                  value={field.value}
                                  placeholder={"Select date"}
                                  displayFormat="DD.MM.YYYY"
                                  containerClassName="certificate-date-picker"
                                  inputClassName={`flex justify-between w-full gap-3 px-4 h-[44px]  py-2 items-center text-dropdown-text-data bg-dropdown-surface-background  border border-input-stroke-default text-base placeholder:text-input-text-placeholder placeholder:text-base placeholder:font-normal self-stretch  focus:shadow-[none] ring-0 focus:ring-1 focus:ring-offset-input-surface-background disabled:bg-input-surface-disabled disabled:border-dropdown-stroke-default-2 focus:border-input-stroke-active focus:ring-input-stroke-active rounded-lg z-[2]`}
                                  disabled={!canUpdateSubSupplier}
                                  primaryColor={"emerald"}
                                  toggleClassName="absolute text-icon-fill-input custom-toggle-icon right-0 h-full z-[4]"
                                  onChange={(value) => {
                                    field.onChange(setTimeInDateObject(value));
                                    if (
                                      getValues("dispatch_date") &&
                                      getValues("dispatch_date").startDate
                                    ) {
                                      if (
                                        new Date(getValues("dispatch_date").startDate) <
                                        new Date(value.startDate)
                                      ) {
                                        setError("arrive_date.startDate", {
                                          type: "custom",
                                          message: "Check for the dispatch date",
                                        });
                                      } else {
                                        clearErrors("arrive_date.startDate");
                                      }
                                    }
                                    if (selectedOrderDocumentsId) {
                                      setIsManuallyValuesSet(true);
                                      setIsInitialValuesSet(false);
                                    }
                                  }}
                                />
                              </>
                            )}
                          />
                        </div>
                        <ErrorMessage errors={errors?.arrive_date?.startDate?.message} />
                      </div>
                    </div>
                    <div className="col-span-2 lg:col-span-1">
                      <div className="grid gap-1.5">
                        <Label>Dispatched</Label>
                        <div className="relative">
                          <Controller
                            control={control}
                            name="dispatch_date"
                            render={({ field }) => (
                              <>
                                <Datepicker
                                  useRange={false}
                                  asSingle={true}
                                  value={field.value}
                                  placeholder={"Select date"}
                                  displayFormat="DD.MM.YYYY"
                                  containerClassName="certificate-date-picker"
                                  inputClassName={`flex justify-between w-full gap-3 px-4 h-[44px]  py-2 items-center text-dropdown-text-data bg-dropdown-surface-background  border border-input-stroke-default text-base placeholder:text-input-text-placeholder placeholder:text-base placeholder:font-normal self-stretch  focus:shadow-[none] ring-0 focus:ring-1 focus:ring-offset-input-surface-background disabled:bg-input-surface-disabled disabled:border-dropdown-stroke-default-2 focus:border-input-stroke-active focus:ring-input-stroke-active rounded-lg z-[2]`}
                                  disabled={!canUpdateSubSupplier}
                                  primaryColor={"emerald"}
                                  toggleClassName="absolute text-icon-fill-input custom-toggle-icon right-0 h-full z-[4]"
                                  onChange={(value) => {
                                    if (
                                      getValues("arrive_date") &&
                                      getValues("arrive_date").startDate
                                    ) {
                                      if (
                                        new Date(getValues("arrive_date").startDate) >
                                        new Date(value.startDate)
                                      ) {
                                        setError("dispatch_date.startDate", {
                                          type: "custom",
                                          message: "Check for the issue date",
                                        });
                                      } else {
                                        clearErrors("dispatch_date.startDate");
                                      }
                                    }

                                    field.onChange(setTimeInDateObject(value));
                                    if (selectedOrderDocumentsId) {
                                      setIsManuallyValuesSet(true);
                                      setIsInitialValuesSet(false);
                                    }
                                  }}
                                />
                              </>
                            )}
                          />
                        </div>
                        <ErrorMessage errors={errors?.dispatch_date?.startDate?.message} />
                      </div>
                    </div>
                    <div className="col-span-2">
                      <div className="grid gap-1.5">
                        {canUpdateSubSupplier && (
                          <div className="grid gap-1.5">
                            <div className="flex flex-col md:flex-row gap-3 items-center group justify-center md:items-start md:justify-start lg:gap-5">
                              <div
                                className={`w-full  relative  bg-white rounded-xl h-[126px] ring-1 ring-inset ring-border-divider-stroke group-hover:ring-2  group-hover:ring-global-primary cursor-pointer `}
                                onDragOver={handleDragEnter}
                                onDragEnter={handleDragEnter}
                                onDragLeave={handleDragLeave}
                                onDrop={handleDrop}
                              >
                                <div>
                                  <label htmlFor="dropzone-file" className="cursor-pointer">
                                    <div className="w-full flex-col px-3 md:px-6 py-[15.25px] justify-start items-center inline-flex">
                                      <div className="w-full flex-col justify-start items-center gap-3 flex">
                                        <ToasterIcon className="rounded-lg">
                                          <UploadImgIcon />
                                        </ToasterIcon>
                                        <div className="flex-col justify-start items-center gap-1 flex">
                                          <div className="justify-center items-start gap-1 md:inline-flex">
                                            <div className="text-global-title text-xs lg:text-sm font-semibold leading-tight text-center md:text-start">
                                              Upload verification document
                                            </div>
                                            <div className="text-global-paragraph text-xs lg:text-sm font-normal leading-tight">
                                              or drag and drop
                                            </div>
                                          </div>
                                          <div className="text-center text-global-paragraph text-[10px] lg:text-xs font-normal  leading-[18px]">
                                            PDF (max. 10mb)
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className={`h-full hidden group-hover:block`}>
                                      <PDFDragDropIcon className="absolute top-1 right-1 lg:top-auto lg:bottom-5 lg:right-6 " />
                                    </div>
                                  </label>

                                  <input
                                    id="dropzone-file"
                                    type="file"
                                    className="hidden"
                                    accept=".pdf"
                                    onChange={(e) => {
                                      onFileDropOrChange(e);
                                      if (selectedOrderDocumentsId) {
                                        setIsManuallyValuesSet(true);
                                        setIsInitialValuesSet(false);
                                      }
                                    }}
                                    multiple
                                  />
                                </div>
                              </div>
                            </div>
                            <ErrorMessage errors={errors?.cert_files?.message} />
                          </div>)}
                        {imagePreviews.length > 0 ? (
                          <div className="grid grid-cols-1 gap-4 mt-2.5">
                            {imagePreviews.map((preview, index) => (
                              <div className="relative col-span-1" key={index}>
                                <div
                                  className={`flex justify-between rounded-xl   px-4 py-[15px] gap-4 border ${!canUpdateSubSupplier
                                    ? "bg-input-surface-disabled border-dropdown-stroke-default-2"
                                    : "bg-dropdown-surface-background border-input-stroke-default "
                                    }`}
                                >
                                  <div className="flex justify-start gap-3">
                                    <PDFIcon
                                      className="w-10 h-10"
                                      fillColor={"var(--color-global-primary)"}
                                    />
                                    <div className="flex flex-col gap-0">
                                      <p className="text-sm font-medium leading-5 text-global-paragraph truncate">
                                        {showFileData[index]?.name &&
                                          truncateString(showFileData[index]?.name)}
                                      </p>
                                      <p className="text-global-paragraph text-sm font-normal leading-5">
                                        {`${(showFileData[index]?.size / (1024 * 1024)).toFixed(4)} MB`}
                                      </p>
                                    </div>
                                  </div>
                                  {
                                    canUpdateSubSupplier &&
                                    <Trash
                                      strokeColor={"var(--color-icon-outline-default-hover)"}
                                      onClick={() => handleCertFileDelete(index)}
                                      className="cursor-pointer"
                                    />
                                  }
                                </div>
                              </div>
                            ))}
                          </div>
                        ) : (
                          " "
                        )}
                      </div>
                    </div>
                  </div>

                  <Divider className={"mb-4 mt-8 lg:mb-6"} />
                  {canUpdateSubSupplier ? (
                    <div className="flex  justify-center items-center lg:justify-between gap-4 px-6">
                      <div className="flex flex-col lg:flex-row  w-full gap-3">
                        <Button
                          variant={"secondary"}
                          className={
                            "text-btn-text-default px-2.5 py-4 w-full  h-11 text-base font-semibold rounded-lg "
                          }
                          onClick={handleCancel}
                        >
                          Cancel
                        </Button>
                        {selectedOrderDocumentsId && (
                          <>
                            {!isInitialValuesSet && isManuallyValuesSet ? (
                              <Button
                                variant={"primary"}
                                type="submit"
                                isDisabled={isPending}
                                className={
                                  "text-btn-text-default px-2.5 py-4 w-full gap-1.5  h-11 text-base font-semibold rounded-lg text-white "
                                }
                              >
                                <span>Save</span>
                                <LinkIcon width={20} height={20} />
                              </Button>
                            ) : (
                              <Button
                                variant={"primary"}
                                type="button"
                                onClick={() => handleOnUnLink(selectedOrderDocumentsId)}
                                className={
                                  "text-btn-text-default px-2.5 py-4 w-full gap-1.5  h-11 text-base font-semibold rounded-lg text-white "
                                }
                              >
                                <span>Unlink</span>
                                <LinkIcon width={20} height={20} />
                              </Button>
                            )}
                          </>
                        )}
                        {!selectedOrderDocumentsId && (
                          <Button
                            variant={"primary"}
                            type="submit"
                            isDisabled={isPending}
                            className={
                              "text-btn-text-default px-2.5 py-4 w-full gap-1.5  h-11 text-base font-semibold rounded-lg text-white "
                            }
                          >
                            <span>Link</span>
                            <LinkIcon width={20} height={20} />
                          </Button>
                        )}
                      </div>
                    </div>
                  ) : (
                    <div className="flex  justify-center items-center lg:justify-between gap-4 px-6">
                      <div className="flex flex-col lg:flex-row  w-full gap-3">
                        <Button
                          variant={"secondary"}
                          className={
                            "text-btn-text-default px-2.5 py-4 w-full  h-11 text-base font-semibold rounded-lg "
                          }
                          onClick={handleCancel}
                        >
                          Cancel
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddLocationForm;
