import { MarkerIcon } from "assests";
import React, { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { googleMapStyles } from "utils/constant";
import ReactDOMServer from "react-dom/server";
import MapBusinessCard from "./MapBusinessCard";
import { useGoogleMaps } from "contexts/GoogleMapsContext";

const MapView = ({ details, locationFrom = null }) => {
  const ref = useRef();
  const [locations, setLocations] = useState([]);
  const { isLoaded } = useGoogleMaps();

  const geocode = (address) => {
    return new Promise((resolve, reject) => {
      const geocoder = new window.google.maps.Geocoder();

      geocoder.geocode({ address: address }, (results, status) => {
        if (status === "OK") {
          const { lat, lng } = results[0].geometry.location;
          resolve({ lat: lat(), lng: lng() });
        } else {
          reject(`Geocode was not successful for the following reason: ${status}`);
        }
      });
    });
  };

  useEffect(() => {
    if (details) {
      const fetchGeocodeData = async () => {
        if (ref.current && typeof details === "object" && !locationFrom) {
          try {
            const geoCodeData = await geocode(details.address);
            setLocations((prev) => [...prev, { ...details, ...geoCodeData }]);
          } catch (error) {
            toast.error("Failed to fetch geocode data");
          }
        }

        if (ref.current && Array.isArray(details) && locationFrom === "orders") {
          const newLocations = await Promise.all(
            details.map(async (item) => {
              try {
                const geoCodeData = await geocode(item.address);
                if (geoCodeData) {
                  return { ...item, ...geoCodeData };
                } else {
                  return null;
                }
              } catch (error) {
                console.error(error);
                return null;
              }
            }),
          );
          setLocations((prev) => [...newLocations.filter((loc) => loc !== null)]);
        }
      };
      fetchGeocodeData();
    }
  }, [details]);

  useEffect(() => {
    if (ref && locations && typeof window !== "undefined" && window.google) {
      const mapInstance = new window.google.maps.Map(ref.current, {
        center: { lat: 0, lng: 0 },
        zoom: 1.8,
        scrollwheel: true,
        zoomControl: false,
        streetViewControl: false,
        disableDefaultUI: true,
        // styles: googleMapStyles,
        maxZoom: 15,
        minZoom: 1.5,
      });

      const lineSymbol = {
        path: "M 0,-1 0,1",
        strokeOpacity: 0.7,
        scale: 3,
      };

      new window.google.maps.Polyline({
        path: locations,
        geodesic: true,
        strokeColor: "#101828",
        strokeOpacity: 0,
        icons: [
          {
            icon: lineSymbol,
            offset: "0",
            repeat: "20px",
          },
        ],
        map: mapInstance,
      });

      const infoWindowInstance = new window.google.maps.InfoWindow();
      locations.length > 0 &&
        locations?.forEach((locationData) => {
          const marker = new window.google.maps.Marker({
            position: { lat: locationData.lat, lng: locationData.lng },
            map: mapInstance,
            icon: {
              url: MarkerIcon,
              scaledSize: new window.google.maps.Size(50, 50),
            },
          });

          // marker.addListener("click", () => {
          //   const contentString = ReactDOMServer.renderToString(
          //     <MapBusinessCard details={locationData} locationFrom={locationFrom} />,
          //   );
          //   infoWindowInstance.setContent(contentString);
          //   infoWindowInstance.open(mapInstance, marker);
          // });
        });
    }
  }, [ref, locations]);

  if (!isLoaded) {
    return <div className="w-full h-full bg-gray-100 animate-pulse" />;
  }

  return <div ref={ref} className="w-full h-full rounded-xl border border-[#E9EAEB] shadow-[0px_1px_2px_0px_rgba(10,13,18,0.05)]" />;
};

export default MapView;
