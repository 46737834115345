import { Button } from "components/UI/Button";

const DeleteWorkspace = ({ onClick, title, isDisabled }) => {
  return (
    <Button
      type="button"
      variant={"action"}
      className="flex items-center justify-start w-full  gap-2 px-2.5 py-[9px] rounded  hover:bg-transparent"
      onClick={onClick}
      isDisabled={isDisabled}
    >
      <span className="">{title || "Delete Workspace"}</span>
    </Button>
  );
};

export default DeleteWorkspace;
