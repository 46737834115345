import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";

import SubNavbar from "layout/SubNavbar";

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { DottedIcon, LogoutIcon, PlanIcon, Shock, TickIcon, TrashUpdateIcon } from "assests";
import DashboardOwnerCard from "components/Dashboard/DashboardOwnerCard";
import PendingInvitedCard from "components/Dashboard/PendingInvitedCard";
import WelcomeCard from "components/PlansPage/WelcomeCard";
import TopNavbar from "components/TopHeader/TopNavbar";
import CommonDiscardButton from "components/UI/CommonDiscardButton";
import CommonDiscardButtonUpdated from "components/UI/CommonDiscardButtonUpdated";
import Modal from "components/UI/Modal";
import useCustomToaster from "hooks/useCustomToaster";
import useUser from "hooks/useUser";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { getDashboardWorkspaces } from "services/dashboard.services";
import { acceptStatus } from "services/user.services";
import {
  cancelInvitation,
  deleteWorkspace,
  getPendingInvitationList,
  getPendingInvitedList,
  leaveWorkspace,
} from "services/workspace.services";
import {
  setActionableInvitationId,
  setActionableItemData,
  setPendingInvitationListData,
  setPendingInvitedListData,
  setWorkspacesData,
  toggleDeleteWorkspaceModal,
  toggleIsInvitationCreateModalOpen,
  toggleLeaveWorkspaceModal,
  toggleShowAcceptInviteModal,
} from "store/accountDashboardSlice";
import { setRefetchBillingData } from "store/billingSlice";
import InvitationCreateForm from "./DashboardWorkspaces/InvitationCreateForm";
import InviteCard from "./DashboardWorkspaces/InviteCard";

const DashboardMain = () => {
  const dispatch = useDispatch();
  const { currentWorkspace, user } = useSelector((state) => state.authenticationSlice);
  const { currentPlan, workspacePlan, refetchBillingData } = useSelector(
    (state) => state.billingSlice,
  );
  const { userMutation } = useUser();
  const { token } = useSelector((state) => state.authenticationSlice);
  const [showTutorial, setShowTutorial] = useState(false);
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const [showUpgradeLimitModal, setShowUpgradeLimitModal] = useState(false);
  const { showCustomToast } = useCustomToaster();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const {
    workspacesData,
    showAcceptInviteModal,
    isInvitationCreateModalOpen,
    pendingInvitationData,
    pendingInvitedData,
    leaveWorkspaceModal,
    deleteWorkspaceModal,
    actionableInvitationId,
    actionableItemData,
  } = useSelector((state) => state.accountDashboardSlice);

  const closeModal = () => {
    if (leaveWorkspaceModal) {
      dispatch(toggleLeaveWorkspaceModal());
    }
    if (deleteWorkspaceModal) {
      dispatch(toggleDeleteWorkspaceModal());
    }
    if (showAcceptInviteModal) {
      dispatch(toggleShowAcceptInviteModal());
    }
    if (isInvitationCreateModalOpen) {
      dispatch(toggleIsInvitationCreateModalOpen());
    }
    if (showUpgradeModal) {
      setShowUpgradeModal(false);
    }
    if (showUpgradeLimitModal) {
      setShowUpgradeLimitModal(false);
    }
    if (actionableInvitationId) {
      dispatch(setActionableInvitationId());
    }
    if (actionableItemData) {
      dispatch(setActionableItemData());
    }
  };

  useEffect(() => {
    const isFirstPayment = Cookies.get("showTutorial") || false;
    const parseIsFirstPayment = JSON.parse(isFirstPayment);
    if (parseIsFirstPayment) {
      setShowTutorial(true);
    }
  }, []);
  const handleCloseTutorial = () => {
    Cookies.set("showTutorial", "false", { expires: 365 });
    setShowTutorial(false);
  };

  const {
    data: pendingInvitationListDataQuery,
    isLoading,
    refetch: refetchPendingInvitationList,
  } = useQuery({
    queryKey: ["pendingInvitationList", "getPendingInvitationList", currentWorkspace],
    queryFn: getPendingInvitationList,
    enabled:
      !!currentWorkspace &&
      !currentWorkspace?.role_name?.includes("editor") &&
      !currentWorkspace?.role_name?.includes("viewer"),
  });

  const {
    data: pendingInvitedListDataQuery,
    isLoading: isLoadingPendingInvitedList,
    refetch: refetchPendingInvitedList,
  } = useQuery({
    queryKey: ["pendingInvitedList", "getPendingInvitedList", currentWorkspace],
    queryFn: getPendingInvitedList,
    enabled:
      !!currentWorkspace &&
      !currentWorkspace?.role_name?.includes("editor") &&
      !currentWorkspace?.role_name?.includes("viewer"),
  });

  const {
    data: workspaceDashboardDataquery,
    isLoading: isLoadingWorkspaceDashboardData,
    refetch: refetchWorkspaceDashboardData,
  } = useQuery({
    queryKey: ["workspaces", "getWorkspaces", currentWorkspace],
    queryFn: () =>
      getDashboardWorkspaces({ workspace_id: parseInt(currentWorkspace?.workspace_id) }),
    enabled: !!currentWorkspace,
  });

  const handleLeaveWorkspace = useMutation({
    mutationKey: ["leaveWorkspace", "delete"],
    mutationFn: (data) => leaveWorkspace(data),
    onMutate: () => {
      showCustomToast(
        {
          title: "Leaving...",
        },
        "leaveWorkspace",
      );
    },
    onSuccess: (res) => {
      toast.dismiss("leaveWorkspace");
      showCustomToast({
        title: res.data.message || "Left workspace successfully",
      });
      refetchDatas();
      closeModal();
      if (!refetchBillingData) {
        dispatch(setRefetchBillingData(true));
      }
      queryClient.invalidateQueries(["leaveWorkspace", "get", "delete"]);
    },
    onError: (error) => {
      toast.dismiss("leaveWorkspace");
      showCustomToast({
        title: error.response.data.message || error.message,
      });
    },
    onSettled: () => {
      toast.dismiss("leaveWorkspace");
    },
  });

  const handleDeleteWorkspace = useMutation({
    mutationKey: ["leaveWorkspace", "delete"],
    mutationFn: (data) => deleteWorkspace(data),
    onMutate: () => {
      showCustomToast(
        {
          title: "Deleting...",
        },
        "leaveWorkspace",
      );
    },
    onSuccess: (res) => {
      toast.dismiss("leaveWorkspace");
      showCustomToast({
        title: res.data.message || "Workspace deleted successfully",
      });
      refetchDatas();
      closeModal();
      if (!refetchBillingData) {
        dispatch(setRefetchBillingData(true));
      }
      queryClient.invalidateQueries(["leaveWorkspace", "get", "delete"]);
    },
    onError: (error) => {
      toast.dismiss("leaveWorkspace");
      showCustomToast({
        title: error.response.data.message || error.message,
      });
    },
    onSettled: () => {
      toast.dismiss("leaveWorkspace");
    },
  });

  const acceptMutation = useMutation({
    mutationKey: ["user", "acceptinvitation"],
    mutationFn: ({ hash }) => {
      return acceptStatus({ hash });
    },
    onMutate: () => {
      showCustomToast(
        {
          title: "Accepting invitation...",
        },
        "acceptinginvitation",
      );
    },
    onSuccess: (res) => {
      toast.dismiss("acceptinginvitation");
      showCustomToast({
        title: "Invitation has been accepted",
      });
      closeModal();
      refetchDatas();
      queryClient.invalidateQueries("protectedResource");
    },
    onError: (error) => {
      toast.dismiss("acceptinginvitation");
      const errormessage = error.response?.data?.message || error.message || "Something went wrong";
      showCustomToast({
        title: errormessage,
      });
    },
  });

  const handleLeave = (actionType) => {
    switch (actionType) {
      case "delete":
        handleDeleteWorkspace.mutate({ invitation_id: parseInt(actionableInvitationId) });
        break;
      case "leave":
        handleLeaveWorkspace.mutate({
          workspace_id: parseInt(actionableInvitationId),
        });
        break;
      default:
        break;
    }
  };
  const handleAcceptInvite = (item) => {
    acceptMutation.mutate({ hash: item?.hash });
  };

  useEffect(() => {
    if (
      workspaceDashboardDataquery &&
      workspaceDashboardDataquery.status === 200 &&
      !isLoadingWorkspaceDashboardData
    ) {
      const workspaceDashboardAllData = workspaceDashboardDataquery?.data?.workspaces?.data;
      if (user && user.workspace_type === "agent") {
        const filteredWorkspaces = workspaceDashboardAllData.filter(
          (workspace) => workspace.purchased_package !== "supplier",
        );
        dispatch(
          setWorkspacesData({
            workspacesData: filteredWorkspaces,
            append: false,
          }),
        );
      } else {
        dispatch(
          setWorkspacesData({
            workspacesData: workspaceDashboardDataquery?.data?.workspaces?.data,
            append: false,
          }),
        );
      }
      userMutation.mutate();
      if (!refetchBillingData) {
        dispatch(setRefetchBillingData(true));
      }
    }
  }, [workspaceDashboardDataquery, isLoadingWorkspaceDashboardData]);

  useEffect(() => {
    if (
      pendingInvitationListDataQuery &&
      pendingInvitationListDataQuery.status === 200 &&
      !isLoading
    ) {
      const details = pendingInvitationListDataQuery.data.data.data;
      if (user && user.workspace_type === "agent") {
        const filteredWorkspaces = details.filter(
          (item) => item.workspace.purchased_package !== "supplier",
        );
        dispatch(setPendingInvitationListData(filteredWorkspaces));
      } else {
        dispatch(setPendingInvitationListData(details));
      }
    }
  }, [pendingInvitationListDataQuery, isLoading]);

  useEffect(() => {
    if (
      pendingInvitedListDataQuery &&
      pendingInvitedListDataQuery.status === 200 &&
      !isLoadingPendingInvitedList
    ) {
      const details = pendingInvitedListDataQuery.data.data.data;
      if (user && user.workspace_type === "agent") {
        const filteredWorkspaces = details.filter((item) => !item.role.includes("supplier"));
        dispatch(setPendingInvitedListData(filteredWorkspaces));
      } else {
        dispatch(setPendingInvitedListData(details));
      }
    }
  }, [pendingInvitedListDataQuery, isLoadingPendingInvitedList]);

  const refetchDatas = () => {
    refetchPendingInvitationList();
    refetchWorkspaceDashboardData();
    refetchPendingInvitedList();
  };

  const declineInvitationMutation = useMutation({
    mutationKey: ["user", "declineInvitation"],
    mutationFn: (data) => cancelInvitation(data),
    onMutate: () => {
      showCustomToast(
        {
          title: "Declining invitation...",
        },
        "decliningvitation",
      );
    },
    onSuccess: (res) => {
      toast.dismiss("decliningvitation");
      showCustomToast({
        title: "Invitation has been declined",
      });
      closeModal();
      refetchDatas();
      queryClient.invalidateQueries("protectedResource");
    },
    onError: (error) => {
      toast.dismiss("decliningvitation");
      const errormessage = error.response?.data?.message || error.message || "Something went wrong";
      showCustomToast({
        title: errormessage,
      });
    },
  });

  const handleDeclineInvite = (item) => {
    declineInvitationMutation.mutate({ invitation_id: item?.id });
  };

  if (!token || !localStorage.getItem("auth")) {
    return <Navigate to="/signin" replace />;
  }

  return (
    <>
      {showTutorial && <WelcomeCard discardModal={handleCloseTutorial} />}
      <TopNavbar isFromDashboard={false}>
        <SubNavbar title="My Workspaces" subtitle="Manage and access your workspaces here." />
      </TopNavbar>
      <div className="px-4 md:px-8 pt-[64px] md:pt-[116px]">
        <div className="block my-4 md:hidden md:my-0">
          <SubNavbar title="My Workspaces" subtitle="Manage and access your workspaces here." />
        </div>
        <div className="bg-global-divider-medium h-[1px] mb-6  hidden md:block" />
        <div className="flex flex-col flex-grow w-full h-full gap-6 md:gap-0">
          <div className="flex flex-col sm:flex-row flex-wrap items-center gap-4 md:gap-6  ">
            {currentWorkspace && currentWorkspace.role_name.includes("admin") && (
              <div className="flex-shrink-0 w-full sm:w-auto">
                <InviteCard {...{ setShowUpgradeModal, setShowUpgradeLimitModal }} />
              </div>
            )}
            {workspacesData &&
              workspacesData?.length > 0 &&
              workspacesData?.map((item, index) => (
                <div className="flex-shrink-0 w-full sm:w-auto">
                  <DashboardOwnerCard key={index} item={item} index={index} />
                </div>
              ))}
            {pendingInvitationData &&
              pendingInvitationData?.length > 0 &&
              pendingInvitationData?.map((item, index) => (
                <div className="flex-shrink-0 w-full sm:w-auto">
                  <DashboardOwnerCard key={index} item={item} index={index} />
                </div>
              ))}

            {pendingInvitedData &&
              pendingInvitedData?.length > 0 &&
              pendingInvitedData?.map((item, index) => (
                <div className="flex-shrink-0 w-full sm:w-auto">
                  <PendingInvitedCard
                    key={index}
                    item={item}
                    index={index}
                    refetchDatas={refetchDatas}
                  />
                </div>
              ))}
          </div>
        </div>
        <Modal open={showAcceptInviteModal} close={closeModal}>
          <CommonDiscardButton
            closeModal={closeModal}
            title={`${actionableItemData?.inviter?.firstName ? actionableItemData?.inviter?.firstName : actionableItemData?.inviter?.name?.split(" ")[0]} has sent you an invite`}
            subText1={`You have been invited by ${actionableItemData?.workspace?.name} to join their workspace as an ${actionableItemData?.workspace?.purchased_package}. Click "Accept Invite" to join and start collaborating.`}
            className="max-w-[600px]"
            IconComponent={DottedIcon}
            confirmButtonText="Accept Invite"
            confirmButtonVariant="primary"
            inviteBtnTrue={true}
            inviterInfo={actionableItemData?.inviter}
            onConfirmClick={() => handleAcceptInvite(actionableItemData)}
            handleDeclineOnClick={() => handleDeclineInvite(actionableItemData)}
            isPending={acceptMutation.isPending || declineInvitationMutation.isPending}
            icon={<TickIcon strokeColor={"var(--color-icon-fill-secondary-button)"} />}
          />
        </Modal>

        <Modal open={leaveWorkspaceModal} close={closeModal}>
          <CommonDiscardButtonUpdated
            closeModal={closeModal}
            title="Leave workspace"
            subText1="Are you sure you want to leave selected workspace? This action cannot be undone."
            className="max-w-[600px]"
            IconComponent={DottedIcon}
            confirmButtonText="Confirm"
            confirmButtonVariant="primary"
            onConfirmClick={() => handleLeave("leave")}
            icon={<LogoutIcon />}
          />
        </Modal>
        <Modal open={deleteWorkspaceModal} close={closeModal}>
          <CommonDiscardButtonUpdated
            closeModal={closeModal}
            title="Remove workspace"
            subText1="Are you sure you want to remove selected workspace? This action cannot be undone."
            className="max-w-[600px]"
            IconComponent={DottedIcon}
            confirmButtonText="Remove"
            confirmButtonVariant="warning"
            onConfirmClick={() => handleLeave("delete")}
            icon={<TrashUpdateIcon strokeColor={"var(--color-icon-fill-secondary-button)"} />}
          />
        </Modal>
        <Modal open={isInvitationCreateModalOpen} close={closeModal}>
          <InvitationCreateForm closeModal={closeModal} invitationListRefetch={refetchDatas} />
        </Modal>
        <Modal open={showUpgradeModal} close={closeModal}>
          <CommonDiscardButtonUpdated
            closeModal={closeModal}
            title="Upgrade plan"
            subText1={`You are currently on a free plan. Upgrade to a paid plan to start creating your own partner workspaces.`}
            className="max-w-[600px]"
            IconComponent={PlanIcon}
            confirmButtonText={"Ugrade Plan"}
            confirmButtonVariant="primary"
            onConfirmClick={() =>
              navigate(`/workspace/${currentWorkspace?.user_workspace_id}/setting?selectedTab=plan`)
            }
            icon={<Shock strokeColor={"var(--color-icon-fill-secondary-button)"} />}
          />
        </Modal>
        <Modal open={showUpgradeLimitModal} close={closeModal}>
          <CommonDiscardButtonUpdated
            closeModal={closeModal}
            title="Upgrade Required"
            subText1={`To invite more business partners to your team, you need to upgrade to ${currentPlan?.plan?.name?.toLowerCase() === "premium" ? "an Enterprise" : "a Premium"} plan. Unlock the ability to expand your team and access advanced features by upgrading now.`}
            className="max-w-[600px]"
            IconComponent={PlanIcon}
            confirmButtonText={
              currentPlan?.plan?.name?.toLowerCase() === "premium" ? "Contact Sales" : "Upgrade Now"
            }
            icon={
              <Shock
                width={20}
                height={20}
                strokeColor={"var(--color-icon-fill-secondary-button)"}
              />
            }
            confirmButtonVariant="primary"
            onConfirmClick={() =>
              currentPlan?.plan?.name?.toLowerCase() === "premium"
                ? (window.location = "mailto:sales@fibervue.com")
                : navigate(
                    `/workspace/${currentWorkspace?.user_workspace_id}/setting?selectedTab=plan`,
                  )
            }
          />
        </Modal>
      </div>
    </>
  );
};

export default DashboardMain;
