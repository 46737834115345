const { object, string, union, boolean } = require("zod");

export const basePaymentSchema = object({
  cardHolderName: string().min(1, "Cardholder name is required"),
  // country: object(
  //   {
  //     label: string(),
  //     value: string(),
  //   },
  //   {
  //     required_error: "Country is required",
  //     invalid_type_error: "Country is required",
  //   },
  // ).refine(({ value }) => value !== "", "Country is required"),
  // city: union([
  //   object(
  //     {
  //       label: string(),
  //       value: string(),
  //     },
  //     {
  //       required_error: "City is required",
  //       invalid_type_error: "City is required",
  //     },
  //   ),
  //   string().default(""),
  // ])
  //   .nullable()
  //   .optional(),
  // taxRegistrationNumber: string().min(1, "Tax number is required"),
});

export const paymentSchema = basePaymentSchema.extend({
  planId: string().min(1, "Plan ID is required"),
  auto_renew: boolean().default(true),
  acceptTerms: boolean().refine((val) => val === true, {
    message: "Required",
  }),
  workspace_id: string().min(1, "Workspace ID is required"),
});
