import { Cross } from "assests";
import { Button } from "components/UI/Button";
import UpgradeComponent from "components/UI/UpgradeComponent";
import Divider from "./Divider";

const CommonDiscardButtonUpdated = ({
  title,
  subText1,
  subText2,
  IconComponent,
  confirmButtonText,
  onConfirmClick,
  closeModal,
  confirmButtonClassName,
  className,
  confirmButtonVariant,
  boldText,
  inviteBtnTrue,
  inviterInfo,
  icon,
  closeModalText
}) => {
  const renderSubtitle = () => {
    if (!boldText) {
      return <span>{subText1}</span>;
    }
    const parts = subText1.split(boldText);
    return (
      <>
        {parts[0]}
        <span className="font-semibold">{boldText}</span>
        {parts[1]}
      </>
    );
  };
  return (
    <div
      className={` bg-white max-h-[600px] rounded-xl relative z-[2]  ${className}`}
    >
      {closeModal && (
        <div className="absolute right-[22px] top-[22px] cursor-pointer" onClick={closeModal}>
          <Cross fillColor={"var(--color-icon-default-5)"} height={24} width={24} />
        </div>
      )}
      <div className={`flex p-6 ${inviteBtnTrue ? "flex-col" : "flex-row"}`}>
        {IconComponent && (
          <div className="mr-4">
            <UpgradeComponent>
              <IconComponent fillColor="var(--color-icon-default-2)" strokeColor="var(--color-icon-default-2)" width={24} height={24} />
            </UpgradeComponent>
          </div>
        )}
        <div className={`flex flex-col gap-1 ${inviteBtnTrue && "pt-4"}`}>
          <h4 className="text-sm lg:text-lg font-semibold text-text-primary">{title}</h4>
          <p className="text-xs lg:text-sm  text-global-paragraph ">
            {renderSubtitle()}
            {subText2 && (
              <>
                <br />
                {subText2}
              </>
            )}
          </p>
          {inviteBtnTrue && (
            <div className="pt-2">
              <div className="flex items-center gap-3">
                <div className="flex justify-center items-center  p-0 ring-1 ring-global-divider-soft rounded-full border-none text-btn-text-default bg-transparent">
                  {inviterInfo && inviterInfo?.image ? (
                    <img
                      src={`${inviterInfo?.image}?t=${new Date().getTime()}`}
                      alt={`Profile`}
                      className="rounded-full w-10 h-10"
                    />
                  ) : (
                    <div className="flex bg-global-surface justify-center items-center text-xl font-semibold text-text-quaternary w-10 h-10  p-0 ring-1 ring-global-divider-soft  rounded-full border-none">
                      {inviterInfo && inviterInfo?.name?.substring(0, 2)}
                    </div>
                  )}
                </div>

                <div>
                  <p className="text-sm font-semibold text-global-title-2 truncate">
                    {inviterInfo?.name}
                  </p>
                  <p className="text-xs lg:text-sm  text-global-paragraph truncate">
                    {inviterInfo?.email}
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <Divider className="mt-5 lg:mt-[27px]" />
      <div className="flex justify-center p-6 lg:justify-end flex-col lg:flex-row gap-3 ">
        {closeModal && (
          <Button
            variant="secondary"
            className="px-4 py-2.5 w-full  h-11 text-sm md:text-base font-semibold"
            onClick={closeModal}
          >
            {closeModalText ? closeModalText : "Cancel"}
          </Button>
        )}
        {
          confirmButtonText &&
          (<Button
            variant={confirmButtonVariant}
            className={`px-4 py-2.5 w-full gap-1.5  h-11 custom-primary-shadow text-white text-sm md:text-base font-semibold whitespace-nowrap ${confirmButtonClassName}`}
            onClick={onConfirmClick}
          >
            {confirmButtonText} {icon && icon}
          </Button>
          )
        }
      </div>
    </div>
  );
};

export default CommonDiscardButtonUpdated;
