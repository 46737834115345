import { useEffect, useState } from "react";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import { getCssVariable } from "utils/helper";
import { calculatePadding } from "./CircleProgressBar";

const CircleProgressReduceBar = ({
  currentProgress,
  size,
  strokeColorVariable,
  title,
  subText,
}) => {
  const [strokeColor, setStrokeColor] = useState("");

  useEffect(() => {
    if (strokeColorVariable) {
      const color = getCssVariable(strokeColorVariable);
      setStrokeColor(color);
    }
  }, [strokeColorVariable]);

  const calculatedSize = size ? size * 4 : 160;
  const paddingSize = calculatePadding(calculatedSize);

  return (
    <div
      className="relative"
      style={{ height: `${calculatedSize}px`, width: `${calculatedSize}px` }}
    >
      <div className="absolute z-10">
        <CircularProgressbar
          value={currentProgress}
          styles={buildStyles({
            pathColor: strokeColor,
            transition: "stroke-dashoffset 0.5s ease 0s",
            trailColor: "#F2F4F7",
          })}
        />
      </div>
      <div
        className="w-full h-full rounded-full bg-transparent"
        style={{ padding: `${paddingSize}px` }}
      >
        <div className="flex flex-col gap-0 items-center justify-center w-full h-full rounded-full bg-transparent pt-1">
          <div className="text-xs text-center font-medium text-input-text-label leading-[18px]">
            {title}
          </div>
          <div className="text-lg font-semibold text-table-text-data-dark text-center leading-7">
            {subText}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CircleProgressReduceBar;
