import React from "react";

const CardAmount = React.forwardRef(
  (
    { width = "26", height = "26", viewBox = "0 0 26 26", fill = "none", strokeColor = "white", className, ...props },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M23.8334 10.8333H2.16669M11.9167 15.1666H6.50002M2.16669 8.88329L2.16669 17.1166C2.16669 18.3301 2.16669 18.9368 2.40284 19.4003C2.61057 19.808 2.94202 20.1394 3.34971 20.3471C3.81318 20.5833 4.41991 20.5833 5.63335 20.5833L20.3667 20.5833C21.5801 20.5833 22.1869 20.5833 22.6503 20.3471C23.058 20.1394 23.3895 19.808 23.5972 19.4003C23.8334 18.9368 23.8334 18.3301 23.8334 17.1166V8.88329C23.8334 7.66985 23.8334 7.06312 23.5972 6.59965C23.3895 6.19196 23.058 5.86051 22.6503 5.65278C22.1869 5.41663 21.5801 5.41663 20.3667 5.41663L5.63335 5.41663C4.41991 5.41663 3.81318 5.41663 3.34971 5.65278C2.94202 5.8605 2.61057 6.19196 2.40284 6.59965C2.16669 7.06312 2.16669 7.66985 2.16669 8.88329Z"
          stroke={strokeColor}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  },
);

CardAmount.displayName = "CardAmount";

export default CardAmount;
