import { ArrowLeftIcon } from 'assests';
import React from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';


const BackToDashboard = ({ className }) => {
    const location = useLocation();
    return (
        <div className={`py-4 md:py-0 ${className}`}>
            <Link to={`/`}>
                <div className="flex justify-start items-center gap-1.5">
                    <ArrowLeftIcon />
                    <p className="text-sm font-semibold text-btn-text-link">Back to Dashboard</p>
                </div>
            </Link>
        </div>
    );
};

export default BackToDashboard;