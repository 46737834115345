import { IconButton, Menu } from "@mui/material";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { InvitePartnerIcon, MoreOptions, PlanIcon, Shock, TrashUpdateIcon } from "assests";
import EmptyTable from "components/Card/EmptyTable";
import DeleteButton from "components/MoreAction/DeleteButton";
import EditButton from "components/MoreAction/EditButton";
import MoreActionOptionUpdated from "components/MoreAction/MoreActionOptionUpdated";
import SearchCreatePanelUpdate from "components/SearchCreatePanelUpdate";
import { Button } from "components/UI/Button";
import CommonDiscardButton from "components/UI/CommonDiscardButton";
import CommonDiscardButtonUpdated from "components/UI/CommonDiscardButtonUpdated";
import { LineProgressBar } from "components/UI/LineProgressBar";
import LoadingComponent from "components/UI/LoadingComponent";
import Modal from "components/UI/Modal";
import SortIcons from "components/UI/SortIcons";
import TertiaryPagination from "components/UI/TertiaryPagination";
import useCustomToaster from "hooks/useCustomToaster";
import usePermission from "hooks/usePermission";
import queryString from "query-string";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  bulkDeleteSubSuppliers,
  deleteSubSupplier,
  downloadSubSuppliersAsCSV,
  getSubSuppliers,
} from "services/sub_suppliers.services";
import { setRefetchBillingData } from "store/billingSlice";
import {
  setActionableSubSupplierItem,
  setAllSubSupplierData,
  setSubSupplierDetails,
  setSubSupplierList,
  toggleActionableSubSupplierId,
  toggleDuplicateAction,
  toggleIsSubSupplierCreateModalOpen,
  toggleIsSubSupplierEditable,
  toggleSubSupplierBulkDeleteModal,
  toggleSubSupplierDeleteModal,
} from "store/subSupplierSlice";
import { getInitials, removeQueryParam, truncateString } from "utils/helper";
import AddSubSupplier from "./AddSubSupplier";
import SubSupplierFilter from "./SubSupplierFilter";

const SubSupplier = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [searchKey, setSearchKey] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const { workspaceId } = useParams();
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [selectedOrders, setSelectedOrders] = useState([]);
  const parsed = queryString.parse(location.search);
  const [partnerName, setPartnerName] = useState("");
  const [activeFilters, setActiveFilters] = useState({});
  const [filteredData, setFilteredData] = useState({});
  const [subSupplierOptions, setSubSupplierOptions] = useState([]);
  // const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const { showCustomToast } = useCustomToaster();
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleSizeChange = (newSize) => {
    setSize(newSize);
    setPage(1);
  };
  const {
    actionableSubSupplierId,
    isSubSupplierEditable,
    isDuplicateAction,
    subSupplierDetails,
    subSupplierList,
    showDiscardModal,
    deleteModal,
    allSubSupplierData,
    actionableSubSupplierItem,
    isSubSupplierCreateModalOpen,
    bulkDeleteModal,
  } = useSelector((state) => state.subSupplierSlice);
  const { currentWorkspace, user, subscription, subscription_status } = useSelector(
    (state) => state.authenticationSlice,
  );
  const { refetchBillingData, workspacePlan } = useSelector((state) => state.billingSlice);
  const {
    data: subSuppliersQueryData,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: [
      "subSuppliers",
      "getSubSuppliers",
      filteredData,
      page,
      size,
      searchKey,
      orderBy,
      workspaceId,
    ],
    queryFn: () =>
      getSubSuppliers({
        page,
        per_page: size,
        search: searchKey,
        sort: orderBy,
        workspace_id: parseInt(workspaceId),
        ...filteredData,
      }),
    enabled: !!page || !!size || !!workspaceId,
  });
  const {
    data: articlesNopaginatedData,
    isLoading: isArticlesNopaginatedData,
    refetch: refetchNopaginatedData,
  } = useQuery({
    queryKey: ["subsuppliernopaginate", "getData", workspaceId],
    queryFn: () =>
      getSubSuppliers({
        workspace_id: parseInt(workspaceId),
        pagination: "no",
      }),
    enabled: !!workspaceId,
  });
  useEffect(() => {
    if (!isArticlesNopaginatedData && articlesNopaginatedData) {
      if (articlesNopaginatedData?.data?.sub_supplier) {
        setSubSupplierOptions(articlesNopaginatedData?.data?.sub_supplier);
      }
    }
  }, [articlesNopaginatedData, isArticlesNopaginatedData]);

  const refetchDatas = () => {
    refetch();
    refetchNopaginatedData();
  };
  const handleViewDetailsClick = (id, type = "supplier") => {
    navigate(`/workspace/${workspaceId}/supplier-details/${id}/${type}`, { replace: true });
  };
  const openCreateModal = () => {
    dispatch(toggleIsSubSupplierCreateModalOpen());
    setSelectedOrders([]);
    if (actionableSubSupplierId) {
      dispatch(toggleActionableSubSupplierId());
    }
    if (!isSubSupplierEditable) {
      dispatch(toggleIsSubSupplierEditable());
    }
  };

  const closeModal = () => {
    if (isSubSupplierCreateModalOpen) {
      dispatch(toggleIsSubSupplierCreateModalOpen());
    }
    if (actionableSubSupplierId) {
      dispatch(toggleActionableSubSupplierId());
    }
    if (subSupplierDetails) {
      dispatch(setSubSupplierDetails());
    }
    if (isSubSupplierEditable) {
      dispatch(toggleIsSubSupplierEditable());
    }

    if (isDuplicateAction) {
      dispatch(toggleDuplicateAction());
    }
    if (deleteModal) {
      dispatch(toggleSubSupplierDeleteModal());
    }
    if (actionableSubSupplierItem) {
      dispatch(setActionableSubSupplierItem());
    }
    if (bulkDeleteModal) {
      dispatch(toggleSubSupplierBulkDeleteModal());
    }
    // if (showUpgradeModal) {
    //   setShowUpgradeModal(false);
    // }
    removeQueryParam(["fromGlobalSearch", "subsuppliers_id", "selectedTab"], location, navigate);
  };

  const handleActionClick = (articleId) => {
    // if (actionableSubSupplierId === articleId) {
    //   return dispatch(toggleActionableSubSupplierId());
    // }
    dispatch(toggleActionableSubSupplierId(articleId));
  };
  const handleCreateAction = () => {
    // if (
    //   (currentWorkspace &&
    //     currentWorkspace.is_owner &&
    //     (!subscription ||
    //       (subscription && (!subscription_status || subscription_status === "canceled")))) ||
    // !workspacePlan
    // ) {
    //   setShowUpgradeModal(true);
    // } else {
    openCreateModal();
    //   setShowUpgradeModal(false);
    // }
  };
  const handleClickOptionButton = (articleId, actionType) => {
    switch (actionType) {
      case "view":
        dispatch(toggleActionableSubSupplierId(articleId));
        dispatch(toggleIsSubSupplierCreateModalOpen());
        setAnchorEl(null);
        break;
      case "edit":
        dispatch(toggleActionableSubSupplierId(articleId));
        dispatch(toggleIsSubSupplierEditable());
        dispatch(toggleIsSubSupplierCreateModalOpen());
        setAnchorEl(null);
        break;
      case "duplicate":
        dispatch(toggleActionableSubSupplierId(articleId));
        dispatch(toggleDuplicateAction());
        if (!isSubSupplierEditable) {
          dispatch(toggleIsSubSupplierEditable());
        }
        dispatch(toggleIsSubSupplierCreateModalOpen());
        setAnchorEl(null);
        break;
      case "delete":
        dispatch(toggleActionableSubSupplierId(articleId));
        dispatch(toggleSubSupplierDeleteModal());
        setAnchorEl(null);
        break;
      default:
        break;
    }
  };

  const deleteSubSupplierMutation = useMutation({
    mutationKey: ["Sub-supplier", "delete"],
    mutationFn: (templateId) => deleteSubSupplier(templateId),
    onMutate: () => {
      showCustomToast(
        {
          title: "Deleting...",
        },
        "deleteSubSupplier",
      );
    },
    onSuccess: () => {
      toast.dismiss("deleteSubSupplier");
      if (!refetchBillingData) {
        dispatch(setRefetchBillingData(true));
      }
      // toast.success("Sub-Suppliers deleted successfully");
      showCustomToast({
        title: `Sub-Suppliers deleted successfully`,
      });

      dispatch(toggleSubSupplierDeleteModal());
      refetchDatas();
      if (actionableSubSupplierId) {
        dispatch(toggleActionableSubSupplierId());
      }
      queryClient.invalidateQueries(["subSuppliers", "get", "delete"]);
    },
    onError: (error) => {
      toast.dismiss("deleteSubSupplier");

      showCustomToast({
        title: error.response?.data?.message || error.message,
      });
    },
    onSettled: () => {
      toast.dismiss("deleteSubSupplier");
    },
  });

  // const handleOnDelete = () => {
  //   if (!actionableSubSupplierId) return;
  //   deleteSubSupplierMutation.mutate(actionableSubSupplierId);
  // };

  useEffect(() => {
    if (!isLoading && subSuppliersQueryData) {
      console.log(subSuppliersQueryData);
      dispatch(
        setSubSupplierList({
          subSupplierList: subSuppliersQueryData?.data?.sub_supplier?.data,
          append: false,
        }),
      );
      dispatch(setAllSubSupplierData(subSuppliersQueryData?.data));
    }
  }, [subSuppliersQueryData, isLoading]);

  const handleSearch = (e) => {
    const searchText = e.target.value?.trim();
    if (searchText.length >= 2) {
      setSearchKey(searchText);
    } else {
      setSearchKey("");
    }
  };
  const downloadSubSupplierAsCSV = useMutation({
    mutationKey: ["sub-suppiers", "download"],
    mutationFn: (params) => downloadSubSuppliersAsCSV(params),
    onMutate: () => {
      showCustomToast(
        {
          title: `Downloading`,
        },
        "downloadSubSuppliers",
      );
    },
    onSuccess: (res) => {
      toast.dismiss("downloadSubSuppliers");
      // toast.success("Downloaded successfully");
      showCustomToast({
        title: `Downloaded successfully`,
      });
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "sub-suppiers.csv");
      document.body.appendChild(link);
      link.click();
      link.remove();

      queryClient.invalidateQueries(["sub-suppiers", "download"]);
    },
    onError: (error) => {
      toast.dismiss("downloadSubSuppliers");

      showCustomToast({
        title: error.response?.data?.message || error.message || "Something went wrong",
      });
    },
  });
  const handleDownloadCSV = () => {
    downloadSubSupplierAsCSV.mutate({
      workspace_id: parseInt(workspaceId),
    });
  };
  const onClickCheckbox = (inviatation) => {
    const findedItem = selectedOrders.some((item) => item.id === inviatation.id);
    if (!findedItem) {
      setSelectedOrders((prevSelectedOrders) => [...prevSelectedOrders, inviatation]);
    } else {
      setSelectedOrders((prevSelectedOrders) =>
        prevSelectedOrders.filter((item) => item.id !== inviatation.id),
      );
    }
  };
  const handleSelectAll = (e) => {
    if (!e.target.checked || !subSupplierList.length > 0) {
      setSelectedOrders([]);
      return;
    }
    const subsupplierIds =
      subSupplierList.length > 0 && subSupplierList.map((subsupplier) => subsupplier);
    console.log(subsupplierIds, "subsupplierIds");
    setSelectedOrders(subsupplierIds);
  };

  const handleDelete = (ids) => {
    if (actionableSubSupplierId) {
      deleteUserMutation.mutate(actionableSubSupplierId);
    } else {
      const allIds = ids.map((item) => item.id);
      if (allIds) {
        deleteAllMutation.mutate({ subs: allIds });
      }
    }
  };
  const openDeleteModal = (e) => {
    e.stopPropagation();
    dispatch(toggleSubSupplierBulkDeleteModal());
  };

  const openSingleModal = (e, item) => {
    e.stopPropagation();
    setAnchorEl(null);
    setSelectedOrders([]);
    dispatch(toggleSubSupplierDeleteModal(item.id));
    dispatch(toggleSubSupplierDeleteModal());
  };

  const deleteUserMutation = useMutation({
    mutationKey: ["subSupplierDelete", "delete"],
    mutationFn: (data) => deleteSubSupplier(data),
    onMutate: () => {
      showCustomToast(
        {
          title: "Removing...",
        },
        "subSupplierDelete",
      );
    },
    onSuccess: (res) => {
      toast.dismiss("subSupplierDelete");
      showCustomToast({
        title: res.data.message || "Removed successfully",
      });
      if (!refetchBillingData) {
        dispatch(setRefetchBillingData(true));
      }
      refetchDatas();
      closeModal();
      queryClient.invalidateQueries(["subSupplierDelete", "get", "delete"]);
    },
    onError: (error) => {
      toast.dismiss("subSupplierDelete");
      showCustomToast({
        title: error.response.data.message || error.message,
      });
    },
    onSettled: () => {
      toast.dismiss("subSupplierDelete");
    },
  });

  const deleteAllMutation = useMutation({
    mutationKey: ["retailers", "delete"],
    mutationFn: (data) => bulkDeleteSubSuppliers(data),
    onMutate: () => {
      showCustomToast(
        {
          title: "Deleting...",
        },
        "deleteSupplier",
      );
    },
    onSuccess: (response) => {
      toast.dismiss("deleteSupplier");
      if (response.data?.failed?.length === 0) {
        if (!refetchBillingData) {
          dispatch(setRefetchBillingData(true));
        }
        showCustomToast({
          title: "Deleted successfully",
        });
      } else {
        showCustomToast({
          title: "Can not be deleted",
        });
      }
      closeModal();
      refetchDatas();
      setSelectedOrders([]);
      queryClient.invalidateQueries(["suppliers", "get", "delete"]);
    },
    onError: (error) => {
      toast.dismiss("deleteSupplier");
      showCustomToast({
        title: error.response?.data?.message || error.message,
      });
    },
    onSettled: () => {
      toast.dismiss("deleteSupplier");
    },
  });

  const handleOnDelete = () => {
    if (!actionableSubSupplierId) return;
    deleteSubSupplierMutation.mutate(actionableSubSupplierId);
  };

  const handleDeleteAll = () => {
    if (selectedOrders.length > 0) {
      deleteAllMutation.mutate({ subs: selectedOrders.map((item) => item.id) });
    }
  };
  useEffect(() => {
    if (parsed.fromGlobalCreate === "true") {
      dispatch(toggleIsSubSupplierCreateModalOpen());
      if (actionableSubSupplierId) {
        dispatch(toggleActionableSubSupplierId());
      }
      if (!isSubSupplierEditable) {
        dispatch(toggleIsSubSupplierEditable());
      }
    }
    if (parsed.fromGlobalSearch === "true" && parsed.subsuppliers_id !== null) {
      handleActionClick(parsed.subsuppliers_id);
      handleClickOptionButton(parsed.subsuppliers_id, "view");
    }
  }, [parsed.fromGlobalCreate, parsed.subsuppliers_id, parsed.fromGlobalSearch]);
  useEffect(() => {
    if (user && currentWorkspace) {
      const handleKeyDown = (e) => {
        const isMac = e.metaKey; // `metaKey` for Cmd on Mac
        const isWindows = e.ctrlKey; // `ctrlKey` for Ctrl on Windows
        if ((isMac || isWindows) && e.key.toLowerCase() === "p") {
          e.preventDefault();
          if (actionableSubSupplierId) {
            setAnchorEl(null);
            handleViewDetailsClick(actionableSubSupplierId);
          }
        }

        if (e.key === "Delete" && currentWorkspace &&
          ["admin", "editor"]?.includes(currentWorkspace?.role_name?.split(".")[1])) {
          e.preventDefault();
          if (actionableSubSupplierId) {
            setAnchorEl(null);
            handleClickOptionButton(actionableSubSupplierId, "delete");
            setSelectedOrders([]);
          } else if (selectedOrders.length > 0) {
            e.preventDefault();
            dispatch(toggleSubSupplierBulkDeleteModal());
            if (actionableSubSupplierId) {
              dispatch(toggleActionableSubSupplierId());
            }
          }
        }
        if (
          (isMac || isWindows) &&
          (e.key === "e" || e.key === "E") &&
          currentWorkspace &&
          ["admin", "editor"]?.includes(currentWorkspace?.role_name?.split(".")[1])
        ) {
          e.preventDefault();
          if (actionableSubSupplierId) {
            setAnchorEl(null);
            handleClickOptionButton(actionableSubSupplierId, "edit");
          }
        }
        if (
          (e.ctrlKey || e.metaKey) &&
          (e.key === "B" || e.key === "b") &&
          currentWorkspace &&
          ["admin", "editor"]?.includes(currentWorkspace?.role_name?.split(".")[1])
        ) {
          e.preventDefault();
          setAnchorEl(null);
          handleCreateAction();
        }
        if (
          (e.ctrlKey || e.metaKey) &&
          (e.key === "A" || e.key === "a") &&
          currentWorkspace &&
          ["admin", "editor"]?.includes(currentWorkspace?.role_name?.split(".")[1])
        ) {
          e.preventDefault();
          setAnchorEl(null);
          if (actionableSubSupplierId) {
            dispatch(toggleActionableSubSupplierId());
          }
          const allChecked =
            subSupplierList?.length > 0 && selectedOrders.length === subSupplierList.length;
          const newSelectAllState = !allChecked;
          const checkboxEvent = { target: { checked: newSelectAllState } };
          handleSelectAll(checkboxEvent);
        }
      };
      window.addEventListener("keydown", handleKeyDown);
      return () => {
        window.removeEventListener("keydown", handleKeyDown);
      };
    }
  }, [
    user,
    dispatch,
    currentWorkspace,
    actionableSubSupplierId,
    actionableSubSupplierItem,
    handleClickOptionButton,
    handleViewDetailsClick,
  ]);
  return (
    <>
      <div>
        <div className="bg-btn-surface-secondary border border-border-divider-stroke  rounded-xl custom-card w-full  mb-6">
          <SearchCreatePanelUpdate
            permissionText="canAddSubSupplier"
            permissionText2="canAddSubSupplier"
            createTxt="Add Sub-Supplier"
            title="Sub-Suppliers"
            handleDownloadCSV={handleDownloadCSV}
            selectedItems={selectedOrders}
            openDeleteModal={openDeleteModal}
            btnText={allSubSupplierData && allSubSupplierData?.total}
            onChange={handleSearch}
            onBtnClick={handleCreateAction}
            classWidth="w-full md:w-[173px] whitespace-nowrap"
          >
            <SubSupplierFilter
              {...{
                activeFilters,
                setActiveFilters,
                setFilteredData,
                subSupplierOptions,
              }}
            />
          </SearchCreatePanelUpdate>
          <div className="w-full max-w-screen overflow-x-auto ">
            <table className="w-full border-spacing-0 table-auto rounded-table-custom overflow-x-auto">
              <thead className="text-sm text-left whitespace-nowrap text-global-paragraph">
                <tr className="border-b  border-t border-b-global-divider-soft border-t-global-divider-soft">
                  <th className="pl-6 pr-4 py-[11.5px]">
                    <div className="flex items-center gap-3">
                      <input
                        type="checkbox"
                        className={`rounded-md  w-5 h-5 ${subSupplierList && subSupplierList.length > 0 ? "cursor-pointer" : "cursor-default"}`}
                        onChange={handleSelectAll}
                        checked={
                          subSupplierList &&
                          subSupplierList.length > 0 &&
                          selectedOrders.length === subSupplierList.length
                        }
                      />
                      <div className="flex items-center">
                        <span className="mr-1 text-xs font-medium text-global-paragraph leading-[18px]">
                          Partner name
                        </span>
                        <SortIcons
                          hasData={subSupplierList && subSupplierList.length > 0}
                          sortName="name"
                          {...{ orderBy, setOrderBy }}
                        />
                      </div>
                    </div>
                  </th>

                  <th className="relative px-4 py-[11.5px]  ">
                    <div className="flex items-center">
                      <span className="mr-1 text-xs font-medium text-global-paragraph leading-[18px]">
                        Active Orders
                      </span>
                      <SortIcons
                        hasData={subSupplierList && subSupplierList.length > 0}
                        sortName="active_orders_count"
                        {...{ orderBy, setOrderBy }}
                      />
                    </div>
                  </th>
                  <th className="relative px-4 py-[11.5px]">
                    <div className="flex items-center">
                      <span className="mr-1 text-xs font-medium text-global-paragraph leading-[18px]">
                        Verification %
                      </span>
                      <SortIcons
                        hasData={subSupplierList && subSupplierList.length > 0}
                        sortName="verification_score"
                        {...{ orderBy, setOrderBy }}
                      />
                    </div>
                  </th>
                  <th className="relative px-4 py-[11.5px]  ">
                    <div className="flex items-center">
                      <span className="mr-1 text-xs font-medium text-global-paragraph leading-[18px]">
                        Type
                      </span>
                      <SortIcons
                        hasData={subSupplierList && subSupplierList.length > 0}
                        sortName="type"
                        {...{ orderBy, setOrderBy }}
                      />
                    </div>
                  </th>

                  <th className="relative px-4 py-[11.5px]">
                    <div className="flex items-center">
                      <span className="mr-1 text-xs font-medium text-global-paragraph leading-[18px]">
                        Tier
                      </span>
                      <SortIcons
                        hasData={subSupplierList && subSupplierList.length > 0}
                        sortName="tier_id"
                        {...{ orderBy, setOrderBy }}
                      />
                    </div>
                  </th>

                  <th className="relative px-4  py-[11.5px]  ">
                    <div className="flex items-center">
                      <span className="mr-1 text-xs font-medium text-global-paragraph leading-[18px]">
                        Street
                      </span>
                      <SortIcons
                        hasData={subSupplierList && subSupplierList.length > 0}
                        sortName="street"
                        {...{ orderBy, setOrderBy }}
                      />
                    </div>
                  </th>
                  <th className="relative px-4 py-[11.5px]">
                    <div className="flex items-center">
                      <span className="mr-1 text-xs font-medium text-global-paragraph leading-[18px]">
                        City
                      </span>
                      <SortIcons
                        hasData={subSupplierList && subSupplierList.length > 0}
                        sortName="region"
                        {...{ orderBy, setOrderBy }}
                      />
                    </div>
                  </th>

                  <th className="relative px-4 py-[11.5px]">
                    <div className="flex items-center">
                      <span className="mr-1 text-xs font-medium text-global-paragraph leading-[18px]">
                        Country
                      </span>
                      <SortIcons
                        hasData={subSupplierList && subSupplierList.length > 0}
                        sortName="country"
                        {...{ orderBy, setOrderBy }}
                      />
                    </div>
                  </th>

                  <th className="relative px-4 py-[11.5px]  ">
                    <div className="flex items-center">
                      <span className="mr-1"> </span>
                    </div>
                  </th>
                </tr>
              </thead>

              <tbody>
                {!isLoading && subSupplierList && subSupplierList?.length > 0 ? (
                  subSupplierList?.map((item, index) => (
                    <tr
                      key={index}
                      onClick={() => {
                        handleActionClick(item.id);
                        // handleTableRowClick(item?.status, parseInt(item?.workspace_id));
                      }}
                      className={`whitespace-nowrap border-b last:border-0 border-b-global-divider-soft  ${index % 2 === 0 ? "bg-global-white" : "bg-white"} rounded-lg text-global-paragraph text-sm font-normal cursor-default`}
                    >
                      <td className="pl-6 pr-4 py-[15.5px]  ">
                        <div className="flex items-center gap-3">
                          <input
                            onClick={(e) => e.stopPropagation()}
                            onChange={() => onClickCheckbox(item)}
                            checked={selectedOrders.includes(item)}
                            type="checkbox"
                            className="rounded-md border cursor-pointer w-5 h-5  "
                          />
                          <div className="flex justify-start items-center gap-3">
                            {item && item?.name && (
                              <div className="flex bg-global-surface uppercase justify-center items-center text-xs md:text-base font-semibold text-global-paragraph leading-6 w-8 h-8 md:h-10 md:w-10 p-2 ring-1 ring-global-divider-medium ring-opacity-75 ring-inset rounded-full border-none">
                                {item && item?.name ? getInitials(item?.name) : ""}
                              </div>
                            )}

                            <div className="flex flex-col">
                              <p className="text-sm leading-5 font-medium text-global-title capitalize">
                                {item?.name ? truncateString(item?.name) : ""}
                              </p>
                              <p className="text-sm text-global-gray-brand leading-5">
                                {item?.country}
                              </p>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td className="relative px-4 py-[15.5px] ">{item?.active_orders_count}</td>

                      <td className="relative px-4 py-[15.5px] text-sm text-global-paragraph leading-5 w-auto  ">
                        <div className="flex items-center gap-3">
                          <LineProgressBar
                            className="h-2 w-full lg:w-[164px]"
                            variant={"blue"}
                            progress={item?.verification_score}
                          />
                          <span>{`${item?.verification_score}%`}</span>
                        </div>
                      </td>
                      <td className="relative px-4 py-[15.5px] text-sm text-global-paragraph leading-5  ">
                        <Button
                          variant={"status"}
                          className={`rounded-[6px] w-auto h-[22px] px-1.5 py-[2px] border border-[#D5D7DA] custom-card cursor-default capitalize   text-status-text-default  text-xs leading-[18px] font-medium `}
                        >
                          {item?.type}
                        </Button>
                      </td>
                      <td className="relative px-4 py-[15.5px] ">
                        {item && item?.tier_name && item?.tier_name?.replace(/^\D+/, "")}
                      </td>

                      <td className="relative px-4 py-[15.5px] text-sm text-global-paragraph leading-5  ">
                        <div className="flex items-center">
                          <span>{item?.street ? truncateString(item?.street) : null}</span>
                        </div>
                      </td>
                      <td className="relative px-4 py-[15.5px] text-sm text-global-paragraph leading-5  ">
                        <div className="flex items-center">
                          <span>{item?.region ? truncateString(item?.region) : null}</span>
                        </div>
                      </td>
                      <td className="relative px-4 py-[15.5px] text-sm text-global-paragraph leading-5  ">
                        <div className="flex items-center">
                          <span>{item?.country ? truncateString(item?.country) : null}</span>
                        </div>
                      </td>

                      <td
                        onClick={(e) => {
                          e.stopPropagation();
                          setAnchorEl(null);
                        }}
                        className="relative cursor-pointer px-4 py-[15.5px] text-sm text-global-paragraph leading-5  "
                      >
                        <IconButton
                          aria-label="more"
                          id={`${index}`}
                          aria-controls={open ? "long-menu" : undefined}
                          aria-expanded={open ? "true" : undefined}
                          aria-haspopup="true"
                          onClick={(e) => {
                            handleActionClick(item.id);
                            setAnchorEl(e.currentTarget);
                            setPartnerName(item.name);
                            dispatch(setActionableSubSupplierItem(item));
                            setSelectedOrders([]);
                            e.stopPropagation();
                          }}
                        >
                          <MoreOptions width={20} height={20} />
                        </IconButton>
                        {!isSubSupplierCreateModalOpen &&
                          actionableSubSupplierId &&
                          actionableSubSupplierId === item?.id && (
                            <Menu
                              id="long-menu"
                              MenuListProps={{
                                "aria-labelledby": "long-button",
                              }}
                              anchorEl={anchorEl}
                              open={open}
                              onClose={handleClose}
                              anchorOrigin={{
                                vertical: "top",
                                horizontal: "left",
                              }}
                              elevation={0}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                              }}
                              PaperProps={{
                                style: {
                                  maxHeight: 45 * 4.5,
                                  borderRadius: "8px",
                                  border: "1px solid var(--color-border-secondary)",
                                  padding: 0,
                                  backgroundColor: "transparent",
                                  boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.05)",
                                },
                              }}
                            >
                              <MoreActionOptionUpdated
                                editButton={
                                  currentWorkspace &&
                                  ["admin", "editor"]?.includes(currentWorkspace?.role_name?.split(".")[1]) && (
                                    <EditButton
                                      title="Edit"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleClickOptionButton(item.id, "edit");
                                      }}
                                      shortcut="⌘+E"
                                      className={"w-[236px]"}
                                    />
                                  )
                                }
                                deleteButton={
                                  currentWorkspace &&
                                  ["admin", "editor"]?.includes(currentWorkspace?.role_name?.split(".")[1]) && (
                                    <DeleteButton
                                      title="Delete"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleClickOptionButton(
                                          item.id,
                                          // parseInt(item.workspace_id),
                                          "delete",
                                        );
                                      }}
                                      className={"w-[236px]"}
                                      shortcut="⌘+Delete"
                                    />
                                  )
                                }
                              />
                            </Menu>
                          )}
                      </td>
                    </tr>
                  ))
                ) : isLoading ? (
                  <tr>
                    <td colSpan={12} className="text-center pb-3">
                      <LoadingComponent />
                    </td>
                  </tr>
                ) : searchKey && subSupplierList && subSupplierList?.length === 0 ? (
                  <EmptyTable colSpan={12} text="Result not found" />
                ) : (
                  <EmptyTable colSpan={12} />
                )}
              </tbody>
            </table>
          </div>
          {allSubSupplierData && allSubSupplierData.total > 0 && (
            <TertiaryPagination
              page={page}
              totalPages={Math.ceil(allSubSupplierData.total / size)}
              setPage={setPage}
              size={size}
              handleSizeChange={handleSizeChange}
            />
          )}
        </div>
        <Modal open={isSubSupplierCreateModalOpen} close={closeModal} title={""}>
          <AddSubSupplier
            closeModal={closeModal}
            refetchDatas={refetchDatas}
            fromOrderDetailsPage={false}
          />
        </Modal>
        {/* <Modal open={showUpgradeModal} close={closeModal}>
          <CommonDiscardButtonUpdated
            closeModal={closeModal}
            title="Upgrade plan"
            subText1={
              currentWorkspace && !currentWorkspace?.is_owner && !workspacePlan
                ? `Your partner workspace is currently on a free plan.`
                : `You are currently on a free plan. Upgrade to a paid plan to start creating your own ${user && user?.workspace_type} workspaces.`
            }
            className="w-full lg:max-w-[544px]"
            confirmButtonClassName={"w-full h-11"}
            IconComponent={PlanIcon}
            confirmButtonText={
              currentWorkspace && !currentWorkspace?.is_owner && !workspacePlan
                ? null
                : "Ugrade Plan"
            }
            confirmButtonVariant="primary"
            onConfirmClick={() => {
              navigate(
                `/workspace/${currentWorkspace?.user_workspace_id}/setting?selectedTab=plan`,
              );
              // if (showUpgradeModal) {
              //   setShowUpgradeModal(false);
              // }
            }}
            icon={
              <Shock
                width={20}
                height={20}
                strokeColor={"var(--color-icon-fill-secondary-button)"}
              />
            }
          />
        </Modal> */}

        <Modal open={deleteModal} close={closeModal} title={""}>
          <CommonDiscardButtonUpdated
            closeModal={closeModal}
            title={`Remove ${partnerName}?`}
            subText1={`Are you sure you want to remove ${partnerName} from your account? All active order sassociated with this partner will be changed to draft status. This action is irreversible and will terminate any ongoing collaboration with ${partnerName}.`}
            className="w-full lg:max-w-[600px]"
            IconComponent={InvitePartnerIcon}
            confirmButtonText="Remove"
            confirmButtonVariant="warning"
            confirmButtonClassName={"w-full h-11"}
            onConfirmClick={handleOnDelete}
            icon={
              <TrashUpdateIcon
                width={20}
                height={20}
                strokeColor={"var(--color-icon-fill-secondary-button)"}
              />
            }
          />
        </Modal>
        <Modal open={bulkDeleteModal} close={closeModal}>
          <CommonDiscardButtonUpdated
            closeModal={closeModal}
            title={`Remove ${selectedOrders.length === 1 ? "partner" : "partners"}`}
            subText1={`Are you sure you want to remove ${selectedOrders.length === 1 ? "partner" : "partners"
              } from your account? All active orders associated with ${selectedOrders.length === 1 ? "this partner" : "these partners"
              } will be changed to draft status. This action is irreversible and will terminate any ongoing collaboration with ${selectedOrders.length === 1 ? "this partner" : "these partners"
              }.`}
            className="w-full lg:max-w-[600px]"
            IconComponent={InvitePartnerIcon}
            confirmButtonText="Remove"
            confirmButtonVariant="warning"
            confirmButtonClassName={"w-full h-11"}
            onConfirmClick={handleDeleteAll}
            icon={
              <TrashUpdateIcon
                width={20}
                height={20}
                strokeColor={"var(--color-icon-fill-secondary-button)"}
              />
            }
          />
        </Modal>
      </div>
    </>
  );
};

export default SubSupplier;
