const { createSlice } = require("@reduxjs/toolkit");

const certificateSlice = createSlice({
  name: "certificate",
  initialState: {
    isCertificateCreateModalOpen: false,
    isworkspaceCertificateCreateModalOpen: false,
    actionableCertificateId: null,
    isCertificateEditable: false,
    certificateList: [],
    deleteModal: false,
    showDiscardModal: false,
    certificateDetails: null,
    workspaceCertificateList: [],
    workspaceAllCertificateData: null,
    actionableWrokspaceCertificateId: null,
    isworkspaceCertificateEditable: false,
    workspaceCertificateDetails: null,
    deleteWorkspaceModal: false,
    showWorkspaceDiscardModal: false,
    certificateTypeList: [],
  },
  reducers: {
    toggleisCertificateCreateModalOpen: (state) => ({
      ...state,
      isCertificateCreateModalOpen: !state.isCertificateCreateModalOpen,
    }),
    toggleActionableCertificateId: (state, action) => ({
      ...state,
      actionableCertificateId: action.payload ?? null,
    }),
    toggleisCertificateEditable: (state) => ({
      ...state,
      isCertificateEditable: !state.isCertificateEditable,
    }),
    toggleShowDiscardModal: (state) => ({ ...state, showDiscardModal: !state.showDiscardModal }),
    toggleDeleteModal: (state) => {
      state.deleteModal = !state.deleteModal;
    },
    setCertificateList: (state, action) => {
      if (action.payload.append) {
        // append the certificate to the list
        return {
          ...state,
          certificateList: [action.payload.certificate, ...state.certificateList],
        };
      } else {
        // set the certificate list
        return {
          ...state,
          certificateList: action.payload.certificateList,
        };
      }
    },
    setCertificateTypeList: (state, action) => {
      if (action.payload.append) {
        // append the certificate to the list
        return {
          ...state,
          certificateTypeList: [action.payload.certificateTypeList, ...state.certificateList],
        };
      } else {
        // set the certificate list
        return {
          ...state,
          certificateTypeList: action.payload.certificateTypeList,
        };
      }
    },
    setCertificateDetails: (state, action) => ({
      ...state,
      certificateDetails: action.payload ?? null,
    }),

    toggleIsWorkspaceCertificateEditable: (state) => ({
      ...state,
      isworkspaceCertificateEditable: !state.isworkspaceCertificateEditable,
    }),
    toggleShoWorkspaceDiscardModal: (state) => ({
      ...state,
      showWorkspaceDiscardModal: !state.showWorkspaceDiscardModal,
    }),
    toggleDeleteWorkspacecertificateModal: (state) => {
      state.deleteWorkspaceModal = !state.deleteWorkspaceModal;
    },
    setWorkspaceCertificateList: (state, action) => {
      if (action.payload.append) {
        // append the certificate to the list
        return {
          ...state,
          workspaceCertificateList: [action.payload.certificate, ...state.workspaceCertificateList],
        };
      } else {
        // set the certificate list
        return {
          ...state,
          workspaceCertificateList: action.payload.workspaceCertificateList,
        };
      }
    },
    setWorkspaceCertificateDetails: (state, action) => ({
      ...state,
      workspaceCertificateDetails: action.payload ?? null,
    }),
    setworkspaceAllCertificateData: (state, action) => ({
      ...state,
      workspaceAllCertificateData: action.payload ?? null,
    }),
    toggleisWrokspaceCertificateCreateModalOpen: (state) => ({
      ...state,
      isworkspaceCertificateCreateModalOpen: !state.isworkspaceCertificateCreateModalOpen,
    }),
    toggleActionableWrokspaceCertificateId: (state, action) => ({
      ...state,
      actionableWrokspaceCertificateId: action.payload ?? null,
    }),
    setCertificateSliceState: (state, action) => ({
      ...state,
      ...action.payload,
    }),
  },
});

export const {
  toggleisCertificateCreateModalOpen,
  toggleActionableCertificateId,
  toggleisCertificateEditable,
  toggleShowDiscardModal,
  toggleDuplicateAction,
  setCertificateList,
  setCertificateDetails,
  setCertificateSliceState,
  toggleDeleteModal,
  setworkspaceAllCertificateData,
  toggleisWrokspaceCertificateCreateModalOpen,
  toggleActionableWrokspaceCertificateId,
  setWorkspaceCertificateList,
  setWorkspaceCertificateDetails,
  toggleIsWorkspaceCertificateEditable,
  toggleShoWorkspaceDiscardModal,
  toggleDeleteWorkspacecertificateModal,
  setCertificateTypeList,
} = certificateSlice.actions;
export default certificateSlice.reducer;
