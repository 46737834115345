import { ActiveEye, WarningInfo } from "assests";
import { Button } from "components/UI/Button";
import React from "react";
import { useNavigate } from "react-router-dom";

const CancelSubsciptionConfirmation = ({ closeModal, onClick, currentWorkspace, currentPlan }) => {
  const navigate = useNavigate();
  const actionableWorkspaceId = parseInt(currentWorkspace?.workspace_id);
  const routes = `/workspace/${actionableWorkspaceId}/orders?status=active`;
  const active_orders_count = 0;
  return (
    <div className="w-full lg:w-[524px]  bg-global-white rounded-3xl p-6 lg:p-8">
      {currentPlan && currentPlan.active_orders_count === 0 ? (
        <div className="grid gap-4 md:gap-6">
          <div className="grid grid-cols-7 gap-3 md:gap-5">
            <div className="col-span-1">
              <WarningInfo />
            </div>
            <div className="col-span-6">
              <div className="flex flex-col gap-2">
                <p className="text-sm md:text-base font-bold leading-5 text-global-gray-brand">
                  Are you sure you want to cancel this subscription?
                </p>
                <p className="text-xs md:text-sm leading-5 text-global-gray-brand ">
                  Going forward, this subscription will be canceled and you will back to the free
                  subscription where you can't create more order because we already see you cross
                  your oder creation limit on the free plan.
                </p>
              </div>
            </div>
          </div>
          <div className="flex justify-end gap-4">
            <Button
              size={"sm"}
              variant={"outline"}
              className={"px-6 h-10 text-[10px] md:text-sm"}
              onClick={closeModal}
            >
              Cancel
            </Button>

            <Button size={"sm"} className={"px-6 h-10 text-[10px] md:text-sm"} onClick={onClick}>
              Okay
            </Button>
          </div>
        </div>
      ) : (
        <div className="grid gap-4 md:gap-6">
          <div className="grid grid-cols-7 gap-3 md:gap-5">
            <div className="col-span-1">
              <WarningInfo />
            </div>
            <div className="col-span-6">
              <div className="flex flex-col gap-2">
                <p className="text-sm md:text-base font-bold leading-5 text-global-gray-brand">
                  Action Required
                </p>
                <p className="text-xs md:text-sm leading-5 text-global-gray-brand ">
                  This subscription cannot be canceled at this moment. Please complete or cancel all
                  active orders before proceeding to cancel.
                </p>
              </div>
            </div>
          </div>
          <div className="flex justify-end gap-2 md:gap-4">
            <Button
              size={"sm"}
              onClick={() => {
                closeModal();
                navigate(routes);
              }}
              variant={"outline"}
              className={"px-6 h-10 text-[10px] md:text-sm flex items-center  gap-2"}
            >
              <ActiveEye fillColor={"var(--color-icon-fill-default)"} width={18} height={18} />
              <span>View active orders</span>
            </Button>
            <Button size={"sm"} className={"px-6 h-10 text-[10px] md:text-sm"} onClick={closeModal}>
              Cancel
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default CancelSubsciptionConfirmation;
