import api from "config/axios";
export const getAuditTypes = (id) => api.get(`/result_types`, { workspace_id: id });
export const createAudit = (data) =>
  api.post("/audits", data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
export const getAudits = (query) => {
  const params = new URLSearchParams();
  Object.keys(query).forEach((key) => {
    const value = query[key];
    if (Array.isArray(value)) {
      value.forEach((item) => {
        params.append(`${key}[]`, item);
      });
    } else if (value) {
      params.append(key, value);
    }
  });
  return api.get(`/audits?${params}`);
};
export const getAuditDetailsById = (id) => api.get(`/audits/${id}`);
export const downloadAudits = async (query) => {
  try {
    const params = new URLSearchParams();
    Object.keys(query).forEach((key) => {
      if (query[key]) {
        params.append(key, query[key]);
      }
    });
    return api.get(`/audits?download=yes&${params}`);
  } catch (error) {
    throw error;
  }
};

export const updateAudit = (data) =>
  api.post(`/audits/${data.id}`, data.body, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

export const deleteAudit = (id) => api.delete(`/audits/${id}`);

export const deleteWorkspaceAudit = (id) => {
  try {
    const response = api.delete(`/certificates/${id}?type=partner`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const bulkDeleteWorkspaceAudit = async (data) => {
  try {
    const response = await api.post(`/audit/bulk-delete`, data);
    return response;
  } catch (error) {
    throw error;
  }
};
