const { createSlice } = require("@reduxjs/toolkit");

const auditSlice = createSlice({
  name: "audit",
  initialState: {
    isAuditCreateModalOpen: false,
    isworkspaceAuditCreateModalOpen: false,
    actionableAuditId: null,
    isAuditEditable: false,
    auditList: [],
    deleteModal: false,
    showDiscardModal: false,
    auditDetails: null,
    workspaceAuditList: [],
    workspaceAllAuditData: null,
    actionableWrokspaceAuditId: null,
    isworkspaceAuditEditable: false,
    workspaceAuditDetails: null,
    deleteWorkspaceModal: false,
    showWorkspaceDiscardModal: false,
    auditTypeList: [],
  },
  reducers: {
    toggleisAuditCreateModalOpen: (state) => ({
      ...state,
      isAuditCreateModalOpen: !state.isAuditCreateModalOpen,
    }),
    toggleActionableAuditId: (state, action) => ({
      ...state,
      actionableAuditId: action.payload ?? null,
    }),
    toggleisAuditEditable: (state) => ({ ...state, isAuditEditable: !state.isAuditEditable }),
    toggleShowDiscardModal: (state) => ({ ...state, showDiscardModal: !state.showDiscardModal }),
    toggleDeleteModal: (state) => {
      state.deleteModal = !state.deleteModal;
    },
    setAuditList: (state, action) => {
      if (action.payload.append) {
        // append the audit to the list
        return {
          ...state,
          auditList: [action.payload.audit, ...state.auditList],
        };
      } else {
        // set the audit list
        return {
          ...state,
          auditList: action.payload.auditList,
        };
      }
    },
    setAuditTypeList: (state, action) => {
      if (action.payload.append) {
        // append the audit to the list
        return {
          ...state,
          auditTypeList: [action.payload.auditTypeList, ...state.auditList],
        };
      } else {
        // set the audit list
        return {
          ...state,
          auditTypeList: action.payload.auditTypeList,
        };
      }
    },
    setauditDetails: (state, action) => ({
      ...state,
      auditDetails: action.payload ?? null,
    }),

    toggleisworkspaceAuditEditable: (state) => ({ ...state, isworkspaceAuditEditable: !state.isworkspaceAuditEditable }),
    toggleShoWorkspaceDiscardModal: (state) => ({ ...state, showWorkspaceDiscardModal: !state.showWorkspaceDiscardModal }),
    toggleDeleteWorkspaceauditModal: (state) => {
      state.deleteWorkspaceModal = !state.deleteWorkspaceModal;
    },
    setWorkspaceAuditList: (state, action) => {
      if (action.payload.append) {
        // append the audit to the list
        return {
          ...state,
          workspaceAuditList: [action.payload.audit, ...state.workspaceAuditList],
        };
      } else {
        // set the audit list
        return {
          ...state,
          workspaceAuditList: action.payload.workspaceAuditList,
        };
      }
    },
    setWorkspaceauditDetails: (state, action) => ({
      ...state,
      workspaceAuditDetails: action.payload ?? null,
    }),
    setworkspaceAllAuditData: (state, action) => ({
      ...state,
      workspaceAllAuditData: action.payload ?? null,
    }),
    toggleisWrokspaceAuditCreateModalOpen: (state) => ({
      ...state,
      isworkspaceAuditCreateModalOpen: !state.isworkspaceAuditCreateModalOpen,
    }),
    toggleActionableWrokspaceAuditId: (state, action) => ({
      ...state,
      actionableWrokspaceAuditId: action.payload ?? null,
    }),

    setAuditSliceState: (state, action) => ({
      ...state,
      ...action.payload,
    }),

  },
});

export const {
  toggleisAuditCreateModalOpen,
  toggleActionableAuditId,
  toggleisAuditEditable,
  toggleShowDiscardModal,
  toggleDuplicateAction,
  setAuditList,
  setauditDetails,
  setAuditSliceState,
  toggleDeleteModal,
  setworkspaceAllAuditData,
  toggleisWrokspaceAuditCreateModalOpen,
    toggleActionableWrokspaceAuditId,
  
  setWorkspaceAuditList,
  setWorkspaceauditDetails,
  toggleisworkspaceAuditEditable,
  toggleShoWorkspaceDiscardModal,
  toggleDeleteWorkspaceauditModal,
  setAuditTypeList
} = auditSlice.actions;
export default auditSlice.reducer;