import { DarkLogo, LogoCollpased, XCloseIcon } from "assests";
import { Button } from "components/UI/Button";
import Divider from "components/UI/Divider";
import TabItem from "components/UI/TabItem";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { IoIosArrowForward } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useParams } from "react-router-dom";
import { setCurrentWorkspace } from "store/authenticationSlice";
import { toggleShowSidebarMessage } from "store/billingSlice";
import { collapseSidebar } from "store/navbarSlice";
import { toggleTheme } from "store/themeSlice";
import { SidebarMenu, SidebarMenuSettings } from "utils/constant";
import { permissions } from "utils/permissions";
import CollapsMenu from "./CollapsMenu";
import { greeting } from "utils/helper";
const LeftSidebar = () => {
  const dispatch = useDispatch();
  const { isDarkMode } = useSelector((state) => state.themeSlice);
  const { isSidebarCollapsed } = useSelector((state) => state.navbarSlice);
  const { user, subscription, workspaces, currentWorkspace } = useSelector(
    (state) => state.authenticationSlice,
  );
  const { pathname } = useLocation();
  const { workspaceId } = useParams();

  //local state
  const [maxHeight, setMaxHeight] = useState(null);
  const [filteredMenu, setFilteredMenu] = useState(null);

  const handleThemeToggle = () => {
    dispatch(toggleTheme());
  };

  useEffect(() => {
    /**
     * Current workspace data snapshot
     * role_name: retailer.admin,
     * workspace_id: 1,
     * is_owner: true/false
     */

    if (user) {
      if (pathname.includes("workspace") && workspaceId) {
        if (user.roles && Array.isArray(user.roles) && user.roles.length > 0) {
          const findRoles = user.roles.find((item) => {
            if (
              !item.name.split(".").includes("workspace") &&
              parseInt(item.pivot.workspace_id) === parseInt(workspaceId) &&
              item.name !== "workspace.admin"
            ) {
              return item;
            }
            return null;
          });
          if (findRoles) {
            let currentWorkspaceData = {
              role_name: pathname.includes("settings") ? user.role : findRoles.name,
              workspace_id: parseInt(findRoles.pivot.workspace_id),
              is_owner: parseInt(user.workspace_id) === parseInt(workspaceId),
              user_workspace_id: parseInt(user.workspace_id)
            };
            if (currentWorkspaceData.workspace_id && workspaces && workspaces.length > 0) {
              workspaces.find((item) => {
                if (
                  (item.invited_workspace_id || item.pivot.workspace_id) ===
                  currentWorkspaceData.workspace_id
                ) {
                  currentWorkspaceData.invited_workspace_name = item.invited_workspace_name;
                  currentWorkspaceData.invited_workspace_type = item.invited_workspace_type;
                  currentWorkspaceData.invited_workspace_id = item.invited_workspace_id;
                }
              });
            }
            dispatch(setCurrentWorkspace(currentWorkspaceData));
            const updatedSidebarMenu = Object.keys(SidebarMenu).reduce((acc, key) => {
              const menuItems = SidebarMenu[key].filter((item) => {
                if (currentWorkspaceData.is_owner && currentWorkspaceData.role_name) {
                  return permissions[currentWorkspaceData.role_name]?.ownerMenuItems?.includes(
                    item.path,
                  );
                } else if (!currentWorkspaceData.is_owner) {
                  if (item.path === "setting") {
                    return false;
                  }
                  return permissions[currentWorkspaceData.role_name]?.invitedMenuItems?.includes(
                    item.path,
                  );
                } else {
                  return permissions[currentWorkspaceData.role_name]?.invitedMenuItems?.includes(
                    item.path,
                  );
                }
              });
              if (menuItems?.length > 0) {
                acc[key] = menuItems;
              }
              return acc;
            }, {});
            setFilteredMenu(updatedSidebarMenu);
          }
        }
      } else {
        if (user.roles && Array.isArray(user.roles) && user.roles.length > 0) {
          const findRoles = user.roles.find((item) => {
            if (
              !item.name.split(".").includes("workspace") &&
              parseInt(item.pivot.workspace_id) === parseInt(user.workspace_id)
            ) {
              return item;
            }
            return null;
          });
          if (findRoles) {
            const currentWorkspaceData = {
              role_name: findRoles.name,
              workspace_id: parseInt(findRoles.pivot.workspace_id),
              is_owner: user.is_owner,
              invited_workspace_name: null,
              invited_workspace_type: null,
              invited_workspace_id: null,
              user_workspace_id: parseInt(user.workspace_id)
            };
            dispatch(setCurrentWorkspace(currentWorkspaceData));
            const updatedSidebarMenu = Object.keys(SidebarMenu).reduce((acc, key) => {
              const menuItems = SidebarMenu[key].filter((item) => {
                if (currentWorkspaceData.is_owner && currentWorkspaceData.role_name) {
                  return permissions[currentWorkspaceData.role_name]?.ownerMenuItems?.includes(
                    item.path,
                  );
                } else if (!currentWorkspaceData.is_owner) {
                  return permissions[currentWorkspaceData.role_name]?.ownerMenuItems?.includes(
                    item.path,
                  );
                } else {
                  return permissions[currentWorkspaceData.role_name]?.invitedMenuItems?.includes(
                    item.path,
                  );
                }
              });
              if (menuItems?.length > 0) {
                acc[key] = menuItems;
              }
              return acc;
            }, {});
            setFilteredMenu(updatedSidebarMenu);
          }
        }
      }
    }
  }, [pathname, user, workspaceId, dispatch]);

  useEffect(() => {
    const screenHeight = window.innerHeight;
    if (screenHeight < 668) {
      setMaxHeight("220px");
    } else if (screenHeight <= 768) {
      setMaxHeight("350px");
    } else if (screenHeight <= 920) {
      setMaxHeight("400px");
    } else {
      setMaxHeight(null);
    }
  }, [window.innerHeight]);

  const [openSections, setOpenSections] = useState({
    partners: false,
    compliancy: false,
  });

  // Toggle the open state for a specific section (e.g., partners or compliancy)
  const handleOpen = (key) => {
    if (isSidebarCollapsed) {
      dispatch(collapseSidebar());
    }
    setOpenSections((prevState) => ({
      ...prevState,
      [key]: !prevState[key], // Toggle the state for the given key
    }));
  };
  function upgradeShowHIde() {
    var element = document.getElementById("upgradePlan").classList.remove("hidden");
  }

  useEffect(() => {
    let itemStatus = JSON.parse(localStorage.getItem("subscription"));
    if (!subscription && !itemStatus) {
      const isPlanNotUpgraded = Cookies.get("showSidebarMsg") || false;
      const parseIsPlanNotUpgraded = JSON.parse(isPlanNotUpgraded);
      if (parseIsPlanNotUpgraded) {
        dispatch(toggleShowSidebarMessage(true));
        upgradeShowHIde();
      }
    } else if (subscription) {
      Cookies.set("showSidebarMsg", "false", { expires: 7 });
      dispatch(toggleShowSidebarMessage(false));
      document.getElementById("upgradePlan").classList.add("hidden");
      localStorage.removeItem("subscription");
    }
  }, [subscription]);

  const handleCloseSidebarMessage = () => {
    Cookies.set("showSidebarMsg", "false", { expires: 7 });
    dispatch(toggleShowSidebarMessage(false));
    document.getElementById("upgradePlan").classList.add("hidden");
    localStorage.removeItem("subscription");
  };

  const closeSidebarSmallScreen = () => {
    dispatch(collapseSidebar());
    if (!isSidebarCollapsed) {
      setOpenSections(false);
    }
  }

  return (
    <>
      {window.innerWidth <= 767 && !isSidebarCollapsed && (
        <div className="fixed inset-0 transition-opacity bg-[#444656] bg-opacity-50 z-[100]"></div>
      )}
      <div
        className={`${window.innerWidth <= 767 && !isSidebarCollapsed ? "absolute left-0 w-[80vw] z-[100]" : "md:pl-1  py-1 bg-transparent"} `}
      >
        <div
          className={`flex items-center justify-center flex-col gap-0 pt-10 pb-4 relative shadow-md ${isSidebarCollapsed ? "w-0 md:w-16 px-0 md:px-3" : "md:w-[292px] px-4"} h-screen  md:h-full lg:rounded-xl  md:ring-1 md:ring-inset md:ring-[#8E8F98] bg-global-sidebar-bg transition-all duration-300`}
        >


          <div>
            <div className="relative pb-10">
              <div className="">
                <Link to={"/"} className={`flex justify-center gap-3 ${isSidebarCollapsed ? "hidden md:block" : ""}`}>
                  {isSidebarCollapsed ? <LogoCollpased /> : <DarkLogo />}
                </Link>
              </div>
            </div>
          </div>


          <div
            className="flex flex-col flex-1 pb-1 w-full gap-2  overflow-y-clip"
            style={{ height: "100%", overflowY: "auto" }}
          >
            {filteredMenu &&
              Object.keys(filteredMenu).map((key, index) => {
                const menuItems = filteredMenu[key];
                if (menuItems && menuItems?.length > 0) {
                  return (
                    <div key={index} className="flex flex-col gap-2">
                      {(key === "partners" || key === "account") && (
                        <Divider className="!bg-[#8E8F98]" />
                      )}
                      <div className="flex flex-col gap-2">
                        <div className="flex flex-col gap-1">
                          {key === "partners" || key === "compliancy" ? (
                            <CollapsMenu
                              isOpen={openSections[key]}
                              itemKey={key}
                              menuItems={menuItems}
                              pathname={pathname}
                              handleOpen={handleOpen}
                              closeSidebarSmallScreen={closeSidebarSmallScreen}
                            />
                          ) : (
                            menuItems?.map((item, itemIndex) => (
                              <TabItem item={item} key={itemIndex} pathname={pathname} closeSidebarSmallScreen={closeSidebarSmallScreen} />
                            ))
                          )}
                        </div>
                      </div>
                    </div>
                  );
                }
              })}
          </div>

          <div
            className={`flex flex-col gap-4 w-full ${isSidebarCollapsed ? "hidden md:block" : ""}`}
          >
            {
              <div
                id="upgradePlan"
                className={` flex-col pt-6 hidden  ${isSidebarCollapsed ? "hidden" : ""}`}
                style={{}}
              >
                {!isSidebarCollapsed && (
                  <div className="">
                    <div className="w-full bg-menu-item-surface-active h-[132px] p-4 rounded-xl ">
                      <div className="grid gap-4 text-sm text-global-theme">
                        <div>
                          <div className="flex justify-between items-center">
                            <div className="font-semibold">Upgrade plan</div>
                            <div>
                              <XCloseIcon
                                onClick={handleCloseSidebarMessage}
                                width={20}
                                height={20}
                                className="cursor-pointer"
                              />
                            </div>
                          </div>

                          <h3 className="font-normal pt-1 ">
                            Invite partners and get access to all{" "}
                            <span className="uppercase">PRO</span> features.
                          </h3>
                        </div>
                        <div className="flex items-center gap-3">
                          <Button
                            onClick={handleCloseSidebarMessage}
                            variant="link"
                            className="p-0 m-0 text-sm text-global-theme"
                          >
                            Dismiss
                          </Button>
                          <Link
                            to={`/workspace/${currentWorkspace?.user_workspace_id}/setting?selectedTab=plan`}
                          >
                            <Button
                              variant="link"
                              className="p-0 m-0 text-sm text-global-primary cursor-pointer"
                            >
                              Upgrade plan
                            </Button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            }

            <Divider className={isSidebarCollapsed ? "hidden" : "!bg-[#8E8F98]"} />

            <div
              className={`px-2 pt-1 pb-5 relative justify-end items-center gap-4 inline-flex ${isSidebarCollapsed ? "hidden" : ""}`}
            >
              <div className="grow shrink basis-0  justify-start items-center gap-2 flex">
                <div className="w-10 h-10 relative rounded-full">
                  <div className="flex justify-center items-center p-0 ring-1 ring-global-divider-soft rounded-full border-none text-btn-text-default bg-transparent">
                    {user && user?.image ? (
                      <div className="!w-10 h-10">
                        <img
                          src={`${user?.image}?t=${new Date().getTime()}`}
                          alt={`Profile`}
                          className="rounded-full !w-10 h-10"
                        />
                      </div>
                    ) : (
                      <div className="flex bg-global-surface justify-center items-center uppercase text-base font-semibold text-text-quaternary w-10 h-10  p-0 ring-1 ring-global-divider-soft  rounded-full border-none">
                        {user &&
                          `${user?.first_name?.substring(0, 1)}${user?.last_name?.substring(0, 1)}`}
                      </div>
                    )}
                  </div>
                  <div className="w-2.5 h-2.5 left-[30px] top-[30px] absolute bg-[#17b169] rounded-full border border-white" />
                </div>
                <div className="grid ">
                  <p className="text-sm leading-5 font-semibold text-white truncate">{greeting}</p>
                  <p className="text-sm font-normal leading-5 text-white truncate">
                    {user?.name}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            onClick={() => {
              dispatch(collapseSidebar());
              if (!isSidebarCollapsed) {
                setOpenSections(false);
              }
            }}
            className={` bg-menu-item-surface-active w-8 h-8 flex items-center justify-center rounded-md  cursor-pointer z-[100] ${isSidebarCollapsed ? "rotate-0 hidden md:flex " : "rotate-180 absolute bottom-4 right-4"}`}
            style={{
              boxShadow:
                "0px 1px 2px 0px rgba(10, 13, 18, 0.05),0px -2px 0px 0px rgba(10, 13, 18, 0.05) inset, 0px 0px 0px 1px rgba(10, 13, 18, 0.18) inset",
            }}
          >
            <IoIosArrowForward size={20} color="var(--color-white)" />
          </div>
          <Divider className={`${isSidebarCollapsed ? "block my-4" : "hidden"}`} />
          <div
            className={`px-2 pt-1 pb-5 relative justify-end items-center gap-4  ${isSidebarCollapsed ? "hidden md:inline-flex" : "hidden"}`}
          >
            <div className="grow shrink basis-0  justify-start items-center gap-2 flex">
              <div className="w-10 h-10 relative rounded-full">
                <div className="flex justify-center items-center p-0 ring-1 ring-global-divider-soft rounded-full border-none text-btn-text-default bg-transparent">
                  {user && user?.image ? (
                    <div className="!w-10 h-10">
                      <img
                        src={`${user?.image}?t=${new Date().getTime()}`}
                        alt={`Profile`}
                        className="rounded-full !w-10 h-10"
                      />
                    </div>
                  ) : (
                    <div className="flex bg-global-surface justify-center items-center uppercase text-base font-semibold text-text-quaternary w-10 h-10  p-0 ring-1 ring-global-divider-soft  rounded-full border-none">
                      {user &&
                        `${user?.first_name?.substring(0, 1)}${user?.last_name?.substring(0, 1)}`}
                    </div>
                  )}
                </div>
                <div className="w-2.5 h-2.5 left-[30px] top-[30px] absolute bg-[#17b169] rounded-full border border-white" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LeftSidebar;
