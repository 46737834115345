import React from "react";

const CompanyDetailsStepper = React.forwardRef(
  (
    { width = "48", height = "48", viewBox = "0 0 48 48", fill = "none", className, ...props },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_ddddii_10304_34178)">
          <rect x="4" y="3" width="40" height="40" rx="8" fill="white" />
          <rect x="4.5" y="3.5" width="39" height="39" rx="7.5" stroke="#E4E7EC" />
          <path
            d="M23.1667 22.1667H19.1667C18.2333 22.1667 17.7666 22.1667 17.4101 22.3483C17.0965 22.5081 16.8415 22.7631 16.6817 23.0767C16.5001 23.4332 16.5001 23.8999 16.5001 24.8333V30.5M31.5001 30.5V18.1667C31.5001 17.2332 31.5001 16.7665 31.3184 16.41C31.1586 16.0964 30.9037 15.8414 30.5901 15.6817C30.2335 15.5 29.7668 15.5 28.8334 15.5H25.8334C24.9 15.5 24.4333 15.5 24.0768 15.6817C23.7632 15.8414 23.5082 16.0964 23.3484 16.41C23.1667 16.7665 23.1667 17.2332 23.1667 18.1667V30.5M32.3334 30.5H15.6667M26.0834 18.8333H28.5834M26.0834 22.1667H28.5834M26.0834 25.5H28.5834"
            stroke="#344054"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <filter
            id="filter0_ddddii_10304_34178"
            x="0.25"
            y="0.5"
            width="47.5"
            height="47.5"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="1" />
            <feGaussianBlur stdDeviation="1" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.05 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_10304_34178"
            />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="1.25" />
            <feGaussianBlur stdDeviation="1.25" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.06 0"
            />
            <feBlend
              mode="normal"
              in2="effect1_dropShadow_10304_34178"
              result="effect2_dropShadow_10304_34178"
            />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="1.25" />
            <feGaussianBlur stdDeviation="1.875" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.1 0"
            />
            <feBlend
              mode="normal"
              in2="effect2_dropShadow_10304_34178"
              result="effect3_dropShadow_10304_34178"
            />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="1" />
            <feGaussianBlur stdDeviation="1" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.05 0"
            />
            <feBlend
              mode="normal"
              in2="effect3_dropShadow_10304_34178"
              result="effect4_dropShadow_10304_34178"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect4_dropShadow_10304_34178"
              result="shape"
            />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="-2" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.05 0"
            />
            <feBlend mode="normal" in2="shape" result="effect5_innerShadow_10304_34178" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feMorphology
              radius="1"
              operator="erode"
              in="SourceAlpha"
              result="effect6_innerShadow_10304_34178"
            />
            <feOffset />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.18 0"
            />
            <feBlend
              mode="normal"
              in2="effect5_innerShadow_10304_34178"
              result="effect6_innerShadow_10304_34178"
            />
          </filter>
        </defs>
      </svg>
    );
  },
);

export default CompanyDetailsStepper;
