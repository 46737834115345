// Assuming you're using rc-slider or similar
import Slider from "rc-slider";
import { useRef } from "react";


const TransparencySlider = ({ transparencyRange, onChange, maxValue, minValue }) => {
  const sliderRef = useRef(null);
  const getSliderPosition = (value) => {
    // Get the position relative to the slider container
    if (!sliderRef.current) return 0;
    const sliderWidth = sliderRef.current.offsetWidth;
    const minimumValue = minValue;
    const maximumValue = maxValue;
    return ((value - minimumValue) / (maximumValue - minimumValue)) * sliderWidth;
  };

  return (
    <div className="py-5 mt-9 px-6 relative w-full">
      <div className="flex gap-2 items-center relative h-full ">
        {/* Min value */}
        <div
          className="self-stretch h-[34px]  px-[11.5px] py-2 bg-white rounded-lg border border-[#e4e7ec] flex-col justify-center items-center flex"
          style={{
            position: "absolute",
            left: `${getSliderPosition(transparencyRange[0]) ? getSliderPosition(transparencyRange[0]) : 0}px`, // Positioning the min value div
            transform: "translateX(-50%)", // Center the div over the min slider thumb
            top: "-40px", // Adjust vertical positioning
          }}
        >
          <div className="text-center text-[#414651] text-xs font-semibold leading-[18px]">
            {transparencyRange[0]}
          </div>
        </div>

        <div
          ref={sliderRef}
          className="w-full relative"
          style={{ height: "25px" }} // Adjust as necessary
        >
          <Slider
            range
            max={maxValue}
            min={minValue}
            value={transparencyRange}
            onChange={onChange}
          />
        </div>

        {/* Max value */}
        <div
          className="self-stretch h-[34px] px-[11.5px] py-2 bg-white rounded-lg border border-[#e4e7ec] flex-col justify-center items-center flex"
          style={{
            position: "absolute",
            left: `${getSliderPosition(transparencyRange[1]) ? getSliderPosition(transparencyRange[1]) : window && window.innerWidth >= 768 ? 258 : 230}px`,
            transform: "translateX(-50%)", // Center the div over the max slider thumb
            top: "-40px", // Adjust vertical positioning
          }}
        >
          <div className="text-center text-[#414651] text-xs font-semibold leading-[18px]">
            {transparencyRange[1]}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransparencySlider;