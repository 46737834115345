const CustomDropdownCard = ({ children, className }) => {
  return (
    <div className="relative w-full">
      <div className="absolute z-50  py-1 w-full  rounded-lg focus:outline-none bg-global-theme ">
        <div
          className={`rounded-lg px-1.5 bg-btn-surface-secondary max-h-[320px] w-full md:w-[320px] border border-global-divider-soft overflow-y-auto ${className}`}
          style={{
            boxShadow:
              "0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08)",
          }}
        >
          <div className="grid grid-cols-1 gap-[2px] py-1">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default CustomDropdownCard;
