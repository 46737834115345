import React from "react";

const homeIcon = React.forwardRef(
  (
    {
      width = "20",
      height = "20",
      viewBox = "0 0 20 20",
      fill = "none",
      fillColor = "#11C182",
      className,
      ...props
    },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.5 8.80421C2.5 8.32558 2.5 8.08627 2.56169 7.86588C2.61633 7.67065 2.70614 7.48704 2.82669 7.32405C2.96278 7.14005 3.15168 6.99312 3.52949 6.69927L9.18141 2.30333C9.47418 2.07562 9.62057 1.96177 9.78221 1.918C9.92484 1.87938 10.0752 1.87938 10.2178 1.918C10.3794 1.96177 10.5258 2.07562 10.8186 2.30333L16.4705 6.69927C16.8483 6.99312 17.0372 7.14005 17.1733 7.32405C17.2939 7.48704 17.3837 7.67065 17.4383 7.86588C17.5 8.08627 17.5 8.32558 17.5 8.80421V14.8333C17.5 15.7667 17.5 16.2335 17.3183 16.59C17.1586 16.9036 16.9036 17.1585 16.59 17.3183C16.2335 17.5 15.7668 17.5 14.8333 17.5H5.16667C4.23325 17.5 3.76654 17.5 3.41002 17.3183C3.09641 17.1585 2.84144 16.9036 2.68166 16.59C2.5 16.2335 2.5 15.7667 2.5 14.8333V8.80421Z"
          stroke="#717680"
          strokeWidth="1.66667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  },
);
export default homeIcon;
