import React from "react";

const Generale = React.forwardRef(
  (
    {
      width = "40",
      height = "27",
      viewBox = "0 0 40 27",
      fill = "none",
      fillColor = "#11C182",
      className,
      ...props
    },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_12487_7254)">
          <mask
            id="mask0_12487_7254"
            style={{ maskType: "luminance" }}
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="40"
            height="27"
          >
            <path d="M40 0H0V26.6667H40V0Z" fill="white" />
          </mask>
          <g mask="url(#mask0_12487_7254)">
            <path
              d="M37.0645 0H2.93555C1.31688 0 0 1.29777 0 2.89295V23.7737C0 25.3688 1.31688 26.6667 2.93555 26.6667H37.0645C38.6832 26.6667 40 25.3688 40 23.7737V2.89295C40 1.29777 38.6832 0 37.0645 0ZM12.3044 17.363H16.2697C16.7455 17.363 17.131 17.743 17.131 18.2118C17.131 18.6807 16.7453 19.0607 16.2697 19.0607H12.3044C11.8286 19.0607 11.4431 18.6807 11.4431 18.2118C11.4431 17.743 11.8287 17.363 12.3044 17.363ZM3.725 8.84878C3.725 8.3799 4.11063 7.99995 4.58633 7.99995H9.74635C10.2221 7.99995 10.6077 8.37998 10.6077 8.84878V12.5253C10.6077 12.9942 10.222 13.3742 9.74635 13.3742H4.58633C4.11055 13.3742 3.725 12.9941 3.725 12.5253V8.84878ZM4.58633 17.363H8.55158C9.02737 17.363 9.41292 17.743 9.41292 18.2118C9.41292 18.6807 9.02728 19.0607 8.55158 19.0607H4.58633C4.11055 19.0607 3.725 18.6807 3.725 18.2118C3.725 17.743 4.11072 17.363 4.58633 17.363ZM18.7422 22.8353H4.58633C4.11055 22.8353 3.725 22.4553 3.725 21.9865C3.725 21.5177 4.11063 21.1377 4.58633 21.1377H18.7422C19.218 21.1377 19.6035 21.5177 19.6035 21.9865C19.6035 22.4553 19.218 22.8353 18.7422 22.8353ZM23.9877 19.0607H20.0225C19.5467 19.0607 19.1612 18.6807 19.1612 18.2118C19.1612 17.743 19.5468 17.363 20.0225 17.363H23.9877C24.4635 17.363 24.849 17.743 24.849 18.2118C24.849 18.6807 24.4635 19.0607 23.9877 19.0607ZM31.7058 19.0607H27.7405C27.2647 19.0607 26.8792 18.6807 26.8792 18.2118C26.8792 17.743 27.2648 17.363 27.7405 17.363H31.7058C32.1815 17.363 32.5672 17.743 32.5672 18.2118C32.5672 18.6807 32.1813 19.0607 31.7058 19.0607ZM35.4137 6.66075H26.0615C25.5857 6.66075 25.2002 6.28073 25.2002 5.81193C25.2002 5.34313 25.5858 4.9631 26.0615 4.9631H35.4137C35.8895 4.9631 36.275 5.34313 36.275 5.81193C36.275 6.28073 35.8893 6.66075 35.4137 6.66075Z"
              fill="#11C182"
            />
          </g>
        </g>
        <defs>
          <clipPath id="clip0_12487_7254">
            <rect width="40" height="26.6667" fill="white" />
          </clipPath>
        </defs>
      </svg>
    );
  },
);
export default Generale;
