import { IconButton, Menu } from "@mui/material";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  Action,
  DottedIcon,
  InvitePartnerIcon,
  LogoutIcon,
  MoreOptions,
  PlanIcon,
  ReturnIcon,
  Shock,
  TrashUpdateIcon,
  WithdrawProfile,
} from "assests";
import EmptyTable from "components/Card/EmptyTable";
import CancelButton from "components/MoreAction/CancelButton";
import DeleteButton from "components/MoreAction/DeleteButton";
import MoreActionOptionUpdated from "components/MoreAction/MoreActionOptionUpdated";
import ResendButton from "components/MoreAction/ResendButton";
import ViewButton from "components/MoreAction/ViewButton";
import SearchCreatePanelUpdate from "components/SearchCreatePanelUpdate";
import { Button } from "components/UI/Button";
import CommonDiscardButtonUpdated from "components/UI/CommonDiscardButtonUpdated";
import LimitReached from "components/UI/LimitReached";
import { LineProgressBar } from "components/UI/LineProgressBar";
import LoadingComponent from "components/UI/LoadingComponent";
import MemberTooltip from "components/UI/MemberToolptip";
import Modal from "components/UI/Modal";
import SortIcons from "components/UI/SortIcons";
import TertiaryPagination from "components/UI/TertiaryPagination";
import useCustomToaster from "hooks/useCustomToaster";
import usePermission from "hooks/usePermission";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { BsCircleFill } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  bulkDeleteInvitations,
  resendInvitation,
  updateInvitationStatus,
} from "services/invitation.services";
import { downloadSupplierAsCSV, getSuppliers } from "services/supplier.services";
import { setActionableCompanyType } from "store/accountDashboardSlice";
import { setRefetchBillingData } from "store/billingSlice";
import {
  setAllSupplierData,
  setSupplierList,
  toggleActionableSupplierId,
  toggleActionableSupplierWorkspaceId,
  toggleIsSupplierCreateModalOpen,
  toggleSupplierBulkDeleteModal,
  toggleSupplierCancleModal,
  toggleSupplierDeleteModal,
  toggleSupplierReachedModal,
  toggleSupplierResendModal,
  toggleActionableSupplierItem
} from "store/supplierSlice";
import { getInitials, transpercencyPercentageColors, truncateString } from "utils/helper";
import AddSupplier from "./AddSupplier";
import SupplierFilter from "./SupplierFilter";
import { leaveWorkspace } from "services/workspace.services";

const SupplierContents = ({ selectedOrders, setSelectedOrders }) => {
  const { hasPermission, checkPlanLimitation } = usePermission();
  const {
    isSupplierCreateModalOpen,
    actionableSupplierId,
    actionableSupplierWorkspaceId,
    deleteModal,
    cancleModal,
    resendModal,
    supplierList, allSupplierData, supplier, reachedModal, bulkDeleteModal, actionableSupplierItem
  } = useSelector((state) => state.supplierSlice);
  const { workspaceId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const queryClient = useQueryClient();
  const handleViewDetailsClick = (id, type = "supplier") => {
    navigate(`/workspace/${workspaceId}/supplier-details/${id}/${type}`, { replace: true });
  };
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const [status, setStatus] = useState(null);
  const [searchKey, setSearchKey] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const { showCustomToast } = useCustomToaster();
  const [partnerName, setPartnerName] = useState("");
  const [activeFilters, setActiveFilters] = useState({});
  const [filteredData, setFilteredData] = useState({});
  const [supplierOptions, setSupplierOptions] = useState([]);
  const { refetchBillingData, workspacePlan } = useSelector((state) => state.billingSlice);
  const { actionableCompanyType } = useSelector((state) => state.accountDashboardSlice);
  const { user, currentWorkspace, subscription, subscription_status } = useSelector(
    (state) => state.authenticationSlice,
  );
  const {
    data: suppliersData,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: [
      "suppliers",
      "getSuppliers",
      page,
      size,
      searchKey,
      orderBy,
      workspaceId,
      filteredData,
    ],
    queryFn: () =>
      getSuppliers({
        page,
        per_page: size,
        search: searchKey,
        sort: orderBy,
        workspace_id: parseInt(workspaceId),
        ...filteredData,
      }),
    enabled: !!page || !!size || !!searchKey || !orderBy || !workspaceId,
  });

  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
    if (actionableSupplierId) {
      dispatch(toggleActionableSupplierId());
    }
    if (actionableSupplierItem) {
      dispatch(toggleActionableSupplierItem());
    }
  };
  const handleSizeChange = (newSize) => {
    setSize(newSize);
    setPage(1);
  };
  const {
    data: articlesNopaginatedData,
    isLoading: isArticlesNopaginatedData,
    refetch: refetchNopaginatedData,
  } = useQuery({
    queryKey: ["suppliernopaginate", "getData", workspaceId],
    queryFn: () =>
      getSuppliers({
        workspace_id: parseInt(workspaceId),
        pagination: "no",
      }),
    enabled: !!workspaceId,
  });

  useEffect(() => {
    if (!isArticlesNopaginatedData && articlesNopaginatedData) {
      if (articlesNopaginatedData?.data?.data) {
        setSupplierOptions(articlesNopaginatedData?.data?.data);
      }
    }
  }, [articlesNopaginatedData, isArticlesNopaginatedData]);
  const handleActionClick = (item) => {
    const supplierId = item?.invitation_id;
    const supplierWorkspaceId = parseInt(item?.workspace_id);
    const itemStatus = item.status;
    if (supplierId) {
      dispatch(toggleActionableSupplierId(supplierId));
    } else {
      dispatch(toggleActionableSupplierId(supplierWorkspaceId));
    }
    dispatch(toggleActionableSupplierWorkspaceId(supplierWorkspaceId));
    dispatch(toggleActionableSupplierItem(item));
    setStatus(itemStatus);
  };

  useEffect(() => {
    if (!isLoading && suppliersData) {
      dispatch(setSupplierList({ supplierList: suppliersData?.data?.data?.data, append: false }));
      dispatch(setAllSupplierData({ allSupplierData: suppliersData?.data?.data, append: false }));
    }
  }, [suppliersData, isLoading]);

  const cancelInvitation = useMutation({
    mutationKey: ["inviation", "cancel-invitation"],
    mutationFn: (data) => updateInvitationStatus(data),
    onMutate: () => {
      showCustomToast(
        {
          title: "Cancelling....",
        },
        "cancelInvitation",
      );
    },
    onSuccess: (res) => {
      dispatch(toggleSupplierCancleModal());
      toast.dismiss("cancelInvitation");

      showCustomToast({
        title: "Successfully cancelled!",
        subtitle: "Invitation cancelled successfully",
      });
      dispatch(toggleActionableSupplierId());
      refetch();
      if (!refetchBillingData) {
        dispatch(setRefetchBillingData(true));
      }
    },
    onError: (error) => {
      toast.dismiss("cancelInvitation");

      showCustomToast({
        title: error.message || "Something went wrong",
      });
    },
  });

  const confirmCancelInvitation = () => {
    dispatch(toggleSupplierCancleModal());
    const mutationData = {
      id: actionableSupplierId,
      body: { status: "cancelled" },
    };
    cancelInvitation.mutate(mutationData, {
      onSuccess: () => {
        refetch();
        dispatch(toggleSupplierCancleModal());
        if (!refetchBillingData) {
          dispatch(setRefetchBillingData(true));
        }
      },
    });
  };

  const handleOnCancle = () => {
    if (!actionableSupplierId && !supplier) return;
    confirmCancelInvitation();
  };

  const closeModal = () => {
    if (isSupplierCreateModalOpen) {
      dispatch(toggleIsSupplierCreateModalOpen());
    }
    if (actionableSupplierId) {
      dispatch(toggleActionableSupplierId());
    }

    if (deleteModal) {
      dispatch(toggleSupplierDeleteModal());
    }

    if (cancleModal) {
      dispatch(toggleSupplierCancleModal());
    }
    if (resendModal) {
      dispatch(toggleSupplierResendModal());
    }
    if (actionableSupplierWorkspaceId) {
      dispatch(toggleActionableSupplierWorkspaceId());
    }
    if (reachedModal) {
      dispatch(toggleSupplierReachedModal());
    }
    if (bulkDeleteModal) {
      dispatch(toggleSupplierBulkDeleteModal());
    }
    if (showUpgradeModal) {
      setShowUpgradeModal(false);
    }
    if (actionableCompanyType) {
      dispatch(setActionableCompanyType(null));
    }
    if (actionableSupplierItem) {
      dispatch(toggleActionableSupplierItem());
    }
  };

  const handleClickOptionButton = (supplierId, supplierWorkspaceId, actionType) => {
    switch (actionType) {
      case "cancel":
        dispatch(toggleActionableSupplierId(supplierId));
        dispatch(toggleActionableSupplierWorkspaceId(supplierWorkspaceId));
        dispatch(toggleSupplierCancleModal());
        setSelectedOrders([]);
        setAnchorEl(null);
        break;
      case "delete":
        dispatch(toggleActionableSupplierId(supplierId));
        dispatch(toggleActionableSupplierWorkspaceId(supplierWorkspaceId));
        dispatch(toggleSupplierDeleteModal());
        setSelectedOrders([]);
        setAnchorEl(null);
        break;
      case "resend":
        dispatch(toggleActionableSupplierId(supplierId));
        dispatch(toggleActionableSupplierWorkspaceId(supplierWorkspaceId));
        resendRetailerInvitation.mutate(supplierId);
        setAnchorEl(null);
        setSelectedOrders([]);
        break;
      default:
        break;
    }
  };

  const openCreateModal = () => {
    dispatch(setActionableCompanyType("supplier"));
    dispatch(toggleIsSupplierCreateModalOpen());
    setSelectedOrders([]);
    if (actionableSupplierId) {
      dispatch(toggleActionableSupplierId());
    }
    if (actionableSupplierItem) {
      dispatch(toggleActionableSupplierItem());
    }
  };
  const handleCreateAction = () => {
    if (
      (currentWorkspace &&
        currentWorkspace.is_owner &&
        (!subscription ||
          (subscription && (!subscription_status || subscription_status === "canceled")))) ||
      !workspacePlan
    ) {
      setShowUpgradeModal(true);
    } else if (!checkPlanLimitation.canCreatePartner) {
      dispatch(toggleSupplierReachedModal());
    } else {
      openCreateModal();
      setShowUpgradeModal(false);
    }
  };

  const openBulkDeleteModal = (e) => {
    e.stopPropagation();
    setAnchorEl(null);
    dispatch(toggleSupplierBulkDeleteModal());
  };

  const handleSearch = (e) => {
    const searchText = e.target.value?.trim();
    if (searchText.length >= 2) {
      setSearchKey(searchText);
    } else {
      setSearchKey("");
    }
  };

  const handleTableRowClick = (status, orderId) => {
    if (status === "accepted") {
      return handleViewDetailsClick(orderId, status);
    } else {
      return;
    }
  };

  const resendRetailerInvitation = useMutation({
    mutationKey: ["inviationResend", "resend-invitation"],
    mutationFn: (data) => resendInvitation(data),
    onMutate: () => {
      showCustomToast(
        {
          title: "Resending invitation....",
        },
        "resendInvitation",
      );
    },
    onSuccess: (res) => {
      toast.dismiss("resendInvitation");
      showCustomToast({
        title: "Successfully sent!",
        subtitle: "Invitation resent successfully",
      });
      closeModal();
      refetch();
      if (!refetchBillingData) {
        dispatch(setRefetchBillingData(true));
      }
    },
    onError: (error) => {
      toast.dismiss("resendInvitation");
      toast.error(error.message || "Something went wrong");
      showCustomToast({
        title: error.message || "Something went wrong",
      });
    },
  });

  const downloadSuppliersAsCSV = useMutation({
    mutationKey: ["suppiers", "download"],
    mutationFn: (params) => downloadSupplierAsCSV(params),
    onMutate: () => {
      showCustomToast(
        {
          title: "Downloading...",
        },
        "downloadSuppliers",
      );
    },
    onSuccess: (res) => {
      toast.dismiss("downloadSuppliers");
      showCustomToast({
        title: "Downloaded successfully",
      });
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "suppliers.csv");
      document.body.appendChild(link);
      link.click();
      link.remove();
      queryClient.invalidateQueries(["retailers", "download"]);
    },
    onError: (error) => {
      toast.dismiss("downloadSuppliers");
      showCustomToast({
        title: error.response.data.message || error.message || "Something went wrong!",
      });
    },
  });

  const handleDownloadCSV = () => {
    downloadSuppliersAsCSV.mutate({
      workspace_id: parseInt(workspaceId),
    });
  };

  const onClickCheckbox = (retailer) => {
    if (retailer && retailer.invitation_id) {
      const findedItem = selectedOrders.some((item) => item.invitation_id === retailer.invitation_id);
      if (!findedItem) {
        setSelectedOrders((prevSelectedOrders) => [...prevSelectedOrders, retailer]);
      } else {
        setSelectedOrders((prevSelectedOrders) =>
          prevSelectedOrders.filter((item) => item.invitation_id !== retailer.invitation_id),
        );
      }
    }
  };

  const handleSelectAll = (e) => {
    if (!e.target.checked || !supplierList.length > 0) {
      setSelectedOrders([]);
      return;
    }
    const invitationIds = supplierList.length > 0 && supplierList.filter((item) => item.invitation_id).map((inviation) => inviation);
    setSelectedOrders(invitationIds);
  };

  const refetchDatas = () => {
    refetch();
    refetchNopaginatedData();
  };

  const deleteAllMutation = useMutation({
    mutationKey: ["retailers", "delete"],
    mutationFn: (data) => bulkDeleteInvitations(data),
    onMutate: () => {
      showCustomToast(
        {
          title: "Deleting...",
        },
        "deleteSupplier",
      );
    },
    onSuccess: (response) => {
      toast.dismiss("deleteSupplier");
      if (response.data?.failed?.length === 0) {
        showCustomToast({
          title: "Suppliers deleted successfully",
        });
      } else {
        showCustomToast({
          title: "Suppliers can not be deleted",
        });
      }
      closeModal();
      if (!refetchBillingData) {
        dispatch(setRefetchBillingData(true));
      }
      refetchDatas();
      setSelectedOrders([]);
      queryClient.invalidateQueries(["suppliers", "get", "delete"]);
    },
    onError: (error) => {
      toast.dismiss("deleteSupplier");
      showCustomToast({
        title: error.response?.data?.message || error.message,
      });
    },
    onSettled: () => {
      toast.dismiss("deleteSupplier");
    },
  });



  const handleLeaveWorkspace = useMutation({
    mutationKey: ["leaveWorkspace", "delete"],
    mutationFn: (data) => leaveWorkspace(data),
    onMutate: () => {
      showCustomToast(
        {
          title: "Leaving...",
        },
        "leaveWorkspace",
      );
    },
    onSuccess: (res) => {
      toast.dismiss("leaveWorkspace");
      showCustomToast({
        title: res.data.message || "Left workspace successfully",
      });
      refetchDatas();
      closeModal();
      if (!refetchBillingData) {
        dispatch(setRefetchBillingData(true));
      }
      queryClient.invalidateQueries(["leaveWorkspace", "get", "delete"]);
    },
    onError: (error) => {
      toast.dismiss("leaveWorkspace");
      showCustomToast({
        title: error.response.data.message || error.message,
      });
    },
    onSettled: () => {
      toast.dismiss("leaveWorkspace");
    },
  });
  const handleOnDelete = () => {
    if (!actionableSupplierId) return;
    if (actionableSupplierId && actionableSupplierItem && actionableSupplierItem.invitation_id) {
      deleteAllMutation.mutate({ invitation_id: [actionableSupplierId] });
    } else {
      handleLeaveWorkspace.mutate({
        workspace_id: parseInt(actionableSupplierWorkspaceId),
      });
    }
  };

  const handleDeleteAll = () => {
    const allIds = selectedOrders.map((item) => item.invitation_id);
    if (allIds) {
      deleteAllMutation.mutate({ invitation_id: allIds });
    }
  };

  useEffect(() => {
    if (user && currentWorkspace) {
      const handleKeyDown = (e) => {
        const isMac = e.metaKey; // `metaKey` for Cmd on Mac
        const isWindows = e.ctrlKey; // `ctrlKey` for Ctrl on Windows
        if ((isMac || isWindows) && e.key.toLowerCase() === "p") {
          e.preventDefault();
          if ((status === "accepted" || status === "removed") && actionableSupplierWorkspaceId) {
            setAnchorEl(null);
            handleViewDetailsClick(actionableSupplierWorkspaceId, status);
          }
        }
        if (e.key === "Delete" && hasPermission("deleteSupplier")) {
          e.preventDefault();
          setAnchorEl(null);
          if (actionableSupplierId && status) {
            if (status === "accepted") {
              handleClickOptionButton(
                actionableSupplierId,
                actionableSupplierWorkspaceId,
                "delete",
              );
            } else if (status === "pending") {
              handleClickOptionButton(
                actionableSupplierId,
                actionableSupplierWorkspaceId,
                "cancel",
              );
            }
          } else if (selectedOrders.length > 0) {
            dispatch(toggleSupplierBulkDeleteModal());
          }
        }
        if (
          (e.ctrlKey || e.metaKey) &&
          (e.key === "B" || e.key === "b") &&
          hasPermission("canAddSupplier")
        ) {
          e.preventDefault();
          setAnchorEl(null);
          handleCreateAction();
          if (actionableSupplierId) {
            dispatch(toggleActionableSupplierId());
          }
        }
        // Resend Shortcut: Cmd+R (Mac) or Ctrl+R (Windows)
        if (
          (isMac || isWindows) &&
          e.shiftKey &&
          (e.key === "I" || e.key === "i") &&
          hasPermission("deleteSupplier")
        ) {
          e.preventDefault();
          if (actionableSupplierId && status === "pending") {
            setAnchorEl(null);
            handleClickOptionButton(actionableSupplierId, actionableSupplierWorkspaceId, "resend");
          }
        }
        if (
          (isMac || isWindows) &&
          e.shiftKey &&
          (e.key === "C" || e.key === "c") &&
          hasPermission("deleteSupplier")
        ) {
          e.preventDefault();
          if (actionableSupplierId && status === "pending") {
            setAnchorEl(null);
            handleClickOptionButton(actionableSupplierId, actionableSupplierWorkspaceId, "cancel");
          }
        }
        if (
          (e.ctrlKey || e.metaKey) &&
          (e.key === "A" || e.key === "a") &&
          hasPermission("deleteSupplier")
        ) {
          e.preventDefault();
          setAnchorEl(null);
          if (actionableSupplierId) {
            dispatch(toggleActionableSupplierId());
          }
          if (actionableSupplierItem) {
            dispatch(toggleActionableSupplierItem());
          }
          const eligibleTemplates = selectedOrders;
          const allChecked =
            eligibleTemplates?.length > 0 && selectedOrders.length === eligibleTemplates.length;
          const newSelectAllState = !allChecked;
          const checkboxEvent = { target: { checked: newSelectAllState } };
          handleSelectAll(checkboxEvent);
        }
      };
      window.addEventListener("keydown", handleKeyDown);
      return () => {
        window.removeEventListener("keydown", handleKeyDown);
      };
    }
  }, [
    user,
    currentWorkspace,
    dispatch,
    actionableSupplierId,
    actionableSupplierWorkspaceId,
    handleClickOptionButton,
    handleViewDetailsClick,
  ]);
  return (
    <>
      <Modal open={isSupplierCreateModalOpen} close={closeModal} title={""}>
        <AddSupplier closeModal={closeModal} invitationListRefetch={refetch} />
      </Modal>
      <Modal open={reachedModal} close={closeModal}>
        <LimitReached
          closeModal={closeModal}
          IconComponent={Action}
          title="Partner limit reached"
          subText1={`You cannot add a new business partner  as you have reached the maximum limit of ${workspacePlan && workspacePlan?.package?.partner_links} partners in your ${workspacePlan && workspacePlan?.package?.name} plan. Please reduce your usage or upgrade your plan.`}
          className={"w-full  lg:w-[600px]"}
          linked={workspacePlan && workspacePlan?.partner_links_count}
          totalLink={workspacePlan && workspacePlan?.package?.partner_links}
          linkedtitle={"Business Partners"}
          confirmButtonText={currentWorkspace && !currentWorkspace?.is_owner ? null : "Ugrade Plan"}
          confirmButtonVariant={"primary"}
          confirmButtonClassName={"w-full h-11"}
          onConfirmClick={() => {
            navigate(`/workspace/${currentWorkspace?.user_workspace_id}/setting?selectedTab=plan`);
            if (reachedModal) {
              dispatch(toggleSupplierReachedModal());
            }
          }}
          icon={
            <Shock width={20} height={20} strokeColor={"var(--color-icon-fill-secondary-button)"} />
          }
        />
      </Modal>
      <Modal open={deleteModal} close={closeModal} title={""}>
        <CommonDiscardButtonUpdated
          closeModal={closeModal}
          title={actionableSupplierItem && actionableSupplierItem.invitation_id ? `Remove ${partnerName}?` : "Leave workspace"}
          subText1={actionableSupplierItem && actionableSupplierItem.invitation_id ? `Are you sure you want to remove ${partnerName} from your account? All active orders associated with this partner will be changed to draft status. This action is irreversible and will terminate any ongoing collaboration with ${partnerName}.` : "Are you sure you want to leave selected workspace? This action cannot be undone."}
          className="w-full lg:max-w-[600px]"
          IconComponent={actionableSupplierItem && actionableSupplierItem.invitation_id ? InvitePartnerIcon : DottedIcon}
          confirmButtonText={actionableSupplierItem && actionableSupplierItem.invitation_id ? "Remove" : "Confirm"}
          confirmButtonVariant={actionableSupplierItem && actionableSupplierItem.invitation_id ? "warning" : "primary"}
          confirmButtonClassName={"w-full h-11"}
          onConfirmClick={handleOnDelete}
          icon={
            actionableSupplierItem && actionableSupplierItem.invitation_id ?
              <TrashUpdateIcon
                width={20}
                height={20}
                strokeColor={"var(--color-icon-fill-secondary-button)"}
              />
              : <LogoutIcon />
          }
        />
      </Modal>
      <Modal open={bulkDeleteModal} close={closeModal}>
        <CommonDiscardButtonUpdated
          closeModal={closeModal}
          title={`Remove ${selectedOrders.length === 1 ? "partner" : "partners"}`}
          subText1={`Are you sure you want to remove ${selectedOrders.length === 1 ? "partner" : "partners"
            } from your account? All active orders associated with ${selectedOrders.length === 1 ? "this partner" : "these partners"
            } will be changed to draft status. This action is irreversible and will terminate any ongoing collaboration with ${selectedOrders.length === 1 ? "this partner" : "these partners"
            }.`}
          className="w-full lg:max-w-[600px]"
          IconComponent={InvitePartnerIcon}
          confirmButtonText="Remove"
          confirmButtonVariant="warning"
          confirmButtonClassName={"w-full h-11"}
          onConfirmClick={handleDeleteAll}
          icon={
            <TrashUpdateIcon
              width={20}
              height={20}
              strokeColor={"var(--color-icon-fill-secondary-button)"}
            />
          }
        />
      </Modal>
      <Modal open={cancleModal} close={closeModal} title={""}>
        <CommonDiscardButtonUpdated
          closeModal={closeModal}
          title="Withdraw invitation?"
          subText1="You are about to withdraw the invitation. This action will cancel the previously sent invitation and the invitee will lose access to this workspace."
          className="w-full lg:max-w-[600px]"
          IconComponent={WithdrawProfile}
          confirmButtonText="Withdraw Invite"
          confirmButtonVariant="primary"
          confirmButtonClassName={"w-full h-11"}
          onConfirmClick={handleOnCancle}
          icon={<ReturnIcon width={20} height={20} />}
        />
      </Modal>
      <Modal open={showUpgradeModal} close={closeModal}>
        <CommonDiscardButtonUpdated
          closeModal={closeModal}
          title="Upgrade plan"
          subText1={
            currentWorkspace && !currentWorkspace?.is_owner && !workspacePlan
              ? `Your partner workspace is currently on a free plan.`
              : `You are currently on a free plan. Upgrade to a paid plan to start creating your own ${user && user?.workspace_type} workspaces.`
          }
          className="w-full lg:max-w-[544px]"
          confirmButtonClassName={"w-full h-11"}
          IconComponent={PlanIcon}
          confirmButtonText={
            currentWorkspace && !currentWorkspace?.is_owner && !workspacePlan ? null : "Ugrade Plan"
          }
          confirmButtonVariant="primary"
          onConfirmClick={() => {
            navigate(`/workspace/${currentWorkspace?.user_workspace_id}/setting?selectedTab=plan`);
            if (showUpgradeModal) {
              setShowUpgradeModal(false);
            }
          }}
          icon={
            <Shock width={20} height={20} strokeColor={"var(--color-icon-fill-secondary-button)"} />
          }
        />
      </Modal>
      <div className="bg-btn-surface-secondary border border-border-divider-stroke   rounded-xl custom-card w-full  mb-6">
        <SearchCreatePanelUpdate
          createTxt="Invite Supplier"
          title="Suppliers"
          handleDownloadCSV={handleDownloadCSV}
          selectedItems={selectedOrders}
          openDeleteModal={openBulkDeleteModal}
          btnText={allSupplierData && allSupplierData.total}
          onBtnClick={handleCreateAction}
          onChange={handleSearch}
          permissionText="canAddSupplier"
          permissionText2="deleteSupplier"
          classWidth="w-full md:w-[150px] whitespace-nowrap"
        >
          <SupplierFilter
            {...{
              activeFilters,
              setActiveFilters,
              setFilteredData,
              supplierOptions,
            }}
          />
        </SearchCreatePanelUpdate>

        <div className="w-full max-w-screen overflow-x-auto ">
          <table className="w-full border-spacing-0 table-auto rounded-table-custom overflow-x-auto">
            <thead className="text-sm text-left whitespace-nowrap text-global-paragraph">
              <tr className="border-b  border-t border-b-global-divider-soft border-t-global-divider-soft">
                <th className="pl-6 pr-4 py-[11.5px]">
                  <div className="flex items-center gap-3">
                    <input
                      type="checkbox"
                      className={`rounded-md  w-5 h-5 ${supplierList && supplierList.length > 0 ? "cursor-pointer" : "cursor-default"}`}
                      onChange={handleSelectAll}
                      checked={
                        supplierList && supplierList?.filter((item) => item.invitation_id).length > 0 &&
                        selectedOrders.length === supplierList?.filter((item) => item.invitation_id).length
                      }
                    />
                    <div className="flex items-center">
                      <span className="mr-1 text-xs font-medium text-global-paragraph leading-[18px]">
                        Partner Name
                      </span>
                      <SortIcons
                        hasData={supplierList && supplierList.length > 0}
                        sortName="name"
                        {...{ orderBy, setOrderBy }}
                      />
                    </div>
                  </div>
                </th>

                <th className="relative px-4 py-[11.5px]  ">
                  <div className="flex items-center">
                    <span className="mr-1 text-xs font-medium text-global-paragraph leading-[18px]">
                      Status
                    </span>
                    <SortIcons
                      hasData={supplierList && supplierList.length > 0}
                      sortName="status"
                      {...{ orderBy, setOrderBy }}
                    />
                  </div>
                </th>

                <th className="relative px-4 py-[11.5px]  ">
                  <div className="flex items-center">
                    <span className="mr-1 text-xs font-medium text-global-paragraph leading-[18px]">
                      Active Orders
                    </span>
                    <SortIcons
                      hasData={supplierList && supplierList.length > 0}
                      sortName="orders_count"
                      {...{ orderBy, setOrderBy }}
                    />
                  </div>
                </th>
                <th className="relative px-4 py-[11.5px]  ">
                  <div className="flex items-center">
                    <span className="mr-1 text-xs font-medium text-global-paragraph leading-[18px]">
                      Audits
                    </span>
                    <SortIcons
                      hasData={supplierList && supplierList.length > 0}
                      sortName="audits_count"
                      {...{ orderBy, setOrderBy }}
                    />
                  </div>
                </th>
                <th className="relative px-4 py-[11.5px]  ">
                  <div className="flex items-center">
                    <span className="mr-1 text-xs font-medium text-global-paragraph leading-[18px]">
                      Transparency %
                    </span>
                    <SortIcons
                      hasData={supplierList && supplierList.length > 0}
                      sortName="transparency_score"
                      {...{ orderBy, setOrderBy }}
                    />
                  </div>
                </th>
                <th className="relative px-4 py-[11.5px]">
                  <div className="flex items-center">
                    <span className="mr-1 text-xs font-medium text-global-paragraph leading-[18px]">
                      Verification %
                    </span>
                    <SortIcons
                      hasData={supplierList && supplierList.length > 0}
                      sortName="verification_score"
                      {...{ orderBy, setOrderBy }}
                    />
                  </div>
                </th>
                <th className="relative px-4 py-[11.5px]">
                  <div className="flex items-center">
                    <span className="mr-1 text-xs font-medium text-global-paragraph leading-[18px]">
                      Type
                    </span>
                    <SortIcons
                      hasData={supplierList && supplierList.length > 0}
                      sortName="company_type"
                      {...{ orderBy, setOrderBy }}
                    />
                  </div>
                </th>
                <th className="relative px-4 py-[11.5px]">
                  <div className="flex items-center">
                    <span className="mr-1 text-xs font-medium text-global-paragraph leading-[18px]">
                      Tier
                    </span>
                    <SortIcons
                      hasData={supplierList && supplierList.length > 0}
                      sortName="type"
                      {...{ orderBy, setOrderBy }}
                    />
                  </div>
                </th>
                <th className="relative px-4 py-[11.5px]">
                  <div className="flex items-center">
                    <span className="mr-1 text-xs font-medium text-global-paragraph leading-[18px]">
                      Certificates
                    </span>
                    <SortIcons
                      hasData={supplierList && supplierList.length > 0}
                      sortName="certificates"
                      {...{ orderBy, setOrderBy }}
                    />
                  </div>
                </th>

                <th className="relative px-4  py-[11.5px]  ">
                  <div className="flex items-center">
                    <span className="mr-1 text-xs font-medium text-global-paragraph leading-[18px]">
                      Street
                    </span>
                    <SortIcons
                      hasData={supplierList && supplierList.length > 0}
                      sortName="street"
                      {...{ orderBy, setOrderBy }}
                    />
                  </div>
                </th>
                <th className="relative px-4 py-[11.5px]">
                  <div className="flex items-center">
                    <span className="mr-1 text-xs font-medium text-global-paragraph leading-[18px]">
                      City
                    </span>
                    <SortIcons
                      hasData={supplierList && supplierList.length > 0}
                      sortName="region"
                      {...{ orderBy, setOrderBy }}
                    />
                  </div>
                </th>

                <th className="relative px-4 py-[11.5px]">
                  <div className="flex items-center">
                    <span className="mr-1 text-xs font-medium text-global-paragraph leading-[18px]">
                      Country
                    </span>
                    <SortIcons
                      hasData={supplierList && supplierList.length > 0}
                      sortName="country"
                      {...{ orderBy, setOrderBy }}
                    />
                  </div>
                </th>

                <th className="relative px-4 py-[11.5px]  ">
                  <div className="flex items-center">
                    <span className="mr-1"> </span>
                  </div>
                </th>
              </tr>
            </thead>

            <tbody>
              {!isLoading && supplierList && supplierList?.length > 0 ? (
                supplierList?.map((item, index) => (
                  <tr
                    key={index}
                    onClick={() => {
                      handleActionClick(
                        item
                      );
                      handleTableRowClick(item?.status, parseInt(item?.workspace_id));
                    }}

                    className={`whitespace-nowrap border-b last:border-0 border-b-global-divider-soft  ${index % 2 === 0 ? "bg-global-white" : "bg-white"} rounded-lg text-global-paragraph text-sm font-normal ${item?.status === "accepted" ? "cursor-pointer" : "cursor-default"} `}
                  >
                    <td className="pl-6 pr-4 py-[15.5px]  ">
                      <div className="flex items-center gap-3">
                        <input
                          onClick={(e) => e.stopPropagation()}
                          onChange={() =>
                            onClickCheckbox(item)
                          }
                          checked={selectedOrders.some(
                            (order) => order.invitation_id && (order.invitation_id === item.invitation_id),
                          )}
                          type="checkbox"
                          className="rounded-md border cursor-pointer w-5 h-5  "
                        />
                        <div className="flex justify-start items-center gap-3">
                          {item && item?.name && (
                            <div className="flex bg-global-surface uppercase justify-center items-center text-xs md:text-base font-semibold text-global-paragraph leading-6 w-8 h-8 md:h-10 md:w-10 p-2 ring-1 ring-global-divider-medium ring-opacity-75 ring-inset rounded-full border-none">
                              {item && item?.name ? getInitials(item?.name) : ""}
                            </div>
                          )}
                          <div className="flex flex-col">
                            <p className="text-sm leading-5 font-medium text-global-title capitalize">
                              {item?.name}
                            </p>
                            <p className="text-sm text-global-gray-brand leading-5">
                              {item?.country}
                            </p>
                          </div>
                        </div>
                      </div>
                    </td>

                    <td className=" px-4 py-[15.5px]  ">
                      <div className="flex items-center">
                        {item?.status ? (
                          <Button
                            variant={"status"}
                            className={
                              " flex justify-center items-center gap-1 px-1.5 py-[2px]  h-[22px] text-global-gray-brand font-medium text-xs leading-[18px]  border border-border-divider-stroke custom-card cursor-default "
                            }
                          >
                            <BsCircleFill
                              className={`w-1.5 h-1.5 ${item?.status === "accepted" ? "text-global-primary" : "text-global-paragraph"}`}
                            />
                            {item.status.toLowerCase() === "accepted"
                              ? "Active"
                              : item.status.toLowerCase() === "pending"
                                ? "Invite Pending"
                                : item.status}
                          </Button>
                        ) : null}
                      </div>
                    </td>
                    <td className="relative px-4 py-[15.5px] ">{item?.active_orders_count}</td>
                    <td className="relative px-4 py-[15.5px] ">{item?.audits_count}</td>
                    <td className="relative px-4 py-[15.5px] text-sm text-global-paragraph leading-5 w-auto ">
                      <div className="flex items-center gap-3">
                        <LineProgressBar
                          className="h-2 w-full lg:w-[164px]"
                          variant={transpercencyPercentageColors(
                            item?.transparency_score ? item?.transparency_score : 0,
                          )}
                          progress={item?.transparency_score ? item?.transparency_score : 0}
                        />
                        <span>{`${item?.transparency_score ? item?.transparency_score : 0}%`}</span>
                      </div>
                    </td>

                    <td className="relative px-4 py-[15.5px] text-sm text-global-paragraph leading-5 w-auto  ">
                      <div className="flex items-center gap-3">
                        <LineProgressBar
                          className="h-2 w-full lg:w-[164px]"
                          variant={"blue"}
                          progress={item?.verification_score ? item?.verification_score : 0}
                        />
                        <span>{`${item?.verification_score ? item?.verification_score : 0}%`}</span>
                      </div>
                    </td>
                    <td className="relative px-4 py-[15.5px] text-sm text-global-paragraph leading-5  ">
                      <Button
                        variant={"status"}
                        className={`rounded-[6px] w-auto h-[22px] px-1.5 py-[2px] border border-[#D5D7DA] custom-card cursor-default capitalize   text-status-text-default  text-xs leading-[18px] font-medium `}
                      >
                        Cut-and-Sew Factory
                      </Button>
                    </td>
                    <td className="relative px-4 py-[15.5px] ">1</td>
                    <td className="relative px-4 py-[15.5px] ">
                      <div className="flex items-center">
                        {item?.certificates && item?.certificates?.length > 0 ? (
                          <div className="flex items-center gap-1">
                            {item?.certificates?.slice(0, 2)?.map((item, index) => (
                              <Button
                                key={index}
                                variant={"status"}
                                className={`rounded-[6px] h-[22px] px-2 py-[2px] truncate text-status-text-default  custom-card text-xs leading-[18px]  font-medium cursor-default capitalize ring-0 border border-border-divider-stroke`}
                              >
                                {item?.certified_by?.name &&
                                  truncateString(item?.certified_by?.name)}
                              </Button>
                            ))}
                            {item?.certificates.length > 2 && (
                              <MemberTooltip
                                length={item?.certificates?.length - 2}
                                children={
                                  <>
                                    {item?.certificates?.map((item, index) => (
                                      <Button
                                        key={index}
                                        variant={"status"}
                                        className={`rounded-[6px] h-[22px] mb-2 px-2 py-[2px] truncate text-status-text-default  custom-card text-xs leading-[18px]  font-medium cursor-default capitalize ring-0 border border-border-divider-stroke`}
                                      >
                                        {item?.certified_by?.name &&
                                          truncateString(item?.certified_by?.name)}
                                      </Button>
                                    ))}
                                  </>
                                }
                              />
                            )}
                          </div>
                        ) : null}
                      </div>
                    </td>
                    <td className="relative px-4 py-[15.5px] text-sm text-global-paragraph leading-5  ">
                      <div className="flex items-center">
                        <span>{item?.street ? truncateString(item?.street) : null}</span>
                      </div>
                    </td>
                    <td className="relative px-4 py-[15.5px] text-sm text-global-paragraph leading-5  ">
                      <div className="flex items-center">
                        <span>{item?.region ? truncateString(item?.region) : null}</span>
                      </div>
                    </td>
                    <td className="relative px-4 py-[15.5px] text-sm text-global-paragraph leading-5  ">
                      <div className="flex items-center">
                        <span>{item?.country ? truncateString(item?.country) : null}</span>
                      </div>
                    </td>

                    <td
                      onClick={(e) => {
                        e.stopPropagation();
                        setAnchorEl(null);
                      }}
                      className="relative cursor-pointer px-4 py-[15.5px] text-sm text-global-paragraph leading-5  "
                    >
                      <IconButton
                        aria-label="more"
                        id={`${index}`}
                        aria-controls={open ? "long-menu" : undefined}
                        aria-expanded={open ? "true" : undefined}
                        aria-haspopup="true"
                        onClick={(e) => {
                          handleActionClick(item);
                          setAnchorEl(e.currentTarget);
                          setPartnerName(item.name);
                          setSelectedOrders([]);
                          e.stopPropagation();
                        }}
                      >
                        <MoreOptions width={20} height={20} />
                      </IconButton>
                      {!isSupplierCreateModalOpen &&
                        actionableSupplierId &&
                        (item.invitation_id
                          ? parseInt(actionableSupplierId) === parseInt(item?.invitation_id)
                          : parseInt(actionableSupplierId) === parseInt(item.workspace_id)) && (
                          <Menu
                            id="long-menu"
                            MenuListProps={{
                              "aria-labelledby": "long-button",
                            }}
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            anchorOrigin={{
                              vertical: "top",
                              horizontal: "left",
                            }}
                            elevation={0}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "right",
                            }}
                            PaperProps={{
                              style: {
                                maxHeight: 45 * 4.5,
                                borderRadius: "8px",
                                border: "1px solid var(--color-border-secondary)",
                                padding: 0,
                                backgroundColor: "transparent",
                                boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.05)",
                              },
                            }}
                          >
                            <MoreActionOptionUpdated
                              viewButton={
                                (item?.status === "accepted" || item?.status === "removed") && (
                                  <ViewButton
                                    onClick={() =>
                                      handleViewDetailsClick(
                                        parseInt(item?.workspace_id),
                                        item.status,
                                      )
                                    }
                                    className={"w-[236px]"}
                                    shortcut="⌘+P"
                                    title="View partner"
                                  />
                                )
                              }
                              deleteButton={
                                item?.status === "accepted" &&
                                hasPermission("deleteSupplier") && (
                                  <DeleteButton
                                    title={item.invitation_id ? "Remove" : "Leave"}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleClickOptionButton(
                                        item.invitation_id ? item.invitation_id : item.workspace_id,
                                        parseInt(item.workspace_id),
                                        "delete"
                                      );
                                    }}
                                    className={"w-[236px]"}
                                    shortcut="⌘+Delete"
                                  />
                                )
                              }
                              cancelButton={
                                item?.status === "pending" &&
                                hasPermission("deleteSupplier") && (
                                  <CancelButton
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleClickOptionButton(
                                        item.invitation_id,
                                        parseInt(item.workspace_id),
                                        "cancel",
                                      );
                                    }}
                                    className={"w-[236px]"}
                                    shortcut="⌘+Shift+C"
                                  />
                                )
                              }
                              resendButton={
                                item?.status === "pending" &&
                                hasPermission("deleteSupplier") && (
                                  <ResendButton
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleClickOptionButton(
                                        item.invitation_id,
                                        parseInt(item.workspace_id),
                                        "resend",
                                      );
                                    }}
                                    className="w-full"
                                    shortcut="⌘+Shift+I"
                                  />
                                )
                              }
                            />
                          </Menu>
                        )}
                    </td>
                  </tr>
                ))
              ) : isLoading ? (
                <tr>
                  <td colSpan={12} className="text-center pb-3">
                    <LoadingComponent />
                  </td>
                </tr>
              ) : searchKey && supplierList && supplierList?.length === 0 ? (
                <EmptyTable colSpan={12} text="Result not found" />
              ) : (
                <EmptyTable colSpan={12} />
              )}
            </tbody>
          </table>
        </div>
        {allSupplierData && allSupplierData.data && allSupplierData.total > 0 && (
          <TertiaryPagination
            page={page}
            totalPages={Math.ceil(allSupplierData.total / size)}
            setPage={setPage}
            size={size}
            handleSizeChange={handleSizeChange}
          />
        )}
      </div>
    </>
  );
};

export default SupplierContents;
