import React from 'react';
import Divider from './Divider';
import { Link } from 'react-router-dom';
import { Button } from './Button';
import { ArrowUpRightIcon } from 'assests';

const CommonCard = ({ children, btnText, link, onClick, minHeight, className, btnClassName }) => {
    return (
        <div className="rounded-xl bg-global-white-default w-full border border-border-secondary shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)]">
            <div className={`p-4 md:p-6 flex  gap-4 md:gap-6 min-h-[${minHeight}px] ${className}`}>
                {children}
            </div>
            <Divider />
            <div className={`py-4 md:py-[15px] px-4 md:px-6 flex justify-end min-h-[51px] ${btnClassName}`}>
                {
                    link ?
                        <Link to={link}>
                            <Button variant="link" className="p-0 gap-1">
                                {btnText} <ArrowUpRightIcon width={20} height={20} />
                            </Button>
                        </Link> :
                        <Button onClick={onClick} variant="link" className="p-0 gap-1">
                            {btnText} <ArrowUpRightIcon width={20} height={20} />
                        </Button>
                }

            </div>
        </div>
    );
};

export default CommonCard;