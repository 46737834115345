import React from "react";

const halflogoicon = React.forwardRef(
  (
    { width = "20", height = "28", viewBox = "0 0 20 28", fill = "none", className, ...props },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.1033 8.97556H0.846191V6.79188C0.846191 3.04034 4.00234 0 7.89678 0H19.1539V2.18368C19.1539 5.93521 15.9977 8.97556 12.1033 8.97556Z"
          fill="#11C182"
        />
        <path
          d="M7.89678 19.1389H19.1539V16.9552C19.1539 13.2037 15.9977 10.1633 12.1033 10.1633H0.846191V12.347C0.846191 16.0985 4.00402 19.1389 7.89678 19.1389Z"
          fill="#11C182"
        />
        <path
          d="M10.1656 26.4274V28.0001H7.89873C4.0043 28.0001 0.848145 24.9597 0.848145 21.2082V19.6355H3.115C7.00776 19.6339 10.1656 22.6758 10.1656 26.4274Z"
          fill="#11C182"
        />
      </svg>
    );
  },
);

halflogoicon.displayName = "halflogoicon";

export default halflogoicon;
