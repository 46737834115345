import { ArrowLeft } from "assests";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Button } from "./Button";

const BackButton = ({ url, text, isButton, handleBtnClick }) => {
  const [isHovered, setIshovered] = useState(false);
  return (
    <div className="flex items-center justify-center gap-1.5 "
    >
      <div className=" flex items-center justify-center gap-1.5" onMouseEnter={() => setIshovered(true)}
        onMouseLeave={() => setIshovered(false)}>
        <ArrowLeft
          fillColor={isHovered ? "var(--color-global-primary)" : "var(--color-icon-outline-default)"}
          height={20}
          width={20}
        />
        {url && (
          <Link
            to={url}
            className={`text-sm font-semibold  whitespace-nowrap ${isHovered ? "text-global-primary" : "text-btn-text-tertiary"}`}
          >
            {text}
          </Link>
        )}

        {isButton && (
          <Button
            onClick={handleBtnClick}
            className={`text-sm font-semibold border-none p-0 m-0 bg-transparent hover:bg-transparent whitespace-nowrap ${isHovered ? "text-global-primary" : "text-btn-text-tertiary"}`}
          >
            Go back
          </Button>
        )}
      </div>
    </div>
  );
};

export default BackButton;
