import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { updateUserDetailsById } from "services/user.services";
import { setAuthentication } from "store/authenticationSlice";
import {
  setUserDetails,
  setUserList,
  setUserSliceState,
  toggleActionableUserId,
  toggleIsUserCreateModalOpen,
  toggleIsUserEditable,
  toggleShowDiscardModal,
  toggleShowUpdateProfileModal,
} from "store/userSlice";
import { profileSettingSchema } from "validation/profileSettings.validation";
import useCustomToaster from "./useCustomToaster";

const useProfileSettings = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const [isEditableFirstName, setIsEditableFirstName] = useState(false);
  const [isEditableLastName, setIsEditableLastName] = useState(false);
  const [showSuccessEmailUpdated, setShowSuccessEmailUpdated] = useState(false);
  const [successMsgtimeLeft, setSuccessMsgtimeLeft] = useState(0);
  const [isDragOver, setIsDragOver] = useState(false);
  const [fileData, setFileData] = useState([]);
  const [imagePreviews, setImagePreviews] = useState([]);
  const [timeLeft, setTimeLeft] = useState(0);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [OTP, setOTP] = useState("");
  const { showCustomToast } = useCustomToaster();
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    setError,
    setFocus,
    watch,
    control,
    clearErrors,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
      telephone: "",
      first_name: "",
      last_name: "",
      job_title: "",
    },
    resolver: zodResolver(profileSettingSchema),
  });

  const { actionableUserId } = useSelector((state) => state.userSlice);
  const { user } = useSelector((state) => state.authenticationSlice);

  const updateUserMutation = useMutation({
    mutationKey: ["user", "updateUser"],
    mutationFn: (data) => updateUserDetailsById(data),
    onMutate: () => {
      showCustomToast(
        {
          title: "Upating the User...",
        },
        "updateUserLoadingToast",
      );
    },
    onSuccess: (res) => {
      toast.dismiss("updateUserLoadingToast");
      const data = res.data;
      if (data.User.temp_email) {
        setIsButtonDisabled(true);
        setTimeLeft(60);
        setOTP("");
      }
      if (data.User) {
        const updateUser = {
          user: {
            ...data.User,
            workspace_id: parseInt(data.User.workspace_id),
          },
        };
        dispatch(setAuthentication(updateUser));
      }
      setFileData([]);
      setImagePreviews([]);
      handleDiscard();
      queryClient.invalidateQueries("protectedResource");
      showCustomToast({
        title: data.message,
      });
      setDefaultFormValue(data.User);
    },
    onError: (error) => {
      toast.dismiss("updateUserLoadingToast");
      showCustomToast({
        title: error.response?.data?.message || error.message || "Something went wrong",
      });
      if (error.response?.data?.errors) {
        Object.entries(error.response.data.errors).forEach(([field, messages]) => {
          setError(field, { type: "server", message: messages[0] });
          setFocus(field);
        });
      }
    },
  });

  const processProfleUpdateValues = (values) => {
    const processedData = {
      ...values,
      userId: user.id,
      name: `${values.first_name} ${values.last_name}`,
      telephone: values.telephone.replace("+", ""),
    };
    const formData = new FormData();
    Object.entries(processedData).forEach(([key, value]) => formData.append(key, value));
    if (fileData && fileData.length > 0) {
      fileData.forEach((file, index) => {
        formData.append(`image`, file); // Append each file individually with a dynamic key
      });
    }
    return formData;
  };

  const handleSubmitFn = () => {
    const processedValues = processProfleUpdateValues(getValues());
    updateUserMutation.mutate({ body: processedValues });
  };

  const handleDiscard = () => {
    dispatch(toggleShowUpdateProfileModal());
    setDefaultFormValue(user);
  };

  const handleEditClick = () => {
    setIsEditableFirstName(!isEditableFirstName);
    setIsEditableLastName(!isEditableLastName);
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragOver(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragOver(false);
    onFileDropOrChange(e);
  };

  const onFileDropOrChange = (e) => {
    const MAX_FILE_SIZE = 10;
    let files = null;
    if (isDragEvent(e)) {
      files = e.dataTransfer.files;
    } else {
      files = e.target.files;
    }

    if (files && files.length > 0) {
      const file = files[0]; // Only take the first file

      const updatedFileData = [file]; // Replace fileData with the new file
      setFileData(updatedFileData);
      // setValue("image", file?.name);
      // Check file type
      if (!file.type.startsWith("image/")) {
        showCustomToast({
          title: "Please drop a .jpeg, .jpg, .png file.",
        });
        return;
      }

      // Check file size
      if (file.size > MAX_FILE_SIZE * 1024 * 1024) {
        showCustomToast({
          title: "File size exceeds 10MB limit.",
        });
        return;
      }

      // Generate image preview if it's an image file
      if (file.type.startsWith("image/")) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setImagePreviews([reader.result]); // Replace imagePreviews with the new preview
        };
        reader.readAsDataURL(file);
      } else {
        // Set null for non-image files
        setImagePreviews([]);
      }
    }
  };

  const isDragEvent = (event) => {
    return "dataTransfer" in event;
  };

  const setDefaultFormValue = (details) => {
    if (!user) return;
    if (details) {
      const { first_name, last_name, name, roles, email, job_title, telephone, image } = details;
      if (email) {
        // const userRoles = roles.map((role) => role.name).join(", ");
        const names = name?.split(" ");
        setValue("email", email);
        setValue("first_name", first_name || names?.[0] || "");
        setValue("last_name", last_name || names?.[1] || "");
        // setValue("roles", userRoles);
        if (job_title !== null && job_title !== "null" && job_title !== "") {
          setValue("job_title", job_title);
        } else {
          setValue("job_title", "");
        }
        setValue("telephone", telephone);
      }
      if (image) {
        setImagePreviews([`${image}?t=${new Date().getTime()}`]);
      }
    }
  };

  useEffect(() => {
    if (user && user.id) {
      setDefaultFormValue(user);
    }
  }, [user]);

  useEffect(() => {
    if (timeLeft > 0) {
      const timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
      return () => clearInterval(timer);
    } else {
      setIsButtonDisabled(false);
    }
  }, [timeLeft]);

  return {
    handleSubmit: handleSubmit(handleSubmitFn),
    // watch: watchedValues,
    register,
    reset,
    getValues,
    errors,
    toggleIsUserCreateModalOpen,
    toggleActionableUserId,
    toggleIsUserEditable,
    toggleShowDiscardModal,
    setUserList,
    setUserDetails,
    setUserSliceState,
    actionableUserId,
    isPending: updateUserMutation.isPending,
    setValue,
    handleEditClick,
    isEditableFirstName,
    isEditableLastName,
    handleDiscard,
    clearErrors,
    control,
    isDragOver,
    handleDragEnter,
    handleDragLeave,
    handleDrop,
    onFileDropOrChange,
    imagePreviews,
    timeLeft,
    setTimeLeft,
    isButtonDisabled,
    setIsButtonDisabled,
    OTP,
    setOTP,
    showSuccessEmailUpdated,
    setShowSuccessEmailUpdated,
    successMsgtimeLeft,
    setSuccessMsgtimeLeft,
    setDefaultFormValue
  };
};

export default useProfileSettings;
