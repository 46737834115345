import { useMutation } from "@tanstack/react-query";
import { Cross, PlanIcon } from "assests";
import { Button } from "components/UI/Button";
import UpgradeComponent from "components/UI/UpgradeComponent";
import useCustomToaster from "hooks/useCustomToaster";
import useUser from "hooks/useUser";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { migrateSubscriptionPlan } from "services/subscription.services";
import { setRefetchBillingData } from "store/billingSlice";
import { calculateFutureDate, checkDowngradeOrUpgrade } from "utils/helper";

const UpgradePlanModal = ({ handleCancel }) => {
  const navigate = useNavigate();
  const { showCustomToast } = useCustomToaster();
  const { currentPlan } = useSelector((state) => state.billingSlice);
  const { selectedPlanData, selectedPlanType } = useSelector((state) => state.subscriptionSlice);
  const [downgradeOrUpgrade, setDowngradeOrUpgrade] = useState(null);
  const dispatch = useDispatch();
  const { userMutation } = useUser();
  const upgradePlanMutation = useMutation({
    mutationKey: ["upgrade-plan"],
    mutationFn: (data) => migrateSubscriptionPlan(data),
    onMutate: () => {
      showCustomToast(
        {
          title: "Migrating your plan...",
        },
        "upgrade-plan",
      );
    },
    onSuccess: (res) => {
      toast.dismiss("upgrade-plan");
      if (res.data && res.status === 200 && res.data.status) {
        dispatch(setRefetchBillingData(true));
        userMutation.mutate();
        showCustomToast({
          title: `Plan downgrade scheduled`,
          subtitle: `Your account will switch to a ${selectedPlanData?.name?.toUpperCase()} Plan at the end of the current billing period.`,
          boldText: `${selectedPlanData?.name?.toUpperCase()} Plan`,
        });
        handleCancel();
        if (downgradeOrUpgrade === "UPGRADE") {
          Cookies.set("showTutorial", "true", { expires: 365 });
        }
        navigate("/", { replace: true });
      }
    },
    onError: (error) => {
      toast.dismiss("upgrade-plan");
      showCustomToast({
        title: error.message || "Something went wrong",
      });
    },
  });

  const handleUpgrade = (plan) => {
    if (!currentPlan && !plan) return;
    const mutationData = {
      plan_id: plan.id,
      subscription_id: currentPlan.subscription.subscription_id,
    };
    upgradePlanMutation.mutate(mutationData);
  };

  useEffect(() => {
    if ((currentPlan, selectedPlanData, selectedPlanType)) {
      setDowngradeOrUpgrade(
        checkDowngradeOrUpgrade(currentPlan, selectedPlanData, selectedPlanType),
      );
    }
  }, [currentPlan, selectedPlanData, selectedPlanType]);

  return (
    <>
      <div
        className={` bg-white p-6 relative  z-[2] rounded-xl  md:max-w-[600px]`}
      >
        <div className="absolute right-[22px] top-[22px] cursor-pointer  " onClick={handleCancel}>
          <Cross fillColor={"var(--color-icon-fill-default)"} height={24} width={24} />
        </div>
        <div className={`flex flex-row`}>
          <div className="mr-4">
            <UpgradeComponent>
              <PlanIcon fillColor="var(--color-icon-default-2)" />
            </UpgradeComponent>
          </div>

          <div className={`flex flex-col gap-2`}>
            <h4 className="text-base lg:text-lg font-semibold text-text-primary">
              Are you sure you want to <span className="lowercase">{downgradeOrUpgrade}</span> your
              plan?
            </h4>
            <div className="text-xs lg:text-sm  text-global-paragraph">
              Your current plan will be{" "}
              {downgradeOrUpgrade && `${downgradeOrUpgrade?.toLowerCase()}d`} to{" "}
              {selectedPlanData && selectedPlanData?.name?.toUpperCase()} at the end of the current
              billing period on {selectedPlanType && calculateFutureDate(selectedPlanType)}.
              <div className="pt-5">
                You will lose access to all{" "}
                <span className="uppercase">{currentPlan?.plan?.name}</span> features.
              </div>
            </div>
          </div>
        </div>

        <div className="flex justify-center lg:justify-end flex-col lg:flex-row gap-3 pt-8">
          <Button
            variant="secondary"
            className="px-4 py-2.5 w-full lg:w-[86px] h-11 text-sm md:text-base font-semibold"
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button
            variant={"warning"}
            className={`px-4 py-2.5 w-full lg:w-[186px] h-11 custom-primary-shadow text-white text-sm md:text-base font-semibold whitespace-nowrap `}
            onClick={() => handleUpgrade(selectedPlanData)}
          >
            Confirm {downgradeOrUpgrade?.toLowerCase()}
          </Button>
        </div>
      </div>
    </>
  );
};

export default UpgradePlanModal;
