import { useQuery } from "@tanstack/react-query";
import { LogoCollpased } from "assests";
import EmptyComponent from "components/Card/EmptyComponent";
import MapViewUpdated from "components/Card/MapViewUpdated";
import BreadCrumb from "components/TopHeader/BreadCrumb";
import TopNavbar from "components/TopHeader/TopNavbar";
import BorderDesign from "components/UI/BorderDesign";
import { Button } from "components/UI/Button";
import Card from "components/UI/Card";
import CircleProgressBarMultiple from "components/UI/CircleProgressBarMultiple";
import LoadingComponent from "components/UI/LoadingComponent";
import SubNavbar from "layout/SubNavbar";
import Audits from "pages/Settings/Workspace/AccountDetailsPage/Audits";
import Certificates from "pages/Settings/Workspace/AccountDetailsPage/Certificates";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getAgentDetails } from "services/company-details.services";
import { formatLocation, transpercencyLineProgressPercentageColors } from "utils/helper";

const SupplierDetailPage = () => {
  const navigate = useNavigate();
  const { supplierId, workspaceId } = useParams();
  const [allData, setAllData] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [supplierDetails, setSupplierDetails] = useState(null);
  const [totalOrders, setTotalOrders] = useState(0);
  const [isMap, setIsMap] = useState(false);
  const [complacencyDetails, setComplacencyDetails] = useState(null);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  //mutation
  const {
    data: supplierQueryData,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ["suppliers", "getAgentDetails", supplierId],
    queryFn: () =>
      getAgentDetails(supplierId, {
        type: "supplier",
        status: "active",
        workspace_id: parseInt(workspaceId),
      }),
    enabled: !!supplierId,
  });
  useEffect(() => {
    if (supplierQueryData && supplierQueryData.status === 200 && !isLoading) {
      const details = supplierQueryData.data.workspace;
      const allDetails = supplierQueryData.data;
      const total_order = supplierQueryData.data.total_order;
      setSupplierDetails(details);
      setTotalOrders(total_order);
      setAllData(allDetails);
    }
  }, [supplierQueryData, isLoading]);

  const handleViewDetailsClick = () => {
    navigate(`/workspace/${workspaceId}/orders?status=active&partner_id=${supplierId}`, {
      replace: true,
    });
  };

  return (
    <>
      <TopNavbar isFromDashboard={false}>
        <BreadCrumb text="My Suppliers" />
      </TopNavbar>
      <div className="px-4 md:px-8   pt-[64px] md:pt-[108px]">
        <div className="block md:hidden pt-4">
          <BreadCrumb text="My Suppliers" />
        </div>
        {!supplierDetails && isLoading ? (
          <LoadingComponent />
        ) : (
          <>
            <div className="pt-4 md:pt-6">
              <SubNavbar
                title={supplierDetails && supplierDetails?.name}
                subtitle={`Supplier located in ${
                  supplierDetails &&
                  formatLocation({
                    region: supplierDetails?.region,
                    country: supplierDetails?.country,
                  })
                }`}
              />
            </div>
            <div className="bg-global-divider-medium h-[1px] my-5  hidden md:block" />
            <div className="h-full ">
              <div className="h-full ">
                {supplierDetails ? (
                  <>
                    <div className="flex flex-col flex-grow w-full h-full py-4 gap-y-4">
                      <div className="grid grid-cols-12 gap-4">
                        <div className="col-span-12 lg:col-span-6 w-full">
                          <Card
                            size={"sm"}
                            borderRadius={"sm"}
                            className="p-0 m-0 bg-global-theme  sticky top-0  rounded-xl gap-3 lg:gap-9  text-card-small-title shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)] border border-divider-stroke"
                          >
                            <div className="px-6 pt-5 pb-[19px] flex flex-col justify-center gap-5 items-center">
                              <div className="p-3">
                                <CircleProgressBarMultiple
                                  currentProgress={
                                    allData?.transparency_score ? allData?.transparency_score : 0
                                  }
                                  size={32}
                                  strokeColorVariable={transpercencyLineProgressPercentageColors(
                                    allData?.transparency_score ? allData?.transparency_score : 0,
                                  )}
                                  childrenProgress={
                                    allData?.verification_score ? allData?.verification_score : 0
                                  }
                                  childrenSize={25}
                                  childrenStrokeColorVariable={"--color-progress-surface-blue"}
                                  subText={totalOrders}
                                  text="Orders"
                                />
                              </div>
                              <div>
                                <div className="justify-center items-center gap-3 inline-flex">
                                  <div className="justify-start items-center gap-2 flex">
                                    <div className="w-2 h-2 bg-global-primary rounded-full" />
                                    <div className="text-input-text-label text-sm font-normal leading-tight">
                                      Transparency %
                                    </div>
                                  </div>
                                  <div className="justify-start items-center gap-2 flex">
                                    <div className="w-2 h-2 bg-global-blue rounded-full" />
                                    <div className="text-input-text-label text-sm font-normal leading-tight">
                                      Verification %
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="border-t border-border-secondary">
                              <div className="px-6 py-4 justify-end flex items-center  text-sm font-semibold text-login-text-title">
                                <Button
                                  variant="link"
                                  className="p-0 m-0"
                                  onClick={handleViewDetailsClick}
                                >
                                  View Active Orders
                                </Button>
                              </div>
                            </div>
                          </Card>
                        </div>
                        <div className="col-span-12 lg:col-span-6 w-full">
                          <Card
                            size={"sm"}
                            borderRadius={"sm"}
                            className="p-0 m-0 bg-global-theme  sticky top-0 rounded-xl gap-3 lg:gap-9  text-card-small-title shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)] border border-divider-stroke"
                          >
                            <div className="px-6 pt-5 pb-[19px] flex flex-col justify-start gap-5 items-start">
                              <div className="flex flex-row justify-start items-center gap-2.5 ">
                                <div
                                  className="rounded-xl"
                                  style={{
                                    boxShadow:
                                      "0px 1.25px 2.5px 0px rgba(16, 24, 40, 0.06), 0px 1.25px 3.75px 0px rgba(16, 24, 40, 0.1)",
                                  }}
                                >
                                  <BorderDesign
                                    className={`w-[45px] h-[45px] rounded-xl bg-global-primary`}
                                    boxShadowShow={false}
                                  >
                                    <LogoCollpased fillColor="var(--color-white)" />
                                  </BorderDesign>
                                </div>
                                <div className="text-global-title font-semibold text-sm lg:text-base truncate ">
                                  Partner ID:{" "}
                                  <span className="font-normal">{supplierDetails?.partner_id}</span>
                                </div>
                              </div>
                            </div>
                            <div className="border-t border-border-secondary h-[204px] relative w-full">
                              <MapViewUpdated details={supplierDetails} />

                              <div className=" px-[18px] py-2.5 origin-top-left absolute left-4 bottom-4  bg-global-theme rounded-2xl shadow-[0px_18px_40px_0px_rgba(112,144,176,0.12)] flex-col justify-start items-start gap-2.5 inline-flex">
                                <div className="flex-col justify-start items-start flex ">
                                  <div className="self-stretch  justify-start items-center gap-2.5 inline-flex">
                                    <div className="h-2.5 w-2.5 rounded-full bg-global-primary" />
                                    <div className="text-text-data-dark text-xs font-medium  leading-[18px] py-[2px]">
                                      Active
                                    </div>
                                  </div>
                                  <div className="text-text-data-dark text-base font-semibold  leading-2xl">
                                    {supplierDetails &&
                                      formatLocation({
                                        region: supplierDetails?.region,
                                        country: supplierDetails?.country,
                                      })}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Card>
                        </div>
                      </div>
                      <Certificates
                        workspace={supplierQueryData?.data?.workspace}
                        workspaceRefetch={refetch}
                      />
                      <div>
                        <Audits />
                      </div>
                    </div>
                  </>
                ) : (
                  <EmptyComponent
                    text="You don’t have any Suppliers yet."
                    buttonText="Create a supplier"
                    onClick={openModal}
                    icon={true}
                  />
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default SupplierDetailPage;
