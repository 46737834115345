import { FilterOption, TickIcon, XCloseIcon } from "assests";
import { Button } from "components/UI/Button";
import CustomDropdownCard from "components/UI/CustomDropdownCard";
import TransparencySlider from "components/UI/TransparencySlider";
import { useEffect, useRef, useState } from "react";
import { RxCross2 } from "react-icons/rx";
import { useSelector } from "react-redux";
import Datepicker from "react-tailwindcss-datepicker";
import { convertDate, filterNonEmptyKeys, setTimeInDateObject } from "utils/helper";
const OrderFilters = ({
  activeFilters,
  setActiveFilters,
  setFilteredData,
  orderOptions,
  companyList,
  seasonList,
  articleListQuery,
  artcileLoading,
  artcileStatus,
  articleTypeList,
  supplierList,
  partnerList
}) => {
  const filterOptions = [
    "order_no",
    "status",
    "transparency",
    "verification",
    "partner_name",
    "partner_type",
    "order_date",
    "supplier_name",
    "ETD",
    "ETA",
    "season",
    "quantity",
    "tier_4",
    "tier_3",
    "tier_2",
    "tier_1",
    "tier_0",
    "article_no",
    "article_type",
    "date_added",
    "last_updated",
    "created_by",
  ];
  const filterRef = useRef(null);
  const childfilterRef = useRef(null);
  const [articleList, setArticleList] = useState([]);
  const [transparencyRange, setTransparencyRange] = useState([0, 100]);
  const [transparencyScoreRange, setTransparencyScoreRange] = useState([0, 100]);
  const [varificationRange, setVarificationRange] = useState([0, 100]);
  const [hoveredId, setHoveredId] = useState({});
  const [isHovered, setIshovered] = useState(false);
  const [mainDropdownVisible, setMainDropdownVisible] = useState(false);
  const [currentFilterDropdown, setCurrentFilterDropdown] = useState(null);
  const { user, currentWorkspace } = useSelector((state) => state.authenticationSlice);

  const tierStatus = [
    {
      label: "Completed",
      value: "completed",
    },
    {
      label: "Pending",
      value: "pending",
    },
  ];

  const accountTypeList = [
    {
      label: "Client",
      value: "retailer",
    },
    {
      label: "Agent",
      value: "agent",
    }
  ];

  const statusOptions = [
    {
      label: "Active",
      value: "active",
    },
    {
      label: "Pending",
      value: "pending",
    },
    {
      label: "Draft",
      value: "draft",
    },
    {
      label: "Completed",
      value: "completed",
    },
    {
      label: "Archived",
      value: "archived",
    },
    {
      label: "Cancelled",
      value: "cancelled",
    },
  ];

  // Toggle Main Dropdown
  const toggleMainDropdown = () => {
    setMainDropdownVisible(!mainDropdownVisible);
    setCurrentFilterDropdown(null);
  };

  // Add Filter
  const addFilter = (filterName) => {
    if (!activeFilters[filterName]) {
      setActiveFilters((prev) => ({
        ...prev,
        [filterName]: filterName === "Active Orders" ? transparencyRange : [],
      }));
    }
  };

  // Remove Filter
  const removeFilter = (filterName) => {
    setActiveFilters((prev) => {
      const updatedFilters = { ...prev };
      delete updatedFilters[filterName];
      return updatedFilters;
    });
    setCurrentFilterDropdown(null);
    if (filterName === "quantity") {
      setTransparencyRange([0, 100]);
    }
    if (filterName === "transparency") {
      setTransparencyScoreRange([0, 100]);
    }
    if (filterName === "verification") {
      setVarificationRange([0, 100]);
    }
  };


  const addFilterValue = (filterName, value, label) => {
    setActiveFilters((prev) => {
      const updatedValues = prev[filterName] || [];
      if (!updatedValues.some((item) => item.value === value)) {
        updatedValues.push({ value, label });
      }
      // Return the updated filters
      return { ...prev, [filterName]: updatedValues };
    });
  };

  const removeFilterValue = (filterName, value) => {
    setActiveFilters((prev) => {
      const updatedValues = prev[filterName]?.filter((item) => item.value !== value);
      if (!updatedValues || updatedValues.length === 0) {
        const { [filterName]: _, ...rest } = prev; // Destructure to exclude the filterName
        return rest;
      }
      return { ...prev, [filterName]: updatedValues };
    });
  };

  // Handle Transparency Slider Change
  const handleSliderChange = (range) => {
    setTransparencyRange(range);
    setActiveFilters((prev) => ({
      ...prev,
      quantity: range,
    }));
  };

  const handleTranspercencyScoreSliderChange = (range) => {
    setTransparencyScoreRange(range);
    setActiveFilters((prev) => ({
      ...prev,
      transparency: range,
    }));
  };

  const handleVarificationScoreSliderChange = (range) => {
    setVarificationRange(range);
    setActiveFilters((prev) => ({
      ...prev,
      verification: range,
    }));
  };

  useEffect(() => {
    if (
      articleListQuery &&
      artcileStatus === "success" &&
      !artcileLoading &&
      articleListQuery.status === 200 &&
      articleListQuery?.data?.articles &&
      articleListQuery?.data?.articles?.length > 0
    ) {
      const formatedArticleList = articleListQuery?.data?.articles?.map((article) => ({
        label: article?.sku,
        value: article?.sku,
      }));
      setArticleList(formatedArticleList);
    }
  }, [articleListQuery, artcileLoading, artcileStatus]);

  useEffect(() => {
    if (activeFilters) {
      const transformedData = {
        order_number: activeFilters.order_no,
        status: activeFilters.status,
        transparency_score: activeFilters.transparency,
        verification_score: activeFilters.verification,
        partner_id: activeFilters.partner_name,
        partner_type: activeFilters.partner_type,
        order_date: activeFilters.order_date,
        supplier_id: activeFilters.supplier_name,
        order_eta: activeFilters.ETA,
        order_etd: activeFilters.ETD,
        season_id: activeFilters.season,
        quantity: activeFilters.quantity,
        tier_4: activeFilters.tier_4,
        tier_3: activeFilters.tier_3,
        tier_2: activeFilters.tier_2,
        tier_1: activeFilters.tier_1,
        tier_0: activeFilters.tier_0,
        article_sku: activeFilters.article_no,
        article_type: activeFilters.article_type,
        created_at: activeFilters.date_added,
        updated_at: activeFilters.last_updated,
        created_by: activeFilters.created_by,
      };
      const updatedFilteredData = filterNonEmptyKeys(transformedData);
      setFilteredData(updatedFilteredData);
    }
  }, [activeFilters]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (filterRef.current && !filterRef.current.contains(event.target)) {
        setMainDropdownVisible(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (childfilterRef.current && !childfilterRef.current.contains(event.target)) {
        setCurrentFilterDropdown(null);
      }
    };
    document.addEventListener("click", handleClickOutside);
    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [currentFilterDropdown]);
  const [uniqueCretedByUser, setUniqueCretedByUser] = useState([]);

  const processUniqueData = () => {
    if (!orderOptions || orderOptions.length === 0) return;
    // Filter items that have a valid created_by_user.id
    const acceptedOptions = orderOptions.filter(
      item => typeof item?.created_by_user?.id === "number"
    );
    const userMap = new Map();
    acceptedOptions.forEach(item => {
      const user = item.created_by_user;
      if (!userMap.has(user.id)) {
        userMap.set(user.id, { value: user.id, label: user.name });
      }
    });
    // Convert Map values to an array
    const users = Array.from(userMap.values());
    setUniqueCretedByUser(users);
  };


  useEffect(() => {
    if (orderOptions && orderOptions.length > 0) {
      processUniqueData();
    }
  }, [orderOptions]);


  useEffect(() => {
    if (user && currentWorkspace) {
      const handleKeyDown = (event) => {
        // Check if 'Ctrl + R' is pressed

        if ((event.ctrlKey || event.metaKey) && event.shiftKey && (event.key === "Y" || event.key === "y")) {
          event.preventDefault();
          setMainDropdownVisible(true);
        }
      };
      window.addEventListener("keydown", handleKeyDown);
      return () => {
        window.removeEventListener("keydown", handleKeyDown);
      };
    }
  }, [user, currentWorkspace]);

  return (
    <div
      className={`flex flex-col md:flex-row md:flex-wrap  w-full ${activeFilters && Object.keys(activeFilters).length > 0 && "gap-2"}`}
    >
      <div ref={childfilterRef} className="flex flex-col md:flex-row md:flex-wrap gap-2 order-2 md:order-1">
        {activeFilters &&
          Object.keys(activeFilters).map((filter) => (
            <div key={filter} className="relative">
              <Button
                variant="secondary"
                className="text-txt-secondary px-3.5 py-2.5 justify-between h-10 lg:h-auto  gap-1 truncate w-full md:w-auto text-sm tracking-wide font-normal  flex"
                onClick={() => {
                  setCurrentFilterDropdown(currentFilterDropdown === filter ? null : filter);
                  setMainDropdownVisible(false);
                }}
              >
                <div>
                  <span className="capitalize">{filter === "order_no" ? "Order No." : (filter === "transparency" || filter === "verification") ? `${filter} %` : filter?.replace(/_/g, " ")}</span>:
                  {(filter === "date_added" || filter === "last_updated" || filter === "order_date" || filter === "ETD" || filter === "ETA") &&
                    activeFilters[filter] ? (
                    <span className="font-semibold">
                      {" "}
                      {activeFilters[filter]?.startDate && activeFilters[filter]?.endDate
                        ? `${convertDate(activeFilters[filter].startDate)} ~ ${convertDate(activeFilters[filter].endDate)}`
                        : null}
                    </span>
                  ) : activeFilters[filter]?.length > 0 ? (
                    <span className="font-semibold">
                      {" "}
                      {activeFilters[filter].length > 2
                        ? "Multiple"
                        : activeFilters[filter]
                          .map((status) =>
                            typeof status === "object" && status.label ? status.label : status,
                          )
                          .join(", ")}
                    </span>
                  ) : null}
                </div>
                <div>
                  <RxCross2
                    size={20}
                    onClick={(e) => {
                      e.stopPropagation();
                      removeFilter(filter);
                    }}
                    className="cursor-pointer"
                  />
                </div>
              </Button>

              {/* Sub-dropdown for Status and order_no*/}
              {currentFilterDropdown === filter && filter === "order_no" && (
                <CustomDropdownCard>
                  {orderOptions && orderOptions.length > 0 ? (
                    orderOptions.map((article, index) => {
                      const isSelected = activeFilters[filter]?.some(
                        (item) => item.value === article.order_number,
                      );
                      return (
                        <div
                          key={index}
                          onClick={(e) => {
                            e.stopPropagation();
                            addFilterValue(filter, article.order_number, article.order_number);
                          }}
                          onMouseEnter={() => setHoveredId(article.order_number)}
                          onMouseLeave={() => setHoveredId(null)}
                          className={`cursor-pointer flex items-center justify-start w-full pl-2 pr-2.5 py-2.5 rounded-md hover:bg-global-white ${isSelected ? "bg-global-white" : ""
                            }`}
                        >
                          <span className="text-base font-medium text-global-title truncate">
                            {article.order_number}
                          </span>
                          {isSelected && (
                            <span className="ml-auto">
                              {hoveredId === article.order_number ? (
                                <XCloseIcon
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    removeFilterValue(filter, article.order_number);
                                  }}
                                  strokeColor={"var(--color-login-text-title)"}
                                  width={20}
                                  height={20}
                                />
                              ) : (
                                <TickIcon width={20} height={20} />
                              )}
                            </span>
                          )}
                        </div>
                      );
                    })
                  ) : (
                    <p className="text-base px-6 py-5 font-normal text-center text-global-gray-brand">
                      No data found
                    </p>
                  )}
                </CustomDropdownCard>
              )}
              {currentFilterDropdown === filter && filter === "status" && (
                <CustomDropdownCard>
                  {statusOptions.map((status, index) => {
                    const isSelected = activeFilters[filter]?.some(
                      (item) => item.value === status.value,
                    );
                    return (
                      <div
                        key={index}
                        onClick={(e) => {
                          e.stopPropagation();

                          addFilterValue(filter, status.value, status.label);
                        }}
                        onMouseEnter={() => setHoveredId(status.value)}
                        onMouseLeave={() => setHoveredId(null)}
                        className={`cursor-pointer flex items-center justify-start w-full pl-2 pr-2.5 py-2.5 rounded-md hover:bg-global-white ${isSelected ? "bg-global-white" : ""
                          }`}
                      >
                        <span className="text-base font-medium text-global-title truncate">
                          {status.label}
                        </span>
                        {isSelected && (
                          <span className="ml-auto">
                            {hoveredId === status.value ? (
                              <XCloseIcon
                                onClick={(e) => {
                                  e.stopPropagation();
                                  removeFilterValue(filter, status.value);
                                }}
                                strokeColor={"var(--color-login-text-title)"}
                                width={20}
                                height={20}
                              />
                            ) : (
                              <TickIcon width={20} height={20} />
                            )}
                          </span>
                        )}
                      </div>
                    );
                  })}
                </CustomDropdownCard>
              )}
              {/* Sub-dropdown for Transparency Score */}
              {currentFilterDropdown === filter && filter === "quantity" && (
                <CustomDropdownCard className="w-[292px] md:w-[320px]">
                  <TransparencySlider
                    setTransparencyRange={setTransparencyRange}
                    transparencyRange={transparencyRange}
                    onChange={handleSliderChange}
                    maxValue={100}
                    minValue={0}
                  />
                </CustomDropdownCard>
              )}
              {currentFilterDropdown === filter && filter === "transparency" && (
                <CustomDropdownCard className="w-[292px] md:w-[320px]">
                  <TransparencySlider
                    setTransparencyRange={setTransparencyScoreRange}
                    transparencyRange={transparencyScoreRange}
                    onChange={handleTranspercencyScoreSliderChange}
                    maxValue={100}
                    minValue={0}
                  />
                </CustomDropdownCard>
              )}
              {currentFilterDropdown === filter && filter === "verification" && (
                <CustomDropdownCard className="w-[292px] md:w-[320px]">
                  <TransparencySlider
                    setTransparencyRange={setVarificationRange}
                    transparencyRange={varificationRange}
                    onChange={handleVarificationScoreSliderChange}
                    maxValue={100}
                    minValue={0}
                  />
                </CustomDropdownCard>
              )}

              {currentFilterDropdown === filter && filter === "supplier_name" && (
                <CustomDropdownCard>
                  {supplierList && supplierList.length > 0 ? (
                    supplierList.map((status, index) => {
                      const isSelected = activeFilters[filter]?.some(
                        (item) => item.value === status.value,
                      );
                      return (
                        <div
                          key={index}
                          onClick={(e) => {
                            e.stopPropagation();
                            addFilterValue(filter, status.value, status.label);
                          }}
                          onMouseEnter={() => setHoveredId(status.value)}
                          onMouseLeave={() => setHoveredId(null)}
                          className={`cursor-pointer flex items-center justify-start w-full pl-2 pr-2.5 py-2.5 rounded-md hover:bg-global-white ${isSelected ? "bg-global-white" : ""
                            }`}
                        >
                          <span className="text-base font-medium text-global-title truncate">
                            {status.label}
                          </span>
                          {isSelected && (
                            <span className="ml-auto">
                              {hoveredId === status.value ? (
                                <XCloseIcon
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    removeFilterValue(filter, status.value);
                                  }}
                                  strokeColor={"var(--color-login-text-title)"}
                                  width={20}
                                  height={20}
                                />
                              ) : (
                                <TickIcon width={20} height={20} />
                              )}
                            </span>
                          )}
                        </div>
                      );
                    })
                  ) : (
                    <p className="text-base px-6 py-5 font-normal text-center text-global-gray-brand">
                      No data found
                    </p>
                  )}
                </CustomDropdownCard>
              )}

              {currentFilterDropdown === filter && filter === "partner_name" && (
                <CustomDropdownCard>
                  {partnerList && partnerList.length > 0 ? (
                    partnerList.map((status, index) => {
                      const isSelected = activeFilters[filter]?.some(
                        (item) => item.value === status.value,
                      );
                      return (
                        <div
                          key={index}
                          onClick={(e) => {
                            e.stopPropagation();
                            addFilterValue(filter, status.value, status.label);
                          }}
                          onMouseEnter={() => setHoveredId(status.value)}
                          onMouseLeave={() => setHoveredId(null)}
                          className={`cursor-pointer flex items-center justify-start w-full pl-2 pr-2.5 py-2.5 rounded-md hover:bg-global-white ${isSelected ? "bg-global-white" : ""
                            }`}
                        >
                          <span className="text-base font-medium text-global-title truncate">
                            {status.label}
                          </span>
                          {isSelected && (
                            <span className="ml-auto">
                              {hoveredId === status.value ? (
                                <XCloseIcon
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    removeFilterValue(filter, status.value);
                                  }}
                                  strokeColor={"var(--color-login-text-title)"}
                                  width={20}
                                  height={20}
                                />
                              ) : (
                                <TickIcon width={20} height={20} />
                              )}
                            </span>
                          )}
                        </div>
                      );
                    })
                  ) : (
                    <p className="text-base px-6 py-5 font-normal text-center text-global-gray-brand">
                      No data found
                    </p>
                  )}
                </CustomDropdownCard>
              )}

              {currentFilterDropdown === filter && filter === "partner_type" && (
                <CustomDropdownCard>
                  {accountTypeList && accountTypeList.length > 0 && accountTypeList.map((status, index) => {
                    const isSelected = activeFilters[filter]?.some(
                      (item) => item.value === status.value,
                    );
                    return (
                      <div
                        key={index}
                        onClick={(e) => {
                          e.stopPropagation();

                          addFilterValue(filter, status.value, status.label);
                        }}
                        onMouseEnter={() => setHoveredId(status.value)}
                        onMouseLeave={() => setHoveredId(null)}
                        className={`cursor-pointer flex items-center justify-start w-full pl-2 pr-2.5 py-2.5 rounded-md hover:bg-global-white ${isSelected ? "bg-global-white" : ""
                          }`}
                      >
                        <span className="text-base font-medium text-global-title truncate">
                          {status.label}
                        </span>
                        {isSelected && (
                          <span className="ml-auto">
                            {hoveredId === status.value ? (
                              <XCloseIcon
                                onClick={(e) => {
                                  e.stopPropagation();
                                  removeFilterValue(filter, status.value);
                                }}
                                strokeColor={"var(--color-login-text-title)"}
                                width={20}
                                height={20}
                              />
                            ) : (
                              <TickIcon width={20} height={20} />
                            )}
                          </span>
                        )}
                      </div>
                    );
                  })}
                </CustomDropdownCard>
              )}

              {currentFilterDropdown === filter && filter === "season" && (
                <CustomDropdownCard>
                  {seasonList && seasonList.length > 0 && seasonList.map((status, index) => {
                    const isSelected = activeFilters[filter]?.some(
                      (item) => item.value === status.value,
                    );
                    return (
                      <div
                        key={index}
                        onClick={(e) => {
                          e.stopPropagation();

                          addFilterValue(filter, status.value, status.label);
                        }}
                        onMouseEnter={() => setHoveredId(status.value)}
                        onMouseLeave={() => setHoveredId(null)}
                        className={`cursor-pointer flex items-center justify-start w-full pl-2 pr-2.5 py-2.5 rounded-md hover:bg-global-white ${isSelected ? "bg-global-white" : ""
                          }`}
                      >
                        <span className="text-base font-medium text-global-title truncate">
                          {status.label}
                        </span>
                        {isSelected && (
                          <span className="ml-auto">
                            {hoveredId === status.value ? (
                              <XCloseIcon
                                onClick={(e) => {
                                  e.stopPropagation();
                                  removeFilterValue(filter, status.value);
                                }}
                                strokeColor={"var(--color-login-text-title)"}
                                width={20}
                                height={20}
                              />
                            ) : (
                              <TickIcon width={20} height={20} />
                            )}
                          </span>
                        )}
                      </div>
                    );
                  })}
                </CustomDropdownCard>
              )}

              {currentFilterDropdown === filter && (filter === "tier_0" || filter === "tier_1" || filter === "tier_2" || filter === "tier_3" || filter === "tier_4") && (
                <CustomDropdownCard>
                  {tierStatus && tierStatus.length > 0 ? (
                    tierStatus.map((status, index) => {
                      const isSelected = activeFilters[filter]?.some(
                        (item) => item.value === status.value,
                      );
                      return (
                        <div
                          key={index}
                          onClick={(e) => {
                            e.stopPropagation();
                            addFilterValue(filter, status.value, status.label);
                          }}
                          onMouseEnter={() => setHoveredId(status.value)}
                          onMouseLeave={() => setHoveredId(null)}
                          className={`cursor-pointer flex items-center justify-start w-full pl-2 pr-2.5 py-2.5 rounded-md hover:bg-global-white ${isSelected ? "bg-global-white" : ""
                            }`}
                        >
                          <span className="text-base font-medium text-global-title truncate">
                            {status.label}
                          </span>
                          {isSelected && (
                            <span className="ml-auto">
                              {hoveredId === status.value ? (
                                <XCloseIcon
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    removeFilterValue(filter, status.value);
                                  }}
                                  strokeColor={"var(--color-login-text-title)"}
                                  width={20}
                                  height={20}
                                />
                              ) : (
                                <TickIcon width={20} height={20} />
                              )}
                            </span>
                          )}
                        </div>
                      );
                    })
                  ) : (
                    <p className="text-base px-6 py-5 font-normal text-center text-global-gray-brand">
                      No data found
                    </p>
                  )}
                </CustomDropdownCard>
              )}

              {currentFilterDropdown === filter && filter === "article_no" && (
                <CustomDropdownCard>
                  {articleList && articleList.length > 0 && articleList.map((status, index) => {
                    const isSelected = activeFilters[filter]?.some(
                      (item) => item.value === status.value,
                    );
                    return (
                      <div
                        key={index}
                        onClick={(e) => {
                          e.stopPropagation();

                          addFilterValue(filter, status.value, status.label);
                        }}
                        onMouseEnter={() => setHoveredId(status.value)}
                        onMouseLeave={() => setHoveredId(null)}
                        className={`cursor-pointer flex items-center justify-start w-full pl-2 pr-2.5 py-2.5 rounded-md hover:bg-global-white ${isSelected ? "bg-global-white" : ""
                          }`}
                      >
                        <span className="text-base font-medium text-global-title truncate">
                          {status.label}
                        </span>
                        {isSelected && (
                          <span className="ml-auto">
                            {hoveredId === status.value ? (
                              <XCloseIcon
                                onClick={(e) => {
                                  e.stopPropagation();
                                  removeFilterValue(filter, status.value);
                                }}
                                strokeColor={"var(--color-login-text-title)"}
                                width={20}
                                height={20}
                              />
                            ) : (
                              <TickIcon width={20} height={20} />
                            )}
                          </span>
                        )}
                      </div>
                    );
                  })}
                </CustomDropdownCard>
              )}

              {currentFilterDropdown === filter && filter === "article_type" && (
                <CustomDropdownCard>
                  {articleTypeList && articleTypeList.length > 0 && articleTypeList.map((status, index) => {
                    const isSelected = activeFilters[filter]?.some(
                      (item) => item.value === status.value,
                    );
                    return (
                      <div
                        key={index}
                        onClick={(e) => {
                          e.stopPropagation();

                          addFilterValue(filter, status.value, status.label);
                        }}
                        onMouseEnter={() => setHoveredId(status.value)}
                        onMouseLeave={() => setHoveredId(null)}
                        className={`cursor-pointer flex items-center justify-start w-full pl-2 pr-2.5 py-2.5 rounded-md hover:bg-global-white ${isSelected ? "bg-global-white" : ""
                          }`}
                      >
                        <span className="text-base font-medium text-global-title truncate">
                          {status.label}
                        </span>
                        {isSelected && (
                          <span className="ml-auto">
                            {hoveredId === status.value ? (
                              <XCloseIcon
                                onClick={(e) => {
                                  e.stopPropagation();
                                  removeFilterValue(filter, status.value);
                                }}
                                strokeColor={"var(--color-login-text-title)"}
                                width={20}
                                height={20}
                              />
                            ) : (
                              <TickIcon width={20} height={20} />
                            )}
                          </span>
                        )}
                      </div>
                    );
                  })}
                </CustomDropdownCard>
              )}

              {(currentFilterDropdown === filter && (filter === "order_date" || filter === "date_added" || filter === "date_updated" || filter === "ETD" || filter === "ETA" || filter === "last_updated")) && (
                <div>
                  {activeFilters[filter] && (
                    <div className="absolute z-50  py-1 w-full  rounded-lg focus:outline-none bg-global-theme ">
                      <div
                        className="rounded-lg p-6 bg-btn-surface-secondary max-h-[320px] w-full md:w-[320px] border border-global-divider-soft"
                        style={{
                          boxShadow:
                            "0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08)",
                        }}
                      >
                        <div className="grid grid-cols-1 gap-[2px] py-1">
                          <div className="relative">
                            <Datepicker
                              useRange={window.innerWidth > 768 ? true : false}
                              asSingle={false}
                              value={activeFilters[filter]}
                              placeholder={"Select date range"}
                              displayFormat="DD-MM-YYYY"
                              inputClassName={`flex justify-between w-full gap-3 px-4 h-[44px]  py-2 items-center text-dropdown-text-data text-base placeholder:text-input-text-placeholder placeholder:text-base placeholder:font-normal self-stretch border border-dropdown-stroke-default bg-dropdown-surface-background focus:shadow-[none] focus:ring-offset-input-surface-background focus:border-input-stroke-active focus:ring-input-stroke-active rounded-lg z-[2]`}
                              containerClassName="certificate-date-picker"
                              toggleClassName="absolute text-icon-fill-input custom-toggle-icon right-0 h-full z-[4]"
                              onChange={(range) => {
                                setActiveFilters((prev) => ({
                                  ...prev,
                                  [filter]: setTimeInDateObject(range),
                                }));
                              }}
                              primaryColor={"emerald"}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}

              {currentFilterDropdown === filter && filter === "created_by" && (
                <CustomDropdownCard>
                  {uniqueCretedByUser && uniqueCretedByUser.length > 0 ? (
                    uniqueCretedByUser.map((status, index) => {
                      const isSelected = activeFilters[filter]?.some(
                        (item) => item.value === status.value,
                      );
                      return (
                        <div
                          key={index}
                          onClick={(e) => {
                            e.stopPropagation();
                            addFilterValue(filter, status.value, status.label);
                          }}
                          onMouseEnter={() => setHoveredId(status.value)}
                          onMouseLeave={() => setHoveredId(null)}
                          className={`cursor-pointer flex items-center justify-start w-full pl-2 pr-2.5 py-2.5 rounded-md hover:bg-global-white ${isSelected ? "bg-global-white" : ""
                            }`}
                        >
                          <span className="text-base font-medium text-global-title truncate">
                            {status.label}
                          </span>
                          {isSelected && (
                            <span className="ml-auto">
                              {hoveredId === status.value ? (
                                <XCloseIcon
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    removeFilterValue(filter, status.value);
                                  }}
                                  strokeColor={"var(--color-login-text-title)"}
                                  width={20}
                                  height={20}
                                />
                              ) : (
                                <TickIcon width={20} height={20} />
                              )}
                            </span>
                          )}
                        </div>
                      );
                    })
                  ) : (
                    <p className="text-base px-6 py-5 font-normal text-center text-global-gray-brand">
                      No data found
                    </p>
                  )}
                </CustomDropdownCard>
              )}
            </div>
          ))}
      </div>
      <div className="order-1 md:order-2">
        <div ref={filterRef} className="w-full md:w-auto">
          <Button
            variant="secondary"
            className="text-txt-secondary px-[15.08px] py-2.5 w-full md:w-auto  gap-1.5 text-sm   font-semibold flex h-10 lg:h-auto"
            onMouseEnter={() => setIshovered(true)}
            onMouseLeave={() => setIshovered(false)}
            onClick={toggleMainDropdown}
          >
            <FilterOption
              width={20}
              height={20}
              strokeColor={
                isHovered ? "var(--color-icon-fill-secondary-button)" : "var(--color-txt-secondary)"
              }
            />
            {Object.keys(activeFilters).length > 0 ? (
              "More filters"
            ) : (
              <span className="tracking-[0.01em]">Filters</span>
            )}
          </Button>

          {mainDropdownVisible && (
            <CustomDropdownCard>
              {filterOptions.map((filter, index) => {
                const isSelected = activeFilters[filter];
                return (
                  <div
                    key={index}
                    onClick={() => addFilter(filter)}
                    onMouseEnter={() => setHoveredId(filter)}
                    onMouseLeave={() => setHoveredId(null)}
                    className={`cursor-pointer flex gap-2 flex-wrap items-center justify-start w-full pl-2 pr-2.5 py-2.5 rounded-md hover:bg-global-white ${isSelected ? "bg-global-white" : ""
                      }`}
                  >
                    <span className="text-base font-medium text-global-title truncate capitalize">
                      {filter === "order_no" ? "Order No." : (filter === "transparency" || filter === "verification") ? `${filter} %` : filter?.replace(/_/g, " ")}
                    </span>
                    {isSelected && (
                      <span className="ml-auto">
                        {hoveredId === filter ? (
                          <XCloseIcon
                            onClick={(e) => {
                              e.stopPropagation();
                              removeFilter(filter);
                            }}
                            strokeColor={"var(--color-login-text-title)"}
                            width={20}
                            height={20}
                          />
                        ) : (
                          <TickIcon width={20} height={20} />
                        )}
                      </span>
                    )}
                  </div>
                );
              })}
            </CustomDropdownCard>
          )}
        </div>
      </div>
    </div>
  );
};

export default OrderFilters;
