import { useQuery } from "@tanstack/react-query";
import { ArrowRight } from "assests";
import EmptyComponent from "components/Card/EmptyComponent";
import OrderMapCard from "components/Card/OrderMapCard";
import BreadCrumb from "components/TopHeader/BreadCrumb";
import TopNavbar from "components/TopHeader/TopNavbar";
import Card from "components/UI/Card";
import ChipButton from "components/UI/ChipButton";
import CircleProgressBar from "components/UI/CircleProgressBar";
import LoadingComponent from "components/UI/LoadingComponent";
import SubNavbar from "layout/SubNavbar";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getOrderDetails } from "services/order.services";
import { setOrderDetailsData, setSelectedTierLists } from "store/orderSlice";
import {
  formatLocation,
  formattedDate,
  transpercencyLineProgressPercentageColors,
  truncateString,
} from "utils/helper";
import OrderDetailsImageCard from "./OrderDetailsImageCard";
import UpdateSupplyChainCard from "./UpdateSupplyChainCard";

const OrderDetailsPage = () => {
  const dispatch = useDispatch();
  const { orderDetailsData, isSubSupplierCreateModalOpen, selectedSubTierId } = useSelector(
    (state) => state.orderSlice,
  );
  // const [isAddSupplier, setIsAddSupplier] = useState(false);
  const { orderId, workspaceId } = useParams();

  const {
    data: orderDetailsQuery,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ["orders", "getOrderDetails", orderId],
    queryFn: () => getOrderDetails(orderId),
    enabled: !!orderId,
  });

  useEffect(() => {
    if (
      !isLoading &&
      orderDetailsQuery &&
      orderDetailsQuery?.data &&
      orderDetailsQuery?.data?.order &&
      orderDetailsQuery?.data?.order?.transparency
    ) {
      const transparency = orderDetailsQuery?.data?.order?.transparency;
      let tierData = {};
      if (transparency) {
        for (let i = 0; i < 5; i++) {
          if (!tierData[`tier_${i}`]) {
            tierData[`tier_${i}`] = [];
          }
          if (transparency[`tier_${i}`] && transparency[`tier_${i}`].length > 0) {
            transparency[`tier_${i}`].map((item) =>
              tierData[`tier_${i}`].push({ label: item?.name, value: item?.id }),
            );
          }
        }
      }
      dispatch(setSelectedTierLists(tierData));
      dispatch(setOrderDetailsData(orderDetailsQuery?.data?.order));
    }
  }, [orderDetailsQuery, isLoading]);

  const convertSeason = (season) => {
    if (season.includes("/")) {
      return season
        .split("/")
        .map((part) => part[0]) // Take the first character of each part
        .join("/");
    }
    return season; // Return as is if there's no "/"
  };

  return (
    <>
      {isLoading ? (
        <LoadingComponent />
      ) : !isLoading && orderDetailsData ? (
        <>
          <TopNavbar isFromDashboard={false}>
            <BreadCrumb text="My Orders" />
          </TopNavbar>
          <div className="px-4 md:px-8  pt-[64px] md:pt-[108px] ">
            <div className="block md:hidden pt-4">
              <BreadCrumb text="My Orders" />
            </div>
            <div className="py-4 md:py-6 ">
              <SubNavbar title={orderDetailsData?.order_number} />
            </div>
            <div className="grid grid-cols-12 md:grid-cols-4 gap-4 md:gap-6">
              <div className="col-span-12 lg:col-span-1 w-full">
                <Card
                  size="sm"
                  className="bg-white flex flex-col h-[106px] justify-start items-start p-4 md:p-5 rounded-xl border border-border-secondary custom-card-buttom"
                >
                  <div className="mb-2 text-sm leading-5 font-medium text-[#535862] text-start">
                    Status
                  </div>
                  <div
                    className={
                      "px-1.5 py-[1px]  gap-1 flex justify-center items-center custom-card-buttom rounded-[6px] border border-[#D5D7DA] bg-white"
                    }
                  >
                    <div className="bg-global-primary w-1.5 h-1.5 rounded-full" />
                    <div className="text-[#414651] text-xs leading-[18px] font-medium">
                      {orderDetailsData?.status?.name}
                    </div>
                  </div>
                </Card>
              </div>
              <div className="col-span-12 lg:col-span-1 w-full">
                <Card
                  size="sm"
                  className="bg-white flex  h-[106px] justify-start items-center p-4 md:p-5 rounded-xl border border-border-secondary custom-card-buttom gap-2"
                >
                  <div className="w-full">
                    <div className="text-sm leading-5 font-medium text-card-small-title ">
                      Transparency Score{" "}
                    </div>
                    <div className="text-3xl font-semibold leading-[38px] text-global-title ">{`${orderDetailsData?.transparency?.total_score ? orderDetailsData?.transparency?.total_score : 0}%`}</div>
                  </div>
                  <div className="sticky top-0">
                    <CircleProgressBar
                      currentProgress={
                        orderDetailsData?.transparency?.total_score
                          ? orderDetailsData?.transparency?.total_score
                          : 0
                      }
                      size={17.25}
                      strokeColorVariable={transpercencyLineProgressPercentageColors(
                        `${orderDetailsData?.transparency?.total_score ? orderDetailsData?.transparency?.total_score : 0}`,
                      )}
                    />
                  </div>
                </Card>
              </div>
              <div className="col-span-12 lg:col-span-1 w-full">
                <Card
                  size="sm"
                  className="bg-white flex  h-[106px] justify-start items-center p-4 md:p-5 rounded-xl border border-border-secondary custom-card-buttom gap-2"
                >
                  <div className="w-full">
                    <div className="text-sm leading-5 font-medium text-card-small-title ">
                      Verification Score
                    </div>
                    <div className="text-3xl font-semibold leading-[38px] text-global-title ">{`${orderDetailsData?.transparency?.total_verification_score ? orderDetailsData?.transparency?.total_verification_score : 0}%`}</div>
                  </div>
                  <div className="sticky top-0">
                    <CircleProgressBar
                      currentProgress={
                        orderDetailsData?.transparency?.total_verification_score
                          ? orderDetailsData?.transparency?.total_verification_score
                          : 0
                      }
                      size={17.25}
                      strokeColorVariable={"--color-progress-surface-blue"}
                    />
                  </div>
                </Card>
              </div>
              <div className="col-span-12 lg:col-span-1 w-full">
                <Card
                  size="sm"
                  className="bg-white flex flex-col h-[106px] justify-start items-start p-4 md:p-5 rounded-xl border border-border-secondary custom-card-buttom"
                >
                  <div className="mb-2 text-sm leading-5 font-medium text-[#535862] text-start">
                    Linked Tiers
                  </div>

                  <div className="text-global-title leading-[38px] font-semibold text-3xl">
                    {orderDetailsData?.tier_totals?.total_linked_tiers ?? 0}
                    <span className="text-2xl">
                      /{orderDetailsData?.tier_totals?.total_tiers ?? 0}
                    </span>
                  </div>
                </Card>
              </div>
              <div className="col-span-12 lg:col-span-1 w-full">
                <Card
                  size="sm"
                  className="bg-white flex flex-col h-[106px] justify-start items-start p-4 md:p-5 rounded-xl border border-border-secondary custom-card-buttom"
                >
                  <div className="mb-2 text-sm leading-5 font-medium text-[#535862] text-start">
                    Quantity
                  </div>

                  <div className="text-global-title leading-[38px] font-semibold text-3xl">
                    {orderDetailsData.quantity && truncateString(orderDetailsData.quantity)}
                  </div>
                </Card>
              </div>
              <div className="col-span-12 lg:col-span-1 w-full">
                <Card
                  size="sm"
                  className="bg-white flex flex-col h-[106px] justify-start items-start p-4 md:p-5 rounded-xl border border-border-secondary custom-card-buttom"
                >
                  <div className="mb-2 text-sm leading-5 font-medium text-[#535862] text-start">
                    Season
                  </div>

                  <div className="text-global-title leading-[38px] font-semibold text-3xl">
                    {orderDetailsData.season &&
                      orderDetailsData.season.name &&
                      convertSeason(orderDetailsData.season.name)}
                    ‘{orderDetailsData?.season?.year?.slice(-2)}
                  </div>
                </Card>
              </div>
              <div className="col-span-12 lg:col-span-1 w-full">
                <Card
                  size="sm"
                  className="bg-white flex flex-col h-[106px] justify-start items-start p-4 md:p-5 rounded-xl border border-border-secondary custom-card-buttom"
                >
                  <div className="mb-2 text-sm leading-5 font-medium text-[#535862] text-start">
                    Order Date
                  </div>

                  <div className="text-global-title leading-[38px] font-semibold text-3xl">
                    {orderDetailsData?.order_date && formattedDate(orderDetailsData?.order_date)}
                  </div>
                </Card>
              </div>
              <div className="col-span-12 lg:col-span-1 w-full">
                <Card
                  size="sm"
                  className="bg-white flex flex-col h-[106px] justify-start items-start p-4 md:p-5 rounded-xl border border-border-secondary custom-card-buttom"
                >
                  <div className="mb-2 text-sm leading-5 font-medium text-[#535862] text-start">
                    Last Updated
                  </div>

                  <div className="text-global-title leading-[38px] font-semibold text-3xl">
                    {orderDetailsData?.updated_at && formattedDate(orderDetailsData?.updated_at)}
                  </div>
                </Card>
              </div>
            </div>
            <div className="pt-4 md:pt-6 ">
              <p className="text-[18px] leading-7 font-semibold text-global-title whitespace-nowrap">
                Supply Chain Tracking
              </p>
              <div className="overflow-x-auto pt-[2px]  mb-5">
                <div className="flex justify-start items-center gap-2 mb-1 md:mb-0">
                  <ChipButton
                    className={
                      "py-[2px] px-1.5 border border-[#E4E7EC] bg-white rounded-[6px] custom-card text-xs leading-[18px] font-medium text-global-title-2 whitespace-nowrap"
                    }
                  >
                    From:{" "}
                    {orderDetailsData?.supplier
                      ? formatLocation({
                          region: orderDetailsData?.supplier?.region,
                          country: orderDetailsData?.supplier?.country,
                        })
                      : orderDetailsData?.company &&
                          orderDetailsData?.company?.purchased_package === "supplier"
                        ? formatLocation({
                            region: orderDetailsData?.company?.region,
                            country: orderDetailsData?.company?.country,
                          })
                        : orderDetailsData?.workspace &&
                            orderDetailsData?.workspace?.purchased_package === "supplier"
                          ? formatLocation({
                              region: orderDetailsData?.workspace?.region,
                              country: orderDetailsData?.workspace?.country,
                            })
                          : "N/A"}
                  </ChipButton>
                  <div>
                    <ArrowRight width={20} height={20} fillColor={"var(--color-gray-900)"} />
                  </div>
                  <ChipButton
                    className={
                      "py-[2px] px-1.5 border border-[#E4E7EC] bg-white rounded-[6px] custom-card text-xs leading-[18px] font-medium text-global-title-2 whitespace-nowrap"
                    }
                  >
                    To:{" "}
                    {orderDetailsData?.company &&
                    (orderDetailsData?.company?.purchased_package === "retailer" ||
                      orderDetailsData?.company?.purchased_package === "importer" ||
                      orderDetailsData?.company?.purchased_package === "brand")
                      ? formatLocation({
                          region: orderDetailsData?.company?.region,
                          country: orderDetailsData?.company?.country,
                        })
                      : orderDetailsData?.workspace &&
                          (orderDetailsData?.workspace?.purchased_package === "retailer" ||
                            orderDetailsData?.workspace?.purchased_package === "importer" ||
                            orderDetailsData?.workspace?.purchased_package === "brand")
                        ? formatLocation({
                            region: orderDetailsData?.workspace?.region,
                            country: orderDetailsData?.workspace?.country,
                          })
                        : "N/A"}
                  </ChipButton>
                </div>
              </div>
            </div>
            <div className="flex  flex-col lg:flex-row gap-4 md:gap-6">
              <div className="w-full lg:w-[calc(100%_-_400px)]">
                <OrderMapCard orderDetailsData={orderDetailsData} />
                <OrderDetailsImageCard articleData={orderDetailsData?.article} />
              </div>

              <div className="w-full  lg:w-[400px]  md:pb-12 ">
                {orderDetailsData && <UpdateSupplyChainCard refetchOrderDetails={refetch} />}
              </div>
            </div>
          </div>
        </>
      ) : (
        <EmptyComponent text="You don’t have any Details yet." icon={false} />
      )}
    </>
  );
};

export default OrderDetailsPage;
