import React, { useEffect, useState } from "react";
import LeftForm from "./LeftForm";
import RightForm from "./RightForm";
import CompanyDetailsForm from "./CompanyDetailsForm";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import usePayment from "hooks/usePayment";
import { setShowCompanyDetails } from "store/subscriptionSlice";
import { useQuery } from "@tanstack/react-query";
import { getBillingDetails, getPaymentMethods } from "services/billing.services";
import {
  setBillingDetails,
  setIsEditingBillingDetails,
  setPaymentMethodDetails,
} from "store/billingSlice";
const UpgradePlan = () => {
  const dispatch = useDispatch();
  const { currentWorkspace } = useSelector((state) => state.authenticationSlice);
  const { selectedPlanData, showCompanyDetails } = useSelector((state) => state.subscriptionSlice);
  const { isEditingBillingDetails, currentPlan, billingDetails } = useSelector(
    (state) => state.billingSlice,
  );

  const {
    control,
    register,
    setValue,
    setError,
    handleSubmit,
    handleChangeCardItem,
    errors,
    isLoading,
    confirmPaymentMutation,
    downgradeOrUpgrade,
    errorMessage,
    isPending
  } = usePayment();

  const {
    data: paymentMethodDetails,
    isLoading: paymentMethodDetailsLoading,
    status: paymentMethodDetailsStatus,
  } = useQuery({
    queryKey: ["paymentMethodDetails", currentPlan],
    queryFn: () => getPaymentMethods(currentPlan?.subscription?.payment_method),
    enabled: !!currentPlan && downgradeOrUpgrade === "UPGRADE",
  });

  const {
    data: fetchedbillingDetails,
    isLoading: billingDetailsLoading,
    status,
  } = useQuery({
    queryKey: ["fetchedbillingDetails", currentWorkspace?.workspace_id],
    queryFn: () => getBillingDetails(currentWorkspace?.workspace_id),
    enabled: !!currentWorkspace,
  });

  const handleShowCompanyDetails = () => {
    dispatch(setShowCompanyDetails());
    if (isEditingBillingDetails) {
      dispatch(setIsEditingBillingDetails(false));
    }
  };

  useEffect(() => {
    if (selectedPlanData) {
      setValue("planId", selectedPlanData.id.toString());
    } else {
      console.error("Invalid selectedPlanData structure");
    }
  }, [selectedPlanData]);

  useEffect(() => {
    if (!billingDetailsLoading && fetchedbillingDetails && status === "success") {
      const billingDetailsData = fetchedbillingDetails.data.billing;
      dispatch(setBillingDetails(billingDetailsData));
    }
  }, [fetchedbillingDetails, billingDetailsLoading, status]);

  useEffect(() => {
    if (
      !paymentMethodDetailsLoading &&
      paymentMethodDetails &&
      paymentMethodDetailsStatus === "success"
    ) {
      const paymentMethodDetailsData = paymentMethodDetails.data.methods;
      dispatch(setPaymentMethodDetails(paymentMethodDetailsData));
    }
  }, [paymentMethodDetails, paymentMethodDetailsLoading, paymentMethodDetailsStatus]);

  if (!selectedPlanData)
    return (
      <Navigate to={`/workspace/${currentWorkspace?.user_workspace_id}/setting?selectedTab=plan`} />
    );

  return (
    <div className="w-full h-full">
      <div className="grid grid-cols-12 ">
        <LeftForm {...{ selectedPlanData, setValue, downgradeOrUpgrade }} />
        <div className="col-span-12 lg:col-span-6 px-4 lg:px-0">
          <CompanyDetailsForm
            {...{ handleShowCompanyDetails }}
            className={`${showCompanyDetails ? "block" : "hidden"}`}
          />
          <RightForm
            onShowCompanyDetails={handleShowCompanyDetails}
            {...{
              control,
              register,
              setValue,
              setError,
              handleSubmit,
              handleChangeCardItem,
              errors,
              isLoading,
              confirmPaymentMutation,
              selectedPlanData,
              downgradeOrUpgrade,
              errorMessage,
              isPending
            }}
            className={`${showCompanyDetails ? "hidden" : "block"}`}
          />
        </div>
      </div>
    </div>
  );
};

export default UpgradePlan;
