import { Switch } from "@headlessui/react";
import React from "react";

const ToggleSwitch = ({ autoRenew, handleSwitchChange, register }) => {
  return (
    <Switch
      checked={autoRenew}
      onChange={handleSwitchChange}
      className={`${
        autoRenew ? "bg-switch-active-green" : "bg-switch-inactive"
      } relative inline-flex h-5 w-9 outline-none focus:outline-none focus:ring-0 focus:border-0 items-center rounded-full transition-colors`}
    >
      <span
        className={`${
          autoRenew ? "translate-x-[18px]" : "translate-x-[2px]"
        } inline-block h-4 w-4 transform rounded-full bg-white transition-transform`}
      />
    </Switch>
  );
};

export default ToggleSwitch;
