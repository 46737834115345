import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useEffect } from "react";
import { useSelector } from "react-redux";

const Modal = ({ open, close, children }) => {
  const { isSidebarCollapsed } = useSelector((state) => state.navbarSlice);

  // useEffect(() => {
  //   const handleKeyDown = (event) => {
  //     if (open && (event.key === "Escape" || event.ctrlKey || event.metaKey)) {
  //       close(); // Close modal on Escape or any shortcut keys
  //     }
  //   };

  //   document.addEventListener("keydown", handleKeyDown);
  //   return () => {
  //     document.removeEventListener("keydown", handleKeyDown);
  //   };
  // }, [open, close]);

  return (
    <Transition appear show={!!open} as={Fragment}>
      <Dialog as="div" className="px-4 relative z-50 " onClose={close}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            className={`fixed inset-0 transition-opacity bg-[rgba(249,250,251,0.80)] backdrop-blur-[4.5px]  ${isSidebarCollapsed ? "left-0 md:left-[68px]" : "md:left-[296px]"}`}
          ></div>
        </Transition.Child>

        <div
          className={`fixed inset-0 overflow-y-auto ${isSidebarCollapsed ? "left-0 md:left-[68px]" : "md:left-[296px]"} `}
        >
          <div className="flex items-center justify-center min-h-full   text-center relative w-full p-4">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className={`text-left transition-all  transform w-full md:w-auto  `}>
                <div className="w-full  h-full custom-modal-shadow_template rounded-xl">
                  {children}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default Modal;
