const { createSlice } = require("@reduxjs/toolkit");

const subscriptionSlice = createSlice({
  name: "subscription",
  initialState: {
    activeModalStep: 1,
    isSubscriptionCreateModalOpen: false,
    subscriptionList: [],
    selectedPlanData: null,
    selectedPlanType: "month",
    autoRenew: true,
    workspaceCreateFormData: null,
    showWelcomeModal: false,
    showCompanyDetails: false,
  },

  reducers: {
    toggleIsSubscriptionCreateModalOpen: (state) => ({
      ...state,
      isSubscriptionCreateModalOpen: !state.isSubscriptionCreateModalOpen,
    }),
    toggleShowWelcomeModal: (state) => {
      state.showWelcomeModal = !state.showWelcomeModal;
    },
    setShowCompanyDetails: (state) => {
      state.showCompanyDetails = !state.showCompanyDetails;
    },

    setSubscriptionList: (state, action) => {
      if (action.payload.append) {
        return {
          ...state,
          subscriptionList: [action.payload.subscription, ...state.subscriptionList],
        };
      } else {
        return {
          ...state,
          subscriptionList: action.payload.subscriptionList,
        };
      }
    },
    setSelectedPlanData: (state, action) => {
      return {
        ...state,
        selectedPlanData: action.payload,
      };
    },
    setWorkSpaceCreateData: (state, action) => {
      return {
        ...state,
        workspaceCreateFormData: action.payload,
      };
    },
    setActiveModalStep: (state, payload) => {
      return {
        ...state,
        activeModalStep: payload.payload,
      };
    },
    setSelectedPlanType: (state, payload) => {
      return {
        ...state,
        selectedPlanType: payload.payload,
      };
    },
    setAutoRenew: (state, payload) => {
      return {
        ...state,
        autoRenew: payload.payload,
      };
    },
  },
});

export const {
  setSubscriptionList,
  toggleIsSubscriptionCreateModalOpen,
  setSelectedPlanData,
  setWorkSpaceCreateData,
  setActiveModalStep,
  toggleShowWelcomeModal,
  setSelectedPlanType,
  setAutoRenew,
  setShowCompanyDetails,
} = subscriptionSlice.actions;
export default subscriptionSlice.reducer;
