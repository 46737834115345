import React from "react";

const InvitePartnerIcon = React.forwardRef(
  (
    {
      width = "24",
      height = "24",
      viewBox = "0 0 24 24",
      strokeColor = "#414651",
      fill = "none",
      className,
    },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.101 4C11.3636 2.76281 13.0927 2 15 2C18.866 2 22 5.13401 22 9C22 10.9073 21.2372 12.6365 19.9999 13.899M16 15C16 18.866 12.866 22 9 22C5.13401 22 2 18.866 2 15C2 11.134 5.13401 8 9 8C12.866 8 16 11.134 16 15Z"
          stroke={strokeColor}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  },
);

InvitePartnerIcon.displayName = "InvitePartnerIcon";

export default InvitePartnerIcon;
