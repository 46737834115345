import BreadCrumb from "components/TopHeader/BreadCrumb";
import TopNavbar from "components/TopHeader/TopNavbar";
import SubNavbar from "layout/SubNavbar";
import { useEffect, useState } from "react";
import Template from "../Template/Template";
import queryString from "query-string";
import ArticleContent from "./ArticleContent";
import { removeQueryParam } from "utils/helper";
import { useLocation, useNavigate } from "react-router-dom";
const Articles = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const parsed = queryString.parse(location.search);
  const [activeTab, setActiveTab] = useState("Articles");
  const [selectedArticles, setSelectedArticles] = useState([]);
  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
    setSelectedArticles([]);
    removeQueryParam(["selectedTab"], location, navigate);
  };
  useEffect(() => {
    if (parsed.selectedTab) {
      setActiveTab(parsed.selectedTab);
    }
  }, [parsed.selectedTab]);

  return (
    <>
      <div>
        <TopNavbar isFromDashboard={false}>
          <BreadCrumb
            text={activeTab === "Articles" ? "My Articles" : "Supply Chain Templates"}
            textAnother={activeTab === "Articles" ? "" : "My Articles"}
            secondaryLink={() => handleTabClick("Articles")}
          />
        </TopNavbar>
        <>
          <div className="px-4 md:px-8  pt-[64px] md:pt-[108px]">
            <div className="block md:hidden pt-4">
              {" "}
              <BreadCrumb
                text={activeTab === "Articles" ? "My Articles" : "Supply Chain Templates"}
                textAnother={activeTab === "Articles" ? "" : "My Articles"}
              />
            </div>
            <div className="pt-4 md:pt-6">
              <SubNavbar
                title="My Articles"
                subtitle="Manage your articles and supply chain templates here."
              />
            </div>

            <div className="bg-global-divider-medium h-[1px] my-5  hidden md:block" />
            <div className="overflow-x-auto">
              <div className="mb-4 md:mb-5 flex items-center justify-between bg-transparent border border-border-divider-stroke rounded-md font-semibold w-[283px] h-9 whitespace-nowrap mt-4 lg:mt-0">
                <button
                  className={`w-full flex justify-center items-center text-center text-sm  px-2 lg:px-4 h-9 rounded-md ${activeTab === "Articles"
                    ? "bg-white border border-border-primary text-gray-900 custom-card  "
                    : "bg-transparent border-none text-gray-700"
                    }`}
                  onClick={() => handleTabClick("Articles")}
                >
                  Articles
                </button>
                <button
                  className={`w-full flex justify-center items-center text-center text-sm px-4 h-9 rounded-md ${activeTab === "Templates"
                    ? "bg-white border border-border-primary text-gray-900 custom-card  "
                    : "bg-transparent border-none text-gray-700"
                    }`}
                  onClick={() => handleTabClick("Templates")}
                >
                  Supply Chain Templates
                </button>
              </div>
            </div>
            {activeTab === "Articles" ? (
              <>
                <ArticleContent {...{ selectedArticles, setSelectedArticles }} />
              </>
            ) : (
              activeTab === "Templates" && <Template handleTabClick={handleTabClick} />
            )}
          </div>
        </>
      </div>
    </>
  );
};

export default Articles;
