import { ArrowRight, EyeIcon } from "assests";
import EmptyComponent from "components/Card/EmptyComponent";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

const OrderDetailsImageCard = ({ articleData }) => {
  return (
    <>
      {articleData && (
        <div className="pt-0 md:pt-6">

          <div className="text-[#101828] text-lg font-semibold  leading-7">
            {articleData?.gender && `${articleData?.gender?.name}'s `}
            <span>{articleData?.article_type?.name}</span>
          </div>
          <div className="overflow-x-auto">
            <div className="mb-1 md:mb-0">
              <div className="justify-start items-center gap-2 inline-flex whitespace-nowrap">
                <div className="px-1.5 py-0.5 bg-white rounded-md shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)] border border-[#e4e7ec] justify-start items-center flex">
                  <div className="text-center text-[#414651] text-xs font-medium  leading-[18px]">
                    Composition:{" "}
                    {articleData.compositions &&
                      articleData.compositions.length > 0 &&
                      articleData.compositions
                        .map((item) => `${item.percentage}% ${item.name}`)
                        .join(" / ")}
                  </div>
                </div>
                <ArrowRight width={20} height={20} />
                <div className="px-1.5 py-0.5 bg-white rounded-md shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)] border border-[#e4e7ec] justify-start items-center flex">
                  <div className="text-center text-[#414651] text-xs font-medium  leading-[18px] ">
                    Article Number: <span className="truncate">{articleData?.sku}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="overflow-x-auto pt-4 md:pt-5">
            <div className="mb-1 md:mb-0">
              <div className="justify-start items-center gap-4 md:gap-6 inline-flex">
                <div className="h-[360px] w-[348px]  relative overflow-hidden rounded-xl shadow-[0px_1px_2px_0px_rgba(10,13,18,0.05)] border border-[#e9e9eb]">
                  {articleData?.front_file_url ? (
                    <>
                      <img
                        className="w-full h-full "
                        src={articleData?.front_file_url}
                        alt={`back_image`}
                      />
                      <a target="_blank" rel="noreferrer" href={`${articleData?.front_file_url}`}>
                        <div className="absolute bottom-2 right-2">
                          <div className="p-2.5 bg-[#fcfcfc] rounded-full border border-[#d5d7da] justify-center items-center inline-flex cursor-pointer">
                            <div className="p-1 justify-center items-center inline-flex overflow-hidden">
                              <EyeIcon
                                width={20}
                                height={20}
                                strokeColor={"var(--color-icon-default-4)"}
                              />
                            </div>
                          </div>
                        </div>
                      </a>
                    </>
                  ) : (
                    <EmptyComponent text="No image uploaded yet " />
                  )}
                </div>
                <div className="h-[360px] w-[348px]  relative overflow-hidden rounded-xl shadow-[0px_1px_2px_0px_rgba(10,13,18,0.05)] border border-[#e9e9eb]">
                  {articleData?.back_file_url ? (
                    <>
                      <img
                        className="w-full h-full "
                        src={articleData?.back_file_url}
                        alt={`front_image`}
                      />

                      <a target="_blank" rel="noreferrer" href={`${articleData?.back_file_url}`}>
                        <div className="absolute bottom-2 right-2">
                          <div className="p-2.5 bg-[#fcfcfc] rounded-full border border-[#d5d7da] justify-center items-center inline-flex cursor-pointer">
                            <div className="p-1 justify-center items-center inline-flex overflow-hidden">
                              <EyeIcon
                                width={20}
                                height={20}
                                strokeColor={"var(--color-icon-default-4)"}
                              />
                            </div>
                          </div>
                        </div>
                      </a>
                    </>
                  ) : (
                    <EmptyComponent text="No image uploaded yet " />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default OrderDetailsImageCard;
