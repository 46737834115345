import {
  CertificateIcon,
  Cross,
  LinkIcon,
  PDFDragDropIcon,
  PDFIcon,
  Trash,
  UploadImgIcon,
} from "assests";
import { Button } from "components/UI/Button";
import CustomSelect from "components/UI/CustomSelect";
import Divider from "components/UI/Divider";
import ErrorMessage from "components/UI/ErrorMessage";
import { Input } from "components/UI/Input";
import { Label } from "components/UI/Label";
import { Controller } from "react-hook-form";
import {
  setWorkspaceCertificateDetails,
  toggleActionableWrokspaceCertificateId,
  toggleisCertificateCreateModalOpen,
  toggleisCertificateEditable,
  toggleisWrokspaceCertificateCreateModalOpen,
} from "store/certificateSlice";

import { useQueryClient } from "@tanstack/react-query";
import ToasterIcon from "components/UI/ToasterIcon";
import UpgradeComponent from "components/UI/UpgradeComponent";
import useWorkspaceCertificate from "hooks/useWorkspaceCertificate";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import Datepicker from "react-tailwindcss-datepicker";
import {
  preventMinus,
  preventPasteNegative,
  preventSpaceAndPasteNegative,
  preventSpaceMinus,
  setTimeInDateObject,
  truncateString,
} from "utils/helper";

const CreateCertificate = ({ workspaceRefetch, supplierId }) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const {
    actionableWrokspaceCertificateId,
    isworkspaceCertificateCreateModalOpen,
    isCertificateCreateModalOpen,
  } = useSelector((state) => state.certificateSlice);
  const {
    control,
    setValue,
    getValues,
    watch,
    setError,
    clearErrors,
    isPending,
    isSuccess,
    certificateType,
    certifiedBy,
    register,
    handleSubmit,
    onFormSubmit,
    errors,
    onError,
    imagePreviews,
    isDragOver,
    handleDragEnter,
    handleDragLeave,
    handleDrop,
    onFileDropOrChange,
    handleCertFileDelete,
    fileData,
    isCertificateEditable,
    actionableCertificateId,
    workspaceCertificateDetails,
    reset,
    showFileData,
    supplierList,
    isLoading,
    isCertificateDetailsLoading
  } = useWorkspaceCertificate({ workspaceRefetch, supplierId });
  const handleCancel = () => {
    reset();
    closeModal();
  };
  const closeModal = () => {
    if (isCertificateCreateModalOpen) {
      dispatch(toggleisCertificateCreateModalOpen());
    }
    if (isworkspaceCertificateCreateModalOpen) {
      dispatch(toggleisWrokspaceCertificateCreateModalOpen());
    }
    if (actionableWrokspaceCertificateId) {
      dispatch(toggleActionableWrokspaceCertificateId());
    }
    if (workspaceCertificateDetails) {
      dispatch(setWorkspaceCertificateDetails());
    }
    if (isCertificateEditable) {
      dispatch(toggleisCertificateEditable());
    }
  };

  return (
    <>
      <div className="relative  z-[2] border border-global-divider-soft rounded-xl  w-full lg:w-[600px] max-h-[600px] py-6 bg-white">
        <div className="flex justify-between gap-x-4 px-6 pb-5">
          {isLoading || (actionableWrokspaceCertificateId && isCertificateDetailsLoading) ? <div></div> :
            <div className="flex justify-start items-center gap-4">
              <UpgradeComponent>
                <CertificateIcon />
              </UpgradeComponent>
              <div className="flex justify-between ">
                <div className="flex flex-col gap-0 ">
                  <h2 className="text-sm lg:text-lg  font-semibold text-start text-login-text-title ">
                    {actionableWrokspaceCertificateId &&
                      workspaceCertificateDetails &&
                      truncateString(
                        `${workspaceCertificateDetails?.certificate_type?.name}${" "}${workspaceCertificateDetails?.certificate_id}`,
                      )}
                    {!actionableWrokspaceCertificateId && "New Certificate"}
                  </h2>
                  <p className="text-xs lg:text-sm text-global-paragraph font-normal text-wrap">
                    {actionableWrokspaceCertificateId &&
                      workspaceCertificateDetails &&
                      (!isCertificateEditable
                        ? truncateString("View certificate details here.")
                        : truncateString(`Edit certificate details here.`))}
                    {!actionableWrokspaceCertificateId && "Add certificate details here."}
                  </p>
                </div>
                {/* <Cross className="w-6 h-6" /> */}
              </div>
            </div>
          }
          <div>
            <Cross
              fillColor="var(--color-icon-fill-input)"
              width={24}
              height={24}
              onClick={handleCancel}
              className="cursor-pointer"
            />
          </div>
        </div>

        {isLoading || (actionableWrokspaceCertificateId && isCertificateDetailsLoading) ? (
          <div className="grid gap-2">
            <div className="flex items-center justify-center w-full h-32 mt-10">
              <div className="border-4 rounded-full w-7 h-7 animate-spin border-global-primary border-t-transparent"></div>
            </div>
            <p className="mt-5 text-center">Preparing form data, please wait...</p>
          </div>
        ) : (
          <form
            onSubmit={handleSubmit(onFormSubmit)}
            onError={onError}
            method="post"
            className="w-full rounded-xl"
          >
            <div className="grid grid-cols-2 w-full gap-y-3 lg:gap-y-6 gap-x-5 px-6  pt-5 h-[350px] lg:h-[380px] overflow-y-auto">
              <div className="col-span-2 ">
                <div className="grid gap-1.5">
                  <Label htmlFor="certificate_type_id">Partner Name</Label>
                  <CustomSelect
                    control={control}
                    name="certificate_type_id"
                    placeholder="Select partner"
                    {...register("workspace_id")}
                    className="disabled:border-none"
                    options={supplierList}
                    isClearable={false}
                    isDisabled={!isCertificateEditable}
                    style={{
                      padding: "4px 10px 4.5px 6px",
                      fontSize: "16px",
                      width: "100%",
                      iconSize: 16,
                    }}
                  />
                  <ErrorMessage errors={errors?.workspace_id?.message} />
                </div>
              </div>
              <div className="col-span-2 lg:col-span-1 ">
                <div className="grid gap-1.5">
                  <Label htmlFor="certificate_type_id">Certificate Type</Label>
                  <CustomSelect
                    control={control}
                    name="certificate_type_id"
                    placeholder="Select type"
                    {...register("certificate_type_id")}
                    className="disabled:border-none"
                    options={certificateType}
                    isClearable={false}
                    isDisabled={!isCertificateEditable}
                    style={{
                      padding: "4px 10px 4.5px 6px",
                      fontSize: "16px",
                      width: "100%",
                      iconSize: 16,
                    }}
                  />
                  <ErrorMessage errors={errors?.certificate_type_id?.message} />
                </div>
              </div>
              <div className="col-span-2 lg:col-span-1">
                <div className="grid gap-1.5">
                  <Label htmlFor="company_name">Certificate Number</Label>
                  <Input
                    id="certificate_id"
                    type="text"
                    placeholder="Enter Number"
                    {...register("certificate_id")}
                    className={`border-input-stroke-default  truncate`}
                    onPaste={preventSpaceAndPasteNegative}
                    onKeyPress={preventSpaceMinus}
                    disabled={!isCertificateEditable}
                    size="lg"
                  />
                  <ErrorMessage errors={errors?.certificate_id?.message} />
                </div>
              </div>
              <div className="col-span-2">
                <div className="grid gap-1.5">
                  <Label>Certified By</Label>
                  <CustomSelect
                    control={control}
                    name="certified_by_id"
                    placeholder="Select certifier"
                    {...register("certified_by_id")}
                    options={certifiedBy}
                    isClearable={false}
                    isDisabled={!isCertificateEditable}
                    style={{
                      padding: "4px 10px 4.5px 6px",
                      fontSize: "16px",
                      width: "100%",
                    }}
                  />
                  <ErrorMessage errors={errors?.certified_by_id?.message} />
                </div>
              </div>
              <div className="col-span-2 lg:col-span-1">
                <div className="grid gap-1.5">
                  <Label>Issue Date</Label>
                  <div className="relative">
                    <Controller
                      control={control}
                      name="issued_at"
                      render={({ field }) => (
                        <>
                          <Datepicker
                            useRange={false}
                            asSingle={true}
                            value={field.value}
                            placeholder={"Select date"}
                            displayFormat="DD.MM.YYYY"
                            containerClassName="certificate-date-picker "
                            inputClassName={`flex justify-between w-full gap-3 px-4 h-[44px]  py-2 items-center text-dropdown-text-data bg-dropdown-surface-background  border border-input-stroke-default text-base placeholder:text-input-text-placeholder placeholder:text-base placeholder:font-normal self-stretch  focus:shadow-[none] ring-0 focus:ring-1 focus:ring-offset-input-surface-background disabled:bg-input-surface-disabled disabled:border-dropdown-stroke-default-2 focus:border-input-stroke-active focus:ring-input-stroke-active rounded-lg `}
                            primaryColor={"emerald"}
                            toggleClassName="absolute text-icon-fill-input custom-toggle-icon right-0 h-full z-[4]"
                            disabled={!isCertificateEditable}
                            onChange={(value) => {
                              field.onChange(setTimeInDateObject(value));
                              if (
                                getValues("expired_at") &&
                                getValues("expired_at").startDate
                              ) {
                                if (
                                  new Date(getValues("expired_at").startDate) <
                                  new Date(value.startDate)
                                ) {
                                  setError("issued_at.startDate", {
                                    type: "custom",
                                    message: "Check for the expiry date",
                                  });
                                } else {
                                  clearErrors("issued_at.startDate");
                                }
                              }
                            }}
                          />
                        </>
                      )}
                    />
                  </div>
                  <ErrorMessage errors={errors?.issued_at?.startDate?.message} />
                </div>
              </div>
              <div className="col-span-2 lg:col-span-1">
                <div className="grid gap-1.5">
                  <Label>Expiry Date</Label>
                  <div className="relative">
                    <Controller
                      control={control}
                      name="expired_at"
                      render={({ field }) => (
                        <>
                          <Datepicker
                            useRange={false}
                            asSingle={true}
                            value={field.value}
                            placeholder={"Select date"}
                            displayFormat="DD.MM.YYYY"
                            containerClassName="certificate-date-picker"
                            inputClassName={`flex justify-between w-full gap-3 px-4 h-[44px]  py-2 items-center text-dropdown-text-data bg-dropdown-surface-background  border border-input-stroke-default text-base placeholder:text-input-text-placeholder placeholder:text-base placeholder:font-normal self-stretch  focus:shadow-[none] ring-0 focus:ring-1 focus:ring-offset-input-surface-background disabled:bg-input-surface-disabled disabled:border-dropdown-stroke-default-2 focus:border-input-stroke-active focus:ring-input-stroke-active rounded-lg `}
                            primaryColor={"emerald"}
                            toggleClassName="absolute text-icon-fill-input custom-toggle-icon right-0 h-full z-[4]"
                            disabled={!isCertificateEditable}
                            onChange={(value) => {
                              if (getValues("issued_at") && getValues("issued_at").startDate) {
                                if (
                                  new Date(getValues("issued_at").startDate) >
                                  new Date(value.startDate)
                                ) {
                                  setError("expired_at.startDate", {
                                    type: "custom",
                                    message: "Check for the issue date",
                                  });
                                } else {
                                  clearErrors("expired_at.startDate");
                                }
                              }
                              field.onChange(setTimeInDateObject(value));
                            }}
                          />
                        </>
                      )}
                    />
                  </div>
                  <ErrorMessage errors={errors?.expired_at?.startDate?.message} />
                </div>
              </div>

              <div className="col-span-2">
                <div className="grid gap-1.5">
                  {isCertificateEditable && (
                    <div className="grid gap-1.5">
                      <div className="flex flex-col md:flex-row gap-3 items-center group justify-center md:items-start md:justify-start lg:gap-5">
                        <div
                          className={`w-full  relative  bg-white rounded-xl h-[126px] ring-1 ring-inset ring-border-divider-stroke group-hover:ring-2  group-hover:ring-global-primary cursor-pointer `}
                          onDragOver={handleDragEnter}
                          onDragEnter={handleDragEnter}
                          onDragLeave={handleDragLeave}
                          onDrop={handleDrop}
                        >
                          <div>
                            <label htmlFor="dropzone-file" className="cursor-pointer">
                              <div className="w-full flex-col px-3 md:px-6 py-[15.25px] justify-start items-center inline-flex">
                                <div className="w-full flex-col justify-start items-center gap-3 flex">
                                  <ToasterIcon className="rounded-lg">
                                    <UploadImgIcon />
                                  </ToasterIcon>
                                  <div className="flex-col justify-start items-center gap-1 flex">
                                    <div className="justify-center items-start gap-1 md:inline-flex">
                                      <div className="text-global-title text-xs lg:text-sm font-semibold leading-tight text-center md:text-start">
                                        Click to upload
                                      </div>
                                      <div className="text-global-paragraph text-xs lg:text-sm font-normal leading-tight">
                                        or drag and drop
                                      </div>
                                    </div>
                                    <div className="text-center text-global-paragraph text-[10px] lg:text-xs font-normal  leading-[18px]">
                                      PDF (max. 10mb)
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className={`h-full hidden group-hover:block`}>
                                <PDFDragDropIcon className="absolute top-1 right-1 lg:top-auto lg:bottom-5 lg:right-6 " />
                              </div>
                            </label>

                            <input
                              id="dropzone-file"
                              type="file"
                              className="hidden"
                              accept=".pdf"
                              onChange={(e) => onFileDropOrChange(e)}
                              multiple
                            />
                          </div>
                        </div>
                      </div>
                      <ErrorMessage errors={errors?.cert_files?.message} />
                    </div>
                  )}
                  {imagePreviews.length > 0 ? (
                    <div className="grid grid-cols-1 gap-4 mt-2.5">
                      {imagePreviews.map((preview, index) => (
                        <div className="relative col-span-1" key={index}>
                          <div
                            className={`flex justify-between rounded-xl   px-4 py-[15px] gap-4 border ${!isCertificateEditable
                              ? "bg-input-surface-disabled border-dropdown-stroke-default-2"
                              : "bg-dropdown-surface-background border-input-stroke-default "
                              }`}
                          >
                            <div className="flex justify-start gap-3">
                              <PDFIcon
                                className="w-10 h-10"
                                fillColor={"var(--color-global-primary)"}
                              />
                              <div className="flex flex-col gap-0">
                                <p className="text-sm font-medium leading-5 text-global-paragraph truncate">
                                  {truncateString(showFileData[index]?.name)}
                                </p>
                                <p className="text-global-paragraph text-sm font-normal leading-5">
                                  {`${(showFileData[index]?.size / (1024 * 1024)).toFixed(4)} MB`}
                                </p>
                              </div>
                            </div>
                            {isCertificateEditable && (
                              <Trash
                                strokeColor={"var(--color-icon-outline-default-hover)"}
                                onClick={() => handleCertFileDelete(index)}
                                className="cursor-pointer"
                              />
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    " "
                  )}
                </div>
              </div>
            </div>
            <Divider className={"mb-4 mt-8 lg:mb-6"} />
            {(!actionableWrokspaceCertificateId ||
              (actionableWrokspaceCertificateId && isCertificateEditable)) && (
                <>
                  <div className="flex  justify-center items-center lg:justify-between px-6">
                    <div className="flex flex-col lg:flex-row  w-full gap-3">
                      <Button
                        variant={"secondary"}
                        className={
                          "text-btn-text-default px-2.5 py-4 w-full  h-11 text-base font-semibold rounded-lg "
                        }
                        onClick={handleCancel}
                      >
                        Cancel
                      </Button>

                      <Button
                        variant={"primary"}
                        type="submit"
                        isDisabled={isPending}
                        className={
                          "text-btn-text-default px-2.5 py-4 w-full gap-1.5  h-11 text-base font-semibold rounded-lg text-white "
                        }
                      >
                        {" "}
                        {actionableWrokspaceCertificateId ? (
                          "Save"
                        ) : (
                          <>
                            <span>Link Certificate</span>
                            <LinkIcon width={20} height={20} />
                          </>
                        )}
                      </Button>
                    </div>
                  </div>
                </>
              )}

            {actionableWrokspaceCertificateId && !isCertificateEditable && (
              <div className="flex  justify-center items-center lg:justify-between px-6">
                <div className="flex flex-col lg:flex-row  w-full gap-3">
                  <Button
                    variant={"secondary"}
                    className={
                      "text-btn-text-default px-2.5 py-4 w-full  h-11 text-base font-semibold rounded-lg "
                    }
                    onClick={handleCancel}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            )}
          </form>
        )}
      </div>
    </>
  );
};

export default CreateCertificate;
