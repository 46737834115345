import { Cross, Plus } from "assests";
import { forwardRef } from "react";
import { Controller, useWatch } from "react-hook-form";
import { PiCaretDownBold } from "react-icons/pi";
import ReactSelect, { components, createFilter } from "react-select";
import { Button } from "./Button";
import Divider from "./Divider";
import { Input } from "./Input";

const CustomSelectEdition = forwardRef((props, ref) => {
  const {
    control,
    name,
    value,
    placeholder,
    options,
    handleOnChange,
    icon,
    style,
    onInputChange,
    isClearable,
    components,
    isMulti,
    iconLeft,
    isDisabled,
    isViewMode,
    isViewMode1,
    isError,
    isWatchedValueNeed = true,
    getOptionLabel,
    iconSize,
    noHeight,
  } = props;

  const customStyles = {
    menu: (provided) => ({
      ...provided,
      textAlign: "left",
      cursor: "pointer",
      backgroundColor: "var(--color-input-surface-background)",
      zIndex: 9,
      borderRadius: "8px",
      padding: "12px ",
      boxShadow: "0 5px 12px rgba(0, 0, 0, 0.1)",
      border: "var(--color-global-divider)",
      minWidth: style.minWidth && style.minWidth,
      overflowY: "clip",
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: "white",
      borderRadius: "6px",
      marginRight: "6px",
      marginLeft: 0,
      marginTop: 0,
      marginBottom: 0,
      minWidth: style.minWidth && style.minWidth,
      overflowY: "clip",
      border: "1px solid #D0D5DD",
      boxShadow: "0 1px 2px 0 rgba(16, 24, 40, 0.05)",
      whiteSpace: "nowrap", // Prevents wrapping
      textOverflow: "ellipsis", // Displays ellipsis
      overflow: "hidden", // Hides overflow content
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      height: "22px",
      padding: "0px 6px",
      fontSize: "12px",
      fontWeight: "500px",
      color: "#344054",
    }),

    multiValueRemove: (provided) => ({
      ...provided,
      color: "#98A2B3", // Set the cross icon color to white
      cursor: "pointer", // Change cursor on hover
      padding: "0px", // Add some padding around the cross icon
      fontSize: "12px", // Set font size for the cross icon
      backgroundColor: "transparent", // Set background color to transparent
      borderRadius: "50%", // Make the icon button circular
      ":hover": {
        backgroundColor: "transparent", // Change background color when hovering over the icon
      },
    }),

    control: (provided) => ({
      ...provided,
      height: "auto",
      padding: style?.padding ? style?.padding : "10px 12px",
      boxShadow: "0 1px 2px 0 rgba(16, 24, 40, 0.05)",
      textAlign: "left",
      border: isDisabled
        ? "1px solid #D5D7DA"
        : style?.border
          ? style?.border
          : isError
            ? "1px solid #D92D20"
            : "1px solid #D5D7DA",

      outline: "none",
      borderRadius: style?.borderRadius ? style?.borderRadius : "6px",
      backgroundColor: isDisabled
        ? "var(--color-input-surface-hover)"
        : isError
          ? "#FEF3F2"
          : "var(--color-input-surface-background)",
      "&:hover": {
        backgroundColor: "var(--color-input-surface-hover)",
      },
      justifyContent: "center",
      alignItems: "center",
    }),

    input: (provided) => ({
      ...provided,
      fontSize: "12px",
      color: style?.color ? style?.color : "var(--color-input-text-data)",
      paddingLeft: iconLeft ? "20px" : "",
      overflowX: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "var(--color-gray-700)",
      fontSize: "12px",
      fontWeight: 400,
      opacity: style?.opacity ? style?.opacity : "1",
      paddingLeft: iconLeft ? "20px" : "",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: " #344054", // Handle color dynamically
      fontSize: "12px", // Font size
      paddingLeft: iconLeft ? "20px" : "", // Conditional padding for iconLeft
      textTransform: "capitalize", // Capitalize text
      placeholder: "16px", // Placeholder size (if needed in your design)
      paddingTop: "2px", // Padding on top
      paddingBottom: "2px", // Padding on bottom
      paddingLeft: "6px", // Padding on left
      paddingRight: "6px", // Padding on right (you had px: 0 which is shorthand for padding-left and padding-right)
      height: "22px", // Height
      backgroundColor: "white", // Background color
      border: "1px solid #D0D5DD", // Border color with solid
      display: "flex", // Flexbox display
      justifyContent: "start", // Center content horizontally
      alignItems: "center", // Center content vertically
      gap: "1.5rem", // Space between items (1.5rem is about 24px, but you can adjust if needed)
      width: "auto",
      borderRadius: "6px",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    }),

    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "var(--color-option-surface-hover)" : "initial",
      fontWeight: state.isSelected ? 500 : 400,
      "&:hover": {
        backgroundColor: state.isSelected
          ? "var(--color-option-surface-hover)"
          : "var(--color-option-surface-hover)",
        fontWeight: 500,
      },
      color: "var(--color-option-text-default)",
      textTransform: "capitalize",
      fontSize: "16px",
      borderRadius: "4px",
      marginBottom: "8px",
      cursor: "pointer",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    }),
    iconLeft: (provided) => ({
      ...provided,
      position: "absolute",
      left: 12, // Adjust the left padding as needed
      top: "50%",
      transform: "translateY(-50%)",
    }),
  };
  const renderSelectedOptions = () => {
    if (value && value.length > 0) {
      return value?.map((val, index) => (
        <span
          key={index} // Use a unique key, here we use the index
          className="py-[2px] px-0 h-[22px] bg-white border border-[#D0D5DD] custom-card text-[#344054] text-xs font-medium rounded-lg flex justify-center items-center gap-1.5 whitespace-nowrap truncate w-full "
        >
          {val?.label}
        </span>
      ));
    } else {
      return (
        <span className="py-[2px] px-0 h-[22px] bg-white border border-[#D0D5DD] custom-card text-[#344054] text-xs font-medium rounded-lg flex justify-center items-center gap-1.5 whitespace-nowrap truncate w-full">
          {value}
        </span>
      );
    }
  };

  const renderSelectedOptions1 = () => {
    if (value && value?.length > 0) {
      return value.map((val, index) => (
        <button
          key={index}
          type="button"
          className="py-[2px] px-0 h-[22px] bg-white border border-[#D0D5DD] custom-card text-[#344054] text-xs font-medium rounded-md  flex justify-center items-center gap-1.5 whitespace-nowrap truncate "
        >
          {val.label}
          <Cross className={"w-4 h-4 "} fillColor={"#98A2B3"} />
        </button>
      ));
    } else {
      return null;
    }
  };

  const watchedValue = useWatch({ control, name });

  return (
    <div>
      {(isViewMode1 && value && value?.length > 0) || (isViewMode && value && value?.length > 0) ? (
        <div
          className={
            isViewMode1
              ? `w-full flex flex-row items-center  bg-${isError ? "red" : "white"
              } border border-[#D0D5DD] custom-card rounded-md  overflow-x-ellipsis whitespace-nowrap text-[#344054] text-xs font-medium`
              : "px-3  placeholder:text-base placeholder:text-text-input-text-placeholder border border-[#D0D5DD] custom-card rounded-md  overflow-x-ellipsis whitespace-nowrap text-[#344054] text-xs font-medium"
          }
        >
          {isViewMode1 ? renderSelectedOptions1() : renderSelectedOptions()}
        </div>
      ) : (
        <Controller
          control={control}
          defaultValue={watchedValue || null}
          name={name}
          render={({ field }) => (
            <div className="relative">
              {iconLeft && (
                <div className="absolute inset-y-0 left-0 z-[1] flex items-center pl-3">
                  {iconLeft}
                </div>
              )}
              <ReactSelect
                ref={ref}
                classNamePrefix="select2-selection "
                id={name}
                options={options}
                value={isWatchedValueNeed ? watchedValue || value || null : value || null}
                onChange={(selectedOption) => {
                  field.onChange(selectedOption);
                  if (handleOnChange) {
                    handleOnChange(selectedOption);
                  }
                }}
                isMulti={isMulti}
                isSearchable={true}
                isClearable={isClearable}
                onInputChange={onInputChange}
                autoFocus={false}
                placeholder={placeholder}
                styles={customStyles}
                getOptionLabel={getOptionLabel}
                components={{
                  ...components,
                  DropdownIndicator: () => (
                    <>
                      {icon ? (
                        icon
                      ) : (
                        <PiCaretDownBold size={16} color="var(--color-icon-fill-input)" />
                      )}
                    </>
                  ),
                  IndicatorSeparator: () => null,
                  clearIndicator: (provided) => ({
                    ...provided,
                    color: "var(--color-icon-fill-input)",
                    onClick: (e) => {
                      console.log("clearing the value");
                    },
                  }),
                }}
                isDisabled={isDisabled}
              />
            </div>
          )}
        />
      )}
    </div>
  );
});

export const customMenuList = ({ children, label, onClick, ...props }) => (
  <div>
    <components.MenuList {...props}>
      <div className="relative">{children}</div>
    </components.MenuList>
    <div className="w-full z-10 ">
      <Divider className="mt-1" />
      <div className="flex justify-center items-center pt-2">
        <Button
          variant={"tertiary"}
          onClick={() => {
            onClick();
            props.selectProps.onMenuClose();
          }}
          className={"text-btn-text-default text-center text-[10px] h-9"}
        >
          <Plus width={10} height={10} fillColor={"var(--color-icon-outline-default)"} />

          {label || "Add New Item"}
        </Button>
      </div>
    </div>
  </div>
);

export default CustomSelectEdition;
