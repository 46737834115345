import { Button } from "components/UI/Button";
import ErrorMessage from "components/UI/ErrorMessage";
import { Input } from "components/UI/Input";
import { Label } from "components/UI/Label";
import React from "react";
import { handleRestrictNumbersOnKeyPress, handleRestrictNumbersOnPaste } from "utils/helper";

const FirstStep = ({ closeModal, register, errors, nextStep }) => {
  return (
    <div className="w-full">
      <div className="flex flex-col gap-1 mt-4 mb-3 lg:mb-5">
        <h3 className="text-lg font-semibold text-global-title">Invite Member</h3>
        <p className="text-sm text-global-paragraph ">Enter member details.</p>
      </div>
      <div className=" px-[2px] overflow-y-auto">
        <div className="grid grid-cols-1 gap-4 lg:gap-8 mb-6">
          <div className="grid gap-1.5">
            <Label>
              First Name <span className="text-global-primary">*</span>
            </Label>

            <Input
              className="h-10"
              placeholder="Enter first name"
              onKeyPress={handleRestrictNumbersOnKeyPress}
              onPaste={handleRestrictNumbersOnPaste}
              {...register("first_name", {
                setValueAs: (value) => value.trim(),
              })}
            />

            <ErrorMessage errors={errors?.first_name?.message} />
          </div>

          <div className="grid gap-1.5">
            <Label>
              Last Name <span className="text-global-primary">*</span>
            </Label>

            <Input
              className="h-10"
              placeholder="Enter last name"
              onKeyPress={handleRestrictNumbersOnKeyPress}
              onPaste={handleRestrictNumbersOnPaste}
              {...register("last_name", {
                setValueAs: (value) => value.trim(),
              })}
            />

            <ErrorMessage errors={errors?.last_name?.message} />
          </div>

          <div className="grid gap-1.5">
            <Label>
              Email address <span className="text-global-primary">*</span>
            </Label>

            <Input className="h-10" placeholder="Enter email" {...register("email")} />

            <ErrorMessage errors={errors?.email?.message} />
          </div>
          <div className="grid gap-1.5">
            <Label>
              Job title
            </Label>
            <Input className="h-10" placeholder="Enter job title" {...register("job_title")} />
            <ErrorMessage errors={errors?.job_title?.message} />
          </div>
        </div>
      </div>
      <div className="pt-5 lg:pt-[41.5px] flex justify-center lg:justify-end flex-col lg:flex-row items-center gap-3">
        <Button
          variant={"secondary"}
          className={"w-full lg:w-[90px] h-11 py-2.5 px-4 font-semibold text-base"}
          onClick={closeModal}
        >
          Cancel
        </Button>
        <Button
          variant={"primary"}
          className={"w-full lg:w-[107px] h-11 py-2.5 px-4 font-semibold text-base"}
          onClick={nextStep}
        >
          Continue
        </Button>
      </div>
    </div>
  );
};

export default FirstStep;
