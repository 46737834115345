import React from "react";

const InvitePlus = React.forwardRef(
  (
    {
      width = "32",
      height = "32",
      viewBox = "0 0 32 32",
      fill = "none",
      fillColor = "white",
      className,
      ...props
    },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M31.3735 18.4663H18.0424V31.5804H13.5987V18.4663H0.267578V14.095H13.5987V0.980957H18.0424V14.095H31.3735V18.4663Z"
          fill={fillColor}
        />
      </svg>
    );
  },
);

InvitePlus.displayName = "InvitePlus";

export default InvitePlus;
