import { emailValidation, passwordPattern } from "utils/constant";
import * as z from "zod";
export const profileSettingSchema = z.object({
  first_name: z
    .string({
      required_error: "Required",
    })
    .min(3, { message: "Enter at least 3 characters" })
    .max(100, { message: "First name must not exceed 100 characters" }),
  last_name: z
    .string({
      required_error: "Required",
    })
    .min(1, { message: "Enter at least 1 characters" })
    .max(100, { message: "Last name must not exceed 100 characters" }),
  email: emailValidation,
  telephone: z
    .string({
      required_error: "Required",
      invalid_type_error: "Required",
    })
    .min(7, "Required"),
  job_title: z.string().optional().nullable(true),
});

export const setpasswordSchema = z
  .object({
    old_password: z.string().refine((value) => passwordPattern.test(value), {
      message: "",
    }),
    password: z.string().refine((value) => passwordPattern.test(value), {
      message: "",
    }),
    password_confirmation: z
      .string({
        required_error: "",
        invalid_type_error: "",
      })
      .min(1, { message: "" }),
  })
  .refine((data) => data.password === data.password_confirmation, {
    message: "The new password and confirmation password do not match.",
    path: ["password_confirmation"],
  });
