import React from "react";

const MailIcon = React.forwardRef(
  (
    {
      width = "20",
      height = "20",
      viewBox = "0 0 20 20",
      fill = "none",
      fillColor = "#8E8F98",
      className,
    },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.66663 5.83301L8.47073 10.5959C9.0217 10.9816 9.29719 11.1744 9.59685 11.2491C9.86154 11.3151 10.1384 11.3151 10.4031 11.2491C10.7027 11.1744 10.9782 10.9816 11.5292 10.5959L18.3333 5.83301M5.66663 16.6663H14.3333C15.7334 16.6663 16.4335 16.6663 16.9683 16.3939C17.4387 16.1542 17.8211 15.7717 18.0608 15.3013C18.3333 14.7665 18.3333 14.0665 18.3333 12.6663V7.33301C18.3333 5.93288 18.3333 5.23281 18.0608 4.69803C17.8211 4.22763 17.4387 3.84517 16.9683 3.60549C16.4335 3.33301 15.7334 3.33301 14.3333 3.33301H5.66663C4.26649 3.33301 3.56643 3.33301 3.03165 3.60549C2.56124 3.84517 2.17879 4.22763 1.93911 4.69803C1.66663 5.23281 1.66663 5.93288 1.66663 7.33301V12.6663C1.66663 14.0665 1.66663 14.7665 1.93911 15.3013C2.17879 15.7717 2.56124 16.1542 3.03165 16.3939C3.56643 16.6663 4.26649 16.6663 5.66663 16.6663Z"
          stroke={fillColor}
          strokeWidth="1.66667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  },
);

export default MailIcon;
