import { Cross, TemplateIcon } from "assests";
import { Button } from "components/UI/Button";
import CustomSelect, { customMenuList } from "components/UI/CustomSelect";
import Divider from "components/UI/Divider";
import ErrorMessage from "components/UI/ErrorMessage";
import { Input } from "components/UI/Input";
import { Label } from "components/UI/Label";
import Modal from "components/UI/Modal";
import UpgradeComponent from "components/UI/UpgradeComponent";
import { useDispatch, useSelector } from "react-redux";
import {
  setRefetchTemplateData,
  setShowTemplateForm,
  updateOrderSliceState,
} from "store/orderSlice";
import OrderTemplateForm from "./OrderTemplateForm";

const SelectTemplate = ({
  control,
  register,
  getValues,
  errors,
  formatedTemplateList,
  tierList,
  tiersItem,
  handleClickAddNewTempalte,
  handleTierOptionSelect,
  handleSelectTemplate,
  onClose,
}) => {
  const dispatch = useDispatch();
  const { showCreateTemplateForm } = useSelector((state) => state.orderSlice);

  return (
    <>
      <div className="w-full lg:w-[600px] lg:h-[600px] py-6 z-[2]  rounded-xl bg-white ">
        <div className="grid grid-cols-1 gap-4 lg:gap-6 w-full ">
          <div className="absolute right-5 top-5 cursor-pointer z-[2] ">
            <Cross fillColor={"#A4A7AE"} height={24} width={24} onClick={onClose} />
          </div>
          <div className="flex justify-start  px-6 z-[1] ">
            <UpgradeComponent>
              <TemplateIcon />
            </UpgradeComponent>

            <div className="flex justify-between gap-6 lg:gap-8 px-3 lg:px-4 z-[2]">
              <div className="flex flex-col gap-1">
                <h2 className="font-semibold text-sm lg:text-lg leading-7 text-start text-primary-900 w-full">
                  Supply Chain Settings
                </h2>
                <p className="text-sm text-login-text-paragraph font-normal truncate leading-5">
                  Adjust supply chain settings for this order here.
                </p>
              </div>
            </div>
          </div>
          <div className="z-[2]">
            <form>
              <div className="grid grid-cols-1 w-full px-6  max-h-[392px] overflow-auto mt-3 lg:mt-5 pb-6">
                <div className="grid gap-1.5 w-full pb-4 lg:pb-6 ">
                  <Label
                    htmlFor="templateName"
                    className="text-sm leading-5 font-medium whitespace-nowrap"
                  >
                    Template Name
                  </Label>
                  <CustomSelect
                    control={control}
                    name="template_id"
                    placeholder="Select Template"
                    options={formatedTemplateList}
                    handleOnChange={handleSelectTemplate}
                    components={{
                      MenuList: (props) =>
                        customMenuList({
                          ...props,
                          label: "Add Template",
                          onClick: handleClickAddNewTempalte,
                        }),
                    }}
                    style={{
                      padding: "3.5px 10px 7px 1.5px",
                      fontSize: "16px",
                    }}
                  />
                  <ErrorMessage errors={errors?.template_id?.message} />
                </div>
                <p className="pb-4 text-base font-bold leading-10 md:text-center text-global-title">
                  {getValues("template_id") ? "Template details" : "New template"}
                </p>

                <div className="grid gap-1.5 w-full pb-4 lg:pb-6 ">
                  <Label htmlFor="templateName" className="text-xs font-semibold">
                    Template Name
                  </Label>
                  <Input
                    id="templateName"
                    type="text"
                    className={`px-[14px] py-[12.5px] h-11 custom-card text-base font-normal placeholder:text-base leading-[19.36px] text-gray-950 placeholder:leading-[19.36px] placeholder:text-gray-700 hover:outline-none hover:border hover:border-input-stroke-default`}
                    name="templates.name"
                    placeholder="For T-shirt"
                    {...register("templates.name")}
                  />
                  <ErrorMessage errors={errors?.templates?.name?.message} />
                </div>
                {tierList && (
                  <>
                    <div className="flex flex-row gap-6 lg:gap-8 w-full py-4">
                      <Label
                        htmlFor="templateName"
                        className="text-sm leading-5 pr-1 tracking-wide font-medium whitespace-nowrap"
                      >
                        Tier 4
                      </Label>
                      <div className="w-full grid grid-cols-1 gap-1.5">
                        <CustomSelect
                          control={control}
                          name="templates.tier_5"
                          placeholder="Select Template"
                          options={tierList["tier_5"]}
                          value={tiersItem["tier_5"]}
                          handleOnChange={(selectedOption) =>
                            handleTierOptionSelect("tier_5", selectedOption)
                          }
                          isClearable={false}
                          isMulti={true}
                          style={{
                            padding: "3px 10px 3px 6px",
                            fontSize: "12px",
                            width: "100%",
                            noHeight: true,
                          }}
                        />
                        <ErrorMessage errors={errors?.templates?.tier_5?.message} />
                      </div>
                    </div>
                    <div className="flex flex-row gap-6 lg:gap-8 w-full py-4">
                      <Label
                        htmlFor="templateName"
                        className="text-sm leading-5 pr-1 tracking-wide font-medium whitespace-nowrap"
                      >
                        Tier 3
                      </Label>
                      <div className="w-full grid grid-cols-1 gap-1.5">
                        <CustomSelect
                          control={control}
                          name="templates.tier_4"
                          placeholder="Select Template"
                          options={tierList["tier_4"]}
                          handleOnChange={(selectedOption) =>
                            handleTierOptionSelect("tier_4", selectedOption)
                          }
                          isClearable={false}
                          value={tiersItem["tier_4"] || []}
                          isMulti={true}
                          style={{
                            padding: "3px 10px 3px 6px",
                            fontSize: "12px",
                            width: "100%",
                            noHeight: true,
                          }}
                        />
                        <ErrorMessage errors={errors?.templates?.tier_4?.message} />
                      </div>
                    </div>
                    <div className="flex flex-row gap-6 lg:gap-8 w-full py-4">
                      <Label
                        htmlFor="templateName"
                        className="text-sm leading-5 pr-1 tracking-wide font-medium whitespace-nowrap"
                      >
                        Tier 2
                      </Label>
                      <div className="w-full grid grid-cols-1 gap-1.5">
                        <CustomSelect
                          control={control}
                          name="templates.tier_3"
                          placeholder="Select Template"
                          options={tierList["tier_3"]}
                          handleOnChange={(selectedOption) =>
                            handleTierOptionSelect("tier_3", selectedOption)
                          }
                          isClearable={false}
                          value={tiersItem["tier_3"]}
                          isMulti={true}
                          style={{
                            padding: "3px 10px 3px 6px",
                            fontSize: "12px",
                            width: "100%",
                            noHeight: true,
                          }}
                        />
                        <ErrorMessage errors={errors?.templates?.tier_3?.message} />
                      </div>
                    </div>
                    <div className="flex flex-row gap-6 lg:gap-8 w-full py-4">
                      <Label
                        htmlFor="templateName"
                        className="text-sm leading-5 pr-1 tracking-wide font-medium whitespace-nowrap"
                      >
                        Tier 1
                      </Label>
                      <div className="w-full grid grid-cols-1 gap-1.5">
                        <CustomSelect
                          control={control}
                          name="templates.tier_2"
                          placeholder="Select Template"
                          options={tierList["tier_2"]}
                          handleOnChange={(selectedOption) =>
                            handleTierOptionSelect("tier_2", selectedOption)
                          }
                          isClearable={false}
                          value={tiersItem["tier_2"]}
                          isMulti={true}
                          style={{
                            padding: "3px 10px 3px 6px",
                            fontSize: "12px",
                            width: "100%",
                            noHeight: true,
                          }}
                        />
                        <ErrorMessage errors={errors?.templates?.tier_2?.message} />
                      </div>
                    </div>
                    <div className="flex flex-row gap-6 lg:gap-8 w-full py-4">
                      <Label
                        htmlFor="templateName"
                        className="text-sm leading-5 pr-1 tracking-wide font-medium whitespace-nowrap"
                      >
                        Tier 0
                      </Label>
                      <div className="w-full grid grid-cols-1 gap-1.5">
                        <CustomSelect
                          control={control}
                          name="templates.tier_1"
                          placeholder="Select Template"
                          options={tierList["tier_1"]}
                          handleOnChange={(selectedOption) =>
                            handleTierOptionSelect("tier_1", selectedOption)
                          }
                          isClearable={false}
                          value={tiersItem["tier_1"]}
                          isMulti={true}
                          style={{
                            padding: "3px 10px 3px 6px",
                            fontSize: "12px",
                            width: "100%",
                            noHeight: true,
                          }}
                        />
                        <ErrorMessage errors={errors?.templates?.tier_1?.message} />
                      </div>
                    </div>
                  </>
                )}
              </div>
              <Divider className={"mb-6"} />
              <div className="grid gap-6  px-6 sticky  mt-4 lg:mt-0 ">
                <div className="flex  justify-center items-center lg:justify-between gap-4">
                  <div className="flex flex-col lg:flex-row  w-full gap-3">
                    <Button
                      type="button"
                      size={"md"}
                      className={
                        "w-full text-txt-secondary-secondary bg-white h-11 px-4 py-2.5 text-base leading-6 font-semibold custom-outline-shadow hover:text-white"
                      }
                      variant={"secondary"}
                      onClick={onClose}
                    >
                      Cancel
                    </Button>
                    <Button
                      onClick={() => dispatch(setShowTemplateForm(false))}
                      size={"md"}
                      className="h-11 px-4 py-2.5 text-base w-full "
                    >
                      Save
                    </Button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <Modal open={showCreateTemplateForm} close={() => null}>
        <OrderTemplateForm
          refetch={() => {
            dispatch(setRefetchTemplateData(true));
          }}
          closeModal={() => dispatch(updateOrderSliceState({ showCreateTemplateForm: false }))}
        />
      </Modal>
    </>
  );
};

export default SelectTemplate;
