import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  Action,
  Audit_icon,
  InfoNewIcon,
  MoreOptions,
  PDFIcon,
  PlanIcon,
  Shock,
  TickIcon,
  TrashUpdateIcon,
} from "assests";
import EmptyTable from "components/Card/EmptyTable";
import SearchCreatePanelUpdate from "components/SearchCreatePanelUpdate";
import BreadCrumb from "components/TopHeader/BreadCrumb";
import TopNavbar from "components/TopHeader/TopNavbar";
import { Button } from "components/UI/Button";
import LoadingComponent from "components/UI/LoadingComponent";
import Modal from "components/UI/Modal";
import SortIcons from "components/UI/SortIcons";
import TertiaryPagination from "components/UI/TertiaryPagination";
import useCustomToaster from "hooks/useCustomToaster";
import SubNavbar from "layout/SubNavbar";

import { IconButton, Menu } from "@mui/material";
import DeleteButton from "components/MoreAction/DeleteButton";
import EditButton from "components/MoreAction/EditButton";
import MoreActionOptionUpdated from "components/MoreAction/MoreActionOptionUpdated";
import CommonDiscardButtonUpdated from "components/UI/CommonDiscardButtonUpdated";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import {
  bulkDeleteWorkspaceAudit,
  deleteAudit,
  downloadAudits,
  getAudits,
  getAuditTypes,
} from "services/audit.services";

import {
  setworkspaceAllAuditData,
  setWorkspaceAuditList,
  toggleActionableWrokspaceAuditId,
  toggleDeleteWorkspaceauditModal,
  toggleisAuditEditable,
  toggleisWrokspaceAuditCreateModalOpen,
} from "store/auditSlice";
import {
  formatDateToDayMonthYear,
  getAuditProgress,
  getInitials,
  truncateString,
} from "utils/helper";

import ViewButton from "components/MoreAction/ViewButton";
import { setRefetchBillingData } from "store/billingSlice";
import AuditFilters from "./AuditFilters";
import CreateAudit from "./CreateAudit";
import usePermission from "hooks/usePermission";
import { useNavigate } from "react-router-dom";

const AuditsMain = () => {
  const [selectedOrders, setSelectedOrders] = useState([]);
  const { showCustomToast } = useCustomToaster();
  const { hasPermission } = usePermission();
  const navigate = useNavigate();
  const { refetchBillingData, workspacePlan } = useSelector((state) => state.billingSlice);
  const { user, currentWorkspace, subscription, subscription_status } = useSelector(
    (state) => state.authenticationSlice,
  );
  const supplierId = currentWorkspace?.workspace_id && parseInt(currentWorkspace?.workspace_id);
  const {
    workspaceAuditList,
    workspaceAllAuditData,
    isAuditEditable,
    deleteWorkspaceModal,
    actionableWrokspaceAuditId,
    isworkspaceAuditCreateModalOpen,
  } = useSelector((state) => state.auditSlice);
  const [anchorEl, setAnchorEl] = useState(null);
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
    if (actionableWrokspaceAuditId) {
      dispatch(toggleActionableWrokspaceAuditId());
    }
  };
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [orderBy, setOrderBy] = useState("");
  const [enteredSearchKey, setEnteredSearchKey] = useState("");
  const [activeFilters, setActiveFilters] = useState({});
  const [filteredData, setFilteredData] = useState({});
  const [auditOptions, setAuditOptions] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState("");
  const handleSizeChange = (newSize) => {
    setSize(newSize);
  };

  const {
    data: auditsQueryData,
    isLoading: isauditsQueryLoading,
    refetch,
  } = useQuery({
    queryKey: [
      "audits",
      "getAudits",
      supplierId,
      page,
      size,
      orderBy,
      selectedStatus,
      enteredSearchKey,
      filteredData,
    ],
    queryFn: () =>
      getAudits({
        page,
        per_page: size,
        sort: orderBy,
        search: enteredSearchKey,
        workspace_id: parseInt(supplierId),
        result_type: selectedStatus ? [selectedStatus] : "",
        ...filteredData,
      }),
    enabled: !!supplierId && !!user && !!currentWorkspace && (!!page || !!size),
  });

  const {
    data: articlesNopaginatedData,
    isLoading: isArticlesNopaginatedData,
    refetch: refetchNopaginatedData,
  } = useQuery({
    queryKey: ["articlesnopaginate", "getData", supplierId],
    queryFn: () =>
      getAudits({
        workspace_id: parseInt(supplierId),
        pagination: "no",
        // type: "partner",
      }),
    enabled: !!supplierId,
  });

  useEffect(() => {
    if (!isArticlesNopaginatedData && articlesNopaginatedData) {
      if (articlesNopaginatedData?.data?.audits) {
        setAuditOptions(articlesNopaginatedData?.data?.audits);
      }
    }
  }, [articlesNopaginatedData, isArticlesNopaginatedData]);

  const refetchDatas = () => {
    refetch();
    refetchNopaginatedData();
  };

  useEffect(() => {
    if (auditsQueryData && auditsQueryData.status === 200 && !isauditsQueryLoading) {
      const details = auditsQueryData.data.audits.data;
      dispatch(setWorkspaceAuditList({ append: false, workspaceAuditList: details }));
      dispatch(setworkspaceAllAuditData(auditsQueryData?.data?.audits));
    }
  }, [auditsQueryData, isauditsQueryLoading]);

  const onClickCheckbox = (inviatation) => {
    const findedItem = selectedOrders.some((item) => item.id === inviatation.id);
    if (!findedItem) {
      setSelectedOrders((prevSelectedOrders) => [...prevSelectedOrders, inviatation]);
    } else {
      setSelectedOrders((prevSelectedOrders) =>
        prevSelectedOrders.filter((item) => item.id !== inviatation.id),
      );
    }
  };

  const closeModal = () => {
    if (isworkspaceAuditCreateModalOpen) {
      dispatch(toggleisWrokspaceAuditCreateModalOpen());
    }
    if (actionableWrokspaceAuditId) {
      dispatch(toggleActionableWrokspaceAuditId());
    }
    if (deleteWorkspaceModal) {
      dispatch(toggleDeleteWorkspaceauditModal());
    }
    if (isAuditEditable) {
      dispatch(toggleisAuditEditable());
    }
    if (showUpgradeModal) {
      setShowUpgradeModal(false);
    }
  };

  const handleSelectAll = (e) => {
    if (!e.target.checked || !workspaceAuditList.length > 0) {
      setSelectedOrders([]);
      return;
    }
    const invitationIds =
      workspaceAuditList.length > 0 && workspaceAuditList.map((inviation) => inviation);
    setSelectedOrders(invitationIds);
  };

  const handleDelete = (ids) => {
    if (actionableWrokspaceAuditId) {
      deleteUserMutation.mutate(actionableWrokspaceAuditId);
    } else {
      const allIds = ids.map((item) => item.id);
      if (allIds) {
        deleteAllMutation.mutate({ ids: allIds });
      }
    }
  };
  const openDeleteModal = (e) => {
    e.stopPropagation();
    dispatch(toggleDeleteWorkspaceauditModal());
  };

  const openSingleModal = (e, item) => {
    e.stopPropagation();
    setAnchorEl(null);
    setSelectedOrders([]);
    dispatch(toggleActionableWrokspaceAuditId(item.id));
    dispatch(toggleDeleteWorkspaceauditModal());
  };

  const deleteUserMutation = useMutation({
    mutationKey: ["auditDelete", "delete"],
    mutationFn: (data) => deleteAudit(data),
    onMutate: () => {
      showCustomToast(
        {
          title: "Removing...",
        },
        "auditDelete",
      );
    },
    onSuccess: (res) => {
      toast.dismiss("auditDelete");
      showCustomToast({
        title: res.data.message || "Removed successfully",
      });
      refetchDatas();
      if (!refetchBillingData) {
        dispatch(setRefetchBillingData(true));
      }
      closeModal();
      queryClient.invalidateQueries(["auditDelete", "get", "delete"]);
    },
    onError: (error) => {
      toast.dismiss("auditDelete");
      showCustomToast({
        title: error.response.data.message || error.message,
      });
    },
    onSettled: () => {
      toast.dismiss("auditDelete");
    },
  });

  const deleteAllMutation = useMutation({
    mutationKey: ["allAuditsDelete", "deleteAllAudit"],
    mutationFn: (data) => bulkDeleteWorkspaceAudit(data),
    onMutate: () => {
      showCustomToast(
        {
          title: "Removing...",
        },
        "deleteAllAudit",
      );
    },
    onSuccess: (res) => {
      toast.dismiss("deleteAllAudit");
      showCustomToast({
        title: res.data.message || "Removed successfully",
      });
      refetchDatas();
      if (!refetchBillingData) {
        dispatch(setRefetchBillingData(true));
      }
      setSelectedOrders([]);
      closeModal();
      queryClient.invalidateQueries(["allAuditsDelete", "get", "delete"]);
    },
    onError: (error) => {
      toast.dismiss("deleteAllAudit");
      showCustomToast({
        title: error.response.data.message || error.message,
      });
    },
    onSettled: () => {
      toast.dismiss("deleteAllAudit");
    },
  });

  const downloadInvitationsAsCSV = useMutation({
    mutationKey: ["audits", "download"],
    mutationFn: (data) => downloadAudits(data),
    onMutate: () => {
      showCustomToast(
        {
          title: "Downloading...",
        },
        "downloadAudits",
      );
    },
    onSuccess: (res) => {
      toast.dismiss("downloadAudits");
      showCustomToast({
        title: "Downloaded successfully",
      });
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "audits.csv");
      document.body.appendChild(link);
      link.click();
      link.remove();
      queryClient.invalidateQueries(["audits", "download"]);
    },
    onError: (error) => {
      toast.dismiss("downloadAudits");
      showCustomToast({
        title: error.response.data.message || error.message,
      });
    },
  });

  const handleDownloadCSV = () => {
    downloadInvitationsAsCSV.mutate({
      workspace_id: parseInt(currentWorkspace?.workspace_id),
      type: "partner",
    });
  };

  const handleCreateAction = () => {
    if ((currentWorkspace && currentWorkspace.is_owner && (!subscription || (subscription && (!subscription_status || subscription_status === "canceled")))) || !workspacePlan) {
      setShowUpgradeModal(true);
    } else {
      dispatch(toggleisWrokspaceAuditCreateModalOpen());
      dispatch(toggleisAuditEditable());
      if (actionableWrokspaceAuditId) {
        dispatch(toggleActionableWrokspaceAuditId());
      }
      setShowUpgradeModal(false);
    }
  };

  const handleClickOptionButton = (auditId, actionType) => {
    switch (actionType) {
      case "create":
        handleCreateAction();
        setAnchorEl(null);
        break;
      case "view":
        dispatch(toggleActionableWrokspaceAuditId(auditId));
        dispatch(toggleisWrokspaceAuditCreateModalOpen());
        if (isAuditEditable) {
          dispatch(toggleisAuditEditable());
        }
        setAnchorEl(null);
        break;
      case "edit":
        dispatch(toggleActionableWrokspaceAuditId(auditId));
        dispatch(toggleisAuditEditable());
        dispatch(toggleisWrokspaceAuditCreateModalOpen());
        setAnchorEl(null);
        break;
      default:
        break;
    }
  };

  const handleActionClick = (id) => {
    dispatch(toggleActionableWrokspaceAuditId(id));
  };

  useEffect(() => {
    if (user && currentWorkspace) {
      const handleKeyDown = (e) => {
        if ((e.ctrlKey || e.metaKey) && (e.key === "E" || e.key === "e") && hasPermission("uploadCertificate")) {
          e.preventDefault();
          if (actionableWrokspaceAuditId) {
            handleClickOptionButton(actionableWrokspaceAuditId, "edit");
          }
        }

        if ((e.ctrlKey || e.metaKey) && e.shiftKey && e.key.toLowerCase() === "v") {
          if (actionableWrokspaceAuditId) {
            e.preventDefault();
            setAnchorEl(null);
            handleClickOptionButton(actionableWrokspaceAuditId, "view");
          }
        }
        if (e.key === "Delete" && hasPermission("uploadCertificate")) {
          e.preventDefault();
          if (actionableWrokspaceAuditId) {
            setAnchorEl(null);
            dispatch(toggleActionableWrokspaceAuditId(actionableWrokspaceAuditId));
            dispatch(toggleDeleteWorkspaceauditModal());
          } else if (selectedOrders && selectedOrders.length > 0) {
            e.preventDefault();
            dispatch(toggleDeleteWorkspaceauditModal());
          }
        }
        if ((e.ctrlKey || e.metaKey) && (e.key === "B" || e.key === "b") && hasPermission("canAddCertificate")) {
          e.preventDefault();
          setAnchorEl(null);
          handleClickOptionButton(actionableWrokspaceAuditId, "create");
        }
        if ((e.ctrlKey || e.metaKey) && (e.key === "A" || e.key === "a") && hasPermission("uploadCertificate")) {
          e.preventDefault();
          const allChecked =
            workspaceAuditList?.length > 0 && selectedOrders.length === workspaceAuditList.length;
          const newSelectAllState = !allChecked;
          const checkboxEvent = { target: { checked: newSelectAllState } };
          handleSelectAll(checkboxEvent);
        }
      };
      window.addEventListener("keydown", handleKeyDown);
      return () => {
        window.removeEventListener("keydown", handleKeyDown);
      };
    }
  }, [
    user,
    currentWorkspace,
    dispatch,
    actionableWrokspaceAuditId,
    selectedOrders,
    handleSelectAll,
  ]);

  const handleSearch = (e) => {
    const searchText = e.target.value?.trim();
    if (searchText.length >= 2) {
      setEnteredSearchKey(searchText);
    } else {
      setEnteredSearchKey("");
    }
  };

  const [title, setTitle] = useState("Audits");

  // Handle status change and update title
  const handleStatusChange = (value) => {
    setSelectedStatus(value);
    setSelectedOrders([]);
    // Update title based on selected status
    if (value === "") {
      setTitle("Audits");
    } else if (value === "Pass") {
      setTitle("Passed Audits");
    } else if (value === "Conditional Pass") {
      setTitle("Conditional Passed Audits");
    } else if (value === "Failed") {
      setTitle("Failed Audits");
    }
  };
  const statusOrder = [
    {
      label: "Pass",
      value: "Pass",
    },
    {
      label: "Conditional Pass",
      value: "Conditional Pass",
    },
    {
      label: "Fail",
      value: "Failed",
    },
  ];

  return (
    <>
      <TopNavbar isFromDashboard={false}>
        <BreadCrumb textAnother="My Audits" text={selectedStatus === "" ? "All" : title} />
      </TopNavbar>
      <div className="px-4 md:px-8  pt-[64px] md:pt-[108px]">
        <div className="block md:hidden mt-4">
          {" "}
          <BreadCrumb text={title} />
        </div>
        <div className="pt-6">
          <SubNavbar title="My Audits" subtitle="View and manage your supplier audits here." />
        </div>
        <div className="bg-global-divider-medium h-[1px] my-5  hidden md:block" />
        <div className="mb-5 overflow-x-auto">
          <div className="mb-1 md:mb-0 flex items-center justify-between bg-transparent border border-border-divider-stroke  rounded-md font-semibold w-[360px] h-9 whitespace-nowrap mt-4 lg:mt-0">
            <button
              key="view_all"
              className={`w-full flex justify-center items-center text-center text-sm px-4 h-9 rounded-md ${selectedStatus === ""
                ? "bg-white border border-border-primary text-gray-900 custom-card rounded-lg"
                : "bg-transparent border-none text-gray-700"
                }`}
              onClick={() => handleStatusChange("")}
            >
              View all
            </button>
            {statusOrder.length > 0 &&
              statusOrder.map((result, index) => (
                <button
                  key={index}
                  className={`w-full flex justify-center items-center text-center text-sm px-4 h-9 rounded-md ${selectedStatus === result.value
                    ? "bg-white border border-border-primary text-gray-900 custom-card rounded-lg"
                    : "bg-transparent border-none text-gray-700"
                    }`}
                  onClick={() => handleStatusChange(result.value)}
                >
                  {result.label}
                </button>
              ))}
          </div>
        </div>

        <div className="bg-btn-surface-secondary border border-border-divider-stroke  rounded-xl custom-card w-full mb-6  mt-4 lg:mt-0">
          <SearchCreatePanelUpdate
            createTxt="Add Audit"
            title={title}
            handleDownloadCSV={handleDownloadCSV}
            selectedItems={selectedOrders}
            openDeleteModal={openDeleteModal}
            btnText={workspaceAllAuditData && workspaceAllAuditData.total}
            onBtnClick={() => handleClickOptionButton(null, "create")}
            onChange={handleSearch}
            classWidth="w-full md:w-[120px] whitespace-nowrap"
            permissionText="canAddCertificate"
            permissionText2="uploadCertificate"
          >
            <AuditFilters
              workspaceId={supplierId}
              {...{
                activeFilters,
                setActiveFilters,
                setFilteredData,
                auditOptions,
                setAuditOptions,
              }}
            />
          </SearchCreatePanelUpdate>

          <div className="w-full max-w-screen overflow-x-auto ">
            <table className="w-full border-spacing-0 table-auto rounded-table-custom overflow-x-auto">
              <thead className="text-sm text-left whitespace-nowrap text-global-paragraph">
                <tr className="border-b  border-t border-b-global-divider-soft border-t-global-divider-soft">
                  <th className="pl-6 pr-4 py-[11.5px]">
                    <div className="flex items-center gap-3">
                      <input
                        type="checkbox"
                        className={`rounded-md  w-5 h-5 ${workspaceAuditList && workspaceAuditList.length > 0 ? "cursor-pointer" : "cursor-default"}`}
                        onChange={handleSelectAll}
                        checked={
                          workspaceAuditList &&
                          workspaceAuditList.length > 0 &&
                          selectedOrders.length === workspaceAuditList.length
                        }
                      />
                      <div className="flex items-center">
                        <span className="mr-1 text-xs font-medium text-global-paragraph leading-[18px]">
                          Audit Name
                        </span>
                        <SortIcons
                          hasData={workspaceAuditList && workspaceAuditList.length > 0}
                          sortName="name"
                          {...{ orderBy, setOrderBy }}
                        />
                      </div>
                    </div>
                  </th>

                  <th className="relative px-4  py-[11.5px]  ">
                    <div className="flex items-center">
                      <span className="mr-1 text-xs font-medium text-global-paragraph leading-[18px]">
                        Days until Expiry
                      </span>
                      <SortIcons
                        hasData={workspaceAuditList && workspaceAuditList.length > 0}
                        sortName="expiry"
                        {...{ orderBy, setOrderBy }}
                      />
                    </div>
                  </th>

                  <th className="relative px-4 py-[11.5px]  ">
                    <div className="flex items-center">
                      <span className="mr-1 text-xs font-medium text-global-paragraph leading-[18px]">
                        Audit No.
                      </span>
                      <SortIcons
                        hasData={workspaceAuditList && workspaceAuditList.length > 0}
                        sortName="audit_id"
                        {...{ orderBy, setOrderBy }}
                      />
                    </div>
                  </th>
                  <th className="relative px-4 py-[11.5px]  ">
                    <div className="flex items-center">
                      <span className="mr-1 text-xs font-medium text-global-paragraph leading-[18px]">
                        Partner Name
                      </span>
                      <SortIcons
                        hasData={workspaceAuditList && workspaceAuditList.length > 0}
                        sortName="partner_name"
                        {...{ orderBy, setOrderBy }}
                      />
                    </div>
                  </th>

                  <th className="relative px-4 py-[11.5px]  ">
                    <div className="flex items-center">
                      <span className="mr-1 text-xs font-medium text-global-paragraph leading-[18px]">
                        Result
                      </span>
                      <SortIcons
                        hasData={workspaceAuditList && workspaceAuditList.length > 0}
                        sortName="result_type"
                        {...{ orderBy, setOrderBy }}
                      />
                    </div>
                  </th>

                  <th className="relative px-4 py-[11.5px]  ">
                    <div className="flex items-center">
                      <span className="mr-1 text-xs font-medium text-global-paragraph leading-[18px]">
                        Issue Date
                      </span>
                      <SortIcons
                        hasData={workspaceAuditList && workspaceAuditList.length > 0}
                        sortName="issue_date"
                        {...{ orderBy, setOrderBy }}
                      />
                    </div>
                  </th>
                  <th className="relative px-4 py-[11.5px]  ">
                    <div className="flex items-center">
                      <span className="mr-1 text-xs font-medium text-global-paragraph leading-[18px]">
                        Expiry Date
                      </span>
                      <SortIcons
                        hasData={workspaceAuditList && workspaceAuditList.length > 0}
                        sortName="expired_at"
                        {...{ orderBy, setOrderBy }}
                      />
                    </div>
                  </th>
                  <th className="relative px-4 py-[11.5px]  ">
                    <div className="flex items-center">
                      <span className="mr-1 text-xs font-medium text-global-paragraph leading-[18px]">
                        Street
                      </span>
                      <SortIcons
                        hasData={workspaceAuditList && workspaceAuditList.length > 0}
                        sortName="street"
                        {...{ orderBy, setOrderBy }}
                      />
                    </div>
                  </th>
                  <th className="relative px-4 py-[11.5px]  ">
                    <div className="flex items-center">
                      <span className="mr-1 text-xs font-medium text-global-paragraph leading-[18px]">
                        City
                      </span>
                      <SortIcons
                        hasData={workspaceAuditList && workspaceAuditList.length > 0}
                        sortName="region"
                        {...{ orderBy, setOrderBy }}
                      />
                    </div>
                  </th>
                  <th className="relative px-4 py-[11.5px]  ">
                    <div className="flex items-center">
                      <span className="mr-1 text-xs font-medium text-global-paragraph leading-[18px]">
                        Country
                      </span>
                      <SortIcons
                        hasData={workspaceAuditList && workspaceAuditList.length > 0}
                        sortName="country"
                        {...{ orderBy, setOrderBy }}
                      />
                    </div>
                  </th>
                  <th className="relative px-4 py-[11.5px]  ">
                    <div className="flex items-center">
                      <span className="mr-1 text-xs font-medium text-global-paragraph leading-[18px]">
                        Uploaded By
                      </span>
                      <SortIcons
                        hasData={workspaceAuditList && workspaceAuditList.length > 0}
                        sortName="partner_id"
                        {...{ orderBy, setOrderBy }}
                      />
                    </div>
                  </th>
                  <th className="relative px-4 py-[11.5px]  ">
                    <div className="flex items-center">
                      <span className="mr-1"> </span>
                    </div>
                  </th>
                </tr>
              </thead>

              <tbody>
                {!isauditsQueryLoading && workspaceAuditList && workspaceAuditList?.length > 0 ? (
                  workspaceAuditList?.map((item, index) => (
                    <tr
                      key={index}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleClickOptionButton(item.id, "view");
                      }}
                      className={`whitespace-nowrap border-b last:border-0 border-b-global-divider-soft  ${index % 2 === 0 ? "bg-global-white" : "bg-white"} rounded-lg text-global-paragraph text-sm font-normal cursor-pointer`}
                    >
                      <td className="pl-6 pr-4 py-[15.5px]  ">
                        <div className="flex items-center gap-3">
                          <input
                            onChange={() => onClickCheckbox(item)}
                            onClick={(e) => e.stopPropagation()}
                            checked={selectedOrders.some((list) => list.id === item?.id)}
                            type="checkbox"
                            className="rounded-md border cursor-pointer w-5 h-5  "
                          />
                          <div className="flex justify-start items-center gap-3">
                            <div className="flex justify-start items-center text-table-text-data-dark font-medium text-sm gap-3">
                              <PDFIcon fillColor={"var(--color-global-primary)"} />
                            </div>

                            <div className="flex flex-col">
                              <p className="text-sm leading-5 font-medium text-global-title">
                                Factory Audit
                              </p>
                              <p className="text-sm text-global-paragraph leading-5">
                                {item?.partner_workspace?.name ? item?.partner_workspace?.name : null}
                              </p>
                            </div>
                          </div>
                        </div>
                      </td>

                      <td className=" px-4 py-[15.5px]  ">
                        {item.issue_date && item.expired_at ? (
                          <Button
                            variant={"status"}
                            className={
                              " flex justify-center items-center gap-1 px-1.5 py-[2px]  h-[22px] text-global-gray-brand font-medium text-xs leading-[18px]  border border-border-divider-stroke custom-card cursor-default "
                            }
                          >
                            <div
                              className={`w-1.5 h-1.5 rounded-full ${item.issue_date && item.expired_at && getAuditProgress(item.issue_date, item.expired_at)?.progressThemeColor}`}
                            />
                            {item &&
                              getAuditProgress(item.issue_date, item.expired_at).remainingDays}{" "}
                            days
                          </Button>
                        ) : (
                          null
                        )}
                      </td>
                      <td className="relative px-4 py-[15.5px] text-sm text-global-paragraph leading-5  ">
                        {item?.audit_id ? truncateString(item?.audit_id) : null}
                      </td>
                      <td className="relative px-4 py-[15.5px] ">
                        {item?.partner_workspace ? (
                          <p>{item?.partner_workspace?.name}</p>
                        ) : (
                          null
                        )}
                      </td>
                      <td className="relative px-4 py-[15.5px] text-sm text-global-paragraph leading-5  ">
                        {item.result_type ? (
                          <Button
                            variant={"status"}
                            className={
                              "flex justify-center items-center gap-1 px-1.5 py-[2px] h-[22px] text-global-gray-brand font-medium text-xs leading-[18px] border border-border-divider-stroke custom-card cursor-default rounded-[6px]"
                            }
                          >
                            <div>
                              {item.result_type_id === 1 ? (
                                <TickIcon
                                  width={12}
                                  height={12}
                                  fillColor={"var(--color-global-primary)"}
                                  strokeColor={"var(--color-global-primary)"}
                                />
                              ) : item.result_type_id === 2 ? (
                                <InfoNewIcon
                                  width={12}
                                  height={12}
                                  fillColor="var(--color-global-danger)"
                                  strokeColor="var(--color-global-danger)"
                                />
                              ) : item.result_type_id === 3 ? (
                                <Action
                                  width={12}
                                  height={12}
                                  fillColor="var(--color-global-warning)"
                                  strokeColor="var(--color-global-warning)"
                                />
                              ) : (
                                ""
                              )}
                            </div>
                            {item && item?.result_type?.name}
                          </Button>
                        ) : (
                          null
                        )}
                      </td>

                      <td className="relative px-4 py-[15.5px] text-sm text-global-paragraph leading-5  ">
                        {item?.issue_date
                          ? formatDateToDayMonthYear(item?.issue_date)
                          : null}
                      </td>
                      <td className="relative px-4 py-[15.5px] text-sm text-global-paragraph leading-5  ">
                        {item?.expired_at
                          ? formatDateToDayMonthYear(item?.expired_at)
                          : null}
                      </td>

                      <td className="relative px-4 py-[15.5px] text-sm text-global-paragraph leading-5  ">
                        {item?.partner_workspace
                          ? truncateString(`${item?.partner_workspace?.street}`)
                          : null}
                      </td>
                      <td className="relative px-4 py-[15.5px] text-sm text-global-paragraph leading-5  ">
                        {item?.partner_workspace
                          ? truncateString(`${item?.partner_workspace?.region}`)
                          : null}
                      </td>
                      <td className="relative px-4 py-[15.5px] text-sm text-global-paragraph leading-5  ">
                        {item?.partner_workspace
                          ? truncateString(`${item?.partner_workspace?.country}`)
                          : null}
                      </td>
                      <td className="relative px-4 py-[15.5px] text-sm text-global-paragraph leading-5  ">
                        <div className="flex justify-start items-center gap-3">
                          {
                            item.created_by_user &&
                            <div className="flex bg-global-surface uppercase justify-center items-center text-xs md:text-base font-semibold text-global-paragraph leading-6 w-8 h-8 md:h-10 md:w-10  ring-1 ring-global-divider-medium ring-opacity-75 ring-inset rounded-full border-none">
                              {item?.created_by_user?.image ? (
                                <img
                                  src={item?.created_by_user?.image}
                                  alt=""
                                  className="w-full h-full object-center rounded-full"
                                />
                              ) : (
                                item?.created_by_user?.name && getInitials(item?.created_by_user?.name)
                              )}
                            </div>
                          }

                          <div className="flex flex-col">
                            <p className="text-sm leading-5 font-medium text-global-title capitalize">
                              {item?.created_by_user?.name}
                            </p>
                            <p className="text-sm text-global-gray-brand leading-5">
                              {item?.created_by_user?.email}
                            </p>
                          </div>
                        </div>
                      </td>

                      <td
                        onClick={(e) => e.stopPropagation()}
                        className="relative cursor-pointer px-4 py-[15.5px] text-sm text-global-paragraph leading-5  "
                      >
                        <IconButton
                          aria-label="more"
                          id={`${index}`}
                          aria-controls={open ? "long-menu" : undefined}
                          aria-expanded={open ? "true" : undefined}
                          aria-haspopup="true"
                          onClick={(e) => {
                            handleActionClick(item.id);
                            setAnchorEl(e.currentTarget);
                            e.stopPropagation();
                          }}
                        >
                          <MoreOptions width={20} height={20} />
                        </IconButton>
                        {!isworkspaceAuditCreateModalOpen &&
                          actionableWrokspaceAuditId &&
                          actionableWrokspaceAuditId === item?.id && (
                            <Menu
                              id="long-menu"
                              MenuListProps={{
                                "aria-labelledby": "long-button",
                              }}
                              anchorEl={anchorEl}
                              open={open}
                              onClose={handleClose}
                              anchorOrigin={{
                                vertical: "top",
                                horizontal: "left",
                              }}
                              elevation={0}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                              }}
                              PaperProps={{
                                style: {
                                  maxHeight: 45 * 4.5,
                                  borderRadius: "8px",
                                  border: "1px solid var(--color-border-secondary)",
                                  padding: 0,
                                  backgroundColor: "transparent",
                                  boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.05)",

                                  justifyContent: "center",
                                  alignItems: "center",
                                  alignContent: "center",
                                },
                              }}
                            >
                              <MoreActionOptionUpdated
                                viewButton={
                                  <ViewButton
                                    title="View"
                                    isNotPartner={true}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleClickOptionButton(item.id, "view");
                                    }}
                                    shortcut="⌘+Shift+V"
                                    className={"w-[236px]"}
                                  />
                                }
                                editButton={
                                  hasPermission("uploadCertificate") &&
                                  <EditButton
                                    title="Edit"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleClickOptionButton(item.id, "edit");
                                    }}
                                    shortcut="⌘+E"
                                    className={"w-[236px]"}
                                  />
                                }
                                deleteButton={
                                  hasPermission("uploadCertificate") &&
                                  <DeleteButton
                                    title="Remove"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      openSingleModal(e, item);
                                    }}
                                    shortcut="⌘+Delete"
                                    className={"w-[236px]"}
                                  />
                                }
                              />
                            </Menu>
                          )}
                      </td>
                    </tr>
                  ))
                ) : isauditsQueryLoading ? (
                  <tr>
                    <td colSpan={11} className="text-center pb-3">
                      <LoadingComponent />
                    </td>
                  </tr>
                ) : enteredSearchKey && workspaceAuditList && workspaceAuditList?.length === 0 ? (
                  <EmptyTable colSpan={11} text="Result not found" />
                ) : (
                  <EmptyTable colSpan={11} />
                )}
              </tbody>
            </table>
          </div>
          {workspaceAllAuditData &&
            workspaceAllAuditData.data &&
            workspaceAllAuditData.total > 0 && (
              <TertiaryPagination
                page={page}
                totalPages={Math.ceil(workspaceAllAuditData.total / size)}
                setPage={setPage}
                size={size}
                handleSizeChange={handleSizeChange}
              />
            )}
        </div>
      </div>
      <Modal open={isworkspaceAuditCreateModalOpen} close={closeModal}>
        <CreateAudit supplierId={supplierId} workspaceRefetch={refetchDatas} />
      </Modal>
      <Modal open={deleteWorkspaceModal} close={closeModal}>
        <CommonDiscardButtonUpdated
          closeModal={closeModal}
          title={selectedOrders.length > 1 ? "Remove audits" : "Remove audit"}
          subText1={`Are you sure you want to remove selected ${selectedOrders.length > 1 ? "audits" : "audit"}? This action cannot be undone.`}
          className="w-full lg:max-w-[600px]"
          IconComponent={Audit_icon}
          confirmButtonText="Remove"
          confirmButtonVariant="warning"
          confirmButtonClassName={"w-full h-11"}
          onConfirmClick={() => handleDelete(selectedOrders)}
          icon={
            <TrashUpdateIcon
              width={20}
              height={20}
              strokeColor={"var(--color-icon-fill-secondary-button)"}
            />
          }
        />
      </Modal>
      <Modal open={showUpgradeModal} close={closeModal}>
        <CommonDiscardButtonUpdated
          closeModal={closeModal}
          title="Upgrade plan"
          subText1={currentWorkspace && !currentWorkspace?.is_owner && !workspacePlan ? `Your partner workspace is currently on a free plan.` : `You are currently on a free plan. Upgrade to a paid plan to start creating your own ${user && user?.workspace_type} workspaces.`}
          className="w-full lg:max-w-[544px]"
          confirmButtonClassName={"w-full h-11"}
          IconComponent={PlanIcon}
          confirmButtonText={currentWorkspace && !currentWorkspace?.is_owner && !workspacePlan ? null : "Ugrade Plan"}
          confirmButtonVariant="primary"
          onConfirmClick={() => {
            navigate(`/workspace/${currentWorkspace?.user_workspace_id}/setting?selectedTab=plan`);
            if (showUpgradeModal) {
              setShowUpgradeModal(false);
            }
          }}
          icon={
            <Shock width={20} height={20} strokeColor={"var(--color-icon-fill-secondary-button)"} />
          }
        />
      </Modal>
    </>
  );
};

export default AuditsMain;
