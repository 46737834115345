import { IconButton, Menu } from "@mui/material";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  Action,
  DottedIcon,
  InvitePartnerIcon,
  LogoutIcon,
  MoreOptions,
  PlanIcon,
  ReturnIcon,
  Shock,
  TrashUpdateIcon,
  WithdrawProfile,
} from "assests";
import EmptyTable from "components/Card/EmptyTable";
import CancelButton from "components/MoreAction/CancelButton";
import DeleteButton from "components/MoreAction/DeleteButton";
import MoreActionOptionUpdated from "components/MoreAction/MoreActionOptionUpdated";
import ResendButton from "components/MoreAction/ResendButton";
import ViewButton from "components/MoreAction/ViewButton";
import SearchCreatePanelUpdate from "components/SearchCreatePanelUpdate";
import BreadCrumb from "components/TopHeader/BreadCrumb";
import TopNavbar from "components/TopHeader/TopNavbar";
import { Button } from "components/UI/Button";
import CommonDiscardButton from "components/UI/CommonDiscardButton";
import CommonDiscardButtonUpdated from "components/UI/CommonDiscardButtonUpdated";
import LimitReached from "components/UI/LimitReached";
import { LineProgressBar } from "components/UI/LineProgressBar";
import LoadingComponent from "components/UI/LoadingComponent";
import Modal from "components/UI/Modal";
import SortIcons from "components/UI/SortIcons";
import TertiaryPagination from "components/UI/TertiaryPagination";
import useCustomToaster from "hooks/useCustomToaster";
import usePermission from "hooks/usePermission";
import SubNavbar from "layout/SubNavbar";
import queryString from "query-string";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { BsCircleFill } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";
import {
  bulkDeleteInvitations,
  resendInvitation,
  updateInvitationStatus,
} from "services/invitation.services";
import { downloadRetailerAsCSV, getRetailers } from "services/retailer.services";
import { setRefetchBillingData } from "store/billingSlice";
import {
  setAllRetailerData,
  setRetailerList,
  toggleActionableRetailerId,
  toggleActionableRetailerItem,
  toggleActionableRetailerWorkspaceId,
  toggleIsRetailerCreateModalOpen,
  toggleReachedModal,
  toggleRetailerBulkDeleteModal,
  toggleRetailerCancleModal,
  toggleRetailerDeleteModal,
  toggleRetailerResendModal,
} from "store/retailerSlice";
import { getInitials, transpercencyPercentageColors, truncateString } from "utils/helper";
import AddRetailer from "./AddRetailer";
import RetailerFilters from "./RetailerFilters";
import { leaveWorkspace } from "services/workspace.services";
import { setActionableCompanyType } from "store/accountDashboardSlice";

const Retailer = () => {
  const { hasPermission, checkPlanLimitation } = usePermission();
  const { refetchBillingData } = useSelector((state) => state.billingSlice);
  const { showCustomToast } = useCustomToaster();
  const {
    isRetailerCreateModalOpen,
    actionableRetailerId,
    actionableRetailerItem,
    deleteModal,
    cancleModal,
    actionableRetailerWorkspaceId,
    bulkDeleteModal,
    resendModal,
    reachedModal,
  } = useSelector((state) => state.retailerSlice);
  const { workspaceId } = useParams();
  const { user, currentWorkspace, subscription, subscription_status } = useSelector(
    (state) => state.authenticationSlice,
  );
  const { actionableCompanyType } = useSelector(
    (state) => state.accountDashboardSlice,
  );
  const navigate = useNavigate();
  const location = useLocation();
  const [partnerName, setPartnerName] = useState("");
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [searchKey, setSearchKey] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [anchorEl, setAnchorEl] = useState(null);
  const parsed = queryString.parse(location.search);
  const open = Boolean(anchorEl);
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const statusList = [
    {
      label: "View All",
      value: "",
    },
    {
      label: "Active",
      value: "accepted",
    },
    {
      label: "Invite Pending",
      value: "pending",
    },
  ];
  const getTitle = () => {
    if (selectedStatus === "accepted") {
      return "Active Clients";
    } else if (selectedStatus === "pending") {
      return "Pending Clients";
    } else {
      return "Clients";
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
    if (actionableRetailerId) {
      dispatch(toggleActionableRetailerId());
    }
    if (actionableRetailerItem) {
      dispatch(toggleActionableRetailerItem());
    }
  };
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { workspacePlan } = useSelector((state) => state.billingSlice);
  const { retailer, retailerList, allRetailerData } = useSelector((state) => state.retailerSlice);
  const [selectedStatus, setSelectedStatus] = useState(statusList[0].value);
  const [retailerOptions, setRetailerOptions] = useState([]);
  const [activeFilters, setActiveFilters] = useState({});
  const [filteredData, setFilteredData] = useState({});
  const [status, setStatus] = useState(null);
  const {
    data: retailersData,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: [
      "retailers",
      "getRetailers",
      page,
      size,
      searchKey,
      orderBy,
      workspaceId,
      selectedStatus,
      filteredData,
    ],
    queryFn: () =>
      getRetailers({
        page,
        per_page: size,
        search: searchKey,
        sort: orderBy,
        filter_by_status: selectedStatus ? [selectedStatus] : "",
        workspace_id: parseInt(workspaceId),
        ...filteredData,
      }),
    enabled: !!workspaceId || !!page || !!size || !!searchKey || !!selectedStatus || !orderBy,
  });

  const {
    data: articlesNopaginatedData,
    isLoading: isArticlesNopaginatedData,
    refetch: refetchNopaginatedData,
  } = useQuery({
    queryKey: ["retailernopaginate", "getData", workspaceId],
    queryFn: () =>
      getRetailers({
        workspace_id: parseInt(workspaceId),
        pagination: "no",
      }),
    enabled: !!workspaceId,
  });

  useEffect(() => {
    if (!isArticlesNopaginatedData && articlesNopaginatedData) {
      if (articlesNopaginatedData?.data?.data) {
        setRetailerOptions(articlesNopaginatedData?.data?.data);
      }
    }
  }, [articlesNopaginatedData, isArticlesNopaginatedData]);

  const handleSizeChange = (newSize) => {
    setSize(newSize);
    setPage(1);
  };

  const handleViewDetailsClick = (id, status) => {
    if (status === "accepted" || status === "removed") {
      navigate(`/workspace/${workspaceId}/retailer-details/${id}?status=${status}`, {
        replace: true,
      });
    }
  };
  const handleActionClick = (item) => {
    const retailerId = item?.invitation_id;
    const retailerWorkspaceId = parseInt(item?.workspace_id);
    const itemStatus = item.status;
    if (retailerId) {
      dispatch(toggleActionableRetailerId(retailerId));
    } else {
      dispatch(toggleActionableRetailerId(retailerWorkspaceId));
    }
    dispatch(toggleActionableRetailerWorkspaceId(retailerWorkspaceId));
    dispatch(toggleActionableRetailerItem(item));
    setStatus(itemStatus);
  };


  useEffect(() => {
    if (!isLoading && retailersData) {
      dispatch(setRetailerList({ retailerList: retailersData?.data?.data?.data, append: false }));
      dispatch(setAllRetailerData({ allRetailerData: retailersData?.data?.data, append: false }));
    }
  }, [retailersData, isLoading]);

  const cancelInvitation = useMutation({
    mutationKey: ["inviation", "cancel-invitation"],
    mutationFn: (data) => updateInvitationStatus(data),
    onMutate: () => {
      showCustomToast(
        {
          title: "Canceling invitation....",
        },
        "cancelInvitation",
      );
    },
    onSuccess: (res) => {
      dispatch(toggleRetailerCancleModal());
      toast.dismiss("cancelInvitation");
      showCustomToast({
        title: res?.data?.message || "Invitation cancelled successfully",
      });
      closeModal();
      refetchDatas();
      if (!refetchBillingData) {
        dispatch(setRefetchBillingData(true));
      }
    },
    onError: (error) => {
      toast.dismiss("cancelInvitation");
      showCustomToast({
        title: error.message || "Something went wrong",
      });
    },
  });

  const resendRetailerInvitation = useMutation({
    mutationKey: ["inviationResend", "resend-invitation"],
    mutationFn: (data) => resendInvitation(data),
    onMutate: () => {
      showCustomToast(
        {
          title: "Resending invitation....",
        },
        "resendInvitation",
      );
    },
    onSuccess: (res) => {
      toast.dismiss("resendInvitation");
      showCustomToast({
        title: "Successfully sent!",
        subtitle: "Invitation resent successfully",
      });
      closeModal();
      refetch();
      if (!refetchBillingData) {
        dispatch(setRefetchBillingData(true));
      }
    },
    onError: (error) => {
      toast.dismiss("resendInvitation");
      toast.error(error.message || "Something went wrong");
      showCustomToast({
        title: error.message || "Something went wrong",
      });
    },
  });

  const confirmCancelInvitation = () => {
    dispatch(toggleRetailerCancleModal());
    const mutationData = {
      id: actionableRetailerId,
      body: { status: "cancelled" },
    };
    cancelInvitation.mutate(mutationData, {
      onSuccess: () => {
        refetchDatas();
        if (!refetchBillingData) {
          dispatch(setRefetchBillingData(true));
        }
        dispatch(toggleRetailerCancleModal());
      },
    });
  };

  const handleOnCancle = () => {
    if (!actionableRetailerId && !retailer) return;
    confirmCancelInvitation();
  };

  const openCreateModal = () => {
    dispatch(toggleIsRetailerCreateModalOpen());
    if (actionableRetailerId) {
      dispatch(toggleActionableRetailerId());
    }
    if (actionableRetailerItem) {
      dispatch(toggleActionableRetailerItem());
    }
    if (user && user.workspace_type === "supplier") {
      dispatch(setActionableCompanyType("retailer"));
    }
    setSelectedOrders([]);
  };

  const refetchDatas = () => {
    refetch();
    refetchNopaginatedData();
  };

  const closeModal = () => {
    setSelectedOrders([]);
    setPartnerName("");
    if (isRetailerCreateModalOpen) {
      dispatch(toggleIsRetailerCreateModalOpen());
    }
    if (actionableRetailerId) {
      dispatch(toggleActionableRetailerId());
    }
    if (deleteModal) {
      dispatch(toggleRetailerDeleteModal());
    }
    if (bulkDeleteModal) {
      dispatch(toggleRetailerBulkDeleteModal());
    }
    if (cancleModal) {
      dispatch(toggleRetailerCancleModal());
    }
    if (actionableRetailerWorkspaceId) {
      dispatch(toggleActionableRetailerWorkspaceId());
    }
    if (actionableRetailerItem) {
      dispatch(toggleActionableRetailerItem());
    }
    if (resendModal) {
      dispatch(toggleRetailerResendModal());
    }
    if (reachedModal) {
      dispatch(toggleReachedModal());
    }
    if (showUpgradeModal) {
      setShowUpgradeModal(false);
    }
    if (actionableCompanyType) {
      dispatch(setActionableCompanyType());
    }
  };

  const handleClickOptionButton = (retailerId, retailerWorkspaceId, actionType) => {
    switch (actionType) {
      case "cancel":
        dispatch(toggleActionableRetailerId(retailerId));
        dispatch(toggleActionableRetailerWorkspaceId(retailerWorkspaceId));
        dispatch(toggleRetailerCancleModal());
        setAnchorEl(null);
        setSelectedOrders([]);
        break;
      case "delete":
        dispatch(toggleActionableRetailerId(retailerId));
        dispatch(toggleActionableRetailerWorkspaceId(retailerWorkspaceId));
        dispatch(toggleRetailerDeleteModal());
        setSelectedOrders([]);
        setAnchorEl(null);
        break;
      case "resend":
        dispatch(toggleActionableRetailerId(retailerId));
        dispatch(toggleActionableRetailerWorkspaceId(retailerWorkspaceId));
        resendRetailerInvitation.mutate(retailerId);
        setAnchorEl(null);
        setSelectedOrders([]);
        break;
      default:
        break;
    }
  };

  const handleTableRowClick = (status, orderId) => {
    if (status === "accepted") {
      return handleViewDetailsClick(orderId, status);
    } else {
      return;
    }
  };

  const handleSearch = (e) => {
    const searchText = e.target.value?.trim();
    if (searchText.length >= 2) {
      setSearchKey(searchText);
    } else {
      setSearchKey("");
    }
  };

  const downloadRetailersAsCSV = useMutation({
    mutationKey: ["retailers", "download"],
    mutationFn: (params) => downloadRetailerAsCSV(params),
    onMutate: () => {
      showCustomToast(
        {
          title: "Downloading...",
        },
        "downloadRetaiers",
      );
    },
    onSuccess: (res) => {
      toast.dismiss("downloadRetaiers");
      showCustomToast({
        title: "Downloaded successfully",
      });
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "clients.csv");
      document.body.appendChild(link);
      link.click();
      link.remove();
      queryClient.invalidateQueries(["retailers", "download"]);
    },
    onError: (error) => {
      toast.dismiss("downloadRetaiers");
      showCustomToast({
        title: error.response.data.message || error.message || "Something went wrong!",
      });
    },
  });

  const onClickCheckbox = (retailer) => {
    if (retailer && retailer.invitation_id) {
      const findedItem = selectedOrders.some((item) => item.invitation_id === retailer.invitation_id);
      if (!findedItem) {
        setSelectedOrders((prevSelectedOrders) => [...prevSelectedOrders, retailer]);
      } else {
        setSelectedOrders((prevSelectedOrders) =>
          prevSelectedOrders.filter((item) => item.invitation_id !== retailer.invitation_id),
        );
      }
    }
  };

  const handleSelectAll = (e) => {
    if (!e.target.checked || retailerList.length === 0) {
      setSelectedOrders([]);
      return;
    }
    const invitationIds = retailerList.length > 0 && retailerList.filter((item) => item.invitation_id).map((inviation) => inviation);
    setSelectedOrders(invitationIds);
  };

  const handleStatusChange = (statusValue) => {
    setSelectedStatus(statusValue);
    setSelectedOrders([]); // Clear selected orders when changing status
  };

  const deleteAllMutation = useMutation({
    mutationKey: ["retailers", "delete"],
    mutationFn: (data) => bulkDeleteInvitations(data),
    onMutate: () => {
      showCustomToast(
        {
          title: "Deleting...",
        },
        "deleteRetailer",
      );
    },
    onSuccess: (response) => {
      toast.dismiss("deleteRetailer");
      if (response.data?.failed?.length === 0) {
        showCustomToast({
          title: "Clients deleted successfully",
        });
      } else {
        showCustomToast({
          title: "Clients can not be deleted",
        });
      }
      closeModal();
      if (!refetchBillingData) {
        dispatch(setRefetchBillingData(true));
      }
      refetchDatas();
      setSelectedOrders([]);
      queryClient.invalidateQueries(["retailers", "get", "delete"]);
    },
    onError: (error) => {
      toast.dismiss("deleteRetailer");
      showCustomToast({
        title: error.response?.data?.message || error.message,
      });
    },
    onSettled: () => {
      toast.dismiss("deleteRetailer");
    },
  });

  const handleDownloadCSV = () => {
    downloadRetailersAsCSV.mutate({ workspace_id: parseInt(workspaceId) });
  };

  const handleLeaveWorkspace = useMutation({
    mutationKey: ["leaveWorkspace", "delete"],
    mutationFn: (data) => leaveWorkspace(data),
    onMutate: () => {
      showCustomToast(
        {
          title: "Leaving...",
        },
        "leaveWorkspace",
      );
    },
    onSuccess: (res) => {
      toast.dismiss("leaveWorkspace");
      showCustomToast({
        title: res.data.message || "Left workspace successfully",
      });
      refetchDatas();
      closeModal();
      if (!refetchBillingData) {
        dispatch(setRefetchBillingData(true));
      }
      queryClient.invalidateQueries(["leaveWorkspace", "get", "delete"]);
    },
    onError: (error) => {
      toast.dismiss("leaveWorkspace");
      showCustomToast({
        title: error.response.data.message || error.message,
      });
    },
    onSettled: () => {
      toast.dismiss("leaveWorkspace");
    },
  });
  const handleOnDelete = () => {
    if (!actionableRetailerId) return;
    if (actionableRetailerId && actionableRetailerItem && actionableRetailerItem.invitation_id) {
      deleteAllMutation.mutate({ invitation_id: [actionableRetailerId] });
    } else {
      handleLeaveWorkspace.mutate({
        workspace_id: parseInt(actionableRetailerWorkspaceId),
      });
    }
  };

  const handleDeleteAll = () => {
    const allIds = selectedOrders.map((item) => item.invitation_id);
    if (allIds) {
      deleteAllMutation.mutate({ invitation_id: allIds });
    }
  };

  const openBulkDeleteModal = (e) => {
    e.stopPropagation();
    setAnchorEl(null);
    dispatch(toggleRetailerBulkDeleteModal());
  };

  const handleCreateAction = () => {
    if (
      (currentWorkspace &&
        currentWorkspace.is_owner &&
        (!subscription ||
          (subscription && (!subscription_status || subscription_status === "canceled")))) ||
      !workspacePlan
    ) {
      setShowUpgradeModal(true);
    } else if (!checkPlanLimitation.canCreatePartner) {
      dispatch(toggleReachedModal());
    } else {
      openCreateModal();
      setShowUpgradeModal(false);
    }
  };

  useEffect(() => {
    if (user && currentWorkspace) {
      const handleKeyDown = (e) => {
        const isMac = e.metaKey; // `metaKey` for Cmd on Mac
        const isWindows = e.ctrlKey; // `ctrlKey` for Ctrl on Windows
        if ((isMac || isWindows) && (e.key === "P" || e.key === "p")) {
          e.preventDefault();
          if ((status === "accepted" || status === "removed") && actionableRetailerWorkspaceId) {
            setAnchorEl(null);
            handleViewDetailsClick(actionableRetailerWorkspaceId, status);
          }
        }
        // Delete Shortcut: Cmd+D (Mac) or Ctrl+D (Windows)
        if (e.key === "Delete" && hasPermission("deleteRetailer")) {
          e.preventDefault();
          setAnchorEl(null);
          if (actionableRetailerId && status) {
            if (status === "accepted") {
              handleClickOptionButton(
                actionableRetailerId,
                actionableRetailerWorkspaceId,
                "delete",
              );
            } else if (status === "pending") {
              handleClickOptionButton(
                actionableRetailerId,
                actionableRetailerWorkspaceId,
                "cancel",
              );
            }
          } else if (selectedOrders.length > 0) {
            dispatch(toggleRetailerBulkDeleteModal());
          }
        }
        if (
          (e.ctrlKey || e.metaKey) &&
          (e.key === "B" || e.key === "b") &&
          hasPermission("canAddRetailer")
        ) {
          e.preventDefault();
          setAnchorEl(null);
          handleCreateAction();
        }

        if (
          (isMac || isWindows) &&
          e.shiftKey &&
          (e.key === "I" || e.key === "i") &&
          hasPermission("deleteRetailer")
        ) {
          e.preventDefault();
          if (actionableRetailerId && status === "pending") {
            setAnchorEl(null);
            handleClickOptionButton(actionableRetailerId, actionableRetailerWorkspaceId, "resend");
          }
        }
        if (
          (e.ctrlKey || e.metaKey) &&
          (e.key === "A" || e.key === "a") &&
          hasPermission("deleteRetailer")
        ) {
          e.preventDefault();
          setAnchorEl(null);
          if (actionableRetailerId) {
            dispatch(toggleActionableRetailerId());
          }
          if (actionableRetailerItem) {
            dispatch(toggleActionableRetailerItem());
          }
          e.preventDefault();
          const eligibleTemplates = selectedOrders;
          const allChecked =
            eligibleTemplates?.length > 0 && selectedOrders.length === eligibleTemplates.length;
          const newSelectAllState = !allChecked;
          const checkboxEvent = { target: { checked: newSelectAllState } };
          handleSelectAll(checkboxEvent);
        }
        if (
          (isMac || isWindows) &&
          e.shiftKey &&
          (e.key === "C" || e.key === "c") &&
          hasPermission("deleteRetailer")
        ) {
          e.preventDefault();
          if (actionableRetailerId && status === "pending") {
            setAnchorEl(null);
            handleClickOptionButton(actionableRetailerId, actionableRetailerWorkspaceId, "cancel");
          }
        }
      };
      window.addEventListener("keydown", handleKeyDown);
      return () => {
        window.removeEventListener("keydown", handleKeyDown);
      };
    }
  }, [
    user,
    currentWorkspace,
    dispatch,
    actionableRetailerId,
    actionableRetailerWorkspaceId,
    handleClickOptionButton,
    handleViewDetailsClick,
  ]);

  return (
    <>
      <TopNavbar isFromDashboard={false}>
        <BreadCrumb
          textAnother="My Clients"
          text={
            selectedStatus === "accepted"
              ? "Active Clients"
              : selectedStatus === "pending"
                ? "Pending Clients"
                : "All"
          }
        />
      </TopNavbar>

      <div className="px-4 md:px-8  pt-[64px] md:pt-[108px]">
        <div className="block md:hidden pt-4">
          <BreadCrumb
            textAnother="My Clients"
            text={
              selectedStatus === "accepted"
                ? "Active Clients"
                : selectedStatus === "pending"
                  ? "Pending Clients"
                  : "All"
            }
          />
        </div>
        <div className="pt-4 md:pt-6">
          <SubNavbar title="My Clients" subtitle="Invite and manage your clients here." />
        </div>
        <div className="bg-global-divider-medium h-[1px] my-5  hidden md:block" />
        <div className="mb-5 flex items-center justify-between bg-transparent border border-border-divider-stroke  rounded-md font-semibold w-full md:max-w-[296px] h-9 whitespace-nowrap mt-4 lg:mt-0">
          {statusList.map((status) => (
            <button
              key={status.value}
              className={`w-full flex justify-center items-center text-center text-sm px-2 lg:px-4 h-9 rounded-md ${selectedStatus === status.value
                ? "bg-white border border-border-primary text-gray-900 custom-card"
                : "bg-transparent border-none text-gray-700"
                }`}
              onClick={() => handleStatusChange(status.value)}
            >
              {status.label}
            </button>
          ))}
        </div>

        <div className="bg-btn-surface-secondary border border-border-divider-stroke   rounded-xl custom-card w-full  mb-6">
          <SearchCreatePanelUpdate
            createTxt="Invite Client"
            title={getTitle()}
            handleDownloadCSV={handleDownloadCSV}
            selectedItems={selectedOrders}
            openDeleteModal={openBulkDeleteModal}
            btnText={allRetailerData && allRetailerData.total}
            onBtnClick={handleCreateAction}
            onChange={handleSearch}
            permissionText="canAddRetailer"
            permissionText2="deleteRetailer"
            classWidth="w-full md:w-[133px] whitespace-nowrap"
          >
            <RetailerFilters
              {...{
                activeFilters,
                setActiveFilters,
                setFilteredData,
                retailerOptions,
              }}
            />
          </SearchCreatePanelUpdate>

          <div className="w-full max-w-screen overflow-x-auto ">
            <table className="w-full border-spacing-0 table-auto rounded-table-custom overflow-x-auto">
              <thead className="text-sm text-left whitespace-nowrap text-global-paragraph">
                <tr className="border-b  border-t border-b-global-divider-soft border-t-global-divider-soft">
                  <th className="pl-6 pr-4 py-[11.5px]">
                    <div className="flex items-center gap-3">
                      <input
                        type="checkbox"
                        className={`rounded-md  w-5 h-5 ${retailerList && retailerList.length > 0 ? "cursor-pointer" : "cursor-default"}`}
                        onChange={handleSelectAll}
                        checked={
                          retailerList && retailerList?.filter((item) => item.invitation_id).length > 0 &&
                          selectedOrders.length === retailerList?.filter((item) => item.invitation_id).length
                        }
                      />
                      <div className="flex items-center">
                        <span className="mr-1 text-xs font-medium text-global-paragraph leading-[18px]">
                          Partner Name
                        </span>
                        <SortIcons
                          hasData={retailerList && retailerList.length > 0}
                          sortName="name"
                          {...{ orderBy, setOrderBy }}
                        />
                      </div>
                    </div>
                  </th>

                  <th className="relative px-4 py-[11.5px]  ">
                    <div className="flex items-center">
                      <span className="mr-1 text-xs font-medium text-global-paragraph leading-[18px]">
                        Status
                      </span>
                      <SortIcons
                        hasData={retailerList && retailerList.length > 0}
                        sortName="status"
                        {...{ orderBy, setOrderBy }}
                      />
                    </div>
                  </th>

                  <th className="relative px-4 py-[11.5px]  ">
                    <div className="flex items-center">
                      <span className="mr-1 text-xs font-medium text-global-paragraph leading-[18px]">
                        Active Orders
                      </span>
                      <SortIcons
                        hasData={retailerList && retailerList.length > 0}
                        sortName="orders_count"
                        {...{ orderBy, setOrderBy }}
                      />
                    </div>
                  </th>
                  <th className="relative px-4 py-[11.5px]  ">
                    <div className="flex items-center">
                      <span className="mr-1 text-xs font-medium text-global-paragraph leading-[18px]">
                        Transparency %
                      </span>
                      <SortIcons
                        hasData={retailerList && retailerList.length > 0}
                        sortName="transparency_score"
                        {...{ orderBy, setOrderBy }}
                      />
                    </div>
                  </th>
                  <th className="relative px-4 py-[11.5px]">
                    <div className="flex items-center">
                      <span className="mr-1 text-xs font-medium text-global-paragraph leading-[18px]">
                        Verification %
                      </span>
                      <SortIcons
                        hasData={retailerList && retailerList.length > 0}
                        sortName="verification_score"
                        {...{ orderBy, setOrderBy }}
                      />
                    </div>
                  </th>
                  <th className="relative px-4 py-[11.5px]">
                    <div className="flex items-center">
                      <span className="mr-1 text-xs font-medium text-global-paragraph leading-[18px]">
                        Type
                      </span>
                      <SortIcons
                        hasData={retailerList && retailerList.length > 0}
                        sortName="company_type"
                        {...{ orderBy, setOrderBy }}
                      />
                    </div>
                  </th>

                  <th className="relative px-4  py-[11.5px]  ">
                    <div className="flex items-center">
                      <span className="mr-1 text-xs font-medium text-global-paragraph leading-[18px]">
                        Street
                      </span>
                      <SortIcons
                        hasData={retailerList && retailerList.length > 0}
                        sortName="street"
                        {...{ orderBy, setOrderBy }}
                      />
                    </div>
                  </th>
                  <th className="relative px-4 py-[11.5px]">
                    <div className="flex items-center">
                      <span className="mr-1 text-xs font-medium text-global-paragraph leading-[18px]">
                        City
                      </span>
                      <SortIcons
                        hasData={retailerList && retailerList.length > 0}
                        sortName="region"
                        {...{ orderBy, setOrderBy }}
                      />
                    </div>
                  </th>
                  <th className="relative px-4 py-[11.5px]">
                    <div className="flex items-center">
                      <span className="mr-1 text-xs font-medium text-global-paragraph leading-[18px]">
                        Country
                      </span>
                      <SortIcons
                        hasData={retailerList && retailerList.length > 0}
                        sortName="country"
                        {...{ orderBy, setOrderBy }}
                      />
                    </div>
                  </th>

                  <th className="relative px-4 py-[11.5px]  ">
                    <div className="flex items-center">
                      <span className="mr-1"> </span>
                    </div>
                  </th>
                </tr>
              </thead>

              <tbody>
                {!isLoading && retailerList && retailerList?.length > 0 ? (
                  retailerList?.map((item, index) => (
                    <tr
                      key={index}
                      onClick={() => {
                        handleActionClick(item);
                        handleTableRowClick(item?.status, parseInt(item?.workspace_id));
                      }}
                      className={`whitespace-nowrap border-b last:border-0 border-b-global-divider-soft  ${index % 2 === 0 ? "bg-global-white" : "bg-white"} rounded-lg text-global-paragraph text-sm font-normal  ${item.status === "accepted" ? "cursor-pointer" : "cursor-default"}`}
                    >
                      <td className="pl-6 pr-4 py-[15.5px]  ">
                        <div className="flex items-center gap-3">
                          <input
                            onClick={(e) => e.stopPropagation()}
                            onChange={() =>
                              onClickCheckbox(item)
                            }
                            checked={selectedOrders.some(
                              (order) => order.invitation_id && (order.invitation_id === item.invitation_id),
                            )}

                            type="checkbox"
                            className="rounded-md border cursor-pointer w-5 h-5  "
                          />
                          <div className="flex justify-start items-center gap-3">
                            {item && item?.name && (
                              <div className="flex bg-global-surface uppercase justify-center items-center text-xs md:text-base font-semibold text-global-paragraph leading-6 w-8 h-8 md:h-10 md:w-10 p-2 ring-1 ring-global-divider-medium ring-opacity-75 ring-inset rounded-full border-none">
                                {item && item?.name ? getInitials(item?.name) : ""}
                              </div>
                            )}

                            <div className="flex flex-col">
                              <p className="text-sm leading-5 font-medium text-global-title capitalize">
                                {item?.name}
                              </p>
                              <p className="text-sm text-global-gray-brand leading-5">
                                {item?.country}
                              </p>
                            </div>
                          </div>
                        </div>
                      </td>

                      <td className=" px-4 py-[15.5px]  ">
                        <div className="flex items-center">
                          {item?.status ? (
                            <Button
                              variant={"status"}
                              className={
                                " flex justify-center items-center gap-1 px-1.5 py-[2px]  h-[22px] text-global-gray-brand font-medium text-xs leading-[18px]  border border-border-divider-stroke custom-card cursor-default "
                              }
                            >
                              <BsCircleFill
                                className={`w-1.5 h-1.5 ${item?.status === "accepted" ? "text-global-primary" : "text-global-paragraph"}`}
                              />
                              {item.status.toLowerCase() === "accepted"
                                ? "Active"
                                : item.status.toLowerCase() === "pending"
                                  ? "Invite Pending"
                                  : item.status}
                            </Button>
                          ) : null}
                        </div>
                      </td>
                      <td className="relative px-4 py-[15.5px] ">{item?.active_orders_count}</td>

                      <td className="relative px-4 py-[15.5px] text-sm text-global-paragraph leading-5  ">
                        <div className="flex items-center gap-3 ">
                          <LineProgressBar
                            className="h-2 w-full lg:w-[163px]"
                            variant={transpercencyPercentageColors(
                              item?.transparency_score ? item?.transparency_score : 0,
                            )}
                            progress={item?.transparency_score ? item?.transparency_score : 0}
                          />
                          <span>{`${item?.transparency_score ? item?.transparency_score : 0}%`}</span>
                        </div>
                      </td>

                      <td className="relative px-4 py-[15.5px] text-sm text-global-paragraph leading-5  ">
                        <div className="flex items-center gap-3">
                          <LineProgressBar
                            className="h-2 w-full lg:w-[164px]"
                            variant={"blue"}
                            progress={item?.verification_score ? item?.verification_score : 0}
                          />
                          <span>{`${item?.verification_score ? item?.verification_score : 0}%`}</span>
                        </div>
                      </td>
                      <td className="relative px-4 py-[15.5px] text-sm text-global-paragraph leading-5  ">
                        {item?.company_type ? (
                          <Button
                            variant={"status"}
                            className={`rounded-[6px] w-auto h-[22px] px-1.5 py-[2px] border border-[#D5D7DA] custom-card cursor-default capitalize   text-status-text-default  text-xs leading-[18px] font-medium  `}
                          >
                            {item?.company_type}
                          </Button>
                        ) : null}
                      </td>

                      <td className="relative px-4 py-[15.5px] text-sm text-global-paragraph leading-5  ">
                        <div className="flex items-center">
                          <span>{item?.street ? truncateString(item?.street) : null}</span>
                        </div>
                      </td>
                      <td className="relative px-4 py-[15.5px] text-sm text-global-paragraph leading-5  ">
                        <div className="flex items-center">
                          <span>{item?.region ? truncateString(item?.region) : null}</span>
                        </div>
                      </td>
                      <td className="relative px-4 py-[15.5px] text-sm text-global-paragraph leading-5  ">
                        <div className="flex items-center">
                          <span>{item?.country ? truncateString(item?.country) : null}</span>
                        </div>
                      </td>

                      <td
                        onClick={(e) => {
                          e.stopPropagation();
                          setAnchorEl(null);
                        }}
                        className="relative cursor-pointer px-4 py-[15.5px] text-sm text-global-paragraph leading-5  "
                      >
                        <IconButton
                          aria-label="more"
                          id={`${index}`}
                          aria-controls={open ? "long-menu" : undefined}
                          aria-expanded={open ? "true" : undefined}
                          aria-haspopup="true"
                          onClick={(e) => {
                            handleActionClick(item);
                            setPartnerName(item.name);
                            setAnchorEl(e.currentTarget);
                            setSelectedOrders([]);
                            e.stopPropagation();
                          }}
                        >
                          <MoreOptions width={20} height={20} />
                        </IconButton>
                        {!isRetailerCreateModalOpen &&
                          actionableRetailerId &&
                          (item.invitation_id
                            ? parseInt(actionableRetailerId) === parseInt(item?.invitation_id)
                            : parseInt(actionableRetailerId) === parseInt(item.workspace_id)) && (
                            <Menu
                              id="long-menu"
                              MenuListProps={{
                                "aria-labelledby": "long-button",
                              }}
                              anchorEl={anchorEl}
                              open={open}
                              onClose={handleClose}
                              anchorOrigin={{
                                vertical: "top",
                                horizontal: "left",
                              }}
                              elevation={0}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                              }}
                              PaperProps={{
                                style: {
                                  maxHeight: 45 * 4.5,

                                  borderRadius: "8px",
                                  border: "1px solid var(--color-border-secondary)",
                                  padding: 0,
                                  backgroundColor: "transparent",
                                  boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.05)",
                                  justifyContent: "center",
                                  alignContent: "center",
                                  alignItems: "center",
                                },
                              }}
                            >
                              <MoreActionOptionUpdated
                                viewButton={
                                  (item?.status === "accepted" || item?.status === "removed") && (
                                    <ViewButton
                                      onClick={() =>
                                        handleViewDetailsClick(
                                          parseInt(item?.workspace_id),
                                          item.status,
                                        )
                                      }
                                      className={"w-[236px]"}
                                      shortcut="⌘+P"
                                      title="View partner"
                                    />
                                  )
                                }
                                deleteButton={
                                  item?.status === "accepted" &&
                                  hasPermission("deleteRetailer") && (
                                    <DeleteButton
                                      title={item.invitation_id ? "Remove" : "Leave"}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleClickOptionButton(
                                          item.invitation_id ? item.invitation_id : item.workspace_id,
                                          parseInt(item.workspace_id),
                                          "delete"
                                        );
                                      }}
                                      className={"w-[236px]"}
                                      shortcut="⌘+Delete"
                                    />
                                  )
                                }
                                cancelButton={
                                  item?.status === "pending" &&
                                  hasPermission("deleteRetailer") && (
                                    <CancelButton
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleClickOptionButton(
                                          item.invitation_id ? item.invitation_id : item.workspace_id,
                                          parseInt(item.workspace_id),
                                          "cancel",
                                        );
                                      }}
                                      className={"w-[236px]"}
                                      shortcut="⌘+Shift+ C"
                                    />
                                  )
                                }
                                resendButton={
                                  item?.status === "pending" &&
                                  hasPermission("deleteRetailer") && (
                                    <ResendButton
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleClickOptionButton(
                                          item.invitation_id ? item.invitation_id : item.workspace_id,
                                          parseInt(item.workspace_id),
                                          "resend",
                                        );
                                      }}
                                      className={"w-[236px]"}
                                      shortcut="⌘+Shift+ I"
                                    />
                                  )
                                }
                              />
                            </Menu>
                          )}
                      </td>
                    </tr>
                  ))
                ) : isLoading ? (
                  <tr>
                    <td colSpan={11} className="text-center pb-3">
                      <LoadingComponent />
                    </td>
                  </tr>
                ) : searchKey && retailerList && retailerList?.length === 0 ? (
                  <EmptyTable colSpan={11} text="Result not found" />
                ) : (
                  <EmptyTable colSpan={11} />
                )}
              </tbody>
            </table>
          </div>
          {allRetailerData && allRetailerData.data && allRetailerData.total > 0 && (
            <TertiaryPagination
              page={page}
              totalPages={Math.ceil(allRetailerData.total / size)}
              setPage={setPage}
              size={size}
              handleSizeChange={handleSizeChange}
            />
          )}
        </div>
      </div>
      <Modal open={reachedModal} close={closeModal}>
        <LimitReached
          closeModal={closeModal}
          IconComponent={Action}
          title="Partner limit reached"
          subText1={`You cannot add a new business partner  as you have reached the maximum limit of ${workspacePlan && workspacePlan?.package?.partner_links} partners  in your ${workspacePlan && workspacePlan?.package?.name} plan. Please reduce your usage or upgrade your plan.`}
          className={"w-full  lg:w-[600px]"}
          linked={workspacePlan && workspacePlan?.partner_links_count}
          totalLink={workspacePlan && workspacePlan?.package?.partner_links}
          linkedtitle={"Business Partners"}
          confirmButtonText={currentWorkspace && !currentWorkspace?.is_owner ? null : "Ugrade Plan"}
          confirmButtonVariant={"primary"}
          confirmButtonClassName={"w-full h-11"}
          onConfirmClick={() => {
            navigate(`/workspace/${currentWorkspace?.user_workspace_id}/setting?selectedTab=plan`);
            if (reachedModal) {
              dispatch(toggleReachedModal());
            }
          }}
          icon={
            <Shock width={20} height={20} strokeColor={"var(--color-icon-fill-secondary-button)"} />
          }
        />
      </Modal>

      <Modal open={isRetailerCreateModalOpen} close={closeModal} title={""}>
        <AddRetailer closeModal={closeModal} invitationListRefetch={refetchDatas} />
      </Modal>
      <Modal open={cancleModal} close={closeModal} title={""}>
        <CommonDiscardButtonUpdated
          closeModal={closeModal}
          title="Withdraw invitation?"
          subText1="You are about to withdraw the invitation. This action will cancel the previously sent invitation and the invitee will lose access to this workspace."
          className="w-full lg:max-w-[600px]"
          IconComponent={WithdrawProfile}
          confirmButtonText="Withdraw Invite"
          confirmButtonVariant="primary"
          confirmButtonClassName={"w-full h-11"}
          onConfirmClick={handleOnCancle}
          icon={<ReturnIcon width={20} height={20} />}
        />
      </Modal>
      <Modal open={deleteModal} close={closeModal} title={""}>
        <CommonDiscardButtonUpdated
          closeModal={closeModal}
          title={actionableRetailerItem && actionableRetailerItem.invitation_id ? `Remove ${partnerName}?` : "Leave workspace"}
          subText1={actionableRetailerItem && actionableRetailerItem.invitation_id ? `Are you sure you want to remove ${partnerName} from your account? All active orders associated with this partner will be changed to draft status. This action is irreversible and will terminate any ongoing collaboration with ${partnerName}.` : "Are you sure you want to leave selected workspace? This action cannot be undone."}
          className="w-full lg:max-w-[600px]"
          IconComponent={actionableRetailerItem && actionableRetailerItem.invitation_id ? InvitePartnerIcon : DottedIcon}
          confirmButtonText={actionableRetailerItem && actionableRetailerItem.invitation_id ? "Remove" : "Confirm"}
          confirmButtonVariant={actionableRetailerItem && actionableRetailerItem.invitation_id ? "warning" : "primary"}
          confirmButtonClassName={"w-full h-11"}
          onConfirmClick={handleOnDelete}
          icon={
            actionableRetailerItem && actionableRetailerItem.invitation_id ?
              <TrashUpdateIcon
                width={20}
                height={20}
                strokeColor={"var(--color-icon-fill-secondary-button)"}
              />
              : <LogoutIcon />
          }
        />
      </Modal>

      <Modal open={bulkDeleteModal} close={closeModal}>
        <CommonDiscardButtonUpdated
          closeModal={closeModal}
          title={`Remove ${selectedOrders.length === 1 ? "partner" : "partners"}`}
          subText1={`Are you sure you want to remove ${selectedOrders.length === 1 ? "partner" : "partners"
            } from your account? All active orders associated with ${selectedOrders.length === 1 ? "this partner" : "these partners"
            } will be changed to draft status. This action is irreversible and will terminate any ongoing collaboration with ${selectedOrders.length === 1 ? "this partner" : "these partners"
            }.`}
          className="w-full lg:max-w-[600px]"
          IconComponent={InvitePartnerIcon}
          confirmButtonText="Remove"
          confirmButtonVariant="warning"
          confirmButtonClassName={"w-full h-11"}
          onConfirmClick={handleDeleteAll}
          icon={
            <TrashUpdateIcon
              width={20}
              height={20}
              strokeColor={"var(--color-icon-fill-secondary-button)"}
            />
          }
        />
      </Modal>
      <Modal open={showUpgradeModal} close={closeModal}>
        <CommonDiscardButton
          closeModal={closeModal}
          title="Upgrade plan"
          subText1={
            currentWorkspace && !currentWorkspace?.is_owner && !workspacePlan
              ? `Your partner workspace is currently on a free plan.`
              : `You are currently on a free plan. Upgrade to a paid plan to start creating your own ${user && user?.workspace_type} workspaces.`
          }
          className="max-w-[544px]"
          confirmButtonClassName="w-full"
          IconComponent={PlanIcon}
          confirmButtonText={
            currentWorkspace && !currentWorkspace?.is_owner && !workspacePlan ? null : "Ugrade Plan"
          }
          confirmButtonVariant="primary"
          icon={
            <Shock width={20} height={20} strokeColor={"var(--color-icon-fill-secondary-button)"} />
          }
          onConfirmClick={() =>
            navigate(`/workspace/${currentWorkspace?.user_workspace_id}/setting?selectedTab=plan`)
          }
        />
      </Modal>
    </>
  );
};

export default Retailer;
