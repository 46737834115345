export const permissions = {
  "retailer.admin": {
    ownerMenuItems: [
      "workspaces",
      "certificates",
      "audits",
      "templates",
      "articles",
      "orders",
      "agents",
      "suppliers",
      "subsuppliers",
      "setting",
      "support",
      "report",
    ],
    ownerActions: [
      "createTemplate",
      "canAddTemplate",
      "updateTemplate",
      "deleteTemplate",
      "duplicateTemplate",
      "createArticle",
      "canAddArticle",
      "updateArticle",
      "deleteArticle",
      "duplicateArticle",
      "createAgent",
      "canAddAgent",
      "updateAgent",
      "deleteAgent",
      "createOrder",
      "canAddOrder",
      "updateOrder",
      "deleteOrder",
      "cancelOrder",
      "duplicateOrder",
      "createSupplier",
      "canAddSupplier",
      "updateSupplier",
      "deleteSupplier",
      "createSubSupplier",
      "canAddSubSupplier",
      "updateSubSupplier",
      "deleteSubSupplier",
      "duplicateSubSupplier",
      "uploadCertificate",
      "canAddCertificate",
      "canGlobalCreate",
    ],
    invitedMenuItems: [
      "workspaces",
      "templates",
      "articles",
      "orders",
      "subsuppliers",
      "suppliers",
      "support",
      "report",
    ],
    invitedActions: [
      // "createOrder",
      // "canAddOrder",
      "updateOrder",
      "deleteOrder",
      "cancelOrder",
      // "duplicateOrder",
      "createAgent",
      "canAddAgent",
      "updateAgent",
      "deleteAgent",
      // "createArticle",
      // "canAddArticle",
      // "updateArticle",
      // "deleteArticle",
      // "duplicateArticle",
      // "createTemplate",
      // "canAddTemplate",
      // "updateTemplate",
      // "deleteTemplate",
      // "duplicateTemplate",
      "createSupplier",
      "canAddSupplier",
      "updateSupplier",
      "deleteSupplier",
      "createSubSupplier",
      "canAddSubSupplier",
      "updateSubSupplier",
      "deleteSubSupplier",
      "duplicateSubSupplier",
      "canGlobalCreate",
    ],
    canInvite: ["supplier", "agent"],
    invitedPersonCanView: ["supplier", "agent"],
  },
  "retailer.editor": {
    ownerMenuItems: [
      "workspaces",
      "certificates",
      "audits",
      "templates",
      "articles",
      "orders",
      "agents",
      "suppliers",
      "subsuppliers",
      "setting",
      "support",
      "report",
    ],
    ownerActions: [
      "createTemplate",
      "canAddTemplate",
      "updateTemplate",
      "deleteTemplate",
      "duplicateTemplate",
      "createArticle",
      "canAddArticle",
      "updateArticle",
      "deleteArticle",
      "duplicateArticle",
      "updateAgent",
      "deleteAgent",
      "createOrder",
      "canAddOrder",
      "deleteOrder",
      "cancelOrder",
      "duplicateOrder",
      "updateOrder",
      "updateSupplier",
      "deleteSupplier",
      "createSubSupplier",
      "canAddSubSupplier",
      "updateSubSupplier",
      "deleteSubSupplier",
      "duplicateSubSupplier",
      "uploadCertificate",
      "canAddCertificate",
      "canGlobalCreate",
    ],
    invitedMenuItems: [
      "workspaces",
      "templates",
      "articles",
      "orders",
      "suppliers",
      "subsuppliers",
      "support",
      "report",
    ],
    invitedActions: [
      // "createOrder",
      // "canAddOrder",
      "updateOrder",
      "deleteOrder",
      "cancelOrder",
      // "duplicateOrder",
      // "createArticle",
      // "canAddArticle",
      // "updateArticle",
      // "deleteArticle",
      // "duplicateArticle",
      "updateAgent",
      "deleteAgent",
      // "createTemplate",
      // "canAddTemplate",
      // "updateTemplate",
      // "deleteTemplate",
      // "duplicateTemplate",
      "updateSupplier",
      "deleteSupplier",
      "createSubSupplier",
      "canAddSubSupplier",
      "updateSubSupplier",
      "deleteSubSupplier",
      "duplicateSubSupplier",
      "canGlobalCreate",
    ],
    canInvite: ["supplier", "agent"],
    invitedPersonCanView: ["supplier", "agent"],
  },
  "retailer.viewer": {
    ownerMenuItems: [
      "workspaces",
      "certificates",
      "audits",
      "templates",
      "articles",
      "orders",
      "agents",
      "suppliers",
      "subsuppliers",
      "setting",
      "support",
      "report",
    ],
    ownerActions: [],
    invitedMenuItems: [
      "workspaces",
      "templates",
      "articles",
      "orders",
      "suppliers",
      "subsuppliers",
      "support",
      "report",
    ],
    invitedActions: [],
    canInvite: [],
    invitedPersonCanView: [],
  },
  "retailer.deleted": {
    ownerMenuItems: [
      "workspaces",
      "certificates",
      "audits",
      "templates",
      "articles",
      "orders",
      "agents",
      "suppliers",
      "subsuppliers",
      "setting",
      "support",
      "report",
    ],
    ownerActions: [],
    invitedMenuItems: [
      "workspaces",
      "templates",
      "articles",
      "orders",
      "suppliers",
      "subsuppliers",
      "support",
      "report",
    ],
    invitedActions: [],
    canInvite: [],
    invitedPersonCanView: [],
  },
  "importer.admin": {
    ownerMenuItems: [
      "workspaces",
      "certificates",
      "audits",
      "templates",
      "articles",
      "orders",
      "agents",
      "suppliers",
      "subsuppliers",
      "setting",
      "support",
      "report",
    ],
    ownerActions: [
      "createTemplate",
      "canAddTemplate",
      "updateTemplate",
      "deleteTemplate",
      "duplicateTemplate",
      "createArticle",
      "canAddArticle",
      "updateArticle",
      "deleteArticle",
      "duplicateArticle",
      "createAgent",
      "canAddAgent",
      "updateAgent",
      "deleteAgent",
      "createOrder",
      "canAddOrder",
      "updateOrder",
      "deleteOrder",
      "cancelOrder",
      "duplicateOrder",
      "createSupplier",
      "canAddSupplier",
      "updateSupplier",
      "deleteSupplier",
      "createSubSupplier",
      "canAddSubSupplier",
      "updateSubSupplier",
      "deleteSubSupplier",
      "duplicateSubSupplier",
      "uploadCertificate",
      "canAddCertificate",
      "canGlobalCreate",
    ],
    invitedMenuItems: [
      "workspaces",
      "templates",
      "articles",
      "orders",
      "subsuppliers",
      "suppliers",
      "support",
      "report",
    ],
    invitedActions: [
      // "createOrder",
      // "canAddOrder",
      "updateOrder",
      "deleteOrder",
      "cancelOrder",
      // "duplicateOrder",
      "createAgent",
      "canAddAgent",
      "updateAgent",
      "deleteAgent",
      // "createArticle",
      // "canAddArticle",
      // "updateArticle",
      // "deleteArticle",
      // "duplicateArticle",
      // "createTemplate",
      // "canAddTemplate",
      // "updateTemplate",
      // "deleteTemplate",
      // "duplicateTemplate",
      "createSupplier",
      "canAddSupplier",
      "updateSupplier",
      "deleteSupplier",
      "createSubSupplier",
      "canAddSubSupplier",
      "updateSubSupplier",
      "deleteSubSupplier",
      "duplicateSubSupplier",
      "canGlobalCreate",
    ],
    canInvite: ["supplier", "agent"],
    invitedPersonCanView: ["supplier", "agent"],
  },
  "importer.editor": {
    ownerMenuItems: [
      "workspaces",
      "certificates",
      "audits",
      "templates",
      "articles",
      "orders",
      "agents",
      "suppliers",
      "subsuppliers",
      "setting",
      "support",
      "report",
    ],
    ownerActions: [
      "createTemplate",
      "canAddTemplate",
      "updateTemplate",
      "deleteTemplate",
      "duplicateTemplate",
      "createArticle",
      "canAddArticle",
      "updateArticle",
      "deleteArticle",
      "duplicateArticle",
      "updateAgent",
      "deleteAgent",
      "createOrder",
      "canAddOrder",
      "deleteOrder",
      "cancelOrder",
      "duplicateOrder",
      "updateOrder",
      "updateSupplier",
      "deleteSupplier",
      "createSubSupplier",
      "canAddSubSupplier",
      "updateSubSupplier",
      "deleteSubSupplier",
      "duplicateSubSupplier",
      "uploadCertificate",
      "canAddCertificate",
      "canGlobalCreate",
    ],
    invitedMenuItems: [
      "workspaces",
      "templates",
      "articles",
      "orders",
      "suppliers",
      "subsuppliers",
      "support",
      "report",
    ],
    invitedActions: [
      // "createOrder",
      // "canAddOrder",
      "updateOrder",
      "deleteOrder",
      "cancelOrder",
      // "duplicateOrder",
      // "createArticle",
      // "canAddArticle",
      // "updateArticle",
      // "deleteArticle",
      // "duplicateArticle",
      "updateAgent",
      "deleteAgent",
      // "createTemplate",
      // "canAddTemplate",
      // "updateTemplate",
      // "deleteTemplate",
      // "duplicateTemplate",
      "updateSupplier",
      "deleteSupplier",
      "createSubSupplier",
      "canAddSubSupplier",
      "updateSubSupplier",
      "deleteSubSupplier",
      "duplicateSubSupplier",
      "canGlobalCreate",
    ],
    canInvite: ["supplier", "agent"],
    invitedPersonCanView: ["supplier", "agent"],
  },
  "importer.viewer": {
    ownerMenuItems: [
      "workspaces",
      "certificates",
      "audits",
      "templates",
      "articles",
      "orders",
      "agents",
      "suppliers",
      "subsuppliers",
      "setting",
      "support",
      "report",
    ],
    ownerActions: [],
    invitedMenuItems: [
      "workspaces",
      "templates",
      "articles",
      "orders",
      "suppliers",
      "subsuppliers",
      "support",
      "report",
    ],
    invitedActions: [],
    canInvite: [],
    invitedPersonCanView: [],
  },
  "importer.deleted": {
    ownerMenuItems: [
      "workspaces",
      "certificates",
      "audits",
      "templates",
      "articles",
      "orders",
      "agents",
      "suppliers",
      "subsuppliers",
      "setting",
      "support",
      "report",
    ],
    ownerActions: [],
    invitedMenuItems: [
      "workspaces",
      "templates",
      "articles",
      "orders",
      "suppliers",
      "subsuppliers",
      "support",
      "report",
    ],
    invitedActions: [],
    canInvite: [],
    invitedPersonCanView: [],
  },
  "brand.admin": {
    ownerMenuItems: [
      "workspaces",
      "certificates",
      "audits",
      "templates",
      "articles",
      "orders",
      "agents",
      "suppliers",
      "subsuppliers",
      "setting",
      "support",
      "report",
    ],
    ownerActions: [
      "createTemplate",
      "canAddTemplate",
      "updateTemplate",
      "deleteTemplate",
      "duplicateTemplate",
      "createArticle",
      "canAddArticle",
      "updateArticle",
      "deleteArticle",
      "duplicateArticle",
      "createAgent",
      "canAddAgent",
      "updateAgent",
      "deleteAgent",
      "createOrder",
      "canAddOrder",
      "updateOrder",
      "deleteOrder",
      "cancelOrder",
      "duplicateOrder",
      "createSupplier",
      "canAddSupplier",
      "updateSupplier",
      "deleteSupplier",
      "createSubSupplier",
      "canAddSubSupplier",
      "updateSubSupplier",
      "deleteSubSupplier",
      "duplicateSubSupplier",
      "uploadCertificate",
      "canAddCertificate",
      "canGlobalCreate",
    ],
    invitedMenuItems: [
      "workspaces",
      "templates",
      "articles",
      "orders",
      "subsuppliers",
      "suppliers",
      "support",
      "report",
    ],
    invitedActions: [
      // "createOrder",
      // "canAddOrder",
      "updateOrder",
      "deleteOrder",
      "cancelOrder",
      // "duplicateOrder",
      "createAgent",
      "canAddAgent",
      "updateAgent",
      "deleteAgent",
      // "createArticle",
      // "canAddArticle",
      // "updateArticle",
      // "deleteArticle",
      // "duplicateArticle",
      // "createTemplate",
      // "canAddTemplate",
      // "updateTemplate",
      // "deleteTemplate",
      // "duplicateTemplate",
      "createSupplier",
      "canAddSupplier",
      "updateSupplier",
      "deleteSupplier",
      "createSubSupplier",
      "canAddSubSupplier",
      "updateSubSupplier",
      "deleteSubSupplier",
      "duplicateSubSupplier",
      "canGlobalCreate",
    ],
    canInvite: ["supplier", "agent"],
    invitedPersonCanView: ["supplier", "agent"],
  },
  "brand.editor": {
    ownerMenuItems: [
      "workspaces",
      "certificates",
      "audits",
      "templates",
      "articles",
      "orders",
      "agents",
      "suppliers",
      "subsuppliers",
      "setting",
      "support",
      "report",
    ],
    ownerActions: [
      "createTemplate",
      "canAddTemplate",
      "updateTemplate",
      "deleteTemplate",
      "duplicateTemplate",
      "createArticle",
      "canAddArticle",
      "updateArticle",
      "deleteArticle",
      "duplicateArticle",
      "updateAgent",
      "deleteAgent",
      "createOrder",
      "canAddOrder",
      "deleteOrder",
      "cancelOrder",
      "duplicateOrder",
      "updateOrder",
      "updateSupplier",
      "deleteSupplier",
      "createSubSupplier",
      "canAddSubSupplier",
      "updateSubSupplier",
      "deleteSubSupplier",
      "duplicateSubSupplier",
      "uploadCertificate",
      "canAddCertificate",
      "canGlobalCreate",
    ],
    invitedMenuItems: [
      "workspaces",
      "templates",
      "articles",
      "orders",
      "suppliers",
      "subsuppliers",
      "support",
      "report",
    ],
    invitedActions: [
      // "createOrder",
      // "canAddOrder",
      "updateOrder",
      "deleteOrder",
      "cancelOrder",
      // "duplicateOrder",
      // "createArticle",
      // "canAddArticle",
      // "updateArticle",
      // "deleteArticle",
      // "duplicateArticle",
      "updateAgent",
      "deleteAgent",
      // "createTemplate",
      // "canAddTemplate",
      // "updateTemplate",
      // "deleteTemplate",
      // "duplicateTemplate",
      "updateSupplier",
      "deleteSupplier",
      "createSubSupplier",
      "canAddSubSupplier",
      "updateSubSupplier",
      "deleteSubSupplier",
      "duplicateSubSupplier",
      "canGlobalCreate",
    ],
    canInvite: ["supplier", "agent"],
    invitedPersonCanView: ["supplier", "agent"],
  },
  "brand.viewer": {
    ownerMenuItems: [
      "workspaces",
      "certificates",
      "audits",
      "templates",
      "articles",
      "orders",
      "agents",
      "suppliers",
      "subsuppliers",
      "setting",
      "support",
      "report",
    ],
    ownerActions: [],
    invitedMenuItems: [
      "workspaces",
      "templates",
      "articles",
      "orders",
      "suppliers",
      "subsuppliers",
      "support",
      "report",
    ],
    invitedActions: [],
    canInvite: [],
    invitedPersonCanView: [],
  },
  "brand.deleted": {
    ownerMenuItems: [
      "workspaces",
      "certificates",
      "audits",
      "templates",
      "articles",
      "orders",
      "agents",
      "suppliers",
      "subsuppliers",
      "setting",
      "support",
      "report",
    ],
    ownerActions: [],
    invitedMenuItems: [
      "workspaces",
      "templates",
      "articles",
      "orders",
      "suppliers",
      "subsuppliers",
      "support",
      "report",
    ],
    invitedActions: [],
    canInvite: [],
    invitedPersonCanView: [],
  },
  "agent.admin": {
    ownerMenuItems: [
      "workspaces",
      "certificates",
      "audits",
      "templates",
      "articles",
      "orders",
      "retailers",
      "suppliers",
      "setting",
      "support",
      "report",
      "subsuppliers",
    ],
    ownerActions: [
      // "createTemplate",
      // "canAddTemplate",
      // "updateTemplate",
      // "deleteTemplate",
      // "duplicateTemplate",
      "createRetailer",
      "canAddRetailer",
      "updateRetailer",
      "deleteRetailer",
      // "createArticle",
      // "canAddArticle",
      // "updateArticle",
      // "deleteArticle",
      // "duplicateArticle",
      // "createOrder",
      // "canAddOrder",
      "updateOrder",
      "deleteOrder",
      "cancelOrder",
      // "duplicateOrder",
      "createSupplier",
      "canAddSupplier",
      "updateSupplier",
      "deleteSupplier",
      "uploadCertificate",
      "canAddCertificate",
      "createSubSupplier",
      "canAddSubSupplier",
      "updateSubSupplier",
      "deleteSubSupplier",
      "duplicateSubSupplier",
      "canGlobalCreate",
    ],
    invitedMenuItems: [
      "workspaces",
      "templates",
      "articles",
      "orders",
      "suppliers",
      "support",
      "report",
      "subsuppliers",
    ],
    invitedActions: [
      "createOrder",
      "canAddOrder",
      "updateOrder",
      "deleteOrder",
      "cancelOrder",
      "duplicateOrder",
      "createArticle",
      "canAddArticle",
      "updateArticle",
      "deleteArticle",
      "duplicateArticle",
      "createTemplate",
      "canAddTemplate",
      "updateTemplate",
      "deleteTemplate",
      "duplicateTemplate",
      "createSupplier",
      "canAddSupplier",
      "updateSupplier",
      "deleteSupplier",
      "createSubSupplier",
      "canAddSubSupplier",
      "updateSubSupplier",
      "deleteSubSupplier",
      "duplicateSubSupplier",
      "canGlobalCreate",
    ],
    canInvite: ["retailer", "supplier", "importer", "brand"],
    invitedPersonCanView: ["retailer", "supplier", "importer", "brand"],
  },
  "agent.editor": {
    ownerMenuItems: [
      "workspaces",
      "certificates",
      "audits",
      "templates",
      "articles",
      "orders",
      "retailers",
      "suppliers",
      "setting",
      "support",
      "report",
      "subsuppliers",
    ],
    ownerActions: [
      // "createTemplate",
      // "canAddTemplate",
      // "updateTemplate",
      // "deleteTemplate",
      // "duplicateTemplate",
      "updateRetailer",
      "deleteRetailer",
      // "createArticle",
      // "canAddArticle",
      // "updateArticle",
      // "deleteArticle",
      // "duplicateArticle",
      // "createOrder",
      // "canAddOrder",
      "updateOrder",
      "deleteOrder",
      "cancelOrder",
      // "duplicateOrder",
      "updateSupplier",
      "deleteSupplier",
      "uploadCertificate",
      "canAddCertificate",
      "createSubSupplier",
      "canAddSubSupplier",
      "updateSubSupplier",
      "deleteSubSupplier",
      "duplicateSubSupplier",
      "canGlobalCreate",
    ],
    invitedMenuItems: [
      "workspaces",
      "templates",
      "articles",
      "orders",
      "suppliers",
      "support",
      "report",
      "subsuppliers",
    ],
    invitedActions: [
      "createOrder",
      "canAddOrder",
      "updateOrder",
      "deleteOrder",
      "cancelOrder",
      "duplicateOrder",
      "createArticle",
      "canAddArticle",
      "updateArticle",
      "deleteArticle",
      "duplicateArticle",
      "createTemplate",
      "canAddTemplate",
      "updateTemplate",
      "deleteTemplate",
      "duplicateTemplate",
      "updateSupplier",
      "deleteSupplier",
      "createSubSupplier",
      "canAddSubSupplier",
      "updateSubSupplier",
      "deleteSubSupplier",
      "duplicateSubSupplier",
      "canGlobalCreate",
    ],
    canInvite: ["retailer", "supplier", "importer", "brand"],
    invitedPersonCanView: ["retailer", "supplier", "importer", "brand"],
  },
  "agent.viewer": {
    ownerMenuItems: [
      "workspaces",
      "certificates",
      "audits",
      "templates",
      "articles",
      "orders",
      "retailers",
      "suppliers",
      "setting",
      "support",
      "report",
      "subsuppliers",
    ],
    ownerActions: [],
    invitedMenuItems: [
      "workspaces",
      "templates",
      "articles",
      "orders",
      "suppliers",
      "support",
      "report",
      "subsuppliers",
    ],
    invitedActions: [],
    canInvite: [],
    invitedPersonCanView: [],
  },
  "agent.deleted": {
    ownerMenuItems: [
      "workspaces",
      "certificates",
      "audits",
      "templates",
      "articles",
      "orders",
      "retailers",
      "suppliers",
      "subsuppliers",
      "setting",
      "support",
      "report",
    ],
    ownerActions: [],
    invitedMenuItems: [
      "workspaces",
      "templates",
      "articles",
      "orders",
      "suppliers",
      "subsuppliers",
      "support",
      "report",
    ],
    invitedActions: [],
    canInvite: [],
    invitedPersonCanView: [],
  },
  "supplier.admin": {
    ownerMenuItems: [
      "workspaces",
      "templates",
      "articles",
      "orders",
      "retailers",
      "subsuppliers",
      "suppliers",
      "setting",
      "support",
      "report",
    ],
    ownerActions: [
      // "createTemplate",
      // "canAddTemplate",
      // "updateTemplate",
      // "deleteTemplate",
      // "duplicateTemplate",
      // "createArticle",
      // "canAddArticle",
      // "updateArticle",
      // "deleteArticle",
      // "duplicateArticle",
      // "createOrder",
      // "canAddOrder",
      // "duplicateOrder",
      "updateOrder",
      "deleteOrder",
      "cancelOrder",
      "createRetailer",
      "canAddRetailer",
      "updateRetailer",
      "deleteRetailer",
      "createSubSupplier",
      "canAddSubSupplier",
      "updateSubSupplier",
      "deleteSubSupplier",
      "duplicateSubSupplier",
      "uploadCertificate",
      "canAddCertificate",
      "canGlobalCreate",
    ],
    invitedMenuItems: [
      "workspaces",
      "templates",
      "articles",
      "orders",
      "subsuppliers",
      "support",
      "report",
      "suppliers",
    ],
    invitedActions: [
      "createTemplate",
      "canAddTemplate",
      "updateTemplate",
      "deleteTemplate",
      "duplicateTemplate",
      "createArticle",
      "canAddArticle",
      "updateArticle",
      "deleteArticle",
      "duplicateArticle",
      "trackOrder",
      "createSubSupplier",
      "canAddSubSupplier",
      "updateSubSupplier",
      "deleteSubSupplier",
      "duplicateSubSupplier",
      "uploadCertificate",
      "canAddCertificate",
      "createOrder",
      "canAddOrder",
      "updateOrder",
      "deleteOrder",
      "cancelOrder",
      "duplicateOrder",
    ],
    canInvite: ["retailer", "importer", "brand"],
    invitedPersonCanView: ["retailer", "importer", "brand"],
  },
  "supplier.editor": {
    ownerMenuItems: [
      "workspaces",
      "templates",
      "articles",
      "orders",
      "retailers",
      "subsuppliers",
      "suppliers",
      "setting",
      "support",
      "report",
    ],
    ownerActions: [
      // "createTemplate",
      // "canAddTemplate",
      // "updateTemplate",
      // "deleteTemplate",
      // "duplicateTemplate",
      // "createArticle",
      // "canAddArticle",
      // "updateArticle",
      // "deleteArticle",
      // "duplicateArticle",
      // "createOrder",
      // "canAddOrder",
      "updateOrder",
      "deleteOrder",
      "cancelOrder",
      // "duplicateOrder",
      "updateRetailer",
      "deleteRetailer",
      "createSubSupplier",
      "canAddSubSupplier",
      "updateSubSupplier",
      "deleteSubSupplier",
      "duplicateSubSupplier",
      "uploadCertificate",
      "canAddCertificate",
      "canGlobalCreate",
    ],
    invitedMenuItems: [
      "workspaces",
      "templates",
      "articles",
      "orders",
      "subsuppliers",
      "support",
      "report",
      "suppliers",
    ],
    invitedActions: [
      "createTemplate",
      "canAddTemplate",
      "updateTemplate",
      "deleteTemplate",
      "duplicateTemplate",
      "createArticle",
      "canAddArticle",
      "updateArticle",
      "deleteArticle",
      "duplicateArticle",
      "trackOrder",
      "createSubSupplier",
      "canAddSubSupplier",
      "updateSubSupplier",
      "deleteSubSupplier",
      "duplicateSubSupplier",
      "uploadCertificate",
      "canAddCertificate",
      "createOrder",
      "canAddOrder",
      "updateOrder",
      "deleteOrder",
      "cancelOrder",
      "duplicateOrder",
    ],
    canInvite: ["retailer", "importer", "brand"],
    invitedPersonCanView: ["retailer", "importer", "brand"],
  },
  "supplier.viewer": {
    ownerMenuItems: [
      "workspaces",
      "templates",
      "articles",
      "orders",
      "retailers",
      "subsuppliers",
      "suppliers",
      "setting",
      "support",
      "report",
    ],
    ownerActions: [],
    invitedMenuItems: [
      "workspaces",
      "templates",
      "articles",
      "orders",
      "subsuppliers",
      "support",
      "report",
      "suppliers",
    ],
    invitedActions: [],
    canInvite: [],
    invitedPersonCanView: [],
  },
  "supplier.deleted": {
    ownerMenuItems: [],
    ownerActions: [],
    invitedMenuItems: [
      "workspaces",
      "templates",
      "articles",
      "orders",
      "subsuppliers",
      "support",
      "report",
      "suppliers",
    ],
    invitedActions: [],
    canInvite: [],
    invitedPersonCanView: [],
  },
  "workspace.admin": {
    ownerMenuItems: [
      "workspaces",
      "certificates",
      "audits",
      "templates",
      "articles",
      "orders",
      "agents",
      "suppliers",
      "retailers",
      "subsuppliers",
      "setting",
      "support",
      "report",
    ],
    ownerActions: [
      "createTemplate",
      "canAddTemplate",
      "updateTemplate",
      "deleteTemplate",
      "duplicateTemplate",
      "createArticle",
      "canAddArticle",
      "updateArticle",
      "deleteArticle",
      "duplicateArticle",
      "createUser",
      "createAgent",
      "canAddAgent",
      "updateAgent",
      "deleteAgent",
      "createOrder",
      "canAddOrder",
      "updateOrder",
      "deleteOrder",
      "cancelOrder",
      "duplicateOrder",
      "createSupplier",
      "canAddSupplier",
      "updateSupplier",
      "deleteSupplier",
      "uploadCertificate",
      "canAddCertificate",
      "createActiveUser",
      "editActiveUser",
      "deleteActiveUser",
      "resendActiveUser",
      "cancelActiveUser",
      "editCompanyProfile",
      "companyProfileEdit",
      "viewWorkspace",
      "deleteWorkspace",
      "canGlobalCreate",
    ],
    invitedMenuItems: [],
    invitedActions: [],
    canInvite: ["retailer", "supplier", "agent", "importer", "brand"],
    invitedPersonCanView: ["retailer", "supplier", "agent", "importer", "brand"],
  },
  "workspace.editor": {
    ownerMenuItems: [
      "workspaces",
      "certificates",
      "audits",
      "templates",
      "articles",
      "orders",
      "agents",
      "suppliers",
      "retailers",
      "subsuppliers",
      "setting",
      "support",
      "report",
    ],
    ownerActions: [
      "createTemplate",
      "canAddTemplate",
      "updateTemplate",
      "deleteTemplate",
      "duplicateTemplate",
      "createArticle",
      "canAddArticle",
      "updateArticle",
      "deleteArticle",
      "duplicateArticle",
      "createAgent",
      "canAddAgent",
      "updateAgent",
      "deleteAgent",
      "createOrder",
      "canAddOrder",
      "updateOrder",
      "deleteOrder",
      "cancelOrder",
      "duplicateOrder",
      "createSupplier",
      "canAddSupplier",
      "updateSupplier",
      "deleteSupplier",
      "uploadCertificate",
      "canAddCertificate",
      //setting
      "createActiveUser",
      // "editActiveUser",
      "deleteActiveUser",
      "resendActiveUser",
      "cancelActiveUser",
      "editCompanyProfile",
      "transferOwnerShip",
      "canGlobalCreate",
    ],
    invitedMenuItems: [],
    invitedActions: [],
    canInvite: ["retailer", "supplier", "agent", "importer", "brand"],
    invitedPersonCanView: ["retailer", "supplier", "agent", "importer", "brand"],
  },
  "workspace.viewer": {
    ownerMenuItems: [
      "workspaces",
      "certificates",
      "audits",
      "templates",
      "articles",
      "orders",
      "agents",
      "suppliers",
      "retailers",
      "subsuppliers",
      "setting",
      "support",
      "report",
    ],
    ownerActions: [],
    invitedMenuItems: [],
    invitedActions: [],
    canInvite: [],
    invitedPersonCanView: [],
  },
  "workspace.deleted": {
    ownerMenuItems: [
      "workspaces",
      "certificates",
      "audits",
      "templates",
      "articles",
      "orders",
      "agents",
      "suppliers",
      "retailers",
      "subsuppliers",
      "setting",
      "support",
      "report",
    ],
    ownerActions: [],
    invitedMenuItems: [],
    invitedActions: [],
    canInvite: [],
    invitedPersonCanView: [],
  },
  superadmin: {
    canInvite: ["retailer", "supplier", "agent", "importer", "brand"],
    invitedPersonCanView: ["retailer", "supplier", "agent", "importer", "brand"],
  },
};

export const optionViewPermissions = {
  supplier: {},
  retailer: {
    canInvite: ["supplier", "agent"],
    invitedPersonCanView: ["supplier", "agent"],
  },
  importer: {
    canInvite: ["supplier", "agent"],
    invitedPersonCanView: ["supplier", "agent"],
  },
  brand: {
    canInvite: ["supplier", "agent"],
    invitedPersonCanView: ["supplier", "agent"],
  },
  agent: {},
  supperadmin: {
    canInvite: ["retailer", "supplier", "agent", "importer", "brand"],
    invitedPersonCanView: ["retailer", "supplier", "agent", "importer", "brand"],
  },
  // workspace: {
  //   canInvite: [],
  //   invitedPersonCanView: [],
  // }
};
