import { GreaterThan, MoreOptions, Plus, UpgradeBg, XCircle } from "assests";
import { Input } from "components/UI/Input";
import { Label } from "components/UI/Label";
import Modal from "components/UI/Modal";
import AddLocationForm from "pages/Workspace/Order/OrderDetails/AddLocationForm";
import { useDispatch, useSelector } from "react-redux";
import {
  setSelectedSubTierId,
  setSelectedTierType,
  toggleDeleteSubSupplierLocationModal,
  toggleIsForwarderCreateModalOpen,
  toggleIsOrderDocumentsEditable,
  toggleIsSubSupplierCreateFromOrderModalOpen,
  toggleIsSubSupplierCreateModalOpen,
  toggleIsTiersUpdateModalOpen,
  toggleSelectedOrderDocuments,
} from "store/orderSlice";

import AttachFiles from "assests/svg/AttachFiles";
import usePermission from "hooks/usePermission";
import { Fragment, useMemo } from "react";
import ForwarderForm from "./ForwarderForm";
import UpdateOrderTiers from "./UpdateOrderTiers";
import { Button } from "components/UI/Button";

const UpdateSupplyChainCard = ({ refetchOrderDetails }) => {
  const { hasPermission } = usePermission();
  const dispatch = useDispatch();
  const {
    selectedTierLists,
    selectedSubTierId,
    deleteSubSupplierLocationModal,
    orderDetailsData,
    isOrderDocumentsEditable,
    selectedOrderDocumentsId,
    isSubSupplierCreateModalOpen,
    isForwarderCreateModalOpen,
    isTiersUpdateModalOpen,
    selectedTierType,
    isSubSupplierCreateFromOrderModalOpen
  } = useSelector((state) => state.orderSlice);
  const { user, currentWorkspace } = useSelector((state) => state.authenticationSlice);

  const openCreateModal = (tierItem, subTierId, subTierType) => {
    console.log(subTierType, "subTierType");
    if (tierItem === "tier_0" && subTierType === "Forwarder") {
      dispatch(toggleIsForwarderCreateModalOpen());
    } else {
      dispatch(toggleIsSubSupplierCreateModalOpen());
    }
    dispatch(setSelectedSubTierId(subTierId));
    dispatch(setSelectedTierType(subTierType));
    if (!isOrderDocumentsEditable) {
      dispatch(toggleIsOrderDocumentsEditable());
    }
    if (selectedOrderDocumentsId) {
      dispatch(toggleSelectedOrderDocuments());
    }
  };

  const handleClickOptionButton = (tierItem, orderDocumentsId, subTierId, actionType, subTierType) => {
    switch (actionType) {
      case "view":
        dispatch(toggleSelectedOrderDocuments(orderDocumentsId));
        dispatch(setSelectedSubTierId(subTierId));
        dispatch(setSelectedTierType(subTierType));
        if (tierItem === "tier_0" && subTierType === "Forwarder") {
          dispatch(toggleIsForwarderCreateModalOpen());
        } else {
          dispatch(toggleIsSubSupplierCreateModalOpen());
        }
        break;
      case "edit":
        dispatch(toggleSelectedOrderDocuments(orderDocumentsId));
        dispatch(setSelectedSubTierId(subTierId));
        dispatch(setSelectedTierType(subTierType));
        dispatch(toggleIsOrderDocumentsEditable());
        if (tierItem === "tier_0" && subTierType === "Forwarder") {
          dispatch(toggleIsForwarderCreateModalOpen());
        } else {
          dispatch(toggleIsSubSupplierCreateModalOpen());
        }
        break;
      case "delete":
        dispatch(toggleDeleteSubSupplierLocationModal());
        dispatch(setSelectedSubTierId(subTierId));
        dispatch(setSelectedTierType(subTierType));
        dispatch(setSelectedSubTierId(orderDocumentsId));
        break;
      default:
        break;
    }
  };

  const closeModal = () => {
    if (isSubSupplierCreateModalOpen) {
      dispatch(toggleIsSubSupplierCreateModalOpen());
    }
    if (isForwarderCreateModalOpen) {
      dispatch(toggleIsForwarderCreateModalOpen());
    }
    if (deleteSubSupplierLocationModal) {
      dispatch(toggleDeleteSubSupplierLocationModal());
    }
    if (selectedSubTierId) {
      dispatch(setSelectedSubTierId());
    }
    if (isOrderDocumentsEditable) {
      dispatch(toggleIsOrderDocumentsEditable());
    }
    if (selectedOrderDocumentsId) {
      dispatch(toggleSelectedOrderDocuments());
    }
    if (isTiersUpdateModalOpen) {
      dispatch(toggleIsTiersUpdateModalOpen());
    }
    if (selectedTierType) {
      dispatch(setSelectedTierType());
    }
    if (isSubSupplierCreateFromOrderModalOpen) {
      dispatch(toggleIsSubSupplierCreateFromOrderModalOpen())
    }

  };

  const getSubSupplierDetails = (tier, item) => {
    let result = null;
    if (
      orderDetailsData &&
      tier &&
      orderDetailsData?.transparency?.[`${tier}_sub_suppliers`] &&
      Array.isArray(orderDetailsData?.transparency?.[`${tier}_sub_suppliers`]) &&
      orderDetailsData?.transparency?.[`${tier}_sub_suppliers`].length > 0
    ) {
      const transparencyData = orderDetailsData?.transparency[`${tier}_sub_suppliers`];
      const allData = transparencyData?.find((subItem) => subItem?.sub_tier?.id === item?.value);
      if (allData && orderDetailsData.documents && orderDetailsData.documents.length > 0) {
        const docId = orderDetailsData.documents.find(
          (item) =>
            parseInt(item?.sub_supplier_id) === allData?.sub_supplier?.id &&
            parseInt(item?.sub_tier_id) === allData?.sub_tier?.id,
        )?.id;
        if (docId) {
          result = { ...allData.sub_supplier, docId };
        }
      }
    }
    if (
      orderDetailsData &&
      tier &&
      orderDetailsData.transparency?.[`${tier}_sub_suppliers`] &&
      typeof orderDetailsData.transparency?.[`${tier}_sub_suppliers`] === "object" &&
      Object.keys(orderDetailsData.transparency?.[`${tier}_sub_suppliers`]).length > 0
    ) {
      const subSuppliers = orderDetailsData?.transparency[`${tier}_sub_suppliers`];

      const preparedList = Object.entries(subSuppliers).reduce(
        (acc, [subTierId, subTierSubSuppliers]) => {
          if (parseInt(subTierId) === item.value) {
            if (
              subTierSubSuppliers &&
              Array.isArray(subTierSubSuppliers) &&
              subTierSubSuppliers.length > 0
            ) {
              const modifiedData = subTierSubSuppliers.map((subTierSubSupplier) => {
                // find the document
                const docId = orderDetailsData.documents.find(
                  (doc) =>
                    parseInt(doc.sub_supplier.id) === subTierSubSupplier.id &&
                    parseInt(doc.sub_tier_id) === parseInt(subTierId),
                );

                if (docId) {
                  return { ...subTierSubSupplier, docId };
                }

                return subTierSubSupplier;
              });
              // return modifiedData;
              return [...acc, ...modifiedData];
            }
          }
          return acc;
        },
        [],
      );
      result = preparedList;
    }
    return result;
  };

  const handleTiersUpdateOpen = () => {
    dispatch(toggleIsTiersUpdateModalOpen());
  };

  const canUpdateSubSupplier = useMemo(() => {
    if (currentWorkspace) {
      if (currentWorkspace?.role_name?.includes("viewer")) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }, [orderDetailsData]);

  return (
    <>

      <Modal open={isTiersUpdateModalOpen} close={closeModal}>
        <UpdateOrderTiers
          closeModal={closeModal}
          orderDetailsData={orderDetailsData}
          refetchOrderDetails={refetchOrderDetails}
        />
      </Modal>
      <Modal open={isSubSupplierCreateModalOpen} close={closeModal}>
        <AddLocationForm
          closeModal={closeModal}
          selectedSubTierId={selectedSubTierId}
          refetchOrderDetails={refetchOrderDetails}
          canUpdateSubSupplier={canUpdateSubSupplier}
        />
      </Modal>
      <Modal open={isForwarderCreateModalOpen} close={closeModal}>
        <ForwarderForm
          closeModal={closeModal}
          selectedSubTierId={selectedSubTierId}
          refetchOrderDetails={refetchOrderDetails}
          canUpdateSubSupplier={canUpdateSubSupplier}
        />
      </Modal>
      <div className="bg-white relative p-6 w-full h-full flex-1 border border-[#E9EAEB] rounded-xl z-[2]">
        <div className="z-[1]">
          <div className="relative w-12">
            <UpgradeBg
              style={{
                zIndex: -1,
                position: "absolute",
                top: "12%",
                left: "14%",
                transform: "translate(-14%, -11%)",
              }}
            />
          </div>
        </div>

        <div className="absolute right-3 top-3 z-[2] cursor-pointer ">
          <MoreOptions width={20} height={20} onClick={handleTiersUpdateOpen} />
        </div>

        <div className="grid grid-cols-1 gap-12 z-[6]">
          {orderDetailsData &&
            selectedTierLists &&
            Object.keys(selectedTierLists).length > 0 &&
            Object.keys(selectedTierLists)
              .reverse()
              .map((tier) => (
                <div key={tier}>
                  <div className="capitalize text-global-title text-[18px] font-semibold leading-[28px] ">
                    {tier.replace("_", " ")}
                  </div>
                  <Label
                    htmlFor="person_name"
                    className="text-sm leading-5 text-dropdown-text-label mt-1 font-normal"
                  >
                    {tier === "tier_0" && "Logistics and Distribution"}
                    {tier === "tier_1" && "Garment Manufacturers"}
                    {tier === "tier_2" && "Material and Component Suppliers"}
                    {tier === "tier_3" && "Material Processors"}
                    {tier === "tier_4" && "Raw Material Producers"}
                  </Label>
                  {selectedTierLists &&
                    selectedTierLists[tier].length > 0 &&
                    selectedTierLists[tier].map((item, index) => (
                      <div key={index} className="grid gap-1.5">
                        <div className="flex gap-1.5 items-start mt-5">
                          <Label
                            htmlFor="person_name"
                            className="text-sm leading-5 font-medium  text-global-title-2"
                          >
                            {item.label}
                          </Label>
                          {/* {hasPermission("createSubSupplier") && item.label !== "Forwarder" && (
                            <Button
                              type="button"
                              size="sm"
                              className="p-[5px] rounded-full"
                              onClick={() => openCreateModal(tier, item.value, item.label)}
                            >
                              <Plus
                                width={10}
                                height={10}
                                fillColor={"var(--color-icon-fill-light)"}
                              />
                            </Button>

                          )} */}
                        </div>

                        {getSubSupplierDetails(tier, item) &&
                          Array.isArray(getSubSupplierDetails(tier, item)) &&
                          getSubSupplierDetails(tier, item).length > 0 ? (
                          <Fragment>
                            {getSubSupplierDetails(tier, item).map((subSupplier, index) => (
                              <div key={index}>
                                <Input
                                  iconLeft={
                                    <AttachFiles
                                      height={"20px"}
                                      width={"20px"}
                                      strokeColor={subSupplier.status === "cancelled" ? "var(--color-input-text-data)" : "white"}
                                    />
                                  }
                                  className={`h-11 border  border-input-stroke-default disabled:cursor-not-allowed cursor-pointer px-[14px] pr-10 truncate py-[10px] text-white leading-6 font-medium placeholder:text-white placeholder:text-base  focus:border-none ${subSupplier && subSupplier?.docId && subSupplier?.docId?.files && subSupplier?.docId?.files?.length > 0 && subSupplier.status !== "cancelled" ? "!bg-global-blue" : subSupplier.status !== "cancelled" && "!bg-global-primary"} ${subSupplier.status === "cancelled" ? "bg-input-surface-disabled border-dropdown-stroke-default-2 read-only:bg-input-surface-hover text-input-text-data" : ""}`}
                                  placeholder="Select partner"
                                  readOnly
                                  value={subSupplier.name ? subSupplier.name : ""}
                                  disabled={subSupplier.status === "cancelled"}
                                  onClick={() =>
                                    subSupplier
                                      ? handleClickOptionButton(
                                        tier,
                                        subSupplier.docId.id,
                                        item.value,
                                        "view",
                                        item.label
                                      )
                                      : openCreateModal(tier, item.value, item.label)
                                  }
                                  iconRight={
                                    subSupplier.status !== "cancelled" &&
                                    (
                                      <GreaterThan
                                        strokeColor={"white"}
                                        height={"24px"}
                                        width={"24px"}
                                        className={`cursor-pointer ${subSupplier.status !== "cancelled" && subSupplier.docId.id ? "" : "rotate-45"}`}
                                        onClick={() => {
                                          subSupplier.docId.id
                                            ? handleClickOptionButton(
                                              tier,
                                              subSupplier.docId.id,
                                              item.value,
                                              "delete",
                                              item.label
                                            )
                                            : openCreateModal(tier, item.value, item.label);
                                        }}
                                      />
                                    )
                                  }
                                />

                              </div>
                            ))}
                          </Fragment>
                        ) : (
                          <Input
                            className="h-11  border bg-white border-input-stroke-default cursor-pointer px-[14px] py-[10px] placeholder:text-text-input-text-placeholder text-base font-normal focus:border-none read-only:bg-transparent"
                            placeholder="Select partner"
                            readOnly
                            onClick={() =>
                              openCreateModal(tier, item.value, item.label)
                            }
                            iconRight={
                              <GreaterThan
                                strokeColor={"#717680"}
                                height={"20px"}
                                width={"20px"}
                                className={`cursor-pointer `}
                                onClick={() => openCreateModal(tier, item.value, item.label)}
                              />
                            }
                          />
                        )}
                      </div>
                    ))}
                </div>
              ))}

          {/* {selectedTier &&
          selectedTierLists &&
          selectedTierLists[selectedTier].length > 0 &&
          selectedTierLists[selectedTier].map((item, index) => (
            <div key={index} className="grid gap-3 pb-4">
              <div className="flex items-center gap-2 ">
                <Label
                  htmlFor="person_name"
                  className="text-xs font-semibold text-dropdown-text-label"
                >
                  {item.label}
                </Label>
                {hasPermission("createSubSupplier") && (
                  <XCircle
                    fillColor={"var(--color-icon-fill-input)"}
                    height={"24px"}
                    width={"24px"}
                    className={`cursor-pointer rotate-45`}
                    onClick={() => openCreateModal(item.value)}
                  />
                )}
              </div>

              {getSubSupplierDetails(item) &&
              Array.isArray(getSubSupplierDetails(item)) &&
              getSubSupplierDetails(item).length > 0 ? (
                <Fragment>
                  {getSubSupplierDetails(item).map((subSupplier, index) => (
                    <div key={index}>
                      <Input
                        className=" border border-input-stroke-default disabled:cursor-not-allowed cursor-pointer border-dashed placeholder:text-dropdown-text-placeholder text-xs font-normal focus:border-none read-only:bg-transparent"
                        placeholder="Add Location"
                        readOnly
                        value={subSupplier.name ? subSupplier.name : ""}
                        disabled={subSupplier.status === "cancelled"}
                        onClick={() =>
                          subSupplier
                            ? handleClickOptionButton(subSupplier.docId.id, item.value, "view")
                            : hasPermission("createSubSupplier") && openCreateModal(item.value)
                        }
                        iconRight={
                          subSupplier.status !== "cancelled" &&
                          hasPermission("createSubSupplier") && (
                            <XCircle
                              fillColor={"var(--color-icon-fill-input)"}
                              height={"24px"}
                              width={"24px"}
                              className={`cursor-pointer ${subSupplier.status !== "cancelled" && subSupplier.docId.id ? "" : "rotate-45"}`}
                              onClick={() => {
                                subSupplier.docId.id
                                  ? handleClickOptionButton(
                                      subSupplier.docId.id,
                                      item.value,
                                      "delete",
                                    )
                                  : openCreateModal(item.value);
                              }}
                            />
                          )
                        }
                      />
                    </div>
                  ))}
                </Fragment>
              ) : (
                <Input
                  className=" border border-input-stroke-default cursor-pointer border-dashed placeholder:text-dropdown-text-placeholder text-xs font-normal focus:border-none read-only:bg-transparent"
                  placeholder="Add Location"
                  readOnly
                  disabled={!hasPermission("createSubSupplier")}
                  onClick={() =>
                    hasPermission("createSubSupplier") ? openCreateModal(item.value) : null
                  }
                  iconRight={
                    hasPermission("createSubSupplier") && (
                      <XCircle
                        fillColor={"var(--color-icon-fill-input)"}
                        height={"24px"}
                        width={"24px"}
                        className={`cursor-pointer rotate-45`}
                        onClick={() => openCreateModal(item.value)}
                      />
                    )
                  }
                />
              )}
            </div>
          ))} */}
        </div>
      </div>
    </>
  );
};

export default UpdateSupplyChainCard;
