import CustomSelect, { customMenuList } from "components/UI/CustomSelect";
import ErrorMessage from "components/UI/ErrorMessage";
import { Label } from "components/UI/Label";
import { useMemo } from "react";
const SupplyChainRetailerUser = ({
  control,
  register,
  agentList,
  workspace,
  retailerList,
  supplierList,
  errors,
  onClickInvite,
  handleChangeType,
  isValid,
  currentWorkspace,
}) => {
  const supplyChainCompanyList = useMemo(
    () => [...agentList, ...supplierList],
    [agentList, supplierList],
  );

  const workspaceRolePrefix = currentWorkspace?.role_name?.split(".")[0];

  return (
    <>
      <div className="w-full">
        <div className="grid gap-4 lg:gap-6 ">
          <div className="grid grid-cols-2 gap-4 lg:gap-6">
            {workspace && typeof workspace === "object" && workspaceRolePrefix && (
              <>
                {["importer", "brand", "retailer"].includes(workspaceRolePrefix) && (
                  <div className="col-span-2">
                    <div className="grid gap-1.5">
                      <Label htmlFor="supplierOptions" className="text-sm leading-5 font-medium">
                        Partner Name
                      </Label>

                      <CustomSelect
                        control={control}
                        name="invites.isAgentOrSupplierOption.company_id"
                        placeholder="Select partner"
                        options={supplyChainCompanyList}
                        handleOnChange={(selectedOption) => {
                          handleChangeType(selectedOption, "isAgentOrSupplierOption");
                        }}
                        {...register("invites.isAgentOrSupplierOption.company_id")}
                        isClearable={false}
                        {...(currentWorkspace?.role_name.includes("admin")
                          ? {
                            components: {
                              MenuList: (props) =>
                                customMenuList({
                                  ...props,
                                  label: "Invite Partner",
                                  onClick: () => onClickInvite("PARTNER"),
                                }),
                            },
                          }
                          : {})}
                        style={{
                          padding: "3.5px 10px 7px 1.5px",
                          fontSize: "16px",
                        }}
                        isDisabled={!isValid || !currentWorkspace.is_owner}
                      />
                      <ErrorMessage errors={errors?.invites?.supplier?.company_id?.message} />
                    </div>
                  </div>
                )}

                {workspaceRolePrefix && workspaceRolePrefix === "agent" && (
                  <>
                    <div className="col-span-2 lg:col-span-1">
                      <div className="grid gap-1.5">
                        <Label htmlFor="supplierOptions" className="text-sm leading-5 font-medium">
                          Client Name
                        </Label>
                        <CustomSelect
                          control={control}
                          name="supplierOptions"
                          placeholder="Select client"
                          options={retailerList}
                          handleOnChange={(selectedOption) =>
                            handleChangeType(selectedOption, "retailer")
                          }
                          {...register("invites.retailer.company_id")}
                          isClearable={false}
                          {...(currentWorkspace?.role_name.includes("admin")
                            ? {
                              components: {
                                MenuList: (props) =>
                                  customMenuList({
                                    ...props,
                                    label: "Invite Partner",
                                    onClick: () => onClickInvite("RETAILER"),
                                  }),
                              },
                            }
                            : {})}
                          style={{
                            padding: "3.5px 10px 7px 1.5px",
                            fontSize: "16px",
                          }}
                          isDisabled={
                            !isValid ||
                            (!currentWorkspace.is_owner &&
                              ["retailer", "importer", "brand"].includes(
                                currentWorkspace.invited_workspace_type,
                              ))
                          }
                        />
                        <ErrorMessage errors={errors?.invites?.retailer?.company_id?.message} />
                      </div>
                    </div>
                    <div className="col-span-2 lg:col-span-1">
                      <div className="grid gap-1.5">
                        <Label htmlFor="supplierOptions" className="text-sm leading-5 font-medium">
                          Supplier Name
                        </Label>
                        <CustomSelect
                          control={control}
                          name="supplierOptions"
                          placeholder="Select supplier"
                          options={supplierList}
                          value={null}
                          isClearable={false}
                          handleOnChange={(selectedOption) =>
                            handleChangeType(selectedOption, "supplier")
                          }
                          {...register("invites.supplier.company_id")}
                          {...(currentWorkspace?.role_name.includes("admin")
                            ? {
                              components: {
                                MenuList: (props) =>
                                  customMenuList({
                                    ...props,
                                    label: "Invite Partner",
                                    onClick: () => onClickInvite("SUPPLIER"),
                                  }),
                              },
                            }
                            : {})}
                          style={{
                            padding: "3.5px 10px 7px 1.5px",
                            fontSize: "16px",
                          }}
                          isDisabled={
                            !isValid ||
                            (!currentWorkspace.is_owner &&
                              currentWorkspace.invited_workspace_type === "supplier")
                          }
                        />
                        <ErrorMessage errors={errors?.invites?.supplier?.company_id?.message} />
                      </div>
                    </div>
                  </>
                )}

                {workspaceRolePrefix && workspaceRolePrefix === "supplier" && (
                  <div className="col-span-2">
                    <div className="grid gap-1.5">
                      <Label htmlFor="supplierOptions" className="text-sm leading-5 font-medium">
                        Client Name
                      </Label>
                      <CustomSelect
                        control={control}
                        name="supplierOptions"
                        placeholder="Select client"
                        options={retailerList}
                        handleOnChange={(selectedOption) =>
                          handleChangeType(selectedOption, "retailer")
                        }
                        {...register("invites.retailer.company_id")}
                        isClearable={false}
                        {...(currentWorkspace?.role_name.includes("admin")
                          ? {
                            components: {
                              MenuList: (props) =>
                                customMenuList({
                                  ...props,
                                  label: "Invite Partner",
                                  onClick: () => onClickInvite("RETAILER"),
                                }),
                            },
                          }
                          : {})}
                        style={{
                          padding: "3.5px 10px 7px 1.5px",
                          fontSize: "16px",
                        }}
                        isDisabled={!isValid || !currentWorkspace.is_owner}
                      />
                      <ErrorMessage errors={errors?.invites?.retailer?.company_id?.message} />
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default SupplyChainRetailerUser;
