import { IconButton, Menu } from "@mui/material";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  Action,
  CompliancyIcon,
  Cross,
  Duplicate,
  LinkIcon,
  MailIcon,
  MoreOptions,
  PlanIcon,
  Shock,
  TrashUpdateIcon,
  UTurnIcon,
} from "assests";
import EmptyTable from "components/Card/EmptyTable";
import ArchiveButton from "components/MoreAction/ArchiveButton";
import CancelButton from "components/MoreAction/CancelButton";
import CreateButton from "components/MoreAction/CreateButton";
import DeleteButton from "components/MoreAction/DeleteButton";
import DuplicateButton from "components/MoreAction/DuplicateButton";
import EditButton from "components/MoreAction/EditButton";
import LinkSupplier from "components/MoreAction/LinkSupplier";
import MoreActionOptionUpdated from "components/MoreAction/MoreActionOptionUpdated";
import UploadButton from "components/MoreAction/UploadButton";
import ViewButton from "components/MoreAction/ViewButton";
import SearchCreatePanelUpdate from "components/SearchCreatePanelUpdate";
import BreadCrumb from "components/TopHeader/BreadCrumb";
import TopNavbar from "components/TopHeader/TopNavbar";
import { Button } from "components/UI/Button";
import CommonDiscardButtonUpdated from "components/UI/CommonDiscardButtonUpdated";
import { LineProgressBar } from "components/UI/LineProgressBar";
import LoadingComponent from "components/UI/LoadingComponent";
import Modal from "components/UI/Modal";
import SortIcons from "components/UI/SortIcons";
import TertiaryPagination from "components/UI/TertiaryPagination";
import useCustomToaster from "hooks/useCustomToaster";
import usePermission from "hooks/usePermission";
import SubNavbar from "layout/SubNavbar";
import AddSupplierModal from "pages/Supplier/AddSupplierModal";
import queryString from "query-string";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { BsCircleFill } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  archiveOrders,
  bulkCancelInvitationOrders,
  bulkCancelOrders,
  bulkDeleteOrders,
  bulkDuplicateOrders,
  downloadOrdersAsCSV,
  getOrders,
} from "services/order.services";
// import { getSubTiersList } from "services/subtiers.services";
import LimitReached from "components/UI/LimitReached";
import { getOrderAgents } from "services/agent.services";
import { getArticleTypeList } from "services/article-types.services";
import { getArticles } from "services/article.services";
import { getOrderRetailers } from "services/retailer.services";
import { getSeasonList } from "services/season.services";
import { getOrderSuppliers } from "services/supplier.services";
import {
  setActionableCompanyType,
  toggleIsInvitationCreateModalOpen,
} from "store/accountDashboardSlice";
import { toggleIsAgentCreateModalOpen } from "store/agentSlice";
import { setRefetchBillingData } from "store/billingSlice";
import {
  setAllOrderData,
  setOrderList,
  setSelectedOrderItemData,
  setShowArticleForm,
  setShowTemplateForm,
  toggleActionableOrderId,
  toggleArtcileReachedModal,
  toggleBulkDeleteModal,
  toggleDeleteModal,
  toggleDuplicateAction,
  toggleInviteReachedModal,
  toggleIsOrderCreateModalOpen,
  toggleIsOrderEditable,
  toggleIsUploadBulkOrderModalOpen,
  toggleOrderCancleInvitationModal,
  toggleOrderCancleModal,
  toggleReachedOrderModal,
  updateOrderSliceState,
} from "store/orderSlice";
import { toggleIsRetailerCreateModalOpen } from "store/retailerSlice";
import { toggleIsSupplierCreateModalOpen } from "store/supplierSlice";
import {
  OrderStausList,
  formatDateToDayMonthYear,
  getInitials,
  removeQueryParam,
  transpercencyPercentageColors,
  truncateString,
} from "utils/helper";
import AddOrder from "./AddOrder";
import UploadBulkOrder from "./BulkOrder/UploadBulkOrder";
import OrderFilters from "./OrderFilters";
const Order = () => {
  const { hasPermission, checkPlanLimitation } = usePermission();
  const { workspaceId, status } = useParams();
  const { showCustomToast } = useCustomToaster();
  const { refetchBillingData, workspacePlan } = useSelector((state) => state.billingSlice);
  const { isSidebarCollapsed } = useSelector((state) => state.navbarSlice);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [articleTypeList, setArticleTypeList] = useState([]);
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [selectedOrderItems, setSelectedOrdersItems] = useState([]);
  const [searchKey, setSearchKey] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [isAddSupplier, setIsAddSupplier] = useState(false);
  const [activeFilters, setActiveFilters] = useState({});
  const [filteredData, setFilteredData] = useState({});
  const [orderOptions, setOrderOptions] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const queryClient = useQueryClient();
  const open = Boolean(anchorEl);
  const parsed = queryString.parse(location.search);
  const [companyList, setCompanyList] = useState([]);
  const [seasonList, setSeasonList] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState(OrderStausList[0].value);
  const [disabledButtons, setDisabledButtons] = useState({
    isCancelOrderDisabled: false,
    isCancelInviteDisabled: false,
    isRemoveDisabled: false,
  });
  const { user, currentWorkspace, subscription, subscription_status } = useSelector(
    (state) => state.authenticationSlice,
  );
  const { isRetailerCreateModalOpen } = useSelector((state) => state.retailerSlice);
  const { isSupplierCreateModalOpen } = useSelector((state) => state.supplierSlice);
  const { isAgentCreateModalOpen } = useSelector((state) => state.agentSlice);
  const { actionableCompanyType, isInvitationCreateModalOpen } = useSelector(
    (state) => state.accountDashboardSlice,
  );

  const [isHoveredDuplicate, setIshoveredDuplicate] = useState(false);
  const [isHoveredDelete, setIshoveredDelete] = useState(false);
  const [ishoveredCancelInvite, setIshoveredCancelInvite] = useState(false);
  const [ishoveredCancelOrder, setIshoveredCancelOrder] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const openMenu = (event) => {
    if (
      (currentWorkspace &&
        currentWorkspace.is_owner &&
        (!subscription ||
          (subscription && (!subscription_status || subscription_status === "canceled")))) ||
      !workspacePlan
    ) {
      setShowUpgradeModal(true);
    } else if (checkPlanLimitation && !checkPlanLimitation.canCreateOrder) {
      dispatch(toggleReachedOrderModal());
    } else {
      setMenuAnchorEl(event.currentTarget);
    }
    // Anchor the menu to the button
  };

  const closeMenu = () => {
    setMenuAnchorEl(null);
  };

  const isMenuOpen = Boolean(menuAnchorEl);

  const {
    isOrderCreateModalOpen,
    actionableOrderId,
    deleteModal,
    cancleInvitationModal,
    cancleOrderModal,
    orderList,
    allOrderData,
    isUploadBulkOrderModalOpen,
    order,
    isDuplicateAction,
    isOrderEditable,
    bulkDeleteModal,
    reachedOrderModal,
    selectedOrderItemData,
    artcileReachedModal,
    inviteReachedModal,
    showArticleForm,
    showTemplateForm,
    showCreateTemplateForm,
    refetchRetailerData,
    refetchAgentData,
    refetchSupplierData,
  } = useSelector((state) => state.orderSlice);
  const handleClose = () => {
    setAnchorEl(null);
    if (actionableOrderId) {
      dispatch(toggleActionableOrderId());
    }
  };

  const {
    data: ordersData,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: [
      "orders",
      "getOrders",
      selectedStatus,
      page,
      size,
      searchKey,
      orderBy,
      workspaceId,
      filteredData,
    ],
    queryFn: () =>
      getOrders({
        ...parsed,
        workspace_id: parseInt(workspaceId),
        page,
        per_page: size,
        search: searchKey,
        sort: orderBy,
        status: selectedStatus ? [selectedStatus] : "",
        partner_id: parsed.partner_id ? [parseInt(parsed.partner_id)] : "",
        ...filteredData,
      }),
    enabled: !!workspaceId || !!page || !!size || !!searchKey || !orderBy,
  });

  const {
    data: ordersNopaginatedData,
    isLoading: isOrdersNopaginatedData,
    refetch: refetchNopaginatedData,
  } = useQuery({
    queryKey: ["ordernopaginate", "getData", workspaceId],
    queryFn: () =>
      getOrders({
        workspace_id: parseInt(workspaceId),
        pagination: "no",
      }),
    enabled: !!workspaceId,
  });

  const {
    data: articleListQuery,
    isLoading: artcileLoading,
    status: artcileStatus,
    refetch: articleListQueryRefetch,
  } = useQuery({
    queryKey: ["articles", "getArticleList", workspaceId],
    queryFn: () => getArticles({ pagination: "no", workspace_id: parseInt(workspaceId) }),
    enabled: !!workspaceId,
  });

  useEffect(() => {
    if (!isOrdersNopaginatedData && ordersNopaginatedData) {
      if (ordersNopaginatedData?.data?.orders) {
        setOrderOptions(ordersNopaginatedData?.data?.orders);
      }
    }
  }, [ordersNopaginatedData, isOrdersNopaginatedData]);

  const openCreateModal = () => {
    dispatch(toggleIsOrderCreateModalOpen());
    setMenuAnchorEl(null);
    if (actionableOrderId) {
      dispatch(toggleActionableOrderId());
    }
    setMenuAnchorEl(null);
  };

  const handleSizeChange = (newSize) => {
    setSize(newSize);
    setPage(1);
  };

  const handleActionClick = (item) => {
    dispatch(toggleActionableOrderId(item.id));
    dispatch(setSelectedOrderItemData(item));
    setSelectedOrders([]);
    setSelectedOrdersItems([]);
  };

  const openUploadBulkOrderModal = () => {
    dispatch(toggleIsUploadBulkOrderModalOpen());
    setMenuAnchorEl(null);
  };

  const closeModal = () => {
    if (isUploadBulkOrderModalOpen) {
      dispatch(toggleIsUploadBulkOrderModalOpen());
    }
    if (isOrderCreateModalOpen) {
      dispatch(toggleIsOrderCreateModalOpen());
    }
    if (actionableOrderId) {
      dispatch(toggleActionableOrderId());
    }

    if (deleteModal) {
      dispatch(toggleDeleteModal());
    }

    if (cancleInvitationModal) {
      dispatch(toggleOrderCancleInvitationModal());
    }

    if (cancleOrderModal) {
      dispatch(toggleOrderCancleModal());
    }
    if (isDuplicateAction) {
      dispatch(toggleDuplicateAction());
    }
    if (bulkDeleteModal) {
      dispatch(toggleBulkDeleteModal());
    }
    if (isAddSupplier) {
      setIsAddSupplier(false);
    }
    if (reachedOrderModal) {
      dispatch(toggleReachedOrderModal());
    }
    if (showUpgradeModal) {
      setShowUpgradeModal(false);
    }
    if (isRetailerCreateModalOpen) {
      dispatch(toggleIsRetailerCreateModalOpen());
    }
    if (isSupplierCreateModalOpen) {
      dispatch(toggleIsSupplierCreateModalOpen());
    }
    if (isAgentCreateModalOpen) {
      dispatch(toggleIsAgentCreateModalOpen());
    }
    if (actionableCompanyType) {
      dispatch(setActionableCompanyType(null));
    }
    if (artcileReachedModal) {
      dispatch(toggleArtcileReachedModal());
    }
    if (showArticleForm) {
      dispatch(setShowArticleForm(false));
    }
    if (inviteReachedModal) {
      dispatch(toggleInviteReachedModal());
    }
    if (isInvitationCreateModalOpen) {
      dispatch(toggleIsInvitationCreateModalOpen());
    }
    if (showTemplateForm) {
      dispatch(setShowTemplateForm(false));
    }
    if (showCreateTemplateForm) {
      dispatch(updateOrderSliceState({ showCreateTemplateForm: false }));
    }
  };
  const handleViewDetailsClick = (id) => {
    navigate(`/workspace/${workspaceId}/orders/${id}`, { replace: true });
  };

  const showHideActions = (status, itemWorkspaceId, item) => {
    const orderId = item.id;
    let viewButton = null;
    let deleteButton = null;
    let cancelButton = null;
    let editButton = null;
    let archiveButton = null;
    let linkSupplierButton = null;
    switch (status) {
      case "draft":
        editButton =
          currentWorkspace &&
            currentWorkspace.role_name.includes("supplier") &&
            currentWorkspace.invited_workspace_type === "agent"
            ? null
            : hasPermission("updateOrder") && (
              <EditButton
                title="Edit"
                onClick={(e) => {
                  e.stopPropagation();
                  setAnchorEl(null);
                  handleClickOptionButton(orderId, "edit");
                }}
                shortcut="⌘+E"
                className={"w-[236px]"}
              />
            );
        deleteButton =
          currentWorkspace &&
            currentWorkspace.role_name.includes("supplier") &&
            currentWorkspace.invited_workspace_type === "agent"
            ? null
            : hasPermission("deleteOrder") &&
            parseInt(user?.workspace_id) === parseInt(itemWorkspaceId) && (
              <DeleteButton
                title="Remove"
                onClick={(e) => {
                  e.stopPropagation();
                  setAnchorEl(null);
                  handleClickOptionButton(orderId, "delete");
                }}
                shortcut="⌘+Delete"
                className={"w-[236px]"}
              />
            );
        break;
      case "invited":
        cancelButton =
          currentWorkspace &&
            currentWorkspace.role_name.includes("supplier") &&
            currentWorkspace.invited_workspace_type === "agent"
            ? null
            : hasPermission("cancelOrder") &&
            parseInt(user?.workspace_id) === parseInt(itemWorkspaceId) && (
              <CancelButton
                title="Cancel Invite"
                onClick={(e) => {
                  e.stopPropagation();
                  setAnchorEl(null);
                  handleClickOptionButton(orderId, "cancelInvitation");
                }}
                className={"w-[236px]"}
                shortcut="⌘+Shift+C"
              />
            );
        break;
      case "active":
        viewButton =
          currentWorkspace &&
            currentWorkspace.role_name.includes("supplier") &&
            parseInt(user?.workspace_id) === parseInt(itemWorkspaceId) ? (
            <ViewButton
              title="Update Tiers"
              onClick={(e) => {
                e.stopPropagation();
                setAnchorEl(null);
                handleViewDetailsClick(orderId);
              }}
              shortcut="⌘+Shift+V"
              className={"w-[236px]"}
              icon={
                <LinkIcon
                  width={16}
                  height={16}
                  fillColor={"var(--color-icon-default-4)"}
                  strokeColor={"var(--color-icon-default-4)"}
                />
              }
            />
          ) : (
            <ViewButton
              title="View"
              onClick={(e) => {
                e.stopPropagation();
                setAnchorEl(null);
                handleViewDetailsClick(orderId);
              }}
              shortcut="⌘+Shift+V"
              className={"w-[236px]"}
              isNotPartner={true}
            />
          );
        cancelButton = hasPermission("cancelOrder") &&
          parseInt(user?.workspace_id) === parseInt(itemWorkspaceId) && (
            <CancelButton
              title="Cancel Order"
              onClick={(e) => {
                e.stopPropagation();
                handleClickOptionButton(orderId, "cancel");
              }}
              className={"w-[236px]"}
              shortcut="⌘+Shift+C"
            />
          );
        archiveButton = hasPermission("deleteOrder") &&
          parseInt(user?.workspace_id) === parseInt(itemWorkspaceId) && (
            <ArchiveButton
              title="Archive Order"
              onClick={(e) => {
                e.stopPropagation();
                setAnchorEl(null);
                handleArchiveOrder(orderId);
              }}
              shortcut="⌘+Shift+O"
              className={"w-[236px]"}
            />
          );
        break;
      case "pending":
        viewButton =
          currentWorkspace &&
            currentWorkspace.role_name.includes("supplier") &&
            parseInt(user?.workspace_id) !== parseInt(itemWorkspaceId) ? (
            <ViewButton
              title="Update Tiers"
              onClick={(e) => {
                e.stopPropagation();
                setAnchorEl(null);
                handleViewDetailsClick(orderId);
              }}
              shortcut="⌘+Shift+V"
              className={"w-[236px]"}
              icon={
                <LinkIcon
                  width={16}
                  height={16}
                  fillColor={"var(--color-icon-default-4)"}
                  strokeColor={"var(--color-icon-default-4)"}
                />
              }
            />
          ) : (
            <ViewButton
              title="View"
              onClick={(e) => {
                e.stopPropagation();
                setAnchorEl(null);
                handleViewDetailsClick(orderId);
              }}
              shortcut="⌘+Shift+V"
              className={"w-[236px]"}
              isNotPartner={true}
            />
          );
        cancelButton =
          currentWorkspace &&
            currentWorkspace.role_name.includes("supplier") &&
            currentWorkspace.invited_workspace_type === "agent"
            ? null
            : hasPermission("cancelOrder") &&
            parseInt(user?.workspace_id) === parseInt(itemWorkspaceId) && (
              <CancelButton
                title="Cancel Order"
                onClick={(e) => {
                  e.stopPropagation();
                  handleClickOptionButton(orderId, "cancel");
                }}
                className={"w-[236px]"}
                shortcut="⌘+Shift+C"
              />
            );
        linkSupplierButton = currentWorkspace &&
          currentWorkspace.role_name.includes("agent") &&
          ((!item.invites && !item.supplier) ||
            (item.invites && !item.invites.supplier_invitation && !item.supplier)) && (
            <LinkSupplier
              title="Link Supplier"
              onClick={(e) => {
                e.stopPropagation();
                setAnchorEl(null);
                handleClickOptionButton(orderId, "link_supplier");
              }}
              // shortcut="⌘+Shift+V"
              className={"w-[236px]"}
              icon={
                <LinkIcon
                  width={16}
                  height={16}
                  fillColor={"var(--color-icon-default-4)"}
                  strokeColor={"var(--color-icon-default-4)"}
                />
              }
            />
          );
        break;
      case "completed":
        viewButton =
          currentWorkspace &&
            currentWorkspace.role_name.includes("supplier") &&
            parseInt(user?.workspace_id) !== parseInt(itemWorkspaceId) ? (
            <ViewButton
              title="Update Tiers"
              onClick={(e) => {
                e.stopPropagation();
                setAnchorEl(null);
                handleViewDetailsClick(orderId);
              }}
              shortcut="⌘+Shift+V"
              className={"w-[236px]"}
              icon={
                <LinkIcon
                  width={16}
                  height={16}
                  fillColor={"var(--color-icon-default-4)"}
                  strokeColor={"var(--color-icon-default-4)"}
                />
              }
            />
          ) : (
            <ViewButton
              title="View"
              onClick={(e) => {
                e.stopPropagation();
                setAnchorEl(null);
                handleViewDetailsClick(orderId);
              }}
              shortcut="⌘+Shift+V"
              className={"w-[236px]"}
              isNotPartner={true}
            />
          );
        break;
      default:
        break;
    }
    return {
      viewButton,
      deleteButton,
      cancelButton,
      editButton,
      archiveButton,
      linkSupplierButton,
    };
  };

  const handleClickOptionButton = (orderId, actionType) => {
    switch (actionType) {
      case "edit":
        dispatch(toggleActionableOrderId(orderId));
        dispatch(toggleIsOrderEditable());
        dispatch(toggleIsOrderCreateModalOpen());
        setAnchorEl(null);
        break;
      case "cancel":
        dispatch(toggleActionableOrderId(orderId));
        dispatch(toggleOrderCancleModal());
        setAnchorEl(null);
        break;
      case "cancelInvitation":
        dispatch(toggleActionableOrderId(orderId));
        dispatch(toggleOrderCancleInvitationModal());
        setAnchorEl(null);
        break;
      case "duplicate":
        if (
          (currentWorkspace &&
            currentWorkspace.is_owner &&
            (!subscription ||
              (subscription && (!subscription_status || subscription_status === "canceled")))) ||
          !workspacePlan
        ) {
          setShowUpgradeModal(true);
        } else if (checkPlanLimitation && !checkPlanLimitation.canCreateOrder) {
          dispatch(toggleReachedOrderModal());
        } else {
          dispatch(toggleActionableOrderId(orderId));
          dispatch(toggleDuplicateAction());
          if (!isOrderEditable) {
            dispatch(toggleIsOrderEditable());
          }
          dispatch(toggleIsOrderCreateModalOpen());
        }

        setAnchorEl(null);
        break;
      case "delete":
        dispatch(toggleActionableOrderId(orderId));
        dispatch(toggleDeleteModal());
        setAnchorEl(null);
        break;
      case "link_supplier":
        setAnchorEl(null);
        dispatch(toggleActionableOrderId(orderId));
        setIsAddSupplier(true);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (!isLoading && ordersData) {
      dispatch(setOrderList({ orderList: ordersData?.data?.orders?.data, append: false }));
      dispatch(setAllOrderData({ allOrderData: ordersData?.data?.orders, append: false }));
    }
  }, [ordersData, isLoading]);

  const onClickCheckbox = (orderItem) => {
    if (parseInt(user?.workspace_id) !== parseInt(orderItem?.created_by_user?.own_workspace_id))
      return;
    setSelectedOrders((prevSelectedOrders) =>
      prevSelectedOrders.includes(orderItem.id)
        ? prevSelectedOrders.filter((id) => id !== orderItem.id)
        : [...prevSelectedOrders, orderItem.id],
    );
    setSelectedOrdersItems((prevSelectedOrdersItems) =>
      prevSelectedOrdersItems.includes(orderItem)
        ? prevSelectedOrdersItems.filter((item) => item.id !== orderItem.id)
        : [...prevSelectedOrdersItems, orderItem],
    );
  };

  useEffect(() => {
    if (selectedOrderItems.length > 0) {
      getDisabledButtons();
    }
  }, [selectedOrderItems]); // Runs whenever selectedOrderItems changes

  const getDisabledButtons = () => {
    const selectedStatuses = selectedOrderItems.map((order) => order.status.name);
    const uniqueStatuses = new Set(selectedStatuses);
    setDisabledButtons({
      isCancelOrderDisabled: ["cancelled", "completed", "archived", "draft"].some((status) =>
        uniqueStatuses.has(status),
      ),
      isCancelInviteDisabled: ["cancelled", "active", "completed", "archived", "draft", "pending"].some(
        (status) => uniqueStatuses.has(status),
      ),
      isRemoveDisabled: ["active", "completed", "pending"].some((status) => uniqueStatuses.has(status)),
    });
  };

  const refetchDatas = () => {
    refetchNopaginatedData();
    refetch();
  };
  //all-mutations
  const deleteAllMutation = useMutation({
    mutationKey: ["order", "delete"],
    mutationFn: (data) => bulkDeleteOrders(data),
    onMutate: () => {
      showCustomToast(
        {
          title: "Deleting...",
        },
        "deleteOrder",
      );
    },
    onSuccess: () => {
      toast.dismiss("deleteOrder");
      showCustomToast({
        title: "Order deleted successfully",
      });
      closeModal();
      if (!refetchBillingData) {
        dispatch(setRefetchBillingData(true));
      }
      refetchDatas();
      setSelectedOrders([]);
      setSelectedOrdersItems([]);
    },
    onError: (error) => {
      toast.dismiss("deleteOrder");
      showCustomToast({
        title: error.response.data.message || error.message,
      });
    },
    onSettled: () => {
      toast.dismiss("deleteOrder");
    },
  });

  const duplicateMutation = useMutation({
    mutationKey: ["Order", "duplicate"],
    mutationFn: (data) => bulkDuplicateOrders(data),
    onMutate: () => {
      showCustomToast(
        {
          title: "Duplicating...",
        },
        "duplicateOrder",
      );
    },
    onSuccess: () => {
      toast.dismiss("duplicateOrder");
      showCustomToast({
        title: "Order duplicated successfully",
      });
      refetchDatas();
      setSelectedOrders([]);
      setSelectedOrdersItems([]);
      if (!refetchBillingData) {
        dispatch(setRefetchBillingData(true));
      }
      navigate(`/workspace/${workspaceId}/orders`);
      queryClient.invalidateQueries(["order", "get", "duplicate"]);
    },
    onError: (error) => {
      toast.dismiss("duplicateOrder");
      showCustomToast({
        title: error.response.data.message || error.message,
      });
    },
    onSettled: () => {
      toast.dismiss("duplicateOrder");
    },
  });

  const archiveMutation = useMutation({
    mutationKey: ["OrderArchive", "archive"],
    mutationFn: (data) => archiveOrders(data),
    onMutate: () => {
      showCustomToast(
        {
          title: "Archiving...",
        },
        "archiveOrder",
      );
    },
    onSuccess: () => {
      toast.dismiss("archiveOrder");
      showCustomToast({
        title: "Order archived successfully",
      });
      refetchDatas();
      setSelectedOrders([]);
      setSelectedOrdersItems([]);
      if (!refetchBillingData) {
        dispatch(setRefetchBillingData(true));
      }
      navigate(`/workspace/${workspaceId}/orders`);
      queryClient.invalidateQueries(["order", "get", "archive"]);
    },
    onError: (error) => {
      toast.dismiss("archiveOrder");
      showCustomToast({
        title: error.response.data.message || error.message,
      });
    },
    onSettled: () => {
      toast.dismiss("archiveOrder");
    },
  });

  const cancelInvitation = useMutation({
    mutationKey: ["inviation", "cancel-invitation"],
    mutationFn: (data) => bulkCancelInvitationOrders(data),
    onMutate: () => {
      showCustomToast(
        {
          title: "Cancelling...",
        },
        "cancelOrder",
      );
    },
    onSuccess: (res) => {
      toast.dismiss("cancelOrder");
      if (cancleInvitationModal) {
        dispatch(toggleOrderCancleInvitationModal());
      }
      if (actionableOrderId) {
        dispatch(toggleActionableOrderId());
      }
      showCustomToast({
        title: "Invitation cancelled successfully",
      });
      refetchDatas();
      setSelectedOrders([]);
      setSelectedOrdersItems([]);
      if (!refetchBillingData) {
        dispatch(setRefetchBillingData(true));
      }
    },
    onError: (error) => {
      toast.dismiss("cancelOrder");
      showCustomToast({
        title: error.message || "Something went wrong",
      });
    },
  });

  const cancelOrderMutation = useMutation({
    mutationKey: ["order", "cancel-order"],
    mutationFn: (data) => bulkCancelOrders(data),
    onMutate: () => {
      showCustomToast(
        {
          title: "Cancelling...",
        },
        "cancelOrder",
      );
    },
    onSuccess: (res) => {
      toast.dismiss("cancelOrder");

      showCustomToast({
        title: "Order cancelled successfully",
      });
      if (cancleOrderModal) {
        dispatch(toggleOrderCancleModal());
      }
      if (actionableOrderId) {
        dispatch(toggleActionableOrderId());
      }
      if (!refetchBillingData) {
        dispatch(setRefetchBillingData(true));
      }
      refetchDatas();
      setSelectedOrders([]);
      setSelectedOrdersItems([]);
    },
    onError: (error) => {
      toast.dismiss("cancelOrder");
      showCustomToast({
        title: error.message || "Something went wrong",
      });
    },
  });

  const downloadOrdersCSV = useMutation({
    mutationKey: ["ordersDownload", "downloadOrders"],
    mutationFn: (data) => downloadOrdersAsCSV(data),
    onMutate: () => {
      showCustomToast(
        {
          title: "Downloading...",
        },
        "downloadOrders",
      );
    },
    onSuccess: (res) => {
      toast.dismiss("downloadOrders");
      showCustomToast({
        title: "Downloaded successfully",
      });
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "orders.csv");
      document.body.appendChild(link);
      link.click();
      link.remove();
      queryClient.invalidateQueries(["ordersDownload", "downloadOrders"]);
    },
    onError: (error) => {
      toast.dismiss("downloadOrders");
      showCustomToast({
        title: error.response.data.message || error.message,
      });
    },
  });

  const handleDuplicateOrder = (orderId) => {
    if (
      (currentWorkspace &&
        currentWorkspace.is_owner &&
        (!subscription ||
          (subscription && (!subscription_status || subscription_status === "canceled")))) ||
      !workspacePlan
    ) {
      setShowUpgradeModal(true);
    } else if (checkPlanLimitation && !checkPlanLimitation.canCreateOrder) {
      dispatch(toggleReachedOrderModal());
    } else {
      duplicateMutation.mutate({ orders: orderId, workspace_id: parseInt(workspaceId) });
    }
  };

  const handleArchiveOrder = (orderId) => {
    archiveMutation.mutate({ order_id: orderId, workspace_id: parseInt(workspaceId) });
  };

  const handleCancleOrder = (orderId) => {
    cancelOrderMutation.mutate({ orders: orderId });
  };

  const handleCancelInvitation = (orderId) => {
    cancelInvitation.mutate({ orders: orderId });
  };

  const handleDeleteOrder = () => {
    deleteAllMutation.mutate({ orders: selectedOrders });
  };

  const handleSelectAll = (e) => {
    if (!e.target.checked || !orderList?.length > 0) {
      setSelectedOrders([]);
      setSelectedOrdersItems([]);
      return;
    }
    const orderItems =
      orderList.length > 0 &&
      orderList
        .filter(
          (item) =>
            parseInt(item?.created_by_user?.own_workspace_id) === parseInt(user?.workspace_id),
        )
        ?.map((order) => order);
    const orderIds =
      orderList.length > 0 &&
      orderList
        .filter(
          (item) =>
            parseInt(item?.created_by_user?.own_workspace_id) === parseInt(user?.workspace_id),
        )
        ?.map((order) => order.id);
    setSelectedOrdersItems(orderItems);
    setSelectedOrders(orderIds);
  };

  const handleOnCancleOrder = () => {
    if (!actionableOrderId && !order) return;
    cancelOrderMutation.mutate({ orders: [actionableOrderId] });
  };

  const handleOnCancleInvitation = () => {
    if (!actionableOrderId && !order) return;
    cancelInvitation.mutate({ orders: [actionableOrderId] });
  };

  const handleOnDelete = () => {
    if (!actionableOrderId && !order) return;
    deleteAllMutation.mutate({ orders: [actionableOrderId] });
  };

  const handleTableRowClick = (status, orderId) => {
    if (status === "draft") {
      return hasPermission("updateOrder") && handleClickOptionButton(orderId, "edit");
    } else if (status === "active" || status === "completed" || status === "pending") {
      return handleViewDetailsClick(orderId);
    }
    return;
  };

  const handleSearch = (e) => {
    const searchText = e.target.value?.trim();
    if (searchText.length >= 2) {
      setSearchKey(searchText);
    } else {
      setSearchKey("");
    }
  };

  const handleStatusChange = (statusValue) => {
    setSelectedStatus(statusValue);
    setSelectedOrders([]);
    setSelectedOrdersItems([]);
    removeQueryParam(["status"], location, navigate);
  };

  useEffect(() => {
    if (parsed.status) {
      setSelectedStatus(parsed.status);
      setSelectedOrders([]);
      setSelectedOrdersItems([]);
    }
  }, [parsed.status]);

  const handleDownloadCSV = () => {
    downloadOrdersCSV.mutate({ workspace_id: parseInt(workspaceId), download: "yes" });
  };

  const getTitle = () => {
    if (selectedStatus === "active") {
      return "Active Orders";
    } else if (selectedStatus === "pending") {
      return "Pending Orders";
    } else if (selectedStatus === "draft") {
      return "Draft Orders";
    } else if (selectedStatus === "completed") {
      return "Completed Orders";
    } else if (selectedStatus === "archived") {
      return "Archived Orders";
    } else if (selectedStatus === "cancelled") {
      return "Cancelled Orders";
    } else {
      return "Orders";
    }
  };

  const openBulkDeleteModal = (e) => {
    e.stopPropagation();
    setAnchorEl(null);
    dispatch(toggleBulkDeleteModal());
  };

  const [supplierList, setSupplierList] = useState([]);
  const [agentList, setAgentList] = useState([]);
  const [retailerList, setRetailerList] = useState([]);

  const [filteredSupplierList, setFilteredSupplierList] = useState([]);
  const [filteredAgentList, setFilteredAgentList] = useState([]);
  const [filteredRetailerList, setFilteredRetailerList] = useState([]);

  const { data: seasonListDataQuery } = useQuery({
    queryKey: ["seasons", "getSeasonList", workspaceId],
    queryFn: getSeasonList,
    enabled: !!workspaceId,
  });

  const { data: articleTypesQuery, isLoading: isArticleTypeListLoading } = useQuery({
    queryKey: ["articleTypes", "getList", workspaceId],
    queryFn: getArticleTypeList,
    enabled: !!workspaceId,
  });

  useEffect(() => {
    if (!isArticleTypeListLoading && articleTypesQuery && articleTypesQuery?.data?.article_type) {
      const updatedArticleTypeList = articleTypesQuery?.data?.article_type?.map((item) => ({
        value: item.id,
        label: item.name,
      }));
      setArticleTypeList(updatedArticleTypeList);
    }
  }, [articleTypesQuery, isArticleTypeListLoading]);

  useEffect(() => {
    if (seasonListDataQuery && seasonListDataQuery?.data?.status) {
      const modifiedList = seasonListDataQuery?.data?.seasons?.map((season) => ({
        label: `${season.name} (${season.year})`,
        value: season.id,
      }));
      setSeasonList(modifiedList);
    }
  }, [seasonListDataQuery]);

  const {
    data: agentListQuery,
    isLoading: agentQueryIsLoading,
    status: agentListQueryStatus,
  } = useQuery({
    queryKey: ["createUpdateOrderQueries", "getAgentList", refetchAgentData, workspaceId],
    queryFn: () => getOrderAgents({ pagination: "no", workspace_id: parseInt(workspaceId) }),
    enabled:
      !!workspaceId &&
      !!currentWorkspace &&
      !["agent", "supplier"].includes(currentWorkspace?.role_name?.split(".")[0]),
  });

  const {
    data: supplierListQuery,
    isLoading: isSupplierLoading,
    status: supplierQueryStatus,
  } = useQuery({
    queryKey: ["createUpdateOrderQueries", "getSupplierList", refetchSupplierData, workspaceId],
    queryFn: () => getOrderSuppliers({ pagination: "no", workspace_id: parseInt(workspaceId) }),
    enabled:
      !!workspaceId &&
      !!currentWorkspace &&
      !["supplier"].includes(currentWorkspace?.role_name?.split(".")[0]),
  });

  const {
    data: retailerListQuery,
    isLoading: isRetailerListQueryLoading,
    status: retailerQueryStatus,
  } = useQuery({
    queryKey: ["createUpdateOrderQueries", "getRetailersList", refetchRetailerData, workspaceId],
    queryFn: () => getOrderRetailers({ pagination: "no", workspace_id: parseInt(workspaceId) }),
    enabled:
      !!workspaceId &&
      !!currentWorkspace &&
      !["retailer", "importer", "brand"].includes(currentWorkspace?.role_name?.split(".")[0]),
  });

  useEffect(() => {
    if (
      !agentQueryIsLoading &&
      agentListQuery &&
      agentListQueryStatus === "success" &&
      agentListQuery.data
    ) {
      const dataList = agentListQuery?.data?.data;
      if (dataList && Array.isArray(dataList) && dataList.length > 0) {
        const agentList = dataList.map((item) => ({
          value: item?.pending ? item.invitation_id : item.workspace_id,
          label: item?.company_name,
          company_type: "agent",
        }));
        setAgentList(agentList);
        const filteredList = dataList
          ?.filter((item) => !item?.pending)
          ?.map((item) => ({
            value: item.workspace_id,
            label: item?.company_name,
            company_type: "agent",
          }));
        setFilteredAgentList(filteredList);
      }
    }
  }, [agentListQuery, agentQueryIsLoading, agentListQueryStatus]);

  useEffect(() => {
    if (supplierListQuery && !isSupplierLoading && supplierQueryStatus === "success") {
      if (supplierListQuery.data) {
        const result = supplierListQuery.data.data;
        if (result && Array.isArray(result) && result.length > 0) {
          const supplierList = result?.map((item) => ({
            value: item?.pending ? item.invitation_id : item.workspace_id,
            label: item?.company_name,
            company_type: "supplier",
          }));
          setSupplierList(supplierList);
          const filteredList = result
            ?.filter((item) => !item?.pending)
            ?.map((item) => ({
              value: item?.workspace_id,
              label: item?.company_name,
              company_type: "supplier",
            }));
          setFilteredSupplierList(filteredList);
        }
      }
    }
  }, [supplierListQuery, isSupplierLoading, supplierQueryStatus]);

  useEffect(() => {
    if (
      !isRetailerListQueryLoading &&
      retailerListQuery &&
      retailerQueryStatus === "success" &&
      retailerListQuery.data
    ) {
      const dataList = retailerListQuery?.data?.data;
      if (dataList && Array.isArray(dataList) && dataList.length > 0) {
        const retailerList = dataList?.map((item) => ({
          value: item?.pending ? item.invitation_id : item.workspace_id,
          label: item?.company_name,
          company_type: "retailer",
        }));
        setRetailerList(retailerList);
        const filteredList = dataList
          ?.filter((item) => !item?.pending)
          ?.map((item) => ({
            value: item?.workspace_id,
            label: item?.company_name,
            company_type: "retailer",
          }));
        setFilteredRetailerList(filteredList);
      }
    }
  }, [retailerListQuery, isRetailerListQueryLoading, retailerQueryStatus]);

  const autoSelectPartnerBulk = (currentWorkspaceData) => {
    if (
      !currentWorkspaceData ||
      typeof currentWorkspaceData != "object" ||
      currentWorkspaceData.is_owner
    ) {
      return;
    }
    const { invited_workspace_id, invited_workspace_name, invited_workspace_type } =
      currentWorkspaceData;
    const workspaceRolePrefix = currentWorkspaceData?.role_name?.split(".")[0];
    /**
     * Check if the user is not the owner and the invited workspace type is supplier, retailer, importer, or brand
     * and i am agent who is creating order for the supplier, retailer, importer, or brand
     * on that time auto select the partner according the visited workspace type
     */
    if (workspaceRolePrefix && workspaceRolePrefix === "agent") {
      if (["retailer", "importer", "brand"].includes(invited_workspace_type)) {
        const data = {
          value: invited_workspace_id,
          label: invited_workspace_name,
          company_type: "retailer",
        };
        setRetailerList([data]);
        setFilteredRetailerList([data]);
      }
      if (invited_workspace_type === "supplier") {
        const data = {
          value: invited_workspace_id,
          label: invited_workspace_name,
          company_type: "supplier",
        };
        setSupplierList([data]);
        setFilteredSupplierList([data]);
      }
    }
    /**
     * Check if the user is not the owner and the invited workspace type is supplier or agent
     * and i am retailer, importer, brand who is creating order for the supplier or agent
     * on that time auto select the partner according the visited workspace type
     */
    if (workspaceRolePrefix && ["retailer", "importer", "brand"].includes(workspaceRolePrefix)) {
      if (invited_workspace_type === "agent") {
        const data = {
          value: invited_workspace_id,
          label: invited_workspace_name,
          company_type: "agent",
        };
        setAgentList([data]);
        setFilteredAgentList([data]);
      }
      if (invited_workspace_type === "supplier") {
        const data = {
          value: invited_workspace_id,
          label: invited_workspace_name,
          company_type: "supplier",
        };
        setSupplierList([data]);
        setFilteredSupplierList([data]);
      }
    }

    /**
     * Check if the user is not the owner and the invited workspace type is retailer, importer, or brand
     * and i am supplier who is creating order for the  retailer, importer, or brand
     * on that time auto select the partner according the visited workspace type
     */
    if (workspaceRolePrefix && ["supplier"].includes(workspaceRolePrefix)) {
      if (["retailer", "importer", "brand"].includes(invited_workspace_type)) {
        const data = {
          value: invited_workspace_id,
          label: invited_workspace_name,
          company_type: "retailer",
        };
        setRetailerList([data]);
        setFilteredRetailerList([data]);
      }
    }
  };

  useEffect(() => {
    if (currentWorkspace) {
      autoSelectPartnerBulk(currentWorkspace);
    }
  }, [currentWorkspace, workspaceId, refetchSupplierData, refetchAgentData, refetchRetailerData]);

  useEffect(() => {
    if (user && currentWorkspace) {
      const handleKeyDown = (e) => {
        const isMac = e.metaKey; // `metaKey` for Cmd on Mac
        const isWindows = e.ctrlKey; // `ctrlKey` for Ctrl on Windows
        if ((isMac || isWindows) && e.shiftKey && e.key.toLowerCase() === "v") {
          if (actionableOrderId) {
            e.preventDefault();
            setAnchorEl(null);
            const status = selectedOrderItemData?.status?.name;
            if (status === "active" || status === "pending" || status === "completed") {
              handleViewDetailsClick(actionableOrderId);
            }
          }
        }
        if (
          currentWorkspace.role_name.includes("supplier") &&
          currentWorkspace.invited_workspace_type === "agent"
        ) {
          return;
        } else {
          if (
            (e.ctrlKey || e.metaKey) &&
            (e.key === "B" || e.key === "b") &&
            hasPermission("canAddOrder")
          ) {
            e.preventDefault();
            setAnchorEl(null);
            setMenuAnchorEl(null);
            if (
              (currentWorkspace &&
                currentWorkspace.is_owner &&
                (!subscription ||
                  (subscription &&
                    (!subscription_status || subscription_status === "canceled")))) ||
              !workspacePlan
            ) {
              setShowUpgradeModal(true);
            } else if (checkPlanLimitation && !checkPlanLimitation.canCreateOrder) {
              dispatch(toggleReachedOrderModal());
            } else {
              dispatch(toggleIsOrderCreateModalOpen());
              if (actionableOrderId) {
                dispatch(toggleActionableOrderId());
              }
              setShowUpgradeModal(false);
            }
          }
          if (
            (e.ctrlKey || e.metaKey) &&
            (e.key === "U" || e.key === "u") &&
            hasPermission("canAddOrder")
          ) {
            e.preventDefault();
            setAnchorEl(null);
            setMenuAnchorEl(null);
            if (
              (currentWorkspace &&
                currentWorkspace.is_owner &&
                (!subscription ||
                  (subscription &&
                    (!subscription_status || subscription_status === "canceled")))) ||
              !workspacePlan
            ) {
              setShowUpgradeModal(true);
            } else if (checkPlanLimitation && !checkPlanLimitation.canCreateOrder) {
              dispatch(toggleReachedOrderModal());
            } else {
              dispatch(toggleIsUploadBulkOrderModalOpen());
              if (actionableOrderId) {
                dispatch(toggleActionableOrderId());
              }
            }
          }
          if (e.key === "Delete" && hasPermission("deleteOrder")) {
            e.preventDefault();
            setAnchorEl(null);
            if (actionableOrderId && selectedOrderItemData) {
              const status = selectedOrderItemData?.status?.name;
              const itemId = selectedOrderItemData?.created_by_user?.own_workspace_id;
              if (
                (status === "draft" || status === "cancelled") &&
                parseInt(user?.workspace_id) === parseInt(itemId)
              ) {
                handleClickOptionButton(actionableOrderId, "delete");
              }
            } else if (selectedOrders && selectedOrders.length > 0) {
              dispatch(toggleBulkDeleteModal());
            }
          }
          if (
            (isMac || isWindows) &&
            e.shiftKey &&
            e.key.toLowerCase() === "c" &&
            hasPermission("cancelOrder")
          ) {
            if (actionableOrderId) {
              e.preventDefault();
              setAnchorEl(null);
              const status = selectedOrderItemData?.status?.name;
              const itemId = selectedOrderItemData?.created_by_user?.own_workspace_id;
              if (parseInt(user?.workspace_id) === parseInt(itemId)) {
                if (status === "invited") {
                  dispatch(toggleOrderCancleInvitationModal());
                } else if (status === "active" || status === "pending") {
                  dispatch(toggleOrderCancleModal());
                }
              }
            }
          }
          if (
            (isMac || isWindows) &&
            e.shiftKey &&
            e.key.toLowerCase() === "o" &&
            hasPermission("deleteOrder")
          ) {
            if (actionableOrderId) {
              e.preventDefault();
              setAnchorEl(null);
              const status = selectedOrderItemData?.status?.name;
              const itemId = selectedOrderItemData?.created_by_user?.own_workspace_id;
              if (parseInt(user?.workspace_id) === parseInt(itemId)) {
                if (status === "active") {
                  handleArchiveOrder(actionableOrderId);
                }
              }
            }
          }
          if (
            (isMac || isWindows) &&
            (e.key === "D" || e.key === "d") &&
            hasPermission("duplicateOrder")
          ) {
            e.preventDefault();
            if (
              (currentWorkspace &&
                currentWorkspace.is_owner &&
                (!subscription ||
                  (subscription &&
                    (!subscription_status || subscription_status === "canceled")))) ||
              !workspacePlan
            ) {
              setShowUpgradeModal(true);
            } else if (checkPlanLimitation && !checkPlanLimitation.canCreateOrder) {
              dispatch(toggleReachedOrderModal());
            } else {
              if (actionableOrderId) {
                setAnchorEl(null);
                duplicateMutation.mutate({
                  orders: [actionableOrderId],
                  workspace_id: parseInt(workspaceId),
                });
              }
            }
          }
          if (
            (isMac || isWindows) &&
            (e.key === "E" || e.key === "e") &&
            hasPermission("updateOrder")
          ) {
            e.preventDefault();
            if (actionableOrderId) {
              setAnchorEl(null);
              const status = selectedOrderItemData?.status?.name;
              if (status === "draft") {
                dispatch(toggleIsOrderEditable());
                dispatch(toggleIsOrderCreateModalOpen());
              }
            }
          }
          if (
            (e.ctrlKey || e.metaKey) &&
            (e.key === "A" || e.key === "a") &&
            hasPermission("deleteOrder")
          ) {
            e.preventDefault();
            const eligibleTemplates = selectedOrders;
            const allChecked =
              eligibleTemplates?.length > 0 && selectedOrders.length === eligibleTemplates.length;
            const newSelectAllState = !allChecked;
            const checkboxEvent = { target: { checked: newSelectAllState } };
            handleSelectAll(checkboxEvent);
          }
        }
      };

      window.addEventListener("keydown", handleKeyDown);
      return () => {
        window.removeEventListener("keydown", handleKeyDown);
      };
    }
  }, [user, currentWorkspace, dispatch, actionableOrderId, selectedOrders, handleSelectAll]);

  return (
    <>
      <Modal open={isOrderCreateModalOpen} close={closeModal}>
        <AddOrder
          closeModal={closeModal}
          refetchData={refetchDatas}
          {...{ articleListQueryRefetch }}
        />
      </Modal>
      <Modal open={isAddSupplier} close={closeModal}>
        <AddSupplierModal
          closeModal={closeModal}
          refetchData={refetch}
          orderId={actionableOrderId}
          orderNo={selectedOrderItemData && selectedOrderItemData?.order_number}
        />
      </Modal>
      <Modal open={bulkDeleteModal} close={closeModal}>
        <CommonDiscardButtonUpdated
          closeModal={closeModal}
          title={`Remove ${selectedOrders.length > 1 ? "orders" : "order"}`}
          subText1={`Are you sure you want to remove selected ${selectedOrders.length > 1 ? "orders" : "order"}? This action cannot be undone.`}
          className="w-full lg:max-w-[600px]"
          IconComponent={CompliancyIcon}
          confirmButtonText="Remove"
          confirmButtonVariant="warning"
          confirmButtonClassName={"w-full h-11"}
          onConfirmClick={handleDeleteOrder}
          icon={
            <TrashUpdateIcon
              width={20}
              height={20}
              strokeColor={"var(--color-icon-fill-secondary-button)"}
            />
          }
        />
      </Modal>
      <Modal open={isUploadBulkOrderModalOpen} close={closeModal}>
        <UploadBulkOrder
          refetch={refetchDatas}
          {...{
            closeModal,
            seasonList,
            articleListQuery,
            artcileLoading,
            artcileStatus,
            agentList: filteredAgentList,
            retailerList: filteredRetailerList,
            supplierList: filteredSupplierList,
          }}
        />
      </Modal>
      <Modal open={deleteModal} close={closeModal}>
        <CommonDiscardButtonUpdated
          closeModal={closeModal}
          title="Remove order"
          subText1="Are you sure you want to remove selected order? This action cannot be undone."
          className="w-full lg:max-w-[600px]"
          IconComponent={CompliancyIcon}
          confirmButtonText="Remove"
          confirmButtonVariant="warning"
          confirmButtonClassName={"w-full h-11"}
          onConfirmClick={handleOnDelete}
          icon={
            <TrashUpdateIcon
              width={20}
              height={20}
              strokeColor={"var(--color-icon-fill-secondary-button)"}
            />
          }
        />
      </Modal>
      <Modal open={cancleInvitationModal} close={closeModal}>
        <CommonDiscardButtonUpdated
          closeModal={closeModal}
          title="Cancel invitation?"
          subText1="Are you sure you want to cancel invitation? After canceling the invitation this order will saved as a draft."
          className="w-full lg:max-w-[600px]"
          IconComponent={CompliancyIcon}
          confirmButtonText="Remove"
          confirmButtonVariant="warning"
          confirmButtonClassName={"w-full h-11"}
          onConfirmClick={handleOnCancleInvitation}
          icon={
            <TrashUpdateIcon
              width={20}
              height={20}
              strokeColor={"var(--color-icon-fill-secondary-button)"}
            />
          }
        />
      </Modal>
      <Modal open={cancleOrderModal} close={closeModal}>
        <CommonDiscardButtonUpdated
          closeModal={closeModal}
          title="Cancel order?"
          subText1="Are you sure you want to cancel selected order? This action cannot be undone."
          className="w-full lg:max-w-[600px]"
          IconComponent={CompliancyIcon}
          confirmButtonText="Remove"
          confirmButtonVariant="warning"
          confirmButtonClassName={"w-full h-11"}
          onConfirmClick={handleOnCancleOrder}
          icon={
            <TrashUpdateIcon
              width={20}
              height={20}
              strokeColor={"var(--color-icon-fill-secondary-button)"}
            />
          }
        />
      </Modal>
      <Modal open={reachedOrderModal} close={closeModal}>
        <LimitReached
          closeModal={closeModal}
          IconComponent={Action}
          title="Order limit reached"
          subText1={`You cannot add a new order as you have reached the maximum limit of ${workspacePlan && workspacePlan?.package?.order_lines} orders in your ${workspacePlan && workspacePlan?.package?.name?.toUpperCase()} plan. Please reduce your usage or upgrade your plan.`}
          className={"w-full lg:w-[600px]"}
          linked={workspacePlan && workspacePlan?.orders_count}
          totalLink={workspacePlan && workspacePlan?.package?.order_lines}
          linkedtitle={"Orders"}
          confirmButtonText={currentWorkspace && !currentWorkspace?.is_owner ? null : "Ugrade Plan"}
          confirmButtonVariant={"primary"}
          confirmButtonClassName={"w-full h-11"}
          onConfirmClick={() => {
            navigate(`/workspace/${currentWorkspace?.user_workspace_id}/setting?selectedTab=plan`);
            if (reachedOrderModal) {
              dispatch(toggleReachedOrderModal());
            }
          }}
          icon={
            <Shock width={20} height={20} strokeColor={"var(--color-icon-fill-secondary-button)"} />
          }
        />
      </Modal>
      <Modal open={showUpgradeModal} close={closeModal}>
        <CommonDiscardButtonUpdated
          closeModal={closeModal}
          title="Upgrade plan"
          subText1={
            currentWorkspace && !currentWorkspace?.is_owner && !workspacePlan
              ? `Your partner workspace is currently on a free plan.`
              : `You are currently on a free plan. Upgrade to a paid plan to start creating your own ${user && user?.workspace_type} workspaces.`
          }
          className="max-w-[544px]"
          confirmButtonClassName="w-full h-11"
          IconComponent={PlanIcon}
          confirmButtonText={
            currentWorkspace && !currentWorkspace?.is_owner && !workspacePlan ? null : "Ugrade Plan"
          }
          confirmButtonVariant="primary"
          onConfirmClick={() => {
            navigate(`/workspace/${currentWorkspace?.user_workspace_id}/setting?selectedTab=plan`);
            if (showUpgradeModal) {
              setShowUpgradeModal(false);
            }
          }}
          icon={
            <Shock width={20} height={20} strokeColor={"var(--color-icon-fill-secondary-button)"} />
          }
        />
      </Modal>
      <TopNavbar isFromDashboard={false}>
        <BreadCrumb textAnother={"My Orders"} text={selectedStatus === "" ? "All" : getTitle()} />
      </TopNavbar>
      <div className="px-4 md:px-8   pt-[64px] md:pt-[108px]">
        <div className="block md:hidden pt-4">
          <BreadCrumb textAnother={"My Orders"} text={selectedStatus === "" ? "All" : getTitle()} />
        </div>
        <div className="pt-4 md:pt-6">
          <SubNavbar title="My Orders" subtitle="Manage your orders and their attributes here." />
        </div>
        <div className="bg-global-divider-medium h-[1px] my-5  hidden md:block" />
        <div className="overflow-x-auto">
          <div className="mb-1 flex items-center justify-between bg-transparent border border-border-divider-stroke   rounded-md font-semibold w-[627px] whitespace-nowrap mt-4 lg:mt-0">
            {OrderStausList.map((status) => (
              <button
                key={status.value}
                className={`w-full flex justify-center items-center text-center text-sm px-4 py-2 h-9 rounded-md ${selectedStatus === status.value
                  ? "bg-white border border-border-primary text-gray-900 custom-card  "
                  : "bg-transparent border-none text-gray-700"
                  }`}
                onClick={() => handleStatusChange(status.value)}
              >
                {status.label}
              </button>
            ))}
          </div>
        </div>

        <div className="pt-4">
          <div className="bg-btn-surface-secondary border border-border-divider-stroke  rounded-xl custom-card w-full  mb-6 ">
            <SearchCreatePanelUpdate
              permissionText="canAddOrder"
              permissionText2="deleteOrder"
              createTxt={
                currentWorkspace &&
                  currentWorkspace.role_name.includes("supplier") &&
                  currentWorkspace.invited_workspace_type === "agent"
                  ? ""
                  : "Add Order"
              }
              title={getTitle()}
              handleDownloadCSV={handleDownloadCSV}
              selectedItems={selectedOrders}
              openDeleteModal={openBulkDeleteModal}
              isDisabled={disabledButtons.isRemoveDisabled}
              btnText={allOrderData && allOrderData.total}
              onBtnClick={openMenu}
              onChange={handleSearch}
              classWidth="w-full md:w-[122px] whitespace-nowrap"
            >
              <OrderFilters
                workspaceId={workspaceId}
                partnerList={[...agentList, ...retailerList]}
                {...{
                  activeFilters,
                  setActiveFilters,
                  filteredData,
                  setFilteredData,
                  orderOptions,
                  setOrderOptions,
                  companyList,
                  setCompanyList,
                  seasonList,
                  articleListQuery,
                  artcileLoading,
                  artcileStatus,
                  articleTypeList,
                  supplierList,
                }}
              />
            </SearchCreatePanelUpdate>
            <div className="z-10 ">
              <Menu
                id="action-menu"
                anchorEl={menuAnchorEl}
                open={isMenuOpen}
                onClose={closeMenu}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                PaperProps={{
                  style: {
                    maxHeight: 45 * 4.5,
                    border: "1px solid var(--color-border-secondary)",
                    padding: 0,
                    marginTop: "4px",
                    backgroundColor: "transparent",
                    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.05)",
                    justifyContent: "center",
                    borderRadius: "8px",
                  },
                }}
              >
                <MoreActionOptionUpdated
                  createButton={
                    <CreateButton
                      title="Create new"
                      onClick={(e) => {
                        e.stopPropagation();
                        openCreateModal();
                      }}
                      shortcut="⌘+B"
                      className={"w-[236px]"}
                    />
                  }
                  uploadButton={
                    <UploadButton
                      title="Upload"
                      onClick={(e) => {
                        e.stopPropagation();
                        openUploadBulkOrderModal();
                      }}
                      shortcut="⌘+U"
                      className={"w-[236px]"}
                    />
                  }
                />
              </Menu>
            </div>

            <div className="w-full max-w-screen overflow-x-auto ">
              <table className="w-full border-spacing-0 table-auto rounded-table-custom overflow-x-auto">
                <thead className="text-sm text-left whitespace-nowrap text-global-paragraph">
                  <tr className="border-b  border-t border-b-global-divider-soft border-t-global-divider-soft">
                    <th className="pl-6 pr-4 py-[11.5px]">
                      <div className="flex items-center gap-3">
                        <input
                          type="checkbox"
                          className={`rounded-md  w-5 h-5 ${orderList && orderList.length > 0 ? "cursor-pointer" : "cursor-default"}`}
                          onChange={handleSelectAll}
                          checked={
                            orderList?.filter(
                              (item) =>
                                parseInt(item?.created_by_user?.own_workspace_id) ===
                                parseInt(user?.workspace_id),
                            ).length > 0 &&
                            selectedOrders.length ===
                            orderList?.filter(
                              (item) =>
                                parseInt(item?.created_by_user?.own_workspace_id) ===
                                parseInt(user?.workspace_id),
                            ).length
                          }
                        />
                        <div className="flex items-center">
                          <span className="mr-1 text-xs font-medium text-global-paragraph leading-[18px]">
                            Order Number
                          </span>
                          <SortIcons
                            hasData={orderList && orderList.length > 0}
                            sortName="order_number"
                            {...{ orderBy, setOrderBy }}
                          />
                        </div>
                      </div>
                    </th>

                    <th className="relative px-4  py-[11.5px]  ">
                      <div className="flex items-center">
                        <span className="mr-1 text-xs font-medium text-global-paragraph leading-[18px]">
                          Status
                        </span>
                        <SortIcons
                          hasData={orderList && orderList.length > 0}
                          sortName="status"
                          {...{ orderBy, setOrderBy }}
                        />
                      </div>
                    </th>

                    <th className="relative px-4 py-[11.5px]  ">
                      <div className="flex items-center">
                        <span className="mr-1 text-xs font-medium text-global-paragraph leading-[18px]">
                          Transparency %
                        </span>
                        <SortIcons
                          hasData={orderList && orderList.length > 0}
                          sortName="transparency_score"
                          {...{ orderBy, setOrderBy }}
                        />
                      </div>
                    </th>

                    <th className="relative px-4 py-[11.5px]  ">
                      <div className="flex items-center">
                        <span className="mr-1 text-xs font-medium text-global-paragraph leading-[18px]">
                          Vertification %
                        </span>
                        <SortIcons
                          hasData={orderList && orderList.length > 0}
                          sortName="verification_score"
                          {...{ orderBy, setOrderBy }}
                        />
                      </div>
                    </th>
                    <th className="relative px-4 py-[11.5px]  ">
                      <div className="flex items-center">
                        <span className="mr-1 text-xs font-medium text-global-paragraph leading-[18px]">
                          Partner Name
                        </span>
                        <SortIcons
                          hasData={orderList && orderList.length > 0}
                          sortName="company"
                          {...{ orderBy, setOrderBy }}
                        />
                      </div>
                    </th>
                    <th className="relative px-4 py-[11.5px]  ">
                      <div className="flex items-center">
                        <span className="mr-1 text-xs font-medium text-global-paragraph leading-[18px]">
                          Partner Type
                        </span>
                        <SortIcons
                          hasData={orderList && orderList.length > 0}
                          sortName="company_type"
                          {...{ orderBy, setOrderBy }}
                        />
                      </div>
                    </th>
                    <th className="relative px-4 py-[11.5px]  ">
                      <div className="flex items-center">
                        <span className="mr-1 text-xs font-medium text-global-paragraph leading-[18px]">
                          Order Date
                        </span>
                        <SortIcons
                          hasData={orderList && orderList.length > 0}
                          sortName="order_date"
                          {...{ orderBy, setOrderBy }}
                        />
                      </div>
                    </th>
                    <th className="relative px-4 py-[11.5px]  ">
                      <div className="flex items-center">
                        <span className="mr-1 text-xs font-medium text-global-paragraph leading-[18px]">
                          ETA
                        </span>
                        <SortIcons
                          hasData={orderList && orderList.length > 0}
                          sortName="order_eta"
                          {...{ orderBy, setOrderBy }}
                        />
                      </div>
                    </th>
                    <th className="relative px-4 py-[11.5px]  ">
                      <div className="flex items-center">
                        <span className="mr-1 text-xs font-medium text-global-paragraph leading-[18px]">
                          ETD
                        </span>
                        <SortIcons
                          hasData={orderList && orderList.length > 0}
                          sortName="order_etd"
                          {...{ orderBy, setOrderBy }}
                        />
                      </div>
                    </th>
                    <th className="relative px-4 py-[11.5px]  ">
                      <div className="flex items-center">
                        <span className="mr-1 text-xs font-medium text-global-paragraph leading-[18px]">
                          Season
                        </span>
                        <SortIcons
                          hasData={orderList && orderList.length > 0}
                          sortName="season"
                          {...{ orderBy, setOrderBy }}
                        />
                      </div>
                    </th>
                    <th className="relative px-4 py-[11.5px]  ">
                      <div className="flex items-center">
                        <span className="mr-1 text-xs font-medium text-global-paragraph leading-[18px]">
                          Quantity
                        </span>
                        <SortIcons
                          hasData={orderList && orderList.length > 0}
                          sortName="quantity"
                          {...{ orderBy, setOrderBy }}
                        />
                      </div>
                    </th>
                    {currentWorkspace && !currentWorkspace.role_name.includes("supplier") && (
                      <th className="relative px-4 py-[11.5px]  ">
                        <div className="flex items-center">
                          <span className="mr-1 text-xs font-medium text-global-paragraph leading-[18px]">
                            Supplier Name
                          </span>
                          <SortIcons
                            hasData={orderList && orderList.length > 0}
                            sortName="supplier"
                            {...{ orderBy, setOrderBy }}
                          />
                        </div>
                      </th>
                    )}
                    <th className="relative px-4 py-[11.5px]  ">
                      <div className="flex items-center">
                        <span className="mr-1 text-xs font-medium text-global-paragraph leading-[18px]">
                          Tier 4
                        </span>
                        <SortIcons
                          hasData={orderList && orderList.length > 0}
                          sortName="tier_4"
                          {...{ orderBy, setOrderBy }}
                        />
                      </div>
                    </th>
                    <th className="relative px-4 py-[11.5px]  ">
                      <div className="flex items-center">
                        <span className="mr-1 text-xs font-medium text-global-paragraph leading-[18px]">
                          Tier 3
                        </span>
                        <SortIcons
                          hasData={orderList && orderList.length > 0}
                          sortName="tier_3"
                          {...{ orderBy, setOrderBy }}
                        />
                      </div>
                    </th>
                    <th className="relative px-4 py-[11.5px]  ">
                      <div className="flex items-center">
                        <span className="mr-1 text-xs font-medium text-global-paragraph leading-[18px]">
                          Tier 2
                        </span>
                        <SortIcons
                          hasData={orderList && orderList.length > 0}
                          sortName="tier_2"
                          {...{ orderBy, setOrderBy }}
                        />
                      </div>
                    </th>
                    <th className="relative px-4 py-[11.5px]  ">
                      <div className="flex items-center">
                        <span className="mr-1 text-xs font-medium text-global-paragraph leading-[18px]">
                          Tier 1
                        </span>
                        <SortIcons
                          hasData={orderList && orderList.length > 0}
                          sortName="tier_1"
                          {...{ orderBy, setOrderBy }}
                        />
                      </div>
                    </th>

                    <th className="relative px-4 py-[11.5px]  ">
                      <div className="flex items-center">
                        <span className="mr-1 text-xs font-medium text-global-paragraph leading-[18px]">
                          Tier 0
                        </span>
                        <SortIcons
                          hasData={orderList && orderList.length > 0}
                          sortName="tier_0"
                          {...{ orderBy, setOrderBy }}
                        />
                      </div>
                    </th>

                    <th className="relative px-4 py-[11.5px]  ">
                      <div className="flex items-center">
                        <span className="mr-1 text-xs font-medium text-global-paragraph leading-[18px]">
                          Article No.
                        </span>
                        <SortIcons
                          hasData={orderList && orderList.length > 0}
                          sortName="article_name"
                          {...{ orderBy, setOrderBy }}
                        />
                      </div>
                    </th>
                    <th className="relative px-4 py-[11.5px]  ">
                      <div className="flex items-center">
                        <span className="mr-1 text-xs font-medium text-global-paragraph leading-[18px]">
                          Article Type
                        </span>
                        <SortIcons
                          hasData={orderList && orderList.length > 0}
                          sortName="article_type"
                          {...{ orderBy, setOrderBy }}
                        />
                      </div>
                    </th>

                    <th className="relative px-4 py-[11.5px]  ">
                      <div className="flex items-center">
                        <span className="mr-1 text-xs font-medium text-global-paragraph leading-[18px]">
                          Date Added
                        </span>
                        <SortIcons
                          hasData={orderList && orderList.length > 0}
                          sortName="created_at"
                          {...{ orderBy, setOrderBy }}
                        />
                      </div>
                    </th>

                    <th className="relative px-4 py-[11.5px]  ">
                      <div className="flex items-center">
                        <span className="mr-1 text-xs font-medium text-global-paragraph leading-[18px]">
                          Last Updated
                        </span>
                        <SortIcons
                          hasData={orderList && orderList.length > 0}
                          sortName="updated_at"
                          {...{ orderBy, setOrderBy }}
                        />
                      </div>
                    </th>
                    {currentWorkspace && !currentWorkspace.role_name.includes("supplier") && (
                      <th className="relative px-4 py-[11.5px]  ">
                        <div className="flex items-center">
                          <span className="mr-1 text-xs font-medium text-global-paragraph leading-[18px]">
                            Created by
                          </span>
                          <SortIcons
                            hasData={orderList && orderList.length > 0}
                            sortName="created_by"
                            {...{ orderBy, setOrderBy }}
                          />
                        </div>
                      </th>
                    )}

                    <th className="relative px-4 py-[11.5px]  ">
                      <div className="flex items-center">
                        <span className="mr-1"> </span>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {!isLoading && orderList && orderList?.length > 0 ? (
                    orderList?.map((item, index) => {
                      const {
                        viewButton,
                        deleteButton,
                        cancelButton,
                        editButton,
                        archiveButton,
                        linkSupplierButton,
                      } = showHideActions(
                        item?.status?.name,
                        item?.created_by_user?.own_workspace_id,
                        item,
                      );
                      return (
                        <tr
                          key={index}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleTableRowClick(item?.status?.name, item.id);
                          }}
                          className={`whitespace-nowrap border-b last:border-0 border-b-global-divider-soft  ${index % 2 === 0 ? "bg-global-white" : "bg-white"} rounded-lg text-global-paragraph text-sm font-normal ${item.status.name === "cancelled" ? "cursor-not-allowed" : "cursor-pointer"}`}
                        >
                          <td className="pl-6 pr-4 py-[15.5px]  ">
                            <div className="flex items-center gap-3">
                              <input
                                onChange={() => onClickCheckbox(item)}
                                onClick={(e) => e.stopPropagation()}
                                checked={
                                  selectedOrders.includes(item.id) &&
                                  parseInt(item?.created_by_user?.own_workspace_id) ===
                                  parseInt(user?.workspace_id)
                                }
                                type="checkbox"
                                className="rounded-md border cursor-pointer w-5 h-5  "
                              />
                              <p className="text-sm leading-5 font-medium text-global-title">
                                {item.order_number ? truncateString(item.order_number) : null}
                              </p>
                            </div>
                          </td>
                          <td className="relative px-4 py-[15.5px] ">
                            {item?.status ? (
                              <Button
                                variant={"status"}
                                className={`rounded-[6px] w-auto h-[22px] px-2 py-[2px]  border border-border-divider-stroke custom-card cursor-default capitalize   text-status-text-default  text-xs leading-[18px] font-medium `}
                              >
                                <BsCircleFill
                                  className={`w-1.5 h-1.5 ${item?.status?.name === "active" ? "text-global-primary" : "text-global-paragraph"}`}
                                />
                                {item?.status?.name}
                              </Button>
                            ) : null}
                          </td>
                          <td className="relative px-4 py-[15.5px] text-sm text-global-paragraph leading-5  ">
                            <div className="flex items-center gap-3 ">
                              <LineProgressBar
                                className="h-2 w-full lg:w-[50px]"
                                variant={transpercencyPercentageColors(
                                  item?.transparency ? item?.transparency?.total_score : 0,
                                )}
                                progress={item?.transparency ? item?.transparency?.total_score : 0}
                              />
                              <span>{`${item?.transparency ? item?.transparency?.total_score : 0}%`}</span>
                            </div>
                          </td>
                          <td className="relative px-4 py-[15.5px] text-sm text-global-paragraph leading-5  ">
                            <div className="flex items-center gap-3">
                              <LineProgressBar
                                className="h-2 w-full lg:w-[51px]"
                                variant={"blue"}
                                progress={
                                  item?.transparency
                                    ? item?.transparency?.total_verification_score
                                    : 0
                                }
                              />
                              <span>{`${item?.transparency ? item?.transparency?.total_verification_score : 0}%`}</span>
                            </div>
                          </td>
                          <td className="relative px-4 py-[15.5px] ">
                            {item.company && item?.company?.id === user?.workspace_id
                              ? item?.workspace?.name
                              : item?.company?.name
                                ? truncateString(item?.company?.name)
                                : null}
                          </td>
                          <td className="relative px-4 py-[15.5px] text-sm text-global-paragraph leading-5  ">
                            {item.company && item?.company?.id === user?.workspace_id ? (
                              <Button
                                variant={"status"}
                                className={`rounded-[6px] w-auto h-[22px] px-2 py-[2px]  border border-border-divider-stroke custom-card cursor-default capitalize   text-status-text-default  text-xs leading-[18px] font-medium `}
                              >
                                {item?.workspace?.purchased_package &&
                                  truncateString(item?.workspace?.purchased_package)}
                              </Button>
                            ) : item?.company?.purchased_package ? (
                              <Button
                                variant={"status"}
                                className={`rounded-[6px] w-auto h-[22px] px-2 py-[2px]  border border-border-divider-stroke custom-card cursor-default capitalize   text-status-text-default  text-xs leading-[18px] font-medium `}
                              >
                                {item.company.purchased_package &&
                                  truncateString(item.company.purchased_package)}
                              </Button>
                            ) : null}
                          </td>
                          <td className="relative px-4 py-[15.5px] text-sm text-global-paragraph leading-5  ">
                            {item?.order_date ? formatDateToDayMonthYear(item?.order_date) : null}
                          </td>
                          <td className="relative px-4 py-[15.5px] text-sm text-global-paragraph leading-5  ">
                            {item?.order_eta ? formatDateToDayMonthYear(item?.order_eta) : "N/A"}
                          </td>
                          <td className="relative px-4 py-[15.5px] text-sm text-global-paragraph leading-5  ">
                            {item?.order_etd ? formatDateToDayMonthYear(item?.order_etd) : "N/A"}
                          </td>
                          <td className="relative px-4 py-[15.5px] text-sm text-global-paragraph leading-5  ">
                            {item?.season ? (
                              <Button
                                variant={"status"}
                                className={`rounded-[6px] w-auto h-[22px] px-2 py-[2px]  border border-border-divider-stroke custom-card cursor-default capitalize   text-status-text-default  text-xs leading-[18px] font-medium `}
                              >
                                {item?.season?.name}
                              </Button>
                            ) : null}
                          </td>
                          <td className="relative px-4 py-[15.5px] ">
                            {item.quantity ? `${truncateString(item.quantity)} pcs` : null}
                          </td>
                          {currentWorkspace && !currentWorkspace.role_name.includes("supplier") && (
                            <td className="relative px-4 py-[15.5px] ">
                              {item.supplier && item?.supplier?.name
                                ? item.supplier.name && truncateString(item.supplier.name)
                                : item.workspace.purchased_package === "supplier"
                                  ? item.workspace.name && truncateString(item.workspace.name)
                                  : null}
                            </td>
                          )}
                          <td className="relative px-4 py-[15.5px] ">
                            {item?.transparency?.tier_4 ? (
                              <Button
                                variant={"status"}
                                className={
                                  " flex justify-center items-center gap-1 px-1.5 py-[2px]  h-[22px] text-global-gray-brand font-medium text-xs leading-[18px]  border border-border-divider-stroke custom-card cursor-default "
                                }
                              >
                                {item?.transparency?.tier_4_completed?.length ===
                                  item?.transparency?.tier_4?.length ? (
                                  <>
                                    <BsCircleFill className={`w-1.5 h-1.5 text-global-primary`} />
                                    Completed
                                  </>
                                ) : (
                                  <>
                                    {item?.transparency?.tier_4_completed
                                      ? item?.transparency?.tier_4_completed?.length
                                      : 0}
                                    /
                                    {item?.transparency?.tier_4
                                      ? item?.transparency?.tier_4?.length
                                      : 0}
                                  </>
                                )}
                              </Button>
                            ) : null}
                          </td>
                          <td className="relative px-4 py-[15.5px] ">
                            {item?.transparency?.tier_3 ? (
                              <Button
                                variant={"status"}
                                className={
                                  " flex justify-center items-center gap-1 px-1.5 py-[2px]  h-[22px] text-global-gray-brand font-medium text-xs leading-[18px]  border border-border-divider-stroke custom-card cursor-default "
                                }
                              >
                                {item?.transparency?.tier_3_completed?.length ===
                                  item?.transparency?.tier_3?.length ? (
                                  <>
                                    <BsCircleFill className={`w-1.5 h-1.5 text-global-primary`} />
                                    Completed
                                  </>
                                ) : (
                                  <>
                                    {item?.transparency?.tier_3_completed
                                      ? item?.transparency?.tier_3_completed?.length
                                      : 0}
                                    /
                                    {item?.transparency?.tier_3
                                      ? item?.transparency?.tier_3?.length
                                      : 0}
                                  </>
                                )}
                              </Button>
                            ) : null}
                          </td>
                          <td className="relative px-4 py-[15.5px] ">
                            {item?.transparency?.tier_2 ? (
                              <Button
                                variant={"status"}
                                className={
                                  " flex justify-center items-center gap-1 px-1.5 py-[2px]  h-[22px] text-global-gray-brand font-medium text-xs leading-[18px]  border border-border-divider-stroke custom-card cursor-default "
                                }
                              >
                                {item?.transparency?.tier_2_completed?.length ===
                                  item?.transparency?.tier_2?.length ? (
                                  <>
                                    <BsCircleFill className={`w-1.5 h-1.5 text-global-primary`} />
                                    Completed
                                  </>
                                ) : (
                                  <>
                                    {item?.transparency?.tier_2_completed
                                      ? item?.transparency?.tier_2_completed?.length
                                      : 0}
                                    /
                                    {item?.transparency?.tier_2
                                      ? item?.transparency?.tier_2?.length
                                      : 0}
                                  </>
                                )}
                              </Button>
                            ) : null}
                          </td>
                          <td className="relative px-4 py-[15.5px] ">
                            {item?.transparency?.tier_1 ? (
                              <Button
                                variant={"status"}
                                className={
                                  " flex justify-center items-center gap-1 px-1.5 py-[2px]  h-[22px] text-global-gray-brand font-medium text-xs leading-[18px]  border border-border-divider-stroke custom-card cursor-default "
                                }
                              >
                                {item?.transparency?.tier_1_completed?.length ===
                                  item?.transparency?.tier_1?.length ? (
                                  <>
                                    <BsCircleFill className={`w-1.5 h-1.5 text-global-primary`} />
                                    Completed
                                  </>
                                ) : (
                                  <>
                                    {item?.transparency?.tier_1_completed
                                      ? item?.transparency?.tier_1_completed?.length
                                      : 0}
                                    /
                                    {item?.transparency?.tier_1
                                      ? item?.transparency?.tier_1?.length
                                      : 0}
                                  </>
                                )}
                              </Button>
                            ) : null}
                          </td>
                          <td className="relative px-4 py-[15.5px] ">
                            {item?.transparency?.tier_0 ? (
                              <Button
                                variant={"status"}
                                className={
                                  " flex justify-center items-center gap-1 px-1.5 py-[2px]  h-[22px] text-global-gray-brand font-medium text-xs leading-[18px]  border border-border-divider-stroke custom-card cursor-default "
                                }
                              >
                                {item?.transparency?.tier_0_completed?.length ===
                                  item?.transparency?.tier_0?.length ? (
                                  <>
                                    <BsCircleFill className={`w-1.5 h-1.5 text-global-primary`} />
                                    Completed
                                  </>
                                ) : (
                                  <>
                                    {item?.transparency?.tier_0_completed
                                      ? item?.transparency?.tier_0_completed?.length
                                      : 0}
                                    /
                                    {item?.transparency?.tier_0
                                      ? item?.transparency?.tier_0?.length
                                      : 0}
                                  </>
                                )}
                              </Button>
                            ) : null}
                          </td>
                          <td className="relative px-4 py-[15.5px] ">
                            {item.article ? truncateString(item.article.sku) : null}
                          </td>
                          <td className="relative px-4 py-[15.5px] ">
                            {item?.article?.article_types
                              ? truncateString(item?.article?.article_types?.name)
                              : null}
                          </td>

                          <td className="relative px-4 py-[15.5px] text-sm text-global-paragraph leading-5  ">
                            {item?.created_at ? formatDateToDayMonthYear(item?.created_at) : null}
                          </td>
                          <td className="relative px-4 py-[15.5px] text-sm text-global-paragraph leading-5  ">
                            {item?.updated_at ? formatDateToDayMonthYear(item?.updated_at) : null}
                          </td>
                          {currentWorkspace && !currentWorkspace.role_name.includes("supplier") && (
                            <td className="relative px-4 py-[15.5px] text-sm text-global-paragraph leading-5  ">
                              <div className="flex justify-start items-center gap-3">
                                {item?.created_by_user && (
                                  <div className="flex bg-global-surface uppercase justify-center items-center text-xs font-semibold text-global-paragraph leading-6 w-8 h-8 ring-1 ring-global-divider-medium ring-opacity-75 ring-inset rounded-full border-none">
                                    {item?.created_by_user ? (
                                      item?.created_by_user?.image ? (
                                        <img
                                          src={item?.created_by_user?.image}
                                          className="w-full h-full object-center rounded-full"
                                          alt=""
                                        />
                                      ) : (
                                        getInitials(item?.created_by_user?.name)
                                      )
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                )}

                                <div className="flex flex-col">
                                  <p className="text-sm leading-5 font-normal text-global-title capitalize">
                                    {item?.created_by_user
                                      ? truncateString(item?.created_by_user?.name)
                                      : null}
                                  </p>
                                  <p className="text-sm text-global-gray-brand leading-5">
                                    {item?.created_by_user && item?.created_by_user?.email
                                      ? truncateString(item?.created_by_user?.email)
                                      : null}
                                  </p>
                                </div>
                              </div>
                            </td>
                          )}
                          <td
                            onClick={(e) => e.stopPropagation()}
                            className="relative cursor-pointer px-4 py-[15.5px] text-sm text-global-paragraph leading-5  "
                          >
                            <IconButton
                              aria-label="more"
                              id={`${index}`}
                              aria-controls={open ? "long-menu" : undefined}
                              aria-expanded={open ? "true" : undefined}
                              aria-haspopup="true"
                              onClick={(e) => {
                                e.stopPropagation();
                                handleActionClick(item);
                                setAnchorEl(e.currentTarget);
                              }}
                            >
                              <MoreOptions width={20} height={20} />
                            </IconButton>
                            {!isOrderCreateModalOpen &&
                              actionableOrderId &&
                              actionableOrderId === item?.id && (
                                <Menu
                                  id="long-menu"
                                  MenuListProps={{
                                    "aria-labelledby": "long-button",
                                  }}
                                  anchorEl={anchorEl}
                                  open={open}
                                  onClose={handleClose}
                                  anchorOrigin={{
                                    vertical: "top",
                                    horizontal: "left",
                                  }}
                                  elevation={0}
                                  transformOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                  }}
                                  PaperProps={{
                                    style: {
                                      maxHeight: 45 * 4.5,
                                      borderRadius: "8px",
                                      border: "1px solid var(--color-border-secondary)",
                                      padding: 0,
                                      backgroundColor: "transparent",
                                      boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.05)",
                                      justifyContent: "center",
                                    },
                                  }}
                                >
                                  <MoreActionOptionUpdated
                                    linkSupplierButton={linkSupplierButton}
                                    viewButton={viewButton}
                                    editButton={editButton}
                                    duplicateButton={
                                      currentWorkspace &&
                                        currentWorkspace.role_name.includes("supplier") &&
                                        currentWorkspace.invited_workspace_type === "agent"
                                        ? null
                                        : currentWorkspace &&
                                        currentWorkspace.is_owner &&
                                        hasPermission("duplicateOrder") &&
                                        parseInt(user?.workspace_id) ===
                                        parseInt(item?.created_by_user?.own_workspace_id) && (
                                          <DuplicateButton
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              setAnchorEl(null);
                                              handleDuplicateOrder([item.id]);
                                            }}
                                            shortcut="⌘+D"
                                            className={"w-[236px]"}
                                          />
                                        )
                                    }
                                    archiveButton={archiveButton}
                                    deleteButton={deleteButton}
                                    cancelButton={cancelButton}
                                  />
                                </Menu>
                              )}
                          </td>
                        </tr>
                      );
                    })
                  ) : isLoading ? (
                    <tr>
                      <td colSpan={11} className="text-center pb-3">
                        <LoadingComponent />
                      </td>
                    </tr>
                  ) : searchKey && orderList && orderList?.length === 0 ? (
                    <EmptyTable colSpan={22} text="Result not found" />
                  ) : (
                    <EmptyTable colSpan={22} />
                  )}
                </tbody>
              </table>
            </div>
            {allOrderData && allOrderData.data && allOrderData.total > 0 && (
              <TertiaryPagination
                page={page}
                totalPages={Math.ceil(allOrderData.total / size)}
                setPage={setPage}
                size={size}
                handleSizeChange={handleSizeChange}
              />
            )}
          </div>
        </div>

        {currentWorkspace &&
          currentWorkspace.role_name.includes("supplier") &&
          currentWorkspace.invited_workspace_type === "agent"
          ? null
          : selectedOrders.length > 0 &&
          hasPermission("deleteOrder") && (
            <div className="relative flex items-center justify-center mx-auto">
              <div
                className={`py-4 lg:py-3.5  mx-auto z-[49] flex md:gap-6  bg-[#FCFCFC] rounded-xl h-auto lg:min-h-[72px] fixed bottom-2 md:bottom-10 border border-global-divider-soft custom-modal-dropdown items-stretch lg:items-center  ${isSidebarCollapsed ? "w-[98%] md:w-[calc(98%_-_80px)] lg:w-[900px] " : "w-[98%] md:w-[calc(98%_-_296px)] lg:w-[calc(100%_-_296px)] lg:max-w-[900px]"}`}
              >
                <div className="flex-1 pl-8  mx-auto">
                  <div className=" flex  flex-col lg:flex-row  justify-between gap-4 items-center">
                    <div className="flex justify-between items-center gap-6">
                      <div className="text-base lg:text-xl font-semibold text-global-title leading-5 whitespace-nowrap">
                        {selectedOrders.length} Item Selected{" "}
                      </div>
                    </div>
                    <div className="flex justify-between items-center gap-3 lg:gap-6 flex-wrap">
                      <div className="flex flex-wrap items-center justify-center gap-3 lg:gap-3">
                        {currentWorkspace && currentWorkspace.is_owner && hasPermission("duplicateOrder") && (
                          <Button
                            className={`h-10 px-3.5 py-2.5 flex flex-row justify-center items-center gap-1 rounded-lg whitespace-nowrap `}
                            type="button"
                            variant="secondary"
                            onMouseEnter={() => setIshoveredDuplicate(true)}
                            onMouseLeave={() => setIshoveredDuplicate(false)}
                            onClick={() => {
                              const totalCurrentCount =
                                parseInt(selectedOrders.length) +
                                parseInt(workspacePlan?.orders_count);
                              const limitCount = parseInt(workspacePlan?.package?.order_lines);
                              if (workspacePlan && totalCurrentCount > limitCount) {
                                dispatch(toggleReachedOrderModal());
                              } else {
                                handleDuplicateOrder(selectedOrders);
                              }
                            }}
                            isDisabled={duplicateMutation.isPending}
                          >
                            <div>
                              <Duplicate
                                strokeColor={
                                  isHoveredDuplicate
                                    ? "var(--color-icon-fill-secondary-button)"
                                    : "var(--color-txt-secondary)"
                                }
                                className="w-5 h-5"
                              />
                            </div>
                            <span className="text-sm font-semibold text-center leading-5">
                              Duplicate
                            </span>
                          </Button>
                        )}

                        <Button
                          className={`h-10 px-3.5 py-2.5 flex flex-row justify-center items-center gap-1 rounded-lg whitespace-nowrap `}
                          type="button"
                          variant="secondary"
                          onMouseEnter={() => setIshoveredCancelOrder(true)}
                          onMouseLeave={() => setIshoveredCancelOrder(false)}
                          isDisabled={
                            disabledButtons.isCancelOrderDisabled || cancelOrderMutation.isPending
                          }
                          onClick={() => {
                            handleCancleOrder(selectedOrders);
                          }}
                        >
                          <div>
                            <UTurnIcon
                              strokeColor={
                                ishoveredCancelOrder
                                  ? "var(--color-icon-fill-secondary-button)"
                                  : "var(--color-txt-secondary)"
                              }
                              className="w-5 h-5"
                            />
                          </div>
                          <span className="text-sm font-semibold text-center leading-5">
                            Cancel Order
                          </span>
                        </Button>
                        <Button
                          className={`h-10 px-3.5 py-2.5 flex flex-row justify-center items-center gap-1 rounded-lg whitespace-nowrap `}
                          type="button"
                          variant="secondary"
                          onMouseEnter={() => setIshoveredCancelInvite(true)}
                          onMouseLeave={() => setIshoveredCancelInvite(false)}
                          isDisabled={
                            disabledButtons.isCancelInviteDisabled || cancelInvitation.isPending
                          }
                          onClick={() => {
                            handleCancelInvitation(selectedOrders);
                          }}
                        >
                          <div>
                            <MailIcon
                              strokeColor={
                                ishoveredCancelInvite
                                  ? "var(--color-icon-fill-secondary-button)"
                                  : "var(--color-txt-secondary)"
                              }
                              fillColor={
                                ishoveredCancelInvite
                                  ? "var(--color-icon-fill-secondary-button)"
                                  : "var(--color-txt-secondary)"
                              }
                              className="w-5 h-5"
                            />
                          </div>
                          <span className="text-sm font-semibold text-center leading-5">
                            Cancel Invite
                          </span>
                        </Button>
                        <Button
                          className={`h-10 px-3.5 py-2.5 flex flex-row justify-center items-center gap-1 rounded-lg whitespace-nowrap `}
                          type="button"
                          variant="secondary"
                          onMouseEnter={() => setIshoveredDelete(true)}
                          onMouseLeave={() => setIshoveredDelete(false)}
                          onClick={handleDeleteOrder}
                          isDisabled={
                            disabledButtons.isRemoveDisabled || deleteAllMutation.isPending
                          }
                        >
                          <div>
                            <TrashUpdateIcon
                              strokeColor={
                                isHoveredDelete
                                  ? "var(--color-icon-fill-secondary-button)"
                                  : "var(--color-txt-secondary)"
                              }
                              className="w-5 h-5"
                            />
                          </div>
                          <span className="text-sm font-semibold text-center leading-5">
                            Remove
                          </span>
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={`pr-3 lg:pr-8 lg:pl-0`}>
                  <Cross
                    className="cursor-pointer"
                    width={24}
                    height={24}
                    fillColor={"#A4A7AE"}
                    onClick={handleSelectAll}
                  />
                </div>
              </div>
            </div>
          )}
      </div>
    </>
  );
};

export default Order;
