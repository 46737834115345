import React from "react";

const SendIcon = React.forwardRef(
  (
    {
      width = "20",
      height = "20",
      viewBox = "0 0 20 20",
      fill = "none",
      strokeColor = "white",
      className,
      ...props
    },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.74987 9.99982H4.16653M4.09599 10.2427L2.15022 16.055C1.99736 16.5116 1.92093 16.7399 1.97578 16.8805C2.02341 17.0026 2.12571 17.0952 2.25195 17.1304C2.39731 17.171 2.61687 17.0722 3.05598 16.8746L16.9822 10.6078C17.4108 10.4149 17.6251 10.3185 17.6913 10.1845C17.7489 10.0681 17.7489 9.93155 17.6913 9.81516C17.6251 9.68119 17.4108 9.58475 16.9822 9.39188L3.05112 3.12293C2.61334 2.92593 2.39444 2.82742 2.24922 2.86783C2.12311 2.90292 2.02082 2.99524 1.97302 3.11711C1.91798 3.25744 1.9936 3.48526 2.14482 3.94088L4.09653 9.8211C4.1225 9.89935 4.13549 9.93848 4.14062 9.9785C4.14517 10.014 4.14512 10.05 4.14048 10.0855C4.13525 10.1255 4.12216 10.1646 4.09599 10.2427Z"
          stroke={strokeColor}
          strokeWidth="1.66667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  },
);

export default SendIcon;
