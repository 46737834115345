const { createSlice } = require("@reduxjs/toolkit");

const userSlice = createSlice({
  name: "user",
  initialState: {
    isUserCreateModalOpen: false,
    actionableUserId: null,
    isUserEditable: false,
    isDuplicateAction: false,
    userList: [],
    userDetails: null,
    showDiscardModal: false,
    deleteModal: false,
    CancelButton: false,
    cancleModal: false,
    resendModal: false,
    updatedPasswordModal: false,
    allUserData: null,
    selectedUserId: null,
    showUpdateProfileModal: false,
    profileDeleteModalOpen: false,
    transferOwnershipModalOpen: false,
    workspaceList: [],
    selectedWorkspaces: []

  },
  reducers: {
    toggleIsUserCreateModalOpen: (state) => ({
      ...state,
      isUserCreateModalOpen: !state.isUserCreateModalOpen,
    }),
    toggleActionableUserId: (state, action) => ({
      ...state,
      actionableUserId: action.payload ?? null,
    }),
    toggleSelectedUserId: (state, action) => ({
      ...state,
      selectedUserId: action.payload ?? null,
    }),
    toggleIsUserEditable: (state) => ({ ...state, isUserEditable: !state.isUserEditable }),
    toggleShowDiscardModal: (state) => ({ ...state, showDiscardModal: !state.showDiscardModal }),
    toggleUserDeleteModal: (state) => {
      state.deleteModal = !state.deleteModal;
    },
    toggleUserCancleModal: (state) => {
      state.cancleModal = !state.cancleModal;
    },
    toggleUserResendModal: (state) => {
      state.resendModal = !state.resendModal;
    },
    togglePasswordModal: (state) => {
      state.updatedPasswordModal = !state.updatedPasswordModal;
    },
    toggleShowUpdateProfileModal: (state) => {
      state.showUpdateProfileModal = !state.showUpdateProfileModal;
    },
    toggleProfileDeleteModalOpen: (state) => {
      state.profileDeleteModalOpen = !state.profileDeleteModalOpen;
    },
    toggleTransferOwnershipModalOpen: (state) => {
      state.transferOwnershipModalOpen = !state.transferOwnershipModalOpen;
    },
    setUserList: (state, action) => {
      if (action.payload.append) {
        return {
          ...state,
          userList: [action.payload.user, ...state.userList],
        };
      } else {
        return {
          ...state,
          userList: action.payload.userList,
        };
      }
    },
    setWorkspaceListData: (state, action) => ({
      ...state,
      workspaceList: action.payload.workspaceList ?? null,
    }),
    setSelectedWorkspaces: (state, action) => ({
      ...state,
      selectedWorkspaces: action.payload ?? [],
    }),
    setAllUserData: (state, action) => ({
      ...state,
      allUserData: action.payload.allUserData ?? null,
    }),
    setUserDetails: (state, action) => ({
      ...state,
      userDetails: action.payload ?? null,
    }),
    setUserSliceState: (state, action) => ({
      ...state,
      ...action.payload,
    }),
  },
});

export const {
  toggleIsUserCreateModalOpen,
  toggleActionableUserId,
  toggleIsUserEditable,
  toggleShowDiscardModal,
  toggleUserDeleteModal,
  toggleUserCancleModal,
  toggleUserResendModal,
  togglePasswordModal,
  setUserList,
  setUserDetails,
  setUserSliceState,
  setAllUserData,
  toggleSelectedUserId,
  toggleShowUpdateProfileModal,
  toggleProfileDeleteModalOpen,
  toggleTransferOwnershipModalOpen,
  setWorkspaceListData,
  setSelectedWorkspaces
} = userSlice.actions;
export default userSlice.reducer;
