import { useMutation, useQueryClient } from "@tanstack/react-query";
import { DeleteProfile } from "assests";
import BackToDashboard from "components/UI/BackToDashboard";
import { Button } from "components/UI/Button";
import ChipButton from "components/UI/ChipButton";
import CommonCard from "components/UI/CommonCard";
import CommonDiscardButton from "components/UI/CommonDiscardButton";
import Divider from "components/UI/Divider";
import Modal from "components/UI/Modal";
import useCustomToaster from "hooks/useCustomToaster";
import useProfileSettings from "hooks/useProfileSettings";
import SubNavbar from "layout/SubNavbar";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { deleteUserProfile } from "services/user.services";
import { clearAuthData } from "store/authenticationSlice";
import {
  toggleProfileDeleteModalOpen,
  toggleShowUpdateProfileModal,
  toggleTransferOwnershipModalOpen,
} from "store/userSlice";
import ChangePasswordForm from "./ChangePasswordForm";
import Editprofile from "./Editprofile";
import TransferOwnershipForm from "./TransferOwnershipForm";
import VerifyUpdatedEmail from "./VerifyUpdatedEmail";
import { useEffect } from "react";
import SuccessCardEmailVerified from "./SuccessCardEmailVerified";
import TopNavbar from "components/TopHeader/TopNavbar";
import BreadCrumb from "components/TopHeader/BreadCrumb";
import { removeQueryParam } from "utils/helper";
const Settings = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { user } = useSelector((state) => state.authenticationSlice);
  const { showUpdateProfileModal, profileDeleteModalOpen, transferOwnershipModalOpen } =
    useSelector((state) => state.userSlice);
  const { showCustomToast } = useCustomToaster();
  const {
    reset,
    setValue,
    isPending,
    onError,
    getValues,
    errors,
    register,
    handleSubmit,
    clearErrors,
    control,
    isDragOver,
    handleDragEnter,
    handleDragLeave,
    handleDrop,
    onFileDropOrChange,
    imagePreviews,
    timeLeft,
    setTimeLeft,
    OTP,
    setOTP,
    isButtonDisabled,
    setIsButtonDisabled,
    showSuccessEmailUpdated,
    setShowSuccessEmailUpdated,
    successMsgtimeLeft,
    setSuccessMsgtimeLeft,
    setDefaultFormValue,
  } = useProfileSettings();

  const closeModal = () => {
    if (profileDeleteModalOpen) {
      dispatch(toggleProfileDeleteModalOpen());
    }
    if (showUpdateProfileModal) {
      setDefaultFormValue(user);
      dispatch(toggleShowUpdateProfileModal());
    }
    if (transferOwnershipModalOpen) {
      dispatch(toggleTransferOwnershipModalOpen());
    }
    removeQueryParam(["selectedTab"], location, navigate);
  };

  const handleUpdateProfileModal = (actionType) => {
    switch (actionType) {
      case "edit":
        dispatch(toggleShowUpdateProfileModal());
        break;
      case "delete":
        dispatch(toggleProfileDeleteModalOpen());
        break;
      case "transfer":
        dispatch(toggleTransferOwnershipModalOpen());
        break;
      default:
        break;
    }
  };

  const handleDeleteProfile = (e) => {
    e.preventDefault();
    e.stopPropagation();
    deleteUserProfileMutation.mutate();
  };
  const handleLogOut = () => {
    dispatch(clearAuthData());
    dispatch({ type: "LOGOUT" });
    return <Navigate to="/signin" />;
  };

  const deleteUserProfileMutation = useMutation({
    mutationKey: ["userProfileDelete", "delete"],
    mutationFn: deleteUserProfile,
    onMutate: () => {
      showCustomToast(
        {
          title: "Deleting...",
        },
        "userProfileDelete",
      );
    },
    onSuccess: () => {
      toast.dismiss("userProfileDelete");
      showCustomToast({
        title: "User deleted successfully",
      });
      closeModal();
      handleLogOut();
      queryClient.invalidateQueries(["userProfileDelete", "get", "delete"]);
    },
    onError: (error) => {
      toast.dismiss("userProfileDelete");
      showCustomToast({
        title: error.response.data.message || error.message,
      });
    },
    onSettled: () => {
      toast.dismiss("userProfileDelete");
    },
  });

  const getRoleDisplay = (isOwner, role) => {
    if (isOwner) {
      return "Owner";
    }
    if (role) {
      if (role.includes(".admin")) {
        return "Admin";
      } else if (role.includes(".editor")) {
        return "Editor";
      } else if (role.includes(".viewer")) {
        return "Viewer";
      } else {
        return role;
      }
    }
  };

  const handleCloseSuccessEmailVerified = () => {
    setShowSuccessEmailUpdated(false);
    setSuccessMsgtimeLeft(0);
  };

  useEffect(() => {
    if (successMsgtimeLeft > 0) {
      const timer = setInterval(() => {
        setSuccessMsgtimeLeft((prevTime) => prevTime - 1);
      }, 1000);
      return () => clearInterval(timer);
    } else {
      setShowSuccessEmailUpdated(false);
    }
  }, [successMsgtimeLeft]);

  return (
    <>
      <div className="w-full overflow-hidden pt-0  pb-16">
        <div className="grid grid-cols-12 justify-start items-center gap-3 md:gap-6">
          <div className="col-span-12">
            <div className="w-full lg:w-[520px]">
              <CommonCard
                onClick={() => handleUpdateProfileModal("edit")}
                btnText={"Edit details"}
                minHeight={143}
                className="justify-between items-start md:items-center"
              >
                <div className="flex flex-col justify-start items-center gap-4 md:gap-6">
                  <div className="justify-between items-start flex w-full">
                    <div className="pt-[3px] flex-col justify-start items-start gap-1 inline-flex">
                      <div className="justify-start items-center gap-2 inline-flex">
                        <div className="text-global-title text-base md:text-lg font-semibold leading-7">
                          {user?.name}
                        </div>
                        {user?.role && (
                          <ChipButton childrenClassName="text-sm lg:text-base">{getRoleDisplay(user?.is_owner, user?.role)}</ChipButton>
                        )}
                      </div>
                      <div className=" text-global-paragraph text-sm font-normal  leading-5">
                        {user?.email}{" "}
                        <span className="font-bold ">
                          (
                          {user?.email_verified_at !== null || user?.email_verified_at !== ""
                            ? "verified"
                            : "unverified"}
                          )
                        </span>{" "}
                        <br /> {user?.telephone && `+${user?.telephone}`} <br />{" "}
                        {user?.job_title !== null &&
                          user?.job_title !== "null" &&
                          user?.job_title !== "" &&
                          user?.job_title}
                      </div>
                    </div>
                  </div>
                </div>
                <div className=" flex justify-center items-center">
                  {user && user?.image ? (
                    <div className=" flex justify-center items-center  p-0 ring-1 ring-global-divider-soft ring-inset rounded-full border-none text-btn-text-default bg-transparent">
                      <img
                        src={`${user?.image}?t=${new Date().getTime()}`}
                        alt={`Profile`}
                        className="rounded-full w-12 h-12 md:h-16 md:w-16"
                      />
                    </div>
                  ) : (
                    <div className=" flex bg-global-surface justify-center items-center text-xl md:text-2xl uppercase font-semibold text-text-quaternary w-12 h-12 md:h-16 md:w-16 p-0 ring-1 ring-global-divider-soft ring-inset rounded-full border-none">
                      {user &&
                        `${user?.first_name?.substring(0, 1)}${user?.last_name?.substring(0, 1)}`}
                    </div>
                  )}
                </div>
              </CommonCard>
            </div>
          </div>
          {!showSuccessEmailUpdated && user && user.temp_email && (
            <div className="col-span-12">
              <div className="w-full lg:w-[520px]">
                <VerifyUpdatedEmail
                  {...{
                    timeLeft,
                    setTimeLeft,
                    OTP,
                    setOTP,
                    isButtonDisabled,
                    setIsButtonDisabled,
                    setShowSuccessEmailUpdated,
                    setSuccessMsgtimeLeft,
                  }}
                />
              </div>
            </div>
          )}
          {showSuccessEmailUpdated && (
            <div className="col-span-12">
              <div className="w-full lg:w-[520px]">
                <SuccessCardEmailVerified {...{ handleCloseSuccessEmailVerified }} />
              </div>
            </div>
          )}
        </div>
        <Divider className={"bg-global-divider-medium my-4 md:my-[35px]"} />

        <div className="grid grid-cols-12 gap-0">
          <div className="col-span-12">
            <ChangePasswordForm />
            <Divider className={"bg-global-divider-medium my-4 md:my-[35px]"} />
            {user && (
              <div className="pb-4 lg:pb-6">
                <h4 className="text-base lg:text-lg text-global-title font-semibold">
                  {user.is_owner ? "Transfer Ownership" : "Delete Profile"}
                </h4>
                <p className="text-xs lg:text-sm text-global-paragraph font-normal leading-5 lg:whitespace-nowrap pb-4 md:pb-6">
                  {user.is_owner
                    ? "Transfering your FiberVue™ profile ownership is irreversible. You will loose access to all items you’ve created."
                    : "Deleting your FiberVue™ profile is irreversible. All items you’ve created will be permanently erased."}
                </p>
                {user.is_owner ? (
                  <>
                    <Button
                      variant={"primary"}
                      className="text-sm h-11 lg:text-base w-full md:w-[186px]"
                      onClick={() => handleUpdateProfileModal("transfer")}
                    >
                      Transfer ownership
                    </Button>
                  </>
                ) : (
                  <Button
                    variant={"warning"}
                    className="text-sm h-11 lg:text-base w-full md:w-[136px]"
                    onClick={() => handleUpdateProfileModal("delete")}
                  >
                    Delete profile
                  </Button>
                )}
              </div>
            )}
          </div>
        </div>

        <Modal open={profileDeleteModalOpen} close={closeModal}>
          <CommonDiscardButton
            closeModal={closeModal}
            onConfirmClick={handleDeleteProfile}
            title="Delete Profile"
            subText1="Are you sure you want to delete your profile? This action is irreversible."
            className="max-w-[544px]"
            IconComponent={DeleteProfile}
            confirmButtonText="Delete profile"
            confirmButtonVariant="warning"
            confirmButtonClassName="w-full h-11"
          />
        </Modal>
        <Modal open={showUpdateProfileModal} close={closeModal}>
          <Editprofile
            {...{
              closeModal,
              reset,
              setValue,
              isPending,
              onError,
              getValues,
              errors,
              register,
              handleSubmit,
              clearErrors,
              control,
              isDragOver,
              handleDragEnter,
              handleDragLeave,
              handleDrop,
              onFileDropOrChange,
              imagePreviews,
            }}
          />
        </Modal>
        <Modal open={transferOwnershipModalOpen} close={closeModal}>
          <TransferOwnershipForm closeModal={closeModal} />
        </Modal>
      </div>
    </>
  );
};

export default Settings;
