import * as z from "zod";

export const createArticleSchema = z
  .object({
    sku: z.string({ message: "Required" })
      .max(100, { message: "Article no. must not exceed 100 characters" })
      .min(1, { message: "Required" }),
    workspace_id: z.number({
      required_error: "Required",
      invalid_type_error: "Required",
    }),

    compositions: z
      .array(
        z.object(
          {
            label: z.string({ message: "Required" }),
            value: z.number({
              message: "Required",
              invalid_type_error: "Required",
            }),
          },
          {
            required_error: "Required",
            invalid_type_error: "Required",
          },
        ),
      )
      .nonempty({ message: "Required" }),
    gender_id: z.object(
      {
        label: z.string({ message: "Required" }),
        value: z.number({ message: "Required" }),
      },
      { required_error: "Required", invalid_type_error: "Required" },
    ),
    template_id: z.object(
      {
        label: z.string({ message: "Required" }),
        value: z.number({ message: "Required" }),
      },
      { required_error: "Required", invalid_type_error: "Required" },
    ),
    percentage: z
      .array(
        z
          .number({
            message: "Required",
            invalid_type_error: "Required",
          })
      )
      .nonempty({ message: "Required" }),

    article_type: z.object(
      {
        label: z.string({ message: "Required" }),
        value: z.number({ message: "Required" }),
      },
      {
        required_error: "Required",
        invalid_type_error: "Required",
      },
    ),
  })
  .refine(
    (data) => {
      // do the sum of percentage should be 100
      const sum = data.percentage.reduce((a, b) => a + b, 0);
      return Math.round(sum * 100) / 100 === 100;
    },
    {
      path: ["sum"],
    },
  );
