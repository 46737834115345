import { CardCvcElement, CardExpiryElement, CardNumberElement } from "@stripe/react-stripe-js";
import { Button } from "components/UI/Button";
import CustomSelect from "components/UI/CustomSelect";
import ErrorMessage from "components/UI/ErrorMessage";
import { Input } from "components/UI/Input";
import InstanceCard from "components/UI/InstanceCard";
import { Label } from "components/UI/Label";
import { Country } from "country-state-city";
import React from "react";
import { useSelector } from "react-redux";
import { handleRestrictNumbersOnKeyPress, handleRestrictNumbersOnPaste } from "utils/helper";

const StripePaymentCardDetails = ({
  control,
  register,
  errors,
  handleChangeCardItem,
  errorMessage,
  setErrorMessage
}) => {
  return (
    <>
      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-12 lg:col-span-8">
          <div className="grid gap-1.5">
            <Label className="text-sm font-medium text-input-text-label">Name on card</Label>
            <Input
              id="cardHolderName"
              type="text"
              size="lg"
              placeholder="Enter name"
              onKeyPress={handleRestrictNumbersOnKeyPress}
              onPaste={handleRestrictNumbersOnPaste}
              {...register("cardHolderName", {
                setValueAs: (value) => value.trim(),
              })}
            />
            <ErrorMessage errors={errors?.cardHolderName?.message} />
          </div>
        </div>

        <div className="col-span-12 lg:col-span-4">
          <div className="grid gap-1.5">
            <Label className="text-sm font-medium text-input-text-label">Expiry</Label>

            <CardExpiryElement
              as={Input}
              options={{
                style: {
                  base: {
                    fontSize: "16px",
                    padding: "12px",
                    backgroundColor: "var(--color-input-surface-background)",
                    border: "1px solid var(--color-input-stroke-default)",
                    borderRadius: "0.5rem",
                    color: "#344054",
                    fontFamily: "inherit",
                    "::placeholder": {
                      color: "#667085",
                      fontWeight: "400",
                    },
                    ":focus": {
                      border: "1px solid #11c182",
                      outline: "1px solid #11c182",
                      transition: "all 300ms ease-in-out",
                    },
                  },
                  placeholder: {
                    color: "var(--color-input-text-placeholder)",
                  },
                },
              }}
              onChange={handleChangeCardItem}
              className="grid w-full gap-3 h-11 items-center px-3.5 text-base bg-input-surface-background shadow-[0px,1px,2px,0px,rgba(16,24,40,0.05)] focus:shadow-[none]  py-[2.5px] text-input-text-data placeholder:text-input-text-placeholder placeholder:font-normal placeholder:text-base  self-stretch border border-input-stroke-default  hover:border-gray-800 transition duration-300 ease-in-out rounded-lg"
              required
            />

            <ErrorMessage errors={errors?.cardExpiry?.message} />
          </div>
        </div>

        <div className="col-span-12 lg:col-span-8">
          <div className="grid gap-1.5 ">
            <Label className="text-sm font-medium text-input-text-label">Card number</Label>

            <CardNumberElement
              options={{
                showIcon: true,
                style: {
                  base: {
                    fontSize: "16px",
                    padding: "12px",
                    backgroundColor: "var(--color-input-surface-background)",
                    border: "1px solid var(--color-input-stroke-default)",
                    borderRadius: "0.5rem",
                    color: "#344054",
                    fontFamily: "inherit",
                    "::placeholder": {
                      color: "#667085",
                      fontWeight: "400",
                    },
                    ":focus": {
                      border: "1px solid #11c182",
                      outline: "1px solid #11c182",
                      transition: "all 300ms ease-in-out",
                    },
                  },
                  placeholder: {
                    color: "var(--color-input-text-placeholder)",
                  },
                },
              }}
              onChange={handleChangeCardItem}
              className="grid w-full gap-3 h-11 items-center px-3.5 text-base bg-input-surface-background shadow-[0px,1px,2px,0px,rgba(16,24,40,0.05)] focus:shadow-[none]  py-[2.5px] text-input-text-data placeholder:text-input-text-placeholder placeholder:font-normal placeholder:text-base  self-stretch border border-input-stroke-default  hover:border-gray-800 transition duration-300 ease-in-out rounded-lg"
              required
            />
            <ErrorMessage errors={errors?.cardNumber?.message} />
          </div>
        </div>

        <div className="col-span-12 lg:col-span-4">
          <div className="grid gap-1.5">
            <Label className="text-sm font-medium text-input-text-label">CVV</Label>

            <CardCvcElement
              placeholder="123"
              options={{
                style: {
                  base: {
                    fontSize: "16px",
                    padding: "12px",
                    backgroundColor: "var(--color-input-surface-background)",
                    border: "1px solid var(--color-input-stroke-default)",
                    borderRadius: "0.5rem",
                    color: "#344054",
                    fontFamily: "inherit",
                    "::placeholder": {
                      color: "#667085",
                      fontWeight: "400",
                    },
                    ":focus": {
                      border: "1px solid #11c182",
                      outline: "1px solid #11c182",
                      transition: "all 300ms ease-in-out",
                    },
                  },
                  placeholder: {
                    color: "var(--color-input-text-placeholder)",
                  },
                },
              }}
              onChange={handleChangeCardItem}
              className="grid w-full gap-3 h-11 items-center px-3.5 text-base bg-input-surface-background shadow-[0px,1px,2px,0px,rgba(16,24,40,0.05)] focus:shadow-[none]  py-[2.5px] text-input-text-data placeholder:text-input-text-placeholder placeholder:font-normal placeholder:text-base  self-stretch border border-input-stroke-default  hover:border-gray-800 transition duration-300 ease-in-out rounded-lg"
              required
            />
            <ErrorMessage errors={errors?.cardCvc?.message} />
          </div>
        </div>
        {
          errorMessage &&
          <div className="col-span-12 mt-1">
            <InstanceCard
              errorMessage={errorMessage}
            />
          </div>
        }

      </div>

    </>
  );
};

export default StripePaymentCardDetails;
