import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { DeleteProfile, DownloadCloudIcon, EditPen, Plus, RemoveIcon, TrashIcon } from "assests";
import EmptyTable from "components/Card/EmptyTable";
import BreadCrumb from "components/TopHeader/BreadCrumb";
import TopNavbar from "components/TopHeader/TopNavbar";
import BackToDashboard from "components/UI/BackToDashboard";
import { Button } from "components/UI/Button";
import ChipButton from "components/UI/ChipButton";
import CommonCard from "components/UI/CommonCard";
import CommonDiscardButton from "components/UI/CommonDiscardButton";
import Divider from "components/UI/Divider";
import { LineProgressBar } from "components/UI/LineProgressBar";
import LoadingComponent from "components/UI/LoadingComponent";
import MemberTooltip from "components/UI/MemberToolptip";
import Modal from "components/UI/Modal";
import SettingsPermissionModal from "components/UI/SettingsPermissionModal";
import SortIcons from "components/UI/SortIcons";
import TertiaryPagination from "components/UI/TertiaryPagination";
import useCreateUserManagement from "hooks/useCreateUserManagement";
import useCustomToaster from "hooks/useCustomToaster";
import usePermission from "hooks/usePermission";
import useSubscriptionModel from "hooks/useSubscriptionModel";
import SubNavbar from "layout/SubNavbar";
import SubScriptionModal from "pages/Dashboard/DashboardWorkspaces/SubScriptionModal";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { BsCircleFill } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { bulkDeleteUsers, deleteUser, downloadUsersAsCSV, getUsers } from "services/user.services";
import { setRefetchBillingData } from "store/billingSlice";
import {
  setAllUserData,
  setUserList,
  toggleActionableUserId,
  toggleIsUserCreateModalOpen,
  toggleIsUserEditable,
  toggleSelectedUserId,
  toggleUserDeleteModal,
} from "store/userSlice";
import { calculatePercentage, truncateString } from "utils/helper";
import AddNewUserForm from "./AddNewUserForm";
import EditUserForm from "./EditUser/EditUserForm";
const UserManagement = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { showCustomToast } = useCustomToaster();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { refetchBillingData, currentPlan } = useSelector((state) => state.billingSlice);
  const { currentWorkspace, user } = useSelector((state) => state.authenticationSlice);
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [searchKey, setSearchKey] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const [isHovered, setIshovered] = useState(false);
  const [isHoveredTrash, setHoveredTrash] = useState(false);
  const { workspaceId } = useParams();
  const { hasPermission, checkPlanLimitation } = usePermission();

  const {
    data: userData,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ["users", "getUsers", page, size, searchKey, orderBy],
    queryFn: () =>
      getUsers({
        page,
        per_page: size,
        sort: orderBy,
        workspace_id: parseInt(workspaceId),
      }),
    enabled: !!page && !!size,
  });

  const {
    control,
    isPending,
    onError,
    setActiveStep,
    errors,
    getValues,
    handleClickStepper,
    register,
    discardChanges,
    handleSubmit,
    onDiscard,
    handleClickEdiable,
    setValue,
    nextStep,
    reset,
    watch,
    clearErrors,
    editNextStep,
    user_hash,
    selectedOrders,
    setSelectedOrders,
    userIds,
    closeModal,
    userPermissionType,
    setUserPermissionType,
  } = useCreateUserManagement({ refetchUserData: refetch });

  const {
    isSubscriptionCreateModalOpen,
    openCreateModal: openSubscriptionCreateModal,
    closeModal: closeSubscriptionModal,
  } = useSubscriptionModel();

  const {
    actionableUserId,
    isUserEditable,
    userDetails,
    userList,
    showDiscardModal,
    isUserCreateModalOpen,
    deleteModal,
    allUserData,
    workspaceList,
  } = useSelector((state) => state.userSlice);

  const handleSizeChange = (newSize) => {
    setSize(newSize);
    setPage(1);
  };

  const deleteUserMutation = useMutation({
    mutationKey: ["User", "delete"],
    mutationFn: (userIds) => deleteUser(userIds),
    onMutate: () => {
      showCustomToast(
        {
          title: "Removing...",
        },
        "deleteUser",
      );
    },
    onSuccess: (res) => {
      toast.dismiss("deleteUser");
      showCustomToast({
        title: res.data.message || "Member removed successfully",
      });
      refetch();
      closeModal();
      if (userIds) {
        dispatch(toggleSelectedUserId());
      }
      if (!refetchBillingData) {
        dispatch(setRefetchBillingData(true));
      }
      queryClient.invalidateQueries(["user", "get", "delete"]);
    },
    onError: (error) => {
      toast.dismiss("deleteUser");
      showCustomToast({
        title: error.response.data.message || error.message,
      });
    },
    onSettled: () => {
      toast.dismiss("deleteUser");
    },
  });

  const handleClickOpen = (actionType) => {
    switch (actionType) {
      case "delete":
        dispatch(toggleUserDeleteModal());

        break;
      case "addMember":
        dispatch(toggleIsUserCreateModalOpen());
        break;

      default:
        break;
    }
  };

  const handleClickOptionButton = async (userId, actionType) => {
    switch (actionType) {
      case "edit":
        dispatch(toggleActionableUserId(userId));
        dispatch(toggleIsUserEditable());
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (user_hash && currentWorkspace && currentWorkspace.role_name.includes("admin")) {
      const { id, permission } = JSON.parse(atob(user_hash));
      if (id) {
        dispatch(toggleActionableUserId(id));
        dispatch(toggleIsUserEditable());
      }
      if (permission) {
        setUserPermissionType(permission);
      }
    }
  }, [user_hash, currentWorkspace]);

  useEffect(() => {
    if (userData && userData.status === 200) {
      dispatch(setUserList({ append: false, userList: userData?.data?.users?.data }));
      dispatch(setAllUserData({ append: false, allUserData: userData.data.users }));
    }
  }, [userData]);

  const downloadUsersCSV = useMutation({
    mutationKey: ["users", "download"],
    mutationFn: (params) => downloadUsersAsCSV(params),
    onMutate: () => {
      showCustomToast(
        {
          title: "Downloading...",
        },
        "downloadUsers",
      );
    },
    onSuccess: (res) => {
      toast.dismiss("downloadUsers");
      showCustomToast({
        title: "Downloaded successfully",
      });
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "users.csv");
      document.body.appendChild(link);
      link.click();
      link.remove();
      queryClient.invalidateQueries(["users", "download"]);
    },
    onError: (error) => {
      toast.dismiss("downloadUsers");
      showCustomToast({
        title: error.response.data.message || error.message,
      });
    },
  });

  const handleAddMember = () => {
    if (checkPlanLimitation && !checkPlanLimitation?.canCreateUser) {
      setShowUpgradeModal(true);
    } else {
      handleClickOpen("addMember");
    }
  };

  const closeLimitModal = () => {
    setShowUpgradeModal(false);
  };

  const onClickCheckbox = (inviatation) => {
    if (inviatation.is_owner || user.email === inviatation.email) return;
    const findedItem = selectedOrders.some((item) => item.id === inviatation.id);
    if (!findedItem) {
      setSelectedOrders((prevSelectedArticles) => [...prevSelectedArticles, inviatation]);
    } else {
      setSelectedOrders((prevSelectedArticles) =>
        prevSelectedArticles.filter((item) => item.id !== inviatation.id),
      );
    }
  };

  const handleSelectAll = (e) => {
    if (!e.target.checked || !userList.length > 0) {
      setSelectedOrders([]);
      return;
    }
    const invitationIds = userList.length > 0 && userList?.filter((item) => !item.is_owner && user.email !== item.email)?.map((inviation) => inviation);
    setSelectedOrders(invitationIds);
  };

  const deleteAllMutation = useMutation({
    mutationKey: ["allInvitationsDelete", "deleteAllInviation"],
    mutationFn: (data) => bulkDeleteUsers(data),
    onMutate: () => {
      showCustomToast(
        {
          title: "Removing...",
        },
        "deleteAllInviation",
      );
    },
    onSuccess: (res) => {
      toast.dismiss("deleteAllInviation");
      showCustomToast({
        title: res.data.message || "Member's removed successfully",
      });
      refetch();
      closeModal();
      if (!refetchBillingData) {
        dispatch(setRefetchBillingData(true));
      }
      queryClient.invalidateQueries(["allInvitationsDelete", "get", "delete"]);
    },
    onError: (error) => {
      toast.dismiss("deleteAllInviation");
      showCustomToast({
        title: error.response.data.message || error.message,
      });
    },
    onSettled: () => {
      toast.dismiss("deleteAllInviation");
    },
  });

  const handleDelete = (ids) => {
    if (actionableUserId) {
      deleteUserMutation.mutate(actionableUserId);
    } else {
      const allIds = ids.map((item) => item.id);
      if (allIds) {
        deleteAllMutation.mutate({ invitations: allIds });
      }
    }
  };

  const openDeleteModal = (e) => {
    e.stopPropagation();
    dispatch(toggleUserDeleteModal());
  };

  const openSingleModal = (e, item) => {
    e.stopPropagation();
    setSelectedOrders([]);
    dispatch(toggleActionableUserId(item.id));
    dispatch(toggleUserDeleteModal());
  };

  return (
    <>
      <SettingsPermissionModal actionText="members" {...{ showUpgradeModal, closeLimitModal }} />
      <Modal open={deleteModal} close={closeModal}>
        <CommonDiscardButton
          closeModal={closeModal}
          title="Remove Member"
          subText1="Are you sure you want to remove this member from your account? This action cannot be undone."
          className="max-w-[544px]"
          IconComponent={DeleteProfile}
          confirmButtonText="Remove"
          confirmButtonVariant="warning"
          confirmButtonClassName={"w-full  h-11"}
          onConfirmClick={() => handleDelete(selectedOrders)}
        />
      </Modal>
      <Modal open={isSubscriptionCreateModalOpen} close={closeSubscriptionModal} title={""}>
        <SubScriptionModal closeModal={closeSubscriptionModal} />
      </Modal>
      <Modal open={isUserCreateModalOpen} close={closeModal} title={""}>
        <AddNewUserForm
          {...{
            closeModal,
            control,
            isPending,
            onError,
            isUserEditable,
            actionableUserId,
            showDiscardModal,
            userDetails,
            setActiveStep,
            errors,
            getValues,
            handleClickStepper,
            register,
            discardChanges,
            handleSubmit,
            onDiscard,
            handleClickEdiable,
            setValue,
            nextStep,
            clearErrors,
            watch,
          }}
        />
      </Modal>

      <Modal open={isUserEditable} close={closeModal} title={""}>
        <EditUserForm
          {...{
            closeModal,
            control,
            isPending,
            onError,
            isUserEditable,
            actionableUserId,
            showDiscardModal,
            userDetails,
            setActiveStep,
            errors,
            getValues,
            handleClickStepper,
            register,
            discardChanges,
            handleSubmit,
            onDiscard,
            handleClickEdiable,
            setValue,
            editNextStep,
            clearErrors,
            watch,
            userPermissionType,
          }}
        />
      </Modal>

      <div >
        <div className="max-w-screen h-full overflow-x-auto">
          <div className="w-full">
            <div className="grid grid-cols-12 justify-start items-center gap-3 md:gap-6 ">
              <div className="col-span-12 lg:col-span-6 w-full lg:max-w-[520px] mt-0">
                <CommonCard
                  btnText={"Upgrade plan"}
                  minHeight={188}
                  link={`/workspace/${currentWorkspace?.user_workspace_id}/setting?selectedTab=plan`}
                  btnClassName="min-h-[49px]"
                >
                  <div className="flex flex-col justify-start   gap-6">
                    <div className="justify-between items-start flex w-full">
                      <div className="pt-1 flex-col justify-start items-start gap-1 inline-flex">
                        <div className="justify-start items-center gap-2 inline-flex">
                          <div className="text-global-title text-base md:text-lg font-semibold leading-7">
                            Plan Usage
                          </div>
                          <ChipButton className="!uppercase" childrenClassName="text-sm lg:text-base">
                            {currentPlan?.plan?.name ? currentPlan?.plan?.name : "FREE"}
                          </ChipButton>
                        </div>
                        <p className="text-xs lg:text-sm text-global-paragraph tracking-tight font-normal">
                          You have used {currentPlan?.user_count ? currentPlan?.user_count : 0} out
                          of{" "}
                          {currentPlan?.plan?.package?.users
                            ? currentPlan?.plan?.package?.users
                            : 0}{" "}
                          active member credits from your current plan.
                        </p>
                      </div>
                    </div>
                    <div className="grid grid-cols-12 gap-3">
                      <div className="col-span-12">
                        <p className="text-sm font-medium text-global-title">
                          {currentPlan?.user_count ? currentPlan?.user_count : 0} of{" "}
                          {currentPlan?.plan?.package?.users
                            ? currentPlan?.plan?.package?.users
                            : 0}{" "}
                          users
                        </p>
                      </div>
                      <div className="col-span-12">
                        <LineProgressBar
                          className="h-[8px]"
                          variant="green"
                          progress={
                            currentPlan?.user_count && currentPlan?.plan?.package?.users
                              ? calculatePercentage(
                                currentPlan?.user_count,
                                currentPlan?.plan?.package?.users,
                              )
                              : 0
                          }
                        />
                      </div>
                    </div>
                  </div>
                </CommonCard>
              </div>
            </div>
          </div>
          <Divider className={"my-4 lg:my-[35px]"} />

          <div className="bg-btn-surface-secondary  border border-border-divider-stroke  rounded-xl custom-card w-full  mb-6">
            <div className="flex flex-col lg:flex-row justify-center  lg:justify-between py-3 lg:py-[20.5px] px-4 lg:px-6 gap-3 lg:gap-5">
              <div className="flex flex-col gap-1">
                <div className="flex gap-2 justify-start items-center">
                  <h3 className="text-base lg:text-lg font-semibold text-global-title leading-5 lg:leading-7">
                    Active members
                  </h3>
                  <ChipButton
                    className={
                      "text-[10px] lg:text-xs text-global-paragraph h-[22px] py-[2px] !px-1.5 whitespace-nowrap"
                    }
                  >
                    {allUserData && allUserData?.total} members
                  </ChipButton>
                </div>
                <p className="text-xs lg:text-sm text-global-paragraph leading-5 ">
                  Manage your members and their account permissions here.
                </p>
              </div>
              <div className="flex flex-col md:flex-row gap-3">
                <div className="flex flex-row gap-3">
                  <div className=" order-2 md:order-1">
                    <Button
                      variant="secondary"
                      className={"w-full lg:w-[157px] h-10 py-2.5 px-[14px] "}
                      onClick={() => downloadUsersCSV.mutate()}
                      onMouseEnter={() => setIshovered(true)}
                      onMouseLeave={() => setIshovered(false)}
                    >
                      <DownloadCloudIcon
                        width={20}
                        height={20}
                        strokeColor={
                          isHovered
                            ? "var(--color-icon-fill-secondary-button)"
                            : "var(--color-txt-secondary)"
                        }
                      />{" "}
                      <span className="hidden md:block">Download CSV</span>
                    </Button>
                  </div>
                  {selectedOrders.length > 0 && (
                    <div className="hidden md:block">
                      <Button
                        variant="secondary"
                        className={
                          "flex gap-1.5 justify-center items-center whitespace-nowrap w-full lg:w-[111px] h-10 py-2.5 px-[14px]"
                        }
                        onMouseEnter={() => setHoveredTrash(true)}
                        onMouseLeave={() => setHoveredTrash(false)}
                        onClick={openDeleteModal}
                      >
                        <TrashIcon
                          className={"w-5 h-5"}
                          strokeColor={
                            isHoveredTrash ? "var(--color-white)" : "var(--color-icon-default-2)"
                          }
                        />
                        Remove
                      </Button>
                    </div>
                  )}
                  <div className="flex-1 order-1 md:order-2">
                    <Button
                      variant={"primary"}
                      className={
                        "w-full lg:w-[144px] h-10 py-2.5 px-[14px] flex gap-1.5 text-sm font-semibold whitespace-nowrap"
                      }
                      onClick={handleAddMember}
                    >
                      <div>
                        <Plus width={20} height={20} fillColor={"var(--color-white)"} />
                      </div>
                      Add Member
                    </Button>
                  </div>
                </div>
                {selectedOrders.length > 0 && (
                  <div className="block md:hidden">
                    <Button
                      variant="secondary"
                      className={
                        "flex gap-1.5 justify-center items-center whitespace-nowrap w-full lg:w-[111px] h-10 py-2.5 px-[14px]"
                      }
                      onMouseEnter={() => setHoveredTrash(true)}
                      onMouseLeave={() => setHoveredTrash(false)}
                      onClick={openDeleteModal}
                    >
                      <TrashIcon
                        className={"w-5 h-5"}
                        strokeColor={
                          isHoveredTrash ? "var(--color-white)" : "var(--color-icon-default-2)"
                        }
                      />
                      Remove
                    </Button>
                  </div>
                )}
              </div>
            </div>

            <div className="w-full overflow-x-auto ">
              <table className="w-full border-spacing-0 table-auto  rounded-table-custom overflow-x-auto ">
                <thead className="text-sm text-left whitespace-nowrap text-global-gray-brand">
                  <tr className="border-b border-t border-b-global-divider-soft border-t-global-divider-soft">
                    <th className="pl-6 pr-4 py-[11.5px]  ">
                      <div className="flex items-center gap-3">
                        <input
                          type="checkbox"
                          className={`rounded-md  w-5 h-5 ${userList && userList.length > 0 ? "cursor-pointer" : "cursor-default"}`}
                          onChange={handleSelectAll}
                          checked={
                            userList?.filter((item) => !item.is_owner && user.email !== item.email).length >
                            0 &&
                            selectedOrders.length ===
                            userList?.filter((item) => !item.is_owner && user.email !== item.email).length
                          }
                        />
                        <div className="flex items-center">
                          <span className="mr-1 text-xs font-medium text-global-gray-brand leading-[18px]">
                            Name
                          </span>
                          <SortIcons
                            hasData={userList && userList.length > 0}
                            sortName="person_name"
                            {...{ orderBy, setOrderBy }}
                          />
                        </div>
                      </div>
                    </th>

                    <th className="relative px-4 py-[11.5px]  ">
                      <div className="flex items-center">
                        <span className="mr-1 text-xs font-medium text-global-gray-brand leading-[18px]">
                          Status
                        </span>
                        <SortIcons
                          hasData={userList && userList.length > 0}
                          sortName="status"
                          {...{ orderBy, setOrderBy }}
                        />
                      </div>
                    </th>

                    <th className="relative px-4 py-[11.5px]  ">
                      <div className="flex items-center">
                        <span className="mr-1 text-xs font-medium text-global-gray-brand leading-[18px]">
                          Permission
                        </span>
                        <SortIcons
                          hasData={userList && userList.length > 0}
                          sortName="role"
                          {...{ orderBy, setOrderBy }}
                        />
                      </div>
                    </th>
                    <th className="relative px-4 py-[11.5px]  ">
                      <div className="flex items-center">
                        <span className="mr-1 text-xs font-medium text-global-gray-brand leading-[18px]">
                          Email address
                        </span>
                        <SortIcons
                          hasData={userList && userList.length > 0}
                          sortName="email"
                          {...{ orderBy, setOrderBy }}
                        />
                      </div>
                    </th>
                    <th className="relative px-4 py-[11.5px]  ">
                      <div className="flex items-center">
                        <span className="mr-1 text-xs font-medium text-global-gray-brand leading-[18px]">
                          Workspace
                        </span>
                        <SortIcons
                          hasData={userList && userList.length > 0}
                          sortName="workspaces"
                          {...{ orderBy, setOrderBy }}
                        />
                      </div>
                    </th>

                    <th className="relative px-4 py-3  border-b border-b-global-divider-soft">
                      <div className="flex items-center">
                        <span className="mr-1"> </span>
                      </div>
                    </th>
                  </tr>
                </thead>

                <tbody className="">
                  {!isLoading && userList && userList.length > 0 ? (
                    userList?.map((invitation, index) => (
                      <tr
                        key={index}
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        className={`whitespace-nowrap  border-b border-b-global-divider-soft  ${index % 2 === 0 ? "bg-global-white" : "bg-white"} rounded-lg text-global-gray-brand text-sm font-normal`}
                      >
                        <td className="pl-6 pr-4 py-[15.5px]   border-b border-b-global-divider-soft">
                          <div className="flex items-center gap-3">
                            <input
                              onChange={() => onClickCheckbox(invitation)}
                              onClick={(e) => e.stopPropagation()}
                              checked={selectedOrders.some((item) => user.email !== item.email && !invitation.is_owner && item.id === invitation?.id)}
                              type="checkbox"
                              className="rounded-md border cursor-pointer w-5 h-5 border-[#444A58] checked:bg-[#11C182] checked:border-[#11C182]"
                            />
                            <div className="flex justify-start items-center gap-3">
                              <div className="relative">
                                {" "}
                                {invitation && invitation?.user_image ? (
                                  <>
                                    <div className="flex justify-center items-center p-0 ring-1 ring-global-divider-soft ring-inset rounded-full border-none text-btn-text-default bg-transparent">
                                      <img
                                        src={`${invitation?.user_image}?t=${new Date().getTime()}`}
                                        alt={`Profile`}
                                        className="rounded-full  h-8 w-8 md:w-10 md:h-10"
                                      />
                                    </div>

                                    <BsCircleFill
                                      className={`absolute bottom-0 right-0 w-1.5 h-1.5 ${invitation?.status === "accepted" ? "text-global-primary" : "text-global-paragraph"}`}
                                    />
                                  </>
                                ) : (
                                  <>
                                    <div className="cursor-pointer flex bg-global-surface justify-center items-center text-base font-semibold text-global-paragraph leading-6 w-8 h-8 md:h-10 md:w-10 p-2 ring-1 ring-global-divider-medium ring-opacity-75 ring-inset rounded-full border-none capitalize">
                                      {invitation && invitation.first_name
                                        ? `${invitation.first_name.substring(0, 1).toUpperCase()}${invitation.last_name.substring(0, 1).toUpperCase()}`
                                        : ""}
                                    </div>
                                    <BsCircleFill
                                      className={`absolute bottom-0 right-0 w-2.5 h-2.5 ${invitation?.status === "accepted" ? "text-global-primary" : "text-global-paragraph"}`}
                                    />
                                  </>
                                )}
                              </div>

                              <div className="flex flex-col">
                                <p className="text-sm leading-6 font-medium text-global-gray-brand capitalize">
                                  {invitation?.person_name}
                                </p>
                                <p className="text-sm text-global-gray-brand font-normal leading-5 capitalize">
                                  {invitation?.job_title}
                                </p>
                              </div>
                            </div>
                          </div>
                        </td>

                        <td className=" px-4 py-[15.5px]   border-b border-b-global-divider-soft">
                          {invitation?.status ? (
                            <Button
                              variant="status"
                              className={
                                " flex justify-center items-center gap-1 px-1.5 py-[2px] w-auto  h-[22px] text-global-gray-brand font-medium text-xs leading-[18px]  border border-border-divider-stroke custom-card cursor-default "
                              }
                            >
                              <BsCircleFill
                                className={`w-1.5 h-1.5 ${invitation?.status === "accepted" ? "text-global-primary" : "text-global-paragraph"}`}
                              />
                              {invitation.status.toLowerCase() === "accepted"
                                ? "Active"
                                : invitation.status.toLowerCase() === "pending"
                                  ? "Invite pending"
                                  : invitation.status
                                    .toLowerCase()
                                    .split("_")
                                    .map(
                                      (part) =>
                                        part.charAt(0).toUpperCase() + part.slice(1).trim(),
                                    )
                                    .join(" ")}
                            </Button>
                          ) : null}
                        </td>
                        <td className="relative px-4 py-[15.5px]  border-b border-b-global-divider-soft">
                          {invitation?.role ? (
                            <Button
                              variant="status"
                              className={`rounded-[6px] w-16 h-[22px] px-2 py-[2px]  border border-border-divider-stroke custom-card cursor-default capitalize   text-status-text-default  text-xs leading-[18px] font-medium `}
                            >
                              {invitation?.role?.split(".")[1]}
                            </Button>
                          ) :
                            null
                          }
                        </td>

                        <td className="relative px-4 py-[15.5px]  text-sm text-global-gray-brand leading-5  border-b border-b-global-divider-soft">
                          {invitation?.email ? truncateString(invitation.email) : null}
                        </td>
                        <td className=" px-4 py-[15.5px] overflow-visible  text-sm text-global-gray-brand leading-5  border-b border-b-global-divider-soft">
                          {workspaceList &&
                            workspaceList.length > 0 &&
                            invitation?.workspaces &&
                            invitation?.workspaces?.length > 0 ? (
                            <div className="flex items-center gap-1">
                              {workspaceList
                                .filter((workspace) =>
                                  invitation?.workspaces?.includes(workspace.id),
                                )
                                ?.slice(0, 2)
                                ?.map((workspace, index) => (
                                  <Button
                                    key={index}
                                    variant="status"
                                    className={`rounded-[6px] h-[22px] px-2 py-[2px] truncate text-status-text-default  custom-card text-xs leading-[18px]  font-medium cursor-default capitalize ring-0 border border-border-divider-stroke`}
                                  >
                                    {truncateString(workspace.name)}
                                  </Button>
                                ))}

                              {invitation.workspaces.length > 2 && (
                                <MemberTooltip
                                  length={invitation?.workspaces?.length - 2}
                                  children={
                                    <>
                                      {workspaceList
                                        .filter((workspace) =>
                                          invitation?.workspaces?.includes(workspace.id),
                                        )
                                        ?.map((workspace, index) => (
                                          <Button
                                            key={index}
                                            variant="status"
                                            className={`rounded-[6px] h-[22px] mb-2 px-2 py-[2px] truncate text-status-text-default  custom-card text-xs leading-[18px]  font-medium cursor-default capitalize ring-0 border border-border-divider-stroke`}
                                          >
                                            {truncateString(workspace.name)}
                                          </Button>
                                        ))}
                                    </>
                                  }
                                />
                              )}
                            </div>
                          ) : (
                            <>
                              {invitation?.role?.includes("admin") &&
                                workspaceList &&
                                workspaceList?.length > 0 ? (
                                <div className="flex items-center gap-1">
                                  {workspaceList?.slice(0, 2)?.map((workspace, index) => (
                                    <Button
                                      key={index}
                                      variant="status"
                                      className={`rounded-[6px] h-[22px] px-2 py-[2px] truncate text-status-text-default  custom-card text-xs leading-[18px]  font-medium cursor-default capitalize ring-0 border border-border-divider-stroke`}
                                    >
                                      {truncateString(workspace.name)}
                                    </Button>
                                  ))}
                                  {workspaceList.length > 2 && (
                                    <MemberTooltip
                                      length={workspaceList?.length - 2}
                                      children={
                                        <>
                                          {workspaceList?.map((workspace, index) => (
                                            <Button
                                              key={index}
                                              variant="status"
                                              className={`rounded-[6px] h-[22px] mb-2 px-2 py-[2px] truncate text-status-text-default  custom-card text-xs leading-[18px]  font-medium cursor-default capitalize ring-0 border border-border-divider-stroke`}
                                            >
                                              {truncateString(workspace.name)}
                                            </Button>
                                          ))}
                                        </>
                                      }
                                    />
                                  )}
                                </div>
                              ) : (
                                null
                              )}
                            </>
                          )}
                        </td>

                        <td className="relative  px-4 py-[15.5px]  text-sm text-global-gray-brand leading-5  border-b border-b-global-divider-soft">
                          {user &&
                            (invitation.is_owner || user.email === invitation.email) ? null : (
                            <div className="flex items-center gap-1">
                              <div className="p-2">
                                <RemoveIcon
                                  strokeColor={"var(--color-icon-outline-default-hover)"}
                                  onClick={(e) => openSingleModal(e, invitation)}
                                  className="cursor-pointer"
                                />
                              </div>
                              <div className="p-2">
                                {invitation.status === "accepted" ? (
                                  <EditPen
                                    className="cursor-pointer"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleClickOptionButton(invitation.id, "edit");
                                    }}
                                  />
                                ) : null}
                              </div>
                            </div>
                          )}
                        </td>
                      </tr>
                    ))
                  ) : isLoading ? (
                    <tr>
                      <td colSpan={11} className="text-center pb-3">
                        <LoadingComponent />
                      </td>
                    </tr>
                  ) : (
                    <EmptyTable colSpan={11} />
                  )}
                </tbody>
              </table>
            </div>
            {allUserData && allUserData.data && allUserData.total > 0 && (
              <TertiaryPagination
                page={page}
                totalPages={Math.ceil(allUserData.total / size)}
                setPage={setPage}
                size={size}
                handleSizeChange={handleSizeChange}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default UserManagement;
