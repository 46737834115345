import { Divider } from "@mui/material";
import {
  CardCvcElement,
  CardElement,
  CardExpiryElement,
  CardNumberElement,
} from "@stripe/react-stripe-js";
import { CheckCircleFill } from "assests";
import StripePaymentCardDetails from "components/Card/StripePaymentCardDetails";
import { Button } from "components/UI/Button";
import CustomSelect from "components/UI/CustomSelect";
import DynamicSVG from "components/UI/DynamicSVG";
import ErrorMessage from "components/UI/ErrorMessage";
import { Input } from "components/UI/Input";
import { Label } from "components/UI/Label";
import Tooltip from "components/UI/Tooltip";
import { City, Country, State } from "country-state-city";
import useCreateWorkspace from "hooks/useCreateWorkspace";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
const PaymentDetails = () => {
  const {
    control,
    register,
    setValue,
    handleSubmit,
    handleChangeCardItem,
    errors,
    isLoading,
    confirmPaymentMutation,
  } = useCreateWorkspace();
  const { selectedPlanData } = useSelector((state) => state.subscriptionSlice);

  const [countryOptions, setCountryOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedCity, setSelectedCity] = useState("");

  const handleChangeCountry = (selectedOption) => {
    if (selectedOption && typeof selectedOption === "object") {
      setSelectedCountry(selectedOption);
      setValue("country", selectedOption);

      const cities = City.getCitiesOfCountry(selectedOption.value);
      if (cities && cities.length > 0) {
        const cityList = cities.map((city) => {
          return {
            value: city.name,
            label: city.name,
          };
        });
        setCityOptions(cityList);
      } else {
        setCityOptions([]);
        setSelectedCity("");
        setValue("city", null);
      }
    } else {
      setValue("country", "");
      setValue("city", null);
      setSelectedCountry("");
      setSelectedCity("");
      setCityOptions([]);
    }
  };

  const handleChangeCity = (selectedOption) => {
    if (selectedOption && typeof selectedOption === "object") {
      setSelectedCity(selectedOption);
      // setValue("city", selectedOption.value);
    } else {
      setValue("city", "");
      setSelectedCity("");
    }
  };

  useEffect(() => {
    if (selectedPlanData && selectedPlanData.st_plan_id) {
      setValue("planId", selectedPlanData.st_plan_id);
    }
  }, [selectedPlanData]);

  useEffect(() => {
    const allCountries = Country.getAllCountries();

    const countryList = allCountries.map((country) => {
      return {
        value: country.isoCode,
        label: country.name,
      };
    });

    setCountryOptions(countryList);
  }, []);

  return (
    <div className="w-full">
      <div className="grid grid-cols-1 justify-items-center align-items-center">
        <div className="grid justify-items-center align-items-center mb-6 md:mb-12">
          <p className="font-bold text-3xl text-global-title ">Payment Details</p>
        </div>
        <div className="w-full md:w-[749px] p-4 md:p-12 bg-global-white rounded-2xl shadow-md  ">
          <div className="grid grid-cols-12 gap-6 md:gap-10 divide-y-2 md:divide-y-0  md:divide-x-2">
            <div className="col-span-12 md:col-span-4 ">
              <div className="grid grid-cols-1 gap-6">
                <div>
                  <p className="text-global-gray-brand text-2xl font-bold">
                    {selectedPlanData?.name}
                  </p>
                  <p className="text-global-gray-brand text-sm font-light">
                    {selectedPlanData?.package?.order_lines} order lines
                  </p>
                </div>

                <Divider className="bg-global-divider-medium" />
                <div className="grid gap-4 py-4 h-[184px]">
                  <div className="flex justify-between items-center gap-3">
                    <div className="flex justify-start items-center gap-3">
                      <CheckCircleFill
                        fillColor={"var(--color-global-white)"}
                        height={24}
                        width={24}
                      />
                      <p className="text-global-gray-brand text-sm font-normal">
                        {selectedPlanData?.package?.order_lines} order lines
                      </p>
                    </div>
                    <Tooltip fromPlan={true} text="Lorem Ipsum is simply dummy text." />
                  </div>
                  <div className="flex justify-between items-center gap-3">
                    <div className="flex justify-start items-center gap-3">
                      <CheckCircleFill
                        fillColor={"var(--color-global-white)"}
                        height={24}
                        width={24}
                      />
                      <p className="text-global-gray-brand text-sm font-normal">
                        {selectedPlanData?.package?.articles} articles
                      </p>
                    </div>
                    <Tooltip fromPlan={true} text="Lorem Ipsum is simply dummy text." />
                  </div>
                  <div className="flex justify-between items-center gap-3">
                    <div className="flex justify-start items-center gap-3">
                      <CheckCircleFill
                        fillColor={"var(--color-global-white)"}
                        height={24}
                        width={24}
                      />
                      <p className="text-global-gray-brand text-sm font-normal">
                        {selectedPlanData?.package?.users} account users
                      </p>
                    </div>
                    <Tooltip fromPlan={true} text="Lorem Ipsum is simply dummy text." />
                  </div>
                  <div className="flex justify-between items-center gap-3">
                    <div className="flex justify-start items-center gap-3">
                      <CheckCircleFill
                        fillColor={"var(--color-global-white)"}
                        height={24}
                        width={24}
                      />
                      <p className="text-global-gray-brand text-sm font-normal">
                        {selectedPlanData?.package?.partner_links} partner links
                      </p>
                    </div>
                    <Tooltip fromPlan={true} text="Lorem Ipsum is simply dummy text." />
                  </div>
                </div>
              </div>
            </div>

            <div className="pt-6 md:pt-0 md:pl-10 col-span-12 md:col-span-8">
              <form onSubmit={handleSubmit}>
                <div className="grid grid-cols-1 gap-4 md:gap-8">
                  <StripePaymentCardDetails
                    {...{
                      control,
                      register,
                      errors,
                      handleChangeCardItem,
                      selectedCountry,
                      handleChangeCountry,
                      cityOptions,
                      selectedCity,
                      handleChangeCity,
                      
                    }}
                  />
                  <div className=" bg-global-primary-light rounded-lg p-3 border border-global-primary-light">
                    <div className="grid grid-cols-1 gap-2 divide-y-2 divide-global-primary  divide-dashed">
                      <div>
                        {/* <div className="flex justify-between">
                          <p className="global-gray-brand text-[10px] font-normal">Tax</p>
                          <p className="global-gray-brand text-xs font-normal">€48.8</p>
                        </div> */}
                        <div className="flex justify-between">
                          <p className="text-global-gray-brand text-[10px] font-normal">
                            Subscription bill
                          </p>
                          <p className="text-global-gray-brand text-xs font-normal">
                            €{selectedPlanData?.price}
                          </p>
                        </div>
                      </div>

                      <div className="pt-2">
                        <div className="flex justify-between">
                          <p className="text-global-gray-brand text-xs font-semibold">Total Bill</p>
                          <p className="text-global-gray-brand text-base font-semibold ">
                            €{selectedPlanData?.price}/month
                          </p>
                        </div>
                        <div className="flex justify-between">
                          <p className="text-global-gray-brand text-[10px] font-normal">
                            Renews for €{selectedPlanData?.package?.purchase_price} every month plus
                            applicable taxes
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Button
                    type="submit"
                    className={
                      "text-sm font-semibold  disabled:text-btn-text-primary-disabled disabled:bg-btn-surface-disabled px-8 py-4 h-[48px] disabled:cursor-not-allowed"
                    }
                    isDisabled={isLoading || confirmPaymentMutation.isLoading}
                  >
                    Upgrade
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentDetails;
