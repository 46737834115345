import React from "react";

const message_chat_circle = React.forwardRef(
  (
    {
      width = "20",
      height = "20",
      viewBox = "0 0 20 20",
      fill = "none",
      fillColor = "#444656",
      className,
      ...props
    },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_11304_13971)">
          <path
            d="M5.07864 9.35741C5.02685 9.0236 4.99997 8.68163 4.99997 8.33342C4.99997 4.65152 8.00438 1.66675 11.7105 1.66675C15.4166 1.66675 18.421 4.65152 18.421 8.33342C18.421 9.16514 18.2677 9.96129 17.9876 10.6955C17.9295 10.848 17.9004 10.9242 17.8872 10.9838C17.8741 11.0427 17.869 11.0842 17.8676 11.1446C17.8662 11.2056 17.8744 11.2727 17.891 11.407L18.2265 14.1322C18.2628 14.4272 18.2809 14.5747 18.2319 14.6819C18.1889 14.7759 18.1125 14.8505 18.0176 14.8913C17.9092 14.9379 17.7622 14.9164 17.4681 14.8733L14.8137 14.4842C14.6751 14.4639 14.6058 14.4537 14.5427 14.4541C14.4803 14.4544 14.4371 14.459 14.376 14.4719C14.3142 14.4849 14.2353 14.5144 14.0775 14.5735C13.3414 14.8492 12.5437 15.0001 11.7105 15.0001C11.362 15.0001 11.0197 14.9737 10.6856 14.9228M6.35965 18.3334C8.8304 18.3334 10.8333 16.2814 10.8333 13.7501C10.8333 11.2188 8.8304 9.16675 6.35965 9.16675C3.88891 9.16675 1.88597 11.2188 1.88597 13.7501C1.88597 14.2589 1.9669 14.7484 2.1163 15.2057C2.17945 15.399 2.21102 15.4957 2.22139 15.5617C2.2322 15.6306 2.2341 15.6694 2.23007 15.739C2.22621 15.8057 2.20952 15.8812 2.17613 16.032L1.66667 18.3334L4.16234 17.9926C4.29856 17.974 4.36667 17.9647 4.42615 17.9651C4.48877 17.9655 4.52201 17.9689 4.58343 17.9811C4.64176 17.9928 4.72847 18.0234 4.9019 18.0846C5.35883 18.2458 5.84926 18.3334 6.35965 18.3334Z"
            stroke="#344054"
            strokeWidth="1.66667"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_11304_13971">
            <rect width="20" height="20" fill="white" />
          </clipPath>
        </defs>
      </svg>
    );
  },
);

export default message_chat_circle;
