import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { setSelectedSettingTab } from "store/settingsWorkspaceSlice";
const TabItem = ({ item, pathname, closeSidebarSmallScreen }, key) => {
  const dispatch = useDispatch()
  const { isSidebarCollapsed } = useSelector((state) => state.navbarSlice);
  const { currentWorkspace } = useSelector((state) => state.authenticationSlice);
  const { sidebarCountData } = useSelector((state) => state.billingSlice);
  const { path, icon: Icon, name, iconShow, supportButton } = item;
  const route = pathname.startsWith("/setting")
    ? `/setting/${path}`
    : path === "workspaces"
      ? "/"
      : `/workspace/${currentWorkspace?.workspace_id}/${path}`;
  const showHideSupplier = () => {
    if (currentWorkspace) {
      if (currentWorkspace.role_name.includes("supplier")) {
        return false
      }
      else if (!currentWorkspace.role_name.includes("supplier") && currentWorkspace.invited_workspace_type === "supplier") {
        return false
      } else {
        return true
      }
    }
  }
  return (
    <>
      {path === "support" ? (
        <a href="mailto:support@fibervue.com" key={key}>
          <div
            className={`flex  items-center w-full py-2 rounded-md text-base font-medium gap-3 text-white ${pathname === route ? "items-center place-items-center bg-menu-item-surface-active" : "hover:items-center hover:place-items-center hover:bg-menu-item-surface-active"} ${isSidebarCollapsed ? "justify-center" : "justify-start"} ${!iconShow ? "pr-3" : "px-3"} `}
          >
            <div>
              {Icon && (
                <Icon
                  height={20}
                  width={20}
                  fillColor="var(--color-white)"
                  strokeColor="var(--color-white)"
                  className={`${iconShow ? "visible" : "hidden"}`}
                />
              )}
            </div>
            {!isSidebarCollapsed && (
              <>
                <div className="flex justify-between items-center w-full">
                  <div className={`text-base font-semibold transition-all duration-300`}>
                    {name}{" "}
                    {path === "workspaces" &&
                      currentWorkspace.invited_workspace_name &&
                      !currentWorkspace.is_owner ? (
                      <div
                        className="h-[22px] bg-white border border-gray-300  text-gray-900 px-1.5 py-[2px] inline-block font-medium text-xs rounded-[6px] ml-3 truncate"
                        style={{ boxShadow: "0px 1px 2px 0px rgba(10, 13, 18, 0.05)" }}
                      >
                        {currentWorkspace.invited_workspace_name}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div>
                    {!iconShow && (
                      <div
                        className={`py-[3px] px-2 rounded-full text-xs font-medium  tracking-wide  ${pathname === route ? "items-center place-items-center " : "bg-menu-item-surface-active"}`}
                      >
                        {
                          sidebarCountData && currentWorkspace ?
                            <>
                              {!showHideSupplier() &&
                                path === "suppliers"
                                ? sidebarCountData["sub_suppliers"]
                                : sidebarCountData[path] ?? ""}
                            </>
                            : 0
                        }
                      </div>
                    )}
                  </div>
                </div>
                {supportButton && (
                  <div
                    className={`py-[3px] w-auto  whitespace-nowrap rounded-md text-xs font-medium  flex px-1.5 gap-1.5   text-txt-secondary-secondary justify-center  items-center bg-bg-primary`}
                  >
                    <div className="w-1.5 h-1.5 rounded-full bg-btn-surface-primary" />
                    <div>Reply in 24h</div>
                  </div>
                )}
              </>
            )}
          </div>
        </a>
      ) : (
        <NavLink
          key={key}
          to={route}
          onClick={() => {
            if (path === "setting") {
              dispatch(setSelectedSettingTab("profile"));
            }
            if (window.innerWidth <= 767 && !isSidebarCollapsed) {
              closeSidebarSmallScreen()
            }
          }}
          className={`flex  items-center w-full py-2 rounded-md text-base font-medium gap-3 text-white ${pathname === route ? "items-center place-items-center bg-menu-item-surface-active" : "hover:items-center hover:place-items-center hover:bg-menu-item-surface-active"} ${isSidebarCollapsed ? "justify-center" : "justify-start"} ${!iconShow ? "pr-3" : "px-3"} `}
        >
          <div>
            {Icon && (
              <Icon
                height={20}
                width={20}
                fillColor="var(--color-white)"
                strokeColor="var(--color-white)"
                className={`${iconShow ? "visible" : "hidden"}`}
              />
            )}
          </div>

          {!isSidebarCollapsed && (
            <>
              <div className="flex justify-between items-center w-full">
                <div
                  className={`text-base font-semibold transition-all duration-300 flex items-center `}
                  >
                    
                  <div>
                    {currentWorkspace &&
                      !showHideSupplier() &&
                      path === "suppliers"
                      ? "Sub-Suppliers"
                        : name}
                      
                  </div>
                  {path === "workspaces" &&
                    currentWorkspace.invited_workspace_name &&
                    !currentWorkspace.is_owner ? (
                    <div
                      className="h-[22px] bg-white border border-gray-300  text-gray-900 px-1.5 py-[2px] inline-block font-medium text-xs rounded-[6px] ml-3"
                      style={{ boxShadow: "0px 1px 2px 0px rgba(10, 13, 18, 0.05)" }}
                    >
                      <div className="max-w-[85px] truncate">
                        {currentWorkspace.invited_workspace_name}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div>
                  {!iconShow && (
                    <div
                      className={`py-[3px] px-2 rounded-full text-xs font-medium  tracking-wide  ${pathname === route ? "items-center place-items-center " : "bg-menu-item-surface-active"}`}
                    >
                      {
                        sidebarCountData && currentWorkspace ?
                          <>
                            {!showHideSupplier() &&
                              path === "suppliers"
                              ? sidebarCountData["sub_suppliers"]
                              : sidebarCountData[path] ?? ""}
                          </>
                          : 0
                      }
                    </div>
                  )}
                </div>
              </div>
              {supportButton && (
                <div
                  className={`py-[3px] w-auto  whitespace-nowrap rounded-md text-xs font-medium  flex px-1.5 gap-1.5   text-txt-secondary-secondary justify-center  items-center bg-bg-primary`}
                >
                  <div className="w-1.5 h-1.5 rounded-full bg-btn-surface-primary" />
                  <div>Reply in 24h</div>
                </div>
              )}
            </>
          )}
        </NavLink>
      )}
    </>
  );
};

export default TabItem;
