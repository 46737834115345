import React from "react";

const SubscribeLock = React.forwardRef(
  (
    { width = "21", height = "20", viewBox = "0 0 21 20", fill = "none", className, ...props },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.6666 8.33333V6.66667C14.6666 4.36548 12.8011 2.5 10.4999 2.5C8.19873 2.5 6.33325 4.36548 6.33325 6.66667V8.33333M10.4999 12.0833V13.75M7.83325 17.5H13.1666C14.5667 17.5 15.2668 17.5 15.8016 17.2275C16.272 16.9878 16.6544 16.6054 16.8941 16.135C17.1666 15.6002 17.1666 14.9001 17.1666 13.5V12.3333C17.1666 10.9332 17.1666 10.2331 16.8941 9.69836C16.6544 9.22795 16.272 8.8455 15.8016 8.60582C15.2668 8.33333 14.5667 8.33333 13.1666 8.33333H7.83325C6.43312 8.33333 5.73306 8.33333 5.19828 8.60582C4.72787 8.8455 4.34542 9.22795 4.10574 9.69836C3.83325 10.2331 3.83325 10.9332 3.83325 12.3333V13.5C3.83325 14.9001 3.83325 15.6002 4.10574 16.135C4.34542 16.6054 4.72787 16.9878 5.19828 17.2275C5.73306 17.5 6.43312 17.5 7.83325 17.5Z"
          stroke="white"
          strokeWidth="1.66667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  },
);
SubscribeLock.displayName = "SubscribeLock";

export default SubscribeLock;
