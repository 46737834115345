import { useDispatch } from "react-redux";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { forgetpasswordSchema } from "validation/auth.validation";
import { zodResolver } from "@hookform/resolvers/zod";
import toast from "react-hot-toast";
import { forgetpassword } from "services/auth.services";
import useCustomToaster from "./useCustomToaster";

const useForgetPassword = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { showCustomToast } = useCustomToaster();
  const {
    register,
    handleSubmit,
    reset,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
    },
    resolver: zodResolver(forgetpasswordSchema),
  });

  // Mutation hook for API call
  const mutation = useMutation({
    mutationKey: ["auth", "forgetpassword"],
    mutationFn: forgetpassword,
    onError: handleError, // Handle error response
  });

  /**
   * Handler for form submission
   * @param {Object} data - Form data
   */
  const handleSubmitFn = (data) =>
    mutation.mutate(data, {
      onSuccess: (response) => {
        const data = response.data;
        if (data.status) {
          const email = getValues("email");
          reset(); // Reset form fields
          queryClient.invalidateQueries({
            queryKey: ["auth", "forgetpassword"],
          });
          toast.dismiss("forgetpassword");
          showCustomToast({
            title: "You’ve got mail!",
            subtitle: "We've sent an email with instructions to reset your password. Please check your inbox and follow the steps to regain access to your account."
          });
          navigate(`/sent-email-success/${btoa(`${email}`)}`, { replace: true });
        }
      },
    });
  const resendInstruction = (email) => {
    mutation.mutate(
      { email },
      {
        onSuccess: (response) => {
          const data = response.data;
          if (response.status || data.status) {
            queryClient.invalidateQueries({
              queryKey: ["auth", "forgetpassword"],
            });

            toast.dismiss("forgetpassword");
            showCustomToast({
              title: `New code sent!`,
              subtitle: `If you still haven’t received the email, please`,
              url: "contact us.",
            });
          }
        },
      },
    );
  };

  /**
   * Handle error response from API call
   * @param {Object} error - Error object
   */
  function handleError(error) {
    const errorMessage = error?.response?.data?.errors?.email || error.message;
    const errorMessages = error?.response?.data?.errors;
    const toastMessages = errorMessages ? Object.values(errorMessages).flat() : [errorMessage];
    toastMessages.forEach((errorMessage) => {
      showCustomToast({
        title: errorMessage,
      });
    });
  }

  // Return state and handlers
  return {
    errors, // Form errors
    isLoading: mutation.isPending, // Loading state
    register, // Form registration
    handleSubmit: handleSubmit(handleSubmitFn), // Form submission handler
    resendInstruction,
  };
};

export default useForgetPassword;
