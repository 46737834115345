import React from "react";

const starsSignup = React.forwardRef(
  (
    {
      width = "20",
      height = "20",
      viewBox = "0 0 20 20",
      fill = "none",
      strokeColor = "#344054",
      className,
      ...props
    },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_12268_846)">
          <path
            d="M3.75008 18.3334V14.1667M3.75008 5.83335V1.66669M1.66675 3.75002H5.83341M1.66675 16.25H5.83341M10.8334 2.50002L9.38827 6.25741C9.15326 6.86843 9.03575 7.17394 8.85302 7.43093C8.69107 7.65869 8.49208 7.85768 8.26432 8.01963C8.00734 8.20236 7.70182 8.31986 7.0908 8.55487L3.33342 10L7.0908 11.4452C7.70183 11.6802 8.00734 11.7977 8.26432 11.9804C8.49208 12.1424 8.69107 12.3414 8.85302 12.5691C9.03575 12.8261 9.15326 13.1316 9.38827 13.7426L10.8334 17.5L12.2786 13.7426C12.5136 13.1316 12.6311 12.8261 12.8138 12.5691C12.9758 12.3414 13.1748 12.1424 13.4025 11.9804C13.6595 11.7977 13.965 11.6802 14.576 11.4452L18.3334 10L14.576 8.55487C13.965 8.31986 13.6595 8.20236 13.4025 8.01963C13.1748 7.85768 12.9758 7.65869 12.8138 7.43093C12.6311 7.17394 12.5136 6.86843 12.2786 6.2574L10.8334 2.50002Z"
            stroke={strokeColor}
            strokeWidth="1.66667"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_12268_846">
            <rect width="20" height="20" fill="white" />
          </clipPath>
        </defs>
      </svg>
    );
  },
);

export default starsSignup;
