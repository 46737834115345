// login form validation using zod
import { emailValidation, passwordPattern } from "utils/constant";
import * as z from "zod";
export const loginSchema = z.object({
  email: emailValidation,
  password: z
    .string({ required_error: "Required", invalid_type_error: "Password is invalid" })
    .min(8),
  remember_me: z.boolean().optional(),
});

export const registerSchema = z
  .object({
    firstName: z
      .string({
        required_error: "Required",
      })
      .min(3, { message: "Enter at least 3 characters" }).max(100, { message: "First name must not exceed 100 characters" }),
    lastName: z
      .string({
        required_error: "LastName is required",
      })
      .min(1, { message: "Enter at least 1 characters" }).max(100, { message: "Last name must not exceed 100 characters" }),
    email: emailValidation,
    telephone: z
      .string({
        required_error: "Required",
        invalid_type_error: "Required",
      })
      .min(7, "Required"),
    password: z.string().refine((value) => passwordPattern.test(value), {
      message: "Required",
    }),
    password_confirmation: z.string().min(1, { message: "Required" }),
    companyName: z
      .string({
        required_error: "Required",
      })
      .min(3, { message: "Enter at least 3 characters" }).max(100, { message: "Company name must not exceed 100 characters" }),
    address: z
      .string({
        required_error: "Required",
        invalid_type_error: "Required",
      })
      .min(1, { message: "Required" }),
    registration_number: z
      .string({
        required_error: "Required",
      })
      .min(1, { message: "Required" }),
    company_type: z.object(
      {
        label: z.string({ message: "Required" }),
        value: z.string({ message: "Required" }),
      },
      {
        required_error: "Required",
        invalid_type_error: "Required",
      },
    ),
    street: z.string().optional().nullable(true),
    zip: z.string().optional().nullable(true),
    region: z.string().optional().nullable(true),
    country: z.string().optional().nullable(true),
    country_code: z.string().optional().nullable(true),
  })
  .refine((data) => data.password === data.password_confirmation, {
    message: "Password do not match",
    path: ["password_confirmation"],
  });

export const forgetpasswordSchema = z.object({
  email: emailValidation,
});

export const resetpasswordSchema = z
  .object({
    password: z.string().refine((value) => passwordPattern.test(value), {
      message: "Required",
    }),
    email: emailValidation,
    token: z.string().min(1, { message: "Token is required" }),
    password_confirmation: z.string().min(8, { message: "Password do not match" }),
  })
  .refine((data) => data.password === data.password_confirmation, {
    message: "Password do not match",
    path: ["password_confirmation"],
  });
