import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSidebarAllCounts, getUserSubscriptionDetails } from "services/subscription.services";
import { setCurrentPlan, setSidebarCountData } from "store/billingSlice";

const useUserCurrentSubscription = () => {
  const { currentWorkspace } = useSelector((state) => state.authenticationSlice);
  const { refetchBillingData } = useSelector((state) => state.billingSlice);
  const dispatch = useDispatch();

  const {
    data: sidebarCountQuery,
    isLoading: isLoadingSidebarCountQuery,
    refetch: fetchingSidebarCountQuery,
    error: sidebarCountFetchError,
  } = useQuery({
    queryKey: ["sidebarcount", currentWorkspace?.workspace_id],
    queryFn: () => getSidebarAllCounts({ workspace_id: parseInt(currentWorkspace?.workspace_id) }),
    enabled: !!currentWorkspace,
  });

  const {
    data: userSubscriptionQueryData,
    isLoading: isLoadingSubsQuery,
    refetch,
    isRefetching,
    error: subscriptionFetchError,
  } = useQuery({
    queryKey: ["userSubscriptionQueryData", currentWorkspace],
    queryFn: async () => {
      const workspaceId = parseInt(currentWorkspace?.workspace_id);
      return getUserSubscriptionDetails(workspaceId);
    },
    enabled: !!currentWorkspace,
    select: (res) => res.data,
  });

  useEffect(() => {
    if (userSubscriptionQueryData && !isLoadingSubsQuery && !subscriptionFetchError) {
      const userSubscriptionData = userSubscriptionQueryData.data;
      dispatch(setCurrentPlan(userSubscriptionData));
    }
  }, [userSubscriptionQueryData, isLoadingSubsQuery, subscriptionFetchError, dispatch]);

  useEffect(() => {
    if (sidebarCountQuery && !isLoadingSidebarCountQuery && !sidebarCountFetchError) {
      const countData = sidebarCountQuery.data;
      dispatch(setSidebarCountData(countData));
    }
  }, [sidebarCountQuery, isLoadingSidebarCountQuery, sidebarCountFetchError, dispatch]);

  useEffect(() => {
    if (refetchBillingData) {
      refetch();
      fetchingSidebarCountQuery();
    }
  }, [refetchBillingData]);

  return { refetch, isRefetching };
};

export default useUserCurrentSubscription;
