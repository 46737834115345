import * as z from "zod";
export const createSubSupplierSchema = z.object({
  name: z
    .string({
      required_error: "Company name is required",
    })
    .min(3, { message: "Enter at least 3 characters" }).max(100, { message: "Company name must not exceed 100 characters" }),
  street: z.string().min(1, {
    message: "Street is required",
  }),
  country: z.string().min(1, {
    message: "Country is required",
  }),
  region: z.string().min(1, {
    message: "City is required",
  }),
    address: z.string().min(1, {
      message: "Address is required",
    }),
  sub_tier_id: z.object(
    {
     label: z.string({ message: "Required" }),
     value: z.number({ message: "Required" }),
    },
    {
      required_error: "Required",
      invalid_type_error: "Required",
    },
      ),
  workspace_id: z.number({
    required_error: "Workspace id required",
    invalid_type_error: "Workspace id required",
  }),
});
