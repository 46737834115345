import React from "react";

const ButtonClose = React.forwardRef(
  (
    {
      width = "40",
      height = "40",
      viewBox = "0 0 40 40",
      fill = "none",
      xmlns = "http://www.w3.org/2000/svg",
      className,
      onClick = () => { },
      ...props
    },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className, onClick }}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M26 14L14 26M14 14L26 26"
          stroke="#A4A7AE"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  },
);

export default ButtonClose;
