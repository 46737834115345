import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { retailer } from "services/retailer.services";
import { setRefetchBillingData } from "store/billingSlice";
import { businessPartnerList } from "utils/fakeData";
import { invitationFromWorkspaceSchema, invitationSchema } from "validation/invitation.validation";
import useCustomToaster from "./useCustomToaster";
import { toggleIsInvitationCreateModalOpen } from "store/accountDashboardSlice";
import {
  setRefetchAgentData,
  setRefetchRetailerData,
  setRefetchSupplierData,
} from "store/orderSlice";

const useInvitation = ({ invitationListRefetch }) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { showCustomToast } = useCustomToaster();
  const { refetchBillingData } = useSelector((state) => state.billingSlice);
  const { currentWorkspace } = useSelector((state) => state.authenticationSlice);
  const { actionableCompanyType, isInvitationCreateModalOpen } = useSelector(
    (state) => state.accountDashboardSlice,
  );
  const { refetchRetailerData, refetchSupplierData, refetchAgentData } = useSelector(
    (state) => state.orderSlice,
  );
  const {
    register,
    handleSubmit,
    reset:resetForm,
    setValue,
    formState: { errors },
    getValues,
    control,
    clearErrors,
  } = useForm({
    defaultValues: {
      workspace_id: parseInt(currentWorkspace?.workspace_id),
      company_name: "",
      first_name: "",
      last_name: "",
      email: "",
      company_type: null,
      details: "",
    },
    resolver: zodResolver(invitationFromWorkspaceSchema),
  });

  const mutation = useMutation({
    mutationKey: ["invitation", "create"],
    mutationFn: retailer,
    onMutate: () => {
      showCustomToast(
        {
          title: "Sending invitation...",
        },
        "loadingInvitation",
      );
    },
    onSuccess: handleSuccess,
    onError: handleError,
    onSettled: () => {
      toast.dismiss("loadingInvitation");
    },
  });

  const handleSubmitFn = (data) => {
    const formData = {
      ...data,
      company_type: data.company_type.value,
    };
    mutation.mutate(formData);
  };

  function handleSuccess(response) {
    toast.dismiss("loadingInvitation");
    const data = response.data;
    resetForm();
    invitationListRefetch();
    if (!refetchBillingData) {
      dispatch(setRefetchBillingData(true));
    }
    if (isInvitationCreateModalOpen) {
      dispatch(toggleIsInvitationCreateModalOpen());
    }
    if (refetchAgentData) {
      dispatch(setRefetchAgentData(false));
    }
    if (refetchRetailerData) {
      dispatch(setRefetchRetailerData(false));
    }
    if (refetchSupplierData) {
      dispatch(setRefetchSupplierData(false));
    }
    queryClient.invalidateQueries({
      queryKey: ["invitation", "create"],
    });
    showCustomToast({
      title: data.message,
    });
    navigate(pathname);
  }

  function handleError(error) {
    toast.dismiss("loadingInvitation");
    const errorMessage = error?.response?.data?.message || error.message;
    const errorMessages = error?.response?.data?.errors;
    const toastMessages = errorMessages ? Object.values(errorMessages).flat() : [errorMessage];
    toastMessages.forEach((item) =>
      showCustomToast({
        title: item,
      }),
    );
  }

  const handleChangeCompanyType = (selectedOption) => {
    if (selectedOption) {
      setValue("company_type", selectedOption);
    } else {
      setValue("company_type", null);
    }
  };

  useEffect(() => {
    if (actionableCompanyType) {
      const findcompanyType = businessPartnerList.find(
        (item) => item.value === actionableCompanyType,
      );
      setValue("company_type", findcompanyType);
    }
  }, [actionableCompanyType]);

  return {
    errors,
    isPending: mutation.isPending,
    isSuccess: mutation.isSuccess,
    register,
    handleSubmit: handleSubmit(handleSubmitFn),
    handleChangeCompanyType,
    setValue,
    getValues,
    clearErrors,
    control,
  };
};

export default useInvitation;
