import React from "react";
import { WarningIcon } from "assests";

const InstanceCard = ({ errorMessage }) => {
  const errorDataList = [
    {
      title: "Insufficient Funds",
      subText: "Your card has insufficient funds. Please use a different card or contact your bank.",
    }, {
      title: "Expired Card",
      subText: "Your card has expired. Please update your card details.",
    },

    {
      title: "Invalid Card Number",
      subText: "The card number you entered is invalid. Please check and try again.",
    },
    {
      title: "Incorrect CVV",
      subText: "The CVV code you entered is incorrect. Please check and try again.",
    },
    {
      title: "Card Declined",
      subText: "Your card was declined. Please contact your bank for more information.",
    },
    {
      title: "Exceeded Limit",
      subText: "Your card was declined for making repeated attempts too frequently or exceeding its amount limit.",
    }
  ];

  const findError = (apiErrorMessage) => {
    const errorMapping = {
      "Your card's security code is incorrect.": "Incorrect CVV",
      "Your card was declined.": "Card Declined",
      "Your card has insufficient funds.": "Insufficient Funds",
      "Your card number is invalid.": "Invalid Card Number",
      "Your card was declined for making repeated attempts too frequently or exceeding its amount limit.": "Exceeded Limit"
    };

    // First check against known variations
    const mappedTitle = errorMapping[apiErrorMessage];

    // If we have a specific mapping, use it to find the error
    if (mappedTitle) {
      const error = errorDataList?.find((error) => error?.title === mappedTitle);
      if (error) {
        return error;
      }
    }

    // If no match is found, return a custom object with the API message as both title and subText
    return {
      title: "Error",
      subText: apiErrorMessage,
    };
  };

  const error = errorMessage && findError(errorMessage);
  return (
    <>
      {error &&
        <div className="w-full ">
          <div className="grid ">
            <div className="flex justify-start items-start gap-3">
              <div>
                <WarningIcon />
              </div>
              <div>
                <div className="flex flex-col gap-1">
                  <p className="font-semibold text-sm  text-global-title leading-5">
                    {error && error.title}
                  </p>
                  <p className="text-global-title-2 leading-5  lg:text-sm ">
                    {error && error.subText}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </>
  );
};

export default InstanceCard;
