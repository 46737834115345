import React from "react";

const AddPeople = React.forwardRef(
  (
    {
      width = "16",
      height = "16",
      viewBox = "0 0 16 16",
      fill = "none",
      strokeColor = "#717680",
      className,
      ...props
    },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Content">
          <path
            id="Vector"
            d="M8.00004 10.3333H5.00004C4.06967 10.3333 3.60448 10.3333 3.22595 10.4482C2.37368 10.7067 1.70673 11.3736 1.4482 12.2259C1.33337 12.6044 1.33337 13.0696 1.33337 14M12.6667 14V10M10.6667 12H14.6667M9.66671 5C9.66671 6.65685 8.32356 8 6.66671 8C5.00985 8 3.66671 6.65685 3.66671 5C3.66671 3.34315 5.00985 2 6.66671 2C8.32356 2 9.66671 3.34315 9.66671 5Z"
            stroke={strokeColor}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </svg>
    );
  },
);

export default AddPeople;
