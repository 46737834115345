import React from "react";

const MoreOptions = React.forwardRef(
  (
    {
      width = "24",
      height = "24",
      viewBox = "0 0 24 24",
      fillColor = "#9FA2B4",
      className,
      fill,
      ...props
    },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.6665 13C13.2188 13 13.6665 12.5523 13.6665 12C13.6665 11.4477 13.2188 11 12.6665 11C12.1142 11 11.6665 11.4477 11.6665 12C11.6665 12.5523 12.1142 13 12.6665 13Z"
          stroke={fillColor}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.6665 6C13.2188 6 13.6665 5.55228 13.6665 5C13.6665 4.44772 13.2188 4 12.6665 4C12.1142 4 11.6665 4.44772 11.6665 5C11.6665 5.55228 12.1142 6 12.6665 6Z"
          stroke={fillColor}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.6665 20C13.2188 20 13.6665 19.5523 13.6665 19C13.6665 18.4477 13.2188 18 12.6665 18C12.1142 18 11.6665 18.4477 11.6665 19C11.6665 19.5523 12.1142 20 12.6665 20Z"
          stroke={fillColor}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  },
);

export default MoreOptions;
