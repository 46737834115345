import { BackSlashIcon, DottedIcon, HomeIcon } from "assests";
import React from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";

const BreadCrumb = ({ text, textAnother, secondaryLink }) => {
  const { currentWorkspace } = useSelector((state) => state.authenticationSlice);
  const location = useLocation(); // Get current location

  // Check if the current route is "settings"
  const isSettingsRoute = location.pathname.includes("/setting");

  if (isSettingsRoute) return null;
  return (
    <div className="overflow-x-auto">
      <div className="h-7 justify-start items-center inline-flex">
        <div className="justify-start items-center gap-2 flex">
          <div className="p-1">
            <Link to={"/"}>
              {currentWorkspace &&
                currentWorkspace.invited_workspace_name &&
                !currentWorkspace.is_owner ? (
                <DottedIcon strokeColor="#717680" />
              ) : (
                <HomeIcon />
              )}
            </Link>
          </div>
          <div>
            <BackSlashIcon />
          </div>
          {currentWorkspace &&
            currentWorkspace.invited_workspace_name &&
            !currentWorkspace.is_owner && (
              <Link
                className="text-sm font-semibold cursor-pointer leading-5 text-[#535862] whitespace-nowrap justify-start items-center gap-2 flex"
                to={`/workspace/${currentWorkspace?.workspace_id}/report`}
              >
                {currentWorkspace.invited_workspace_name} <BackSlashIcon />
              </Link>
            )}
          {textAnother && (
            <div
              onClick={secondaryLink}
              className="text-sm font-semibold cursor-pointer leading-5 text-[#535862] whitespace-nowrap justify-start items-center gap-2 flex"
            >
              {textAnother} <BackSlashIcon />
            </div>
          )}

          <div className="px-2 py-1 bg-global-theme h-7 rounded-md justify-center items-center flex">
            <div className="text-breadcrumb-txt-bg whitespace-nowrap text-sm  tracking-[0.005em] font-semibold  leading-tight">
              {text}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BreadCrumb;
