import api from "config/axios";

export const createOrder = (data) => api.post("/orders", data);

export const uploadOrderImage = async (data) => {
  try {
    const response = await api.post("/orders/upload-image", data, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const updateOrder = async (data) => {
  try {
    const response = await api.put(`/orders/${parseInt(data.actionableOrderId)}`, data);
    return response;
  } catch (error) {
    throw error;
  }
};

export const getOrders = async (query) => {
  const params = new URLSearchParams();
  try {
    Object.keys(query).forEach((key) => {
      const value = query[key];
      if (Array.isArray(value)) {
        value.forEach((item) => {
          params.append(`${key}[]`, item);
        });
      } else if (value) {
        params.append(key, value);
      }
    });
    const response = await api.get(`/orders?${params}`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const createBulkOrder = async (data) => {
  try {
    const response = await api.post("/orders/import-csv", data, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const getOrderDetails = async (id) => {
  try {
    const response = await api.get(`/orders/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const unLinkForwarderData = async (id) => {
  try {
    const response = await api.delete(`/forwarder/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const unLinkSubsupplierData = async (id) => {
  try {
    const response = await api.delete(`/unlink-subsupplier/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
};


export const createOrderDocuments = async (data) => {
  try {
    const response = await api.post("/order-documents", data, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const updateOrderDocuments = async (data) => {
  try {
    const response = await api.post(`/order-documents/${data.id}`, data.body, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const getOrderDocumentsById = async (id) => {
  try {
    const response = await api.get(`/order-documents/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
};
export const deleteOrder = async (id) => {
  try {
    const response = await api.delete(`/orders/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const bulkDeleteOrders = async (data) => {
  try {
    const response = await api.post(`/orders/bulk-delete`, { ...data });
    return response;
  } catch (error) {
    throw error;
  }
};

export const bulkCancelInvitationOrders = async (data) => {
  try {
    const response = await api.post(`/orders/bulk-cancel-order-invitations`, { ...data });
    return response;
  } catch (error) {
    throw error;
  }
};

export const bulkDuplicateOrders = async (data) => {
  try {
    const response = await api.post(`/orders/bulk-duplicate`, { ...data });
    return response;
  } catch (error) {
    throw error;
  }
};

export const archiveOrders = async (data) => {
  try {
    const response = await api.post(`/archive-order`, { ...data });
    return response;
  } catch (error) {
    throw error;
  }
};

export const bulkCancelOrders = async (data) => {
  try {
    const response = await api.post(`/orders/bulk-cancel-orders`, { ...data });
    return response;
  } catch (error) {
    throw error;
  }
};

export const downloadOrdersAsCSV = async (data) => {
  try {
    const response = await api.get(`/orders?workspace_id=${data.workspace_id}&download=yes`, data);
    return response;
  } catch (error) {
    throw error;
  }
};

export const getAllOrderCompany = async (query) => {
  const params = new URLSearchParams();
  try {
    Object.keys(query).forEach((key) => {
      if (query[key]) {
        params.append(key, query[key]);
      }
    });
    const response = await api.get(`/order-filter/company?${params}`);
    return response;
  } catch (error) {
    throw error;
  }
};
