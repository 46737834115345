import api from "config/axios";
export const agent = (data) => api.post("/invitations", data);

export const getAgents = async (query) => {
  const params = new URLSearchParams();
  try {
    Object.keys(query).forEach((key) => {
      const value = query[key];
      if (Array.isArray(value)) {
        value.forEach((item) => {
          params.append(`${key}[]`, item);
        });
      } else if (value) {
        params.append(key, value);
      }
    });
    const response = await api.get(`/agents?${params}`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const downloadAgentAsCSV = async (params) => {
  try {
    const response = await api.get(`/agents`, {
      params: { ...params, download: "yes" },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const getAllAgents = async (query) => {
  const params = new URLSearchParams();
  try {
    Object.keys(query).forEach((key) => {
      if (query[key]) {
        params.append(key, query[key]);
      }
    });
    const response = await api.get(`/all-agents?${params}`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const getOrderAgents = async (query) => {
  const params = new URLSearchParams();
  try {
    Object.keys(query).forEach((key) => {
      if (query[key]) {
        params.append(key, query[key]);
      }
    });
    const response = await api.get(`/order-agents?${params}`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const getAgentCompany = async (query) => {
  const params = new URLSearchParams();
  try {
    Object.keys(query).forEach((key) => {
      if (query[key]) {
        params.append(key, query[key]);
      }
    });
    const response = await api.get(`/agents-filter/company?${params}`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const bulkDeleteAgents = async (data) => {
  try {
    const response = await api.post(`/agents/bulk-delete`, { agents: data });
    return response;
  } catch (error) {
    throw error;
  }
};
