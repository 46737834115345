import { LockOutLinedIcon, PlanIcon } from "assests";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CommonDiscardButton from "./CommonDiscardButton";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import useCustomToaster from "hooks/useCustomToaster";
import toast from "react-hot-toast";
import { requestForPermission } from "services/global.services";
import LimitMembershipCard from "pages/Settings/UserManagement/LimitMembershipCard";
const SettingsPermissionModal = ({ actionText, showUpgradeModal, closeLimitModal }) => {
  const navigate = useNavigate();
  const { showCustomToast } = useCustomToaster();
  const queryClient = useQueryClient();
  const { isSidebarCollapsed } = useSelector((state) => state.navbarSlice);
  const { currentWorkspace, subscription, subscription_status, user } = useSelector(
    (state) => state.authenticationSlice,
  );
  const { checkPlanLimitation, currentPlan } = useSelector((state) => state.billingSlice);

  const requestForPermissionMutation = useMutation({
    mutationKey: ["user", "requestForPermission"],
    mutationFn: requestForPermission,
    onMutate: () => {
      showCustomToast(
        {
          title: "Requesting for permission...",
        },
        "requestForPermission",
      );
    },
    onSuccess: (res) => {
      toast.dismiss("requestForPermission");
      showCustomToast({
        title: "Successfully requested for admin access",
      });
      queryClient.invalidateQueries("protectedResource");
    },
    onError: (error) => {
      toast.dismiss("requestForPermission");
      const errormessage = error.response?.data?.message || error.message || "Something went wrong";
      showCustomToast({
        title: errormessage,
      });
    },
  });

  const handleRequestForPermission = () => {
    requestForPermissionMutation.mutate();
  };

  return (
    <>
      {currentWorkspace &&
        (currentWorkspace.role_name.includes("editor") || currentWorkspace.role_name.includes("viewer")) && (
          <div
            className={`fixed inset-0 z-[51] px-4 md:px-0 transition-opacity bg-[#FFFFFFCC] backdrop-blur-sm flex justify-center items-center bg-opacity-50 ${isSidebarCollapsed ? "left-0 md:left-[68px]" : "md:left-[296px]"}`}
          >
            <div className="md:m-4">
              <CommonDiscardButton
                title="Permission required"
                subText1="This section is restricted to admins only. Please request access from your administrator if you need to view this page."
                className="max-w-[544px] border border-border-secondary !shadow-none"
                IconComponent={LockOutLinedIcon}
                confirmButtonText="Request Permission"
                confirmButtonVariant="primary"
                onConfirmClick={handleRequestForPermission}
                backToDashboard={() => {
                  navigate("/");
                }}
              />
            </div>
          </div>
        )}
      {actionText &&
        currentWorkspace &&
        currentWorkspace.is_owner &&
        (!subscription ||
          (subscription && (!subscription_status || subscription_status === "canceled"))) && (
          <div
            className={`fixed inset-0 z-[51] px-4 md:px-0 transition-opacity bg-[#FFFFFFCC] backdrop-blur-sm flex justify-center items-center bg-opacity-50 ${isSidebarCollapsed ? "left-0 md:left-16" : "md:left-[292px]"}`}
          >
            <CommonDiscardButton
              title="Upgrade Required"
              subText1={`To add more ${actionText} to your team, you need to upgrade to a paid plan. Unlock the ability to expand your team and access advanced features by upgrading now.`}
              className="max-w-[544px] border border-border-secondary !shadow-none"
              confirmButtonClassName="w-full"
              IconComponent={LockOutLinedIcon}
              confirmButtonText="Upgrade Now"
              confirmButtonVariant="primary"
              onConfirmClick={() =>
                navigate(`/workspace/${currentWorkspace?.user_workspace_id}/setting?selectedTab=plan`)
              }
            />
          </div>
        )}

      {actionText === "members" &&
        showUpgradeModal &&
        currentWorkspace &&
        currentWorkspace.is_owner &&
        checkPlanLimitation &&
        !checkPlanLimitation.canCreateUser && (
          <div
            className={`fixed inset-0 z-[51] px-4 md:px-0 transition-opacity bg-[#444656] flex justify-center items-center bg-opacity-50 ${isSidebarCollapsed ? "left-0 md:left-16" : "md:left-[250px]"}`}
          >
            <LimitMembershipCard
              closeModal={closeLimitModal}
              title="Member limit reached"
              subText1={`You cannot add a new member as you have reached the maximum limit of ${currentPlan?.plan?.package?.users} members in your ${currentPlan?.plan?.name?.toUpperCase()} plan. Please reduce your usage or ${currentPlan?.plan?.name?.toLowerCase() === "premium" ? "contact sales" : "upgrade your plan"}.`}
              className="max-w-[544px]"
              confirmButtonClassName="w-full"

              IconComponent={PlanIcon}
              confirmButtonText={
                currentPlan?.plan?.name?.toLowerCase() === "premium"
                  ? "Contact Sales"
                  : "Upgrade Now"
              }
              confirmButtonVariant="primary"
              onConfirmClick={() =>
                currentPlan?.plan?.name?.toLowerCase() === "premium"
                  ? (window.location = "mailto:sales@fibervue.com")
                  : navigate(
                    `/workspace/${currentWorkspace?.user_workspace_id}/setting?selectedTab=plan`,
                  )
              }
            />
          </div>
        )}
    </>
  );
};

export default SettingsPermissionModal;
