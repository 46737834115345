import React from "react";

const UploadImg = React.forwardRef(
    (
        {
            width = "20",
            height = "20",
            viewBox = "0 0 20 20",
            fill = "none",
            strokeColor = "#344054",
            className,
            ...props
        },
        ref,
    ) => {
        return (
            <svg
                ref={ref}
                {...{ width, height, viewBox, fill, className }}
                {...props}
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M6.66663 13.3333L9.99996 10M9.99996 10L13.3333 13.3333M9.99996 10V17.5M16.6666 13.9524C17.6845 13.1117 18.3333 11.8399 18.3333 10.4167C18.3333 7.88536 16.2813 5.83333 13.75 5.83333C13.5679 5.83333 13.3975 5.73833 13.3051 5.58145C12.2183 3.73736 10.212 2.5 7.91663 2.5C4.46485 2.5 1.66663 5.29822 1.66663 8.75C1.66663 10.4718 2.36283 12.0309 3.48908 13.1613"
                    stroke={strokeColor}
                    strokeWidth="1.66667"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        );
    },
);

export default UploadImg;
