import { Cross, Plus } from "assests";
import { forwardRef } from "react";
import { Controller, useWatch } from "react-hook-form";
import { PiCaretDownBold } from "react-icons/pi";
import ReactSelect, { components, createFilter } from "react-select";
import { Button } from "./Button";
import Divider from "./Divider";

const CustomSelect = forwardRef((props, ref) => {
  const {
    control,
    name,
    value,
    placeholder,
    options,
    handleOnChange,
    icon,
    style,
    onInputChange,
    isClearable,
    components,
    isMulti,
    iconLeft,
    isDisabled,
    isViewMode,
    isViewMode1,
    isError,
    isWatchedValueNeed = true,
    getOptionLabel,
    iconSize,
  } = props;

  const customStyles = {
    menu: (provided) => ({
      ...provided,
      textAlign: "left",
      cursor: "pointer",
      backgroundColor: "var(--color-input-surface-background)",
      zIndex: 9,
      borderRadius: "8px",
      padding: "4px 6px",
      boxShadow: "0 5px 12px rgba(0, 0, 0, 0.1)",
      border: "var(--color-global-divider)",
      minWidth: style.minWidth && style.minWidth,
      overflowY: "clip",
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: "white",
      borderRadius: "6px",
      marginRight: "6px",
      marginLeft: 0,
      // marginTop: 0,
      marginBottom: 0,
      minWidth: style.minWidth && style.minWidth,
      overflowY: "clip",
      border: "1px solid #D0D5DD",
      boxShadow: "0 1px 2px 0 rgba(16, 24, 40, 0.05)",
      gap: "2px",
      whiteSpace: "nowrap", // Prevents wrapping
      textOverflow: "ellipsis", // Displays ellipsis
      overflow: "hidden", // Hides overflow content
      alignItems: "center",
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      fontSize: "12px",
      fontWeight: "500px",
      color: "#344054", // Set the text color to white
    }),

    multiValueRemove: (provided) => ({
      ...provided,
      iconSize: "16px",
      color: "#98A2B3", // Set the cross icon color to white
      cursor: "pointer", // Change cursor on hover
      backgroundColor: "transparent", // Set background color to transparent
      ":hover": {
        backgroundColor: "transparent", // Change background color when hovering over the icon
      },
      padding:"0px 4px 0px 0px",
      alignItems: "center",
      justifyContent: "center",
    }),
    control: (provided) => ({
      ...provided,
      height: style.noHeight ? "100%" : "44px",
      padding: style?.padding ? style?.padding : "10px 12px",
      boxShadow: "0 1px 2px 0 rgba(16, 24, 40, 0.05)",
      textAlign: "left",
      border: isDisabled
        ? "1px solid #D5D7DA"
        : style?.border
          ? style?.border
          : isError
            ? "1px solid #D92D20"
            : "1px solid #D5D7DA",

      outline: "none",
      borderRadius: style?.borderRadius ? style?.borderRadius : "8px",
      backgroundColor: isDisabled
        ? "#F7F8FC"
        : isError
          ? "#FEF3F2"
          : "var(--color-input-surface-background)",
      "&:hover": {
        backgroundColor: "#F7F8FC",
      },
      justifyContent: "center",
      alignItems: "center",
    }),
    input: (provided) => ({
      ...provided,
      fontSize: "16px",
      color: style?.color ? style?.color : "var(--color-input-text-data)",
      paddingLeft: iconLeft ? "20px" : "",
      overflowX: "hidden",
      textOverflow: "ellipsis",
      "&:hover": {
        boxShadow: "none",
        outline: "none",
      },
      "&:focus": {
        boxShadow: "none",
        outline: "none",
      },
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "var(--color-gray-700)",
      fontSize: "16px",
      fontWeight: 400,
      opacity: style?.opacity ? style?.opacity : "1",
      paddingLeft: iconLeft ? "20px" : "",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: style?.color ? style?.color : "var(--color-input-text-data)",
      fontSize: "16px",
      paddingLeft: iconLeft ? "20px" : "",
      textTransform: "capitalize",
      placeholder: "16px",
    }),

    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "var(--color-option-surface-hover)" : "initial",
      fontWeight: state.isSelected ? 500 : 400,
      "&:hover": {
        backgroundColor: state.isSelected
          ? "var(--color-option-surface-hover)"
          : "var(--color-option-surface-hover)",
        fontWeight: 500,
      },
      color: "var(--color-option-text-default)",
      textTransform: "capitalize",
      fontSize: "16px",
      borderRadius: "4px",
      marginBottom: "8px",
      cursor: "pointer",
    }),
    iconLeft: (provided) => ({
      ...provided,
      position: "absolute",
      left: 12, // Adjust the left padding as needed
      top: "50%",
      transform: "translateY(-50%)",
    }),
  };
  const renderSelectedOptions = () => {
    if (value && value?.length > 0) {
      return value?.map((val) => val?.label)?.join(", ");
    } else {
      return value;
    }
  };
  const renderSelectedOptions1 = () => {
    if (value && value?.length > 0) {
      return value.map((val, index) => (
        <button
          key={index}
          type="button"
          className="py-[2px] px-0 h-[22px] bg-white border border-[#D0D5DD] custom-card text-[#344054] text-xs font-medium rounded-md  flex justify-center items-center gap-1.5 whitespace-nowrap truncate "
        >
          {val.label}
          <Cross className={"w-4 h-4 "} fillColor={"#98A2B3"} />
        </button>
      ));
    } else {
      return null;
    }
  };

  const watchedValue = useWatch({ control, name });
  return (
    <div>
      {(isViewMode1 && value && value?.length > 0) || (isViewMode && value && value?.length > 0) ? (
        <div
          className={
            isViewMode1
              ? `w-full flex flex-row items-center  bg-${isError ? "red" : "white"
              } border border-[#D0D5DD] custom-card rounded-md  overflow-x-ellipsis whitespace-nowrap text-[#344054] text-xs font-medium`
              : "px-3 text-xs placeholder:text-base placeholder:text-text-input-text-placeholder border-0 "
          }
        >
          {isViewMode1 ? renderSelectedOptions1() : renderSelectedOptions()}
        </div>
      ) : (
        <Controller
          control={control}
          defaultValue={watchedValue || null}
          name={name}
          render={({ field }) => (
            <div className="relative">
              {iconLeft && (
                <div className="absolute inset-y-0 left-0 z-[1] flex items-center pl-3">
                  {iconLeft}
                </div>
              )}
              <ReactSelect
                ref={ref}
                classNamePrefix={`select2-selection ${style.noHeight ? "no-heightSelecton" : "select2-selection"}`}
                id={name}
                options={options}
                value={isWatchedValueNeed ? watchedValue || value || null : value || null}
                onChange={(selectedOption) => {
                  field.onChange(selectedOption);
                  if (handleOnChange) {
                    handleOnChange(selectedOption);
                  }
                }}
                filterOption={createFilter({
                  matchFrom: 'any',
                  stringify: option => option.data.customLabel ? option.data.customLabel : option.label,
                })}
                isMulti={isMulti}
                isSearchable={true}
                isClearable={isClearable}
                onInputChange={onInputChange}
                autoFocus={false}
                placeholder={placeholder}
                styles={customStyles}
                getOptionLabel={getOptionLabel}
                components={{
                  ...components,
                  DropdownIndicator: () => (
                    <>
                      {icon ? (
                        icon
                      ) : (
                        <PiCaretDownBold size={16} color="var(--color-icon-fill-input)" />
                      )}
                    </>
                  ),

                  IndicatorSeparator: () => null,
                  clearIndicator: (provided) => ({
                    ...provided,
                    color: "var(--color-icon-fill-input)",
                    onClick: (e) => {
                      console.log("clearing the value");
                    },
                  }),
                }}
                isDisabled={isDisabled}
              />
            </div>
          )}
        />
      )}
    </div>
  );
});

export const customMenuList = ({ children, label, onClick, ...props }) => (
  <div>
    <div className="flex justify-end items-center py-4">
      <Button
        variant={"tertiary"}
        onClick={() => {
          onClick();
          props.selectProps.onMenuClose();
        }}
        className={"text-global-title font-medium text-center text-base h-6"}
      >
        <Plus
          fillColor={" var(--color-gray-700)"}
          strokeColor={" var(--color-gray-700)"}
          width={20}
          height={20}
        />
        {label || "Add New Item"}
      </Button>
    </div>
    <components.MenuList {...props}>
      <div className="relative overflow-y-auto max-h-[248px]">{children}</div>
    </components.MenuList>
  </div>
);

export default CustomSelect;
