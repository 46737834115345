import { BulkInfoIcon, PolygonIcon } from "assests";
const Tooltip = ({ text, fromPlan = false }) => {
  return (
    <div className="relative">
      <div className="group/tooltip  flex justify-center">
        <div>
          <BulkInfoIcon
            className="relative cursor-pointer"
            width={14}
            height={14}
            fillColor={fromPlan ? "var(--color-icon-fill-table)" : "var(--color-icon-fill-default)"}
            strokeColor={fromPlan ? "var(--color-icon-fill-table)" : "var(--color-icon-fill-default)"}
          />
        </div>
        <div className="absolute z-50 border border-border-secondary scale-0 min-w-28 lg:w-48 p-2  top-6  opacity-0 shadow-[0px_4px_4px_0px_rgba(0,0,0,0.05)] text-status-text-default  inline-flex  duration-300 bg-tooltip-surface-light rounded-lg transition-all   group-hover/tooltip:scale-100 group-hover/tooltip:opacity-100 grow shrink basis-0 text-center  text-xs leading-[18px]  font-medium">
          <div className="text-wrap">
            {text}
          </div>
          <div
            className="tooltip-arrow left-1/2  absolute transform -translate-x-1/2  -top-[16px]"
            data-popper-arrow
          >
            <PolygonIcon
              width={25}
              height={25}
              strokeColor="var(--color-tooltip-surface-light)"
              fillColor="var(--color-tooltip-surface-light)"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tooltip;
