import React from "react";

const arrow_next = React.forwardRef(
  (
    {
      width = "20",
      height = "20",
      viewBox = "0 0 20 20",
      fill = "none",
      strokeColor = "#344054",
      className,
      ...props
    },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.16669 10.0003H15.8334M15.8334 10.0003L10 4.16699M15.8334 10.0003L10 15.8337"
          stroke={strokeColor}
          strokeWidth="1.67"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  },
);
arrow_next.displayName = "arrow_next";

export default arrow_next;
