import { Cross, DottedIcon, SendIcon } from "assests";
import { Button } from "components/UI/Button";
import CustomSelect from "components/UI/CustomSelect";
import Divider from "components/UI/Divider";
import ErrorMessage from "components/UI/ErrorMessage";
import { Input } from "components/UI/Input";
import { Label } from "components/UI/Label";
import UpgradeComponent from "components/UI/UpgradeComponent";
import useInvitation from "hooks/useInvitation";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { businessPartnerList } from "utils/fakeData";
import { handleRestrictNumbersOnKeyPress, handleRestrictNumbersOnPaste } from "utils/helper";
import { permissions } from "utils/permissions";

const InvitationCreateForm = ({ closeModal, invitationListRefetch }) => {
  const { errors, register, handleSubmit, control, isPending, handleChangeCompanyType } =
    useInvitation({ invitationListRefetch });

  const [filterdInviteViewList, setFilterdInviteViewList] = useState([]);
  const { user, currentWorkspace } = useSelector((state) => state.authenticationSlice);

  useEffect(() => {
    if (user && currentWorkspace) {
      const role = currentWorkspace.role_name.split(".")[0];
      if (role) {
        const { canInvite } = permissions[currentWorkspace.role_name];
        const filteredList = businessPartnerList.filter((item) => canInvite.includes(item.value));
        setFilterdInviteViewList(filteredList);
      }
    }
  }, [user, currentWorkspace]);

  return (
    <>
      <div className="relative  z-[2] border border-global-divider-soft rounded-xl  w-full lg:w-[600px] max-h-[600px] py-6 bg-white">
        <div className="flex justify-between gap-x-4 px-6 pb-5">
          <div className="flex justify-start items-center gap-4">
            <UpgradeComponent>
              <DottedIcon width={24} height={24} />
            </UpgradeComponent>
            <div className="flex flex-col gap-0">
              <h3 className="text-sm lg:text-lg  font-semibold text-start text-login-text-title">
                Invite Business Partner
              </h3>
              <p className="text-sm text-global-paragraph font-normal text-wrap">
                Create new workspace and invite a business partner.
              </p>
            </div>
          </div>
          <div>
            <Cross
              fillColor="var(--color-icon-fill-input)"
              width={24}
              height={24}
              onClick={closeModal}
              className="cursor-pointer"
            />
          </div>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="grid grid-cols-2 w-full gap-y-3 lg:gap-y-6 gap-x-5 px-6  pt-5 h-[350px] lg:h-[380px] overflow-y-auto">
            <div className="col-span-2">
              <div className="grid gap-1.5">
                <Label htmlFor="company_name" className="flex justify-start">
                  Company Name
                </Label>
                <Input
                  id="company_name"
                  type="text"
                  name="company_name"
                  placeholder="Enter company name"
                  {...register("company_name", {
                    setValueAs: (value) => value.trim(),
                  })}
                />
                {errors.company_name && <ErrorMessage errors={errors?.company_name?.message} />}
              </div>
            </div>

            {user && user.workspace_type !== "supplier" && (
              <div className="col-span-2 w-full">
                <div className="grid gap-1.5">
                  <Label htmlFor="company_type" className="flex justify-start">
                    Business Type
                  </Label>
                  <CustomSelect
                    control={control}
                    name="company_type"
                    placeholder="Select"
                    options={filterdInviteViewList}
                    isClearable={false}
                    style={{
                      padding: "3.337px 10px 3.337px 4px",
                      fontSize: "16px",
                      width: "100%",
                    }}
                    handleOnChange={handleChangeCompanyType}
                    {...register("company_type")}
                  />

                  <ErrorMessage errors={errors?.company_type?.message} />
                </div>
              </div>
            )}

            <div className="col-span-2 lg:col-span-1">
              <div className="grid gap-1.5">
                <Label htmlFor="first_name">First Name</Label>
                <Input
                  id="first_name"
                  type="text"
                  placeholder="Enter first name"
                  onKeyPress={handleRestrictNumbersOnKeyPress}
                  onPaste={handleRestrictNumbersOnPaste}
                  {...register("first_name", {
                    setValueAs: (value) => value.trim(),
                  })}
                />
                {errors.first_name && <ErrorMessage errors={errors?.first_name?.message} />}
              </div>
            </div>
            <div className="col-span-2 lg:col-span-1">
              <div className="grid gap-1.5">
                <Label htmlFor="last_name">Last Name</Label>
                <Input
                  id="last_name"
                  type="text"
                  placeholder="Enter last name"
                  onKeyPress={handleRestrictNumbersOnKeyPress}
                  onPaste={handleRestrictNumbersOnPaste}
                  {...register("last_name", {
                    setValueAs: (value) => value.trim(),
                  })}
                />
                {errors.last_name && <ErrorMessage errors={errors?.last_name?.message} />}
              </div>
            </div>
            <div className="col-span-2">
              <div className="grid gap-1.5">
                <Label htmlFor="email" className="flex justify-start">
                  Email
                </Label>
                <Input
                  id="email"
                  type="email"
                  name="email"
                  placeholder="Enter email"
                  {...register("email")}
                />
                {errors.email && <ErrorMessage errors={errors?.email?.message} />}
              </div>
            </div>
            <div className="col-span-2">
              <div className="grid gap-1.5">
                <Label htmlFor="title">Personal Message</Label>
                <textarea
                  id="details"
                  name="details"
                  className="flex w-full gap-3 px-3.5 py-3 h-[118px] items-center text-base shadow-[0px,1px,2px,0px,rgba(16,24,40,0.05)]   rounded-lg focus:ring-1   placeholder:text-input-text-placeholder  placeholder:font-normal placeholder:text-base  disabled:border-none  focus:shadow-[none] focus:ring-offset-input-surface-background focus:border-input-stroke-active focus:ring-input-stroke-active text-input-text-data   self-stretch border border-input-stroke-default bg-input-surface-background hover:border-gray-800 transition duration-300 ease-in-out  disabled:bg-input-surface-hover read-only:bg-input-surface-hover"
                  placeholder="Add a personal message"
                  rows="4"
                  {...register("details")}
                />
                {errors.details && <ErrorMessage errors={errors?.details?.message} />}
              </div>
            </div>
          </div>
          <Divider className={"mb-4 mt-4 lg:mt-8 lg:mb-6"} />
          <div className="flex  justify-center items-center lg:justify-between gap-4 px-6">
            <div className="flex flex-col lg:flex-row  w-full gap-2 lg:gap-3">
              <Button
                variant={"secondary"}
                className={
                  "text-btn-text-default px-2.5 py-4 w-full  h-11 text-base font-semibold rounded-lg "
                }
                onClick={closeModal}
              >
                Cancel
              </Button>

              <Button
                variant={"primary"}
                type="submit"
                isDisabled={isPending}
                className={
                  "text-btn-text-default px-2.5 py-4 w-full gap-1.5  h-11 text-base font-semibold rounded-lg text-white "
                }
              >
                Invite <SendIcon width={20} height={20} />
              </Button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default InvitationCreateForm;
