import React from "react";

const UpgradeBg = React.forwardRef(
  (
    { width = "216", height = "216", viewBox = "0 0 216 216", fill = "none", className, ...props },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_11599_3460)">
          <mask
            id="mask0_11599_3460"
            style={{ maskType: "alpha" }}
            maskUnits="userSpaceOnUse"
            x="-120"
            y="-120"
            width="336"
            height="336"
          >
            <rect
              width="336"
              height="336"
              transform="translate(-120 -120)"
              fill="url(#paint0_radial_11599_3460)"
            />
          </mask>
          <g mask="url(#mask0_11599_3460)">
            <circle cx="48" cy="48" r="47.5" stroke="#E4E7EC" />
            <circle cx="48" cy="48" r="47.5" stroke="#E4E7EC" />
            <circle cx="48" cy="48" r="71.5" stroke="#E4E7EC" />
            <circle cx="48" cy="48" r="95.5" stroke="#E4E7EC" />
            <circle cx="48" cy="48" r="119.5" stroke="#E4E7EC" />
            <circle cx="48" cy="48" r="143.5" stroke="#E4E7EC" />
            <circle cx="48" cy="48" r="167.5" stroke="#E4E7EC" />
          </g>
        </g>
        <defs>
          <radialGradient
            id="paint0_radial_11599_3460"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(168 168) rotate(90) scale(168 168)"
          >
            <stop />
            <stop offset="1" stop-opacity="0" />
          </radialGradient>
          <clipPath id="clip0_11599_3460">
            <rect width="216" height="216" fill="white" />
          </clipPath>
        </defs>
      </svg>
    );
  },
);
UpgradeBg.displayName = "UpgradeBg";

export default UpgradeBg;
