const { createSlice } = require("@reduxjs/toolkit");

const settingsWorkspaceSlice = createSlice({
  name: "settingsWorkspace",
  initialState: {
    actionableWorkspaceId: null,
    totalActiveOrders: 0,
    selectedSettingTab: "profile",
  },
  reducers: {
    toggleActionableWorkspaceId: (state, action) => ({
      ...state,
      actionableWorkspaceId: action.payload ?? null,
    }),
    setToatlActiveOrders: (state, action) => ({
      ...state,
      totalActiveOrders: action.payload ?? 0
    }),
    setSelectedSettingTab: (state, action) => ({
      ...state,
      selectedSettingTab: action.payload ?? 0
    })
  },
});

export const {
  toggleActionableWorkspaceId,
  setToatlActiveOrders,
  setSelectedSettingTab

} = settingsWorkspaceSlice.actions;
export default settingsWorkspaceSlice.reducer;