import { Country } from "country-state-city";
import { useEffect, useState } from "react";
import CustomSelect from "./CustomSelect";

const CountrySelect = ({ control, selectedCountry, setSelectedCountry, isDisabled, onChange }) => {
  const [countries, setCountries] = useState([]);
  useEffect(() => {
    const res = Country.getAllCountries().map((countryItem) => ({
      value: countryItem.name,
      label: countryItem.name,
      flag: countryItem.flag,
      isoCode: countryItem.isoCode,
    }));
    setCountries(res);
  }, []);

  const handleChange = (selectedOption) => {
    setSelectedCountry(selectedOption);
    onChange()
  };

  // Custom option rendering with flag and name
  const customOption = (props) => {
    const { data, innerRef, innerProps } = props;
    return (
      <div
        ref={innerRef}
        {...innerProps}
        style={{ display: "flex", alignItems: "center", padding: "5px" }}
      >
        <img src={data.flag} alt={data.label} style={{ width: "20px", marginRight: "10px" }} />
        {data.label}
      </div>
    );
  };

  return (
    <div className="w-full">
      <CustomSelect
        control={control}
        name=""
        placeholder="Select a country"
        options={countries}
        handleOnChange={handleChange}
        components={customOption}
        getOptionLabel={(e) => (
          <div style={{ display: "flex", alignItems: "center" }}>
            <span style={{ width: "20px", marginRight: "8px" }}>{e.flag}</span>
            {e.label}
          </div>
        )}
        value={selectedCountry}
        getOptionValue={(e) => e.value}
        isClearable={false}
        style={{
          padding: "3px 10px 4.5px 6px",
          fontSize: "16px",
          width: "100%",
        }}
        isDisabled={isDisabled}
      />
    </div>
  );
};

export default CountrySelect;
