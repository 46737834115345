import {
  ArrowDownIcon,
  ClosingIcon,
  DarkLogo,
  LightLogo,
  NavMenuIcon,
  PlayIcon,
} from "assests";
import { Button } from "components/UI/Button";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { clearAuthData } from "store/authenticationSlice";
import { collapseNavbar } from "store/navbarSlice";
const Navbar = ({ redirectToSignin }) => {
  const { isDarkMode } = useSelector((state) => state.themeSlice);
  const { activeStep } = useSelector((state) => state.createAccountSlice);
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { showRequestDemo, isNavbarCollapsed } = useSelector((state) => state.navbarSlice);
  const handleRequestDemoClick = () => {
    if (!showRequestDemo) {
      dispatch(collapseNavbar());
    }
  };

  const [hasBorder, setHasBorder] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window && window.scrollY > 20) {
        setHasBorder(true);
      } else {
        setHasBorder(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleLogOut = () => {
    dispatch(clearAuthData());
    dispatch({ type: "LOGOUT" });
    navigate(`/signin`, { replace: true });
  };

  return (
    <div>
      {isNavbarCollapsed && (
        <div
          className={`fixed top-0 left-0 z-10 transition-all ease-in-out duration-300 w-full h-[72px] lg:h-20  flex-col justify-center items-center gap-2.5 inline-flex ${location.pathname === "/signin" || (location.pathname === "/signup" && activeStep === 1) ? `bg-white ${hasBorder && "border-b border-global-gray-medium"} ` : ` bg-white  lg:bg-transparent lg:border-none ${hasBorder && "border-b border-global-gray-medium"}`}`}
        >
          <div className="w-full h-11 px-8 justify-center items-center flex max-w-[1280px]">
            <div className="w-full justify-between items-center inline-flex">
              <div className=" justify-center flex-1 lg:justify-start items-center gap-20 flex">
                <div>
                  {redirectToSignin ? (
                    <Button onClick={handleLogOut} variant="link" className="flex justify-center cursor-pointer gap-3 p-0 m-0">
                      {isDarkMode ? <DarkLogo /> : <LightLogo />}
                    </Button>
                  ) : (
                    <Link to={"/"} className="flex justify-center cursor-pointer gap-3">
                      {isDarkMode ? <DarkLogo /> : <LightLogo />}
                    </Link>
                  )}
                </div>
                {location.pathname === "/signin" && (
                  <div className={`justify-start items-center gap-8  hidden lg:flex `}>
                    <div className="justify-center items-center gap-2 flex">
                      <div className="text-global-title text-base font-semibold ">Features</div>

                      <ArrowDownIcon height={20} width={20} />
                    </div>
                    <div className="justify-center items-center gap-2 flex">
                      <div className="text-global-title text-base font-semibold ">Solutions</div>
                      <ArrowDownIcon height={20} width={20} />
                    </div>
                    <div className="justify-center items-center gap-2 flex">
                      <div className="text-global-title text-base font-semibold ">Changelog</div>
                    </div>
                    <div className="justify-center items-center gap-2 flex">
                      <div className="text-global-title text-base font-semibold ">News</div>
                    </div>
                    <div className="justify-center items-center gap-2 flex">
                      <div className="text-global-title text-base font-semibold ">Pricing</div>
                    </div>
                  </div>
                )}
              </div>

              <div className="hidden lg:flex">
                {location.pathname === "/signin" && (
                  <Link
                    to="https://fibervue-business.netlify.app/request-demo"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Button
                      className="h-[44px] text-base px-4 w-[173px] font-semibold rounded-lg"
                    >
                      Request Demo <PlayIcon />
                    </Button>
                  </Link>
                )}
                {location.pathname === "/signup" && activeStep === 1 && (
                  <div className="flex items-center justify-center">
                    <p className="relative z-10 pl-2 pr-1 text-base font-normal text-login-global-title whitespace-nowrap">
                      Already have an account?
                    </p>
                    <Link
                      to="/signin"
                      className="p-0 m-0 pr-2 bg-transparent  text-base font-semibold  text-btn-text-link hover:text-global-primary whitespace-nowrap "
                    >
                      Log in
                    </Link>
                  </div>
                )}
              </div>
              {location.pathname === "/signin" && (
                <div className="flex justify-end  lg:hidden">
                  <div onClick={() => dispatch(collapseNavbar())} className="cursor-pointer">
                    {isNavbarCollapsed ? (
                      <NavMenuIcon width={24} height={24} />
                    ) : (
                      <ClosingIcon width={24} height={24} />
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      {!isNavbarCollapsed && (
        <div className="fixed z-50 left-0 top-0 h-full w-full  bg-white">
          <div
            className={`fixed top-0 left-0 z-10 transition-all ease-in-out duration-300 w-full h-[72px] lg:h-20  flex-col justify-center items-center gap-2.5 inline-flex ${location.pathname === "/signin" || (location.pathname === "/signup" && activeStep === 1) ? `bg-white border-b border-global-gray-medium` : ` bg-white lg:bg-transparent lg:border-none border-b border-global-gray-medium `}`}
          >
            <div className="w-full h-11 px-8 justify-center items-center flex">
              <div className="w-full justify-between items-center inline-flex">
                <div className=" justify-start flex-1 lg:justify-start items-center gap-20 flex">
                  <div>
                    <Link to={"/"} className="flex justify-center cursor-pointer gap-3">
                      {isDarkMode ? <DarkLogo /> : <LightLogo />}
                    </Link>
                  </div>
                </div>

                {location.pathname === "/signin" && (
                  <div className="flex justify-end  lg:hidden">
                    <div onClick={() => dispatch(collapseNavbar())} className="cursor-pointer">
                      {isNavbarCollapsed ? (
                        <NavMenuIcon width={24} height={24} />
                      ) : (
                        <ClosingIcon width={24} height={24} />
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="flex items-center justify-start relative transition-all duration-300 pt-[72px]">
            <div className=" grid grid-cols-1 gap-8 p-8">
              <div className="justify-start items-center gap-2 flex">
                <div className="text-global-title text-base font-semibold ">Features</div>
                <ArrowDownIcon height={20} width={20} />
              </div>
              <div className="justify-start items-center gap-2 flex">
                <div className="text-global-title text-base font-semibold ">Solutions</div>
                <ArrowDownIcon height={20} width={20} />
              </div>

              <Link to="#" className="text-global-title font-semibold text-base ">
                Changelog
              </Link>
              <Link to="#" className="text-global-title font-semibold text-base ">
                News
              </Link>
              <Link to="#" className="text-global-title font-semibold text-base ">
                Pricing
              </Link>
              {location.pathname === "/signin" && (
                <Link
                  to="https://fibervue-business.netlify.app/request-demo"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Button
                    onClick={handleRequestDemoClick}
                    className="h-[44px] text-base  px-4 w-[173px] font-semibold rounded-lg"
                  >
                    Request Demo <PlayIcon />
                  </Button>
                </Link>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Navbar;