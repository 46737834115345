import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import { passwordStrength } from "check-password-strength";
import { Button } from "components/UI/Button";
import Divider from "components/UI/Divider";
import ErrorMessage from "components/UI/ErrorMessage";
import { Input } from "components/UI/Input";
import { Label } from "components/UI/Label";
import PassWordCriteria from "components/UI/PassWordCriteria";
import { setpasswordSchema } from "validation/profileSettings.validation";
import { updatePassword } from "services/user.services";
import { clearAuthData } from "store/authenticationSlice";
import { toggleTheme } from "store/themeSlice";
import useCustomToaster from "hooks/useCustomToaster";
import toast from "react-hot-toast";

const ChangePasswordForm = ({ closeModal }) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { showCustomToast } = useCustomToaster();
  const { isDarkMode } = useSelector((state) => state.themeSlice);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassCriteria, setShowPassCriteria] = useState(false);
  const [passwordStrengthData, setPasswordStrengthData] = useState(null);
  const [showOldPassCriteria, setShowOldPassCriteria] = useState(false);
  const [oldPasswordStrengthData, setOldPasswordStrengthData] = useState(null);

  const [searchParams] = useSearchParams();
  const handleLogOut = () => {
    if (isDarkMode) {
      dispatch(toggleTheme());
    }
    dispatch({ type: "LOGOUT" });
    dispatch(clearAuthData());
    return <Navigate to="/signin" />;
  };

  const handleResetPasswordRedirect = () => {
    navigate(`/forget-password`, { replace: true });
    if (isDarkMode) {
      dispatch(toggleTheme());
    }
    dispatch({ type: "LOGOUT" });
    dispatch(clearAuthData());
  };

  const {
    register,
    handleSubmit: onUpdatePassword,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      old_password: "",
      password: "",
      password_confirmation: "",
    },
    resolver: zodResolver(setpasswordSchema),
  });

  const mutation = useMutation({
    mutationKey: ["password", "password"],
    mutationFn: updatePassword,
    onMutate: () => {
      showCustomToast(
        {
          title: "Updating...",
        },
        "update-password",
      );
    },
    onSuccess: handleSuccess,
    onError: handleError,
  });

  function handleSuccess(response) {
    toast.dismiss("update-password");
    const data = response.data;
    queryClient.invalidateQueries("protectedResource");
    showCustomToast({
      title: data.message,
    });
    reset();
    setShowPassCriteria(false);
    setShowOldPassCriteria(false);
  }

  function handleError(error) {
    const errorMessage = error?.response?.data?.message || error.message;
    showCustomToast({
      title: errorMessage,
    });
  }

  const handleSubmitFn = (data) => {
    mutation.mutate(data);
  };

  const handleCancelPassChange = () => {
    reset();
    setShowPassCriteria(false);
    setShowOldPassCriteria(false);
  };

  const handleOldPasswordChange = (e) => {
    setOldPassword(e.target.value);
    setValue("old_password", e.target.value);
  };

  const handleNewPasswordChange = (e) => {
    setNewPassword(e.target.value);
    setValue("password", e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    setValue("password_confirmation", e.target.value);
  };

  useEffect(() => {
    const newPasswordStrengthData = passwordStrength(newPassword);
    setPasswordStrengthData(newPasswordStrengthData);
    setShowPassCriteria(newPassword.length > 0);
  }, [newPassword]);

  useEffect(() => {
    const oldPasswordStrengthData = passwordStrength(oldPassword);
    setOldPasswordStrengthData(oldPasswordStrengthData);
    setShowOldPassCriteria(oldPassword.length > 0);
  }, [oldPassword]);

  const oldPasswordValue = watch("old_password");
  const newPasswordValue = watch("password");
  const confirmPasswordValue = watch("password_confirmation");

  return (
    <div className="w-full lg:w-[545px]">
      <form onSubmit={onUpdatePassword(handleSubmitFn)}>
        <h4 className="text-global-title font-semibold text-base lg:text-lg leading-7">Password</h4>
        <p className="text-global-paragraph font-normal text-xs lg:text-sm leading-5 truncate">
          Please enter your current password to change your password.{" "}
        </p>
        <Divider className={"bg-global-divider-medium my-4 lg:my-5"} />
        <div className="grid gap-4 lg:gap-5 w-full ">
          <div className="grid gap-1.5 w-full">
            <Label htmlFor="old_password">
              Current Password <span className="text-global-primary">*</span>
            </Label>
            <div className="md:pl-[1px]">
              <Input
                id="old_password"
                type="password"
                placeholder="••••••••"
                {...register("old_password")}
                className="h-12 py-2 px-[14.5px] "
                onChange={handleOldPasswordChange}
              />
            </div>
            <p className="text-xs lg:text-sm  font-normal text-global-paragraph leading-5">
              Can’t remember you current password?
              <span
                onClick={handleResetPasswordRedirect}
                className="text-global-primary cursor-pointer"
              >
                {" "}
                Reset your password via email.
              </span>
            </p>
            {showOldPassCriteria && (
              <PassWordCriteria passwordStrengthData={oldPasswordStrengthData} />
            )}
            <ErrorMessage errors={errors?.old_password?.message} />
          </div>
          <div className="grid gap-1.5 w-full ">
            <Label
              htmlFor="password"
              className="text-sm font-medium leading-5 text-input-text-label "
            >
              New Password{""} <span className="text-global-primary">*</span>
            </Label>
            <div className="md:pl-[1px]">
              <Input
                id="password"
                type="password"
                placeholder="••••••••"
                {...register("password")}
                onChange={handleNewPasswordChange}
                className="h-12 py-2 px-[14.5px] "
              />
            </div>
            {showPassCriteria && <PassWordCriteria passwordStrengthData={passwordStrengthData} />}
            <ErrorMessage errors={errors?.password?.message} />
          </div>
          <div className="grid gap-1.5 w-full ">
            <Label htmlFor="password_confirmation">
              Confirm Password <span className="text-global-primary">*</span>
            </Label>

            <div className="md:pl-[1px]">
              <Input
                id="password_confirmation"
                {...register("password_confirmation")}
                type="password"
                placeholder="••••••••"
                className="h-12 py-2 px-[14.5px] "
                onChange={handleConfirmPasswordChange}
              />
            </div>
            <ErrorMessage errors={errors?.password_confirmation?.message} />
          </div>
        </div>
        <div className="my-4 lg:my-6 flex flex-col lg:flex-row justify-center lg:justify-end gap-4 md:gap-3">
          <Button
            variant={"secondary"}
            onClick={handleCancelPassChange}
            className={`w-full h-11 text-sm lg:text-base lg:w-[86px] py-2.5 px-4 `}
            isDisabled={!oldPasswordValue && !newPasswordValue && !confirmPasswordValue}
          >
            Cancel
          </Button>
          <Button
            variant={"primary"}
            className={"w-full h-11 lg:w-[169px] py-2.5 px-4 whitespace-nowrap"}
            type={"submit"}
            isDisabled={
              !oldPasswordValue || !newPasswordValue || !confirmPasswordValue || mutation.isPending
            }
          >
            Update password
          </Button>
        </div>
      </form>
    </div>
  );
};

export default ChangePasswordForm;
