import { useMutation, useQueryClient } from "@tanstack/react-query";
import EmptyComponent from "components/Card/EmptyComponent";
import { Button } from "components/UI/Button";
import Divider from "components/UI/Divider";
import LoadingComponent from "components/UI/LoadingComponent";
import Modal from "components/UI/Modal";
import CertificateCard from "pages/Settings/CompanyProfileList/CertificateCard";
import CreateCertificate from "pages/Workspace/Compliancy/Certificates/CreateCertificate";
import { useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { deleteCertificate } from "services/certificate.services";
import {
  setCertificateDetails,
  toggleActionableCertificateId,
  toggleActionableWrokspaceCertificateId,
  toggleDeleteModal,
  toggleisCertificateCreateModalOpen,
  toggleisCertificateEditable,
  toggleisWrokspaceCertificateCreateModalOpen,
} from "store/certificateSlice";
import DeleteConfirmation from "./DeleteConfirmatiom";

const Certificates = ({ workspace, workspaceRefetch, iscertificatesQuryLoading }) => {
  const {
    isCertificateEditable,
    isCertificateCreateModalOpen,
    actionableCertificateId,
    deleteModal,
    certificateDetails,
    actionableWrokspaceCertificateId,
    isworkspaceCertificateCreateModalOpen,
  } = useSelector((state) => state.certificateSlice);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const [showAll, setShowAll] = useState(false);

  const toggleView = () => {
    setShowAll((prevShowAll) => !prevShowAll);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const deleteCertificateMutation = useMutation({
    mutationKey: ["certificate", "delete"],
    mutationFn: (certificateId) => deleteCertificate(certificateId),
    onMutate: () => {
      toast.loading("Deleting...", { id: "deleteCertificate" });
    },
    onSuccess: () => {
      toast.dismiss("deleteCertificate");
      toast.success("certificate deleted successfully");
      dispatch(toggleDeleteModal());
      workspaceRefetch();
      if (actionableCertificateId) {
        dispatch(toggleActionableCertificateId());
      }
      queryClient.invalidateQueries(["certificates", "get", "delete"]);
    },
    onError: (error) => {
      toast.dismiss("deleteCertificate");
      toast.error(error.response.data.message || error.message);
    },
    onSettled: () => {
      toast.dismiss("deleteCertificate");
    },
  });

  const openCreateModal = () => {
    dispatch(toggleisCertificateCreateModalOpen());
    if (actionableCertificateId) {
      dispatch(toggleActionableCertificateId());
    }
    if (!isCertificateEditable) {
      dispatch(toggleisCertificateEditable());
    }
  };

  const handleOnDelete = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!actionableCertificateId && !deleteModal) return;
    deleteCertificateMutation.mutate(actionableCertificateId);
  };

  const closeModal = () => {
    if (isworkspaceCertificateCreateModalOpen) {
      dispatch(toggleisWrokspaceCertificateCreateModalOpen());
    }
    if (actionableWrokspaceCertificateId) {
      dispatch(toggleActionableWrokspaceCertificateId());
    }
    if (certificateDetails) {
      dispatch(setCertificateDetails());
    }
    if (isCertificateEditable) {
      dispatch(toggleisCertificateEditable());
    }
  };

  const handleClickOptionButton = (certificateId, actionType) => {
    switch (actionType) {
      case "edit":
        dispatch(toggleActionableWrokspaceCertificateId(certificateId));
        dispatch(toggleisWrokspaceCertificateCreateModalOpen());
        if (!isCertificateEditable) {
          dispatch(toggleisCertificateEditable());
        }
        setAnchorEl(null);
        break;
      case "view":
        dispatch(toggleActionableWrokspaceCertificateId(certificateId));
        dispatch(toggleisWrokspaceCertificateCreateModalOpen());
        if (isCertificateEditable) {
          dispatch(toggleisCertificateEditable());
        }
        setAnchorEl(null);
        break;
      default:
        break;
    }
  };

  return (
    <div>
      <Modal open={isworkspaceCertificateCreateModalOpen} close={closeModal} title={""}>
        <CreateCertificate supplierId={workspace?.id} workspaceRefetch={workspaceRefetch} />
      </Modal>

      <Modal open={deleteModal} close={closeModal} title={""}>
        <DeleteConfirmation text="certificate" closeModal={closeModal} onClick={handleOnDelete} />
      </Modal>

      <>
        <Divider className={"bg-global-divider-medium my-4 md:my-[35px]"} />
        <div className="flex flex-col lg:flex-row justify-center  lg:items-center lg:justify-between">
          <div className="flex flex-col gap-1">
            <h3 className="text-base lg:text-lg font-semibold text-global-title">
              Company Certificates
            </h3>
            <p className="text-xs lg:text-sm font-normal text-global-paragraph">
              Monitor and manage factory’s certificates
            </p>
          </div>
          <div className="mt-3 lg:mt-0">
            <Button
              variant={"secondary"}
              className="text-sm font-semibold h-10 w-full md:w-[167px] px-[14px] gap-1 whitespace-nowrap"
              onClick={toggleView}
            >
              View All Certificates
            </Button>
          </div>
        </div>

        <div className="flex flex-col sm:flex-row flex-wrap items-center gap-4 md:gap-6  mt-4 md:mt-6">
          {!iscertificatesQuryLoading &&
          workspace?.certificates &&
          workspace?.certificates?.length > 0 ? (
            workspace.certificates
              .slice(0, showAll ? workspace.certificates.length : 3)
              .map((item, index) => (
                <div className="flex-shrink-0 w-full sm:w-auto">
                  <CertificateCard
                    key={index}
                    item={item}
                    index={index}
                    handleClickOptionButton={handleClickOptionButton}
                  />
                </div>
              ))
          ) : iscertificatesQuryLoading ? (
            <LoadingComponent />
          ) : (
            <EmptyComponent text="No certificate found!" />
          )}
        </div>
      </>
    </div>
  );
};

export default Certificates;
