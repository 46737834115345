import * as z from "zod";

export const invitationSchema = z.object({
  company_id: z.object(
    {
      label: z.union([z.string(), z.any()]),
      value: z.number(),
    },
    {
      required_error: "Select",
      invalid_type_error: "Select",
    },
  ),
  company_type: z.enum([
    "supplier",
    "retailer",
    "agent",
    "isAgentOrSupplierOption",
    "importer",
    "brand",
  ]), //type to invite
  new_data: z.object({
    company_type: z.enum(["supplier", "retailer", "agent", "importer", "brand"]),
    invitation_id: z.number(),
    workspace_id: z.number({
      required_error: "Workspace id required",
      invalid_type_error: "Workspace id required",
    }),
  }),
});

export const createOrderSchema = z.object({
  orderNumber: z
    .string({
      required_error: "Required",
      invalid_type_error: "Required",
    })
    .min(1, { message: "Required" })
    .max(100, { message: "Order number must not exceed 100 characters" }),
  quantity: z
    .number({
      required_error: "Required",
      invalid_type_error: "Required",
    })
    .max(99999999, { message: "Quantity must not exceed 8 digit" }),
  order_date: z.object({
    startDate: z
      .string({
        required_error: "Required",
        invalid_type_error: "Required",
      })
      .min(1, "Required"),
    endDate: z.string(),
  }),
  season_id: z.object(
    {
      label: z.union([z.string(), z.any()]),
      value: z.number(),
    },
    {
      required_error: "Required",
      invalid_type_error: "Required",
    },
  ),
  status: z.enum(["active", "draft"]),
  workspace_id: z.number({
    required_error: "Workspace id required",
    invalid_type_error: "Workspace id required",
  }),
  template_id: z.object(
    {
      label: z.union([z.string(), z.any()]),
      value: z.number(),
    },
    {
      required_error: "Required",
      invalid_type_error: "Required",
    },
  ),
  templates: z.any(),
  article_id: z.object(
    {
      label: z.union([z.string(), z.any()]),
      value: z.number(),
    },
    {
      required_error: "Required",
      invalid_type_error: "Required",
    },
  ),
  invites: z.any(),
  created_by: z.number({
    required_error: "Required",
    invalid_type_error: "Required",
  }),
  created_by_user_type: z.string({
    required_error: "Required",
    invalid_type_error: "Required",
  }),
});

export const createTiersLocationSchema = z
  .object({
    sub_supplier_id: z.object(
      {
        label: z.any(),
        value: z.number(),
      },
      {
        required_error: "Required",
        invalid_type_error: "Required",
      },
    ),
    arrive_date: z.object({
      startDate: z
        .string({
          required_error: "Required",
          invalid_type_error: "Required",
        })
        .min(1, "Required"),
      endDate: z.string(),
    }),
    dispatch_date: z.object({
      startDate: z
        .string({
          required_error: "Required",
          invalid_type_error: "Required",
        })
        .min(1, "Required"),
      endDate: z.string(),
    }),
    sub_supplier_street: z.string().min(1, {
      message: "Street is required",
    }),
    sub_supplier_country: z.string().min(1, {
      message: "Country is required",
    }),
    sub_supplier_region: z.string().min(1, {
      message: "City is required",
    }),
    address: z.string().min(1, {
      message: "Address is required",
    }),
    workspace_id: z.number({
      required_error: "Workspace id required",
      invalid_type_error: "Workspace id required",
    }),
    order_id: z.number(),
    sub_tier_id: z.number(),
  })
  .refine(
    (data) => {
      const arriveStart = data.arrive_date.startDate ? new Date(data.arrive_date.startDate) : null;
      const dispatchStart = data.dispatch_date.startDate
        ? new Date(data.dispatch_date.startDate)
        : null;

      // Only perform the validation if both dates are present
      if (arriveStart && dispatchStart) {
        return arriveStart <= dispatchStart;
      }

      // If either date is missing, return true to indicate no error
      return true;
    },
    {
      message: "Arrive date must not be later than dispatch date",
      path: ["arrive_date", "startDate"],
    },
  )
  .refine(
    (data) => {
      const arriveStart = data.arrive_date.startDate ? new Date(data.arrive_date.startDate) : null;
      const dispatchStart = data.dispatch_date.startDate
        ? new Date(data.dispatch_date.startDate)
        : null;

      // Only perform the validation if both dates are present
      if (arriveStart && dispatchStart) {
        return dispatchStart >= arriveStart;
      }

      // If either date is missing, return true to indicate no error
      return true;
      return arriveStart && dispatchStart && dispatchStart >= arriveStart;
    },
    {
      message: "Dispatch date must not be earlier than arrive date",
      path: ["dispatch_date", "startDate"],
    },
  );

// workspace_id: parseInt(workspaceId),
// order_id: parseInt(orderId),
// sub_supplier_id: null,
// ship_to: null,
// ship_from: null,
// sub_tier_id: null,
// issued_at: { startDate: "", endDate: "" },
// expired_at: { startDate: "", endDate: "" },

export const createForwarderSchema = z
  .object({
    sub_supplier_id: z.object(
      {
        label: z.any(),
        value: z.number(),
      },
      {
        required_error: "Required",
        invalid_type_error: "Required",
      },
    ),
    ship_to: z.object(
      {
        label: z.any(),
        value: z.number(),
      },
      {
        required_error: "Required",
        invalid_type_error: "Required",
      },
    ),
    ship_from: z.object(
      {
        label: z.any(),
        value: z.number(),
      },
      {
        required_error: "Required",
        invalid_type_error: "Required",
      },
    ),
    arrive_date: z.object({
      startDate: z
        .string({
          required_error: "Required",
          invalid_type_error: "Required",
        })
        .min(1, "Required"),
      endDate: z.string(),
    }),
    dispatch_date: z.object({
      startDate: z
        .string({
          required_error: "Required",
          invalid_type_error: "Required",
        })
        .min(1, "Required"),
      endDate: z.string(),
    }),
    forwarder: z.string(),
    workspace_id: z.number({
      required_error: "Workspace id required",
      invalid_type_error: "Workspace id required",
    }),
    order_id: z.number(),
    sub_tier_id: z.number(),
  })
  .refine(
    (data) => {
      const arriveStart = data.arrive_date.startDate ? new Date(data.arrive_date.startDate) : null;
      const dispatchStart = data.dispatch_date.startDate
        ? new Date(data.dispatch_date.startDate)
        : null;

      // Only perform the validation if both dates are present
      if (arriveStart && dispatchStart) {
        return dispatchStart <= arriveStart;
      }

      // If either date is missing, return true to indicate no error
      return true;
    },
    {
      message: "ETD date must not be later than ETA date",
      path: ["dispatch_date", "startDate"],
    },
  )
  .refine(
    (data) => {
      const arriveStart = data.arrive_date.startDate ? new Date(data.arrive_date.startDate) : null;
      const dispatchStart = data.dispatch_date.startDate
        ? new Date(data.dispatch_date.startDate)
        : null;

      // Only perform the validation if both dates are present
      if (arriveStart && dispatchStart) {
        return arriveStart >= dispatchStart;
      }

      // If either date is missing, return true to indicate no error
      return true;
    },
    {
      message: "ETA date must not be earlier than ETD date",
      path: ["arrive_date", "startDate"],
    },
  );
