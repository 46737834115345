import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { storeBillingDetails, updateBillingDetails } from "services/billing.services";
import { setBillingDetails, setIsEditingBillingDetails } from "store/billingSlice";
import { setShowCompanyDetails } from "store/subscriptionSlice";
import { emailValidation } from "utils/constant";
import * as z from "zod";
import useCustomToaster from "./useCustomToaster";

const billingSchema = z.object({
  workspace_id: z.number({
    required_error: "Workspace id is required",
  }),
  company_name: z.string({
    required_error: "Company name is required",
    invalid_type_error: "Company name is required",
  }).min(1, {
    message: "Company name is required",
  }),
  street: z.string({
    required_error: "Street is required",
    invalid_type_error: "Street is required",
  }).min(1, {
    message: "Street is required",
  }),
  zip: z.string({
    required_error: "Zip is required",
    invalid_type_error: "Zip is required",
  }).min(1, {
    message: "Zip is required",
  }),
  region: z.string({
    required_error: "City is required",
    invalid_type_error: "City is required",
  }).min(1, {
    message: "City is required",
  }),
  country: z.string({
    required_error: "Country is required",
    invalid_type_error: "Country is required",
  }).min(1, {
    message: "Country is required",
  }),
  country_code: z.string({
    required_error: "Country code is required",
    invalid_type_error: "Country code is required",
  }).min(1, {
    message: "Country code is required",
  }),
  email: emailValidation,
  vat: z.string({
    required_error: "VAT is required",
    invalid_type_error: "VAT is required",
  }).min(1, {
    message: "VAT is required",
  }),
});

const useBillingDetails = () => {
  const dispatch = useDispatch();
  const { showCustomToast } = useCustomToaster();
  const { user } = useSelector((state) => state.authenticationSlice);
  const { showCompanyDetails } = useSelector((state) => state.subscriptionSlice);
  const { isEditingBillingDetails, billingDetails, currentPlan } = useSelector(
    (state) => state.billingSlice,
  );
  const [street, setStreet] = useState("");
  const [selectedCountry, setSelectedCountry] = useState(null);
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    reset,
    getValues,
  } = useForm({
    defaultValues: {
      workspace_id: user && parseInt(user?.workspace_id),
      company_name: "",
      street: "",
      zip: "",
      region: "",
      country: "",
      country_code: "",
      email: "",
      vat: "",
    },
    resolver: zodResolver(billingSchema),
  });

  const storeBillingDetailsMutation = useMutation({
    mutationKey: ["storeBillingDetails"],
    mutationFn: storeBillingDetails,
    onMutate: () => {
      showCustomToast(
        {
          title: "Storing billing details...",
        },
        "store-Billing-Details",
      );
    },
    onSuccess: (response) => {
      toast.dismiss("store-Billing-Details");
      if (response.status === 200 && response.data.status) {
        const updatedBillingDetails = response.data.billing;
        dispatch(setBillingDetails(updatedBillingDetails));
        if (showCompanyDetails) {
          dispatch(setShowCompanyDetails(false));
        }
        if (isEditingBillingDetails) {
          dispatch(setIsEditingBillingDetails(false));
        }
        reset();
      }
      showCustomToast({
        title: "Billing details stored successfully",
      });
    },
    onError: (error) => {
      console.log(error);
      toast.dismiss("store-Billing-Details");
      showCustomToast({
        title: error.response.data.message || "Something went wrong",
      });
    },
  });

  const updateBillingDetailsMutation = useMutation({
    mutationKey: ["updateBillingDetails"],
    mutationFn: updateBillingDetails,
    onMutate: () => {
      showCustomToast(
        {
          title: "Updating billing details...",
        },
        "update-Billing-Details",
      );
    },
    onSuccess: (response) => {
      toast.dismiss("update-Billing-Details");
      if (response.status === 200 && response.data.status) {
        const updatedBillingDetails = response.data.billing;
        dispatch(setBillingDetails(updatedBillingDetails));
        dispatch(setShowCompanyDetails());
        if (isEditingBillingDetails) {
          dispatch(setIsEditingBillingDetails(false));
        }
      }
      showCustomToast({
        title: "Billing details updated successfully",
      });
    },
    onError: (error) => {
      showCustomToast({
        title: error.response.data.message || "Something went wrong",
      });
    },
  });

  const onSubmit = (data) => {
    if (isEditingBillingDetails) {
      updateBillingDetailsMutation.mutate({ ...data, id: billingDetails.id });
    } else {
      storeBillingDetailsMutation.mutate(data);
    }
  };

  const onSubmitError = (error) => {
    console.log(error);
  };

  useEffect(() => {
    if (user) {
      setValue("workspace_id", parseInt(user?.workspace_id));
    }
  }, [user, setValue]);

  return {
    control,
    register,
    handleSubmit: handleSubmit(onSubmit, onSubmitError),
    errors,
    setValue,
    clearErrors,
    isEditingBillingDetails,
    billingDetails,
    selectedCountry,
    setSelectedCountry,
    currentPlan,
    getValues,
    street,
    setStreet,
  };
};

export default useBillingDetails;
