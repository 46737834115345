import { Listbox, Transition } from "@headlessui/react";
import { Bell, DarkLogo, Ellipse, LightLogo, Menu, Search, UpgradeThunderIcon } from "assests";
import { Button } from "components/UI/Button";
import { equalTo, limitToLast, off, onValue, orderByChild, query, ref } from "firebase/database";
import Cookies from "js-cookie";
import { Fragment, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useParams } from "react-router-dom";
import { collapseSidebar } from "store/navbarSlice";
import useSound from "use-sound";
import notificationSound from "../../assests/Notification.mp3";
import { database } from "../../firebase";
import AppHeaderNotification from "./Notification";
import ProfileDropdown from "./ProfileDropdown";
import GlobalActions from "./GlobalActions";
const TopNavbar = ({ isFromDashboard, children }) => {
  const { workspaces, user, subscription, currentWorkspace } = useSelector(
    (state) => state.authenticationSlice,
  );
  const { isDarkMode } = useSelector((state) => state.themeSlice);
  const { isSidebarCollapsed } = useSelector((state) => state.navbarSlice);
  const [isHovered, setIshovered] = useState(false);
  const dispatch = useDispatch();
  const { workspaceId } = useParams();
  const { pathname } = useLocation();
  const location = useLocation();
  let workspaceName = "";
  if (workspaces && workspaces?.length > 0) {
    const workspace = workspaces?.find(
      (item) => item?.invited_workspace_id === parseInt(workspaceId),
    );

    if (workspace) {
      workspaceName = workspace?.invited_workspace_name;
    }
  }

  const localUser = JSON.parse(localStorage.getItem("user"));
  const [notificationData, setNotificationData] = useState([]);
  const [unreadNotificationCount, setUnreadNotificationCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const notificationsPerPage = 100;
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const notificationRef = useRef(null);
  const profileDropdownRef = useRef(null);
  const [playSound] = useSound(notificationSound);
  const isDashboardPath = location.pathname === `/workspace/${workspaceId}/dashboard`;
  const [isInputVisible, setIsInputVisible] = useState(false); // State to handle input visibility
  const [isSearchActive, setIsSearchActive] = useState(false);
  const searchRef = useRef(null); // Ref for the search div
  const searchInputRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (notificationRef.current && !notificationRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (profileDropdownRef.current && !profileDropdownRef.current.contains(event.target)) {
        setIsProfileOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    // Function to handle clicks outside of the search input field
    const handleClickOutside = (event) => {
      if (
        searchRef.current &&
        !searchRef.current.contains(event.target) && // Check if searchRef exists
        searchInputRef.current &&
        !searchInputRef.current.contains(event.target) // Check if inputRef exists
      ) {
        setIsInputVisible(false);
        setIsSearchActive(false); // Reset the div to rounded-full when input is hidden
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const dbRef = ref(database, "/notification");
    try {
      const queryRef = query(
        dbRef,
        orderByChild(`user_id`),
        equalTo(localUser.id),
        limitToLast(currentPage * notificationsPerPage),
      );
      const onValueChange = onValue(queryRef, (snapshot) => {
        if (snapshot.exists()) {
          const dataArray = Object.values(snapshot.val());
          let count = 0;
          dataArray.forEach((element) => {
            if (!element.isRead) {
              count++;
            }
          });

          const prevUnreadNotification = unreadNotificationCount;
          if (Number(count) > Number(prevUnreadNotification)) {
            playSound();
          }

          const sorted = dataArray.sort((a, b) => b.reference_key - b.reference_key);
          setNotificationData(sorted);
          setUnreadNotificationCount(count);
        }
      });
      return () => {
        off(dbRef, "value", onValueChange);
      };
    } catch (error) {
      console.error("Error updating status:", error);
    }
  }, [currentPage]);

  const handleScroll = async (e) => {
    const containerDiv = document.getElementById("notificationContainer");
    const newitemHeight = containerDiv.offsetHeight;
    let scrollTop = e.currentTarget.scrollTop;
    if (scrollTop < 0) {
      scrollTop = scrollTop * -1;
    }
    let totalViewSizeNeeded = containerDiv.scrollHeight;
    let postion = totalViewSizeNeeded - (scrollTop + newitemHeight);
    if (postion < 150 && notificationData.length === currentPage * notificationsPerPage) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };
  function upgradeShowHIde() {
    var element = document.getElementById("upgradePlanBtn").classList.remove("invisible");
  }

  useEffect(() => {
    let itemStatus = JSON.parse(localStorage.getItem("subscriptionBtn"));
    if (!subscription && !itemStatus) {
      upgradeShowHIde();
    } else if (subscription) {
      Cookies.set("showSidebarMsg", "false", { expires: 7 });
      document.getElementById("upgradePlanBtn").classList.add("invisible");
      localStorage.removeItem("subscriptionBtn");
    }
  }, [subscription]);

  const isSettingsRoute = location.pathname.includes("settings");

  return (
    <div className="relative ">
      <nav
        className={`flex gap-4 top-0 items-center ease-in-out transition-all duration-300 justify-between py-3.5 md:pb-6 md:pt-8 bg-global-theme md:bg-global-surface ${isFromDashboard ? "relative px-4" : "fixed z-10 pl-6 pr-4 md:px-8"} ${isSidebarCollapsed
          ? isFromDashboard
            ? "w-full"
            : `w-full md:w-[calc(100%_-_80px)]`
          : isFromDashboard
            ? "w-full"
            : `w-full md:w-[calc(100%_-_296px)]`
          }  `}
      >
        <div className="hidden md:flex gap-8">
          {children}
        </div>
        {!isFromDashboard ? (
          <div
            onClick={() => dispatch(collapseSidebar())}
            className={`block md:hidden  cursor-pointer`}
          >
            <Menu className="w-6 h-6" strokeColor="var(--color-global-gray)" />
          </div>
        ) : (
          <div className="block md:hidden">
            <Link to={"/"}>
              {isDarkMode ? (
                <DarkLogo height={16} width={82} />
              ) : (
                <LightLogo height={16} width={82} />
              )}
            </Link>
          </div>
        )}
        <div className="flex gap-4 justify-center items-center">
          <div id="upgradePlanBtn" className="invisible">
            <Link to={`/workspace/${currentWorkspace?.user_workspace_id}/setting?selectedTab=plan`}>
              <Button
                variant="secondary"
                className="text-txt-secondary px-3.5 py-2.5 gap-1 text-sm tracking-wide font-semibold hidden md:flex w-[143px] whitespace-nowrap h-10 leading-5  rounded-lg "
                onMouseEnter={() => setIshovered(true)}
                onMouseLeave={() => setIshovered(false)}
              >
                <UpgradeThunderIcon
                  width={20}
                  height={20}
                  strokeColor={
                    isHovered
                      ? "var(--color-icon-fill-secondary-button)"
                      : "var(--color-icon-outline-default)"
                  }
                />
                Upgrade now
              </Button>
            </Link>
          </div>
          {!isSettingsRoute && (
            <>
              <div
                className={`flex justify-center items-center  gap-1  relative ${isSearchActive ? "rounded-lg" : "rounded-full"} h-9 md:h-[52px] bg-global-theme`}
                ref={searchRef}
              >
                <div>
                  <GlobalActions  {...{ setIsInputVisible, setIsSearchActive, isInputVisible, searchInputRef }} />
                </div>
                <div ref={notificationRef} className="w-10 h-9 flex items-center justify-center px-1.5">
                  <Listbox className="w-full relative">
                    {({ open }) => (
                      <>
                        <div className="relative">
                          <Listbox.Button
                            onClick={() => setIsOpen(true)}
                            className="cursor-pointer relative mt-2"
                          >
                            <Bell className="w-5 h-5" strokeColor="var(--color-icon-default-4)" />
                            {unreadNotificationCount > 0 && (
                              <Ellipse
                                fillColor="var(--color-global-danger)"
                                className="absolute top-0 right-0 w-1 h-1 transform translate-x-1/2 -translate-y-1/2 rounded-full"
                              />
                            )}
                          </Listbox.Button>

                          {isOpen ? (
                            <Transition
                              show={open}
                              as={Fragment}
                              leave="transition ease-in duration-100"
                              leaveFrom="opacity-100"
                              leaveTo="opacity-0"
                            >
                              <Listbox.Options className="fixed z-30 top-1 notification-shadow   ring-1 ring-border-secondary right-1 overflow-hidden text-base w-full md:w-[400px] h-[calc(100vh_-_8px)] lg:h-[calc(100vh_-_8px)] bg-white rounded-lg focus:outline-none sm:text-sm">
                                <AppHeaderNotification
                                  handleScroll={handleScroll}
                                  userId={localUser?.id}
                                  setIsOpen={setIsOpen}
                                  isOpen={isOpen}
                                  notificationData={notificationData}
                                />
                              </Listbox.Options>
                            </Transition>
                          ) : (
                            ""
                          )}
                        </div>
                      </>
                    )}
                  </Listbox>
                </div>

                <div ref={profileDropdownRef} className="px-1.5">
                  <Listbox className="w-full relative ">
                    {({ open }) => (
                      <>
                        <div className="relative pl-3 ">
                          <Listbox.Button
                            onClick={() => setIsProfileOpen(true)}
                            className="cursor-pointer flex justify-center items-center  ring-1 ring-global-divider-soft  rounded-full border-none text-btn-text-default bg-transparent"
                          >
                            {user && user?.image ? (
                              <img
                                src={`${user?.image}?t=${new Date().getTime()}`}
                                alt={`Profile`}
                                className="rounded-full w-7 h-7 md:h-10 md:w-10 "
                              />
                            ) : (
                              <div className="cursor-pointer flex bg-global-surface uppercase justify-center items-center text-base font-semibold text-text-quaternary w-7 h-7 md:h-10 md:w-10  p-0 ring-1 ring-global-divider-soft rounded-full border-none">
                                {user &&
                                  `${user?.first_name?.substring(0, 1)}${user?.last_name?.substring(0, 1)}`}
                              </div>
                            )}
                          </Listbox.Button>

                          {isProfileOpen ? (
                            <Transition
                              show={open}
                              as={Fragment}
                              leave="transition ease-in duration-100"
                              leaveFrom="opacity-100"
                              leaveTo="opacity-0"
                            >
                              <Listbox.Options className="absolute z-10 top-12 right-0 overflow-hidden w-[248px] bg-global-white rounded-lg ">
                                <ProfileDropdown />
                              </Listbox.Options>
                            </Transition>
                          ) : (
                            ""
                          )}
                        </div>
                      </>
                    )}
                  </Listbox>
                </div>
              </div>
            </>
          )}
        </div>
      </nav>
    </div>
  );
};

export default TopNavbar;
