import React from "react";

const CertificateIcon = React.forwardRef(
  (
    {
      width = "20",
      height = "20",
      viewBox = "0 0 20 20",
      fillColor = "#344054",
      fill = "none",
      className,
      ...props
    },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_473_6187)">
          <path
            d="M7.50008 10.0001L9.16675 11.6667L12.9167 7.91675M14.9177 4.1655C15.0893 4.58053 15.4187 4.91041 15.8335 5.08264L17.2878 5.68506C17.7028 5.85698 18.0326 6.18674 18.2045 6.6018C18.3764 7.01685 18.3764 7.4832 18.2045 7.89826L17.6025 9.35158C17.4305 9.76682 17.4303 10.2336 17.6031 10.6487L18.204 12.1016C18.2892 12.3071 18.3331 12.5275 18.3331 12.75C18.3332 12.9726 18.2893 13.1929 18.2042 13.3985C18.119 13.6041 17.9942 13.7909 17.8368 13.9483C17.6794 14.1056 17.4926 14.2304 17.287 14.3155L15.8337 14.9175C15.4187 15.0891 15.0888 15.4185 14.9166 15.8332L14.3142 17.2876C14.1423 17.7027 13.8125 18.0324 13.3975 18.2043C12.9825 18.3763 12.5161 18.3763 12.1011 18.2043L10.6478 17.6023C10.2328 17.4308 9.7666 17.4312 9.35181 17.6033L7.89749 18.2049C7.48268 18.3764 7.01676 18.3763 6.60206 18.2045C6.18735 18.0327 5.85779 17.7033 5.68576 17.2887L5.08318 15.8339C4.91158 15.4189 4.5822 15.089 4.16745 14.9168L2.71313 14.3143C2.29828 14.1425 1.96863 13.813 1.79665 13.3981C1.62467 12.9833 1.62444 12.5172 1.796 12.1022L2.39797 10.6489C2.56946 10.2338 2.56911 9.76762 2.39699 9.35282L1.79589 7.8974C1.71067 7.69183 1.66678 7.47148 1.66675 7.24894C1.66671 7.0264 1.71053 6.80604 1.79568 6.60044C1.88084 6.39484 2.00568 6.20804 2.16305 6.05071C2.32043 5.89338 2.50727 5.7686 2.71289 5.6835L4.16616 5.08151C4.58081 4.91006 4.91049 4.58108 5.08283 4.1668L5.68523 2.71242C5.85715 2.29737 6.1869 1.96761 6.60193 1.79569C7.01697 1.62377 7.48331 1.62377 7.89834 1.79569L9.35162 2.39768C9.76667 2.56918 10.2328 2.56882 10.6476 2.3967L12.1026 1.79662C12.5175 1.6248 12.9838 1.62483 13.3987 1.79672C13.8137 1.96861 14.1434 2.29827 14.3153 2.71322L14.9179 4.16802L14.9177 4.1655Z"
            stroke={fillColor}
            strokeWidth="1.66667"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_473_6187">
            <rect width="20" height="20" fill="white" />
          </clipPath>
        </defs>
      </svg>
    );
  },
);

CertificateIcon.displayName = "CertificateIcon";

export default CertificateIcon;
