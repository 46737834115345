import React from "react";

const fi_bell = React.forwardRef(
  (
    {
      width = "20",
      height = "20",
      viewBox = "0 0 20 20",
      fill = "none",
      strokeColor = "#444656",
      className,
      ...props
    },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="fi:bell">
          <path
            id="Vector"
            d="M15.0097 6.66675C15.0097 5.34067 14.4829 4.0689 13.5452 3.13121C12.6076 2.19353 11.3358 1.66675 10.0097 1.66675C8.68362 1.66675 7.41185 2.19353 6.47417 3.13121C5.53649 4.0689 5.0097 5.34067 5.0097 6.66675C5.0097 12.5001 2.5097 14.1667 2.5097 14.1667H17.5097C17.5097 14.1667 15.0097 12.5001 15.0097 6.66675Z"
            stroke={strokeColor}
            strokeWidth="1.66667"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Vector_2"
            d="M11.4514 17.5C11.3049 17.7526 11.0946 17.9622 10.8416 18.1079C10.5886 18.2537 10.3017 18.3304 10.0097 18.3304C9.71774 18.3304 9.43088 18.2537 9.17787 18.1079C8.92485 17.9622 8.71456 17.7526 8.56805 17.5"
            stroke={strokeColor}
            strokeWidth="1.66667"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </svg>
    );
  },
);

fi_bell.displayName = "fi_bell";

export default fi_bell;
