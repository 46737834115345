import React from "react";

const EmailVerification = React.forwardRef(
  (
    {
      width = "48",
      height = "48",
      viewBox = "0 0 48 48",
      fill = "none",

      className,
      ...props
    },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_ddddii_10304_34189)">
          <rect x="4" y="3" width="40" height="40" rx="8" fill="white" />
          <rect x="4.5" y="3.5" width="39" height="39" rx="7.5" stroke="#E4E7EC" />
          <path
            d="M31.9167 28L26.381 23M21.6191 23L16.0834 28M15.6667 18.8333L22.4708 23.5962C23.0218 23.9819 23.2973 24.1747 23.597 24.2494C23.8617 24.3154 24.1385 24.3154 24.4032 24.2494C24.7029 24.1747 24.9783 23.9819 25.5293 23.5962L32.3334 18.8333M19.6667 29.6666H28.3334C29.7335 29.6666 30.4336 29.6666 30.9684 29.3942C31.4388 29.1545 31.8212 28.772 32.0609 28.3016C32.3334 27.7668 32.3334 27.0668 32.3334 25.6666V20.3333C32.3334 18.9332 32.3334 18.2331 32.0609 17.6983C31.8212 17.2279 31.4388 16.8455 30.9684 16.6058C30.4336 16.3333 29.7335 16.3333 28.3334 16.3333H19.6667C18.2666 16.3333 17.5666 16.3333 17.0318 16.6058C16.5614 16.8455 16.1789 17.2279 15.9392 17.6983C15.6667 18.2331 15.6667 18.9332 15.6667 20.3333V25.6666C15.6667 27.0668 15.6667 27.7668 15.9392 28.3016C16.1789 28.772 16.5614 29.1545 17.0318 29.3942C17.5666 29.6666 18.2666 29.6666 19.6667 29.6666Z"
            stroke="#344054"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <filter
            id="filter0_ddddii_10304_34189"
            x="0.25"
            y="0.5"
            width="47.5"
            height="47.5"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="1" />
            <feGaussianBlur stdDeviation="1" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.05 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_10304_34189"
            />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="1.25" />
            <feGaussianBlur stdDeviation="1.25" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.06 0"
            />
            <feBlend
              mode="normal"
              in2="effect1_dropShadow_10304_34189"
              result="effect2_dropShadow_10304_34189"
            />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="1.25" />
            <feGaussianBlur stdDeviation="1.875" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.1 0"
            />
            <feBlend
              mode="normal"
              in2="effect2_dropShadow_10304_34189"
              result="effect3_dropShadow_10304_34189"
            />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="1" />
            <feGaussianBlur stdDeviation="1" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.05 0"
            />
            <feBlend
              mode="normal"
              in2="effect3_dropShadow_10304_34189"
              result="effect4_dropShadow_10304_34189"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect4_dropShadow_10304_34189"
              result="shape"
            />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="-2" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.05 0"
            />
            <feBlend mode="normal" in2="shape" result="effect5_innerShadow_10304_34189" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feMorphology
              radius="1"
              operator="erode"
              in="SourceAlpha"
              result="effect6_innerShadow_10304_34189"
            />
            <feOffset />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.18 0"
            />
            <feBlend
              mode="normal"
              in2="effect5_innerShadow_10304_34189"
              result="effect6_innerShadow_10304_34189"
            />
          </filter>
        </defs>
      </svg>
    );
  },
);

export default EmailVerification;
