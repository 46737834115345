import { IconButton, Menu } from "@mui/material";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Action, EditLable, MoreOptions, PlanIcon, Shock, TrashUpdateIcon } from "assests";
import EmptyTable from "components/Card/EmptyTable";
import CreateButton from "components/MoreAction/CreateButton";
import DeleteButton from "components/MoreAction/DeleteButton";
import DuplicateButton from "components/MoreAction/DuplicateButton";
import EditButton from "components/MoreAction/EditButton";
import MoreActionOptionUpdated from "components/MoreAction/MoreActionOptionUpdated";
import UploadButton from "components/MoreAction/UploadButton";
import ViewButton from "components/MoreAction/ViewButton";
import SearchCreatePanelUpdate from "components/SearchCreatePanelUpdate";
import BreadCrumb from "components/TopHeader/BreadCrumb";
import TopNavbar from "components/TopHeader/TopNavbar";
import ActionRequired from "components/UI/ActionRequired";
import { Button } from "components/UI/Button";
import CommonDiscardButton from "components/UI/CommonDiscardButton";
import CommonDiscardButtonUpdated from "components/UI/CommonDiscardButtonUpdated";
import LimitReached from "components/UI/LimitReached";
import LoadingComponent from "components/UI/LoadingComponent";
import Modal from "components/UI/Modal";
import SortIcons from "components/UI/SortIcons";
import TertiaryPagination from "components/UI/TertiaryPagination";
import useCustomToaster from "hooks/useCustomToaster";
import usePermission from "hooks/usePermission";
import SubNavbar from "layout/SubNavbar";
import queryString from "query-string";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  bulkDeleteArticles,
  deleteArticle,
  downloadArticlesAsCSV,
  getArticles,
} from "services/article.services";
import { getGenders } from "services/gender.services";
import {
  setActionableArticleItem,
  setArticleAllData,
  setArticleDetails,
  setArticleList,
  toggleActionableArticleId,
  toggleActionModal,
  toggleDeleteModal,
  toggleDuplicateAction,
  toggleIsArticleCreateModalOpen,
  toggleIsArticleEditable,
  toggleIsUploadBulkArticleModalOpen,
  toggleReachedModal,
} from "store/articleSlice";
import { setRefetchBillingData } from "store/billingSlice";
import { setGenderList } from "store/genderSlice";
import { formatDateToDayMonthYear, removeQueryParam, truncateString } from "utils/helper";
import Template from "../Template/Template";
import ArticleFilters from "./ArticleFilters";
import UploadBulkArticle from "./BulkArticle/UploadBulkArticle";
import CreateArticle from "./CreateArticle";

const ArticleContent = ({ selectedArticles, setSelectedArticles }) => {
  const dispatch = useDispatch();
  const { hasPermission, checkPlanLimitation } = usePermission();
  const queryClient = useQueryClient();
  const location = useLocation();
  const navigate = useNavigate();
  const { workspaceId } = useParams();
  const [activeFilters, setActiveFilters] = useState({});
  const [filteredData, setFilteredData] = useState({});
  const { workspacePlan } = useSelector((state) => state.billingSlice);
  const { genderList } = useSelector((state) => state.genderSlice);
  const [articleOptions, setArticleOptions] = useState([]);
  const {
    articleAllData,
    articleList,
    isArticleEditable,
    isArticleCreateModalOpen,
    actionableArticelId,
    articleDetails,
    isDuplicateAction,
    deleteModal,
    reachedModal,
    isUploadBulkArticleModalOpen,
    actionModal,
    actionableArticleItem,
  } = useSelector((state) => state.articleSlice);
  const { user, currentWorkspace, subscription, subscription_status } = useSelector(
    (state) => state.authenticationSlice,
  );
  const articleId = currentWorkspace?.workspace_id && parseInt(currentWorkspace?.workspace_id);
  const { refetchBillingData } = useSelector((state) => state.billingSlice);
  //Location state initalization
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [orderBy, setOrderBy] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchKey, setSearchKey] = useState("");
  const parsed = queryString.parse(location.search);
  const { showCustomToast } = useCustomToaster();
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const [genderDataList, setGenderDataList] = useState([]);
  const open = Boolean(anchorEl);
  const [activeTab, setActiveTab] = useState("Articles");
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const openMenu = (event) => {
    if ((currentWorkspace && currentWorkspace.is_owner && (!subscription || (subscription && (!subscription_status || subscription_status === "canceled")))) || !workspacePlan) {
      setShowUpgradeModal(true);
    } else if (checkPlanLimitation && !checkPlanLimitation.canCreateArticle) {
      dispatch(toggleReachedModal());
    } else {
      setMenuAnchorEl(event.currentTarget);
    }
    // Anchor the menu to the button
  };

  const closeMenu = () => {
    setMenuAnchorEl(null);
  };

  const isMenuOpen = Boolean(menuAnchorEl);

  /**
   * Closes the anchor element.
   *
   * @return {void}
   */
  const handleClose = () => {
    setAnchorEl(null);
    if (actionableArticelId) {
      dispatch(toggleActionableArticleId());
    }
  };

  /**
   * Updates the page data fetching size and
   * sets the page to 1 which is current page.
   * Object is when the page size change will fetch
   * the data according to the new limit and
   * always current wil be reseted to 1
   *
   * @param {type} newSize - The new size to be set.
   * @return {type} Description of what is returned.
   */
  const handleSizeChange = (newSize) => {
    setSize(newSize);
    setPage(1);
  };

  /**
   * Opens the create modal for articles, toggles actionable article id, and sets article to editable if not already.
   */
  const openCreateModal = () => {
    dispatch(toggleIsArticleCreateModalOpen());
    setMenuAnchorEl(null);
    if (actionableArticelId) {
      dispatch(toggleActionableArticleId());
    }
    if (!isArticleEditable) {
      dispatch(toggleIsArticleEditable());
    }
  };

  const openUploadBulkOrderModal = () => {
    dispatch(toggleIsUploadBulkArticleModalOpen());
    setMenuAnchorEl(null);
  };

  /**
   * Closes the modal and performs necessary actions based on the current state.
   *
   * @return {void}
   */
  const closeModal = () => {
    if (isArticleCreateModalOpen) {
      dispatch(toggleIsArticleCreateModalOpen());
    }
    if (actionableArticelId) {
      dispatch(toggleActionableArticleId());
    }
    if (articleDetails) {
      dispatch(setArticleDetails());
    }
    if (isArticleEditable) {
      dispatch(toggleIsArticleEditable());
    }
    if (isDuplicateAction) {
      dispatch(toggleDuplicateAction());
    }
    if (deleteModal) {
      dispatch(toggleDeleteModal());
    }
    if (isUploadBulkArticleModalOpen) {
      dispatch(toggleIsUploadBulkArticleModalOpen());
    }
    if (reachedModal) {
      dispatch(toggleReachedModal());
    }
    if (actionModal) {
      dispatch(toggleActionModal());
    }
    if (showUpgradeModal) {
      setShowUpgradeModal(false);
    }
    if (actionableArticleItem) {
      dispatch(setActionableArticleItem());
    }

    removeQueryParam(["fromGlobalCreate", "fromGlobalSearch", "article_id", "selectedTab"], location, navigate);
  };

  const handleActionClick = (articleId) => {
    dispatch(toggleActionableArticleId(articleId));
  };

  const {
    data: articlesData,
    isLoading: isarticlesQuryLoading,
    refetch,
  } = useQuery({
    queryKey: ["articles", "get", page, size, searchKey, orderBy, filteredData, workspaceId],
    queryFn: () =>
      getArticles({
        workspace_id: parseInt(workspaceId),
        page,
        per_page: size,
        search: searchKey,
        sort: orderBy,
        ...filteredData,
      }),
    enabled: !!workspaceId || !!page || !!size || !!searchKey || !orderBy,
  });

  const {
    data: articlesNopaginatedData,
    isLoading: isArticlesNopaginatedData,
    refetch: refetchNopaginatedData,
  } = useQuery({
    queryKey: ["articlesnopaginate", "getData", workspaceId],
    queryFn: () =>
      getArticles({
        workspace_id: parseInt(workspaceId),
        pagination: "no",
      }),
    enabled: !!workspaceId,
  });

  useEffect(() => {
    if (!isArticlesNopaginatedData && articlesNopaginatedData) {
      if (articlesNopaginatedData?.data?.articles) {
        setArticleOptions(articlesNopaginatedData?.data?.articles);
      }
    }
  }, [articlesNopaginatedData, isArticlesNopaginatedData]);

  const refetchDatas = () => {
    refetch();
    refetchNopaginatedData();
  };

  const deleteAllMutation = useMutation({
    mutationKey: ["article", "delete"],
    mutationFn: (data) => bulkDeleteArticles(data),
    onMutate: () => {
      toast.loading("Deleting...", { id: "deleteAllArticle" });
      showCustomToast(
        {
          title: "Deleting...",
        },
        "deleteAllArticle",
      );
    },
    onSuccess: () => {
      toast.dismiss("deleteAllArticle");
      showCustomToast({
        title: "Article deleted successfully",
      });
      closeModal();
      refetchDatas();
      setSelectedArticles([]);
      if (!refetchBillingData) {
        dispatch(setRefetchBillingData(true));
      }
      queryClient.invalidateQueries(["articles", "get", "delete"]);
    },
    onError: (error) => {
      console.log(error);
      toast.dismiss("deleteAllArticle");
      showCustomToast({
        title: error.response.data.message || "Something went wrong",
      });
    },
    onSettled: () => {
      toast.dismiss("deleteAllArticle");
    },
  });

  const { data: genderQuery } = useQuery({
    queryKey: ["genders", "getList"],
    queryFn: getGenders,
    enabled: genderList.length === 0,
  });

  useEffect(() => {
    if (genderQuery && genderQuery?.data?.status && genderQuery?.data?.gender?.length > 0) {
      const dataList = genderQuery?.data?.gender?.map((item) => ({
        label: item.name,
        value: item.name,
      }));
      setGenderDataList(dataList);
      dispatch(setGenderList(genderQuery?.data?.gender));
    }
  }, [genderQuery]);

  const genderName = (genderId) => {
    if (!genderList) return null;
    const gender = genderList.find(({ id }) => id === parseInt(genderId));
    if (gender) {
      return gender.name;
    }
    return null;
  };

  const articleComposition = (article) => {
    const compositions = article.compositions;
    const percentages = article.percentage;
    const { length } = compositions;
    let result = "";
    let i = 0;
    if ((compositions && compositions.length) !== (percentages && percentages.length))
      return result;
    while (i < length) {
      const composition = compositions[i];
      const percentage = percentages[i++];
      result += `${composition.name.charAt(0).toUpperCase() + composition.name.slice(1)}-${percentage}%${i < length ? ", " : ""}`;
    }

    return result;
  };

  const deleteArticleMutation = useMutation({
    mutationKey: ["article", "delete"],
    mutationFn: (data) => deleteArticle(data),
    onMutate: () => {
      toast.loading("Deleting...", { id: "deleteArticle" });
      showCustomToast(
        {
          title: "Deleting...",
        },
        "deleteArticle",
      );
    },
    onSuccess: () => {
      toast.dismiss("deleteArticle");
      showCustomToast({
        title: "Article deleted successfully",
      });
      closeModal();
      refetchDatas();
      if (!refetchBillingData) {
        dispatch(setRefetchBillingData(true));
      }
      queryClient.invalidateQueries(["articles", "get", "delete"]);
    },
    onError: (error) => {
      console.log(error);
      toast.dismiss("deleteArticle");
      showCustomToast({
        title: error.response.data.message || "Something went wrong",
      });
    },
    onSettled: () => {
      toast.dismiss("deleteArticle");
    },
  });

  const downloadArticlesCSV = useMutation({
    mutationKey: ["article", "download"],
    mutationFn: (data) => downloadArticlesAsCSV(data),
    onMutate: () => {
      // toast.loading("Downloading...", { id: "downloadArticles" });
      showCustomToast(
        {
          title: "Downloading...",
        },
        "downloadArticles",
      );
    },
    onSuccess: (res) => {
      toast.dismiss("downloadArticles");
      showCustomToast({
        title: "Downloaded successfully",
      });
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "articles.csv");
      document.body.appendChild(link);
      link.click();
      link.remove();
      queryClient.invalidateQueries(["article", "download"]);
    },
    onError: (error) => {
      console.log(error);
      toast.dismiss("downloadArticles");
      showCustomToast({
        title: error.response.data.message || "Something went wrong",
      });
    },
  });
  const handleDownloadCSV = () => {
    downloadArticlesCSV.mutate({
      workspace_id: parseInt(currentWorkspace?.workspace_id),
    });
  };

  const onClickCheckbox = (article) => {
    if (parseInt(article.active_orders) > 0 || parseInt(user?.workspace_id) !== parseInt(article?.created_by_user?.own_workspace_id)) return;
    const findedItem = selectedArticles.some((item) => item.id === article.id);
    if (!findedItem) {
      setSelectedArticles((prevSelectedArticles) => [...prevSelectedArticles, article]);
    } else {
      setSelectedArticles((prevSelectedArticles) =>
        prevSelectedArticles.filter((item) => item.id !== article.id),
      );
    }
  };

  const handleSelectAll = (e) => {
    if (!e.target.checked || !articleList.length > 0) {
      setSelectedArticles([]);
      return;
    }
    const articleIds =
      articleList.length > 0 &&
      articleList?.filter((item) => parseInt(item.active_orders) === 0 && parseInt(item?.created_by_user?.own_workspace_id) === parseInt(user?.workspace_id))?.map((article) => article);
    setSelectedArticles(articleIds);
  };

  const handleClickOptionButton = (articleId, actionType) => {
    switch (actionType) {
      case "view":
        dispatch(toggleActionableArticleId(articleId));
        dispatch(toggleIsArticleCreateModalOpen());
        if (isArticleEditable) {
          dispatch(toggleIsArticleEditable());
        }
        setAnchorEl(null);
        break;
      case "edit":
        dispatch(toggleActionableArticleId(articleId));
        if (!isArticleEditable) {
          dispatch(toggleIsArticleEditable());
        }
        dispatch(toggleIsArticleCreateModalOpen());
        setAnchorEl(null);
        break;
      case "duplicate":
        if ((currentWorkspace && currentWorkspace.is_owner && (!subscription || (subscription && (!subscription_status || subscription_status === "canceled")))) || !workspacePlan) {
          setShowUpgradeModal(true);
        } else if (checkPlanLimitation && !checkPlanLimitation.canCreateArticle) {
          dispatch(toggleReachedModal());
        } else {
          // Proceed with duplication if checks are passed
          dispatch(toggleActionableArticleId(articleId));
          dispatch(toggleDuplicateAction());
          // Ensure article is editable if not already
          if (!isArticleEditable) {
            dispatch(toggleIsArticleEditable());
          }
          dispatch(toggleIsArticleCreateModalOpen());
        }
        setAnchorEl(null);
        break;
      case "delete":
        dispatch(toggleActionableArticleId(articleId));
        dispatch(toggleDeleteModal());
        setAnchorEl(null);
        break;
      default:
        break;
    }
  };

  const handleOnDelete = () => {
    if (!actionableArticelId) return;
    deleteArticleMutation.mutate({
      id: actionableArticelId,
      main_workspace_id: parseInt(currentWorkspace?.workspace_id),
    });
  };

  useEffect(() => {
    if (!isarticlesQuryLoading && articlesData) {
      dispatch(setArticleList({ articleList: articlesData?.data?.articles?.data, append: false }));
      dispatch(setArticleAllData({ articleAllData: articlesData?.data?.articles, append: false }));
    }
  }, [articlesData, isarticlesQuryLoading]);

  const handleSearch = (e) => {
    const searchText = e.target.value?.trim();
    if (searchText.length >= 2) {
      setSearchKey(searchText);
      setPage(1);
    } else {
      setPage(1);
      setSearchKey("");

    }
  };

  useEffect(() => {
    if (parsed.fromGlobalCreate === "true") {
      dispatch(toggleIsArticleCreateModalOpen());
      if (actionableArticelId) {
        dispatch(toggleActionableArticleId());
      }
      if (!isArticleEditable) {
        dispatch(toggleIsArticleEditable());
      }
    }
    if (parsed.fromGlobalSearch === "true" && parsed.article_id !== null) {
      handleActionClick(parsed.article_id);
      handleClickOptionButton(parsed.article_id, "view");
    }
  }, [parsed.fromGlobalCreate, parsed.article_id, parsed.fromGlobalSearch]);

  const openSingleModal = (e, item) => {
    e.stopPropagation();
    setAnchorEl(null);
    setSelectedArticles([]);
    dispatch(toggleActionableArticleId(item.id));
    const hasActiveOrdr = item.active_orders > 0;
    if (hasActiveOrdr) {
      dispatch(toggleActionModal());
    } else {
      dispatch(toggleDeleteModal());
    }
  };

  const handleDelete = (ids) => {
    if (actionableArticelId) {
      deleteArticleMutation.mutate({
        id: actionableArticelId,
        main_workspace_id: parseInt(currentWorkspace?.workspace_id),
      });
    } else {
      const allIds = ids.map((item) => item.id);
      if (allIds) {
        deleteAllMutation.mutate({
          ids: allIds,
          main_workspace_id: parseInt(currentWorkspace?.workspace_id),
        });
      }
    }
  };

  const hasActiveOrdr = selectedArticles.some((order) => order.active_orders > 0);
  const openDeleteModal = (e) => {
    e.stopPropagation();
    if (hasActiveOrdr) {
      dispatch(toggleActionModal());
    } else {
      dispatch(toggleDeleteModal());
    }
  };

  useEffect(() => {
    if (user && currentWorkspace) {
      const handleKeyDown = (e) => {
        const isMac = e.metaKey; // `metaKey` for Cmd on Mac
        const isWindows = e.ctrlKey; // `ctrlKey` for Ctrl on Windows

        if ((isMac || isWindows) && e.shiftKey && e.key.toLowerCase() === "v") {
          if (actionableArticelId) {
            e.preventDefault();
            setAnchorEl(null);
            handleClickOptionButton(actionableArticelId, "view");
          }
        }
        if ((currentWorkspace.role_name.includes("supplier") && currentWorkspace.invited_workspace_type === "agent")) {
          return
        } else {
          // Edit Shortcut: Cmd+R (Mac) or Ctrl+R (Windows)
          if ((isMac || isWindows) && (e.key === "e" || e.key === "E") && hasPermission("updateArticle")) {
            e.preventDefault();
            if (actionableArticelId) {
              setAnchorEl(null);
              handleClickOptionButton(actionableArticelId, "edit");
            }
          }

          if ((e.ctrlKey || e.metaKey) && (e.key === "B" || e.key === "b") && hasPermission("canAddArticle")) {
            e.preventDefault();
            setAnchorEl(null);
            setMenuAnchorEl(null);
            if ((currentWorkspace && currentWorkspace.is_owner && (!subscription || (subscription && (!subscription_status || subscription_status === "canceled")))) || !workspacePlan) {
              setShowUpgradeModal(true);
            } else if (checkPlanLimitation && !checkPlanLimitation.canCreateArticle) {
              dispatch(toggleReachedModal());
            } else {
              dispatch(toggleIsArticleCreateModalOpen());
              if (!isArticleEditable) {
                dispatch(toggleIsArticleEditable());
              }
              if (actionableArticelId) {
                dispatch(toggleActionableArticleId());
              }
            }
          }
          if ((e.ctrlKey || e.metaKey) && (e.key === "U" || e.key === "u") && hasPermission("canAddArticle")) {
            e.preventDefault();
            setAnchorEl(null);
            setMenuAnchorEl(null);
            if ((currentWorkspace && currentWorkspace.is_owner && (!subscription || (subscription && (!subscription_status || subscription_status === "canceled")))) || !workspacePlan) {
              setShowUpgradeModal(true);
            } else if (checkPlanLimitation && !checkPlanLimitation.canCreateArticle) {
              dispatch(toggleReachedModal());
            } else {
              dispatch(toggleIsUploadBulkArticleModalOpen());

              if (actionableArticelId) {
                dispatch(toggleActionableArticleId());
              }
            }
          }
          if (e.key === "Delete" && hasPermission("deleteArticle")) {
            e.preventDefault();
            setAnchorEl(null);
            if (actionableArticelId && actionableArticleItem) {
              if (actionableArticleItem.active_orders > 0) {
                dispatch(toggleActionModal());
              } else {
                dispatch(toggleDeleteModal());
              }
            } else if (selectedArticles.length > 0) {
              dispatch(toggleDeleteModal());
            }
          }
          // Duplicate Shortcut: Cmd+Shift+D (Mac) or Ctrl+Shift+D (Windows)
          if ((isMac || isWindows) && (e.key === "D" || e.key === "d") && hasPermission("duplicateArticle")) {
            e.preventDefault();
            if (actionableArticelId) {
              setAnchorEl(null);
              handleClickOptionButton(actionableArticelId, "duplicate");
            }
          }
          if ((e.ctrlKey || e.metaKey) && (e.key === "A" || e.key === "a") && hasPermission("deleteArticle")) {
            e.preventDefault();
            const eligibleTemplates = selectedArticles.filter(
              (item) => parseInt(item.active_orders) === 0 && parseInt(item?.created_by_user?.own_workspace_id) === parseInt(user?.workspace_id),
            );
            const allChecked =
              eligibleTemplates?.length > 0 && selectedArticles.length === eligibleTemplates.length;
            const newSelectAllState = !allChecked;
            const checkboxEvent = { target: { checked: newSelectAllState } };
            handleSelectAll(checkboxEvent);
          }
        }
      };

      window.addEventListener("keydown", handleKeyDown);
      return () => {
        window.removeEventListener("keydown", handleKeyDown);
      };
    }
  }, [user, currentWorkspace, dispatch, actionableArticelId, selectedArticles, handleSelectAll]);

  return (
    <>
      <div>
        <div className="bg-btn-surface-secondary border border-border-divider-stroke  rounded-xl custom-card w-full  mb-6 ">
          <SearchCreatePanelUpdate
            permissionText="canAddArticle"
            permissionText2="deleteArticle"
            createTxt={
              (currentWorkspace &&
                currentWorkspace.role_name.includes("supplier") && currentWorkspace.invited_workspace_type === "agent")
                ? ""
                : "Add Article"
            }
            title="Articles"
            handleDownloadCSV={handleDownloadCSV}
            selectedItems={selectedArticles}
            openDeleteModal={openDeleteModal}
            btnText={articleAllData && articleAllData.total}
            onBtnClick={openMenu}
            onChange={handleSearch}
            classWidth="w-full md:w-[129px] whitespace-nowrap"
          >
            <ArticleFilters
              workspaceId={workspaceId}
              genderList={genderDataList}
              setGenderList={setGenderDataList}
              {...{
                activeFilters,
                setActiveFilters,
                filteredData,
                setFilteredData,
                articleOptions,
                setArticleOptions,
              }}
            />
          </SearchCreatePanelUpdate>
          <div className="z-10 relative px-4">
            <Menu
              id="action-menu"
              anchorEl={menuAnchorEl}
              open={isMenuOpen}
              onClose={closeMenu}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              PaperProps={{
                style: {
                  maxHeight: 45 * 4.5,
                  border: "1px solid var(--color-border-secondary)",
                  padding: 0,
                  marginTop: "4px",
                  backgroundColor: "transparent",
                  boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.05)",
                  justifyContent: "center",
                  borderRadius: "8px",
                },
              }}
            >

              <MoreActionOptionUpdated
                createButton={
                  <CreateButton
                    title="Create new"
                    onClick={(e) => {
                      e.stopPropagation();
                      openCreateModal();
                    }}
                    shortcut="⌘+B"
                    className={"w-[236px]"}
                  />
                }
                uploadButton={
                  <UploadButton
                    title="Upload"
                    onClick={(e) => {
                      e.stopPropagation();
                      openUploadBulkOrderModal();
                    }}
                    shortcut="⌘+U"
                    className={"w-[236px]"}
                  />
                }
              />

            </Menu>
          </div>

          <div className="w-full max-w-screen overflow-x-auto ">
            <table className="w-full border-spacing-0 table-auto rounded-table-custom overflow-x-auto">
              <thead className="text-sm text-left whitespace-nowrap text-global-paragraph">
                <tr className="border-b  border-t border-b-global-divider-soft border-t-global-divider-soft">
                  <th className="pl-6 pr-4 py-[11.5px]">
                    <div className="flex items-center gap-3">
                      <input
                        type="checkbox"
                        className={`rounded-md  w-5 h-5 ${articleList && articleList.length > 0 ? "cursor-pointer" : "cursor-default"}`}
                        onChange={handleSelectAll}
                        checked={
                          articleList?.filter((item) => parseInt(item.active_orders) === 0 && parseInt(item?.created_by_user?.own_workspace_id) === parseInt(user?.workspace_id)).length >
                          0 &&
                          selectedArticles.length ===
                          articleList?.filter((item) => parseInt(item.active_orders) === 0 && parseInt(item?.created_by_user?.own_workspace_id) === parseInt(user?.workspace_id)).length
                        }
                      />
                      <div className="flex items-center">
                        <span className="mr-1 text-xs font-medium text-global-paragraph leading-[18px]">
                          Article ID
                        </span>
                        <SortIcons
                          hasData={articleList && articleList.length > 0}
                          sortName="sku"
                          {...{ orderBy, setOrderBy }}
                        />
                      </div>
                    </div>
                  </th>

                  <th className="relative px-4  py-[11.5px]  ">
                    <div className="flex items-center">
                      <span className="mr-1 text-xs font-medium text-global-paragraph leading-[18px]">
                        Article Type
                      </span>
                      <SortIcons
                        hasData={articleList && articleList.length > 0}
                        sortName="name"
                        {...{ orderBy, setOrderBy }}
                      />
                    </div>
                  </th>

                  <th className="relative px-4 py-[11.5px]  ">
                    <div className="flex items-center">
                      <span className="mr-1 text-xs font-medium text-global-paragraph leading-[18px]">
                        Sex
                      </span>
                      <SortIcons
                        hasData={articleList && articleList.length > 0}
                        sortName="gender_id"
                        {...{ orderBy, setOrderBy }}
                      />
                    </div>
                  </th>

                  <th className="relative px-4 py-[11.5px]  ">
                    <div className="flex items-center">
                      <span className="mr-1 text-xs font-medium text-global-paragraph leading-[18px]">
                        Compostion
                      </span>
                      <SortIcons
                        hasData={articleList && articleList.length > 0}
                        sortName="status"
                        {...{ orderBy, setOrderBy }}
                      />
                    </div>
                  </th>
                  <th className="relative px-4 py-[11.5px]  ">
                    <div className="flex items-center">
                      <span className="mr-1 text-xs font-medium text-global-paragraph leading-[18px]">
                        Active Orders
                      </span>
                      <SortIcons
                        hasData={articleList && articleList.length > 0}
                        sortName="active_orders"
                        {...{ orderBy, setOrderBy }}
                      />
                    </div>
                  </th>
                  <th className="relative px-4 py-[11.5px]  ">
                    <div className="flex items-center">
                      <span className="mr-1 text-xs font-medium text-global-paragraph leading-[18px]">
                        Date Added
                      </span>
                      <SortIcons
                        hasData={articleList && articleList.length > 0}
                        sortName="created_at"
                        {...{ orderBy, setOrderBy }}
                      />
                    </div>
                  </th>
                  <th className="relative px-4 py-[11.5px]  ">
                    <div className="flex items-center">
                      <span className="mr-1 text-xs font-medium text-global-paragraph leading-[18px]">
                        Last Updated
                      </span>
                      <SortIcons
                        hasData={articleList && articleList.length > 0}
                        sortName="updated_at"
                        {...{ orderBy, setOrderBy }}
                      />
                    </div>
                  </th>

                  <th className="relative px-4 py-[11.5px]  ">
                    <div className="flex items-center">
                      <span className="mr-1"> </span>
                    </div>
                  </th>
                </tr>
              </thead>

              <tbody>
                {!isarticlesQuryLoading && articleList && articleList?.length > 0 ? (
                  articleList?.map((item, index) => (
                    <tr
                      key={index}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleClickOptionButton(item.id, "view");
                      }}
                      className={`whitespace-nowrap border-b last:border-0 border-b-global-divider-soft  ${index % 2 === 0 ? "bg-global-white" : "bg-white"} rounded-lg text-global-paragraph text-sm font-normal cursor-pointer`}
                    >
                      <td className="pl-6 pr-4 py-[15.5px]  ">
                        <div className="flex items-center gap-3">
                          <input
                            onChange={() => onClickCheckbox(item)}
                            onClick={(e) => e.stopPropagation()}
                            checked={
                              selectedArticles.some((order) => order.id === item.id) &&
                              parseInt(item.active_orders) === 0 && parseInt(user?.workspace_id) === parseInt(item?.created_by_user?.own_workspace_id)
                            }
                            type="checkbox"
                            className="rounded-md border cursor-pointer w-5 h-5  "
                          />
                          <div className="flex justify-start items-center gap-3">
                            <div className="flex justify-start items-center text-table-text-data-dark font-medium text-sm gap-3">
                              <div className="bg-[#F2F4F7] h-10 w-10 rounded-full flex items-center justify-center p-2">
                                <EditLable className="w-6 h-6" />
                              </div>
                            </div>

                            <div className="flex flex-col">
                              <p className="text-sm leading-5 font-medium text-global-title">
                                {item.sku ? truncateString(item.sku) : null}
                              </p>
                              <div className="flex items-center">
                                <span className="text-sm text-global-paragraph leading-5">
                                  {`${genderName(item.gender_id)}'s `}
                                  <span>{item?.article_types?.name}</span>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td className="relative px-4 py-[15.5px] ">
                        {item?.article_types ? (
                          <Button
                            variant={"status"}
                            className={`rounded-[6px] w-auto h-[22px] px-2 py-[2px]  border border-border-divider-stroke custom-card cursor-default capitalize   text-status-text-default  text-xs leading-[18px] font-medium `}
                          >
                            {item?.article_types?.name}
                          </Button>
                        ) : (
                          null
                        )}
                      </td>
                      <td className="relative px-4 py-[15.5px] ">
                        {item?.gender_id ? (
                          <Button
                            variant={"status"}
                            className={`rounded-[6px] w-auto h-[22px] px-2 py-[2px]  border border-border-divider-stroke custom-card cursor-default capitalize   text-status-text-default  text-xs leading-[18px] font-medium `}
                          >
                            {genderName(item.gender_id)
                              ? truncateString(genderName(item.gender_id))
                              : null}
                          </Button>
                        ) : (
                          null
                        )}
                      </td>
                      <td className="relative px-4 py-[15.5px] text-sm text-global-paragraph leading-5  ">
                        {articleComposition(item)
                          ? truncateString(articleComposition(item))
                          : null}
                      </td>
                      <td className="relative px-4 py-[15.5px] ">
                        {item?.active_orders === 0 ? "0" : item?.active_orders || null}
                      </td>
                      <td className="relative px-4 py-[15.5px] text-sm text-global-paragraph leading-5  ">
                        {item?.created_at
                          ? formatDateToDayMonthYear(item?.created_at)
                          : null}
                      </td>
                      <td className="relative px-4 py-[15.5px] text-sm text-global-paragraph leading-5  ">
                        {item?.updated_at
                          ? formatDateToDayMonthYear(item?.updated_at)
                          : null}
                      </td>

                      <td
                        onClick={(e) => e.stopPropagation()}
                        className="relative cursor-pointer px-4 py-[15.5px] text-sm text-global-paragraph leading-5  "
                      >
                        <IconButton
                          aria-label="more"
                          id={`${index}`}
                          aria-controls={open ? "long-menu" : undefined}
                          aria-expanded={open ? "true" : undefined}
                          aria-haspopup="true"
                          onClick={(e) => {
                            handleActionClick(item.id);
                            setAnchorEl(e.currentTarget);
                            e.stopPropagation();
                            dispatch(setActionableArticleItem(item));
                            setSelectedArticles([]);
                          }}
                        >
                          <MoreOptions width={20} height={20} />
                        </IconButton>
                        {!isArticleCreateModalOpen &&
                          actionableArticelId &&
                          actionableArticelId === item?.id && (
                            <Menu
                              id="long-menu"
                              MenuListProps={{
                                "aria-labelledby": "long-button",
                              }}
                              anchorEl={anchorEl}
                              open={open}
                              onClose={handleClose}
                              anchorOrigin={{
                                vertical: "top",
                                horizontal: "left",
                              }}
                              elevation={0}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                              }}
                              PaperProps={{
                                style: {
                                  maxHeight: 45 * 4.5,
                                  borderRadius: "8px",
                                  border: "1px solid var(--color-border-secondary)",
                                  padding: 0,
                                  backgroundColor: "transparent",
                                  boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.05)",
                                  justifyContent: "center",
                                },
                              }}
                            >
                              <MoreActionOptionUpdated
                                viewButton={
                                  <ViewButton
                                    title="View"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleClickOptionButton(item.id, "view");
                                    }}
                                    isNotPartner={true}
                                    shortcut="⌘+Shift+V"
                                    className={"w-[236px]"}
                                  />
                                }
                              editButton={(currentWorkspace && currentWorkspace.role_name.includes("supplier") && currentWorkspace.invited_workspace_type === "agent") ? null : hasPermission("updateArticle") && parseInt(user?.workspace_id) === parseInt(item?.created_by_user?.own_workspace_id) &&
                                  <EditButton
                                    title="Edit"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleClickOptionButton(item.id, "edit");
                                    }}
                                    shortcut="⌘+E"
                                    className={"w-[236px]"}
                                  />
                                }
                                duplicateButton={
                                  (currentWorkspace &&
                                    currentWorkspace.role_name.includes("supplier") && currentWorkspace.invited_workspace_type === "agent") ? null : hasPermission("duplicateArticle") && parseInt(user?.workspace_id) === parseInt(item?.created_by_user?.own_workspace_id) &&
                                  <DuplicateButton
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleClickOptionButton(item.id, "duplicate");
                                    }}
                                    shortcut="⌘+D"
                                    className={"w-[236px]"}
                                  />
                                }
                                deleteButton={
                                  (currentWorkspace &&
                                    currentWorkspace.role_name.includes("supplier") && currentWorkspace.invited_workspace_type === "agent") ? null : hasPermission("deleteArticle") && parseInt(user?.workspace_id) === parseInt(item?.created_by_user?.own_workspace_id) &&
                                  <DeleteButton
                                    title="Remove"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      openSingleModal(e, item);
                                    }}
                                    shortcut="⌘+Delete"
                                    className={"w-[236px]"}
                                  />
                                }
                              />
                            </Menu>
                          )}
                      </td>
                    </tr>
                  ))
                ) : isarticlesQuryLoading ? (
                  <tr>
                    <td colSpan={11} className="text-center pb-3">
                      <LoadingComponent />
                    </td>
                  </tr>
                ) : searchKey && articleList && articleList?.length === 0 ? (
                  <EmptyTable colSpan={11} text="Result not found" />
                ) : (
                  <EmptyTable colSpan={11} />
                )}
              </tbody>
            </table>
          </div>
          {articleAllData && articleAllData.data && articleAllData.total > 0 && (
            <TertiaryPagination
              page={page}
              totalPages={Math.ceil(articleAllData.total / size)}
              setPage={setPage}
              size={size}
              handleSizeChange={handleSizeChange}
            />
          )}
        </div>
      </div>
      <Modal open={isArticleCreateModalOpen} close={closeModal}>
        <CreateArticle closeModal={closeModal} refetchArticleData={refetchDatas} />
      </Modal>
      <Modal open={isUploadBulkArticleModalOpen} close={closeModal}>
        <UploadBulkArticle closeModal={closeModal} refetch={refetchDatas} />
      </Modal>
      <Modal open={deleteModal} close={closeModal}>
        <CommonDiscardButtonUpdated
          closeModal={closeModal}
          title={selectedArticles.length > 1 ? "Remove articles" : "Remove article"}
          title1={"Action Required"}
          subText1={`Are you sure you want to remove selected ${selectedArticles.length > 1 ? "articles" : "article"}? This action cannot be undone.`}
          subText3={`To delete article ${selectedArticles.article_id}, first unlink any active orders that are associated with this article.`}
          className="w-full lg:max-w-[600px]"
          IconComponent={EditLable}
          confirmButtonText="Remove"
          confirmButtonVariant="warning"
          confirmButtonClassName={"w-full h-11"}
          // onConfirmClick={() => openDeleteModal(selectedArticles)}
          onConfirmClick={() =>
            actionableArticelId ? handleOnDelete() : handleDelete(selectedArticles)
          }
          icon={
            <TrashUpdateIcon
              width={20}
              height={20}
              strokeColor={"var(--color-icon-fill-secondary-button)"}
            />
          }
        />
      </Modal>

      <Modal open={actionModal} close={closeModal}>
        <ActionRequired
          closeModal={closeModal}
          title={"Action Required"}
          subText1={`To delete article ${selectedArticles.find((order) => order.active_orders > 0)?.sku}, first unlink any active orders that are associated with this article.`}
          className="w-[600px] max-h-[600]"
          IconComponent={Action}
        />
      </Modal>
      <Modal open={showUpgradeModal} close={closeModal}>
        <CommonDiscardButtonUpdated
          closeModal={closeModal}
          title="Upgrade plan"
          subText1={currentWorkspace && !currentWorkspace?.is_owner && !workspacePlan ? `Your partner workspace is currently on a free plan.` : `You are currently on a free plan. Upgrade to a paid plan to start creating your own ${user && user?.workspace_type} workspaces.`}
          className="max-w-[544px]"
          confirmButtonClassName="w-full h-11"
          IconComponent={PlanIcon}
          confirmButtonText={currentWorkspace && !currentWorkspace?.is_owner && !workspacePlan ? null : "Ugrade Plan"}
          confirmButtonVariant="primary"
          onConfirmClick={() => {
            navigate(`/workspace/${currentWorkspace?.user_workspace_id}/setting?selectedTab=plan`);
            if (showUpgradeModal) {
              setShowUpgradeModal(false);
            }
          }}
          icon={
            <Shock width={20} height={20} strokeColor={"var(--color-icon-fill-secondary-button)"} />
          }
        />
      </Modal>
      <Modal open={reachedModal} close={closeModal}>
        <LimitReached
          closeModal={closeModal}
          IconComponent={Action}
          title="Article limit reached"
          subText1={`You cannot add a new article as you have reached the maximum limit of ${workspacePlan && workspacePlan?.package?.articles} articles in your ${workspacePlan && workspacePlan?.package?.name} plan. Please reduce your usage or upgrade your plan.`}
          className={"w-full lg:w-[600px]"}
          linked={workspacePlan && workspacePlan?.articles_count}
          totalLink={workspacePlan && workspacePlan?.package?.articles}
          linkedtitle={"Articles"}
          confirmButtonText={currentWorkspace && !currentWorkspace?.is_owner ? null : "Ugrade Plan"}
          confirmButtonVariant={"primary"}
          confirmButtonClassName={"w-full h-11"}
          onConfirmClick={() => {
            navigate(`/workspace/${currentWorkspace?.user_workspace_id}/setting?selectedTab=plan`);
            if (reachedModal) {
              dispatch(toggleReachedModal());
            }
          }}
          icon={
            <Shock width={20} height={20} strokeColor={"var(--color-icon-fill-secondary-button)"} />
          }
        />
      </Modal>
    </>
  );
};

export default ArticleContent;
