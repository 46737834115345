import { useQuery } from "@tanstack/react-query";
import { Lock, LogoCollpased } from "assests";
import EmptyComponent from "components/Card/EmptyComponent";
import MapViewUpdated from "components/Card/MapViewUpdated";
import BreadCrumb from "components/TopHeader/BreadCrumb";
import TopNavbar from "components/TopHeader/TopNavbar";
import BorderDesign from "components/UI/BorderDesign";
import { Button } from "components/UI/Button";
import Card from "components/UI/Card";
import CircleProgressBarMultiple from "components/UI/CircleProgressBarMultiple";
import LoadingComponent from "components/UI/LoadingComponent";
import SubNavbar from "layout/SubNavbar";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getAgentDetails } from "services/company-details.services";
import { formatLocation, transpercencyLineProgressPercentageColors } from "utils/helper";

const AgentDetailPage = () => {
  const [agentDetails, setAgentDetails] = useState(null);
  const [allData, setAllData] = useState(null);
  const [totalOrders, setTotalOrders] = useState(0);
  const location = useLocation();
  const navigate = useNavigate();
  const isStatusRemoved = location.search.includes("status=removed");
  const { agentId, workspaceId } = useParams();
  const handleViewDetailsClick = () => {
    navigate(`/workspace/${workspaceId}/orders?status=active&partner_id=${agentId}`, {
      replace: true,
    });
  };
  /// mutation
  const { data: agentQueryData, isLoading } = useQuery({
    queryKey: ["agents", "getAgentDetails", agentId],
    queryFn: () =>
      getAgentDetails(agentId, { status: "active", workspace_id: parseInt(workspaceId) }),
    enabled: !!agentId,
  });

  useEffect(() => {
    if (agentQueryData && agentQueryData.status === 200 && !isLoading) {
      const allDetails = agentQueryData.data;
      const details = agentQueryData.data.workspace;
      const total_order = agentQueryData.data.total_order;
      setAgentDetails(details);
      setTotalOrders(total_order);
      setAllData(allDetails);
    }
  }, [isLoading, isLoading]);

  return (
    <div>
      <TopNavbar isFromDashboard={false}>
        <BreadCrumb text="My Agents" />
      </TopNavbar>
      <div className="px-4 md:px-8   pt-[64px] md:pt-[108px]">
        <div className="block md:hidden pt-4">
          <BreadCrumb text="My Agents" />
        </div>
        <div className="pt-4 md:pt-6">
          <SubNavbar
            title={agentDetails && agentDetails?.name}
            subtitle={`Agent located in ${
              agentDetails &&
              formatLocation({
                region: agentDetails?.region,
                country: agentDetails?.country,
              })
            }`}
          />
        </div>
        <div className="bg-global-divider-medium h-[1px] my-5  hidden md:block" />
        {!agentDetails && isLoading ? (
          <LoadingComponent />
        ) : agentDetails ? (
          <>
            <div className="flex flex-col flex-grow w-full h-full pt-4 pb-12 gap-y-4">
              {isStatusRemoved && (
                <div className="h-[37px] flex flex-row justify-content-start bg-info-surface-danger  px-3 py-2.5">
                  <div className="flex items-center gap-2">
                    <Lock height={14} width={14} fillColor={"var(--color-icon-outline-danger)"} />
                    <p className="text-info-text-danger text-sm font-normal">
                      You have removed this company. Now you can only see the data and generate the
                      reports.
                    </p>
                  </div>
                </div>
              )}

              <div className="grid grid-cols-12 gap-4">
                <div className="col-span-12 lg:col-span-6 w-full">
                  <Card
                    size={"sm"}
                    borderRadius={"sm"}
                    className="p-0 m-0 bg-global-theme  sticky top-0  rounded-xl gap-3 lg:gap-9  text-card-small-title shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)] border border-divider-stroke"
                  >
                    <div className="px-6 pt-5 pb-[19px] flex flex-col justify-center gap-5 items-center">
                      <div className="p-3">
                        <CircleProgressBarMultiple
                          currentProgress={
                            allData?.transparency_score ? allData?.transparency_score : 0
                          }
                          size={32}
                          strokeColorVariable={transpercencyLineProgressPercentageColors(
                            allData?.transparency_score ? allData?.transparency_score : 0,
                          )}
                          childrenProgress={
                            allData?.verification_score ? allData?.verification_score : 0
                          }
                          childrenSize={25}
                          childrenStrokeColorVariable={"--color-progress-surface-blue"}
                          subText={totalOrders}
                          text="Orders"
                        />
                      </div>
                      <div>
                        <div className="justify-center items-center gap-3 inline-flex">
                          <div className="justify-start items-center gap-2 flex">
                            <div className="w-2 h-2 bg-global-primary rounded-full" />
                            <div className="text-input-text-label text-sm font-normal leading-tight">
                              Transparency %
                            </div>
                          </div>
                          <div className="justify-start items-center gap-2 flex">
                            <div className="w-2 h-2 bg-global-blue rounded-full" />
                            <div className="text-input-text-label text-sm font-normal leading-tight">
                              Verification %
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="border-t border-border-secondary">
                      <div className="px-6 py-4 justify-end flex items-center  text-sm font-semibold text-login-text-title">
                        <Button variant="link" className="p-0 m-0" onClick={handleViewDetailsClick}>
                          View Active Orders
                        </Button>
                      </div>
                    </div>
                  </Card>
                </div>
                <div className="col-span-12 lg:col-span-6 w-full">
                  <Card
                    size={"sm"}
                    borderRadius={"sm"}
                    className="p-0 m-0 bg-global-theme  sticky top-0 rounded-xl gap-3 lg:gap-9  text-card-small-title shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)] border border-divider-stroke"
                  >
                    <div className="px-6 pt-5 pb-[19px] flex flex-col justify-start gap-5 items-start">
                      <div className="flex flex-row justify-start items-center gap-2.5 ">
                        <div
                          className="rounded-xl"
                          style={{
                            boxShadow:
                              "0px 1.25px 2.5px 0px rgba(16, 24, 40, 0.06), 0px 1.25px 3.75px 0px rgba(16, 24, 40, 0.1)",
                          }}
                        >
                          <BorderDesign
                            className={`w-[45px] h-[45px] rounded-xl bg-global-primary`}
                            boxShadowShow={false}
                          >
                            <LogoCollpased fillColor="var(--color-white)" />
                          </BorderDesign>
                        </div>
                        <div className="text-global-title font-semibold text-sm lg:text-base truncate ">
                          Partner ID:{" "}
                          <span className="font-normal">{agentDetails?.partner_id}</span>
                        </div>
                      </div>
                    </div>
                    <div className="border-t border-border-secondary h-[204px] relative w-full">
                      <MapViewUpdated details={agentDetails} />

                      <div className=" px-[18px] py-2.5 origin-top-left absolute left-4 bottom-4  bg-global-theme rounded-2xl shadow-[0px_18px_40px_0px_rgba(112,144,176,0.12)] flex-col justify-start items-start gap-2.5 inline-flex">
                        <div className="flex-col justify-start items-start flex">
                          <div className="self-stretch  justify-start items-center gap-2.5 inline-flex">
                            <div className="h-2.5 w-2.5 rounded-full bg-global-primary" />
                            <div className="text-text-data-dark text-xs font-medium  leading-[18px]">
                              Active
                            </div>
                          </div>
                          <div className="text-text-data-dark text-base font-semibold  leading-normal">
                            {agentDetails &&
                              formatLocation({
                                region: agentDetails?.region,
                                country: agentDetails?.country,
                              })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </div>
          </>
        ) : (
          <EmptyComponent text="No details found!" icon={false} />
        )}
      </div>
    </div>
  );
};

export default AgentDetailPage;
