import {
  AccountMemberIcon,
  Amount,
  ArticleIcon,
  BrandRetailerIcon,
  CompanyIcon,
  DashboardIcon,
  MaterialExtractionIcon,
  MaterialProcessingIcon,
  MaterialProductionIcon,
  OrderIcon,
  PartnerLinkIcon,
  PartnersIcon,
  PlanIcon,
  ProductAssemblyIcon,
  ReportIcon,
  SettingIcon,
  SupportIcon,
  UserIcon,
  VideoContenticon,
} from "assests";
import * as z from "zod";
export const userRoles = [
  "superadmin",
  "retailer.admin",
  "retailer.editor",
  "retailer.viewer",
  "agent.admin",
  "agent.editor",
  "agent.viewer",
  "supplier.admin",
  "supplier.editor",
  "supplier.viewer",
  "workspace.admin",
  "workspace.editor",
  "workspace.viewer",
];

export const SidebarMenu = {
  main_menu: [
    {
      name: "Workspaces",
      path: "workspaces",
      icon: DashboardIcon,
      iconShow: true,
      exclude: [],
    },
    {
      name: "Orders",
      path: "orders",
      icon: OrderIcon,
      iconShow: true,
      exclude: [],
    },
    {
      name: "Articles",
      path: "articles",
      icon: ArticleIcon,
      iconShow: true,
      exclude: [],
    },
  ],

  partners: [
    {
      name: "Clients",
      path: "retailers",
      icon: PartnersIcon,
      iconShow: false,
      exclude: [],
    },
    {
      name: "Agents",
      path: "agents",
      icon: PartnersIcon,
      iconShow: false,
      exclude: [],
    },
    {
      name: "Suppliers",
      path: "suppliers",
      icon: PartnersIcon,
      iconShow: false,
      exclude: [],
    },
  ],
  compliancy: [
    {
      name: "Certificates",
      path: "certificates",
      icon: ReportIcon,
      iconShow: false,
      exclude: [],
    },
    {
      name: "Audits",
      path: "audits",
      icon: SettingIcon,
      iconShow: false,
      exclude: [],
    },
  ],
  account: [
    {
      name: "Reporting",
      path: "report",
      icon: ReportIcon,
      iconShow: true,
      exclude: [],
    },
    {
      name: "Settings",
      path: "setting",
      icon: SettingIcon,
      iconShow: true,
      exclude: [],
    },
    {
      name: "Support",
      path: "support",
      icon: SupportIcon,
      iconShow: true,
      supportButton: true,
      exclude: [],
    },
  ],
};

export const SidebarMenuSettings = {
  settings: [
    {
      name: "Company",
      path: "company-profile",
      icon: CompanyIcon,
      iconShow: true,
      exclude: [],
    },
    {
      name: "Profile",
      path: "profile-settings",
      icon: UserIcon,
      iconShow: true,
      exclude: [],
    },
    {
      name: "Plans",
      path: "plans",
      icon: PlanIcon,
      iconShow: true,
      exclude: [],
    },
    {
      name: "Billing",
      path: "billing",
      iconShow: true,
      icon: Amount,
      exclude: [],
    },
    {
      name: "Partner Links",
      path: "invitations",
      icon: PartnerLinkIcon,
      iconShow: true,
      exclude: [],
    },
    {
      name: "Account Members",
      path: "active-users",
      icon: AccountMemberIcon,
      iconShow: true,
      exclude: [],
    },
  ],
};

export const SettingItem = {
  name: "Setting",
  path: "setting",
  icon: SettingIcon,
  roles: userRoles,
};

export const WelcomeCardStepList = [
  {
    // title: "Welcome to PRO",
    // description: "We’re glad to have you onboard. Here are some quick tips to get you up and running.",
    step: 1,
    // video: VideoContenticon
  },
  {
    title: "Welcome to PRO",
    description:
      "We’re glad to have you onboard. Here are some quick tips to get you up and running.",
    step: 2,
    video: VideoContenticon,
  },
  {
    title: "Welcome to PRO",
    description:
      "We’re glad to have you onboard. Here are some quick tips to get you up and running.",
    step: 3,
    video: VideoContenticon,
  },
  {
    title: "Welcome to PRO",
    description:
      "We’re glad to have you onboard. Here are some quick tips to get you up and running.",
    step: 4,
    video: VideoContenticon,
  },
];

export const CreateAccountStepList = [
  {
    label: "Personal details",
    step: 1,
  },
  {
    label: "Company details",
    step: 2,
  },
  {
    label: "Set Password",
    step: 3,
  },
  {
    label: "Confirm Email",
    step: 4,
  },
  {
    label: "Ready to go!",
    step: 5,
  },
];

export const AccountDashboardPackageLists = [
  {
    tier: "Tier 4",
    id: "tier_4",
    title: "Raw Material Processing",
    percentage: 0,
    totalItems: 0,
    linkedItems: 0,
    icon: MaterialExtractionIcon,
  },
  {
    tier: "Tier 3",
    id: "tier_3",
    title: "Raw Material Extraction",
    percentage: 0,
    totalItems: 0,
    linkedItems: 0,
    icon: MaterialProcessingIcon,
  },
  {
    tier: "Tier 2",
    id: "tier_2",
    title: "Material Production",
    percentage: 0,
    totalItems: 0,
    linkedItems: 0,
    icon: MaterialProductionIcon,
  },
  {
    tier: "Tier 1",
    id: "tier_1",
    title: "Finished Product Assembly",
    percentage: 0,
    totalItems: 0,
    linkedItems: 0,
    icon: ProductAssemblyIcon,
  },
  {
    tier: "Tier 0",
    id: "tier_0",
    title: "Point of Sale",
    percentage: 0,
    totalItems: 0,
    linkedItems: 0,
    icon: BrandRetailerIcon,
  },
];

export const workspaceRoles = ["workspace.admin", "workspace.editor", "workspace.viewer"];

export const googleMapStyles = [
  {
    elementType: "geometry",
    stylers: [
      {
        color: "#f5f5f5",
      },
    ],
  },
  {
    elementType: "labels",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#616161",
      },
    ],
  },
  {
    elementType: "labels.text.stroke",
    stylers: [
      {
        color: "#f5f5f5",
      },
    ],
  },
  {
    featureType: "administrative",
    elementType: "geometry",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "administrative.land_parcel",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "administrative.land_parcel",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#bdbdbd",
      },
    ],
  },
  {
    featureType: "administrative.neighborhood",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "poi",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "geometry",
    stylers: [
      {
        color: "#eeeeee",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#757575",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "geometry",
    stylers: [
      {
        color: "#e5e5e5",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#9e9e9e",
      },
    ],
  },
  {
    featureType: "road",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "geometry",
    stylers: [
      {
        color: "#ffffff",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "road.arterial",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#757575",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry",
    stylers: [
      {
        color: "#dadada",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#616161",
      },
    ],
  },
  {
    featureType: "road.local",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#9e9e9e",
      },
    ],
  },
  {
    featureType: "transit",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "transit.line",
    elementType: "geometry",
    stylers: [
      {
        color: "#e5e5e5",
      },
    ],
  },
  {
    featureType: "transit.station",
    elementType: "geometry",
    stylers: [
      {
        color: "#eeeeee",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "geometry",
    stylers: [
      {
        color: "#c9c9c9",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#9e9e9e",
      },
    ],
  },
];

export const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
export const passwordPattern =
  /^(?=.*[!@#$%^&*()_+{}:"<>?[\];',./`~\\-])[A-Za-z\d!@#$%^&*()_+{}:"<>?[\];',./`~\\-]{8,}$/;
export const emailValidation = z
  .string()
  .min(1, { message: "Email is required" })
  .refine((email) => email.includes("@"), {
    message: "Email address must contain '@'.",
  })
  .refine(
    (email) => emailPattern.test(email), // general email format
    { message: "Email is invalid" },
  );

export const CreateUserStepList = [
  {
    step: 1,
  },
  {
    step: 2,
  },
  {
    step: 3,
  },
];

export const EditUserStepList = [
  {
    step: 1,
  },
  {
    step: 2,
  },
];
