const { createSlice } = require("@reduxjs/toolkit");

const agentSlice = createSlice({
  name: "agent",
  initialState: {
    currentStep: 1,
    validatedSteps: [],
    agentList: [],
    agent: null,
    actionableAgentId: null,
    actionableAgentWorkspaceId: null,
    isAgentCreateModalOpen: false,
    compositionFormLength: 1,
    allAgentData: null,
    showDiscardModal: false,
    deleteModal: false,
    CancelButton: false,
    cancleModal: false,
    resendModal: false,
    bulkDeleteModal: false,
    reachedModal: false,
    actionableAgentItem: null
  },
  reducers: {
    toggleIsAgentCreateModalOpen: (state) => ({
      ...state,
      isAgentCreateModalOpen: !state.isAgentCreateModalOpen,
    }),
    toggleActionableAgentId: (state, action) => ({
      ...state,
      actionableAgentId: action.payload ?? null,
    }),
    toggleActionableAgentWorkspaceId: (state, action) => ({
      ...state,
      actionableAgentWorkspaceId: action.payload ?? null,
    }),
    toggleActionableAgentItem: (state, action) => ({
      ...state,
      actionableAgentItem: action.payload ?? null,
    }),

    toggleShowDiscardModal: (state) => ({ ...state, showDiscardModal: !state.showDiscardModal }),
    toggleAgentDeleteModal: (state) => {
      state.deleteModal = !state.deleteModal;
    },
    toggleAgentBulkDeleteModal: (state) => {
      state.bulkDeleteModal = !state.bulkDeleteModal;
    },
    toggleAgentCancleModal: (state) => {
      state.cancleModal = !state.cancleModal;
    },
    toggleAgentResendModal: (state) => {
      state.resendModal = !state.resendModal;
    },
    toggleReachedModal: (state) => {
      state.reachedModal = !state.reachedModal;
    },
    setCurrentStep: (state, action) => ({
      ...state,
      currentStep: action.payload,
    }),
    setAgentList: (state, action) => {
      const { append, agentList, agent } = action.payload;
      return {
        ...state,
        agentList: append ? [...state.agentList, agent] : agentList,
      };
    },
    setAllAgentData: (state, action) => ({
      ...state,
      allAgentData: action.payload.allAgentData ?? null,
    }),
    setAgent: (state, action) => ({
      ...state,
      agent: action.payload ?? null,
    }),
    setActionableAgentId: (state, action) => ({
      ...state,
      actionableAgentId: action.payload ?? null,
    }),
    setValidatedSteps: (state, action) => {
      if (action.payload) {
        const newValidatedSteps = new Set([...state.validatedSteps, action.payload]);
        return {
          ...state,
          validatedSteps: Array.from(newValidatedSteps),
        };
      } else {
        return { ...state, validatedSteps: [] };
      }
    },
    setCompositionFormLength: (state, action) => ({
      ...state,
      compositionFormLength: action.payload,
    }),
  },
});

export const {
  setAllAgentData,
  setCurrentStep,
  setAgentList,
  setAgent,
  setActionableAgentId,
  setValidatedSteps,
  setCompositionFormLength,
  toggleIsAgentCreateModalOpen,
  toggleActionableAgentId,
  toggleShowDiscardModal,
  toggleAgentDeleteModal,
  toggleAgentCancleModal,
  toggleAgentResendModal,
  toggleActionableAgentWorkspaceId,
  toggleAgentBulkDeleteModal,
  toggleReachedModal,
  toggleActionableAgentItem
} = agentSlice.actions;

export default agentSlice.reducer;
