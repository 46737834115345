import React from "react";

const dragDrop = React.forwardRef(
  (
    { width = "48", height = "50", viewBox = "0 0 48 50", fill = "none", className, ...props },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M35 41.25H11C9.20507 41.25 7.75 39.7949 7.75 38V6C7.75 4.20508 9.20508 2.75 11 2.75H27C27.1212 2.75 27.2375 2.79816 27.3232 2.88388L38.1161 13.6768C38.2018 13.7625 38.25 13.8788 38.25 14V38C38.25 39.7949 36.7949 41.25 35 41.25Z"
          fill="white"
          stroke="#D0D5DD"
          strokeWidth="1.5"
        />
        <path d="M27 2.5V10C27 12.2091 28.7909 14 31 14H38.5" stroke="#D0D5DD" strokeWidth="1.5" />
        <path
          d="M1 22C1 20.8954 1.89543 20 3 20H25C26.1046 20 27 20.8954 27 22V34C27 35.1046 26.1046 36 25 36H3C1.89543 36 1 35.1046 1 34V22Z"
          fill="#11C182"
        />
        <path
          d="M6.38947 24.7273V32H4.85183V24.7273H6.38947ZM7.65456 24.7273H9.55087L11.5537 29.6136H11.6389L13.6418 24.7273H15.5381V32H14.0466V27.2663H13.9862L12.1041 31.9645H11.0885L9.20641 27.2486H9.14604V32H7.65456V24.7273ZM21.6274 27.0781C21.5777 26.9053 21.5078 26.7526 21.4179 26.62C21.3279 26.4851 21.2178 26.3714 21.0876 26.2791C20.9598 26.1844 20.813 26.1122 20.6473 26.0625C20.4839 26.0128 20.3028 25.9879 20.104 25.9879C19.7323 25.9879 19.4056 26.0803 19.1238 26.2649C18.8445 26.4496 18.6267 26.7183 18.4704 27.071C18.3142 27.4214 18.2361 27.8499 18.2361 28.3565C18.2361 28.8632 18.313 29.294 18.4669 29.6491C18.6208 30.0043 18.8386 30.2753 19.1203 30.4624C19.402 30.647 19.7346 30.7393 20.1182 30.7393C20.4662 30.7393 20.7633 30.6778 21.0095 30.5547C21.2581 30.4292 21.4475 30.2528 21.5777 30.0256C21.7103 29.7983 21.7765 29.5296 21.7765 29.2195L22.089 29.2656H20.214V28.108H23.2574V29.0241C23.2574 29.6634 23.1224 30.2126 22.8525 30.6719C22.5827 31.1288 22.211 31.4815 21.7375 31.7301C21.264 31.9763 20.7219 32.0994 20.1111 32.0994C19.4292 32.0994 18.8303 31.9491 18.3142 31.6484C17.7981 31.3454 17.3956 30.9157 17.1068 30.3594C16.8203 29.8007 16.6771 29.1378 16.6771 28.3707C16.6771 27.7812 16.7623 27.2557 16.9328 26.794C17.1056 26.33 17.3471 25.937 17.6572 25.6151C17.9674 25.2931 18.3284 25.0481 18.7403 24.88C19.1523 24.7119 19.5985 24.6278 20.0791 24.6278C20.491 24.6278 20.8746 24.6882 21.2297 24.8089C21.5848 24.9273 21.8997 25.0954 22.1743 25.3132C22.4513 25.531 22.6773 25.7902 22.8525 26.0909C23.0277 26.3892 23.1402 26.7183 23.1899 27.0781H21.6274Z"
          fill="white"
        />
        <g filter="url(#filter0_d_11643_3520)">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M34.6727 34.8478C35.16 34.6624 36.1213 34.7759 36.3751 35.3404C36.5913 35.8215 36.777 36.6327 36.7872 36.4598C36.8116 36.0756 36.7628 35.2446 36.9263 34.8103C37.045 34.4937 37.2785 34.1959 37.6226 34.0907C37.9119 34.0012 38.252 33.9699 38.5525 34.0335C38.8702 34.1001 39.2042 34.3323 39.329 34.5531C39.6965 35.2019 39.7026 36.5306 39.7198 36.4598C39.7848 36.1766 39.7909 35.18 40.0071 34.8103C40.1502 34.5656 40.5116 34.3469 40.7045 34.3115C41.0029 34.2574 41.3704 34.2407 41.683 34.3032C41.9358 34.3542 42.2779 34.6614 42.3703 34.8103C42.5926 35.1685 42.7174 36.1807 42.755 36.5369C42.7712 36.6837 42.8301 36.1276 43.0524 35.7704C43.4646 35.105 44.9243 34.9759 44.9791 36.4359C45.0055 37.1169 44.9994 37.0857 44.9994 37.5439C44.9994 38.0812 44.9872 38.4061 44.9588 38.7956C44.9283 39.2111 44.841 40.1535 44.7141 40.6096C44.6269 40.9231 44.3375 41.6281 44.0513 42.0509C44.0513 42.0509 42.9611 43.3526 42.8423 43.9378C42.7235 44.5241 42.7631 44.5283 42.7388 44.9427C42.7154 45.3582 42.8616 45.9039 42.8616 45.9039C42.8616 45.9039 42.0485 46.0122 41.6089 45.9393C41.212 45.8747 40.7207 45.0646 40.5938 44.8167C40.4192 44.4751 40.0467 44.5408 39.9015 44.7928C39.6742 45.1916 39.1818 45.907 38.8357 45.9518C38.1566 46.0393 36.7496 45.983 35.6482 45.9726C35.6482 45.9726 35.836 44.9198 35.4178 44.5585C35.1082 44.2877 34.5753 43.742 34.2565 43.4546L33.412 42.4955C33.1247 42.1206 32.3948 41.5281 32.1502 40.4284C31.934 39.4537 31.9553 38.9757 32.1878 38.5852C32.4233 38.1885 32.8679 37.9719 33.0547 37.9344C33.2658 37.8906 33.7571 37.8938 33.9429 37.9989C34.1692 38.127 34.2606 38.1645 34.4382 38.4061C34.6717 38.7258 34.755 38.881 34.6545 38.5321C34.5773 38.2593 34.3276 37.9125 34.2139 37.522C34.1033 37.1461 33.8068 36.54 33.8282 35.9329C33.8363 35.7028 33.9327 35.13 34.6727 34.8478Z"
            fill="white"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M34.6727 34.8478C35.16 34.6624 36.1213 34.7759 36.3751 35.3404C36.5913 35.8215 36.777 36.6327 36.7872 36.4598C36.8116 36.0756 36.7628 35.2446 36.9263 34.8103C37.045 34.4937 37.2785 34.1959 37.6226 34.0907C37.9119 34.0012 38.252 33.9699 38.5525 34.0335C38.8702 34.1001 39.2042 34.3323 39.329 34.5531C39.6965 35.2019 39.7026 36.5306 39.7198 36.4598C39.7848 36.1766 39.7909 35.18 40.0071 34.8103C40.1502 34.5656 40.5116 34.3469 40.7045 34.3115C41.0029 34.2574 41.3704 34.2407 41.683 34.3032C41.9358 34.3542 42.2779 34.6614 42.3703 34.8103C42.5926 35.1685 42.7174 36.1807 42.755 36.5369C42.7712 36.6837 42.8301 36.1276 43.0524 35.7704C43.4646 35.105 44.9243 34.9759 44.9791 36.4359C45.0055 37.1169 44.9994 37.0857 44.9994 37.5439C44.9994 38.0812 44.9872 38.4061 44.9588 38.7956C44.9283 39.2111 44.841 40.1535 44.7141 40.6096C44.6269 40.9231 44.3375 41.6281 44.0513 42.0509C44.0513 42.0509 42.9611 43.3526 42.8423 43.9378C42.7235 44.5241 42.7631 44.5283 42.7388 44.9427C42.7154 45.3582 42.8616 45.9039 42.8616 45.9039C42.8616 45.9039 42.0485 46.0122 41.6089 45.9393C41.212 45.8747 40.7207 45.0646 40.5938 44.8167C40.4192 44.4751 40.0467 44.5408 39.9015 44.7928C39.6742 45.1916 39.1818 45.907 38.8357 45.9518C38.1566 46.0393 36.7496 45.983 35.6482 45.9726C35.6482 45.9726 35.836 44.9198 35.4178 44.5585C35.1082 44.2877 34.5753 43.742 34.2565 43.4546L33.412 42.4955C33.1247 42.1206 32.3948 41.5281 32.1502 40.4284C31.934 39.4537 31.9553 38.9757 32.1878 38.5852C32.4233 38.1885 32.8679 37.9719 33.0547 37.9344C33.2658 37.8906 33.7571 37.8938 33.9429 37.9989C34.1692 38.127 34.2606 38.1645 34.4382 38.4061C34.6717 38.7258 34.755 38.881 34.6545 38.5321C34.5773 38.2593 34.3276 37.9125 34.2139 37.522C34.1033 37.1461 33.8068 36.54 33.8282 35.9329C33.8363 35.7028 33.9327 35.13 34.6727 34.8478Z"
            stroke="#101828"
            strokeWidth="0.75"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M38 39.5C38 39.2239 37.7761 39 37.5 39C37.2239 39 37 39.2239 37 39.5V42.5C37 42.7761 37.2239 43 37.5 43C37.7761 43 38 42.7761 38 42.5V39.5ZM39.5 39C39.7761 39 40 39.2239 40 39.5V42.5C40 42.7761 39.7761 43 39.5 43C39.2239 43 39 42.7761 39 42.5V39.5C39 39.2239 39.2239 39 39.5 39ZM41.5 39C41.7761 39 42 39.2239 42 39.5V42.5C42 42.7761 41.7761 43 41.5 43C41.2239 43 41 42.7761 41 42.5V39.5C41 39.2239 41.2239 39 41.5 39Z"
          fill="#101828"
        />
        <defs>
          <filter
            id="filter0_d_11643_3520"
            x="29.625"
            y="32.625"
            width="17.75"
            height="16.75"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="1" />
            <feGaussianBlur stdDeviation="1" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.027451 0 0 0 0 0.12549 0 0 0 0 0.239216 0 0 0 0.24 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_11643_3520"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_11643_3520"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    );
  },
);

export default dragDrop;
