import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import { BgImg, Mail01Icon, PhoneBGImg } from "assests";
import { Button } from "components/UI/Button";
import FormHeader from "components/UI/FormHeader";
import useRegistration from "hooks/useRegistration";
import { resendEmailValidationOTP, verifyEmailAuto } from "services/auth.services";
import { useMutation } from "@tanstack/react-query";
import useUser from "hooks/useUser";
import { clearAuthData } from "store/authenticationSlice";
import LoadingComponent from "components/UI/LoadingComponent";
import OTPInput from "otp-input-react";
import SigninLogo from "components/UI/SigninLogo";
import useCustomToaster from "hooks/useCustomToaster";
const VerifyOtp = () => {
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);
  const hash = urlParams.get("hash");
  const expires = urlParams.get("expires");
  const { token, user } = useSelector((state) => state.authenticationSlice);
  const [OTP, setOTP] = useState("");
  const dispatch = useDispatch();
  const { isEmailValidating, handleError, resendOtp, confirmEmailMutation } = useRegistration();
  const { userMutation } = useUser();
  const { isDarkMode } = useSelector((state) => state.themeSlice);
  const [timeLeft, setTimeLeft] = useState(60);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const { showCustomToast } = useCustomToaster();
  const handleResend = (e) => {
    e.preventDefault();
    resendOtp(e);
    setIsButtonDisabled(true);
    setTimeLeft(60);
    setOTP("")
  };

  useEffect(() => {
    if (timeLeft > 0) {
      const timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
      return () => clearInterval(timer);
    } else {
      setIsButtonDisabled(false);
    }
  }, [timeLeft]);

  const emailLinkVerifyMutation = useMutation({
    mutationKey: ["verify-otp"],
    mutationFn: (data) => verifyEmailAuto(data),
    onSuccess: (res) => {
      showCustomToast({
        title: "Email verified successfully",
        subtitle:"Your email address has been successfully verified. You can now log in and access all features of your account."
      });
      localStorage.setItem("userName", res?.data?.user?.name)
      if (!token || !localStorage.getItem("auth") || !user) {
        navigate("/verify-email-success", { replace: true })
      } else {
        userMutation.mutate();
        navigate("/", { replace: true })
      }
    },
    onError: (error) => {
      showCustomToast({
        title: "Link has been expired!",
      });
      navigate("/signin", { replace: true })
    }
  },

  );

  useEffect(() => {
    if (hash && expires) {
      emailLinkVerifyMutation.mutate({
        hash, expires
      })
    }
  }, [hash, expires])

  const handleLogOut = () => {
    dispatch(clearAuthData());
    dispatch({ type: "LOGOUT" });
    navigate("/signin", { replace: true })
  };


  const handleValidatOtp = () => {
    if (!OTP || OTP.length < 4) return;
    const payloadData = { token, body: OTP };
    confirmEmailMutation.mutate(payloadData, {
      onSuccess: (response) => {
        const data = response.data;
        if (data.status) {
          userMutation.mutate();
          console.log(data)
          showCustomToast({
            title: data.message,
          });
        }
      },
    });
  };

  const sendInitialOtp = useCallback(async () => {
    try {
      const res = await resendEmailValidationOTP({ token });
      if (res.status !== 200 || !res.data.status) {
        handleError(res);
        return;
      }
      showCustomToast({
        title: res.data.message,
      });

    } catch (error) {
      handleError(error);
    }
  }, []);

  useEffect(() => {
    if (token && user && !user.email_verified_at) {
      sendInitialOtp();
    }
  }, [token, user, sendInitialOtp]);

  if (hash && expires && emailLinkVerifyMutation.isPending) {
    return <LoadingComponent />;
  }

  if ((!token || !localStorage.getItem("auth") || !user) && emailLinkVerifyMutation.isSuccess) {
    return <Navigate to="/verify-email-success" replace={true} />;
  }

  if (token && user && user.email_verified_at) {
    return <Navigate to="/" replace={true} />;
  }

  return (
    <>
      <div className={`w-full h-full min-h-screen   ${isDarkMode ? "bg-global-surface" : "bg-global-theme"
        } overflow-y-auto `}>

        <div className="flex items-center justify-center w-full pt-[48px] pb-2 lg:py-[120px] relative ">
          <div className="z-[2] w-full flex flex-col items-center justify-center">
            <div
              className=" flex items-center justify-center w-full h-full "
              style={{ pointerEvents: "none" }}
            >
              <img src={BgImg} alt="" className="fixed top-0 hidden md:block z-[-1]" />
              <img src={PhoneBGImg} alt="" className="fixed top-0 block md:hidden z-[-1]" />
            </div>
            <div className="relative w-full px-4 md:p-0 md:w-[360px]">
              <div className="flex flex-col gap-8">
                <div className="flex flex-col gap-6">
                  <SigninLogo>
                    <Mail01Icon strokeColor="#344054" width={28} height={28} />
                  </SigninLogo>
                  <FormHeader
                    title="Check your email"
                    subtitle={`We sent a verification link to ${user?.email}`}
                  />
                </div>
                <div className="grid gap-8">
                  <div className="grid w-full gap-3">
                    <div className="flex flex-row  gap-2 lg:gap-3 justify-center items-center custom-otp-input">
                      <OTPInput
                        id="verificationCode"
                        value={OTP}
                        onChange={setOTP}
                        autoFocus
                        OTPLength={4}
                        otpType="number"
                        disabled={false}
                        data-testid="input"
                      />
                    </div>
                  </div>
                  <div className="flex justify-center">
                    <Button
                      size="lg"
                      className="flex items-center w-full gap-2  text-base font-semibold"
                      onClick={handleValidatOtp}
                      isDisabled={!OTP || OTP.length < 4 || OTP.length > 4 || isEmailValidating}
                    >
                      Verify email
                    </Button>
                  </div>
                </div>

                <div className="flex items-center justify-center">
                  <p className="relative pl-2 pr-1 text-sm font-normal  text-login-text-paragraph whitespace-nowrap">
                    Didn’t receive the email?
                  </p>
                  <Button
                    onClick={(e) => handleResend(e)}
                    isDisabled={isButtonDisabled}
                    className="p-0 m-0 pr-2  bg-transparent hover:bg-transparent disabled:bg-transparent text-sm font-semibold  text-btn-text-link hover:text-global-primary whitespace-nowrap "
                  >
                    {isButtonDisabled ? `Click to resend (${timeLeft}s)` : 'Click to resend'}
                  </Button>
                </div>

                <div className="flex items-center justify-center">
                  <p className="relative z-10 pl-2 pr-1 text-sm font-normal  text-login-text-paragraph whitespace-nowrap">
                    Incorrect Email? Please
                  </p>
                  <Button
                    onClick={handleLogOut}
                    className="p-0 m-0 pr-2 bg-transparent hover:bg-transparent text-sm font-semibold  text-btn-text-link hover:text-global-primary whitespace-nowrap "
                  >
                    Logout
                  </Button>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div >
    </>
  );
};

export default VerifyOtp;
