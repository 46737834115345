import React from "react";

const LockSuccess = React.forwardRef(
  (
    {
      width = "38",
      height = "58",
      viewBox = "0 0 38 58",
      stroke = "none",
      strokeColor = "#11C182",

      className,

      ...props
    },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, stroke, className }}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19 57C28.665 57 36.5 49.165 36.5 39.5C36.5 29.835 28.665 22 19 22C9.33502 22 1.5 29.835 1.5 39.5C1.5 49.165 9.33502 57 19 57Z"
          stroke={strokeColor}
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M24.25 39.5C24.25 42.3973 21.8987 44.7493 19 44.7493C16.1006 44.7493 13.75 42.3973 13.75 39.5C13.75 36.5985 16.1013 34.25 19 34.25C21.8987 34.25 24.25 36.5985 24.25 39.5Z"
          stroke={strokeColor}
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.8743 23.1228V11.5C12.8743 8.1162 15.6176 5.3743 19 5.3743C22.3831 5.3743 25.1236 8.1162 25.1236 11.5V23.1228C26.7049 23.715 28.1735 24.5249 29.5007 25.5238V11.5C29.5007 5.6998 24.7995 1 19 1C13.2005 1 8.5 5.7005 8.5 11.5V25.5238C9.8244 24.5249 11.2937 23.715 12.8743 23.1228Z"
          stroke={strokeColor}
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  },
);

export default LockSuccess;
