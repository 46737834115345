import { Mail01Icon } from "assests";
import React from "react";
import StripeUpgradeForm from "./StripeUpgradeForm";
const RightForm = ({
  onShowCompanyDetails,
  control,
  register,
  setValue,
  setError,
  handleSubmit,
  handleChangeCardItem,
  errors,
  isLoading,
  selectedPlanData,
  downgradeOrUpgrade,
  className,
  errorMessage,
  isPending
}) => {
  return (
    <div className={`col-span-12 lg:col-span-6 inline-flex relative  justify-center items-center min-h-screen h-full w-full py-4 lg:py-[171px] bg-global-white ${className}`}>
      <StripeUpgradeForm
        {...{
          control,
          register,
          errors,
          handleChangeCardItem,
          onShowCompanyDetails,
          handleSubmit,
          isLoading,
          selectedPlanData,
          downgradeOrUpgrade,
          errorMessage,
          isPending
        }}
      />
      <div className="absolute bottom-4 right-8">
        <a href="mailto:hello@fibervue.com">
          <div className="flex items-center gap-2">
            <Mail01Icon width={16} height={16} />{" "}
            <p className="text-sm font-normal text-btn-text-tertiary">hello@fibervue.com</p>
          </div>
        </a>
      </div>
    </div>
  );
};

export default RightForm;
