import React from "react";

const Arrow_up_right = React.forwardRef(
    (
        {
            width = "20",
            height = "20",
            viewBox = "0 0 20 20",
            fill = "none",
            fillColor = "#11C182",
            className,
            ...props
        },
        ref,
    ) => {
        return (
            <svg
                ref={ref}
                {...{ width, height, viewBox, fill, className }}
                {...props}
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M5.83334 14.1668L14.1667 5.8335M14.1667 5.8335H5.83334M14.1667 5.8335V14.1668"
                    stroke={fillColor}
                    strokeWidth="1.66667"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        );
    },
);

Arrow_up_right.displayName = "Arrow_up_right";

export default Arrow_up_right;
