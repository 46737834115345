const { createSlice } = require("@reduxjs/toolkit");

const retailerSlice = createSlice({
  name: "retailer",
  initialState: {
    currentStep: 1,
    validatedSteps: [],
    retailerList: [],
    retailer: null,
    actionableRetailerId: null,
    actionableRetailerWorkspaceId: null,
    compositionFormLength: 1,
    allRetailerData: null,
    showDiscardModal: false,
    deleteModal: false,
    CancelButton: false,
    cancleModal: false,
    resendModal: false,
    bulkDeleteModal: false,
    reachedModal: false,
    actionableRetailerItem: null
  },
  reducers: {
    toggleIsRetailerCreateModalOpen: (state) => ({
      ...state,
      isRetailerCreateModalOpen: !state.isRetailerCreateModalOpen,
    }),
    toggleActionableRetailerId: (state, action) => ({
      ...state,
      actionableRetailerId: action.payload ?? null,
    }),
    toggleActionableRetailerItem: (state, action) => ({
      ...state,
      actionableRetailerItem: action.payload ?? null,
    }),
    toggleActionableRetailerWorkspaceId: (state, action) => ({
      ...state,
      actionableRetailerWorkspaceId: action.payload ?? null,
    }),
    toggleShowDiscardModal: (state) => ({ ...state, showDiscardModal: !state.showDiscardModal }),
    toggleRetailerDeleteModal: (state) => {
      state.deleteModal = !state.deleteModal;
    },
    toggleRetailerBulkDeleteModal: (state) => {
      state.bulkDeleteModal = !state.bulkDeleteModal;
    },
    toggleRetailerCancleModal: (state) => {
      state.cancleModal = !state.cancleModal;
    },
    toggleRetailerResendModal: (state) => {
      state.resendModal = !state.resendModal;
    },
    toggleReachedModal: (state) => {
      state.reachedModal = !state.reachedModal;
    },
    setCurrentStep: (state, action) => ({
      ...state,
      currentStep: action.payload,
    }),
    setRetailerList: (state, action) => {
      const { append, retailerList, retailer } = action.payload;
      return {
        ...state,
        retailerList: append ? [...state.retailerList, retailer] : retailerList,
      };
    },
    setRetailer: (state, action) => ({
      ...state,
      retailer: action.payload ?? null,
    }),
    setAllRetailerData: (state, action) => ({
      ...state,
      allRetailerData: action.payload.allRetailerData ?? null,
    }),
    setActionableRetailerId: (state, action) => ({
      ...state,
      actionableRetailerId: action.payload ?? null,
    }),
    setValidatedSteps: (state, action) => {
      if (action.payload) {
        const newValidatedSteps = new Set([...state.validatedSteps, action.payload]);
        return {
          ...state,
          validatedSteps: Array.from(newValidatedSteps),
        };
      } else {
        return { ...state, validatedSteps: [] };
      }
    },
    setCompositionFormLength: (state, action) => ({
      ...state,
      compositionFormLength: action.payload,
    }),
  },
});

export const {
  setCurrentStep,
  setRetailerList,
  setRetailer,
  setActionableRetailerId,
  setValidatedSteps,
  setCompositionFormLength,
  setAllRetailerData,
  toggleIsRetailerCreateModalOpen,
  toggleActionableRetailerId,
  toggleShowDiscardModal,
  toggleRetailerDeleteModal,
  toggleRetailerCancleModal,
  toggleRetailerResendModal,
  toggleActionableRetailerWorkspaceId,
  toggleRetailerBulkDeleteModal,
  toggleReachedModal,
  toggleActionableRetailerItem
} = retailerSlice.actions;

export default retailerSlice.reducer;
