import { AddMember, Cross, EdituserIcon } from "assests";
import UpgradeComponent from "components/UI/UpgradeComponent";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { EditUserStepList } from "utils/constant";
import FirstStepEdit from "./Steps/FirstStepEdit";
import ThirdStep from "../Steps/ThirdStep";

const EditUserForm = ({
  closeModal,
  control,
  actionableUserId,
  userDetails,
  errors,
  getValues,
  register,
  handleSubmit,
  setValue,
  editNextStep,
  clearErrors,
  userPermissionType
}) => {
  const { activeStep } = useSelector((state) => state.createAccountSlice);
  const [activeRole, setActiveRole] = useState(null);
  useEffect(() => {
    if (userDetails && !userPermissionType) {
      const role = userDetails.role.split(".")[1];
      setActiveRole(role);
      setValue("type", role);
    }
    if (userPermissionType) {
      setActiveRole(userPermissionType);
      setValue("type", userPermissionType);
    }
  }, [userDetails]);
  return (
    <div className="relative flex items-center justify-center">
      <div className="w-full lg:w-[400px] z-[2] relative p-4 lg:p-6 rounded-xl bg-white">
        <div className="flex  justify-between">
          <div>
            <UpgradeComponent>
              <EdituserIcon />
            </UpgradeComponent>
          </div>
          <div className="abosolute top-[26px] right-[26px]">
            <Cross
              fillColor={"var(--color-global-paragraph-2)"}
              height={24}
              width={24}
              className={"w-6 h-6 cursor-pointer"}
              onClick={closeModal}
            />
          </div>
        </div>
        <div className="flex flex-col justify-between w-full h-full gap-12 ">
          <div className="grid gap-10">
            {activeStep === 1 && (
              <FirstStepEdit
                {...{
                  control,
                  register,
                  editNextStep,
                  setValue,
                  errors,
                  closeModal,
                  getValues,
                  activeRole,
                  setActiveRole,
                  actionableUserId,
                  userDetails
                }}
              />
            )}
            {activeStep === 2 && (
              <ThirdStep
                {...{
                  closeModal,
                  setValue,
                  handleSubmit,
                  errors,
                  clearErrors,
                  actionableUserId
                }}
              />
            )}
          </div>
        </div>
        <div className="relative flex items-center  h-[10.5px] justify-center">
          <div className="absolute bottom-[134px] lg:bottom-[90px]  ">
            <div className="flex space-x-4">
              {EditUserStepList?.slice(0, getValues("type") === "admin" ? 1 : 2)?.map(
                (_, index) => (
                  <div
                    key={index}
                    className={`w-[10px] h-[10px] flex rounded-full cursor-pointer ${activeStep === index + 1
                      ? "bg-global-primary"
                      : activeStep > index + 1
                        ? "bg-global-gray-soft"
                        : "bg-global-gray-soft"
                      }`}
                  ></div>
                ),
              )}
            </div>
          </div>
        </div>
      </div>



    </div>
  );
};

export default EditUserForm;
