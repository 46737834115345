import { Autocomplete, LoadScript } from "@react-google-maps/api";
import { CheckUpgradeIcon, Cross } from "assests";
import { Button } from "components/UI/Button";
import CountrySelect from "components/UI/CountrySelect";
import ErrorMessage from "components/UI/ErrorMessage";
import { Input } from "components/UI/Input";
import { Label } from "components/UI/Label";
import UpgradeComponent from "components/UI/UpgradeComponent";
import { useGoogleMaps } from "contexts/GoogleMapsContext";
import { Country } from "country-state-city";
import useBillingDetails from "hooks/useBillingDetails";
import { useEffect, useState } from "react";
const CompanyDetailsUpgrade = ({ handleShowCompanyDetails, fromBilling }) => {
  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    setSelectedCountry,
    control,
    errors,
    isEditingBillingDetails,
    billingDetails,
    selectedCountry,
    street,
    setStreet,
    watch,
  } = useBillingDetails();

  const { isLoaded } = useGoogleMaps();

  const [autocomplete, setAutocomplete] = useState(null);

  useEffect(() => {
    if (isEditingBillingDetails && billingDetails) {
      let country = Country.getCountryByCode(billingDetails.country);
      if (!country) {
        country = Country.getAllCountries().find(
          (c) => c.name.toLowerCase() === billingDetails?.country?.toLowerCase(),
        );
      }
      if (country) {
        setSelectedCountry({
          value: country.name,
          label: country.name,
          flag: country.flag,
          isoCode: country.isoCode,
        });
        setValue("country", country.name);
        setValue("country_code", country.isoCode);
      }
      setValue("company_name", billingDetails.company_name);
      setStreet(billingDetails.street);
      setValue("street", billingDetails.street);
      setValue("zip", billingDetails.zip);
      setValue("region", billingDetails.region);
      setValue("email", billingDetails.email);
      setValue("vat", billingDetails.vat);
      setValue("workspace_id", billingDetails.workspace_id);
    }
  }, [isEditingBillingDetails, billingDetails]);

  const onLoad = (autocomplete) => {
    setAutocomplete(autocomplete);
  };

  const onPlaceChanged = () => {
    if (autocomplete) {
      const place = autocomplete.getPlace();
      if (place.address_components) {
        let street = "";
        let zip = "";
        let region = "";
        let country = "";
        let countryCode = "";
        place.address_components.forEach((component) => {
          const types = component.types;
          if (types.includes("street_number")) {
            street = component.long_name + " ";
          }
          if (types.includes("route")) {
            street += component.long_name;
          }
          if (types.includes("postal_code")) {
            zip = component.long_name;
          }
          if (types.includes("locality")) {
            region = component.long_name;
          }
          if (types.includes("country")) {
            country = component.long_name;
            countryCode = component.short_name;
          }
        });
        let countryData = Country.getCountryByCode(country);
        if (!countryData) {
          countryData = Country.getAllCountries().find(
            (c) => c.name.toLowerCase() === country?.toLowerCase(),
          );
        }
        if (countryData) {
          setValue("country", countryData.name);
          setValue("country_code", countryData.isoCode);
          setSelectedCountry({
            value: countryData.name,
            label: countryData.name,
            flag: countryData.flag,
            isoCode: countryData.isoCode,
          });
        }
        setValue("street", street);
        setValue("zip", zip);
        setValue("region", region);
        setValue("country", country);
        setValue("country_code", countryCode);
        setStreet(street);
      }
    }
  };

  useEffect(() => {
    if (selectedCountry) {
      clearErrors("country");
      clearErrors("country_code");
      setValue("country", selectedCountry.value);
      setValue("country_code", selectedCountry.isoCode);
    }
  }, [selectedCountry]);

  return (
    <div
      className={`w-full  p-6 relative z-[2]  bg-global-theme border border-global-divider-soft rounded-xl   ${fromBilling ? "max-w-[640px]" : "max-w-[480px]"}`}
    >
      <UpgradeComponent>
        <CheckUpgradeIcon />
      </UpgradeComponent>
      <div
        className="absolute right-[26px] top-[26px] cursor-pointer "
        onClick={handleShowCompanyDetails}
      >
        <Cross fillColor={"var(--color-icon-fill-default)"} height={24} width={24} />
      </div>

      <form onSubmit={handleSubmit}>
        <div className="grid grid-cols-1 gap-4 lg:gap-5 ">
          <div className="grid gap-1">
            <p className="text-global-title font-semibold text-base lg:text-lg pt-4">
              Billing details
            </p>
            <p className="text-global-paragraph text-sm">
              This information will be displayed on your invoice and used to calculate taxes.
            </p>
          </div>
          <div className="grid grid-cols-12 gap-4">
            <div className="col-span-12 ">
              <div className="grid gap-1.5">
                <Label className="text-sm font-medium text-input-text-label">Company Name</Label>
                <Input
                  id="company_name"
                  type="text"
                  size="lg"
                  placeholder="Enter company name"
                  {...register("company_name", {
                    setValueAs: (value) => value.trim(),
                  })}
                />
                <ErrorMessage errors={errors?.company_name?.message} />
              </div>
            </div>
            <div className="col-span-12 ">
              <div className="grid gap-1.5">
                <Label className="text-sm font-medium text-input-text-label">Street</Label>
                <div className="relative">
                  {isLoaded && (
                    <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
                      <Input
                        id="street"
                        type="text"
                        placeholder="Enter street"
                        {...register("street")}
                        onChange={(e) => {
                          console.log(e);
                        }}
                      />
                    </Autocomplete>
                  )}
                </div>
                <ErrorMessage errors={errors?.street?.message} />
              </div>
            </div>

            <div className="col-span-12 lg:col-span-6">
              <div className="grid gap-1.5">
                <Label className="text-sm font-medium text-input-text-label">ZIP</Label>
                <Input
                  id="zip"
                  type="text"
                  size="lg"
                  placeholder="Enter postalcode"
                  {...register("zip")}
                />
                <ErrorMessage errors={errors?.zip?.message} />
              </div>
            </div>
            <div className="col-span-12 lg:col-span-6">
              <div className="grid gap-1.5 ">
                <Label className="text-sm font-medium text-input-text-label">City</Label>
                <Input
                  id="region"
                  type="text"
                  size="lg"
                  placeholder="Enter city"
                  {...register("region")}
                />
                <ErrorMessage errors={errors?.region?.message} />
              </div>
            </div>

            <div className="col-span-12  w-full">
              <div className="grid gap-1.5 w-full">
                <Label className="text-sm font-medium text-input-text-label">Country</Label>
                <CountrySelect control={control} {...{ selectedCountry, setSelectedCountry }} />
                <ErrorMessage errors={errors?.country?.message} />
              </div>
            </div>
            <div className="col-span-12 ">
              <div className="grid gap-1.5">
                <Label className="text-sm font-medium text-input-text-label">Email</Label>
                <Input
                  id="email"
                  type="text"
                  size="lg"
                  placeholder="Enter billing email address"
                  {...register("email")}
                />
                <div>
                  {errors?.email ? (
                    <ErrorMessage errors={errors?.email?.message} />
                  ) : (
                    <p className="text-sm text-input-text-support">
                      Invoices will be sent to this email address.
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className="col-span-12 ">
              <div className="grid gap-1.5">
                <Label className="text-sm font-medium text-input-text-label">
                  VAT / Tax number
                </Label>
                <div className="flex w-full h-[44px] gap-1.5 flex-start phone-input-field border border-input-stroke-default hover:border-gray-800   hover:transition hover:duration-300 hover:ease-in-out ">
                  <span className="inline-flex items-center pl-3.5 pr-3 border-r border-r-input-stroke-default rounded-e-0">
                    {selectedCountry ? selectedCountry?.isoCode : "N/A"}
                  </span>
                  <Input
                    type="text"
                    size="lg"
                    className="rounded-none rounded-e-lg w-full h-full placeholder:text-input-text-placeholder placeholder:font-normal placeholder:text-base  self-stretch"
                    placeholder="865112084B01"
                    {...register("vat", {
                      setValueAs: (value) => value.trim(),
                    })}
                  />
                </div>
                <ErrorMessage errors={errors?.vat?.message} />
              </div>
            </div>
          </div>

          <div className="flex flex-col lg:flex-row gap-3 pt-3">
            <Button
              variant={"secondary"}
              className="px-4 py-2.5 w-full  h-11 text-[10px] text-base font-semibold rounded-lg"
              onClick={handleShowCompanyDetails}
            >
              Cancel
            </Button>

            <Button
              type="submit"
              variant={"primary"}
              className="px-4 py-2.5 w-full  h-11 text-[10px] text-base font-semibold rounded-lg"
            >
              {isEditingBillingDetails ? "Update Company" : "Add Company"}
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default CompanyDetailsUpgrade;
