import React from "react";

const compliancy = React.forwardRef(
  (
    {
      width = "20",
      height = "20",
      viewBox = "0 0 20 20",
      fill = "none",
      strokeColor = "white",
      fillColor = "white",
      className,
      ...props
    },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_1001_227)">
          <path
            d="M7.5 10L9.16667 11.6667L12.9167 7.91667M14.9177 4.16542C15.0893 4.58044 15.4186 4.91033 15.8334 5.08256L17.2877 5.68498C17.7027 5.8569 18.0325 6.18666 18.2044 6.60171C18.3763 7.01677 18.3763 7.48312 18.2044 7.89817L17.6024 9.3515C17.4304 9.76674 17.4302 10.2336 17.603 10.6486L18.2039 12.1015C18.2891 12.3071 18.333 12.5274 18.333 12.75C18.3331 12.9725 18.2893 13.1929 18.2041 13.3985C18.119 13.6041 17.9941 13.7909 17.8367 13.9482C17.6794 14.1055 17.4925 14.2303 17.2869 14.3154L15.8336 14.9174C15.4186 15.089 15.0887 15.4184 14.9165 15.8331L14.3141 17.2875C14.1422 17.7026 13.8125 18.0323 13.3974 18.2043C12.9824 18.3762 12.5161 18.3762 12.101 18.2043L10.6477 17.6023C10.2327 17.4308 9.76653 17.4311 9.35173 17.6032L7.89742 18.2048C7.48261 18.3763 7.01668 18.3762 6.60198 18.2044C6.18728 18.0326 5.85772 17.7032 5.68568 17.2886L5.0831 15.8338C4.91151 15.4188 4.58213 15.0889 4.16738 14.9167L2.71306 14.3143C2.2982 14.1424 1.96855 13.8129 1.79657 13.3981C1.6246 12.9832 1.62436 12.5171 1.79593 12.1021L2.39789 10.6488C2.56938 10.2337 2.56903 9.76754 2.39692 9.35273L1.79582 7.89732C1.71059 7.69175 1.66671 7.4714 1.66667 7.24886C1.66664 7.02632 1.71045 6.80596 1.79561 6.60036C1.88077 6.39476 2.0056 6.20796 2.16298 6.05063C2.32036 5.89329 2.50719 5.76851 2.71281 5.68342L4.16609 5.08143C4.58073 4.90998 4.91041 4.581 5.08276 4.16671L5.68516 2.71234C5.85707 2.29729 6.18682 1.96753 6.60186 1.79561C7.0169 1.62369 7.48323 1.62369 7.89827 1.79561L9.35154 2.3976C9.76659 2.56909 10.2328 2.56874 10.6476 2.39662L12.1025 1.79654C12.5175 1.62472 12.9837 1.62475 13.3987 1.79664C13.8136 1.96853 14.1433 2.29819 14.3153 2.71314L14.9178 4.16794L14.9177 4.16542Z"
            stroke={strokeColor}
            strokeWidth="1.39167"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_1001_227">
            <rect width="20" height="20" fill={fillColor} />
          </clipPath>
        </defs>
      </svg>
    );
  },
);

compliancy.displayName = "compliancy";

export default compliancy;
