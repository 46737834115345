import { ArrowDownUpdatedIcon } from "assests";


const SortIcons = ({ sortName, orderBy, setOrderBy, hasData }) => {

  function toggleSortOrder(sortName) {
    if (!orderBy.includes("-")) {
      setOrderBy(`-${sortName}`)
    } else {
      setOrderBy(sortName)
    }
  }
  return (
    <ArrowDownUpdatedIcon onClick={() => hasData && toggleSortOrder(sortName)} className={`${hasData ? "cursor-pointer" : "cursor-default"} ${orderBy && sortName === orderBy ? "rotate-180" : "rotate-0"}`}
      color="var(--color-global-gray)" width={16} height={16}
    />

  );
};

export default SortIcons;
