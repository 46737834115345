import React from "react";

const SuccessIcon = React.forwardRef(
  (
    { width = "64", height = "64", viewBox = "0 0 64 64", fill = "none", className, ...props },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_ddddii_10427_34832)">
          <path
            d="M4 15C4 8.37258 9.37258 3 16 3H48C54.6274 3 60 8.37258 60 15V47C60 53.6274 54.6274 59 48 59H16C9.37258 59 4 53.6274 4 47V15Z"
            fill="white"
          />
          <path
            d="M4.5 15C4.5 8.64873 9.64873 3.5 16 3.5H48C54.3513 3.5 59.5 8.64873 59.5 15V47C59.5 53.3513 54.3513 58.5 48 58.5H16C9.64873 58.5 4.5 53.3513 4.5 47V15Z"
            stroke="#E4E7EC"
          />
          <path
            d="M26.7502 31L30.2502 34.5L37.2502 27.5M43.6668 31C43.6668 37.4433 38.4435 42.6666 32.0002 42.6666C25.5568 42.6666 20.3335 37.4433 20.3335 31C20.3335 24.5567 25.5568 19.3333 32.0002 19.3333C38.4435 19.3333 43.6668 24.5567 43.6668 31Z"
            stroke="#344054"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <filter
            id="filter0_ddddii_10427_34832"
            x="0.25"
            y="0.5"
            width="63.5"
            height="63.5"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="1" />
            <feGaussianBlur stdDeviation="1" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.05 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_10427_34832"
            />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="1.25" />
            <feGaussianBlur stdDeviation="1.25" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.06 0"
            />
            <feBlend
              mode="normal"
              in2="effect1_dropShadow_10427_34832"
              result="effect2_dropShadow_10427_34832"
            />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="1.25" />
            <feGaussianBlur stdDeviation="1.875" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.1 0"
            />
            <feBlend
              mode="normal"
              in2="effect2_dropShadow_10427_34832"
              result="effect3_dropShadow_10427_34832"
            />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="1" />
            <feGaussianBlur stdDeviation="1" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.05 0"
            />
            <feBlend
              mode="normal"
              in2="effect3_dropShadow_10427_34832"
              result="effect4_dropShadow_10427_34832"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect4_dropShadow_10427_34832"
              result="shape"
            />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="-2" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.05 0"
            />
            <feBlend mode="normal" in2="shape" result="effect5_innerShadow_10427_34832" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feMorphology
              radius="1"
              operator="erode"
              in="SourceAlpha"
              result="effect6_innerShadow_10427_34832"
            />
            <feOffset />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.18 0"
            />
            <feBlend
              mode="normal"
              in2="effect5_innerShadow_10427_34832"
              result="effect6_innerShadow_10427_34832"
            />
          </filter>
        </defs>
      </svg>
    );
  },
);
SuccessIcon.displayName = "SuccessIcon";
export default SuccessIcon;
