import { CompliancyIcon, PartnersIcon, UpperCarretIcon } from 'assests';
import { Button } from 'components/UI/Button';
import TabItem from 'components/UI/TabItem';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

const CollapsMenu = ({ itemKey, menuItems, pathname, handleOpen, isOpen, closeSidebarSmallScreen }) => {
    const { isSidebarCollapsed } = useSelector((state) => state.navbarSlice);
    return (
        <div >
            <Button
                onClick={() => handleOpen(itemKey)} // Pass the key to handleOpen
                variant={"link"}
                className={`flex py-2 px-3 items-center w-full rounded-md text-base font-medium gap-3 capitalize text-white hover:place-items-center hover:bg-menu-item-surface-active  hover:text-white ${isSidebarCollapsed ? "justify-center" : "justify-between"}`}
            >
                <div className='flex gap-3 justify-start items-center'>
                    {itemKey === "partners" ? <PartnersIcon height={20} width={20} /> : <CompliancyIcon height={20} width={20} />} {!isSidebarCollapsed && itemKey}
                </div>
                {!isSidebarCollapsed &&
                    <div className={`flex justify-center items-center ${isOpen ? "rotate-0" : "rotate-180"}`}>
                        <UpperCarretIcon
                            fillColor={"#A4A7AE"}
                            height={16}
                            width={16}
                        />
                    </div>
                }
            </Button>
            {isOpen && (
                <div className={`flex flex-col gap-1 pt-1 ${isSidebarCollapsed ? "" : "pl-8"}`}>
                    {menuItems?.map((item, itemIndex) => (
                        <TabItem
                            item={item}
                            key={itemIndex}
                            pathname={pathname}
                            closeSidebarSmallScreen={closeSidebarSmallScreen}
                        />
                    ))}
                </div>
            )}
        </div>
    );
};
export default CollapsMenu;
