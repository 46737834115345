import api from "config/axios";

export const getGlobalSearchData = async (query) => {
  const params = new URLSearchParams(query);
  try {
    const response = await api.get(`/global-search?${params}`);
    return response;
  } catch (error) {
    throw error;
  }
};



export const requestForPermission = async () => {
  try {
    const response = await api.post(`/users/request`);
    return response;
  } catch (error) {
    throw error;
  }
}
