import React from "react";

const EmailVerificationIcon = React.forwardRef(
  (
    { width = "64", height = "64", viewBox = "0 0 64 64", fill = "none", className, ...props },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_ddddii_10426_34590)">
          <path
            d="M4 15C4 8.37258 9.37258 3 16 3H48C54.6274 3 60 8.37258 60 15V47C60 53.6274 54.6274 59 48 59H16C9.37258 59 4 53.6274 4 47V15Z"
            fill="white"
          />
          <path
            d="M4.5 15C4.5 8.64873 9.64873 3.5 16 3.5H48C54.3513 3.5 59.5 8.64873 59.5 15V47C59.5 53.3513 54.3513 58.5 48 58.5H16C9.64873 58.5 4.5 53.3513 4.5 47V15Z"
            stroke="#E4E7EC"
          />
          <path
            d="M20.3333 25.1667L29.859 31.8347C30.6304 32.3746 31.016 32.6446 31.4356 32.7492C31.8061 32.8416 32.1937 32.8416 32.5643 32.7492C32.9838 32.6446 33.3695 32.3746 34.1408 31.8347L43.6666 25.1667M25.9333 40.3333H38.0666C40.0268 40.3333 41.0069 40.3333 41.7556 39.9519C42.4141 39.6163 42.9496 39.0809 43.2851 38.4223C43.6666 37.6736 43.6666 36.6935 43.6666 34.7333V27.2667C43.6666 25.3065 43.6666 24.3264 43.2851 23.5777C42.9496 22.9191 42.4141 22.3837 41.7556 22.0481C41.0069 21.6667 40.0268 21.6667 38.0666 21.6667H25.9333C23.9731 21.6667 22.993 21.6667 22.2443 22.0481C21.5857 22.3837 21.0503 22.9191 20.7147 23.5777C20.3333 24.3264 20.3333 25.3065 20.3333 27.2667V34.7333C20.3333 36.6935 20.3333 37.6736 20.7147 38.4223C21.0503 39.0809 21.5857 39.6163 22.2443 39.9519C22.993 40.3333 23.9731 40.3333 25.9333 40.3333Z"
            stroke="#344054"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <filter
            id="filter0_ddddii_10426_34590"
            x="0.25"
            y="0.5"
            width="63.5"
            height="63.5"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="1" />
            <feGaussianBlur stdDeviation="1" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.05 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_10426_34590"
            />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="1.25" />
            <feGaussianBlur stdDeviation="1.25" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.06 0"
            />
            <feBlend
              mode="normal"
              in2="effect1_dropShadow_10426_34590"
              result="effect2_dropShadow_10426_34590"
            />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="1.25" />
            <feGaussianBlur stdDeviation="1.875" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.1 0"
            />
            <feBlend
              mode="normal"
              in2="effect2_dropShadow_10426_34590"
              result="effect3_dropShadow_10426_34590"
            />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="1" />
            <feGaussianBlur stdDeviation="1" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.05 0"
            />
            <feBlend
              mode="normal"
              in2="effect3_dropShadow_10426_34590"
              result="effect4_dropShadow_10426_34590"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect4_dropShadow_10426_34590"
              result="shape"
            />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="-2" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.05 0"
            />
            <feBlend mode="normal" in2="shape" result="effect5_innerShadow_10426_34590" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feMorphology
              radius="1"
              operator="erode"
              in="SourceAlpha"
              result="effect6_innerShadow_10426_34590"
            />
            <feOffset />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.18 0"
            />
            <feBlend
              mode="normal"
              in2="effect5_innerShadow_10426_34590"
              result="effect6_innerShadow_10426_34590"
            />
          </filter>
        </defs>
      </svg>
    );
  },
);

export default EmailVerificationIcon;
