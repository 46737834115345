import { Button } from "components/UI/Button";

const MoreActionOptionUpdated = ({
  openButton,
  transferButton,
  viewButton,
  editButton,
  duplicateButton,
  deleteButton,
  cancelButton,
  resendButton,
  createButton,
  uploadButton,
  deleteWorkspace,
  archiveButton,
  linkSupplierButton,
}) => {
  const hasButtons =
    openButton ||
    viewButton ||
    editButton ||
    duplicateButton ||
    deleteButton ||
    transferButton ||
    cancelButton ||
    resendButton ||
    createButton ||
    uploadButton ||
    deleteWorkspace ||
    archiveButton ||
    linkSupplierButton;
  return (
    <>
      {hasButtons ? (
        <div className="flex flex-col items-center justify-start py-1 px-1.5 min-w-[151px]  rounded-lg shadow-3xl bg-global-theme">
          <div className="grid gap-y-1 w-full rounded-lg ">
            {linkSupplierButton && <div>{linkSupplierButton}</div>}
            {openButton && <div>{openButton}</div>}
            {viewButton && <div>{viewButton}</div>}
            {editButton && <div>{editButton}</div>}
            {duplicateButton && <div>{duplicateButton}</div>}
            {archiveButton && <div>{archiveButton}</div>}
            {deleteButton && <div>{deleteButton}</div>}
            {cancelButton && <div>{cancelButton}</div>}
            {resendButton && <div>{resendButton}</div>}
            {transferButton && <div>{transferButton}</div>}
            {createButton && <div>{createButton}</div>}
            {uploadButton && <div>{uploadButton}</div>}
            {deleteWorkspace && <div>{deleteWorkspace}</div>}
          </div>
        </div>
      ) : (
        <div className="flex flex-col items-center justify-start py-1 px-1.5 min-w-[151px] border border-global-divider rounded-lg shadow-3xl bg-global-theme">
          <div className="grid gap-2 w-full">
            <Button
              type="button"
              variant={"action"}
              className="flex items-center justify-start w-full gap-2 px-2.5 py-[9px] rounded  hover:bg-transparent"
              onClick={(e) => e.stopPropagation()}
            >
              <span>There is no action yet on your part</span>
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default MoreActionOptionUpdated;
