import api from "config/axios";

export const getArticles = (query) => {
  const params = new URLSearchParams();
  Object.keys(query).forEach((key) => {
    const value = query[key];
    if (Array.isArray(value)) {
      value.forEach((item) => {
        params.append(`${key}[]`, item);
      });
    } else if (value) {
      params.append(key, value);
    }
  });
  return api.get(`/articles?${params}`);
};

export const getAllArticles = (query) => {
  const params = new URLSearchParams();
  Object.keys(query).forEach((key) => {
    if (query[key]) {
      params.append(key, query[key]);
    }
  });
  return api.get(`/all-articles?${params}`);
};

export const createArticle = (data) =>
  api.post("/articles", data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

export const getArticleDetailsById = (id) => api.get(`/articles/${id}`);

// export const updateArticleDetailsById = (data) => api.put(`/articles/${data.id}`, data.body);
export const updateArticleDetailsById = (data) =>
  api.post(`/articles/${data.id}`, data.body, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

export const deleteArticle = async (data) => {
  try {
    const response = await api.delete(
      `/articles/${data.id}?main_workspace_id=${data.main_workspace_id}`,
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const bulkDeleteArticles = async (data) => {
  try {
    const response = await api.post(`/article/bulk-delete`, data);
    return response;
  } catch (error) {
    throw error;
  }
};

export const downloadArticlesAsCSV = async (data) => {
  try {
    const response = await api.post(`/article/download-csv`, data);
    return response;
  } catch (error) {
    throw error;
  }
};

export const importArticleCSV = (data) =>
  api.post(`/articles/import-csv`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

export const createBulkArticle = (data) => {
  return api.post("/article/import-csv", data, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};
