import { AddMember, Cross } from 'assests';
import { Button } from 'components/UI/Button';
import { LineProgressBar } from 'components/UI/LineProgressBar';
import UpgradeComponent from 'components/UI/UpgradeComponent';
import React from 'react'
import { useSelector } from 'react-redux';
import { calculatePercentage } from 'utils/helper';

const LimitMembershipCard = ({
  title,
  subText1,
  subText2,
  IconComponent,
  confirmButtonText,
  onConfirmClick,
  closeModal,
  confirmButtonClassName,
  className,
  confirmButtonVariant,
  boldText,
  inviteBtnTrue,
  inviterInfo, }) => {
  const { currentPlan } = useSelector((state) => state.billingSlice);
  return (
    <div className={` bg-white p-6 rounded-xl relative z-[2] ${className}`}>
      <div className="absolute right-[22px] top-[22px] cursor-pointer  " onClick={closeModal}>
        <Cross fillColor={"var(--color-icon-fill-default)"} height={24} width={24} />
      </div>
      <div className={`flex  flex-row`}>
        <div className="mr-4">
          <UpgradeComponent>
            <AddMember />
          </UpgradeComponent>
        </div>

        <div className={`flex flex-col gap-1 `}>
          <h4 className="text-base lg:text-lg font-semibold text-text-primary">
            {title}
          </h4>
          <p className="text-xs lg:text-sm  text-global-paragraph tracking-tight">
            {subText1}
          </p>
        </div>
      </div>
      <div className="grid gap-2 pt-6">
        <LineProgressBar
          className="h-[8px]"
          variant="red"
          progress={
            currentPlan?.user_count && currentPlan?.plan?.package?.users
              ? calculatePercentage(
                currentPlan?.user_count,
                currentPlan?.plan?.package?.users,
              )
              : 0
          }
        />
        <div className="flex justify-end text-sm text-global-title-2 font-medium">
          {currentPlan?.user_count ? currentPlan?.user_count : 0}/
          {currentPlan?.plan?.package?.users ? currentPlan?.plan?.package?.users : 0} members
        </div>
      </div>
      <div className="flex justify-center lg:justify-end flex-col lg:flex-row gap-3 pt-8">
        {closeModal &&
          <Button
            variant="secondary"
            className="px-4 py-2.5 w-full lg:w-[90px] h-11 text-sm md:text-base font-semibold"
            onClick={closeModal}
          >
            Cancel
          </Button>
        }
        <Button
          variant={confirmButtonVariant}
          className={`px-4 py-2.5 w-full lg:w-[186px] h-11 custom-primary-shadow text-white text-sm md:text-base font-semibold whitespace-nowrap ${confirmButtonClassName}`}
          onClick={onConfirmClick}
        >
          {confirmButtonText}
        </Button>
      </div>
    </div>
  );
}

export default LimitMembershipCard
