import { ArrowRight, BgImg, Celebration, CheckCircle, CheckCircleOutlined, PhoneBGImg } from "assests";
import SuccessIcon from "assests/svg/SuccessIcon";
import { Button } from "components/UI/Button";
import DynamicSVG from "components/UI/DynamicSVG";
import FormHeader from "components/UI/FormHeader";
import SigninLogo from "components/UI/SigninLogo";
import React from "react";
import { useNavigate } from "react-router-dom";

const Done = ({ getValues }) => {
  const navigate = useNavigate();
  const firstName = getValues("firstName");
  return (
    <div className="flex items-center justify-center w-full ">
      <div className="z-[2] w-full flex flex-col items-center justify-center">
        <div
          className=" flex items-center justify-center w-full h-full "
          style={{ pointerEvents: "none" }}
        >
          <img src={BgImg} alt="" className="fixed top-0 hidden md:block z-[-1]" />
          <img src={PhoneBGImg} alt="" className="fixed top-0 block md:hidden z-[-1]" />
        </div>
        <div className="relative w-full px-4 md:p-0 md:w-[360px]">
          <div className="flex flex-col gap-8">
            <div className="flex flex-col gap-6">
              <SigninLogo>
                <CheckCircleOutlined width={28} height={28} />
              </SigninLogo>
              <FormHeader
                icon={false}
                title={`Welcome aboard, `}
                titleTwo={`${firstName}!`}
                subtitle={`Your journey towards enhanced garment supply chain management, starts now.`}
              />
            </div>
            <Button
              size="lg"
              className="flex items-center w-full gap-2 text-base font-semibold"
              onClick={() => navigate("/signin", { replace: true })}
            >
              Continue
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Done;
