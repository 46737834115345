import React from "react";

const ArticleIcon = React.forwardRef(
  (
    { width = "20", height = "20", viewBox = "0 0 20 20", fill = "none", className, ...props },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17.5 9.16667L11.1716 2.83824C10.7392 2.40587 10.523 2.18968 10.2707 2.03507C10.047 1.898 9.80317 1.79699 9.54808 1.73575C9.26036 1.66667 8.95462 1.66667 8.34314 1.66667L5 1.66667M2.5 7.25L2.5 8.89543C2.5 9.30309 2.5 9.50691 2.54605 9.69872C2.58688 9.86878 2.65422 10.0314 2.7456 10.1805C2.84867 10.3487 2.9928 10.4928 3.28105 10.7811L9.78105 17.2811C10.4411 17.9411 10.7711 18.2711 11.1516 18.3947C11.4864 18.5035 11.847 18.5035 12.1817 18.3947C12.5622 18.2711 12.8923 17.9411 13.5523 17.2811L15.6144 15.219C16.2744 14.5589 16.6044 14.2289 16.7281 13.8484C16.8368 13.5136 16.8368 13.153 16.7281 12.8183C16.6044 12.4378 16.2744 12.1077 15.6144 11.4477L9.53105 5.36439C9.2428 5.07613 9.09867 4.93201 8.93048 4.82894C8.78135 4.73756 8.61878 4.67022 8.44872 4.62939C8.25691 4.58334 8.05308 4.58334 7.64543 4.58334H5.16667C4.23325 4.58334 3.76654 4.58334 3.41002 4.76499C3.09641 4.92478 2.84145 5.17975 2.68166 5.49335C2.5 5.84987 2.5 6.31658 2.5 7.25Z"
          stroke="white"
          strokeWidth="1.39167"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  },
);

ArticleIcon.displayName = "ArticleIcon";

export default ArticleIcon;
