import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getOrderAgents } from "services/agent.services";
import { getArticleTypeList } from "services/article-types.services";
import { getArticles } from "services/article.services";
import { getCompositionList } from "services/composition.services";
import { getGenders } from "services/gender.services";
import {
  createOrder,
  getOrderDetails,
  updateOrder,
  uploadOrderImage,
} from "services/order.services";
import { getOrderRetailers } from "services/retailer.services";
import { getSeasonList } from "services/season.services";
import { getSubTiersList } from "services/subtiers.services";
import { getOrderSuppliers } from "services/supplier.services";
import { getTemplates } from "services/template.services";
import {
  setActionableCompanyType,
  toggleIsInvitationCreateModalOpen,
} from "store/accountDashboardSlice";
import { toggleIsAgentCreateModalOpen } from "store/agentSlice";
import { setArticleTypeList } from "store/articleTypeSlice";
import { setRefetchBillingData } from "store/billingSlice";
import { setCompositionList } from "store/compositionSlice";
import {
  setFormatedArticleList,
  setFormatedTemplateList,
  setOrderDetailsData,
  setShowArticleForm,
  setShowTemplateForm,
  setValidatedSteps,
  toggleActionableOrderId,
  toggleArtcileReachedModal,
  toggleInviteReachedModal,
  toggleIsOrderCreateModalOpen,
  updateOrderSliceState,
} from "store/orderSlice";
import { toggleIsRetailerCreateModalOpen } from "store/retailerSlice";
import { toggleIsSupplierCreateModalOpen } from "store/supplierSlice";
import {
  formateLable,
  formattedDateIntoObject,
  formattedTodaysDateIntoObject,
  objectDeepCheck,
} from "utils/helper";
import { createOrderSchema } from "validation/order.validation";
import useCustomToaster from "./useCustomToaster";
import usePermission from "./usePermission";

const useAddOrder = ({ refetchData }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { workspaceId } = useParams();
  const { checkPlanLimitation } = usePermission();
  const { user, workspaces, currentWorkspace } = useSelector((state) => state.authenticationSlice);
  const { refetchBillingData, workspacePlan } = useSelector((state) => state.billingSlice);
  const {
    validatedSteps,
    actionableOrderId,
    isOrderCreateModalOpen,
    isDuplicateAction,
    orderDetailsData,
    formatedArticleList,
    showArticleForm,
    showTemplateForm,
    formatedTemplateList,
    refetchRetailerData,
    refetchSupplierData,
    refetchAgentData,
    refetchTemplateData,
    artcileReachedModal,
    inviteReachedModal,
  } = useSelector((state) => state.orderSlice);

  const ownWorkspaceId = currentWorkspace?.user_workspace_id;

  const defaultTiersItem = {
    tier_1: [],
    tier_2: [],
    tier_3: [],
    tier_4: [],
    tier_5: [],
  };

  const newDataDefaultValue = {
    person_name: "",
    company_name: "",
    email: "",
    company_type: "",
    workspace_id: parseInt(workspaceId),
  };

  // Local State
  const [seasonList, setSeasonList] = useState([]);
  const [tiersItem, setTiersItem] = useState(defaultTiersItem);
  const [tierList, setTierList] = useState(null);
  const [agentList, setAgentList] = useState([]);
  const [supplierList, setSupplierList] = useState([]);
  const [retailerList, setRetailerList] = useState([]);
  const [isDraft, setIsDraft] = useState(false);
  const [genderList, setGenderList] = useState([]);
  const [workspace, setWorkspace] = useState(null);

  const [showAgentForm, setShowAgentForm] = useState(false);
  const [showSupplierForm, setShowSupplierForm] = useState(false);
  const [showRetailerForm, setShowRetailerForm] = useState(false);
  //file upload
  const [isDragOver, setIsDragOver] = useState(false);
  const [fileData, setFileData] = useState([]);
  const [showFileData, setShowFileData] = useState([]);
  const [imagePreviews, setImagePreviews] = useState([]);
  const [hasImage, setHasImage] = useState(false);
  const [isDataReady, setIsDataReady] = useState(false);

  const queryClient = useQueryClient();
  const { showCustomToast } = useCustomToaster();
  const {
    control,
    register,
    reset,
    watch,
    setValue,
    getValues,
    setError,
    clearErrors,
    handleSubmit,
    trigger,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: {
      orderNumber: "",
      quantity: "",
      season_id: "",
      order_date: formattedTodaysDateIntoObject(),
      status: "draft",
      workspace_id: parseInt(workspaceId),
      created_by: currentWorkspace?.user_workspace_id,
      created_by_user_type: user?.workspace_type,
      template_id: "",
      article_id: "",
      templates: {
        name: "",
        workspace_id: parseInt(workspaceId),
        subtiers: [],
      },
      invites: null,
    },
    reValidateMode: "onChange",
    resolver: zodResolver(createOrderSchema),
  });

  const { data: seasonListQuery } = useQuery({
    queryKey: ["seasons", "getSeasonList"],
    queryFn: getSeasonList,
    enabled: !!workspaceId,
  });

  const { data: orderDetailsById, isLoading: isOrderDetailsLoading } = useQuery({
    queryKey: ["orders", "getOrderDetails", actionableOrderId],
    queryFn: () => getOrderDetails(actionableOrderId),
    enabled: !!workspaceId && !!actionableOrderId,
  });

  const { data: articleTypesAndCompositionQuery } = useQuery({
    queryKey: ["articleTypes", "compositions", "getList", workspaceId],
    queryFn: () => Promise.all([getArticleTypeList(), getCompositionList()]),
    enabled: !!workspaceId,
  });

  const { data: genderQuery } = useQuery({
    queryKey: ["genders", "getList", workspaceId],
    queryFn: getGenders,
    enabled: !!workspaceId,
  });

  const {
    data: articleListQuery,
    isLoading: isArticleLoading,
    status: articleStatus,
  } = useQuery({
    queryKey: ["articles", "getArticleList", workspaceId],
    queryFn: () => getArticles({ pagination: "no", workspace_id: parseInt(workspaceId) }),
    enabled: !!workspaceId,
  });

  const {
    data: templatesQuery,
    isLoading: isTemplateLoading,
    status: templateStatus,
  } = useQuery({
    queryKey: ["templates", "getTemplateList", refetchTemplateData, workspaceId],
    queryFn: () => getTemplates({ pagination: "no", workspace_id: parseInt(workspaceId) }),
    enabled: !!workspaceId,
  });

  const { data: SubTiers } = useQuery({
    queryKey: ["template", "get-sub-tiers", workspaceId],
    queryFn: getSubTiersList,
    enabled: !!workspaceId,
  });

  const { data: agentListQuery, isLoading: agentQueryIsLoading } = useQuery({
    queryKey: ["createUpdateOrderQueries", "getAgentList", refetchAgentData, workspaceId],
    queryFn: () => getOrderAgents({ pagination: "no", workspace_id: parseInt(workspaceId) }),
    enabled:
      !!workspaceId &&
      !!currentWorkspace &&
      !["agent", "supplier"].includes(currentWorkspace?.role_name?.split(".")[0]),
  });

  const {
    data: supplierListQuery,
    isLoading: isSupplierLoading,
    status: supplierQueryStatus,
  } = useQuery({
    queryKey: ["createUpdateOrderQueries", "getSupplierList", refetchSupplierData, workspaceId],
    queryFn: () => getOrderSuppliers({ pagination: "no", workspace_id: parseInt(workspaceId) }),
    enabled:
      !!workspaceId &&
      !!currentWorkspace &&
      !["supplier"].includes(currentWorkspace?.role_name?.split(".")[0]),
  });

  const {
    data: retailerListQuery,
    isLoading: isRetailerListQueryLoading,
    status: retailerQueryStatus,
  } = useQuery({
    queryKey: ["createUpdateOrderQueries", "getRetailersList", refetchRetailerData, workspaceId],
    queryFn: () => getOrderRetailers({ pagination: "no", workspace_id: parseInt(workspaceId) }),
    enabled:
      !!workspaceId &&
      !!currentWorkspace &&
      !["retailer", "importer", "brand"].includes(currentWorkspace?.role_name?.split(".")[0]),
  });

  useEffect(() => {
    const articleTypes = articleTypesAndCompositionQuery?.[0];
    const compositionData = articleTypesAndCompositionQuery?.[1];
    if (articleTypes?.data?.article_type) {
      const updatedArticleTypeList = articleTypes?.data?.article_type?.map((item) => ({
        value: item?.id,
        label: item?.name,
      }));
      dispatch(setArticleTypeList(updatedArticleTypeList));
    }

    if (compositionData?.data?.compositions) {
      const updatedCompositionList = compositionData?.data?.compositions?.map((item) => ({
        value: item?.id,
        label: item?.name,
      }));
      dispatch(setCompositionList(updatedCompositionList));
    }
  }, [articleTypesAndCompositionQuery]);

  const createOrderMutation = useMutation({
    mutationKey: ["orders", "createOrder"],
    mutationFn: (data) => createOrder(data),
    onMutate: () => {
      showCustomToast(
        {
          title: isDraft ? "Saving the Order..." : "Creating the Order...",
        },
        "createOrderLoadingToast",
      );
    },
    onSuccess: (res) => {
      toast.dismiss("createOrderLoadingToast");
      if (res.data.status) {
        setImagePreviews([]);
        setFileData([]);
        setShowFileData([]);
        setHasImage(false);
        refetchData();
        showCustomToast({
          title:
            res?.data?.order?.status?.name === "draft"
              ? "Order saved successfully"
              : "Order created successfully",
        });
        setIsDraft(false);
        if (!refetchBillingData) {
          dispatch(setRefetchBillingData(true));
        }
        if (isOrderCreateModalOpen) {
          dispatch(toggleIsOrderCreateModalOpen());
        }
        reset();
        if (res?.data?.order?.status?.name === "draft") {
          setTiersItem(defaultTiersItem);
          navigate(`/workspace/${workspaceId}/orders?status=draft`);
        } else {
          setTiersItem(defaultTiersItem);
          navigate(`/workspace/${workspaceId}/orders?status=${res?.data?.order?.status?.name}`);
        }

        if (res.data.order.id) {
          if (fileData && fileData.length > 0) {
            const formData = new FormData();
            fileData.forEach((file, index) => {
              formData.append(`photos[${index}]`, file); // Append each file individually with a dynamic key
            });
            formData.append("order_id", res.data.order.id);
            uploadOrderImageMutation.mutate(formData);
          }
        }
      }
    },
    onError: (error) => {
      toast.dismiss("createOrderLoadingToast");
      if (isDraft) {
        setIsDraft(false);
      }
      if (error.response?.data?.errors) {
        Object.entries(error.response.data.errors).forEach(([field, messages]) => {
          messages.forEach((message) => {
            showCustomToast({
              title: message,
            });
          });
        });
      } else {
        showCustomToast({
          title: error.response?.data?.message || error.message,
        });
      }
    },
    onSettled: () => {
      toast.dismiss("createOrderLoadingToast");
      if (isDraft) {
        setIsDraft(false);
      }
    },
  });

  const updateOrderMutation = useMutation({
    mutationKey: ["orders", "updateOrder"],
    mutationFn: (data) => updateOrder(data),
    onMutate: () => {
      showCustomToast(
        {
          title: isDraft ? "Saving the Order..." : "Updating the Order...",
        },
        "updateOrderLoadingToast",
      );
    },
    onSuccess: (res) => {
      toast.dismiss("updateOrderLoadingToast");
      if (res.data.status) {
        queryClient.invalidateQueries({
          queryKey: ["orders", "getOrderDetails", actionableOrderId],
        });
        setImagePreviews([]);
        setFileData([]);
        setShowFileData([]);
        setHasImage(false);
        refetchData();
        showCustomToast({
          title:
            res?.data?.order?.status?.name === "draft"
              ? "Order saved successfully"
              : "Order updated successfully",
        });
        setIsDraft(false);
        if (isOrderCreateModalOpen) {
          dispatch(toggleIsOrderCreateModalOpen());
        }
        if (actionableOrderId) {
          reset();
          setTiersItem(defaultTiersItem);
          dispatch(setOrderDetailsData(null));
          dispatch(toggleActionableOrderId());
        }

        if (res?.data?.order?.status?.name === "draft") {
          if (validatedSteps.length > 0) {
            dispatch(setValidatedSteps());
          }
          navigate(`/workspace/${workspaceId}/orders?status=draft`);
        } else {
          navigate(`/workspace/${workspaceId}/orders?status=${res?.data?.order?.status?.name}`);
        }
        if (res.data.order.id) {
          if (hasImage && fileData && fileData.length === 0) {
            const formData = new FormData();
            formData.append(`photos[]`, []);
            formData.append("order_id", res.data.order.id);
            uploadOrderImageMutation.mutate(formData);
          } else if (fileData && fileData.length > 0) {
            const formData = new FormData();
            fileData.forEach((file, index) => {
              formData.append(`photos[${index}]`, file); // Append each file individually with a dynamic key
            });
            formData.append("order_id", res.data.order.id);
            uploadOrderImageMutation.mutate(formData);
          }
        }
      }
    },
    onError: (error) => {
      toast.dismiss("updateOrderLoadingToast");
      if (isDraft) {
        setIsDraft(false);
      }
      if (error.response?.data?.errors) {
        Object.entries(error.response.data.errors).forEach(([field, messages]) => {
          messages.forEach((message) => {
            showCustomToast({
              title: message,
            });
          });
        });
      } else {
        showCustomToast({
          title: error.response?.data?.message || error.message,
        });
      }
    },
    onSettled: () => {
      toast.dismiss("updateOrderLoadingToast");
      if (isDraft) {
        setIsDraft(false);
      }
    },
  });

  const uploadOrderImageMutation = useMutation({
    mutationKey: ["orders", "uploadOrderImage"],
    mutationFn: (data) => uploadOrderImage(data),
    onSuccess: (res) => {
      toast.dismiss("uploadOrderImageLoadingToast");
      if (res.data.status === 200) {
        setFileData([]);
        setShowFileData([]);
        setImagePreviews([]);
        setHasImage(false);
      }
    },
    onError: (error) => {
      toast.dismiss("uploadOrderImageLoadingToast");
      if (isDraft) {
        setIsDraft(false);
      }
      if (error.response?.data?.errors) {
        Object.entries(error.response.data.errors).forEach(([field, messages]) => {
          messages.forEach((message) => {
            showCustomToast({
              title: message,
            });
          });
        });
      } else {
        showCustomToast({
          title: error.response?.data?.message || error.message,
        });
      }
    },
    onSettled: () => {
      toast.dismiss("createOrderLoadingToast");
      if (isDraft) {
        setIsDraft(false);
      }
    },
  });

  /**
   * Handle the article select
   * @param {object} selectedOption - The selected option
   * @return {void} No return value
   */
  const handleArticleSelect = (selectedOption) => {
    if (selectedOption) {
      setValue("article_id", selectedOption);
      clearErrors("article_id");
      if (formatedTemplateList.length > 0) {
        const find = formatedTemplateList.find((item) => item.value === selectedOption.template_id);
        setValue("template_id", find);
        updatedTemplateData(find, templatesQuery, { hasChangedArticleOrTemplate: true });
      }
    }
  };

  /**
   * Auto select the partner when the user is not the owner
   * or visiting the invited workspace to create order.
   * @param {object} currentWorkspaceData - The current workspace data
   * @return {void} No return value
   */

  const [hasAutoSelected, setHasAutoSelected] = useState(false)
  const watchInvites = watch("invites");
  const autoSelectPartner = (currentWorkspaceData) => {
    if (
      !currentWorkspaceData ||
      typeof currentWorkspaceData != "object" ||
      currentWorkspaceData.is_owner
    ) {
      return;
    }

    //destruct the data
    const {
      invited_workspace_id,
      user_workspace_id,
      invited_workspace_name,
      invited_workspace_type,
    } = currentWorkspaceData;

    const workspaceRolePrefix = currentWorkspace?.role_name?.split(".")[0];
    /**
     * Check if the user is not the owner and the invited workspace type is supplier or agent
     * and i am retailer, importer, brand who is creating order for the supplier or agent
     * on that time auto select the partner according the visited workspace type
     */
    if (
      workspaceRolePrefix &&
      ["retailer", "importer", "brand"].includes(workspaceRolePrefix) &&
      ["supplier", "agent"].includes(invited_workspace_type)
    ) {
      if (getValues("invites") !== null) {
        setValue("invites", null);
      }

      const inviteData = {
        isAgentOrSupplierOption: {
          company_id: {
            label: formateLable(invited_workspace_name, invited_workspace_type),
            customLabel: `${invited_workspace_name}${" "}${invited_workspace_type}`,
            value: user_workspace_id,
            company_type: user.workspace_type,
            purchased_package: user.workspace_type,
            pending: false,
            workspace_id: user_workspace_id,
          },
          company_type: user.workspace_type,
        },
      };
      if (invited_workspace_type === "agent") {
        setAgentList([inviteData.isAgentOrSupplierOption.company_id]);
      } else {
        setSupplierList([inviteData.isAgentOrSupplierOption.company_id]);
      }
      setValue("invites", inviteData);
    }

    /**
     * Check if the user is not the owner and the invited workspace type is supplier, retailer, importer, or brand
     * and i am agent who is creating order for the supplier, retailer, importer, or brand
     * on that time auto select the partner according the visited workspace type
     */
    if (
      workspaceRolePrefix &&
      ["agent"].includes(workspaceRolePrefix) &&
      ["supplier", "retailer", "importer", "brand"].includes(invited_workspace_type)
    ) {
      if (getValues("invites") !== null) {
        setValue("invites", null);
      }
      const dynamicPartnerType = invited_workspace_type === "supplier" ? "supplier" : "retailer";
      const inviteData = {
        [dynamicPartnerType]: {
          company_id: {
            label: formateLable(invited_workspace_name, invited_workspace_type),
            customLabel: `${invited_workspace_name}${" "}${invited_workspace_type}`,
            value: user_workspace_id,
            company_type: user.workspace_type,
            purchased_package: user.workspace_type,
            pending: false,
            workspace_id: user_workspace_id,
          },
          company_type: user.workspace_type,
        },
      };
      if (["retailer", "importer", "brand"].includes(invited_workspace_type)) {
        setRetailerList([inviteData[dynamicPartnerType].company_id]);
      } else {
        setSupplierList([inviteData[dynamicPartnerType].company_id]);
      }
      setValue("invites", inviteData);
    }

    /**
     * Check if the user is not the owner and the invited workspace type is retailer, importer, or brand
     * and i am supplier who is creating order for the  retailer, importer, or brand
     * on that time auto select the partner according the visited workspace type
     */
    if (
      workspaceRolePrefix &&
      ["supplier"].includes(workspaceRolePrefix) &&
      ["retailer", "importer", "brand"].includes(invited_workspace_type)
    ) {
      if (getValues("invites") !== null) {
        setValue("invites", null);
      }

      const inviteData = {
        retailer: {
          company_id: {
            label: formateLable(invited_workspace_name, invited_workspace_type),
            customLabel: `${invited_workspace_name}${" "}${invited_workspace_type}`,
            value: user_workspace_id,
            company_type: user.workspace_type,
            purchased_package: user.workspace_type,
            pending: false,
            workspace_id: user_workspace_id,
          },
          company_type: user.workspace_type,
        },
      };

      if (["retailer", "importer", "brand"].includes(invited_workspace_type)) {
        setRetailerList([inviteData.retailer.company_id]);
      }
      setValue("invites", inviteData);
    }
    setHasAutoSelected(true);
  };

  useEffect(() => {
    if (currentWorkspace) {
      autoSelectPartner(currentWorkspace);
    }
  }, [currentWorkspace, hasAutoSelected]);

  /**
   * Handle the order status according the form validation
   * also handle the invite field validation
   * @return {void} No return value
   */



  useEffect(() => {
    if (!isValid && currentWorkspace.is_owner) {
      setValue("status", "draft");
      setValue("invites", null);
    }
    if (!isValid && !currentWorkspace.is_owner) {
      setHasAutoSelected(true);
    }
    if (isValid && watchInvites && typeof watchInvites === "object" && watchInvites !== null) {
      const hasValidCompanyIds = Object.values(watchInvites).every((item) => {
        if (!item || !item.company_id || !item.company_id.value) {
          return false;
        }
        return true;
      });
      if (!hasValidCompanyIds) {
        setValue("status", "draft");
      } else {
        setValue("status", "active");
      }
    } else {
      setValue("status", "draft");
    }
  }, [isValid, watchInvites, currentWorkspace]);

  /**
   * Handle the drag enter event.
   *
   * @param {type} e - The event object
   * @return {type} No return value
   */
  const handleDragEnter = (e) => {
    e.preventDefault();
    setIsDragOver(true);
  };

  /**
   * Handles the drag leave event.
   *
   * @param {type} e - The event object
   * @return {type} No return value
   */
  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragOver(false);
  };

  /**
   * A function to handle the drop event.
   *
   * @param {Event} e - The event object
   * @return {void} No return value
   */
  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragOver(false);
    onFileDropOrChange(e);
  };

  /**
   * Handles the file drop or change event.
   *
   * @param {Event} e - The event object containing file information.
   * @return {void} No return value.
   */
  const onFileDropOrChange = (e) => {
    const MAX_FILE_SIZE = 10;
    let files = null;
    if (isDragEvent(e)) {
      files = e.dataTransfer.files;
    } else {
      files = e.target.files;
    }

    if (files && files.length > 0) {
      const newFiles = Array.from(files); // Convert FileList to array
      const validFiles = []; // To store valid files only
      const previewPromises = newFiles.map((file, index) => {
        return new Promise((resolve, reject) => {
          // Check file type
          if (file.type !== "application/pdf" && !file.type.startsWith("image/")) {
            showCustomToast({
              title: "Please drop a PDF or image file.",
            });
            reject("Invalid file type");
            return;
          }
          // Check file size
          if (file.size > MAX_FILE_SIZE * 1024 * 1024) {
            showCustomToast({
              title: "File size exceeds 10MB limit.",
            });
            reject("File size exceeds limit");
            return;
          }
          // Add valid files to the list
          validFiles.push(file);

          // Generate image preview if it's an image file
          if (file.type.startsWith("image/")) {
            const reader = new FileReader();
            reader.onloadend = () => {
              resolve(reader.result);
            };
            reader.onerror = () => {
              reject("Error reading file");
            };
            reader.readAsDataURL(file);
          } else {
            // Resolve with null for non-image files
            resolve(null);
          }
        });
      });

      Promise.all(previewPromises)
        .then((previews) => {
          setImagePreviews((prevPreviews) => [...prevPreviews, ...previews]);
          setFileData((prevFileData) => [...prevFileData, ...validFiles]);
          setShowFileData((prevShowFileData) => [...prevShowFileData, ...validFiles]);
          // Clear the input value to allow re-selection of the same files
          if (e.target && e.target.value) {
            e.target.value = null;
          }
        })
        .catch((error) => {
          console.error("Error processing files:", error);
        });
    }
  };

  /**
   * Deletes an image from the order by removing it from the image previews, file data, and show file data arrays.
   *
   * @param {number} index - The index of the image to be deleted.
   * @return {void} This function does not return anything.
   */
  const handleorderImageDelete = (index) => {
    // Remove the image preview and corresponding file data
    const updatedPreviews = [...imagePreviews];
    const updatedFileData = [...fileData];
    const updatedShowFileData = [...showFileData];
    updatedPreviews.splice(index, 1);
    updatedFileData.splice(index, 1);
    updatedShowFileData.splice(index, 1);

    setImagePreviews(updatedPreviews);
    setFileData(updatedFileData);
    setShowFileData(updatedShowFileData);
  };

  /**
   * Checks if the event contains "dataTransfer" property.
   *
   * @param {type} event - The event object to check
   * @return {type} true if event has "dataTransfer" property, false otherwise
   */
  const isDragEvent = (event) => {
    return "dataTransfer" in event;
  };

  /**
   * Handles adding a new article by setting values and updating composition form length.
   *
   * @param None
   * @return None
   */
  const handleClickAddNewArticle = () => {
    if (checkPlanLimitation && !checkPlanLimitation.canCreateArticle) {
      dispatch(toggleArtcileReachedModal());
    } else {
      dispatch(setShowArticleForm(true));
    }
  };

  /**
   * Handle the selection of a template.
   *
   * @param {type} selectedOption - The selected option
   * @return {type} Description of what is returned
   */
  const handleSelectTemplate = (selectedOption) => {
    if (selectedOption) {
      clearErrors("template_id");
      clearErrors("templates");
      updatedTemplateData(selectedOption, templatesQuery, { hasChangedArticleOrTemplate: true });
    } else {
      setValue("template_id", null);
      setTiersItem(defaultTiersItem);
      setValue("templates", { ...defaultTiersItem, name: "", subtiers: [] });
    }
  };

  const onOrderFormSubmit = async (data) => {
    const processedData = await processOrderData(data);
    if (actionableOrderId) {
      updateOrderMutation.mutate({ ...processedData, actionableOrderId });
    } else {
      createOrderMutation.mutate(processedData);
    }
  };

  const onOrderFormSubmitError = async (error) => {
    console.log("error", error);
  };

  const handleClickAddNewTempalte = () => {
    dispatch(updateOrderSliceState({ showCreateTemplateForm: true }));
  };

  const handleTierOptionSelect = (tier, selectedOption) => {
    if (selectedOption && tier) {
      setTiersItem((prev) => ({
        ...prev,
        [tier]: selectedOption,
      }));
      setValue(`templates.${tier}`, selectedOption);
    }
  };

  const processOrderData = async (data) => {
    let tempProcessedData = { ...data };
    if (Object.keys(data).length > 0) {
      Object.keys(data).forEach((key) => {
        if (
          data[key] &&
          typeof data[key] === "object" &&
          "label" in data[key] &&
          "value" in data[key]
        ) {
          tempProcessedData[key] = data[key].value;
        } else if (
          key === "order_date" &&
          typeof data[key] === "object" &&
          (data[key] !== null || data[key]?.startDate !== null)
        ) {
          tempProcessedData.order_date = data[key].startDate;
        } else if (key === "templates") {
          const flattenedTiers = Object.entries(data[key]).reduce((acc, [key, value]) => {
            if (key.startsWith("tier_")) {
              acc.push(value.map((item) => item.value));
            }
            return acc;
          }, []);
          tempProcessedData.templates = {
            name: data.templates.name,
            subtiers: flattenedTiers.flat(),
            workspace_id: parseInt(workspaceId),
          };
        } else if (key === "invites" && data[key] !== null && Object.keys(data[key]).length > 0) {
          tempProcessedData.invites = Object.values(data[key])
            .map(({ company_id, company_type }) => {
              const getCompanyType = () => {
                if (company_type === "isAgentOrSupplierOption") {
                  return company_id.company_type;
                }
                if (company_type === "retailer") {
                  return company_id.purchased_package;
                }
                return company_type;
              };
              const tempCompanyType = getCompanyType();
              if (company_id && typeof company_id === "object") {
                return company_id.pending
                  ? {
                    new_data: {
                      company_type: tempCompanyType,
                      invitation_id: company_id.value,
                    },
                  }
                  : {
                    company_id: company_id.value,
                    company_type: tempCompanyType,
                  };
              } else {
                return null;
              }
            })
            .filter(Boolean);
        }
      });
    }

    return tempProcessedData;
  };

  const handleChangeCompany = (selectedOption) => {
    if (!selectedOption) {
      setValue("invites", null);
      clearErrors("invites");
    }
    if (
      currentWorkspace &&
      [
        "retailer.admin",
        "importer.admin",
        "brand.admin",
        "retailer.editor",
        "importer.editor",
        "brand.editor",
      ].includes(currentWorkspace.role_name) &&
      selectedOption
    ) {
      setValue("invites", null);
    }
  };

  const handleChangeType = (selectedOption, type) => {
    if (selectedOption) {
      if (
        currentWorkspace &&
        [
          "retailer.admin",
          "importer.admin",
          "brand.admin",
          "retailer.editor",
          "importer.editor",
          "brand.editor",
        ].includes(currentWorkspace.role_name)
      ) {
        setValue("invites", null);
      }
      setValue(`invites.${type}.company_id`, selectedOption);
      setValue(`invites.${type}.company_type`, type);
      clearErrors(`invites.${type}`);
    } else {
      clearErrors(`invites.${type}`);
      setValue(`invites.${type}.company_type`, null);
      setValue(`invites.${type}.new_data`, {
        ...newDataDefaultValue,
        company_type: type,
      });
    }
  };

  const onClickInvite = (type) => {
    switch (type) {
      case "AGENT":
        if (checkPlanLimitation && !checkPlanLimitation.canCreatePartner) {
          dispatch(toggleInviteReachedModal());
        } else {
          dispatch(toggleIsAgentCreateModalOpen());
          dispatch(setActionableCompanyType("agent"));
        }
        break;
      case "SUPPLIER":
        if (checkPlanLimitation && !checkPlanLimitation.canCreatePartner) {
          dispatch(toggleInviteReachedModal());
        } else {
          dispatch(toggleIsSupplierCreateModalOpen());
          dispatch(setActionableCompanyType("supplier"));
        }
        break;
      case "RETAILER":
        if (checkPlanLimitation && !checkPlanLimitation.canCreatePartner) {
          dispatch(toggleInviteReachedModal());
        } else {
          dispatch(toggleIsRetailerCreateModalOpen());
          if (user && user.workspace_type === "supplier") {
            dispatch(setActionableCompanyType("retailer"));
          }
        }
        break;
      case "PARTNER":
        if (checkPlanLimitation && !checkPlanLimitation.canCreatePartner) {
          dispatch(toggleInviteReachedModal());
        } else {
          dispatch(toggleIsInvitationCreateModalOpen());
        }
        break;
      default:
        break;
    }
  };

  const setDefaultFormValue = (details) => {
    if (!details && !actionableOrderId) return;
    if (details) {
      const {
        order_number,
        workspace_id,
        quantity,
        season_id,
        articles_id,
        invites,
        images,
        status,
        order_date,
        order_etd,
        order_eta,
        supplier,
        order_template,
      } = details;

      if (order_etd) {
        setValue("order_etd", formattedDateIntoObject(order_etd));
      }
      if (order_eta) {
        setValue("order_eta", formattedDateIntoObject(order_eta));
      }
      setValue("order_date", formattedDateIntoObject(order_date));
      setValue("orderNumber", order_number);
      setValue("quantity", quantity);
      setValue("workspace_id", parseInt(workspace_id));
      if (seasonList) {
        const findSeason = seasonList?.find((item) => item.value === parseInt(season_id));
        setValue("season_id", findSeason);
      }
      if (formatedArticleList) {
        const findarticle = formatedArticleList?.find(
          (item) => item.value === parseInt(articles_id),
        );
        setValue("article_id", findarticle);
      }

      if (order_template) {
        const templateName = order_template.name;
        const selectedTemplate = {
          label: tierLabel(templateName, order_template.tier),
          value: parseInt(order_template.template_id),
        };
        setValue("template_id", selectedTemplate);
        updatedTemplateData(selectedTemplate, templatesQuery, {
          passedUpdatedOrderData: details,
        });
      }

      if (
        currentWorkspace &&
        [
          "retailer.admin",
          "importer.admin",
          "brand.admin",
          "retailer.editor",
          "importer.editor",
          "brand.editor",
        ].includes(currentWorkspace?.role_name) &&
        invites &&
        invites.length > 0
      ) {
        setValue(`invites.${invites[0].company_type}.company_id`, {
          label: invites[0].company_name,
          value: invites[0].id,
          purchased_package: details[invites[0].company_type]
            ? details[invites[0].company_type].purchased_package
            : null,
        });
        setValue(
          `invites.${invites[0].company_type}.company_type`,
          details[invites[0].company_type]
            ? details[invites[0].company_type].purchased_package
            : null,
        );
      }
      if (currentWorkspace && currentWorkspace?.role_name?.includes("agent") && invites) {
        const supplierData = invites.supplier_invitation;
        if (supplierData) {
          setValue("invites.supplier.company_id", {
            label: supplierData.company_name,
            value: supplierData.id,
            purchased_package: supplierData.company_type,
          });
          setValue("invites.supplier.company_type", "supplier");
        }
      }
      if (
        currentWorkspace &&
        currentWorkspace?.role_name.includes("supplier") &&
        invites &&
        invites.length > 0
      ) {
        const retailerData = invites.find((item) =>
          ["retailer", "importer", "brand"].includes(item.company_type),
        );
        if (retailerData) {
          setValue("invites.retailer.company_id", {
            label: retailerData.name,
            value: retailerData.id,
            purchased_package: retailerData.company_type,
          });
          setValue("invites.retailer.company_type", "retailer");
        }
      }
      trigger();
    }
  };

  useEffect(() => {
    if (workspaces?.length > 0 && workspaceId) {
      const selectedWorkspace = workspaces?.find(
        (workspace) => parseInt(workspace?.pivot?.workspace_id) === parseInt(workspaceId),
      );
      setWorkspace(selectedWorkspace);
    }
  }, [workspaceId, workspaces]);

  useEffect(() => {
    if (genderQuery && genderQuery?.data?.status) {
      const modifiedList = genderQuery?.data?.gender?.map(({ name, id }) => ({
        label: name,
        value: id,
      }));
      setGenderList(modifiedList);
    }
  }, [genderQuery]);

  useEffect(() => {
    if (seasonListQuery && seasonListQuery.data.status) {
      const modifiedList = seasonListQuery?.data?.seasons?.map((season) => ({
        label: formateLable(season.name, season.year),
        customLabel: `${season.name}${" "}${season.year}`,
        value: season?.id,
      }));
      setSeasonList(modifiedList);
    }
  }, [seasonListQuery]);

  useEffect(() => {
    if (
      articleListQuery &&
      articleStatus === "success" &&
      !isArticleLoading &&
      articleListQuery.status === 200
    ) {
      const formatedArticleList = articleListQuery?.data?.articles?.map((article) => ({
        label: formateLable(article.sku, article.article_types.name),
        customLabel: `${article.sku}${" "}${article.article_types.name}`,
        value: article.id,
        template_id: article.template_id || article?.template?.id,
      }));
      dispatch(setFormatedArticleList(formatedArticleList));
    }
  }, [articleListQuery, isArticleLoading, articleStatus]);

  const tierLabel = (templateName, tiers) => {
    const flattenedTiers = tiers.flatMap((tier) =>
      tier.sub_tiers.map((subTier) => (subTier ? subTier.name : "")),
    );
    return templateName;
  };

  useEffect(() => {
    if (templatesQuery && templatesQuery.data.status) {
      const modifiedList = templatesQuery?.data?.templates?.map((template) => ({
        label: tierLabel(template.name, template.tiers),
        value: template?.id,
      }));
      dispatch(setFormatedTemplateList(modifiedList));
    }
  }, [templatesQuery]);

  const findTheTempalteFromTheList = (templateId, templatesQueryResult) => {
    if (templatesQueryResult && templatesQueryResult.data.status) {
      return templatesQueryResult?.data?.templates?.find((template) => template.id === templateId);
    }
  };

  const prepareCompareableTemplateObject = (sourceName, sourceRawTemplatedData) => {
    if (!sourceRawTemplatedData) return;
    let templateObject = null;
    switch (sourceName) {
      case "order_template":
        templateObject = {
          template_id: sourceRawTemplatedData.template_id,
          name: sourceRawTemplatedData.name,
        };

        // Add tiers if they exist
        if (
          sourceRawTemplatedData.tier &&
          Array.isArray(sourceRawTemplatedData.tier) &&
          sourceRawTemplatedData.tier.length > 0
        ) {
          sourceRawTemplatedData.tier.forEach((tier, index) => {
            templateObject[`tier_${index + 1}`] = tier.sub_tiers.map((subTier) => ({
              value: subTier?.id,
              label: subTier?.name,
            }));
          });
        }
        break;

      case "original_template":
        templateObject = {
          template_id: sourceRawTemplatedData.id,
          name: sourceRawTemplatedData.name,
        };
        // Add tiers if they exist
        if (
          sourceRawTemplatedData.tiers &&
          Array.isArray(sourceRawTemplatedData.tiers) &&
          sourceRawTemplatedData.tiers.length > 0
        ) {
          sourceRawTemplatedData.tiers.forEach((tier, index) => {
            templateObject[`tier_${index + 1}`] = tier.sub_tiers.map((subTier) => ({
              value: subTier.id,
              label: subTier.name,
            }));
          });
        }
        // Add similar tier processing if needed
        break;

      case "form_registered_template":
        templateObject = {
          template_id: sourceRawTemplatedData.template_id?.value || null,
          name: sourceRawTemplatedData.name || "",
        };

        const hasTierData =
          sourceRawTemplatedData["templates"] &&
          Object.keys(sourceRawTemplatedData["templates"]).some((key) => /^tier_[1-5]$/.test(key));

        if (hasTierData) {
          for (let i = 1; i <= 5; i++) {
            const tierKey = `tier_${i}`;
            if (
              Array.isArray(sourceRawTemplatedData["templates"][tierKey]) &&
              sourceRawTemplatedData["templates"][tierKey].length > 0
            ) {
              templateObject[tierKey] = sourceRawTemplatedData["templates"][tierKey].map(
                (subTier) => ({
                  value: subTier.value,
                  label: subTier.label,
                }),
              );
            }
          }
        }
        break;

      default:
        break;
    }
    return templateObject;
  };

  const handleTemplateUpdate = (templateData, findedTemplate) => {
    const { name, template_id, ...tierData } = templateData;
    setTiersItem(tierData);
    if (findedTemplate) {
      setValue("templates", { ...tierData, name: findedTemplate.name });
    }
  };

  const updatedTemplateData = (watchedTemplateId, templatesQueryResult, restOptions = {}) => {
    if (watchedTemplateId && templatesQueryResult && templatesQueryResult.data.status) {
      const identifiedTemplateId = watchedTemplateId?.value || null;

      const {
        hasChangedArticleOrTemplate = false,
        isCancel = false,
        passedUpdatedOrderData = null,
      } = restOptions;

      const currentOrderData = passedUpdatedOrderData || orderDetailsData;

      //this condition identified the order is in editing mode or creation mode
      if (
        actionableOrderId &&
        currentOrderData &&
        identifiedTemplateId &&
        currentOrderData.order_template
      ) {
        //prepare the order template object
        const currentOrderTemplateDetails = currentOrderData.order_template;
        const orderTemplate = prepareCompareableTemplateObject(
          "order_template",
          currentOrderTemplateDetails,
        );
        // check if the order is draft or pending
        const isItDraftOrder = currentOrderData?.status?.name === "draft";
        const isItPendingOrder = currentOrderData?.status?.name === "pending";

        // check if the template id is the same as the order template id
        const isTempalteIdSameAsOrderTemplateId =
          currentOrderData?.template?.id === identifiedTemplateId;

        if (isItDraftOrder && isTempalteIdSameAsOrderTemplateId) {
          const findedTemplate = findTheTempalteFromTheList(identifiedTemplateId, templatesQuery);
          const originalTemplate = prepareCompareableTemplateObject(
            "original_template",
            findedTemplate,
          );
          const formTemplate = prepareCompareableTemplateObject(
            "form_registered_template",
            getValues(),
          );
          // Check if form has any tier data
          const hasTierData =
            formTemplate && Object.keys(formTemplate).some((key) => /^tier_[1-5]$/.test(key));

          // Compare templates and update form
          if (objectDeepCheck(originalTemplate, orderTemplate)) {
            // Original and order templates are different - use order template as base
            if (hasTierData) {
              if (isCancel) {
                handleTemplateUpdate(orderTemplate, findedTemplate);
              } else {
                const shouldUseFormTemplate = objectDeepCheck(orderTemplate, formTemplate);
                handleTemplateUpdate(
                  shouldUseFormTemplate && !hasChangedArticleOrTemplate
                    ? formTemplate
                    : orderTemplate,
                  findedTemplate,
                );
              }
            } else {
              handleTemplateUpdate(orderTemplate, findedTemplate);
            }
          } else {
            if (hasTierData) {
              // Original and order templates match - use original template as base
              const shouldUseFormTemplate = objectDeepCheck(originalTemplate, formTemplate);
              if (shouldUseFormTemplate && !hasChangedArticleOrTemplate) {
                handleTemplateUpdate(formTemplate, findedTemplate);
              } else {
                handleTemplateUpdate(originalTemplate, findedTemplate);
              }
            } else {
              if (isCancel) {
                if (objectDeepCheck(orderTemplate, formTemplate)) {
                  handleTemplateUpdate(formTemplate, findedTemplate);
                  return;
                }
              }
              handleTemplateUpdate(originalTemplate, findedTemplate);
            }
          }
        } else {
          if (isCancel && !hasChangedArticleOrTemplate) {
            const formTemplate = prepareCompareableTemplateObject(
              "form_registered_template",
              getValues(),
            );

            const hasTierData =
              formTemplate && Object.keys(formTemplate).some((key) => /^tier_[1-5]$/.test(key));
            const isSameTemplate = orderTemplate.template_id === formTemplate.template_id;

            if (isSameTemplate) {
              const compareTemplate = objectDeepCheck(orderTemplate, formTemplate);
              if (hasTierData && compareTemplate) {
                const findedTemplate = findTheTempalteFromTheList(
                  orderTemplate.template_id,
                  templatesQuery,
                );
                handleTemplateUpdate(formTemplate, findedTemplate);
                return;
              }
            }
            const findedTemplate = findTheTempalteFromTheList(
              orderTemplate.template_id,
              templatesQuery,
            );
            const selectedTemplate = {
              label: tierLabel(findedTemplate.name, findedTemplate.tiers),
              value: findedTemplate.id,
            };
            setValue("template_id", selectedTemplate);
            handleTemplateUpdate(orderTemplate, findedTemplate);
          } else {
            const findTemplate = findTheTempalteFromTheList(identifiedTemplateId, templatesQuery);
            const originalTemplate = prepareCompareableTemplateObject(
              "original_template",
              findTemplate,
            );
            handleTemplateUpdate(originalTemplate, findTemplate);
          }
        }
      } else {
        const findedTemplate = findTheTempalteFromTheList(identifiedTemplateId, templatesQuery);
        if (findedTemplate) {
          const originalTemplate = prepareCompareableTemplateObject(
            "original_template",
            findedTemplate,
          );
          const formTemplate = prepareCompareableTemplateObject(
            "form_registered_template",
            getValues(),
          );

          // Check if form has any tier data
          const hasTierData =
            formTemplate && Object.keys(formTemplate).some((key) => /^tier_[1-5]$/.test(key));

          // If no tier data or templates don't match, use original template
          if (
            !hasTierData ||
            originalTemplate.template_id !== formTemplate.template_id ||
            hasChangedArticleOrTemplate
          ) {
            handleTemplateUpdate(originalTemplate, findedTemplate);
            return;
          }

          // Use form template if it differs from original, otherwise use original
          const templateToUse =
            objectDeepCheck(originalTemplate, formTemplate) && !isCancel
              ? formTemplate
              : originalTemplate;

          handleTemplateUpdate(templateToUse, findedTemplate);
        }
      }
    }
  };

  const onCloseTempalteForm = () => {
    updatedTemplateData(getValues("template_id"), templatesQuery, { isCancel: true });
    dispatch(setShowTemplateForm(false));
  };

  useEffect(() => {
    if (SubTiers && SubTiers.data.status) {
      let tierData = {};
      for (const subTier of SubTiers.data.sub_tiers) {
        const tierId = subTier.tier.id;
        if (!tierData[`tier_${tierId}`]) {
          tierData[`tier_${tierId}`] = [];
        }
        if (subTier) tierData[`tier_${tierId}`].push({ label: subTier.name, value: subTier.id });
      }
      setTierList(tierData);
    }
  }, [SubTiers]);

  useEffect(() => {
    if (!isRetailerListQueryLoading && retailerListQuery && retailerListQuery.data) {
      const dataList = retailerListQuery?.data?.data;
      if (dataList && Array.isArray(dataList) && dataList.length > 0) {
        const retailerList = dataList?.map((item) => ({
          ...item,
          label: formateLable(item?.company_name, item?.company_type),
          customLabel: `${item?.company_name}${" "}${item?.company_type}`,
          value: item?.pending ? item.invitation_id : item.workspace_id,
          purchased_package: item?.company_type,
        }));
        setRetailerList(retailerList);
      }
    }
  }, [retailerListQuery, isRetailerListQueryLoading]);

  useEffect(() => {
    if (!agentQueryIsLoading && agentListQuery && agentListQuery.data) {
      const dataList = agentListQuery?.data?.data;
      if (dataList && Array.isArray(dataList) && dataList.length > 0) {
        const agentList = dataList.map((item) => ({
          ...item,
          label: formateLable(item?.company_name, item?.company_type),
          customLabel: `${item?.company_name}${" "}${item?.company_type}`,
          value: item?.pending ? item.invitation_id : item.workspace_id,
          purchased_package: item?.company_type,
        }));
        setAgentList(agentList);
      }
    }
  }, [agentListQuery, agentQueryIsLoading]);

  useEffect(() => {
    if (supplierListQuery && !isSupplierLoading && supplierQueryStatus === "success") {
      if (supplierListQuery.data) {
        const result = supplierListQuery.data.data;
        if (result && Array.isArray(result) && result.length > 0) {
          const supplierList = result?.map((item) => ({
            ...item,
            label: formateLable(item?.company_name, item?.company_type),
            customLabel: `${item?.company_name}${" "}${item?.company_type}`,
            value: item?.pending ? item.invitation_id : item.workspace_id,
            purchased_package: item?.company_type,
          }));
          setSupplierList(supplierList);
        }
      }
    }
  }, [supplierListQuery, isSupplierLoading, supplierQueryStatus]);

  const formatedLists = useMemo(
    () => ({
      seasonList,
      formatedArticleList,
      formatedTemplateList,
    }),
    [seasonList, formatedArticleList, formatedTemplateList],
  );

  useEffect(() => {
    if (orderDetailsById?.data && seasonList && formatedArticleList && formatedTemplateList) {
      setIsDataReady(true);
    }
  }, [actionableOrderId, orderDetailsById, seasonList, formatedArticleList, formatedTemplateList]);

  useEffect(() => {
    if (isDataReady && actionableOrderId && orderDetailsById) {
      if (orderDetailsById.data && orderDetailsById.data.order) {
        console.log(orderDetailsById.data.order);
        dispatch(setOrderDetailsData(orderDetailsById.data.order));
        setDefaultFormValue(orderDetailsById.data.order);
      }
    }
  }, [isDataReady, actionableOrderId, formatedLists]);

  const showForms = {
    showAgentForm,
    showArticleForm,
    showRetailerForm,
    showSupplierForm,
    newDataDefaultValue,
  };

  return {
    control,
    seasonList,
    errors,
    showArticleForm,
    formatedTemplateList,
    tierList,
    showTemplateForm,
    tiersItem,
    agentList,
    retailerList,
    supplierList,
    genderList,
    workspace,
    showForms,
    watch,
    clearErrors,
    register,
    setValue,
    getValues,
    processOrderSubmitOrderForm: handleSubmit(onOrderFormSubmit, onOrderFormSubmitError),
    handleClickAddNewTempalte,
    handleTierOptionSelect,
    handleClickAddNewArticle,
    handleSelectTemplate,
    onClickInvite,
    handleChangeType,
    handleChangeCompany,
    fileData,
    setFileData,
    showFileData,
    isDragOver,
    handleDragEnter,
    handleDragLeave,
    handleDrop,
    onFileDropOrChange,
    imagePreviews,
    handleorderImageDelete,
    setError,
    actionableOrderId,
    isDuplicateAction,
    orderDetailsData,
    isPending: createOrderMutation.isPending || updateOrderMutation.isPending,
    isOrderDetailsLoading,
    formatedArticleList,
    isValid,
    workspacePlan,
    currentWorkspace,
    artcileReachedModal,
    onCloseTempalteForm,
    inviteReachedModal,
    handleArticleSelect,
  };
};

export default useAddOrder;
