import api from "config/axios";
export const createCertificate = (data) =>
  api.post("/certificates", data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

export const getCertificates = (query) => {
  const params = new URLSearchParams();
  Object.keys(query).forEach((key) => {
    const value = query[key];
    if (Array.isArray(value)) {
      value.forEach((item) => {
        params.append(`${key}[]`, item);
      });
    } else if (value) {
      params.append(key, value);
    }
  });

  return api.get(`/certificates?${params}`);
};

export const downloadCertificates = async (query) => {
  try {
    const params = new URLSearchParams();
    Object.keys(query).forEach((key) => {
      if (query[key]) {
        params.append(key, query[key]);
      }
    });
    return api.get(`/certificates?download=yes&${params}`);
  } catch (error) {
    throw error;
  }
};

export const getCertificateDetailsById = (id) => api.get(`/certificates/${id}`);
export const updateCertificates = (data) =>
  api.post(`/certificates/${data.id}`, data.body, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

export const deleteCertificate = (id) => api.delete(`/certificates/${id}`);

export const deleteWorkspaceCertificate = (id) => {
  try {
    const response = api.delete(`/certificates/${id}?type=partner`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const bulkDeleteWorkspaceCertificate = async (data) => {
  try {
    const response = await api.post(`/certificate-bulk-delete`, data);
    return response;
  } catch (error) {
    throw error;
  }
};
