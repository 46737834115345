import { CompanyIcon, MailSignup, PasscodeIcon, SingleUserIcon, StarsSignupIcon } from "assests";
import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import SigninLogo from "./SigninLogo";

const Step = ({ index, step, activeStep, isActive, handleClickStepper }) => {
  const dispatch = useDispatch();

  const handleStepClick = useCallback(() => {
    handleClickStepper(step);
  }, [dispatch, step, handleClickStepper]);

  // Function to render the icon based on the step number
  const renderIcon = (step) => {
    switch (step) {
      case 1:
        return <SingleUserIcon />;
      case 2:
        return <CompanyIcon fillColor="var(--color-login-icon-outlined-default)" width={20} height={20} />;
      case 3:
        return <PasscodeIcon />;
      case 4:
        return <MailSignup />;
      case 5:
        return <StarsSignupIcon />;
      default:
        return null;
    }
  };

  // Function to get the step label text
  const getStepLabel = (step) => {
    switch (step) {
      case 1:
        return "Your details";
      case 2:
        return "Company details";
      case 3:
        return "Choose a password";
      case 4:
        return "Email verification";
      case 5:
        return "All set!";
      default:
        return "";
    }
  };

  return (
    <>
      <ul className="w-full relative  flex-row gap-x-0 hidden lg:flex">
        <li className="shrink basis-0 flex-1 group">
          <div className="w-full inline-flex items-center text-xs ">
            <div className="relative">
              <span className="flex justify-center items-center"> <SigninLogo fromSignup={true}>{renderIcon(step)}</SigninLogo></span>

              <div className="absolute left-1/2 top-16 transform -translate-x-1/2 -translate-y-1/2 whitespace-nowrap">
                <span
                  className={`block text-sm font-semibold ${activeStep >= step ? "text-global-gray-brand" : " text-global-gray-brand-inactive"}`}
                >
                  {getStepLabel(step)}
                </span>
              </div>
            </div>

            <div
              className={`w-[216px] lg:w-[180px] h-[2px]   ${isActive ? "text-global-gray-brand bg-global-gray-brand font-semibold" : "bg-global-gray-soft  font-semibold"} ${index === 4 ? "group-last:hidden" : "flex-1"}`}
            ></div>
          </div>
        </li>
      </ul>
      <div
        className={`w-[10px] h-[10px] flex lg:hidden rounded-full cursor-pointer ${activeStep === step
          ? "bg-global-primary"
          : activeStep > step
            ? "bg-[#E4E7EC]"
            : "bg-[#E4E7EC]"
          }`}
      ></div>
    </>
  );
};

const Stepper = ({ stepList, handleClickStepper }) => {
  const { activeStep } = useSelector((state) => state.createAccountSlice);
  const { isDarkMode } = useSelector((state) => state.themeSlice);

  return (
    <>
      <div
        className={`flex items-center justify-center w-full lg:pt-[56px] ${activeStep === 1 && " pb-[120px]"}`}
      >
        <div className={`flex items-center justify-center`}>
          <div className="inline-flex items-center justify-start gap-4 lg:gap-0 w-full h-auto">
            {stepList &&
              stepList.map(({ label, step }, index) => (
                <Step
                  key={index}
                  isDarkMode={isDarkMode}
                  isActive={activeStep - 1 >= step}
                  {...{ label, index, step, activeStep, handleClickStepper }}
                />
              ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Stepper;
