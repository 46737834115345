import { CheckCircleOutlined, Cross } from "assests";
import SigninLogo from "components/UI/SigninLogo";
import React from "react";

const SuccessCardEmailVerified = ({ handleCloseSuccessEmailVerified }) => {
    return (
        <div className="w-full relative rounded-xl bg-global-white-default border border-border-secondary shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)] ">
            <div className="absolute top-[18px] right-[18px]">
                <Cross
                    fillColor="var(--color-icon-fill-input)"
                    width={24}
                    height={24}
                    onClick={handleCloseSuccessEmailVerified}
                    className="cursor-pointer"
                />
            </div>
            <div className="w-full p-6">
                <div className="flex flex-col gap-4">
                    <div className="flex flex-col gap-4">
                        <SigninLogo>
                            <CheckCircleOutlined width={28} height={28} />
                        </SigninLogo>
                        <div className="grid gap-[2px]">
                            <h6
                                className={`font-semibold text-center text-login-text-title text-base md:text-lg`}
                            >
                                Successfully updated
                            </h6>
                            <p className="font-normal text-center text-login-text-paragraph  text-sm">
                                Your FiberVue™ email has been successfully updated
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SuccessCardEmailVerified;
