const { createSlice } = require("@reduxjs/toolkit");

const accountDashboardSlice = createSlice({
  name: "accountDashboard",
  initialState: {
    activeTab: "overview",
    workspacesData: null,
    accountDashboardData: null,
    workspaceDashboardData: null,
    deleteWorkspaceModal: false,
    leaveWorkspaceModal: false,
    showAcceptInviteModal: false,
    isInvitationCreateModalOpen: false,
    actionableCompanyType: null,
    pendingInvitationData: null,
    pendingInvitedData: null,
    actionableInvitationId: null,
    actionableItemData: null
  },
  reducers: {
    setActiveTab: (state, action) => {
      state.activeTab = action.payload;
    },
    setAccountDashboardData: (state, action) => ({
      ...state,
      accountDashboardData: action.payload.accountDashboardData ?? null,
    }),
    setWorkspaceDashboardData: (state, action) => ({
      ...state,
      workspaceDashboardData: action.payload.workspaceDashboardData ?? null,
    }),
    setWorkspacesData: (state, action) => ({
      ...state,
      workspacesData: action.payload.workspacesData ?? null,
    }),
    setPendingInvitationListData: (state, action) => ({
      ...state,
      pendingInvitationData: action.payload ?? null,
    }),
    setPendingInvitedListData: (state, action) => ({
      ...state,
      pendingInvitedData: action.payload ?? null,
    }),
    toggleDeleteWorkspaceModal: (state) => ({
      ...state,
      deleteWorkspaceModal: !state.deleteWorkspaceModal,
    }),
    toggleLeaveWorkspaceModal: (state) => ({
      ...state,
      leaveWorkspaceModal: !state.leaveWorkspaceModal,
    }),
    toggleShowAcceptInviteModal: (state) => ({
      ...state,
      showAcceptInviteModal: !state.showAcceptInviteModal,
    }),
    toggleIsInvitationCreateModalOpen: (state) => ({
      ...state,
      isInvitationCreateModalOpen: !state.isInvitationCreateModalOpen,
    }),
    setActionableCompanyType: (state, action) => ({
      ...state,
      actionableCompanyType: action.payload ?? null,
    }),
    setActionableInvitationId: (state, action) => ({
      ...state,
      actionableInvitationId: action.payload ?? null,
    }),
    setActionableItemData: (state, action) => ({
      ...state,
      actionableItemData: action.payload ?? null,
    }),
  },
});

export const {
  setActiveTab,
  setAccountDashboardData,
  setWorkspaceDashboardData,
  toggleDeleteWorkspaceModal,
  toggleLeaveWorkspaceModal,
  toggleShowAcceptInviteModal,
  toggleIsInvitationCreateModalOpen,
  setActionableCompanyType,
  setPendingInvitationListData,
  setWorkspacesData,
  setPendingInvitedListData,
  setActionableInvitationId,
  setActionableItemData
} = accountDashboardSlice.actions;
export default accountDashboardSlice.reducer;
