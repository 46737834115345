import React, { useEffect } from "react";
import SetPassword from "./SetPassword";
import ConfirmEmail from "./ConfirmEmail";
import CompanyDetails from "./CompanyDetails";
import Done from "./Done";
import Stepper from "components/UI/Stepper";
import PersonalDetails from "./PersonalDetails";
import { CreateAccountStepList } from "utils/constant";
import { useSelector } from "react-redux";
import useRegistration from "hooks/useRegistration";
import { Navigate, useNavigate } from "react-router-dom";
import { setActiveStep } from "store/createAccountSlice";
import Navbar from "layout/Navbar";
const Signup = () => {
  const { activeStep, countryList, cityList } = useSelector((state) => state.createAccountSlice);
  const { token } = useSelector((state) => state.authenticationSlice);
  const { isDarkMode } = useSelector((state) => state.themeSlice);
  const navigate = useNavigate()
  const {
    control,
    passwordInputType,
    confirmPasswordInputType,
    errors,
    isRegistering,
    isEmailValidating,
    register,
    nextStep,
    setValue,
    togglePasswordType,
    toggleConfirmPasswordType,
    getValues,
    resendOtp,
    validateTheOtp,
    handleClickStepper,
    hash,
    dispatch,
    setDefaultCountryCode,
    defaultCountryCode,
    phoneNumberErrorMessage,
    handlePhoneNumberChange,
    watch,
    handleChangeCompanyType,
    filterdInviteViewList
  } = useRegistration();

  useEffect(() => {
    if (token || localStorage.getItem("auth") || !hash) {
      navigate(`/`, { replace: true });
    }
  }, []);


  return (
    <>
      <div
        className={`w-full  min-h-screen   h-full ${isDarkMode ? "bg-global-surface" : "bg-global-theme"
          }`}
      >
        <div className="hidden lg:block">
          <Navbar />
        </div>
        <div
          className={`flex flex-col justify-between w-full h-full gap-6 md:gap-12  ${activeStep !== 1 && "pt-[48px] lg:py-[120px]"}`}
        >
          <div className="flex flex-col justify-between w-full h-full gap-12 ">
            <div className="grid gap-10">
              {activeStep === 1 && (
                <PersonalDetails
                  {...{
                    register,
                    nextStep,
                    errors,
                    hash,
                    setActiveStep,
                    getValues,
                    setDefaultCountryCode,
                    setValue,
                    defaultCountryCode,
                    phoneNumberErrorMessage,
                    handlePhoneNumberChange,
                    watch,
                  }}
                />
              )}
              {activeStep === 2 && (
                <CompanyDetails
                  {...{
                    control,
                    register,
                    nextStep,
                    errors,
                    setValue,
                    getValues,
                    countryList,
                    cityList,
                    dispatch,
                    setActiveStep,
                    handleChangeCompanyType,
                    filterdInviteViewList
                  }}
                />
              )}
              {activeStep === 3 && (
                <SetPassword
                  {...{
                    register,
                    nextStep,
                    errors,
                    getValues,
                    setValue,
                    passwordInputType,
                    confirmPasswordInputType,
                    togglePasswordType,
                    toggleConfirmPasswordType,
                    isRegistering,
                    setActiveStep,
                  }}
                />
              )}
              {activeStep === 4 && (
                <ConfirmEmail
                  {...{ getValues, resendOtp, validateTheOtp, setActiveStep, isEmailValidating }}
                />
              )}
              {activeStep === 5 && <Done {...{ getValues }} />}
            </div>
          </div>
          <div className={`${activeStep === 1 ? "lg:hidden" : activeStep === 5 ? "hidden" : "block"}`}>
            <Stepper
              stepList={CreateAccountStepList}
              handleClickStepper={handleClickStepper}
              step={activeStep}
            />
          </div>

        </div>
      </div>
    </>
  );
};

export default Signup;
