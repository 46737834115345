
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";

import Navbar from "./Navbar";
import { BgImg, PhoneBGImg } from "assests";
const LoginLayout = ({ children }) => {
  const { token } = useSelector((state) => state.authenticationSlice);
  const { isDarkMode } = useSelector((state) => state.themeSlice);
  if (token || localStorage.getItem("auth")) {
    return <Navigate to="/" replace={true} />;
  }

  return (
    <div
      className={`w-full h-full min-h-screen   ${isDarkMode ? "bg-global-surface" : "bg-global-theme"
        } overflow-y-auto `}
    >
      <div
        className=" flex items-center justify-center w-full h-full "
        style={{ pointerEvents: "none" }}
      >
        <img src={BgImg} alt="" className="fixed top-0 hidden md:block" />
        <img src={PhoneBGImg} alt="" className="fixed top-[50px] block md:hidden" />
      </div>
      <Navbar />
      <div className="flex flex-col justify-between w-full h-full py-[120px]  lg:py-[176px] gap-20">
        {children}
      </div>
    </div>
  );
};

export default LoginLayout;
