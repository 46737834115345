import toast from "react-hot-toast";
import SigninLogo from "./UI/SigninLogo";
import { InfoCircleIcon, SuccessIcon, XCloseIcon } from "assests";
import ToasterIcon from "./UI/ToasterIcon";

const CustomToaster = ({ data, toastT }) => {
    const renderSubtitle = () => {
        if (!data.boldText) {
            return <span>{data?.subtitle}</span>;
        }

        const parts = data?.subtitle.split(data.boldText);
        return (
            <>
                {parts[0]}
                <span className="font-semibold">
                    {data.boldText}
                </span>
                {parts[1]}
            </>
        );
    };
    return (
        <>
            <div
                className={`${toastT?.visible ? "animate-enter" : "animate-leave"
                    }  max-w-md min-w-[300px] md:min-w-[510px] relative  min-h-[76px] p-4 rounded-xl pointer-events-auto border border-global-gray-medium flex justify-start  gap-4 ring-0  bg-white ${data?.subtitle ? "items-start" : "items-center"}`}
                style={{
                    boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.10)",
                }}
            >
                {
                    data?.icon ?
                        <SuccessIcon width={40} height={40} />
                        : <ToasterIcon>
                            <InfoCircleIcon width={20} height={20} />
                        </ToasterIcon>
                }

                <div className="w-full relative">
                    <div className="pr-5">
                        {data?.title && (
                            <p className={`text-sm leading-tight font-semibold text-toast-text-title`}>
                                {data?.title}
                            </p>
                        )}
                        {data?.subtitle && (
                            <p className={`mt-1 text-sm leading-tight font-normal text-toast-text-subtitle`}>
                                {renderSubtitle()}
                                {data?.url && (
                                    <a href="mailto:hello@fivervue.com">
                                        <strong> {data?.url}</strong>
                                    </a>
                                )}
                            </p>
                        )}
                    </div>
                </div>

                <div className="absolute top-4 right-4">
                    <button
                        type="button"
                        onClick={() => toast.remove(toastT?.id)}
                        className="w-full border-0 flex items-center  cursor-pointer justify-center text-sm font-medium "
                    >
                        <XCloseIcon width={20} height={20} />
                    </button>
                </div>
            </div>
        </>
    );
};

export const showToast = (data, id) => {
    toast.custom((t) => <CustomToaster data={data} toastT={t} />, { id });
};
