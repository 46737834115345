import React from "react";

const Archive = React.forwardRef(
  (
    {
      width = "16",
      height = "16",
      viewBox = "0 0 16 16",
      fill = "none",
      strokeColor = "#717680",
      className,
      ...props
    },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.33333 5.33106C2.224 5.32824 2.1446 5.32191 2.07321 5.30771C1.54429 5.2025 1.13083 4.78904 1.02562 4.26012C1 4.13132 1 3.97644 1 3.66667C1 3.3569 1 3.20201 1.02562 3.07321C1.13083 2.54429 1.54429 2.13083 2.07321 2.02562C2.20201 2 2.3569 2 2.66667 2H12.6667C12.9764 2 13.1313 2 13.2601 2.02562C13.789 2.13083 14.2025 2.54429 14.3077 3.07321C14.3333 3.20201 14.3333 3.3569 14.3333 3.66667C14.3333 3.97644 14.3333 4.13132 14.3077 4.26012C14.2025 4.78904 13.789 5.2025 13.2601 5.30771C13.1887 5.32191 13.1093 5.32824 13 5.33106M6.33333 8.66667H9M2.33333 5.33333H13V10.8C13 11.9201 13 12.4802 12.782 12.908C12.5903 13.2843 12.2843 13.5903 11.908 13.782C11.4802 14 10.9201 14 9.8 14H5.53333C4.41323 14 3.85318 14 3.42535 13.782C3.04903 13.5903 2.74307 13.2843 2.55132 12.908C2.33333 12.4802 2.33333 11.9201 2.33333 10.8V5.33333Z"
          stroke={strokeColor}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  },
);

Archive.displayName = "Archive";

export default Archive;
