import { IconButton, Menu } from "@mui/material";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { EditLable, MoreOptions, PlanIcon, Shock, TemplateIcon, TrashUpdateIcon } from "assests";
import DeleteButton from "components/MoreAction/DeleteButton";
import DuplicateButton from "components/MoreAction/DuplicateButton";
import EditButton from "components/MoreAction/EditButton";
import ViewButton from "components/MoreAction/ViewButton";
import Modal from "components/UI/Modal";
import { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  bulkDeleteTemplates,
  deleteTemplate,
  downloadTemplatesAsCSV,
  getTemplates,
} from "services/template.services";
import {
  setTemplateAllData,
  setTemplateList,
  toggleActionableTemplateId,
  toggleActionableWorkspaceId,
  toggleCreateTemplateOpen,
  toggleDelAccessDeniedModal,
  toggleDeleteModal,
  toggleIsTemplateEditable,
  toggleTemplateDuplicateAction,
  toggleThresholdModal,
} from "store/templateSlice";

import EmptyTable from "components/Card/EmptyTable";
import MoreActionOptionUpdated from "components/MoreAction/MoreActionOptionUpdated";
import SearchCreatePanelUpdate from "components/SearchCreatePanelUpdate";
import ActionRequired from "components/UI/ActionRequired";
import { Button } from "components/UI/Button";
import CommonDeleteButtonUpdated from "components/UI/CommonDeleteButtonUpdated";
import CommonDiscardButton from "components/UI/CommonDiscardButton";
import LoadingComponent from "components/UI/LoadingComponent";
import MemberTooltip from "components/UI/MemberToolptip";
import SortIcons from "components/UI/SortIcons";
import TertiaryPagination from "components/UI/TertiaryPagination";
import useCustomToaster from "hooks/useCustomToaster";
import queryString from "query-string";
import { setRefetchBillingData } from "store/billingSlice";
import { formatDateToDayMonthYear, removeQueryParam, truncateString } from "utils/helper";
import CreateTemplate from "./CreateTemplate";
import TemplateFilters from "./TemplateFilters";
import usePermission from "hooks/usePermission";
import CommonDiscardButtonUpdated from "components/UI/CommonDiscardButtonUpdated";
const Template = () => {
  const {
    isCreateTemplateOpen,
    actionableTemplateId,
    templateList,
    isTemplateEditable,
    deleteModal,
    isDuplicateAction,
    templateAllData,
    thresholdModal,
    delAccessDeniedModal,
    actionableWorkspaceId,
  } = useSelector((state) => state.templateSlice);
  const { user, currentWorkspace, subscription, subscription_status } = useSelector(
    (state) => state.authenticationSlice,
  );
  const { hasPermission, checkPlanLimitation } = usePermission();
  const { refetchBillingData, workspacePlan } = useSelector((state) => state.billingSlice);
  const [templateOptions, setTemplateOptions] = useState([]);
  const { workspaceId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const parsed = queryString.parse(location.search);
  const [anchorEl, setAnchorEl] = useState(null);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const [searchKey, setSearchKey] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const [selectedTemplates, setSelectedTemplates] = useState([]);
  const inputRefExternal = useRef(null);
  const open = Boolean(anchorEl);
  const { showCustomToast } = useCustomToaster();
  const [activeFilters, setActiveFilters] = useState({});
  const [filteredData, setFilteredData] = useState({});
  const handleClose = () => {
    setAnchorEl(null);
    if (actionableTemplateId) {
      dispatch(toggleActionableTemplateId());
    }
  };

  const handleSizeChange = (newSize) => {
    setSize(newSize);
    setPage(1);
  };

  const {
    data: templatesData,
    isLoading: istemplateQueryLoading,
    refetch,
  } = useQuery({
    queryKey: ["templates", "get", page, size, searchKey, workspaceId, filteredData, orderBy],
    queryFn: () =>
      getTemplates({
        workspace_id: parseInt(workspaceId),
        page,
        per_page: size,
        search: searchKey,
        sort: orderBy,
        ...filteredData,
      }),
    enabled: !!workspaceId || !!page || !!size || !!searchKey || !orderBy,
  });

  const {
    data: articlesNopaginatedData,
    isLoading: isArticlesNopaginatedData,
    refetch: refetchNopaginatedData,
  } = useQuery({
    queryKey: ["templatenopaginate", "getData", workspaceId],
    queryFn: () =>
      getTemplates({
        workspace_id: parseInt(workspaceId),
        pagination: "no",
      }),
    enabled: !!workspaceId,
  });

  useEffect(() => {
    if (!isArticlesNopaginatedData && articlesNopaginatedData) {
      if (articlesNopaginatedData?.data?.templates) {
        setTemplateOptions(articlesNopaginatedData?.data?.templates);
      }
    }
  }, [articlesNopaginatedData, isArticlesNopaginatedData]);

  const downloadTemplateCSV = useMutation({
    mutationKey: ["template", "downloadTemplate"],
    mutationFn: (data) => downloadTemplatesAsCSV(data),
    onMutate: () => {
      showCustomToast(
        {
          title: "Downloading...",
        },
        "downloadTemplates",
      );
    },
    onSuccess: (res) => {
      toast.dismiss("downloadTemplates");
      // toast.success("Downloaded successfully");
      console.log(res);

      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "templates.csv");
      document.body.appendChild(link);
      link.click();
      link.remove();
      showCustomToast({
        title: "Template downloaded successfully",
      });
      queryClient.invalidateQueries(["template", "downloadTemplate"]);
    },
    onError: (error) => {
      toast.dismiss("downloadTemplates");
      toast.error(error.response.data.message || error.message);
    },
  });

  const openCreateModal = () => {
    dispatch(toggleCreateTemplateOpen());
    if (actionableTemplateId) {
      dispatch(toggleActionableTemplateId());
    }
    if (!isTemplateEditable) {
      dispatch(toggleIsTemplateEditable());
    }
  };
  const openAction = () => {
    if ((currentWorkspace && currentWorkspace.is_owner && (!subscription || (subscription && (!subscription_status || subscription_status === "canceled")))) || !workspacePlan) {
      setShowUpgradeModal(true);
    } else {
      openCreateModal();
    }
  };
  const closeModal = () => {
    if (isCreateTemplateOpen) {
      dispatch(toggleCreateTemplateOpen());
    }
    if (actionableTemplateId) {
      dispatch(toggleActionableTemplateId());
    }
    if (isTemplateEditable) {
      dispatch(toggleIsTemplateEditable());
    }
    if (deleteModal) {
      dispatch(toggleDeleteModal());
    }
    if (isDuplicateAction) {
      dispatch(toggleTemplateDuplicateAction());
    }
    if (actionableWorkspaceId) {
      dispatch(toggleActionableWorkspaceId());
    }
    if (showUpgradeModal) {
      setShowUpgradeModal(false);
    }
    if (thresholdModal) {
      dispatch(toggleThresholdModal());
    }
    if (delAccessDeniedModal) {
      dispatch(toggleDelAccessDeniedModal());
    }
    removeQueryParam(
      ["fromGlobalCreate", "fromGlobalSearch", "template_id", "selectedTab"],
      location,
      navigate,
    );
  };

  const refetchDatas = () => {
    refetch();
    refetchNopaginatedData();
  };
  const handleActionClick = (templateId, workspaceId) => {
    dispatch(toggleActionableTemplateId(templateId));
    dispatch(toggleActionableWorkspaceId(workspaceId));
  };

  const handleClickOptionButton = (templateId, actionType, workspaceId) => {
    switch (actionType) {
      case "view":
        if (isTemplateEditable) {
          dispatch(toggleIsTemplateEditable());
        }
        dispatch(toggleActionableTemplateId(templateId));
        dispatch(toggleActionableWorkspaceId(workspaceId));
        dispatch(toggleCreateTemplateOpen());
        setAnchorEl(null);
        break;
      case "edit":
        dispatch(toggleActionableTemplateId(templateId));
        dispatch(toggleActionableWorkspaceId(workspaceId));
        if (parseInt(workspaceId) === 1) {
          dispatch(toggleThresholdModal());
        } else {
          if (!isTemplateEditable) {
            dispatch(toggleIsTemplateEditable());
          }
          dispatch(toggleCreateTemplateOpen());
        }
        setAnchorEl(null);
        break;
      case "duplicate":
        dispatch(toggleActionableTemplateId(templateId));
        dispatch(toggleActionableWorkspaceId(workspaceId));
        dispatch(toggleTemplateDuplicateAction());
        if (!isTemplateEditable) {
          dispatch(toggleIsTemplateEditable());
        }
        dispatch(toggleCreateTemplateOpen());
        setAnchorEl(null);
        break;
      case "delete":
        dispatch(toggleActionableTemplateId(templateId));
        dispatch(toggleActionableWorkspaceId(workspaceId));
        dispatch(toggleDeleteModal());
        setAnchorEl(null);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (!istemplateQueryLoading && templatesData) {
      dispatch(
        setTemplateList({ templateList: templatesData?.data?.templates?.data, append: false }),
      );
      dispatch(
        setTemplateAllData({ templateAllData: templatesData?.data?.templates, append: false }),
      );
    }
  }, [templatesData, istemplateQueryLoading]);

  const deleteTemplateMutation = useMutation({
    mutationKey: ["template", "delete"],
    mutationFn: (templateId) => deleteTemplate(templateId),
    onMutate: () => {
      showCustomToast(
        {
          title: "Deleting...",
        },
        "deleteTemplate",
      );
      // toast.loading("Deleting...", { id: "deleteTemplate" });
    },
    onSuccess: () => {
      toast.dismiss("deleteTemplate");
      closeModal();
      if (!refetchBillingData) {
        dispatch(setRefetchBillingData(true));
      }
      showCustomToast({
        title: "Template deleted successfully",
      });
      refetchDatas();
      queryClient.invalidateQueries(["templates", "get", "delete"]);
    },
    onError: (error) => {
      toast.dismiss("deleteTemplate");
      toast.error(error.response.data.message || error.message);
    },
    onSettled: () => {
      toast.dismiss("deleteTemplate");
    },
  });

  const deleteAllMutation = useMutation({
    mutationKey: ["template", "delete"],
    mutationFn: (data) => bulkDeleteTemplates(data),
    onMutate: () => {
      // toast.loading("Deleting...", { id: "deleteTemplate" });
      showCustomToast(
        {
          title: "Deleting...",
        },
        "deleteTemplate",
      );
    },

    onSuccess: () => {
      toast.dismiss("deleteTemplate");
      refetchDatas();
      setSelectedTemplates([]);
      showCustomToast({
        title: "Template deleted successfully",
      });
      closeModal();
      if (!refetchBillingData) {
        dispatch(setRefetchBillingData(true));
      }
      queryClient.invalidateQueries(["templates", "get", "delete"]);
    },
    onError: (error) => {
      showCustomToast({
        title: error.response.data.message || "Something went wrong",
      });
    },
    onSettled: () => {
      toast.dismiss("deleteTemplate");
    },
  });

  const onClickCheckbox = (template) => {
    if (parseInt(template?.workspace_id) === 1 || parseInt(user?.workspace_id) !== parseInt(template?.created_by_user?.own_workspace_id)) return;
    const findedItem = selectedTemplates.some((item) => item.id === template.id);
    if (!findedItem) {
      setSelectedTemplates((prevSelectedOrders) => [...prevSelectedOrders, template]);
    } else {
      setSelectedTemplates((prevSelectedOrders) =>
        prevSelectedOrders.filter((item) => item.id !== template.id),
      );
    }
  };

  const handleSelectAll = (e) => {
    if (!e.target.checked || !templateList.length > 0) {
      setSelectedTemplates([]);
      return;
    }
    const templateIds =
      templateList.length > 0 &&
      templateList.filter((item) => parseInt(item?.workspace_id) !== 1 && parseInt(item?.created_by_user?.own_workspace_id) === parseInt(user?.workspace_id)).map((template) => template);
    setSelectedTemplates(templateIds);
  };

  const handleSearch = (e) => {
    const searchText = e.target.value?.trim();
    if (searchText.length >= 2) {
      setSearchKey(searchText);
    } else {
      setSearchKey("");
    }
  };
  const truncateItems = (items, maxLength) => {
    let displayNames = "";
    let totalLength = 0;

    for (let i = 0; i < items.length; i++) {
      let item = items[i]?.name ?? "N/A";
      totalLength += item.length;

      if (totalLength > maxLength) {
        displayNames += (i === 0 ? item.substring(0, maxLength - 3) : "") + "...";
        break;
      } else {
        displayNames += (i > 0 ? ", " : "") + item;
      }
    }

    return displayNames;
  };

  const handleDelete = (ids) => {
    if (actionableTemplateId) {
      deleteTemplateMutation.mutate(actionableTemplateId);
    } else {
      const allIds = ids.map((item) => item.id);
      if (allIds) {
        deleteAllMutation.mutate(allIds);
      }
    }
  };

  const openTemplateDeleteModal = (e) => {
    e.stopPropagation();
    dispatch(toggleDeleteModal());
  };

  const openSingleModal = (e, id, workspace_id) => {
    e.stopPropagation();
    setAnchorEl(null);
    setSelectedTemplates([]);
    dispatch(toggleActionableTemplateId(id));
    if (parseInt(workspace_id) === 1) {
      dispatch(toggleDelAccessDeniedModal());
    } else {
      dispatch(toggleDeleteModal());
    }
  };

  const handleOnDelete = () => {
    if (!actionableTemplateId) return;
    deleteTemplateMutation.mutate(actionableTemplateId);
  };

  useEffect(() => {
    if (parsed.fromGlobalCreate === "true") {
      dispatch(toggleCreateTemplateOpen());
      if (actionableTemplateId) {
        dispatch(toggleActionableTemplateId());
      }
      if (!isTemplateEditable) {
        dispatch(toggleIsTemplateEditable());
      }
    }
    if (
      parsed.fromGlobalSearch === "true" &&
      parsed.template_id !== null &&
      parsed.workspace_id !== null
    ) {
      handleActionClick(parsed.template_id);
      handleClickOptionButton(parsed.template_id, "view", parsed.workspace_id);
    }
  }, [parsed.fromGlobalCreate, parsed.template_id, parsed.fromGlobalSearch]);
  const handleDownloadCSV = () => {
    downloadTemplateCSV.mutate({ workspace_id: parseInt(workspaceId) });
  };

  function chunkArray(arr, size) {
    return Array.from({ length: Math.ceil(arr.length / size) }, (v, i) =>
      arr.slice(i * size, i * size + size),
    );
  }

  useEffect(() => {
    if (user && currentWorkspace) {
      const handleKeyDown = (e) => {
        const isMac = e.metaKey; // `metaKey` for Cmd on Mac
        const isWindows = e.ctrlKey; // `ctrlKey` for Ctrl on Windows
        if ((isMac || isWindows) && e.shiftKey && e.key.toLowerCase() === "v") {
          e.preventDefault();
          if (actionableTemplateId && actionableWorkspaceId) {
            setAnchorEl(null);
            handleClickOptionButton(actionableTemplateId, "view", actionableWorkspaceId);
          }
        }
        if ((currentWorkspace &&
          currentWorkspace.role_name.includes("supplier") && currentWorkspace.invited_workspace_type === "agent")) {
          return
        } else {
          // Edit Shortcut: Cmd+R (Mac) or Ctrl+R (Windows)
          if ((isMac || isWindows) && (e.key === "E" || e.key === "e") && hasPermission("updateTemplate")) {
            e.preventDefault();
            if (actionableTemplateId && actionableWorkspaceId) {
              setAnchorEl(null);
              handleClickOptionButton(actionableTemplateId, "edit", actionableWorkspaceId);
            }
          }
          // Delete Shortcut: Cmd+D (Mac) or Ctrl+D (Windows)
          if (e.key === "Delete" && hasPermission("deleteTemplate")) {
            e.preventDefault();
            if (selectedTemplates.length > 0) {
              dispatch(toggleDeleteModal());
            } else if (actionableTemplateId && actionableWorkspaceId) {
              setAnchorEl(null);
              openSingleModal(e, actionableTemplateId, actionableWorkspaceId);
            }
          }
          if ((e.ctrlKey || e.metaKey) && (e.key === "B" || e.key === "b") && hasPermission("canAddTemplate")) {
            e.preventDefault();
            setAnchorEl(null);
            dispatch(toggleCreateTemplateOpen());
          }
          // Duplicate Shortcut: Cmd+Shift+D (Mac) or Ctrl+Shift+D (Windows)
          if ((isMac || isWindows) && (e.key === "D" || e.key === "d") && hasPermission("duplicateTemplate")) {
            e.preventDefault();
            if (actionableTemplateId && actionableWorkspaceId) {
              setAnchorEl(null);
              handleClickOptionButton(actionableTemplateId, "duplicate", actionableWorkspaceId);
            }
          }
          if ((isMac || isWindows) && (e.key === "A" || e.key === "a") && hasPermission("deleteTemplate")) {
            e.preventDefault();

            // Dynamically calculate allChecked based on templateList
            const eligibleTemplates = selectedTemplates.filter(
              (item) => parseInt(item?.workspace_id) !== 1 && parseInt(item?.created_by_user?.own_workspace_id) === parseInt(user?.workspace_id),
            );
            const allChecked =
              eligibleTemplates?.length > 0 && selectedTemplates.length === eligibleTemplates.length;
            const newSelectAllState = !allChecked;
            const checkboxEvent = { target: { checked: newSelectAllState } };
            handleSelectAll(checkboxEvent);
          }
        }
      };

      window.addEventListener("keydown", handleKeyDown);
      return () => {
        window.removeEventListener("keydown", handleKeyDown);
      };
    }
  }, [
    user,
    currentWorkspace,
    dispatch,
    actionableTemplateId,
    selectedTemplates,
    templateList,
    handleSelectAll,
  ]);

  return (
    <>
      <div>
        <Modal open={isCreateTemplateOpen} close={closeModal} title={""}>
          <CreateTemplate closeModal={closeModal} refetch={refetchDatas} />
        </Modal>
        <Modal open={deleteModal} close={closeModal}>
          <CommonDeleteButtonUpdated
            closeModal={closeModal}
            title={selectedTemplates.length > 1 ? "Remove templates" : "Remove template"}
            title1={"Action Required"}
            subText1={`Are you sure you want to remove selected ${selectedTemplates.length > 1 ? "templates" : "template"}? This action cannot be undone.`}
            className="w-full lg:w-[600px]"
            IconComponent={EditLable}
            confirmButtonText="Remove"
            confirmButtonVariant="warning"
            confirmButtonClassName={"w-full h-11"}
            onConfirmClick={() =>
              actionableTemplateId ? handleOnDelete() : handleDelete(selectedTemplates)
            }
            icon={
              <TrashUpdateIcon
                width={20}
                height={20}
                strokeColor={"var(--color-icon-fill-secondary-button)"}
              />
            }
          />
        </Modal>
        <Modal open={thresholdModal} close={closeModal}>
          <ActionRequired
            closeModal={closeModal}
            title={"Edit Access Denied"}
            subText1={`This is a system-generated template and cannot be edited `}
            className="w-full lg:w-[600px] "
            IconComponent={EditLable}
          />
        </Modal>
        <Modal open={delAccessDeniedModal} close={closeModal}>
          <ActionRequired
            closeModal={closeModal}
            title={"Delete Access Denied"}
            subText1={`This is a system-generated template and cannot be deleted.`}
            className="w-full lg:w-[600px] "
            IconComponent={EditLable}
          />
        </Modal>
        <Modal open={showUpgradeModal} close={closeModal}>
          <CommonDiscardButtonUpdated
            closeModal={closeModal}
            title="Upgrade plan"
            subText1={currentWorkspace && !currentWorkspace?.is_owner && !workspacePlan ? `Your partner workspace is currently on a free plan.` : `You are currently on a free plan. Upgrade to a paid plan to start creating your own ${user && user?.workspace_type} workspaces.`}
            className="w-full lg:max-w-[544px]"
            confirmButtonClassName={"w-full h-11"}
            IconComponent={PlanIcon}
            confirmButtonText={currentWorkspace && !currentWorkspace?.is_owner && !workspacePlan ? null : "Ugrade Plan"}
            confirmButtonVariant="primary"
            onConfirmClick={() => {
              navigate(`/workspace/${currentWorkspace?.user_workspace_id}/setting?selectedTab=plan`);
              if (showUpgradeModal) {
                setShowUpgradeModal(false);
              }
            }}
            icon={
              <Shock width={20} height={20} strokeColor={"var(--color-icon-fill-secondary-button)"} />
            }
          />
        </Modal>

        <>
          <div className="bg-btn-surface-secondary border border-border-divider-stroke  rounded-xl custom-card w-full  mb-6">
            <SearchCreatePanelUpdate
              permissionText="canAddTemplate"
              permissionText2="deleteTemplate"
              inputRefExternal={inputRefExternal}
              createTxt={
                (currentWorkspace &&
                  currentWorkspace.role_name.includes("supplier") && currentWorkspace.invited_workspace_type === "agent")
                  ? ""
                  : "Add Templates"
              }
              title="Supply Chain Templates"
              handleDownloadCSV={handleDownloadCSV}
              selectedItems={selectedTemplates}
              openDeleteModal={openTemplateDeleteModal}
              btnText={templateList && templateList?.length}
              onBtnClick={openAction}
              onChange={handleSearch}
              classWidth="w-full md:w-[146px] whitespace-nowrap"
            >
              <TemplateFilters
                workspaceId={workspaceId}
                {...{
                  activeFilters,
                  setActiveFilters,
                  filteredData,
                  setFilteredData,
                  templateOptions,
                  setTemplateOptions,
                }}
              />
            </SearchCreatePanelUpdate>
            <div className="w-full max-w-screen overflow-x-auto ">
              <table className="w-full border-spacing-0 table-auto rounded-table-custom overflow-x-auto">
                <thead className="text-sm text-left whitespace-nowrap text-global-paragraph">
                  <tr className="border-b  border-t border-b-global-divider-soft border-t-global-divider-soft">
                    <th className="pl-6 pr-4 py-[11.5px]">
                      <div className="flex items-center gap-3">
                        <input
                          type="checkbox"
                          className={`rounded-md  w-5 h-5 ${templateList && templateList.length > 0 ? "cursor-pointer" : "cursor-default"}`}
                          onChange={handleSelectAll}
                          checked={
                            templateList?.filter((item) => parseInt(item?.created_by_user?.own_workspace_id) === parseInt(user?.workspace_id) && item?.id !== null && parseInt(item?.workspace_id) !== 1).length > 0 &&
                            selectedTemplates.length === templateList?.filter((item) => parseInt(item?.created_by_user?.own_workspace_id) === parseInt(user?.workspace_id) && item?.id !== null && parseInt(item?.workspace_id) !== 1).length
                          }
                        />

                        <div className="flex items-center">
                          <span className="mr-1 text-xs font-medium text-global-paragraph leading-[18px]">
                            Template Name
                          </span>
                          <SortIcons
                            hasData={templateList && templateList.length > 0}
                            sortName="name"
                            {...{ orderBy, setOrderBy }}
                          />
                        </div>
                      </div>
                    </th>

                    <th className="relative px-4  py-[11.5px]  ">
                      <div className="flex items-center">
                        <span className="mr-1 text-xs font-medium text-global-paragraph leading-[18px]">
                          Active Orders
                        </span>
                        <SortIcons
                          hasData={templateList && templateList.length > 0}
                          sortName="active_orders"
                          {...{ orderBy, setOrderBy }}
                        />
                      </div>
                    </th>

                    <th className="relative px-4 py-[11.5px]  ">
                      <div className="flex items-center">
                        <span className="mr-1 text-xs font-medium text-global-paragraph leading-[18px]">
                          Tier 4
                        </span>
                        <SortIcons
                          hasData={templateList && templateList.length > 0}
                          sortName="tier_4"
                          {...{ orderBy, setOrderBy }}
                        />
                      </div>
                    </th>

                    <th className="relative px-4 py-[11.5px]  ">
                      <div className="flex items-center">
                        <span className="mr-1 text-xs font-medium text-global-paragraph leading-[18px]">
                          Tier 3
                        </span>
                        <SortIcons
                          hasData={templateList && templateList.length > 0}
                          sortName="tier_3"
                          {...{ orderBy, setOrderBy }}
                        />
                      </div>
                    </th>
                    <th className="relative px-4 py-[11.5px]  ">
                      <div className="flex items-center">
                        <span className="mr-1 text-xs font-medium text-global-paragraph leading-[18px]">
                          Tier 2
                        </span>
                        <SortIcons
                          hasData={templateList && templateList.length > 0}
                          sortName="tier_2"
                          {...{ orderBy, setOrderBy }}
                        />
                      </div>
                    </th>
                    <th className="relative px-4 py-[11.5px]  ">
                      <div className="flex items-center">
                        <span className="mr-1 text-xs font-medium text-global-paragraph leading-[18px]">
                          Tier 1
                        </span>
                        <SortIcons
                          hasData={templateList && templateList.length > 0}
                          sortName="tier_1"
                          {...{ orderBy, setOrderBy }}
                        />
                      </div>
                    </th>
                    <th className="relative px-4 py-[11.5px]  ">
                      <div className="flex items-center">
                        <span className="mr-1 text-xs font-medium text-global-paragraph leading-[18px]">
                          Tier 0
                        </span>
                        <SortIcons
                          hasData={templateList && templateList.length > 0}
                          sortName="tier_0"
                          {...{ orderBy, setOrderBy }}
                        />
                      </div>
                    </th>
                    <th className="relative px-4 py-[11.5px]  ">
                      <div className="flex items-center">
                        <span className="mr-1 text-xs font-medium text-global-paragraph leading-[18px]">
                          Date Added
                        </span>
                        <SortIcons
                          hasData={templateList && templateList.length > 0}
                          sortName="created_at"
                          {...{ orderBy, setOrderBy }}
                        />
                      </div>
                    </th>
                    <th className="relative px-4 py-[11.5px]  ">
                      <div className="flex items-center">
                        <span className="mr-1 text-xs font-medium text-global-paragraph leading-[18px]">
                          Last Updated
                        </span>
                        <SortIcons
                          hasData={templateList && templateList.length > 0}
                          sortName="updated_at"
                          {...{ orderBy, setOrderBy }}
                        />
                      </div>
                    </th>

                    <th className="relative px-4 py-[11.5px]  ">
                      <div className="flex items-center">
                        <span className="mr-1"> </span>
                      </div>
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {!istemplateQueryLoading && templateList && templateList?.length > 0 ? (
                    templateList?.map((item, index) => (
                      <tr
                        key={index}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleClickOptionButton(item.id, "view", item.workspace_id);
                        }}
                        className={`whitespace-nowrap border-b last:border-0 border-b-global-divider-soft  ${index % 2 === 0 ? "bg-global-white" : "bg-white"} rounded-lg text-global-paragraph text-sm font-normal cursor-pointer`}
                      >
                        <td className="pl-6 pr-4 py-[15.5px]  ">
                          <div className="flex items-center gap-3">
                            <input
                              onChange={() => onClickCheckbox(item)}
                              onClick={(e) => e.stopPropagation()}
                              checked={
                                selectedTemplates.some((order) => order.id === item.id) &&
                                parseInt(user?.workspace_id) !== 1 && parseInt(user?.workspace_id) === parseInt(item?.created_by_user?.own_workspace_id)
                              }
                              type="checkbox"
                              className="rounded-md border cursor-pointer w-5 h-5  "
                            />
                            <div className="flex justify-start items-center gap-3">
                              <div className="flex justify-start items-center text-table-text-data-dark font-medium text-sm gap-3">
                                <div className="bg-[#F2F4F7] h-10 w-10 rounded-full flex items-center justify-center p-2">
                                  <TemplateIcon className="w-6 h-6" />
                                </div>
                              </div>

                              <div className="flex flex-col gap-0">
                                <p className="text-sm leading-5 font-medium text-global-title">
                                  {item.name ? truncateString(item.name) : null}
                                </p>
                                <p className="text-sm text-input-text-label leading-5">
                                  {item.workspace_id && parseInt(item.workspace_id) === 1
                                    ? "System"
                                    : "Custom"}
                                </p>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className="relative px-4 py-[15.5px] ">
                          {item.workspace_id && parseInt(item.workspace_id) === 1
                            ? ""
                            : item?.active_orders === 0
                              ? 0
                              : item?.active_orders || null}
                        </td>

                        {[...item.tiers].reverse().map((tier, index) => (
                          <td key={index} className="relative px-4 py-[15.5px] truncate">
                            <div className="flex gap-1 w-full">
                              {tier.sub_tiers.slice(0, 2).map((sub_tier, subIndex) => (
                                <Button
                                  key={subIndex}
                                  variant={"status"}
                                  onClick={(e) => e.stopPropagation()}
                                  className="rounded-md w-auto h-[22px] px-2 py-[2px] border border-border-divider-stroke custom-card cursor-default capitalize text-status-text-default text-xs leading-[18px] font-medium"
                                >
                                  {truncateItems([sub_tier], 23)}
                                </Button>
                              ))}
                              {tier.sub_tiers.length > 2 && (
                                <MemberTooltip
                                  length={tier.sub_tiers?.length - 2}
                                  onClick={(e) => e.stopPropagation()}
                                  children={
                                    <>
                                      {tier.sub_tiers?.map((sub_tier, index) => (
                                        <Button
                                          key={index}
                                          onClick={(e) => e.stopPropagation()}
                                          variant={"status"}
                                          className={`rounded-[6px] h-[22px] mb-2 px-2 py-[2px] truncate text-status-text-default  custom-card text-xs leading-[18px]  font-medium cursor-default capitalize ring-0 border border-border-divider-stroke`}
                                        >
                                          {truncateItems([sub_tier], 23)}
                                        </Button>
                                      ))}
                                    </>
                                  }
                                />
                              )}
                            </div>
                          </td>
                        ))}

                        <td className="relative px-4 py-[15.5px] text-sm text-global-paragraph leading-5  ">
                          {item?.created_at
                            ? formatDateToDayMonthYear(item?.created_at)
                            : null}
                        </td>
                        <td className="relative px-4 py-[15.5px] text-sm text-global-paragraph leading-5  ">
                          {item?.updated_at
                            ? formatDateToDayMonthYear(item?.updated_at)
                            : null}
                        </td>

                        <td
                          onClick={(e) => e.stopPropagation()}
                          className="relative cursor-pointer px-4 py-[15.5px] text-sm text-global-paragraph leading-5  "
                        >
                          <IconButton
                            aria-label="more"
                            id={`${index}`}
                            aria-controls={open ? "long-menu" : undefined}
                            aria-expanded={open ? "true" : undefined}
                            aria-haspopup="true"
                            onClick={(e) => {
                              handleActionClick(item.id, item.workspace_id);
                              setAnchorEl(e.currentTarget);
                              e.stopPropagation();
                              setSelectedTemplates([]);
                            }}
                          >
                            <MoreOptions width={20} height={20} />
                          </IconButton>
                          {!isCreateTemplateOpen &&
                            actionableTemplateId &&
                            actionableTemplateId === item?.id && (
                              <Menu
                                id="long-menu"
                                MenuListProps={{
                                  "aria-labelledby": "long-button",
                                }}
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                anchorOrigin={{
                                  vertical: "top",
                                  horizontal: "left",
                                }}
                                elevation={0}
                                transformOrigin={{
                                  vertical: "top",
                                  horizontal: "right",
                                }}
                                PaperProps={{
                                  style: {
                                    maxHeight: 45 * 4.5,
                                    borderRadius: "8px",
                                    border: "1px solid var(--color-border-secondary)",
                                    padding: 0,
                                    backgroundColor: "transparent",
                                    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.05)",
                                  },
                                }}
                              >
                                <MoreActionOptionUpdated
                                  viewButton={
                                    <ViewButton
                                      title="View"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleClickOptionButton(item.id, "view", item.workspace_id);
                                      }}
                                      className="w-[248px]"
                                      shortcut="⌘+Shift + V"
                                      isNotPartner={true}
                                    />
                                  }
                                  editButton={
                                    (currentWorkspace &&
                                      currentWorkspace.role_name.includes("supplier") && currentWorkspace.invited_workspace_type === "agent") ? null : parseInt(item?.created_by_user?.own_workspace_id) === parseInt(user?.workspace_id) && hasPermission("updateTemplate") &&
                                    <EditButton
                                      title="Edit"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleClickOptionButton(item.id, "edit", item.workspace_id);
                                      }}
                                      className="w-[248px]"
                                      shortcut="⌘+E"
                                    />
                                  }
                                  duplicateButton={
                                    (currentWorkspace &&
                                      currentWorkspace.role_name.includes("supplier") && currentWorkspace.invited_workspace_type === "agent") ? null : parseInt(item?.created_by_user?.own_workspace_id) === parseInt(user?.workspace_id) && hasPermission("duplicateTemplate") &&
                                    <DuplicateButton
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleClickOptionButton(
                                          item.id,
                                          "duplicate",
                                          item.workspace_id,
                                        );
                                      }}
                                      className="w-[248px]"
                                      shortcut="⌘+D"
                                    />
                                  }
                                  deleteButton={
                                    (currentWorkspace &&
                                      currentWorkspace.role_name.includes("supplier") && currentWorkspace.invited_workspace_type === "agent") ? null : parseInt(item?.created_by_user?.own_workspace_id) === parseInt(user?.workspace_id) && hasPermission("deleteTemplate") &&
                                    <DeleteButton
                                      title="Remove"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        openSingleModal(e, item.id, item.workspace_id);
                                      }}
                                      className="w-[248px]"
                                      shortcut="⌘+Delete"
                                    />
                                  }
                                />
                              </Menu>
                            )}
                        </td>
                      </tr>
                    ))
                  ) : istemplateQueryLoading ? (
                    <tr>
                      <td colSpan={11} className="text-center pb-3">
                        <LoadingComponent />
                      </td>
                    </tr>
                  ) : searchKey && templateList && templateList?.length === 0 ? (
                    <EmptyTable colSpan={11} text="Result not found" />
                  ) : (
                    <EmptyTable colSpan={11} />
                  )}
                </tbody>
              </table>
            </div>
            {templateAllData && templateAllData.data && templateAllData.total > 0 && (
              <TertiaryPagination
                page={page}
                totalPages={Math.ceil(templateAllData.total / size)}
                setPage={setPage}
                size={size}
                handleSizeChange={handleSizeChange}
              />
            )}
          </div>
        </>
      </div>
    </>
  );
};

export default Template;
