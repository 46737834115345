import { useMutation, useQueryClient } from "@tanstack/react-query";
import { DottedIcon, LogoCollpased, TrashUpdateIcon } from "assests";
import BorderDesign from "components/UI/BorderDesign";
import { Button } from "components/UI/Button";
import ChipButton from "components/UI/ChipButton";
import CommonDiscardButtonUpdated from "components/UI/CommonDiscardButtonUpdated";
import Divider from "components/UI/Divider";
import { LineProgressBar } from "components/UI/LineProgressBar";
import Modal from "components/UI/Modal";
import useCustomToaster from "hooks/useCustomToaster";
import { useState } from "react";
import toast from "react-hot-toast";
import { cancelInvitation } from "services/workspace.services";
import { transpercencyPercentageColors } from "utils/helper";

const PendingInvitedCard = ({ item, index, refetchDatas }) => {
  const queryClient = useQueryClient();
  const { showCustomToast } = useCustomToaster();
  const [showCancelInviteModal, setShowCancelInviteModal] = useState(false);
  const cancelInvitationMutation = useMutation({
    mutationKey: ["user", "cancelInvitation"],
    mutationFn: (data) => cancelInvitation(data),
    onMutate: () => {
      showCustomToast(
        {
          title: "Canceling invitation...",
        },
        "cancelingvitation",
      );
    },
    onSuccess: (res) => {
      toast.dismiss("cancelingvitation");
      showCustomToast({
        title: "Invitation has been cancelled",
      });
      closeModal();
      refetchDatas();
      queryClient.invalidateQueries("protectedResource");
    },
    onError: (error) => {
      toast.dismiss("cancelingvitation");
      const errormessage = error.response?.data?.message || error.message || "Something went wrong";
      showCustomToast({
        title: errormessage,
      });
    },
  });

  const closeModal = () => {
    if (showCancelInviteModal) {
      setShowCancelInviteModal(false);
    }
  };

  const handleCancelInvite = (item) => {
    cancelInvitationMutation.mutate({ invitation_id: item?.id });
  };

  return (
    <>
      <Modal open={showCancelInviteModal} close={closeModal}>
        <CommonDiscardButtonUpdated
          closeModal={closeModal}
          title={`Cancel Invitation`}
          subText1={`Are you sure you want to cancel invitation? This action cannot be undone.`}
          className="w-full md:w-[600px]"
          IconComponent={DottedIcon}
          confirmButtonText="Cancel"
          closeModalText="Close"
          confirmButtonVariant="warning"
          onConfirmClick={() => handleCancelInvite(item)}
          icon={<TrashUpdateIcon strokeColor={"var(--color-icon-fill-secondary-button)"} />}
        />
      </Modal>
      <div
        className={
          "relative w-full sm:w-[344px]   max-h-[218px] rounded-xl bg-global-theme  custom-card border border-global-gray-soft"
        }
      >
        <div className="flex flex-col gap-3 p-5 relative">
          <div className="py-2 ">
            <div className="flex flex-row justify-start items-center gap-3">
              <div
                className="rounded-xl"
                style={{
                  boxShadow:
                    "0px 1.25px 2.5px 0px rgba(16, 24, 40, 0.06), 0px 1.25px 3.75px 0px rgba(16, 24, 40, 0.1)",
                }}
              >
                <BorderDesign
                  className={`w-[45px] h-[45px] rounded-xl ${item?.status === "accepted" ? "bg-global-primary" : "bg-global-paragraph"}`}
                  boxShadowShow={false}
                >
                  <LogoCollpased fillColor="var(--color-white)" />
                </BorderDesign>
              </div>
              <div className="text-global-title font-semibold text-sm lg:text-base truncate sm:max-w-[155px]">
                {item?.company_name}
              </div>
              <ChipButton>{item?.company_type}</ChipButton>
            </div>
          </div>

          <div className="text-global-paragraph font-normal capitalize text-sm">
            Pending Invitation
          </div>
          <div className="flex justify-between items-center gap-3">
            <LineProgressBar
              className="h-2"
              variant={transpercencyPercentageColors(0)}
              progress={0}
            />
            <div className="text-global-title-2 font-medium text-sm">{0}%</div>
          </div>
        </div>
        <Divider />
        <div className="w-full flex  justify-end items-center">
          {/* <Link to={`/workspace/${item?.workspace?.id}/orders`}>
            <Button variant={"link"} className="py-4 px-6 flex-1 justify-end text-end">
              View workspace
            </Button>
          </Link> */}
          <Button
            onClick={() => setShowCancelInviteModal(true)}
            variant={"link"}
            className="py-4 px-6 flex-1 justify-end text-end"
          >
            Cancel Invite
          </Button>
        </div>
      </div>
    </>
  );
};

export default PendingInvitedCard;
