import { Listbox, Transition } from "@headlessui/react";
import { InvitePlusIcon } from "assests";
import { Button } from "components/UI/Button";
import Divider from "components/UI/Divider";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setActionableCompanyType,
  toggleIsInvitationCreateModalOpen,
} from "store/accountDashboardSlice";
import { inviteCompanyTypeList } from "utils/fakeData";
import { permissions } from "utils/permissions";

const InviteCard = ({ setShowUpgradeModal, setShowUpgradeLimitModal }) => {
  const dispatch = useDispatch();
  const [isInviteTypeDropdownOpen, setIsInviteTypeDropdownOpen] = useState(false);
  const [filterdInviteViewList, setFilterdInviteViewList] = useState([]);
  const { user, currentWorkspace, subscription, subscription_status } = useSelector(
    (state) => state.authenticationSlice,
  );
  const { checkPlanLimitation, workspacePlan } = useSelector((state) => state.billingSlice);
  const handleClickOptionButton = (actionType, companyType) => {
    switch (actionType) {
      case "create":
        dispatch(setActionableCompanyType(companyType));
        dispatch(toggleIsInvitationCreateModalOpen());
        setIsInviteTypeDropdownOpen(false);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (user && currentWorkspace) {
      const role = currentWorkspace.role_name.split(".")[0];
      if (role) {
        const { canInvite } = permissions[currentWorkspace.role_name];
        const filteredList = inviteCompanyTypeList.filter((item) => canInvite.includes(item.type));
        setFilterdInviteViewList(filteredList);
      }
      const handleKeyDown = (event) => {
        // Check if 'Ctrl + R' is pressed
        if ((event.ctrlKey || event.metaKey) && (event.key === "r" || event.key === "R")) {
          event.preventDefault(); // Prevent default behavior of Ctrl+R (page refresh)
          if (checkPlanLimitation && !checkPlanLimitation.canCreatePartner) {
            setShowUpgradeLimitModal(true);
            setIsInviteTypeDropdownOpen(false);
          } else if (["supplier", "agent", "workspace"].includes(role)) {
            dispatch(setActionableCompanyType("retailer")); // Set the company type
            dispatch(toggleIsInvitationCreateModalOpen()); // Open the form
            setIsInviteTypeDropdownOpen(false);
          }
        }
        if ((event.ctrlKey || event.metaKey) && event.shiftKey && (event.key === "x" || event.key === "X")) {
          event.preventDefault(); // Prevent default behavior of Ctrl+R (page refresh)
          if (checkPlanLimitation && !checkPlanLimitation.canCreatePartner) {
            setShowUpgradeLimitModal(true);
            setIsInviteTypeDropdownOpen(false);
          } else if (["retailer", "agent", "importer", "brand", "workspace"].includes(role)) {
            dispatch(setActionableCompanyType("supplier")); // Set the company type
            dispatch(toggleIsInvitationCreateModalOpen()); // Open the form
            setIsInviteTypeDropdownOpen(false);
          }
        }
        if ((event.ctrlKey || event.metaKey) && (event.key === "a" || event.key === "A")) {
          event.preventDefault(); // Prevent default behavior of Ctrl+R (page refresh)
          if (checkPlanLimitation && !checkPlanLimitation.canCreatePartner) {
            setShowUpgradeLimitModal(true);
            setIsInviteTypeDropdownOpen(false);
          } else if (["retailer", "importer", "brand", "workspace"].includes(role)) {
            dispatch(setActionableCompanyType("agent")); // Set the company type
            dispatch(toggleIsInvitationCreateModalOpen()); // Open the form
            setIsInviteTypeDropdownOpen(false);
          }
        }
        if ((event.ctrlKey || event.metaKey) && (event.key === "b" || event.key === "B")) {
          event.preventDefault(); // Prevent default behavior of Ctrl+R (page refresh)
          if (["supplier", "agent", "workspace"].includes(role)) {
            dispatch(setActionableCompanyType("brand")); // Set the company type
            dispatch(toggleIsInvitationCreateModalOpen()); // Open the form
            setIsInviteTypeDropdownOpen(false);
          }
        }
        if ((event.ctrlKey || event.metaKey) && (event.key === "i" || event.key === "I")) {
          event.preventDefault(); // Prevent default behavior of Ctrl+R (page refresh)
          if (checkPlanLimitation && !checkPlanLimitation.canCreatePartner) {
            setShowUpgradeLimitModal(true);
            setIsInviteTypeDropdownOpen(false);
          } else if (["supplier", "agent", "workspace"].includes(role)) {
            dispatch(setActionableCompanyType("importer")); // Set the company type
            dispatch(toggleIsInvitationCreateModalOpen()); // Open the form
            setIsInviteTypeDropdownOpen(false);
          }
        }
      };
      window.addEventListener("keydown", handleKeyDown);
      return () => {
        window.removeEventListener("keydown", handleKeyDown);
      };
    }
  }, [user, currentWorkspace, dispatch]);

  return (
    <>
      {user && user.workspace_type !== "supplier" && (
        <Listbox className="relative w-full sm:w-auto">
          {({ open }) => (
            <>
              <div className="relative w-full sm:w-auto">
                <Listbox.Button
                  onClick={() => {
                    if (
                      !subscription ||
                      (subscription && (!subscription_status || subscription_status === "canceled")) || !workspacePlan
                    ) {
                      setShowUpgradeModal(true);
                      setIsInviteTypeDropdownOpen(false);
                    } else if (checkPlanLimitation && !checkPlanLimitation.canCreatePartner) {
                      setShowUpgradeLimitModal(true);
                      setIsInviteTypeDropdownOpen(false);
                    } else {
                      setIsInviteTypeDropdownOpen(true);
                      setShowUpgradeLimitModal(false);
                      setShowUpgradeModal(false);
                    }
                  }}
                  className="cursor-pointer w-full sm:w-auto"
                >
                  <div
                    className={
                      "relative bg-global-theme  w-full sm:max-w-[344px] max-h-[218px] rounded-xl border border-global-gray-soft custom-card"
                    }
                  >
                    <div className="flex flex-col p-5">
                      <div className="w-full sm:w-[304px] h-[125px] bg-global-primary rounded-[10px] ">
                        <div className="flex justify-center items-center h-full">
                          <InvitePlusIcon
                            width={31.11}
                            height={30.6}
                            className="w-[31.11] h-[30.6]"
                          />
                        </div>
                      </div>
                    </div>
                    <Divider />
                    <div className="w-full flex  justify-end items-center">
                      <div>
                        <div className="relative">
                          <Button
                            variant={"link"}
                            className="cursor-pointer justify-end items-end text-end font-semibold text-sm py-4 px-6 flex-1 border-none bg-transparent text-btn-text-link hover:text-btn-text-link-hover"
                          >
                            Invite Business Partner
                          </Button>
                          {isInviteTypeDropdownOpen ? (
                            <Transition
                              show={open}
                              as={Fragment}
                              leave="transition ease-in duration-100"
                              leaveFrom="opacity-100"
                              leaveTo="opacity-0"
                            >
                              <Listbox.Options className="absolute z-10 mt-[-10px] right-4 overflow-hidden w-[248px] bg-global-white rounded-lg shadow-[0px_4px_4px_0px_rgba(0,0,0,0.05)] border border-border-secondary">
                                <div className="flex flex-col items-center justify-start py-1 px-1.5 w-full  rounded-lg shadow-3xl bg-global-theme">
                                  <div className="grid  gap-y-1 w-full">
                                    {filterdInviteViewList &&
                                      filterdInviteViewList.length > 0 &&
                                      filterdInviteViewList.map((item, index) => (
                                        <Button
                                          type="button"
                                          variant={"action"}
                                          className={`flex items-center justify-between text-sm w-full font-semibold  gap-2 px-2.5 h-[38px] rounded-sm  hover:bg-global-white `}
                                          onClick={() =>
                                            handleClickOptionButton("create", item.type)
                                          }
                                          key={index}
                                        >
                                          <div className="flex items-center justify-start  gap-2">
                                            <item.icon
                                              fillColor={"var(--color-icon-default-4)"}
                                              strokeColor={"var(--color-icon-default-4)"}
                                              width={18}
                                              height={18}
                                            />
                                            <span>{item.name}</span>
                                          </div>
                                          <div className="text-xs  font-medium text-global-paragraph-2 px-1 py-1 bg-global-theme rounded border border-divider-stroke">
                                            {item.shortcut}
                                          </div>
                                        </Button>
                                      ))}
                                  </div>
                                </div>
                              </Listbox.Options>
                            </Transition>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </Listbox.Button>
              </div>
            </>
          )}
        </Listbox>
      )}
      {user && user.workspace_type === "supplier" && (
        <div className="relative w-full sm:w-auto">
          <div
            className={
              "relative bg-global-theme cursor-pointer w-full sm:max-w-[344px] max-h-[218px] rounded-xl border border-global-gray-soft custom-card"
            }
            onClick={() => {
              if (
                !subscription ||
                (subscription && (!subscription_status || subscription_status === "canceled")) || !workspacePlan
              ) {
                setShowUpgradeModal(true);
              } else if (checkPlanLimitation && !checkPlanLimitation.canCreatePartner) {
                setShowUpgradeLimitModal(true);
              } else {
                handleClickOptionButton("create", "retailer");
              }
            }}
          >
            <div className="flex flex-col p-5">
              <div className="w-full sm:w-[304px] h-[125px] bg-global-primary rounded-[10px] ">
                <div className="flex justify-center items-center h-full">
                  <InvitePlusIcon width={31.11} height={30.6} className="w-[31.11] h-[30.6]" />
                </div>
              </div>
            </div>
            <Divider />
            <div className="w-full flex  justify-end items-center">
              <div>
                <div className="w-full relative">
                  <div className="relative">
                    <Button
                      variant={"link"}
                      className="cursor-pointer justify-end items-end text-end font-semibold text-sm py-4 px-6 flex-1 border-none bg-transparent text-btn-text-link hover:text-btn-text-link-hover"
                    >
                      Invite Business Partner
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default InviteCard;
