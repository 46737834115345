import React from "react";

const Lock01 = React.forwardRef(
  (
    {
      width = "28",
      height = "28",
      viewBox = "0 0 28 28",
      fill = "none",
      fillColor = "#444656",
      className,
      onClick = () => {},
      ...props
    },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className, onClick }}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19.8334 11.6667V9.33333C19.8334 6.11167 17.2217 3.5 14.0001 3.5C10.7784 3.5 8.16675 6.11167 8.16675 9.33333V11.6667M14.0001 16.9167V19.25M10.2667 24.5H17.7334C19.6936 24.5 20.6737 24.5 21.4224 24.1185C22.081 23.783 22.6164 23.2475 22.9519 22.589C23.3334 21.8403 23.3334 20.8602 23.3334 18.9V17.2667C23.3334 15.3065 23.3334 14.3264 22.9519 13.5777C22.6164 12.9191 22.081 12.3837 21.4224 12.0481C20.6737 11.6667 19.6936 11.6667 17.7334 11.6667H10.2667C8.30656 11.6667 7.32647 11.6667 6.57778 12.0481C5.91921 12.3837 5.38378 12.9191 5.04823 13.5777C4.66675 14.3264 4.66675 15.3065 4.66675 17.2667V18.9C4.66675 20.8602 4.66675 21.8403 5.04823 22.589C5.38378 23.2475 5.91921 23.783 6.57778 24.1185C7.32647 24.5 8.30656 24.5 10.2667 24.5Z"
          stroke="#344054"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  },
);

export default Lock01;
