import { Button } from "components/UI/Button";

const LinkSupplier = ({ onClick, title, isDisabled, shortcut, className, icon }) => {
  return (
    <>
      <Button
        type="button"
        variant={"action"}
        className={`flex items-center justify-between text-sm w-full font-semibold  gap-2 px-2.5 h-[38px] rounded-sm  hover:bg-global-white ${className}`}
        onClick={onClick}
        isDisabled={isDisabled}
      >
        <div className="flex items-center justify-start  gap-2">
          {icon && icon}
          <span>{title || "Link Supplier"}</span>
        </div>
        <div className="text-xs font-medium text-global-paragraph-2 px-1 py-1 bg-transparent rounded border border-divider-stroke">
          {shortcut}
        </div>
      </Button>
    </>
  );
};

export default LinkSupplier;
