import React from "react";

const CertificateFill = React.forwardRef(
  (
    { width = "41", height = "40", viewBox = "0 0 41 40", fill = "none", className, ...props },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.333374 20C0.333374 8.95431 9.28768 0 20.3334 0C31.3791 0 40.3334 8.95431 40.3334 20C40.3334 31.0457 31.3791 40 20.3334 40C9.28768 40 0.333374 31.0457 0.333374 20Z"
          fill="#11C182"
        />
        <path
          d="M0.708374 20C0.708374 9.16141 9.49479 0.375 20.3334 0.375C31.172 0.375 39.9584 9.16141 39.9584 20C39.9584 30.8386 31.172 39.625 20.3334 39.625C9.49479 39.625 0.708374 30.8386 0.708374 20Z"
          stroke="black"
          stroke-opacity="0.1"
          strokeWidth="0.75"
        />
        <path
          d="M17.3334 20L19.3334 22L23.8334 17.5M26.2346 12.9985C26.4405 13.4965 26.8357 13.8924 27.3334 14.0991L29.0786 14.822C29.5767 15.0283 29.9724 15.424 30.1787 15.9221C30.3849 16.4201 30.3849 16.9797 30.1787 17.4778L29.4563 19.2218C29.2499 19.7201 29.2496 20.2803 29.4569 20.7783L30.1781 22.5218C30.2803 22.7685 30.333 23.0329 30.333 23.2999C30.3331 23.567 30.2805 23.8314 30.1783 24.0781C30.0761 24.3249 29.9263 24.549 29.7375 24.7378C29.5486 24.9266 29.3244 25.0764 29.0777 25.1785L27.3337 25.9009C26.8357 26.1068 26.4399 26.5021 26.2332 26.9998L25.5103 28.745C25.304 29.2431 24.9083 29.6388 24.4103 29.8451C23.9122 30.0514 23.3526 30.0514 22.8546 29.8451L21.1107 29.1227C20.6126 28.9169 20.0532 28.9173 19.5554 29.1239L17.8103 29.8458C17.3125 30.0516 16.7534 30.0514 16.2557 29.8453C15.7581 29.6391 15.3626 29.2439 15.1562 28.7464L14.4331 27.0006C14.2272 26.5026 13.8319 26.1067 13.3342 25.9L11.589 25.1771C11.0912 24.9709 10.6956 24.5754 10.4893 24.0777C10.2829 23.5799 10.2826 23.0205 10.4885 22.5225L11.2108 20.7786C11.4166 20.2805 11.4162 19.7211 11.2097 19.2233L10.4883 17.4768C10.3861 17.2301 10.3334 16.9657 10.3334 16.6986C10.3333 16.4316 10.3859 16.1672 10.4881 15.9204C10.5903 15.6737 10.7401 15.4496 10.9289 15.2608C11.1178 15.072 11.342 14.9222 11.5887 14.8201L13.3327 14.0977C13.8302 13.892 14.2259 13.4972 14.4327 13.0001L15.1556 11.2548C15.3619 10.7567 15.7576 10.361 16.2556 10.1547C16.7536 9.94842 17.3132 9.94842 17.8113 10.1547L19.5552 10.8771C20.0533 11.0829 20.6127 11.0825 21.1104 10.8759L22.8563 10.1558C23.3543 9.94966 23.9138 9.9497 24.4118 10.156C24.9097 10.3622 25.3053 10.7578 25.5117 11.2558L26.2348 13.0015L26.2346 12.9985Z"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  },
);

CertificateFill.displayName = "CertificateFill";

export default CertificateFill;
