import { current } from "@reduxjs/toolkit";
import { CheckCircleFill, InfoIcon } from "assests";
import { Button } from "components/UI/Button";
import DynamicSVG from "components/UI/DynamicSVG";
import React from "react";

const RecreateSubsConfirmationModal = ({ currentPlan, onClose, handClickRecreateSubscription }) => {
  return (
    <div className="relative flex items-center justify-center ">
      {currentPlan && (
        <div className="w-[350px] p-8 rounded-xl bg-login-background text-global-gray-brand flex flex-col gap-10">
          <div className="flex flex-col gap-10">
            <div className="flex flex-col gap-3">
              <div className=" text-base">{currentPlan.subscription.plan.name}</div>
              <h1 className="font-bold text-[32px] ">
                &#8364;
                {`${currentPlan.subscription.plan.package.purchase_price}/${currentPlan.subscription.plan.interval}`}
              </h1>
              <p>{currentPlan.subscription.plan.package.order_lines} order lines</p>
            </div>

            <div className="flex flex-col gap-4">
              {" "}
              <div className="flex justify-start items-center gap-3">
                <CheckCircleFill
                  fillColor={"var(--color-global-white)"}
                  height={24}
                  width={24}
                />
                <p className="global-gray-brand text-sm font-light">
                  {currentPlan.subscription.plan.package.order_lines} order lines
                </p>
              </div>
              <div className="flex justify-start items-center gap-3">
                <CheckCircleFill
                  fillColor={"var(--color-global-white)"}
                  height={24}
                  width={24}
                />
                <p className="global-gray-brand text-sm font-light">
                  {currentPlan.subscription.plan.package.articles} articles
                </p>
              </div>
              <div className="flex justify-start items-center gap-3">
                <CheckCircleFill
                  fillColor={"var(--color-global-white)"}
                  height={24}
                  width={24}
                />
                <p className="global-gray-brand text-sm font-light">Unlimited account users</p>
              </div>
              <div className="flex justify-start items-center gap-3">
                <CheckCircleFill
                  fillColor={"var(--color-global-white)"}
                  height={24}
                  width={24}
                />
                <p className="global-gray-brand text-sm font-light">Unlimited partner links</p>
              </div>
              <div className="flex justify-start items-center gap-3">
                <CheckCircleFill
                  fillColor={"var(--color-global-white)"}
                  height={24}
                  width={24}
                />
                <p className="global-gray-brand text-sm font-normal">Certificate expiry alerts</p>
              </div>
            </div>
            <div className="flex  items-center gap-4 h-[96px]">
              <Button
                type="button"
                variant="outline"
                className={"h-10 py-2.7 px-6 text-sm font-semibold text-btn-surface-default"}
                onClick={onClose}
              >
                Close
              </Button>
              <Button
                type="button"
                className=" h-10 py-2.7 px-6 whitespace-nowrap text-sm font-semibold text-global-white"
                onClick={handClickRecreateSubscription}
              >
                Renew subscription
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default RecreateSubsConfirmationModal;
