import api from "config/axios";

export const getSubSuppliers = async (query) => {
  const params = new URLSearchParams();
  try {
    Object.keys(query).forEach((key) => {
      const value = query[key];
      if (Array.isArray(value)) {
        value.forEach((item) => {
          params.append(`${key}[]`, item);
        });
      } else if (value) {
        params.append(key, value);
      }
    });
    const response = await api.get(`/sub-suppliers?${params}`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const getPorts = async (query) => {
  const params = new URLSearchParams();
  try {
    Object.keys(query).forEach((key) => {
      const value = query[key];
      if (Array.isArray(value)) {
        value.forEach((item) => {
          params.append(`${key}[]`, item);
        });
      } else if (value) {
        params.append(key, value);
      }
    });
    const response = await api.get(`/ports?${params}`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const createSubSupplier = async (data) => {
  try {
    const response = await api.post("/sub-suppliers", data);
    return response;
  } catch (error) {
    throw error;
  }
};

export const getSubSupplierDetailsById = async (data) => {
  try {
    const response = await api.get(`/sub-suppliers/${data.id}?workspace_id=${data.workspace_id}`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const updateSubSupplierDetailsById = async (data) => {
  try {
    const response = await api.put(`/sub-suppliers/${data.id}`, data.body);
    return response;
  } catch (error) {
    throw error;
  }
};

export const deleteSubSupplier = async (id) => {
  try {
    const response = await api.delete(`/sub-suppliers/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const bulkDeleteSubSuppliers = async (data) => {
  try {
    const response = await api.post(`/sub-suppliers/bulk-delete`, data);
    return response;
  } catch (error) {
    throw error;
  }
};
// export const downloadSubSuppliersAsCSV = async (workspaceId) => {
//     try {
//         const response = await api.post(`/sub-suppliers/download-csv/${workspaceId}`)
//         return response;
//     } catch (error) {
//         throw error;
//     }

// };

export const deleteSubSupplierLocation = async (id) => {
  try {
    const response = await api.delete(`/order-documents/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const downloadSubSuppliersAsCSV = async (params) => {
  try {
    const response = await api.get(`/sub-suppliers`, {
      params: { ...params, download: "yes" },
    });
    return response;
  } catch (error) {
    throw error;
  }
};
