// import { LocationOnIcon } from "assests";
// import { useEffect, useRef, useState } from "react";
// import ReactDOMServer from "react-dom/server";
// import toast from "react-hot-toast";
// import { googleMapStyles } from "utils/constant";
// import MapBusinessCard from "./MapBusinessCard";

// const MapViewUpdated = ({ details, locationFrom = null }) => {
//   const ref = useRef();
//   const [locations, setLocations] = useState([]);

//   const geocode = (address) => {
//     return new Promise((resolve, reject) => {
//       const geocoder = new window.google.maps.Geocoder();
//       geocoder.geocode({ address: address }, (results, status) => {
//         if (status === "OK") {
//           const { lat, lng } = results[0].geometry.location;
//           resolve({ lat: lat(), lng: lng() });
//         } else {
//           reject(`Geocode was not successful for the following reason: ${status}`);
//         }
//       });
//     });
//   };

//   useEffect(() => {
//     const fetchGeocodeData = async () => {
//       if (ref.current && typeof details === "object" && !locationFrom) {
//         try {
//           const geoCodeData = await geocode(details.address);
//           setLocations((prev) => [...prev, { ...details, ...geoCodeData }]);
//         } catch (error) {
//           toast.error("Failed to fetch geocode data");
//         }
//       }

//       if (ref.current && Array.isArray(details) && locationFrom === "orders") {
//         const newLocations = await Promise.all(
//           details.map(async (item) => {
//             try {
//               const geoCodeData = await geocode(item.address);
//               if (geoCodeData) {
//                 return { ...item, ...geoCodeData };
//               } else {
//                 return null;
//               }
//             } catch (error) {
//               console.error(error);
//               return null;
//             }
//           }),
//         );
//         setLocations((prev) => [...newLocations.filter((loc) => loc !== null)]);
//       }
//     };

//     fetchGeocodeData();
//   }, [details]);

//   useEffect(() => {
//     if (ref) {
//       const mapInstance = new window.google.maps.Map(ref.current, {
//         center: { lat: 0, lng: 0 },
//         zoom: 1.8,
//         scrollwheel: true,
//         zoomControl: false,
//         streetViewControl: false,
//         disableDefaultUI: true,
//         styles: googleMapStyles,
//         maxZoom: 12,
//         minZoom: 1.5,
//       });

//       const lineSymbol = {
//         path: "M 0,-1 0,1",
//         strokeOpacity: 0.7,
//         scale: 3,
//       };

//       new window.google.maps.Polyline({
//         path: locations,
//         geodesic: true,
//         strokeColor: "#37C290",
//         strokeOpacity: 0,
//         icons: [
//           {
//             icon: lineSymbol,
//             offset: "0",
//             repeat: "20px",
//           },
//         ],
//         map: mapInstance,
//       });

//       const infoWindowInstance = new window.google.maps.InfoWindow();
//       locations.forEach((locationData) => {
//         const marker = new window.google.maps.Marker({
//           position: { lat: locationData.lat, lng: locationData.lng },
//           map: mapInstance,
//           icon: {
//             url: LocationOnIcon,
//             scaledSize: new window.google.maps.Size(50, 50),
//           },
//         });

//         marker.addListener("click", () => {
//           const contentString = ReactDOMServer.renderToString(
//             <MapBusinessCard details={locationData} locationFrom={locationFrom} />,
//           );
//           infoWindowInstance.setContent(contentString);
//           infoWindowInstance.open(mapInstance, marker);
//         });
//       });
//     }
//   }, [ref, locations]);

//   return (
//     <div
//       ref={ref}
//       id="map"
//       style={{
//         position: "absolute",
//         height: "100%",
//         width: "100%",
//         top: 0,
//         borderRadius: "0px 0px 12px 12px",
//       }}
//     />
//   );
// };

// export default MapViewUpdated;

import { LocationOnIcon } from "assests";
import { useEffect, useRef, useState } from "react";
import ReactDOMServer from "react-dom/server";
import toast from "react-hot-toast";
import { googleMapStyles } from "utils/constant";
import MapBusinessCard from "./MapBusinessCard";
import { useGoogleMaps } from "contexts/GoogleMapsContext";

const MapViewUpdated = ({ details, locationFrom = null }) => {
  const ref = useRef();
  const [locations, setLocations] = useState([]);
  const { isLoaded } = useGoogleMaps();

  const geocode = (address) => {
    return new Promise((resolve, reject) => {
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ address: address }, (results, status) => {
        if (status === "OK") {
          const { lat, lng } = results[0].geometry.location;
          resolve({ lat: lat(), lng: lng() });
        } else {
          reject(`Geocode was not successful for the following reason: ${status}`);
        }
      });
    });
  };

  useEffect(() => {
    const fetchGeocodeData = async () => {
      if (ref.current && typeof details === "object" && !locationFrom) {
        try {
          const geoCodeData = await geocode(details.address);
          setLocations((prev) => [...prev, { ...details, ...geoCodeData }]);
        } catch (error) {
          toast.error("Failed to fetch geocode data");
        }
      }

      if (ref.current && Array.isArray(details) && locationFrom === "orders") {
        const newLocations = await Promise.all(
          details.map(async (item) => {
            try {
              const geoCodeData = await geocode(item.address);
              if (geoCodeData) {
                return { ...item, ...geoCodeData };
              } else {
                return null;
              }
            } catch (error) {
              console.error(error);
              return null;
            }
          }),
        );
        setLocations((prev) => [...newLocations.filter((loc) => loc !== null)]);
      }
    };

    fetchGeocodeData();
  }, [details]);

  useEffect(() => {
    if (ref && locations.length > 0) {
      const mapInstance = new window.google.maps.Map(ref.current, {
        center:
          locations.length === 1
            ? { lat: locations[0].lat, lng: locations[0].lng }
            : { lat: 0, lng: 0 },
        zoom: locations.length === 1 ? 10 : 1.8,
        scrollwheel: true,
        zoomControl: false,
        streetViewControl: false,
        disableDefaultUI: true,
        // styles: googleMapStyles,
        maxZoom: 12,
        minZoom: 1.5,
      });

      // Adjust bounds for multiple locations
      const bounds = new window.google.maps.LatLngBounds();
      locations.forEach((locationData) => {
        const marker = new window.google.maps.Marker({
          position: { lat: locationData.lat, lng: locationData.lng },
          map: mapInstance,
          icon: {
            url: LocationOnIcon,
            scaledSize: new window.google.maps.Size(50, 50),
          },
        });

        // Add each location to bounds
        bounds.extend(new window.google.maps.LatLng(locationData.lat, locationData.lng));

        // Set up InfoWindow
        const infoWindowInstance = new window.google.maps.InfoWindow();
        // marker.addListener("click", () => {
        //   const contentString = ReactDOMServer.renderToString(
        //     // <MapBusinessCard details={locationData} locationFrom={locationFrom} />
        //   );
        //   infoWindowInstance.setContent(contentString);
        //   infoWindowInstance.open(mapInstance, marker);
        // });
      });

      // Adjust the map to show all markers or set to the first location
      if (locations.length === 1) {
        mapInstance.setCenter({ lat: locations[0].lat, lng: locations[0].lng });
      } else {
        mapInstance.fitBounds(bounds);
      }
    }
  }, [ref, locations]);

  if (!isLoaded) {
    return <div className="w-full h-full bg-gray-100 animate-pulse" />;
  }

  return (
    <div
      ref={ref}
      id="map"
      style={{
        position: "absolute",
        height: "100%",
        width: "100%",
        top: 0,
        borderRadius: "0px 0px 12px 12px",
      }}
    />
  );
};

export default MapViewUpdated;
