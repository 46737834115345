import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getSubTiersList } from "services/subtiers.services";
import {
  createNewTemplate,
  getTemplateDetailsById,
  updateTemplateDetailsById,
} from "services/template.services";
import { setRefetchBillingData } from "store/billingSlice";
import {
  setTemplateDetails,
  setTemplateList,
  toggleActionableTemplateId,
  toggleCreateTemplateOpen,
  toggleDiscardModal,
  toggleIsTemplateEditable,
  toggleTemplateDuplicateAction,
} from "store/templateSlice";
import { removeQueryParam } from "utils/helper";
import { createTemplateSchema } from "validation/template.validation";
import useCustomToaster from "./useCustomToaster";
import {  updateOrderSliceState } from "store/orderSlice";
const useCreateTemplate = ({ refetch }) => {
  const queryClient = useQueryClient();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { showCustomToast } = useCustomToaster();
  const { refetchBillingData } = useSelector((state) => state.billingSlice);
  const {
    isTemplateEditable,
    discardModal,
    actionableTemplateId,
    templateList,
    templateDetails,
    isDuplicateAction,
    actionableWorkspaceId,
    isCreateTemplateOpen
  } = useSelector((state) => state.templateSlice);
  const { showCreateTemplateForm } = useSelector((state) => state.orderSlice);
  const { workspaceId } = useParams();
  const [tiersItem, setTiersItem] = useState({
    tier_1: [],
    tier_2: [],
    tier_3: [],
    tier_4: [],
    tier_5: [],
  });
  const [tierList, setTierList] = useState(null);

  const { data: SubTiers, isLoading: isSubtierListLoading } = useQuery({
    queryKey: ["template", "get-sub-tiers"],
    queryFn: getSubTiersList,
  });

  const {
    control,
    register,
    handleSubmit,
    setValue,
    reset,
    setError,
    setFocus,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      workspace_id: parseInt(workspaceId),
      subtiers: [],
      tier_1: [],
      tier_2: [],
      tier_3: [],
      tier_4: [],
      tier_5: [],
    },
    resolver: zodResolver(createTemplateSchema),
  });

  const { data: templateDetailsById, isPending: isTemplateDetailsLoading } = useQuery({
    queryKey: ["template", "getDetails"],
    queryFn: () => getTemplateDetailsById(actionableTemplateId),
    enabled: !!actionableTemplateId,
  });

  const closeModal = () => {
    if (isTemplateEditable) {
      dispatch(toggleIsTemplateEditable());
    }
    if (isCreateTemplateOpen) {
      dispatch(toggleCreateTemplateOpen());
    }
    if (showCreateTemplateForm) {
      dispatch(updateOrderSliceState({ showCreateTemplateForm: false }))
    }
    if (isDuplicateAction) {
      dispatch(toggleTemplateDuplicateAction());
    }
    if (actionableTemplateId) {
      dispatch(toggleActionableTemplateId());
    }
  }

  const createMutation = useMutation({
    mutationKey: ["template", "create"],
    mutationFn: (data) => createNewTemplate(data),
    onMutate: () => {
      showCustomToast(
        {
          title: `${isDuplicateAction ? "Duplicating the template...." : "Creating template..."}`,
        },
        "createTemplateLoadingToast",
      );
    },
    onSuccess: (response) => {
      toast.dismiss("createTemplateLoadingToast");
      const data = response.data;
      if (data.status) {
        resetTheCreateForm();
        closeModal()
        if (refetch && typeof refetch === "function") {
          refetch();
        }
        if (!refetchBillingData) {
          dispatch(setRefetchBillingData(true));
        }
        dispatch(setTemplateList({ ...data, append: true }));
        showCustomToast({
          title: "Template created successfully",
        });
      }
    },
    onError: (error) => {
      console.log(error);
      toast.dismiss("createTemplateLoadingToast");
      if (error.response?.data?.errors) {
        Object.entries(error.response.data.errors).forEach(([field, messages]) => {
          setError(field, { type: "server", message: messages[0] });
          setFocus(field);
          showCustomToast({
            title: messages[0],
          });
        });
      } else {
        showCustomToast({
          title: error.response.data.message || "Something went wrong",
        });
      }

    },
    onSettled: () => {
      toast.dismiss("createTemplateLoadingToast");
      queryClient.invalidateQueries({
        queryKey: ["template", "get-sub-tiers"],
      });
      removeQueryParam(["fromGlobalCreate", "fromGlobalSearch", "template_id", "selectedTab"], location, navigate);
    },
  });

  const updateTemplateMutation = useMutation({
    mutationKey: ["template", "updateTemplate"],
    mutationFn: (data) => updateTemplateDetailsById(data),
    onMutate: () => {
      // toast.loading("Upating the Template...", { id: "updateTemplateLoadingToast" });
      showCustomToast(
        {
          title: "Upating the Template...",
        },
        "updateTemplateLoadingToast",
      );
    },
    onSuccess: (res) => {
      toast.dismiss("updateTemplateLoadingToast");
      dispatch(setTemplateDetails(res.data.template));
      const updatedTemplate = res.data.template;
      const updatedTemplates = templateList.map((template) =>
        template.id === updatedTemplate.id ? updatedTemplate : template,
      );
      dispatch(setTemplateList({ append: false, templateList: updatedTemplates }));
      setDefaultFormValue(res.data.template);
      closeModal()
      showCustomToast({
        title: "Template updated successfully",
      });
      // toast.success("Template updated successfully");
      queryClient.invalidateQueries({ queryKey: ["templates", "updateTemplate"] });
    },
    onError: (error) => {
      showCustomToast({
        title: error.response.data.message || "Something went wrong",
      });
    },
    onSettled: () => {
      toast.dismiss("updateTemplateLoadingToast");
      queryClient.invalidateQueries({
        queryKey: ["template", "updateTemplate"],
      });
      removeQueryParam(["fromGlobalCreate", "fromGlobalSearch", "template_id", "selectedTab"], location, navigate);
    },
  });

  const handleFormSubmit = (data) => {
    const { tier_1, tier_2, tier_3, tier_4, tier_5, ...rest } = data;
    const subtiers = [tier_1, tier_2, tier_3, tier_4, tier_5]
      .filter(Boolean)
      .flatMap((tiers) => tiers.map((tier) => tier.value));
    const processedData = {
      ...rest,
      subtiers,
    };
    if (actionableTemplateId && !isDuplicateAction) {
      updateTemplateMutation.mutate({ body: processedData, id: actionableTemplateId });
    } else {
      createMutation.mutate(processedData);
    }
  };

  const onFormError = (error) => {
    console.log(error);
  };

  const handleTierOptionSelect = (tier, selectedOption) => {
    if (selectedOption && tier) {
      setTiersItem((prev) => ({
        ...prev,
        [tier]: selectedOption,
      }));
      setValue(tier, selectedOption);
    }
  };

  const handleDiscardModal = (data) => {
    if (data && data === "yes") {
      resetTheCreateForm();
      dispatch(toggleDiscardModal());
      dispatch(toggleCreateTemplateOpen());
    } else {
      dispatch(toggleDiscardModal());
    }
    removeQueryParam(["fromGlobalCreate", "fromGlobalSearch", "template_id", "selectedTab"], location, navigate);
  };

  const resetTheCreateForm = () => {
    reset();
    setTiersItem({
      tier_1: [],
      tier_2: [],
      tier_3: [],
      tier_4: [],
      tier_5: [],
    });
  };

  const onSubmit = (data) => {
    const processedData = {
      ...data,
      subtiers: data.subtiers?.value,
    };

    if (actionableTemplateId) {
      updateTemplateMutation.mutate({ body: processedData, id: actionableTemplateId });
    } else {
      createMutation.mutate(processedData);
    }
  };

  const onValidationError = (error) => {
    console.log(error);
  };

  const toggleEditOrDiscard = () => {
    dispatch(toggleIsTemplateEditable());
    if (isTemplateEditable) {
      dispatch(toggleDiscardModal());
    }
  };

  const handleConfirmDiscard = (confirm) => {
    dispatch(toggleIsTemplateEditable());
    if (!confirm) {
      dispatch(toggleDiscardModal());
      return;
    }
    dispatch(toggleIsTemplateEditable());
    setDefaultFormValue(templateDetails);
    dispatch(toggleDiscardModal());
    dispatch(toggleCreateTemplateOpen());
    removeQueryParam(["fromGlobalCreate", "fromGlobalSearch", "template_id", "selectedTab"], location, navigate);
  };

  useEffect(() => {
    if (actionableTemplateId && templateDetailsById && templateDetailsById.status === 200) {
      if (templateDetailsById.data) {
        const tierData = {};
        const templateData = templateDetailsById.data.template;
        dispatch(setTemplateDetails(templateData));
        if (templateData) {
          const itemTiers = templateData.tiers;
          setValue("name", templateData.name);
          if (itemTiers.length > 0) {
            for (const tier of itemTiers) {
              const subTiers = tier.sub_tiers;
              const tierId = tier.parent_tier_id;
              if (subTiers && subTiers.length > 0) {
                for (const subTier of subTiers) {
                  if (!tierData[`tier_${tierId}`]) {
                    tierData[`tier_${tierId}`] = [];
                  }
                  if (subTier) {
                    tierData[`tier_${tierId}`].push({ label: subTier.name, value: subTier.id });
                  }
                }
              }
            }
          }

          Object.entries(tierData).forEach(([key]) => {
            setValue(key, tierData[key]);
          });
          setTiersItem(tierData);
        }
      }
    }
  }, [templateDetailsById, actionableTemplateId]);

  useEffect(() => {
    if (SubTiers && SubTiers.data.status) {
      let tierData = {};
      for (const subTier of SubTiers.data.sub_tiers) {
        const tierId = subTier.tier.id;
        if (!tierData[`tier_${tierId}`]) {
          tierData[`tier_${tierId}`] = [];
        }
        if (subTier) tierData[`tier_${tierId}`].push({ label: subTier.name, value: subTier.id });
      }
      setTierList(tierData);
    }
  }, [SubTiers]);
  const setDefaultFormValue = (details) => {
    if (!details && !actionableTemplateId) return;
    const { name, workspace_id, subtiers: subtierId } = details;

    // const findTemplateType = templateList.find(
    //   (template) => template.value === Number(subtierId),
    // );

    setValue("name", name);
    // setValue("subtierse", findTemplateType);
    // setValue("workspace_id", workspace_id);
  };

  return {
    control,
    tierList,
    tiersItem,
    errors,
    createMutation,
    discardModal,
    actionableTemplateId,
    templateDetails,
    isTemplateEditable,
    register,
    isPending: createMutation.isPending || updateTemplateMutation.isPending,
    reset,
    handleSubmit,
    onFormError,
    handleFormSubmit,
    handleTierOptionSelect,
    onSubmit,
    handleDiscardModal,
    onValidationError,
    toggleEditOrDiscard,
    handleConfirmDiscard,
    isDuplicateAction,
    actionableWorkspaceId,
    isTemplateDetailsLoading,
    isSubtierListLoading
  };
};

export default useCreateTemplate;
