import api from "config/axios";

export const resendInvitation = (id) => api.get(`/resend/invitations/${id}`);
export const updateInvitationStatus = (data) =>
  api.post(`/invitations/status/${data.id}`, data.body);

// export const getInvitations = (query) => {
//   console.log(query);
//   const params = new URLSearchParams();
//   Object.keys(query).forEach((key) => {
//     if (query[key]) {
//       if (
//         key === "filter_column" ||
//         key === "filter_value" ||
//         key === "invitation_date" ||
//         key === "activation_date"
//       ) {
//         for (let i = 0; i < query[key].length; i++) {
//           params.append(`${key}[]`, query[key][i]);
//         }
//       } else {
//         params.append(key, query[key]);
//       }
//     }
//   });
//   return api.get(`/partners?${params}`);
// };

export const getAllCompany = async () => {
  try {
    const response = await api.get(`/invitation-filter/company`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const getAllInvitedBy = async () => {
  try {
    const response = await api.get(`/invitation-filter/invited-by`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const getAllCountry = async () => {
  try {
    const response = await api.get(`/country`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const downloadInvitationAsCSV = async (params) => {
  try {
    const response = await api.get(`/partners`, {
      params: { ...params, download: "yes" },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const bulkDeleteInvitations = async (data) => {
  try {
    const response = await api.post(`/remove-partners`, data);
    return response;
  } catch (error) {
    throw error;
  }
};

export const getCityByCountryId = (countryId) => api.get(`/city/${countryId}`);

export const deleteInvitation = async (data) => {
  try {
    const response = await api.post(`/remove-partner`, data);
    return response;
  } catch (error) {
    throw error;
  }
};

export const bulkRevokeRelations = (query) => {
  try {
    return api.post(`/revoke-relations`, {
      relations: query,
    });
  } catch (error) {
    throw error;
  }
};

export const searchPartners = (query) => {
  console.log(query);
  const params = new URLSearchParams();
  Object.keys(query).forEach((key) => {
    if (query[key]) {
      params.append(key, query[key]);
    }
  });
  console.log("params", params);
  return api.get(`/partners?${params}`);
};
