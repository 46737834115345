import React from "react";

const Importer = React.forwardRef(
  (
    {
      width = "16",
      height = "16",
      viewBox = "0 0 17 16",
      fill = "none",
      strokeColor = "#667085",
      fillColor = "white",
      className,
      ...props
    },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_11720_6239)">
          <path
            d="M5.66667 7.33333H3.73333C3.35996 7.33333 3.17328 7.33333 3.03067 7.406C2.90523 7.46991 2.80324 7.5719 2.73933 7.69734C2.66667 7.83995 2.66667 8.02663 2.66667 8.4V14M11.6667 7.33333H13.6C13.9734 7.33333 14.1601 7.33333 14.3027 7.406C14.4281 7.46991 14.5301 7.5719 14.594 7.69734C14.6667 7.83995 14.6667 8.02663 14.6667 8.4V14M11.6667 14V4.13333C11.6667 3.3866 11.6667 3.01323 11.5213 2.72801C11.3935 2.47713 11.1895 2.27316 10.9387 2.14532C10.6534 2 10.2801 2 9.53333 2H7.8C7.05326 2 6.6799 2 6.39468 2.14532C6.1438 2.27316 5.93982 2.47713 5.81199 2.72801C5.66667 3.01323 5.66667 3.3866 5.66667 4.13333V14M15.3333 14H2M8 4.66667H9.33333M8 7.33333H9.33333M8 10H9.33333"
            stroke={strokeColor}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_11720_6239">
            <rect width="16" height="16" fill={fillColor} transform="translate(0.666504)" />
          </clipPath>
        </defs>
      </svg>
    );
  },
);

Importer.displayName = "Importer";

export default Importer;
