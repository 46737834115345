import { ArrowLeft, ArrowPrev, InfoCircleIcon, LogoCollpased, StripeIcon, UpgradeIcon } from "assests";
import ToggleSwitch from "components/UI/ToggleSwitch";
import WeCare from "components/UI/WeCare";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { setAutoRenew } from "store/subscriptionSlice";

const LeftForm = ({ selectedPlanData, setValue, downgradeOrUpgrade }) => {
  const dispatch = useDispatch();
  const { selectedPlanType, autoRenew } = useSelector((state) => state.subscriptionSlice);
  if (!selectedPlanData) return null;

  const handleSwitchChange = (value) => {
    dispatch(setAutoRenew(value));
    setValue("auto_renew", value);
  };




  return (
    <div className="col-span-12 lg:col-span-6 inline-flex justify-start py-16 lg:py-[164px] bg-global-theme">
      <div className="w-full md:w-[480px] mx-auto py-0 px-4 lg:px-0">
        <div className="flex flex-col gap-6 lg:gap-16">
          <div className="w-full md:w-[377px] mx-auto">
            <button
              className="flex justify-start gap-2 items-center"
              onClick={() => window.history.back()}
            >
              <ArrowPrev strokeColor="var(--color-icon-default-2)" width={20} height={20} />
              <LogoCollpased width={15.12} height={24} className="w-[15.12px] h-6" />
            </button>
            <div className="flex flex-col gap-6 lg:gap-10">
              <div className="flex flex-col gap-2">
                <p className="text-global-paragraph text-xl lg:text-2xl font-medium pt-8 lg:pt-12">
                  {!downgradeOrUpgrade ? "Subscribe" : "Upgrade"} to <span className="uppercase">{selectedPlanData.name}</span>
                </p>
                <div className="flex flex-row gap-2">
                  <p className="text-global-title whitespace-nowrap text-3xl lg:text-5xl lg:leading-[60px] font-semibold tracking-[-2%]">
                    EUR €{selectedPlanData.price}
                  </p>
                  <p className="max-w-[62px] text-global-paragraph text-sm lg:text-xl lg:leading-[30px] font-medium">
                    per {selectedPlanType === "month" ? "month" : "year"}
                  </p>
                </div>
                <div className="flex flex-row gap-2">
                  <ToggleSwitch {...{ autoRenew, handleSwitchChange }} />
                  <p className="text-global-title font-medium text-sm lg:text-base">Auto-renew</p>
                </div>
              </div>

              <div className="flex flex-col">
                <div className="text-global-title uppercase font-semibold text-sm lg:text-base">
                  {selectedPlanData.name}
                </div>
                <div className="flex flex-row justify-between items-center">
                  <p className="font-normal text-sm lg:text-base text-global-paragraph">
                    Billed {selectedPlanType === "month" ? "monthly" : "yearly"}
                  </p>{" "}
                  <p className="text-sm lg:text-base text-global-title font-medium">
                    EUR €{selectedPlanData.price}
                  </p>
                </div>
              </div>
              <div className="flex flex-row justify-between items-center">
                <p className="font-semibold text-sm lg:text-base text-global-title">Subtotal</p>{" "}
                <p className="text-sm lg:text-base text-global-title font-medium">
                  EUR €{selectedPlanData.price}{" "}
                </p>
              </div>

              <div className="flex flex-row justify-between items-center">
                <p className="font-semibold text-sm lg:text-base text-global-title">
                  Total due today
                </p>
                <p className="text-sm lg:text-base text-global-title font-medium">
                  EUR €{selectedPlanData.price}{" "}
                </p>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-3">
            {downgradeOrUpgrade &&
              <div className="px-4 w-full py-3 bg-white rounded-xl ring-1 ring-inset ring-global-divider-soft text-sm text-global-paragraph justify-start items-start gap-3 inline-flex">
                <div>
                  <InfoCircleIcon strokeColor="var(--color-global-primary)" width={20} height={20} />
                </div>
                <div >Don't worry! You'll only be charged for the remaining days this month. Stripe will reconcile the amount of on your next invoice. Thank you for upgrading!</div>
              </div>
            }
            <WeCare className={`${downgradeOrUpgrade ? "!px-4 !py-2.5" : "!px-8 !py-[10.5px]"}`} />
          </div>
          <div className="flex justify-center items-center gap-4">
            <div className="flex flex-row gap-1">
              <p className="text-xs lg:text-sm text-text-quaternary">Powered by</p>
              <StripeIcon />
            </div>
            <Link to="https://stripe.com/legal/ssa" target="_blank" className="text-text-quaternary text-sm font-normal">
              Terms
            </Link>
            <Link to="https://stripe.com/privacy" target="_blank" className="text-text-quaternary text-sm font-normal">
              Privacy
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeftForm;
