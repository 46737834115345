import React from "react";

const checkCircleOutlined = React.forwardRef(
    (
        {
            width = "28",
            height = "28",
            viewBox = "0 0 28 28",
            fill = "none",
            strokeColor = "#344054",
            className,
            ...props
        },
        ref,
    ) => {
        return (
            <svg
                ref={ref}
                {...{ width, height, viewBox, fill, strokeColor, className }}
                {...props}
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M8.75016 14L12.2502 17.5L19.2502 10.5M25.6668 14C25.6668 20.4433 20.4435 25.6666 14.0002 25.6666C7.55684 25.6666 2.3335 20.4433 2.3335 14C2.3335 7.55666 7.55684 2.33331 14.0002 2.33331C20.4435 2.33331 25.6668 7.55666 25.6668 14Z"
                    stroke={strokeColor}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        );
    },
);

export default checkCircleOutlined;
