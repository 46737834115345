import { cva } from "class-variance-authority";
import React from "react";
import { cn } from "utils/helper";

const lineporgressVariants = cva(["h-full rounded-full"], {
  variants: {
    variant: {
      green: "bg-progress-surface-green",
      red: "bg-progress-surface-red",
      blue: "bg-progress-surface-blue",
      yellow: "bg-progress-surface-yellow",
      transparent: "bg-[#E4E7EC]",
    },
  },
  defaultVariants: {
    variant: "green",
  },
});

const LineProgressBar = ({ className, variant, progress }) => {
  const baseClasses = cn([
    "w-full h-1.5 relative bg-progress-line-surface-background rounded-full ",
    className,
  ]);
  return (
    <div className={baseClasses}>
      <div
        className={cn(lineporgressVariants({ variant }))}
        style={{ width: `${progress}%` }}
      ></div>
    </div>
  );
};

export { LineProgressBar, lineporgressVariants };
