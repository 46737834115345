const { createSlice } = require("@reduxjs/toolkit");

const navbarSlice = createSlice({
  name: "nav",
  initialState: {
    isSidebarCollapsed: window.innerWidth >= 768 ? false : true,
    showRequestDemo: false,
    isNavbarCollapsed: true,
  },
  reducers: {
    collapseSidebar: (state) => {
      return {
        ...state,
        isSidebarCollapsed: !state.isSidebarCollapsed,
      };
    },
    collapseNavbar: (state) => {
      return {
        ...state,
        isNavbarCollapsed: !state.isNavbarCollapsed,
      };
    },
    toggleRequestDemo: (state) => {
      return {
        ...state,
        showRequestDemo: !state.showRequestDemo,
      }
    }
  },
});

export const { collapseSidebar, toggleRequestDemo, collapseNavbar } = navbarSlice.actions;

export default navbarSlice.reducer;
