import React from "react";

const Duplicate = React.forwardRef(
  (
    {
      width = "16",
      height = "16",
      viewBox = "0 0 16 16",
      fill = "none",
      strokeColor = "#717680",
      className,
    },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.00065 1.33512C6.55062 1.34121 6.28045 1.36727 6.062 1.47858C5.81111 1.60641 5.60714 1.81038 5.47931 2.06126C5.368 2.27972 5.34195 2.54988 5.33585 2.99992M13.0007 1.33512C13.4507 1.34121 13.7209 1.36727 13.9393 1.47858C14.1902 1.60641 14.3942 1.81038 14.522 2.06126C14.6333 2.27972 14.6594 2.54988 14.6655 2.99991M14.6655 8.99991C14.6594 9.44995 14.6333 9.72012 14.522 9.93857C14.3942 10.1895 14.1902 10.3934 13.9393 10.5213C13.7209 10.6326 13.4507 10.6586 13.0007 10.6647M14.6673 5.33325V6.66658M9.33402 1.33325H10.6673M3.46732 14.6666H8.53398C9.28072 14.6666 9.65409 14.6666 9.93931 14.5213C10.1902 14.3934 10.3942 14.1895 10.522 13.9386C10.6673 13.6534 10.6673 13.28 10.6673 12.5333V7.46658C10.6673 6.71985 10.6673 6.34648 10.522 6.06126C10.3942 5.81038 10.1902 5.60641 9.93931 5.47858C9.65409 5.33325 9.28072 5.33325 8.53398 5.33325H3.46732C2.72058 5.33325 2.34721 5.33325 2.062 5.47858C1.81111 5.60641 1.60714 5.81038 1.47931 6.06126C1.33398 6.34648 1.33398 6.71985 1.33398 7.46658V12.5333C1.33398 13.28 1.33398 13.6534 1.47931 13.9386C1.60714 14.1895 1.81111 14.3934 2.062 14.5213C2.34721 14.6666 2.72058 14.6666 3.46732 14.6666Z"
          stroke={strokeColor}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  },
);

export default Duplicate;
