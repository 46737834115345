import React from "react";

const Morning_icon = React.forwardRef(
  (
    { width = "32", height = "32", viewBox = "0 0 32 32", fill = "none", className, ...props },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_710_1656)">
          <path
            d="M13.046 32C5.89251 32 0.0728149 26.468 0.0728149 19.6684V14.6929C0.0728149 14.1722 0.494878 13.7501 1.01557 13.7501H25.0765C25.5971 13.7501 26.0192 14.1722 26.0192 14.6929V19.6684C26.0191 26.4681 20.1994 32 13.046 32Z"
            fill="#11C182"
          />
          <path
            d="M25.0764 32H1.01593C0.495307 32 0.0731812 31.5779 0.0731812 31.0573C0.0731812 30.5366 0.495244 30.1145 1.01593 30.1145H25.0764C25.597 30.1145 26.0191 30.5366 26.0191 31.0573C26.0191 31.5779 25.597 32 25.0764 32Z"
            fill="#D5DEED"
          />
          <path
            d="M19.0299 10.8687C18.806 10.8687 18.5813 10.7899 18.4012 10.6297C18.2442 10.4901 16.8684 9.21517 16.9097 7.47285C16.9267 6.7566 17.2052 5.69435 18.4353 4.70072C18.9796 4.26103 19.2497 3.81941 19.2607 3.3506C19.2817 2.47066 18.41 1.65516 18.4012 1.64728C18.0121 1.30128 17.9772 0.705341 18.3232 0.31634C18.6693 -0.0726607 19.2652 -0.107598 19.6542 0.238402C19.8112 0.378028 21.187 1.65297 21.1457 3.39528C21.1287 4.11154 20.8502 5.17379 19.6201 6.16741C19.0784 6.60498 18.8084 7.04429 18.7949 7.51048C18.7732 8.25286 19.4105 8.99923 19.658 9.22423C20.047 9.57023 20.08 10.1644 19.734 10.5535C19.5482 10.7624 19.2896 10.8687 19.0299 10.8687Z"
            fill="#FFCE1F"
          />
          <path
            d="M13.0483 10.8687C12.8245 10.8687 12.5997 10.7899 12.4197 10.6297C12.2626 10.4901 10.8868 9.21517 10.9282 7.47285C10.9452 6.7566 11.2236 5.69435 12.4537 4.70072C12.998 4.26103 13.2681 3.81941 13.2792 3.3506C13.3001 2.47066 12.4284 1.65516 12.4196 1.64728C12.0305 1.30128 11.9956 0.705341 12.3417 0.31634C12.6877 -0.0726607 13.2836 -0.107598 13.6726 0.238402C13.8297 0.378028 15.2055 1.65297 15.1641 3.39528C15.147 4.11154 14.8686 5.17379 13.6385 6.16741C13.0969 6.60498 12.8269 7.04429 12.8133 7.51048C12.7916 8.25286 13.429 8.99923 13.6764 9.22423C14.0655 9.57023 14.0985 10.1644 13.7525 10.5535C13.5666 10.7624 13.308 10.8687 13.0483 10.8687Z"
            fill="#FFDE42"
          />
          <path
            d="M7.06429 10.8679C6.84141 10.8679 6.61772 10.7894 6.4381 10.6296C6.28103 10.49 4.90522 9.21507 4.94666 7.47275C4.96366 6.7565 5.24209 5.69425 6.47222 4.70062C7.01654 4.26093 7.2866 3.81931 7.29766 3.3505C7.3186 2.47056 6.44691 1.65505 6.4381 1.64718C6.04903 1.30118 6.0141 0.70524 6.36016 0.316239C6.70616 -0.0728238 7.30216 -0.107699 7.6911 0.238302C7.84816 0.377927 9.22398 1.65287 9.1826 3.39518C9.16554 4.11143 8.8871 5.17369 7.65704 6.16731C7.11272 6.607 6.84272 7.04863 6.8316 7.51744C6.81072 8.39738 7.68235 9.21294 7.69116 9.22076C8.08022 9.56676 8.11516 10.1627 7.7691 10.5517C7.58285 10.7611 7.3241 10.8679 7.06429 10.8679Z"
            fill="#FFDE42"
          />
          <path
            d="M27.1349 24.969H24.2761C23.7555 24.969 23.3334 24.547 23.3334 24.0263C23.3334 23.5057 23.7554 23.0835 24.2761 23.0835H27.1349C28.7378 23.0835 30.0418 21.7795 30.0418 20.1766C30.0418 18.5737 28.7378 17.2697 27.1349 17.2697H25.0764C24.5558 17.2697 24.1336 16.8476 24.1336 16.3269C24.1336 15.8063 24.5557 15.3842 25.0764 15.3842H27.1349C29.7774 15.3842 31.9273 17.534 31.9273 20.1765C31.9273 22.819 29.7774 24.969 27.1349 24.969Z"
            fill="#394257"
          />
          <path
            d="M13.7525 10.5534C14.0985 10.1643 14.0655 9.57013 13.6764 9.22413C13.5302 9.0912 13.248 8.77626 13.0459 8.3877V10.8686C13.0467 10.8686 13.0475 10.8687 13.0483 10.8687C13.308 10.8687 13.5666 10.7624 13.7525 10.5534Z"
            fill="#FFCE1F"
          />
          <path
            d="M13.6726 0.238227C13.4929 0.0783519 13.269 0.000851713 13.0459 0.000976713V2.47773C13.1831 2.73911 13.2865 3.04067 13.2792 3.35042C13.2733 3.59592 13.1959 3.83386 13.0459 4.06805V6.80043C13.1824 6.58711 13.379 6.37693 13.6386 6.16724C14.8687 5.17355 15.1472 4.1113 15.1642 3.39511C15.2055 1.65285 13.8296 0.377852 13.6726 0.238227Z"
            fill="#FFCE1F"
          />
          <path
            d="M25.0764 13.7501H13.046V32C20.1994 32 26.0191 26.468 26.0191 19.6684V14.6929C26.0191 14.1722 25.597 13.7501 25.0764 13.7501Z"
            fill="#05B091"
          />
          <path
            d="M25.0764 30.1145H13.046V31.9999H25.0764C25.597 31.9999 26.0191 31.5779 26.0191 31.0572C26.0191 30.5365 25.597 30.1145 25.0764 30.1145Z"
            fill="#BAC6DB"
          />
        </g>
        <defs>
          <clipPath id="clip0_710_1656">
            <rect width="32" height="32" fill="white" />
          </clipPath>
        </defs>
      </svg>
    );
  },
);

Morning_icon.displayName = "Morning_icon";

export default Morning_icon;
