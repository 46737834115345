import { Listbox, Transition } from "@headlessui/react";
import { IconButton, Menu } from "@mui/material";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  Action,
  Cross,
  FilterOption,
  InvitePartnerIcon,
  LogoCollpased,
  MoreOptions,
  MoreTable,
  PlanIcon,
  ReturnIcon,
  Shock,
  TrashUpdateIcon,
  WithdrawProfile,
} from "assests";
import EmptyComponent from "components/Card/EmptyComponent";
import CancelButton from "components/MoreAction/CancelButton";
import DeleteButton from "components/MoreAction/DeleteButton";
import MoreActionOptions from "components/MoreAction/MoreActionOptions";
import ViewButton from "components/MoreAction/ViewButton";
import SearchCreatePanel from "components/SearchCreatePanel";
import { Button } from "components/UI/Button";
import { FiDownload } from "components/UI/DynamicSVG";
import { LineProgressBar } from "components/UI/LineProgressBar";
import Pagination from "components/UI/Pagination";
import SortIcons from "components/UI/SortIcons";
import WithdrawInvitationModal from "pages/Agent/WithdrawInvitationModal";
import { useEffect, useState } from "react";
import TopNavbar from "components/TopHeader/TopNavbar";
import BreadCrumb from "components/TopHeader/BreadCrumb";
import SubNavbar from "layout/SubNavbar";
import SubSupplier from "pages/SubSupplier/SubSupplier";
import SupplierContents from "./SupplierContents";
import { useSelector } from "react-redux";
import queryString from "query-string";
import { useLocation, useNavigate } from "react-router-dom";
import { removeQueryParam } from "utils/helper";
const Supplier = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { currentWorkspace } = useSelector((state) => state.authenticationSlice);
  const [activeTab, setActiveTab] = useState("supplier");
  const parsed = queryString.parse(location.search);

  const [selectedOrders, setSelectedOrders] = useState([]);
  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
    setSelectedOrders([]);
    removeQueryParam(["selectedTab"], location, navigate);
  };

  useEffect(() => {
    if (currentWorkspace && !showHideSupplier()) {
      handleTabClick("subsupplier");
    }
  }, [currentWorkspace]);

  useEffect(() => {
    if (parsed.selectedTab) {
      setActiveTab(parsed.selectedTab);
    }
  }, [parsed.selectedTab]);

  const showHideSupplier = () => {
    if (currentWorkspace) {
      if (currentWorkspace.role_name.includes("supplier")) {
        return false;
      } else if (
        !currentWorkspace.role_name.includes("supplier") &&
        currentWorkspace.invited_workspace_type === "supplier"
      ) {
        return false;
      } else {
        return true;
      }
    }
  };

  return (
    <>
      <TopNavbar isFromDashboard={false}>
        <BreadCrumb
          text={activeTab === "supplier" ? "My Suppliers" : "Sub-Supplier"}
          textAnother={activeTab === "supplier" ? "" : "My Suppliers"}
          secondaryLink={() => handleTabClick("supplier")}
        />
      </TopNavbar>

      <div className="px-4 md:px-8  pt-[64px] md:pt-[108px]">
        <div className="block md:hidden pt-4">
          <BreadCrumb
            text={activeTab === "supplier" ? "My Suppliers" : "Sub-Supplier"}
            textAnother={activeTab === "supplier" ? "" : "My Suppliers"}
          />
        </div>
        <div className="pt-4 md:pt-6">
          <SubNavbar
            title={activeTab === "supplier" ? "My Suppliers" : "My Sub-Suppliers"}
            subtitle={
              activeTab === "supplier"
                ? "Invite and manage your suppliers here."
                : "Invite and manage your sub-suppliers here."
            }
          />
        </div>

        <div className="bg-global-divider-medium h-[1px] my-5  hidden md:block" />

        <div
          className={`mb-5 flex items-center justify-between bg-transparent border border-border-divider-stroke rounded-md font-semibold w-full  h-9 whitespace-nowrap mt-4 lg:mt-0 ${currentWorkspace && showHideSupplier() ? "lg:w-[220px]" : "lg:w-[129px]"}`}
        >
          {currentWorkspace && showHideSupplier() && (
            <button
              className={`w-full flex justify-center items-center text-center text-sm  px-2 lg:px-4 h-9 rounded-md ${activeTab === "supplier"
                ? "bg-white border border-border-primary text-gray-900 custom-card  "
                : "bg-transparent border-none text-gray-700"
                }`}
              onClick={() => handleTabClick("supplier")}
            >
              Supplier
            </button>
          )}
          <button
            className={`w-full flex justify-center items-center text-center text-sm px-4 h-9 rounded-md ${activeTab === "subsupplier"
              ? "bg-white border border-border-primary text-gray-900 custom-card  "
              : "bg-transparent border-none text-gray-700"
              }`}
            onClick={() => handleTabClick("subsupplier")}
          >
            Sub-Suppliers
          </button>
        </div>
        {(currentWorkspace && showHideSupplier() && activeTab === "supplier") ? (
          <>
            <SupplierContents {...{ selectedOrders, setSelectedOrders }} />
          </>
        ) : (
          activeTab === "subsupplier" && <SubSupplier handleTabClick={handleTabClick} />
        )}
      </div>
    </>
  );
};

export default Supplier;
