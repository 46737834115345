import React from "react";

const LogoutIcon = React.forwardRef(
  (
    {
      width = "20",
      height = "20",
      viewBox = "0 0 20 20",
      fill = "none",
      strokeColor = "white",
      className,
      ...props
    },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.8333 14.1666L18 9.99997M18 9.99997L13.8333 5.8333M18 9.99997H8M10.5 14.1666C10.5 14.413 10.5 14.5361 10.4908 14.6428C10.3957 15.7516 9.57905 16.664 8.48753 16.881C8.38252 16.9019 8.26001 16.9155 8.01529 16.9427L7.16412 17.0373C5.8854 17.1794 5.24601 17.2504 4.73805 17.0879C4.06078 16.8711 3.50785 16.3762 3.21765 15.727C3 15.2401 3 14.5968 3 13.3102V6.68972C3 5.40311 3 4.7598 3.21765 4.2729C3.50785 3.6237 4.06078 3.12881 4.73805 2.91208C5.24601 2.74953 5.88538 2.82057 7.16412 2.96265L8.01529 3.05723C8.2601 3.08443 8.3825 3.09803 8.48753 3.11891C9.57905 3.33593 10.3957 4.24834 10.4908 5.35714C10.5 5.46383 10.5 5.58699 10.5 5.8333"
          stroke={strokeColor}
          strokeWidth="1.66667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  },
);

export default LogoutIcon;
