import { Cross, DragDropIcon, MailIcon, UploadImgIcon, UserIcon } from "assests";
import { Button } from "components/UI/Button";
import ErrorMessage from "components/UI/ErrorMessage";
import { Input } from "components/UI/Input";
import { Label } from "components/UI/Label";
import ToasterIcon from "components/UI/ToasterIcon";
import UpgradeComponent from "components/UI/UpgradeComponent";
import { useEffect, useState } from "react";
import useGeoLocation from "react-ipgeolocation";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import flags from "react-phone-number-input/flags";
import { useSelector } from "react-redux";
import { handleRestrictNumbersOnKeyPress, handleRestrictNumbersOnPaste } from "utils/helper";
const Editprofile = ({
  closeModal,
  reset,
  setValue,
  isPending,
  onError,
  getValues,
  errors,
  register,
  handleSubmit,
  clearErrors,
  control,
  isDragOver,
  handleDragEnter,
  handleDragLeave,
  handleDrop,
  onFileDropOrChange,
  imagePreviews,
}) => {
  const { user } = useSelector((state) => state.authenticationSlice);
  const [defaultCountryCode, setDefaultCountryCode] = useState();
  const [phoneNumberErrorMessage, setPhoneNumberErrorMessage] = useState(null);
  const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(false);
  const location = useGeoLocation();
  useEffect(() => {
    if (location) {
      setDefaultCountryCode(location.country);
    }
  }, [location]);

  const handlePhoneNumberChange = (value) => {
    if (value === undefined || !isValidPhoneNumber(value)) {
      setValue("telephone", value);
      setPhoneNumberErrorMessage("Invalid phone number");
      setIsNextButtonDisabled(true);
    } else {
      setPhoneNumberErrorMessage(null);
      clearErrors("telephone");
      setValue("telephone", value);
      setIsNextButtonDisabled(false);
    }
  };

  return (
    <>
      <div className="w-full md:max-w-[640px] lg:w-[640px] p-6 relative z-[2] bg-white border border-global-divider-soft rounded-xl ">
        <div className="flex justify-between mb-4">
          <div>
            <UpgradeComponent>
              <UserIcon fillColor="var(--color-icon-default-2)" />
            </UpgradeComponent>
          </div>
          <div>
            <Cross
              fillColor="var(--color-icon-fill-input)"
              width={24}
              height={24}
              onClick={closeModal}
              className="cursor-pointer"
            />
          </div>
        </div>
        <form onSubmit={handleSubmit} onError={onError}>
          <div className="grid grid-cols-1 gap-4 z-[2]">
            <div>
              {" "}
              <h3 className="text-base lg:text-lg font-semibold text-global-title leading-7">
                Profile details
              </h3>
              <p className="text-xs lg:text-sm text-global-paragraph leading-5">
                Update your profile details here.
              </p>
            </div>
            <div className="grid grid-cols-12 gap-4">
              <div className="col-span-12 lg:col-span-6">
                <div className="grid gap-1.5">
                  <Label htmlFor="first_name">
                    First Name<span className="text-global-primary ml-[2px]">*</span>
                  </Label>

                  <Input
                    id="first_name"
                    type="text"
                    placeholder="Enter"
                    onKeyPress={handleRestrictNumbersOnKeyPress}
                    onPaste={handleRestrictNumbersOnPaste}
                    {...register("first_name", {
                      setValueAs: (value) => value.trim(),
                    })}
                  />
                  {errors.first_name && <ErrorMessage errors={errors?.first_name?.message} />}
                </div>
              </div>
              <div className="col-span-12 lg:col-span-6">
                <div className="grid gap-1.5">
                  <Label htmlFor="last_name">
                    Last Name<span className="text-global-primary ml-[2px]">*</span>
                  </Label>

                  <Input
                    id="last_name"
                    type="text"
                    placeholder="Enter"
                    onKeyPress={handleRestrictNumbersOnKeyPress}
                    onPaste={handleRestrictNumbersOnPaste}
                    {...register("last_name", {
                      setValueAs: (value) => value.trim(),
                    })}
                  />
                  {errors.last_name && <ErrorMessage errors={errors?.last_name?.message} />}
                </div>
              </div>
            </div>
            <div className="grid gap-1.5">
              <Label htmlFor="email" className="flex justify-start">
                Email
                <span className="text-global-primary ml-[2px]">*</span>
              </Label>
              <Input
                id="email"
                type="email"
                name="email"
                placeholder="Enter email"
                {...register("email")}
                iconLeft={<MailIcon width={20} height={20} />}
              />
              {errors.email && <ErrorMessage errors={errors?.email?.message} />}
            </div>
            <div className="grid gap-1.5">
              <Label htmlFor="phone">
                Phone<span className="text-global-primary ml-[2px]">*</span>
              </Label>

              <div className="flex w-full h-[44px] gap-1.5 flex-start phone-input-field border border-input-stroke-default bg-input-surface-background hover:border-gray-800   hover:transition hover:duration-300 hover:ease-in-out ">
                <PhoneInput
                  autoComplete="off"
                  name="telephone"
                  international
                  defaultCountry={defaultCountryCode || ""}
                  withCountryCallingCode
                  flags={flags}
                  value={(user?.telephone && `+${user?.telephone}`) || ""}
                  countryCallingCodeEditable={false}
                  onChange={handlePhoneNumberChange}
                  countrySelectProps={{
                    unicodeFlags: true,
                  }}
                />
              </div>
              {(phoneNumberErrorMessage || errors?.telephone?.message) && (
                <ErrorMessage errors={phoneNumberErrorMessage || errors?.telephone?.message} />
              )}
            </div>
            <div className="grid gap-1.5">
              <Label htmlFor="title">Job Title</Label>

              <Input
                id="job_title"
                type="text"
                placeholder="Enter job title"
                className="bg-input-surface-background"
                onKeyPress={handleRestrictNumbersOnKeyPress}
                onPaste={handleRestrictNumbersOnPaste}
                {...register("job_title")}
              />
              {errors.job_title && <ErrorMessage errors={errors?.job_title?.message} />}
            </div>
            <div className="grid gap-1.5">
              <Label>Profile Image</Label>
              <div className="flex flex-col md:flex-row gap-3 items-center justify-center md:items-start md:justify-start lg:gap-5">
                <div>
                  {imagePreviews?.length > 0 ? (
                    <div className=" flex justify-center items-center  p-0 ring-1 ring-global-divider-soft rounded-full w-12 h-12 md:h-16 md:w-16  text-btn-text-default bg-transparent">
                      <div className="w-12 h-12 md:h-16 md:w-16 ">
                        {imagePreviews &&
                          imagePreviews?.map((preview, index) => (
                            <div className="relative" key={index}>
                              <img
                                src={preview}
                                alt={`Preview${index}`}
                                key={new Date().getTime()}
                                className="rounded-full w-12 h-12 md:h-16 md:w-16"
                              />
                            </div>
                          ))}
                      </div>
                    </div>
                  ) : (
                    <div className="flex bg-global-surface justify-center uppercase items-center text-2xl font-semibold text-text-quaternary w-12 h-12 md:h-16 md:w-16 p-0 ring-1 ring-global-divider-soft rounded-full border-none">
                      {user &&
                        `${user?.first_name?.substring(0, 1)}${user?.last_name?.substring(0, 1)}`}
                    </div>
                  )}
                </div>
                <div className="group">
                  <div
                    className={`w-full  relative  bg-white rounded-xl h-[126px] border border-border-divider-stroke group-hover:border-2  group-hover:border-global-primary md:w-[508px]`}
                    onDragOver={handleDragEnter}
                    onDragEnter={handleDragEnter}
                    onDragLeave={handleDragLeave}
                    onDrop={handleDrop}
                  >
                    <div>
                      <label htmlFor="profile-dropzone-file" className="cursor-pointer">
                        <div className="w-full flex-col px-3 md:px-6 py-[15.25px] justify-start items-center inline-flex">
                          <div className="w-full flex-col justify-start items-center gap-3 flex">
                            <ToasterIcon className="rounded-lg">
                              <UploadImgIcon />
                            </ToasterIcon>
                            <div className="flex-col justify-start items-center gap-1 flex">
                              <div className="justify-center items-start gap-1 inline-flex">
                                <div className="text-global-title text-sm font-semibold leading-tight">
                                  Click to upload
                                </div>
                                <div className="text-global-paragraph text-sm font-normal leading-tight">
                                  or drag and drop
                                </div>
                              </div>
                              <div className="text-center text-global-paragraph text-xs font-normal  leading-[18px]">
                                JPEG, JPG or PNG (max. 500x500px)
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="h-full hidden group-hover:block">
                          <DragDropIcon className="absolute top-1 right-1 md:top-auto md:bottom-5 md:right-6 " />
                        </div>
                      </label>

                      <input
                        type="file"
                        id="profile-dropzone-file"
                        accept=".png,.jpg,.jpeg"
                        className="hidden"
                        onChange={(e) => onFileDropOrChange(e)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col lg:flex-row justify-center gap-3 mt-2 lg:mt-4">
              <Button
                onClick={closeModal}
                variant={"secondary"}
                className={"w-full h-11 px-4 py-2.5"}
              >
                Cancel
              </Button>
              <Button
                isDisabled={isNextButtonDisabled}
                type="submit"
                variant={"primary"}
                className={"w-full h-11 px-4 py-2.5"}
              >
                Save
              </Button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default Editprofile;
